import React from 'react'
import {localisationMaster} from "Localisation/Localisation";
import MainTableRow from "../../Global/MainTable/MainTableRow/MainTableRow";

import "./TeacherCard.scss"

export default function TeacherCard(props) {
    const link = 'online-schedule/booked-teachers-schedule'

    const colsSettings = {
        is_teacher_extended_schedule: true,
        id: {name: 'id'},
        main_rows: [
            {name: 'teacher_name'},
            {name: 'teacher_plan'},
            {name: 'teacher_reserve'},
        ],
        action: [
            {name: localisationMaster('text-info-4', link), action: 'detail', permission: ['forward']}, // разрешение вперед - костыль
        ],
        sub_rows: []
    }

    const getHours = min => Math.trunc(min/60) + (min%60 && ':' + min%60)

    const item = props.item
    item.teacher_plan = "Месячный план: " + getHours(item.month_plan * 60) + " ч"
    item.teacher_reserve = "Зарезервировано: " + getHours(item.reserved * 60) + " ч"

    return (
        <div className="teacherCard">
            <MainTableRow colsSettings={colsSettings} items={item} />
        </div>
    )
}
