import React from 'react';
import Paper from 'components/UI/Paper/Paper';
import Flex from 'components/UI/Flex/Flex';
import Button from 'components/UI/Button/Button';
import styles from './SlotDelete.module.scss';

function SlotDelete() {
  const isError = true;

  return (
    <Paper>
      <Flex>
        <div>
          <p className={styles.text}>Удалить все бронирования</p>
          {isError &&
            <p className={styles.error_text}>Необходимо удалить все забронированные клиентами слоты</p>
          }
        </div>
        <Button
          onClick={() => {}}
          isDisabled={isError}
          isWide
        >
          Удалить
        </Button>
      </Flex>
    </Paper>
  );
}

export default SlotDelete;
