import {action, computed, makeObservable, observable, toJS} from 'mobx';
import { superAxiosRequest } from 'axiosApi';

export const DataType = {
  activeTab: 'activeTab',
  filtersData: 'filtersData',
};

export const ActionType = {
  resetAll: 'resetAll',
  resetCalendar: 'resetCalendar',
  resetCustomers: 'resetCustomers',
};

const getLists = (...args) => {
  const employeesList = new Map();
  const programsList = new Map();

  args.forEach((array) => {
    array.forEach(({employee_name, employee_id, program_id, program_name}) => {
      employeesList.set(employee_id, employee_name);
      programsList.set(program_id, program_name);
    })
  });
  return {
    employees: [...employeesList.entries()].map(([value, label]) => ({ value, label })),
    programs: [...programsList.entries()].map(([value, label]) => ({ value, label })),
  };
};

class SlotsBookedScheduleStore {
  companies = [];
  selectedCompany = null;
  selectedLocation = null;
  dates = [];
  selectedDates = new Set();
  isCustomersLoading = true;
  customers = [];
  activeTab = 0;
  filtersData = null;
  employeesList = [];
  programList = [];

  constructor() {
    makeObservable(this, {
      companies: observable,
      selectedCompany: observable,
      selectedLocation: observable,
      dates: observable,
      selectedDates: observable,
      isCustomersLoading: observable,
      customers: observable,
      activeTab: observable,
      filtersData: observable,
      employeesList: observable,
      programList: observable,

      companiesList: computed,
      locations: computed,
      filteredData: computed,
      selectedDate: computed,

      setData: action.bound,
      resetData: action.bound,
      getDatesFromServer: action.bound,
      getCustomersFromServer: action.bound,
      sendNotification: action.bound,
      filterData: action,
      deleteCustomer: action.bound,
    });
  }

  get companiesList() {
    if (!this.companies.length) {
      return [];
    }
    return toJS(this.companies)
      .map((company) => ({ ...company, value: company.id, label: company.name, }))
      .sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get locations() {
    if (!this.selectedCompany) {
      return [];
    }
    const locations = this.companies.find((item) => item.id === this.selectedCompany.value).locations;
    return toJS(locations)
      .map((location) => ({...location, value: location.id, label: location.name}))
      .sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get selectedDate() {
    return this.dates[[...this.selectedDates.values()][0]].date_at;
  }

  get filteredData() {
    return this.customers.map((array) => {
      return this.filterData(array, this.filtersData);
    })
  }

  filterData(array, filter) {
    if (!filter) {
      return array;
    }
    return array.filter(({student_name, student_phone, student_email, program_id, employee_id}) => {
      const isInputFilter = filter?.input
        ? student_name.toLowerCase().includes(filter.input) ||
          (student_phone && student_phone.toLowerCase().includes(filter.input)) ||
          (student_email && student_email.toLowerCase().includes(filter.input))
        : true ;
      const isProgram = filter?.program ? program_id === filter.program : true;
      const isEmployee = filter?.employee ? employee_id === filter.employee : true;

      return isInputFilter && isProgram && isEmployee;
    });
  }

  setData(data, type) {
    this[type] = data;
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetAll:
      default:
        this.selectedLocation = null;
        this.selectedDates.clear();
        this.filtersData = null;
        break;
      case ActionType.resetCalendar:
        this.selectedDates.clear();
        this.activeTab = 0;
        this.filtersData = null;
        break;
      case ActionType.resetCustomers:
        this.customers = [];
        this.activeTab = 0;
        break;
    }
  }

  getDatesFromServer({ params, onFinally }) {
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/booked_schedule',
      params: {
        ...params,
        step: 1,
        company: this.selectedCompany.value,
        location: this.selectedLocation.value,
      },
    })
      .then(({data}) => this.dates = data)
      .finally(() => onFinally());
  }

  getCustomersFromServer() {
    this.isCustomersLoading = true;
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/booked_schedule',
      params: {
        step: 2,
        company: this.selectedCompany.value,
        location: this.selectedLocation.value,
        date_from: this.selectedDate,
      },
    }).then(({ data }) => {
      this.customers = [data.active, data.waiting, data.deleted];
      const {employees, programs} = getLists(data.active, data.waiting, data.deleted);
      this.employeesList = employees;
      this.programList = programs;
    })
      .finally(() => this.isCustomersLoading = false);
  }

  sendNotification(data, onSuccess, onFail) {
    superAxiosRequest({
      method: 'put',
      link: 'online_schedule/resend_notification',
    }, data)
      .then(() => onSuccess())
      .catch(({response}) => {
        let error = '';
        if (typeof response.data === 'object') {
          error = Object.values(response.data)[0][0];
        }
        onFail(error);
      });
  }

  deleteCustomer(id, onSuccess, onFail) {
    superAxiosRequest({
      method: 'delete',
      link: `online_schedule/booked_schedule/${id}`,
    })
      .then(() => {
        const deletedSlot = this.filteredData[this.activeTab].find((item) => item.id === id);
        if (this.activeTab === 0) {
          const dateIndex = this.dates.findIndex(({ date_at }) => date_at === deletedSlot.slot_date);
          this.dates[dateIndex] = {...this.dates[dateIndex], booked_slots: this.dates[dateIndex].booked_slots - 1};
        }

        this.customers[this.activeTab] = this.customers[this.activeTab].filter((item) => item.id !== id);
        this.customers[2].push(deletedSlot);
        onSuccess();
      })
      .catch(() => onFail());
  }
}

export default new SlotsBookedScheduleStore();
