import React, { useState, useEffect, useRef } from 'react';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import Calendar from 'components/ContentBox/Online-schedule/components/Calendar';
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import MainTableRow from 'components/ContentBox/Global/MainTable/MainTableRow/MainTableRow';
import ChoiceLocation from '../components/ChoiceLocation/ChoiceLocation';
import swal from "sweetalert";
import Loader from "components/ContentBox/Global/Loader/Loader";
//import Stores from 'Stores';
//import CardRecordCustomer from './CardRecordCustomer'
//import Stores from '../../../../Stores';

import "./BookedOnlineShedule.scss"
import Stores from '../../../../Stores';
import { Link, Redirect } from 'react-router-dom';
import { colsSettings, reSend } from './settings';
import SurfMenu from "../../Groups/components/SurfMenu";

import "./BookedOnlineShedule.scss"



// Компонент 'Расписание записи клиентов'
// http://localhost:3000/online-schedule/booked-online-schedule
const BookedOnlineSchedule = () => {
    var apiConf;
    let LinkLocal = 'online-schedule/booked-online-schedule'

    // Настройки отображения клиентов

    // Фильтр клиентов
    const tabs = [
        { name: 'Подтвержденные', link: 'is_verify' },
        { name: 'Ожидают оплаты', link: 'is_waiting_for_payment' },
        { name: 'Удаленные/не оплатившие', link: 'is_active' }
    ]

    const [location, setLocation] = useState(0);
    const [locationList, setLocationList] = useState([]);
    const [someDay, setSomeDay] = useState(0);
    const [slotsLoaded, setSlotsLoaded] = useState([false, []])
    const [dateSaE, setDateSaE] = useState([]);
    const [loaded, setLoaded] = useState([false, []])
    const [redirect, setredirect] = useState([false, ''])
    const REDIRECT_REF = useRef()
    const [config, setconfig] = useState(colsSettings)
    const [clientsType, setClientsType] = useState('is_verify')

    const timeConverter = UNIX_timestamp => {
        const a = new Date(UNIX_timestamp);
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        let date = a.getDate();
        if (date < 10) {
            date = '0' + date;
        }
        return year + '-' + month + '-' + date;
    }


    const updateSlot = async (data) => {
        localStorage.setItem('tmp_array', JSON.stringify(data))
        window.location = '/online-schedule/record-customer'
    }

    const removeSlot = (id) => {
        swal({
            title: "Вы уверены?",
            text: "Удалить запись?",
            icon: "warning",
            buttons: ["Нет", "Да"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    apiConf = {
                        method: 'delete',
                        link: `time_slots/teacher/clients/${id}`,
                    }
                    superAxiosRequest(apiConf).then(() => {
                        reload();
                    })
                        .catch(() => {
                        });
                    swal(`Запись удалена`, {
                        icon: "success",
                    });
                } else {
                    swal("Удаление отменено")

                }
            })

    }

    const createDate = (dts, nav) => {
        let newDate = new Date();
        if (dts) newDate = new Date(dts)
        if (nav) {
            switch (nav) {
                case 'prev':
                    newDate = new Date(newDate.setDate(newDate.getDate() - 7));
                    break;
                case 'next':
                    newDate = new Date(newDate.setDate(newDate.getDate() + 7));
                    break;
                default: break;
            }
        }
        let day = newDate.getDay();
        if (day === 0) { day = 7 }
        let start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
        let end = newDate.setDate(newDate.getDate() + 27);
        setDateSaE([timeConverter(start), timeConverter(end)])
        reload()
    }
    //const [dateSaE, setDateSaE] = useState(createDate());

    const updateAvailable = day => {
        setSomeDay(day)
        setSlotsLoaded([false, []])
        setClientsType('is_verify')
    }

    //const [someDay, setSomeDay] = useState(timeConverter((new Date()).getTime()));

    const createDaysInformation = (array) => {
        let daysInformation = [];
        let newDate = new Date(dateSaE[0] + ' 00:00:01')
        let day = newDate.getDay()
        // let newDate = new Date(Date.now());
        //let day = (new Date(Date.now())).getDay()
        if (day === 0) { day = 7 }
        let start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
        let inject, i, somd, dow, c, fullDate, fullDate_timestampt;

        for (i = 0; i < 28; i++) {
            c = i * 86400;
            somd = Math.round(new Date(start).getTime() / 1000);
            somd = (somd + c) * 1000;

            dow = (new Date(somd)).getDay();

            if (dow === 0) { dow = 7 }

            fullDate_timestampt = (new Date(somd)).getTime();
            fullDate = timeConverter(fullDate_timestampt)

            somd = (new Date(somd)).getDate();
            if (array !== undefined) {
                inject = { date: somd, clients_count: array[i]['clients_count'], time: 'Не указано', dow: dow, slotSize: '', date_full: fullDate }
            } else {
                inject = { date: somd, clients_count: '1'/*clientsInSlot[i]['client_count']*/, time: 'Не указано', dow: dow, slotSize: '', date_full: fullDate }
            }
            daysInformation.push(
                inject
            )

        }
        return (daysInformation)
    }

    const reload = () => {
        setSlotsLoaded([false, []]);
        setLoaded([false, []]);
        setClientsType('is_verify')
    }

    const toClipBoard = text => {
        navigator.clipboard.writeText(text)
            .then(() => {
                // window.alert('Ссылка скопирована в буфер обмена')
                swal("", 'Ссылка скопирована в буфер обмена', "success")
            })
    }

    const action = (data, e, items) => {
        let a, body
        switch (data) {
            case 'zoom':
                toClipBoard(items.zoom_url)
                break;
            case 'sms':
                reSend(items.id, 'sms')
                break;
            case 'email':
                reSend(items.id, 'email')
                break;
            case 'delete':
                removeSlot(items.id)
                break;
            case 'edit':
                updateSlot(items)
                break;
            case 'checkEmail':
                body = {
                    email: items.student.email
                }
                /*                 Stores.setFiltred(body)
                                setredirect([true, '/c/mailing/sms/'])
                 */
                localStorage.setItem('filtred', JSON.stringify(body))
                a = document.createElement('a');
                a.target = "_blank";
                a.href = '/c/mailing/email/'
                a.click();
                break;
            case 'checkSms':
                body = {
                    phone: items.student_phone
                }
                Stores.setFiltred(JSON.stringify(body))
                setredirect([true, '/c/mailing/sms/'])
                //customHistory.push('/c/mailing/sms/')
                //localStorage.setItem('filtred', JSON.stringify(body))
                /*  a = document.createElement('a');
                 a.target = "_blank";
                 a.href = '/c/mailing/sms/'
                 a.click(); */
                break;
            default: break;
        }
    }

    const updateLocation = location => {
        setLocation(location.id);
        setLoaded([false, []]);
        setSomeDay(0);
        setSlotsLoaded([false, []]);
    }

    useEffect(() => {
        redirect[0] && REDIRECT_REF.current.click()
    }, [redirect])

    useEffect(() => {
        createDate(new Date())
    }, [])


    useEffect(() => {
        setSlotsLoaded([false, []])
    }, [clientsType])

    useEffect(() => {
        //superAxiosRequest();
        if (locationList.length < 1) {
            apiConf = {
                method: 'get',
                link: 'db/locations',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                setLocationList(response.data.result);
                //setisLOADED(true)
            });
        }
        if (location !== 0 && !loaded[0]) {
            let apiConf = {
                method: 'get',
                link: 'time_slots/teacher/clients_date',
                params: {
                    from_date: dateSaE[0],
                    to_date: dateSaE[1],
                    location: location
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                setLoaded([true, createDaysInformation(response.data)])
            })
                .catch(() => {

                })
        }

        if (someDay !== 0 && !slotsLoaded[0]) {
            let whereTake = 'teacher_slot'
            let apiConf = {
                method: 'get',
                link: 'time_slots/teacher/clients',
                params: {
                    is_future: true,
                    s_date: someDay,
                    location: location,
                    limit: 300,
                }
            }

            // Фильтр слотов
            if (clientsType){
                if(clientsType === 'is_waiting_for_payment'){
                    apiConf.params.is_verify = false
                } else if(clientsType === 'is_active'){
                    apiConf.params[clientsType] = false
                    whereTake = 'prev_teacher_slot'
                } else {
                    apiConf.params[clientsType] = true
                }
            }

            superAxiosRequest(apiConf, 'data').then(response => {
                let array = response.data.result.sort(((a, b) => a[whereTake].slot.time_start > b[whereTake].slot.time_start ? 1 : -1))
                setSlotsLoaded([true, array])
            });
        }
    });

    const getColSettings = (colsSettings, clientsType, index) => {
        let settings = {...colsSettings}
        if(clientsType === 'is_active' && slotsLoaded[1][index].prev_teacher_slot){
            let main_rows = [
                {
                    compiled:
                        [
                            { name: '_last_name', updir: 'student', past: ' ', function: 'transliterate' },
                            { name: '_first_name', updir: 'student', function: 'transliterate' },
                        ]
                },
                {
                    compiled: [
                        { name: 'time_start', updir: 'prev_teacher_slot', sub_dir: 'slot', past: ', ', function: 'slice_time' },
                        { name: 's_date', updir: 'prev_teacher_slot', function: 'date_without_year' },
                    ]
                },
                { name: 'result' },
            ]
            settings.main_rows = main_rows
        } else if(slotsLoaded[1][index].teacher_slot){
            settings = {...colsSettings}
        }
            return settings
    }

    return (
        <>  <div style={{ opacity: 0 }}>
            <Link to={redirect[1]} /* target="_blank" */ ref={REDIRECT_REF} />
        </div>
            <ContentHeader title={localisationMaster('title', LinkLocal)} />
            <div className="booked-online-schedule-page">
                <div className="booked-online-schedule-page__container">
                    <ChoiceLocation
                        locationId={location}
                        setLoaded={setLoaded}
                        // soft_drop={softDrop} // *?
                        onChangeLocation={updateLocation}
                    />

                    {location !== 0 &&
                        <Calendar loaded={loaded[1]}
                            calendarType="slots_client_count"
                            setSomeDay={updateAvailable}
                            someDayValue={someDay}
                            slide={true}
                            SaE={dateSaE}
                            slideAction={createDate}
                        />
                    }
                </div>

                {loaded[0] &&
                    <SurfMenu menu={tabs} type={clientsType} changeType={setClientsType} />
                }

                <div className="booked-online-schedule-page__container">
                    {(slotsLoaded[0])?
                        <div className="booked-online-schedule-page__clients">
                            {slotsLoaded[1].map((item, index) =>
                                <MainTableRow items={item}
                                    colsSettings={getColSettings(colsSettings, clientsType, index)}
                                    drop={reload}
                                    key={index}
                                    actions={action}
                                />
                            )}
                        </div>
                        : loaded[0] && someDay !== 0 ? <Loader /> : null}
                </div>

                <div style={{ height: '200px' }} />
            </div>
        </>
    )
}

export default BookedOnlineSchedule