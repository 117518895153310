import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/UI/Flex/Flex';
import Tab from 'components/UI/Tab/Tab';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';
import styles from './TimeTabs.module.scss';
import ModalWrapper from 'components/UI/ModalWrapper/ModalWrapper';
import InputWithControls from 'components/UI/InputWithControls/InputWithControls';
import Button from 'components/UI/Button/Button';
import Hr from 'components/UI/Hr/Hr';

const selectors = [
  { id: 1, value: 15, text: '15 мин' },
  { id: 2, value: 30, text: '30 мин' },
  { id: 3, value: 45, text: '45 мин' },
  { id: 4, value: 60, text: '60 мин' },
  { id: 5, value: 90, text: '90 мин' },
  { id: 6, value: 'custom', text: 'Свое' },
];
const findSelectedValue = (id) => selectors.find((item) => item.id === id).value;
const isCustomDuration = (duration) => selectors.every(({value}) => value !== duration);

function TimeTabs() {
  const { duration, setTime } = slotsScheduleSettingsStore;
  const [isModalShown, setIsModalShown] = useState(false);
  const [customTime, setCustomTime] = useState(duration);

  const handleClick = (evt) => {
    const id = +evt.target.dataset.id;
    id === 6 ? setIsModalShown(true) : setTime(findSelectedValue(id), 'duration');
  };
  const handleModalSubmit = () => {
    setTime(+customTime, 'duration');
    setIsModalShown(false);
  };

  return (
    <>
      <Flex tag='ul' classes={styles.list}>
        {selectors.map(({id, value, text}) => (
          <Tab
            key={id.toString()}
            value={value}
            id={id}
            text={id === 6 && isCustomDuration(duration) ? `${duration} мин` : text}
            onClick={handleClick}
            isActive={(value === duration) || (id === 6 && isCustomDuration(duration))}
          />
        ))}
      </Flex>
      {
        isModalShown &&
          <ModalWrapper
            title='Своя длительность'
            onClose={() => setIsModalShown(false)}
            classes={styles.modal}
          >
            <InputWithControls
              onChange={(value) => setCustomTime(value)}
              value={customTime}
              type='мин.'
              justify='center'
              step={15}
              max={480}
            />
            <Hr withTopMargin={false} />
            <Flex>
              <Button variant='white' onClick={() => setIsModalShown(false)}>Отменить</Button>
              <Button onClick={handleModalSubmit}>Добавить</Button>
            </Flex>
          </ModalWrapper>
      }
    </>
  );
}

export default observer(TimeTabs);

