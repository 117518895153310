import React, {useState} from "react";
import style from "../../Marks.module.scss";
import {ReactComponent as Circle} from 'components/UI/icons/circle.svg';

const WithMarks = ({ numberOfMarks, addMark }) => {
    const [pressedIndex, setPressedIndex] = useState(null);

    return Array.from({length: numberOfMarks}, (_, index) => {
        const markIndex = index + 1;
        let markColor = '';
        let pressed = ''

        const handlePress = (index) => {
            setPressedIndex(index);
        };


        if (numberOfMarks === 5) {
            if (markIndex >= 4) {
                markColor = style.markGreen
                pressed = style.pressedGreen
            } else if (markIndex === 3) {
                markColor = style.markYellow
                pressed = style.pressedYellow
            } else if (markIndex <= 2) {
                markColor = style.markRed;
                pressed = style.pressedRed
            }
        } else {
            if (markIndex >= 7) {
                markColor = style.markGreen
                pressed = style.pressedGreen
            } else if (markIndex >= 4 && markIndex <= 6) {
                markColor = style.markYellow
                pressed = style.pressedYellow
            } else if (markIndex >= 1 && markIndex <= 3) {
                markColor = style.markRed
                pressed = style.pressedRed
            }
        }

        const isPressed = pressedIndex === markIndex
        return (
          <div
            className={`${style.marks} ${markColor} ${numberOfMarks <= 5 && style.marksShort} ${isPressed && pressed}`}
            key={index}
            onClick={() => addMark(markIndex)}
            onMouseDown={() => handlePress(markIndex)}
          >
              <Circle/>
              <span className={style.mark}>{markIndex}</span>
          </div>
        );
    }).reverse();
}

export default WithMarks
