import {permissions} from "settings/permissions";

export const colsSettings = {
  action: [
    { name: 'Отправить уведомление', action: 'notify', permission: ['forward']},
    { name: 'Перезаписать клиента', action: 'edit', perm: permissions.booked_online_schedule_edit_client },
    { name: 'Отправленные письма', action: 'emails', permission: ['forward']},
    { name: 'Отправленные SMS', action: 'sms', permission: ['forward'] },
    { name: 'Страница записи клиента', action: 'customer', permission: ['forward']},
    { name: 'Удалить запись', action: 'delete', perm: permissions.booked_online_schedule_delete_client}
  ],
  prefix: 'time_slots',
  link: 'clients',
  id: { name: 'id' },
  main_rows: [
    { name: 'student_name', function: 'transliterate' },
    { name: 'date' },
    { name: 'start_level' },
  ],
  sub_rows: [
    { name: 'format', description: 'Формат' },
    { name: 'contacts', description: 'Контакты клиента', function: 'contacts' },
    { name: 'employee_name', description: 'Сотрудник' },
  ],
};

export const getColSettings = (colsSettings, clientsType, item, timeZone = 0) => {
  const { created_at, confirmed_at, sms_code, email_code } = item;
  item.shifted_created_at = new Date(Date.parse(created_at) + timeZone * 3600000).toISOString();
  const settings = {...colsSettings};
  const sub_rows = colsSettings.sub_rows.slice();
  const action = colsSettings.action.slice();
  if (created_at) {
    sub_rows.push({
      compiled: [
        { name: 'shifted_created_at', past: ' (', function: 'date_time' },
        { name: 'created_name', past: ')', function: 'transliterate' },
      ],
      description: 'Кем и когда создано',
    })
  }
  if (confirmed_at) {
    sub_rows.push({
      compiled: [
        {name: 'confirmed_at', past: ' (', function: 'date_time'},
        {name: 'confirmed_name', past: ')', function: 'transliterate'},
      ],
      description: 'Дата подтверждения',
    });
  }

  sub_rows.push({
    compiled: [
      {name: 'program_name', past: ' | '},
      {name: 'age_name'},
    ],
    description: ' Программа, возраст',
  });
  sub_rows.push({ name: 'payment_comment', description: 'Комментарий оплаты', function: 'payment_comment'});
  sub_rows.push({ name: 'add_field1', description: 'Дополнительные данные' });

  if (item.add_field2) {
    sub_rows.push({name: 'add_field2', description: ''})
  }
  if (item.add_field3) {
    sub_rows.push({name: 'add_field3', description: ''})
  }

  if (item.zoom_url) {
    action.unshift({ name: 'Ссылка на ZOOM', action: 'zoom', permission: ['forward'] });
  }
  if (sms_code || email_code) {
    action.push({ name: 'Код подтверждения', action: 'code', permission: ['forward']});
  }
  //action.push({ name: 'Удалить запись', action: 'delete', permission: ['forward']});

  return { ...settings, action, sub_rows };
};


