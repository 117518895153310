import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import styles from './Paper.module.scss';


const Paper = forwardRef((
  { children, tag, padding, classes, ...args },
  ref,
) => {
  const Tag = tag;
  const customStyle = {
    padding: padding ? `${padding}px` : '',
  };

  return (
    <Tag
      ref={ref}
      className={cn({
        [styles.wrapper]: true,
        [classes]: classes,
      })}
      style={customStyle}
      {...args}
    >
      {children}
    </Tag>
  );
});

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOf(['div', 'section', 'p', 'form', 'fieldset', 'ul', 'li']),
  padding: PropTypes.string,
  classes: PropTypes.string,
}

Paper.defaultProps = {
  tag: 'div',
  padding: '',
}

export default Paper;
