import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {superAxiosRequest} from 'axiosApi'
import swal from "sweetalert";
import {localisationMaster} from "Localisation/Localisation";
import {colors} from "../Global/ContentTable_test/avatar";
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import coursesStore from 'Stores/coursesStore';
import ActionMenu from '../Global/ActionMenu/ActionMenu';
import './LessonCard.scss';
import arrowDown from '../img/down-arrow.svg';
import {ReactComponent as IconGallery} from "components/ContentBox/img/image-gallery-icon.svg";

export default function LessonCard(props) {
    const {CourseStore} = coursesStore

    const [open, setOpen] = useState(false)

    const menu = [
        { name: localisationMaster('text_10', 'lessons/course'), active: true, action: 'detail' , permission: ['forward']},
        { name: localisationMaster('text_11', 'lessons/course'), active: true, action: 'edit' , permission: ['forward']},
        { name: localisationMaster('content', 'lessons/course'), active: true, link: '/lessons/lesson', nav_link: true, permission: ['forward']},
        { name: localisationMaster('text_12', 'lessons/course'), active: false, action: 'copy', permission: ['forward'] },
        { name: localisationMaster('text_13', 'lessons/course'), active: false, action: 'delete' , permission: ['forward']},
    ]

    const action = data => {
        switch (data) {
            case 'detail':
                setOpen(!open)
                break;
            case 'delete':
                deleteLesson()
                break;
            case 'copy':
                copyLesson()
                break;
            case 'edit':
                editLesson()
                break;
            default:
                break;
        }
    }

    // Копирование урока
    const copyLesson = () => {
        swal({
            text: 'Вы точно хотите создать копию урока "' + props.items.name + '"?',
            buttons: {
                cancel: "Отмена",
                confirm: {
                    value: props.items.name + ' copy',
                    text: "Да"
                }
            },
            content: {
                element: "input",
                attributes: {
                    value: props.items.name + ' copy'
                },
            },
        }).then(value => {
            if (value !== null){
                const apiConf = {
                    method: 'get',
                    link: 'lessons/lesson/' + props.items.id + '/copy',
                    params: {
                        name: value || props.items.name + ' copy'
                    }
                }
                superAxiosRequest(apiConf).then(res => {
                    CourseStore.addLesson(res.data)
                })
            }
        })
    }

    // Редактирование урока
    const editLesson = () => {
        const modal_settings = {
            name: 'course-lesson',
            link: 'lessons/lesson/' + props.items.id,
            button: localisationMaster('button-two', 'company/profiles/account', 'add_category'),
            title: 'Редактирование урока',
            type: 'SelectorAndInput',
            active_inputs: ['text', 'textarea_tags', 'textarea_tags_second', 'image-selector'],
            data: 'json',
            media: true,
            is_put: true,
            name_media: 'image',
            pl_text: localisationMaster('text_2', 'lessons/course'),
            name_text: 'name',
            pl_textarea_tags: localisationMaster('text_3', 'lessons/course'),
            name_textarea_tags: 'description',
            pl_textarea_tags_second: localisationMaster('text_4', 'lessons/course'),
            name_textarea_tags_second: 'home_work',
            //course_id: props.items.course.id,
            func: CourseStore.updateLesson,
            valid: {
                name: 'required'
            }
        }
        const body = {
            //id: props.items.id,
            course: props.items.course.id,
            name: props.items.name,
            description: props.items.description,
            home_work: props.items.home_work,
        }
        modalStore.activity(modal_settings, body)
    }

    // Удаление урока
    const deleteLesson = () => {
        swal({
            text: `Вы точно хотите безвозвратно удалить урок "${props.items.name}"?`,
            icon: "warning",
            buttons: ["Нет", "Да"],
            dangerMode: true,
        }).then(yes => {
            if (yes){
                const apiConf = {
                    method: 'delete',
                    link:  'lessons/lesson/' + props.items.id
                }
                superAxiosRequest(apiConf).then(() => {
                    CourseStore.deleteLesson(props.items.id)
                })
            }
        })
    }
    
    return (
        <div className="courseLessonCard">
            <div className="courseLessonCard__header">
                <div className="courseLessonCard__headerSection">
                    <div className="courseLessonCard__arrows">
                        <div className={props.position  === 1 ? 'courseLessonCard__arrows--hide' : ''}>
                            <img src={arrowDown} alt="arrow_down"
                                 onClick={() => props.position > 1 && props.changePosition(props.items.id, -1)}/>
                        </div>
                        <div className={props.last === props.position ? 'courseLessonCard__arrows--hide' : ''}>
                            <img src={arrowDown} alt="arrow_down"
                                 onClick={() => props.last !== props.position && props.changePosition(props.items.id, 1)}/>
                        </div>
                    </div>
                    <div className="courseLessonCard__pic">
                        {props.items.image ?
                            <img src={Stores.baseLink() + props.items.image} alt={props.items.name}/>
                            :
                            <div style={{backgroundColor: colors(props.items.id.toString())}}>
                                <IconGallery/>
                            </div>
                        }
                    </div>
                    <Link to={'/lessons/lesson/' + props.items.id} target="_blank">{props.items.name}</Link>
                </div>
                <ActionMenu menu={menu} action={action} id={props.items.id}/>
            </div>
        {open &&
        <div className="courseLessonCard__body">
            <div className="courseLessonCard__unit">
                <span>{localisationMaster('text_14', 'lessons/course')}</span>
                <span dangerouslySetInnerHTML={{__html: props.items.description}}/>
            </div>
            <div className="courseLessonCard__unit">
                <span>{localisationMaster('text_15', 'lessons/course')}</span>
                <span dangerouslySetInnerHTML={{__html: props.items.home_work || 'Не указано'}}/>
            </div>
        </div>}
    </div>
    )
}
