const addStr = (str, index, stringToAdd) => {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
};

export const phoneMask = (number) => {
    if (number.startsWith('996')) {
        let numberPhone = number;
        numberPhone = addStr(numberPhone, 0, '+');
        numberPhone = addStr(numberPhone, 4, ' ');
        numberPhone = addStr(numberPhone, 5, ' (');
        numberPhone = addStr(numberPhone, 10, ') ');
        numberPhone = addStr(numberPhone, 15, ' ');
        return numberPhone;
    }
    if (number.startsWith('998')) {
        let numberPhone = number;
        numberPhone = addStr(numberPhone, 0, '+');
        numberPhone = addStr(numberPhone, 4, ' ');
        numberPhone = addStr(numberPhone, 7, ' ');
        numberPhone = addStr(numberPhone, 11, ' ');
        return numberPhone;
    }
    if (number.startsWith('380') || number.startsWith('357')) {
        let numberPhone = number;
        numberPhone = addStr(numberPhone, 0, '+');
        numberPhone = addStr(numberPhone, 4, ' (');
        numberPhone = addStr(numberPhone, 8, ') ');
        numberPhone = addStr(numberPhone, 13, ' ');
        numberPhone = addStr(numberPhone, 16, ' ');
        return numberPhone;
    }
    if (number.startsWith('7')) {
        let numberPhone = number;
        numberPhone = addStr(numberPhone, 0, '+');
        numberPhone = addStr(numberPhone, 2, ' ');
        numberPhone = addStr(numberPhone, 3, ' (');
        numberPhone = addStr(numberPhone, 8, ') ');
        numberPhone = addStr(numberPhone, 13, ' ');
        numberPhone = addStr(numberPhone, 16, ' ');
        return numberPhone;
    }
    if (number.startsWith('375')) {
        let numberPhone = number;
        numberPhone = addStr(numberPhone, 0, '+');
        numberPhone = addStr(numberPhone, 4, ' ');
        numberPhone = addStr(numberPhone, 5, ' (');
        numberPhone = addStr(numberPhone, 9, ') ');
        numberPhone = addStr(numberPhone, 14, ' ');
        numberPhone = addStr(numberPhone, 17, ' ');
        return numberPhone;
    }
    return number;
};

export const aCountry = [
    {id: 1, code: '+7', mask: ' (###) ###-##-##', length: 12},
    {id: 2, code: '+375', mask: ' (##) ###-##-##', length: 13},
    {id: 3, code: '+380', mask: ' (##) ###-##-##', length: 13},
    {id: 4, code: '+993', mask: '-#-###-####', length: 12},
    {id: 5, code: '+994', mask: '-##-###-##-##', length: 13},
    {id: 6, code: '+996', mask: ' (###)###-###', length: 13},
    {id: 7, code: '+998', mask: '-##-###-####', length: 13},
];

export const maskPhone = (number, countryID = null) => {
    const country = aCountry.find(({id, code}) => countryID ? id === countryID : number?.startsWith(code));
    if (!country) {
        return number;
    }
    let {code, mask} = country;
    number = number.replace(code, '');
    mask = mask.replace(/[#]/g, '_');
    for (let i = 0; i < number.length; i++) {
        mask = mask.replace('_', number[i]);
    }

    return countryID ? mask : code + mask;
};
