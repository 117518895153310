import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {addZero} from "helpers/math";
import {observer} from "mobx-react";
import lessonTeacherStore from 'components/Modules/Ebook/LessonTeacher/lessonTeacherStore';
import ChaptersPopup from './EbookHeaderModal/ChaptersPopup';
import st from "./TeacherToolbar.module.scss";
import {ReactComponent as MenuIcon} from "components/UI/icons/list-check.svg";
import {ReactComponent as ArrowIcon} from "components/UI/icons/arrow-right-s-line.svg";
import {ReactComponent as ExitIcon} from "components/UI/icons/close-circle-line.svg";
import {ReactComponent as HomeIcon} from "components/UI/icons/home-8-line.svg";
import {ReactComponent as Palette} from "components/UI/icons/palette-line.svg";

const TeacherToolbar = ({translate, showBoard, setShowBoard}) => {
  const history = useHistory()
  const {
    chapter,
    section,
    activeSection,
    setActiveSection,
  } = lessonTeacherStore

  const [isOpenModal, setIsOpenModal] = useState(false)

  const changeSection = direction => {
    setActiveSection(direction)
    window.scrollTo({top: 0, behavior: "smooth"})
  }

  return (
    <>
      <div className={st.indent}/>
      <header className={st.header}>
        <div onClick={() => setIsOpenModal(prev => !prev)}
             className={`${st.menu} ${st.button}`}>
          <MenuIcon/>
          <span>{section && section.name}</span>
        </div>
        {isOpenModal && <ChaptersPopup value={activeSection}
                                       setActiveSection={setActiveSection}
                                       setClose={() => setIsOpenModal(false)}
                                       data={chapter && chapter.sections}/>}
        <div className={st.sectionToggle}>
          <div className={`${st.arrowLeft} ${activeSection === 1 ? st.arrowDisabled : ''}`}>
            <ArrowIcon onClick={() => changeSection('prev')}/>
          </div>
          <span>{addZero(activeSection)} of {addZero(chapter?.sections.length || 0)}</span>
          <div className={`${st.arrowRight} ${activeSection === chapter?.sections.length ? st.arrowDisabled : ''}`}>
            <ArrowIcon onClick={() => changeSection('next')}/>
          </div>
        </div>
        <div className={st.buttons}>
        <Link to={`/ebooks/homework/${chapter?.homework}`} className={`${st.button} ${st.buttonResponsive} ${st.home}`}>
          <HomeIcon/>
          <span>{translate?.homework}</span>
        </Link>
          <div className={`${st.button} ${st.buttonResponsive} ${st.drawing_board}`} onClick={() => setShowBoard(!showBoard)}>
              <Palette/>
              <span>{showBoard ? 'Закрыть доску' : 'Рисовать на доске'}</span>
          </div>
        </div>
        <div onClick={() => history.push('/')}
             className={`${st.button} ${st.buttonResponsive} ${st.exit}`}>
          <span>{translate?.exit}</span>
          <ExitIcon/>
        </div>
      </header>
    </>

  );
};

export default observer(TeacherToolbar);