import React, {useState} from 'react';
import './filter-input.css';
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "../../../../Localisation/Localisation";

const FilterInput = (props) => {

    const [inputClient, setInputClient] = useState('');
    const [openUser, setOpenUser] = useState(false);
    const [allClients, setAllClients] = useState(null);
    //const [clientsFilter, setClientsFilter] = useState([]);
    //const [clientId, setClientId] = useState('');

    const openFilterUser = () => {
        if (inputClient.length >= 3) {
            setOpenUser(true)
        } else {
            setOpenUser(false)
        }
    }
    const addClientId = (item) => {
        if(item.name){
            setInputClient(item.name);
        }else {
            setInputClient(item.profile.full_name + ' ' + item.email);
        }
        props.clientId(item);
        setOpenUser(false)
    }


    const returnClients = () => {
        var clientsClean = []
        if (allClients !== null) {
            document.body.onclick = function (e) {
                var target = e.target;
                if (target.className === "clients-list-item-strong" || target.className === "clients-list-item-span") {
                }else {
                    //setOpenUser(false)
                }
            }
            /*allClients.map((item, index) => {
                clientsClean.push(
                    <div    className='clients-list-item'
                            onClick={()=>addClientId(item)}>
                            <strong className='clients-list-item-strong' onClick={()=>addClientId(item)}>{item.profile.first_name + ' ' + item.profile.last_name + " "}</strong>
                            {item.profile.phone.length > 0 ? " " + item.profile.phone + " ": null}
                            <span  className='clients-list-item-span' onClick={()=>addClientId(item)}>{item.profile.email}</span>
                    </div>
                )
            })*/
            for(let i=0; i < allClients.length; i++){
                clientsClean.push(
                    <div    className='clients-list-item'
                            key={allClients[i].id.toString()}
                            onClick={()=>addClientId(allClients[i])}>
                            <strong className='clients-list-item-strong' onClick={()=>addClientId(allClients[i])}>
                                {allClients[i].profile ? allClients[i].profile.first_name + ' ' + allClients[i].profile.last_name  + " " : allClients[i].name + ' '}</strong>
                            {allClients[i].profile ? " " + allClients[i].profile.phone + " ": null}
                            <span  className='clients-list-item-span' onClick={()=>addClientId(allClients[i])}>{allClients[i].profile ? allClients[i].profile.email : null}</span>
                    </div>
                )
            }
        }
        if (clientsClean.length !== 0) {
            return (
                clientsClean
            )
        } else {
            return (
                localisationMaster('placeholder-11', 'online-schedule/record-customer')
            )
        }
    }

    const requestUser = () => {
        //clientsFilter.splice(0, clientsFilter.length)
        if (inputClient.length >= 2) {
        openFilterUser();
        const apiConf = {
            method: 'get',
            params: {search: inputClient},
            link: props.link,

        }
        superAxiosRequest(apiConf).then(res => {
            setAllClients(res.data.result);
        })
            .catch(error => {
            });
        } else if(openUser && inputClient.length === 0){
            setOpenUser(false)
        }
    }

    document.body.onclick = function (e) {
        var target = e.target;
        if (target.className === "clients-list" || target.className === "clients-list-item") {
        } else if(openUser && target.className !== "clients-list"){
            if(target.className !== "clients-list-item"){
                setOpenUser(false)
            }
        }
    }

    return (
        <div className="filter-box-client">
            <input onChange={(e) => setInputClient(e.target.value)}
                   placeholder={props.placeholder}
                   value={inputClient}
                   style={{width: props.width}}
                   onKeyUp={requestUser}

                   /*onKeyDown={inputClient !== null && inputClient.length >= 2 ? requestUser : null}*/
            />
            {openUser ?
                <div className='clients-list'   style={{width: props.width}}>
                        {returnClients()}
                </div>
                : null}
        </div>
    )
}

export default FilterInput;
