import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './SectionTitle.module.scss';

function SectionTitle({ title, tag, secondary, description, withMargin, adaptive, children }) {
  const Tag = tag;

  const Title = () => (
    <Tag
      className={cn({
        [styles.title]: true,
        [styles.title__description]: description || children,
        [styles.secondary]: secondary,
        [styles.title__margin]: withMargin && !description,
        [styles.title__adaptive]: adaptive,
      })}
    >
      { title }
    </Tag>
  );

  const ExtendedTitle = () => (
    <div className={cn({
      [styles.wrapper__margin]: withMargin,
    })}>
      <Title />
      {
        description &&
        <p className={styles.description}>
          {description}
        </p>
      }
      { children }
    </div>
  )

  return (description || children) ? <ExtendedTitle /> : <Title />;
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  secondary: PropTypes.bool,
  description: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([null]),
  ]),
  withMargin: PropTypes.bool,
  adaptive: PropTypes.bool,
}

SectionTitle.defaultProps = {
  tag: 'h2',
  secondary: false,
  description: '',
  children: null,
  withMargin: true,
  adaptive: false,
}

export default SectionTitle;
