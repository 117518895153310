import {observer} from 'mobx-react'
import React from 'react'
import Duration from '../../../Duration/Duration'

const TestRequest = ({title, desctiption, value, handler}) => {

  return (
    <div className="DurationNote">
      <span className="DurationNote--title">{title}</span>
      <span className="DurationNote--note">{desctiption}</span>
      <div>
        <Duration handler={handler} value={value}/>
      </div>
    </div>
  )
}
export default observer(TestRequest);