import React, {useEffect, useRef, useState} from 'react'
import Stores from 'Stores';
import groupStore, {schoolDayStatuses as sds} from "Stores/groupStore";
import modalStore from 'Stores/modalStore';
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {createDays, dateConvert, getClassName, sorter, sorterRev} from './TimeTableCalendarFunctions';
import DayInfo from "../DayInfo";
import "./TimeTableCalendar.scss";
import swal from "sweetalert";
import arrow from 'components/ContentBox/img/br_down.png'
import {ReactComponent as IconInfo} from 'components/ContentBox/img/info.svg';
import {ReactComponent as IconReplace} from 'components/ContentBox/img/double-arrow.svg';


export const showDisabledComment = () => {
    swal(
      localisationMaster('text_10', 'groups/group'),
      localisationMaster('text_11', 'groups/group'),
      "warning"
    )
}

export default function TimeTableCalendar(props) {
    const refSlider = useRef(null)
    const refDaysBox = useRef(null)

    const [days, setDays] = useState([false, []])
    const [open, setOpen] = useState(false)
    const [tooltip, setTooltip] = useState([false, {}])

    const dayMay = item => {
        if (props.clickDisabled || Stores.isClient) return;

        const date = item.date
        let info = props.items.filter(x => x.date === date && x.schedule_id === item.schedule)

        let statuses;
        statuses = props.statuses;
        const actions = [
            {id: 777, name: localisationMaster('text_39', 'modal_templates')},
            {id: 999, name: localisationMaster('change_teacher_time', 'groups/group', 'time_table_day')},
        ]
        
        let value = statuses.sort((a, b) => a.id < b.id ? 1 : -1)
        if (new Date(date) > new Date()) value = value.filter(item => item.id !== 7)
        
        if (Stores.Rules.checkRule('schoolday', 'edit_absence')) {
            value = [
                {id: 7, name: localisationMaster('lesson', 'groups/group')},
                {id: 3, name: localisationMaster('missed', 'groups/group', 'time_table_day')}
            ]
        }
        if (!props.student && !Stores.Rules.checkRule('schoolday', 'edit_absence')) {
            value = [...value, ...actions]
        }
        const fullSchedule = props.shedule?.find(el => el.id === item.schedule);

        const ms = {
            name: 'time_table_day',
            link: 'schedule/transfer_lesson',
            button: localisationMaster('text_54', 'groups/all-groups'),
            title: `${timeConverter(date, 'age')}`,
            type: 'time_table_note',
            data: 'json',
            schedule: { ...info, weekdays: (fullSchedule?.weekdays || []).map(el => el.day) },
            g_id: +props.gid,
            is_put: item.status,
            sh_id: item.schedule,
            date: [date],
            last: sorterRev(days[1]),
            student: props.isStudent ? props.student : false,
            main_s: !props.isStudent,
            htm: item.hours_type_minutes,
            statuses: value,
            timeDelta: createTimeDelate(item),
            date_range: sorter(days[1]),
            payload: {
                school_day: props.schooldays.find(x => x.date === item.date),
                ...(props.school_day_notes && {school_day_note: props.school_day_notes.find(x => x.date === item.date && x.user === props.student.id)}),
            },
            func: groupStore.updateGroupData
        }

        modalStore.activity(ms)
    }

    const createTimeDelate = item => {
        const startAt = new Date(item.date + ' ' + item.start_at).getTime()
        let finishAt = new Date(item.date + ' ' + item.finish_at).getTime()
        if (finishAt < startAt)
            finishAt += 24 * 60 * 60 * 1000
        return (finishAt - startAt) / 1000 / 60
    }

    const onMouseEnterHandler = ({ target }, item) => {
        let body,
            statusStr = localisationMaster('lesson', 'groups/group'), //Занятие
            status,
            comment = '',
            timeDelta = createTimeDelate(item),
            isNotSkip = true

        if (item.status) {
            status = props.statuses.find(x => x.id === item.status[0]);
            comment = item.status[2];
            statusStr = status.name
            isNotSkip = ![sds.NOT_PAID_SKIP, sds.PAID_SKIP, sds.PART_PAID_SKIP, sds.CANCELED].includes(item.status[0])
        }

        //STUDENT================================
        if (props.isStudent && status) {
            body = {
                lesson: {
                    title: statusStr ,
                    content: isNotSkip ? ` № ${item.id}` : '',
                    teachers: item.teachers,
                    timeDelta: timeDelta,
                    day_sum: item.day_sum.toFixed(2)
                },
                text: comment,
                bonusSum: item.school_day_note.bonus_sum
            }
        }
        else if (props.isStudent && item.disabled){
            body = {
                lesson: {
                    title: statusStr ,
                    content: `№ ${item.id} (${localisationMaster('text_9', 'groups/group')})`,
                    teachers: item.teachers,
                    timeDelta: timeDelta
                },
                text: comment
            }
        }
        //STUDENT================================

        //Clean================================
        else if (item.status) {
            body = {
                lesson: {
                    title: statusStr,
                    content: isNotSkip ? `№ ${item.id} ` : '',
                    teachers: item.teachers,
                    timeDelta: timeDelta
                },
                text: comment,
            }
        }
        //Clean================================

        else if (item.holydays) {
            body = {
                lesson: {
                    // Праздничный день
                    title: localisationMaster('holiday', 'groups/group'),
                },
                text: comment,
            }
        }

        else {
            body = {
                lesson: {
                    title: statusStr,
                    content: `№ ${item.id}`,
                    teachers: item.teachers,
                    timeDelta: timeDelta
                },
                text: comment,
            }
        }


        if (props.ebooks && Stores.isAdmin && props.ebooks.length > 0){
            if (isNotSkip && !item.holydays){
                body.ebook = {
                    group_id: +props.gid,
                    date: item.date + ' ' + item.start_at,
                    register: item.register
                }
            }
        }

        setTooltip([true, {
            target: target.closest(".timetable-calendar__day-box"),
            ...body
        }])
    }

    const onMouseLeaveHandler = () => setTooltip([false, {}])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (refSlider.current)
                refSlider.current.style.height = refDaysBox.current.offsetHeight + 'px'
        })
        const ref = refDaysBox.current
        resizeObserver.observe(ref)
        return () => resizeObserver.unobserve(ref);
    }, [])

    useEffect(() => {
        let [days, allTime] = createDays(props.items, props.student, props.isStudent, props.holidays, props.schooldays, props.school_day_notes)

        // Добавление регистров
        if (Array.isArray(props.ebooks)){
            const ebooks = props.ebooks.map(eb => ({
                registers: [...eb.registers],
                start_date: eb.start_date,
                end_date: eb.end_date
            }))

            days = days.map(d => {
                const isSkip = [sds.NOT_PAID_SKIP, sds.PAID_SKIP, sds.PART_PAID_SKIP, sds.CANCELED].includes(d.status[0])
                if (d.holydays || isSkip) return d

                const date = new Date(d.date)
                const index = ebooks.findIndex(eb => {
                    const checkEndDate = eb.end_date ? new Date(eb.end_date) >= date : true
                    return (new Date(eb.start_date) <= date && checkEndDate)
                })
                if (index !== -1)
                    d.register = ebooks[index].registers.shift()

                return d
            })
        }

        if (props.isStudent) {
            props.lessonsLeft(allTime);
        }
        setDays([true, days])
    }, [props])

    useEffect(() => {
        //const limit = 36;
        const iLength = days[1].length;
        const beforeTodayLength = days[1].filter(x => new Date(x.date) <= Date.now()).length;
        const afterTodayLength = iLength - beforeTodayLength - 1;

        let start, end
        if (beforeTodayLength >= 18){
            end = beforeTodayLength + 17
            if (afterTodayLength >=17){
                start = beforeTodayLength - 18;
            }
            else {
                const need = 17 - afterTodayLength
                start = beforeTodayLength - 18 - need
            }
        }
        else {
            start = 0
            if (afterTodayLength >=17){
                const need = 18 - beforeTodayLength
                end = beforeTodayLength + 17 + need
            }
            else {
                end = iLength
            }
        }

        const $daysBox = refDaysBox.current;
        const $days = $daysBox.children;
        const sClassName = 'timetable-calendar__day-box--hide';

        [].map.call($days, ({ classList }, i) => {
            if (open)
                classList.remove(sClassName)
            else {
                if (i < start || i > end)
                    classList.add(sClassName)
                else
                    classList.remove(sClassName)
            }
        })
    }, [open, days])

    return (
        <div className="timetable-calendar" onMouseLeave={onMouseLeaveHandler}>
            <div ref={refSlider} className="timetable-calendar__slider">
                <div className="timetable-calendar__days" ref={refDaysBox}>
                    {days[0] && sorter(days[1]).map((item, index) =>
                        <div className="timetable-calendar__day-box" key={index}>
                            <div
                                className={getClassName(item.status, item.debtor, item.holydays, props.isStudent, item.disabled, item.creditor)}
                                onClick={() => {
                                    !item.disabled ? dayMay(item) : showDisabledComment()
                                }}
                                onMouseEnter={(e) => onMouseEnterHandler(e, item)}
                                onMouseLeave={() => {
                                    props.isStudent && onMouseLeaveHandler()
                                }}
                            >
                                {(item.debetor === 1 || item.creditor) ?
                                    <>
                                        {/* <span>{dateConvert(item.date)}</span>. */}
                                        <span>{dateConvert(item.date)}</span>
                                    </>
                                    :
                                    <span>{dateConvert(item.date)}</span>
                                }
                            </div>

                            {(item.transfers && !item.replacing) &&
                            <div  className="timetable-calendar__day--transfer"/>}

                            {(item.status && item.status[2]) &&
                            <div className="timetable-calendar__day--comment"><IconInfo/></div>}

                            {item.replacing && <div className="timetable-calendar__day--replace"><IconReplace/></div>}

                        </div>
                    )}
                </div>
            </div>
            {days[1].length > 36 &&
            <div
                className={"timetable-calendar__more " + (open ? ' timetable-calendar__more--less' : '')}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <img src={arrow} alt=""/>{open ?
                localisationMaster('text_56_less', 'groups/all-groups') :
                localisationMaster('text_56', 'groups/all-groups')}
                <img src={arrow} alt=""/>
            </div>}

            {tooltip[0] &&
            <DayInfo tooltip={tooltip[1]} refDaysBox={refDaysBox} setTooltip={onMouseLeaveHandler}/>}

        </div>
    )
}
