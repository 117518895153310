import { localisationMaster } from 'Localisation/Localisation';
import listingStore from "Stores/listingStore";
import {permissions} from "settings/permissions";
import { translate as loc, dict } from "Localisation";

export const company = [
    {
        link: 'profiles',
        folder: 'company',
        permission: ['employersprofile', 'view_all'],
        modal_placeholder: '',
        title: localisationMaster('title', 'company/profiles'),
        button: [localisationMaster('button-title', 'company/profiles'), 'employersprofile', 'add'],

        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'add_user',
            link: 'accounts/employers',
            button: localisationMaster('button-two', 'company/profiles', 'add_user'),
            title: localisationMaster('title-modal', 'company/profiles', 'add_user'),
            type: 'add_user',
            data: 'json',
            width: '600px',
            valid: {
                email: 'required',
                password: 'required',
                first_name: 'required',
                last_name: 'required',
                sex: 'required',
                company: 'required',
                location: 'required',
                checked: 'required',
                role: 'required',
            },
            //func: listingStore.updateData, need response on POST
        },

        colsSettings: {
            action: [
                { name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile', link: '/account', nav_link: true, permission: ['employersprofile', 'edit'] },
                { name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'), action: 'detail', permission: ['employersprofile', 'view'] },
            ],
            link: 'employers_list',
            prefix: 'accounts',
            id: { name: 'id' },
            is_active: { name: 'is_active', updir: 'profile' },
            main_rows: [
                { name: 'full_name', depence: false, updir: 'profile', function: 'transliterate' },
                { name: 'name', depence: false, updir: 'profile', sub_dir: 'location' },

            ],
            is_open: false,
            avatar: { name: 'avatar', updir: 'profile', ac: true, f: 'first_name', l: 'last_name', no_link: true },

            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-2', 'company/profiles'), updir: 'profile', sub_dir: 'location' },
                { name: 'locations', description: localisationMaster('table-row-title-3', 'company/profiles'), updir: 'profile', sub_dir: 'locations', function: 'array', arr: true, key_name: 'name' },
            ]
        },

        filterLiner: true,
        errorText: 'По вашему запросу сотрудник не найден',
        filterLinerLink: "accounts/employers_list",
        config: [
            { id: 1, nameState: 'search', placeholder: localisationMaster('text-2', 'filter'), type: "input" },
            {
                id: 2,
                apiLink: 'db/locations',
                nameState: 'employer_profile__location',
                placeholder: localisationMaster('Choose-a-location', 'online-schedule/time-slots'),
                type: "select_request"
            },
            { id: 3, apiLink: 'accounts/roles', nameState: 'groups', placeholder: localisationMaster('text-3', 'filter'), type: "select_request" },
            {
                id: 4,
                apiLink: 'accounts/is_active',
                nameState: 'employer_profile__is_active',
                new_value: 'value',
                placeholder: localisationMaster('text-8', 'filter'),
                type: "select_request",
                option_value_key: 'value',
            },
            { id: 5, apiLink: 'accounts/employment_types_catalog', nameState: 'employer_profile__profession__employment', placeholder: 'Выберите департамент', type: "select_father" },
            { id: 6, nameState: 'employer_profile__profession', optionsKey: 'professions', fatherName: 'employer_profile__profession__employment', placeholder: 'Выберите должность', type: "select_son" },
            //{ id: 6, apiLink: 'accounts/employment_types_catalog', nameState: 'employer_profile__profession', placeholder: 'Выберите должность', type: "selectProfessions" },
        ],
        apiSettings: {}
    },
    {
        link: 'clients-profiles',
        folder: 'company',
        permission: ['clientsprofile', 'view_client'],
        button: ['Добавить клиента', permissions.add_client],
        modal_placeholder: '',
        title: localisationMaster('title', 'online-schedule/future-slots'),
        modal_settings: {
            name: 'add_client',
            link: 'accounts/clients',
            button: localisationMaster('text_54', 'groups/all-groups'),
            title: localisationMaster('text_3', 'online-schedule/clients-after-online'),
            type: 'client_info',
            create: true,
            data: 'json',
            width: '620px',
            add_client: true,
            isResponsive: true,
            valid: {
                first_name: 'required',
                last_name: 'required',
                inn: 'required',
                date_of_birth: 'required',
                phone: 'required',
                email: 'required',
            }
        },
        modal_type: 'asideModal',
        colsSettings: {
            action: [
                { name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile_client', link: '/account_clients', nav_link: true, permission: ['clientsprofile', 'view_client'] },
                { name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward'] },
            ],
            button: localisationMaster('button-title', 'company/profiles'),
            link: 'clients',
            prefix: 'accounts',
            id: { name: 'id' },
            is_active: { name: 'is_active', updir: 'profile' },
            main_rows: [
                { name: 'full_name', depence: false, updir: 'profile', function: 'transliterate' },
                { name: 'phone', depence: false, updir: 'profile', function: 'phone_mask' },
                { name: 'email', depence: false, updir: 'profile' },
            ],
            is_open: false,
            avatar: { name: 'avatar', updir: 'profile', ac: true, f: 'first_name', l: 'last_name' },
            sub_rows: [
                { name: 'date_of_birth', description: localisationMaster('name_3', 'tests/widget-clients'), updir: 'profile', function: '    ' },
                //{name: 'name', description:  localisationMaster('table-row-title-2', 'company/profiles'), updir: 'profile', sub_dir:'location'},
                //{name: 'additional_phones', description: 'Доп. телефоны', updir: 'profile', sub_dir:'additional_phones', arr: true, key_name: 'number'},
                { name: 'whatsapp', description: 'Whatsapp', updir: 'profile' },
                { name: 'inn', description: localisationMaster('table-row-title-5', 'company/companies'), updir: 'profile' },
                { name: 'comment', description: localisationMaster('table-row-title-2', 'company/companies'), updir: 'profile' },
            ]
        },

        filterLiner: true,
        filterLinerLink: "accounts/clients",
        config: [
            { id: 1, nameState: 'search', placeholder: localisationMaster('text-2_1', 'filter'), type: "input" },
            {
                id: 2,
                type: "select_request",
                placeholder: localisationMaster('text-9', 'filter'),
                apiLink: 'accounts/is_active',
                option_value_key: 'value',
                nameState: 'client_profile__is_active',
            },
        ],
        apiSettings: {}
    },
    {
        link: 'locations',
        folder: 'company',
        permission: ['location', 'view'],
        button: [localisationMaster('button-title', 'company/locations'), permissions.add_location],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'locations',
            link: 'db/locations',
            button: localisationMaster('button-two', 'company/locations', 'add_location'),
            title: loc(dict.company_locations.add_location),
            type: 'LocationsForm',
            data: 'json',
            companies: true,
            func: listingStore.updateData,
            valid: {
                name: 'required',
                address: 'required',
                home_phone: 'required',
                phone: 'required',
                email: 'required',
                start_at: 'required',
                end_at: 'required',
                time_zone: 'required',
            }
        },
        modalValues: {
            start_at: '08:00',
            end_at: '19:00',
        },
        title: localisationMaster('title', 'company/locations'),
        colsSettings: {
            items: [localisationMaster('table-row-title-1', 'tests/questions'),
            localisationMaster('table-row-title-2', 'tests/questions'), localisationMaster('table-row-title-3', 'tests/questions'),
            localisationMaster('table-row-title-4', 'tests/questions'), localisationMaster('table-row-title-5', 'tests/questions')],
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_location', permission: ['location', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['location', 'delete'] },
                { name: localisationMaster('text_10', 'lessons/course'), action: 'detail', permission: ['location', 'view'] },
            ],
            link: 'locations',
            prefix: 'db',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
                { name: 'classrooms', depence: false, function: 'array_count', arr: true, f_title: localisationMaster('name_1', 'company/locations', 'add_location') },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('name_2', 'company/locations', 'add_location'), updir: 'companies', function: 'array', arr: true, key_name: 'name' },
                { name: 'address', description: localisationMaster('name_3', 'company/locations', 'add_location') },
                { name: 'home_phone', description: localisationMaster('name_4', 'company/locations', 'add_location') },
                { name: 'phone', description: localisationMaster('name_5', 'company/locations', 'add_location') },
                { name: 'email', description: localisationMaster('name_6', 'company/locations', 'add_location') },
            ]
        },

        filterLiner: true,
        filterLinerLink: "db/locations",
        config: [
            {
                type: "select_request",
                placeholder: localisationMaster('text_3', 'c/tests/placement-test/create-widget'),
                apiLink: 'db/companies',
                nameState: 'company'
            },
        ],
    },
    {
        link: 'programs',
        folder: 'company',
        permission: ['program', 'view'],
        button: [localisationMaster('button-title', 'company/programs'), 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'programs',
            link: 'db/programs',
            button: localisationMaster('button-two', 'company/programs', 'add_program'),
            title: localisationMaster('title-modal', 'company/programs', 'add_program'),
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs: ['text', 'select'],
            data: 'json',
            pl_text: localisationMaster('input-placeholder-1', 'company/programs', 'add_program'),
            pl_select: localisationMaster('select-placeholder-1', 'company/programs', 'add_program'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies',
            func: listingStore.updateData,
        },
        title: localisationMaster('title', 'company/programs'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['program', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['program', 'delete'] },
            ],
            link: 'programs',
            prefix: 'db',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-3', 'tests/questions'), },
                { name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },

        apiSettings: {}
    },


    {
        link: 'age',
        folder: 'company',
        permission: ['age', 'view'],
        button: [localisationMaster('button-title', 'company/age'), 'age', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'age',
            link: 'db/age',
            button: localisationMaster('button-two', 'company/age', 'add_age'),
            title: localisationMaster('title-modal', 'company/age', 'add_age'),
            type: 'SelectorAndInput',
            active_inputs: ['multi-number', 'text', 'select'],
            data: 'json',
            pl_text: localisationMaster('input-placeholder-1', 'company/age', 'add_age'),
            name_text: 'name',
            name_number: 'from_age',
            name_number_second: 'to_age',
            pl_number: localisationMaster('input-placeholder-2', 'company/age', 'add_age'),
            pl_number_second: localisationMaster('input-placeholder-3', 'company/age', 'add_age'),
            companies: true,
            name_select: 'company',
            pl_select: localisationMaster('select-placeholder-1', 'company/age', 'add_age'),
            select_link: 'db/companies',
            func: listingStore.updateData,
        },
        title: localisationMaster('title', 'company/age'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['age', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['age', 'delete'] },
            ],
            link: 'age',
            prefix: 'db',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-2', 'tests/questions'), },
                { name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },

        apiSettings: {}
    },
    {
        link: 'debetors',
        folder: 'company',
        permission: ['age', 'view'],
        title: localisationMaster('text-12', 'online-schedule/time-slots'),
        exportToExcel: true,
        colsSettings: {
            action: [
                { name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile_client', permission: ['slotclient', 'edit'] },
                { name: localisationMaster('text_7', 'online-schedule/clients-after-online'), permission: ['forward'], action: 'go_group'},
                { name: localisationMaster('text_6', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward'] }
            ],
            link: 'company_debtors',
            prefix: 'ledger',
            id: { name: 'id' },
            main_rows: [
                {
                    compiled: [
                        { name: 'first_name', updir: 'student', key_name: 'first_name', past: ' ' },
                        { name: 'last_name', updir: 'student', key_name: 'last_name' },
                    ],
                },
                {
                    compiled: [
                        { name: 'number', updir: 'group', key_name: 'number', past: ' - ' },
                        { name: 'name', updir: 'group', key_name: 'name' },
                    ],
                },
                { name: '', depence: false },
                { name: 'group_balance', depence: false, function: 'group_balance', classname: 'redDebetor redDebetor--not_paid' },

            ],
            is_open: false,
            sub_rows: [
                {
                    compiled: [
                        { name: 'number', updir: 'group', key_name: 'number', past: ' - ' },
                        { name: 'name', updir: 'group', key_name: 'name' },
                    ],
                    description: localisationMaster('group_name', 'online-schedule/time-slots'),
                },
                { name: 'group_balance', depence: false, function: 'group_balance', description: localisationMaster('group_balance', 'online-schedule/time-slots'), classname: 'redDebetor' },
                { name: 'company_balance', description: localisationMaster('company_balance', 'online-schedule/time-slots'), },
                { name: 'full_name', updir: 'student', sub_dir: 'responsible', key_name: 'full_name', description: localisationMaster('responsible', 'online-schedule/time-slots'), },
                { name: 'phone', updir: 'student', key_name: 'phone', description: localisationMaster('phone', 'online-schedule/time-slots'), },
                { name: 'email', updir: 'student', key_name: 'email', description: localisationMaster('email', 'online-schedule/time-slots'), },

                //{ name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },

        filterLiner: true,
        filterLinerLink: "ledger/company_debtors",
        config: [
            { id: 1, nameState: 'name', placeholder: localisationMaster('text-1', 'online-schedule/time-slots'), type: "input" },
            { id: 2, nameState: 'group', placeholder: localisationMaster('text-2', 'online-schedule/time-slots'), type: "input" },
            {
                id: 6, values: [
                    { id: 1, name: localisationMaster('text-5', 'online-schedule/time-slots') },
                    { id: 0, name: localisationMaster('text-4', 'online-schedule/time-slots') }
                ],
                nameState: 'my_debtors', placeholder: localisationMaster('text_9', 'groups/all-groups'), type: "type_selector", key_name: 'my_debtors'
            },
            { id: 5, apiLink: 'db/companies', nameState: 'company', placeholder: localisationMaster('text-3', 'online-schedule/time-slots'), type: "select_request" },
            { id: 4, apiLink: 'db/locations', nameState: 'location', placeholder: localisationMaster('Choose-a-location', 'online-schedule/time-slots'), type: "select_request" },
            {
                id: 7, nameState: 'days',
                placeholder: localisationMaster('text-7', 'online-schedule/time-slots'),
                type: "select",
                values: [
                    { id: 3, name: localisationMaster('text-8', 'online-schedule/time-slots') },
                    { id: 5, name: localisationMaster('text-9', 'online-schedule/time-slots') },
                    { id: 7, name: localisationMaster('text-10', 'online-schedule/time-slots') },
                    { id: 10, name: localisationMaster('text-11', 'online-schedule/time-slots') },
                ]
            }
        ],
        apiSettings: {}
    },
    {
        link: 'student-discount',
        folder: 'company',
        permission: ['age', 'view'],
        title: loc(dict.groups_discount.title_students),
        exportToExcel: true,
        colsSettings: {
            action: [
                {
                    name: localisationMaster('text_4', 'online-schedule/clients-after-online'),
                    action: 'go_profile_client',
                    link: '/account_clients',
                    nav_link: true,
                    permission: ['slotclient', 'edit']
                },
                {
                    name: localisationMaster('text_6', 'online-schedule/clients-after-online'),
                    action: 'detail',
                    permission: ['forward']
                },
                {
                    name: 'Редактировать скидку',
                    //permission: ['forward'],
                    permission: ['groupstudentdiscountrelations', 'edit'],
                    action: 'edit_discount_dates'
                }
            ],
            //link: 'discounts_only',
            link: 'discounts_only_new',
            prefix: 'groups',
            id: { name: 'user_id' },
            main_rows: [
                { name: 'student_name'},
                { name: 'group_name'},
                { name: ''},
                { name: 'discount_name', classname: 'redDebetor redDebetor--not_paid' },
            ],
            is_open: false,
            sub_rows: [
                {
                    name: 'group_name',
                    description: localisationMaster('group_name', 'online-schedule/time-slots')
                },
                {
                    name: 'discount_name',
                    description: localisationMaster('discount', 'online-schedule/time-slots'),
                    classname: 'redDebetor'
                },
                { name: 'responsible', description: localisationMaster('responsible', 'online-schedule/time-slots'), },
                { name: 'phone', description: localisationMaster('phone', 'online-schedule/time-slots'), },
                { name: 'email', description: localisationMaster('email', 'online-schedule/time-slots'), },
                {name: 'date_from', description: loc(dict.company.student_discount.start_date, 'Дата начала')},
                {name: 'date_until', description: loc(dict.company.student_discount.end_date, 'Дата окончания')},
            ]
        },

        filterLiner: true,
        filterLinerLink: "groups/discounts_only_new",
        config: [
            { id: 1, nameState: 'search', placeholder: localisationMaster('text-1', 'online-schedule/time-slots'), type: "input" },
            { id: 2, nameState: 'group', placeholder: localisationMaster('text-2', 'online-schedule/time-slots'), type: "input" },
            {
                id: 3,
                nameState: 'discount_id',
                placeholder: loc(dict.groups_discounts.discount, 'Скидка'),
                type: 'select_request',
                apiLink: 'groups/discounts',
                params: { limit: 999 },
                apiGetResult: data => data.result.sort((a, b) => a.count - b.count).map(el => ({ 
                    ...el, value: el.id, label: `${el.name} (${el.type_name}, ${el.count}${el.type === 1 ? 'тг' : '%'})` 
                })),
            },
            { id: 4, apiLink: 'db/companies', nameState: 'company', placeholder: localisationMaster('text-3', 'online-schedule/time-slots'), type: "select_request" },
            { id: 5, apiLink: 'db/locations', nameState: 'location', placeholder: localisationMaster('Choose-a-location', 'online-schedule/time-slots'), type: "select_request" },
            {
                id: 6,
                values: [
                    {id: 0, name: loc(dict.groups_discount.all_students)},
                    {id: 1, name: loc(dict.groups_discount.actual_students)}
                ],
                nameState: 'actual',
                type: "type_selector",
                initState: 1
            },
            {
                id: 7,
                type: 'date_day_picker', 
                nameState: ['date_from', 'date_until']
            },
        ],
        apiSettings: {}
    },
    {
        link: 'student-certs',
        folder: 'company',
        permission: ['age', 'view'],
        title: 'Список студентов с сертификатами',
        colsSettings: {
            action: [
                { name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile_client', link: '/account_clients', nav_link: true, permission: ['slotclient', 'edit'] },
                { name: localisationMaster('text_6', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward'] },
            ],
            link: 'student_certs',
            prefix: 'groups',
            id: { name: 'student_id' },
            main_rows: [
                {
                    compiled: [
                        { name: 'first_name', updir: 'student', key_name: 'first_name', past: ' ' },
                        { name: 'last_name', updir: 'student', key_name: 'last_name' },
                    ],
                },
                { name: 'name', updir: 'certificate', },
                { name: '', depence: false },
                { name: 'amount', updir: 'certificate', depence: false, function: 'cleanSum', classname: 'redDebetor redDebetor--big' },
            ],
            is_open: false,
            sub_rows: [ 
                { name: 'name', depence: false, description: 'Сертификат', function: 'cert_body' },
                { name: 'name', depence: false, description: 'Срок действия', function: 'dateRange', classname: 'redDebetor' },
                { name: 'name', function: 'nameList', description: 'Компании', },
                { name: 'responsible_name', updir: 'student', description: 'Ответственный', },
                { name: 'phone', updir: 'student', description: 'Телефон', },
                { name: 'email', updir: 'student', description: 'Email', },
            ]
        },

        filterLiner: true,
        filterLinerLink: "groups/student_certs",
        config: [
            { id: 1, nameState: 'search', placeholder: 'Имя или почта студента', type: "input" },
            { id: 5, apiLink: 'groups/certificates', nameState: 'certificate', placeholder: 'Выберите сертификат', type: "select_request" },
            {
                id: 6, values: [
                    { id: 1, name: 'Действующие' },
                    { id: 0, name: 'Просроченные' }
                ],
                initState: 1,
                nameState: 'active', placeholder: '', type: "type_selector"
            },
        ],
        apiSettings: {}
    },
    {
        link: 'cert-in-group',
        folder: 'company',
        permission: ['age', 'view'],
        title: 'Список студентов с сертификатами',
        exportToExcel: true,
        colsSettings: {
            action: [
                { name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'to_profile_client', permission: ['clientsprofile', 'view_client'], },
                { name: localisationMaster('text_6', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward'] },
            ],
            link: 'student_group_certs',
            prefix: 'groups',
            id: { name: 'student_id' },
            main_rows: [
                {
                    compiled: [
                        { name: 'name', updir: 'student_certificate', sub_dir: 'student', key_name: 'name', past: ' ' },
                    ],
                },
                { name: 'group_name' },
                { name: '', depence: false },
                { name: 'group_name', depence: false, function: 'leftOnCert', classname: 'redDebetor redDebetor--big' },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', depence: false, updir: 'student_certificate', sub_dir: 'certificate', description: 'Сертификат', function: 'cert_body_gr' },
                { name: 'id', depence: false, description: 'Срок действия', function: 'dateRangeGr', classname: 'redDebetor' },
                // { name: 'id', function: 'nameListGr', description: 'Компании', },
                // { name: 'responsible_name', description: 'Ответственный', },
                // { name: 'phone', updir: 'student_certificate', sub_dir: 'student', description: 'Телефон', },
                // { name: 'email', updir: 'student_certificate', sub_dir: 'student', description: 'Email', },
            ]
        },

        filterLiner: true,
        filterLinerLink: "groups/student_group_certs",
        config: [
            { id: 1, nameState: 'search', placeholder: 'Имя или почта студента', type: "input" },
            { id: 2, nameState: 'group', placeholder: 'Имя или номер группы', type: "input" },
            { id: 5, apiLink: 'groups/certificates', nameState: 'certificate', placeholder: 'Выберите сертификат', type: "select_request" },
            { id: 5, apiLink: 'db/companies', nameState: 'company', placeholder: 'Выберите компанию', type: "select_request" },
            { id: 4, apiLink: 'db/locations', nameState: 'location', placeholder: localisationMaster('Choose-a-location', 'online-schedule/time-slots'), type: "select_request" },
        ],
        apiSettings: {}
    },
    {
        link: 'companies',
        folder: 'company',
        permission: ['company', 'view'],
        button: [localisationMaster('button-title', 'company/companies'), 'company', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'companies',
            link: 'db/companies',
            media: true,
            button: localisationMaster('text_4', 'company/locations'),
            title: loc(dict.company_locations.add_company),
            type: 'ModalCompany',
            data: 'json',
            func: listingStore.updateData,
            valid: {
                name: 'required',
                currency: 'required',
                comment: 'required',
            },
        },
        title: localisationMaster('title', 'company/companies'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_company', permission: ['company', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['company', 'delete'] },
            ],
            link: 'companies',
            prefix: 'db',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            avatar: { name: 'logo', ac: true, f: 'name', l: 'name' },
            is_open: false,
            sub_rows: [
                { name: 'currency', description: loc(dict.company_locations.currency), function: 'get_currency' },
                { name: 'comment', description: `${localisationMaster('table-row-title-2', 'company/companies')}:` },
            ]
        },
        apiSettings: {}
    },

    //CERT

    {
        link: 'certificates',
        folder: 'company',
        permission: ['program', 'view'],
        button: ['Добавить сертификат', 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'certificates',
            link: 'groups/certificates',
            button: localisationMaster('button-two', 'company/programs', 'add_program'),
            title: '',
            // type: 'BaseTemplate',
            type: 'certificates',
            func: listingStore.updateData,
        },
        title: 'Сертификаты на обучение',
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['program', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['program', 'delete'] },
            ],
            link: 'certificates',
            prefix: 'groups',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: 'Название', },
                { name: 'amount', description: 'Сумма' },
            ]
        },

        apiSettings: {}
    },
    //LEDGER
    {
        link: 'salary',
        folder: 'ledger',
        permission: ['program', 'view'],
        button: ['Добавить ставку', 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'salary',
            link: 'ledger/salary_v2',
            button: localisationMaster('button-two', 'company/programs', 'add_program'),
            title: 'Добавление ставки преподавателя',
            type: 'salary',
            data: 'json',
            func: listingStore.updateData,
            valid: {
                name: 'required',
            }
        },
        title: 'Ставки преподавателей',
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_forward', permission: ['program', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['program', 'delete'] },
            ],
            link: 'salary_v2',
            prefix: 'ledger',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                {
                    listType: 'table',
                    tableData: [
                        {
                            description: 'Академ. часы',
                            sub: [
                                {description: 'ставки онлайн', data: { name: 'rate_online', updir: 'academ_hour', function: 'rates_list' }},
                                {description: 'ставки офлайн', data: { name: 'rate', updir: 'academ_hour', function: 'rates_list' }},
                            ],
                        },
                        {
                            description: 'Астроном. часы',
                            sub: [
                                {description: 'ставки онлайн', data: { name: 'rate_online', updir: 'astro_hour', function: 'rates_list' },},
                                {description: 'ставки офлайн', data: { name: 'rate', updir: 'astro_hour',  function: 'rates_list' },},
                            ],
                        }, 
                    ],
                    
                },
                { name: 'comment', description: 'Комментарий' },
            ] 
        },

        apiSettings: {},
        // Filter
        filter: true,
        filterLink: "ledger/salary_v2",
        config: [
            {
                //id: 1,
                nameState: 'name',
                placeholder: 'Имя',
                type: 'input'
            },
        ],
    },
    {
        link: 'evaluation',
        folder: 'progress-report',
        permission: ['program', 'view'],
        button: ['Добавить критерий', 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'evaluation',
            link: 'progress_report/evaluation',
            button: localisationMaster('button-two', 'company/programs', 'add_program'),
            title: 'Добавить критерий оценок',
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs: ['name-number', 'select'],
            data: 'json',
            pl_text: 'Название',
            pl_number: 'Порядковый номер',
            name_number: 'order',
            pl_select: localisationMaster('select-placeholder-1', 'company/programs', 'add_program'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies',
            func: listingStore.updateData,
        },
        title: 'Критерии оценок',
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_forward', permission: ['program', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['program', 'delete'] },
            ],
            link: 'evaluation',
            prefix: 'progress_report',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: 'Название', },
                //{ name: 'summ', description: 'Сумма' },
            ]
        },

        apiSettings: {}
    },
    {
        link: 'rating',
        folder: 'progress-report',
        permission: ['program', 'view'],
        button: ['Добавить оценку', 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'rating',
            link: 'progress_report/rating',
            button: localisationMaster('button-two', 'company/programs', 'add_program'),
            title: 'Добавить оценку',
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs: ['name-number', 'select'],
            data: 'json',
            pl_text: 'Название',
            pl_number: 'Порядковый номер',
            name_number: 'order',
            pl_select: localisationMaster('select-placeholder-1', 'company/programs', 'add_program'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies',
            func: listingStore.updateData,
        },
        title: 'Оценки',
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_forward', permission: ['program', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['program', 'delete'] },
            ],
            link: 'rating',
            prefix: 'progress_report',
            id: { name: 'id' },
            is_active: { name: 'is_active' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: 'Название', },
                //{ name: 'summ', description: 'Сумма' },
            ]
        },

        apiSettings: {}
    },
    {
        link: 'companies',
        folder: 'online-schedule',
        permission: ['company', 'view'],
        title: 'Настройки онлайн-записи',
        colsSettings: {
            action: [
                { name: 'Настройки', link: '/online-schedule/company-setting', nav_link: true, permission: ['forward'] },
                { name: localisationMaster('text_6', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward'] },
            ],
            link: 'companies',
            prefix: 'db',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            avatar: { name: 'logo', ac: true, f: 'name', l: 'name' },
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-1', 'company/companies') },
                { name: 'comment', description: localisationMaster('table-row-title-2', 'company/companies') },
            ]
        },

        filter: false,
        filterLink: '',
        config: [],
        apiSettings: {}
    },
]
