import React from 'react';
import {localisationMaster} from "Localisation/Localisation";
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";
import usersStore from 'Stores/usersStore';
import modalStore from 'Stores/modalStore';
import st from "./ContractDoc.module.scss";
import documentImg from "components/ContentBox/img/new_document_img.svg"


export default function ContractDoc(props) {
  const doc = props.doc

  const getType = name => {
    let arr = []
    if (name) {
      arr = name.split('.')
      return arr[arr.length - 1]
    } else {
      return 'ERR'
    }
  }

  const actions = (data) => {
    switch (data) {
      case 'edit':
        const ms = {
          link: 'employees/appendix/' + doc.id,
          button: 'Save',
          title: 'Редактировать документ',
          type: 'SelectorAndInput',
          active_inputs: ['text', 'textarea'],
          is_put: true,

          pl_text: 'Имя документа',
          name_text: 'title',

          pl_textarea: 'Комментарий',
          name_textarea: 'description',

          func: usersStore.addUserDoc
        }
        let inner = {
          title: doc.title,
          description: doc.description,
          contract: doc.contract,
          employee_id: doc.employee_id
        }
        modalStore.activity(ms, inner)
        break;

      default:
        break;
    }
  }

  return (
    <div className={st.contractDoc}>

      <div className={st.gridIcon}>
        <a href={doc.file} download target="_blank" rel="noreferrer">
          <div className={st.documentIcon}>
            <img src={documentImg} alt={doc.title}/>
            <span>{getType(doc.file)}</span>
          </div>
        </a>
      </div>

      <div className={st.documentDesc}>
        <h5>{doc.title}</h5>
        <p>{doc.description}</p>
      </div>

      <div className={st.gridButton}>
        <span onClick={() => actions('edit')} className={st.btn}>
          {localisationMaster('text_11', 'lessons/course')}
        </span>
      </div>

      {checkPermList(permissions.delete_contract_docs) &&
        <div className={st.gridButton}>
          <span onClick={() => actions('delete')} className={st.btn}>
            {localisationMaster('text_13', 'lessons/course')}
          </span>
        </div>}
    </div>
  )
}
