import React from "react";
import {superAxiosRequest} from "axiosApi";
import {permissions} from "settings/permissions";
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import modalStore from "Stores/modalStore";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import st from "./StudyTest.module.scss";
import swal from "sweetalert";

const StudyTest = ({item, ...props}) => {

  const menu = [
    // Назначить группе
    {
      name: localisationMaster('text_4', 'study/study-test'),
      action: 'purpose_test',
      perm: permissions.add_purpose_test
    },
    // Назначить студентам
    {
      name: localisationMaster('text_5', 'study/study-test'),
      action: 'purpose_test_st',
      perm: permissions.add_purpose_test
    },
    // Редактировать
    {
      name: localisationMaster('text_11', 'lessons/course'),
      action: 'edit',
      perm: permissions.edit_study_test,
    },
    // Удалить
    {
      name: localisationMaster('text_13', 'lessons/course'),
      action: 'delete',
      perm: permissions.delete_study_test,
    },
  ]

  const action = action => {
    let ms
    switch (action){
      case 'go_to_test':
        window.open(`/study/test-questions/${item.id}`, '_blank')
        break;
      case 'purpose_test':
      case 'purpose_test_st':
        ms = {
          name: "create-study-test",
          link: item.under_request ? 'study/request_test_purpose' : 'study/purpose_test',
          button: localisationMaster('text_1', 'groups/all-groups'),
          title:
            localisationMaster('text_22', 'study/study-test', 'modal') +
            (action === 'purpose_test_st' ?
              localisationMaster('text_13_1', 'groups/all-groups') :
              localisationMaster('text_12_1', 'groups/all-groups')),
          type: "create-study-test",
          id: item.id,
          under_request: item.under_request,
          func: res => {
            swal({
              title: localisationMaster('text_23', 'study/study-test', 'modal'),
              text: `Тест "${res.name}" ${localisationMaster('text_24', 'study/study-test', 'modal')}`,
              icon: "success",
              buttons: [
                'OK',
                localisationMaster('text_25', 'study/study-test', 'modal'),
              ],
            }).then((go) => {
              go && window.open('/study/test/' + res.id, '_blank')
            })
          }
        }
        if (action === 'purpose_test_st') ms.students = true;
        const dates = {
          date_from: timeConverter(new Date(), "today"),
          date_until: timeConverter(new Date(), "today"),
          start_at: "09:00",
          finish_at: "18:00",
        }
        const md = {
          study_test: item.id,
          during: 30,
          type_show_test: true,
          focus_live: false,
          focus_live_during: 15,
          focus_live_count: 5,
          mouse_freeze: false,
          mouse_freeze_during: 15,
          mouse_freeze_count: 5,
          shuffle: false,
          try_count: 3,
        }
        modalStore.activity(ms, {
          ...dates,
          ...(item.under_request ? {purposed_test: {...dates, ...md}} : {...md})
        })
        break;
      case "edit":
        ms = {
          //name: 'add-study-test',
          title: localisationMaster('text_1', 'study/study-test'),
          type: 'add-study-test',
          button: "Сохранить",
          valid: {
            company: "required",
            name: "required",
            level: "required",
            sub_lvl: "required",
          },
          is_put: true,
          link: 'study/study_test/' + item.id,
          func: props.updateRow,
        };
        modalStore.activity(ms, item);
        break;
      case "delete":
        swal({
          title: localisationMaster("text_2", "alert"),
          text: `${localisationMaster('text_6', 'study/study-test')}: "${item.name}"?`,
          icon: "warning",
          buttons: [
            localisationMaster("button_2", "alert"),
            localisationMaster("button_1", "alert"),
          ],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const apiConf = {
              method: "delete",
              link: 'study/study_test/' + item.id,
            }
            superAxiosRequest(apiConf).then(({data}) => {
              if (data.result) props.updateData(data)
            })
          } else {
            swal(localisationMaster("text_5", "alert"));
          }
        });
        break;
      default:
        break;
    }
  }

  return (
    <div className={st.row}>
      <div className={st.params} onClick={() => action('go_to_test')}>
        <div className={st.paramNameLevel}>
          <a href={`/study/test-questions/${item.id}`} onClick={e => e.preventDefault()}>
            {item.name}
          </a>
          <span>{item.sub_lvl.lvl.name} | {item.sub_lvl.name} | {item.sub_lvl.lvl.subname}</span>
        </div>
        <div className={st.param}>
          <span className={st.paramName}>Questions</span>
          <span className={st.paramValue}>{item.quantity_questions}</span>
        </div>
        <div className={st.param}>
          <span className={st.paramName}>Score</span>
          <span className={st.paramValue}>65</span>
        </div>
        <div className={st.param}>
          <span className={st.paramName}>Passing Scores</span>
          <div>
            <span className={st.paramValue}>55</span>
            <span className={st.separatorH14}/>
            <span className={st.percent}>85%</span>
          </div>
        </div>
      </div>
      <div>
        <ActionMenu menu={menu} action={action}/>
      </div>
    </div>
  )
}

export default StudyTest;
