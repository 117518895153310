import {action, observable, toJS, makeObservable, computed} from "mobx";
import {superAxiosRequest} from "axiosApi";
import Stores from "Stores";

class HomeworkTeacherStore {
  homeworkData = null
  errorStatus = ''
  wss

  constructor() {
    makeObservable(this, {
      homeworkData: observable,
      errorStatus: observable,

      clearHomework: action,

      homework: computed,
    })
  }

  fetchHomework = (hash, onSuccess) => {
    const apiConf = {
      method: 'get',
      link: 'ebooks/home-work/' + hash,
    }
    superAxiosRequest(apiConf).then(({data}) => {
      this.homeworkData = data
      onSuccess()
    }).catch(err => {
      if (err.response?.status === 404) {
        this.errorStatus = 404
      }
    })
  }

  connectToLesson = () => {
    const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/ebooks/homework/${this.homework.hash}/?token=${localStorage.getItem('access')}`;
    //const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/ebooks/init_lesson/${this.homework.init_lesson_uuid}/?token=${localStorage.getItem('access')}`;
    this.wss = new WebSocket(wsUrl)
    this.wss.onmessage = ({data}) => {
      const msg = JSON.parse(data)
      if (msg.type === "answered_homework_exercise") {
        this.homeworkData = {
          ...this.homeworkData,
          assign_exercises: msg.message
        }
      }
    }
  }

  clearHomework = () => {
    this.homeworkData = null
    this.errorStatus = null
  }

  deleteExercise = id => {
    superAxiosRequest({
      method: 'delete',
      link: 'ebooks/homework_exercise/' + id,
      params: {
        uuid: this.homework.init_lesson_uuid
      }
    }).then(() => {
      this.homeworkData = {
        ...this.homework,
        exercises: this.homework.exercises.filter(ex => ex.id !== id)
      }
    }).catch(() => {

    })
  }

  get homework() {
    return toJS(this.homeworkData)
  }
}

HomeworkTeacherStore = new HomeworkTeacherStore()

export default HomeworkTeacherStore
