import React, {useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import Stores from "Stores";
import asideModalStore from "Stores/asideModalStore";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormField from "components/UI/AsideModal/Form/FormField";
import st from "./AddBookByCode.module.scss";
import {ReactComponent as NotFoundBookImg} from 'components/ContentBox/img/not_found_book_img.svg';
import useLocalization from "hooks/useLocalization";


const AddBookByCode = () => {
  const {changeValue, check} = asideModalStore
  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'ebooks_books',
    dict: {
      "book_number" : "Book Number",
      "check_number" : "Check Number"
    }
  })
  const [bookNumber, setBookNumber] = useState('')
  const [book, setBook] = useState([])

  const checkNumber = async () => {
    if (!bookNumber) return;
    try {
      const { data } = await superAxiosRequest({
        method: 'get',
        link: 'ebooks/check_book_by_code',
        params: {
          book_number: bookNumber
        }
      });
      if (Array.isArray(data) && data.length > 0) {
        const firstBook = data[0];
        setBook(firstBook);

        changeValue(firstBook.ebook_id, 'ebook_id');
        changeValue(firstBook.id, 'books_code')

      } else {
        setBook('notFound');
      }
    } catch (error) {
      setBook(404);
    }
  };

  return (
    <div className={st.form}>
      <FormField>
        <FormLabel>{translate.book_number}:</FormLabel>
        <FormInput isError={check('ebook_id')}>
          <input type="text"
                 name="book_number"
                 placeholder={translate.book_number}
                 value={bookNumber}
                 onChange={e => setBookNumber(e.target.value)}
          />
        </FormInput>
      </FormField>
      {(book === 'notFound' || book === 404) &&
          <div className={st.notFound}><span>Nothing found</span></div>}

      {book?.id &&
        <>
          {/*<div className={st.bookImage}><img src={Stores.baseLink() + book.image} alt=""/></div>*/}
          {/*<div className={st.bookName}><span>{book.name}</span></div>*/}
          <div className={st.book}>
           <div className={st.book__cover}>
              <div className={st.image}>
                <div className={book.image ? st.img : st.imgNotFound}>
                  {book.image ?
                      <img src={Stores.baseLink() + book.image} alt=""/> :
                      <NotFoundBookImg/>
                  }
                </div>
              </div>
              <div className={st.name}>
                {book.name}
              </div>
           </div>
            {/*<div className={st.footer}>*/}
            {/*  <div className={st.params}>*/}
            {/*    <div>{book.company}</div>*/}
            {/*    <div className={st.courseLvl}>*/}
            {/*      <div>*/}
            {/*        {book.course_level}*/}
            {/*      </div>*/}
            {/*      <div className={st.delimiter}>*/}
            {/*        |*/}
            {/*      </div>*/}
            {/*      <div>*/}
            {/*        {book.skill_level}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </>}


      <div>
        <button className={st.checkButton} onClick={checkNumber}>{translate.check_number}</button>
      </div>
    </div>
  )
}

export default observer(AddBookByCode);
