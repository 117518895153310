import React, {useEffect, useState} from 'react';
import {BlockHeader} from 'components/UI/BlockHeader/BlockHeader';
import {GoToLink} from "components/UI/SuperButton/SuperButton";
import ActiveTest from './components/ActiveTest';
import styles from './ActiveTests.module.scss';
import {superAxiosRequest} from "../../../../axiosApi";
import Loader from "components/UI/Loader/Loader";
import Store from "Stores";

const ActiveTests = ({translate}) => {
    const isClient = Store.isClient
    const defaultTestsPortion = 5

    const [testsData, setTestsData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const apiConf = {
        method: 'get',
        link: !isClient ? `study/purpose_test` : `study/purpose_test_student`,
        params: {
            limit: defaultTestsPortion,
            filter_type: 1
        }
    }

    const updateTests = async () => {
        try {
            const res = await superAxiosRequest(apiConf)
            setTestsData(res.data.result)
        } catch {
            setTestsData([])
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        updateTests()
    }, [defaultTestsPortion])

    return testsData.length > 0 && (
        <div className={styles.box}>
            <BlockHeader title={translate.active_tests}>
                <GoToLink to="/c/study/my-tests" target="_blank"/>
            </BlockHeader>
            <div className={styles.content}>
                {isLoading ? <Loader/> : testsData.map((item, i) => <ActiveTest key={i} {...item} />)}
            </div>
        </div>
    )
};

export default ActiveTests;