import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from 'components/UI/Paper/Paper';
import swal from 'sweetalert';
import Flex from 'components/UI/Flex/Flex';
import Loader from 'components/UI/Loader/Loader';
import Button from 'components/UI/Button/Button';
import ClientNotify from 'components/Modules/Slots/components/ClientNotify/ClientNotify';
import { calcPostfix } from 'components/Modules/Slots/utils';
import { dateFormat } from 'helpers/date';
import { maskPhone } from 'helpers/phoneMask';
import { useHistory } from 'react-router-dom';
import { superAxiosRequest } from 'axiosApi';
import {Context} from '../SlotDetails';
import styles from '../SlotDetails.module.scss';

const MILLISECONDS_IN_YEAR = 31556952000;

const getAge = (birthday) => Math.trunc((Date.now() - Date.parse(birthday)) / MILLISECONDS_IN_YEAR);
const addSpaces = (string) => string.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');

export default function Client({id}) {
  const history = useHistory();
  const { client, slot_client } = useContext(Context);
  const age = getAge(client.birthday);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const handleDeleteSlot = () => {
    swal('', 'Вы действительно хотите удалить запись клиента?', 'warning', { buttons: ['Отмена', 'OK'] })
      .then((response) => {
        if (!response) {
          return;
        }
        setIsLoading(true);
        superAxiosRequest({
          method: 'delete',
          link: `online_schedule/booked_schedule/${slot_client.id}`,
        })
          .then(() => {
            swal('', 'Запись успешно удалена', 'success')
              .then(() => history.push('/online-schedule-v2/history-clients'));
          })
          .finally(() => setIsLoading(false));
      })
  };

  return (
    <Paper classes={`${styles.item} ${styles.client}`}>
      <ul className={styles.info_list}>

        <li className={styles.text_wrapper}>
          <h2 className={styles.title}>
            {client.full_name}
          </h2>
          <p>
            {age} {calcPostfix(age)} ({dateFormat(new Date(client.birthday), 'dd MMMM YYYY г.')})
          </p>
        </li>

        {client.phone &&
          <li className={styles.flex}>
            <a className={styles.link} href={`tel:${client.phone}`}>{maskPhone(client.phone)}</a>
            <a className={styles.link} href={`https://wa.me/${client.phone.slice(1)}`}>(WhatsApp)</a>
          </li>
        }

        <li><a className={styles.link} href={`mailto:${client.email}`}>{client.email}</a></li>

        { client.inn && <li>ИИН: {addSpaces(client.inn)}</li> }
        { slot_client.add_field1 && <li className={styles.break_word}>{slot_client.add_field1}</li> }
        { slot_client.add_field2 && <li className={styles.break_word}>{slot_client.add_field2}</li> }
        { slot_client.add_field3 && <li className={styles.break_word}>{slot_client.add_field3}</li> }
      </ul>

      <Flex gap={10} withGap justify='center'>
        <Button
          classes={`${styles.button} ${styles.button__grey}`}
          onClick={() => history.push(`/online-schedule-v2/record-customer/${id}`)}
        >
          Перезаписать
        </Button>
        <Button
          classes={`${styles.button} ${styles.button__blue}`}
          onClick={() => {setIsModalShown(true)}}
        >
          Уведомить
        </Button>
        <Button
          classes={`${styles.button} ${styles.button__green}`}
          onClick={() => history.push(`/account_clients/${client.user_id}`)}
        >
          Страница клиента
        </Button>
        <Button
          classes={`${styles.button} ${styles.button__red}`}
          onClick={handleDeleteSlot}
        >
          Удалить
        </Button>
      </Flex>
      {isLoading &&
        <Loader fullWidth withOverlay />
      }

      {isModalShown &&
        <ClientNotify
          slotClientIDs={[slot_client.id]}
          onClose={() => setIsModalShown(false)}
        />
      }

    </Paper>
  );
}

Client.propTypes = {
  id: PropTypes.string.isRequired,
};

