import React from "react";
import { ReactComponent as InfoIcon } from "components/ContentBox/img/info-widget.svg";
import './Error.scss';

const Error = ({ text }) => (
  <div className="placementTestBox--error">
     <InfoIcon />
    <p>{text || "Error"}</p>
  </div>
);

export default Error;
