import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import StudyStore from "Stores/studyStore";
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import QuestionCard from "./components/QuestionCard";
import st from "./TestQuestions.module.scss"
import TestInformation from "./components/TestInformation/TestInformation";
import TestCategories from "./components/TestCategories/TestCategories";
import EmptyList from "../Common/EmptyList/EmptyList";

const TestQuestions = props => {
    const tid = +props.match.params.id

    const {studyTest, testQuestions, fetchStudyTest, fetchTestQuestions, addQuestion, fetchQuestionTypes} = StudyStore

    const calcScore = testQuestions?.reduce((currSum, currPoint) => {
        return currSum + currPoint.point
    }, 0)

    const [openAll, setOpenAll] = useState(false)
    const [filteredTestQuestions, setFilteredTestQuestions] = useState(testQuestions)

    const filterTestQuestions = (sectionId) => {
        setFilteredTestQuestions(testQuestions.filter(question => sectionId ? question?.test_section?.id === sectionId : question.test_section === null ))
    }

    useEffect(() => {
        setFilteredTestQuestions(testQuestions)
    }, [testQuestions]);

    useEffect(() => {
        fetchStudyTest(tid)
        fetchTestQuestions(tid)
        fetchQuestionTypes()
    }, [tid])
    const renderButton = () =>
        <div className={st.btnContainer}>
            <button className={st.btn} onClick={addQuestion}>
                Add New Question
            </button>
        </div>

    return (
        <>
            <ContentHeader
                title={`${studyTest.name ? `${studyTest.name}: ` : ''}${localisationMaster('text_10', 'study/study-test')}`}
                breadСrumbs={[
                    { title: localisationMaster('text_2', 'study/study-test') , route: '/c/study/study-test' }
                ]}
            />
            <div className={st.page}>
                <TestInformation
                    questionsCount={testQuestions.length}
                    score={calcScore}
                    levelName={'Project'}
                    levelSubname={'Juniors'}
                    sublevelName={'Level 4'}
                    text={studyTest.description}/>
                <TestCategories filterTestQuestions={filterTestQuestions}/>
                {testQuestions.length ?
                    <div className={st.questions}>
                        {renderButton()}
                        {filteredTestQuestions.map((question, index) =>
                            <QuestionCard key={question.id} question={question} open={openAll} pos={index + 1}/>)}
                        {renderButton()}
                    </div> :
                    <EmptyList
                        title="No question :("
                        text1="Add your first question to this test!"
                        text2="But at the beginning we propose to introduce categories of questions. The categories can be entered after the questions have been completed."
                        button={{
                            text: 'Add New Test',
                            onClick: addQuestion,
                        }}
                    />
                }
            </div>
        </>
    );
};

export default observer(TestQuestions)