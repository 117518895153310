import React from 'react';
import Select from '../../Select/Select';
import FilterInput from "components/ContentBox/Global/Filter/FilterInput";
import {localisationMaster} from "../../../../../Localisation/Localisation";



export default function CreatePoolTest(props) {
    const ms = props.modal_settings;
    const duration =[
        {id:0,  name:localisationMaster('chosen', 'online-schedule/time-slots')},
        {id:15, name:`15 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
        {id:30, name:`30 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
        {id:45, name:`45 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
        {id:60, name:`60 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
        {id:90, name:`90 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
        {id:120, name:`120 ${localisationMaster('minutes', 'online-schedule/time-slots')}`},
    ]

    const pushClientData = (items) =>{
        let old_v = '';
        if(props.returnValue(ms.name_textarea) !== undefined){
            old_v = props.returnValue(ms.name_textarea)
        }
        let coma = ',';
        if(old_v.length < 1){
            coma = ''
        }
        let new_v = old_v + coma + items['email']
        props.objectChangeHandler(new_v, 'email_list') 
    }
    const pushClientDataStudents = (items) =>{
        let old_v = '';
        if(props.returnValue(ms.name_textarea) !== undefined){
            old_v = props.returnValue(ms.name_textarea)
        }
        let coma = ',';
        if(old_v.length < 1){
            coma = ''
        }
        let new_v = old_v + coma + items['students']
        props.objectChangeHandler(new_v, 'email_list')
    }
    const changeHandler = (e, key_name, type) =>{
        props.changeHandler(e, key_name, type);
    }


    return (
        <div>
            <FilterInput link='accounts/clients' clientId={pushClientData} placeholder={localisationMaster('placeholder-1', 'online-schedule/record-customer')}/>
            <FilterInput link='groups/students' clientId={pushClientDataStudents} placeholder={localisationMaster('placeholder-1_1', 'online-schedule/record-customer')}/>
            {/*<SelectRequest onChange={props.ChangeError}*/}
            {/*               value_error={props.program_error}*/}
            {/*               valueId={props.returnValue("group_id",1)}*/}
            {/*               nameState={"group_id"}*/}
            {/*               selected={0} stateError={"selectValue_error"}*/}
            {/*               myChangeHandler={(e) => changeHandler(e, "group_id", props.subMenu)}*/}
            {/*               text={"Выберите группу"}*/}
            {/*               value={props.returnValue('group_id')}*/}
            {/*               ChangeError={props.ChangeError}*/}
            {/*               width="100%"*/}
            {/*               link={"groups"}*/}
            {/*               subMenu={props.subMenu}*/}

            {/*/>*/}
            <textarea 
                value={props.returnValue(ms.name_textarea)} 
                                    placeholder={ms.pl_textarea}
                                    onChange={(e)=>changeHandler(e, ms.name_textarea)}
                />
            <div className= "super-modal-twin-selectors">
                            <input  type="text" value={props.returnValue('name')}
                                    id="1"   
                                    placeholder={localisationMaster('title-modal-3', 'push_pool_test')}
                                    onChange={(e)=>changeHandler(e, 'name')}/>

                            <Select onChange={ms.ChangeError} 
                                    value_error={ms.program_error} 
                                    valueId={props.returnValue('test_time')}
                                    nameState={'test_time'}
                                    selected={0} stateError={'test_time_error'}
                                    myChangeHandler={(e)=>changeHandler(e, 'test_time', 'int')} 
                                    text={localisationMaster('duration', 'online-schedule/time-slots')}
                                    value={duration}
                                    ChangeError={ms.ChangeError}
                                    width="225px"/>

            </div>
        </div>
    )
}
