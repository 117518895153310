import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter2} from 'components/ContentBox/Online-schedule/components/functions';
import {addZero} from 'helpers/math';
import Stores from 'Stores';
import modalStore from 'Stores/modalStore';
import DateTime from '../../DateTime/DateTime';
import DummyCounter from "../../Counter/DummyCounter";
import Checkbox from '../../Checkbox';
import MoneyBox from '../../MoneyBox/MoneyBox';
import Textarea from "../../Textarea/Textarea";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import SelectCustom from '../../Select/SelectCustom';
import HourCounterReb from 'components/ContentBox/Global/HourCounter/HourCounterReb';
import DataPicker from 'components/ContentBox/Global/DatePicker/DataPicker';
import './TimeTableDay.scss'

const TimeTableDay = () => {
    let apiConf;
    const {modalSettings: ms} = modalStore
    const modal_settings = Stores.observableArray(modalStore.data)
    const {schedule, student} = ms
    const [price, setPrice] = useState([false, []])
    const [catalog, setCatalog] = useState([false, [], ''])
    const [responsible, setResponsible] = useState([false, []])
    const [action, setAction] = useState(0)
    const [dateFrom, setDateFrom] = useState(!!schedule ? new Date(schedule[0].date) : new Date())
    const [dateTo, setDateTo] = useState(new Date(ms.last))
    const [vsTeacher, setVsTeacher] = useState(false)
    const [inputsValues, setInputsValues] = useState({status: 0})

    const getSum = ratio => Math.round(ratio * price[1].daily_minutes * price[1].price_per_minute)

    const returnValue = key_name => inputsValues[key_name] || ''

    const objectChangeHandler = (value, key_name) => {
        setInputsValues({ ...inputsValues, [key_name]: value });
    }

    const actionHandler = action => {
        let newValues = {
            dates: inputsValues.dates
        }

        if (action === 777 || action === 999) {
            newValues.schedule = +schedule[0].schedule_id
            newValues.date_from = action === 999 ? ms.date[0] : schedule[0].date
            newValues.date_to = action === 999 ? ms.date[0] : timeConverter2(ms.last)
            newValues.hour_type = price[1]?.hour_type_id || 1
            newValues.start_at = schedule[0].start_at.slice(0, 5)
            newValues.finish_at = schedule[0].finish_at.slice(0, 5)
            newValues.hours_per_day = price[1]?.hours_per_day || 1
        }
        else {
            newValues.user = student.id
            newValues.status = action

            if (action === 1 || action === 4) {
                newValues.share_ratio = 0.5
                newValues.share_sum = getSum(newValues.share_ratio)
                newValues.teacher_debit_ratio = 0.5
                newValues.teacher_debit_sum = getSum(newValues.teacher_debit_ratio)
            } else if (action === 6) {
                newValues.fine = 0.5
                newValues.fine_sum = getSum(newValues.fine)
            }
        }

        // Уведомление для клиентов
        newValues.send_email = false
        //Провести расписание без клиентов
        if(ms.main_s) newValues.just_teacher = false

        setInputsValues(newValues);
        setAction(action)
    }

    const changeEndHandler = date => {
        objectChangeHandler(timeConverter2(date), 'date_to')
        setDateTo(date)
    }

    const fineChangeHandler = (value, name) => {
        // Базовая стоимость в копейках
        const full_price = price[1].daily_minutes * price[1].price_per_minute;
        let fine, fine_sum
        switch (name) {
            case 'fine':
                fine = value
                fine_sum = Math.round(value * full_price)
                break;
            case 'fine_sum':
                fine_sum = value
                fine = full_price ? +(value / full_price).toFixed(2) : 5
                break;
            default:
                break;
        }
        setInputsValues({...inputsValues, fine, fine_sum})
    }

    const calculator = (value, name) => {
        if (price[0]) {
            // Базовая стоимость в копейках
            const full_price = price[1].daily_minutes * price[1].price_per_minute;
            const arr = inputsValues;
            switch (name) {
                case 'share_ratio':
                    arr.share_ratio = value
                    arr.share_sum = getSum(arr.share_ratio)
                    break
                case 'share_sum':
                    arr.share_sum = value;
                    arr.share_ratio = full_price ? +(value / full_price).toFixed(2) : 5
                    break;
                case 'teacher_debit_sum':
                    arr.teacher_debit_sum = value;
                    arr.teacher_debit_ratio = full_price ? +(value / full_price).toFixed(2) : 5
                    break
                case 'teacher_debit_ratio':
                    arr.teacher_debit_ratio = value
                    arr.teacher_debit_sum = getSum(arr.teacher_debit_ratio)
                    break
                default:
                    break;
            }

            setInputsValues({ ...arr });
        }
    }

    const fetchCatalog = () => {
        const {date_from, date_to, start_at, finish_at} = inputsValues
        const key = JSON.stringify({date_from, date_to, start_at, finish_at})
        if (key === catalog[2]) return
        const conf = {
            method: 'get',
            link: 'schedule/schedule_catalog/' + ms.g_id,
            params: {
                schedule_id: +schedule[0].schedule_id,
                date_from: action === 777 ?  date_to : date_from,
                date_until: date_to,
                start_at,
                finish_at,
            }
        }
        superAxiosRequest(conf).then(response => {
            setCatalog([true, response.data, key])
            const arr = response.data.teachers.items.map(x => ({id: x.id, name: Stores.transliterate(x.full_name)}))
            setResponsible([true, arr])
        }).catch(() => {})
    }

    const hoursePerDayHandler = count => {
        if (count === undefined) return
        let minutes = catalog[0] ? catalog[1].hour_types.items.find(x => x.id === inputsValues.hour_type).minutes : 60
        let delta = (count * minutes)
        let CurrentTime = new Date(new Date('2021-01-01 ' + inputsValues.start_at));
        CurrentTime.setMinutes(CurrentTime.getMinutes() + delta);
        let new_time = addZero(CurrentTime.getHours()) + ":" + addZero(CurrentTime.getMinutes());
        // array.hours_per_day = value;
        // array.finish_at = new_time;
        setInputsValues({
            ...inputsValues,
            hours_per_day: count,
            hourse_total: Math.round(count),
            finish_at: new_time,
        })
    }

    const hTHandler = (id, minutes) => {
        if (!id) return
        let count = inputsValues.hours_per_day
        let delta = (count * minutes)
        let CurrentTime = new Date(new Date('2021-01-01 ' + inputsValues.start_at));
        CurrentTime.setMinutes(CurrentTime.getMinutes() + delta);
        let new_time = addZero(CurrentTime.getHours()) + ":" + addZero(CurrentTime.getMinutes());
        // array.hours_per_day = value;
        // array.finish_at = new_time;
        setInputsValues({
            ...inputsValues,
            hour_type: id,
            hours_per_day: count,
            hourse_total: Math.round(count),
            finish_at: new_time,
        })
    }

    const timeHandler = (val, key) => {
        let minutes = catalog[0] ? catalog[1].hour_types.items.find(x => x.id === inputsValues.hour_type).minutes : 60
        if (key === 'start_at') {
            let delta = inputsValues.hours_per_day * minutes
            let CurrentTime = new Date('2021-01-01 ' + val);
            CurrentTime.setMinutes(CurrentTime.getMinutes() + delta);
            let new_time = addZero(CurrentTime.getHours()) + ":" + addZero(CurrentTime.getMinutes());
            setInputsValues({
                ...inputsValues,
                start_at: val,
                finish_at: new_time,
            })
        } else {
            let CurrentTime = new Date('2021-01-01 ' + val);
            let StartTime = new Date('2021-01-01 ' + inputsValues.start_at);
            if (CurrentTime > StartTime) {
                let delta = CurrentTime.getTime() - StartTime.getTime()
                delta = (delta / 1000 / 60) / minutes
                setInputsValues({
                    ...inputsValues,
                    hours_per_day:  delta,
                    hourse_total: Math.round(delta),
                    finish_at: val,
                })
            }
        }
    }

    useEffect(() => {
        if (!Stores.Rules.checkRule('schoolday', 'edit_absence')) {
            apiConf = {
                method: 'get',
                link: `schedule/school_day_price`,
                params: {
                    schedule_id: schedule[0].schedule_id,
                    ...(student.id && {client_id: student.id})
                }
            }
            superAxiosRequest(apiConf).then(({ data }) => setPrice([true, data]))
        }
        // тут было inputsValues.status === 0
        if (modal_settings.is_put && (inputsValues.status < 10 || !!inputsValues.status)) {
            let arr = {
                schedule: schedule[0].schedule_id,
                user: student.id,
                //date: data[0].date,
                dates: [[schedule[0].date, schedule[0].schedule_id]],
                status: +modal_settings.is_put[0],
                comment: modal_settings.is_put[2]
            }
            if (arr.status === 1 || arr.status === 4) {
                //if (modal_settings.is_put[3]) arr.share = modal_settings.is_put[3]
                //if (modal_settings.is_put[4]) arr.sum = modal_settings.is_put[4]
                ['share_ratio', 'share_sum', 'teacher_debit_ratio', 'teacher_debit_sum'].forEach(key => {
                    arr[key] = ms.payload.school_day[key]
                })
            }
            if (arr.status === 6) {
                //if (modal_settings.is_put[5]) arr.fine = modal_settings.is_put[5]
                arr.fine = ms.payload.school_day_note.fine
                arr.fine_sum = ms.payload.school_day_note.fine_sum
            }
            setAction(+modal_settings.is_put[0])
            setInputsValues({...arr});
        } else if (!modal_settings.is_put && inputsValues.status < 10) {
            let arr = {
                dates: [[schedule[0].date, schedule[0].schedule_id]],
            }
            setInputsValues({...arr});
        }  
    }, [])

    useEffect(() => {
        // Замена
        if (action === 999) {
            setDateTo(new Date(ms.date))
        }
        // Перенос
        if (action === 777) {
            setDateTo(new Date(ms.last))
        }
        if (action === 777 || action === 999) {
            /*setInputsValues({
                ...inputsValues,
                hour_type: +ms.htm === 45 ? 1 : 2,
                hours_per_day: +ms.timeDelta / +ms.htm,
                hourse_total: +ms.timeDelta / +ms.htm,
            })*/
            fetchCatalog()
        }
    }, [action])

    useEffect(() => {
        modalStore.changeValuesArray(inputsValues)
        if (action === 777 || action === 999) fetchCatalog()
    }, [inputsValues])

    return (
        <div className="modalTimeTableDay">
            <div>
                <SelectCustom
                    placeholder={localisationMaster('text_40', 'modal_templates')}
                    options={ms.statuses}
                    valueID={action}
                    onChange={o => actionHandler(o.id)}
                    error={action === 0}
                />
            </div>

            {(action < 777 && action > 0) &&
                <>
                {ms.main_s && <div>
                    <CheckboxToggle
                        checked={inputsValues.just_teacher}
                        right={localisationMaster('text_62', 'modal_templates')}
                        mode="inline"
                        onChange={(e) => {
                            setInputsValues({...inputsValues, just_teacher: e})
                        }}/>
                </div>}
            </>}

            {(action === 777 || action === 999) &&
            <>
                <div className="modalTimeTableDay__transferDate">

                    <div className="modalTimeTableDay__transferDateText">
                        {localisationMaster('text_41', 'modal_templates')}
                    </div>

                    <DataPicker 
                        selected={new Date(dateFrom)} 
                        disabled 
                        onChange={date => setDateFrom(date)} 
                        selectsStart={true}
                        endDate={dateTo || new Date()}
                    />

                    <div className="modalTimeTableDay__transferDateText">
                        {localisationMaster('text_42', 'modal_templates')}
                    </div>

                    <DataPicker 
                        disabled={action === 999}
                        selected={new Date(dateTo) || new Date()} 
                        startDate={new Date(dateFrom)}
                        onChange={changeEndHandler}
                        selectsEnd={true}
                        popperPlacement="bottom-end"
                        dayClassName={date => {
                            const day = new Date(date).getDay();
                            return !schedule.weekdays.includes(day) && 'modalTimeTableDay__weekdays';
                        }}
                    />

                </div>

                <div className="modalTimeTableDay__gridRow modalTimeTableDay__gridRow--col3">
                    <div>
                        <SelectCustom
                            placeholder="Тип часов"
                            options={catalog[0] ? catalog[1].hour_types.items : []}
                            sortKey="name"
                            valueID={inputsValues.hour_type}
                            onChange={o => hTHandler(o.id, o.minutes)}
                            isLoading={!catalog[0]}/>
                    </div>

                    <div>
                        <HourCounterReb
                            name="hours_per_day"
                            value={inputsValues.hours_per_day}
                            onChange={hoursePerDayHandler}
                            note={localisationMaster('text_48', 'modal_templates')}
                            min={.5}
                            max={8}
                            step={.5}
                        />
                    </div>

                    <div>
                        <DateTime placeholder='9:00'
                                  start_at={inputsValues.start_at}
                                  finish_at={inputsValues.finish_at}
                                  objectChangeHandler={timeHandler}/>
                    </div>
                </div>

                {action === 777 &&
                <div>
                    <CheckboxToggle right={localisationMaster('text_43', 'modal_templates')}
                                    checked={vsTeacher}
                                    onChange={() => setVsTeacher(!vsTeacher)}
                                    mode="inline"
                    />
                </div>}

                <div className="modalTimeTableDay__gridRow modalTimeTableDay__gridRow--teachVsClass">
                    <div>
                        <SelectCustom
                            placeholder={localisationMaster('text_44', 'modal_templates')}
                            options={responsible[1]}
                            sortKey="name"
                            //valueID={Array.isArray(returnValue('teachers')) ? returnValue('teachers')[0] : null}
                            valueID={inputsValues.teachers?.[0] || null}
                            onChange={o => objectChangeHandler([o.id], 'teachers')}
                            isLoading={!responsible[0]}
                            isDisabled={vsTeacher}
                            isSearchable
                        />
                    </div>

                    <div>
                        <SelectCustom
                            placeholder={localisationMaster('text_45', 'modal_templates')}
                            options={catalog[0] ? catalog[1].classrooms.items : []}
                            sortKey="name"
                            valueID={inputsValues.classroom}
                            onChange={o => objectChangeHandler(o.id, 'classroom')}
                            isLoading={!catalog[0]}
                            isDisabled={vsTeacher}
                        />
                    </div>
                </div>

            </>}

            {(action === 1 || action === 4) &&
            <>
                <div className="modalTimeTableDay__gridRow modalTimeTableDay__gridRow--shareSum">
                    <div>{`${student ? localisationMaster('text_65', 'modal_templates') : localisationMaster('text_63', 'modal_templates')}:`}</div>
                    <div>
                        <DummyCounter name="share_ratio" value={inputsValues.share_ratio} min={0.01} max={5} step={0.01}
                                      onChange={calculator} fixed={2}/>
                    </div>
                    <div>
                        <MoneyBox currency={localisationMaster('tg', 'groups/group')}
                                  offset={100}
                                  changeHandler={calculator}
                                  name="share_sum"
                                  returnValue={returnValue}/>
                    </div>
                </div>
                {!student &&
                <div className="modalTimeTableDay__gridRow modalTimeTableDay__gridRow--shareSum">
                    <div>{localisationMaster('text_64', 'modal_templates')}:</div>
                    <div>
                        <DummyCounter value={inputsValues.teacher_debit_ratio} min={0.01} max={5} step={0.01}
                                      name="teacher_debit_ratio" onChange={calculator} fixed={2}/>
                    </div>
                    <div>
                        <MoneyBox currency={localisationMaster('tg', 'groups/group')}
                                  offset={100}
                                  changeHandler={calculator}
                                  name="teacher_debit_sum"
                                  returnValue={returnValue}/>
                    </div>
                </div>}
            </>}

            {action === 6 &&
            <div className="modalTimeTableDay__gridRow modalTimeTableDay__gridRow--shareSum">
                <div>{localisationMaster('text_65', 'modal_templates')}:</div>
                <div>
                    <DummyCounter name="fine" value={inputsValues.fine} min={0.01} max={5} step={0.01}
                                  onChange={fineChangeHandler} fixed={2}/>
                </div>
                <div>
                    <MoneyBox currency={localisationMaster('tg', 'groups/group')}
                              offset={100}
                              changeHandler={fineChangeHandler}
                              name="fine_sum"
                              returnValue={returnValue}/>
                </div>
            </div>}

            <div>
                <Textarea onChange={objectChangeHandler}
                          value={inputsValues.comment}
                          stateName="comment"
                          placeholder={localisationMaster('comment', 'online-schedule/future-slots')}
                />
            </div>

            <div>
                <Checkbox id="qwertyuiop"
                          label={student ?
                              localisationMaster('notify_client', 'groups/group', 'time_table_day') :
                              localisationMaster('notify_clients_teachers', 'groups/group', 'time_table_day')}
                          checked={inputsValues.send_email || false}
                          isChecked={() => objectChangeHandler(!inputsValues.send_email, 'send_email')}/>
            </div>

        </div>
    )
}

export default observer(TimeTableDay);
