import React, {useEffect, useState} from 'react';
import {Parser, ProcessNodeDefinitions} from "html-to-react";
import {classNames as cn} from "helpers";
import TextMarkup from "../components/TextMarkup/TextMarkup";
import st from "./DragWords.module.scss";

export default function DragWords(props) {
  const [words, setWords] = useState([])
  const [selected, setSelected] = useState([])

  const [touchIndex, setTouchIndex] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  let dragged, dragIndex

  const onDragStartHandler = (e, i) => {
    dragged = e.target
    dragIndex = i
    e.target.classList.add('onDrag')
  }

  const onDragEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const onDragEnterHandler = e => {
    e.target.classList.add(st.onDragOver)
  }

  const onDragLeaveHandler = e => {
    e.target.classList.remove(st.onDragOver)
  }

  const onDragOverHandler = e => {
    // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
    if (dragged)
      // prevent default to allow drop
      e.preventDefault()
  }

  const onTouchStartHandler = (e, i) => {
    setIsTouched(true)
    e.target.classList.add('onDrag')
    setTouchIndex(i);
  }

  const onTouchEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  // I had a scenario where an HTML template was generated by a dummy teacher,
  // yet I wanted to leverage React for the parts I did have control over
  const htmlParser = new Parser(React)
  const processNodeDefinitions = new ProcessNodeDefinitions()
  const processingInstructions = [
    // Processing for my-contenteditable-component
    {
      shouldProcessNode: node => node.name === 'my-drop-component',
      processNode: node => {
        const index = +node.attribs['data-index']
        return props.studentAnswer ?
          <span className={cn({
            [st.dropZone]: true,
            [st.dropZone_right]: props.studentAnswer.result[index],
            [st.dropZone_wrong]: !props.studentAnswer.result[index],
          })}>
            {props.studentAnswer.answer.split('|')[index]}
          </span>
          :
          props.answers[index] ?
            <span className={st.dropZone}
                  onTouchStart={() => setIsTouched(false)}
                  onClick={() => {
                    if (isTouched) {
                      setTouchIndex(null)
                      return
                    }
                    let answ = [...props.answers]
                    answ[index] = ''
                    let arr = [...words]
                    setWords([...arr, props.answers[index]])

                    const idxSelected = selected?.findIndex(item => item.word === props.answers[index] && item.state);

                    if (idxSelected >= 0) {
                      const newSelecteds = [...selected]
                      newSelecteds[idxSelected].state = false
                      setSelected(newSelecteds)

                      const isAnswer = !newSelecteds.some(item => item.state === false)
                      props.handleAnswers(answ, isAnswer)
                    }
              }}>
              {props.answers[index]}
            </span>
            :
            <span className={st.dropZone}
                  onDragEnter={onDragEnterHandler}
                  onDragLeave={onDragLeaveHandler}
                  onDragOver={onDragOverHandler}
                  onDrop={e => {
                    onDropOrTouchHandler(e, index)
                  }}
                  onTouchStart={e => onDropOrTouchHandler(e, index)}
            />
      }
    },
    // Default processing
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]
  const replaceBrackets = str => {
    let index = 0
    return str.replaceAll('[]', () => `<my-drop-component data-index="${index++}"></my-drop-component>`)
  }
  const ReactElement = htmlParser.parseWithInstructions(
    `<div>${replaceBrackets(props.items.text)}</div>`,
    () => true,
    processingInstructions
  )

  const onDropOrTouchHandler = ({ target }, index) => {
    if(touchIndex === null && !dragged) return
    target.classList.remove(st.onDragOver)
    if (isTouched || dragged) {
      const newSelecteds = [...selected]
      const wordIndex = isTouched ? touchIndex : dragIndex
      newSelecteds[wordIndex].state = true
      setSelected(newSelecteds)

      const wl = newSelecteds.filter(item => !item.state).map(item => item.word)
      setWords([...wl])

      const arr = props.answers.slice()
      arr[index] = selected[wordIndex].word

      const fieldsCount = (props.items.text.match(/\[]/g) || []).length
      const enteredAnswersCount = arr.filter(el => el !== "" || el === undefined).length

      props.handleAnswers(arr, fieldsCount === enteredAnswersCount)
    }
  }

  useEffect(() => {
    const answers = props.items.answer_mix.split('|')
    setWords(answers)
    setSelected(answers.map(item => ({ word: item, state: false })))
  }, [props.items])

  useEffect(() => {
    let sAnswerText = props.items.text
    props.answers.forEach(answer => {
      sAnswerText = sAnswerText.replace('[]', `[${answer}]`)
    })

    if (!props.answers.length && !words.length) {
      const answers = props.items.answer_mix.split('|');
      setWords(answers)
      setSelected(answers.map(item => ({ word: item, state: false })))
    }
  }, [props.answers])

  /*const dropZone = index => props.studentAnswer ?
    <span className={cn({
      [st.dropZone]: true,
      [st.dropZone_right]: props.studentAnswer.result[index],
      [st.dropZone_wrong]: !props.studentAnswer.result[index],
    })}>
      {props.studentAnswer.answer.split('|')[index]}
    </span>
    :
    props.answers[index] ?
      <span
        className={st.dropZone}
        onTouchStart={() => setIsTouched(false)}
        onClick={() => {
          if (isTouched) {
            setTouchIndex(null)
            return
          }
          let answ = [...props.answers]
          answ[index] = ''
          let arr = [...words]
          setWords([...arr, props.answers[index]])

          const idxSelected = selected?.findIndex(item => item.word === props.answers[index] && item.state);

          if(idxSelected >= 0) {
            const newSelecteds = [...selected]
            newSelecteds[idxSelected].state = false
            setSelected(newSelecteds)

            const isAnswer = !newSelecteds.some(item => item.state === false)
            props.handleAnswers(answ, isAnswer)
          }
        }}>
      {props.answers[index]}
    </span>
    :
    <span
      className={st.dropZone}
      onDragEnter={onDragEnterHandler}
      onDragLeave={onDragLeaveHandler}
      onDragOver={onDragOverHandler}
      onDrop={e => {onDropOrTouchHandler(e, index)}}
      onTouchStart={e => onDropOrTouchHandler(e, index)}
    />*/

  /*const aText = props.items.text.split('[]') || []*/

  return (
    <TextMarkup>
      <div className={st.dragWords}>
        {!props.studentAnswer &&
          <div className={st.wordsBox}>
            {selected.map(({word, state}, index) => (
              <span
                key={index}
                draggable
                onDragStart={e => onDragStartHandler(e, index)}
                onDragEnd={onDragEndHandler}
                onTouchStart={e => onTouchStartHandler(e, index)}
                onTouchEnd={onTouchEndHandler}
                className={state ? st.selected : ''}
              >
              <div className={st.dote}>
                <div/>
              </div>
                {word}
            </span>
            ))}
          </div>
        }
        <div className={st.text}>
          {ReactElement}
          {/*<p>
            <span dangerouslySetInnerHTML={{__html: aText.shift()}}/>
            {aText.map((part, index) =>
              <Fragment key={index}>
                {dropZone(index)}
                <span dangerouslySetInnerHTML={{__html: part.replaceAll('<div>', '<br>')}}/>
              </Fragment>
            )}
          </p>*/}
        </div>
      </div>
    </TextMarkup>
  )
}
