import React from "react";
import { ReactComponent as InfoWhite } from "components/ContentBox/img/info-white.svg";
import MainSelector from "components/ContentBox/Global/GlobalModalSingle/templates/MainSelector";
import { localisationMaster } from "Localisation/Localisation";
import {classNames as cn} from "helpers";
import "./Result.scss";

const Result = ({ form, changeForm, validate, resultRef }) => (
  <div className="placementTestBox" ref={resultRef}>
    <div className="placementTestCreate__result">
      <div className="placementTestBox__mainBoxLine">
        <p className="placementTestBox__title">
          {localisationMaster(
            "text_21",
            "c/tests/placement-test/create-widget"
          )}
        </p>
      </div>
      <div className="placementTestBox__mainBoxLine">
        <p className="placementTestBox__subtitle">
          {localisationMaster(
            "text_22",
            "c/tests/placement-test/create-widget"
          )}
          :
        </p>
        <textarea
          onChange={(e) => changeForm(e.target.value, "html_body")}
          value={form.html_body}
          className={cn({
            "placementTestBox__textarea": true,
            "placementTestBox__textarea--error":  validate("html_body")
          })}
        ></textarea>
      </div>
      <div className="placementTestBox__mainBoxLine">
        <p className="placementTestBox__subtitle">
          {localisationMaster(
            "text_23",
            "c/tests/placement-test/create-widget"
          )}
          :
        </p>
        <p className="placementTestBox__text">
          {localisationMaster(
            "text_24",
            "c/tests/placement-test/create-widget"
          )}
        </p>
        <textarea
          onChange={(e) => changeForm(e.target.value, "answers_unit")}
          value={form.answers_unit}
          className={cn({
            "placementTestBox__textarea": true,
            "placementTestBox__textarea--error":  validate("answers_unit")
          })}
        ></textarea>
      </div>
      <MainSelector
        className="placementTestBox__sample"
        component={() => <InfoWhite />}
      >
        <p>
          {localisationMaster(
            "text_25",
            "c/tests/placement-test/create-widget"
          )}
          :
        </p>
        <p>
          {localisationMaster("table-row-title-1", "tests/widget-clients")}:{" "}
          {"{{full_name}}"}
        </p>
        <p>
          {localisationMaster("name_3", "tests/widget-clients")}: {"{{dob}}"}
        </p>
        <p>
          {localisationMaster(
            "field_1",
            "c/tests/placement-test/create-widget"
          )}
          : {"{{age}}"}
        </p>
        <p>E-mail: {"{{email}}"}</p>
        <p>
          {localisationMaster(
            "field_2",
            "c/tests/placement-test/create-widget"
          )}
          : {"{{age}}"}: {"{{test_date}}"}
        </p>
        <p>
          {localisationMaster(
            "link_text",
            "c/tests/placement-test/create-widget"
          )}{" "}
          &laquo;
          {localisationMaster(
            "field_3",
            "c/tests/placement-test/create-widget"
          )}
          &raquo;: {"{{signup_link}}"}
        </p>
        <p>
          {localisationMaster(
            "link_text",
            "c/tests/placement-test/create-widget"
          )}{" "}
          &laquo;
          {localisationMaster(
            "field_4",
            "c/tests/placement-test/create-widget"
          )}
          &raquo;: {"{{new_attempt}}"}
        </p>
        <p>
          {localisationMaster(
            "link_text",
            "c/tests/placement-test/create-widget"
          )}{" "}
          &laquo;
          {localisationMaster(
            "field_5",
            "c/tests/placement-test/create-widget"
          )}
          &raquo;: {"{{online booking}}"}
        </p>
        <p>
          {localisationMaster(
            "field_6",
            "c/tests/placement-test/create-widget"
          )}
          : {"{{correct_answers}}"}
        </p>
        <p>
          {localisationMaster(
            "field_7",
            "c/tests/placement-test/create-widget"
          )}
          : {"{{result_level}}"}
        </p>
      </MainSelector>
    </div>
  </div>
);

export default Result;
