import React from 'react';
import style from "../ReviewInfoBlock.module.scss";

const DiscussionButton = ({scrollToBlock}) => {
    return (
        <div>
            <button className={style.btn} onClick={scrollToBlock}>Discussion</button>
        </div>
    );
};

export default DiscussionButton;