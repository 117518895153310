import {action, computed, makeObservable, observable} from 'mobx';
import Stores from 'Stores';
import { superAxiosRequestEasy } from 'axiosApi';
import { calcPayDate, calcTimeDiff } from 'components/Modules/Slots/utils';
import LocalStorage from 'services/LocalStorage';
import { getOptions, ActionType } from "Stores/slotsNewRecordOnlineStore";

const initialGuardianData = {
  first_name: { mandatory: true },
  last_name: { mandatory: true },
  phone: { mandatory: true },
  email: { mandatory: true },
  birthday: { mandatory: true },
  sex: { mandatory: true },
  inn: { mandatory: true },
};

class SlotsTeacherRecordOnlineStore {
  teacher = null;
  programs = [];
  ages = [];
  locations = [];
  selectedLocation = null;
  selectedProgram = null;
  selectedAge = null;
  dates = [];
  selectedDates = new Set();
  slots = [];
  selectedSlots = new Set();
  employees = [];
  clientData = null;
  toggles = null;
  paymentTime = null;
  paymentType = null; // 1 - платный, 2 - по смс, 3 - через админа, 4 - по емайл, 5 - без подтверждения
  payDate = null;
  banks = [];
  bank = null;
  selectedEmployee = null;
  isGuardian = false;
  guardianData = initialGuardianData;
  timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  slotClientID = null;
  order = null;
  paymentStep = 1;
  isOrderPayed = false;

  constructor() {
    makeObservable(this, {
      teacher: observable,
      selectedLocation: observable,
      selectedProgram: observable,
      selectedAge: observable,
      dates: observable,
      selectedDates: observable,
      slots: observable,
      selectedSlots: observable,
      employees: observable,
      clientData: observable,
      toggles: observable,
      paymentTime: observable,
      paymentType: observable, // 1 - платный, 2 - по смс, 3 - через админа, 4 - по емайл, 5 - без подтверждения
      payDate: observable,
      isGuardian: observable,
      banks: observable,
      bank: observable,
      timeZone: observable,
      guardianData: observable,
      selectedEmployee: observable,
      order: observable,
      paymentStep: observable,
      isOrderPayed: observable,
      programs: observable,
      ages: observable,
      locations: observable,

      getTeacherInfo: action.bound,
      resetData: action.bound,
      setData: action.bound,
      getDatesFromServer: action.bound,
      getSlotsFromServer: action.bound,
      setClientData: action.bound,
      submitData: action.bound,
      checkPaymentStatus: action.bound,
      confirmCode: action.bound,

      isReadyToLoadCalendar: computed,
      sum: computed,
      isSubmitAllowed: computed,
    });
  }

  setData = (data, type) => {
    this[type] = data;
  }

  resetData = type => {
    switch (type) {
      case ActionType.resetAll:
        this.clientData = null;
        this.selectedSlots.clear();
        this.selectedDates.clear();
        this.slots = [];
        this.bank = null;
        this.paymentType = null;
        this.slotClientID = null
        this.order = null;
        this.paymentStep = 1;
        this.isOrderPayed = false;
        break;
      case ActionType.resetSlots:
        this.slots = [];
        this.selectedSlots.clear();
        this.paymentType = null;
        this.paymentStep = 1;
        this.isOrderPayed = false;
        break;
      case ActionType.resetSelectedSlots:
        this.selectedSlots.clear();
        break;
      case ActionType.resetGuardianData:
        this.guardianData = initialGuardianData;
        break;
      default:
        return;
    }
  }

  getTeacherInfo = ({ onFail = () => { }, onSucces = () => { }, link }) => {
    superAxiosRequestEasy({
      method: 'get',
      link: 'online_schedule/client/teacher',
      params: { url: `${Stores.baseLink()}/teacher-record-online-test/${link}` }
    }).then(({ data }) => {
      this.teacher = {
        ...data,
        ...(data.avatar && { avatar: `${Stores.baseLink()}/${data.avatar}` })
      };
      this.selectedEmployee = this.teacher.id;
      this.locations = getOptions(data.locations);
      this.ages = getOptions(data.ages);
      this.programs = getOptions(data.programs);
      onSucces(data);
    })
      .catch(onFail)
  }

  getDatesFromServer = (date, onFinally, onFail) => {
    const apiConf = {
      method: 'get',
      link: 'online_schedule/client',
      params: {
        company: this.teacher.company.id,
        location: this.selectedLocation.value,
        age: this.selectedAge.value,
        program: this.selectedProgram.value,
        date_from: date,
        step: 2,
        employee_id: this.teacher.id,
      },
    };
    superAxiosRequestEasy(apiConf)
      .then(({ data }) => this.dates = data)
      .catch(() => onFail())
      .finally(() => onFinally(false));
  }

  getSlotsFromServer(dateIndex) {
    const apiConf = {
      method: 'get',
      link: 'online_schedule/client',
      params: {
        company: this.teacher.company.id,
        location: this.selectedLocation.value,
        age: this.selectedAge.value,
        program: this.selectedProgram.value,
        date_from: this.dates[dateIndex].date_at,
        step: 3,
        employee_id: this.teacher.id,
      },
    };
    superAxiosRequestEasy(apiConf)
      .then(({ data }) => {
        const { banks, client_data, employees, slot_data, payment_time, payment_type } = data;

        this.employees = employees;
        this.clientData = client_data;
        this.toggles = { 'is_online': slot_data[0]?.is_online, 'is_school': !slot_data[0]?.is_online };
        this.slots = slot_data;
        this.paymentTime = payment_time;
        this.paymentType = payment_type;
        this.payDate = calcPayDate(payment_time, this.timeZone);
        this.banks = banks;
        this.bank = banks[0].id;
      });
  }

  setClientData(value, type, isGuardian) {
    isGuardian
      ? this.guardianData[type] = { ...this.guardianData[type], value }
      : this.clientData[type] = { ...this.clientData[type], value };
  }

  confirmCode(code, target, setAttempts) {
    const body = {
      code,
      target,
    };
    superAxiosRequestEasy({
      method: 'put',
      link: `online_schedule/slots/clients/${this.slotClientID}`
    }, body)
      .then(({ data }) => {
        data.result ? this.paymentStep = 3 : setAttempts((prev) => prev - 1);
      })
  }

  checkPaymentStatus() {
    superAxiosRequestEasy({
      method: 'get',
      link: `payments/check_order/${this.order}`,
    })
      .then(({ data }) => this.isOrderPayed = data.paid_status);
  }

  submitData(onFail) {
    let client_data = {};
    for (let key in this.clientData) {
      client_data[key] = this.clientData[key].value;
    }
    client_data.date_of_birth = this.clientData.birthday.value;
    delete client_data.birthday;

    if (!this.clientData.inn.value) {
      delete client_data.inn;
    }

    if (client_data.level) {
      client_data.start_level = client_data.level;
      delete client_data.level
    }

    const data = {
      age: this.selectedAge.value,
      program: this.selectedProgram.value,
      payment_type: this.paymentType,
      payment_date: this.payDate.dateUTC,
      payment_time: this.payDate.timeUTC,
      payment_comment: 'Устное тестирование',
      is_online: this.toggles.is_online,
      employee_slot: this.employees.find((item) => item.id === this.selectedEmployee).employee_booked_slot_id,
      slot_id: this.slots.find((item) => item.id === [...this.selectedSlots.values()][0]).id,
      client_data,
    };

    if (this.paymentType === 1) {
      data.bank = this.bank;
      data.sum = this.sum * 100;
    }

    if (this.paymentType === 2) {
      data.phone = this.clientData.phone.value;
    }

    if (this.paymentType === 4) {
      data.email = this.clientData.email.value;
    }

    superAxiosRequestEasy({ method: 'post', link: 'online_schedule/client' }, data)
      .then(({ data }) => {
        this.slotClientID = data.slot_client_id;
        if (this.paymentType === 1) {
          this.order = data.order;
          this.paymentStep = 2;
          if (this.bank === 1) {
            superAxiosRequestEasy({
              method: 'get',
              link: `payments/bill_payment_kkb/${data.order}`
            }).then((response) => window.location.href = response.config.url);
          }
        }
        if (this.paymentType === 2 || this.paymentType === 4) {
          this.paymentStep = 2;
        }
        if (this.paymentType === 3 || this.paymentType === 5) {
          this.paymentStep = 3;
        }
      })
      .catch(({ response }) => onFail(response?.data?.error));
  }

  get isReadyToLoadCalendar() {
    return Boolean(this.selectedLocation && this.selectedProgram && this.selectedAge);
  }

  get sum() {
    const { price, price_type } = this.employees.find((item) => item.id === (this.selectedEmployee || this.employees[0].id));
    const { start_at, finish_at } = this.slots.find(({ id }) => id === [...this.selectedSlots.values()][0]);
    const timeDiff = calcTimeDiff(start_at, finish_at);
    const multiplier = price_type === '15 минут' ? Math.ceil(timeDiff / 15) : 1;
    return price * multiplier / 100;
  }

  get isSubmitAllowed() {
    for (let key in this.clientData) {
      if (this.clientData[key].mandatory && !this.clientData[key]?.value) {
        return false;
      }
    }

    if (this.isGuardian) {
      for (let key in this.guardianData) {
        if (this.guardianData[key].mandatory && !this.guardianData[key]?.value) {
          return false;
        }
      }
    }

    const rules = [
      this.employees.length > 0 && this.selectedEmployee === null,
      this.clientData.inn?.value && this.clientData.inn?.value?.length !== 12,
      this.isGuardian && this.guardianData.inn?.value && this.guardianData.inn?.value?.length !== 12,
      this.paymentStep > 1,
    ];

    return rules.every((item) => item !== true);
  }
}

export default new SlotsTeacherRecordOnlineStore();
