import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import ClientFields from './ClientFields';
import { scrollToRef } from 'helpers';

function ClientData({
  isGuardian,
  clientData,
  guardianData,
  onSetClientData,
  onSetIsGuardian,
  onResetData,
  isOverwrite = false,
  withSearch = true,
  asClient,
}) {
  const ref = useRef(null);

  const handleToggleChange = () => {
    if (isGuardian) {
      onResetData();
    }
    onSetIsGuardian(!isGuardian);
  };

  useEffect(() => {
    if (ref) {
      scrollToRef(ref, 100);
    }
  }, []);

  return (
    <Paper ref={ref}>
      <SectionTitle title='Данные клиента' secondary />
      <InputToggle
        label='Ребенок, регистрирует опекун, нет e-mail'
        selected={isGuardian}
        onChange={handleToggleChange}
        withMargin
      />
      { isGuardian &&
        <ClientFields
          type='guardian'
          data={guardianData}
          onSetData={onSetClientData}
          withSearch={withSearch}
        />
      }
      <ClientFields
        type={isGuardian ? 'client' : null}
        data={clientData}
        onSetData={onSetClientData}
        isOverwrite={isOverwrite}
        withSearch={withSearch}
        asClient={asClient}
      />
    </Paper>
  );
}

export default observer(ClientData);
