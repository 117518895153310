import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import Store from "Stores"
import StudyTestResultStore from "Stores/studyTestResultStore"
import Context from "./resultContext";
import Loader from "components/UI/Loader/Loader";
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import ResultGenInfo from "./components/ResultGenInfo";
import ResultAnswers from "./components/ResultAnswers/ResultAnswers";
import st from "./TeacherResult.module.scss"
import {localisationMaster} from "Localisation/Localisation";


const TeacherResult = props => {
    const id = props.match.params.id
    const isClient = Store.isClient
    const time_zone = Store.userTimeZone
    const {result, fetchTestResult} = StudyTestResultStore

    useEffect(() => {
        fetchTestResult(id);
    }, []);

    const resultDate = new Date(`${result?.date_until} ${result?.finish_at}`); // время окончания теста
    const now = new Date(Date.now() + (new Date().getTimezoneOffset() + time_zone * 60) * 60 * 1000); // актуальное время по UTC
    const diff = new Date(resultDate - now);

    return result ?
            result?.id ?
                <Context.Provider value={{isClient}}>
                    <ContentHeader
                        title={`${localisationMaster('title', 'tests/past-tests/test-results')} ${result.name} (${localisationMaster('text_17', 'study/test', 'result')}: ${result.attempt})`}
                        breadСrumbs={isClient ? [
                            {title: 'Ваши тесты', route: '/c/study/my-tests'},
                            {title: `тест: ${result.name}`, route: `/study/my-test/${result.test_id}`}
                        ] : [
                            {title: 'Тесты, назначенные клиентам', route: '/c/study/purpose-test'},
                            {title: `Страница теста: ${result.name}`, route: `/study/test/${result.test_id}`}
                        ]}
                    />
                    <div className={st.page}>
                        <ResultGenInfo result={result}/>
                        {diff < 0 && <ResultAnswers/>} {/* если тест прошел то показывать результаты теста */}
                    </div>
                </Context.Provider>
                :
                <div>{localisationMaster('text_18', 'study/test', 'result')}</div> :
            <Loader/>
}

export default observer(TeacherResult)
