import React, {useEffect, useState} from 'react';
import style from './LessonLogs.module.scss'
import Log from "./Log/Log";
import {superAxiosRequest} from "../../../../axiosApi";

const LessonLogs = ({context, studentId, isHomeWork, hash}) => {

    const [logs, setLogs] = useState([])

    const fetchLogs = () => {
        if (context === 'student') {
            superAxiosRequest({
                method: 'get',
                link: isHomeWork === true ? `ebooks/homework_review_logs` : `ebooks/lesson_review_logs`,
                params: {
                    homework_hash: hash
                }
            }).then((req) => {
                setLogs(req.data.result)
            })
        } else {
            superAxiosRequest({
                method: 'get',
                link: isHomeWork === true ? `ebooks/homework_review_logs` : `ebooks/lesson_review_logs`,
                params: {
                    student: studentId,
                    homework_hash: hash
                }
            }).then((req) => {
                setLogs(req.data.result)
            })
        }
    }

    useEffect(() => {
        fetchLogs()
    }, [studentId]);

    return (
        <div className={style.logsContainer}>
            {
                logs.length > 0 ?
                logs.map((el, index) => {
                    return <Log key={index} type={el.event_type} date={el.created_at} teacher={el?.teacher}/>
                }) : <div>Logs not found</div>
            }
        </div>
    );
};

export default LessonLogs;