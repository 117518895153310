import React, { useEffect, useState } from 'react';
import { classNames as cn } from 'helpers';
import { localisationMaster } from "Localisation/Localisation";
import PropTypes from 'prop-types';
import styles from './Tooltip.module.scss';

function Tooltip({text, isAvailable, isSchool, isOnline, isGroup, isLink, isShort}) {
  const [classes, setClasses] = useState(styles.tooltip);

  useEffect(() => {
    setClasses(cn({
      [styles.tooltip]: true,
      [styles.show]: true,
    }));
  }, []);

  return (
    <div className={classes}>
      <p className={styles.tooltip_name}>{text}</p>
      <p className={styles.tooltip_text}>{localisationMaster('text_7', 'teacher_schedule')}: <span>{isAvailable ? localisationMaster('button_1', 'alert').toLowerCase() : localisationMaster('button_2', 'alert').toLowerCase()}</span></p>
      {!isShort &&
      <>
        <p className={styles.tooltip_text}>
          {isSchool && isOnline && localisationMaster('text_8', 'teacher_schedule')}
          {!isSchool && isOnline && localisationMaster('text_9', 'teacher_schedule')}
          {isSchool && !isOnline && localisationMaster('text_10', 'teacher_schedule')}
        </p>
        <p className={styles.tooltip_text}>{isGroup ? localisationMaster('text_11', 'teacher_schedule') : localisationMaster('text_12', 'teacher_schedule')}</p>
        <p className={styles.tooltip_text}>
          {localisationMaster('zoom_link', 'teacher_schedule')}: {isLink ? localisationMaster('there_is', 'teacher_schedule') : localisationMaster('button_2', 'alert').toLowerCase()}
        </p>
      </>
      }
    </div>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool,
  isSchool: PropTypes.bool,
  isGroup: PropTypes.bool,
  isLink: PropTypes.bool,
  isShort: PropTypes.bool,
};

export default Tooltip;
