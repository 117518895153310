import React, { useState, Fragment } from 'react';
import Avatar from '../Avatar/Avatar';
import styles from './Menu.module.scss';
import EditBox from '../EditBox/EditBox';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import { ReactComponent as RuIcon } from '../../img/ru.svg';
import { ReactComponent as EnIcon } from '../../img/en.svg';
import { ReactComponent as KzIcon } from '../../img/kz.svg';
import { classNames as cn } from "helpers";
import { logoutUser, superAxiosRequest } from 'axiosApi';
import { Link } from 'react-router-dom';
import { localisationMaster } from "Localisation/Localisation";
import Stores from 'Stores';
import { observer } from "mobx-react";
import modalStore from "Stores/modalStore";
import Clock from 'components/ContentBox/Global/Clock/Clock';
import { dict, translate as loc } from "Localisation";
import LocalStorage from "services/LocalStorage";
import { LanguageContext } from 'components/HeadBox/HeadBox';
import { useContext } from 'react';
import asideModalStore from 'Stores/asideModalStore';

const languages = [
    { text: 'English', code: 'EN', Icon: () => <EnIcon /> },
    { text: 'Русский', code: 'RU', Icon: () => <RuIcon /> },
    { text: 'Казахский', code: 'KZ', Icon: () => <KzIcon /> },
];

const Menu = ({
    className = '',
    link,
    closeNavbar,
    openPasswordModal,
}) => {
    const [isLanguages, setIsLanguages] = useState(false);

    const ud = LocalStorage.get('ud');
    const user_data = ud?.user_data;
    const id = user_data?.id;
    const userData = user_data?.profile;
    const role = user_data?.groups[0]?.name;
    const linkProfile = role === 'clients' ? `/account_clients/${id}/` : `/account/${id}/`;
    const BrowserTimeZone = new Date().getTimezoneOffset() / 60;
    const lang = userData?.localization;
    const { translate } = useContext(LanguageContext);

    const updateProfile = (user_data) => {
        const newProfile = { ...ud, user_data };
        LocalStorage.set('ud', newProfile);
        window.location.reload();
    }

    const timeZone = {
        name: 'time_zone',
        link: 'accounts/account_update/' + id,
        button: localisationMaster('button-save', 'index', 'sidebar_main'),
        title: localisationMaster('time-zone', 'index', 'sidebar_main'),
        type: 'SelectorAndInput',
        active_inputs: ['select'],
        data: 'json',
        name_select: 'time_zone',
        pl_select: '',
        select_array: Stores.timeZones,
        ready_selected: +(BrowserTimeZone > 0 ? "-" + BrowserTimeZone : '+' + (-BrowserTimeZone)),
        select_array_id: +(BrowserTimeZone > 0 ? "-" + BrowserTimeZone : '+' + (-BrowserTimeZone)),
        select_is_searchable: true,
        is_put: true,
        func: data => updateProfile(data)
    }

    const changeLanguage = (lang) => {
        let qJson = {
            "localization": lang
        }
        let apiConf = {
            method: 'put',
            link: 'accounts/account_update/' + id,
        }
        superAxiosRequest(apiConf, qJson).then(({ data }) => {
            updateProfile(data);

            if (data?.user_data?.profile?.localization === lang)
                document.location.replace(window.location.href);
        })

    }

    const editEmployeeProfile = () => {
        asideModalStore.activity({
            type: 'customerData',
            title: 'Personal Information',
            button: localisationMaster('text_54', 'groups/all-groups'),
            is_put: true,
            link: `accounts/account_update/${id}`,
            onSubmitSuccess: data => updateProfile(data),
            valid: {
              date_of_birth: 'required',
              email: 'required',
              phone: 'required',
              first_name: 'required',
              last_name: 'required',
              inn: 'required',
            }
        },
        {
            last_name: userData.last_name,
            first_name: userData.first_name ,
            father_name: userData.father_name || '',
            email: user_data.email,
            inn: userData.inn,
            date_of_birth: userData.date_of_birth,
            phone: userData.phone,
            mobile_phone: userData.mobile_phone,
            whatsapp: userData.whatsapp,
            telegram: userData.telegram,
        }
        );
        closeNavbar();
    };

    const editClientProfile = () => {

        const ms = {
            name: 'client_info',
            link: 'accounts/clients/' + userData.id,
            button: localisationMaster('text_54', 'groups/all-groups'),
            title: localisationMaster('text_3', 'online-schedule/clients-after-online'),
            type: 'client_info',
            client_id: id,
            profile: id,
            is_put: true,
            isResponsive: true,
            valid: {
                first_name: 'required',
                last_name: 'required',
                inn: 'required',
                date_of_birth: 'required',
                phone: 'required',
                email: 'required',
            },
            onSubmitSuccess: updateProfile,
        }

        asideModalStore.activity(ms)
    };

    const openModalStatus = () => {
        const ms = {
            title: localisationMaster('text-6', 'modal-profile'),
            button: localisationMaster('button-two', 'modal-profile'),
            type: 'quad',
            fields: [{
                type: 'select',
                apiLink: 'accounts/employers_catalog',
                name: 'vacation_status',
                placeholder: loc(dict.main_page.text_47),
                apiGetResult: data => data.vacation_statuses.items
            }],
            is_put: true,
            link: 'accounts/account_update/' + id,
            func: data => updateProfile(data),
        }
        closeNavbar();
        modalStore.activity(ms, {vacation_status: userData.vacation_status?.id})
    }


    return (
        <div
            className={cn({
                [styles.menu]: true,
                [className]: className,
            })}
        >
            <div className={styles.menuProfile}>
                <div className={styles.menuProfile__avatar}>
                    <Avatar
                        link={link}
                        first_name={userData?.first_name}
                        last_name={userData?.last_name}
                    />
                </div>
                <div className={styles.menuProfile__detail}>
                    <p>{Stores.transliterate(userData?.full_name)}</p>
                    <p>{role}</p>
                </div>
            </div>
            <div className={styles.menuNav}>
                <Link
                    to={linkProfile}
                    className={styles.menuNav__item}
                    onClick={closeNavbar}
                >
                    <p className={styles.menuNav__title}>
                        {translate.my_profile}
                    </p>
                </Link>
                <div
                    onClick={Stores.isClient ? editClientProfile : editEmployeeProfile}
                    className={styles.menuNav__item}
                >
                    <p className={styles.menuNav__title}>
                        {translate.my_settings}
                    </p>
                </div>
                <div
                    onClick={() => {
                        closeNavbar();
                        openPasswordModal();
                    }}
                    className={styles.menuNav__item}
                >
                    <p className={styles.menuNav__title}>
                        {translate.my_password}
                    </p>
                </div>
                {Stores.Rules.checkRule('user_is_active', ['view', 'view_own']) && (
                    <div
                        onClick={openModalStatus}
                        className={styles.menuNav__item}
                    >
                        <p className={styles.menuNav__title}>
                            {translate.my_status}
                        </p>
                        <EditBox className={styles.menuNav__box}>
                            <p>{userData?.vacation_status?.name ?? 'no status'}</p>
                            <div className={`${styles.menuNav__nothing} ${styles.menuNav__nothing_active}`}>
                                <EditIcon />
                            </div>
                        </EditBox>
                    </div>
                )}
            </div>
            <ul className={styles.menuNav}>
                <li
                    className={`${styles.menuNavLanguage} ${styles.menuNav__item}`}
                    onClick={() => setIsLanguages(!isLanguages)}
                >
                    <p className={styles.menuNav__title}>{translate.language}</p>
                    <EditBox className={styles.menuNavLanguage__box}>
                        {languages
                            .filter(({ code }) => code === lang)
                            .map(({ text, code, Icon }, idx) =>
                                <Fragment key={idx}>
                                    <p>{code}</p>
                                    <Icon />
                                </Fragment>
                            )
                        }
                    </EditBox>
                    {isLanguages && (
                        <div className={styles.menuNavLanguage__menu}>
                            {languages
                                .filter(({ code }) => code !== lang)
                                .map(({ text, Icon, code }, idx) =>
                                    <div
                                        key={text}
                                        onClick={() => changeLanguage(code)}
                                        className={cn({
                                            [styles.menuNavLanguage__item]: true,
                                            [styles.menuNavLanguage__item_active]: idx === 0,
                                        })}
                                    >
                                        <Icon />
                                        <p>{text}</p>
                                    </div>
                                )}
                        </div>
                    )}
                </li>
                <li className={styles.menuNav__item} onClick={() => {
                    modalStore.activity(timeZone);
                    closeNavbar();
                }}>
                    <p className={styles.menuNav__title}>{translate.time}</p>
                    <div >
                    <EditBox className={`${styles.menuNav__box} ${styles.menuNav__boxTimezone}`}>
                        <div><Clock time_zone={userData?.time_zone} /></div>
                        <div className={`${styles.menuNav__nothing} ${styles.menuNav__nothing_active}`}>
                            <EditIcon />
                        </div>
                    </EditBox>
                    </div>
                </li>
            </ul>
            <div className={styles.menuExit} onClick={logoutUser}>
                <p>{translate.sign_out}</p>
            </div>
        </div>
    )
}

export default observer(Menu);