export const adaptOnlineToggle = () => ({
  label: 'Включить онлайн запись',
  name: 'allow-online',
});

export const adaptOnlineSystem = () => ([
  {id: 1, name: 'online-system', label: 'ZOOM'},
  {id: 2, name: 'online-system', label: 'Google Meet'},
  {id: 3, name: 'online-system', label: 'Microsoft Teams'},
]);

export const adaptSlotTypeOptions = (isComment, isAccessible, color) => ([
  {
    name: 'allow-comment',
    label: 'Комментарий',
    value: isComment,
  },
  {
    name: 'client-access',
    label: 'Доступ для клиентов',
    value: isAccessible,
  },
  {
    name: 'color',
    label: 'Цвет',
    value: color,
  },
]);

export const adaptRegistrationToggles = () => ([
  {
    id: Math.random(),
    name: 'choose_teacher',
    label: 'Клиент может выбирать преподавателя при регистрации на слот',
    description: 'Разрешить показывать всех доступных преподавателей на интересуемый клиентом день и время',
  },
  {
    id: Math.random(),
    name: 'client_multiple_register',
    label: 'Разрешить регистрацию клиента на несколько слотов в день',
    description: 'Один клиент может зарегистрироваться на несколько слотов в течении одного дня',
  },
  {
    id: Math.random(),
    name: 'client_time_register',
    label: 'Разрешить регистрацию клиента на пересекающиеся по времени слоты',
    description: 'Один клиент может зарегистрироваться на несколько слотов в одно и тоже время',
  },
]);

export const adaptAmoInputs = () => ([
  {
    id: Math.random(),
    name: 'amoSlug',
    label: 'SLUG, адрес отправления данных',
    placeholder: 'https://amoconnect.ru/amo-interpress/api/slug/placement-success'
  },
  {
    id: Math.random(),
    name: 'amoUrl',
    label: 'URL, откуда поступают заявки (если будет незаполнено отправится URL страницы виджета)',
    placeholder: 'https://www.interpress.kz/dev-widget-v2/'
  },
]);

export const adaptAmoToggles = () => ([
  {name: 'full_name', label: 'Имя и фамилия клиента'},
  {name: 'email', label: 'Email клиента'},
  {name: 'phone', label: 'Телефон клиента'},
  {name: 'birthday', label: 'Дата рождения клиента'},
  {name: 'inn', label: 'ИИН/ИНН клиента'},
  {name: 'sex', label: 'Пол клиента'},
  {name: 'level', label: 'Уровень клиента по онлайн записи (если указывается)'},
  {name: 'date', label: 'Дата проведения онлайн записи с клиентом'},
  {name: 'comment', label: 'Комментарий к онлайн записи (если указывается)'},
  {name: 'teacher', label: 'Сотрудник проводивший онлайн запись с клиентом'},
  {name: 'add_field1', label: 'Дополнительное поле'},
  {name: 'add_field2', label: 'Дополнительное поле'},
  {name: 'add_field3', label: 'Дополнительное поле'},
]);
