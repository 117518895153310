import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from "Router/routes";
//import NewRecordWidget from './Widget-shedule/NewRecordWidget';

export default function WithoutAuth() {
    return (
        <div className="wrapper_soft">
            <Switch>
                {/*<Route    path='/pays/sms/widget-shedule/:name/:email/:phone/:day/:program/:age/' component={WidgetShedule} />*/}
                {/*<Route path='/pays/sms/widget-shedule/:name' component={NewRecordWidget}/>*/}
                {/*<Route path='/pays/pay/widget-shedule/:name' component={NewRecordWidget}/>*/}
              {routes.public.map(route => <Route {...route} key={route.path}/>)}
            </Switch>
        </div>
    )
}
