import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {superAxiosRequest} from 'axiosApi';
import Stores from 'Stores';
import groupStore from 'Stores/groupStore';
import modalStore from "Stores/modalStore";
import OpenHeader from "../OpenHeader";
import style from './TestResults.module.scss'
import TestResult from "./TestResult/TestResult";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import HeaderContentBox from "../HeaderContentBox";
import useLocalization from 'hooks/useLocalization';


const TestResults = props => {

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_tests',
    dict: {
      "save": "Save",
      "edit": "Edit",
      "delete": "Delete",
      "adding_personal_test_results": "Adding personal test results",
      "tests": "Tests",
      "general": "General",
      "total": "Total",
      "yr": "ж."
    }
  })

  let ms = {
    link: 'groups/interim_result_tests',
    button: translate.save,
    title: translate.adding_personal_test_results,
    type: 'groupTestResult',
    data: 'json',
    students: (props.students || []).map( item => ( {...item, full_name: Stores.transliterate(item.full_name) , father_name: Stores.transliterate(item.father_name), last_name: Stores.transliterate(item.last_name)})),
    func: data => {
      groupStore.updateGroupData({test_results: data.test_results})
    },
    valid: {
      category: 'required',
      description: 'required',
      student: 'required',
      test: 'required',
      test_scores: 'required',
    }
  }

  const menu = [
    {name: translate.edit, action: 'edit'},
    {name: translate.delete, action: 'delete'},
  ]

  const [open, setOpen] = useState(false)

  const addTest = () => {
    modalStore.activity(ms, {
      group: +props.gid,
    })
  }

  const actions = (action, e, item) => {
    switch (action) {
      case 'edit':
        modalStore.activity({
          ...ms,
          is_put: true,
          link: `${ms.link}/${item.id}`
        }, {
          ...item,
          group: +props.gid,
          teacher: item.teacher.id,
          student: item.student.id,
        })
        break;
      case 'delete':
        const apiCfg = {
          method: 'delete',
          link: `${ms.link}/${item.id}`
        }
        superAxiosRequest(apiCfg).then(res => groupStore.updateGroupData({test_results: res.data.test_results}))
        break;
      default:
        break;
    }
  }

  const groupedStudents = () => {
    const result = Object.groupBy(props.tests, (element) => element.student.id)
    return Object.keys(result).map(studentId => ({
      student: {
        ...result[studentId][0].student
      },
      data: result[studentId]
    }))
  }

  return (
    <HeaderContentBox className={style.testResults}>  
      <OpenHeader title={translate.tests}
                  onClick={() => setOpen(!open)}
                  link={`/groups/tests/${props.gid}`}
                  target={'_blank'}
                  addButton={!Stores.isClient ? addTest : null}/>
      {open && <>
        {
          props.tests.length > 0 ? <div className={style.list}>
            {groupedStudents().slice(0, 10).map((item, index) =>
              <TestResult item={item} key={index} menu={menu} actions={actions} translate={translate}/>
            )}
          </div> : <div className={style.empty}>
            <EmptyData/>
          </div>
        }
      </>}
    </HeaderContentBox>
  )
}

TestResults.propTypes = {
  gid: PropTypes.number,
  tests: PropTypes.arrayOf(PropTypes.object),
}

export default TestResults
