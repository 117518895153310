import React, {createRef, useEffect, useRef, useState} from 'react';
import {ReactSketchCanvas} from 'react-sketch-canvas';
import Stores from "../../../../../Stores";
import {ReactComponent as GoBackIcon} from 'components/UI/icons/arrow-go-back-line.svg';
import {ReactComponent as Eraser} from 'components/UI/icons/eraser-line.svg';
import {ReactComponent as Delete} from 'components/UI/icons/delete-bin-6-line.svg';
import {ReactComponent as PencilIcon} from 'components/UI/icons/pencil-line.svg';
import {ReactComponent as Restart} from 'components/UI/icons/system-Linerestart-line.svg';
import st from './DrawingBoard.module.scss'

const DrawingBoard = ({uuid, mode, showBoard, setShowBoard}) => {

    const canvasRef = createRef()
    const [paths, setPaths] = useState([])
    const [isDrawing, setIsDrawing] = useState(null)
    const [shouldUpdate, setShouldUpdate] = useState(false)

    const colours = [
        {id: 1, val: '#FFC700'},
        {id: 2, val: '#1ABC97'},
        {id: 3, val: '#1BE7FF'},
        {id: 4, val: '#FF0022'},
        {id: 5, val: '#C903FF'},
        {id: 6, val: '#55B1F3'},
    ];

    const [penWidth, setPenWidth] = useState(5)
    const [penColor, setPenColor] = useState('#55B1F3')

    const [showTools, setShowTools] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [tool, setTool] = useState("pencil");
    const [resetAll, setResetAll] = useState(false)

    const handleColorChange = (color) => {
        setPenColor(color.val)
        setTool("pencil")
        setShowTools(false)
        setShowColorPicker(false)
        canvasRef.current?.eraseMode(false)
    };

    const onBlurHandler = () => {
        setShowTools(false)
    };

    const handleClick = (...args) => {
        const isEraser = args[0] || false;
        canvasRef.current?.eraseMode(isEraser);
        setShowTools(true);
        const tool = args[1] || "pencil";
        setTool(tool)
    };

    const onClickShowColorPickerHandler = () => {
        setShowColorPicker(prevState => !prevState);
    }

    const handlePencilClick = () => {
        handleClick(false);
        setShowColorPicker(false);
        onBlurHandler();
    };

    const handleEraserClick = () => {
        canvasRef.current?.eraseMode(true);
        setTool("eraser")
        setShowColorPicker(false);
        onBlurHandler();
    };

    const handleRemoveAllClick = () => {
        sendMessageDrawing({type: 'clear_board', message: ''})
        canvasRef.current.resetCanvas();
        canvasRef.current?.eraseMode(false);
        setShowColorPicker(false);
        setTool("pencil")
        onBlurHandler();
    };


    const renderToolsPanel = () => (
        <div className={st.toolsPanel}>
            <div className={st.panelContainer}>
                <div onClick={onClickShowColorPickerHandler}>
                    <div className={st.circle} style={{backgroundColor: penColor}}></div>
                </div>
                <div className={st.verticalSeparator}></div>
                <div className={st.inputRange}>
                    <input
                        type="range"
                        min="0"
                        max="50"
                        value={penWidth}
                        onChange={(e) => setPenWidth(Number(e.target.value))}
                    />
                    <div>{penWidth}</div>
                </div>
            </div>
            <div className={st.separator}></div>
            <div onClick={handlePencilClick} className={st.tool}>
                <PencilIcon/>
                <div>Pencil</div>
            </div>
            <div className={st.separator}></div>
            <div onClick={handleEraserClick} className={st.tool}>
                <Eraser/>
                <div>Eraser</div>
            </div>
            {mode === 'teacher' &&
                <div onClick={handleRemoveAllClick} className={st.tool}>
                    <Delete/>
                    <div>Clear All</div>
                </div>
            }
        </div>
    );

    const renderColorPicker = () => (
        <div className={st.colorPickerContainer}>
            {colours.map((color) => (
                <div
                    key={color.id}
                    className={st.boxBorderCircle}
                    onClick={() => handleColorChange(color)}
                >
                    <div className={st.circle} style={{backgroundColor: color.val}}></div>
                </div>
            ))}
        </div>
    );

    const renderEditBlock = () => (
        <div className={st.editBlock}>
            <div>
                {tool === "pencil" ?
                    <PencilIcon
                        onClick={() => handleClick(false, "pencil")}
                        onBlur={() => onBlurHandler()}/>
                    : <Eraser
                        onClick={() => handleClick(true, "eraser")}
                        onBlur={() => onBlurHandler()}/>
                }
            </div>
            {mode === 'teacher' &&
                <>
                    <div className={st.verticalSeparator}></div>
                    <GoBackIcon onClick={() => {
                        sendMessageDrawing({type: 'undo', message: ''})
                    }}/>
                </>
            }
            <>
                <div className={st.verticalSeparator}></div>
                <Restart onClick={() => sendMessageDrawing({type: 'refresh_board', message: ''})}/>
            </>
        </div>
    );


    const wsDrawingRef = useRef()

    const sendMessageDrawing = (body) => {
        wsDrawingRef.current.send(JSON.stringify(body))
    }
    const onStroke = (stroke) => {
        if (!stroke.endTimestamp) {
            setIsDrawing(stroke.startTimestamp)
            return
        } else if (isDrawing === stroke.startTimestamp) {
            setIsDrawing(false)
        }
        sendMessageDrawing({
            type: 'on_drawing',
            message: {...stroke, id: `${Date.now()}${Math.round(Math.random() * 500)}`}
        })
    }
    useEffect(() => {
        const link = 'ebooks/drawing'
        const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/${link}/${uuid}/?token=${localStorage.getItem('access')}`
        wsDrawingRef.current = new WebSocket(wsUrl)

    }, [uuid])
    useEffect(() => {

        wsDrawingRef.current.onmessage = ({data}) => {

            const msg = JSON.parse(data)
            switch (msg.type) {
                case 'open_drawing_board':
                    setShowBoard(true)
                    setResetAll(true)
                    setPaths(msg.message)
                    break;
                case 'close_drawing_board':
                    setShowBoard(false)
                    break;
                case 'on_drawing':
                    setPaths(msg.message)
                    break;
                case 'clear_board':
                    sendMessageDrawing({type: 'refresh_board', message: ''})
                    setResetAll(true)
                    break;
                case 'refresh_board':
                    if (isDrawing) {
                        setShouldUpdate(true)
                        return
                    }
                    setResetAll(true)
                    setPaths(msg.message)
                    break;
                default:
                    break;
            }
        }

        if (isDrawing === false && shouldUpdate) {
            sendMessageDrawing({type: 'refresh_board', message: ''})
            setShouldUpdate(false)
        }
    }, [isDrawing]);

    useEffect(() => {
        canvasRef.current?.loadPaths(paths)
    }, [paths]);

    useEffect(() => {
        if (resetAll) {
            canvasRef.current?.resetCanvas()
        }
        setResetAll(false)
    }, [resetAll])

    useEffect(() => {
        if (wsDrawingRef.current.readyState !== 0 && mode === 'teacher') {
            if (showBoard)
                sendMessageDrawing({
                    type: 'open_drawing_board',
                    message: ''
                })
            else {
                sendMessageDrawing({
                    type: 'close_drawing_board',
                    message: ''
                })
            }
        }
        if(showBoard && mode === 'student') {
            sendMessageDrawing({type: 'refresh_board', message: ''})
        }
    }, [showBoard])


    return (
        <>
            {showBoard &&

                <div className={st.modalBoard}>
                    <div className={st.boardPanelWrapper}>
                    <div className={st.outer}>
                            <div className={st.sketchCanvasWrapper}>
                                <ReactSketchCanvas
                                    id={`react-sketch-canvas_${uuid}`}
                                    ref={canvasRef}
                                    strokeWidth={penWidth}
                                    eraserWidth={penWidth}
                                    strokeColor={penColor}
                                    onStroke={(stroke) => {
                                        onStroke(stroke)
                                    }}
                                    withTimestamp={true}
                                    style={{border: "none"}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={st.panelBlocks}>
                        {showColorPicker && renderColorPicker()}
                        {showTools && renderToolsPanel()}
                        {renderEditBlock()}
                    </div>
                </div>
            }
        </>
    );
};

export default DrawingBoard;