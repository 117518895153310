import React from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {getInitAnswer} from "../../TestQuestion"
import modalStore from "Stores/modalStore";
import VariantAdd from "../VariantAdd";
import st from './MatchImages.module.scss';
import MatchPair from "./MatchPair";
import VariantDel from "../VariantDel";

const MatchImages = () => {

    const {modalValues: md, changeValues, changeValuesArray} = modalStore;

    const action = (type, pl) => {
        let answers = []
        let files = []
        switch (type) {
            case 'change':
                answers = md.answers.map((x, i) => i === pl.index ? {...x, value: pl.value} : x)

                const [file1, file2] = pl.value.split('|')
                files = md.files.filter(f => f.file !== file1 && f.file !== file2)
                if (file1)
                    files.push({file: file1})
                if (file2)
                    files.push({file: file2})

                changeValuesArray({
                    ...md,
                    answers,
                    files,
                })
                break
            case 'add':
                answers = md.answers.concat([getInitAnswer(md.type)])
                changeValues(answers, 'answers')
                break
            case 'delete':
                answers = md.answers.filter((x, i) => i !== pl.index)
                const aFileInAnswers = answers
                    .reduce((acc, {value}) => `${acc}|${value}`, '')
                    .split('|')
                    .filter(a => a.length > 0)
             
                files = md.files.filter(f => f.is_question || aFileInAnswers.includes(f.file))

                changeValuesArray({
                    ...md,
                    answers,
                    files,
                })
                break
        }

    }

    return (
        <div className={st.box}>
            <div className={st.title}>
                <strong>Изображения для сопоставления</strong>
                <span>Изображение из левой части необходимо сопоставить с изображением из правой части</span>
            </div>
            {md.answers.map((item, index) =>
                <div className={st.pair} key={item.uuid || item.value + index}>
                    <div className={st.cell}>
                        <MatchPair value={item.value} onChange={value => action('change', {index, value})}/>
                    </div>
                    {md.answers.length > 2 &&
                    <VariantDel onClick={() => action('delete', {index})}/>}
                </div>)}
            <VariantAdd onClick={() => action('add')}/>
        </div>
    );
};

MatchImages.propTypes = {
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
}

MatchImages.defaultProps = {
    onChange: () => {}
}

export default observer(MatchImages)
