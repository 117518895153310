import React, {useEffect, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import styles from "./StatsCoaches.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {NavLink} from 'react-router-dom';
import {ExcelRowsParams} from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import TypeSelector from './components/TypeSelector';
import {createPortal} from "react-dom";
import {timeConverter} from "../../../ContentBox/Online-schedule/components/functions";
import {unidanceTypes} from "../../UniDance/ModalSchedule";


const StatsCoaches = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();

    const [showModal, setShowModal] = useState(null);

    const onClickHandler = (index, e) => {
        const rect= e.target.getBoundingClientRect()
        setShowModal(index === showModal?.index ? null : {index, top: rect.top, left: rect.left + rect.width / 2, target: e.target});
    };

    const handlePageClick = (event) => {
        if (showModal && showModal?.index !== null) {
            const clickedElement = event.target;

            if (!clickedElement.closest(`.${styles.quantityVisitsDetails}`)) {
                setShowModal(null);
            }
        }
    };

    const handleWindowResize = () => {
        if (!showModal) return;
        const rect= showModal.target.getBoundingClientRect()
        setShowModal({
            ...showModal,
            left: rect.left + rect.width / 2
        })
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => {
            document.removeEventListener('resize', handleWindowResize);
        };
    }, [showModal?.left])

    useEffect(() => {
        document.addEventListener('click', handlePageClick);
        return () => {
            document.removeEventListener('click', handlePageClick);
        };
    }, [showModal?.index]);

    const filterCnf = [
        {
            type: 'days-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: 'times-range-picker',
            nameState: ['start_at', 'finish_at']
        },
        {
            type: 'select-custom',
            nameState: 'type',
            placeholder: 'Тип',
            values: unidanceTypes
        },
    ]

    const cols = [
        {name: 'Фамилия'},
        {name: 'Кол-во занятий', rowSpan: 3},
        {name: 'Кол-во посетителей', rowSpan: 3},
        {name: 'Часы', rowSpan: 3},
    ];


    const parseData = (res) => {
        let newState = []
        res.data.forEach((item => {
            let newItem = {
                ...item,
                hourse: (item.minutes / 60).toFixed(1)
            }
            newState.push(newItem)
        }))
        return newState
    }

    const sendRequest = (params) => {
        setIsLoading(true)
        let apiConf = {
            method: 'get',
            params: {
                ...params
            }
        }
        if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
        if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")
        superAxiosRequest({...apiConf, link: 'progress_report/masters_loading'}).then(res => {
            setData(sortHandler(sortValue, parseData(res)))
            setIsLoading(false)
        })
    }
    const getData = () => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/masters_loading',
        })
            .then((res) => {
                setData(sortHandler(sortValue, parseData(res)))
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Фамилия",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Кол-во занятий",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Кол-во посетителей",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Часы",
                    ...ExcelRowsParams.headerRowParam
                }
            ]
            let footerRow = []
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 40},
                {wch: 30},
                {wch: 50},
            ];
            let rows = [
                {hpx: 30},
            ]

            data.forEach(item => {
                tableData.push([
                    {
                        v: `${item.full_name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${item.quantity_lessons}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.quantity_visits}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.hourse}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Статистика по тренерам.xlsx");
        })
    }
    const [sortValue, setSortValue] = useState(1)
    const sortHandler = (sortId, data) => {
        const sortTemplates = {
            1: (a, b) => a.quantity_lessons < b.quantity_lessons ? 1 : -1,
            2: (a, b)=> a.full_name > b.full_name ? 1 : -1,
        }
        const newState = [...data].sort(sortTemplates[sortId])
        setSortValue(sortId)
        return newState
    }

    let sortData = [
        {label: 'По занятиям', id: 1},
        {label: 'По тренерам', id: 2}
    ]

    return (
        <div>
            <ContentHeader title="Статистика по тренерам" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => getData()} />

            <div style={{maxWidth: '350px', marginBottom: '20px', marginLeft: '10px'}} className="">
                <div style={{fontWeight: '500', color: 'var(--color-text-accent)', marginBottom: '10px'}}>Сортировка по:</div>
                <TypeSelector data={sortData} value={sortValue} selectValue={(e) => setData(sortHandler(e, data))} />
            </div>

            {isLoading
                ? <Loader />
                : !Array.isArray(data) ? <h1 style={{textAlign: 'center'}}>Нет данных для отображения!</h1>
                    : (
                        <>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            <>
                                                {data.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={st.fw600} style={{background: "white"}}>
                                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                                <NavLink to={'/c/unidance/masters'} target="_blank">
                                                                    {row.full_name}
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.quantity_lessons}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            <span className={styles.pointer} onClick={(e) => onClickHandler(rowIdx, e)}>{row.quantity_visits}</span>
                                                            {row.quantity_visits_details.length > 0 && showModal?.index === rowIdx &&
                                                              createPortal(
                                                                <div className={styles.quantityVisitsDetails} style={{top: `${showModal.top + 25}px`, left: `${showModal.left - 75}px`}}>
                                                                    {row.quantity_visits_details.map((el, elIdx) => (
                                                                      <div key={elIdx}>{el}</div>
                                                                    ))}
                                                                </div>,
                                                                document.body
                                                            )}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.hourse}
                                                        </td>
                                                    </tr>
                                                )}

                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{padding: '10px'}}>
                                <ExportExcelButton data={data} downloadExcel={downloadExcel} />
                            </div>
                        </>
                    )}
        </div >
    )
}

export default StatsCoaches