import React from 'react';
import PropTypes from 'prop-types';
import {classNames as cn} from '../../../helpers';
import styles from './Hr.module.scss';

function Hr({withTopMargin, withBottomMargin, color, light}) {
  const customStyle = {
    backGroundColor: color ? color : '',
  };

  return (
    <hr
      className={cn({
        [styles.hr]: true,
        [styles.top_margin]: withTopMargin,
        [styles.bottom_margin]: withBottomMargin,
        [styles.light]: light,
      })}
      style={customStyle}
    />
  );
}

Hr.propTypes = {
  withTopMargin: PropTypes.bool,
  withBottomMargin: PropTypes.bool,
  light: PropTypes.bool,
  color: PropTypes.string,
};

Hr.defaultProps = {
  withTopMargin: true,
  withBottomMargin: true,
  color: '',
  light: false,
};

export default Hr;
