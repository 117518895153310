import React from 'react';
import {Route, Redirect, useHistory} from 'react-router-dom';
import {checkPermList} from '../helpers';
import {permissions} from 'settings/permissions';

const PrivateRoute = ({component: Component, path, ...route}) => {
    const history = useHistory();
    const hpn = history.location.pathname
    const pathname = hpn[hpn.length - 1] === '/' ? history.location.pathname.slice(1, -1) : history.location.pathname.slice(1)

    const checkAccess = path => {
        if (route.permission)
            return checkPermList(route.permission)

        const link = path.indexOf(':id') > -1
            ? pathname.substring(0, pathname.lastIndexOf('/'))
            : pathname;
        const a = Object.keys(permissions).find(key => permissions[key].link === link)
        if (a === undefined) return false;
        const perms = permissions[a];
        return checkPermList(perms);
    }

    return (
        <Route path={path} render={props => (!checkAccess(path) ?
            <Redirect to="/"/> :
            <Component {...props} />)}
        />
    );
}

export default PrivateRoute;
