import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react';
import { localisationMaster } from "Localisation/Localisation";
import { dateFormat } from "helpers/date";
import listingStore from 'Stores/listingStore';
import DatePicker from "react-datepicker";
import TypeSelector from '../GlobalModalSingle/templates/WorkPlain/TypeSelector';
import SelectCustom from '../Select/SelectCustom';
import './Filter.scss'

const FilterLiner = props => {
    const { setFilterParams, setFiltredList, dropFilterState, filterParamsObject, perPage } = listingStore
    const [inputsValues, setinputsValues] = useState({});
    const [open, setOpen] = useState(false)
    const [link, setLink] = useState([])
    const refSlider = useRef()
    const refSlide = useRef()

    const dropFilter = (soft = false) => { // soft = true сбрасывает параметры фильтра , но не отправляет запрос
        dropFilterState(props.link, props.baseApi, soft)
    }

    const changeHandler = (value, key_name) => {
        setFilterParams(value, key_name)
    }

    const getBody = item => {
        const components = {
            select_request: <SelectCustom
                placeholder={item.placeholder}
                apiLink={item.apiLink}
                apiGetResult={item.key_name ? data => data[item.key_name].items : item?.apiGetResult}
                sortKey={item.sort_key}
                valueID={filterParamsObject[item.nameState]}
                optionValueKey={item.option_value_key}
                optionLabelKey={item.option_label_key}
                apiConfParams={item.params}
                onChange={o => {
                    changeHandler(item.option_value_key ? o[item.option_value_key] : o.id, item.nameState)
                }}
                isSearchable
            />,
            select: <SelectCustom
                placeholder={item.placeholder}
                valueID={filterParamsObject[item.nameState]}
                options={item.values}
                optionLabelKey={item.option_label_key}
                onChange={o => changeHandler(o.id, item.nameState)}
                isSearchable={item.isSearchable}
            />,
            input: <input
                type={item.intype || "text"}
                placeholder={item.placeholder}
                value={filterParamsObject[item.nameState] || ''}
                onChange={e => changeHandler(e.target.value, item.nameState)}
                className="filter-box__input"
            />,
            type_selector: <TypeSelector
                values={item.values}
                like_obj={true}
                objectChangeHandler={changeHandler}
                selected={filterParamsObject[item.nameState] !== undefined ? filterParamsObject[item.nameState] : (item.initState || 0)}
                name={item.nameState}
            />,
            select_father: <SelectCustom
                placeholder={item.placeholder}
                apiLink={item.apiLink}
                apiGetResult={item.key_name ? data => data[item.key_name].items : null}
                valueID={inputsValues[item.nameState]?.id}
                optionLabelKey={item.option_label_key}
                onChange={o => setinputsValues({ ...inputsValues, [item.nameState]: o })}
                isSearchable
            />,
            select_son: <SelectCustom
                placeholder={item.placeholder}
                valueID={filterParamsObject[item.nameState]}
                options={inputsValues[item.fatherName]?.[item.optionsKey] || []}
                optionLabelKey={item.option_label_key}
                onChange={o => changeHandler(o.id, item.nameState)}
                isSearchable
            />,
            date_range_picker: 
                <div className="filter-box__month-range-picker react-datepicker-custom">
                    <div>
                        <DatePicker
                            selected={filterParamsObject[item.nameState[0]] && new Date(filterParamsObject[item.nameState[0]])}
                            onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[0])}
                            dateFormat="dd.MM.yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            locale="ru"
                            maxDate={filterParamsObject[item.nameState[1]] && new Date(filterParamsObject[item.nameState[1]])}
                        />
                    </div>
                    <div>{localisationMaster('text-11', 'filter')}</div>
                    <div>
                        <DatePicker
                            selected={filterParamsObject[item.nameState[1]] && new Date(filterParamsObject[item.nameState[1]])}
                            onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[1])}
                            dateFormat="dd.MM.yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            locale="ru"
                            minDate={filterParamsObject[item.nameState[0]] && new Date(filterParamsObject[item.nameState[0]])}
                        />
                    </div>
                </div>,
            date_day_picker:
                <div className="filter-box__month-range-picker react-datepicker-custom">
                    <div>
                        <DatePicker
                            selected={filterParamsObject[item.nameState[0]] && new Date(filterParamsObject[item.nameState[0]])}
                            onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[0])}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                            maxDate={filterParamsObject[item.nameState[1]] && new Date(filterParamsObject[item.nameState[1]])}
                        />
                    </div>
                    <div>{localisationMaster('text-11', 'filter')}</div>
                    <div>
                        <DatePicker
                            selected={filterParamsObject[item.nameState[1]] && new Date(filterParamsObject[item.nameState[1]])}
                            onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), item.nameState[1])}
                            dateFormat="dd.MM.yyyy"
                            locale="ru"
                            minDate={filterParamsObject[item.nameState[0]] && new Date(filterParamsObject[item.nameState[0]])}
                        />
                    </div>
                </div>,
        }
        return components[item.type]
    }

    const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

    useEffect(() => {
        if (window.location.pathname !== link) {
            dropFilter(true)
            setLink(link)
            setOpen(false)
        }
    }, [window.location.pathname])

    useEffect(() => {
        let test = { offset: 0, limit: 10, ...props.baseApi }
        if (deepEqual(test, filterParamsObject) && Object.keys(inputsValues).length) setinputsValues({})
    }, [filterParamsObject])

    useEffect(() => {
        refSlider.current.style.height = open ? refSlide.current.offsetHeight + 'px' : ''
    }, [open])

    return (
        <div className="filter-box">
            <div className="filter-box__header" onClick={() => setOpen(!open)}>
                <span className="filter-box__header-title">{localisationMaster('text-4', 'filter')}</span>
                <span className="filter-box__toggle-button">
                    {!open ? localisationMaster('text-5', 'filter') : localisationMaster('text-6', 'filter')}
                </span>
            </div>
            <div className="filter-box__slider" ref={refSlider}>
                <div ref={refSlide} className="filter-box__slide">
                    <div className="filter-box__fields">
                        {open && props.config.map((item, index) =>
                            <div className="filter-box__field" key={index}>{getBody(item)}</div>
                        )}
                    </div>
                    <div className="filter-box__buttons">
                        <button className="filter-box__apply-button"
                            onClick={() => setFiltredList(
                                { link: props.link, method: 'get' },
                                { ...filterParamsObject, offset: 0, limit: perPage, ...props.baseApi }
                            )}>
                            {localisationMaster('text-7', 'filter')}
                        </button>
                        <span className="filter-box__reset-button"
                            onClick={() => dropFilter(false)}>{localisationMaster('text-10', 'filter')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(FilterLiner);