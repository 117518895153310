import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import {ReactComponent as LeftArrow} from './left-arrow.svg';
import styles from './ContentHeader.module.scss';


function ContentHeader({ title, breadCrumbs }) {
    useEffect(() => {
      document.title = `${title} | PHOENIX # InterPress`;
    }, [title]);  

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {((breadCrumbs || []).length > 0 || breadCrumbs === true) && (
                  <div className={styles.breadcrumbs}>
                    <NavLink className={styles.back_link} to="/">Главная</NavLink> {breadCrumbs !== true && <LeftArrow/>}
                      {breadCrumbs !== true && breadCrumbs.map((c, idx) =>
                        <Fragment key={idx}>
                          <NavLink className={styles.back_link} to={c.route}>{c.title}</NavLink>
                          {idx !== breadCrumbs.length - 1 && <LeftArrow/>}
                        </Fragment>
                      )}
                  </div>
                )}
                <h1 className={styles.title}>{ title }</h1>
            </div>
        </div>
    )
}

ContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default observer(ContentHeader);