import React from 'react';
import styles from "./RegisterSectionRow.module.scss"
import {superAxiosRequest} from "axiosApi";
import swal from "sweetalert";
import {dateFormat} from "helpers/date";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import asideModalStore from "Stores/asideModalStore";


const RegisterSectionRow = ({data, handlerDeleteSection, handlerChangeSection, translate}) => {
    const menu = [
        {name: translate.edit, action: 'edit',},
        {name: translate.delete, action: 'delete', },
    ]

    const deleteChapter = (id) => {
        swal({
            text: `${translate.delete_register_section}?`,
            icon: "warning",
            buttons: [translate.no, translate.yes],
            dangerMode: true,
        }).then(yes => {
            if (yes) {
                const apiConf = {
                    method: 'delete',
                    link: `ebooks/ebook_register_chapters/${id}`,
                }
                superAxiosRequest(apiConf).then(() => {
                    handlerDeleteSection(id)
                })
            }
        })
    }

    const editChapter = () => {
        const ms = {
            type: 'generator',
            fields: [
                {
                    type: 'text',
                    name: 'name',
                    label: `${translate.register_section_name}:`,
                    placeholder: `${translate.register_section_name}`
                }, 
            ],
            valid: {
                name: 'required',
            },
            is_put: true,
            link: `ebooks/ebook_register_chapters/${data.id}`,
            title: `${translate.edit_register_section}`,
            button: `${translate.save}`,
            onSubmitSuccess:  handlerChangeSection
        }
        const md = {
            name: data.name,
            ebook_id: data.ebook_id,
        }
        asideModalStore.activity(ms, md)
    }

    const action = (actionType) => {
        switch (actionType) {
            case 'delete':
                deleteChapter(data.id)
                break;
            case 'edit':
                editChapter()
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.listRow}>
            <div className={styles.listRow__col}>
                <div className={styles.listRow__title}>{data.name}</div>
                <ActionMenu
                    menu={menu}
                    action={action}
                />
            </div>
            <div className={styles.listRow__col}>
                <div className={styles.listRow__box}>
                    <div>
                        <b>{dateFormat(new Date(data.updated_at), 'hh:ii')},</b> {dateFormat(new Date(data.updated_at), 'dd MMM YYYY')}
                    </div>
                    <div className={styles.userName}>{data.updated_by_name ? data.updated_by_name :data.created_by_name}</div>
                </div>
            </div>
        </div>
    )
}

export default RegisterSectionRow


