import React from "react";
import Info from "./Info/Info";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import MultiSelect from "components/ContentBox/Global/Idlist/MultiSelect";
import { ReactComponent as OptionsIcon } from "components/ContentBox/img/options.svg";
import { localisationMaster } from "Localisation/Localisation";
import { requiredFields } from "../../../data";
import "./ActiveField.scss";

const ActiveField = ({
  toggleField,
  index,
  el,
  openModal,
  changeField,
  locations,
}) => {
  return (
    <>
      <div className="placementTestBox__toggleLine">
        {requiredFields.includes(el.name) ? (
          <div className="placementTestBox__activeToggle">
            <div className="placementTestBox__block"></div>
            <p>{el.title}</p>
          </div>
        ) : (
          <CheckboxToggle
            right={el.title}
            checked={el.selected}
            onChange={() => toggleField(index, el)}
          />
        )}
        <div className="placementTestBox__activeField">
          <Info {...el} />
          <OptionsIcon onClick={() => openModal(el)} />
        </div>
      </div>
      {el.name === "agreement" ? (
        <div className="placementTestBox__toggleLine placementTestBox__toggleLine--right">
          <input
            type="text"
            placeholder={localisationMaster(
              "text_10",
              "c/tests/placement-test/create-widget"
            )}
            className="placementTestBox__input"
            value={el.data}
            onChange={(e) =>
              changeField(index, { ...el, data: e.target.value })
            }
          />
        </div>
      ) : null}
      {el.name === "locations" ? (
        <div className="placementTestBox__toggleLine placementTestBox__toggleLine--right">
          <MultiSelect
            options={locations[1]}
            values={el?.data || []}
            sortKey="name"
            onChange={(teachers) =>
              changeField(index, { ...el, data: teachers })
            }
          />
        </div>
      ) : null}
    </>
  );
};

export default ActiveField;
