import React from 'react';
import { checkPermList } from 'helpers';
import { permissions } from 'settings/permissions';
import icon from "components/ContentBox/img/zoom.png";
import { ReactComponent as IconEdit } from 'components/ContentBox/img/edit2.svg'
import styles from './Zoom.module.scss';

const Zoom = ({ onClick }) => {
    const checkEdit = checkPermList(permissions.edit_zoom_link);
    return (
        <div className={`${styles.box} ${checkEdit ? '' : styles.client}`} onClick={onClick}>
            <img src={icon} alt="icon" />
            <div className={styles.content}>
                <p>ZOOM</p>
                <p>ссылка группы</p>
            </div>
            {checkEdit && <IconEdit fill='white' />}
        </div>
    )
}

export default Zoom;