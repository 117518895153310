import {observable, action, toJS, computed, makeObservable} from 'mobx';
import {superAxiosRequest} from 'axiosApi';

class ClientStore {
  infoProfile = {}
  isError = false

  constructor() {
    makeObservable(this, {
      infoProfile: observable,

      fetchClient: action,
      updateProfile: action,
      //addContactPerson: action,
      //editContactPerson: action,
      deleteContactPerson: action,

      client: computed,
    })
  }

  fetchClient = id => {
    const apiConf = {
      method: 'get',
      link: 'accounts/clients/' + id,
    }
    superAxiosRequest(apiConf).then(response => {
      this.infoProfile = response.data
    }).catch(error => {
      if (error) this.isError = true
    })
  }

  updateProfile = data => this.infoProfile = data

  /*addContactPerson = data => this.infoProfile = {
    ...this.infoProfile,
    contact_persons: [...this.infoProfile.contact_persons, data]
  }*/

  /*editContactPerson = data => {
    const arr = this.infoProfile.contact_persons
    const idx = arr.findIndex(item => item.id === data.id)
    arr[idx] = data
    this.infoProfile = {...this.infoProfile, contact_persons: arr}
  }*/

  deleteContactPerson = idx => {
    const arr = [...this.infoProfile.contact_persons]
    arr.splice(idx, 1)
    this.infoProfile = {...this.infoProfile, contact_persons: arr}
  }

  get client(){
    return toJS(this.infoProfile)
  }

  get isUserActive() {
    return this.infoProfile.profile.is_active
  }

}

export default ClientStore = new ClientStore()
