import React, {useEffect, useRef, useState} from "react";
import styles from './ColoringBook.module.scss'
import {ReactComponent as Eraser} from 'components/UI/icons/eraser-line.svg';
import {superAxiosRequest} from "axiosApi";

const colors = [
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#008000',
    '#00FFFF',
    '#0000FF',
    '#800080',
    '#FFC0CB',
    '#A52A2A',
    '#808080',
    '#FFD700',
    '#00FF00'
];

export default function ColoringBook(props) {
    const [originalSvgContent, setOriginalSvgContent] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [chosenColor, setChosenColor] = useState('#0000FF');
    const [svgContent, setSvgContent] = useState('');
    const svgContainer = useRef(null);
    const clickableTags = ['path', 'circle', 'rect', 'ellipse', 'g'];
    const svgPath = props.ex.items[0].file

    useEffect(() => {
        const apiConf = {
            link: `files/svg_content`,
            method: 'get',
            params: {
                path: svgPath
            }
        }

        superAxiosRequest(apiConf).then((res) => {
            if (res.data.svg_content) {
                setSvgContent(res.data.svg_content)
                setOriginalSvgContent(res.data.svg_content);
            }
        })
    }, [svgPath]);

    useEffect(() => {
        clearColors();
    }, [props.try]);

    const clearColors = () => {
        if (svgContainer.current && originalSvgContent) {
            svgContainer.current.innerHTML = originalSvgContent;
        }
    };

    const onClickShowColorPickerHandler = () => {
        setShowColorPicker(prevState => !prevState);
    }

    const chooseWhiteColor = () => {
        setChosenColor('#FFFFFF');
    };

    const renderColorPicker = () => (
        <div
            className={styles.colorPickerBlock}
            onMouseLeave={() => {
                setShowColorPicker(false);
            }}
        >
            {colors.map((color) => (
                <div
                    key={color}
                    className={styles.circleBlock}
                    onClick={() => {
                        setChosenColor(color);
                        setShowColorPicker(false);
                    }}
                >
                    <div className={styles.circle} style={{backgroundColor: color}}></div>
                </div>
            ))}
        </div>
    );

    return (
        <div className={styles.coloringBookContainer}>
            <div className={styles.coloringBookBlock}>
                {props.studentAnswer ?
                    <div dangerouslySetInnerHTML={{__html: props?.studentAnswer?.answer}}></div>
                    : <div
                        className={styles.svgContainer}
                        ref={svgContainer}
                        dangerouslySetInnerHTML={{__html: svgContent}}
                        onClick={(e) => {
                            setShowColorPicker(false);
                            const target = e.target;
                            if (clickableTags.includes(target.tagName)) {
                                target.style.fill = chosenColor;
                                const svgString = svgContainer.current.innerHTML;
                                props.handleAnswers(svgString, true);
                            }
                        }}>
                    </div>
                }

                {!props.studentAnswer &&
                    <div className={styles.panelContainer}>
                        {showColorPicker && renderColorPicker()}
                        <div className={styles.panelBlock}>
                            <div onClick={onClickShowColorPickerHandler}>
                                <div className={styles.circle} style={{backgroundColor: chosenColor}}></div>
                            </div>
                            <div className={styles.separator}></div>
                            <Eraser onClick={chooseWhiteColor}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};