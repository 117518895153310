import React from "react";
import Info from "./Info/Info";

const DefaultCRM = () => {
  const defaultFields = [
    { crm_name: "client_name", placeholder: "Имя и фамилия клиента" },
    { crm_name: "client_phone", placeholder: "Телефон клиента" },
    { crm_name: "client_email", placeholder: "E-mail клиента" },
  ];
  return defaultFields.map((el, idx) => (
    <div className="placementTestBox__toggleLine" key={idx}>
      <div className="placementTestBox__activeToggle">
        <div className="placementTestBox__block"></div>
        <p>{el.placeholder}</p>
      </div>
      <div className="placementTestBox__activeField">
        <Info title={el.placeholder} name={el.crm_name} />
        <div className="placementTestBox__block"></div>
      </div>
    </div>
  ));
};

export default DefaultCRM;
