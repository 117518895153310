import React from 'react';
import swal from 'sweetalert';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import Button from 'components/UI/Button/Button';
import Flex from 'components/UI/Flex/Flex';
import { ActionType } from 'Stores/slotsNewRecordOnlineStore';
import styles from './Submit.module.scss';

const showAlert = (message = '') => swal('Ошибка запроса', message, 'warning');

function Submit({ store }) {
  const { isSubmitAllowed, paymentType, paymentTime, paymentStep, resetData, submitData } = store;

  const handleSubmit = () => {
    submitData(showAlert);
  };

  return (
    <Paper>
      <Flex withGap md='center'>
        <Button onClick={handleSubmit} isDisabled={!isSubmitAllowed}>
          {(paymentType === 1 || paymentType === 6) ? 'Забронировать время и перейти к оплате' : 'Забронировать время'}
        </Button>
        <Button
          onClick={() => resetData(ActionType.resetAll)}
          isDisabled={paymentStep > 1}
          variant='secondary'
        >
          Отменить бронирование
        </Button>
        {(paymentType === 1 || paymentType === 6) &&
          <p className={styles.text}>
            После перехода на страницу оплаты у вас будет <strong>20 минут</strong> на проведение оплаты. Если оплата не была произведена вовремя , время теста может измениться, просим связаться с Администрацией по номеру +7 777 757 57 57
          </p>
        }
      </Flex>
    </Paper>
  );
}

export default observer(Submit);
