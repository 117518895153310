import React from 'react';
import { observer } from 'mobx-react';
import Filter from 'components/ContentBox/Global/Filter/Filter';
import slotsBookedScheduleStore, { DataType } from 'Stores/slotsBookedScheduleStore';
import styles from './FilterWrapper.module.scss';
import {translate as loc, dict} from "Localisation";



function FilterWrapper() {
  const { employeesList, programList, setData } = slotsBookedScheduleStore;
  const filterConfig = [
    {
      type: 'input',
      placeholder: loc(dict.history_clients.placeholder_1),
      nameState: 'input',
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_2),
      values: programList,
      nameState: 'program'
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_3),
      values: employeesList,
      nameState: 'employees'
    },
  ];

  const handleFilterChange = (filterData) => {
    setData(filterData, DataType.filtersData);
  };

  const handleClear = () => {
    setData(null, DataType.filtersData);
  };

  return (
    <div className={styles.wrapper}>
      <Filter
        config={filterConfig}
        drop={handleClear}
        getFilterParams={handleFilterChange}
      />
    </div>

  );
}

export default observer(FilterWrapper);
