import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import modalStore from "Stores/modalStore";
import {dateFormat} from "helpers/date";
import {checkHolyDays} from "./CreateTimeTable";
import { classNames as cn } from 'helpers';
import DayTeacherClassroomSelector from "./DayTeacherClassroomSelector";
import DayTooltip from "./DayTooltip";
import st from "./Days.module.scss"

const createCalendar = (date_from, date_until, weekdays, holidays, availableList) => {
    const days = []
    let startAt = new Date(`${date_from}T12:00:00`);
    const endAt = new Date(`${date_until}T12:00:00`);

    let ssDate

    while (startAt <= endAt){
        const dow = startAt.getDay() || 7

        if (weekdays.includes(dow)){
            ssDate = dateFormat(startAt, "YYYY-mm-dd");

            days.push({
                date: new Date(startAt),
                sDate: dateFormat(startAt, "dd.mm.YY"),
                ssDate,
                is_holiday: checkHolyDays(ssDate, holidays),
                is_teacher_available: availableList.find(d => d.date === ssDate)?.teachers[0].is_available,
                is_class_available: availableList.find(d => d.date === ssDate)?.is_class_available,
            })
        }
        startAt = new Date(startAt.setDate(startAt.getDate() + 1))
    }

    return days
}

const Days = props => {
    const {data: {payload: {holidays}}, modalValues} = modalStore
    const {date_from, date_until, weekdays} = modalValues

    const [days, setDays] = useState([])
    const [availableList, setAvailableList] = useState({
        key: '',
        dates: []
    })
    const [selectedDay, setSelectedDay] = useState(null)
    const [tooltip, setTooltip] = useState({
        open: false,
        idx: null,
        target: null,
    })

    const refsDays = []

    const handleMouseEnter = idx => {
        setTooltip({open: true, idx, target: refsDays[idx]})
    }

    const handleMouseOut = () => {
        setTooltip({open: false, idx: null, target: null})
    }

    useEffect(() => {
        const arr = createCalendar(date_from, date_until, weekdays, holidays, availableList.dates)
        setDays(arr)
        props.onChange(arr.map(d => d.ssDate))
    }, [date_from, date_until, weekdays, availableList])

    useEffect(() => {

        const params = {
            group_id: modalValues.group_id,
            start_at: modalValues.start_at,
            finish_at: modalValues.finish_at,
            dates: days.map(d => d.ssDate),
            teachers: modalValues.teachers,
            classroom_id: modalValues.classroom_id,
        }

        const key = JSON.stringify(params)

        if (modalValues.teachers.length === 0 || !modalValues.classroom_id || key === availableList.key) return
        superAxiosRequest({
            method: 'post',
            link: 'schedule/workshop_check_teachers_and_class',
        }, params).then(res => {
            setAvailableList({key, dates: res.data.dates})
        }).catch(() => {

        })
    }, [days, modalValues.classroom_id, modalValues.teachers])

    return (
      <div>
          <div className={st.days}>{days.map((d, idx) => (
            <div key={d.sDate}>
                <div className={cn({
                    [st.day]: true,
                    [st.day__holiday]: d.is_holiday,
                    [st.day__teacher]: d.is_teacher_available === false && !d.is_holiday,
                    [st.day__classroom]: d.is_class_available === false && !d.is_holiday,
                })} onClick={() => setSelectedDay(d.is_holiday ? null : d.date)}
                     onMouseOver={() => handleMouseEnter(idx)}
                     onMouseLeave={handleMouseOut}
                     ref={ref => refsDays[idx] = ref}
                >
                    <span>{d.sDate}</span>
                    {(tooltip.open && tooltip.idx === idx) &&
                      <DayTooltip date={d.date}
                                  teachers={props.teachers}
                                  classrooms={props.classrooms}
                                  target={tooltip.target}/>}
                </div>
            </div>))}
          </div>
          {selectedDay && <DayTeacherClassroomSelector date={selectedDay}/>}
      </div>
    )
}

export default observer(Days)
