import { localisationMaster } from "Localisation/Localisation";

const activeFields = [
  {
    name: "first_name",
    is_required: true,
    type: "text",
    title: localisationMaster('field_title_1', 'c/tests/placement-test/create-widget'),
    placeholder: "Ваше имя",
    selected: true,
  },
  {
    name: "email",
    is_required: true,
    placeholder: "Введите свой Email",
    title: "E-mail",
    type: "email",
    selected: true,
  },
  {
    name: "birth_date",
    is_required: true,
    placeholder: "Дата рождения",
    title: localisationMaster('birth_date', 'c/tests/placement-test/create-widget'),
    type: "birth_date",
    selected: true,
  },
  { 
    name: "last_name",
    is_required: true,
    placeholder: "Ваша фамилия",
    title: localisationMaster('field_title_2', 'c/tests/placement-test/create-widget'),
    type: "text",
    selected: false,
  },
  {
    name: "phone",
    placeholder: "Введите свой номер телефона",
    title: localisationMaster('field_title_3', 'c/tests/placement-test/create-widget'),
    is_required: true,
    type: "phone",
    selected: false,
  },  
  {
    name: "contact_with_client",
    data: null,
    is_required: false,
    placeholder: "Просто пройти тестирование, запись не интересует",
    title: localisationMaster('field_title_4', 'c/tests/placement-test/create-widget'),
    type: "checkbox",
    selected: false, 
  },
  {
    name: "allowed_data_processing",
    placeholder: "Я согласен на <a href=''>обработку персональных данных</a>",
    title: localisationMaster('field_title_5', 'c/tests/placement-test/create-widget'),
    is_required: true,
    data: 'https://www.youtube.com/watch?v=YAXTn0E-Zgo&list=RDMM&index=6',
    type: "checkbox",
    selected: false,
  },
  {
    name: "locations",
    title: localisationMaster('title', 'company/locations'),
    placeholder: "Выберите локацию",
    is_required: false,
    type: "select",
    data: [],
    selected: false,
  },
];

export const defaultForm = {
  active_fields: [...activeFields],
  crm_fields: [],
  answers_unit: "", 
  is_data_collect: false,
  bundles: [],
  company: 0,
  frame: false,
  html_body: "",
  logo: null,
  media_base_url: "",
  name: "",
  title: "",
  url: "",
  lang: 'ru',
  finished_email: null,
  timeout_email: null,
  data_collect_email: null,
  save_client_data_button_title: null,
  crm_send: false,
  crm_slug: '',
  crm_widget_url: '', 
  show_timer: true,
  show_end_button: true,
};

export const requiredFields = ["first_name", "email", "birth_date"];