import React from "react";
import { localisationMaster } from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import { observer } from "mobx-react";

const ModalCRM = () => {
  const { modalValues, changeValues } = modalStore;
  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-field">
        <p className="super-modal__form-label">
          {localisationMaster(
            "text_38",
            "c/tests/placement-test/create-widget"
          )}:
        </p>
        <input
          type="text"
          value={modalValues.crm_name}
          onChange={(e) => changeValues(e.target.value, "crm_name")}
        />
      </div>
    </div>
  );
};

export default observer(ModalCRM);
