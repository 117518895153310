import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import swal from "sweetalert";
import useLocalization from "hooks/useLocalization";
import {superAxiosRequestEasy} from "axiosApi";
import Stores from "Stores";
import {classNames as cn} from "helpers";
import CompanyLogo from "../Common/CompanyLogo";
import InputInn from "../SignUp/InputInn/InputInn";
import SelectCustom from "../../../ContentBox/Global/Select/SelectCustom";
import SexSelector from "../SignUp/SexSelector/SexSelector";
import AuthFooter from "../Common/AuthFooter";
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss";
import stSignUp from "../SignUp/SignUp.module.scss";
import st from "./AddBook.module.scss";
import srcStudying1 from "../img/shutterstock_2350780611.svg";
import {ReactComponent as EyeLine} from "../img/eye-line.svg";
import {ReactComponent as EyeOffLine} from "../img/eye-off-line.svg";


const hasCyrillicCharacters = inputString => /[а-яА-ЯЁё]+/.test(inputString)

const isPunycodeEncoded = str => /xn--[0-9a-z-]+/i.test(str)

const AddBook = () => {
  const {uuid} = useParams()

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    inn: "",
    sex: "M",
    password: "",
    email: "",
    // country: "",
  })
  const [innTypeID, setInnTypeID] = useState(1)
  const [validPassword, setValidPassword] = useState({
    minSymbol: false,
    minLetters: false,
    minNumbers: false,
  })
  const [errors, setErrors] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [book, setBook] = useState(null)

  const {translate} = useLocalization({
    section: 'user',
    subsection: 'sign_up',
    dict: {
      "iin": "IIN",
      "inn": "INN",
      "sex": "Sex",
      "male": "Male",
      "name": "Name",
      "email": "E-mail",
      "phone": "Phone",
      "female": "Female",
      "sign_up": "Sign up",
      "surname": "Surname",
      "password": "Password",
      "login_here": "Login here",
      "have_account": "Already have an account",
      "date_of_birth": "Date of Birth",
      "create_account": "Create Account",
      "pass_min_symbol": "Consist of at least 8 characters",
      "pass_min_letters": "Contain at least 1 letter (a, b, c...)",
      "pass_min_numbers": "Contain at least 1 digit (1, 2, 3...)",
      "country": "Country",
      "select_your_country": "Select your country",
      "add_book": "Add Book",
      "register_new_book": "Register a new book in your personal account",
      "wrote_not_correct_email": "You entered an incorrect email",
      "wrote_not_correct" : "You entered an incorrect",
      "not_correct_iin" : "You entered an incorrect IIN",
      "confirmation_email_sent" : "A confirmation email has been sent to your email.\nPlease go to your email and click on the link in the email to complete your registration.",
      "error" : "An error has occurred"       
    }
  
  })
  
  const passwordText = [
  {
    content: translate.pass_min_symbol,
    key: "minSymbol",
    localization: 'pass_min_symbol',
  },
  {
    content: translate.pass_min_letters,
    key: "minLetters",
    localization: 'pass_min_letters',
  },
  {
    content: translate.pass_min_numbers,
    key: "minNumbers",
    localization: 'pass_min_numbers',
  },
]

  const innOptions = [
    {id: 1, name: translate.iin},
    {id: 2, name: translate.inn},
  ]

  const submitHandler = () => {
    const emptyFields = Object.keys(user).filter(key => user[key] === "")
    const checkPassword = Object.values(validPassword)
    setErrors(emptyFields)
    if (checkPassword.includes(false)) {
      setErrors([...emptyFields, 'password'])
    }
    if (emptyFields.length === 0 && !(checkPassword.includes(false))) {
      const {inn, email} = user

      if (inn.length < 12 || isNaN(+inn)) {
        swal({
          text: `${translate.wrote_not_correct} ${innTypeID === 1 ? `${translate.inn}` : `${translate.inn}`}`,
          icon: "warning",
        }).then(() => {})
      }
      else if (hasCyrillicCharacters(email) ||  isPunycodeEncoded(user.email)) {
        swal({
          text: translate.wrote_not_correct_email,
          icon: "warning",
        }).then(() => {})
      }
      else {
        superAxiosRequestEasy({
            method: 'post',
            link: 'accounts/register_client_book/' + uuid
          },
          user
        ).then(() => {
          swal({
            text: `${translate.confirmation_email_sent}`,
            icon: "success",
          }).then((result) => {
            if (result) {
              window.location.href = "/";
            }
          })
        }).catch(({response}) => {
          if (response?.data?.non_field_errors) {
            swal({
              text: translate.not_correct_iin,
              icon: "warning",
            }).then(() => {})
          } else if (response?.data?.email) {
            swal({
              text: response.data.email[0],
              icon: "warning",
            }).then(() => {})
          } else if(response?.data?.phone) {
            swal({
              text: response.data.phone[0],
              icon: "error",
            }).then(() => {})
          } else {
            swal({
              text: translate.error,
              icon: "error",
            }).then(() => {})
          }
        })
      }
    }
  }

  useEffect(() => {
    superAxiosRequestEasy({
      method: 'get',
      link: `ebooks/books_info/${uuid}`
    }).then(res => {
      setBook(res.data)
    }).catch(() => {

    })
  }, [uuid])

  useEffect(() => {
    const {password} = user
    setErrors([])
    setValidPassword({
      minSymbol: password.length >= 8,
      minLetters: /(?=.*[a-z])/.test(password),
      minNumbers: /(?=.*\d)/.test(password),
    })
  }, [user])

  return (
    <>
      <article className={stWrapperAuth.content}>
        <div className={stWrapperAuth.authBox}>
          <CompanyLogo/>

          <h1 className={stWrapperAuth.heading1}>
            {translate.add_book} <span role="img" aria-label={translate.add_book}>&#128214;</span>
          </h1>

          <div className={st.book}>
            <div className={st.bookText}>{translate.register_new_book}</div>
            {book &&
              <img src={Stores.baseLink() + book.image} alt="" className={st.bookImage}/>}
          </div>

          <div className={`${stWrapperAuth.form} ${stSignUp.formSignUp}`}>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.name}:</div>
              <input
                type="text"
                value={user.first_name}
                onChange={e => {
                  setUser({...user, first_name: e.target.value})
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes('first_name'),
                })}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.surname}:</div>
              <input
                type="text"
                value={user.last_name}
                onChange={e => {
                  setUser({ ...user, last_name: e.target.value })
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes("last_name"),
                })}
              />
            </div>

            {/*<div className={stWrapperAuth.field}>*/}
            {/*  <div className={stWrapperAuth.labelRequired}>{translate.country}:</div>*/}
            {/*  <div>*/}
            {/*    <input*/}
            {/*        placeholder={translate.select_your_country}*/}
            {/*        type="text"*/}
            {/*        value={user.country}*/}
            {/*        onChange={e => {*/}
            {/*          setUser({...user, country: e.target.value})*/}
            {/*        }}*/}
            {/*        className={cn({*/}
            {/*          [stWrapperAuth.input]: true,*/}
            {/*          [stWrapperAuth.inputError]: errors.includes("country"),*/}
            {/*        })}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.iin} / {translate.inn}:</div>
              <InputInn
                options={innOptions}
                onChange={val => setUser({...user, inn: val})}
                value={`${user.inn}`}
                onChangeType={obj => setInnTypeID(obj.id)}
                error={errors.includes("inn")}/>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.sex}:</div>
              <div>
                <SexSelector
                  options={[
                    {id: "M", name: translate.male},
                    {id: "F", name: translate.female},
                  ]}
                  value={user.sex}
                  onChange={val => setUser({...user, sex: val})}/>
              </div>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.email}:</div>
              <input
                type="email"
                value={user.email}
                onChange={e => {
                  setUser({...user, email: e.target.value});
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes("email"),
                })}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.password}:</div>
              <div className={stSignUp.inputPassword}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={user.password}
                  onChange={e => {
                    setUser({...user, password: e.target.value.trim()})
                  }}
                  className={cn({
                    [stWrapperAuth.input]: true,
                    [stWrapperAuth.inputError]: errors.includes("password"),
                  })}
                />
                {showPassword ?
                  <EyeLine onClick={() => setShowPassword(!showPassword)}/> :
                  <EyeOffLine onClick={() => setShowPassword(!showPassword)}/>}
              </div>
              <div>{passwordText.map(item =>
                <p key={item.key} className={cn({
                  [stSignUp.checkPasswordItemCorrect]: validPassword[item.key],
                  [stSignUp.checkPasswordItem]: true,
                })}>
                  {translate[item.localization] || item.content}
                </p>)}
              </div>
            </div>

            <div className={stWrapperAuth.submit}>
              <div onClick={submitHandler}>
                <button>{translate.add_book}</button>
              </div>
              <div className={stWrapperAuth.haveAccount}>
                <p>{translate.have_account}? <a href="/" >{translate.login_here}</a></p>
              </div>
            </div>
          </div>
        </div>
      </article>
      <AuthFooter srcPromo={srcStudying1}/>
    </>
  )
}

export default AddBook;
