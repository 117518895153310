import React from "react";
import PropTypes from "prop-types";
import st from "./FormLabel.module.scss"

const FormLabel = props => {
  return (
    <div className={props.isRequired ? st.formLabelRequired : st.formLabel}>
      {props.children}
    </div>
  )
}

FormLabel.propTypes = {
  isRequired: PropTypes.bool
}

export default FormLabel
