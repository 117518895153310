import React from 'react';

const QuestionLink = props =>
    <>
        <div className="lessonQuestion__contentTextOnly" dangerouslySetInnerHTML={{__html: props.description}}/>
        <div className="questionButtonLink">
            <a href={props.link} target="_blank">{props.anchor}</a>
        </div>
    </>

export default QuestionLink
