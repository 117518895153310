import React from 'react';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import {dateFormat} from "helpers/date";
import style from './WorkTimeInfo.module.scss'

const WorkTimeInfo = (props) => {
    let dayWeek;
    if (props.translate.days_week) dayWeek = props.translate.days_week[props.today.getDay()];

    function convertMilliseconds(milliseconds) {
        if (milliseconds !== 0) {
            const seconds = Math.floor(milliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const remainingSeconds = seconds % 60;
            const remainingMinutes = minutes % 60;
            return `${hours < 10 ? '0'+hours : hours}
                :${remainingMinutes <10 ? '0'+ remainingMinutes : remainingMinutes}
                :${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
        } else {
            return '00:00:00'
        }
    }
    const getRemainderTime = () => {
        if (props.currentDay.finish_at && props.currentDay.finish_at !== '00:00:00') {
            const diff = new Date(`${props.currentDay.date} ${props.currentDay.finish_at}`) - props.today
            return convertMilliseconds(diff)
        }else {
            return convertMilliseconds(0)
        }
    }
    const getOutTime = () => {
        if (props.currentDay.start_at && props.currentDay.start_at !== '00:00:00') {
            const diff = props.today - new Date(`${props.currentDay.date} ${props.currentDay.start_at}`)
            return convertMilliseconds(diff)
        }else {
            return convertMilliseconds(0)
        }
    }

    return (
        <div className={style.workTimeInfo}>
            <div className={style.workTimeInfo__header}>
                {`${props.translate.today}, ${dayWeek} ${timeConverter(new Date(), 'clean_data')}`}
            </div>
            <div className={style.remainderForToday}>
                <div className={style.timeWork}>
                    <div className={style.timeWorkItem}>
                        <span>{props.translate.remainder_for_today}</span>
                        <span> <b>{getRemainderTime()}</b></span>
                    </div>
                    <div className={style.timeWorkItem}>
                        <span>{props.translate.worked_out_today}</span>
                        <span> <b>{getOutTime()}</b></span>
                    </div>
                </div>
                <div>
                    <div className={style.exemplaryTime}>
                        <span>{props.translate.estimated_day_time}:</span>
                        <div className={style.workedOutToday}>
                            <span>{dateFormat(new Date(`${props.currentDay.date} ${props.currentDay.finish_at}`),'hh:ii:ss')}</span>
                            <span>{dateFormat(props.today)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkTimeInfo;