import React, { useEffect, useState } from 'react';
import basketW from '../../../../../img/delete.svg';
import basketQ from '../../../../../img/deleteNEwColor.svg';
import VideoThumbnail from 'react-video-thumbnail'; 
import Stores from '../../../../../../../Stores';
import cogoToast from "cogo-toast";

export default function MultiUploads(props) {
    const [inputsValues, setinputsValues] = useState({})
    const [img, setimg] = useState([])
    const [hovers, sethovers] = useState(0)
    const [filesUploaded, setfilesUploaded] = useState([])
    
    const getImages = (e)=>{
        const at = props.accept.replaceAll('.','').split(',') || false
        let file, type, i, url;
        let filesArray = []
        if(filesUploaded.length > 0){
            filesArray = img
            let new_arr = []
            for(i=0; i < filesUploaded.length; i++){
                new_arr.push(filesUploaded[i])
            }
            for(i=0; i < e.target.files.length; i++){
                file = e.target.files[i];
                url = URL.createObjectURL(file)
                filesArray.push(
                        {id: i, url: url, name: e.target.files[i].name, hover: false}
                    )
                    new_arr.push(e.target.files[i])
            }
            setimg(filesArray)
            setfilesUploaded(new_arr)
        }else{
        for(i=0; i < e.target.files.length; i++){
            file = e.target.files[i];
            type = file.type.split('/')[1]
            if(at && !at.includes(type)) {
                cogoToast.error(`Файл ${file.name} не может быть загружен. ${props.textInfo}` , {position: "top-center"})
                return
            }
            url = URL.createObjectURL(file)
            filesArray.push(
                    {id: i, url: url, name: e.target.files[i].name, hover: false}
                )
        }
        setimg(filesArray)
        setfilesUploaded(e.target.files)
        }
    }

    
    const onHover = (index, state) =>{
        let a = img;
        a[index].hover = state
        setimg([...a])
    }

    const deleteImage = (name, index) =>{
        let clean_arr = []
        let filesArray = []
        let file
        for(let x = 0; x < filesUploaded.length; x++){
            file = filesUploaded[x];
            let url = URL.createObjectURL(file)
            if(x !== index){
                clean_arr.push(filesUploaded[x])
                filesArray.push(
                    {id: x, url: url, name: filesUploaded[x].name, hover: false}
                )
            }
            
        }
        setfilesUploaded(clean_arr)
        setimg(filesArray)
        let iv = inputsValues
        delete iv[name]
        setinputsValues({...iv})

    }  

    const changeDescription = (e, index) =>{
        setinputsValues({...inputsValues, [index]: e.target.value})
    }

    const updateDescr = (e, index) =>{
        let arr = inputsValues
        arr.items[index]['description'] = e.target.value
        setinputsValues({...arr})
    }

    useEffect(() => {
        let array = []
        let key;
        if(Object.keys(inputsValues).length > 0){
        for(key in inputsValues){
            array[key] = inputsValues[key] 
        }}
        array['files'] = filesUploaded
        if(props.update) array['multiupload'] = true
        props.arrayChangeHandler(array)
    }, [inputsValues, filesUploaded])

    
    useEffect(() => {
        if(props.update && props.inputsValues.items){
            setinputsValues({...inputsValues, items: props.inputsValues.items})

         }
    }, [])

    const deleteImageInpt = id =>{
        let arr = inputsValues
        let wd =  arr.items.filter(x =>x.id !== id)
        arr.items = wd
        setinputsValues({...arr})
    }

    return (
        <div className="multi_upload">
            <div className="multi_upload__upload">
            {img.map((item,index)=>{
                return(
                    <div className="multi_upload__upload__line" key={index}>
                    <div className="multi_upload__upload__line__img" onMouseOver={()=>onHover(index, true)} onMouseOut={()=>onHover(index, false)}>
                            <div className="multi_upload__upload__line__img--image">
                                {props.type === 'img' ? 
                                <img  src={item.url}/>
                                :                            
                                <VideoThumbnail 
                                videoUrl={item.url}
                                thumbnailHandler={(thumbnail) => console.log(thumbnail)}

                                />
                    }
                            </div>
                            <div className={img[index].hover ? "multi_upload__upload__line__cover" : 'multi_upload__upload__line__cover multi_upload__upload__line--hide'}>
                                <img  src={basketW} onClick={()=>deleteImage(item.name, index)}/>
                                </div>
                        </div>
                        <div className="multi_upload__upload__line__description">
                            <textarea   value={inputsValues[item.name]} 
                                        onChange={(e)=>changeDescription(e, item.name)}
                                        id={inputsValues[item.name]}
                                        placeholder='Короткое описание'
                                        style={{borderColor: inputsValues[item.name] && inputsValues[item.name].length > 150 && 'red'}}
                            />
                            {inputsValues[item.name] && inputsValues[item.name].length > 150 && 
                                <label for={inputsValues[item.name]}>Описание не должно привышать 150 символов</label>}
                         </div>
                    </div>
                )
            })}
            {(props.update &&  inputsValues.items) && inputsValues.items.map((item,index)=>{
                return(
                    <div className="multi_upload__upload__line" key={index}>
                    <div className="multi_upload__upload__line__img" onMouseOver={()=>sethovers(item.id)} onMouseOut={()=>sethovers(0)}>
                            <div className="multi_upload__upload__line__img--image">
                                {props.type === 'img' ? 
                                <img  src={Stores.baseLink() +  item.file}/>
                                :        
                                <VideoThumbnail 
                                videoUrl={Stores.baseLink().slice(0, -1) + item.file}
                                thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                                />
                                
                    }
                            </div>
                            <div className={hovers === item.id ? "multi_upload__upload__line__cover" : 'multi_upload__upload__line__cover multi_upload__upload__line--hide'}>
                                <img  src={basketW} onClick={()=>deleteImageInpt(item.id)}/>
                            </div>
                        </div>
                        <div className="multi_upload__upload__line__description">
                            <textarea   value={inputsValues.items[index].description} 
                                        onChange={(e)=>updateDescr(e, index)}
                                        id={inputsValues.items[index].file}
                                        placeholder='Короткое описание'
                                        style={{borderColor: inputsValues.items[index].description && inputsValues.items[index].description.length > 150 && 'red'}}
                            />
                            {inputsValues.items[index].description && inputsValues.items[index].description.length > 150 &&
                                <label for={inputsValues[item.name]}>Описание не должно привышать 150 символов</label>}
                         </div>
                    </div>
                )
            })}
            </div>
            
            <input  type="file"
                    name="file[]"
                    id="file"
                    multiple
                    onChange={(e)=>getImages(e)}
                    accept={props.accept || '*'}
                    />
                    <label className="multi_upload__label" htmlFor="file">
                    Выбрать файл
                    </label>
            <p className="multi_upload__types">
                {/*Вы можете загрузить изображение в формате JPG, GIF или PNG.*/}
                {props.textInfo}
            </p>

            </div>
    )
}
