import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';
import femaleSVG from 'components/ContentBox/img/person-female.svg';
import maleSVG from 'components/ContentBox/img/person-male.svg';
import testSVG from 'components/ContentBox/img/test.svg';
import commentSVG from 'components/ContentBox/img/comment.svg';
import {getLevels, getShiftedTime} from 'components/Modules/Slots/utils';
import { agetostr } from 'components/ContentBox/Online-schedule/components/functions';
import { maskPhone } from 'helpers/phoneMask';
import { dateFormat } from 'helpers/date';
import LocalStorage from 'services/LocalStorage';
import slotsHistoryListingStore from 'Stores/slotsHistoryListingStore';
import { checkPermList } from 'helpers';
import { permissions } from 'settings/permissions';
import styles from './ClientCard.module.scss';
import Stores from "Stores";
import { translate as loc, dict } from "Localisation";
import { showSwal } from '../../../BookedSchedule/components/CustomerList/utils';
import { timeConverter } from 'components/ContentBox/Online-schedule/components/functions';



function ClientCard({ data }) {
  const [showTests, setShowTests] = useState(false);
  const history = useHistory();
  const { levels, programs } = slotsHistoryListingStore;
  const { full_name, phone, date_of_birth, client_id, user_id } = data.client;
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;
  const shiftedTime = getShiftedTime(data.test_date, timeZone, 'dd MMMM YYYYг.');

  const actionList = [
    { name: loc(dict.history_clients.find_group), action: 'find_group', permission: ['forward'] },
    { name: loc(dict.history_clients.profile), action: 'profile', permission: ['forward'] },
    (checkPermList(permissions.view_online__slot_v2) && { name: loc(dict.history_clients.info), action: 'info', permission: ['forward'] }),
    { name: loc(dict.history_clients.rewrite), action: 'rewrite', permission: ['forward'] },
    (data.zoom_data ? { name: loc(dict.client_card.text_1), action: 'zoom', permission: ['forward'] } : false),
    (data.placement_tests.length > 0 && { name: loc(dict.placement_test.title), action: 'show_placement_tests', permission: ['forward'] })
  ];


  const handleAction = (actionType) => {
    switch (actionType) {
      case 'find_group':
        history.push(`/groups/sign-up-student/${client_id}`);
        break;
      case 'profile':
        history.push(`/account_clients/${user_id}`);
        break;
      case 'info':
        history.push(`/online-schedule-v2/slot-details/${data.id}`);
        break;
      case 'rewrite':
        history.push(`/online-schedule-v2/record-customer/${data.id}`);
        break;
      case 'zoom':
        const time = shiftedTime.time.split(':')
        return showSwal({ date: `${time[0]}:${time[1]} , ${timeConverter((new Date(data.zoom_data.zoom_date)), 'day_month')}`, link: data.zoom_data.zoom_link }, 'zoom');
      case 'show_placement_tests':
        setShowTests(!showTests)
        break;
      default:
        return;
    }
  };

  return (
    <Paper tag='li' classes={styles.card}>
      <div className={styles.head}>
        <p className={styles.name_wrapper}>
          <span className={styles.name}>{Stores.transliterate(full_name)}</span>
          <span>{agetostr(date_of_birth)}, {dateFormat(new Date(date_of_birth), 'dd MMMM YYYYг.')}</span>
        </p>
        <p>
          <span className={styles.slot_time}>{shiftedTime.time},&nbsp;</span>
          <span>{shiftedTime.date}</span>
        </p>
        <p className={styles.phone_wrapper}>
          <a className={styles.phone} href={`tel:${phone}`}>{maskPhone(phone)}</a>
        </p>
      </div>

      <ul className={styles.info}>
        <li className={styles.info_item}>
          <img
            src={data.employee_gender === 'F' ? femaleSVG : maleSVG}
            width={15}
            alt='иконка преподавателя'
          />
          <div className={styles.info_text}>
            <NavLink
              className={styles.employee_name}
              to={`/account/${data.employee_user_id}`}
              target='_blank'
            >
              {Stores.transliterate(data.employee_full_name)}
            </NavLink>
            {data.start_level &&
              <p>{data.start_level}</p>
            }
            <p>
              {programs.find((item) => item.value === data.program).label}
            </p>
          </div>
        </li>

        {(Boolean(data.training_level) || data.is_missed) &&
          <li className={styles.info_item}>
            <img src={testSVG} width={15} alt="Иконка теста" />
            <div className={styles.info_text}>
              {data.training_level
                ? getLevels(levels.find((item) => item.value === data.training_level)?.label)
                  .map(({ name, level }, index) => (
                    <p key={index.toString()}>
                      <span className={styles.level_name}>{name}: </span>
                      <span>{level}</span>
                    </p>
                  ))
                : <span className={styles.level_missed}>Пропустил</span>
              }
            </div>
          </li>
        }

        {Boolean(data.comment) &&
          <li className={`${styles.info_item} ${styles.info_item__comment}`}>
            <img src={commentSVG} width={15} alt="Иконка комментария" />
            <p className={styles.info_comment}>
              {data.comment}
            </p>
          </li>
        }
      </ul>


      <div className={styles.action_menu}>
        <ActionMenu
          menu={actionList}
          action={handleAction}
        />
      </div>
      <div className={`
        ${styles.placementTests} 
        ${showTests && styles.placementTests__active} 
        ${(data.placement_tests.length > 5 && showTests) && styles.placementTests__many}`
      }>
        <p className={styles.placementTests__title}>{loc(dict.placement_test.title)}</p>
        <ul>
          {data.placement_tests.map(el => (
            <li key={el.id}>
              <div className={styles.placementTests__top}>
                <p>{loc(dict.placement_test.name_test)}: <NavLink to="/">{el.widget.name}</NavLink></p>
                <p>{dateFormat(el.date_start, 'd.MMM.YYYY').split('.').join(' ')}г.</p>
              </div>
              {(el?.level?.name || el?.location?.name) &&
                <div className={styles.placementTests__bottom}>
                  {el?.level && <p>{loc(dict.placement_test.level)}: {el.level.name}</p>}
                  {el?.location?.name && <p>{loc(dict.main_page.location)}: {el.location.name}</p>}
                </div>}
            </li>
          ))}
        </ul>
      </div>
    </Paper>
  );
}

ClientCard.propTypes = {
  data: PropTypes.shape({
    age: PropTypes.number.isRequired,
    date_of_birth: PropTypes.string,
    comment: PropTypes.string,
    employee_full_name: PropTypes.string.isRequired,
    employee_id: PropTypes.number.isRequired,
    employee_gender: PropTypes.oneOf(['M', 'F']).isRequired,
    id: PropTypes.number.isRequired,
    program: PropTypes.number.isRequired,
    start_level: PropTypes.string,
    test_date: PropTypes.string,
    is_missed: PropTypes.bool,
    training_level: PropTypes.number,
    client: PropTypes.shape({
      age: PropTypes.number.isRequired,
      client_id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      phone: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default observer(ClientCard);
