import React, {useEffect, useState} from 'react';
import MainSelector from "components/ContentBox/Global/GlobalModalSingle/templates/MainSelector";
import Question from "./Question";
import RadioInput from "components/ContentBox/Global/RadioGroup/RadioInput";
import cn from "./ExTest.module.css"
import {ReactComponent as ImgClock} from "components/ContentBox/img/stopwatch.svg"
import {ReactComponent as ImgNumbers} from "components/ContentBox/img/numbers.svg"
import CheckboxToggle from '../../../../../CheckboxToggle/CheckboxToggle';

export default function ExTest(props) {
    const initQuestion = {
        text: '',
        points: 5,
        variants: []
    }

    const [type, setType] = useState('timer')
    const [values, setValues] = useState([])

    const onChangeHandler = (val, index, key) => {
        const arr = values
        arr[index][key] = val
        setValues([...arr])
    }

    useEffect(() => {
        if (props.ex.is_timer)
            setType('timer')
        if (props.ex.is_point)
            setType('point')

        if(props.values)
            setValues(props.values)
        else
            setValues([initQuestion])
    }, [])

    const deleteQuestion = i => {
        let newList = values.filter((el, index) => index !== i )
        setValues([...newList])
    }

    useEffect(() => {
        props.onChange(values, 'items')
    }, [values])

    return (
        <div className={cn.exTest}>
            <MainSelector component={() => <ImgClock/>}>
                <div className={cn.typeContent}>
                    {/*<RadioGroup list={[{value: 'timer', label: 'Таймер'}]} checked={type} backFunction={() => {}} />*/}
                    <div className={cn.pointInputRow}>
                        <CheckboxToggle right="Таймер" checked={props.ex.timer} onChange={() => props.onChange(!props.ex.timer, 'timer')}/>
                      <div className={cn.pointInput}>
                            <input type="text"
                                   value={props.ex.time || 1}
                                   onChange={e => props.onChange(+e.target.value, 'time')}
                                   disabled={!props.ex.timer}
                            />
                        </div>
                        <span>минут</span>
                    </div>
                    <p>Таймер позволяет задать время на выполнение этого теста</p>
                </div>
            </MainSelector>
            <MainSelector component={() => <ImgNumbers/>}>
                <div className={cn.typeContent}>
                <CheckboxToggle right="Балльная система" checked={props.ex.point} onChange={() => props.onChange(!props.ex.point, 'point')}/>
                   <p>Вы можете задать кол-во баллов, которые получит ученик за каждый вопрос</p>
                </div>
            </MainSelector>

            <div className={cn.questions}>
                {values.map((item, index) =>
                    <Question key={index} index={index} question={item} onChange={onChangeHandler} isPoint={props.ex.point} onDelete={deleteQuestion}/>
                )}
            </div>

            <div className={`modal-question-add-button-add ${cn.addButton}`} onClick={() => setValues([...values, initQuestion])}>+</div>

            {props.ex.point &&
            <div className={cn.pointsRow}>
                <span>Общее количество баллов</span>
                <span className={cn.points}>{values.reduce((sum, x) => (sum + x.points), 0)}</span>
            </div>
            }
        </div>
    );
};