import React, { useContext } from 'react';
import Paper from 'components/UI/Paper/Paper';
import swal from 'sweetalert';
import { Context } from '../SlotDetails';
import LocalStorage from 'services/LocalStorage';
import { getShiftedTime } from 'components/Modules/Slots/utils';
import styles from '../SlotDetails.module.scss';

export default function Location() {
  const { slot_client } = useContext(Context);
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;
  const shiftedTime = getShiftedTime(`${slot_client.start_date}T${slot_client.start_time}`, timeZone, 'dd MMMM YYYYг.');

  const handleCopyZoomClick = (evt) => {
    evt.preventDefault();
    navigator.clipboard.writeText(slot_client.zoom_url)
      .then(() => swal('', 'Ссылка на ZOOM скопирована', 'success'));
  };

  return (
    <Paper classes={`${styles.item} ${styles.location}`}>
      <p className={styles.title}>
        {shiftedTime.time}, {shiftedTime.date}
      </p>
      <div>
        <h2 className={styles.title}>
          {slot_client.is_online ? 'Онлайн-запись' : slot_client.location_name}
        </h2>
        {!slot_client.is_online && <p>{slot_client.location_address}</p>}
      </div>
      {slot_client.is_online
        ? <>
          {slot_client.zoom_url && <>
            <a className={`${styles.link} ${styles.link__red}`} href={`${slot_client.zoom_url}`}>
              {slot_client.zoom_url}
            </a>
            <a className={styles.link} href={`${slot_client.zoom_url}`} onClick={handleCopyZoomClick}>
            копировать ссылку
            </a></>}
        </>
        : <>
            <a className={styles.link} href={`tel:${slot_client.location_phone[0]}`}>{slot_client.location_phone[0]}</a>
            <a className={styles.link} href={`mailto:${slot_client.location_email}`}>{slot_client.location_email}</a>
          </>
      }
    </Paper>
  );
}

