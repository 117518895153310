import React from "react";
import {ReactComponent as ArrowUp} from "components/UI/icons/arrow-up-s-line.svg";
import {ReactComponent as ArrowDown} from "components/UI/icons/arrow-down-s-line.svg";
import {ReactComponent as Edit} from "components/UI/icons/edit-line.svg";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";
import st from "../SectionEditMenu.module.scss";


export const ControlPanel = ({sections, section, index, action}) => {
  const onClickHandler = (e, act) => {
    e.stopPropagation()
    action(act, section)
  }

  return (
    <div className={st.control}>
      {index > 0 &&
        <div onClick={e => onClickHandler(e,'up')} className={st.icon_container}>
          <ArrowUp/>
        </div>}

      {index !== sections.length - 1 &&
        <div onClick={e => onClickHandler(e, 'down')} className={st.icon_container}>
          <ArrowDown/>
        </div>}

      <div onClick={e => onClickHandler(e, 'edit')} className={st.icon_container}>
        <Edit/>
      </div>

      <div onClick={e => onClickHandler(e, 'delete')} className={st.icon_container}>
        <Delete/>
      </div>
    </div>
  )
}
