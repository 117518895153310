import React, {useEffect, useRef, useState} from 'react';
import style from '../LessonDiscussion.module.scss'
import {useParams} from "react-router-dom";
import {superAxiosRequest} from "../../../../../axiosApi";
import Loader from "../../../../UI/Loader/Loader";
import Message from "../Message/Message";
import TextareaMarkup from "../../../../UI/TextareaMarkup/TextareaMarkup";


const CreateDiscussionPastLesson = ({context, studentId, setRef}) => {
    const {uuid} = useParams()

    const [dialogText, setDialogText] = useState('')
    const [chatId, setChatId] = useState(null)
    const [allMessages, setAllMessages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const targetRef = useRef(null)

    const onChange = (e) => {
        setDialogText(e)
    }
    const createDialog = () => {
        const requestBody = {
            uuid: uuid,
        };

        if (context === 'student') {
            requestBody.uuid = uuid;
        } else {
            requestBody.student = studentId;
        }

        superAxiosRequest({
            link: 'ebooks/create_discussion_past_lesson',
            method: 'post',
        }, requestBody).then((res) => {
            setChatId(res.data.id);
        });
    };
    const sendDialogMessage = () => {
        superAxiosRequest({
            link: `ebooks/discussion_past_lesson/${chatId}`,
            method: 'post'
        }, {
            text: dialogText
        }).then(() => {
            fetchMessages()
            setDialogText('')
        })
    }

    useEffect(() => {
        createDialog()
    }, [uuid,studentId]);

    const fetchMessages = () => {
        setIsLoading(true)
        superAxiosRequest({
            link: `ebooks/discussion_past_lesson/${chatId}`,
            method: 'get',
        }, {
            hash: uuid,
        }).then((res) => {
            setAllMessages(res.data);
        }).finally(() => setIsLoading(false))
    };

    useEffect(() => {
        if (chatId) {
            fetchMessages();
        }
    }, [chatId]);

    useEffect(() => {
        if (setRef) setRef(targetRef)
    }, []);

    return (
        <div className={style.container} ref={targetRef}>
            <div className={style.header}>
                <div className={style.line}></div>
                <div className={style.text}>Lesson Discussion</div>
            </div>
            {
                isLoading ? <Loader/> : <div className={style.comments}>
                    {
                        allMessages.length > 0 ?
                            allMessages.map((el,index) => {
                                return <Message key={index} message={el}/>
                            }) : <div className={style.empty}>
                                <h3>There's no discussion yet!</h3>
                                <span>To start a discussion, leave a comment.</span>
                            </div>
                    }
                </div>
            }
            <div className={style.leaveComment}>
                <span>Leave a Comment</span>
                <TextareaMarkup onChange={(e)=> onChange(e)} value={dialogText}/>
                <button className={style.btnSend} onClick={sendDialogMessage}>Send</button>
            </div>
        </div>
    );
};

export default CreateDiscussionPastLesson;