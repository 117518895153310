import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import styles from "./LineChart.module.scss"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const options = {
    plugins: {
        tooltip: {
            enabled: true, //отображает/не отображает подсказки при наведении
            position: 'nearest'
        },
        legend: {
            display: false,
            labels: {
                usePointStyle: true,
                font: {
                    size: 40
                }
            }
        },
        elements: {
            point: {
                borderColor: "rgb( 255, 255, 255 )",
                hoverRadius: 15,
            }
        }
    }
}

const LineChart = ({newData}) => {
    return (newData &&
      <div className={styles.clientGraph}>
          <Line options={options} data={newData} type='line'/>
      </div>
    )
}

export default LineChart
