import React from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import BankList from './BankList/BankList';
import { DataType } from 'Stores/slotsNewRecordOnlineStore';
import { calcPayDate } from 'components/Modules/Slots/utils';
import styles from './PaymentChoice.module.scss';

function PaymentChoice({store, paymentComment}) {
  const { sum, timeZone, paymentTime, setData, bank, banks } = store;
  const currentDate = calcPayDate(0, timeZone);
  const payBefore = calcPayDate(paymentTime, timeZone);

  return (
    <Paper>
      <SectionTitle title="Выбор способа оплаты" secondary />
      <Paper classes={styles.wrapper}>
        <dl className={styles.list}>
          <dt>Назначение платежа</dt>
          <dd className={styles.bold}>{paymentComment || 'Устное тестирование'}</dd>
          <dt>Сумма</dt>
          <dd>{sum} тг.</dd>
          <dt>Дата и время</dt>
          <dd>{currentDate.withTimeZone}</dd>
          <dt>Всего к оплате</dt>
          <dd className={styles.big}>{sum} тг.</dd>
          <dt>Оплатить до</dt>
          <dd>{payBefore.full}</dd>
        </dl>
      </Paper>
      <BankList
        banks={banks}
        bank={bank}
        setData={setData}
        types={DataType}
      />
    </Paper>
  );
}

export default observer(PaymentChoice);
