import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import TeacherCard from "./TeacherCard";
import style from "../MainInfo.module.scss";
import st from "./GroupTeachers.module.scss";

function GroupTeachers(props) {
  const [teachers, setTeachers] = useState([])

  const showAllTeacher = () => {
    setTeachers([...props.teachers])
  }

  useEffect(() => {
    setTeachers(props.teachers.slice(0, 2))
  }, [])

  return (
    <div className={style.groupSimpleMainBox__col__card}>
      <div className={style.groupSimpleMainBox__cardHeader}>
        {props.translate.group_teachers}
      </div>
      <div className={st.teachers}>
        {props.teachers.length > 0 ?
          teachers.map(item =>
            <TeacherCard teacher={item} key={item.id}/>)
          :
          <div className={style.notFoundTeachers}>
            The group has no teachers yet. You need to add a schedule with a teacher.
          </div>}

        {props.teachers.length > 2 &&
          <div className={st.showAll}>
            <button onClick={showAllTeacher}>Show All Teachers</button>
          </div>}
      </div>

      <div id='teacherTooltip'/>
    </div>
  )
}

export default observer(GroupTeachers)
