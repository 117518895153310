import React, {useEffect, useState} from 'react'
import {superAxiosRequest} from "axiosApi";

import './Select.scss'

export default function SelectRequest(props) {

    const [value, setValue] = useState([]);
    const [key, setKey] = useState('result');

    const myChangeHandler = (e, nameState) => {
        if(props.getFullItem) {
            let arr = value.result.filter(x=>x.id === +e.target.value)
            props.getFullItem(arr[0].list)
        }
        props.myChangeHandler(e, nameState)
    }

    const getApi = link => {
        const apiConf = {
            method: 'get',
            link: link,
            params: {limit: 999}
        }

        if (props.extendedParams)
            apiConf.params = {...apiConf.params, ...props.extendedParams}
        
        superAxiosRequest(apiConf).then(res => {
                setValue(res.data)
            })
    }

    useEffect(() => {
        if (props.key_name)
            setKey(props.key_name)

        getApi(props.link)
    }, [])

    useEffect(() => {
        if (props.key_name)
            setKey(props.key_name)

        getApi(props.link)
    }, [props.link])
  
    const keyy = props.new_key !== undefined ? props.new_key : 'name'
    
    const getList = () =>{
        var values = value[key]
        if(props.without_keys){
            values = value
        }
        return values
    }

    return (
        <div className={'modal-selectors '+ (props.value_error ? ' modal-selectors--error' : '')} >
            <select
                onChange={e => myChangeHandler(e, props.nameState)}
                defaultValue={0}
                value={props.valueId ? props.valueId : 0}
                disabled={(props.disabled || value.length < 1) && true}
                style={{
                    width: props.width,
                    marginBottom: props.margin,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight
                }}>

                <option value={0} disabled>{value.length < 1 ? 'Загрузка' : props.text}</option>

                {value.length !== 0 && props.key_name ?
                    getList().items.sort((a, b) => a[keyy] > b[keyy] ? 1 : -1).map((item, index) =>

                        <option
                            key={index}
                            value={props.new_value ? item[props.new_value] : item.id}
                        >
                            {props.new_key ? item[props.new_key] : item.name}
                        </option>

                    )
                    :
                    value.length !== 0 && getList().sort((a, b) => a[keyy] > b[keyy] ? 1 : -1).map((item, index) =>
                    props.optionsHours ?
                    <option key={index} defaultValue={0} value={item.id}>{item.name + ' (' + item.price_sum + 'тг за ' + item.hours_sum + (item.hours_type.id === 2 ? ' ак.ч.)' : ' астр.ч.)')}</option>
                    :
                    <option
                            key={index}
                            value={props.new_value ? item[props.new_value] : item.id}
                        >
                            {props.new_key ? item[props.new_key] : item.name}
                        </option>
                    )}

            </select>
        </div>
    )
}
