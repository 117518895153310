import { localisationMaster } from 'Localisation/Localisation';

export const mailing = [
    {
        link: 'email',
        folder: 'mailing',
        permission: ['sendpulseemails', 'view' ],
        title: localisationMaster('sub-title-company-9', 'index', 'sidebar_main'),
        colsSettings: {
            action: [
                {
                    name: localisationMaster('status_1', 'index', 'sidebar_main'),
                    action: 'update',
                    permission: ['sendpulseemails', 'edit']
                },
                {
                    name: localisationMaster('status_2', 'index', 'sidebar_main'),
                    action: 'show_email',
                    permission: ['sendpulseemails', 'add']
                },
            ],
            update_link: 'telegram/pulse/update/',
            prefix: 'telegram',
            link: 'pulse',
            id: { name: 'email_key' },
            main_rows: [
                {name: 'subject'},
                {name: 'email'},
                {name: 'delivery'}
            ],
            is_open: false,
            sub_rows: [
                { name: 'sent', description: localisationMaster('status_3', 'index', 'sidebar_main')},
                { name: 'open', description: localisationMaster('status_4', 'index', 'sidebar_main'),  function: 'openOrClick_email'},
                { name: 'click', description: localisationMaster('status_5', 'index', 'sidebar_main'), function: 'openOrClick_email'},
            ]
        },
        
        filterLiner: true,
        filterLinerLink: "telegram/pulse",
        config: [
            { id: 1, nameState: 'email', placeholder: 'E-mail', type: "input" },
            {
                id: 2,
                nameState: 'subject',
                placeholder: localisationMaster('status_6', 'index', 'sidebar_main'),
                type: "input"
            },
        ],
        apiSettings: {}
    },
    {
        link: 'sms',
        folder: 'mailing',
        permission: ['sendmobileservicemessage', 'view'],
        button: '',
        modal_type: '',
        modal_placeholder: '',
        title: localisationMaster('status_7', 'index', 'sidebar_main'),
        colsSettings: {
            action: [
                { name: localisationMaster('status_1', 'index', 'sidebar_main'), action: 'update',permission: ['sendmobileservicemessage', 'edit']},
            ],
            update_link: 'telegram/sms/update/',
            prefix: 'telegram',
            link: 'sms',
            id: { name: 'id' },
            main_rows: [
                {name: 'phone'},
                {name: 'sent'},
                {name: 'delivery'}
            ],
            is_open: false,
            sub_rows: [
                { name: 'message', description: localisationMaster('status_8', 'index', 'sidebar_main')},
            ]
        },
        
        filter: true,
        filterLink: "telegram/sms",
        config: [
            { id: 1, nameState: 'phone', placeholder: localisationMaster('status_9', 'index', 'sidebar_main'), type: "input" },
        ],
        apiSettings: {}
    },
]
