import React, {useState} from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import Filter from '../../../ContentBox/Global/Filter/Filter'
import Loader from "components/ContentBox/Global/Loader/Loader";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import styles from "./LoadingTeachers.module.scss"
import {dateFormat} from "helpers/date";
import {superAxiosRequest} from "axiosApi";
import LineChartTable from './LineChartTable/LineChartTable';
import ContentHeader from '../../../ContentBox/Global/ContentHeader';
import {ExcelRowsParams} from '../../../utils/ExcelExport';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);






const LoadingTeachers = () => {
    const [isLoading, setIsLoading] = useState({})
    const [groupCatalog, setGroupCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: groupCatalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },

        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: groupCatalog?.skill_levels,
            sort_key: 'name',
            nameState: 'skill_level',

        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: null, name: "Все группы"},
                {id: true, name: "Онлайн"},
                {id: false, name: "Офлайн"},
            ]
        },
    ]


    const [myData, setMyData] = useState(null)
    const [date, setDate] = useState(
        {
            from: new Date(new Date().setDate(1)),
            until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
        }
    )



    const getMaxHour = (maxMinute) => {
        let maxHour = Math.ceil((maxMinute / 45) / 10) * 10
        return maxHour > 10 ? maxHour : 10
    }

    const sendRequest = (param) => {
        if (param.date_until && param.date_from) {
            setIsLoading(true)
            let apiConf = {
                method: 'get',
                link: 'progress_report/teacher_loading',
                params: {
                    ...param
                }
            }
            delete apiConf.params.is_active
            if (apiConf.params.skill_level && apiConf.params.skill_level.length > 0) apiConf.params.skill_level = `[${apiConf.params.skill_level.map(item => item)}]`
            else delete apiConf.params.skill_level
            if (apiConf.params.course_level && apiConf.params.course_level.length > 0) apiConf.params.course_level = `[${apiConf.params.course_level.map(item => item)}]`
            else delete apiConf.params.course_level
            if (apiConf.params.location && apiConf.params.location.length > 0) apiConf.params.location = `[${apiConf.params.location.map(item => item)}]`
            else delete apiConf.params.location
            if(apiConf.params.online === null) delete apiConf.params.online
            if (apiConf.params.date_from) apiConf = {...apiConf, params: {...apiConf.params, date_from: dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")}}
            if (apiConf.params.date_until) apiConf = {...apiConf, params: {...apiConf.params, date_until: dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")}}
            superAxiosRequest(apiConf).then(res => {
                setDate({
                    from: new Date(apiConf.params.date_from),
                    until: new Date(apiConf.params.date_until)
                })
                let maxMinute = 0
                res.data.result.forEach(element => {
                    if (element.minutes > maxMinute) maxMinute = element.minutes
                })

                const maxHours = getMaxHour(maxMinute)
                const sectionsLenght = maxHours / 10
                const rowsArray = res.data.result.map(teacher => {
                    const nameEls = teacher.full_name.split(' ')
                    let shrtName = ''
                    nameEls.map((el, id) => id === 0 ? shrtName = el : shrtName = `${shrtName} ${el[0].toUpperCase()}.`)
                    return (
                        {
                            title: shrtName,
                            hours: (teacher.minutes / 45).toFixed(2),
                            lenght: (teacher.minutes / 45) / sectionsLenght,
                            user_id: teacher.user_id
                        })
                })
                setMyData({
                    settings: {
                        maxValue: maxHours,
                        interval: sectionsLenght
                    },
                    rows: rowsArray
                })
                setIsLoading(false)
            })
        }


    }
    const sendFerstReqest = () => {
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/teacher_loading'
        })
            .then(({data}) => {
                setGroupCatalog(data)
            })
            .catch((e) => console.log(e.message))
            .finally(() => setIsLoading(false))
    }

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Ф.И.О. преподавателя",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Колличиство ак.ч.",
                    ...ExcelRowsParams.headerRowParam
                }
            ]
           
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 50},
                {wch: 50},
            ];

            data.forEach((student) => {
                tableData.push([
                    {
                        v: `${student.title}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${student.hours}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Загрузка преподавателей.xlsx");
        })
    };
    console.log(myData)
    return (
        <div className={styles.page}>
            <ContentHeader title="Загрузка преподавателей" />


            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => sendFerstReqest()} />

            {isLoading ? <Loader />
                : myData && myData?.rows?.length > 0 ? <>
                    <LineChartTable
                        chartData={myData}
                        title={`Загрузка преподавателей в часах c ${timeConverter(date.from, 'day')} по ${timeConverter(date.until, 'day')}`}
                    />
                    <button onClick={() => {downloadExcel(myData.rows)}} style={{
                        margin: '10px 10px 0 ',
                        width: 'calc(100% - 20px)'
                    }}>Выгрузить в Excel</button>
                </> : <h1 className={styles.noData}>Нет данных для отображения!</h1>
            }
        </div>
    )
}

export default LoadingTeachers