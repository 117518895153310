import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import st from "./Pagination.module.scss";
import {ReactComponent as ArrowIcon} from "components/UI/icons/arrow-drop-right-line.svg";
import {ReactComponent as MoreIcon} from "components/UI/icons/more-line.svg";
import {ReactComponent as ArrowRightIcon} from "components/UI/icons/arrow-right-line.svg";


const Pagination = props => {

  const
    // Ограничение
    iLimit = props.limit,
    // Количество элементов
    iCount = props.count,
    // Всего страниц
    iTotalPages = Math.max(Math.ceil(iCount / iLimit), 1),
    // Текущая страница
    iPage = Math.min(props.page, iTotalPages),
    // Смещение
    iStart = (iPage - 1) * iLimit,

    offset = 1,
    separator = '...',

    pageLimits = [10, 20, 30, 40, 50]

  const [customPage, setCustomPage] = useState(iPage)

  const getPages = () => {
    const aRange = getRange()

    let aPage = [];
    let li;

    if (iPage > 1)
      aPage.push(
        <li key="prev"
            className={`${st.pageButton} ${st.pageButtonPrev}`}
            onClick={() => props.onChange(iPage - 1)}>
          <ArrowIcon/>
        </li>)

    aRange.forEach(i => {
      if (i === iPage)
        li = <li key={i} className={`${st.pageButton} ${st.pageButtonSelected}`}>{i}</li>
      else if (i === separator)
        li =
          <li key={separator + Math.random()} className={`${st.pageButton} ${st.pageButtonSep}`}><MoreIcon/></li>
      else
        li = <li key={i} className={st.pageButton} onClick={() => props.onChange(i)}>{i}</li>

      aPage.push(li)
    })

    if (iPage < iTotalPages)
      aPage.push(
        <li key="next" className={`${st.pageButton} ${st.pageButtonNext}`}
            onClick={() => props.onChange(iPage + 1)}>
          <ArrowIcon/>
        </li>)

    return aPage;
  }

  const getRange = () => {
    let left = Math.max(iPage - offset, 1);
    let right = Math.min(iPage + offset, iTotalPages);

    let startRange = [];
    if (left > 1) {
      for (let i = 1; i <= Math.min(offset, left - 1); i++)
        startRange.push(i)

      if (left - Math.max(...startRange) > 1)
        startRange.push(separator);
    }

    let middleRange = [];
    for (let i = left; i <= right; i++)
      middleRange.push(i)

    let endRange = [];
    if (iTotalPages > right) {
      for (let i = Math.max(iTotalPages - offset + 1, right + 1); i <= iTotalPages; i++)
        endRange.push(i)

      if (Math.min(...endRange) - right > 1)
        endRange.unshift(separator);
    }

    return [...startRange, ...middleRange, ...endRange];
  }

  const onChangeCustomPageHandler = value => {
    if (value) {
      value = Math.max(value, 1)
      value = Math.min(value, iTotalPages)
    }

    setCustomPage(value)
  }

  const onChangePageLimit = e => props.onChangePerPage(+e.target.value)

  useEffect(() => {
    setCustomPage(iPage)
  }, [iPage])

  if (iTotalPages < 2 && !props.show) return null

  return (
    <div className={st.pagination}>
      <div className={st.limitInfo}>
        <div className={st.selectLimit}>
          <select value={iLimit}
                  onChange={(e) => onChangePageLimit(e)}>
            {pageLimits.map(item =>
              <option key={item} value={item}>{item + ' / ' + (props.translate?.page || 'Page')}</option>)}
          </select>
        </div>
        <div className={st.info}>{iCount > 0 &&
          <span>{iStart + 1} - {Math.min(iStart + iLimit, iCount)} of <b>{iCount}</b></span>}
        </div>
      </div>
      <div className={st.pages}>
        <ul>
          {iTotalPages > 1 && getPages()}
        </ul>
      </div>
      {iTotalPages > 4 &&
        <div className={st.customPage}>
          <input
            type="number"
            value={customPage ? customPage : ""}
            min="1"
            max={iTotalPages}
            step="1"
            onChange={({target}) => onChangeCustomPageHandler(+target.value)}
          />
          <button onClick={() => customPage && props.onChange(customPage)}><ArrowRightIcon/></button>
        </div>}
    </div>
  )
}

Pagination.propTypes = {
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
}

export default Pagination
