import React from 'react'
//import {superAxiosRequest} from 'axiosApi';
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import swal from "sweetalert";
import { localisationMaster } from "../../../../Localisation/Localisation";
import { superAxiosRequest } from 'axiosApi';

export default function GroupUserUnit(props) {
    const {checkUser} = Stores;
    const {activity} = modalStore; 
    let dataStorage = localStorage.getItem('ud');
    var data = JSON.parse(dataStorage);
    const id = data.user_data.profile.id;
    const uid = data.user_data.id;

    const wkday = (weekdays) => {
        let days = [localisationMaster('text_22', 'groups/all-groups'),
        localisationMaster('text_23', 'groups/all-groups'),
        localisationMaster('text_24', 'groups/all-groups'),
        localisationMaster('text_25', 'groups/all-groups'),
        localisationMaster('text_26', 'groups/all-groups'),
        localisationMaster('text_27', 'groups/all-groups'),
        localisationMaster('text_28', 'groups/all-groups')];
        let ready = [];
        //let c;
        for (let i = 0; i < weekdays.length; i++) {
            ready.push(
                days[weekdays[i] - 1]
            )
        }
        let wkdays = ready.join('/')
        return wkdays
    }

    const rData = text => {
        const body = document.createElement("div")
        body.innerHTML += text
        body.style.lineHeight = '25px'
        return body
    }

    const addBalance = (grid) => {
        const body = {
            name: 'addPayment',
            link: 'payments/pay_orders',
            button: localisationMaster('button-two', 'company/profiles/account'),
            title: localisationMaster('title', 'pays/create-pay') + `${props.udata ?  ': ' + props.udata.full_name : ''}`,
            type: 'addPayment',
            data: 'json',
            width: '650px',
            client_id: props.id_user,
            ...(checkUser() && {func: (res) => window.location.href = '/pays/invoice/' + res.order_id})
        }
        activity(body, {status_sending_mail: 'need_mail', amount: 500000, student_group_rel: grid})
    }

    const text = `${localisationMaster('text_38', 'groups/all-groups')} <br /><strong>${props.items.company.name} ${props.items.course_level.name} - ${props.items.name}</strong> <br />
    ${localisationMaster('text_39', 'groups/all-groups')}<br />
    <strong>${wkday(props.items.schedules[0].weekdays)} с ${props.items.schedules[0].start_at.substr(0, 5)}, 
    до ${props.items.schedules[0].finish_at.substr(0, 5)}.</strong> <br />`

    const stesxt = `Вы успешно записались в группу!<br />
                    Ваша группа:<br /><strong>${props.items.number} - ${props.items.name}</strong><br />   
                    Время занятий:<br /><strong>${wkday(props.items.schedules[0].weekdays)}, ${props.items.schedules[0].start_at.substr(0, 5)}-${props.items.schedules[0].finish_at.substr(0, 5)}</strong><br />
                    Необходимо произвести оплату за группу в течении 3-х дней, не менее 5 000 тг. <br /> 
                    Пополнить баланс можно на странице профиля.`

    const atext = `${props.udata?.full_name || 'Клиент'} записан в группу!<br />
                     Группа:<br /><strong>${props.items.number} - ${props.items.name}</strong><br />
                     Время занятий:<br /><strong>${wkday(props.items.schedules[0].weekdays)}, ${props.items.schedules[0].start_at.substr(0, 5)}-${props.items.schedules[0].finish_at.substr(0, 5)}</strong><br />`

    const addToGroup = (idGroup, idClient) => {
        swal({
            title: localisationMaster(id !== props.id_user ? 'text_472' : 'text_47', 'groups/all-groups'),
            content: rData(text),
            //icon: "warning",
            buttons: [localisationMaster('button_2', 'alert'), localisationMaster('button_1', 'alert')],
            dangerMode: false,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let apiConf = {
                        method: 'post',
                        link: 'groups/add_client_to_group',
                    }
                    let body = {
                        group_id: idGroup,
                        client_id: idClient
                    }
                    superAxiosRequest(apiConf, body).then((res) => {
                        const grid = res.data.id
                        swal({
                            // title: localisationMaster(id !== props.id_user ? 'text_472' : 'text_47', 'groups/all-groups'),
                            content: rData(id !== props.id_user ? atext : stesxt),
                            buttons: [
                                localisationMaster('button-one', 'modal-profile'),
                                localisationMaster('balance-button', 'pays/all-pays')],
                            icon: "success",
                        }).then((add) => {
                            if (!add) return;
                            addBalance(grid)
                        })
                        props.drop()
                    }).catch(err => {
                        swal(err.response.data.error, {
                            icon: "error",
                        });
                    })

                } else {
                    swal(localisationMaster('canceled', 'alert'))
                }
            })
    }

    const lastSchedules = (data, location) => {
        if (data.length > 0) {
            /*   let hours = data[0].hours_total
              let hour_type = data[0].hour_type.short_name */
            let time = data[0].start_at.substr(0, 5) + '-' + data[0].finish_at.substr(0, 5)
            let days_sch = data[0].weekdays
            let wkdays = wkday(days_sch)
            return `${time} | ${wkdays}| | ${location}`
        } else {
            return localisationMaster('text_48', 'groups/all-groups')
        }
    }
    return (
        <div className="user-group-listing-unit" style={props.items.registered ? { backgroundColor: '#d6eaea' } : null}>
            <div className="user-group-listing-unit-info">
                <div className="user-group-listing-unit-info-header">
                    <span>{props.items.company.name + ' ' + props.items.course_level.name}&nbsp; - &nbsp;</span>
                    <span>{props.items.name}</span>
                </div>
                <div className="user-group-listing-unit-info-content"><span>{localisationMaster('text_49', 'groups/all-groups')}</span>
                    <span>{lastSchedules(props.items.schedules, props.items.location.name)}
                        {props.items.online ? ' | ОНЛАЙН' : ' | ШКОЛА'}
                    </span>
                </div>
            </div>
            <div className="user-group-listing-unit-button ">
                <button className={props.items.registered ? "disabled" : null}
                    onClick={!props.items.registered ? () => addToGroup(props.items.id, props.id_user, props.items) : null}>
                    {localisationMaster('text_50', 'groups/all-groups')}
                </button>
            </div>
        </div>
    )
}
