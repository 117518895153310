import React, {useState} from "react";
import {dict, translate as loc} from "Localisation";
import {checkPermList, findLast} from "helpers";
import {permissions} from "settings/permissions";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
//import Stores from "Stores";
import ContractDocs from "./ContractDocs";
import modalStore from "Stores/modalStore";
import ContractPlans from "components/ContentBox/Global/GlobalModalSingle/templates/WorkPlain/ContractPlans";
import usersStore from "Stores/usersStore";
import st from "./EmployeeContract.module.scss";
import {dateFormat} from "helpers/date";
import VacationInfo from "./VacationInfo";

const EmployeeContract = ({contract, user, translate}) => {

  const menu = [
    {name: translate.contract_details, action: 'detail'},
    ...(checkPermList(permissions.add_contracts) ?
        [
          {name: translate.edit, action: 'edit'},
          {name: translate.to_extend_the_contract, action: 'prolong'},
          {name: translate.change_the_clock, action: 'plans'}
        ] : [])
  ]

  const {userContracts} = usersStore;

  const [open, setOpen] = useState(false)

  //const retType = index => [loc(dict.contract.text_15), loc(dict.contract.text_16), loc(dict.contract.text_17)][index - 1]

  const formatTime = min => Math.trunc(min / 60) + (min % 60 && ':' + min % 60)

  const action = (data, e, item) => {
    let ms
    switch (data) {
      case 'detail':
        setOpen(!open)
        break;
      case 'edit':
        ms = {
          name: 'employee_contract',
          link: 'employees/contract/' + item.id,
          button: loc(dict.main_page.text_30),
          title: loc(dict.contract.text_12),
          type: 'employee_contract',
          data: 'json',
          is_put: true,
          profile_id: user.profile.id
        }
        modalStore.activity(ms, item)
        break;
      case 'prolong':
        ms = {
          name: 'employee_contract',
          link: 'employees/contract',
          button: loc(dict.main_page.text_30),
          title: loc(dict.contract.text_13),
          type: 'employee_contract',
          data: 'json',
          profile_id: user.profile.id,
          is_prolong: true
        }
        modalStore.activity(ms, findLast(userContracts))
        break;
      case 'plans':
        ms = {
          title: "Учебные и тестовые планы по контракту " + item.title,
          modalBodyComponent: () => <ContractPlans/>,
          button: loc(dict.main_page.text_30),
          is_put: true,
          link: 'employees/contract_plans/' + item.id,
        }
        modalStore.activity(ms, {
          test_plan_mins: item.test_plan_mins,
          hour_type: item.hour_type,
          teaching_plan_mins: item.teaching_plan_mins,
          teaching_plan_hour_type: item.teaching_plan_hour_type,
        })
        break;
      default:
        break;
    }
  }

  return (
    <div className={st.employeeContract}>
      <header className={st.header}>
        <h4>{contract.title}</h4>
          <div>
            <ActionMenu menu={menu} action={action} items={contract}/>
          </div>
      </header>

      {open &&
        <div>
          <div className={st.body}>
            <div className={st.bodyMain}>
              <ul className={st.params}>
                <li className={st.param}>
                  <span>{loc(dict.contract.text_3)}</span>
                  <time>{dateFormat(contract.date_from, "dd MMMM YYYY")}</time>
                </li>

                <li className={st.param}>
                  <span>{loc(dict.contract.text_4)}</span>
                  <time>{dateFormat(contract.date_until, "dd MMMM YYYY")}</time>
                </li>

                {contract.vacation_mins &&
                  <li className={st.param}>
                    <span>{loc(dict.work_table_listing.working_day)}:</span>
                    <span><b>{formatTime(contract.vacation_mins)}</b> {loc(dict.contract.text_8)}</span>
                  </li>}

                {contract.vacation_days &&
                  <li className={st.param}>
                    <span>{loc(dict.work_table_listing.vacation_days)}:</span>
                    <span><b>{+contract.vacation_days}</b> {loc(dict.contract.text_9)}</span>
                  </li>}

                {!!contract.test_plan_mins &&
                  <li className={st.param}>
                    <span>Онлайн запись:</span>
                    <span>
                <b>{`${contract.test_plan_mins / contract.hour_type}`}</b>{` ${contract.hour_type === 45 ? 'ак.' : 'астр.'}ч. в месяц`}
              </span>
                  </li>}

                {!!contract.teaching_plan_mins &&
                  <li className={st.param}>
                    <span>Учебный план:</span>
                    <span>
                <b>{`${contract.teaching_plan_mins / contract.teaching_plan_hour_type}`}</b>{` ${contract.teaching_plan_hour_type === 45 ? 'ак.' : 'астр.'}ч. в месяц`}
              </span>
                  </li>}
              </ul>
              <VacationInfo translate={translate} contract={contract}/>
            </div>
            {contract.comment &&
              <div className={st.comment}>{contract.comment}</div>}
          </div>
          <ContractDocs employeeID={contract.employee_id} contractID={contract.id}/>
        </div>}

      {/*{(contract.salary_type || contract.vacation_days || contract.comment) &&
        <div>{contract.salary_type &&
            <div>
              <div>{loc(dict.contract.text_5)}:</div>
              <span>
                {contract.salary_type ? retType(contract.salary_type) : loc(dict.main_page.text_20)}
                {!!+contract.salary_amount && `, ${Stores.cleanSumm(+contract.salary_amount)} ${loc(dict.contract.tg)}.`}
              </span>
            </div>}
        </div>}*/}
    </div>
  )
}

export default EmployeeContract
