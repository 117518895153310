import React, {useState, useEffect} from 'react';
import Checkbox from 'components/ContentBox/Global/Checkbox';
import {localisationMaster} from 'Localisation/Localisation';
import {classNames as cn} from "helpers";
import st from './Variants.module.scss';
import VariantAdd from "../TestQuestion/components/VariantAdd";
import VariantDel from "../TestQuestion/components/VariantDel";

const Variants = (props) => {
    const [list, setList] = useState([
        {[props.value_name || 'value']: '', is_correct: false},
        {[props.value_name || 'value']: '', is_correct: false}
    ])

    let Link = 'tests/questions'
    let Tag = 'question_plus_variants'

    const chckbx = (e, pkn, index) =>{
        let arr = list
        arr[index].is_correct = !arr[index].is_correct
        setList([...arr])
    }

    const changeHandler = (e, index) =>{
        let arr = list
        arr[index][props.value_name || 'value'] = e.target.value
        setList([...arr])
    }

    const addLine = () =>{
        let arr = list
        arr.push({[props.value_name || 'value']: '', is_correct: false})
        setList([...arr])
    }

    const deleteLine = (index) =>{
        let arr = []
        for(let key in list) +key !== index && arr.push(list[key])

        setList([...arr])
    }

    useEffect(() => {
      props.och(list, props.name || 'answers')
    }, [list])

    useEffect(() => {
       if(!props.data || props.data.length < 1) return;
       setList([...props.data]);
    }, [])

    return (
        <div className={st.box}>
            <div className={st.title}>
                <div>{localisationMaster('text-info-one', Link, Tag)}</div>
                <div>{localisationMaster('text-info-two', Link, Tag)}</div>
            </div>

            <div className={st.variants}>{list.map((item, index) => {
                const key = props.value_name || 'value';
                return (
                    <div className={st.variant} key={index}>
                        <Checkbox
                            id={`variant${index}`}
                            checked={item.is_correct}
                            isChecked={(e, pkn) => chckbx(e, pkn, index)}
                            error={props.error && !list.some(x => x.is_correct)}
                        />
                        <input
                            value={list[index][key]}
                            onChange={(e) => changeHandler(e, index)}
                            className={cn({
                                "super-modal__input--error": props.error && (!item[key].length || list.filter(x => x[key] === item[key]).length >= 2)
                            })}
                        />
                        {list.length > 2 &&
                        /*<div className={st.delete} onClick={() => deleteLine(index)}>&#215;</div>}*/
                        <VariantDel onClick={() => deleteLine(index)}/>}
                    </div>
                )
            })}

            </div>

            <VariantAdd onClick={addLine}/>
        </div>
    )
};

export default Variants
