import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react";
import {msBook} from "../settings";
import {permissions} from "settings/permissions";
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import useLocalization from "hooks/useLocalization";
import Stores from "Stores"
import asideModalStore from "Stores/asideModalStore";
import AsideFilterStore from "components/UI/AsideFilter/asideFilterStore";
import Book from "./components/Book";
import Loader from "components/UI/Loader/Loader";
import SearchBooks from "./components/SearchBooks/SearchBooks";
import TabMenu from "../Common/TabMenu/TabMenu";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import {HeaderAddButton} from "components/UI/SuperButton/SuperButton";
import PageHeader from "components/UI/PageHeader/PageHeader";
import AsideFilter from "components/UI/AsideFilter/AsideFilter";
import FilterTextValues from "components/UI/AsideFilter/FilterTextValues";
import st from "./Books.module.scss";
import AddBookByCode from "../../../ContentBox/Global/GlobalModalSingle/templates/Ebooks/AddBookByCode";

import useLocalStorageFilter from "helpers/useLocalStorageFilter";


const Books = () => {
  const ud = JSON.parse(localStorage.getItem('ud'))
  const userID =  ud.user_data.profile.id
  const isClient = Stores.isClient

  const {isOpenFilter, closeFilter, setTextValues, changeTextValue, filterTextValues} = AsideFilterStore

  const [filter, updateFilter] = useLocalStorageFilter()
  const [books, setBooks] = useState([])
  const [tabID, setTabID] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(12) //первоначальное количество книг
  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'ebooks_books',
    dict: {
      "e_books": "E-Books",
      "add_new_book": "Add New Book",
      "adding_a_book": "Adding a Book",
      "search_for_e_books": "Search for e-books",
      "full_catalog": "Full Catalog",
      "personal_e_books": "Personal E-Books",
      "past_lessons": "Past Lessons",
      "books_empty": "You don't have any books yet",
      "load_more": "Load More",
      "edit": "Edit",
      "copy": "Copy",
      "delete": "Delete",
      "save": "Save",
      "filter": "Filter",
      "company": "Company",
      "select_company": "Select Company",
      "education_type": "Education Type",
      "select_education_type": "Select Education Type",
      "group_level": "Group Level",
      "select_group_level": "Select Group Level",
      "reset_all": "Reset all",
      "apply": "Apply",
      "search": "Search",
      "no_books": "No Books",
      "copy_book" : "Copy the book",
      "my_books" : "My Books",
      "adding_book" : "Adding a Book",
      "book_number" : "Book Number",
      "additional_materials": "Additional materials"
    }
  })

  const tabListMenu = {
    1: [
      {
        name: translate.edit,
        action: 'edit',
        permission: permissions.change_ebook_in_catalog
      },
      {
        name: translate.delete,
        action: 'delete',
        permission: permissions.delete_ebook_from_catalog
      },
      {
        name: translate.copy,
        action: 'copy',
        permission: permissions.copy_ebook_in_catalog
      }
    ],
    2: [
      {
        name: translate.edit,
        action: 'edit',
        permission: permissions.change_ebook
      },
      {
        name: translate.delete,
        action: 'delete',
        permission: permissions.delete_ebook
      },
      {
        name: translate.copy,
        action: 'copy',
        permission: permissions.copy_ebook
      }
    ],
  }

  const history = useHistory()

  const filterCfg = [
    {
      label: translate.search_for_e_books,
      nameState: 'search',
      type: 'input',
      placeholder: `${translate.search_for_e_books}...`
    },
    !isClient ?
        {
          apiLink: 'db/companies',
          type: "select",
          nameState: 'company',
          label: translate.company,
          placeholder: translate.select_company
        } : {},
    {
      type: "select",
      apiLink: 'groups/groups_catalog',
      key_name: 'course_levels',
      nameState: 'course_level',
      label: translate.education_type,
      placeholder: translate.select_education_type
    },
    {
      type: "select",
      nameState: 'skill_level',
      label: translate.group_level,
      placeholder: translate.select_group_level,
      apiLink: 'groups/groups_catalog',
      key_name: 'skill_levels',
      sort_key: 'name',
      is_search: true
    },
  ]

  const createTabs = () => [
    {id: 1, name: isClient ? translate.my_books : translate.full_catalog},
    ...(isClient ? [] : [{id: 2, name: translate.personal_e_books}]),
    {id: 3, name: translate.past_lessons, link: `/ebooks/${isClient ? 'my-' : ''}past-lessons`}
  ]

  const addBook = () => {
    const ms = {
      ...msBook,
      title: translate.adding_a_book,
      button: translate.save,
      onSubmitSuccess: data => setBooks([data, ...books])
    }
    asideModalStore.activity(ms)
  }

  // Добавление книги клиентом по коду
  const addMyBook = () => {
    const ms = {
      title: 'Adding a Book',
      modalBodyComponent: () => <AddBookByCode />,
      valid: {
        ebook_id: 'required',
      },
      button: 'Add Book',
      link: 'ebooks/book_add_by_code',
    }
    asideModalStore.activity(ms, {
      client: userID,
    })
  }

  const fetchBooks = useCallback(() => {
    setIsLoading(true);

    if (isClient) {
      const requests = [
        superAxiosRequest({
          method: 'get',
          link: 'ebooks/students_ebooks',
          ...(checkPermList(permissions.delete_student_book) && {params: {user_id: ud.user_data.id, ...filter}})
        }),
        superAxiosRequest({
          method: 'get',
          link: 'ebooks/students_ebooks_qr',
          ...(checkPermList(permissions.delete_student_book) && {params: {user_id: ud.user_data.id, ...filter}})
        }),
      ];
      Promise.all(requests)
          .then(results => {
            const combinedBooks = results.reduce((acc, res) => acc.concat(res.data), []);
            setBooks(combinedBooks);
          })
          .catch(() => {
            setBooks([]);
          })
          .finally(() => setIsLoading(false));
    } else {
      superAxiosRequest({
        method: 'get',
        link: 'ebooks/books',
        params: {...filter, filter_type: tabID, limit: limit},
      })
          .then(res => {
            setBooks(res.data.result || []);
          })
          .catch(() => {
            setBooks([]);
          })
          .finally(() => setIsLoading(false));
    }
  }, [isClient, ud.user_data.id, filter, tabID, limit]);


  const onChangeTab = tab => {
    if (tab.link){
      history.push(tab.link)
    } else {
      setTabID(tab.id)
    }
  }

  const handleLoadMore = () => {
    setLimit(999)
  }

  useEffect(() => {
    return AsideFilterStore.unmountFilter
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "filter") {
        const storedFilter = localStorage.getItem("filter")
        updateFilter(storedFilter ? JSON.parse(storedFilter) : {})
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [updateFilter])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      // Проверяем, если произошел переход на другую страницу
      if (location.pathname !== '/ebooks/books') {
        updateFilter({});
      }
    })

    return () => {
      unlisten(); // Отменяем прослушивание при размонтировании компонента
    }
  }, [history, updateFilter])

  useEffect(() => {
    fetchBooks()

  }, [fetchBooks])

  return (
      <div className={st.container}>
        <PageHeader title={translate.e_books}>
          {checkPermList(permissions.add_ebook_book) &&
              <HeaderAddButton title={translate.add_new_book || ''} onClick={addBook}/>}
          {isClient &&
              <HeaderAddButton title={translate.add_new_book || ''} onClick={addMyBook}/>}
        </PageHeader>

        <div className={st.booksContainer}>
          {!(isClient && books.length === 0) &&
              <SearchBooks
                  onSearch={text => {
                    updateFilter({...filter, search: text})
                    changeTextValue('search', {
                      key: 'search',
                      label: translate.search_for_e_books,
                      value: text
                    })
                  }}
                  onClear={() => {
                    const {search, ...obj} = filter
                    updateFilter(obj)
                    setTextValues(filterTextValues.filter(field => field.key !== 'search'))
                  }}
                  translate={translate}
                  placeholder={`${translate.search_for_e_books}...`}
                  value={filter?.search || ''}
              />}

          {!!filterTextValues.length && <FilterTextValues onDelete={key => {
            const {[key]: _, ...obj} = filter
            updateFilter(obj)
          }}/>}

          {isOpenFilter && <AsideFilter
              translate={translate}
              getFilterParams = {updateFilter}
              config = {filterCfg}
              filterValue={filter}
              onDropFilter={() => {
                updateFilter({})
                closeFilter()
              }}
          />}

          <TabMenu tabs={createTabs()} onChange={onChangeTab} tabID={tabID}/>

          {isLoading ? <Loader/> :
              books.length > 0 ?
                  <div className={st.list}>{books.map(book =>
                      <Book key={book.id}
                            book={book}
                            books={books}
                            setBooks={setBooks}
                            fetchBooks={fetchBooks}
                            translate={translate}
                            listMenu={tabListMenu[tabID]}
                      />)}

                  </div>
                  :
                  <WithoutExercises text1={`${translate.no_books} :(`} text2={translate.books_empty}/>}
          {books.length === 12 &&
              <button className={st.loadMoreBtn} onClick={handleLoadMore}>{translate.load_more}</button>}
        </div>
      </div>
  )
}

export default observer(Books)