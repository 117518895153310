import React from 'react'
import cn from './InfoCardStudent.module.scss';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";


export default function InfoCardStudent(props) {
    const {
        balance,
        discount,
        surcharge,
        default_price_list,
        discount_list,
        surcharge_list,
        certificates,
        ...student
    } = props.student

    return (
        <div className={cn.box}>
            <div className={cn.studentInfo}>
                <div className={cn.additionalInfo}>
                    <div className={cn.item}>
                        <span className={cn.item__name}>ID:</span>
                        <span className={cn.item__value}>{student.user_id}</span>
                    </div>

                    <div className={cn.item}>
                        <span className={cn.item__name}>Phone:</span>
                        <span className={cn.item__value}>{student.phone}</span>
                    </div>
                    {student.whatsapp && <div className={cn.item}>
                        <span className={cn.item__name}>WhatsApp:</span>
                        <span className={cn.item__value}>{student?.whatsapp}</span>
                    </div>}
                    {student.telegram && <div className={cn.item}>
                        <span className={cn.item__name}>Telegram:</span>
                        <span className={cn.item__value}>{student.telegram}</span>
                    </div>}
                    <div className={cn.item}>
                        <span className={cn.item__name}>Start Date:</span>
                        <span className={cn.item__value}>{timeConverter(student.start_study_at, 'age')}</span>
                    </div>
                </div>
            </div>

        </div>
    )
}
