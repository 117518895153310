import React from 'react';
import { observer } from 'mobx-react';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import TextArea from 'components/UI/TextArea/TextArea';
import slotsRecordCustomerStore, { DataType } from 'Stores/slotsRecordCustomerStore';
import styles from './PaymentComment.module.scss';

const MIN_COMMENT_SIZE = 30;

const Label = () => (
  <span className={styles.label}>
    <strong>Подтвердить оплату тестирования, </strong>обязательно заполните комментарий
  </span>
);

function PaymentComment() {
  const { isComment, isPaymentOrder, comment, setData } = slotsRecordCustomerStore;

  const handleChange = () => {
    setData(!isComment, DataType.isComment);
    setData(!isPaymentOrder, DataType.isPaymentOrder);
  };

  return (
    <>
      <InputToggle
        label={<Label />}
        selected={isComment}
        onChange={handleChange}
        withMargin
      />
      { isComment &&
        <>
          <TextArea
            label='комментарий'
            name='payment_comment'
            placeholder='Комментарий о факте оплаты за тестирование'
            value={comment}
            onChange={(evt) => setData(evt.target.value, DataType.comment)}
            withLabel={false}
            isError={comment.length < MIN_COMMENT_SIZE}
          />
          <p className={styles.comment_description}>
            Длина комментария не менее {MIN_COMMENT_SIZE} символов
          </p>
        </>
      }
    </>
  );
}

export default observer(PaymentComment);
