import React from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import { maskPhone } from 'helpers/phoneMask';
import { getShiftedTime } from 'components/Modules/Slots/utils';
import slotsRecordCustomerStore from 'Stores/slotsRecordCustomerStore';
import LocalStorage from 'services/LocalStorage';
import Stores from 'Stores';
import styles from './ClientAbout.module.scss';
import img1 from "../../../../../ContentBox/img/clientAbout-01.svg"
import img2 from "../../../../../ContentBox/img/clientAbout-02.svg"
import img3 from "../../../../../ContentBox/img/clientAbout-03.svg"
import img4 from "../../../../../ContentBox/img/clientAbout-04.svg"

const getPaymentStatus = (status) => {
  switch (status) {
    case 'not_paid':
      return 'Не оплачен';
    case 'paid':
      return 'Оплата прошла успешно';
    case 'cancelled':
      return 'Ордер отменен';
  }
};

function ClientAbout() {
  const { overwritingClientData, selectedLocation, selectedProgram, selectedAge } = slotsRecordCustomerStore;
  const { client, slot, employee } = overwritingClientData;
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;
  const shiftedTime = getShiftedTime(`${slot.start_date}T${slot.start_time}`, timeZone, 'dd MMMM YYYYг.');

  return (
    <Paper>
      <SectionTitle title='Данные текущей записи' secondary />
      <Flex tag='ul' align='start' withGap>
        <li className={styles.item}>
          <div className={styles.image_wrapper}>
            <img src={img1} width={60} alt='' />
          </div>
          <p className={styles.title}>{client.full_name}</p>
          {client.phone &&
            <p>
              <a className={styles.link} href={`tel:${client.phone}`}>{maskPhone(client.phone)}</a>&nbsp;
              (<a className={styles.link} href={`https://wa.me/${client.phone.slice(1)}`}>WhatsApp</a>)
            </p>
          }
          {client.email &&
            <p>
              <a className={styles.link} href={`mailto:${client.email}`}>{client.email}</a>
            </p>
          }
          {slot.add_field1 && <p>{slot.add_field1}</p>}
          {slot.add_field2 && <p>{slot.add_field2}</p>}
          {slot.add_field3 && <p>{slot.add_field3}</p>}
        </li>

        <li className={styles.item}>
          <div className={styles.image_wrapper}>
            <img src={img2} width={60} alt=''/>
          </div>
          <p className={styles.title}>
            {shiftedTime.time}, {shiftedTime.date}
          </p>
          <p>
            {slot.is_online && 'Онлайн | '}{selectedLocation.label}
          </p>
          <p>
            {employee.employee_name}
          </p>
        </li>

        <li className={styles.item}>
          <div className={styles.image_wrapper}>
            <img src={img3} width={60} alt=''/>
          </div>
          {slot.payment_status &&
            <p>
              {getPaymentStatus(slot.payment_status)}
            </p>
          }
          <p>
            Ордер: {slot.order_id || 'нет'}
            {slot.payment_sum && `, Сумма: ${slot.payment_sum / 100} тг.`}
          </p>
          {slot.order_id &&
            <p>
              (<a className={styles.link} href={`${Stores.baseLink()}/pays/invoice/${slot.order_id}`} target='_blank' rel='noopener noreferrer'>
                посмотреть ордер
              </a>)
            </p>
          }
        </li>

        <li className={styles.item}>
          <div className={styles.image_wrapper}>
            <img src={img4} width={60} alt='' />
          </div>
          {slot.start_level &&
            <p className={styles.title}>
              {slot.start_level}
            </p>
          }
          <p>
            {selectedProgram.label}
          </p>
          <p>
            {selectedAge.label}
          </p>
        </li>
      </Flex>
    </Paper>
  );
}

export default observer(ClientAbout);
