import React from 'react';
import PropTypes from 'prop-types';
import st from './GroupColumn.module.scss'
import VariantDel from "../../../StudyTest/TestQuestion/components/VariantDel";

const GroupColumn = props => {
    const isErrorWord = props.error && !props.value.value
    const isErrorDesc = props.error && !props.value.description

  return (
      <div className={`${st.column} ${props.error &&  props.values.length < 2 ? st.error : ''}`}>
          <div className={st.header}>
              <h3>{props.translate.column} {props.index + 1}</h3>
              {props.index > 0 &&
                  <VariantDel onClick={props.onDelete}/>}
          </div>

          <input type="text" value={props.value.value} placeholder={props.translate.words}
                 onChange={e => props.onChange({...props.value, value: e.target.value})}
                 className={isErrorWord ? st.error : ''}
          />

          <input type="text" value={props.value.description} placeholder={props.translate.column_title}
                 onChange={e => props.onChange({...props.value, description: e.target.value})}
                 className={isErrorDesc ? st.error : ''}
          />
      </div>
  )
}

GroupColumn.propTypes = {
  index: PropTypes.number,
  value: PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default GroupColumn
