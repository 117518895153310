import {observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from 'Localisation/Localisation';
import Stores from 'Stores';
import modalStore from 'Stores/modalStore';
import usersStore from 'Stores/usersStore';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu'
import './Certificates.scss';

export const Certificates = (props) => {
  const {userCertObject, setUserCerts, addCert, deleteUserCert} = usersStore

  const addCertificate = () => {
    let conf = {
      name: 'certificate-to-user',
      type: 'SelectorAndInput',
      title: 'Добавить сертификат',
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: 'groups/add_cert_to_student',
      data: 'json',
      active_inputs: ['select'],
      name_select: 'cert',
      //value_key: 'cert',
      placeholder: 'Название сертификата',
      //api_link: 'groups/certificates',
      sort_key: 'name',
      is_search: true,
      func: addCert,
    }
    let innerData = {
      student: props.id,
      start_at: timeConverter(new Date(Date.now()), 'today')
    }
    superAxiosRequest({method: 'get', link: 'groups/certificates', params: {limit: '999'}})
      .then(res => {
          conf.select_array = res.data.result
          modalStore.activity(conf, innerData)
        }
      )
  }

  const menu = [
    {name: 'Удалить сертификат', action: 'delete', permission: ['forward']},
  ]

  const action = (data, e, items) => {
    switch (data) {
      case 'delete':
        superAxiosRequest({
          link: 'groups/del_cert_from_student/' + items.id,
          method: 'delete'
        })
          .then(() => deleteUserCert(items.id))
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setUserCerts(props.id)
  }, [])

  return (
    <div className="employeeTabContract">{userCertObject.map(item => (
      <div className="certificates_line" key={item.id}>
        <div className="certificates_line_title">
          <span/>
          <span>{item.certificate.name}</span>
        </div>
        <div className="certificates_line_companies">
          {
            item.certificate.companies.map(company => {
              return (
                <span>{company.name}</span>
              )
            })
          }
        </div>
        <div className="certificates_line_sum">
          <span>{Stores.cleanSumm(item.certificate.amount)}</span>
          <span>тг.</span>
        </div>
        <div className="certificates_line_until">
          <span>Действителен до</span>
          <span>{timeConverter(item.end_at, 'age')}</span>
        </div>
        <ActionMenu menu={menu} action={action} items={item}/>
      </div>
    ))}
      <footer>
        <span onClick={addCertificate}>Добавить сертификат</span>
      </footer>
    </div>
  )
}

export default observer(Certificates)
