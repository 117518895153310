import React from 'react';
import PropTypes from 'prop-types';
import st from "./AudioFiles.module.scss";
import AudioFile from "./AudioFile";

function AudioFiles(props) {
  const onChange = val => {
    props.onChange(val, 'items')
  }

  const action = (type, pl) => {
    switch (type) {
      case 'add':
        onChange([...props.files, {file: null, description: '', data: ''}])
        onChange([...props.files, pl])
        break;
      case 'change':
        onChange(props.files.map((f, i) => i === pl.index ? pl.value : f))
        break;
      case 'delete':
        if (props.files.length === 1) {
          onChange(props.files.map((f, i) => i === pl.index ? {...f, file: null, description: '', data:''} : f))
          break;
        }
        onChange(props.files.filter((f, i) => i !== pl.index))
        break;
      default:
        break;
    }
  }

  const remove = (index) => {
    action('delete',{index})
  }

  return (
      <>
        <div className={st.audioFiles}>
          {
            props.files.map((item, index) =>
          <div className={st.row} key={item.id || `new${index}`}>
            <AudioFile
                isError={props.isError}
                value={item}
                onChange={value => action('change', {index, value})}
                allowedExtension={props.allowedExtension}
                type={props.type}
                accept={props.accept}
                remove={() => remove(index)}

            />

          </div>
            )
          }
        </div>

        {
          props.files.at(-1).file &&
            <AudioFile
                value={{file:'', data:'',description:''}}
                onChange={(file) => action('add', file)}
                files={props.files}
                allowedExtension={props.allowedExtension}
                accept={props.accept}
                type={props.type}
            />
        }

      </>
  );
}

AudioFiles.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
}

AudioFiles.defaultProps = {
  files: []
}

export default AudioFiles
