import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import { localisationMaster } from "Localisation/Localisation";
import styles from './WorkingHoursNotice.module.scss';

function WorkingHoursNotice({startAt, endAt, timeZone, address, classes, withTopOffset, fullAddress}) {
  return (
    <p className={cn({
      [styles.text]: true,
      [styles.offset]: withTopOffset,
      [classes]: classes,
    })}>
      <strong>{localisationMaster('warning', 'teacher_schedule')}: </strong> {localisationMaster('working_location', 'teacher_schedule')}
      <strong> {`${startAt} ${localisationMaster('text_15', 'company/locations')} ${endAt}`}</strong> {localisationMaster('in_timezone', 'teacher_schedule')}:
      <strong> {`(GMT+${timeZone}) ${fullAddress ? address : address.split(', ', 1).join('')}`}</strong>
    </p>
  );
}

WorkingHoursNotice.propTypes = {
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  timeZone: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  classes: PropTypes.string,
  withTopOffset: PropTypes.bool,
  fullAddress: PropTypes.bool,
};

WorkingHoursNotice.defaultProps = {
  withTopOffset: true,
  fullAddress: false,
};

export default WorkingHoursNotice;
