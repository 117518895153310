import React from 'react';
import PropTypes from 'prop-types';
import {classNames as cn} from '../../../helpers';
import styles from './Button.module.scss';

const Button = ({
  children,
  type,
  variant,
  isWide,
  isDisabled,
  onClick,
  classes,
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={isDisabled}
    className={cn({
      [styles.button]: true,
      [styles.outlined]: variant === 'outlined',
      [styles.secondary]: variant === 'secondary',
      [styles.white]: variant === 'white',
      [styles.accent]: variant === 'accent',
      [styles.wide]: isWide,
      [classes]: classes,
    })}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['outlined', 'secondary', 'white', 'accent']),
  isWide: PropTypes.bool,
  classes: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  variant: 'outlined',
  isWide: false,
  classes: '',
};

export default Button;
