import React from 'react';
import EmployeeProfile from "components/Modules/User/Employee/EmployeeProfile";
import ClientProfile from 'components/Modules/User/Client/ClientProfile';

export default function Hello() {

  const {user_data} = JSON.parse(localStorage.getItem('ud'));

  const role = user_data.groups[0].name

  return role !== 'clients' ? <EmployeeProfile main={user_data.id}/> : <ClientProfile main={user_data.id}/>
}
