import React from 'react';
import { observer } from 'mobx-react';
import SurfMenu from 'components/ContentBox/Groups/components/SurfMenu';
import slotsHistoryListingStore from 'Stores/slotsHistoryListingStore';
import { translate as loc, dict } from "Localisation";


function TabSection() {
  const { activeTab, setActiveTab, tabsCount } = slotsHistoryListingStore;

  const tabs = [
    { name: loc(dict.history_clients.all_entries), link: 'all', count: tabsCount?.all || 0 },
    { name: loc(dict.history_clients.future_entries), link: 'future', count: tabsCount?.future || 0 },
    { name: loc(dict.history_clients.missed_entries), link: 'missed', count: tabsCount?.missed || 0 },
    { name: loc(dict.history_clients.past_entries), link: 'past', count: tabsCount?.past || 0 },
  ];
  const isNeedToShown = tabs.reduce((acc, item) => acc + item.count, 0) > 0;

  return (
    <section>
      {isNeedToShown &&
        <SurfMenu
          changeType={(type) => setActiveTab(type)}
          type={activeTab}
          menu={tabs}
        />
      }
    </section>
  );
}

export default observer(TabSection);
