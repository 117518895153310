import React, {useEffect, useState} from 'react';
import cogoToast from "cogo-toast";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import TypeSelector from "./WorkPlain/TypeSelector";
import MainSelector from "./MainSelector";
import Idlist from "../../Idlist/idlist";
import st from "./ShareCourse.module.scss";
import {ReactComponent as EmailIcon} from "components/ContentBox/img/email.svg";
import {ReactComponent as SendIcon} from "components/ContentBox/img/send.svg";

const ShareCourse = () => {
    const {data, modalValues, listInKeyChangeHandler} = modalStore

    const [type, setType] = useState()
    const [uuid, setUuid] = useState('')

    const getLink = () => uuid ? window.location.origin + '/lessons/shared_course/' + uuid : ''

    const getUuid = () => {
        const apiConf = {
            method: 'post',
            link: 'lessons/share_course'
        }
        const body = {
            course: modalValues.course_id,
            ...(modalValues.lessons && {lessons: modalValues.lessons})
        }
        superAxiosRequest(apiConf, body).then(res => {
            setUuid(res.data.uuid)
        })
    }

    const copyLink = () => {
        navigator.clipboard.writeText(getLink()).then(() => {
            cogoToast.info(localisationMaster('link_copied', 'lessons/course', 'share_course'))
        })
    }

    useEffect(() => {
        if (type === 1){
            getUuid()
        }
    }, [type])

    return (
        <div className={st.form}>
            {type ?
                <div className="super-modal__form-column">

                    {type === 2 &&
                    <Idlist items={data.lessons.filter(x => !modalValues.lessons?.includes(x.id))}
                            getIds={ids => listInKeyChangeHandler(ids.map(x => x.id), 'lessons')}/>}

                    <div className="super-modal__form-field">
                        <div className={st.row}>
                            <div><input type="text" defaultValue={getLink()} /></div>
                            <div className={st.btn} onClick={() => {
                                uuid ?
                                    copyLink():
                                    getUuid()
                            }}>
                                {uuid ?
                                    localisationMaster('text_12', 'lessons/course') :
                                    localisationMaster('get', 'lessons/course', 'share_course')}
                            </div>
                        </div>
                        <div className={st.notice}>
                            {localisationMaster('text-1', 'lessons/course', 'share_course')}
                        </div>
                    </div>
                    <div className="super-modal__form-field">
                        <div className="super-modal__form-label">
                            {localisationMaster('send_email_notification', 'lessons/course', 'share_course')}
                        </div>
                        <div>
                            <MainSelector component={() => <EmailIcon/>}>
                                <div className={st.mainSelectorContent}>
                                    <div className="super-modal__form-field">
                                        <div className={st.row}>
                                            <div>
                                                <input type="text"
                                                       placeholder={localisationMaster('email_address', 'lessons/course', 'share_course')} />
                                            </div>
                                            <div className={st.btn}><SendIcon/></div>
                                        </div>
                                        <div className={st.notice}>
                                            {localisationMaster('specify_3_addresses', 'lessons/course', 'share_course')}
                                        </div>
                                    </div>
                                </div>
                            </MainSelector>
                        </div>
                    </div>
                </div>
                :
                <TypeSelector values={[
                    localisationMaster('all_course', 'lessons/course', 'share_course'),
                    localisationMaster('partially_select_lessons', 'lessons/course', 'share_course')
                ]}
                              objectChangeHandler={setType}
                              name="type"
                />
            }
        </div>
    );
};

export default observer(ShareCourse)
