import React, { useEffect, useState } from 'react';
import Paper from 'components/UI/Paper/Paper';
import Hr from 'components/UI/Hr/Hr';
import Flex from 'components/UI/Flex/Flex';
import Button from 'components/UI/Button/Button';
import { classNames as cn } from 'helpers';
import { ActionType, DataType } from 'Stores/slotsNewRecordOnlineStore';
import styles from './OrderStatus.module.scss';

const statusText = {
  'false': 'Ожидает оплаты',
  'true': 'Оплачен',
};

const humanizeTime = (time) => {
  const hours = Math.trunc(time / 60).toString().padStart(2, '0');
  const minutes = (time % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

function OrderStatus({ store }) {
  const {order, isOrderPayed, paymentTime, setData, resetData, checkPaymentStatus} = store;
  const [seconds, setSeconds] = useState(paymentTime * 60);
  const [secondsToRequest, setSecondsToRequest] = useState(15);

  const handleCheckButtonClick = () => {
    setSecondsToRequest(15);
    checkPaymentStatus();
  };

  const handleCancelClick = () => {
    resetData(ActionType.resetAll);
  };

  useEffect(() => {
    if (seconds <= 0) {
      // TODO: add some logic when timer has done
      return;
    }
    const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    return () => clearTimeout(timer);
  }, [seconds]);

  useEffect(() => {
    if (secondsToRequest <= 0) {
      return;
    }
    const timer = setTimeout(() => setSecondsToRequest(secondsToRequest - 1), 1000);
    return () => clearTimeout(timer);
  }, [secondsToRequest]);

  const TimerBlock = () => (
    <>
      <div className={styles.inner}>
        {seconds > 0 ?
          <>
            <Button
              onClick={handleCheckButtonClick}
              isWide
              isDisabled={secondsToRequest > 0}
              classes={styles.check_button}
            >
              Проверить статус оплаты
              {secondsToRequest > 0 && <span> (через {secondsToRequest} сек.)</span>}
            </Button>
            <p className={styles.actions}>
              <button
                type="button"
                onClick={handleCancelClick}
                className={styles.actions_cancel}
              >отменить оплату
              </button>
              <button
                type="button"
                onClick={() => setData(1, DataType.paymentStep)}
                className={styles.actions_change}
              > изменить способ оплаты
              </button>
            </p>
          </> : <p className={styles.time_end}>Время оплаты истекло!</p>
        }
      </div>
      <div className={styles.inner}>
        <p className={cn({
          [styles.timer]: true,
          [styles.timer__end]: seconds <= 0,
        })}>
          {humanizeTime(seconds)}
        </p>
        <p className={styles.timer_text}>Осталось времени на оплату</p>
      </div>
    </>
  );

  const SuccessBlock = () => (
    <div className={styles.success_info}>
      <p className={styles.success_title}>
        Вы успешно зарегистрировались на устное тестирование!
      </p>
      <p className={styles.success_text}>
        Вы получите получит все данные о забронированном времени на указанный адрес e-mail.
      </p>
      <p className={styles.success_text}>
        <strong>Уведомление может прийти с задержкой до 30 минут.</strong>
      </p>

      <p className={`${styles.success_text} ${styles.success_text__no_margin}`}>
        Если вы не получили данное письмо, проверьте папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти.
      </p>
      <p className={`${styles.success_text} ${styles.success_text__no_margin}`}>
        Также добавьте no-reply@interpress.kz в список разрешенных отправителей, чтобы предотвратить блокирование писем из-за
        настроек программного обеспечения.
      </p>
    </div>
  );

  return (
    <Paper>
      <Flex withGap>
        <div className={`${styles.inner} ${styles.info_wrapper}`}>
          <h3 className={styles.order_title}>Номер заказа:</h3>
          <p className={styles.order_number}>
            {order}
          </p>
          <p className={cn({
            [styles.order_status]: true,
            [styles.order_status__wait]: !isOrderPayed,
            [styles.order_status__error]: seconds <= 0,
          })}>
            ({seconds > 0 ? statusText[isOrderPayed] : 'не оплачен'})
          </p>
        </div>
        <div className={styles.inner}>
          <h4 className={styles.list_title}>Для оплаты с помощью KASPI необходимо:</h4>
          <ol className={styles.list}>
            <li>1. Перейти в приложение KASPI.kz</li>
            <li>2. Найти компанию InterPress</li>
            <li>3. В поле номера заказа, внести номер заказа</li>
            <li>4. Подтвердить свои данные и совершить оплату</li>
          </ol>
        </div>
      </Flex>
      <Hr/>
      <Flex withGap>
        {isOrderPayed ? <SuccessBlock /> : <TimerBlock />}
      </Flex>
    </Paper>
  );
}

export default OrderStatus;
