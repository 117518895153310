import React, {useEffect, useState} from 'react';
import styles from "./DocsList.module.scss"
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import {superAxiosRequest} from '../../../../axiosApi';
import Document from './component/Document';
import Filter from '../../Global/Filter/Filter';



const DocsList = (props) => {
    const [docsListData, setDocsListData] = useState([])
    const [listMenu, setListMenu] = useState([
        {
            name: 'Открыть',
            action: 'open',
            perm: props.permission
        },
        {
            name: 'Скачать PDF',
            action: 'download',
            perm: props.permission
        },

    ])
    const [docType, setDocType] = useState('')
    const typeProps = props.typeProps
    const filterCnf = [
        {
            type: 'input',
            placeholder: 'ФИО',
            values: '',
            sort_key: 'name',
            nameState: 'name',
        },
    ]

    const sendRequest = (param) => {
        if (param) delete param.is_active
        superAxiosRequest({
            method: 'get',
            link: props.apiLink,
            params: {
                ...param,
                limit: 999
            }
        }).then(res => {
            let data = res.data.result.filter(item => item[props.confirmField] === true)
            setDocType('contract')
            setDocsListData(data)
        })
    }

    useEffect(() => {
        switch (typeProps) {
            case 'template-list':
                setListMenu([...listMenu,
                {
                    name: `Отправить ${props.document.user}у`,
                    action: 'send_to_user',
                    perm: props.permission
                },
                {
                    name: 'Редактировать шаблон',
                    action: 'edit_template',
                    perm: props.permission
                },
                ])
                superAxiosRequest({
                    method: 'get',
                    link: props.apiLink,
                    params: {
                        limit: 999
                    }
                }).then(res => {
                    setDocType('template')
                    setDocsListData(res.data.result)
                })
                break;
            case 'not-signed':
                superAxiosRequest({
                    method: 'get',
                    link: props.apiLink,
                    params: {
                        limit: 999,
                        ...props.apiParams
                    }
                }).then(res => {
                    let data = res.data.result.filter(item => item[props.confirmField] === false)
                    setDocType('contract')
                    setDocsListData(data)
                })
                break;
            case 'signed':
                sendRequest(props.apiParams)
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [typeProps, props?.apiParams])
    return (
        <>
            <ContentHeader title={props.pageTitle} />
            {props.filter &&
                <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() =>sendRequest(props.apiParams)} />    
            }
            <div className={styles.container}>
                {docsListData[0] && docsListData.map((item, key) => <Document
                    documentInfo={props.document}
                    modalInfo={props.modal}
                    docType={docType} key={key}
                    listMenu={listMenu}
                    confirmField={props.confirmField}
                    userNameField={props.userNameField}

                    data={item} />)}
            </div>
        </>
    )
}

export default DocsList