import React from 'react';
import PropTypes from 'prop-types';
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import useFileUpload from "../../../../../../../../../hooks/useFileUpload";
import st from "./ImageVariant.module.scss";

const ImageVariant = props => {
    const [src, text] = props.value.split('|')

    const {refInput, progress, setFile} = useFileUpload({
        onUploadEnd: file => props.onChange(`${file}|${text}`)
    })

    return (
        <div className={props.error && !src ? st.boxError : st.box}>
            {src ?
                <div className={st.variant}>
                    <div className={st.wrapImg}>
                        {/*<img src={file ? URL.createObjectURL(file) : `${Stores.baseLink()}${src}`} alt=""/>*/}
                        <img src={`${Stores.baseLink()}${src}`} alt=""/>
                    </div>
                    <div>
                        <input type="text"
                               name="variant"
                               value={text}
                               onChange={e => props.onChange(`${src}|${e.target.value}`)}
                               className={props.error && text.length < 2 ? 'super-modal__input--error' : undefined}
                        />
                    </div>
                </div>
                :
                <div className={st.choose}>
                    <input type="file" ref={refInput} onChange={e => {setFile(e.target.files[0])}} accept="image/*"/>

                    <button onClick={() => {refInput.current.click()}}>
                        {localisationMaster('modal_doc_text_5', 'lessons/course')}
                    </button>

                    <div className={st.tip}>Поддерживаемые форматы: <span>JPG, GIF, PNG.</span></div>

                    {progress > 0 && progress < 100 &&
                    <div className={st.progress}>
                        <progress max="100" value={progress}/>
                        <div>{progress} %</div>
                    </div>}
                </div>
            }
        </div>
    );
};

ImageVariant.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
}

export default ImageVariant;
