import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'components/ContentBox/Global/ContentTable_test/avatar';
import styles from './TeacherCards.module.scss';

function Avatar({ name, avatar }) {
  avatar = avatar?.startsWith('http') ? avatar : `/${avatar}`;
  const firstName = name.split(' ')[1];
  const lastName = name.split(' ')[0];
  const [isLinkBroken, setIsLinkBroken] = useState(true);

  const checkImgSrc = (src) => {
    const img = new Image();
    img.onload = () => {
      setIsLinkBroken(false);
    }
    img.src = src;
  };

  useEffect(() => {
    checkImgSrc(avatar);
  }, [avatar]);


  return isLinkBroken
    ? (
      <span
        className={`${styles.img} ${styles.img__broken}`}
        style={{ backgroundColor: colors(firstName + lastName), color: 'white' }}
      >
        {firstName.substr(0, 1) + lastName.substr(0, 1)}
      </span>
    )
    : <img src={avatar} alt="фото сотрудника" className={styles.img} />;
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};

export default Avatar;
