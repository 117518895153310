import React, { useEffect, useState }  from 'react';
import Select from '../../Select/Select';
import {superAxiosRequest} from 'axiosApi';
import {createJobTime} from '../../../Online-schedule/components/functions'
import {localisationMaster} from "../../../../../Localisation/Localisation";
import Textarea from "../../Textarea/Textarea";
import SelectRequest from "../../Select/SelectRequest";
import modalStore from "../../../../../Stores/modalStore";
import {observer} from "mobx-react";




const ClassroomForm = observer( (props) => {
    const [locations, setlocations] = useState([false, []])
    //const [locDisabled, setlocDisabled] = useState(false)

    const {changeValues, changeValuesArray, errorChecker, modalValue, errorColor, data} = modalStore
    const createTime = () =>{
        let arr = createJobTime()
        let arr_s = []
        for( let i=0; i < arr.length; i++){
            arr_s.push(
                {id: i + 1, name: arr[i].time}

            )
        }
        return arr_s
    }

    var apiConf ={
        method: 'get',
        link: 'db/locations',
        params: {
            is_active: true,
            offset: 0,
            limit: 100
        }
    }

    useEffect(() => {
        createTime()
        superAxiosRequest(apiConf).then(response=>{
            setlocations([true,response.data.result])
        })
        .catch(error => {
          });

    },[])

    useEffect(() => {
        if(data.loc_id && modalValue.location === undefined) {
            changeValues(+data.loc_id, 'location')
        }
    }, [data.loc_id])

    // useEffect(() => {
    //     if(modalValue ) {
    //         changeValuesArray(modalValue)
    //     }
    // }, [modalValue])


    const getLocTime = (id) =>{
        let loc =[]
        loc = locations[1].filter(x=>x['id'] === id)
        if(loc.length > 0){
        let arr = createTime().filter(x=>x['name'] === loc[0].start_at)
        return arr[0].id
        }
    }


    return (
        <div>
                    <Select    onChange={'location'} 
                    value_error={errorChecker('location')}
                    valueId={modalValue.location}
                    nameState={'location'}
                    s_value={modalValue.location}
                    selected={0} stateError={'location_error'}
                    myChangeHandler={(e)=>changeValues(e.id, 'location')}
                    text={localisationMaster('text_2', 'modal_templates')}
                    disabled={data.loc_id && true }
                    value={locations[0] ? locations[1] : []}
                    ChangeError={'location'}
                    width="480px"/>
            <div className= "super-modal-twin-selectors" style={{marginTop: '20px'}}>
                    <input  type="text" value={modalValue.name}
                                    id="1"
                                    placeholder={localisationMaster('text_3', 'modal_templates')}
                                    onChange={(e)=>changeValues(e.target.value,'name')}
                                    style={{borderColor: errorChecker('name') && errorColor}}
                                    />

                    <input className= "super-modal-twin-selectors-number"  name="to" type="number" min='1' max="1000" value={modalValue.capacity}
                                    id="2"
                                    placeholder={localisationMaster('text_4', 'modal_templates')}
                                    onChange={(e)=>changeValues(e.target.value, 'capacity', 'int')}
                                    style={{borderColor: errorChecker('capacity') && errorColor}}
                                    />

            </div>
            <div className= "super-modal-twin-selectors">
                <Select    onChange={'location'}
                    nameState={'location'}
                    selected={10} stateError={'location_error'}
                    myChangeHandler={(e)=>changeValues(e.target.value, 'start_at')} 
                    text={localisationMaster('text_5', 'modal_templates')}
                    new_id={'name'}
                    value={createTime()}
                    s_value={modalValue.start_at}
                    by_id={'id'}
                    ChangeError={'start_at'}
                    width="230px"/>
                    
                    <Select    onChange={'location'}
                    nameState={'end_at'}
                    selected={'20:00'} stateError={'end_at' + "_error"}
                    myChangeHandler={(e)=>changeValues(e.target.value, 'end_at')}
                    text={localisationMaster('text_6', 'modal_templates')}
                    s_value={modalValue.end_at}
                    by_id={'id'}
                    new_id={'name'}
                    value={createTime()}
                    ChangeError={'end_at'}
                    width="230px"/>
            </div>

            {/*<textarea style={{marginTop: '10px'}}*/}
            {/*        value={props.returnValue('comment')} */}
            {/*        placeholder={localisationMaster('comment', 'online-schedule/future-slots')}*/}
            {/*        onChange={(e)=>props.changeHandler(e, 'comment')}*/}
            {/*/>*/}
            <Textarea           onChange={changeValues}
                                value={modalValue.comment}
                                stateName={'comment'}
                                placeholder={localisationMaster('comment', 'online-schedule/future-slots')}
                                style={{borderColor: errorChecker('comment') && errorColor}}
            />
        </div>
    )
})

export default ClassroomForm
