import React, {useState, useEffect} from 'react'
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter} from '../../Online-schedule/components/functions'
import SelectDateBirth from '../SelectDateBirth/SelectDateBirth'
import './modaldatarange.scss'


export default function DateSpliter(props) {
    const [dateRange, setDateRange] = useState({})

    const changeHandler = (data, name) =>{
        setDateRange({...dateRange, [name]: data})
    }

    const setNow = (name) =>{
        let obj = dateRange
        obj[name] = timeConverter(new Date(), 'today')
        setDateRange({...obj})
    }

    const dropDate = (name) =>{
        let obj = dateRange
        if(props.drop_date){
            obj[name] = props.drop_date
        } else {
            delete obj[name]
        }

        setDateRange({...obj})
    }


    useEffect(() => {
        (props.fix_data && Object.keys(dateRange).length === 0) && setDateRange({...props.fix_data})
    }, [props.fix_data])

    useEffect(() => {
        props.arrayChangeHandler(dateRange)
    }, [dateRange])

    return (
        <div className="modal-date-range">
            <div className="modal-date-range__unit">
                <div className="modal-date-range__unit--titles">
                    <span>{props.titles && props.titles[0]}</span>
                    <span onClick={() => setNow(props.keys[0])}>
                        {localisationMaster('Today', 'online-schedule/time-slots')}
                    </span>
                </div>

                <SelectDateBirth value={dateRange[props.keys[0]]}
                                 name={props.keys[0]}
                                 onChange={changeHandler}
                                 years={props.yearsStart || false}/>
            </div>

            <div className="modal-date-range__unit">
                <div className="modal-date-range__unit--titles">
                    <span>{props.titles && props.titles[1]}</span>
                    <span onClick={() => dropDate(props.keys[1])}>
                        {localisationMaster('text-10', 'filter')}
                    </span>
                </div>

                <SelectDateBirth value={dateRange[props.keys[1]]}
                                 name={props.keys[1]}
                                 onChange={changeHandler}
                                 years={props.yearsEnd || false}/>
            </div>
        </div>
    )
}
