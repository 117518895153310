import React from "react";
import {localisationMaster} from "Localisation/Localisation";
import st from "./CalendarInfo.module.scss"

const CalendarInfo = () =>
    <div className={st.info}>
        {localisationMaster('text_2', 'groups/calendar')}
        {localisationMaster('text_3', 'groups/calendar')}<br/>
        <strong>{localisationMaster('text_4', 'groups/calendar')}</strong>
    </div>

export default CalendarInfo;
