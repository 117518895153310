import React, {useEffect, useState} from 'react';
import styles from "./DocsViewer.module.scss"
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import LocalStorage from "services/LocalStorage";
import DocsCreator from '../DocsCreator/DocsCreator';
import SigningBlock from '../SigningBlock/SigningBlock';
import {superAxiosRequest} from '../../../../axiosApi';
import './printStyle.scss'
import {useHistory} from 'react-router-dom';



const DocsViewer = (props) => {
    const [constructorState, setConstructorState] = useState({})
    const [state, setState] = useState({
        sendCode: true,
        confirmCode: false,
        success: false,
        error: {
            value: false,
            massage: ''
        },
        alert: {
            value: false,
            massage: ''
        },
        localStorageData: {
        },
        documentId: false
    })
    const data = LocalStorage.get('ud');
    const id = +props.match.params.id
    const history = useHistory()
    const uploadJson = (jsonData) => {
        let lastName = data.user_data.profile.last_name ? data.user_data.profile.last_name : ''
        let firstName = data.user_data.profile.first_name ? `${data.user_data.profile.first_name[0]}.` : ''
        let fatherName = data.user_data.profile.father_name ? `${data.user_data.profile?.father_name[0]}.` : ''

        Object.keys(jsonData).forEach(key => {
            switch (jsonData[key].type) {
                case 'initials':
                case 'sign':
                    jsonData[key].value.text = `${lastName} ${firstName} ${fatherName}`
                    break;
                default:
                    break;
            }
        })
        setConstructorState(jsonData)

    }

    const saveDoc = () => {
        superAxiosRequest({
            method: 'put',
            link: `${props.apiLinks.contract}/${state.documentId}`
        }, {
            json_text: {...constructorState}
        })
    }

    const checkEmail = (email) => {
        let result = {value: true, massage: 'В профиле не указан email'}
        if (email) {result = {value: false}}
        return result
    }

    useEffect(() => {
        let newComponentState = {
            sendCode: false,
            confirmCode: false,
            success: true,
            isMyDocs: false
        }

        switch (props.match.params.pageType) {
            case 'contract':
                superAxiosRequest({
                    method: 'get',
                    link: `${props.apiLinks.contract}/${id}`,
                }).then(res => {
                    if (!res.data[props.confirmedField]) {
                        newComponentState = {
                            localStorageData: JSON.parse(localStorage.getItem('signedDocs')),
                            error: {...checkEmail(data.user_data.email)},
                            documentId: res.data.id,
                        }
                    }
                    if (Object.keys(res.data.json_text).length > 0) {   
                        setConstructorState(res.data.json_text)
                    }
                    else {
                        superAxiosRequest({
                            method: 'get',
                            link: `${props.apiLinks.template}/${res.data[props.templateField]}`
                        }).then(res => {
                            uploadJson(res.data.json_text)
                        })
                    }
                    newComponentState.isMyDocs = res.data[props.userField] === data.user_data.profile.id
                    setState({
                        ...state,
                        ...newComponentState
                    })

                })
                break;

            case 'template':
                superAxiosRequest({
                    method: 'get',
                    link: `${props.apiLinks.template}/${id}`
                }).then(res => {
                    uploadJson(res.data.json_text)
                    setState({
                        ...state,
                        ...newComponentState
                    })
                })
                break;

            case 'print':
                superAxiosRequest({
                    method: 'get',
                    link: `${props.apiLinks.contract}/${id}`,
                }).then(res => {
                    if (!res.data[props.confirmedField]) {
                        newComponentState = {
                            localStorageData: JSON.parse(localStorage.getItem('signedDocs')),
                            error: {...checkEmail(data.user_data.email)},
                            documentId: res.data.id,
                        }
                    }
                    if (Object.keys(res.data.json_text).length > 0) { 
                        setConstructorState(res.data.json_text)
                        setTimeout(() => {
                            window.print()
                            history.push(`${props.baseLink}/not-signed`)
                        }, 1000)
                    }
                    else {
                        superAxiosRequest({
                            method: 'get',
                            link: `${props.apiLinks.template}/${res.data[props.templateField]}`
                        }).then(res => {
                            uploadJson(res.data.json_text)
                            setTimeout(() => {
                                window.print()
                                history.push(`${props.baseLink}/not-signed`)
                            }, 1000)
                        })
                    }

                    setState({
                        ...state,
                        ...newComponentState
                    })

                })
                break;

            case 'template-print':
                superAxiosRequest({
                    method: 'get',
                    link: `${props.apiLinks.template}/${id}`
                }).then(res => {
                    uploadJson(res.data.json_text)
                    setState({
                        ...state,
                        ...newComponentState
                    })
                    setTimeout(() => {
                        window.print()
                        history.push(`${props.baseLink}/template-list`)
                    }, 1000)
                })
                break;

            default:
                break;
        }
    }, [id])

    return (
        <div>
            <div className={styles.notPrint}>
                <ContentHeader title={`Подписание договора: ${data.user_data.profile.full_name}`} />
            </div>
            <div className={`${styles.container} ${styles.notPrint}`}>
                <div className={styles.content}>
                    <div className={styles.content__wrapper}>
                        <div className={styles.list}>
                            <DocsCreator docsCreatorData={constructorState} setDocsCreatorData={setConstructorState} mode='view' />
                        </div>
                    </div>
                </div>
                {!state.success && state.isMyDocs && <button onClick={(e) => {saveDoc()}}>Сохранить документ</button>}
                <SigningBlock className={styles.notPrint} documentTemplateId={id}
                    docsData={state}
                    userData={data.user_data}
                    json_text={constructorState}
                    apiLink={props.apiLinks.contract}
                />
            </div>

            <div className={`${styles.list} ${styles.print} `}>
                <DocsCreator docsCreatorData={constructorState} mode='view' />
            </div>
        </div >
    )
}

export default DocsViewer