import React, {useEffect} from 'react';
import DatePicker from "react-datepicker";
import {observer} from 'mobx-react'
import {checkHolyDays} from 'components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendarFunctions';
import {dateFormat} from "helpers/date";
import modalStore from 'Stores/modalStore';
import st from "./CloseSchedule.module.scss"


const CloseSchedule = () => {
    const {data: ms, modalValues, changeValuesArray} = modalStore;

    const updateHT = date => {
        let obj = {...modalValues}
        let day_index,
            start_at,
            end_at,
            count,
            hours_total;

        start_at = new Date(obj.date_from);
        end_at = new Date(date);
        count = 0;

        while (start_at <= end_at) {
            day_index = start_at.getDay() || 7;

            if (obj.weekdays.includes(day_index) && !checkHolyDays(dateFormat(start_at, "YYYY-mm-dd"), ms.payload.holidays))
                count++

            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
        }
        hours_total = obj.hours_per_day * count;
        obj.date_until = date
        obj.hours_total = Math.ceil(hours_total)
        changeValuesArray(obj);
    }

    useEffect(() => {
        updateHT(modalValues.date_until)
    }, [])

    return (
        <div className={st.field}>
            <div>
                <span>Дата закрытия группы</span>
            </div>

            <div className="react-datepicker-custom">
                <DatePicker
                    locale="ru"
                    selected={new Date(modalValues.date_until)}
                    onChange={date => updateHT(dateFormat(date, "YYYY-mm-dd"))}
                    minDate={new Date(Date.now() - 24 * 60 * 60 * 1000)}
                    maxDate={new Date(ms.payload.maxDate)}
                    selectsStart
                    dateFormat="dd.MM.yyyy"
                />
            </div>
        </div>
    )
}

export default observer(CloseSchedule);
