import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import st from './CompleteSentence.module.scss'

const CompleteSentence = props => {
  const [words, setWords] = useState([])
  const [answers, setAnswers] = useState([])

  const up = idx => {
    setWords(words.filter((w, i) => i !== idx))
    setAnswers([...answers, words[idx]])
  }

  const down = idx => {
    setAnswers(answers.filter((a, i) => i !== idx))
    setWords([...words, answers[idx]])
  }

  useEffect(() => {
    if (props.answer?.answers?.length > 0){
      setAnswers(props.answer.answers)
    } else {
      setWords(props.sentence.split('|'))
    }
  }, [props.answer])

  useEffect(() => {
    if (words.length === 0){
      props.onComplete(answers)
    } else {
      props.onComplete([])
    }
  }, [words, answers])

  return (
    <div className={st.box}>
      <div className={st.sentence}>{answers.length ? answers.map((answer, idx) =>
        <div key={idx} onClick={() => {down(idx)}} className={st.word} dangerouslySetInnerHTML={{ __html: answer }}  />) :
        <span className={st.empty}>Выберите слова, чтобы добавить их сюда</span>}
      </div>
      <div className={st.words}>{words.map((word, idx) =>
        <div key={idx} onClick={() => up(idx)} className={st.word} dangerouslySetInnerHTML={{ __html: word }} />)}
      </div>
    </div>
  );
};

CompleteSentence.propTypes = {
  sentence: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default CompleteSentence
