import React from "react";
import {observer} from "mobx-react";
import asideModalStore from "Stores/asideModalStore";
import MultiCheckbox from "components/UI/Checkbox/MultiCheckbox";
import st from "./ModalEmployerSkills.module.scss"

const ModalEmployerSkills = ({skillKey, ...props}) => {
  const skills = props.catalog[skillKey].items

  const {modalValues, changeValue} = asideModalStore

  const onChangeHandler = id => {
    const arr = modalValues[skillKey]
    changeValue(arr.includes(id) ? arr.filter(skID => skID !== id) : [...arr, id], skillKey)
  }

  return (
    <div className={st.modalEmployerSkills}>
      <ul className={st.checkboxes}>{skills.map(item =>
        <MultiCheckbox key={item.id} checked={modalValues[skillKey].includes(item.id)}
                       label={item.name}
                       onChange={() => {
                         onChangeHandler(item.id)
                       }}/>)}
      </ul>
    </div>
  )
}

export default observer(ModalEmployerSkills)
