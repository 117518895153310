import React, { useState, useEffect } from 'react';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';

import './SurfMenu.scss'
import { observer } from 'mobx-react';
import listingStore from '../../../../Stores/listingStore';

const SlideMenuListing = observer((props) => {
    const { setFiltredList, dropData, filterParamsObject, perPage, setFilterType, page, filterType } = listingStore;

    useEffect(() => {
        let defaultAPI = {
            offset: (page - 1) * perPage,
            limit: perPage,
            //is_active: true,
        }
        //slideMenuAction(active, 'filter_type')
        setFiltredList({ link: props.link, method: 'get' }, { ...filterParamsObject, ...defaultAPI, filter_type: filterType })
    }, [filterType])

    useEffect(() => {
        return () => {
            dropData()
        }
    }, [])

    return (
        <div className="surf-menu">
            <div className="surf-menu__grad">
                <div className="surf-menu__tabs">
                    {props.list && props.list.map((item, index) =>
                        (item.count > 0) &&
                        <div
                            key={index}
                            className={"surf-menu__tab " + (item.filter_type === filterType ? " surf-menu__tab--active" : "")}
                            onClick={() => setFilterType(+item.filter_type)}
                        >
                            {item.name}
                            {item.count && <sup className="surf-menu__count"> {item.count}</sup>}
                        </div>
                    )}
                </div>
            </div>

            <div className="surf-menu__right" >
                {props.button &&
                    <div className="surf-menu__action">
                        <ActionMenu menu={props.menu_add} action={props.action_add} />
                    </div>
                }
            </div>

        </div>
    )
})
export default SlideMenuListing;

