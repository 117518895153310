import React, {useEffect, useState, useRef} from 'react';
import styles from './TimeTable.module.scss';
import Flex from 'components/UI/Flex/Flex';
import ArrowButton from 'components/UI/Calendar/components/ArrowButton/ArrowButton';
import {calcTimeWithTimeZoneShift} from 'components/Modules/Slots/utils';
import LocalStorage from 'services/LocalStorage';
import {timeConverter, timeConverter2} from "components/ContentBox/Online-schedule/components/functions";
import TimeTableSlot from './TimeTableSlot';
import TimeTableDatePicker from './TimeTableDatePicker/TimeTableDatePicker';


const TimeTable = ({
    data,
    setDate, date,
    slotTypes,
    workStartTime,
    workFinishTime,
    updateTimeSlot,
    deleteTimeSlot,
    createZoomLink}) => {
    const timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
    const getWorkHourseArray = () => {
        const workHourseArray = []
        for (let i = 0; i <= (workFinishTime - workStartTime) / 3600000; i++) workHourseArray.push(i + (new Date(workStartTime)).getHours())
        return workHourseArray
    }
    const inputRef = useRef(null);

    const [timeTableData, setTimeTableData] = useState()
    useEffect(() => {
        if (data) {
            setTimeTableData(prev => ([]))
            data.map(item => {
                let hourseList = []
                item.items[0].employee_booked_slots.map(slot => {
                    const slotStartTime = new Date((new Date()).setHours(calcTimeWithTimeZoneShift(slot.start_at, timeZone).split(':')[0], calcTimeWithTimeZoneShift(slot.start_at, timeZone).split(':')[1], 0))
                    const slotFinishTime = new Date((new Date()).setHours(calcTimeWithTimeZoneShift(slot.finish_at, timeZone).split(':')[0], calcTimeWithTimeZoneShift(slot.finish_at, timeZone).split(':')[1], 0))
                    hourseList.push({
                        isOnline: slot.is_online,
                        isSchool: slot.is_school,
                        is_active: slot.is_active,
                        typeName: slot.is_active ? slot.type_name : 'Удаленный слот',
                        isAccessible: slot.is_active ? slot.is_accessible : false,
                        workStartTime: workStartTime,
                        timeStart: slotStartTime,
                        timeEnd: slotFinishTime,
                        id: slot.id,
                        slot_type_id: slot.type_id,
                        slot_id: slot.slot_id,
                        color: slot.is_active ? slot.color : 'rgb(255, 218, 231)', durationMinutes: (slotFinishTime - slotStartTime) / 60000, border: slot.is_active ? (slot.color === "#ffffff" ? '1px solid #bdbdbd' : "none") : 'none',
                        comment: slot.comment,
                        is_comment: Boolean(slot.comment),
                        group_clients: slot.group_clients,
                        slot_date: slot.slot_date,
                        employee_id: item.employee_id,
                        slotTypes: slotTypes,
                        zoom_url: slot.zoom_url
                    })

                })
                setTimeTableData(prev => ([...prev, {teacherName: item.employee_name, hourseList: hourseList}]))
            })
        }
    }, [data])
    return (
        <div className={styles.timeTable}>
            <div className={styles.timeTable__header}>
                <div className={styles.timeTable__date}>{timeConverter((date), "age")}</div>
                <div className={styles.timeTable__wrapper_btn}>
                    <TimeTableDatePicker date = {date} setDate={ setDate} />

                    <Flex>
                        <ArrowButton left onClick={() => setDate(prev => (new Date(Date.parse(prev) - (24 * 60 * 60 * 1000))))} />
                        <ArrowButton onClick={() => setDate(prev => (new Date(Date.parse(prev) + (24 * 60 * 60 * 1000))))} />
                    </Flex>
                </div>
            </div>
            <div className={styles.timeTable__table}>
                {data.length !== 0 && <div className={styles.timeTable__timeLine}
                    style={{width: `${200 + Math.floor(((workFinishTime - workStartTime) / 60000))}px`}}>
                    {getWorkHourseArray().map((hour, id) => (<div key ={id} className={styles.timeTable__col_hour}>{hour}</div>))}
                </div>}
                {timeTableData && timeTableData.map((item, rowId) => (<div key = {rowId} className={styles.timeTable__row}
                    style={{width: `${200 + Math.floor(((workFinishTime - workStartTime) / 60000))}px`}} >
                    <div className={styles.timeTable__col_teacher}>{item.teacherName}</div>
                    <div className={styles.timeTable__wrapper_slot}>
                        {getWorkHourseArray().map((hour, id) => {
                            return id + 1 < getWorkHourseArray().length && (<div key = {id} className={styles.timeTable__col}></div>)
                        })}
                        {item.hourseList.map((slot, id) => <TimeTableSlot
                            key = {id}
                            updateTimeSlot={updateTimeSlot}
                            deleteTimeSlot={deleteTimeSlot}
                            createZoomLink={createZoomLink}
                            totalRows={timeTableData.length}
                            rowId={rowId}
                            slotData={slot} />)}
                    </div>
                </div>))}
            </div>
            <div className={styles.timeTable__wrapper_tooltip} id="timeTable__wrapper_tooltip"></div>
        </div>

    );
}

export default TimeTable;