import React, {Fragment, useEffect, useState} from 'react';
import { correctStyleLessons } from './edit';

import "./QuestionDragWords.scss"

export default function QuestionDragWords(props) {

    const [words, setWords] = useState([])

    let dragged, dragIndex

    const onDragStartHandler = (e, i) => {
        dragged = e.target
        dragIndex = i
        e.target.classList.add('onDrag')
    }

    const onDragEndHandler = e => {
        e.target.classList.remove('onDrag')
    }

    const onDragEnterHandler = e => {
        e.target.classList.add('onDragOver')
    }

    const onDragLeaveHandler = e => {
        e.target.classList.remove('onDragOver')
    }

    const onDragOverHandler = e => {
        // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
        if (dragged)
            // prevent default to allow drop
            e.preventDefault()
    }

    const onDropHandler = ({target}, index) => {
        target.classList.remove('onDragOver')
        let wl = words.filter((x,i) => i!==dragIndex)
        // Удаляем элемент из слов
        const word = words[dragIndex]
        setWords([...wl])

        const arr = props.answers.slice()
        arr[index] = word
        props.handleAnswers(arr, !wl.length)
    }

    const dropZone = index =>
    props.answers[index] ?
        <span
            className="questionDragWords__dropZone"
            onClick={() => {
                let answ = [...props.answers]
                answ[index] = ''
                let arr = [...words]
                arr.push(props.answers[index])
                let wl = words.filter((x,i) => i!==dragIndex)
                props.handleAnswers(answ, !wl.length)
                setWords([...arr])
            }}>
            {props.answers[index]}
        </span>
            :
        <span
            className="questionDragWords__dropZone"
            onDragEnter={onDragEnterHandler}
            onDragLeave={onDragLeaveHandler}
            onDragOver={onDragOverHandler}
            onDrop={e=>{onDropHandler(e, index)}}
        />

    useEffect(() => {
        setWords(props.items.answers_mix.split('|'))
    }, [props.items])

    useEffect(() => {
        let sAnswerText = props.items.text
        props.answers.forEach(answer => {
            sAnswerText = sAnswerText.replace('[]', `[${answer}]`)
        })
        if(!props.answers.length && !words.length) setWords(props.items.answers_mix.split('|'))
    }, [props.answers])

    const aText = props.items.text.split('[]')


    return (
        <div className="questionDragWords">
            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) &&
            <div className="lessonQuestion__wordsBox">
                {words.map((word, index) =>
                    <span key={index} draggable onDragStart={e=>{onDragStartHandler(e, index)}} onDragEnd={onDragEndHandler}>{word}</span>
                )}
            </div>
            }
            <div className="questionDragWords__text lessonQuestion__contentText">
                <p>
                    <span dangerouslySetInnerHTML={{__html: aText.shift()}} />
                    {aText.map((part, index) =>
                        <Fragment key={index}>
                            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) &&
                            dropZone(index)}
                            {(props.is_student_checked_try || (props.is_teacher_view && props.at)) &&
                            <span
                                className={`questionDragWords__dropZone ${correctStyleLessons(props.correct_answ[index])}`}>
                                    {props.answ_array[index]}
                            </span>}
                            <span dangerouslySetInnerHTML={{__html: part}} />
                        </Fragment>
                    )}                    
                </p>
            </div>
        </div>
    );
};
