import {action, computed, makeObservable, observable, toJS} from "mobx";
import {superAxiosRequest} from "../axiosApi";
import {localisationMaster} from "../Localisation/Localisation";
import modalStore from "./modalStore";

const msSection = {
    name: 'study_test_question_category',
    type: 'quad',
    button: localisationMaster('button-two', 'company/age', 'add_age'),
    link: 'study/test_sections',
    fields: [
        {
            type: 'text',
            name: 'name',
            label: localisationMaster('text_3', 'modal_templates'),
            required: true,
        },
        {
            type: 'text',
            name: 'percentage',
            label: localisationMaster('text_31', 'study/study-test', 'modal'),
            onChange: (prev, next) => (next > 0 && next <=100) || next === '' ? +next : prev,
        },
        {
            type: 'textarea',
            name: 'comment',
            label: localisationMaster('comment', 'online-schedule/future-slots'),
        }
    ],
    valid: {
        name: 'required',
    },
}

const msQuestion = {
    name: 'add-question-study-test',
    type: 'add-question-study-test',
    link: 'study/question_test',
    data: 'json',
}

class StudyStore {
    studyTestData = {}
    testSectionsData = []
    testQuestionsData = []
    questionTypesData = []

    constructor() {
        makeObservable(this, {
            studyTestData: observable,
            testSectionsData: observable,
            testQuestionsData: observable,
            questionTypesData: observable,

            fetchStudyTest: action,
            fetchTestSections: action,
            setTestSection: action,
            fetchTestQuestions: action,
            setTestQuestions: action,
            fetchQuestionTypes: action,

            studyTest: computed,
            testSections: computed,
            testQuestions: computed,
        })
    }

    fetchStudyTest = id => {
        const apiConf = {
            method: 'get',
            link: 'study/study_test/' + id,
        }
        superAxiosRequest(apiConf).then(res => {
            this.studyTestData = res.data
            this.testSectionsData = res.data.test_sections
        }).catch(() => {})
    }

    fetchTestSections = tid => {
        const apiConf = {
            method: 'get',
            link: 'study/test_sections',
            params: {
                study_test: tid,
            }
        }
        superAxiosRequest(apiConf).then(res => {
            this.testSectionsData = res.data
        })
    }

    setTestSection = data => {
        const arr = [...this.testSectionsData]
        const i = arr.findIndex(x => x.id === data.id)
        arr[i] = data
        this.testSectionsData = arr
    }

    addSection = () => {
        const ms = {
            ...msSection,
            title: localisationMaster('text_29', 'study/study-test', 'modal'),
            button: localisationMaster('button-two', 'company/age', 'add_age'),
            func: () => this.fetchTestSections(this.studyTestData.id),
        }
        const md = {
            study_test: this.studyTestData.id
        }
        modalStore.activity(ms, md)
    }

    editSection = id => {
        const ms = {
            ...msSection,
            title: localisationMaster('text_30', 'study/study-test', 'modal'),
            button: localisationMaster('text_54', 'groups/all-groups'),
            is_put: true,
            link: `${msSection.link}/${id}`,
            func: data => {
                this.setTestSection(data)
                this.fetchTestQuestions(this.studyTestData.id)
            },
        }
        const {name, percentage, comment, study_test} = this.testSectionsData.find(x => x.id === id)
        const md = {
            name,
            percentage,
            comment,
            study_test,
        }
        modalStore.activity(ms, md)
    }

    deleteSection = id => {
        const apiConf = {
            method: 'delete',
            link: 'study/test_sections/' + id
        }
        superAxiosRequest(apiConf).then(() => {
            this.fetchTestSections(this.studyTestData.id)
            this.fetchTestQuestions(this.studyTestData.id)
        }).catch(() => {})
    }

    fetchTestQuestions = tid => {
        const apiConf = {
            method: 'get',
            link: 'study/question_test',
            params: {
                study_test: tid,
            }
        }
        superAxiosRequest(apiConf).then(res => {
            this.testQuestionsData = res.data.result
        })
    }

    setTestQuestions = data => {
        this.testQuestionsData = data.result
    }

    setQuestion = data => {
        const arr = [...this.testQuestionsData]
        const i = arr.findIndex(x => x.id === data.id)
        arr[i] = data
        this.testQuestionsData = arr
    }

    addQuestion = () => {
        const ms = {
            ...msQuestion,
            title: localisationMaster('text_34', 'study/study-test'),
            button: localisationMaster('button-two', 'company/age', 'add_age'),
            func: this.setTestQuestions,
        }
        const md = {
            study_test: this.studyTestData.id,
            point: 1
        }
        modalStore.activity(ms, md)
    }

    editQuestion = question => {
        const ms = {
            ...msQuestion,
            title: localisationMaster('text_33', 'study/study-test'),
            button: localisationMaster('text_54', 'groups/all-groups'),
            is_put: true,
            link: `${msQuestion.link}/${question.id}`,
            func: this.setQuestion,
        }
        const md = {
            ...question,
            test_section: question.test_section?.id,
        }
        delete md.file
        modalStore.activity(ms, md)
    }

    fetchQuestionTypes = () => {
        const apiConf = {
            method: 'get',
            link: 'study/get_type_study_test',
            params: {
                limit: 999
            }
        }
        superAxiosRequest(apiConf).then(res => {
            this.questionTypesData = res.data.result
        })
    }

    get studyTest() {
        return toJS(this.studyTestData)
    }

    get testSections() {
        const sections = this.testSectionsData
        const questions = this.testQuestionsData
        const arr = sections.map(s => ({
            ...s,
            percentage: questions.filter(q => q.test_section?.id === s.id).length / questions.length * 100
        }))

        return toJS(arr) || []
    }

    get testQuestions() {
        return toJS(this.testQuestionsData) || []
    }
}

export default new StudyStore()
