import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import {translate as loc, dict} from "Localisation";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {classNames as cn} from "helpers";
import styles from './ModalCompany.module.scss';
import DummyCounter from '../../../Counter/DummyCounter';
import CheckboxToggle from '../../../CheckboxToggle/CheckboxToggle';

const ModalCompany = () => {
    const {changeValues, modalValues, check} = modalStore;
    const [num, setNum] = useState(0)

    const cleanSum = (sum) => {
        if (sum === 0 || !sum) return 0
        sum = sum.toFixed(2)
        let mini = sum.toString().split('.')[1]
        let maxi = sum.toString().split('.')[0]
        return parseInt(maxi).toLocaleString('ru') + '.' + mini
    }

    useEffect(() => {
        changeValues({
            ...modalValues?.notification_student,
            fixed_price: num.length > 0 ? Number(num?.replace(/\s/g, '')) * 100 : 0
        }, "notification_student")
    }, [num, changeValues])


    return (
        <div className='super-modal__form-column'>
            <div className={styles.modalCompany__wrapper}>
                <div className={styles.modalCompany__field}>
                    <div
                        className="super-modal__form-label--required">{localisationMaster('table-row-title-1', 'lessons/levels')}</div>
                    <input
                        type="text"
                        placeholder={localisationMaster('table-row-title-1', 'lessons/levels')}
                        value={modalValues.name}
                        onChange={(e) => changeValues(e.target.value, 'name')}
                        className={cn({
                            "super-modal__input": true,
                            "super-modal__input--error": check('name'),
                        })}
                    />
                </div>
                <div className={styles.modalCompany__field}>
                    <div className="super-modal__form-label--required">{loc(dict.company_locations.currency)}:</div>
                    <SelectCustom
                        placeholder={loc(dict.company_locations.currency)}
                        apiLink="db/companies"
                        apiConfParams={{currency: true}}
                        error={check('currency')}
                        valueID={modalValues.currency}
                        optionLabelKey="full_name"
                        onChange={o => changeValues(o.id, 'currency')}
                        apiGetResult={(val) => val.map(el => ({
                            ...el,
                            full_name: `${el.name} (${el.short_name} ${el.sign})`
                        }))}
                    />
                </div>
            </div>
            <div className={styles.modalCompany__wrapper}>
                <div className={styles.modalCompany__field}>
                    <div className={styles.modalCompany__box}>
                        <CheckboxToggle
                            right="Cashback"
                            checked={modalValues?.active_cashback}
                            onChange={() => {
                                changeValues(!modalValues?.active_cashback, 'active_cashback')
                                changeValues(0, 'percent')
                            }}
                        />
                    </div>
                </div>
                {modalValues?.active_cashback && <div className={styles.modalCompany__field}>
                    <DummyCounter
                        value={+modalValues.percent || 0}
                        min={0}
                        max={100.5}
                        name="percent"
                        step={0.5}
                        onChange={e => modalValues?.active_cashback && changeValues(e, 'percent')}
                    />
                </div>}
            </div>
            <div className={styles.modalCompany__wrapper}>
                <div className={styles.modalCompany__field}>
                    Период активации в днях
                </div>
                <div className={styles.modalCompany__field}>
                    <DummyCounter
                        value={modalValues.block_period || 0}
                        min={0}
                        max={30}
                        name="block_period"
                        step={1}
                        onChange={e => changeValues(e, 'block_period')}
                    />
                </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.modalCompany__wrapper}>
                <div className={styles.modalCompany__field}>
                  <div>
                    Сумма для первого уведомления
                  </div>
                    <input
                        type="text"
                        min="0"
                        value={modalValues?.notification_student?.sum1}
                        onChange={(e) => changeValues({
                            ...modalValues?.notification_student,
                            sum1: +e.target.value.replace(/\D/g, '')
                        }, 'notification_student')}
                    />
                  <div>
                    Сумма для второго уведомления
                  </div>
                    <input
                        type="text"
                        min="0"
                        value={modalValues?.notification_student?.sum2}
                        onChange={(e) => changeValues({
                            ...modalValues?.notification_student,
                            sum2: +e.target.value.replace(/\D/g, '')
                        }, 'notification_student')}
                    />
                </div>
            </div>
            <div className={styles.modalCompany__wrapper}>
                <div className={styles.modalCompany__field}>
                    Количество уроков
                </div>
                <div className={styles.modalCompany__field}>
                    <DummyCounter
                        value={modalValues?.notification_student?.amount_lesson || 0}
                        min={0}
                        name="notification_student"
                        step={1}
                        onChange={(e) => {
                            changeValues({
                                ...modalValues?.notification_student,
                                amount_lesson: +e,
                            }, 'notification_student');
                        }}
                    />
                </div>
            </div>
            {/*<div className={styles.modalCompany__wrapper}>*/}
            {/*    <div className={styles.modalCompany__field}>*/}
            {/*        <div className="super-modal__form-label">Фиксированная сумма в счете</div>*/}
            {/*        <input*/}
            {/*            type="text"*/}
            {/*            min="0"*/}
            {/*            placeholder={"Фиксированная сумма в счете"}*/}
            {/*            value={num}*/}
            {/*            onChange={(e) => {*/}
            {/*                setNum(cleanSum(e.target.value.replace(/\D/g, '') / 100))*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.modalCompany__field}>
                <div className={styles.modalCompany__box}>
                    <CheckboxToggle
                        right="Отправка уведомлений на Email"
                        checked={modalValues?.notification_student?.email}
                        onChange={() => {
                            changeValues({
                                ...modalValues?.notification_student,
                                email: !modalValues?.notification_student?.email,
                            }, 'notification_student');
                        }}
                    />
                </div>
                <div className={styles.modalCompany__box}>
                    <CheckboxToggle
                        right="Отправка уведомлений на WhatsApp"
                        checked={modalValues?.notification_student?.wazzap}
                        onChange={() => {
                            changeValues({
                                ...modalValues?.notification_student,
                                wazzap: !modalValues?.notification_student?.wazzap,
                            }, 'notification_student');
                        }}
                    />
                </div>
            </div>
            <div className="super-modal__form-field">
                <div
                    className="super-modal__form-label--required">{localisationMaster('textarea-placeholder', 'company/companies', 'add_company')}</div>
                <textarea
                    placeholder={localisationMaster('textarea-placeholder', 'company/companies', 'add_company')}
                    value={modalValues.comment}
                    onChange={(e) => changeValues(e.target.value, 'comment')}
                    className={cn({
                        'super-modal__textarea--error': check('comment')
                    })}
                />
            </div>
        </div>
    )
}

export default observer(ModalCompany);

