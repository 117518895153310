import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import TeacherCards from 'components/Modules/Slots/components/TeacherCards/TeacherCards';
import { DataType, ActionType } from 'Stores/slotsNewRecordOnlineStore';
import { scrollToRef } from 'helpers';
import ClientData from 'components/Modules/Slots/components/ClientData/ClientData';

function ClientDataWrapper({ store }) {
  const employeeRef = useRef(null);
  const {
    selectedEmployee,
    setData,
    chooseTeacher = false,
    employeesInSlot = [],
    isGuardian,
    clientData,
    guardianData,
    setClientData,
    resetData,
  } = store;

  const handleClientDataChange = (value, type, isGuardian) => {
    setClientData(value, type, isGuardian);
  };

  useEffect(() => {
    if (employeeRef.current) {
      scrollToRef(employeeRef, 100);
    }
  }, []);

  return (
    <>
      {(employeesInSlot.length > 0 && chooseTeacher) &&
        <Paper ref={employeeRef}>
          <SectionTitle title="Выберите преподавателя" secondary/>
          <TeacherCards
            teachers={employeesInSlot}
            selectedTeacher={selectedEmployee}
            onSetData={(id) => setData(id, DataType.selectedEmployee)}
          />
        </Paper>
      }
      <ClientData
        isGuardian={isGuardian}
        clientData={clientData}
        guardianData={guardianData}
        onSetClientData={handleClientDataChange}
        onSetIsGuardian={(flag) => setData(flag, DataType.isGuardian)}
        onResetData={() => resetData(ActionType.resetGuardianData)}
        withSearch={false}
        asClient
      />
    </>
  );
}

export default observer(ClientDataWrapper);
