import React from "react";
import st from "./CPTNoBrandsBook.module.scss";


const brandBooks = [{
  name: "English level 1 Grade 3 Kazakhstan Edition",
  folder: "English_2/Branded",
  links: [{
    name: "Grade 3 AB",
    size: 3040076,
    url: "KAZAKH Grade 3_AB PRINT.pdf"
  }, {
    name: "Grade 3 SB",
    size: 55306981,
    url: "KAZAKH Grade 3_SB PRINT.pdf"
  }, {
    name: "Grade 3 TB",
    size: 1968065,
    url: "KAZAKH Grade 3_TB PRINT.pdf"
  }]
}]


const nonBrandBooks = [{
  name: "English 1 for Grade 3 Kazakhstan Edition",
  folder: "English/NON Branded",
  links: [{
    name: "FAM AND FRNDS G3 CLB L1",
    size: 211212253,
    url: "English for Grade 3 Kz CB Non-branded Press PDF.pdf",
  }, {
    name: "FAF KZ WB LV1 GR3",
    size: 16580459,
    url: "English for Grade 3 Kz TG Non-branded Press PDF.pdf",
  }, {
    name: "FAM AND FRNDS G3 TG L1 KZ",
    size: 28670702,
    url: "English for Grade 3 Kz WB Non-branded Press PDF.pdf",
  }]
}, {
  name: "Français 1 Edition pour le Kazakhstan",
  folder: "French/NON Branded",
  links: [{
    name: "Class Book",
    size: 862541532,
    url: "CourseBook.pdf"
  }, {
    name: "Student Book",
    size:  1002088781,
    url: "ExerciseBook.pdf"
  }, {
    name: "Teacher Book",
    size: 23925989,
    url: "TeacherBook.pdf"
  }]
}, {
  name: "Deutsch A1.1 für dritte Klasse",
  folder: "German/NON Branded",
  links: [{
    name: "Course Book D",
    size: 481484136,
    url: "ClassBook.pdf"
  }, {
    name: "Exercise Book D",
    size: 81190824,
    url: "WorkBook.pdf"
  }, {
    name: "Teacher Book D",
    size: 11187464,
    url: "TeacherBook_D-O.pdf"
  }]
}]


const sizeExt = ['bytes', 'KB', 'MB']

const getSize = size => {
  let i = 0;
  while (size > 999){
    size /= 1024;
    i++
  }
  return `${(Math.round(size*10)/10).toString().replace('.', ',')} ${sizeExt[i]}`
}

const CPTNoBrandsBooks = props => {
  const logo = props.match.params.logo

  return (
    <div className={st.page}>
      <div className={st.books}>{(logo === 's' ? nonBrandBooks : brandBooks).map((book, idx) =>
        <div key={idx}>
          <h3>{book.name}</h3>
          <ul>{book.links.map((link, idx) =>
            <li key={idx}>
              <a href={`https://phoenix24.kz/media/uploads/Books/Books/${book.folder}/${link.url}`} target="_blank">
                <b>{link.name}</b><span> (PDF: {getSize(link.size)})</span>
              </a>
            </li>)}
          </ul>
        </div>)}
      </div>
    </div>
  )
}

export default CPTNoBrandsBooks;
