import React from 'react';
import PropTypes from "prop-types";
import MainSelector from "components/ContentBox/Global/GlobalModalSingle/templates/MainSelector";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import TestQuestions from "./TestQuestions";
import st from './Test.module.scss';
import clockImg from "components/ContentBox/img/clock-ebook.png";
import pointImg from "components/ContentBox/img/points-ebook.png";

const Test = (props) =>
  <>
    <MainSelector image={clockImg}>
      <div className={st.timer}>
        <div>
          <CheckboxToggle checked={props.data.is_timer}
                          onChange={() => {
                            props.onChange({...props.data, is_timer: !props.data.is_timer})
                          }}
                          right={props.translate.timer}
                          mode="inline"
          />
        </div>
        <div className={st.timer__minute}>
          <input type="text" value={props.data.time}
                 onChange={e => props.onChange({...props.data, time: e.target.value})}
                 disabled={!props.data.is_timer}/>
          <p>{props.translate.minute}</p>
        </div>
      </div>
      <p className={st.text}>{props.translate.timer_description}</p>
    </MainSelector>

    <MainSelector image={pointImg}>
      <div>
        <CheckboxToggle checked={props.data.is_point}
                        onChange={() => {
                          props.onChange({...props.data, is_point: !props.data.is_point})
                        }}
                        right={props.translate.point_system}
                        mode="inline"/>
      </div>
      <p className={st.text}>{props.translate.point_system_description}</p>
    </MainSelector>

    <TestQuestions translate={props.translate}
                   questions={props.data.questions}
                   onChange={questions => props.onChange({...props.data, questions})}
                   isPoint={props.data.is_point}
                   isError={props.isError}
    />
  </>

Test.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default Test;
