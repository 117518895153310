import React from 'react';
import {NavLink} from "react-router-dom";
import Stores from "Stores";
import {dateFormat} from "helpers/date";
import {EditIconButton, SuperButton} from "components/UI/SuperButton/SuperButton";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";
import style from './HomeTask.module.scss'


const HomeTask = ({item, menu, actions, listing, translate}) => {
    const isClient = Stores.isClient

    return (
        <div key={item.id} className={style.homeTask} style={listing && {border: 'none'}}>
            <div className={style.box}>
                <div className={style.info}>
                    <NavLink target="_blank" to={'/account/' + item.teacher.user_id}>
                        {Stores.transliterate(`${item.teacher.last_name} ${item.teacher.first_name}`)}
                    </NavLink>
                    <div className={style.date}>{dateFormat(new Date(item.date), 'd MMM YYYY')} {translate?.yr}</div>
                </div>

                <div className={style.description}>
                    <div dangerouslySetInnerHTML={{__html: item.description}}/>
                </div>

            </div>

                <div className={style.buttons}>
                    <div className={style.buttonsControl}>
                        {item.homework_hash &&
                            <SuperButton variant="link"
                                         type="linkColored"
                                         path={`/ebooks/${isClient ? 'my-' : ''}homework/${item.homework_hash}`}
                            >
                                {translate.homework}
                            </SuperButton>
                        }
                        {!isClient &&
                            <>
                                <EditIconButton onClick={(e) => actions('edit', e, item)}/>
                                <div className={style.deleteBasket} onClick={(e) => actions('delete', e, item)}>
                                    <Delete/>
                                </div>
                            </>
                        }
                    </div>
                        {!isClient &&
                              <div className={style.actionMenu}>
                                  <ActionMenu menu={menu} action={actions} items={item}/>
                              </div>
                        }

                </div>

        </div>
    );
};

export default HomeTask;