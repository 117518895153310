import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, path, rules, redirect = '/' }) => {
    return (
        <Route path={path} render={props => (rules() ?
            <Component {...props} /> :
            <Redirect to={redirect} /> )}
        />
    )
}

export default ProtectedRoute;