import React, {useState} from 'react';
import styles from './SectionSelectStyle.module.scss'

const SectionSelectStyle = props => {
  const [activeColor, setActiveColor] = useState(props.value || null);
  return (
    <div className={`${styles.box} ${props.isError ? styles.error : ''}`}>
      {props.colors.map((el) =>
        <div key={el.id}
             className={`${styles.styleBox} ${el.id === activeColor ? styles.active : ''}`}
             onClick={() => {
                 props.changeValue(el.id, 'color')
                 setActiveColor(el.id)
             }}>
          <div className={styles.boxBorder}
               style={{border: `1px solid ${el.body_color}`, backgroundColor: el.background_color}}>
            <div className={styles.boxBorderCircle}
                 style={{border: `1px solid ${el.body_color}`, backgroundColor: el.background_color}}>
              <div className={styles.circle} style={{backgroundColor: el.body_color}}></div>
            </div>
          </div>
        </div>)
      }
    </div>
  );
};

export default SectionSelectStyle;
