import React from 'react'
import DatePicker from "react-datepicker";
import {observer} from "mobx-react";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions'
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import Textarea from 'components/ContentBox/Global/Textarea/Textarea';
import { tests, categories } from './data'
import { classNames as cn } from "helpers";
import './AddTestResult.scss';
import Stores from 'Stores';



const AddTestResult = () => {
    const {data, modalValue, changeValues, check} = modalStore

    return (
        <div className="modalTestResult">
            <div>
                <SelectCustom
                    placeholder={localisationMaster('select_student', 'groups/group')}
                    options={Stores.observableArray(data.students).filter(item => { if(item.status === 1)  return item } )}
                    optionLabelKey="full_name"
                    valueID={modalValue.student}
                    onChange={o => changeValues(+o.id, 'student')}
                    error={check('student')}
                />
            </div>

            <div className="modalTestResult__selects">
                <SelectCustom
                    placeholder={localisationMaster('select_categories', 'groups/group')}
                    options={categories}
                    optionValueKey="name"
                    sortKey="name"
                    valueID={modalValue.category}
                    onChange={o => changeValues(o.name, 'category')}
                    error={check('category')}
                    isSearchable
                />

                <SelectCustom
                    placeholder={localisationMaster('select_test', 'groups/group')}
                    options={tests}
                    optionValueKey="name"
                    valueID={modalValue.test}
                    onChange={o => changeValues(o.name, 'test')}
                    error={check('test')}
                />
            </div>

            <div>
                <input
                    type="number"
                    id="a"
                    min={0}
                    max={100}
                    placeholder={localisationMaster('points', 'groups/group')}
                    value={modalValue.test_scores}
                    onChange={e => changeValues(e.target.value, 'test_scores')}
                    className={cn({
                        'super-modal__input--error': check('test_scores'),
                    })}
                />
            </div>

            <div className="modalTestResult__date">
                <span>{localisationMaster('date', 'groups/group')}:</span>
                <div>
                    <DatePicker selected={modalValue.date !== undefined ? new Date(modalValue.date) : null}
                                onChange={date => changeValues(timeConverter(date, 'today'), 'date')}
                                dateFormat="dd.MM.yyyy"/>
                </div>
            </div>

            <div>
                <Textarea onChange={changeValues}
                          value={modalValue.description}
                          stateName="description"
                          placeholder={localisationMaster('table-row-title-2', 'pays/all-pays')}
                          error={check('description')}
                />
            </div>

        </div>
    )
}

export default observer(AddTestResult)
