import React, {useState, useEffect, Fragment, useCallback} from 'react';
import swal from "sweetalert";
import {permissions} from "settings/permissions";
import {superAxiosRequest} from 'axiosApi'
import {localisationMaster} from 'Localisation/Localisation';
import Stores from 'Stores';
import modalStore from 'Stores/modalStore';
import Loader from 'components/ContentBox/Global/Loader/Loader';
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import EmptyBalance from "./EmptyBalance";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import st from "./ClientBalances.module.scss";
import {ReactComponent as TopUpIcon} from "./img/top_up.svg";
import useLocalization from "hooks/useLocalization";

export default function ClientBalances(props) {
  const clientID = +props.id

  const {activity} = modalStore;
  const {checkUser} = Stores;

  const [companyId, setCompanyId] = useState(null)
  const [balance, setBalance] = useState([]) // [Компания, бонусный, группы]

  const fetchBalances = useCallback(() => {
    const apiConf = {
      method: 'get',
      link: 'ledger/get_client_balance',
      params: {
        client_id: clientID
      }
    }
    superAxiosRequest(apiConf).then(({data}) => {
      setBalance([data.company_balances, data.bonus_balance, data.group_balances])
      data.company_balances.length > 0 && setCompanyId(data.company_balances[0].company.id)
    })
  }, [clientID])

  const onChangeCompanyHandler = company_id => {
    setCompanyId(company_id)
  }

  const func = data => setBalance([data.company_balances, data.bonus_balance, data.group_balances])

  // Пополнение баланса
  const topUp = (name, student_group_rel, isCompany) => {
    const ms = {
      name: 'addPayment',
      link: 'payments/pay_orders',
      button: 'Добавить',
      title: `Пополнение баланса: ${name}`,
      type: 'addPayment',
      width: '650px',
      locked: true,
      client_id: props.id,
      ...(checkUser() && {func: (res) => window.location.href = '/pays/invoice/' + res.order_id})
    }
    activity(
      ms,
      isCompany ? {company: student_group_rel, status_sending_mail: 'need_mail', amount: 0}
        : {student_group_rel, status_sending_mail: 'need_mail', amount: 0})
  }

  // Возврат средств
  const refund = (id, max_sum, type_operation, name) => {
    let ms = {
      link: 'payments/refunds',
      button: 'Перевести',
      title: `Возврат средств: ${name}`,
      type: 'refundModal',
      max_sum,
      id,
      func
  }

    const bo = {
      client: props.id,
      [type_operation === 'company' ? 'company_from' : 'group_balance_from']: id,
      refund: true,
      sum: max_sum,
    }

    activity(ms, bo)
  }

  // Перевод средств
  const transfer = (id, max_sum, type_operation, name) => {
    const ms = {
      name: 'refundModal',
      link: 'payments/refunds',
      button: 'Перевести',
      title: `Перевод средств: ${name}`,
      type: 'refundModal',
      max_sum,
      send: true,
      type_operation,
      payload: {
        companies: balance[0],
        groups: balance[2],
      },
      func
    }

    const bo = {
      client: clientID,
      sum: max_sum,
      ...(type_operation === 'company' && {company_from: id}),
      ...(type_operation === 'bonus_balance' && {company_id: companyId}),
      ...(type_operation === 'group' && {group_balance_from: id}),
    }

    activity(ms, bo)
  }

  // Перевод бонусного баланса между компаниями
  const transferBonus = (id, max_sum, type_operation, name) => {
    const ms = {
      link: 'ledger/client_bonusbalance',
      button: 'Перевести',
      title: `Перевод бонуса: ${name}`,
      type: 'refundModal',
      max_sum,
      send: true,
      type_operation,
      payload: {
        companies: balance[0],
        groups: [],
      },
      func
    }

    const bo = {
      client: clientID,
      sum: max_sum,
      company_from: companyId,
      type_operation: "transfer",
    }

    activity(ms, bo)
  }

  // Пополнение бонуса
  const topUpBonus = (id, max_sum, type_operation) => {
    const ms = {
      link: 'ledger/client_bonusbalance',
      button: 'Пополнить',
      title: `Пополнение бонуса`,
      type: 'refundModal',
      max_sum,
      type_operation,
      func
    }

    const bo = {
      client: clientID,
      sum: max_sum,
      company_to: companyId,
      type_operation: "add"
    }

    activity(ms, bo)
  }

  // Списание бонуса
  const deleteBonus = (id, max_sum, type_operation) => {
    const ms = {
      link: 'ledger/client_bonusbalance',
      button: 'Списать',
      title: `Списание бонуса`,
      type: 'refundModal',
      max_sum,
      type_operation,
      func
    }

    const bo = {
      client: clientID,
      sum: max_sum,
      company_to: companyId,
      type_operation: "delete"
    }

    activity(ms, bo)
  }

  // Создание баланса компании
  const createBalances = () => {
    activity({
      name: 'create-balance',
      link: `ledger/company_balance`,
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: `Создание баланса`,
      type: 'SelectorAndInput',
      active_inputs: ['SelectCustomRequest'],
      data: 'json',
      api_link: 'db/companies',
      name_select: 'company',
      width: '520px',
      func: data => {
        func(data);
        data.company_balances.length > 0 && setCompanyId(data.company_balances[0].company.id)
      }
    }, {client: clientID})
  }

  useEffect(() => {
    fetchBalances()
  }, [])

  const {translate} = useLocalization({
    section: 'profile', subsection: 'my_balances', dict: {
      "title": "My Balances",
      "add_new_company_balance": "Add New Company Balance",
      "free_balance_company": "Company Balance",
      "bonus_balance": "Bonus balance",
      "group": "Group",
      "top_up_balance": "Top Up Balance",
      "money_refund": "Money Refund",
      "money_transfer": "Money Transfer",
      "my_invoices": "My Invoices",
      "bonus_transfer": "Bonus Transfer",
      "top_up_bonus": "Top Up Bonus",
      "bonus_deduction": "Bonus Deduction",
    }
  })

  const menu = [
    {name: translate.top_up_balance, action: 'top_up',},
    {name: translate.money_refund, action: 'refund', permission: permissions.add_refunds_transfers},
    {name: translate.money_transfer, action: 'transfer', permission: permissions.add_refunds_transfers},
    {name: translate.my_invoices, link: {to: `/c/pays/client-orders/${clientID}`, target: '_blank'}},
  ]

  const bonusMenu = [
    {name: translate.money_transfer, action: 'transfer', permission: permissions.add_refunds_transfers},
    {name: translate.bonus_transfer, action: 'transferBonus',  permission: permissions.change_bonus_balance},
    {name: translate.top_up_bonus, action: 'top_up_bonus',  permission: permissions.add_bonus_balance},
    {name: translate.bonus_deduction, action: 'delete_bonus',  permission: permissions.delete_bonus_balance},
    {name: translate.my_invoices, link: {to: `/c/pays/client-orders/${clientID}`, target: '_blank'}},
  ]

  const actions = (action, e, items) => {
    switch (action) {
      case 'top_up': {
        const {id, name, isCompany} = items.topUp
        topUp(name, id, isCompany)
      }
        break;
      case 'transfer': {
        const {id, sum, type_operation, name} = items.transfer
        if (sum > 0) {
          transfer(id, sum, type_operation, name)
        } else {
          swal({
            title: 'Перевод бонуса не возможен!',
            text: "Для перевода средств баланс должен быть выше нуля."
          })
        }
      }
        break;
      case 'transferBonus': {
        const {id, sum, type_operation, name} = items.transferBonus
        if (sum > 0) {
          transferBonus(id, sum, type_operation, name)
        } else {
          swal({
            title: 'Перевод бонуса не возможен!',
            text: "Для перевода бонус должен быть выше нуля."
          })
        }
      }
        break;
      case 'top_up_bonus': {
        const {id, sum, type_operation} = items.topUpBonus
          topUpBonus(id, sum, type_operation)
      }
        break;
      case 'delete_bonus': {
        const {id, sum, type_operation} = items.deleteBonus
        if (sum > 0) {
          deleteBonus(id, sum, type_operation)
        } else {
          swal({
            title: 'Списание бонуса не возможено!',
            text: "Для списание бонус должен быть выше нуля."
          })
        }
      }
        break;
      case 'refund': {
        const {id, sum, type_operation, name} = items.transfer
        if (sum > 0) {
          refund(id, sum, type_operation, name)
        } else {
          swal({
            title: 'Возврат средств не возможен!',
            text: "Для возврата средств баланс должен быть выше нуля."
          })
        }
      }
        break;
      default:
        break;
    }
  }

  return (
    <div className={st.clientBalances}>
      <BlockHeader title={translate.title}/>
      {balance.length > 0 ?
        <>
          {balance[0].length > 0 ?
            <>
              <div className={st.tabs}>{balance[0].map(item =>
                <span key={item.company.id}
                      onClick={() => onChangeCompanyHandler(item.company.id)}
                      className={`${st.tab} ${item.company.id === companyId ? st.tabActive : ''}`}>
                {item.company.name}
              </span>)}
                <span onClick={createBalances} className={`${st.tab} ${st.tabActive} ${st.tabAdd}`}>
                + {translate.add_new_company_balance}
              </span>
              </div>
              <div className={st.companySelect}>
                <SelectCustom options={balance[0].map(bal => ({value: bal.company.id, label: bal.company.name}))}
                              valueID={companyId}
                              onChange={opt => onChangeCompanyHandler(opt.value)}/>
              </div>
            </>
            :
            <EmptyBalance createBalances={createBalances} translate={translate}/>}

          {balance[0].length > 0 &&
            balance[0].filter(x => x.company.id === companyId).map(item =>
              <Fragment key={item.id}>
                <div className={`${st.card} ${st.cardFirst}`}>
                  <div className={st.name}>{translate.free_balance_company}:</div>
                  <div className={st.sum}>{Stores.cleanSumm(item.sum / 100) + ' ₸'}</div>
                  <div className={st.topUp}>
                    <button className={st.topUpButton}
                            onClick={() => {
                              topUp('компания ' + item.company.name, item.company.id, true)
                            }}>
                      <TopUpIcon/>
                      <span>{translate.top_up_balance}</span>
                    </button>
                  </div>
                  <div className={st.actionMenu}>
                    <ActionMenu menu={menu} action={actions}
                                items={{
                                  topUp: {
                                    name: 'компания ' + item.company.name,
                                    id: item.company.id,
                                    isCompany: true,
                                  },
                                  transfer: {
                                    id: item.id,
                                    sum: item.sum,
                                    type_operation: 'company',
                                    name: 'компания ' + item.company.name
                                  },
                                }}/>
                  </div>
                </div>

                <div className={`${st.card} ${st.cardFirst}`}>
                  <div className={st.name}>{translate.bonus_balance}:</div>
                  <div className={st.balancesBlock}>
                    {balance[1].filter(el => el.company_id === companyId).map((item, index) => (
                        <>
                          <div key={index} className={st.bonusBalance}>
                            <span className={st.title}>Blocked Balance:</span>
                            <div className={st.value}>{Stores.cleanSumm(item.blocked_sum / 100)}<span> ₸</span></div>
                          </div>
                          <div className={st.sum}>{Stores.cleanSumm(item.bonus_balance / 100) + ' ₸'}</div>
                        </>
                    ))}
                </div>
                  <div className={st.topUp}/>
                  <div className={st.actionMenu}>
                    <ActionMenu menu={bonusMenu} action={actions} items={{
                      transfer: {
                        id: item.id,
                        sum: balance[1].filter(el => el.company_id === companyId).map((el) => (el.bonus_balance)).join(),
                        type_operation: 'bonus_balance',
                        name: 'компания ' + item.company.name,
                      },
                      transferBonus: {
                        id: item.id,
                        sum: balance[1].filter(el => el.company_id === companyId).map((el) => (el.bonus_balance)).join(),
                        type_operation: 'transfer_bonus',
                        name: 'компания ' + item.company.name,
                      },
                      topUpBonus: {
                        id: item.id,
                        sum: balance[1].filter(el => el.company_id === companyId).map((el) => (el.bonus_balance)).join(),
                        type_operation: 'top_up_bonus',
                      },
                      deleteBonus: {
                        id: item.id,
                        sum: balance[1].filter(el => el.company_id === companyId).map((el) => (el.bonus_balance)).join(),
                        type_operation: 'top_up_bonus',
                      }
                    }}/>
                  </div>
                </div>
              </Fragment>)}

          {balance[2].length > 0 &&
            balance[2].filter(x => x.group.company.id === companyId).map(item =>
              <div key={item.id} className={st.card}>
                <div>
                  <div className={st.groupName}>{translate.group} "{item.group.status.name}"</div>
                  <div className={st.name}>{item.group.number} - {item.group.name}</div>
                </div>
                <div className={st.balancesBlock}>
                  {item.bonus_balance > 0 && (
                    <div className={st.bonusBalance}>
                      <span className={st.title}>Bonus Balance:</span>
                      <div className={st.value}>{Stores.cleanSumm(item.bonus_balance / 100)}<span> ₸</span></div>
                    </div>
                  )}
                  <div className={`${st.sum} ${item.sum < 0 ? st.negativeValue : ''}`}>{Stores.cleanSumm(item.sum / 100) + ' ₸'}</div>
                </div>
                <div className={st.topUp}>{(item.group.status.id !== 6 && item.group.status.id !== 7) &&
                  <button className={st.topUpButton}
                          onClick={() => {
                            topUp(`${item.group.number} - ${item.group.name}`, item.group.id)
                          }}>
                    <TopUpIcon/>
                    <span>{translate.top_up_balance}</span>
                  </button>}
                </div>
                <div className={st.actionMenu}>
                  <ActionMenu menu={menu.filter(opt =>
                    !(opt.action === 'top_up' && (item.group.status.id === 6 || item.group.status.id === 7)))}
                              action={actions}
                              items={{
                                topUp: {
                                  name: `${item.group.number} - ${item.group.name}`,
                                  id: item.group.id,
                                  isCompany: false,
                                },
                                transfer: {
                                  id: item.id,
                                  sum: item.sum,
                                  type_operation: 'group',
                                  name: `${item.group.number} - ${item.group.name}`
                                }
                              }}/>
                </div>
              </div>)}
        </>
        :
        <Loader/>}
    </div>
  )
}
