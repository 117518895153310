import React, {useEffect, useState} from 'react';
import st from './ChooseSentenceForm.module.scss'
import ColorFullButton from "../ColorFullButton/ColorFullButton";
import {classNames as cn} from "../../../../../../../../helpers";

const ChooseSentenceForm = props => {

    const [answer, setAnswer] = useState('')
    const handleAnswersHandler = (form) => {
        setAnswer(form)
        props.handleAnswers({sentence: props.sentence, form}, true)
    }
    useEffect(() => {
        setAnswer('')
    }, [props.try])

    return (
        <div className={cn({
            [st.container]: true,
            [st.correct]: props.studentAnswer && props.studentAnswer.result_in_detail,
            [st.wrong]: props.studentAnswer && !props.studentAnswer?.result_in_detail
        })}>
            <div className={st.sentence}>{props.sentence}</div>
            <div className={st.buttons}>
                <ColorFullButton color={'green'} callback={() => handleAnswersHandler('true')}
                                 isSelected={answer === 'true' || props.studentAnswer?.answer === 'true'} isDisabled={props.studentAnswer}>✔</ColorFullButton>
                {props.data.unknown &&
                    <ColorFullButton color={'yellow'} callback={() => handleAnswersHandler('unknown')}
                                     isSelected={answer === 'unknown' || props.studentAnswer?.answer === 'unknown'} isDisabled={props.studentAnswer}>?</ColorFullButton>}
                <ColorFullButton color={'red'} callback={() => handleAnswersHandler('false')}
                                 isSelected={answer === 'false' || props.studentAnswer?.answer === 'false'} isDisabled={props.studentAnswer}>Х</ColorFullButton>
            </div>
        </div>
    );
};

export default ChooseSentenceForm;