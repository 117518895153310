import React, { useState, useEffect } from "react";
import { ReactComponent as InfoIcon } from "components/ContentBox/img/info-widget.svg";
import { localisationMaster } from "Localisation/Localisation";
import { classNames as cn } from "helpers";
import "./style.scss";

const Info = ({ title, placeholder, is_required }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal)
      document.body.addEventListener("click", () => setModal(false), {
        once: true,
      });
  }, [modal]);
  
  return (
    <div className="placementTestInfo" onClick={() => setModal(!modal)}>
      <InfoIcon />
      <div
        className={cn({
          "placementTestInfo__modal": true,
          "placementTestInfo__modal--active": modal,
        })}
      >
        <p className="placementTestInfo__title">{title}</p>
        <p className="placementTestInfo__text">
          {localisationMaster(
            "text_11",
            "c/tests/placement-test/create-widget"
          )}
          : {placeholder}
        </p>
        <p className="placementTestInfo__text">
          {localisationMaster(
            "text_12",
            "c/tests/placement-test/create-widget"
          )}
          :{" "}
          {localisationMaster(
            is_required ? "required" : "no_required",
            "c/tests/placement-test/create-widget"
          )}
        </p>
      </div>
    </div>
  );
};

export default Info;
