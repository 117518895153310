import React from "react";
import PropTypes from "prop-types";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import Avatar from "components/HeadBox/components/Avatar/Avatar";
import {ReactComponent as Basket} from "components/UI/icons/delete-bin-6-line.svg";
import {ReactComponent as BookIcon} from "components/UI/icons/book-2-line.svg";
import st from "./EbooksList.module.scss"
import style from "./MainInfo.module.scss";

const EbooksList = props => {

  return (
    <div className={st.list}>{props.ebooks.length > 0 ?
      props.ebooks.map(item =>
        <div key={item.id} className={st.book}>
          <div className={st.book__bookImg}>
            {item.image ?
              <img src={`${Stores.baseLink()}${item.image}`} alt={item.name}/>
              :
              <Avatar first_name={item.name[0]} last_name={item.name[1].toUpperCase()}/>}
          </div>
          <div className={st.bookInfo}>
            <h3 onClick={() => props.edit(item)}>{item.name}</h3>
            {/*<span>{localisationMaster('lessons', 'groups/group')}: {item.chapters_count}</span>*/}
          </div>
          <div className={st.preview}>
            <div className={st.iconBox}>
              <div className={st.deleteIcon}>
                <Basket onClick={e => {
                  props.delete(item)
                  e.stopPropagation()
                }} />
              </div>
              <div className={st.viewIcon}>
                <button>
                  <BookIcon/>
                  {props.translate.view_course}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
      :
        <div className={style.groupSimpleMainBoxNote}>
          <div className={style.noAddBlock}>
            <span>{props.translate.no_course_in_group}.</span>
            <button onClick={props.add} className={style.addButton}>
              {props.translate.add_course}
            </button>
          </div>
        </div>
    }
    </div>
  )
}

EbooksList.propTypes = {
  ebooks: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
}

export default EbooksList
