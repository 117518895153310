import React from 'react';
import { ReactComponent as Arrow } from '../../img/arrow.svg';
import { ReactComponent as Open } from '../../img/notification-open.svg';
import { ReactComponent as Close } from '../../img/notification-close.svg';
import { translate as loc, dict } from "Localisation";
import styles from './Notifications.module.scss';
import HeadBoxStore from "Stores/headBoxStore";
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import { classNames as cn } from "helpers";
import { LanguageContext } from 'components/HeadBox/HeadBox';
import { useContext } from 'react';
import Stores from 'Stores';

const ud = JSON.parse(localStorage.getItem('ud'))
const time_zone = ud?.user_data.profile.time_zone
const now = new Date(Date.now() + (new Date().getTimezoneOffset() + time_zone * 60) * 60 * 1000) // время по UTC

const getActualDate = (oldDate) => { // возвращает актуальную дату в зависимости от таймзона пользователя
    const date = new Date(oldDate);
    date.setTime(date.getTime() + (time_zone * 60 * 60 * 1000));
    return new Date(date);
};
  
const toTest = (timeStart) => { // дата из аргумента - актуальная дата клиента = промежуток в минутах 
    const date = new Date(timeStart);
    return Math.round((date - now) / 60000)
};  

const Notifications = ({ className, notifications, isNotifications, deleteMessage }) => {
    const isSound = HeadBoxStore.sound;
    const { translate } = useContext(LanguageContext);

    const getNotification = (el, type) => { // в зависимости
        const item = {
          placement_test_finished: { // от типа уведомления
            ...el, // парсить разные body
            title: translate.written_test,
            subtitle: () => el.payload.widget_name,
            link: {text: `${el.payload?.last_name || ""} ${el.payload.first_name}`, data: "/placement_test/result/" + el.payload.test_result_id},
          },
          study_test_purposed: {
            ...el,
            title: translate.new_testing,
            subtitle: () => {
              const actualDate = getActualDate(`${el.payload.date_from} ${el.payload.start_at}`);
              return `${timeConverter(actualDate, 'day_month')}, ${timeConverter(actualDate, 'time')}`;
            },
            link: {text: el.payload.name, data: "/study/test/" + el.payload.purposed_test_id},
          },
          study_test_about_to_start: {
            ...el,
            title: translate.start_testing,
            subtitle: () => {
              const actualDate = getActualDate(`${el.payload.date_from} ${el.payload.start_at}`);
              const minutes = toTest(actualDate);
              return minutes > 0
                ? `${translate.will_start_in} ${minutes} ${loc(translate.minutes)}`
                : `${timeConverter(actualDate, 'day_month')}, ${timeConverter(actualDate, 'time')}`;
            },
            link: {text: el.payload.name, data: "/study/test/" + el.payload.purposed_test_id},
          },
          study_test_about_to_end: {
            ...el,
            title: translate.finish_testing,
            subtitle: () => {
              const actualDate = getActualDate(`${el.payload.date_until} ${el.payload.finish_at}`);
              const minutes = toTest(actualDate);
              return minutes > 0
                ? `${translate.will_end_in} ${minutes} ${translate.minutes}`
                : `${timeConverter(actualDate, 'day_month')}, ${timeConverter(actualDate, 'time')}`;
            },
            link: {text: el.payload.name, data: "/study/test/" + el.payload.purposed_test_id},
          },
          debitors_responsible: {
            ...el,
            title: translate.debitors_responsible,
            subtitle: () => el.payload.debetors,
            link: {text: `${translate.debtors_on} ${timeConverter((new Date(el.payload.date)), 'day_month')}`, data: "/c/company/debetors"},
          },
          change_group_status: {
            ...el,
            title: el.payload.old_status === el.payload.new_status ? translate.change_group_status_one : `${translate.change_group_status_two} "${el.payload.new_status}"`,
            subtitle: () => `${el.payload.number} - ${el.payload.name}`,
            link: {text: `${translate.group}: `, data: `/groups/group/${el.payload.id}`},
            arrow: false,
          },
          add_client_to_group: {
            ...el,
            title: translate.add_client_to_group,
            subtitle: () => `${el.payload.number} - ${el.payload.name}`,
            link: {text: `${translate.group}:`, data: `/groups/my-group/${el.payload.id}`},
            arrow: false,
          },
          start_lesson: {
            ...el,
            title: translate.start_lesson,
            subtitle: () => `${el.payload.number} - ${el.payload.name}`,
            link: { text: `${translate.group}: `, data: `/groups/group/${el.payload.id}` },
            arrow: false,
          },
          add_new_bill: {
            ...el,
            title: translate.add_new_bill,
            subtitle: () => `${Stores.cleanSumm(el.payload.order_summ / 100)} ${translate.currency}`,
            link: { data: `/pays/invoice/${el.payload.order_id}`},
            arrow: false,
            
          },
          bill_confirmed: {
            ...el,
            title: `Счет клиента #${el.payload.order_id} подтвержден и зачислен`,
            subtitle: () =>  `${Stores.cleanSumm(el.payload.order_summ / 100) } ${translate.currency}`,
            link: {data: `/pays/invoice/${el.payload.order_id}`},
            arrow: false,
          },
        };
        return item[type];
    };  
    
    return (
        <div className={`${className} ${styles.notifications}`}>
            <div className={styles.notificationsHeader}>
                <p className={styles.notificationsHeader__title}>{translate.notifications}</p>
                <div 
                    className={styles.notificationsHeader__icon}
                    onClick={() => HeadBoxStore.setSound()}
                >
                    {isSound ? <Open /> : <Close />}
                </div>
            </div>
            {isNotifications
                ?  (
                    <div className={styles.notificationsWarning}>
                        <p className={styles.notificationsWarning__title}>{translate.no_unread_notifications}</p>
                    </div>
                )
                : (
                    <ul className={styles.notificationsList}>
                        {notifications.map((el, idx) => {
                            const element = getNotification(el, el.type);

                            if (!element) return;
                            return (
                                <li
                                    key={idx}
                                    onClick={({ target }) => deleteMessage(target, element)}
                                    className={cn({
                                        [styles.notificationsList__item]: true,
                                        [styles.notificationsList__item_delete]: element.is_read
                                    })}
                                >
                                    <div className={styles.notificationsList__box}>
                                        <div className={styles.notificationsList__check}></div>
                                        <div className={styles.notificationsList__content}>
                                            <p className={styles.notificationsList__title}>
                                                {element.title}
                                            </p>
                                            <div className={styles.notificationsList__text}>
                                                <p>{element.link.text}</p>
                                                <p>{element.arrow !== false && "->"} {element.subtitle()}</p>
                                            </div>
                                        </div>
                                        <div className={styles.notificationsList__time}>
                                            <p>{timeConverter(element.date, "notifications")}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                )
            }
            <div className={styles.notificationsFooter}>
                <div className={styles.notificationsFooter__content}>
                    <p className={styles.notificationsFooter__title}>{translate.view_all}</p>
                    <div className={styles.notificationsFooter__arrow}>
                        <Arrow />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications;