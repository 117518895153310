import React from 'react';
import st from './SolvePuzzle.module.scss'
import Stores from "../../../../../../../../Stores";
import {Puzzle} from "./Puzzle";

const SolvePuzzle = (props) => {
    return (
        <div className={st.puzzle}>
            <Puzzle
                imageSrc={Stores.baseLink() + props.ex.items[0].file}
                columns={props.ex.items[0].data.columns}
                rows={props.ex.items[0].data.rows}
                isAnswered={props.studentAnswer?.result}
                studentTry={props.try}
                studentAnswer={props.studentAnswer}
                handleAnswers={(isCorrect) => props.handleAnswers([isCorrect], true)}
            />
        </div>
    );
};

export default SolvePuzzle;