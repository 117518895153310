import { localisationMaster } from 'Localisation/Localisation';
import listingStore from '../../../../../Stores/listingStore';
import { permissions } from "settings/permissions";

// widget list
export const widget = [
    {
        link: 'placement-test-clients',
        folder: 'tests',
        permission: ['widgettest', 'view'],
        title: localisationMaster('title', 'tests/widget-clients'),
        colsSettings: {
            action: [
                { name: localisationMaster('name_14', 'tests/widget-clients'), action: 'record_to_new_online', perm: permissions.view_placement_test_list_result },
                {
                    name: localisationMaster('name_1', 'tests/past-tests'),
                    perm: permissions.view_placement_test_list_result,
                    nav_link: true,
                    blank: true,
                    link: '/placement_test/result',
                },
                { name: localisationMaster('name_15', 'tests/widget-clients'), action: "placement_test_create_user", perm: permissions.view_placement_test_list_result },
                { name: localisationMaster('name_12', 'tests/widget-clients'), action: 'delete_placement_test_client', perm: permissions.delete_placement_test_result },
            ],
            prefix: 'placement_test',
            link: 'finished_tests',
            id: { name: 'id' },
            main_rows: [
                {
                    compiled: [
                        { name: 'first_name', past: ' ', function: 'transliterate' },
                        { name: 'last_name', function: 'transliterate' },
                    ],
                },
                { name: 'email', depence: false },
                { name: 'phone', depence: false, function: 'phone_mask', classname: "tar" },
            ],
            is_open: false,
            sub_rows: [
                {
                    description: localisationMaster('name_13', 'tests/widget-clients'),
                    name: 'status',
                    function: 'test_valid_status',
                },
                { name: 'name', updir: 'location', description: localisationMaster('name_9', 'tests/widget-clients') },
                {
                    name: 'id', 
                    function: 'widget_result_valid_bundle',
                    description: localisationMaster('placeholder-10', 'online-schedule/record-customer'),
                },
                { name: 'date_start', description: localisationMaster('name_2', 'tests/widget-clients'), function: 'date_time' },
                { name: 'birth_date', description: localisationMaster('name_3', 'tests/widget-clients'), function: 'age' },
            ]
        },
        filterLiner: true,
        filterLinerLink: "placement_test/finished_tests",
        config: [
            { id: 1, nameState: 'name_email_phone', placeholder: localisationMaster('name_11', 'tests/widget-clients'), type: "input" },
            { id: 2, apiLink: 'placement_test/widget', nameState: 'widget', placeholder: localisationMaster('name_10', "tests/widget-clients"), type: "select_request" },
            { id: 3, apiLink: 'db/locations', nameState: 'location', placeholder: localisationMaster('Choose-a-location', 'online-schedule/time-slots'), type: "select_request" },
            { id: 4, apiLink: 'db/programs', nameState: 'program', placeholder: localisationMaster('name_16', 'tests/widget-clients'), type: "select_request" },
            { id: 5, apiLink: 'db/age', nameState: "age", placeholder: localisationMaster("placeholder-7", 'online-schedule/record-customer'), type: "select_request", },
        ],
        apiSettings: {}
    },
    {
        link: 'widget-list',
        folder: 'placement_test',
        headerLinkButton: {
            link: '/placement-test/create-widget',
            text: localisationMaster('header_button', 'c/widget/bundles')
        },
        permission: ['widget', 'view_widget'],
        title: localisationMaster('subtitle-question-1', 'index'),
        colsSettings: {
            action: [
                {
                    name: localisationMaster('btn_request_edit', 'c/tests/placement-test/create-widget'),
                    perm: permissions.edit_placement_test_create,
                    nav_link: true,
                    blank: true,
                    link: '/placement-test/create-widget',
                },
                { name: localisationMaster('text_26', 'c/tests/placement-test/create-widget'), action: 'delete', perm: permissions.delete_placement_test_widget },
            ],
            prefix: 'placement_test',
            link: 'widget',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
                { name: 'url', depence: false }
            ],
            is_open: false,
            sub_rows: [

            ]
        },
        apiSettings: {}
    },
    {
        link: 'widget-clients',
        folder: 'tests',
        permission: ['widgettest', 'view'],
        title: localisationMaster('title', 'tests/widget-clients'),
        colsSettings: {
            action: [
                { name: localisationMaster('name_1', 'tests/widget-clients'), action: 'record_to_online', perm: permissions.edit_widget_result },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', perm: permissions.edit_widget_result },
                { name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'), action: 'detail', perm: permissions.view_widget_list_result },
            ],
            prefix: 'widget',
            link: 'widget_test',
            id: { name: 'id' },
            main_rows: [
                // { name: 'name' },
                {
                    compiled: [
                        { name: 'name', past: ' ', function: 'transliterate' },
                        { name: 'last_name', function: 'transliterate' },

                    ],
                },
                { name: 'email', depence: false },
                { name: 'phone', depence: false, function: 'phone_mask', classname: "tar" },
            ],
            is_open: false,
            sub_rows: [
                { name: 'created_at', description: localisationMaster('name_2', 'tests/widget-clients') },
                { name: 'birthday', description: localisationMaster('name_3', 'tests/widget-clients'), function: 'age' },
                { name: 'result_lvl', description: localisationMaster('name_4', 'tests/widget-clients') },
                { name: 'is_visitor_name', description: localisationMaster('name_5', 'tests/widget-clients') },
                { name: 'is_client_name', description: localisationMaster('name_6', 'tests/widget-clients') },
                { name: 'age_name', description: localisationMaster('name_7', 'tests/widget-clients') },
                { name: 'program_name', description: localisationMaster('name_8', 'tests/widget-clients') },
                { name: 'location_name', description: localisationMaster('name_9', 'tests/widget-clients') },
            ]
        },
        filterLiner: true,
        config: [
            { id: 1, nameState: 'search', placeholder: 'ФИО, e-mail или телефон клиента', type: 'input' },
        ],
        filterLinerLink: 'widget/widget_test',
    },
    {
        link: 'bundle',
        folder: 'widget',
        modal_type: 'GlobalSuperModal',
        title: 'Вопросы к связке',
        sec_title: {
            link: 'placement_test/bundles/',
            field: 'name',
        },
        modal_settings: {
            name: 'addQuestionsPlacementTest',
            link: 'placement_test/questions',
            button: localisationMaster('button-two', 'groups/categories', 'add_category'),
            title: 'Добавление нового вопроса',
            type: 'addQuestionsPlacementTest',
            data: 'json',
            func: listingStore.updateData,
            getMatchId: 'bundle',
            valid: {
                question_text:  'required',
                question_type:  'required',
            },
        },
        modalValues: { right_answer_scores: 1 },
        permission: ['widgetquestion', 'view'],
        button: [localisationMaster('button-title', 'tests/questions'), permissions.edit_placement_test_question_widget],
        colsSettings: {
            items: [],
            action: [
                { name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'), action: 'detail', perm: permissions.view_widget_bundle },
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit-question-bundle', perm: permissions.edit_placement_test_question_widget },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete_question', perm: permissions.edit_placement_test_question_widget },
            ],
            link: 'questions',
            prefix: 'placement_test',
            id: { name: 'id' },
            questions_the_widget: true,
            main_rows: [
                { name: 'question_text', classname: 'w100', function: 'trim_characters' },
                { name: 'right_answer_scores', classname: 'tar', prev: 'Баллов: ' },
                { name: 'question_type', classname: 'tar', function: 'qtype', },
            ],
            is_open: false,
            sub_rows: []
        },
        filter_by_id: 'bundle',
        apiSettings: {},
    },

    {
        link: 'bundles',
        folder: 'widget',
        permission: ['widgetprogramagerelationstable', 'view'],
        button: [localisationMaster("button-title", "tests/settings"), 'surcharge', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'addBundle',
            link: 'placement_test/bundles',
            button: localisationMaster('button-two', 'groups/categories', 'add_category'),
            title: 'Добавление новой связки',
            // type: 'BaseTemplate',
            type: 'addBundle',
            data: 'json',
            func: listingStore.updateData,
            valid: {
                name: 'required',
                age: 'required',
                program: 'required',
                test_duration: 'required',
            },
        },
        modalValues: { test_duration: 60 },
        title: localisationMaster("title", "c/widget/bundles"),
        colsSettings: {
            action: [
                { name: localisationMaster("text_1", "c/widget/bundles"), nav_link: true, link: '/c/widget/bundle', perm: permissions.view_widget_bundle_list },
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit-bundles', perm: permissions.edit_widget_bundle },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete_bundle', perm: permissions.edit_widget_bundle, deletePrev: "Вы действительно хотите удалить" },
            ],
            prefix: 'placement_test',
            link: 'bundles',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
                { name: 'name', depence: false, updir: 'program' },
                { name: 'name', depence: false, updir: 'age', classname: 'tar' },
            ],
            sub_rows: [
                { name: 'questions_count', description: 'Кол-во вопросов' },
                { name: 'test_duration', description: 'Длительность (мин)' },
                { name: 'levels', function: 'levels_list', description: 'Уровни' },
            ]
        },
        filterLiner: true,
        filterLinerLink: "placement_test/bundles",
        config: [
            { id: 0, nameState: 'name', placeholder: localisationMaster("table-row-title-1", "lessons/levels"), type: "input" },
            { id: 2, apiLink: 'db/programs', nameState: 'program', placeholder: localisationMaster("placeholder-10", "online-schedule/record-customer"), type: "select_request" },
            { id: 1, apiLink: 'db/age', nameState: 'age', placeholder: localisationMaster("placeholder-12", "online-schedule/record-customer"), type: "select_request" },
        ],
        apiSettings: {}
    },
]

    // -------------Список прошедших тестов------------
/* {
    link: 'past-tests',
    folder: 'tests',
    permission: ['teachertestpoll', 'view'],
    button: '',
    modal_type: '',
    modal_placeholder: '',
    title: localisationMaster('title', 'tests/past-tests'),
    colsSettings: {
        action: [
            { name: localisationMaster('name_1', 'tests/past-tests'), action: 'test' ,permission: ['teachertestpoll', 'edit']},
        ],
        prefix: 'lessons',
        link: 'teacher_poll_test',
        id: { name: 'id' },
        main_rows: [
            {
                compiled: [
                    { name: 'name', past: ' '},
                    { name: 'lvl_name', past: ' ('},
                    { name: 'sub_lvl_name', past: ')'},

                ],
            },
            { name: 'age_name', depence: false },
            {
                compiled: [
                    { name: 'total_result', past: '/', prev: localisationMaster('name_table-row-title-7_1', 'tests/past-tests')},
                    { name: 'total_tests'},
                ],
            },

        ],
        is_open: false,
        sub_rows: [
            { name: 'name', description: localisationMaster('table-row-title-3', 'tests/past-tests'), },
            { name: 'program_name', description: localisationMaster('table-row-title-1', 'tests/tests'), },
        ]
    },

    filter: true,
    filterLink: "lessons/teacher_poll_test",
    config: [
        { id: 2, apiLink: 'db/age', nameState: 'age', text: localisationMaster('select-two', 'tests/questions', 'question_plus_variants'), type: "select" },
        { id: 3, apiLink: 'db/programs', nameState: 'program', text: localisationMaster('select-one', 'tests/questions', 'question_plus_variants'), type: "select" },
        { id: 4, apiLink: 'study/levels', nameState: 'lvl_id', text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "selectLvl" },
        { id: 5, apiLink: 'study/levels', nameState: 'lvl', text: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "selectSubLvl" },
        { id: 1, nameState: 'search', placeholder: localisationMaster('text-1_1', 'filter'), type: "input" },
    ],
    apiSettings: {}
}, */


/* {
    link: 'tests',
    folder: 'tests',
    permission: ['teacherquestion', 'view'],
    button: '',
    modal_type: '',
    modal_placeholder: '',
    title: localisationMaster('title', 'tests/tests'),
    colsSettings: {
        action: [
            { name: localisationMaster('text_1', 'tests/tests'), action: 'groupTest' },
        ],
        prefix: 'lessons',
        link: 'teacher_relations',
        sub_link: 'teacher_poll_test',
        id: { name: 'id' },
        main_rows: [
            {
                compiled: [
                    { name: 'main_lvl_name', past: ' ('},
                    { name: 'sub_lvl_name', past: ')'},
                ],
            },
            { name: 'age_name', depence: false },
            { compiled: [
                    { name: 'total_questions', prev: localisationMaster('text_1', 'tests/settings')},
                ],
            },

        ],
        is_open: false,
        sub_rows: [
            { name: 'program_name', description: localisationMaster('table-row-title-1', 'tests/tests'), },
        ]
    },

    filter: true,
    filterLink: "lessons/teacher_relations",
    config: [
        { id: 2, apiLink: 'db/age', nameState: 'age', text: localisationMaster('select-two', 'tests/questions', 'question_plus_variants'), type: "select" },
        { id: 3, apiLink: 'db/programs', nameState: 'program', text: localisationMaster('select-one', 'tests/questions', 'question_plus_variants'), type: "select" },
        { id: 4, apiLink: 'study/levels', nameState: 'lvl_id', text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "selectLvl" },
        { id: 5, apiLink: 'study/levels', nameState: 'lvl', text: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "selectSubLvl" },
    ],
    apiSettings: {
        is_teacher: true,
    }
}, */

/*  {
     link:'past-tests-students',
     folder: 'tests',
     permission: ['teachertestresult', 'view'],
     title: localisationMaster('title', 'tests/past-tests'),
     colsSettings: {
         action: [
             { name: localisationMaster('text_10', 'lessons/course'), action: 'detail', permission: ['teachertestresult', 'view']},
         ],
         prefix: 'lessons',
         link: 'student_test_result',
         id: { name: 'id' },
         main_rows: [
             { name: 'program_name', depence: false},
             { name: 'age_name', depence: false},
             { compiled: [
                     { name: 'total_question', prev: localisationMaster('table-row-title-3', 'tests/settings')},
                 ],
             },
             {name: 'result', depence: false},
         ],
         is_open: false,
         sub_rows: [
             { name: 'program_name', description: localisationMaster('table-row-title-1', 'tests/past-tests') },
             { name: 'age_name', description: localisationMaster('table-row-title-2', 'tests/past-tests') },
             { name: 'total_question', description: localisationMaster('table-row-title-3', 'tests/past-tests') },
             { name: 'lvl_name', description: localisationMaster('table-row-title-4', 'tests/past-tests') },
             { name: 'sub_lvl_name', description: localisationMaster('table-row-title-5', 'tests/past-tests') },
             { name: 'result', description: localisationMaster('table-row-title-8', 'tests/past-tests') },
             ]
     },
     multitype: true,
     mulitype_values:["is_future",localisationMaster('sub-menu-button-1', 'tests/past-tests'), localisationMaster('sub-menu-button-2', 'tests/past-tests')],
     apiSettings: {
     }
 }, */