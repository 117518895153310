import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions'
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import ActionMenu from '../Global/ActionMenu/ActionMenu';
import './CourseDocs.scss';
import docs_img from '../img/google-docs.svg';


const CourseDocs = props => {
    let apiConf;

    let modal_settings = {
        name: 'lessons_docs',
        link: 'lessons/doc',
        button: localisationMaster('button-two', 'company/age', 'add_age'),
        title: localisationMaster('modal_doc_text_1', 'lessons/course'),
        type: 'SelectorAndInput',
        active_inputs: ['text', /*'media',*/ 'file-upload', 'textarea', 'checkbox'],
        //data: 'media',
        //media: true,
        name_media: 'file',
        pl_text: localisationMaster('modal_doc_text_2', 'lessons/course'),
        name_text: 'name',
        pl_textarea: localisationMaster('modal_doc_text_3', 'lessons/course'),
        name_textarea: 'description',
        size_textarea: 500,
        pl_checkbox: localisationMaster('modal_doc_text_4', 'lessons/course'),
        checkbox_name: 'is_example',
        media_name: localisationMaster('modal_doc_text_5', 'lessons/course'),
        media_type: localisationMaster('modal_doc_text_6', 'lessons/course'),
        course_id: +props.id,
        checkbox_hidden: !Stores.checkAdmin(),
        func: props.drop,
        valid: {
            name: 'required',
            file: 'required',
        }
    }

    const menu = [
        {name: localisationMaster('text_16', 'lessons/course'), active: true, action: 'download', permission: ['forward']},
        {name: localisationMaster('text_11', 'lessons/course'), active: true, action: 'edit', permission: ['forward']},
        {name: localisationMaster('text_13', 'lessons/course'), active: true, action: 'delete', permission: ['forward']},
    ]

    const [open, setOpen] = useState(false)
    const [docs, setDocs] = useState([false, []])

    const cross_download = (url, fileName) => {
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        var __fileName = fileName;
        req.onload = function () {
            var blob = req.response;
            var contentType = req.getResponseHeader("content-type");
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
            } else {
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link); //remove the link when done
            }
        };
        req.send();
    }

    const action = (data, e, items) => {
        switch (data) {
            case 'delete':
                apiConf = {
                    method: 'delete',
                    link: 'lessons/doc/' + items.id
                }
                superAxiosRequest(apiConf).then(() => {
                    let arr = docs[1].filter(x => x.id !== items.id)
                    setOpen(false)
                    setDocs([true, arr])
                }).catch(() => {
                })
                break;
            case 'edit':
                let body = {
                    name: items.name,
                    description: items.description,
                    is_example: items.is_example
                }
                modal_settings.is_put = true
                modal_settings.link = modal_settings.link + '/' + items.id
                modal_settings.active_inputs = ['text', 'textarea', 'checkbox']
                modal_settings.button = localisationMaster('text_54', 'groups/all-groups')
                modal_settings.valid = {
                    name: 'required',
                }
                modalStore.activity(modal_settings, body)
                break;
            case 'download':
                cross_download(items.file, items.name)
                break;
            default:
                break;
        }
    }

    const getType = name => {
        let arr = []
        if (name) {
            arr = name.split('.')
            return arr[arr.length - 1]
        } else {
            return 'ERR'
        }
    }

    useEffect(() => {
        setDocs([true, props.items])
    }, [props.items])

    return (
        <div className="courseDocs">
            <div className="courseDocs__header" style={!open ? {borderBottom: "none"} : null}>
                <div className="courseDocs__header--count">
                    <span>{localisationMaster('text_17', 'lessons/course')} {docs[1].length} </span>
                    {docs[1].length ?
                    <span onClick={() => setOpen(!open)}>({open ? localisationMaster('text_19', 'lessons/course') : localisationMaster('text_20', 'lessons/course')})
                    </span>
                    : null}
                    </div>
                <div>
                    {props.id &&
                    <button onClick={() => modalStore.activity(modal_settings)}>
                        {localisationMaster('text_18', 'lessons/course')}
                    </button>}
                </div>
            </div>
            <div className={open ? "courseDocs__body" : 'dn'}>
                {(docs[0] && docs[1].length > 0) &&
                docs[1].slice().sort((a, b) => a.id < b.id ? 1 : -1).map((item, index) =>
                    <div className="courseDocs__doc" key={index}>
                        <div className="courseDocs__file">
                            <div className="courseDocs__icon">
                                <img src={docs_img} alt={getType(item.file)}/>
                                <span>{getType(item.file)}</span>
                            </div>
                            <div className="courseDocs__name">
                                <a href={item.file} download>{item.name}</a>
                            </div>
                        </div>
                        <div className="courseDocs__description">
                            {item.description}
                        </div>
                        <div className="courseDocs__date">
                            <span>{timeConverter(item.created_at, 'dd.mm.yyyy')}</span>
                            <span>{timeConverter(item.created_at, 'time')}</span>
                            <span>{item.created_fullname}</span>
                        </div>
                        {props.id &&
                        <div className="courseDocs__action">
                            <ActionMenu menu={menu} action={action} items={item}/>
                        </div>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default observer(CourseDocs)
