import React from 'react'
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import './DateTime.css';

export default function DateTime(props) {

    const cleanTime = date => {
        let h = date.getHours()
        if (+h < 10) h = '0' + h
        let m = date.getMinutes()
        if (+m < 10) m = '0' + m
        return `${h}:${m}`
    }

    return (
        <div className="datetime-box" style={{...props.errorCheker && {borderColor: props.errorCheker('finish_at')}}}>
            <DatePicker
                selected={props.start_at && new Date('2020-01-01 ' + props.start_at.slice(0, 5))}
                //timeCaption="Heure"
                onChange={date => props.objectChangeHandler(cleanTime(date), 'start_at')}
                disabled={props.disabled}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption={props.cap1 ? props.cap1 : 'Начало'}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
            />
            <div className="datetime-box-middle">-</div>
            <DatePicker
                selected={props.finish_at && new Date('2020-01-01 ' + props.finish_at.slice(0, 5))}
                //timeCaption="Heure"
                onChange={date => props.objectChangeHandler(cleanTime(date), 'finish_at')}
                showTimeSelect
                disabled={props.disabled}
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption={props.cap2 ? props.cap2 : 'Конец'}
                timeFormat="HH:mm"
                dateFormat="HH:mm"
            />
        </div>
    )
}
