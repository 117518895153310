import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import styles from './ArrowButton.module.scss';

function ArrowButton({ left, onClick, isDisabled }) {
  return (
    <button
      type='button'
      data-arrow={left ? 'left' : 'right'}
      className={cn({
        [styles.base]: true,
        [styles.left]: left,
        [styles.disabled]: isDisabled,
      })}
      onClick={isDisabled ? null : onClick}
    />
  );
}

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  left: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ArrowButton.defaultProps = {
  left: false,
  isDisabled: false,
};

export default ArrowButton;

