import React from "react";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import Info from "./Info/Info";
import { ReactComponent as OptionsIcon } from "components/ContentBox/img/options.svg";

const CRMField = ({ el, toggleField, openModal, index }) => {
  return (
    <div className="placementTestBox__toggleLine">
      <CheckboxToggle
        right={el.placeholder}
        checked={el.is_enabled}
        error={true}
        onChange={() => toggleField(index, el)}
      />
      <div className="placementTestBox__activeField">
        <Info title={el.placeholder} name={el.crm_name} />
        <OptionsIcon onClick={() => openModal(el)} />
      </div>
    </div>
  );
};

export default CRMField;
