import React from "react";
import PropTypes from "prop-types";
import st from "./HeaderContentBox.module.scss";

const HeaderContentBox = ({children, className}) => {
  return (
    <div className={`${st.headerContentBox} ${className ?? ''}`}>
      {children}
    </div>
  )
}

HeaderContentBox.propTypes = {
  className: PropTypes.string
}

export default HeaderContentBox;
