import React, { Fragment, useState, useEffect } from 'react';
import st from "./WriteWords.module.scss"

export default function WriteWords(props) {
  let text = props.text
  text = text.replaceAll('<div>', '<br/>')
  text = text.replaceAll('<br>', '')
  text = text.replaceAll('</div>', '')

  const initAnswers = () => {
    const reg = /\[(.*?)]/g;
    const arr = [];
    const arr2 = [];

    for (let m = reg.exec(props.answers?.[0]); m; m = reg.exec(props.answers?.[0])){
      arr2.push(m[1])
    }

    for (let i = 0; i < text.match(reg).length; i++) {
      arr.push(arr2[i] || '')
    }

    return arr
  }

  const [answers, setAnswers] = useState(initAnswers)

  const myChangeHandler = (e, index) => {
    let arr = [...answers];
    arr[index] = e.target.value
    setAnswers([...arr])
  }

  useEffect(() => {
    if (answers.every(a => a.length)){
      const body = aPartText.reduce((acc, part, idx) => `${acc}${part}${answers[idx] ? `[${answers[idx]}]` : ''}`, '')
      props.handleAnswers([body])
    } else {
      props.handleAnswers([])
    }
    //props.handleAnswers(answers.every(a => a.length) ? answers : [])
  }, [answers])

  //const aPartText = text.split(/\[.*?]/).filter(el => el.length)
  const aPartText = text.split(/\[.*?]/)

  return (
    <div className={st.text}>{aPartText.map((item, index) =>
      <Fragment key={index}>
        <span dangerouslySetInnerHTML={{__html: item}}/>
        {index < aPartText.length - 1 &&
        <input
          type="text"
          value={answers[index]}
          onChange={e => myChangeHandler(e, index)}
          className="input-type1"
        />}
      </Fragment>)}
    </div>
  )
};
