import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import { superAxiosRequest } from "axiosApi";
import { localisationMaster } from 'Localisation/Localisation';
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import swal from "sweetalert";
import { permissions } from 'settings/permissions';
import { checkPermList } from 'helpers';
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import Loader from 'components/UI/Loader/Loader';
import Rating from "components/ContentBox/Global/GlobalModalSingle/templates/groups/Rating";
import './Reports.module.scss';
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import StudentsProgressReport
    from "../../components/StudentsProgressReports/StudentsProgressReport/StudentsProgressReport";
import style from './Reports.module.scss'
const Reports = (props) => {
    const id = props.match.params.id;
    const isClient = Stores.isClient;
    const [reports, setReports] = useState({ loading: true, list: [], group: null, students: [] });

    const list = [
        (checkPermList(permissions.edit_propgress_report) && { name: 'Редактировать', action: 'edit', permission: ['forward'] }),
        (checkPermList(permissions.delete_propgress_report) && { name: 'Удалить', action: 'delete', permission: ['forward'] }),
    ];

    const valid = { client: 'required', comment: 'required' };

    const actions = (data, e, item) => {
        switch (data) {
            case 'edit':
                let list = item.rate_list.map(el => { return { evaluation: el.evaluation, rating: el.rating } })
                let bo = {
                    comment: item.comment,
                    evaluation_date: item.evaluation_date,
                    rate_list: list,
                    client: item.client
                }
                const ms = {
                    title: `Отчет об успеваемости: ${item.client_name}`,
                    button: localisationMaster('text_54', 'groups/all-groups'),
                    link: 'progress_report/report/' + item.id,
                    st: `${item.client_name}`,
                    modalBodyComponent: () => <Rating />,
                    width: 800,
                    is_put: true,
                    func: (data) =>  setReports({ ...reports, list: data.reports, students: data.students }),
                    valid
                }
                modalStore.activity(ms, bo)
                break;
            case 'delete':
                swal({
                    title: `Вы действительно хотите удалить "Отчет об успеваемости, ${item.client_name}" за ${timeConverter(item.evaluation_date, 'month_year')} г.?`,
                    text: 'Восстановить удаленные данные будет невозможно.',
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                }).then(okay => {
                    if (!okay) return;
                    setReports({ ...reports, loading: true });
                    superAxiosRequest({ link: 'progress_report/report/' + item.id, method: 'delete' }).then(({ data }) => {
                        setReports({ ...reports, loading: false, list: data.reports, students: data.students });
                    })
                });
                break;
            default:
                break;
        }
    }
    
    useEffect(() => {
        superAxiosRequest({ link: 'progress_report/report', method: "get", params: { group_id: id } }).then(({ data }) => {
            setReports({ ...reports, loading: false, group: data.group, list: data.reports, students: data.students })
        })
    }, [id])

    if (reports.loading) return <Loader />
    return (
        <>
            <ContentHeader
                title={`Отчеты об успеваемости: ${reports.group?.number} - ${reports.group?.name}`}
                modal_type={!isClient && "GlobalSuperModal"}
                button={!isClient && ['Добавить', 'view_tests_group']}
                modal_settings={{
                    title: `Отчет об успеваемости`,
                    button: localisationMaster('text_54', 'groups/all-groups'),
                    link: 'progress_report/report',
                    modalBodyComponent: () => <Rating />,
                    func: (data) => setReports({ ...reports, list: data.reports, students: data.students }),
                    width: 600,
                    valid,
                }}
                modalValues={{  
                    group: id, 
                    evaluation_date: timeConverter(new Date(), 'today'), 
                    students: !isClient && reports.students.map(el => ({ id: el.id, name: el.full_name })),
                }}
                breadСrumbs={
                    isClient ? 
                        [ { title: `Группа: ${reports.group?.number} - ${reports.group?.name}`, route: `/groups/group/${reports.group?.id}` } ]
                    : [
                        { title: 'Учебные группы', route: '/groups/all-groups' },
                        { title: `Группа: ${reports.group?.number} - ${reports.group?.name}`, route: `/groups/group/${reports.group?.id}` },
                    ]
                }
            />
            <div className={style.reportsGroup}>
                {reports.list?.length > 0 ? reports.list.map((el,index) =>
                    // <Box
                    //     fullname={el.client_name}
                    //     date={{ data: el.evaluation_date, format: 'clean_data' }}
                    //     link={!isClient && `/account/${el.client}`}
                    //     menu={(!isClient && list.filter(el => el).length > 0) && { list, actions, item: el }}
                    //     key={el.id}
                    // >
                    //     <div className="reportsGroup__content">
                    //         {el.rate_list.length > 0 && <ul className="reportsGroup__list">
                    //             {el.rate_list.map(el => <li key={el.id}>{el.evaluation_name}: {el.rating}</li>)}
                    //         </ul>}
                    //         <p dangerouslySetInnerHTML={{ __html: el.comment }} />
                    //     </div>
                    // </Box>
                    <StudentsProgressReport key={index} item={el} menu={list} actions={actions} listing={true}/>
                ) :<div className={style.empty}>
                    <EmptyData/>
                </div>

                }
            </div>
        </>
    )
}

export default observer(Reports);

