import React from 'react';
import {getPeriodStr} from "../functions";
import Stores from "Stores";
import TimeLeft from "../TimeLeft";
import st from "./StudyTestInfo.module.scss"
import {localisationMaster} from "Localisation/Localisation";


export default function StudyTestInfo({values}) {
    return (
        <div className={st.info}>
            <div className={st.block}>
                <p className={st.period}>
                    <strong>{getPeriodStr(values)}<br/>(GMT {Stores.utz})</strong>
                </p>
                <div className={st.timeBox}>
                    {(values.seconds_until_finish > 0 && values.seconds_until_start < 0) ?
                        <>
                            <span className={st.str}>{localisationMaster('text_2', 'study/test')}:</span>
                            <TimeLeft delta={values.seconds_until_finish}/>
                        </>
                        :
                        (values.seconds_until_finish < 0 && values.seconds_until_start < 0)
                            ?
                            <span className={st.testEnd}>{localisationMaster('text_3', 'study/test')}!</span>
                            :
                            <>
                                <span className={st.str}>{localisationMaster('text_4', 'study/test')}:</span>
                                <TimeLeft delta={values.seconds_until_start}/>
                            </>}
                </div>
            </div>
            <div className={st.blockPadding}>
                <div className={st.lines}>
                    <p>
                        <span>{localisationMaster('table-row-title-3', 'tests/settings')}</span>
                        <span>{values.study_test.quantity_questions}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_5', 'study/test')}:</span>
                        <span>{values.during} {localisationMaster('mins', 'groups/group')}.</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_6', 'study/test')}:</span>
                        <span>{values.try_count}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_7', 'study/test')}:</span>
                        <span>{values.shuffle ? localisationMaster('text_18', 'study/study-test', 'modal') : localisationMaster('text_19', 'study/study-test', 'modal')}</span>
                    </p>
                </div>
            </div>
            <div className={st.blockPadding}>
                <div className={st.lines}>
                    <p>
                        <span>{localisationMaster('text_8', 'study/test')}:</span>
                        <span>{values.focus_live ? `${values.focus_live_during} ${localisationMaster('sec', 'study/test')}.` : localisationMaster('no', 'study/test')}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_9', 'study/test')}:</span>
                        <span>{values.focus_live ? `${values.focus_live_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : localisationMaster('no', 'study/test')}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_16', 'study/test')}:</span>
                        <span>{values.mouse_freeze ? `${values.mouse_freeze_during} ${localisationMaster('sec', 'study/test')}.` : localisationMaster('no', 'study/test')}</span>
                    </p>
                    <p>
                        <span>{localisationMaster('text_11', 'study/test')}:</span>
                        <span>{values.mouse_freeze ? `${values.mouse_freeze_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : localisationMaster('no', 'study/test')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}
