import React from 'react';
import {observer} from 'mobx-react';
import {ReactComponent as Doc} from 'components/ContentBox/img/google-docs.svg';
import modalStore from 'Stores/modalStore';
import './FileList.scss';

const FileList = () => {
    const { modalValue, listInKeyChangeHandler} = modalStore;
    const handler = {}
    const modalData = new Proxy(modalValue, handler)

    const addFiles = (e) => {
        let files
        files = modalData.files.map(el => el)
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(e.target.files[i])
        }
        listInKeyChangeHandler(files, 'files')
    }

    const updateTitles = (index, value) => {
        let arr = [...modalData.titles]
        arr[index] = value
        listInKeyChangeHandler(arr, 'titles')
    }

    const deleteFile = (index) => {
        let files = [];
        let title = [];
        for (let i = 0; i < modalData.files.length; i++) {
            if(i !== index) files.push(modalData.files[i])
        }
        for (let y = 0; y < modalData.titles.length; y++) {
            if(y !== index) title.push(modalData.titles[y])
        }
        listInKeyChangeHandler(files, 'files')
        listInKeyChangeHandler(title, 'titles')

    }

    const getType = name => {
        let arr = []
        if (name) {
            arr = name.split('.')
            return arr[arr.length - 1]
        } else {
            return 'ERR'
        }
    }

    return (
        <div className="fileList">
            <div className="fileList_upload">
                <input type="file" name="file[]" id="file" multiple
                    onChange={(e) => addFiles(e)}
                />
                <label className="super-modal__add-button" htmlFor="file"> Добавить файл</label>
            </div>
            <div className="fileList_list">
                {
                    modalData.files.map((el, index) => (
                        <div className="fileList_item">
                            <div className="courseDocs__icon">
                                <Doc />
                                <span>{getType(el.name)}</span>
                            </div>
                            <input value={modalData.titles[index]} onChange={(e) => updateTitles(index, e.target.value)}/>
                            <div onClick={()=> deleteFile(index)} className="fileList_delete">x</div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default observer(FileList);
