import React, { useEffect, useState } from 'react';
import styles from './MonthlyPlan.module.scss';
import { superAxiosRequest } from 'axiosApi';
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import { translate as loc, dict } from "Localisation";


const MonthlyPlan = () => {
    const [monthlyPlanData, setMonthlyPlanData] = useState([
        {title: `${loc(dict.monthly_plan.monthly_plan)}:`, value: loc(dict.monthly_plan.text_2) },
        {title: `${loc(dict.monthly_plan.actual_plan)}:`, value: loc(dict.monthly_plan.text_2) },
        {title: `${loc(dict.monthly_plan.work_in)} ${timeConverter(new Date((new Date()).setDate(6) - (30 * 24 * 60 * 60 * 1000) ), 'in_month')}:` , value: loc(dict.monthly_plan.text_2) },
        {title: `${loc(dict.monthly_plan.about_work)} ${timeConverter(new Date(), 'in_month')}:`, value: loc(dict.monthly_plan.text_2) },
    ])
    const apiConf = {
        method: 'get',
        link: 'online_schedule/teacher/slots',
        params: {
            test_plan: true,
        }
    }
    const getDateTime = (time) => new Date(new Date(2022, 1, 1, 0, 0, 0, 0).setMinutes(Math.abs(time))) // принимает колличество минут , возвращает время в формате Date
    const getTimeString = (time) => `${time.getHours()} ${loc(dict.monthly_plan.ch)} 
        ${time.getMinutes()} ${loc(dict.monthly_plan.min)}` // принимает время в формате Date , возвращает string с датой в формате "0 ч. 0 мин."  
    useEffect(() => {    
        superAxiosRequest(apiConf).then(response => {
            if (response.data[0]) {
                const lastItem = response.data[response.data.length - 1]
                const monthPlanTime = getDateTime(lastItem.per_month_plan)
                const leftToWorkThisMonthTime = getDateTime(lastItem.data.left_to_work_this_month)
                const reservedThisMonthTime = getDateTime(lastItem.data.reserved_this_month)
                const thisMonthPlanTime = getDateTime(lastItem.data.this_month_plan)
                setMonthlyPlanData([
                    {...monthlyPlanData[0], value: getTimeString(monthPlanTime)},
                    {...monthlyPlanData[1], value: getTimeString(thisMonthPlanTime)},
                    {...monthlyPlanData[2], value: getTimeString(reservedThisMonthTime)},
                    {...monthlyPlanData[3], value: getTimeString(leftToWorkThisMonthTime )},
                ])
            }
         })
    },[])
    return (
        <div className={styles.monthlyPlan}>
            <h3 className={styles.monthlyPlan__title}>{loc(dict.monthly_plan.text_1)}</h3>
            <div className={styles.monthlyPlan__wrapper}>
                {monthlyPlanData.map( (item, id )=> <div key = {id} className={styles.monthlyPlan__card}>
                    <div >{item.title}</div>
                    <div className={styles.monthlyPlan__bold}>{item.value}</div>
                </div> )}
            </div>

        </div>
    )
}

export default MonthlyPlan;