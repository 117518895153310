import React, { useState, useEffect } from "react";
import { ReactComponent as InfoIcon } from "components/ContentBox/img/info-widget.svg";
import { classNames as cn } from "helpers";
import { localisationMaster } from "Localisation/Localisation";

const Info = ({ title, name }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal)
      document.body.addEventListener("click", () => setModal(false), {
        once: true,
      });
  }, [modal]);
  
  return (
    <div className="placementTestInfo" onClick={() => setModal(!modal)}>
      <InfoIcon />
      <div
        className={cn({
          "placementTestInfo__modal": true,
          "placementTestInfo__modal--active": modal,
        })}
      >
        <p className="placementTestInfo__title">{title}</p>
        <p className="placementTestInfo__text">
          {localisationMaster("text_39", "c/tests/placement-test/create-widget")}: 
          {name.length ? name : localisationMaster('text_2', 'placement_test/result')}
        </p>
      </div>
    </div>
  );
};

export default Info;
