import React from 'react';
import style from './Easy.module.scss'
import {ReactComponent as Confirm} from "components/UI/icons/confirm_icon.svg";
import {ReactComponent as Failed} from "components/UI/icons/failed_icon.svg";

const Easy = ({setMark, addMark}) => {
    return (
        <div className={style.easyContainer}>
            <div className={style.btn} onClick={() => addMark(1)}>
                <Confirm/>
                <span>Confirm The Lesson</span>
            </div>
            <div className={`${style.btn} ${style.failed}`} onClick={() => addMark(0)}>
                <Failed/>
                <span>Homework Failed</span>
            </div>
        </div>
    );
};

export default Easy;