import React from 'react';
import styles from "./RegisterSections.module.scss"
import RegisterSectionRow from './RegisterSectionRow';
import asideModalStore from "Stores/asideModalStore";
import {AddButton} from "../../../../ContentBox/Lessons/components/SectionEditMenu/AddButton/AddButton";

const RegisterSections = ({ebook_id, data, handlerDeleteSection, handlerChangeSection, handlerAddSection, translate}) => {

    const addRegisterChapters = () => {
        const ms = {
            type: 'generator',
            fields: [
                {
                    type: 'text',
                    name: 'name',
                    label: `${translate.register_section_name}:`,
                    placeholder: translate.register_section_name
                },
            ],
            valid: {
                name: 'required',
            },
            link: `ebooks/ebook_register_chapters`,
            title: translate.add_register_section,
            button: translate.add,
            onSubmitSuccess: handlerAddSection
        }
        const md = {
            name: '',
            ebook_id: ebook_id,
        }
        asideModalStore.activity(ms, md)
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                <div>{translate.register_sections}: {data.length}</div>
            </div>
            <div
                className={`${styles.empty_list} ${data.length > 0 && styles.list}`}>
                {
                    data.length > 0 ? data.map((item, id)=>
                        <RegisterSectionRow
                            key={id} data={item}
                            translate={translate}
                            handlerDeleteSection = {handlerDeleteSection}
                            handlerChangeSection = {handlerChangeSection}
                        />
                        ) : <div className={styles.noRegisters}>{translate.empty_registers_section}.</div>
                }
                <div className={styles.btn}>
                    <AddButton action={addRegisterChapters}>{translate.add_new_register}</AddButton>
                    {/*<button className={styles.addBtn} onClick={addRegisterChapters}> <PlusIcon/> <span>{translate.add_new_section}</span></button>*/}
                </div>
            </div>
        </div>
    )
}

export default RegisterSections


