import React, {useEffect, useState} from 'react';
import useFileUpload from "hooks/useFileUpload";
import st from './Essay.module.scss'
import {ReactComponent as IconFile} from "components/ContentBox/img/google-docs.svg";

export default function Essay(props) {
  const [text, setText] = useState(props.text || '')
  const [files, setFiles] = useState(props.files || [])

  const onUploadEnd = path => {
    setFiles([...files, {file: path, name: file.name}])
    if (!text) setText('Прикрепление файла')
  }

  const {refInput, file, setFile} = useFileUpload({url: 'files/study_test_doc_upload', onUploadEnd})

  useEffect(() => {
    //props.onChange({text: [text], files: files.map(f => ({file: f.path}))})
    props.onChange({text: text ? [text] : [], files})
  }, [text, files])

  const getFileName = basename => {
    const aPart = basename.split('.')
    const ext = aPart.pop()
    const name = aPart.join('')
    return [`${name.length > 25 ? `${name.slice(0, 25)}...` : name}`, ext]
  }

  return (
    <div className={st.essay}>
      <div className={st.text}>
        <div>
          <textarea value={text} onChange={e => setText(e.target.value)}/>
        </div>
        <div className={st.textLength}>
          <span>Всего символов: {text.length}</span>
        </div>
      </div>
      <div className={st.fileUploader}>
        <div className={st.chooseFile}>
          <input type="file" ref={refInput} style={{display: 'none'}} onChange={e => setFile(e.target.files[0])}/>
          <button className={st.button} onClick={() => {refInput.current.click()}}>Загрузить файл</button>
          <span>Поддерживаемые форматы: PDF, JPG, PNG, DOC, DOCX, TXT</span>
        </div>
        <ul className={st.files}>{files.map(f =>
          <li key={f.file} className={st.file}>
            <div className={st.fileIcon}>
              <IconFile/>
              <span>{getFileName(f.name)[1]}</span>
            </div>
            <span className={st.fileName}>{getFileName(f.name)[0]}</span>
            <div className={st.fileDelete} onClick={() => setFiles(files.filter(x => x.file !== f.file))}/>
          </li>)}
        </ul>
      </div>
    </div>
  );
};
