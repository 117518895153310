import React, {useEffect, useState} from 'react';
import swal from "sweetalert";
import {superAxiosRequestEasy} from "axiosApi";
import {classNames as cn} from "helpers";
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss";
import st from "./ResetPassword.module.scss";
import CompanyLogo from "../Common/CompanyLogo";
import AuthFooter from "../Common/AuthFooter";
import srcResetPassword1 from "../img/rstpsw-1.png";
import useLocalization from "../../../../hooks/useLocalization";

export default function ResetPassword({match: {params: {token}}}) {
  const [password, setPassword] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(true);
  const [isCheck, setIsCheck] = useState(false);
  const [jvtToken, setJvtToken] = useState(false);
  const [jvtTokenError, setJvtTokenError] = useState(false);

  const {translate} = useLocalization({section: 'user', subsection: 'reset_password'})

  const checkPassword = () => {
    if (password.length < 8) {
      setIsPasswordError(true)
      return false;
    } else {
      setIsPasswordError(false)
      return true;
    }
  }

  const checkPasswordTwo = () => passwordTwo.length >= 8

  const getTokens = (user) => {
    if (jvtToken) {
      const apiConf = {
        method: 'post',
        link: `accounts/rest_password`,
      }
      superAxiosRequestEasy(apiConf, user, 'JWT', token).then(res => {
        if (res.status === 200) {
          localStorage.clear()
          swal("", 'Ваш пароль успешно изменен', "success").then(() => {
            document.location.href = '/'
          })
        }
      }).catch(error1 =>
        swal("", error1, "warning")
      )
    }
  }

  const submitHandler = e => {
    e.preventDefault()
    setIsCheck(true)

    if (checkPassword() && checkPasswordTwo() && password === passwordTwo) {
      getTokens({jwt_token: token, new_password: passwordTwo})
    } else if (!checkPassword()) {
      swal("", "Пароль не соответствует требованиям", "warning")
    } else if (password !== passwordTwo) {
      swal("", "Ваши пароли не совпадают", "warning")
    }
  }

  useEffect(() => {
    superAxiosRequestEasy({
      method: 'post',
      link: `accounts/check_jwt_token`,
    }, {
      'jwt_token': token
    }).then(res => {
      res.data.detail === 'Token is live' && setJvtToken(true)
    }).catch(() => {
      swal("", translate.link_out_date || "", "warning")
      setJvtTokenError(true)
    })
  }, [token, translate])

  return (
    <>
      <article className={stWrapperAuth.content}>
        <div className={stWrapperAuth.authBox}>
          <CompanyLogo/>

          <h1 className={stWrapperAuth.heading1}>
            <nobr>{translate.reset_password} <span role="img" aria-label="Create Account">✍🏻</span></nobr>
          </h1>

          <form onSubmit={submitHandler} className={stWrapperAuth.form}>

            <div className={st.text}>
              <p>{translate.email_verification_done}.<br/>{translate.choose_another_password}</p>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.password} :</div>
              <input type="password"
                     value={password}
                     onChange={e => {
                       setPassword(e.target.value)
                     }}
                     className={cn({
                       [stWrapperAuth.input]: true,
                       [stWrapperAuth.inputError]: (isPasswordError && isCheck)
                     })}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.confirm_password} :</div>
              <input type="password"
                     value={passwordTwo}
                     onChange={e => {
                       setPasswordTwo(e.target.value)
                     }}
                     className={cn({
                       [stWrapperAuth.input]: true,
                       [stWrapperAuth.inputError]: (isPasswordError && isCheck)
                     })}
              />
            </div>

            <div className={stWrapperAuth.submit}>
              <div>
                <button className="button">{translate.reset_password}</button>
              </div>
              <div className={stWrapperAuth.haveAccount}>
                <p>{translate.go_back_to} <a href="/">{translate.login}</a></p>
              </div>
            </div>

          </form>

          {jvtTokenError &&
            <div className={st.restorePasswordError}>{translate.link_out_date}</div>}
        </div>
      </article>
      <AuthFooter srcPromo={srcResetPassword1}/>
    </>
  )
}
