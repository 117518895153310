import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import SectionTitle from '../../../../UI/SectionTitle/SectionTitle';
import InputToggle from '../../../../UI/InputToggle/InputToggle';
import OnlineSystemToggles from '../components/OnlineSystemToggles/OnlineSystemToggles';
import Paper from '../../../../UI/Paper/Paper';
import { adaptOnlineToggle } from '../adapters';
import slotsSettingsStore from '../../../../../Stores/slotsSettingsStore';
import styles from './MainSection.module.scss';
import { hideElem, showElem } from 'components/Modules/Slots/utils';

const onlineToggle = adaptOnlineToggle();

function MainSection() {
  const wrapperRef = useRef(null);
  const innerRef = useRef(null);
  const {isOnlineAllowed, toggleIsOnlineAllowed} = slotsSettingsStore;

  const handleToggleChange = () => {
    hideElem(wrapperRef, innerRef, isOnlineAllowed, toggleIsOnlineAllowed);
  };

  useEffect(() => {
    showElem(wrapperRef, innerRef, isOnlineAllowed)
  }, [isOnlineAllowed]);

  return (
    <Paper classes={styles.paper}>
      <div className={`${styles.title_wrapper} ${isOnlineAllowed ? styles.title_wrapper__border : ''}`}>
        <SectionTitle title='Основные настройки' />
        <InputToggle
          label={onlineToggle.label}
          name={onlineToggle.name}
          onChange={handleToggleChange}
          selected={isOnlineAllowed}
        />
      </div>
      <div ref={wrapperRef} className={styles.wrapper}>
        {isOnlineAllowed &&
          <div ref={innerRef} className={styles.inner}>
            <SectionTitle tag='h3' secondary title='Система онлайн конференций:' />
            <OnlineSystemToggles />
          </div>
        }
      </div>
    </Paper>
  );
}

export default observer(MainSection);
