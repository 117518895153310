import React from 'react';
import { observer } from 'mobx-react';
import slotsHistoryListingStore from 'Stores/slotsHistoryListingStore';
import ClientCard from './ClientCard';
import styles from './ClientCard.module.scss';
import { localisationMaster } from "Localisation/Localisation";


function ClientList() {
  const {clients} = slotsHistoryListingStore;

  if (clients.length === 0) {
    return <div className={styles.nothing}>
      {localisationMaster('text_48', 'study/study-test')}
    </div>
  }

  return (
    <ul className={styles.list}>
      {
        clients.map((data) => (
          <ClientCard
            key={data.id}
            data={data}
          />
        ))
      }
    </ul>
  );
}

export default observer(ClientList);

