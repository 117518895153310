import { observer } from "mobx-react";
import React, { useEffect } from "react";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Textarea from "components/ContentBox/Global/Textarea/Textarea";
import Variants from "../StudyTest/AddQuestionsTemplates/Variants";
import AddSimpleMedia from "../AddSimpleMedia";
import TagQuestion from "./components/TagQuestion";
import "./AddQuestionsPlacementTest.scss";

const AddQuestionsPlacementTest = () => {
  const { data, changeValues, getModalValues, check, changeValuesArray, listInKeyChangeHandler, deleteErrors } = modalStore;
  const qTypes = [
    { type: 'image_text_variants', supported: 'png, jpeg, jpg', m_type: 'image' },
    { type: 'audio_text_variants', supported: 'mp3, wav, ogg',  m_type: 'audio' },
    { type: 'video_text_variants', supported: 'avi, mov, mpeg', m_type: 'video' },
    { type: 'image_text_field',    supported: 'png, jpeg, jpg', m_type: 'image' },
    { type: 'audio_text_field',    supported: 'mp3, wav, ogg',  m_type: 'audio' },
    { type: 'video_text_field',    supported: 'avi, mov, mpeg', m_type: 'video' },
  ];

  const setVatiants = (arr, key) => {
    listInKeyChangeHandler(arr, key);
  };
  
  const addMedia = (file, name) => {
    changeValues(typeof file === 'string' ? file : file[0], name);
  };

  useEffect(() => {
    const type =  getModalValues?.question_type || "";
    const file = qTypes?.find((item) => type === item.type);
    deleteErrors();
    data.valid = { 
      ...data.valid, 
      ...(file && { [file.m_type]: 'required' }),
      question_data: 
        type.includes("question_tag_variants") ? "widget_tag_variants" :
        type.includes("variants") ? "widget_question_variants" :
        type.includes("make_sentence") ? "widget_make_sentence" :
        type.includes("text_field") ? 'widget_text_field' : ""
    };
  }, [getModalValues]);

  useEffect(() => {
    if(!data.is_put) return;
    const types = ['image', 'audio', 'video'];
    const newValues = {...getModalValues};
    types.forEach((key) => {
      if(newValues[key] === null) {
        delete newValues[key];
      }
    });
    changeValuesArray(newValues);
  }, []);

  const createBody = (type) => {
    switch (type) {
      case "question_text_variants":
        return (
          <Variants
            och={setVatiants}
            name="question_data"
            value_name="name"
            data={getModalValues.question_data || false}
            error={check("question_data")}
          />
        );
      case "question_text_field":
      case "make_sentence":
        return (
          <>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">Текст ответа:</p>
              <Textarea
                onChange={(data) => changeValues({ correct_answer: data }, 'question_data')}
                update={data.is_put}
                value={getModalValues?.question_data?.correct_answer}
                error={check("question_data")}
                stateName={"question_data"}
              />
            </div>
            {type === "make_sentence" && (
              <div className="super-modal-notice">
                <p>
                  Напишите предложения и разделите слова вертикальной чертой.
                  Слова автоматически перемешаются после сохранения.
                </p>
                <br />
                <p>
                  <strong>Пример:</strong>
                </p>
                <p>We|moved|to|California|last|summer</p>
              </div>
            )}
          </>
        );
      case "image_text_field":
      case "audio_text_field":
      case "video_text_field":
        return (
          <>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">Текст ответа:</p>
              <Textarea
                onChange={(data) => changeValues({ correct_answer: data }, "question_data")}
                update={data.is_put}
                value={getModalValues?.question_data?.correct_answer}
                error={check("question_data")}
                stateName={"question_data"}
              />
            </div>
            <AddSimpleMedia
              objectChangeHandler={(f) =>
                addMedia(f, qTypes.find((x) => x.type === type).m_type)
              }
              error={check(qTypes.find((x) => x.type === type).m_type)}
              values={getModalValues[qTypes.find((x) => x.type === type).m_type]}
              types={qTypes.find((x) => x.type === type).supported}
              update={data.is_put}
              fullLink={true}
            />
          </>
        );
      case "question_tag_variants":
        return (
          <TagQuestion
            onChange={(tags) => listInKeyChangeHandler(tags, "question_data")}
            value={getModalValues?.question_data || false}
            error={check("question_data")}
          />
        );
      case "image_text_variants":
      case "audio_text_variants":
      case "video_text_variants":
        return (
          <>
            <AddSimpleMedia
              objectChangeHandler={(f) =>
                addMedia(f, qTypes.find((x) => x.type === type).m_type)
              }
              values={getModalValues[qTypes.find((x) => x.type === type).m_type]}
              type={qTypes.find((x) => x.type === type).m_type}
              types={qTypes.find((x) => x.type === type).supported}
              update={data.is_put}
              fullLink={true} 
              error={check(qTypes.find((x) => x.type === type).m_type)}
            />
            <Variants
              och={setVatiants}
              name="question_data"
              value_name="name"
              data={getModalValues.question_data || false}
              error={check("question_data")}
            />
          </>
        );
      default:
        return (
          <div className="super-modal-probe">
            <p>Для начала выберите тип вопроса</p>
          </div>
        );
    }
  };

  return (
    <div className="addQuestionsPlacementTest">
      <div className="modalAddPayment">
        <div className="modalAddPayment__field">
          <p className="superModalGroup__label">Тип вопроса:</p>
          <SelectCustom
            placeholder="Типы и названия вопросов:"
            apiLink="placement_test/question_types"
            error={check("question_type")}
            optionValueKey="value"
            valueID={getModalValues?.question_type}
            onChange={(o) => changeValuesArray({  
              right_answer_scores: 1, 
              question_type: o.value,
              bundle: getModalValues.bundle 
            })}
          />
        </div>
        {!!getModalValues.question_type && (
          <>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__noReqLabel">
                Баллов за правильный ответ:
              </p>
              <input
                placeholder="Баллов за правильный ответ"
                type="number"
                value={getModalValues?.right_answer_scores || ""}
                onChange={(e) =>
                  changeValues(e.target.value, "right_answer_scores")
                }
                className={check("right_answer_scores") ? "super-modal__input--error" : undefined}
              />
            </div>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">Текст вопроса:</p>
              <Textarea
                onChange={changeValues}
                update={data.is_put}
                value={getModalValues?.question_text}
                stateName="question_text"
                error={check("question_text")}
              />
            </div>
          </>
        )}
        {createBody(getModalValues.question_type)}
      </div>
    </div>
  );
};
export default observer(AddQuestionsPlacementTest);