import React from 'react';
import PropTypes from "prop-types"
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import {localisationMaster} from "Localisation/Localisation";
import {Link, useHistory} from "react-router-dom";
import {permissions} from "settings/permissions";
import {msBook} from "../../settings";
import Stores from "Stores"
import asideModalStore from "Stores/asideModalStore";
import swal from "sweetalert";
import ActionMenu, { checkActionPermission } from 'components/UI/ActionMenu/ActionMenu';
import st from "./Book.module.scss"
import {ReactComponent as NotFoundBookImg} from 'components/ContentBox/img/not_found_book_img.svg';

function Book(props) {

  const profileMenu = [
    {
      name: props.translate.delete,
      action: 'delete_student_book',
      permission: permissions.delete_student_book
    }
  ]
  const history = useHistory();
  const menu = props.isProfile ? (checkPermList(permissions.delete_student_book) ? profileMenu : []) : props.listMenu;
  const isMenu = props.isProfile
    ? profileMenu.some(item => checkActionPermission(item.permission))
    : props.listMenu?.some(item => checkActionPermission(item.permission));

  const actions = (action) => {
    switch (action) {
      case "edit":
        const ms = {
          ...msBook,
          is_put: true,
          link: `${msBook.link}/${props.book.id}`,
          onSubmitSuccess: data => props.setBooks(props.books.map(b => b.id === data.id ? data : b))
        }
        const {name, company_id, course_level_id, skill_level_id, is_catalog, image} = props.book
        const md = {
          name,
          company: company_id,
          course_level: course_level_id,
          skill_level: skill_level_id,
          image,
          is_catalog,
        }
        asideModalStore.activity(ms, md)
        break
      case "delete":
        swal({
          title: localisationMaster("text_2", "alert"),
          text: `${localisationMaster("delete-1", "index", "sidebar_main")} "${props.book.name}"?`,
          icon: "warning",
          buttons: [
            localisationMaster("button_2", "alert"),
            localisationMaster("button_1", "alert"),
          ],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const apiConf = {
              method: "delete",
              link: `${msBook.link}/${props.book.id}`,
            };
            superAxiosRequest(apiConf).then(() => {
              props.fetchBooks()
              swal(
                `${localisationMaster("text_3", "alert")} ${props.book.name} ${localisationMaster("text_4", "alert")}`,
                {icon: "success",}
              );
            }).catch(() => {
              swal("Книга не может быть удалена")
            });
          } else {
            swal(localisationMaster("text_5", "alert"));
          }
        });
        break;
      case 'copy':
        swal({
          title: localisationMaster("text_2", "alert"),
          text: `${props.translate.copy_book} ${props.book.name}?`,
          icon: "warning",
          buttons: [
            localisationMaster("button_2", "alert"),
            localisationMaster("button_1", "alert"),
          ],
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            const apiConf = {
              method: 'post',
              link: 'ebooks/copy_ebook',
            }
            const payload = {
              ebook_id: props.book.id
            }
            superAxiosRequest(apiConf, payload).then(()=> {
              props.fetchBooks()
            })
          }
        })
        break

      case "delete_student_book":
        swal({
          title: localisationMaster("text_2", "alert"),
          text: `${localisationMaster("delete-1", "index", "sidebar_main")} "${props.book.name}"?`,
          icon: "warning",
          buttons: [
            localisationMaster("button_2", "alert"),
            localisationMaster("button_1", "alert"),
          ],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const apiConf = {
              method: "delete",
              link: `ebooks/students_ebooks/${props.rowID}`,
            };
            superAxiosRequest(apiConf).then(() => {
              props.fetchBooks()
              swal(
                `${localisationMaster("text_3", "alert")} ${props.book.name} ${localisationMaster("text_4", "alert")}`,
                {icon: "success",}
              );
            }).catch(() => {
              swal("Книга не может быть удалена")
            });
          } else {
            swal(localisationMaster("text_5", "alert"));
          }
        });
        break;
    default:
      break;
    }
  }


  const goToFilesTab = () => {
    const bookId = props.book.id;
    history.push(`/ebooks/${Stores.isClient ? 'my-' : ''}book/${bookId}?tab=2`);
  };
  
  return (
    <div className={st.book}>

      {isMenu && <ActionMenu className={st.menu} menu={menu} action={actions} />}
      <Link to={`/ebooks/${Stores.isClient ? 'my-' : ''}book/${props.book.id}`}>
        <div className={st.image}>
          <div className={props.book.image ? st.img : st.imgNotFound}>
            {props.book.image ?
              <img src={Stores.baseLink() + props.book.image} alt=""/> :
                <NotFoundBookImg/>
              }
          </div>
        </div>
        <div className={st.name}>
          {props.book.name}
        </div>
      </Link>
        <div className={st.footer}>
          <div className={st.params}>
            <div className={`${st.additionalMaterials}`} onClick={goToFilesTab}>
              {props.translate.additional_materials}
            </div>
            {/*<div>{props.book.company}</div>*/}
            {/*<div className={st.courseLvl}>*/}
            {/*  <div>*/}
            {/*    {props.book.course_level}*/}
            {/*  </div>*/}
            {/*  <div className={st.delimiter}>*/}
            {/*    |*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    {props.book.skill_level}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

    </div>
  );
}

Book.propTypes = {
  book: PropTypes.object.isRequired,
  fetchBooks: PropTypes.func.isRequired,

  isProfile: PropTypes.bool, // если просмотр книги из профиля
  rowID: PropTypes.number
}

export default Book
