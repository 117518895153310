import React, {useEffect, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {NavLink} from 'react-router-dom';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import THead from "components/ContentBox/Global/ReportTable/THead";
import {ExcelRowsParams} from '../../../utils/ExcelExport';


const AttendanceMap = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();
    const [catalog, setCatalog] = useState({})
    const [workshopsData, setWorkshopsData] = useState([])

    const filterCnf = [
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: catalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },
        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 2, name: "Все группы"},
                {id: 1, name: "Онлайн"},
                {id: 0, name: "Офлайн"},
            ]
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень курса',
            values: catalog?.course_levels,
            data: '',
            sort_key: 'name',
            nameState: 'course_level',
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: catalog?.skill_levels,
            sort_key: 'name',
            nameState: 'skill_level'
        },
    ]


    const returnLessonStatuses = (lesson) => {
        switch (lesson.status) {
            case 1:
                return {isLesson: true, minutes: lesson.minutes, color: '#fff', value: (lesson.minutes / 45).toFixed(2), backgroundColor: '#aaa'}
            case 2:
                return {isLesson: true, minutes: lesson.minutes, value: (lesson.minutes / 45).toFixed(2), backgroundColor: '#fff14f'}
            case 3:
                return {isLesson: true, minutes: lesson.minutes, color: '#fff', value: "П", backgroundColor: '#4aa9e9'}
            case 4:
                return {isLesson: true, minutes: lesson.minutes, color: '#fff', value: "П", backgroundColor: '#d780ab'}
            case 5:
                return {isLesson: false, minutes: lesson.minutes, color: '#fff', value: "П", backgroundColor: '#626262'}
            case 6:
                return {isLesson: true, minutes: lesson.minutes, color: '#fff', value: (lesson.minutes / 45).toFixed(2), backgroundColor: '#aaaaaa'}
            case 7:
                return {isLesson: true, minutes: lesson.minutes, color: '#fff', value: (lesson.minutes / 45).toFixed(2), backgroundColor: '#aaaaaa'}
            case 8:
                return {isLesson: true, minutes: lesson.minutes, value: (lesson.minutes / 45).toFixed(2), backgroundColor: '#eac459'}
            case 9:
                return {isLesson: false, minutes: lesson.minutes, color: '#fff', value: "П", backgroundColor: '#626262'}
        }
    }

    const dateHandler = (res) => {
        let tablesData = []
        res.data.result.map(item => {
            let groupData = {
                id: item.group_id,
                name: item.group_name,
                number: item.group_number,
                cols: [
                    {
                        name: `${item.group_name} ${item.group_number}`,
                        link: `/groups/group/${item.group_id}`,
                        sub: [
                            {
                                name: 'Ученик',
                                classes: [st.widthFitcontent]
                            },
                        ]
                    },
                    {
                        name: '',
                        sub: []
                    },
                    {
                        name: '',
                        sub: [
                            {
                                name: 'Занятий',
                                dataField: 'hours_paid',
                                align: 'center',
                                classes: [st.fz12],

                            },
                            {
                                name: 'Ак.часов',
                                dataField: 'hours_paid',
                                align: 'center',
                                classes: [st.fz12],

                            },
                        ]
                    },
                ],
                rows: [],
                total: {
                    hours: {
                        title: "Итого академ. часов",
                        data: []
                    },
                    students: {
                        title: "Итого студентов",
                        data: []
                    },
                },
                teachers: {
                    title: "Учитель",
                    data: []
                }

            }
            Object.keys(item.data_per_clients).map(key => {
                const clientData = res.data.client_names.find(client => client.id === Number(key))
                let allHours = 0
                item.data_per_clients[key].data.forEach(timeData => allHours = allHours + timeData.minutes)
                groupData.rows.push({
                    id: Number(key),
                    name: clientData.full_name,
                    user_id: clientData.user_id,
                    td: [],
                    ...item.data_per_clients[key],
                })
            })
            Object.keys(item.data_per_dates).map(key => {
                groupData.total.hours.data.push({value: 0})
                groupData.total.students.data.push({value: 0})
                groupData.teachers.data.push({value: [...item.data_per_dates[key].teachers_per_date]})
                groupData.cols[1].sub.push({name: timeConverter(new Date(key), 'day')})
                groupData.rows = groupData.rows.map(row => {
                    if (item.data_per_dates[key].clients_per_date.includes(row.id)) {
                        const thisLesson = row.data.find(rowDateData => rowDateData.date === key)
                        return {...row, td: [...row.td, {...returnLessonStatuses(thisLesson)}]}
                    }
                    return {...row, td: [...row.td, {isLesson: false, value: 'Н', color: '#fff', backgroundColor: "#626262"}]}
                })
            })
            groupData.rows = groupData.rows.map((row) => {
                let all_ac_hours = 0
                let actual_lessons = 0
                row.td.forEach((tdItem, tdId) => {
                    if (tdItem.isLesson) {
                        all_ac_hours = Number((all_ac_hours + (tdItem.minutes / 45)).toFixed(2))
                        actual_lessons = actual_lessons + 1
                        if (groupData.total.students.data[tdId]) groupData.total.students.data[tdId].value = groupData.total.students.data[tdId].value + 1
                        if (groupData.total.hours.data[tdId]) groupData.total.hours.data[tdId].value = groupData.total.hours.data[tdId].value + Number((tdItem.minutes / 45).toFixed(2))
                    }
                })
                return {...row, all_ac_hours: all_ac_hours, summary: {actual_lessons: actual_lessons}}
            })
            tablesData.push(groupData)
        })
        return tablesData
    }

    const sendRequest = async (param) => {
        try {
            setIsLoading(true);
            let apiConf = {
                method: 'get',
                link: 'progress_report/attendance_history',
                params: {
                    ...param
                }
            };
            if (apiConf.params.skill_level && apiConf.params.skill_level.length > 0) apiConf.params.skill_level = `[${apiConf.params.skill_level.map(item => item)}]`
            if (apiConf.params.course_level && apiConf.params.course_level.length > 0) apiConf.params.course_level = `[${apiConf.params.course_level.map(item => item)}]`
            if (apiConf.params.location && apiConf.params.location.length > 0) apiConf.params.location = `[${apiConf.params.location.map(item => item)}]`
            if (apiConf.params.date_from) apiConf = { ...apiConf, params: { ...apiConf.params, date_from: dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd") } }
            if (apiConf.params.date_until) apiConf = { ...apiConf, params: { ...apiConf.params, date_until: dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd") } }

            delete apiConf.params.is_active;

            const [historyRes, workshopsRes] = await Promise.all([
                superAxiosRequest(apiConf),
                superAxiosRequest({ ...apiConf, link: 'progress_report/attendance_history_ws' })
            ])

            const historyTablesData = dateHandler(historyRes)
            const workshopsTablesData = dateHandler(workshopsRes)

            setData(historyTablesData)
            setWorkshopsData(workshopsTablesData)
            setIsLoading(false)
        } catch (error) {
            console.error('An error occurred:', error)
            setIsLoading(false)
        }
    }
    const sendFirstRequest = () => {
        setIsLoading(true)

        superAxiosRequest({
            method: 'get',
            link: 'progress_report/attendance_history'
        })
            .then(({data}) => {
                setCatalog(data)
                setData([])
                setIsLoading(false)

            })
            .catch(() => setIsLoading(false));
    }
    useEffect(() => {
        sendFirstRequest()
    }, [])

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            let listsAray = []
            data.map(thisList => {
                let headRow = [
                    [
                        {
                            v: `${thisList.name} ${thisList.number}`,
                            ...ExcelRowsParams.headerRowParam
                        },
                        {
                            v: "",
                            ...ExcelRowsParams.headerRowParam
                        },
                        {
                            v: "",
                            ...ExcelRowsParams.headerRowParam
                        }
                    ],
                    [
                        {
                            v: "Ученик",
                            ...ExcelRowsParams.headerRowParam
                        },


                    ]
                ]
                let footerRows = [
                    [
                        {
                            v: `Итого студентов`,
                            ...ExcelRowsParams.footerRowParams.firstCell
                        },

                    ],
                    [
                        {
                            v: "Итого академ. часов",
                            ...ExcelRowsParams.footerRowParams.firstCell
                        },

                    ],
                    [
                        {
                            v: "Учитель",
                            ...ExcelRowsParams.footerRowParams.firstCell
                        },

                    ],
                ]
                let tableData = []

                // CREATE HEADER ROWS DATA
                thisList.cols[1].sub.map((item) => {
                    headRow[1].push({
                        v: `${item.name}`,
                        ...ExcelRowsParams.headerRowParam
                    })
                    headRow[0].push({
                        v: ``,
                        ...ExcelRowsParams.headerRowParam
                    })
                })
                headRow[1].push(
                    {
                        v: "Занятий",
                        ...ExcelRowsParams.headerRowParam
                    },
                    {
                        v: "Ак.часов",
                        ...ExcelRowsParams.headerRowParam
                    }
                )

                //CREATE FOOTER ROWS DATA
                thisList.total.hours.data.map(item => {
                    footerRows[0].push(
                        {
                            v: `${item.value}`,
                            ...ExcelRowsParams.footerRowParams.cell
                        },
                    )
                })
                thisList.total.students.data.map(item => {
                    footerRows[1].push(
                        {
                            v: `${item.value}`,
                            ...ExcelRowsParams.footerRowParams.cell
                        },
                    )
                })
                thisList.teachers.data.map(item => {
                    footerRows[2].push(
                        {
                            v: `${item.value[0] ? item.value[0].name : ''}`,
                            ...ExcelRowsParams.footerRowParams.cell
                        },
                    )
                })

                //CREATE TABLE ROWS DATA
                thisList.rows.map(item => {
                    let rowData = [{
                        v: `${item.name}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    }]


                    item.td.map(rowDay => {
                        rowData.push({
                            v: `${rowDay.value}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        })
                    })
                    rowData.push(
                        {
                            v: `${item.summary.actual_lessons}/${item.td.length}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${item.all_ac_hours}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    )

                    tableData.push(rowData)
                })
                let merges = []
                let wscols = [
                    {wch: 30},
                ];

                tableData[0].forEach(() => wscols.push({wch: 20}))
                const ws = XLSX.utils.aoa_to_sheet([...headRow, ...tableData, ...footerRows]);
                ws['!cols'] = wscols;
                ws["!merges"] = merges
                listsAray.push({name: `${thisList.number}`, ws: ws})
            })


            let wb = XLSX.utils.book_new();
            listsAray.forEach((list, id) => {
                XLSX.utils.book_append_sheet(wb, list.ws, `${list.name} ${id}`)
            })


            XLSX.writeFile(wb, "Карта посещаемости студентов.xlsx");
        })
    }

    return (
        <div>
            <ContentHeader title="Карта посещаемости студентов" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => sendFirstRequest()} thisPage={null} />

            {isLoading
                ? <Loader />
                : Array.isArray(data) ?

                    <>
                        <button onClick={() => {
                            downloadExcel(data)
                            downloadExcel(workshopsData)
                        }} style={{
                            margin: '0 10px 10px',
                            width: 'calc(100% - 20px)'
                        }}>Выгрузить в Excel</button>
                        {
                            data.map((group, keyId) =>
                                <div style={{marginBottom: "20px"}} className={st.tableWrapper} key={keyId} ref={refWrapper}>
                                    <div ref={refSticky} className={st.sticky}>
                                        <table className={st.table}>
                                            <THead cols={group.cols} />
                                        </table>
                                    </div>
                                    <table className={`${st.table} ${st.tbodyStriped}`}>
                                        <THead cols={group.cols} ref={refThead} />
                                        <tbody className={st.tbody}>
                                            {Array.isArray(group.rows) && <>
                                                {group.rows.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={st.fw600} style={{
                                                            background: "white",
                                                            width: '300px',
                                                            maxWidth: '300px',
                                                            minWidth: '300px'
                                                        }}>
                                                            <NavLink to={'/account_clients/' + row.user_id} target="_blank">
                                                                {row.name}
                                                            </NavLink>
                                                        </td>
                                                        {row.td.map((td, tdId) =>
                                                            <td style={{
                                                                width: '150px',
                                                                minWidth: '150px',
                                                                backgroundColor: td?.backgroundColor,
                                                                color: td?.color
                                                            }} key={tdId} className={`${st.tdMiddle} ${st.textAlignCenter}  `}>
                                                                <div className={`${st.groupDescription} ${st.bottom} `}>
                                                                    {td.value}
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td style={{width: '150px', maxWidth: '150px', minWidth: '150px'}} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {row.summary.actual_lessons}/{row.td.length}
                                                            </div>
                                                        </td>
                                                        <td style={{width: '150px', maxWidth: '150px', minWidth: '150px'}} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {row.all_ac_hours}
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )}
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.total.hours.title}
                                                    </td>
                                                    {group.total.hours.data.map((hour, hourId) =>
                                                        <td key={hourId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {hour.value}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.total.students.title}
                                                    </td>
                                                    {group.total.students.data.map((student, studentId) =>
                                                        <td key={studentId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {student.value}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.teachers.title}
                                                    </td>

                                                    {group.teachers.data.map((dayTeachers, elId) =>
                                                        <td key={elId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            {dayTeachers.value.map((teacher, idT) =>
                                                                <div key={idT} className={`${st.groupDescription} ${st.bottom}`}>
                                                                    <NavLink to={'/account/' + teacher.user_id} target="_blank">
                                                                        {teacher.name}
                                                                    </NavLink>
                                                                </div>)}

                                                        </td>)}
                                                </tr>

                                            </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )

                        }
                        {
                            workshopsData.map((group, keyId) =>
                                <div style={{marginBottom: "20px"}} className={st.tableWrapper} key={keyId} ref={refWrapper}>
                                    <div ref={refSticky} className={st.sticky}>
                                        <table className={st.table}>
                                            <THead cols={group.cols} />
                                        </table>
                                    </div>
                                    <table className={`${st.table} ${st.tbodyStriped}`}>
                                        <THead cols={group.cols} ref={refThead} />
                                        <tbody className={st.tbody}>
                                            {Array.isArray(group.rows) && <>
                                                {group.rows.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={st.fw600} style={{
                                                            background: "white",
                                                            width: '300px',
                                                            maxWidth: '300px',
                                                            minWidth: '300px'
                                                        }}>
                                                            <NavLink to={'/account_clients/' + row.user_id} target="_blank">
                                                                {row.name}
                                                            </NavLink>
                                                        </td>
                                                        {row.td.map((td, tdId) =>
                                                            <td style={{
                                                                width: '150px',
                                                                minWidth: '150px',
                                                                backgroundColor: td?.backgroundColor,
                                                                color: td?.color
                                                            }} key={tdId} className={`${st.tdMiddle} ${st.textAlignCenter}  `}>
                                                                <div className={`${st.groupDescription} ${st.bottom} `}>
                                                                    {td.value}
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td style={{width: '150px', maxWidth: '150px', minWidth: '150px'}} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {row.summary.actual_lessons}/{row.td.length}
                                                            </div>
                                                        </td>
                                                        <td style={{width: '150px', maxWidth: '150px', minWidth: '150px'}} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {row.all_ac_hours}
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )}
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.total.hours.title}
                                                    </td>
                                                    {group.total.hours.data.map((hour, hourId) =>
                                                        <td key={hourId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {hour.value}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.total.students.title}
                                                    </td>
                                                    {group.total.students.data.map((student, studentId) =>
                                                        <td key={studentId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                {student.value}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                                <tr className={st.bdt}>
                                                    <td className={st.fw600} style={{background: "white"}}>
                                                        {group.teachers.title}
                                                    </td>

                                                    {group.teachers.data.map((dayTeachers, elId) =>
                                                        <td key={elId} className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            {dayTeachers.value.map((teacher, idT) =>
                                                                <div key={idT} className={`${st.groupDescription} ${st.bottom}`}>
                                                                    <NavLink to={'/account/' + teacher.user_id} target="_blank">
                                                                        {teacher.name}
                                                                    </NavLink>
                                                                </div>)}

                                                        </td>)}
                                                </tr>

                                            </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )

                        }
                    </>
                    : (<h1 className={st.noData}>Нет данных для отображения!</h1>
                    )
            }
        </div>
    )
}

export default AttendanceMap;