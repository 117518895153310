import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import Flex from 'components/UI/Flex/Flex';
import styles from './SlotSettings.module.scss';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';
import { findOption } from 'components/Modules/Slots/utils';

const Postfix = {
  MINUTE: 1,
  HOUR: 60,
  DAY: 1440,
};
const gapOptions = [
  {value: Postfix.MINUTE, label: 'мин.'},
  {value: Postfix.HOUR, label: 'часов'},
  {value: Postfix.DAY, label: 'дней'},
];

function TimeGap() {
  const { timeGap, setTime, editingData } = slotsScheduleSettingsStore;

  const handleTimeGapChange = (evt) => {
    const value = evt.target.value.replace(/\D/g, '');
    setTime({ value, postfix: timeGap.postfix }, 'timeGap');
  };
  const handlePostFixChange = (option) => {
    const postfix = option.value;
    setTime({value: timeGap.value, postfix }, 'timeGap');
  };

  useEffect(() => {
    let value = timeGap.value;
    let postfix = timeGap.postfix;
    if (value < Postfix.HOUR || value % Postfix.HOUR !== 0) {
      return;
    }

    const isHour = value >= Postfix.HOUR && value < Postfix.DAY;
    const isDay = value >= Postfix.DAY;
    const isNotWholeDay = timeGap.value % Postfix.DAY !== 0;

    if (isHour) {
      value = value / Postfix.HOUR;
      postfix = Postfix.HOUR;
    }
    if (isDay) {
      value = value / Postfix.DAY;
      postfix = Postfix.DAY;
      if (isNotWholeDay) {
        value = timeGap.value / Postfix.HOUR;
        postfix = Postfix.HOUR;
      }
    }
    setTime({value, postfix }, 'timeGap');
  }, [editingData]);

  return (
    <Flex justify='start' classes={styles.time_gap}>
      <SectionTitle title='Клиент может записаться не позднее' secondary tag='h3' />
      <Input
        pattern='[0-9]*'
        inputMode='numeric'
        withLabel={false}
        label='Время записи, не позднее чем'
        value={timeGap.value.toString()}
        onChange={handleTimeGapChange}
        placeholder='время'
        classes={styles.time_gap_input}
      />
      <Select
        placeholder='Без перерыва'
        options={gapOptions}
        value={findOption(timeGap.postfix, gapOptions)}
        onChange={handlePostFixChange}
        classes={styles.time_gap_select}
      />
      <span className={styles.time_gap_text}>до старта слота</span>
    </Flex>
  );
}

export default observer(TimeGap);

