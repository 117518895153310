import React, {useEffect, useState} from 'react';
import './TimeTableDatePicker.scss';
import DatePicker from "react-datepicker";
import CelenderIcon from 'components/ContentBox/img/calendar.svg';




function TimeTableDatePicker({date, setDate}) {
    return (
        <div className="timeTableDatePicker react-datepicker-custom">
            <label >
                <img src={CelenderIcon} className="timeTableDatePicker__icon" alt="icon" />
                <DatePicker className="timeTableDatePicker__input"
                    calendarClassName="timeTableDatePicker__box"
                    selected={date}
                    locale="ru"
                    onChange={(value) => setDate(new Date(value))} />
            </label>

        </div>
    );
}



export default TimeTableDatePicker;
