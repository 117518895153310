import {action, computed, makeObservable, observable} from 'mobx';
import {superAxiosRequest} from 'axiosApi';
import LocalStorage from 'services/LocalStorage';
import {calcPayDate, calcTimeDiff, getAdaptedTime, validateEmail} from 'components/Modules/Slots/utils';

export const ActionType = {
  getSelectorsData: 'getSelectorsData',
  getCalendar: 'getCalendar',
  getLocation: 'getLocation',
  getSlots: 'getSlots',
  getRegistrationData: 'getRegistrationData',
  resetCalendar: 'resetCalendar',
  resetSlots: 'resetSlots',
  resetClientData: 'resetClientData',
  resetGuardianData: 'resetGuardianData',
  resetAll: 'resetAll',
};

export const DataType = {
  locations: 'locations',
  selectedCompany: 'selectedCompany',
  selectedLocation: 'selectedLocation',
  selectedProgram: 'selectedProgram',
  selectedAge: 'selectedAge',
  isCalendarLoading: 'isCalendarLoading',
  dates: 'dates',
  selectedTeacher: 'selectedTeacher',
  isSms: 'isSms',
  isComment: 'isComment',
  comment: 'comment',
  isPaymentOrder: 'isPaymentOrder',
  isGuardian: 'isGuardian',
  isClientOverwrite: 'isClientOverwrite',
};

export const getOptions = (data, type) => data
  .map((item) => {
    return item.company
      ? {value: item.company.id, label: item.company.name, locations: item.locations, selectType: type}
      : {...item, value: item.id, label: item.name, selectType: type};
  })
  .sort((a, b) => a.label > b.label ? 1 : -1);


const initialGuardianData = {
  first_name: {mandatory: true},
  last_name: {mandatory: true},
  phone: {mandatory: true},
  email: {mandatory: true},
  birthday: {mandatory: true},
  sex: {mandatory: true},
  inn: {mandatory: true},
};

const getSelectedOption = (options, id, selectType) => {
  const item = options.find((option) => (option.id || option.company.id) === id);
  return item.company
    ? {...item.company, locations: item.locations, value: item.company.id, label: item.company.name, selectType}
    : {...item, value: item.id, label: item.name, selectType}
};

const fillClientData = (data, values) => {
  const filled = {};
  for (let key in data) {
    filled[key] = {...data[key]};
    filled[key].value = values.client[key];
  }
  filled.add_field1.value = values.slot.add_field1;
  filled.add_field2.value = values.slot.add_field2;
  filled.add_field3.value = values.slot.add_field3;
  return filled;
};

class SlotsRecordCustomerStore {
  companies = [];
  locations = [];
  ages = [];
  programs = [];
  selectedCompany = null;
  selectedLocation = null;
  selectedProgram = null;
  selectedAge = null;
  isCalendarLoading = true;
  dates = [];
  selectedDates = new Set();
  slots = [];
  selectedSlots = new Set();
  teachers = [];
  clientData = null;
  overwritingClientData = null;
  guardianData = initialGuardianData;
  slotID = null;
  selectedTeacher = null;
  isSms = false;
  isComment = false;
  comment = '';
  isPaymentOrder = true;
  isGuardian = false;
  payDate = null;
  timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  isClientOverwrite = false;
  toggles = null;

  constructor() {
    makeObservable(this, {
      companies: observable,
      locations: observable,
      ages: observable,
      programs: observable,
      selectedCompany: observable,
      selectedLocation: observable,
      selectedProgram: observable,
      selectedAge: observable,
      isCalendarLoading: observable,
      dates: observable,
      selectedDates: observable,
      slots: observable,
      selectedSlots: observable,
      teachers: observable,
      clientData: observable,
      overwritingClientData: observable,
      guardianData: observable,
      isGuardian: observable,
      slotID: observable,
      selectedTeacher: observable,
      isSms: observable,
      isComment: observable,
      comment: observable,
      isPaymentOrder: observable,
      payDate: observable,
      isClientOverwrite: observable,
      toggles: observable,

      setData: action.bound,
      setClientData: action.bound,
      resetData: action.bound,
      getDataFromServer: action.bound,
      fetchDataWhenOverwritingClient: action.bound,
      sendDataToServer: action.bound,
      getSelectedSlotTime: action.bound,
      setToggles: action.bound,

      isCalendarReadyToShow: computed,
      sum: computed,
      isSubmitDisabled: computed,
      isClientDataValid: computed,
      isGuardianDataValid: computed,
    });
  }

  setData(data, type) {
    this[type] = data;
  }

  setToggles(toggles) {
    this.toggles = toggles;
  }

  setClientData(value, type, isGuardian) {
    isGuardian
      ? this.guardianData[type] = { ...this.guardianData[type], value }
      : this.clientData[type] = { ...this.clientData[type], value };
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetCalendar:
        this.selectedDates.clear();
        this.teachers = [];
        this.slots = [];
        this.selectedSlots.clear();
        this.selectedTeacher = null;
        this.comment = '';
        this.isComment = false;
        this.isPaymentOrder = true;
        break;
      case ActionType.resetSlots:
        this.selectedSlots.clear();
        this.teachers = [];
        this.clientData = null;
        this.guardianData = initialGuardianData;
        this.selectedTeacher = null;
        this.comment = '';
        break;
      case ActionType.resetClientData:
        this.selectedTeacher = null;
        break;
      case ActionType.resetGuardianData:
        this.guardianData = initialGuardianData;
        break;
      case ActionType.resetAll:
      default:
        this.guardianData = initialGuardianData;
        this.clientData = null;
        this.selectedTeacher = null;
        this.selectedSlots.clear();
        this.teachers = [];
        this.slots = [];
        this.selectedDates.clear();
        this.dates = [];
        this.selectedLocation = null;
        this.selectedProgram = null;
        this.selectedAge = null;
        this.comment = '';
        this.isComment = false;
        this.isPaymentOrder = true;
        this.toggles = null;
        break;
    }
  }

  get isCalendarReadyToShow() {
    return this.selectedCompany && this.selectedLocation && this.selectedProgram && this.selectedAge;
  }

  get sum() {
    const { price, price_type } = this.teachers.find((item) => item.id === this.selectedTeacher);
    const { start_at, finish_at } = this.slots.find(({id}) => id === this.slotID);
    const timeDiff = calcTimeDiff(start_at, finish_at);
    const multiplier = price_type === '15 минут' ? Math.ceil(timeDiff / 15) : 1;
    return price * multiplier / 100;
  }

  get isClientDataValid() {
    if (!validateEmail(this.clientData?.email?.value)) {
      return false;
    }

    for (let key in this.clientData) {
      if (this.clientData[key].mandatory && !this.clientData[key]?.value) {
        return false;
      }
    }
    return true;
  }

  get isGuardianDataValid() {
    if (!this.isGuardian) {
      return true;
    }

    if (!validateEmail(this.guardianData?.email?.value)) {
      return false;
    }
    for (let key in this.guardianData) {
      if (this.guardianData[key].mandatory && !this.guardianData[key]?.value) {
        return false;
      }
    }
    return true;
  }

  get isSubmitDisabled() {
    return !this.isGuardianDataValid ||
      !this.isClientDataValid ||
      (this.isComment && this.comment.length < 30);
  }

  getSelectedSlotTime() {
    const time = this.slots.find(({id}) => id === [...this.selectedSlots.values()][0]).start_at;
    return getAdaptedTime(time, this.timeZone);
  }

  fetchDataWhenOverwritingClient(id, onFail, onFinally) {
    superAxiosRequest({
      method: 'get',
      link: `online_schedule/admin/client/${id}`,
    })
      .then(({data}) => {
        this.companies = data.companies.map(({ company, locations }) => ({value: company.id, label: company.name, locations, selectType: DataType.selectedCompany}));
        this.ages = data.ages;
        this.programs = data.programs;
        this.selectedCompany = getSelectedOption(data.companies, data.slot_client.company_id, DataType.selectedCompany);
        this.locations = this.selectedCompany.locations;
        this.selectedProgram = getSelectedOption(data.programs, data.slot_client.program_id, DataType.selectedProgram);
        this.selectedAge = getSelectedOption(data.ages, data.slot_client.age_id, DataType.selectedAge);
        this.selectedLocation = getSelectedOption(this.locations, data.slot_client.location_id, DataType.selectedLocation);
        this.overwritingClientData = { client: data.client, slot: data.slot_client, employee: data.employee_slot };
      })
      .catch(() => onFail())
      .finally(() => onFinally());
  }

  getDataFromServer({type, params, onSuccess = () => {}, onFinally = () => {}}) {
    let link = 'online_schedule/admin/client';
    params = {
      ...params,
      company: this.selectedCompany?.value,
      location: this.selectedLocation?.value,
      age: this.selectedAge?.value,
      program: this.selectedProgram?.value,
    };
    switch (type) {
      case ActionType.getSelectorsData:
        params = '';
        break;
      case ActionType.getLocation:
        link = `db/locations/${params.id}`;
        params = '';
        break;
      case ActionType.getCalendar:
        this.isCalendarLoading = true;
        this.resetData(ActionType.resetCalendar);
        params = {
          ...params,
          step: 1,
        };
        break;
      case ActionType.getSlots:
        params = {
          ...params,
          step: 2,
          date_from: this.dates[[...this.selectedDates.values()][0]].date_at,
        };
        break;
      case ActionType.getRegistrationData:
        params = {
          ...params,
          step: 3,
          date_from: this.dates[[...this.selectedDates.values()][0]].date_at,
        };
        break;
      default: break;
    }
    superAxiosRequest({
      method: 'get',
      link,
      params,
    })
      .then(({data}) => {
        const {slot_data, pay_time_booking} = data;
        switch (type) {
          case ActionType.getSelectorsData:
            this.companies = getOptions(data[0].companies, DataType.selectedCompany);
            this.ages = data[1].ages;
            this.programs = data[2].programs;
            if (this.companies.length === 1) {
              this.selectedCompany = this.companies[0];
              this.locations = getOptions(this.selectedCompany.locations, DataType.selectedLocation)
            }
            break;
          case ActionType.getCalendar:
            this.dates = data;
            break;
          case ActionType.getSlots:
            this.toggles = {'is_online': slot_data[0]?.is_online, 'is_school': !slot_data[0]?.is_online};
            this.slots = slot_data.sort((a, b) => {
              const aMinutes = +a.start_at.split(':')[0] * 60 + +a.start_at.split(':')[1];
              const bMinutes = +b.start_at.split(':')[0] * 60 + +b.start_at.split(':')[1];
              return aMinutes - bMinutes;
            });
            break;
          case ActionType.getRegistrationData:
            const savedCustomerData = JSON.parse(localStorage.getItem('save_data_record_customer') || '{}');
            Object.keys(savedCustomerData).forEach(key => {
              data.client_data[key].value = key === 'phone' ? `+${savedCustomerData[key]}` : savedCustomerData[key];
            });
            localStorage.removeItem('save_data_record_customer') // удаляем данные, чтобы при обновлении страницы были пустые
            this.teachers = data.employees;
            this.clientData = this.isClientOverwrite
              ? fillClientData(data.client_data, this.overwritingClientData)
              : data.client_data;
            this.slotID = data.id;
            this.payDate = calcPayDate(pay_time_booking, this.timeZone);
            break;
          default:
            break;
        }
        onSuccess(data);
      })
      .finally(() => onFinally());
  }

  adaptDataToServer() {
    const data = {
      date_at: this.dates[[...this.selectedDates.values()][0]].date_at,
      date_from: this.dates[0].date_at,
      step: 1,
      slot: this.slotID,
      is_online: this.toggles.is_online,
      employee_slot: this.teachers.find(({ id }) => id === this.selectedTeacher).employee_slot_id,
      program: this.selectedProgram.value,
      age: this.selectedAge.value,
      location: this.selectedLocation.value,
      company: this.selectedCompany.value,
      sms: this.isSms,
      is_pay_comment: this.isComment,
      payment_order: this.isPaymentOrder,
      order_description: 'Устное тестирование',
      client_data: {},
    };
    for (let key in this.clientData) {
      if (this.clientData[key]?.value) {
        data.client_data[key] = this.clientData[key]?.value;
      }
    }

    if (this.clientData.birthday?.value) {
      data.client_data.date_of_birth = this.clientData.birthday.value;
    }

    if (this.clientData.level?.value) {
      data.client_data.start_level = this.clientData.level.value;
      delete data.client_data.level
    }

    if (this.isComment) {
      data.payment_comment = this.comment;
    }
    if (this.isPaymentOrder) {
      data.payment_date = this.payDate.dateUTC;
      data.payment_time = this.payDate.timeUTC;
      data.payment_sum = this.sum * 100;
    }
    if (this.isGuardian) {
      data.parent_data = {
        first_name: this.guardianData.first_name?.value,
        last_name: this.guardianData.last_name?.value,
        father_name: this.guardianData.father_name?.value,
        phone: this.guardianData.phone?.value,
        email: this.guardianData.email?.value,
        inn: this.guardianData.inn?.value,
        date_of_birth: this.guardianData.birthday?.value,
        sex: this.guardianData.sex?.value,
      };
    }
    return data;
  }

  sendDataToServer({onSuccess = () => {}, onFail = () => {}, onFinally = () => {}}) {
    const link = this.isClientOverwrite
      ? `online_schedule/admin/client/${this.overwritingClientData.slot.id}`
      : 'online_schedule/admin/client';
    const data = this.adaptDataToServer();
    superAxiosRequest({
      method: this.isClientOverwrite ? 'put' : 'post',
      link,
    }, data)
      .then(({data}) => {
        this.dates = data.dates;
        onSuccess(data.slot_client);
      })
      .catch(({ response }) => {
        const error = response?.data?.error;
        typeof error === 'string' ? onFail(error) : onFail('');
      })
      .finally(() => onFinally());
  }
}

export default new SlotsRecordCustomerStore();
