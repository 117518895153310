import React from 'react';
import styles from "./GradeModal.module.scss"
import {ReactComponent as VoidDottIcon} from "components/ContentBox/img/void-rate.svg";
import {ReactComponent as RateIcon} from "components/ContentBox/img/rate-dott.svg";
import {ReactComponent as InRateIcon} from "components/ContentBox/img/Incorrect-rate.svg";

const GradeModal = ({setClose, clients}) => {

  const getIcon = (key) => {
    let cond = Array.isArray(key) ? !key.includes(false) : key
    let icon
    switch (cond) {
      case false:
        icon = <InRateIcon/>
        break;
      case true:
        icon = <RateIcon/>
        break;
      case null:
        icon = <VoidDottIcon/>
        break;
      default:
        break;
    }

    return icon
  }

  return (
    <div className={styles.box}>{clients.map(user =>
      <div key={user.id} className={styles.userItem}>
        <div onClick={() => setClose(user)} className={styles.name}>{`${user.last_name} ${user.first_name[0]}.`}</div>
        <div className={styles.rateList}>{user.answers.map((rateItem, rI) =>
          <div key={rI} className={styles.rateItem}>{getIcon(rateItem.result)}</div>)}
        </div>
      </div>)}
    </div>
  )
}

export default GradeModal
