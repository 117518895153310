import Axios from 'axios';
import LocalStorage from './services/LocalStorage';
import Stores from './Stores';

const axiosInstance = Axios.create({
    timeout: 160000,
    headers: {
        'Authorization': "JWT " + localStorage.getItem('access'),
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

const createRequest = async (config, token, body, type, axiosConfig) => {
    let contentType = 'application/json'
    if (type && type === 'media') {
        contentType = 'multipart/form-data'
    }
    const axiosInstanceMain = Axios.create({
        timeout: axiosConfig?.timeout || 50000,
        headers: {
            'Authorization': "JWT " + token,
            'Content-Type': contentType,
            'accept': 'application/json',
            ...config.headers
        },
        ...(config.responseType && {responseType: config.responseType})
    });
    const axiosInstanceEasy = Axios.create({
        timeout: 50000,
        headers: {
            'Content-Type': contentType,
            'accept': 'application/json'
        }
    });

    let axioss = axiosInstanceMain;
    if (token === 0) {
        axioss = axiosInstanceEasy
    }
    let link;
    let comeOverHere;
    let params = config['params'];
    let compiledParams = "?"
    link = Stores.apiUrl + config['link'] + '/';
    for (var key in params) {
        compiledParams += key + '=' + params[key] + "&"
    }
    compiledParams = compiledParams.slice(0, -1)
    switch (config['method']) {
        case 'get':
            comeOverHere = axioss.get(link + compiledParams);
            break;
        case 'post':
            comeOverHere = axioss.post(link + compiledParams, body, axiosConfig);
            break;
        case 'put':
            comeOverHere = axioss.put(link + compiledParams, body);
            break;
        case 'delete':
            comeOverHere = axioss.delete(link + compiledParams, body);
            break;
        default:
            break;
    }

    return (await comeOverHere)
}

const dataStorage = JSON.parse(localStorage.getItem('ud'));

const interceprot401 = async (config, token, body, type, axiosConfig) => {
    try {
        var result = createRequest(config, token, body, type, axiosConfig)
        return (await result)
    } catch (error) {
        if (error.response !== undefined) {
            if (error.response.status === 400 || error.response.status === 500 || error.response.status === 502) {
                return (await result)
            } else if (error.response.status === 401) {
                try {
                    let result = await refreshTokenS(dataStorage.refresh)
                    localStorage.setItem('access', result.data['access']);
                    localStorage.setItem('life_time', result.data['access_token_death']);
                    let ready = await createRequest(config, result.data['access'], body, type)
                    return (ready)
                } catch (error) {
                    logoutUser();
                }
            }
        } else {
            console.log('You have some trouble', error)
        }
        throw error;
    }
}

export const superAxiosRequestEasy = async (config, body, type, jwt) => {
    let jwt_ = 0;
    if (type === 'jwt') {
        jwt_ = jwt
    }
    return createRequest(config, jwt_, body, type);
}

export const superAxiosRequest = async (config, body, type, axiosConfig) => {
    if (localStorage.getItem('ud')) {
        if (dataStorage.refresh &&
            //dataStorage.access &&
            dataStorage.refresh_token_death &&
            dataStorage.access_token_death &&
            dataStorage.user_data.groups[0]['name']) {
            let now = Math.round(new Date(Date.now()).getTime() / 1000);
            if (now < dataStorage.refresh_token_death) {
                let token = localStorage.getItem('access')
                if (now > +localStorage.getItem('life_time')) {
                    try {
                        let refreshh = await refreshTokenS(dataStorage.refresh);
                        if (refreshh.status === 200) {
                            localStorage.setItem('access', refreshh.data['access']);
                            localStorage.setItem('life_time', refreshh.data['access_token_death']);
                            return createRequest(config, refreshh.data['access'], body, type, axiosConfig);
                        } else {
                            return logoutUser();
                        }

                    } catch {
                        return logoutUser();
                    }
                } else {
                    return await (interceprot401(config, token, body, type, axiosConfig));
                    //return await (createRequest(config, token, body, type));
                }


            } else {
                logoutUser();
            }

        } else {
            logoutUser();
        }

    } else {
        logoutUser();
    }
}

export const checkUser = () => {
    if (LocalStorage.getSimple('isAuth')) {
        return LocalStorage.getSimple('refresh') !== null;
    }
}

export const refreshTokenS = async (token) => {
    const body = { "refresh": token };
    let resp = axiosInstance.post(Stores.apiUrl + 'token/refresh/', body)
    return (await resp);
}

export const logoutUser = () => {
    localStorage.removeItem('isAuth');
    localStorage.removeItem('life_time');
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('ud');
    document.location.href = '/';
}

export default axiosInstance;
