import React, {useState} from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import groupStore from "Stores/groupStore";
import asideModalStore from "Stores/asideModalStore";
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import ModalHomeWork from "components/ContentBox/Global/GlobalModalSingle/templates/groups/ModalHomeWork";
import HeaderContentBox from "../HeaderContentBox";
import HomeTask from "./HomeTask/HomeTask";
import style from "./HomeTasks.module.scss";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import useLocalization from "hooks/useLocalization";

const HomeTasks = props => {

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_homework',
    dict: {
      "homework_lesson_plan": "Homework / Lesson Plan",
      "save": "Save",
      "edit": "Edit",
      "delete": "Delete",
      "homework": "Homework",
      "yr": "y."
    }
  })

  const ms = {
    isResponsive: true,
    title: translate.homework_lesson_plan,
    button: translate.save,
    modalBodyComponent: () => <ModalHomeWork/>,
    valid: {
      description: 'required',
    },
    link: 'groups/interim_homework',
    onSubmitSuccess: data => {
      groupStore.updateGroupData({homeworks: data.homeworks})
    }
  }

  const md = {
    date: dateFormat(new Date(), "YYYY-mm-dd"),
    group: props.gid,
  }

  const menu = [
    {name: translate.edit, action: 'edit'},
    {name: translate.delete, action: 'delete'},
  ]

  const [open, setOpen] = useState(false)

  const actions = (data, e, item) => {
    switch (data) {
      case 'edit':
        asideModalStore.activity({
          ...ms,
          is_put: true,
          link: ms.link + '/' + item.id
        }, {
          ...item,
          teacher: item.teacher.id,
          group: props.gid,
        })
        break;
      case 'delete':
        const cfg = {
          link: ms.link + '/' + item.id,
          method: 'delete'
        }
        superAxiosRequest(cfg).then(res => groupStore.updateGroupData({homeworks: res.data.homeworks}))
        break;
      default:
        break;
    }
  }

  return (
    <HeaderContentBox className={style.homeTasks}>
      <OpenHeader title={translate.homework}
                  onClick={() => setOpen(!open)}
                  link={`/groups/homeworks/${props.gid}`}
                  target="_blank"
                  addButton={!Stores.isClient ? () => asideModalStore.activity(ms, md) : null}/>
      {open &&
        <>{props.homeworks.length > 0 ?
          <div className={style.list}>{props.homeworks.slice(0, 5).map((item, index) =>
            <HomeTask key={index} item={item} actions={actions} menu={menu} translate={translate}/>)}
            
          </div>
          :
          <div className={style.empty}>
            <EmptyData/>
          </div>}
        </>}
    </HeaderContentBox>
  )
}

HomeTasks.propTypes = {
  homeworks: PropTypes.arrayOf(PropTypes.object),
  gid: PropTypes.number,
}

export default observer(HomeTasks)
