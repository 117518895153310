import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {translate as loc, dict} from "Localisation";
import {localisationMaster} from "Localisation/Localisation";
import {scheduleDays} from "helpers/date"
import Stores from 'Stores';
import './GroupCard.scss';


const getDateRange = (date_from, date_until) => {
    const [, m, d] = date_from.split('-')
    const [, mm, dd] = date_until.split('-')
    return date_from !== date_until ?
      `${loc(dict.main_page.text_52)} ${d}.${m} ${loc(dict.main_page.text_51)} ${dd}.${mm}` :
      `${d}.${m}`
}

export default function GroupCard({translate, ...props}) {
    const location = useLocation()
    // Новое расписание
    const isNewSchedule = props.item.new_schedule

    const [open, setOpen] = useState(false)

    const lastSchedules = schedules => {
        // Если новое расписание
        if (isNewSchedule && Object.keys(props.item.ws_schedules).length) {
            const sch = props.item.ws_schedules
            return <div className='groupCard__infoBlock'>
                <span>{`${Math.ceil(sch.minutes_total / 45)} ${translate.academic_hour}`}</span>
                <div className='groupCard__delimiter'></div>
                <span>{`${sch.start_at?.substring(0, 5)}-${sch.finish_at?.substring(0, 5)}`}</span>
                <div className='groupCard__delimiter'></div>
                <span>{scheduleDays(sch.weekdays)}</span>
            </div>
        }
        // Если старое
        else if (!isNewSchedule && schedules.length > 0) {
            const hours_total = schedules.reduce((sum, sch) => sum + Number(sch.hours_total), 0);
            // Ищем актуальное расписание
            const actualSchedule =
              schedules.find(sch => (new Date() >= new Date(sch.date_from) && new Date <= new Date(sch.date_until)))
              || schedules[0]

            return <div className='groupCard__infoBlock'>
                    <span>{`${hours_total} ${actualSchedule.hour_type.short_name}.${loc(dict.monthly_plan.ch)}`}</span>
                    <div className='groupCard__delimiter'></div>
                    <span>{`${actualSchedule.start_at.substring(0, 5)}-${actualSchedule.finish_at.substring(0, 5)}`}</span>
                    <div className='groupCard__delimiter'></div>
                    <span>{scheduleDays(actualSchedule.weekdays)}</span>
                </div>

        }
        // Без расписания
        else {
            return <span>{localisationMaster('text_96', 'groups/all-groups')}</span>
        }
    }
    const lastTeacher = (schedules, teachers) => {
        let teacher
        if (isNewSchedule) {
            teacher = props.item.ws_schedules.teachers?.[0]
        } else if (schedules?.length > 0) {
            if (teachers.length > 0) {
                const
                  activeSch = schedules.find(x => new Date(x.date_from) < new Date() && new Date(x.date_until) > new Date()),
                  futureSch = schedules.filter(x => new Date(x.date_from) > new Date()),
                  pastSch = schedules.filter(x => new Date(x.date_until) < new Date())
                teacher =
                  activeSch ?
                    teachers.find(x => x.id === activeSch.teachers[0]) :
                    futureSch.length ?
                      teachers.find(x => x.id === futureSch[0].teachers[0]) :
                      pastSch.length &&
                        teachers.find(x => x.id === pastSch[pastSch.length - 1].teachers[0])
            }
        }
        return teacher ?
          <Link target='_blank' to={'/account/' + teacher.user_id}>{Stores.transliterate(teacher.full_name)}</Link> :
          localisationMaster('text_97', 'groups/all-groups')
    }

    const classroom = Object.keys(props.item.ws_schedules).length !== 0 ?
        <>
            <span>{loc(dict.group.text_1)}: {props.item.ws_schedules.classroom?.name}</span>
            <div className='groupCard__delimiter'></div>
        </>  : null
    return (
        <div className={"groupCard " + (open ? " groupCard--open" : "") + (props.item.debtors_count > 0  ? 'groupCard__debtors' : '')}>
            <div className="groupCard__head">
                <div className="groupCard__info">
                    <Link
                        className={"groupCard__groupName " + (props.item.debtors_count > 0 ? " groupCard__groupName--debt" : "")}
                        to={{
                            pathname: `/groups/group/${props.item.id}/`,
                            state: {prevPath: location.pathname + location?.search || ''}
                        }}
                        target='_blank'
                    >
                        {`${props.item.number} - ${props.item.name}`}
                    </Link>

                    <div className="groupCard__infoBox">
                        <div className="groupCard__infoBlock">
                            <span>{props.item.skill_level !== null && props.item.skill_level.name}</span>
                            <div className='groupCard__delimiter'></div>
                            <span>{props.item.discipline !== null && props.item.discipline.name}</span>
                            <div className='groupCard__delimiter'></div>
                            <span>{lastTeacher(props?.item?.schedules, props?.item?.teachers)}</span>
                        </div>

                        <div className="groupCard__infoBlock">
                            <div>
                                <span>
                                    {props.item.location.name}
                                </span>
                            </div>
                            <div className='groupCard__delimiter'></div>
                            <div>
                                {lastSchedules(props.item?.schedules)}
                            </div>
                            <div className='groupCard__delimiter'></div>
                            <span>{props.item.online ? localisationMaster('text_29', 'groups/all-groups') : localisationMaster('text_29a', 'groups/all-groups')}</span>
                            <div className='groupCard__delimiter'></div>
                            {classroom}
                            <span>{props.item.course_level !== null && props.item.course_level.name}</span>
                        </div>

                    </div>
                </div>
                <div className="groupCard__note">
                    <div className={"groupCard__sticker " + (props.item.debtors_count > 0 ? " groupCard__sticker--debt" : "")}>
                        {/*<span>{localisationMaster('text_89', 'groups/all-groups')} / {localisationMaster('text_90', 'groups/all-groups')}</span>*/}
                        {/*<span><strong>{props.item.actual_students_count} / {props.item.debtors_count}</strong></span>*/}
                        <h3 className={props.item.debtors_count > 0 ? 'groupCard__groupName--debt' : ''}>{translate.students}</h3>
                        <div className='groupCard__studentsStats'>
                            <div className='groupCard__stats'>
                                <h4>{translate.actual}</h4>
                                <span>{props.item.actual_students_count}</span>
                            </div>
                            <div className='groupCard__delimiter'></div>
                            <div className='groupCard__stats'>
                                <h4 className={props.item.debtors_count > 0 ? 'groupCard__groupName--debt' : ''}>{translate.debt}</h4>
                                <span className={props.item.debtors_count > 0 ? 'groupCard__groupName--debt' : ''}>{props.item.debtors_count}</span>
                            </div>
                            <div className='groupCard__delimiter'></div>
                            <div className='groupCard__stats'>
                                <h4>{translate.total}</h4>
                                <span>{props.item.students_count}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
