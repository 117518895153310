import {action, observable, toJS, makeObservable, computed} from "mobx";
import {superAxiosRequest} from "axiosApi";

class LessonTeacherStore {
  lessonData = null
  assignExercisesData = []
  homeworkExercisesData = []
  activeSection = 1
  sectionData = null
  errorStatus = ''

  constructor() {
    makeObservable(this, {
      lessonData: observable,
      assignExercisesData: observable,
      homeworkExercisesData: observable,
      activeSection: observable,
      sectionData: observable,
      errorStatus: observable,

      setLessonData: action,
      clearLesson: action,
      setSectionData: action,
      setActiveSection: action,
      assignExercise: action,
      setAssignExercises: action,
      addHomework: action,

      chapter: computed,
      assignExercises: computed,
      section: computed,
    })
  }

  fetchLesson = (link, uuid) => {
    const apiConf = {
      method: 'get',
      link: `${link}/${uuid}`,
    }
    superAxiosRequest(apiConf).then(({data}) => {
      this.setLessonData({...data, uuid})
      this.assignExercisesData = data.assign_exercises
      this.homeworkExercisesData = data.homework_exercises
    }).catch(err => {
      this.errorStatus = err.response.status
    })
  }

  setLessonData = data => this.lessonData = data

  clearLesson = () => {
    this.lessonData = null
    this.assignExercisesData = []
    this.activeSection = 1
  }

  fetchSection = id => {
    const apiConf = {
      method: 'get',
      link: 'ebooks/sections/' + id
    }
    superAxiosRequest(apiConf).then(res => {
      this.setSectionData(res.data)
    })
  }

  setSectionData = data => this.sectionData = data

  setActiveSection = (direction, num) => {
    if (num) this.activeSection = num
    if (direction === 'prev') this.activeSection = Math.max(this.activeSection - 1, 1)
    if (direction === 'next') this.activeSection = Math.min(this.activeSection + 1, this.chapter.sections.length)
  }

  assignExercise = data => {
      this.assignExercisesData = (this.assignExercisesData.find(ae => ae.exercise_id === data.exercise_id)) ?
        this.assignExercisesData.map(ae => ae.exercise_id === data.exercise_id ? data : ae) :
        this.assignExercisesData = [...this.assignExercisesData, data]
  }

  setAssignExercises = arr => {
    this.lessonData = {
      ...this.lessonData,
      assign_exercises: arr
    }
    this.assignExercisesData = arr
  }

  inHomework = id => this.homeworkExercises.includes(id)

  addHomework = data => {
    this.homeworkExercisesData = data
  }

  get chapter() {
    return toJS(this.lessonData)
  }

  get assignExercises() {
    return toJS(this.assignExercisesData)
  }

  get homeworkExercises() {
    return toJS(this.homeworkExercisesData)
  }

  get section() {
    return toJS(this.sectionData)
  }
}

LessonTeacherStore = new LessonTeacherStore()

export default LessonTeacherStore
