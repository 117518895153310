import React, {useCallback, useEffect, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import {useHistory} from "react-router-dom";
import Loader from "components/UI/Loader/Loader";
import Stores from "Stores"
import TabMenu from "../Common/TabMenu/TabMenu";
import useLocalization from "hooks/useLocalization";
import SearchBooks from "../Books/components/SearchBooks/SearchBooks";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import PastLesson from "./PastLesson";
import Pagination from "components/UI/Pagination/Pagination";
import PageHeader from "components/UI/PageHeader/PageHeader";
import st from "./PastLessons.module.scss";
import AsideFilter from "../../../UI/AsideFilter/AsideFilter";
import AsideFilterStore from "../../../UI/AsideFilter/asideFilterStore";
import {localisationMaster} from "../../../../Localisation/Localisation";
import {observer} from "mobx-react";
import FilterTextValues from "../../../UI/AsideFilter/FilterTextValues";


function PastLessons() {
  const isClient = Stores.isClient
  const {isOpenFilter, closeFilter, filterTextValues} = AsideFilterStore
  const [lessons, setLessons] = useState([])
  const [pagination, setPagination] = useState({
    limit: 10,
    count: 0,
    page: 1,
    offset: 0
  })
  const [tabID, setTabID] = useState(3)
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({})
  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'ebooks_past_lessons',
    dict: {
      "e_books": "E-Books",
      "add_new_book": "Add New Book",
      "adding_a_book": "Adding a Book",
      "search_for_e_books": "Search a Lesson",
      "full_catalog": "Full Catalog",
      "personal_e_books": "Personal E-Books",
      "past_lessons": "Past Lessons",
      "lessons_empty": "Most likely you haven't had any lessons yet.",
      "load_more": "Load More",
      "edit": "Edit",
      "copy": "Copy",
      "delete": "Delete",
      "save": "Save",
      "filter": "Filter",
      "company": "Company",
      "select_company": "Select Company",
      "education_type": "Education Type",
      "select_education_type": "Select Education Type",
      "group_level": "Group Level",
      "select_group_level": "Select Group Level",
      "reset_all": "Reset all",
      "apply": "Apply",
      "search": "Search",
      "no_lessons": "No lessons",
      "group_name_or_number": "Group Name or Number",
      "book": "Book",
      "select_book": "Select Book",
      "lesson_status": "Lesson Status",
      "in_progress": "In Progress",
      "finished": "Finished",
      "lesson_date": "Lesson Date",
      "start_date": "Start Date",
      "end_date": "End Date",
      "page" : "Page"
    }
  })

  const lessonStatusType = [
    {id: 0, name: translate.in_progress},
    {id: 1, name: translate.finished}
  ]

  const maxBooksCount = 999999
  const config = [
    {
      nameState: 'group_name_number',
      placeholder: translate.group_name_or_number,
      type: "input",
      label: translate.group_name_or_number,
    },
    {
      nameState: 'ebook_id',
      placeholder: translate.select_book,
      type: "select",
      apiLink: 'ebooks/books',
      apiConfParams: {
        limit: maxBooksCount
      },
      label: translate.book
    },
    {
      values: lessonStatusType,
      nameState: 'is_finish',
      text: localisationMaster('text_9', 'groups/all-groups'),
      type: "type_selector",
      label: translate.lesson_status
    },
    {
      type: 'date_day_picker',
      nameState: ['start_date', 'end_date'],
      label: translate.lesson_date
    }
  ]

  const history = useHistory()

  const createTabs = () => [
    {id: 1, name: isClient ? 'My Books' : translate.full_catalog, link: isClient ? '/ebooks/my-books' : '/ebooks/books'},
    ...(isClient ? [] : [{id: 2, name: translate.personal_e_books, link: '/ebooks/books'}]),
    {id: 3, name: translate.past_lessons}
  ]

  const fetchLessons = useCallback(() => {
    setIsLoading(true)
    superAxiosRequest({
      method: 'get',
      link: 'ebooks/past_lessons',
      params: {
        offset: (pagination.page - 1) * pagination.limit,
        limit: pagination.limit,
        ...filter,
        ...('is_finish' in filter && {is_finish: !!filter.is_finish}),
        ...(search && {search})
      }
    }).then(res => {
      setLessons(res.data.result)
      setPagination(pg => ({...pg, count: res.data._meta.count_items}))
      setIsLoading(false)
    }).catch(() => {
      setLessons([])
      setIsLoading(false)
    })
  }, [pagination.limit, pagination.page, filter, search])

  const onChangeTab = tab => {
    if (tab.link){
      history.push(tab.link)
    } else {
      setTabID(tab.id)
    }
  }

  useEffect(() => {
    return AsideFilterStore.unmountFilter
  }, []);

  useEffect(() => {
    fetchLessons()
  }, [fetchLessons])

  return (
    <div className={st.pageContainer}>
      <PageHeader title={translate.past_lessons}/>

      <div className={st.booksContainer}>
        {lessons.length !== 0 &&
          <SearchBooks
            onFilter = {() => {}}
            onSearch={setSearch}
            onClear={() => setSearch('')}
            defaultValue={search}
            translate={translate}
            placeholder={`${translate.search_for_e_books}...`}
          />}

        {!!filterTextValues.length && <FilterTextValues onDelete={key => {
          const {[key]: _, ...obj} = filter
          setFilter(obj)
        }}/>}

        {isOpenFilter && <AsideFilter
            translate={translate}
            getFilterParams = {setFilter}
            config = {config}
            filterValue={filter}
            onDropFilter={() => {
              setFilter({})
              closeFilter()
            }}
        />}

        <TabMenu tabs={createTabs()} onChange={onChangeTab} tabID={tabID}/>

        <div className={st.listContainer}>{isLoading ?
          <Loader/> :
          lessons.length > 0 ?
            lessons.map(lesson => <PastLesson  key={lesson.uuid} lesson={lesson} />)
            :
            <WithoutExercises text1={translate.no_lessons}  text2={translate.lessons_empty}/>}
        </div>

        <Pagination limit={pagination.limit} count={pagination.count} page={pagination.page}
                    translate={translate}
                    onChange={page => {
                      setPagination(pg => ({...pg, page}))
                    }}
                    onChangePerPage={limit => {
                      setPagination(pg => ({...pg, page: 1, limit}))
                    }}/>
      </div>
    </div>
  )
}

export default observer(PastLessons)