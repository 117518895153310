import React from 'react';
import ReactSelect, {components} from 'react-select';
import {ReactComponent as Chevron} from './chevron.svg';


export const ExerciseSelect = (props) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <Chevron/>
            </components.DropdownIndicator>
        );
    };

    let controlWidth = '140px'
    if (props.value && props.value.label) {
        controlWidth = `${8 * props.value.label.length + 80}px` // Калькулирование ширины select в зависимости от выбраной опции
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxWidth: '250px',
            width: 'max-content',
            zIndex: '999'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'var(--color-primary-2)' : props.isCorrect ? 'var(--color-success-1)' : (props.isCorrect === false ? 'var(--color-danger-1)' : 'var(--color-black-40)'),
            boxShadow: props.isCorrect ? '0 0 0 2px rgba(26, 188, 151, 0.60)' : (props.isCorrect === false ? '0 0 0 2px rgba(255, 0, 34, 0.60)' : ''),
            minHeight: '30px',
            height: '36px',
            fontSize: '18px',
            borderRadius: '7px',
            cursor: 'pointer',
            "&:hover": {
                boxShadow: '0px 0px 0px 2px rgba(85, 177, 243, 0.6)',
                ".select__single-value": {
                    color: 'var(--color-black-80)'
                },
                 ".select__indicator-separator": {
                     backgroundColor: 'var(--color-black-60)'
                 },
            },
            width: props.isImagesSelectText ? '100%' : controlWidth,
            maxWidth: '250px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '36px',
            paddingLeft: '8px',
            marginTop: '-2px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'var(--color-black-40)',
            height: '20px',
            margin: '7px 0',
        }),
        option: (provided, state) => ({
            ...provided,
          lineHeight: '20px'
        }),
        singleValue: (provided) => ({
            ...provided,
            lineHeight: "36px",
            color: 'var(--color-black-80)',
            fontWeight: '700',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '0 2px',
            'svg': {
                width: '24px',
                height: '24px'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '18px',
            whiteSpace: 'noWrap',
            fontWeight: '400',
            color: 'var(--color-black-60)',
        }),
    };
    return (
        <ReactSelect
            classNamePrefix={'select'}
            onChange={props.onChange}
            options={props.options}
            defaultValue={props.defaultValue}
            value={props.value || props.defaultValue}
            styles={customStyles}
            placeholder={props.placeholder}
            components={{DropdownIndicator}}
        />
    );
}


