import { dateFormat } from 'helpers/date';
import { scrollToRef } from 'helpers';

const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_HOUR = 3600000;
const Postfix = {
  YEARS: ['год', 'года', 'лет'],
};

const shiftHours = (hours, shiftCount) => {
  let shifted = +hours + shiftCount;
  if (shifted < 0) {
    shifted += + 24;
  }
  if (shifted > 24) {
    shifted -= 24;
  }

  return shifted.toString().padStart(2, '0');
};
export const getAdaptedTime = (time, timeZone = 0) => {
  if (!timeZone) {
    return time.substring(0, 5);
  }
  const hours = shiftHours(time.split(':')[0], timeZone);
  return `${hours === '24' ? '00' : hours}:${time.split(':')[1]}`;
};

export const getTimeInMinutes = (time, timeZone = 0) => {
  const timeArray = time.split(':');
  const hours = shiftHours(timeArray[0], timeZone);
  return hours * 60 + +timeArray[1];
};

export const humanizeTime = (timeInMinutes, timeZone = 0) => {
  const hours = Math.trunc(timeInMinutes  / 60 + timeZone);
  const minutes = timeInMinutes % 60;
  const adaptedHours = shiftHours(hours, 0);
  const adaptedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${adaptedHours === '24' ? '00' : adaptedHours}:${adaptedMinutes}`;
};

export const adaptDateToServer = (time) => {
  const date = time instanceof Date ? time : new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const normalizedMonth = month < 10 ? `0${month}` : month;
  const dateNumber = date.getDate();
  const normalizedDateNumber = dateNumber < 10 ? `0${dateNumber}` : dateNumber;

  return `${year}-${normalizedMonth}-${normalizedDateNumber}`;
};

export const findOption = (value, options) => options.find((item) => item.value === value);

export const calcTimeWithTimeZoneShift = (time, timeZone) => {
  const timeArray = time.split(':');
  const hours = +timeArray[0];
  const minutes = timeArray[1];
  let shiftedHours = hours + timeZone;

  if (shiftedHours >= 24) {
    shiftedHours -= 24;
  }
  if (shiftedHours < 0) {
    shiftedHours += 24;
  }

  return `${shiftedHours}:${minutes}`;
};

export const calcTimeDiff = (start, finish) => {
  return (new Date(`0001-01-01T${finish}`) - new Date(`0001-01-01T${start}`)) / MILLISECONDS_IN_MINUTE;
};

export const calcPayDate = (timeGap, timeZone, currentDate = Date.now()) => {
  const date = new Date(currentDate + timeGap * MILLISECONDS_IN_MINUTE);
  const dateUTC = new Date(date.getTime() + date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE);
  const userDate = new Date(dateUTC.getTime() + timeZone * MILLISECONDS_IN_HOUR);
  const hours = userDate.getHours().toString().padStart(2, '0');
  const minutes = userDate.getMinutes().toString().padStart(2, '0');
  const adaptedDate = dateFormat(userDate, 'dd MMMM YYYY г.');
  const adaptedDateToServer = dateFormat(dateUTC, 'YYYY-mm-dd');
  const hoursUTC = dateUTC.getHours().toString().padStart(2, '0');
  const minutesUTC = dateUTC.getMinutes().toString().padStart(2, '0');
  const zone = timeZone > 0 ? `GMT+${timeZone}` : `GMT-${timeZone}`;

  return {
    full: `${hours}:${minutes}, ${adaptedDate}`,
    withoutYear: `${hours}:${minutes}, ${dateFormat(userDate, 'dd MMMM')}`,
    withTimeZone: `${adaptedDate} г. ${hours}:${minutes} (${zone})`,
    dateUTC: adaptedDateToServer,
    timeUTC: `${hoursUTC}:${minutesUTC}`,
  };
};

export const getShiftedTime = (date, timeZone, format = 'dd MMMM') => {
  const currentDate = new Date(Date.parse(date) + timeZone * 3600000);
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');

  return {
    time: `${hours}:${minutes}`,
    date: dateFormat(currentDate, format),
  };
};

export const validateEmail = (email) => {
  if (typeof email !== 'string') {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email.toLowerCase());
};

export const calcPostfix = (value, type = 'YEARS') => {
  const lastChar = value % 10;
  if (value > 10 && value < 20) {
    return Postfix[type][2];
  }
  if (lastChar > 1 && lastChar < 5) {
    return Postfix[type][1];
  }
  if (lastChar === 1) {
    return Postfix[type][0];
  }
  return Postfix[type][2];
};

export const getLevels = (level) => {
  if (!level) {
    return [];
  }

  const levels = level.split(' / ');
  const result = [];
  const reg = /\s*\([^()]*\)/;

  levels.forEach((item) => {
    if (item.includes('Lite')) {
      result.push({name: 'Lite', level: item.replace(reg, '') });
    }
    if (item.includes('Smart')) {
      result.push({name: 'Smart', level: item.replace(reg, '') });
    }
    if (item.includes('Pro')) {
      result.push({name: 'Pro', level: item.replace(reg, '') });
    }
  });

  return result;
};

export const showElem = (wrapperRef, innerRef, trigger, isNeedToScroll = false) => {
  if (trigger) {
    const innerHeight = innerRef.current.offsetHeight;
    wrapperRef.current.style.height = 0;
    wrapperRef.current.style.overflowY = 'hidden';
    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.height = innerHeight + 'px';
      }
    }, 0);
    if (isNeedToScroll) {
      scrollToRef(wrapperRef, 100);
    }
    wrapperRef.current.addEventListener('transitionend', () => {
      wrapperRef.current.style.height = 'auto';
      wrapperRef.current.style.overflowY = 'visible';
    }, {once: true});
  }
};

export const hideElem = (wrapperRef, innerRef, trigger, setTrigger = () => {}) => {
  if (trigger) {
    const innerHeight = innerRef.current.offsetHeight;
    wrapperRef.current.style.height = innerHeight + 'px';
    wrapperRef.current.style.overflowY = 'hidden';
    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.height = 0;
      }
    }, 10);
    wrapperRef.current.addEventListener('transitionend', () => {
      wrapperRef.current.style.height = 'auto';
      wrapperRef.current.style.overflowY = 'visible';
      setTrigger(false);
    }, {once: true});
  } else {
    wrapperRef.current.style.height = 0;
    wrapperRef.current.style.overflowY = 'hidden';
    setTrigger(true);
  }
};

export const sortSlotByStartTime = (a, b) => {
  const aMinutes = +a.start_at.split(':')[0] * 60 + a.start_at.split(':')[1];
  const bMinutes = +b.start_at.split(':')[0] * 60 + b.start_at.split(':')[1];
  return  aMinutes - bMinutes;
};
