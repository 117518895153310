import React from "react";
import {observer} from "mobx-react";
import holidaysCalendarStore from "./holidaysCalendarStore";
import st from "./YearSelector.module.scss"

const YearSelector = () => {
    const {year, setYear} = holidaysCalendarStore

    return (
        <div className={st.box}>
            <div onClick={() => setYear(year - 1)}>&#5130;</div>
            {year}
            <div onClick={() => setYear(year + 1)}>&#5125;</div>
        </div>
    )
}

export default observer(YearSelector)
