import React, {useState} from "react";
import MakeWord from "./MakeWord";
import st from "./MakeWords.module.scss";

const MakeWords = ({data, ...props}) => {
  const [active, setActive] = useState(Array.from({length: data.length}).fill(false))

  const handleAnswers = (answers, isActive, index) => {
    const allActive = active.map((is, i) => i === index ? isActive : is)
    const arr = [...props.answers]
    arr[index] = answers
    setActive(allActive)
    props.handleAnswers(arr, allActive.every(x => x))
  }

  return (
    <div className={st.makeWords}>{data.map((oWord, index) =>
      <div key={index} className={data.length > 1 ? st.oneWord : undefined}>
        {data.length > 1 && <span className={st.counter}>{`${index + 1} / ${data.length}`}</span>}
        <MakeWord word={oWord}
                  {...props}
                  handleAnswers={(answers, isActive) => handleAnswers(answers, isActive, index)}
                  studentAnswer={props.studentAnswer && {
                    ...props.studentAnswer,
                    answer: props.studentAnswer.answer.at(index),
                    result: props.studentAnswer.result_in_detail.at(index)
                  }}
        />
      </div>)}
    </div>
  )
}

export default MakeWords;
