import React, {useState, useEffect} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores';
import './ModalChangePassword.scss'
import swal from "sweetalert";

const Link = 'company/profiles/account'
let Tag = 'add_category'

export default function ModalChangePassword(props) {
    const initErrors = [
        {valid: false, name: 'length', text: localisationMaster('error-text-1', Link)},
        {valid: false, name: 'letter', text: localisationMaster('error-1-letter', Link)},
        {valid: false, name: 'number', text: localisationMaster('error-1-digit', Link)},
        {valid: false, name: 'match', text: localisationMaster('error-text-2', Link)}
    ]

    // Возможность изменить пароль без ввода старого
    const user_data_id = JSON.parse(localStorage.getItem('ud')).user_data.id;
    const canChangePassword = Stores.Rules.checkRule('user', 'edit_password') && user_data_id !== +props.id

    const [user, setUser] = useState({old_pass: '', new_pass: '', confirm_new_pass: ''})
    const [errors, setErrors] = useState(initErrors)
    const [error, setError] = useState('')
    const [newClassOne, setNewClassOne] = useState(false);
    const [newClassTwo, setNewClassTwo] = useState(false);
    const [newClassThree, setNewClassThree] = useState(false);

    const onChangeHandler = (key, val) => {
        setUser({...user, [key]: val})
    }

    const validate = () => {
        let arr = errors

        arr = arr.map(item => {
            switch (item.name){
                case 'length':
                    item.valid = user.new_pass.length >= 8
                    break
                case 'letter':
                    item.valid = /[a-zA-Z]/.test(user.new_pass)
                    break
                case 'number':
                    item.valid = /[0-9]/.test(user.new_pass)
                    break
                case 'match':
                    item.valid = user.new_pass.length > 0 && user.new_pass === user.confirm_new_pass
                    break
            }

            return item
        })

        setErrors(arr)
    }

    const onSubmitHandler = e => {
        e.preventDefault();
        setError('')
        if(user.old_pass.length > 0 || canChangePassword){
            if (errors.findIndex(x => !x.valid) === -1){
                let apiConf = {
                    method: 'put',
                    link: 'accounts/change_password',
                }

                const qJson = {new_password : user.new_pass}

                if (canChangePassword)
                    qJson.id = +props.id
                else
                    qJson.old_password = user.old_pass

                superAxiosRequest(apiConf, qJson)
                    .then((res) => {
                        swal("", res.data.message_ru, 'success')
                            .then(()=>{
                                props.modal()
                                // Сброс требования сменить пароль
                                const ud = JSON.parse(localStorage.getItem('ud'))
                                delete ud.required_password_change
                                localStorage.setItem('ud', JSON.stringify(ud))
                            })
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 400) {
                            setError(err.response.data.error)
                            swal({
                                dangerMode: true,
                                text: err.response.data.error,
                                icon: 'error'
                            })
                        }
                    });
            } else {
                swal({
                    dangerMode: true,
                    text: localisationMaster('error-meet', Link),
                    icon: 'error'
                })
            }
        }
        else {
            //setError('Введите текущий пароль')
            swal({
                dangerMode: true,
                text: localisationMaster('error-oldpass', Link),
                icon: 'error'
            })
        }
    }

    useEffect(() => {
        validate()
    }, [user])

    return (
        <div className="super-modal">
            <div className="super-modal-inside" style={{width: '520px'}}>

                <div className="super-modal-header">
                    <div className="super-modal-title">{localisationMaster('title-modal', Link, Tag)}</div>
                    {!props.required &&
                    <div className="super-modal-close" onClick={props.modal}>&#215;</div>}
                </div>

                <div className="super-modal-content modalChangePassword">
                    <form className="modalChangePassword__form" onSubmit={onSubmitHandler}>

                        {!canChangePassword &&
                        <div
                            className={'modalChangePassword__field' + (newClassOne ? ' modalChangePassword__field--focus' : '')}
                            onFocus={() => setNewClassOne(true)}
                            onBlur={() => setNewClassOne(false)}
                        >
                            <p>{localisationMaster('input-placeholder-4', Link, Tag)}</p>
                            <input type="password" value={user.old_pass} onChange={e => {onChangeHandler('old_pass', e.target.value)}} />
                        </div>
                        }

                        <div
                            className={'modalChangePassword__field'+(newClassTwo ? ' modalChangePassword__field--focus' : '')}
                            onFocus={()=>setNewClassTwo(true)}
                            onBlur={()=>setNewClassTwo(false)}
                        >
                            <p>{localisationMaster('input-placeholder-5', Link, Tag)}</p>
                            <input type="password" value={user.new_pass} onChange={e => {onChangeHandler('new_pass', e.target.value)}} />
                        </div>

                        <div
                            className={'modalChangePassword__field'+(newClassThree ? ' modalChangePassword__field--focus' : '')}
                            onFocus={()=>setNewClassThree(true)}
                            onBlur={()=>setNewClassThree(false)}
                        >
                            <p>{localisationMaster('input-placeholder-6', Link, Tag)}</p>
                            <input
                                type="password"
                                value={user.confirm_new_pass}
                                onChange={e => {onChangeHandler('confirm_new_pass', e.target.value)}}
                            />
                        </div>

                    </form>
                    <div className="modalChangePassword__errors">
                        <p>{localisationMaster('new-pass-meet', Link)}:</p>
                        <ul>
                            {errors.map((item, index) =>
                            <li
                                key={index}
                                className={'modalChangePassword__error '+(item.valid ? ' modalChangePassword__error--valid' : '')}
                            >
                                {item.text}
                            </li>
                            )}
                            <li className="modalChangePassword__error">{error}</li>
                        </ul>
                    </div>
                </div>

                <div className="super-modal__buttons">
                    {!props.required &&
                    <div className="super-modal__cancel-button" onClick={props.modal}>
                        {localisationMaster('button-one', Link, Tag)}
                    </div>}
                    <button className="super-modal__add-button" onClick={onSubmitHandler}>
                        {localisationMaster('button-two', Link, Tag)}
                    </button>
                </div>
                   
            </div>   
        </div>
    )
}
