import React, {useState} from 'react';
import style from './WithGrades.module.scss'
import {ReactComponent as Circle} from './img/circle.svg';

const WithGrades = ({addMark}) => {
    const [pressedIndex, setPressedIndex] = useState(null);
    const grades = [
        { value: 1, symbol: 'A+' },
        { value: 2, symbol: 'A' },
        { value: 3, symbol: 'A-' },
        { value: 4, symbol: 'B+' },
        { value: 5, symbol: 'B' },
        { value: 6, symbol: 'B-' },
        { value: 7, symbol: 'C+' },
        { value: 8, symbol: 'C' },
        { value: 9, symbol: 'C-' },
        { value: 10, symbol: 'D+' },
        { value: 11, symbol: 'D' },
        { value: 12, symbol: 'D-' },
        { value: 13, symbol: 'F' },
    ];

    const handlePress = (index) => {
        setPressedIndex(index);
    };

    return grades.map((grade, index) => {
        let gradeColor = '';
        let pressed = ''

        if (grade.symbol >= 'A' && grade.symbol <= 'B-') {
            gradeColor = style.markGreen;
            pressed = style.pressedGreen
        } else if (grade.symbol >= 'C' && grade.symbol <= 'D-') {
            gradeColor = style.markYellow;
            pressed = style.pressedYellow
        } else if (grade.symbol === 'F') {
            gradeColor = style.markRed;
            pressed = style.pressedRed
        }

        const isPressed = pressedIndex === index;

        return (
          <div className={`${style.marks} ${gradeColor} ${isPressed && pressed}`}
               key={grade.value}
               onClick={() => addMark(grade.value)}
               onMouseDown={() => handlePress(index)}
          >
              <Circle/>
              <span className={style.mark}>{grade.symbol}</span>
          </div>
        );
    });
};
export default WithGrades;