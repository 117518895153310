import React, {useEffect, useState} from 'react';
import DocsList from '../DocsList/DocsList';
import {permissions} from 'settings/permissions';


const pages = {
    '/contract/template-list': {
        pageTitle: 'Шаблоны контрактов',
        document: {
            baseLink: '/contract',
            user: 'сотрудник'
        },
        permission: permissions.view_contract_viewer,
        modal: {
            title: 'Отправить документ сотруднику',
            link: 'accounts/employers_list',
            requestLink: 'documents/employees_signing_document',
            fieldName: {
                temtlate: 'template',
                user: 'employee',
            }
        },
        apiLink: 'documents/template_constructor_employees',
    },
    '/contract/not-signed': {
        pageTitle: 'Контракты на подписание',
        document: {
            baseLink: '/contract'
        },
        permission: permissions.view_contract_viewer,
        confirmField: 'email_code_confirmed',
        userNameField: 'employee_name',
        apiLink: 'documents/employees_signing_document',
    },
    '/contract/signed': {
        pageTitle: 'Подписанные контракты',
        document: {
            baseLink: '/contract'
        },
        filter: true,
        permission: permissions.view_contract_viewer,
        userNameField: 'employee_name',
        confirmField: 'email_code_confirmed',
        apiLink: 'documents/employees_signing_document',
    },
    '/docs/template-list': {
        pageTitle: 'Шаблоны заявок на обучение',
        confirmField: 'sms_code_confirmed',
        permission: permissions.view_docs_viewer,
        apiLink: 'documents/template_constructor',
        document: {
            baseLink: '/docs',
            user: 'клиент'
        },
        modal: {
            title: 'Отправить документ клиенту',
            link: 'accounts/clients',
            requestLink: 'documents/client_signing_document',
            fieldName: {
                temtlate: 'document_flow',
                user: 'client',
            }
        },
    },
    '/docs/not-signed': {
        pageTitle: 'Заявки на подписание',
        document: {
            baseLink: '/docs'
        },
        permission: permissions.view_docs_viewer,
        userNameField: 'client_name',
        confirmField: 'sms_code_confirmed',
        apiLink: 'documents/client_signing_document',
        apiParams: {}
    },
    '/docs/signed': {
        pageTitle: 'Подписанные заявки',
        document: {
            baseLink: '/docs'
        },
        filter: true,
        permission: permissions.view_docs_viewer,
        userNameField: 'client_name',
        confirmField: 'sms_code_confirmed',
        apiLink: 'documents/client_signing_document',
        apiParams: {}
    }
}

const DocsListContainer = ({location: {pathname: url, search}, ...props}) => {
    const [pageState, setPageState] = useState()
    let query = new URLSearchParams(search)
    
    useEffect(() => {    
        setPageState({
            ...pages[url],
            apiParams: query.get('client_id') ? {client_id: query.get('client_id')} : {}
        })    
    }, [url, search ])
    return pageState ? <DocsList {...pageState} typeProps={props.match?.params?.type} /> : null
}

export default DocsListContainer
