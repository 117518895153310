import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Tooltip from 'components/Modules/Slots/components/Tooltip/Tooltip';
import { calcTimeWithTimeZoneShift } from 'components/Modules/Slots/utils';
import { teacherSlotType } from './TeacherHistoryCard';
import LocalStorage from 'services/LocalStorage';
import styles from './TeacherHistoryCard.module.scss';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import TimeTableSlotModal from '../../TimelineTeachersHistory/components/TimeTableSlotModal';
import Menu from '../../TeacherSchedule/components/BookedSlots/Menu';
import {TeachersHistoryModalContext} from '../TeachersHistory';
import { superAxiosRequest } from 'axiosApi';



function TeacherHistorySlot({data, employee_id,}) {
  const { id, start_at, finish_at, slot_client, is_active, type_name, comment, color, is_accessible } = data;
  const timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [modalData, setModalData] = useState({
    show: false
  });
  let modalContext = useContext(TeachersHistoryModalContext)
  const currentColor = (!is_active && '#ffdae7') || (slot_client?.length > 0 && '#b1f5cf') || color;
  const handleClose = () => {
    setModalData({show:false})
  }
  const createContentElement = (data, timeZone) => {
    const content = document.createElement('div');
    const onlineStatus = (data.is_online && data.is_school ? "Онлайн + школа" : data.is_online ? "Только онлайн " : " Только в школе") 
    const groupStatus = data.group_clients ? "Групповой слот" : "Одиночный слот"
    const getClientList = () => {
      let list = ""  
      data.slot_client.map(item => (
        list = list + `
        <div>
          <span>${item.first_name? item.first_name: "Имя " }${item.last_name ? item.last_name: "Фамилия" }</span>
          <ul><li>${item.is_missed ? "Пропустил" : item.training_level_name == null ? "Уровень не присвоен" : item.training_level_name.replace("/ ", `</li><li>`)}</li></ul>
        </div>`))
      return list
    }  
    content.className = styles.swal_content;  
    content.innerHTML = `
    <strong>Детали слота:<br> ${timeConverter( data.slot_date, 'day_month')}, ${calcTimeWithTimeZoneShift(data.start_at, timeZone)} - ${calcTimeWithTimeZoneShift(data.finish_at, timeZone)} (${data.type_name})</strong>
    <div>
      <span>${onlineStatus}</span>
      <span>${groupStatus}</span>
    </div>
    `;
    if (data.comment) {
      content.insertAdjacentHTML('beforeend', `
      <span>Комментарий: ${data.comment}</span>
      `)
    }
    if (data.zoom_link) {
      content.insertAdjacentHTML('beforeend', `
      <a href = ${data.zoom_link}>Ссылка на конференцию </a>
      `)
    }
    if (data.is_active && data.slot_client ) {
      content.insertAdjacentHTML('beforeend', `
      <strong>Данные клиента:</strong>
        ${getClientList()}
    `);
    } 
   
  
    return content;
  };
  const deleteTimeSlot = (data, onSuccess = () => { }, onFail = () => { }) => {
    const apiConf = {
        method: 'delete',
        link: `online_schedule/admin/advanced-history/${data.id}`,
    };
    superAxiosRequest(apiConf, {data: {comment: data.comment}})
      .then(() => {
          modalContext.getUpdatedTeachersData(data)
          onSuccess();
        })
        .catch(() => onFail());
  }
  const updateTimeSlot = (data, onSuccess = () => { }, onFail = () => { }) => {
    superAxiosRequest({
        method: 'put',
        link: `online_schedule/admin/advanced-history/${data.id}`,
    }, {...data, location:modalContext.selectedLocation.id})
      .then(() => {
        modalContext.getUpdatedTeachersData(data) 
        onSuccess();
        })
        .catch(() => onFail());
}
  const menuTemplate = {
    change() {
      setIsMenuShown(false)
      return swal({
        buttons: "OK",
        content: createContentElement(data, timeZone),
        icon: "success",
        className: String(styles.swal_modal),
        });
    },
    delete() {
      setIsMenuShown(false)
      setModalData({show:true, type: 'delete'})
    },
    restore() {
      setIsMenuShown(false)
      updateTimeSlot({
        id: data.id,
        is_active: true,
        employee_id: employee_id,
        slot: data.slot_id,
        slot_date:data.slot_date,
        type: data.type_id,
    })
    }
  }

  const handleClick = (type) => {
    if ((typeof type) === "string") {
      menuTemplate[type]() 
    } else {
      if (type === null) {
        setIsMenuShown(false)
        return
      }
      setIsMenuShown(true)  
      setIsTooltipShown(false)
    }
   

  };

  return (
    <li
      style={{backgroundColor: currentColor}}
      key={id}
      className={styles.slot}
      onMouseEnter={() => !isMenuShown && setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      onClick={handleClick}
    >
      {calcTimeWithTimeZoneShift(start_at, timeZone)} - {calcTimeWithTimeZoneShift(finish_at, timeZone)}
      {isTooltipShown &&
      <Tooltip
        text={(slot_client?.length > 0 && 'Занятый слот') || (!is_active && 'Удаленный слот') || type_name}
        isAvailable={is_accessible}
        isShort
      />
          }
          {isMenuShown &&
                 <Menu
                 isDeleted={!data.is_active}
                 onClick={handleClick}
               />
          }
       {modalData.show &&
                <TimeTableSlotModal
                    slotTypes={modalContext.slotTypesArray}
                    id={data.id}
                    type={modalData.type}
                    slotType={data.type_id}
                    isComment={Boolean(data.comment)}
                    isOnline={data.is_online}
                    isSchool={data.is_school}
                    isGroup={data.group_clients}
                    zoomUrl={data.zoom_url}
                    comment={data.comment}
                    onClose={handleClose}
                    slot_date={data.slot_date}
                    employee_id={employee_id}
                    slot_id={data.slot_id}
                    deleteTimeSlot={deleteTimeSlot}
                    propsSelectedLocation = {modalContext.selectedLocation}
                />
            }
    </li>
  );
}

TeacherHistorySlot.propTypes = {
  data: PropTypes.shape(teacherSlotType).isRequired,
}

export default TeacherHistorySlot;
