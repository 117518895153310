import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SettingsSvg } from './icon.svg';
import styles from './SettingsIcon.module.scss';

const SettingsIcon = ({ onClick }) => (
  <SettingsSvg
    className={styles.svg}
    onClick={onClick}
  />
);

SettingsIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SettingsIcon;
