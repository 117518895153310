import React from 'react';
import PropTypes from "prop-types";
import {ReactComponent as CloseLine} from "components/UI/icons/close-line.svg";
import style from './VariantDelete.module.scss'

const VariantDelete = ({onClick, customStyle}) => {
    return (
        <CloseLine onClick={onClick} className={style.del} style={customStyle}/>
    );
};

VariantDelete.propTypes = {
    onClick: PropTypes.func.isRequired
}

VariantDelete.defaultProps = {
    onClick: () => {}
}
export default VariantDelete;