import React, { useEffect, useState } from "react";
import {superAxiosRequestEasy} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import logo from "./logo.png";
import "./Auth.scss";

const ConfirmRegistration = ({ match: { params } }) => {

  const [data, setData] = useState({ status: false, loading: true });

  useEffect(() => {
    superAxiosRequestEasy({
      method: "get",
      link: "accounts/confirm_client_registration/" + params.id,
    }).then(() => {
      setData({status: true, loading: false})
    }).catch(() => {
      setData({status: false, loading: false})
    });
  }, [params.id]);

  const { status, loading } = data;

  return (
    <div className="authorization-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="authorization-box">
          <div>
            <img src={logo} alt="logo" width="70" />
          </div>
          <div className="authorization-box__restore-password authorization-box__verify">
            <h3>
              {status
                ? "Поздравляем, Ваш аккаунт подтвержден!"
                : "Ссылка устарела или была введена не верно!"}
            </h3>
            {status ? (
              <p>
                Теперь Вы можете воспользоваться <a href="/" className="authorization-box__link">формой входа</a> и
                зайти в личный кабинет
              </p>
            ) : (
              <p>
                Пожалуйста нажмите ссылку в письме для подтверждения электронной
                почты.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmRegistration;
