import React, { useState, useEffect } from "react";
import {superAxiosRequest} from "axiosApi";
import './style.scss'
import {localisationMaster} from "Localisation/Localisation";

export default function CheckPayStatus ({ orderID, closeOrder, isDisableCounter }) {
    const [counter, setCounter] = useState(0);
    const [updCounter, setUpdCounter] = useState(15);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    useEffect(() => {
        updCounter > 0 && setTimeout(() => setUpdCounter(updCounter - 1), 1000);
    }, [updCounter]);

    const checkOrder = () => {
        setUpdCounter(15)
        let api = {
            //link: 'payments/check_order/' + orderID,
            link: 'payments/order_status/' + orderID,
            method: 'get'
        }
        //superAxiosRequestEasy(api).then(res => {
        superAxiosRequest(api).then(res => {
            setStatus(res.data.result)
            const a = new Date(res.data.result.payments_term).getTime();
            localStorage.setItem('orderDie', res.data.result.payments_term)
            const b = new Date().getTime();
            const delta = Math.round((a - b) / 1000)
            setCounter(delta)
        }).catch();
    }

    useEffect(() => {
        checkOrder()
    }, [])

    const goodTime = secs => {
        let sec_num = parseInt(secs, 10)
        let hours = Math.floor(sec_num / 3600)
        let minutes = Math.floor(sec_num / 60) % 60
        let seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }

    return (
        <div className="record-customer-fieldset-container checkPayService">
            <div className="checkPayService__header">
                <div className="checkPayService__order">
                    <span>{localisationMaster('order_number', 'pays/invoice')}</span>
                    <span>{orderID}</span>
                    <span>{status && status.status_text}</span>
                </div>
                <div className="checkPayService__note">
                    <div className="checkPayService__noteTitle">
                        {localisationMaster('text-8', 'pays/invoice')}:
                    </div>
                    <div className="checkPayService__noteList">
                        {/*<span>1. Перейти в приложение KASPI.kz</span>
                        <span>2. Найти компанию InterPress</span>
                        <span>3. В поле номера заказа, внести номер заказа</span>
                        <span>4. Подтвердить свои данные и совершить оплату</span>*/}
                        <span>1. {localisationMaster('text-9', 'pays/invoice')}</span>
                        <span>2. {localisationMaster('text-10', 'pays/invoice')}</span>
                        <span>3. {localisationMaster('text-11', 'pays/invoice')}</span>
                        <span>4. {localisationMaster('text-12', 'pays/invoice')}</span>
                    </div>
                </div>
            </div>

            {(!status || (status && status.status_order !== 'paid')) ?
                <div className="checkPayService__footer">
                    <div className="checkPayService__but">
                        {counter < 1 ?
                            <span className="checkPayService__but--red">
                                {localisationMaster('payment_time_expired', 'pays/invoice')}
                            </span> :
                            <>
                                <button
                                    onClick={checkOrder}
                                    className={`${updCounter > 0 ? 'disabled' : ''}`}
                                    disabled={updCounter > 0}>
                                    {`${localisationMaster('check_payment_status', 'pays/invoice')} ${updCounter > 0 ? `(${localisationMaster('through', 'pays/invoice')} ${updCounter} ${localisationMaster('sec', 'pays/invoice')}.)` : ''}`}
                                </button>
                                <button onClick={closeOrder}>
                                    {localisationMaster('text_5', 'online-schedule/future-slots')}
                                </button>
                            </>
                        }
                    </div>
                    {!isDisableCounter &&
                    <div className="checkPayService__timer">
                        <span>{counter > 0 ? goodTime(counter) : '00:00'}</span>
                        <span>{localisationMaster('time_for_payment', 'pays/invoice')}</span>
                    </div>}
                </div>
                :
                <div>
                    <p className="checkPayService__but--green">
                        {localisationMaster('bill_paid', 'pays/invoice')}
                    </p>
                </div>
            }
        </div>
    )
}
