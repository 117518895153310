import React, {useEffect, useState} from 'react';
import DocsConstructor from '../DocsConstructor/DocsConstructor';


const DocsConstructorContainer = (props) => {
    const [pageState, setPageState] = useState()

    let url = props.match.path
    const pages = {
        '/contract/constructor': {
            link: 'documents/template_constructor_employees',
            mode: 'create'
        },
        '/contract/constructor/:id': {
            link: `documents/template_constructor_employees/${props.match.params.id}`,
            mode: 'edit'
        },

        '/docs/constructor/': {
            link: `documents/template_constructor`,
            mode: 'create'
        },
        '/docs/constructor/:id': {
            link: `documents/template_constructor/${props.match.params.id}`,
            mode: 'edit'
        },
    }


    useEffect(() => {
        setPageState(pages[url])
    }, [url])

    return (
        <>
            {pageState && <DocsConstructor {...pageState} />}
        </>
    )
}

export default DocsConstructorContainer

