import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Calendar from 'components/UI/Calendar/Calendar';
import slotsAdvancedTeachersHistoryStore, { ActionType } from 'Stores/slotsAdvancedTeachersHistoryStore';

function CalendarWrapper({classes}) {
  const [startDate, setStartDate] = useState(null);
  const {
    selectedCompany,
    selectedLocation,
    dates,
    selectedDates,
    resetData,
    getDates,
    getCustomersFromServer,
  } = slotsAdvancedTeachersHistoryStore;

  const handleClick = () => {
    if (selectedDates.size === 0) return;
    resetData(ActionType.resetCustomers);
    getCustomersFromServer();
  };
  
  const handleStartDateChange = date => setStartDate(date);

  const handleReset = () => resetData(ActionType.resetCalendar);

  useEffect(() => {
    const isReadyToLoadCalendar = selectedCompany && selectedLocation;
    const dateFrom = startDate ? new Date(startDate) : new Date();
    if (!isReadyToLoadCalendar) return;
    getDates(dateFrom);
  }, [startDate, selectedCompany, selectedLocation]); 

  return (
    <Calendar
      startDate={startDate}
      dates={dates}
      selectedDates={selectedDates}
      resetData={handleReset}
      onStartDateChange={handleStartDateChange}
      onClick={handleClick}
      allowPast={true}
      classes={classes}
    />
  );
}

export default observer(CalendarWrapper);