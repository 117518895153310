import React from 'react';
import {localisationMaster} from "Localisation/Localisation";


const style = {
    height: '45px',
    backgroundColor: '#3474ff',
    color: '#fff',
    fontWeight: '600',
    display: 'flex',
    paddingLeft: '20px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
}

export default function BlueLine(props) {
    return (
        <div style={style}>{localisationMaster('text_2', 'online-schedule/clients-after-online')} {props.items}</div>
    )
}
