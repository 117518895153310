import React, {useState, useEffect, Fragment} from 'react';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores';
import clientStore from "Stores/clientStore";
import modalStore from "Stores/modalStore";
import asideModalStore from "Stores/asideModalStore";
import ModalChangePassword from 'components/ContentBox/Company/Teachers/ModalChangePassword'
import PersonalInfo from './PersonalInfo';
import Loader from 'components/ContentBox/Global/Loader/Loader';
import ModalAddChildAccount from "components/ContentBox/Company/Client/ModalAddChildAccount";
import LessonCard from "components/ContentBox/Company/components/LessonCard/LessonCard";
import {superAxiosRequest} from 'axiosApi';
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";
import UserInfo from "./UserInfo";
import MainInfo from "./MainInfo";
import ClientBalances from "components/ContentBox/Company/Client/Tabs/ClientBalances/ClientBalances";
import LinkedAccounts from "components/ContentBox/Company/Client/Tabs/LinkedAccounts";
import ClientGroups from "components/ContentBox/Company/Client/Tabs/ClientGroups/ClientGroups";
import StudyTests from "components/ContentBox/Company/components/StudyTests/StudyTests";
import DocsTab from "components/ContentBox/Company/Employee/Tabs/Docs/DocsTab";
import {Certificates} from "components/ContentBox/Company/Client/Tabs/Certificates";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import MyLessons from "../MyLessons/MyLessons";
import useLocalization from "hooks/useLocalization";
import Books from "components/ContentBox/Company/Employee/Tabs/Books/Books";
import st from "./ClientProfile.module.scss";


const ClientProfile = props => {
  const ID = +props.main || +props.match.params['id']

  const {translate} = useLocalization({
    section: 'profile_client',
    subsection: 'client_main_info',
    dict: {
      "menu_main": "Main Information",
      "menu_tests": "Tests",
      "menu_groups": "Groups",
      "menu_documents": "Documents",
      "menu_contracts": "Contracts",
      "menu_books": "Books",
      "menu_lessons": "Lessons",
      "menu_balances": "Balances",
      "menu_certificates": "Certificates",
      "menu_linked_accounts": "Linked Accounts",
      "responsible": "Responsible",
      "additional_contacts" : "Additional contacts",
      "new_contact_detail" : "Add new contact detail",
      "top_up_balance" : "Top Up Balance",
      "phone": "Phone",
      "address" : "Address",
      "personal_info" : "Personal Information",
      "comment" : "Comment",
      "not_assigned" : "Not assigned"
    }
  })

  const dataStorage = localStorage.getItem('ud');
  const data = JSON.parse(dataStorage);
  const {required_password_change, user_data} = data
  const {client, fetchClient, updateProfile} = clientStore

  const r = Stores.Rules
  const isClient = Stores.isClient;

  const [modalOpen, setModalOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const [classNotice, setClassNotice] = useState({loading: true, list: []});

  const clientInfo = () => {
    const profile_id = client.profile.id
    const ms = {
      name: 'client_info',
      link: 'accounts/clients/' + profile_id,
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: localisationMaster('text_3', 'online-schedule/clients-after-online'),
      type: 'client_info',
      client_id: ID,
      profile: profile_id,
      is_put: true,
      isResponsive: true,
      valid: {
        first_name: 'required',
        last_name: 'required',
        inn: 'required',
        date_of_birth: 'required',
        phone: 'required',
        email: 'required',
      },
      onSubmitSuccess: updateProfile,
    }

    asideModalStore.activity(ms)
  }

  const openModalAddChildUser = () => {
    const ms = {
      title: 'Добавление пользователя в аккаунт',
      button: 'Добавить',
      modalBodyComponent: () => <ModalAddChildAccount/>,
      is_put: true,
      //link: 'accounts/clients/' + ID,
      link: 'accounts/clients/' + client.profile.id + '/children',
      func: () => fetchClient(ID)
    }
    modalStore.activity(ms, {children: client.profile.children_id.map(x => x.id)})
  }

  const createTabs = () => [
    // Основное
    {
      id: 1,
      name: translate.menu_main,
      tab: <MainInfo info={client} openModalClientInfo={clientInfo} translate={translate}/>
    },

    // Балансы
    ...(checkPermList(permissions.view_client_balances) && Date.now() < 0 ? [{
      id: 2,
      name: translate.menu_balances,
      tab: <ClientBalances
        bonus_balance={client.profile.bonus_balance}
        id={client.profile.id}/>
    }] : []),

    // Сертификаты
    ...(checkPermList(permissions.view_certuser_to_group) ? [{
      id: 3,
      name: translate.menu_certificates,
      tab: <Certificates id={client.profile.id}/>
    }] : []),

    // Связанные аккаунты
    ...(r.checkRule2('users', 'personal_information', 'change_personal_information')
    && client.profile.children_id.length > 0 ? [{
      id: 4,
      name: translate.menu_linked_accounts,
      tab: <LinkedAccounts client={client}
                           openModalAddChildUser={openModalAddChildUser}/>
    }] : []),

    // Группы клиента
    ...(checkPermList(permissions.view_my_group) ? [{
      id: 5,
      name: translate.menu_groups,
      tab: <ClientGroups id={!isClient && client.profile.id}/>,
    }] : []),

    // Тесты
    ...(props.main || user_data.id === ID ? [{
      id: 6,
      name: translate.menu_tests,
      tab: <StudyTests/>,
    }] : []),

    // Книги
    ...(checkPermList(permissions.view_own_student_book) || checkPermList(permissions.delete_student_book) ? [{
      id: 7,
      name: translate.menu_books,
      tab: <Books clientID={client.id}/>
    }] : []),

    // Документы
    ...(checkPermList(permissions.view_own_filedocuments) || checkPermList(permissions.view_filedocuments) ? [{
      id: 8,
      name: translate.menu_documents,
      tab: <DocsTab info={client}/>
    }] : []),
    // My lessons
      ...(checkPermList(permissions.view_ebook_books_student) || checkPermList(permissions.view_init_lessons) ? [{
      id: 9,
      name: translate.menu_lessons,
      tab: <MyLessons userId={ID} />
      }] : [])
  ]

  useEffect(() => {
    if (Stores.isClient && required_password_change) setModalOpen(true)
  }, [required_password_change])

  useEffect(() => {
    Stores.reloadAvatar = false;
    fetchClient(ID)
    if (checkPermList(permissions.view_group_near_schedules) && props.main) {
      superAxiosRequest({method: 'get', link: 'groups/show_my_schedule'})
        .then(({data}) => setClassNotice({list: data, loading: false}))
        .catch(() => setClassNotice({loading: false, list: []}))
    }
  }, [props.main, ID, fetchClient])

  return (client?.profile ?
      <Fragment>
        {modalOpen &&
          <ModalChangePassword modal={() => setModalOpen(!modalOpen)}
                               id={+ID}
                               required={required_password_change}/>}

        <div className={st.clientProfile}>
          <div>
            <UserInfo translate={translate}
                      user={client}
                      tabs={createTabs()}
                      currentTab={currentTab}
                      onChangeTab={setCurrentTab}
                      updateProfile={updateProfile}
                      isShowKundelik={ID === user_data.id}
            />
          </div>
          <main className={st.clientProfileMain}>
            <div>
              {(checkPermList(permissions.view_group_near_schedules) && props.main) &&
                <div className="employeeCard__notifications">
                  {classNotice.loading
                    ? <Loader/>
                    : classNotice.list.map((el, idx) => <LessonCard key={idx} {...el} is_client={Stores.isClient}/>)}
                </div>}
              <PersonalInfo translate={translate} info={client} ID={ID} openModalClientInfo={clientInfo}/>
            </div>

            <div className={st.menu}>
              <SelectCustom options={createTabs()}
                            valueID={currentTab}
                            onChange={opt => setCurrentTab(opt.id)}/>
            </div>

            <div>
              {createTabs().find(tab => tab.id === currentTab).tab}
            </div>
          </main>
        </div>
      </Fragment> :
      <Loader/>
  )
}

export default observer(ClientProfile)
