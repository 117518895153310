import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import PaidTab from './PaidTab/PaidTab';
//import SmsTab from './SmsTab/SmsTab';
import LocationTab from "./LocationTab/LocationTab";
import AdminTab from './AdminTab/AdminTab';
import EmailTab from './EmailTab/EmailTab';
import Paragraph from 'components/UI/Paragraph/Paragraph';
import Tab from 'components/UI/Tab/Tab';
import styles from './Tabs.module.scss';


export const findSelectedOption = (id, options) => options.find(({value}) => id === value);

export const paymentTypeOptions = [
  { value: 1, label: 'Фиксированная стоимость'},
  { value: 2, label: 'Стоимость 15 минут'},
];

const FreeTab = () => (
  <Paragraph withBigMargin>
    <b>ВНИМАНИЕ: </b>регистрация слота без резервирования после выбора даты и внесения своих данных
  </Paragraph>
);

const tabsMenu = [
  {id: 1, text: 'Платно', name: 'Pay', component: PaidTab},
  {id: 6, text: 'Платно по локациям', name: 'Location', component: LocationTab},
  /*{id: 2, text: 'Бесплатно (SMS)', name: 'SMS', component: SmsTab},*/
  {id: 3, text: 'Бесплатно (адм.)', name: 'Admin', component: AdminTab},
  {id: 4, text: 'Бесплатно (email)', name: 'Email', component: EmailTab},
  {id: 5, text: 'Бесплатно', name: 'Free', component: FreeTab},
];

function Tabs() {
  const {payment_type: activeTabID} = slotsSettingsStore.paymentsOptions;

  useEffect(() => {
    superAxiosRequest({
      method: 'get',
      link: `groups/groups_catalog`,
    }).then(({data}) => {
      slotsSettingsStore.setEmployees(data.employers.items);
      slotsSettingsStore.setLocations(data.locations.items);
    }).finally(() => slotsSettingsStore.setIsGroupsCatalogLoading(false));
  }, []);

  const Content = tabsMenu.find(x => x.id === activeTabID).component;

  return (
    <div className={styles.wrapper}>
      <ul className={styles.tabs}>{tabsMenu.map(({id, text, name}) =>
        <Tab
          key={id.toString()}
          value={name}
          text={text}
          onClick={() => slotsSettingsStore.setPaymentType(id)}
          isActive={id === activeTabID}
        />)}
      </ul>
      {Content ? <Content/> : null}
    </div>
  )
}

export default observer(Tabs);
