import React, {useEffect, useState} from "react"
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import {dateFormat} from "helpers/date";
import modalStore from "Stores/modalStore";
import st from "./DayTeacherClassroomSelector.module.scss"

export default function DayTeacherClassroomSelector(props){
  const sDate = dateFormat(props.date, "YYYY-mm-dd")

  const {modalValues, changeValues} = modalStore

  const [isLoading, setIsLoading] = useState(false)
  const [teachers, setTeachers] = useState([])
  const [classrooms, setClassrooms] = useState([])

  const onChange = (val, key) => {
    let arr
    if (Array.isArray(modalValues.dates)){
      const searchDate = modalValues.dates.find(d => d.date === sDate)
      if (searchDate){
        arr = modalValues.dates.map(d => d.date === sDate ? {
          ...d,
          ...(key === 'teacher' && {teachers: [val]}),
          ...(key === 'classroom' && {classroom_id: val}),
        } : d)
      } else {
        arr = [...modalValues.dates, {
          date: sDate,
          ...(key === 'teacher' && {teachers: [val]}),
          ...(key === 'classroom' && {classroom_id: val}),
        }]
      }
    } else {
      arr = [{
        date: sDate,
        ...(key === 'teacher' && {teachers: [val]}),
        ...(key === 'classroom' && {classroom_id: val}),
      }]
    }
    changeValues(arr, 'dates')
  }

  useEffect(() => {
    setIsLoading(true)
    superAxiosRequest({
      method: 'post',
      link: 'schedule/available_resources',
    }, {
      group_id: modalValues.group_id,
      start_at: modalValues.start_at,
      finish_at: modalValues.finish_at,
      dates: [sDate],
    }).then(({data}) => {
      setIsLoading(false)
      setTeachers(data.teachers)
      setClassrooms(data.classrooms)
    }).catch(() => {
      setIsLoading(false)
    })
  }, [props.date])

  return (
    <div className="super-modal__form-field" style={{marginTop: '20px'}}>
      <div className="super-modal__form-label">{dateFormat(props.date, "dd.mm.YYYY")}</div>
      <div className={st.selects}>
        <div>
          <SelectCustom
            placeholder={localisationMaster('text_44', 'modal_templates')}
            options={teachers}
            optionLabelKey="full_name"
            sortKey="full_name"
            valueID={modalValues.dates?.find(d => d.date === sDate)?.teachers?.[0] || null}
            onChange={o => {onChange(o.id, 'teacher')}}
            isLoading={isLoading}
            isSearchable
          />
        </div>
        <div>
          <SelectCustom
            placeholder={localisationMaster('text_45', 'modal_templates')}
            options={classrooms}
            sortKey="name"
            onChange={o => {onChange(o.id, 'classroom')}}
            valueID={modalValues.dates?.find(d => d.date === sDate)?.classroom_id || null}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}


