import {useEffect, useRef, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import swal from "sweetalert";

export default function useFileUpload({url = 'ebooks/file_upload', name = 'file', onUploadEnd = () => { }}) {
    const refInput = useRef()

    const [file, setFile] = useState(null)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (file){
            const formData = new FormData()
            const randomName = Date.now()
            const newFileName = `${randomName}.${file.name.split(".").pop()}`
            const newFile = new File([file], newFileName, { type: file.type });
            formData.append(name, newFile)
            const apiConf = {
                method: 'post',
                link: url,
            }
            const axiosConfig = {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                    // do whatever you like with the percentage complete
                    // maybe dispatch an action that will update a progress bar or something
                    setProgress(percentCompleted)
                }
            }
            superAxiosRequest(apiConf, formData, 'media', axiosConfig).then(res => {
                onUploadEnd(res.data.file)
            }).catch((err) => {
                setFile(null)
                swal("", err.response.data.file[0], 'error')
            })
        }
    }, [file])

    return {
        refInput,
        progress,
        file,
        setFile,
    }
};
