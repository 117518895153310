import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../../../../helpers';
import styles from './SlotTypeOptions.module.scss';

function SlotTypeOption({label, value}) {
  const classes = cn({
    [styles.options_value]: true,
    [styles.options_value__checked]: value === true,
    [styles.options_value__unchecked]: value === false,
    [styles.options_value__color]: typeof value === 'string',
  });

  return (
    <li className={styles.options_item} >
      <span className={styles.options_label}>
        {`${label}:`}
      </span>
      <span className={classes} style={{backgroundColor: value}} />
    </li>
  );
}

SlotTypeOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default SlotTypeOption;
