import React, { useEffect, useState } from 'react';
import LocalStorage from 'services/LocalStorage';
import modalStore from 'Stores/modalStore';
import { superAxiosRequest } from 'axiosApi';
import { useHistory } from 'react-router-dom';
import { localisationMaster } from 'Localisation/Localisation';
import { translate as loc, dict } from 'Localisation';
import { checkPermList } from 'helpers';
import { permissions } from 'settings/permissions';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import Loader from 'components/ContentBox/Global/Loader/Loader';
import HomeTasks from 'components/Modules/Groups/components/HomeTasks/HomeTasks';
import TestResults from 'components/Modules/Groups/components/TestResults/TestResults';
import Timetable from './components/Timetable/Timetable';
import WorkshopTimeTable from './components/Workshop/WorkshopTimeTable';
import Reports from './components/Reports/Reports';
import TeachersRatings from './components/TeachersRatings/TeachersRatings';
import style from './ClientGroupInfo.module.scss';
import { ReactComponent as TopUp } from '../../../ContentBox/img/top_up.svg';
import ActiveLessonsGroup from '../TeacherGroup/components/MainInfo/ActiveLessonsGroup/ActiveLessonsGroup';
import ClientMainInfo from './components/ClientMainBox/ClientMainInfo';
import ResponsibleRating from './components/ResponsibleRatings/ResponsibleRating';
import GroupsHoursBalances from './components/GroupsHoursBalances/GroupsHoursBalances';
import useLocalization from 'hooks/useLocalization';
const ClientGroupInfo = (props) => {

  const id = +props.match.params.id;
  const [group, setGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [activeLessons, setActiveLessons] = useState([]);

  const ud = LocalStorage.get('ud').user_data.profile;
  const userGroup = group?.student_info;
  const defaultPrice = group?.student_info?.status?.default_price;
  const groupInfo = group?.group_info;
  const groupschedules = group?.group_info.total_schedule_minutes;
  const menutesLeft = group?.group_info.minutes_left;
  const discountList  = group?.student_info.status.discount_list
  const surchargeList =  group?.student_info.status.surcharge_list
  

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_client',
    dict: {
      "balance_of_the_group":"Top Up The Balance Of The Group",
      "ac_h":"ac.h.",
      "astr_h":"astr.h.",
      "spent":"spent",
      "scheduled":"scheduled",
      "group_balance": "GROUP BALANCE",
      "debt": "DEBT",
      "course_of_study":"Course of Study",
      "group_options":"Group Options",
      "start_of_training":"Start of Training",
      "company_location":"Company & Location",
      "category_and_program": "Category & Program Type",
      "responsible":"Responsible",
      "signed":"Signed",
      "awaiting":"Awaiting",
      "group_prices":"Group Prices",
      "active_dates":"Active Dates",
      "discounts":"Discounts",
      "surcharges":"Surcharges",
      "certificates":"Certificates",
      "certificate_name":"Certificate name",
      "active_from":"Active From",
      "days": "d",
      "education_type": "Білім түрі",
      "year": "ж"
    }
  })


  const addBalance = () => {
    const body = {
      name: 'addPayment',
      link: 'payments/pay_orders',
      button: 'Оплатить',
      title: `${localisationMaster('title', 'pays/create-pay')}: ${
        ud.full_name
      }`,
      type: 'addPayment',
      data: 'json',
      width: '650px',
      client_id: ud.id,
      func: (res) => history.push(`/pays/invoice/${res.order_id}`),
    };
    modalStore.activity(body, { status_sending_mail: 'need_mail', amount: 0 });
  };


  useEffect(() => {
    superAxiosRequest({
      link: `schedule/client_groups/${id}`,
      method: 'get',
    }).then(({ data }) => {
      setGroup(data)
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false);
      if (err.response.status === 404) {
        window.location.pathname = '/404'
      }
    })
  }, [id])

  useEffect(() => {
    let apiActiveGroupLessons = {
      method: 'get',
      link: 'ebooks/active_lessons_group',
      params: {
        group: id,
        today: true,
      }
    }

    superAxiosRequest(apiActiveGroupLessons).then(res => {
      setActiveLessons(res.data.result)
    })
  }, [id])

  return (
    <>
        <ContentHeader
          title= {`${ud.full_name}: ${groupInfo?.group_number || ''} - ${groupInfo?.group_name || ''}`}
          userID = {group?.group_info?.id}
          btnGroupStatus={{
            status: {...group?.group_info?.status},
            onClick: ()=> {},
          }}
        /> 
        
      {isLoading ?
        <Loader />:
        <div className={style.clientGroupInfo}>
          {groupInfo ? (
            <div className={style.clientGroupInfo__container}>
              <div className={style.clientGroupInfo__mainBox}>
                {Date.now() < 0 &&
                  <button className={style.addBalanceBtn} onClick={addBalance}>
                    <TopUp />

                    <span>Top Up The Balance Of The Group</span>
                  </button>}


                {activeLessons.map((item, index) =>
                  <ActiveLessonsGroup
                    item={item}
                    key={index}
                    isClient={true}
                  />)}

                {Date.now() < 0 &&
                  <GroupsHoursBalances groupschedules={groupschedules} menutesLeft={menutesLeft} group={group}/>}

                <ClientMainInfo translate={translate} id={id} userGroup={userGroup} groupInfo={groupInfo} group={group} defaultPrice={defaultPrice} surchargeList={surchargeList} discountList={discountList}/>

              </div>

              {group.group_info.new_schedule ? (
                <WorkshopTimeTable
                  workshops={group.ws_schedule}
                  holidays={group.holidays}
                  teachers={group.teacher_info}
                  student={group.student_info}
                />
              ) : (
                <Timetable userGroup={userGroup} group={group} id={id} />
              )}
              <HomeTasks gid={id} homeworks={group.homeworks} />
              <TestResults
                student={group.student}
                gid={id}
                link={`/groups/tests/${id}`}
                tests={group.test_results}/>
              {Date.now() < 0 &&
                <Reports reports={group.progress_report} id={id} group={group}/>}

              {Date.now() < 0 && checkPermList(permissions.view_own_teacher_report) &&
                <>
                  <TeachersRatings groupID={id} teachers={group.teacher_info} />
                  <ResponsibleRating groupID={id} employees={[group.responsible]} />
                </>}
            </div>
          ) : (
            <p className="clientGroupInfo__nogroup-text">
              {loc(dict.group.text_6)}!
            </p>
          )}
        </div>}
    </>
  )
}

export default ClientGroupInfo;
