import React from 'react';
import styles from "./MyLessonsEmpty.module.scss";
import testsNotFound from "./img/my-lessons-empty.svg";

const MyLessonsEmpty = (props) => {
  const {translate} = props;
  return (
    <div className={styles.emptyLessonsBlock}>
      <img className={styles.emptyLessonsImg} src={testsNotFound} alt="Lessons are empty"/>
      <p className={styles.text}>{translate}.</p>
    </div>
  );
};

export default MyLessonsEmpty;