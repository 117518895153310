import React, {useEffect, useState} from 'react'
import styles from "./Birthday.module.scss"
import BirthdayCalendar from './components/BirthdayCalendar/BirthdayCalendar'
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import Filter from 'components/ContentBox/Global/Filter/Filter'
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import BirthdayInfo from './components/BirthdayInfo/BirthdayInfo';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import {ExcelRowsParams} from "../../../utils/ExcelExport";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {localisationMaster} from "Localisation/Localisation";



const getFirstMonthDay = (date) => new Date(new Date(date).setDate(1))
const getLastMonthDay = (date) => new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(12, 0, 0, 0));


export const get_current_age = date => {
    return new Date().getFullYear() - date.getFullYear()
};
export const ageToStr = (birthday) => {
    let dob = new Date(birthday);
    let age = get_current_age(dob);
    let txt;
    let count = age % 100;
    if (count >= 5 && count <= 20) {
        txt = localisationMaster('text-6', 'online-schedule/future-slots');
    } else {
        count = count % 10;
        if (count === 1) {
            txt = localisationMaster('text-4', 'online-schedule/future-slots');
        } else if (count >= 2 && count <= 4) {
            txt = localisationMaster('text-5', 'online-schedule/future-slots');
        } else {
            txt = localisationMaster('text-6', 'online-schedule/future-slots');
        }
    }
    return age + " " + txt;
}

const Birthday = () => {
    const today = new Date(new Date().setHours(12, 0, 0, 0))
    const [selectedDay, setSelectedDay] = useState()
    const [pageSate, setPageSate] = useState({})
    const [datesRange, setDatesRange] = useState({
        firstMonthDay: getFirstMonthDay(today),
        lastMonthDay: getLastMonthDay(today)
    })
    const [filterValue, setFilterValue] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            placeholder: 'Компания',
            values: pageSate?.companies,
            sort_key: 'name',
            nameState: 'company',
        },
        {
            type: 'select-custom',
            placeholder: 'Локация',
            values: pageSate?.locations,
            sort_key: 'name',
            nameState: 'location',
        },
    ]

    const getData = (params) => {
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/birthday',
            params: {
                ...params,
                date_from: dateFormat(params.date_from, "YYYY-mm-dd"),
                date_until: dateFormat(params.date_until, "YYYY-mm-dd"),
            }
        })
            .then((res) => {
                setPageSate(res.data)
            })
    }

    const downloadExcel = data => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Дата",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Ф.И.О.",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Возраст",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Дата рождения",
                    ...ExcelRowsParams.headerRowParam
                }
            ]
            let tableData = []
            const merges = []
            let currentRow = 1
            data.data.forEach(item => {
                let date = new Date(new Date(data.date).setDate(item.date))
                if (item.employees.length > 1) merges.push({
                    s: {r: currentRow, c: 0},
                    e: {r: currentRow + (item.employees.length - 1) , c: 0},
                })
                item.employees.map(employe => {
                    currentRow ++ 
                    let rowData = [
                        {
                            v: `${timeConverter(date, 'day')}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${employe.full_name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${ageToStr(employe.date)}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${timeConverter(employe.date, 'age')}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ]
                    tableData.push(rowData)
                })
            })
            const cols = [
                {wch: 20},
                {wch: 50},
                {wch: 30},
                {wch: 30},
            ]
            const rows = [
                {hpx: 30},
            ]
            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
            ws['!cols'] = cols
            ws['!rows'] = rows
            ws["!merges"] = merges

            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, `Дни рождения ${timeConverter(datesRange.firstMonthDay, 'day')} - ${timeConverter(datesRange.lastMonthDay, 'day')}.xlsx`);
        })
    }

    useEffect(() => {
        getData({
            date_from: datesRange.firstMonthDay,
            date_until: datesRange.lastMonthDay,
            ...filterValue
        })
    }, [datesRange, filterValue])


    return (
        <div>
            <ContentHeader title="Дни рождения" />
            <Filter config={filterCnf} getFilterParams={(e) => {
                let filterField = {...e}
                delete filterField.is_active
                setFilterValue(filterField)
            }} drop={() => {setFilterValue({})}} />
            <div className={styles.wrapper}>
                <div style={{padding: '0 0 10px'}}>
                    <ExportExcelButton data={{data: pageSate.result, date: datesRange.firstMonthDay}} downloadExcel={downloadExcel} />
                </div>
                <BirthdayCalendar
                    calendarData={pageSate.result}
                    setSelectedDay={(data) => setSelectedDay(data)}
                    setDatesRange={(date) => {
                        setDatesRange({
                            firstMonthDay: getFirstMonthDay(date),
                            lastMonthDay: getLastMonthDay(date)
                        })
                    }}
                    datesRange={datesRange}
                />
                {selectedDay && <BirthdayInfo {...selectedDay} />}
            </div>
        </div>
    )
}

export default Birthday
