import React, {useState, useEffect} from 'react'
import {superAxiosRequest} from "axiosApi";
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import Checkbox from "components/ContentBox/Global/Checkbox";
import SelectDateBirth from "components/ContentBox/Global/SelectDateBirth/SelectDateBirth";
import InputPhone from "components/ContentBox/Global/InputPhone/InputPhone";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import MainSelector from "./MainSelector";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import st from "./AddUser.module.scss";
import Logo from "components/ContentBox/img/building.svg";

let apiConf;
const Link = "company/profiles";
const Tag = "add_user";

const AddUser = () => {
  const genderList = [
    { id: "M", name: localisationMaster("gender-1", Link, Tag) },
    { id: "F", name: localisationMaster("gender-2", Link, Tag) },
  ];

  const { data: ms, modalValue, changeValues, check } = modalStore;

  const [roleList, setroleList] = useState([{ id: 0, name: "Выберите роль" }]);

  useEffect(() => {
    // Роли
    apiConf = {
      method: "get",
      link: "accounts/roles",
    };
    superAxiosRequest(apiConf, "data")
      .then((response) => {
        setroleList(response.data);
      })
      .catch(() => {});

    changeValues("12312300", "password");
    changeValues(true, "checked");
  }, [changeValues]);

  useEffect(() => {
    ms.link =
      "accounts/employers/" +
      roleList.find((x) => x.id === modalValue.role)?.name;
  }, [modalValue.role, ms.link, roleList]);

  return (
    <div className={`super-modal__form-column ${st.form}`}>
      <div className={st.mainSelectorWrapper}>
        <MainSelector image={Logo}>
          <div className={st.mainSelector}>
            <div>
              <SelectCustom
                placeholder="Компания"
                apiLink="db/companies"
                valueID={modalValue.company}
                onChange={(o) => changeValues(o.id, "company")}
                error={check("company")}
              />
            </div>
            <div>
              <SelectCustom
                placeholder="Локация"
                apiLink="db/locations"
                valueID={modalValue.location}
                onChange={(o) => changeValues(o.id, "location")}
                error={check("location")}
              />
            </div>
          </div>
        </MainSelector>
      </div>

      <div className={`super-modal__form-row2 ${st.row}`}>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Роль:</div>
          <SelectCustom
            placeholder="Роль"
            options={roleList}
            valueID={modalValue.role}
            onChange={(o) => changeValues(o.id, "role")}
            error={check("role")}
          />
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Пол:</div>
          <SelectCustom
            placeholder="Выберите пол"
            options={genderList}
            valueID={modalValue.sex}
            onChange={(o) => changeValues(o.id, "sex")}
            error={check("sex")}
          />
        </div>
      </div>

      <div className={`super-modal__form-row2 ${st.row}`}>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Фамилия:</div>
          <input
            placeholder={localisationMaster("input-placeholder-2", Link, Tag)}
            value={modalValue.last_name}
            onChange={(e) => changeValues(e.target.value, "last_name")}
            className={check("last_name") ? "super-modal__input--error" : ""}
          />
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Имя:</div>
          <input
            placeholder={localisationMaster("input-placeholder-1", Link, Tag)}
            value={modalValue.name}
            onChange={(e) => changeValues(e.target.value, "first_name")}
            className={check("first_name") ? "super-modal__input--error" : ""}
          />
        </div>
      </div>
      <div className={`super-modal__form-row2 ${st.row}`}>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Отчество:</div>
          <input
            placeholder={localisationMaster("input-placeholder-3", Link, Tag)}
            value={modalValue.father_name}
            onChange={(e) => changeValues(e.target.value, "father_name")}
          />
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Дата рождения:</div>
          <div>
            <SelectDateBirth
              value={modalValue.date_of_birth}
              onChange={(val) => changeValues(val, "date_of_birth")}
              error={check("date_of_birth")}
            />
          </div>
        </div>
      </div>
      <div className={`super-modal__form-row2 ${st.row}`}>
        <div className="super-modal__form-field">
          <p className="super-modal__form-label">Телефон:</p>
          <div>
            <InputFlagPhone
              value={modalValue?.mobile_phone || ""}
              onChange={(val) => changeValues(val, "mobile_phone")}
              error={check("mobile_phone")}
            />
          </div>
        </div>
        <div className="super-modal__form-field">
          <p className="super-modal__form-label">E-mail:</p>
          <input
            placeholder={localisationMaster("input-placeholder-4", Link, Tag)}
            value={modalValue.email}
            onChange={(e) =>
              changeValues(e.target.value.toLowerCase(), "email")
            }
            className={check("email") ? "super-modal__input--error" : ""}
          />
        </div>
      </div>
      <div>
        <Checkbox
          label={localisationMaster("checkbox-info", Link, Tag)}
          checked={modalValue.checked}
          isChecked={() => changeValues(!modalValue.checked, "checked")}
        />
      </div>
      <div>
        <textarea
          placeholder="Комментарий "
          value={modalValue.comment}
          onChange={(e) => changeValues(e.target.value, "comment")}
        />
      </div>
    </div>
  );
};

export default observer(AddUser);
