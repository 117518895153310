import React, {useState, useEffect} from 'react';
import './InputInn.scss';

export default function InputInn(props) {
    const list = [
        {id: 1, name: 'ИИН'},
        {id: 2, name: 'ИНН'},
    ]

    const [type, setType] = useState(0)
    const [number, setNumber] = useState('')
    const [innClass, setInnClass] = useState(false);

    const changeTypeHandler = function (e) {
        setType(parseInt(e.target.value))
        setNumber('');
    }

    const changeHandler = (value) =>{
        if (value.length<1)
            setNumber('');

        if(type === 1 && !isNaN(value) && value.length < 13)
            setNumber(value);

        if(type === 2 && !isNaN(value) && value.length < 13)
            setNumber(value)

        if(type === 0 && !isNaN(value) && value.length < 13)
            setNumber(value)
    }

    useEffect(()=>{
        if(number.length > 0)props.onChange(number)
    }, [number])


    return (
       <div className={!innClass ? "input-inn": "input-innNew"} style={{borderColor: props.error ? '#db3031' : ''}}
       >
            <select onChange={e=>changeTypeHandler(e)} value={type} disabled={props.disabled && true} className={"input-inn__select " + (type === 0 ? "input-inn__select--empty" : "")}>
                <option value={list[1].id} disabled>ИНН</option>
                {list.map((item, index)=>{
                    return(
                        <option value={item.id} key={index}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
            <input maxLength='12' disabled={props.disabled && true} onFocus={()=>setInnClass(true)} onBlur={()=>setInnClass(false)} defaultValue={props.value} onChange={e=>changeHandler(e.target.value)} />
        </div>
    )
}
