import React from 'react';
import st from "./ResultSection.module.scss";

export default function ResultSection(props) {
    const section = props.section

    return (
        <div className={st.resultSection}>
            <header>
                <span>{section.name}</span>
                <div className={st.points}>
                    <span>{section.correct_answers}</span>
                    <span>/</span>
                    <span>{section.percentage}%</span>
                </div>
            </header>
            <footer>
                <div className={st.points}>
                    <span>{section.points}</span>
                </div>
            </footer>
        </div>
    );
};
