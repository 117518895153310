import React from 'react';
import InputsWrapper from '../../../../UI/InputsWrapper/InputsWrapper';
import SectionTitle from '../../../../UI/SectionTitle/SectionTitle';
import ContactFormToggle from '../components/ContactFormToggle/ContactFormToggle';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import { observer } from 'mobx-react';

const toggleLabels = {
  add_field1: 'Дополнительное поле',
  add_field2: 'Дополнительное поле',
  add_field3: 'Дополнительное поле',
  birthday: 'Дата рождения',
  email: 'E-mail',
  father_name: 'Отчество',
  first_name: 'Имя',
  inn: 'ИИН / ИНН',
  last_name: 'Фамилия',
  level: 'Уровень',
  phone: 'Телефон',
  sex: 'Пол',
};

const ContactFormTogglesSection = () => {
  const { registrationToggles } = slotsSettingsStore;

  return (
    <>
      <SectionTitle
        tag="h3"
        variant="secondary"
        title="Поля контактной формы"
        description="Настройка полей для сбора информации о клиенте при его регистрации на слот"
      />
      <InputsWrapper>
        {
          registrationToggles.map(([name, data], index) => (
            <ContactFormToggle
              key={index.toString()}
              name={name}
              fixedLabel={toggleLabels[name]}
              label={data.name}
              mandatory={data.mandatory}
              active={data.active}
              type={data.type}
            />
          ))
        }
      </InputsWrapper>
    </>
  );
};

export default observer(ContactFormTogglesSection);
