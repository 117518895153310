import React from 'react';
import PropTypes from 'prop-types';
import { clientDataTypes } from './ClientItem';
import {localisationMaster} from "Localisation/Localisation";
import styles from './ClientItem.module.scss';

const getLocationPostfix = (isOnline, isSchool) => {
  if (isOnline && !isSchool) {
    return localisationMaster('text_9', 'teacher_schedule')
  }
  if (!isOnline && isSchool) {
    return localisationMaster('text_3', 'slots_listing')
  }
  if (isOnline && isSchool) {
    return localisationMaster('text_4', 'slots_listing')
  }
};

function ClientBody({ data }) {
  const { employee_slot, zoom_url } = data;

  return (
    <dl className={styles.client_details}>
      <dt>{localisationMaster('text_5', 'slots_listing')}</dt>
      <dd>{employee_slot.location} ({getLocationPostfix(employee_slot.is_online, employee_slot.is_school)})</dd>
      {employee_slot.is_school &&
      <>
        <dt>{localisationMaster('text_24', 'company/locations')}</dt>
        <dd>{employee_slot.location_address || localisationMaster('button_2', 'alert').toLowerCase()}</dd>
        <dt>{localisationMaster('text_15', 'modal_templates')}</dt>
        <dd>
          {employee_slot.location_phone.map((tel, index) => (
            <p key={index}><a href={`tel:${tel.replace(/\s/g, '')}`}>{tel}</a></p>
          ))}
          <p><a href={`mailto:${employee_slot.location_email}`}>{employee_slot.location_email}</a></p>
        </dd>
      </>
      }
      {employee_slot.is_online && zoom_url &&
      <>
        <dt>{localisationMaster('zoom_link', 'teacher_schedule')}</dt>
        <dd>
          <a className={styles.client_details_link} href={zoom_url} target="_blank" rel="noopener noreferrer">
            {zoom_url}
          </a>
        </dd>
      </>
      }
    </dl>
  );
}

ClientBody.propTypes = {
  data: PropTypes.shape(clientDataTypes).isRequired,
};

export default ClientBody;
