import React, { useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {NavLink} from 'react-router-dom';
import {ExcelRowsParams} from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";


const NewStudents = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();
    const [groupCatalog, setGroupCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: groupCatalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },
        {
            type: 'days-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 2, name: "Все группы"},
                {id: 1, name: "Онлайн"},
                {id: 0, name: "Офлайн"},
            ]
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень курса',
            values: groupCatalog?.course_levels,
            sort_key: 'name',
            nameState: 'course_level'
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: groupCatalog?.skill_levels,
            sort_key: 'name',
            nameState: 'skill_level'
        },



    ]

    const cols = [
        {
            name: 'Ф.И.О. студента',
        },
        {name: 'Школа', rowSpan: 3},
        {name: 'Группа', rowSpan: 3},
    ];


    const parseData = (res) => {
        let clientsList = []
        res.data.result.forEach((item => {
            let newItem = {
                student: {
                    id: item.id,
                    user_id: item.user_id,
                    full_name: item.full_name
                },
                group: {
                    id: item.group.id,
                    name: item.group.name
                },
                location_name: res.data.locations.find(loc => loc.id === item.group.location).name 
            }
            clientsList.push(newItem)
        }))
        return clientsList
    }

    const sendRequest = (params) => {
            setIsLoading(true)
            let apiConf = {
                method: 'get',
                params: {
                    ...params
                }
            }
            if (apiConf.params.skill_level && apiConf.params.skill_level.length > 0) apiConf.params.skill_level = `[${apiConf.params.skill_level.map(item => item)}]`
            if (apiConf.params.course_level && apiConf.params.course_level.length > 0) apiConf.params.course_level = `[${apiConf.params.course_level.map(item => item)}]`
            if (apiConf.params.location && apiConf.params.location.length > 0) apiConf.params.location = `[${apiConf.params.location.map(item => item)}]`
            if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
            if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")
            superAxiosRequest({...apiConf, link: 'progress_report/new_clients_full'}).then(res => {
                setData([...parseData(res)] )
                setIsLoading(false)
            })
    }
    const getData = () => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/new_clients_full',
            params: {
                date_from: dateFormat(new Date(new Date().setDate(1)), "YYYY-mm-dd")
            }
        })
            .then((res) => {
                setGroupCatalog(res.data)
                setData(parseData(res))
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Ф.И.О. студента",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Школа",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Группа",
                    ...ExcelRowsParams.headerRowParam
                }
            ]
            let footerRow = [
                {
                    v: `ИТОГО НОВЫХ СТУДЕНТОВ`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: `${data.length}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ]
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 40},
                {wch: 30},
                {wch: 50},
            ];
            let rows = [
                {hpx: 30},
            ]

            data.forEach(studentItem => {
                tableData.push([
                    {
                        v: `${studentItem.student.full_name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${studentItem.location_name}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${studentItem.group.name}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Новые студенты.xlsx");
        })
    }

    return (
        <div>
            <ContentHeader title="Новые студенты" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => getData()} />

            {isLoading
                ? <Loader />
                : Array.isArray(data) && data.map(el => el.dates).flat().length === 0 ? <h1 className={st.noData}>Нет данных для отображения!</h1>
                    : (
                        <>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            <>
                                              <tr className={st.bdt}>
                                                    <td className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>
                                                        Итого новых студентов
                                                </td>
                                                    <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                                                        {data.length}
                                                    </td>
                                                    <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}/>
                                                </tr>
                                                {data.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={st.fw600} style={{background: "white"}}>
                                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                                <NavLink to={'/account_clients/' + row.student.user_id} target="_blank">
                                                                    {row.student.full_name}
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                                {row.location_name}
                                                            </div>
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                <NavLink to={'/groups/group/' + row.group.id} target="_blank">
                                                                    {row.group.name}
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                              
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{padding: '10px'}}>
                                <ExportExcelButton data={data} downloadExcel={downloadExcel}/>
                            </div>
                        </>
                    )}
        </div >
    )
}

export default NewStudents