import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {superAxiosRequest} from "axiosApi";
import OpenHeader from "../OpenHeader";
import HeaderContentBox from "components/Modules/Groups/components/HeaderContentBox";
import EbookHomework from "./EbookHomework";
import Loader from "components/UI/Loader/Loader";
import st from "./EbookHomeworks.module.scss";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";

const EbookHomeworks = ({groupID}) => {
  const [open, setOpen] = useState(false)
  const [homeworks, setHomeworks] = useState(null)

  const refSlider = useRef(null)
  const refSlide = useRef(null)

  const fetchHomeworks = useCallback(() => {
    superAxiosRequest({
      method: 'get',
      link: 'groups/ebook_homeworks',
      params: {
        group_id: groupID
      }
    }).then(res => {
      setHomeworks(res.data)
    }).catch(() => {

    })
  }, [groupID])

  useEffect(() => {
    const $slide = refSlide.current
    const resizeObserver = new ResizeObserver(entries => {
      setTimeout(() => {
        refSlider.current.style.height = open ? entries[0].contentRect.height + 'px' : 0
      }, 0)
    })
    resizeObserver.observe($slide)

    return () => {
      resizeObserver.unobserve($slide)
    }
  }, [open])

  useEffect(() => {
    if (!open) return
    fetchHomeworks()
  }, [fetchHomeworks, open]);

  return (
    <HeaderContentBox>
      <OpenHeader title="Homework" onClick={() => {
        setOpen(!open)
        !open && refSlider.current.scrollIntoView({behavior: 'smooth'})
      }}/>
      <div ref={refSlider} className={st.slider}>
        <div ref={refSlide}>
          <>{Array.isArray(homeworks) ?
            homeworks.length > 0 ?
              <div className={st.list}>{homeworks.map(hw =>
                <EbookHomework key={hw.id} groupID={groupID} homework={hw} fetchHomeworks={fetchHomeworks}/>)}
              </div> :
              <div className={st.empty}>
                <EmptyData/>
              </div> :
            <Loader/>}
          </>
        </div>
      </div>
    </HeaderContentBox>
  )
}

EbookHomeworks.propTypes = {
  groupID: PropTypes.number
}

export default EbookHomeworks;
