import React from 'react';
import { observer } from 'mobx-react';
import Select from 'components/UI/Select/Select';
import styles from './SortingBlock.module.scss';
import slotsListingStore, {sortingOptions} from 'Stores/slotsListingStore';
import {localisationMaster} from "Localisation/Localisation";

function SortingBlock() {
  const {totalClientsCount, sortingType, setSortingType} = slotsListingStore;

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{localisationMaster('text_22', 'online-schedule/future-slots')}: </p>
      <Select
        value={sortingOptions.find(({value}) => value === sortingType)}
        options={sortingOptions}
        placeholder={`${localisationMaster('sorting', 'slots_listing')}...`}
        classes={styles.select}
        onChange={(option) => setSortingType(option)}
      />
      <p className={styles.count}>
        {localisationMaster('text_23', 'online-schedule/future-slots')}: {totalClientsCount}
      </p>
    </div>
  );
}

export default observer(SortingBlock);
