import React, { useState, useEffect, Fragment } from "react";
import ContentHeader from "../ContentHeader";
import THead from "./THead";
import Filter from "../Filter/Filter";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import { superAxiosRequest } from "axiosApi";
import { Link } from "react-router-dom";
import Loader from "components/ContentBox/Global/Loader/Loader";
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import st from "./Table.module.scss";
import ExportExcelButton from "../../../Modules/Reports/ExportExcelButton/ExportExcelButton";
import {ExcelRowsParams} from "components/utils/ExcelExport";

const TeacherStatistics = () => {
  const [groupCatalog, setGroupCatalog] = useState({});
  const {
    refWrapper,
    refSticky,
    refThead,
    data,
    setData,
    isLoading,
    setIsLoading,
  } = useTableFixedHeader();
  const dateAfter = new Date();
  dateAfter.setMonth(dateAfter.getMonth() - 1);
  const firstDayOfLastMoth = new Date ((new Date(dateAfter)).setDate(1))
  const lastDayOfLastMoth = new Date ((new Date()).setDate(0))

  const cfg = {
    cols: [
      { name: localisationMaster('filter_text_1', 'reports/teacher-statistics')},
      { name: "Учебные группы" },
      { name: "День" },
      { name: "Кол-во студентов" },
      { name: "Ак. ч." },
      { name: localisationMaster('text_1', 'reports/teacher-statistics') },
      { name: `${localisationMaster('text_2', 'reports/teacher-statistics')}, тг.` },
    ],
  };

  const filterCfg = [
    {
      type: "select-custom",
      placeholder: localisationMaster('name_9', 'tests/widget-clients'),
      sort_key: "name",
      nameState: "location",
      values: groupCatalog.locations?.items,
    },
    {
      type: "select-custom",
      placeholder: localisationMaster('filter_text_1', 'reports/teacher-statistics'),
      values: groupCatalog.teachers?.items,
      option_label_key: "full_name",
      sort_key: "full_name",
      nameState: "teacher",
      is_search: true,
    },
    {
      type: "days-range-picker",
      nameState: ["date_from", "date_until"],
      format: "dd MMMM, yyyy",
      defaultDate: {
        after: firstDayOfLastMoth ,
        before: lastDayOfLastMoth,
      },
    },
    {
      type: "select-custom",
      placeholder: localisationMaster('text_10', 'groups/all-groups'),
      nameState: "group_type",
      values: groupCatalog.types?.items,
    },
    {
      type: "select-custom",
      placeholder: localisationMaster('text_30', 'groups/all-groups'),
      sort_key: "name",
      nameState: "discipline",
      values: groupCatalog.disciplines?.items,
    },
    {
      type: "select-custom",
      placeholder: localisationMaster('text_7', 'lessons/course'),
      sort_key: "name",
      nameState: "course_level",
      values: groupCatalog.course_levels?.items,
    },
    {
      type: "select-custom",
      placeholder: localisationMaster('text_7', 'groups/all-groups'),
      nameState: "group_level",
      values: groupCatalog.skill_levels?.items,
    },
    {
      type: "type-selector",
      nameState: "online",
      values: [
        { id: 1, name: localisationMaster('text_11', 'company/locations') },
        { id: 2, name: localisationMaster('text_12', 'company/locations') },
      ],
    },
  ];

  const formatHours = (val) => val.toFixed(2).replace(".", ",");

  const { cleanSumm } = Stores;

  const downloadExcel = data => {
    import('xlsx-js-style').then(XLSX => {
      const headRow = cfg.cols.map(col => ({
        v: col.name,
        ...ExcelRowsParams.headerRowParam
      }))

      const merges = []
      const tableData = []
      let currentRow = 1
      let currentGroup = 1
      data.forEach(row => {
        row.groups.forEach(group => {
          const countGroupMonths = group.months.length
          const countGroupDays = group.months.map(m => m.days.map(d => d)).flat().length
          group.months.forEach(month => {
            // Дни
            month.days.forEach(day => {
              tableData.push([
                {
                  v: row.name,
                  ...ExcelRowsParams.tableRowParam.firstCell,
                },
                {
                  v: `${group.number} ${group.name}\n${group.location}\n${localisationMaster('text_3', 'reports/teacher-statistics')}:\n${group.default_price.name}`,
                  ...ExcelRowsParams.tableRowParam.firstCell,
                },
                {
                  v: day.name,
                  ...ExcelRowsParams.tableRowParam.cell,
                },
                {
                  v: day.students_count,
                  ...ExcelRowsParams.tableRowParam.cell,
                },
                {
                  v: formatHours(day.hours),
                  ...ExcelRowsParams.tableRowParam.cell,
                },
                {
                  v: day.status,
                  ...ExcelRowsParams.tableRowParam.firstCell,
                },
                {
                  v: `${cleanSumm(day.charge / 100)} тг.`,
                  ...ExcelRowsParams.tableRowParam.cell,
                },
              ])
            })
            // Итого за месяц
            tableData.push([
              {
                v: '',
                ...ExcelRowsParams.tableRowParam.cell,
              },
              {
                v: '',
                ...ExcelRowsParams.tableRowParam.cell,
              },
              {
                v: month.name,
                ...ExcelRowsParams.footerRowParams.firstCell,
              },
              {
                v: '',
                ...ExcelRowsParams.footerRowParams.cell,
              },
              {
                v: formatHours(month.total.hours),
                ...ExcelRowsParams.footerRowParams.cell,
              },
              {
                v: '',
                ...ExcelRowsParams.footerRowParams.cell,
              },
              {
                v: `${cleanSumm(month.total.charges / 100)} тг.`,
                ...ExcelRowsParams.footerRowParams.cell,
              },
            ])
          })
          // Итого по группе
          tableData.push([
            {
              v: '',
              ...ExcelRowsParams.tableRowParam.cell,
            },
            {
              v: group.name,
              ...ExcelRowsParams.footerRowParams.firstCell,
            },
            {
              v: '',
              ...ExcelRowsParams.footerRowParams.cell,
            },
            {
              v: '',
              ...ExcelRowsParams.footerRowParams.cell,
            },
            {
              v: formatHours(group.total.hours),
              ...ExcelRowsParams.footerRowParams.cell,
            },
            {
              v: '',
              ...ExcelRowsParams.footerRowParams.cell,
            },
            {
              v: `${cleanSumm(group.total.charges / 100)} тг.`,
              ...ExcelRowsParams.footerRowParams.cell,
            },
          ])
          // Объединение групп
          merges.push({
            s: {r: currentGroup, c: 1},
            e: {r: currentGroup + countGroupDays + countGroupMonths - 1, c: 1},
          })
          currentGroup = currentGroup + countGroupDays + countGroupMonths + 2
        })
        // Итого по учителю
        tableData.push([
          {
            v: 'Итого',
            ...ExcelRowsParams.footerRowParams.firstCell,
          },
          {
            v: '',
            ...ExcelRowsParams.footerRowParams.cell,
          },
          {
            v: '',
            ...ExcelRowsParams.footerRowParams.cell,
          },
          {
            v: '',
            ...ExcelRowsParams.footerRowParams.cell,
          },
          {
            v: formatHours(row.total.hours),
            ...ExcelRowsParams.footerRowParams.cell,
          },
          {
            v: '',
            ...ExcelRowsParams.footerRowParams.cell,
          },
          {
            v: `${cleanSumm(row.total.charges / 100)} тг.`,
            ...ExcelRowsParams.footerRowParams.cell,
          },
        ])
        // Объединение учителей
        const countRowGroups = row.groups.length
        const countRowMonths = row.groups.map(gr => gr.months.map(m => m)).flat().length
        const countRowDays = row.groups.map(gr => gr.months.map(m => m.days.map(d => d))).flat(2).length

        merges.push({
          s: {r: currentRow, c: 0},
          e: {r: currentRow + countRowDays + countRowMonths + countRowGroups - 1, c: 0},
        })
        currentRow = currentRow + countRowDays + countRowMonths + countRowGroups + 1
      })

      const cols = [
        {wch: 40},
        {wch: 30},
        {wch: 50},
        {wch: 21},
        {wch: 10},
        {wch: 60},
        {wch: 20},
      ]
      const rows = [
        {hpx: 30},
      ]

      let wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
      ws['!cols'] = cols
      ws['!rows'] = rows
      ws["!merges"] = merges

      XLSX.utils.book_append_sheet(wb, ws, "List 1");

      XLSX.writeFile(wb, "Статистика по преподавателям.xlsx");
    })
  }

  useEffect(() => {
    const apiConf = {
      method: "get",
      link: "groups/groups_catalog",
    };
    superAxiosRequest(apiConf).then((res) => {
      setGroupCatalog(res.data);
    });
  }, [])


  const getFilteredData = (filter) => {
      setIsLoading(true);
      const apiConf = {
        method: "get",
        link: "ledger/teacher_salary_operations_report",
        params: {
          ...filter,
          date_from: timeConverter(
            filter.date_from ? filter.date_from : firstDayOfLastMoth,
            "today"
          ),
          date_until: timeConverter(
            filter.date_until ? filter.date_until : lastDayOfLastMoth,
            "today"
          ),
        },
      };
      superAxiosRequest(apiConf).then(res => {
        setData(res.data);
        setIsLoading(false);
      });
  }

  return (
    <>
      <ContentHeader title={localisationMaster('title', 'reports/teacher-statistics')} />
      <Filter
        config={filterCfg}
        drop={() => setData([])}
        getFilterParams={getFilteredData}
      />
      {isLoading ?
        <Loader /> :
        !data || !data.length ? (
        <h3 style={{ textAlign: "center" }}>
          {localisationMaster('warning', 'c/tests/placement-test/create-widget')}
        </h3>
      ) : (
        <>
          <div className={st.tableWrapper} ref={refWrapper}>
            <div ref={refSticky} className={st.sticky}>
              <table className={st.table}>
                <THead cols={cfg.cols} />
              </table>
            </div>
            <table className={`${st.table} ${st.tbodyStriped}`}>
              <THead cols={cfg.cols} ref={refThead} />
              <tbody className={st.tbody}>
              {Array.isArray(data) &&
                data.map((row, rowIdx) => {
                  const months = row.groups.flat().map(group => group.months).flat();
                  const days = months.map(month => month.days).flat();
                  return (
                    <Fragment key={rowIdx}>
                      {row.groups.map((group, idxGroup) => {
                        const groupMonths = group.months.flat().map(month => month.days).flat();
                        return (
                          <Fragment key={idxGroup}>
                            {group.months.map((month, idxMonth) => {
                              return (
                                <Fragment key={idxMonth}>
                                  {month.days.map((day, idxDay) => (
                                    <tr key={idxDay} className={st.bdt}>
                                      {idxGroup === 0 &&
                                      idxMonth === 0 &&
                                      idxDay === 0 ? (
                                        <td rowSpan={days.length + row.groups.length + months.length}
                                            style={{ background: "white" }}>
                                          <Link to={`/account/${row.id}`} target="_blank">
                                            {row.name}
                                          </Link>
                                        </td>
                                      ) : null}
                                      {idxMonth === 0 && idxDay === 0 ? (
                                        <td rowSpan={groupMonths.length + group.months.length}>
                                          <Link to={`/groups/group/${group.id}`}
                                                target="_blank"
                                                className={`${st.groupDescription} ${st.bottom}`}>
                                            {group.number} {group.name}
                                          </Link>
                                          <div className={`${st.groupDescription} ${st.bottom}`}>
                                            {group.location}
                                          </div>
                                          <div className={`${st.groupDescription} ${st.medium}`}>
                                            {localisationMaster('text_3', 'reports/teacher-statistics')}:
                                          </div>
                                          <div className={`${st.groupDescription} ${st.bottom}`}>
                                            {group.default_price.name}
                                          </div>
                                          <div className={`${st.groupDescription} ${st.bottom}`}>
                                            {`(${cleanSumm(group.default_price.price_sum)} ${localisationMaster('text_5', 'reports/teacher-statistics')} ${group.default_price.hours_sum} а.ч.)`}
                                          </div>
                                          <div className={`${st.groupDescription} ${st.medium}`}>
                                            {localisationMaster('text_4', 'reports/teacher-statistics')}:
                                          </div>
                                          <div className={st.groupDescription}>
                                            {`Offline: ${cleanSumm(group.teacher_rate.rate[0])} 
                                              - 
                                              ${cleanSumm(group.teacher_rate.rate[group.teacher_rate.rate.length - 1])} / ${localisationMaster('ac_h', 'groups/group')}`}
                                          </div>
                                          <div className={st.groupDescription}>
                                            {`Online: 
                                              ${cleanSumm(group.teacher_rate.rate_online[0])} 
                                              - 
                                              ${cleanSumm(group.teacher_rate.rate_online[group.teacher_rate.rate_online.length - 1])} / ${localisationMaster('ac_h', 'groups/group')}`}
                                          </div>
                                        </td>
                                      ) : null}
                                      <td className={`${st.fz12} ${st.tdMiddle}`} style={{ width: "120px" }}>
                                        {day.name}
                                      </td>
                                      <td className={`${st.fz12} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                        {day.students_count}
                                      </td>
                                      <td className={`${st.fz12} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                        {formatHours(day.hours)}
                                      </td>
                                      <td className={`${st.fz12} ${st.tdMiddle}`}>{day.status}</td>
                                      <td className={`${st.fz12} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                        {cleanSumm(day.charge / 100)} тг.
                                      </td>
                                    </tr>
                                  ))}
                                  <tr className={st.bdt}>
                                    <td className={`${st.tdTotal} ${st.tdMiddle}`}>{month.name}</td>
                                    <td className={`${st.tdTotal} ${st.tdMiddle}`}/>
                                    <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                      {formatHours(month.total.hours)}
                                    </td>
                                    <td className={st.tdTotal}/>
                                    <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                      {cleanSumm(month.total.charges / 100)}
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                            <tr className={st.bdt}>
                              <td className={`${st.tdTotal} ${st.tdMiddle}`} colSpan={2}>{group.name}</td>
                              <td className={`${st.tdTotal} ${st.tdMiddle}`}/>
                              <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                {formatHours(group.total.hours)}
                              </td>
                              <td className={st.tdTotal}/>
                              <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                {cleanSumm(group.total.charges / 100)}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                      <tr className={st.bdt}>
                        <td className={`${st.tdTotal} ${st.tdMiddle}`} colSpan={3}>
                          {localisationMaster("total", "reports/groups-money")}
                        </td>
                        <td className={`${st.tdTotal} ${st.tdMiddle}`}/>
                        <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                          {formatHours(row.total.hours)}
                        </td>
                        <td className={st.tdTotal}/>
                        <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                          {cleanSumm(row.total.charges / 100)}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{padding: '10px'}}>
            <ExportExcelButton data={data} downloadExcel={downloadExcel}/>
          </div>
        </>
      )}
    </>
  );
};

export default TeacherStatistics;
