import React from 'react';
import CalendarWrapper from "components/Modules/Slots/components/CalendarWrapper/CalendarWrapper";
import MainSelectors from "components/Modules/Slots/components/MainSelectors/MainSelectors";
import PaymentChoice from "components/Modules/Slots/components/PaymentChoice/PaymentChoice";
import Slots from "components/Modules/Slots/components/Slots/Slots";
import slotsTeacherRecordOnlineStore from "Stores/slotsTeacherRecordOnlineStore";
import { observer } from "mobx-react";
import ClientDataWrapper from 'components/Modules/Slots/components/ClientDataWrapper/ClientDataWrapper';
import Confirm from 'components/Modules/Slots/components/Confirm/Confirm';
import ConfirmAdmin from 'components/Modules/Slots/components/Confirm/ConfirmAdmin';
import Submit from "components/Modules/Slots/components/Submit/Submit";
import OrderStatus from "components/Modules/Slots/components/OrderStatus/OrderStatus";
import styles from './Content.module.scss';

const Content = () => {
    const store = slotsTeacherRecordOnlineStore;
    const { isReadyToLoadCalendar, slots, selectedSlots, paymentType, paymentStep } = store;
    return (
        <div className={styles.content}>
            <div className={styles.line}><MainSelectors store={store} /></div>
            {isReadyToLoadCalendar && <div className={styles.line}><CalendarWrapper store={store} /></div>}
            {slots.length > 0 && <div className={styles.line}><Slots store={store} /></div>}
            {slots.length > 0 && selectedSlots.size > 0 && (
                <>
                    <div className={styles.line}><ClientDataWrapper store={store} /></div>
                    {paymentType === 1 && <div className={styles.line}><PaymentChoice store={store} /></div>}
                    <div className={styles.line}><Submit store={store} /></div>
                </>
            )}
            {(paymentType === 1 && paymentStep > 1) && <OrderStatus store={store} />}
            {(paymentType === 2 || paymentType === 4 || paymentType === 5) && paymentStep > 1 && <Confirm store={store} />}
            {(paymentType === 3 && paymentStep > 1) && <ConfirmAdmin store={store} />}
        </div>
    )
}

export default observer(Content);

