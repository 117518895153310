import React, { useState} from 'react';
import PropTypes from 'prop-types';
import {superAxiosRequest} from 'axiosApi'
import swal from "sweetalert";
import st from './ChapterCard.module.scss';
import {dateFormat} from "helpers/date";
import asideModalStore from "Stores/asideModalStore";

function ChapterCard(props) {

    const [open, setOpen] = useState(false)

    const menu = [
        {name: props.translate.edit, action: 'edit', permission: ['forward']},
        {name: props.translate.delete, action: 'delete', permission: ['forward']},
    ]

    const action = data => {
        switch (data) {
            case 'detail':
                setOpen(!open)
                break;
            case 'delete':
                deleteChapter()
                break;
            case 'edit':
                const ms = {
                    type: 'generator',
                    fields: [
                        {
                            type: 'text',
                            name: 'name',
                            label: `${props.translate.lesson_name}:`,
                            placeholder: props.translate.lesson_name
                        },
                        {
                            type: 'select',
                            name: 'registerchapters_id',
                            label: `${props.translate.register_sections}:`,
                            placeholder: props.translate.available_register_sections,
                            options: props?.registersSections
                        },
                        {
                            type: 'textarea',
                            name: 'lesson_plan',
                            label: `${props.translate.description}:`,
                            placeholder: props.translate.description,
                        },
                        {
                            type: 'textarea',
                            name: 'homework',
                            label: `${props.translate.homework}:`,
                            placeholder: props.translate.homework,
                        },
                    ],
                    valid: {
                        name: 'required',
                        registerchapters_id: 'required',
                        lesson_plan: 'required',
                        homework: 'required'
                    },
                  
                    title: props.translate.edit_register_section,
                    button: props.translate.save,
                    is_put: true,
                    link: `ebooks/ebook_registers/${props.chapter.id}`,
                    onSubmitSuccess: props.getRegisterItems,
                }
                const md = {
                    name: props.chapter.name,
                    lesson_plan: props.chapter.lesson_plan,
                    homework: props.chapter.homework, 
                    registerchapters_id: props.chapter.registerchapters_id ? props.chapter.registerchapters_id:''
                }
                asideModalStore.activity(ms, md)
                break;
            default:
                break;
        }
    }

    // Удаление главы
    const deleteChapter = () => {
        swal({
            text: `${props.translate.delete_register}: "${props.chapter.name}"?`,
            icon: "warning",
            buttons: [props.translate.no, props.translate.yes],
            dangerMode: true,
        }).then(yes => {
            if (yes) {
                const apiConf = {
                    method: 'delete',
                    link: 'ebooks/ebook_registers/' + props.chapter.id,
                }
                superAxiosRequest(apiConf).then(() => {
                    props.getRegisterItems()
                })
            }
        }) 
    }

    return (
        <div className={st.section}>
            <div className={st.chapterName}>
                {props.chapter.name}
            </div>
            <div className={st.rightSide}>
                <div className={st.info}>
                    <b>{dateFormat(new Date(props.chapter.updated_at), 'hh:ii')},</b> {dateFormat(new Date(props.chapter.updated_at), 'dd MMM YYYY')}
                    <div className={st.userName}>{props.chapter.updated_by_name ? props.chapter.updated_by_name :props.chapter.created_by_name}</div>
                </div>
                <div className={st.btns}>
                    {menu.map((el) => <div key={el.name} className={st.btn} onClick={() => action(el.action)}>{el.name}</div>)}
                </div>
            </div>
        </div>
    )
}

ChapterCard.propTypes = {
    chapter: PropTypes.object,
}

export default ChapterCard
