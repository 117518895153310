
import React, {useEffect} from 'react';
import Axios from 'axios';
import Store from 'Stores';
import { useHistory } from 'react-router-dom';
import Loader from "../../../UI/Loader/Loader";
import LocalStorage from "../../../../services/LocalStorage";

export default function Varification() {
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');

        if (code) {
            Axios.get(`${Store.apiUrl}kundelik/authorization_code`, { params: { code } })
                .then((res) => {
                    if (res.status === 200 ) {
                        // // Сохраняем токен и данные
                        Store.AuthUsers.setIsAuth(true);
                        Store.AuthUsers.createUserData(JSON.stringify(res.data));
                        //localStorage.setItem('isAuth', true);
                        LocalStorage.set('isAuth', true);
                        //localStorage.setItem('access_token_death', lifeTime);
                        //localStorage.setItem('life_time', lifeTime);
                        //localStorage.setItem('access', response.data.access);
                        LocalStorage.setSimple('access', res.data.access);
                        //localStorage.setItem('refresh', response.data.refresh);
                        LocalStorage.setSimple('refresh', res.data.refresh);
                        //localStorage.setItem('ud', JSON.stringify(response.data))
                        LocalStorage.set('ud', res.data)
                    }
                }).then(() =>{
                const originalPath = localStorage.getItem('originalPath');
                if (originalPath) {
                    window.location.href = originalPath;
                }
                })
                .catch((error) => {
                    if (error && error.response.status === 404) {
                        localStorage.setItem('kundelik_user_id', error.response.data.kundelik_user_id);
                        window.location.href = "/";
                    }
                    else if (error && error.response.status === 500) {
                        window.location.href = "/";
                    }


                });
        } else {
                history.push('/');
        }
    }, []);


    return (
        <div>
            <Loader />
        </div>
    )
}