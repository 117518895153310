export const dictionary = [
    {
        link: 'tests/questions',
        translation: [
            {
                description: 'title',
                ru: 'Вопросы',
                en: 'Questions',
                kz: 'Сұрақтар',
            },
            {
                description: 'button-title',
                ru: 'Добавить вопрос',
                en: 'Add a question',
                kz: 'Сұрақ қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Тип',
                en: 'A type',
                kz: 'Түрі',
            },
            {
                description: 'table-row-title-2',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Программа',
                en: 'Program',
                kz: 'Бағдарлама',
            },
            {
                description: 'table-row-title-4',
                ru: 'Текст вопроса',
                en: 'Question text',
                kz: 'Сұрақ мәтіні',
            },
            {
                description: 'table-row-title-5',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },

        ]
    },
    {
        link: 'tests/settings',
        translation: [
            {
                description: 'title',
                ru: 'Настройки',
                en: 'Settings',
                kz: 'Параметрлер',
            },
            {
                description: 'button-title',
                ru: 'Добавить связку',
                en: 'Add bundle',
                kz: 'Бума қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Программа',
                en: 'Program',
                kz: 'Бағдарлама',
            },
            {
                description: 'table-row-title-2',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Вопросов: ',
                en: 'Questions: ',
                kz: 'Саны: ',
            },
            {
                description: 'table-row-title-4',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'text_1',
                ru: 'Вопросов: ',
                en: 'Questions: ',
                kz: 'Саны: ',
            },
            {
                description: 'text_2',
                ru: 'Вопросов',
                en: 'Questions',
                kz: 'Саны',
            },
        ]
    },
    {
        link: 'lessons/levels',
        translation: [
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'tests/past-tests',
        translation: [
            {
                description: 'title',
                ru: 'Список прошедших тестов',
                en: 'List of passed tests',
                kz: 'Өткен сынақтар тізімі',
            },
            {
                description: 'table-row-title-1',
                ru: 'Программа',
                en: 'Program',
                kz: 'Бағдарлама',
            },
            {
                description: 'table-row-title-2',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-4',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'table-row-title-5',
                ru: 'Подуровень',
                en: 'Sublevel',
                kz: 'Төменгі деңгейі',
            },
            {
                description: 'table-row-title-6',
                ru: 'Сначала выберите уровень',
                en: 'First select the level',
                kz: 'Алдымен деңгейді таңдаңыз',
            },
            {
                description: 'table-row-title-7',
                ru: 'Прошли тест',
                en: 'Passed the test',
                kz: 'Тесттен өтті',
            },
            {
                description: 'table-row-title-7_1',
                ru: 'Прошли тест: ',
                en: 'Passed the test: ',
                kz: 'Тесттен өтті: ',
            },
            {
                description: 'table-row-title-8',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'sub-menu-button-1',
                ru: 'Не пройденные тестирования',
                en: 'Failed tests',
                kz: 'Сәтсіз тесттер',
            },
            {
                description: 'sub-menu-button-2',
                ru: 'Завершённые',
                en: 'Completed',
                kz: 'Аяқталды',
            },
            {
                description: 'name_1',
                ru: 'Результаты теста',
                en: 'Test results',
                kz: 'Тест нәтижелері',
            },
        ]
    },
    {
        link: 'tests/past-tests/test-results',
        translation: [
            {
                description: 'title',
                ru: 'Тест:',
                en: 'Test:',
                kz: 'Тест:',
            },
            {
                description: 'table-row-title-1',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'E-mail',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'table-row-title-3',
                ru: 'Результат',
                en: 'Result',
                kz: 'Нәтижесі',
            },
        ]
    },
    {
        link: 'tests/tests',
        translation: [
            {
                description: 'title',
                ru: 'Тестирования клиентов',
                en: 'Customer testing',
                kz: 'Клиенттерді тестілеу',
            },
            {
                description: 'sub-menu-button-1',
                ru: 'Не пройденные тестирования',
                en: 'Failed tests',
                kz: 'Сәтсіз тесттер',
            },
            {
                description: 'sub-menu-button-2',
                ru: 'Завершённые',
                en: 'Completed',
                kz: 'Аяқталды',
            },
            {
                description: 'table-row-title-1',
                ru: 'Программа',
                en: 'Program',
                kz: 'Бағдарлама',
            },
            {
                description: 'table-row-title-2',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Количество вопросов',
                en: 'Number of questions',
                kz: 'Сұрақтар саны',
            },
            {
                description: 'table-row-title-4',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'table-row-title-5',
                ru: 'Подуровень',
                en: 'Sublevel',
                kz: 'Төменгі деңгейі',
            },
            {
                description: 'table-row-title-6',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'text_1',
                ru: 'Назначить тестирование',
                en: 'Schedule testing',
                kz: 'Тестілеу кестесі',
            },
        ]
    },
    {
        link: 'placement_test/result',
        translation: [
            {
                description: 'link',
                ru: 'Вернуться назад: Главная страница',
                en: 'Go back: Home page',
                kz: 'Қайту: үйге'
            },
            {
                description: 'text_1',
                ru: 'Дата прохождение теста',
                en: 'Date of the test',
                kz: 'Тесті өткізу күні',
            },
            {
                description: 'record_to_online',
                ru: 'Записать на устное тестирование',
                en: 'Sign up for oral testing',
                kz: 'Ауызша тестілеуге жазылыңыз',
            },
            {
                description: 'text_2',
                ru: 'Неизвестно',
                en: 'Unknown',
                kz: 'Белгісіз',
            },
            {
                description: 'text_3',
                ru: 'В процессе',
                en: 'In the process',
                kz: 'Бұл процесте',
            },
            {
                description: 'text_4',
                ru: 'результат',
                en: 'result',
                kz: 'нәтиже',
            }
        ],
    },
    {
        link: 'tests/widget-clients',
        translation: [
            {
                description: 'title',
                ru: 'Клиенты прошедшие тест',
                en: 'Clients passed the test',
                kz: 'Клиенттер тестілеуден өтті',
            },
            {
                description: 'sub-menu-button-1',
                ru: 'Незаинтересованние клиенты',
                en: 'Disinterested customers',
                kz: 'Қызықсыз клиенттер',
            },
            {
                description: 'sub-menu-button-2',
                ru: 'Заинтересованные клиенты',
                en: 'Interested clients',
                kz: 'Қызығушылық танытқан клиенттер',
            },
            {
                description: 'table-row-title-1',
                ru: 'ФИО',
                en: 'Full name',
                kz: 'Толық Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Дата прохождения',
                en: 'Date passed',
                kz: 'Күн өтті',
            },
            {
                description: 'table-row-title-3',
                ru: 'Email',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'table-row-title-4',
                ru: 'Телефон',
                en: 'Telephone',
                kz: 'Телефон',
            },
            {
                description: 'table-row-title-5',
                ru: 'Результат',
                en: 'Result',
                kz: 'Нәтижесі',
            },
            {
                description: 'table-row-title-5_1',
                ru: 'Инт.',
                en: 'Int.',
                kz: 'Қызықтырады',
            },
            {
                description: 'table-row-title-5_2',
                ru: 'Клнт.',
                en: 'Clnt',
                kz: 'Клнт',
            },
            {
                description: 'table-row-title-6',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'name_1',
                ru: 'Записать на онлайн',
                en: 'Sign up for online',
                kz: 'Онлайнге тіркеліңіз',
            },
            {
                description: 'name_2',
                ru: 'Дата прохождения',
                en: 'Date passed',
                kz: 'Күн өтті',
            },
            {
                description: 'name_3',
                ru: 'Дата рождения',
                en: 'Date of Birth',
                kz: 'Tуған күні',
            },
            {
                description: 'name_4',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'name_5',
                ru: 'Заинтересован',
                en: 'Interested',
                kz: 'Қызықтырады',
            },
            {
                description: 'name_6',
                ru: 'Клиент',
                en: 'Client',
                kz: 'Клиенттер',
            },
            {
                description: 'name_7',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'name_8',
                ru: 'Программа',
                en: 'Program',
                kz: 'Бағдарлама',
            },
            {
                description: 'name_9',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'name_10',
                ru: 'Выберите виджет',
                en: 'Select a widget',
                kz: 'Виджет таңдаңыз',
            },
            {
                description: 'name_11',
                ru: 'ФИО, e-mail или телефон клиента',
                en: 'Full name, e-mail or phone number of the client',
                kz: 'Клиенттің толық аты-жөні, электрондық поштасы немесе телефон нөмірі',
            },
            {
                description: 'name_12',
                ru: 'Удалить результат',
                en: 'Delete result',
                kz: 'Нәтижені жою',
            },
            {
                description: 'name_13',
                ru: 'Уровень теста',
                en: 'Test level',
                kz: 'Тест деңгейі',
            },
            {
                description: 'name_14',
                ru: 'Запись на устный тест',
                en: 'Registration for an oral test',
                kz: 'Ауызша тестке тіркелу',
            },
            {
                description: 'name_15',
                ru: 'Создать клиента',
                en: 'Create customer',
                kz: 'Тұтынушы жасаңыз',
            },
            {
                description: 'name_16',
                ru: 'Выберите программу обучения',
                en: 'Select a training program',
                kz: 'Жаттығу бағдарламасын таңдаңыз',
            }
        ]
    },
    {
        link: 'docs/clients-docs',
        translation: [
            {
                description: 'title',
                ru: 'Документы',
                en: 'Documents',
                kz: 'Құжаттар',
            },
            {
                description: 'sub-menu-button-1',
                ru: 'Подписан',
                en: 'Signed',
                kz: 'Қол қойылған',
            },
            {
                description: 'sub-menu-button-2',
                ru: 'Ожидает подписание',
                en: 'Awaiting signing',
                kz: 'Қол қоюды күтеміз',
            },
            {
                description: 'table-row-title-1',
                ru: 'ФИО клиента',
                en: 'Full name of the client',
                kz: 'Клиенттің Т.А.Ә.',
            },
            {
                description: 'table-row-title-2',
                ru: 'Договор',
                en: 'Contract',
                kz: 'Шарт',
            },
            {
                description: 'table-row-title-3',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'docs/push-clients-docs',
        translation: [
            {
                description: 'title',
                ru: 'Список документов',
                en: 'List of documents',
                kz: 'Құжаттар тізімі',
            },
            {
                description: 'title-modal',
                ru: 'Назначение документа для студента',
                en: 'Assigning a document to a student',
                kz: 'Оқушыға құжат тапсыру',
            },
            {
                description: 'title-modal-newAction',
                ru: 'Назначить документ',
                en: 'Assigning a document',
                kz: 'Оқушыға құжат',
            },
            {
                description: 'pdf',
                ru: 'Скачать',
                en: 'Download',
                kz: 'Жүктеу',
            },
            {
                description: 'textarea-placeholder',
                ru: 'Начните вводить имя телефон или E-mail клиента',
                en: 'Start entering the customer\'s name, phone number or E-mail',
                kz: 'Клиенттің атауы, телефон нөмірін немесе электрондық поштаны теруді бастаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Болдырмау',
            },
            {
                description: 'button-two',
                ru: 'Отправить договор',
                en: 'Send a contract',
                kz: 'Келісімшартты жіберіңіз',
            },
            {
                description: 'text-info',
                ru: 'Код подтверждения отправлен на номер',
                en: 'Confirmation code sent to number',
                kz: 'Растау коды нөмірге жіберілді',
            },
            {
                description: 'text-info-2',
                ru: 'Отправить код повторно',
                en: 'Resend code',
                kz: 'Кодты қайта жіберіңіз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'text-info-4',
                ru: 'Подписать',
                en: 'Sign',
                kz: 'Қол қою',
            },
            {
                description: 'text-info-3',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
        ]
    },
    {
        link: 'pays/all-pays',
        translation: [
            {
                description: 'title',
                ru: 'Все счета',
                en: 'All invoice',
                kz: 'Барлық шоттар',
            },
            {
                description: 'transactions',
                ru: 'Все транзакции',
                en: 'All Transactions',
                kz: 'Барлық Транзакциялар',
            },
            {
                description: 'balance-button',
                ru: 'Пополнить баланс',
                en: 'Top up balance',
                kz: 'Толтыру балансы',
            },
            {
                description: 'my-bills',
                ru: 'Мои счета',
                en: 'My bills',
                kz: 'Менің шоттарым',
            },
            {
                description: 'button-title',
                ru: 'Выставить новый счет',
                en: 'Issue a new invoice',
                kz: 'Жаңа шот-фактураны беріңіз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Счет',
                en: 'Score',
                kz: 'Шот',
            },
            {
                description: 'table-row-title-2',
                ru: 'Описание',
                en: 'Description',
                kz: 'Сипаттамасы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Имя клиента',
                en: 'Client name',
                kz: 'Клиенттің атауы',
            },
            {
                description: 'table-row-title-4',
                ru: 'Тип оплаты',
                en: 'Payment type',
                kz: 'Төлем түрі',
            },
            {
                description: 'table-row-title-5',
                ru: 'Срок оплаты',
                en: 'Due date',
                kz: 'Мерзімнің өту күні',
            },
            {
                description: 'table-row-title-6',
                ru: 'Сумма',
                en: 'Amount',
                kz: 'Сома',
            },
            {
                description: 'table-row-title-7',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
            {
                description: 'table-row-title-8',
                ru: 'Оплата',
                en: 'Payment',
                kz: 'Төлем',
            },
        ]
    },
    {
        link: 'pays/client-orders',
        translation: [
            {
                description: 'fio',
                ru: 'ФИО, email, ИНН, номер телефона от 3х символов',
                en: 'Full name, email, TIN, phone number from 3 characters',
                kz: 'Толық аты-жөні, электрондық пошта, СТН, 3 таңбадан тұратын телефон нөмірі',
            },
            {
                description: 'order_number',
                ru: 'Номер ордера',
                en: 'Order number',
                kz: 'Тапсырыс нөмірі',
            },
            {
                description: 'group_number',
                ru: 'Номер группы',
                en: 'Group number',
                kz: 'Топ нөмірі',
            },
            {
                description: 'approved_comment',
                ru: 'Коментарий к подтверждению',
                en: 'Approved comment',
                kz: 'Растау туралы түсініктеме',
            },
            {
                description: 'approved_date',
                ru: 'Дата подтверждения',
                en: 'Approved date',
                kz: 'Растау күні',
            },
            {
                description: 'approved',
                ru: 'Подтвердил',
                en: 'Approved',
                kz: 'Расталды',
            },
            {
                description: 'account_validity_date',
                ru: 'Дата действия счета',
                en: 'Payment validity date',
                kz: 'Шоттың жарамдылық мерзімі',
            },
            {
                description: 'deadline_expired',
                ru: 'Вышел срок',
                en: 'Expired',
                kz: 'Мерзімі аяқталды',
            },
            {
                description: 'paid',
                ru: 'Оплачен',
                en: 'Paid',
                kz: 'Төленді',
            },
            {
                description: 'cancelled',
                ru: 'Отменен',
                en: 'Canceled',
                kz: 'Бас тартылды',
            },
            {
                description: 'pending',
                ru: 'Ожидание',
                en: 'Pending',
                kz: 'Күтуде',
            },
        ]
    },
    {
        link: 'pays/invoice',
        translation: [
            {
                description: 'invoice',
                ru: 'Счет',
                en: 'Invoice',
                kz: 'Шот-фактура',
            },
            {
                description: 'checking_invoice_status',
                ru: 'Проверяем состояние счета',
                en: 'Checking invoice status',
                kz: 'Есептік жазба күйін тексеру',
            },
            {
                description: 'pay_before',
                ru: 'Оплатить до',
                en: 'Pay before',
                kz: 'Бұрын төлеңіз',
            },
            {
                description: 'cancel_invoice',
                ru: 'отменить счёт',
                en: 'cancel invoice',
                kz: 'есептік жазбадан бас тарту',
            },
            {
                description: 'purpose_amount_invoice',
                ru: 'Назначение и сумма счета',
                en: 'Purpose and amount of the invoice',
                kz: 'Шот-фактураның мақсаты мен мөлшері',
            },
            {
                description: 'total',
                ru: 'Итого',
                en: 'Total',
                kz: 'Барлығы',
            },
            {
                description: 'total_payable',
                ru: 'Всего к оплате',
                en: 'Total payable',
                kz: 'Жалпы төлеуге жататындар',
            },
            {
                description: 'invoice_id',
                ru: 'ID ордера',
                en: 'Invoice ID',
                kz: 'Тапсырыс ID',
            },
            {
                description: 'select_payment_method',
                ru: 'Выберите способ оплаты',
                en: 'Select a Payment Method',
                kz: 'Төлем әдісін таңдаңыз',
            },
            {
                description: 'with_kaspi',
                ru: 'С помощью Kaspi.kz',
                en: 'With Kaspi.kz',
                kz: 'Kaspi.kz көмегімен',
            },
            {
                description: 'head-1',
                ru: 'Оплата с помощью сайта Kaspi.kz',
                en: 'Payment using the website Kaspi.kz',
                kz: 'Kaspi.kz сайтының көмегімен төлем',
            },
            {
                description: 'head-2',
                ru: 'Оплата с помощью мобильного приложения Kaspi',
                en: 'Payment using the Kaspi mobile app',
                kz: 'Kaspi мобильді қосымшасының көмегімен төлем',
            },
            {
                description: 'text-1',
                ru: 'Авторизуйтесь на сайте kaspi.kz',
                en: 'Log in to the website kaspi.kz',
                kz: 'Kaspi.kz сайтына кіріңіз',
            },
            {
                description: 'text-2',
                ru: 'Перейдите в раздел «Платежи», далее введите в строке поиска «InterPress».',
                en: 'Go to the "Payments" section, then enter "InterPress" in the search bar.',
                kz: '«Төлемдер» бөліміне өтіп, іздеу жолағына «InterPress» енгізіңіз.',
            },
            {
                description: 'text-3',
                ru: 'В открывшейся форме укажите номер счета и нажмите кнопку «Продолжить».',
                en: 'In the form that opens, specify the payment number and click the "Continue" button.',
                kz: 'Ашылған пішінде шот нөмірін көрсетіп, «Жалғастыру» батырмасын басыңыз.',
            },
            {
                description: 'text-4',
                ru: 'Авторизуйтесь в приложении «Kaspi.kz».',
                en: 'Log in to the "Kaspi.kz" app.',
                kz: '«Kaspi.kz» қосымшасына кіріңіз.',
            },
            {
                description: 'payer_data',
                ru: 'Данные плательщика',
                en: 'Payer data',
                kz: 'Төлеушілер туралы мәліметтер',
            },
            {
                description: 'customer_payers',
                ru: 'Плательщики клиента',
                en: 'Customer payers',
                kz: 'Клиент төлеушілер',
            },
            {
                description: 'customer_and_payer',
                ru: 'Я клиент и плательщик',
                en: 'I am a customer and payer',
                kz: 'Мен тапсырыс берушімін және төлеушімін',
            },
            {
                description: 'create_new_payer',
                ru: 'Создание нового плательщика',
                en: 'Creating a new payer',
                kz: 'Жаңа төлеуші ​​құру',
            },
            {
                description: 'text-5',
                ru: 'Должно содержать не менее 11 символов',
                en: 'Must contain at least 11 characters',
                kz: 'Кемінде 11 таңбадан тұруы керек',
            },
            {
                description: 'text-6',
                ru: 'Должно содержать не менее 3 символов',
                en: 'Must contain at least 3 characters',
                kz: 'Кемінде 3 таңбадан тұруы керек',
            },
            {
                description: 'text-7-1',
                ru: 'После перехода на страницу оплаты у вас будет',
                en: 'After switching to the payment page, you will have',
                kz: 'Төлем парағына ауысқаннан кейін төлемді аяқтауға',
            },
            {
                description: 'text-7-2',
                ru: 'на проведение оплаты.',
                en: 'to complete the payment.',
                kz: 'уақыт беріледі.',
            },
            {
                description: 'go_to_payment',
                ru: 'Перейти к оплате',
                en: 'Go to payment',
                kz: 'Тексеріске өту',
            },
            {
                description: 'order_number',
                ru: 'Номер заказа',
                en: 'Order number',
                kz: 'Тапсырыс нөмірі',
            },
            {
                description: 'text-8',
                ru: 'Для оплаты с помощью KASPI необходимо',
                en: 'To pay with KASPI you need',
                kz: 'KASPI-мен төлеу үшін сізге қажет',
            },
            {
                description: 'text-9',
                ru: 'Перейти в приложение KASPI.kz',
                en: 'Go to KASPI.kz app',
                kz: 'KASPI.kz қосымшасына өтіңіз',
            },
            {
                description: 'text-10',
                ru: 'Найти компанию InterPress',
                en: 'Find an InterPress company',
                kz: 'InterPress компаниясын табыңыз',
            },
            {
                description: 'text-11',
                ru: 'В поле номера заказа, внести номер заказа',
                en: 'In the field of the order number, enter the order number',
                kz: 'Тапсырыс нөмірінің өрісіне тапсырыс нөмірін енгізіңіз',
            },
            {
                description: 'text-12',
                ru: 'Подтвердить свои данные и совершить оплату',
                en: 'Confirm your details and make a payment',
                kz: 'Мәліметтеріңізді растаңыз және төлем жасаңыз',
            },
            {
                description: 'payment_time_expired',
                ru: 'Время оплаты истекло',
                en: 'Payment time expired',
                kz: 'Төлем уақыты аяқталды',
            },
            {
                description: 'check_payment_status',
                ru: 'Проверка статуса оплаты',
                en: 'Checking payment status',
                kz: 'Төлем мәртебесін тексеру',
            },
            {
                description: 'through',
                ru: 'через',
                en: 'through',
                kz: 'арқылы',
            },
            {
                description: 'sec',
                ru: 'сек',
                en: 'sec',
                kz: 'сек',
            },
            {
                description: 'hour',
                ru: 'час',
                en: 'hour',
                kz: 'сағат',
            },
            {
                description: 'time_for_payment',
                ru: 'Осталось времени на оплату',
                en: 'Time left for payment',
                kz: 'Төлеуге уақыт қалды',
            },
            {
                description: 'bill_paid',
                ru: 'Счет оплачен',
                en: 'The bill is paid',
                kz: 'Есепшот төленеді',
            },
        ]
    },
    {
        link: 'students_doc/send_document',
        translation: [
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'table-row-title-3',
                ru: 'Список документов',
                en: 'List of documents',
                kz: 'Құжаттар тізімі',
            },
            {
                description: 'table-row-title-4',
                ru: 'Подписание договоров',
                en: 'Contract signature',
                kz: 'Келісімшартқа қол қою',
            },
            {
                description: 'sub-title-doc-1',
                ru: 'Клиентские документы',
                en: 'Client documents',
                kz: 'Клиенттің құжаттары',
            },
            {
                description: 'sub-title-doc-1',
                ru: 'Подписанные',
                en: 'Signed',
                kz: 'Қол қойылған',
            },
            {
                description: 'sub-title-doc-1',
                ru: 'В ожидании подписания',
                en: 'Pending signing',
                kz: 'Қол қою күтілуде',
            },
        ]
    },
    {
        link: 'c/tests/placement-test/create-widget',
        translation: [
            {
                description: 'error',
                ru: 'Ошибка',
                en: 'error',
                kz: 'қате',
            },
            {
                description: 'error_bundles',
                ru: 'Выберите хотя бы одну связку',
                en: 'Select at least one bundle',
                kz: 'Кем дегенде бір буманы таңдаңыз',
            },
            {
                description: 'success',
                ru: 'Настройки виджета сохранены!',
                en: 'Widget settings saved!',
                kz: 'Виджет параметрлері сақталды!',
            },
            {
                description: 'title_1',
                ru: 'Создание виджета',
                en: 'Creating a widget',
                kz: 'Виджет жасау',
            },
            {
                description: 'title_2',
                ru: 'Редактирование виджета',
                en: 'Editing a widget',
                kz: 'Виджетті өңдеу',
            },
            {
                description: 'text_1',
                ru: 'Основные настройки',
                en: 'basic settings',
                kz: 'негізгі параметрлер',
            },
            {
                description: 'text_2',
                ru: 'Название виджета',
                en: 'Widget name',
                kz: 'Виджет атауы',
            },
            {
                description: 'text_3',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'text_4',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'text_5',
                ru: 'Страница, на которой будет размещен виджет',
                en: 'The page on which the widget will be hosted',
                kz: 'Виджет орналастырылатын бет',
            },
            {
                description: 'text_6',
                ru: 'Полный адрес страницы',
                en: 'Full page address',
                kz: 'Толық беттің мекенжайы',
            },
            {
                description: 'text_7',
                ru: 'Отображать как фрейм',
                en: 'Display as frame',
                kz: 'Кадр ретінде көрсету',
            },
            {
                description: 'PTLogo_btn_download',
                ru: 'Загрузить логотип',
                en: 'Upload logo',
                kz: 'Логотип жүктеу',
            },
            {
                description: 'PTLogo_btn_edit',
                ru: 'Изменить логотип',
                en: 'Change logo',
                kz: 'Логотипті өзгерту',
            },
            {
                description: 'text_8',
                ru: 'Поля контактной формы',
                en: 'Contact form fields',
                kz: 'Байланыс формасының өрістері',
            },
            {
                description: 'text_9',
                ru: 'Здесь Вы можете настроить форму для сбора контактов клиентов передначалом тестирования',
                en: 'Here you can set up a form to collect customer contacts before start testing',
                kz: 'Мұнда сіз бұрын клиенттердің контактілерін жинау үшін пішін орната аласыз тестілеуді бастау',
            },
            {
                description: 'text_10',
                ru: 'Ссылка на документ с соглашением',
                en: 'Link to agreement document',
                kz: 'Келісім құжатына сілтеме',
            },
            {
                description: 'text_11',
                ru: 'Заголовок',
                en: 'Heading',
                kz: 'Тақырып',
            },
            {
                description: 'text_12',
                ru: 'Заполнение',
                en: 'Filling',
                kz: 'Толтыру',
            },
            {
                description: 'text_13',
                ru: 'Настройка поля',
                en: 'Setting the field',
                kz: 'Өрісті орнату',
            },
            {
                description: 'text_14',
                ru: 'Обязательно для заполнения',
                en: 'Required to fill',
                kz: 'Толтыру үшін қажет',
            },
            {
                description: 'text_15',
                ru: 'Выбор связок с вопросами',
                en: 'Choosing linkages with a question',
                kz: 'Сұрақ қою арқылы байланыстарды таңдау',
            },
            {
                description: 'text_16',
                ru: 'Поиск связки по параметрам',
                en: 'Link search by parameters',
                kz: 'Параметрлер бойынша сілтемені іздеу',
            },
            {
                description: 'text_17',
                ru: 'Возрастные категорий',
                en: 'Age categories',
                kz: 'Жас категориялары',
            },
            {
                description: 'text_18',
                ru: 'Применить поиск',
                en: 'Apply search',
                kz: 'Іздеуді қолдану',
            },
            {
                description: 'text_19',
                ru: 'Сбросить фильтр',
                en: 'Reset filter',
                kz: 'Сүзгіні қалпына келтіру',
            },
            {
                description: 'text_20',
                ru: 'Связки по параметрам',
                en: 'Bundles by parameters',
                kz: 'Параметрлер бойынша топтамалар'
            },
            {
                description: 'text_21',
                ru: 'Шаблон страницы результата',
                en: 'Result page template',
                kz: 'Нәтиже бетінің үлгісі'
            },
            {
                description: 'text_22',
                ru: 'Страницы результата',
                en: 'Result Pages',
                kz: 'Нәтиже беттері'
            },
            {
                description: 'text_23',
                ru: 'Ответы',
                en: 'Answers',
                kz: 'Жауаптар',
            },
            {
                description: 'text_24',
                ru: 'Первая строка - положительный ответ, вторая строка - отрицательный ответ',
                en: 'The first line is a positive answer, the second line is a negative answer',
                kz: 'Бірінші жол - оң жауап, екінші жол - теріс жауап'
            },
            {
                description: 'text_25',
                ru: 'Допустимые теги в шаблоне страницы результата',
                en: 'Valid Tags in Result Page Template',
                kz: 'Нәтижелер бетінің үлгісіндегі жарамды тегтер',
            },
            {
                description: 'text_26',
                ru: 'Удалить виджет',
                en: 'Remove widget',
                kz: 'Виджетті жою'
            },
            {
                description: 'text_27',
                ru: 'Только сбор данных о клиенте, без прохождения тестирования',
                en: 'Only collection of customer data, no testing',
                kz: 'Тек тұтынушы туралы мәліметтерді жинау, тестілеу жоқ',
            },
            {
                description: 'text_28',
                ru: 'Вы не сможете выбрать связки с вопросами, и клиент после заполнения контактной формы сразу перейдет на результат',
                en: 'You will not be able to select bundles with questions, and the client, after filling out the contact form, will immediately go to the result',
                kz: 'Сіз сұрақтар бар пакеттерді таңдай алмайсыз, ал клиент байланыс формасын толтырғаннан кейін бірден нәтижеге көшеді.',
            },
            {
                description: 'text_29',
                ru: 'Выбранные связки',
                en: 'Selected bundles',
                kz: 'Таңдалған бумалар'
            },
            {
                description: 'text_30',
                ru: 'Шаблоны писем рассылки клиентам',
                en: 'Email templates to clients',
                kz: 'Клиенттерге электрондық пошта үлгілері',
            },
            {
                description: 'text_31',
                ru: 'Клиент прошел тестирование',
                en: 'Client has been tested',
                kz: 'Клиент тестілеуден өтті',
            },
            {
                description: 'text_32',
                ru: 'Время тестирования истекло',
                en: 'Testing time expired',
                kz: 'Тестілеу уақыты аяқталды',
            },
            {
                description: 'text_33',
                ru: 'Отправка данных в amoCRM',
                en: 'Sending data to amoCRM',
                kz: 'Деректерді amoCRM -ге жіберу',
            },
            {
                description: 'text_34',
                ru: 'Настройка данных для отправки в amoCRM, предопределенные названия есть только у полей: Имя, e-mail, телефон. Для остальных полей необходимо указывать имя поля в amoCRM. Подробнее в инструкции.',
                en: 'Setting up data for sending to amoCRM, only the fields have predefined names: Name, e-mail, phone. For the rest of the fields, you must specify the field name in amoCRM. Read more in the instructions.',
                kz: 'AmoCRM-ге жіберу үшін деректерді орнату, тек өрістерде алдын ала анықталған атаулар бар: Аты, электрондық поштасы, телефон. Қалған өрістер үшін өріс атауын amoCRM -де көрсету керек. Толығырақ нұсқаулықтан оқыңыз.',
            },
            {
                description: 'text_35',
                ru: 'адрес отправления данных',
                en: 'data sending address',
                kz: 'деректерді жіберу мекенжайы',
            },
            {
                description: 'text_36',
                ru: 'URL, откуда поступают заявки (если будет незаполнено отправится URL страницы виджета)',
                en: 'URL where requests come from (if the page URL of the widget will be sent empty)',
                kz: 'Сұраныстар келетін URL (егер виджеттің бет URL мекенжайы бос жіберілсе)',
            },
            {
                description: 'text_37',
                ru: 'Название кнопки сохранения данных о клиенте',
                en: 'Name of the button for saving customer data',
                kz: 'Тұтынушы деректерін сақтауға арналған батырманың аты',
            },
            {
                description: 'text_38',
                ru: 'Имя поля в amoCRM',
                en: 'Field name in amoCRM',
                kz: 'AmoCRM өрісінің атауы',
            },
            {
                description: 'text_39',
                ru: 'Название в amoCRM',
                en: 'Name in amoCRM',
                kz: 'AmoCRM ішіндегі атау',
            },
            {
                description: 'text_40',
                ru: 'Заполните данные в полях',
                en: 'Fill in the data in the fields',
                kz: 'Өрістердегі деректерді толтырыңыз',
            },
            {
                description: 'text_41',
                ru: 'Выберите и заполните необходимые данные',
                en: 'Select and fill in the required details',
                kz: 'Қажетті мәліметтерді таңдап, толтырыңыз',
            },
            {
                description: 'text_42',
                ru: 'Кол-во вопросов',
                en: 'Number of questions',
                kz: 'Сұрақтар саны',
            },
            {
                description: 'field_title_1',
                ru: 'Имя',
                en: 'Name',
                kz: 'Аты',
            },
            {
                description: 'field_title_2',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'field_placeholder_2',
                ru: 'Ваша фамилия',
                en: 'Your surname',
                kz: 'Сіздің тегіңіз',
            },
            {
                description: 'birth_date',
                ru: 'Дата рождения',
                en: 'Date of Birth',
                kz: 'Туған кезі',
            },
            {
                description: 'field_title_3',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
            {
                description: 'field_title_4',
                ru: 'Клиент просит не связываться с ним после тестирования',
                en: 'The client asks not to contact him after testing',
                kz: 'Клиент тестілеуден кейін оған хабарласпауды сұрайды',
            },
            {
                description: 'field_title_5',
                ru: 'Согласие на обработку персональных данных',
                en: 'Consent to the processing of personal data',
                kz: 'Жеке деректерді өңдеуге келісім',
            },
            {
                description: 'no_required',
                ru: 'Не обязательное',
                en: 'Optional',
                kz: 'Қосымша',
            },
            {
                description: 'required',
                ru: 'Обязательное',
                en: 'Mandatory',
                kz: 'Міндетті',
            },
            {
                description: 'warning',
                ru: 'Ничего не найдено',
                en: 'Nothing found',
                kz: 'ештеңе табылмады',
            },
            {
                description: 'link',
                ru: 'Свойства и вопросы',
                en: 'Properties and issues',
                kz: 'Қасиеттер мен мәселелер',
            },
            {
                description: 'field_1',
                ru: 'Подсчет возраста',
                en: 'Age counting',
                kz: 'Жасты санау',
            },
            {
                description: 'field_2',
                ru: 'Дата прохождения теста (без времени)',
                en: 'Test date (no time)',
                kz: 'Сынақ күні (уақыт жоқ)',
            },
            {
                description: 'field_3',
                ru: 'Регистрация в личном кабинете',
                en: 'Registration in your personal payment',
                kz: 'Жеке кабинетке тіркелу',
            },
            {
                description: 'field_4',
                ru: 'Пройти тест заново',
                en: 'Take the test again',
                kz: 'Қайта тест тапсырыңыз',
            },
            {
                description: 'field_5',
                ru: 'Запись на тест',
                en: 'Registration for the test',
                kz: 'Тестке тіркеу',
            },
            {
                description: 'field_6',
                ru: 'Количество верных ответов',
                en: 'Number of correct answers',
                kz: 'Дұрыс жауаптардың саны',
            },
            {
                description: 'field_7',
                ru: 'Уровень по количеству верных ответов',
                en: 'Level by the number of correct answers',
                kz: 'Дұрыс жауаптар саны бойынша деңгей',
            },
            {
                description: 'link_text',
                ru: 'Ссылка',
                en: 'Link',
                kz: 'Сілтеме',
            },
            {
                description: 'btn_request_create',
                ru: 'Создать виджет',
                en: 'Create widget',
                kz: 'Виджет жасау',
            },
            {
                description: 'btn_request_edit',
                ru: 'Редактировать виджет',
                en: 'Edit widget',
                kz: 'Виджетті өңдеу',
            },
            {
                description: 'loading',
                ru: 'Загрузка...',
                en: 'loading...',
                kz: 'жүктеу...'
            },
            {
                description: 'warning_1',
                ru: 'Загрузите файл!',
                en: 'Upload the file',
                kz: 'Файлды жүктеңіз!',
            }
        ],
    },
    {
        link: 'company/companies',
        translation: [
            {
                description: 'title',
                ru: 'Компании',
                en: 'Companies',
                kz: 'Компаниялар',
            },
            {
                description: 'button-title',
                ru: 'Добавить компанию',
                en: 'Add company',
                kz: 'Компанияны қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Комментарий',
                en: 'Comment',
                kz: 'Пікір',
            },
            {
                description: 'table-row-title-3',
                ru: 'Логотип',
                en: 'Logo',
                kz: 'Логотип',
            },
            {
                description: 'table-row-title-4',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'table-row-title-5',
                ru: 'ИНН/ИИН',
                en: 'INN/IIN',
                kz: 'ЖСН/ЖСН',
            },
        ]
    },
    {
        link: 'company/locations',
        translation: [
            {
                description: 'title',
                ru: 'Локации',
                en: 'Locations',
                kz: 'Орындар',
            },
            {
                description: 'button-title',
                ru: 'Добавить локацию',
                en: 'Add location',
                kz: 'Орынды қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'text_1',
                ru: 'Редактировать',
                en: 'Edit',
                kz: 'Өңдеу',
            },
            {
                description: 'text_2',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'text_3',
                ru: 'Редактирование аудитории',
                en: 'Edit classroom',
                kz: 'Аудиторияны өңдеу',
            },
            {
                description: 'text_4',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'text_5',
                ru: 'Развернуть',
                en: 'Expand',
                kz: 'Кеңейту',
            },
            {
                description: 'text_6',
                ru: 'Свернуть',
                en: 'Collapse',
                kz: 'Тасалау',
            },
            {
                description: 'text_7',
                ru: 'Добавление новой аудитории',
                en: 'Add new classroom',
                kz: 'Жаңа аудитория қосу',
            },
            {
                description: 'text_8',
                ru: 'Редактирование локации',
                en: 'Edit location',
                kz: 'Орынын өзгерту',
            },
            {
                description: 'text_9',
                ru: 'Компании',
                en: 'Companies',
                kz: 'Компаниялар',
            },
            {
                description: 'text_10',
                ru: 'Тип',
                en: 'Type',
                kz: 'Tүрі',
            },
            {
                description: 'text_11',
                ru: 'Онлайн',
                en: 'Online',
                kz: 'Желіде',
            },
            {
                description: 'text_12',
                ru: 'Офлайн',
                en: 'Offline',
                kz: 'Желіде тыс',
            },
            {
                description: 'text_13',
                ru: 'Аудитории',
                en: 'Classrooms',
                kz: 'Аудитория',
            },
            {
                description: 'text_14',
                ru: 'Вместимость',
                en: 'Offline',
                kz: 'Желіде тыс',
            },
            {
                description: 'text_15',
                ru: 'до',
                en: 'before',
                kz: 'дейін',
            },
            {
                description: 'text_16',
                ru: 'с',
                en: 'from',
                kz: 'бастап',
            },
            {
                description: 'text_17',
                ru: 'Параметры локации',
                en: 'Location parameters',
                kz: 'Орналасу параметрлері',
            },
            {
                description: 'text_18',
                ru: 'Студенты',
                en: 'Students',
                kz: 'Студенттер',
            },
            {
                description: 'text_19',
                ru: 'Всего',
                en: 'Total',
                kz: 'Барлыгы',
            },
            {
                description: 'text_20',
                ru: 'Группы',
                en: 'Groups',
                kz: 'Топ',
            },
            {
                description: 'text_21',
                ru: 'Преподаватели',
                en: 'Teachers',
                kz: 'Мұғалімдер',
            },
            {
                description: 'text_22',
                ru: 'Время работы',
                en: 'Working hours',
                kz: 'Жұмыс уақыты',
            },
            {
                description: 'text_23',
                ru: 'Часовой пояс',
                en: 'Timezone',
                kz: 'Сағаттық белдеу',
            },
            {
                description: 'text_24',
                ru: 'Адрес',
                en: 'Address',
                kz: 'Мекен-жайы',
            },
            {
                description: 'text_25',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
            {
                description: 'text_26',
                ru: 'Мобильный телефон',
                en: 'Mobile phone',
                kz: 'Ұялы телефон',
            },
            {
                description: 'text_27',
                ru: 'E-mail',
                en: 'Email',
                kz: 'Электрондық пошта',
            },

        ]
    },
    {
        link: 'company/profiles',
        translation: [
            {
                description: 'title',
                ru: 'Список сотрудников',
                en: 'List of employees',
                kz: 'Қызметкерлер тізімі',
            },
            {
                description: 'button-title',
                ru: 'Добавить сотрудника',
                en: 'Add employee',
                kz: 'Қызметкерді қосу',
            },
            {
                description: 'table-row-title-1',
                ru: 'ФИО',
                en: 'Full name',
                kz: 'Толық атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'table-row-title-3',
                ru: 'Дополнительные локации',
                en: 'Additional locations',
                kz: 'Қосымша орындар',
            },
            {
                description: 'table-row-title-4',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
            {
                description: 'table-row-title-5',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
        ]
    },
    {
        link: 'company/programs',
        translation: [
            {
                description: 'title',
                ru: 'Программы обучения',
                en: 'Studying programs',
                kz: 'Оқу бағдарламалары',
            },
            {
                description: 'button-title',
                ru: 'Добавить программу',
                en: 'Add program',
                kz: 'Бағдарлама қосу',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'company/age',
        translation: [
            {
                description: 'title',
                ru: 'Возрастные категории',
                en: 'Age categories',
                kz: 'Жас категориялары',
            },
            {
                description: 'button-title',
                ru: 'Добавить возраст',
                en: 'Add age',
                kz: 'Жас қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },

    {
        link: 'online-schedule/time-slots',
        translation: [
            {
                description: 'title',
                ru: 'Временные слоты',
                en: 'Time slots',
                kz: 'Уақыт аралықтары',
            },
            {
                description: 'select-day-1',
                ru: 'ПН-ПТ',
                en: 'Mon-Fri',
                kz: 'Дүйсенбі-жұма',
            },
            {
                description: 'select-day-2',
                ru: 'Все дни',
                en: 'All days',
                kz: 'Барлық күндер',
            },
            {
                description: 'select-day-3',
                ru: 'ПН',
                en: 'Mon',
                kz: 'Дүйсенбі',
            },
            {
                description: 'select-day-4',
                ru: 'ВТ',
                en: 'Tue',
                kz: 'Сейсенбі',
            },
            {
                description: 'select-day-5',
                ru: 'СР',
                en: 'Wed',
                kz: 'Сәрсенбі',
            },
            {
                description: 'select-day-6',
                ru: 'ЧТ',
                en: 'Thu',
                kz: 'Бейсенбі',
            },
            {
                description: 'select-day-7',
                ru: 'ПТ',
                en: 'Fri',
                kz: 'Жұма',
            },
            {
                description: 'select-day-8',
                ru: 'СБ',
                en: 'Sat',
                kz: 'Сенбі',
            },
            {
                description: 'select-day-9',
                ru: 'ВС',
                en: 'Sun',
                kz: 'Жексенбі',
            },
            {
                description: 'Choose-a-location',
                ru: 'Выберите локацию',
                en: 'Choose a location',
                kz: 'Орынды таңдаңыз',
            },
            {
                description: 'Choose-lesson-duration',
                ru: 'Выберите длительность урока',
                en: 'Choose lesson duration',
                kz: 'Сабақтың ұзақтығын таңдаңыз',
            },
            {
                description: 'available-slots',
                ru: 'Вы не указали количество доступных слотов',
                en: 'You have not specified the number of available slots',
                kz: 'Сіз қол жетімді ұялардың санын көрсетпедіңіз',
            },
            {
                description: 'time',
                ru: 'Время работы',
                en: 'Opening hours',
                kz: 'Жұмыс уақыты',
            },
            {
                description: 'minutes',
                ru: ' минут ',
                en: ' minutes ',
                kz: ' минут ',
            },
            {
                description: 'chosen',
                ru: 'Не выбрано',
                en: 'Not chosen',
                kz: 'Таңдалмаған',
            },
            {
                description: 'indicated',
                ru: 'Не указано',
                en: 'Not indicated',
                kz: 'Көрсетілмеген',
            },
            {
                description: 'clients',
                ru: 'клиентов:',
                en: 'clients:',
                kz: 'клиенттер:',
            },
            {
                description: 'entries',
                ru: 'На этот день записей нет',
                en: 'No entries for this day',
                kz: 'Бұл күні жазбалар жоқ',
            },
            {
                description: 'Today',
                ru: 'Сегодня',
                en: 'Today',
                kz: 'Бүгін',
            },
            {
                description: 'day-1',
                ru: 'Понедельник',
                en: 'Monday',
                kz: 'Дүйсенбі',
            },
            {
                description: 'day-2',
                ru: 'Вторник',
                en: 'Tuesday',
                kz: 'Сейсенбі',
            },
            {
                description: 'day-3',
                ru: 'Среда',
                en: 'Wednesday',
                kz: 'Сәрсенбі',
            },
            {
                description: 'day-4',
                ru: 'Четверг',
                en: 'Thursday',
                kz: 'Бейсенбі',
            },
            {
                description: 'day-5',
                ru: 'Пятница',
                en: 'Friday',
                kz: 'Жұма',
            },
            {
                description: 'day-6',
                ru: 'Суббота',
                en: 'Saturday',
                kz: 'Сенбі',
            },
            {
                description: 'day-7',
                ru: 'Воскресенье',
                en: 'Sunday',
                kz: 'Жексенбі',
            },
            {
                description: 'duration',
                ru: 'Длительность',
                en: 'Duration',
                kz: 'Ұзақтығы',
            },
            {
                description: 'check-count-title',
                ru: 'Таблица временных слотов и доступных учителей',
                en: 'Time slot table and available teachers',
                kz: 'Уақыт кестесі және оқытушылар саны',
            },
            {
                description: 'table-slots-info',
                ru: 'Укажите количество доступных учителей на каждый временной слот. Не более 10 учителей на 1 слот',
                en: 'Specify the number of available teachers for each time slot',
                kz: 'Әр уақыт ұяшығына бар оқытушылардың санын көрсетіңіз',
            },
            {
                description: 'button',
                ru: 'Сохранить настройки',
                en: 'Save settings',
                kz: 'Параметрлерді сақтаңыз',
            },
            {
                description: 'info-calendar-1',
                ru: 'Свернуть календарь',
                en: 'Collapse calendar',
                kz: 'Күнтізбені тасалау',
            },
            {
                description: 'info-calendar-2',
                ru: 'Развернуть календарь',
                en: 'Expand calendar',
                kz: 'Күнтізбені кеңейту',
            },
            {
                description: 'select-placeholder',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'group_name',
                ru: 'Группа',
                en: 'Group',
                kz: 'Топ',
            },
            {
                description: 'discount',
                ru: 'Скидка',
                en: 'Discount',
                kz: 'Жеңілдік',
            },
            {
                description: 'responsible',
                ru: 'Ответственный',
                en: 'Responsible',
                kz: 'Жауапкершісі',
            },
            {
                description: 'phone',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
            {
                description: 'email',
                ru: 'Email',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'group_balance',
                ru: 'Долг',
                en: 'Debt',
                kz: 'Міндет',
            },
            {
                description: 'company_balance',
                ru: 'Общий баланс',
                en: 'Overall balance',
                kz: 'Жалпы баланс',
            },
            {
                description: 'text-1',
                ru: 'Имя или почта студента',
                en: 'Student name or email',
                kz: 'Студенттің аты немесе электрондық поштасы',
            },
            {
                description: 'text-2',
                ru: 'Имя или номер группы',
                en: 'Group name or number',
                kz: 'Топтың аты немесе нөмірі',
            },
            {
                description: 'text-3',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'text-4',
                ru: 'Только мои',
                en: 'Only mine',
                kz: 'Тек менікі',
            },
            {
                description: 'text-5',
                ru: 'Все должники',
                en: 'All debtors',
                kz: 'Барлық борышкерлер',
            },
            {
                description: 'text-7',
                ru: ' Дней ',
                en: ' Days ',
                kz: ' Күндер ',
            },
            {
                description: 'text-8',
                ru: ' 3 Дня ',
                en: ' 3 Days ',
                kz: ' 3 Күн ',
            },
            {
                description: 'text-9',
                ru: ' 5 Дней ',
                en: ' 5 Days ',
                kz: ' 5 Күн',
            },
            {
                description: 'text-10',
                ru: ' 7 Дней ',
                en: ' 7 Days ',
                kz: ' 7 Күн',
            },
            {
                description: 'text-11',
                ru: ' 10 Дней ',
                en: ' 10 Days ',
                kz: ' 10 Күн',
            },
            {
                description: 'text-12',
                ru: 'Список должников',
                en: ' List of debtors ',
                kz: ' Дебиторлардың тізімі',
            },
            {
                description: 'text-13',
                ru: 'Список студентов со скидками',
                en: 'List of students with discounts',
                kz: 'Жеңілдіктері бар студенттердің тізімі',
            },

        ]
    },
    {
        link: 'online-schedule/record-customer',
        translation: [
            {
                description: 'title-1',
                ru: 'Запись клиента',
                en: 'Customer record',
                kz: 'Тапсырыс берушінің жазбасы',
            },
            {
                description: 'title-2',
                ru: 'Редактирование записи клиента:',
                en: 'Editing a customer record:',
                kz: 'Клиенттердің жазбаларын өңдеу:',
            },
            {
                description: 'checkbox-info',
                ru: 'Отобразить слоты доступные онлайн',
                en: 'Show available online recordings',
                kz: 'Қол жетімді онлайн жазбаларын көрсету',
            },
            {
                description: 'error-text',
                ru: 'На этот день нет свободных преподавателей',
                en: 'There are no free teachers for this day',
                kz: 'Бұл күні тегін мұғалімдер жоқ',
            },
            {
                description: 'minutes',
                ru: ' минут ',
                en: ' minutes ',
                kz: ' минут ',
            },
            {
                description: 'indicated',
                ru: 'Не указано',
                en: 'Not indicated',
                kz: 'Көрсетілмеген',
            },
            {
                description: 'time',
                ru: 'Выберите время',
                en: 'Choose time',
                kz: 'Уақытты таңдаңыз',
            },
            {
                description: 'teacher',
                ru: 'Выберите преподавателя',
                en: 'Choose a teacher',
                kz: 'Мұғалімді таңдаңыз',
            },
            {
                description: 'info-time',
                ru: 'Новая локация ',
                en: 'New location',
                kz: 'Жаңа орын',
            },
            {
                description: 'button-close',
                ru: 'Закрыть',
                en: 'Close',
                kz: 'Жабық',
            },
            {
                description: 'button-text-title',
                ru: 'Клиент успешно записан на слот',
                en: 'The client is successfully written to the slot',
                kz: 'Клиент ұяшыққа сәтті жазылды',
            },
            {
                description: 'client-text-1',
                ru: 'Клиент получил уведомление с информацией о записи на электронную почту',
                en: 'User received the booking info over email',
                kz: 'Тіркелу жайылы тұтынушы ақпаратты электронды поштасы арқылы алды',
            },
            {
                description: 'client-text-2',
                ru: 'Изменить или отредактировать запись клиента вы можете на странице "Записанные клиенты".',
                en: 'You may change or update the booking data of client on "Booked clients" page.',
                kz: 'Тіркелген қолданушылар" парағында қолданушының тіркелу ретін өзгертіп немесе жаңарта аласыз.',
            },
            {
                description: 'client-text-3',
                ru: 'Kлиент:',
                en: 'Сlient:',
                kz: 'Kлиенттер:',
            },
            {
                description: 'client-text-4',
                ru: 'записан на слот.',
                en: 'written to the slot.',
                kz: 'ұясына жазылған.',
            },
            {
                description: 'such-error',
                ru: 'Пользователь с таким email уже существует',
                en: 'Such user already exist with this email',
                kz: 'Осы емэйл арқылы тіркелген қолданушы бар',
            },
            {
                description: 'online',
                ru: 'Онлайн',
                en: 'Online',
                kz: 'Желіде',
            },
            {
                description: 'school',
                ru: 'Школа',
                en: 'School',
                kz: 'Школа',
            },
            {
                description: 'info-time-sms',
                ru: 'Отправить SMS сообщение с информацией о бронировании времени',
                en: 'Send SMS info about booked slot',
                kz: 'Тіркелген уақыт туралы СМС жіберу',
            },
            {
                description: 'table-slots-title',
                ru: 'Данные клиента',
                en: 'Client data',
                kz: 'Клиент туралы мәліметтер',
            },
            {
                description: 'table-slots-info',
                ru: 'Внесите данные клиента, если клиент уже создан, начните вводить его имя, e-mail или телефон в поле ниже.',
                en: 'Enter the clients details, if the client is already created, start entering his name, e-mail or phone number in the field below.',
                kz: 'Клиенттің деректемелерін енгізіңіз, егер клиент бұрыннан құрылған болса, төмендегі жолға өзінің атауы, электрондық поштасын немесе телефон нөмірін енгізуді бастаңыз.',
            },
            {
                description: 'placeholder-1',
                ru: 'Начните вводить имя телефон или E-mail клиента',
                en: 'Start entering the customer\'s name, phone number or E-mail',
                kz: 'Клиенттің атауы, телефон нөмірін немесе электрондық поштаны теруді бастаңыз',
            },
            {
                description: 'placeholder-1_1',
                ru: 'Начните вводить название группы',
                en: 'Start entering the customer\'s name, phone number or E-mail',
                kz: 'Клиенттің атауы, телефон нөмірін немесе электрондық поштаны теруді бастаңыз',
            },
            {
                description: 'placeholder-2',
                ru: 'Имя клиента',
                en: 'Client name',
                kz: 'Клиенттің атауы',
            },
            {
                description: 'placeholder-3',
                ru: 'E-mail клиента',
                en: 'Client e-mail',
                kz: 'Клиенттің электронды поштасы',
            },
            {
                description: 'placeholder-4',
                ru: 'Дата рождения',
                en: 'Date of Birth',
                kz: 'туған күні',
            },
            {
                description: 'placeholder-5',
                ru: 'Фамилия клиента',
                en: 'Client surname',
                kz: 'Клиенттің тегі',
            },
            {
                description: 'placeholder-6',
                ru: 'Телефон клиента',
                en: 'Customer phone',
                kz: 'Тұтынушы телефоны',
            },
            {
                description: 'placeholder-7',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Жасыңызды таңдаңыз',
            },
            {
                description: 'placeholder-8',
                ru: 'Отчество клиента',
                en: 'Client\'s patronymic',
                kz: 'Клиенттің әкесінің атауы',
            },
            {
                description: 'placeholder-9',
                ru: 'Уровень письменного теста',
                en: 'Written test level',
                kz: 'Жазбаша тест деңгейі',
            },
            {
                description: 'placeholder-10',
                ru: 'Программа обучения',
                en: 'Training program',
                kz: 'Оқу бағдарламасы',
            },
            {
                description: 'placeholder-11',
                ru: 'Нет совпадений',
                en: 'No matches',
                kz: 'Сәйкестіктер жоқ',
            },
            {
                description: 'placeholder-12',
                ru: 'Возрастная категория',
                en: 'Choose your age',
                kz: 'Жасыңызды таңдаңыз',
            },
            {
                description: 'button',
                ru: 'Записать клиента',
                en: 'Register customer',
                kz: 'Клиентті тіркеу',
            },
            {
                description: 'info-calendar-1',
                ru: 'Свернуть календарь',
                en: 'Collapse calendar',
                kz: 'Күнтізбені тасалау',
            },
            {
                description: 'info-calendar-2',
                ru: 'Развернуть календарь',
                en: 'Expand calendar',
                kz: 'Күнтізбені кеңейту',
            },
            {
                description: 'select-placeholder',
                ru: 'Выберите локацию',
                en: 'Choose a location',
                kz: 'Орынды таңдаңыз',
            },
        ]
    },
    {
        link: 'online-schedule/booked-online-schedule',
        translation: [
            {
                description: 'title',
                ru: 'Расписание записи клиентов',
                en: 'Clients Recording Schedule',
                kz: 'Клиенттерді тіркеу кестесі',
            },
            {
                description: 'info-calendar-1',
                ru: 'Свернуть календарь',
                en: 'Collapse calendar',
                kz: 'Күнтізбені тасалау',
            },
            {
                description: 'info-calendar-2',
                ru: 'Развернуть календарь',
                en: 'Expand calendar',
                kz: 'Күнтізбені кеңейту',
            },
            {
                description: 'select-placeholder',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'text-info',
                ru: 'Основные данные',
                en: 'Basic data',
                kz: 'Негізгі мәліметтер',
            },
            {
                description: 'text-customer',
                ru: 'Прошедшие записи',
                en: 'Past customer',
                kz: 'Өткен жазбалары',
            },
        ]
    },
    {
        link: 'online-schedule/booked-teachers-schedule',
        translation: [
            {
                description: 'title',
                ru: 'Расписание преподавателей',
                en: 'Time schedule of teachers\'',
                kz: 'Оқытушылар кестесі',
            },
            {
                description: 'select-placeholder',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'text-info',
                ru: 'Не указано',
                en: 'Not indicated',
                kz: 'Көрсетілмеген',
            },
            {
                description: 'text-info-2',
                ru: 'Удаление слота',
                en: 'Removing slot',
                kz: 'Саңылауды алып тастау',
            },
            {
                description: 'text-info-3',
                ru: 'Скрыть расписание',
                en: 'Hide schedule',
                kz: 'Кестені жасыру',
            },
            {
                description: 'text-info-4',
                ru: 'Показать расписание',
                en: 'Show schedule',
                kz: 'Сабақ кестесін көрсету',
            },
            {
                description: 'profile',
                ru: 'Профиль',
                en: 'Profile',
                kz: 'профилім',
            },
        ]
    },
    {
        link: 'online-schedule/past-slots',
        translation: [
            {
                description: 'title',
                ru: 'Прошедшие записи клиентов',
                en: 'Past customer records',
                kz: 'Өткен клиенттердің жазбалары',
            },
            {
                description: 'span-one',
                ru: 'Результат теста',
                en: 'Test result',
                kz: 'Тест нәтижесі',
            },
            {
                description: 'span-two',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'span-five',
                ru: 'УКАЗАТЬ КОММЕНТАРИЙ',
                en: 'SPECIFY COMMENT',
                kz: 'Арнайы түсініктеме',
            },
            {
                description: 'button',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'online-schedule/future-slots',
        translation: [
            {
                description: 'title',
                ru: 'Клиенты',
                en: 'Clients',
                kz: 'Клиенттер',
            },
            {
                description: 'text-1',
                ru: ' дней ',
                en: ' days ',
                kz: ' күндер ',
            },
            {
                description: 'text-2',
                ru: ' часов ',
                en: ' hours ',
                kz: ' сағат ',
            },
            {
                description: 'text-3',
                ru: ' минут ',
                en: ' minutes ',
                kz: ' минут ',
            },
            {
                description: 'text-4',
                ru: ' год',
                en: ' year ',
                kz: ' жыл ',
            },
            {
                description: 'text-5',
                ru: ' года',
                en: ' years ',
                kz: ' жыл ',
            },
            {
                description: 'text-6',
                ru: ' лет',
                en: ' years',
                kz: ' жыл',
            },
            {
                description: 'span-one',
                ru: 'начнется через',
                en: 'will start in',
                kz: 'басталады',
            },
            {
                description: 'span-two',
                ru: 'Новая локация',
                en: 'New location',
                kz: 'Жаңа орын',
            },
            {
                description: 'span-three',
                ru: 'Пароль для входа в конференцию:',
                en: 'Password to enter the conference:',
                kz: 'Конференцияға кіру үшін арналған пароль:',
            },
            {
                description: 'button',
                ru: 'Перейти в ZOOM',
                en: 'Go to ZOOM',
                kz: 'ZOOM өтіңіз',
            },
            {
                description: 'warning',
                ru: 'После выбора уровня, карточка сразу попадает в прошедшие записи',
                en: 'After choosing a level, the card will be shown in past records',
                kz: 'Деңгейді таңдағаннан кейін карточка сол сәтте өткен жазбаларда көрінеді',
            },
            {
                description: 'warning-1',
                ru: 'ВНИМАНИЕ!',
                en: 'ATTENTION!',
                kz: 'НАЗАР АУДАРЫҢЫЗ!',
            },
            {
                description: 'comment',
                ru: 'Комментарий:',
                en: 'Comment',
                kz: 'Пікір',
            },
            {
                description: 'text_1',
                ru: 'Пропустил',
                en: 'Missed',
                kz: 'Қатыспады',
            },
            {
                description: 'text_2',
                ru: 'Не подключился',
                en: 'Not connected',
                kz: 'Қосылмады',
            },
            {
                description: 'text_3',
                ru: 'Проблемы со связью',
                en: 'Connection problems',
                kz: 'Байланыс проблемасы',
            },
            {
                description: 'text_online',
                ru: '(Онлайн)',
                en: '(Online)',
                kz: '(Онлайн)',
            },
            {
                description: 'text_4',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'text_5',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Болдырмау',
            },
            {
                description: 'text_6',
                ru: 'Не состоялось',
                en: 'Didn\'t take place',
                kz: 'Болған жоқ',
            },
            {
                description: 'text_7',
                ru: 'Состоялось',
                en: 'It took place',
                kz: 'Болды',
            },
            {
                description: 'text_8',
                ru: 'Детали',
                en: 'Details',
                kz: 'Егжейі',
            },
            {
                description: 'text_9',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'text_10',
                ru: 'Информация ученика',
                en: 'Student information',
                kz: 'Оқушының ақпараты',
            },
            {
                description: 'text_11',
                ru: 'Пропустил',
                en: 'Missed',
                kz: 'Болмады',
            },
            {
                description: 'text_12',
                ru: 'Выберите уровень',
                en: 'Choose a level',
                kz: 'Деңгейін таңдаңыз',
            },
            {
                description: 'text_13',
                ru: 'Данные клиента:',
                en: 'User data',
                kz: ' Қолданушы деректері',
            },
            {
                description: 'text_14',
                ru: 'Для прохождения тестирования вам необходимо быть в ',
                en: 'To be tested, you need to be at ',
                kz: 'Тесттен өту үшін сізге ',
            },
            {
                description: 'text_16',
                ru: 'за 15 минут до начала тестирования.',
                en: '15 minutes before the start of testing.',
                kz: 'тест басталардан 15 минут бұрын.',
            },
            {
                description: 'text_19',
                ru: 'Изменить',
                en: 'Change',
                kz: 'Өзгерту',
            },
            {
                description: 'text_20',
                ru: 'Напишите комментарий',
                en: 'Write a comment',
                kz: 'Пікір жазыңыз',
            },
            {
                description: 'text_21',
                ru: 'Сохранение',
                en: 'Preservation',
                kz: 'Сақтау',
            },
            {
                description: 'text_22',
                ru: 'Показать',
                en: 'Show',
                kz: 'Көрсету ',
            },
            {
                description: 'text_23',
                ru: 'Записей',
                en: 'Entries',
                kz: 'Жазбалар',
            },
            {
                description: 'text_24',
                ru: 'Ошибка сохранения, попробуйте позднее',
                en: 'Saving error, please try again later',
                kz: 'Сақтау қатесі, әрекетті кейінірек қайталаңыз ',
            },
            {
                description: 'text_25',
                ru: 'Вы уверены, что хотите установить пропуск для клиента?',
                en: 'Are you sure you want to set a pass for the client?',
                kz: 'Клиент үшін рұқсатты орнатқыңыз келетініне сенімдісіз бе?',
            },
            {
                description: 'text_26',
                ru: 'После выбора, карточка клиента будет перенесена в прошедшие записи',
                en: 'After selection, the client card will be transferred to past records  ',
                kz: 'Таңдаудан кейін клиент картасы бұрынғы жазбаларға ауыстырылады ',
            },
            {
                description: 'text_27',
                ru: 'Вы уверены, что хотите установить уровень для клиента?',
                en: 'Are you sure you want to set the level for the client?',
                kz: 'Клиент үшін деңгейді орнатқыңыз келетініне сенімдісіз бе?',
            },
            {
                description: 'text_28',
                ru: 'После выбора уровня, карточка клиента будет перенесена в прошедшие записи',
                en: 'After choosing a level, the client card will be transferred to past records',
                kz: 'Деңгейді таңдағаннан кейін клиент картасы бұрынғы жазбаларға ауыстырылады',
            },
            {
                description: 'text_29',
                ru: 'Перейти в конференцию ZOOM',
                en: 'Go to the ZOOM meeting',
                kz: 'ZOOM жиналысына өтіңіз ',
            },
            {
                description: 'text_30',
                ru: 'Добавьте комментарий',
                en: 'Add a comment',
                kz: 'Пікір қосу',
            },
            {
                description: 'text_31',
                ru: 'Выберите уровень',
                en: 'Choose a level',
                kz: 'Деңгейді таңдаңыз',
            },
            {
                description: 'text_32',
                ru: 'Будущие записи клиентов',
                en: 'Future customer records ',
                kz: 'Болашақ тұтынушылар жазбалары',
            },
            {
                description: 'text_33',
                ru: 'Прошедшие записи клиентов',
                en: 'Past customer records',
                kz: 'Бұрынғы тұтынушылар жазбалары ',
            },
            {
                description: 'text_34',
                ru: 'По имени, по возрастанию',
                en: 'By name, ascending ',
                kz: 'Аты бойынша, жоғарылау ',
            },
            {
                description: 'text_35',
                ru: 'По имени, по убыванию',
                en: 'By name, descending ',
                kz: 'Аты бойынша, төмендеу ',
            },
            {
                description: 'text_36',
                ru: 'По дате, по возрастанию',
                en: 'By date, ascending ',
                kz: 'Күні бойынша, жоғарылау  ',
            },
            {
                description: 'text_37',
                ru: 'По дате, по убыванию',
                en: 'By date, descending ',
                kz: 'Күні бойынша, төмендеу ',
            },
        ]
    },
    {
        link: 'online-schedule/past-online-schedule',
        translation: [
            {
                description: 'title',
                ru: 'Прошедшие клиенты',
                en: 'Clients passe',
                kz: 'Өткен клиенттер',
            },
            {
                description: 'sub-menu-button-1',
                ru: 'Клиенты без ответственного',
                en: 'Customers without responsibility',
                kz: 'Клиенттер жауапкершіліксіз',
            },
            {
                description: 'sub-menu-button-2',
                ru: 'Мои клиенты',
                en: 'My clients',
                kz: 'Менің клиенттерім',
            },
            {
                description: 'button-one',
                ru: 'Отправить договор',
                en: 'Send a contract',
                kz: 'Топты таңдаңыз',
            },
            {
                description: 'button-two',
                ru: 'Подобрать группу',
                en: 'Choose a group',
                kz: 'Топты таңдаңыз',
            },
            {
                description: 'button-three',
                ru: 'Указать ответственного',
                en: 'Indicate responsible',
                kz: 'Жауапкершілікті көрсетіңіз',
            },
        ]
    },
    {
        link: 'online-schedule/record-customer-error',
        translation: [
            {
                description: 'text-1',
                ru: 'Ошибки',
                en: 'Errors',
                kz: 'Қателер',
            },
            {
                description: 'text-2',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'text-3',
                ru: 'необходимо указать ваше имя',
                en: 'need to indicate your name',
                kz: 'есіміңізді енгізіңіз',
            },
            {
                description: 'text-4',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'text-44',
                ru: 'Отчество',
                en: 'Middle name',
                kz: 'әкесінің аты',
            },
            {
                description: 'text-5',
                ru: 'необходимо указать вашу фамилию',
                en: 'need to indicate your surname',
                kz: 'тегіңізді енгізіңіз',
            },
            {
                description: 'text-6',
                ru: 'введен некорректный email',
                en: 'incorrect email entered',
                kz: 'қате емэйл енгізілді',
            },
            {
                description: 'text-7',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
            {
                description: 'text-8',
                ru: 'должен содержать не менее 7 символов',
                en: '7 symbols should be at least',
                kz: 'аз дегенде 7 таңба болу керек',
            },
            {
                description: 'text-9',
                ru: 'Уровень письменного теста',
                en: 'Level of written testing',
                kz: 'Жазбаша тесттің деңгейі',
            },
            {
                description: 'text-10',
                ru: 'укажите уровень письменного теста',
                en: 'Indicate the level of written testing',
                kz: 'жазбаша тесттің деңгейін енгізіңіз',
            },
            {
                description: 'text-11',
                ru: 'Дата рождения',
                en: 'Birthday',
                kz: 'Туған күні',
            },
            {
                description: 'text-12',
                ru: 'необходимо указать вашу дату рождения',
                en: 'need to indicate your birthday',
                kz: 'Туған күнін енгізіңіз',
            },
            {
                description: 'text-13',
                ru: 'Возрастная категория',
                en: 'Age category',
                kz: 'Жас категориясы',
            },
            {
                description: 'text-14',
                ru: 'выберите возрастную категорию',
                en: 'choose age category',
                kz: 'жас категориясын таңдаңыз',
            },
            {
                description: 'text-15',
                ru: 'Программа обучения',
                en: 'Education program',
                kz: 'Оқыту бағдарламасы',
            },
            {
                description: 'text-16',
                ru: 'выберите программу обучения',
                en: 'choose education program',
                kz: 'оқыту бағдарламасын таңдаңыз',
            },
            {
                description: 'text-17',
                ru: 'Онлайн запись на интервью',
                en: 'Online record for oral testing InterPress',
                kz: 'Интерпресс ауызша тестілеуге онлайн тіркеу',
            },
            {
                description: 'text-18',
                ru: 'Подтвердите ваши данные',
                en: 'Confirm your data',
                kz: 'Ақпаратыңызды растаңыз',
            },
            {
                description: 'text-19',
                ru: 'Оплата принимается только с помощью банковской карты',
                en: 'Payment accepted via banking card only',
                kz: 'Төлемді тек банк карточкасы арқылы қабылдайды',
            },
            {
                description: 'text-20',
                ru: 'Стоимость:',
                en: 'Cost',
                kz: 'Бағасы',
            },
            {
                description: 'text-21',
                ru: '500 тг',
                en: '500 tenge',
                kz: '500 теңге',
            },
            {
                description: 'text-22',
                ru: 'Время оплаты:',
                en: 'Payment time',
                kz: 'Төлем уақыты',
            },
            {
                description: 'text-23',
                ru: 'не более 15 минут',
                en: 'Not more than 15 minutes',
                kz: '15 минуттан көп емес',
            },
            {
                description: 'text-24',
                ru: 'Забронировать время и перейти к оплате',
                en: 'Book the time and go ahead for payment',
                kz: 'Уақытын бекітіп төлемге өту',
            },

        ]
    },
    {
        link: 'online-schedule/clients-after-online',
        translation: [
            {
                description: 'placeholder_1',
                ru: 'Введите ФИО, Email или телефон',
                en: 'Enter full name, email and hone',
                kz: 'Атыжөнін, Емэйл және телефонын енгізіңіз',
            },
            {
                description: 'placeholder_2',
                ru: 'Дата прохождения теста',
                en: 'Test passed date',
                kz: 'Тест өткен күн',
            },
            {
                description: 'placeholder_3',
                ru: 'Преподаватель',
                en: 'Teacher',
                kz: 'Мұғалімі',
            },
            {
                description: 'text_1',
                ru: 'История записей клиентов',
                en: 'Customer record history',
                kz: 'Тұтынушылардың жазбалар тарихы',
            },
            {
                description: 'text_2',
                ru: 'Записей:',
                en: 'Records:',
                kz: 'Жазбалары:',
            },
            {
                description: 'menuName_1',
                ru: 'Редактировать клиента',
                en: 'Edit client',
                kz: 'Клиентті өңдеу',
            },
            {
                description: 'menuName_2',
                ru: 'Профиль клиента',
                en: 'Client profile',
                kz: 'Клиенттің профилі',
            },
            {
                description: 'menuName_3',
                ru: 'Подобрать группу',
                en: 'Choose a group',
                kz: 'Топты таңдау',
            },
            {
                description: 'menuName_4',
                ru: 'В ожидание',
                en: 'Pending',
                kz: 'Кезектегі',
            },
            {
                description: 'menuName_5',
                ru: 'Детали',
                en: 'Details',
                kz: 'Егжейі',
            },
            {
                description: 'text_3',
                ru: 'Информация ученика',
                en: 'Student information',
                kz: 'Студент ақпараты',
            },
            {
                description: 'text_4',
                ru: 'Перейти в профиль',
                en: 'Go to profile',
                kz: 'Профильге кіріңіз',
            },
            {
                description: 'text_6',
                ru: 'Детали',
                en: 'Details',
                kz: 'Егжейі',
            },
            {
                description: 'text_7',
                ru: 'Перейти в группу',
                en: 'Go to group',
                kz: 'Топқа өтіңіз',
            }
        ]
    },
    {
        link: 'company/profiles/account',
        translation: [
            {
                description: 'button-one',
                ru: 'Редактировать',
                en: 'Edit',
                kz: 'Өңдеу',
            },
            {
                description: 'teacher-info-1',
                ru: 'Возраст:',
                en: 'Age:',
                kz: 'Жасы:',
            },
            {
                description: 'status_undef',
                ru: 'Статус не указан',
                en: 'No status specified',
                kz: 'Күй көрсетілмеген',
            },
            {
                description: 'additionalContacts',
                ru: 'Дополнительные контакты',
                en: 'Additional contacts',
                kz: 'Қосымша контактілер'
            },
            {
                description: 'addContactPErson',
                ru: 'Добавить контактное лицо',
                en: 'Add contact person',
                kz: 'Байланысатын адамды қосыңыз'
            },
            {
                description: 'clitab2',
                ru: 'Балансы',
                en: 'Balances',
                kz: 'Баланс'
            },
            {
                description: 'clitab3',
                ru: 'Сертификаты',
                en: 'Certificates',
                kz: 'Сертификаттар'
            },
            {
                description: 'clitab4',
                ru: 'Связанные аккаунты',
                en: 'Linked invoice',
                kz: 'Байланыстырылған есептік жазбалар'
            },
            {
                description: 'clitab6',
                ru: 'Оценки',
                en: 'Evaluations',
                kz: 'Бағалау'
            },
            {
                description: 'addPhone',
                ru: 'Добавить телефон',
                en: 'Add phone',
                kz: 'Add a phone',
            },
            {
                description: 'areYouSure',
                ru: 'Вы уверены, что хотите удалить контактное лицо:',
                en: 'Are you sure you want to delete the contact person:',
                kz: 'Байланыс жасайтын адамды шынымен жойғыңыз келе ме:',
            },
            {
                description: 'teacher-info-2',
                ru: 'Основная локация:',
                en: 'Main location:',
                kz: 'Негізгі орналасқан жері:',
            },
            {
                description: 'error-text-1',
                ru: 'Состоять как минимум из 8 символов',
                en: 'Minimum length of password is 8 symbols',
                kz: 'Аз дегенде 8 та?ба болу керек парольде',
            },
            {
                description: 'error-1-letter',
                ru: 'Содержать как минимум 1 букву (a, b, c...)',
                en: 'Contain at least 1 letter (a, b, c...)',
                kz: 'Кем дегенде 1 әріпті қамтуы керек (a, b, c...)',
            },
            {
                description: 'error-1-digit',
                ru: 'Содержать как минимум 1 цифру (1, 2, 3...)',
                en: 'Contain at least 1 digit (1, 2, 3 ...)',
                kz: 'Кемінде 1 цифрдан тұрады (1, 2, 3 ...)',
            },
            {
                description: 'error-meet',
                ru: 'Новый пароль должен соответствовать всем требованиям',
                en: 'The new password must meet all the requirements',
                kz: 'Жаңа пароль барлық талаптарға сай болуы керек',
            },
            {
                description: 'new-pass-meet',
                ru: 'Новый пароль должен',
                en: 'The new password must',
                kz: 'Жаңа пароль барлық',
            },
            {
                description: 'error-oldpass',
                ru: 'Введите текущий пароль',
                en: 'Enter the current password',
                kz: 'Ағымдағы парольді енгізіңіз',
            },
            {
                description: 'error-text-2',
                ru: 'Новый пароль и его повтор должны полностью совпадать',
                en: 'Entered passwords are not equal',
                kz: 'Еңгізілген парольдар бірдей емес',
            },
            {
                description: 'error-text-3',
                ru: 'Такого пользователя не существует, или Вам отказано в доступе',
                en: 'There is no such user or access is denied',
                kz: 'Ондай қолданушы жоқ немесе сізге кіруге рұқсат жоқ',
            },
            {
                description: 'error-text-4',
                ru: 'Не установлено',
                en: 'Not installed',
                kz: 'Орнатылмаған',
            },
            {
                description: 'button-two',
                ru: 'Активный',
                en: 'Active',
                kz: 'Белсенді',
            },
            {
                description: 'button-three',
                ru: 'Неактивный',
                en: 'Inactive',
                kz: 'Белсенді емес',
            },
            {
                description: 'determined',
                ru: 'Определяется',
                en: 'Determined',
                kz: 'Анықталды',
            },
            {
                description: 'add',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'text-info',
                ru: 'Основная информация',
                en: 'Basic information',
                kz: 'Негізгі ақпарат',
            },
            {
                description: 'title-table-1',
                ru: 'Дисциплины:',
                en: 'Disciplines:',
                kz: 'Оқытатын пәндері:',
            },
            {
                description: 'title-table-2',
                ru: 'Возрастные категории:',
                en: 'Age categories:',
                kz: 'Жас санаттары:',
            },
            {
                description: 'title-table-3',
                ru: 'Учебные категории:',
                en: 'Educational categories:',
                kz: 'Оқу категориялары:',
            },
            {
                description: 'title-table-4',
                ru: 'Локации:',
                en: 'Locations:',
                kz: 'Орналасқан жері:',
            },
            {
                description: 'text_1',
                ru: 'Укажите статус',
                en: 'Indicate status',
                kz: 'Күйді көрсетіңіз',
            },
            {
                description: 'modal_title_1',
                ru: 'Загрузить',
                en: 'Download',
                kz: 'ОЖүктеу',
            },
            {
                description: 'modal_title_2',
                ru: 'Загрузить изображение',
                en: 'Upload image',
                kz: 'Кескінді жүктеңіз',
            },
        ]
    },
    {
        link: 'online-schedule/schedule-setting',
        translation: [
            {
                description: 'title',
                ru: 'Настройка расписания',
                en: 'Schedule setup',
                kz: 'Кестені орнату',
            },
            {
                description: 'save-settings',
                ru: 'Сохранить настройки',
                en: 'Save settings',
                kz: 'Параметрлерді сақтаңыз',
            },
            {
                description: 'checkbox-info',
                ru: 'Доступен для онлайн записи',
                en: 'Available for online appointment',
                kz: 'Интернеттегі кездесуге де қол жетімді',
            },
            {
                description: 'select-placeholder',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'text-calendar-1',
                ru: 'Свернуть календарь',
                en: 'Collapse calendar',
                kz: 'Күнтізбені тасалау',
            },
            {
                description: 'text-calendar-2',
                ru: 'Развернуть календарь',
                en: 'Expand calendar',
                kz: 'Күнтізбені кеңейтуу',
            },
            {
                description: 'choice-slot',
                ru: 'Выберите время',
                en: 'Choose slot',
                kz: 'Уақытты таңдаңыз',
            },
            {
                description: 'text-slot-info-1',
                ru: 'Доступные временные слоты по выбранной локации',
                en: 'location',
                kz: 'аралықтары',
            },
            {
                description: 'text-slot-info-2',
                ru: 'Месячная нагрузка согласно контракта:',
                en: 'Monthly load according to the contract:',
                kz: 'Шарт бойынша айлық жүктеме:',
            },
            {
                description: 'text-slot-info-3',
                ru: 'Необходимо отработать: ',
                en: 'It is necessary to work out:',
                kz: 'Мыналарды пысықтау керек:',
            },
            {
                description: 'text-slot-info-4',
                ru: 'На этот день нет доступных слотов',
                en: 'There is no available slot for the day',
                kz: 'Осы күнге бос орын жоқ',
            },
            {
                description: 'close-slot-label',
                ru: 'Закрытый слот',
                en: 'Closed slot',
                kz: 'Жабық ойық',
            },
        ]
    },
    {
        link: 'pays/create-pay',
        translation: [
            {
                description: 'title',
                ru: 'Пополнение баланса',
                en: 'Balance replenishment',
                kz: 'Балансты толтыру',
            },
            {
                description: 'title-info-one',
                ru: 'Не забывайте пополнять баланс вашего личного кабинета, чтобы в любое время суток иметь возможность оплатить услуги нашей компании',
                en: 'Do not forget to top up the balance of your personal payment so that you can pay for the services of our company at any time of the day.',
                kz: 'Сіздің жеке шотыңыздың балансын толтыруды ұмытпаңыз, осылайша сіз біздің компанияның қызметтері үшін тәуліктің кез келген уақытында төлей аласыз.',
            },
            {
                description: 'pay-text-2',
                ru: 'Яндекс деньги',
                en: 'Yandex money',
                kz: 'Яндекс ақшасы',
            },
            {
                description: 'pay-text-3',
                ru: 'PayPal',
                en: 'PayPal',
                kz: 'PayPal',
            },
            {
                description: 'pay-text-4',
                ru: 'Приложение Kaspi.kz',
                en: 'Kaspi.kz application',
                kz: 'Kaspi.kz қосымшасы',
            },
            {
                description: 'pay-text-5',
                ru: 'Наличными в кассу',
                en: 'Cash to the cashier',
                kz: 'Кассирге қолма-қол ақша',
            },
            {
                description: 'text-info-3',
                ru: 'Оплата с помощью Яндекс Денег',
                en: 'Payment using Yandex Money',
                kz: 'Yandex Money көмегімен төлем',
            },
            {
                description: 'text-info-4',
                ru: 'Для оплаты вы можете использовать баланс кошелька Яндекс Денег или оплатить с помощью привязанной к аккаунту карты.',
                en: 'For payment, you can use the balance of the Yandex Money wallet or pay using a card linked to your payment.',
                kz: 'Төлем үшін сіз Yandex Money әмиянының балансын пайдалана аласыз немесе шотқа байланыстырылған картаны пайдаланып төлей аласыз.',
            },
            {
                description: 'text-info-5',
                ru: 'Оплата с помощью PayPal',
                en: 'Pay with PayPal',
                kz: 'PayPal арқылы төлеңіз',
            },
            {
                description: 'text-info-6',
                ru: 'Для оплаты вы можете использовать баланс аккаунта PayPal или оплатить с помощью привязанной к аккаунту карты.',
                en: 'For payment, you can use the balance of your PayPal payment or pay using the card linked to your payment.',
                kz: 'Төлем жасау үшін сіз PayPal шотыңыздың балансын пайдалана аласыз немесе өзіңіздің шотыңызға байланған картаны пайдаланып төлей аласыз.',
            },
            {
                description: 'text-info-7',
                ru: 'Оплата с помощью Kaspi.kz',
                en: 'Payment via Kaspi.kz',
                kz: 'Kaspi.kz арқылы төлеу',
            },
            {
                description: 'text-info-9',
                ru: '1. Авторизоваться на сайте Kaspi.kz',
                en: '1. Log in to the Kaspi.kz website',
                kz: '1. Kaspi.kz сайтына кіріңіз',
            },
            {
                description: 'text-info-10',
                ru: '2. Перейти в раздел "Платежи", далее введите в строке поиска "InterPress".',
                en: '2. Go to the "Payments" section, then enter "InterPress" in the search bar.',
                kz: '2. «Төлемдер» бөліміне өтіп, іздеу жолағына «InterPress» енгізіңіз.',
            },
            {
                description: 'text-info-12',
                ru: '3. В открывшейся форие укажите номер счета, или свой логин (e-mail) от личного кабинета "InterPress", сумму пополнения и нажмите кнопку "Продолжить".',
                en: '3. In the opened forie specify the payment number, or your username (e-mail) from the personal payment "InterPress", the amount of replenishment and click "Continue".',
                kz: '3. Ашылған сауалда шотыңыздың нөмірін немесе «InterPress» жеке шотыңыздағы логиніңізді (электронды поштаңызды), толтырудың мөлшерін көрсетіңіз және «Жалғастыру» түймесін басыңыз.',
            },
            {
                description: 'text-info-13',
                ru: 'Оплата наличными в кассе',
                en: 'Cash payment at the checkout',
                kz: 'Кассада қолма-қол ақшаны төлеу',
            },
            {
                description: 'text-info-14',
                ru: 'Для оплаты наличными вам необходимо обратиться на ресепшн, для выписки счета и оплатить выставленный счет в кассе школы.',
                en: 'To pay in cash, you need to contact the reception, to issue an invoice and pay the invoice at the school\'s cash desk.',
                kz: 'Қолма-қол ақшамен төлеу үшін сіз қабылдау бөлмесімен байланысып, шот-фактураны жазып, шотты мектептің кассасында төлеуіңіз керек.',
            },
            {
                description: 'button-text-1',
                ru: 'Оплатить',
                en: 'Pay',
                kz: 'Төлеу',
            },
            {
                description: 'button-text-2',
                ru: 'тенге',
                en: 'tenge',
                kz: 'теңгені құрады',
            },
            {
                description: 'text-info-8',
                ru: 'Для оплаты с помощью Kaspi.kz вам необходимо:',
                en: 'To pay using Kaspi.kz you need:',
                kz: 'Kaspi.kz арқылы төлеу үшін сізге қажет:',
            },
        ]
    },
    {
        link: 'pays/payment/balance',
        translation: [
            {
                description: 'title',
                ru: 'Пополнение баланса',
                en: 'Balance replenishment',
                kz: 'Балансты толтыру',
            },
            {
                description: 'title-info-one',
                ru: 'Не забывайте пополнять баланс вашего личного кабинета, чтобы в любое время суток иметь возможность оплатить услуги нашей компании',
                en: 'Do not forget to top up the balance of your personal payment so that you can pay for the services of our company at any time of the day',
                kz: 'Сіздің жеке шотыңыздың балансын толтыруды ұмытпаңыз, осылайша сіз біздің компанияның қызметтері үшін тәуліктің кез келген уақытында төлей аласыз.',
            },
            {
                description: 'title-info-two',
                ru: 'Шаг 1:',
                en: 'Step 1:',
                kz: '1-қадам:',
            },
            {
                description: 'title-info-three',
                ru: 'введите сумму пополнения',
                en: 'enter the amount of replenishment',
                kz: 'толтыру мөлшерін енгізіңіз',
            },
            {
                description: 'button-value',
                ru: 'тенге',
                en: 'tenge',
                kz: 'теңгені құрады',
            },
            {
                description: 'title-info-four',
                ru: 'Шаг 2:',
                en: 'Step 2:',
                kz: '2-қадам:',
            },
            {
                description: 'title-info-five',
                ru: 'выберите способ пополнения',
                en: 'choose a deposit method',
                kz: 'салым әдісін таңдаңыз',
            },
            {
                description: 'pay-text-1',
                ru: 'Банковская карта',
                en: 'Bank card',
                kz: 'Банк картасы',
            },
            {
                description: 'pay-text-2',
                ru: 'Яндекс деньги',
                en: 'Yandex money',
                kz: 'Яндекс ақшасы',
            },
            {
                description: 'pay-text-3',
                ru: 'PayPal',
                en: 'PayPal',
                kz: 'PayPal',
            },
            {
                description: 'pay-text-4',
                ru: 'Приложение Kaspi.kz',
                en: 'Kaspi.kz application',
                kz: 'Kaspi.kz қосымшасы',
            },
            {
                description: 'pay-text-5',
                ru: 'Наличными в кассу',
                en: 'Cash to the cashier',
                kz: 'Кассирге қолма-қол ақша',
            },
            {
                description: 'text-info-1',
                ru: 'Оплата банковскими картами',
                en: 'Payment by bank cards',
                kz: 'Банктік карталармен төлеу',
            },
            {
                description: 'text-info-2',
                ru: 'Для оплаты вам потребуется ввести в веб-форму данные любой вашей действительной банковской карты Visa или MasterCard.',
                en: 'To pay, you will need to enter the details of any of your valid Visa or MasterCard into a web form.',
                kz: 'Төлеу үшін сізге кез келген жарамды Visa немесе MasterCard мәліметтерін веб-пішінге енгізу қажет.',
            },
            {
                description: 'text-info-3',
                ru: 'Оплата с помощью Яндекс Денег',
                en: 'Payment using Yandex Money',
                kz: 'Yandex Money көмегімен төлем',
            },
            {
                description: 'text-info-4',
                ru: 'Для оплаты вы можете использовать баланс кошелька Яндекс Денег или оплатить с помощью привязанной к аккаунту карты.',
                en: 'For payment, you can use the balance of the Yandex Money wallet or pay using a card linked to your payment.',
                kz: 'Төлем үшін сіз Yandex Money әмиянының балансын пайдалана аласыз немесе шотқа байланыстырылған картаны пайдаланып төлей аласыз.',
            },
            {
                description: 'text-info-5',
                ru: 'Оплата с помощью PayPal',
                en: 'Pay with PayPal',
                kz: 'PayPal арқылы төлеңіз',
            },
            {
                description: 'text-info-6',
                ru: 'Для оплаты вы можете использовать баланс аккаунта PayPal или оплатить с помощью привязанной к аккаунту карты.',
                en: 'For payment, you can use the balance of your PayPal payment or pay using the card linked to your payment.',
                kz: 'Төлем жасау үшін сіз PayPal шотыңыздың балансын пайдалана аласыз немесе өзіңіздің шотыңызға байланған картаны пайдаланып төлей аласыз.',
            },
            {
                description: 'text-info-7',
                ru: 'Оплата с помощью Kaspi.kz',
                en: 'Payment via Kaspi.kz',
                kz: 'Kaspi.kz арқылы төлеу',
            },
            {
                description: 'text-info-9',
                ru: '1. Авторизоваться на сайте Kaspi.kz',
                en: '1. Log in to the Kaspi.kz website',
                kz: '1. Kaspi.kz сайтына кіріңіз',
            },
            {
                description: 'text-info-10',
                ru: '2. Перейти в раздел "Платежи", далее введите в строке поиска "InterPress".',
                en: '2. Go to the "Payments" section, then enter "InterPress" in the search bar.',
                kz: '2. «Төлемдер» бөліміне өтіп, іздеу жолағына «InterPress» енгізіңіз.',
            },
            {
                description: 'text-info-12',
                ru: '3. В открывшейся форие укажите номер счета, или свой логин (e-mail) от личного кабинета "InterPress", сумму пополнения и нажмите кнопку "Продолжить".',
                en: '3. In the opened forie specify the payment number, or your username (e-mail) from the personal payment "InterPress", the amount of replenishment and click "Continue".',
                kz: '3. Ашылған сауалда шотыңыздың нөмірін немесе «InterPress» жеке шотыңыздағы логиніңізді (электронды поштаңызды), толтырудың мөлшерін көрсетіңіз және «Жалғастыру» түймесін басыңыз.',
            },
            {
                description: 'text-info-13',
                ru: 'Оплата наличными в кассе',
                en: 'Cash payment at the checkout',
                kz: 'Кассада қолма-қол ақшаны төлеу',
            },
            {
                description: 'text-info-14',
                ru: 'Для оплаты наличными вам необходимо обратиться на ресепшн, для выписки счета и оплатить выставленный счет в кассе школы.',
                en: 'To pay in cash, you need to contact the reception, to issue an invoice and pay the invoice at the school\'s cash desk.',
                kz: 'Қолма-қол ақшамен төлеу үшін сіз қабылдау бөлмесімен байланысып, шот-фактураны жазып, шотты мектептің кассасында төлеуіңіз керек.',
            },
            {
                description: 'button-text-1',
                ru: 'Оплатить',
                en: 'Pay',
                kz: 'Төлеу',
            },
            {
                description: 'button-text-2',
                ru: 'тенге',
                en: 'tenge',
                kz: 'теңгені құрады',
            },
            {
                description: 'text-info-8',
                ru: 'Для оплаты с помощью Kaspi.kz вам необходимо:',
                en: 'To pay using Kaspi.kz you need:',
                kz: 'Kaspi.kz арқылы төлеу үшін сізге қажет:',
            },
        ]
    },

    {
        link: 'pays/payment/answer',
        translation: [
            {
                description: 'info-date',
                ru: 'Сформирован',
                en: 'Formed',
                kz: 'Қалыптасқан',
            },
            {
                description: 'pay-answer',
                ru: 'Оплачен',
                en: 'Paid',
                kz: 'Ақылы',
            },
            {
                description: 'text-answer-1',
                ru: 'Детали оплаты',
                en: 'Details of payment',
                kz: 'Төлем туралы мәліметтер',
            },
            {
                description: 'text-answer-2',
                ru: 'ID транзакции',
                en: 'Transaction ID',
                kz: 'Транзакция идентификаторы',
            },
            {
                description: 'text-answer-3',
                ru: 'Дата транзакции',
                en: 'Transaction date',
                kz: 'Мәміле жасалған күн',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'question_plus_variants',
        translation: [
            {
                description: 'title-modal',
                ru: 'Новый вопрос: вопрос + варианты',
                en: 'New question: question + option',
                kz: 'Жаңа сұрақ: Сұрақ + опциясы',
            },
            {
                description: 'button-name',
                ru: 'Вопрос + варианты',
                en: 'Question + options',
                kz: 'Сұрақ + опциясы',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Жасыңызды таңдаңыз',
            },
            {
                description: 'select-three',
                ru: 'Выберите связку',
                en: 'Select bundle',
                kz: 'Буманы таңдаңыз',
            },
            {
                description: 'select-four',
                ru: 'Уровень',
                en: 'Select level',
                kz: 'Деңгейі таңдаңыз',
            },
            {
                description: 'select-five',
                ru: 'Подуровень',
                en: 'Select sublevel',
                kz: 'Төменгі деңгейі таңдаңыз',
            },
            {
                description: 'textarea-placeholder',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'text-info-one',
                ru: 'Варианты ответов:',
                en: 'Answer options:',
                kz: 'Жауап беру нұсқалары:',
            },
            {
                description: 'text-info-two',
                ru: 'Галочка указывает на выбор правильного варианта ответа',
                en: 'A check mark indicates the choice of the correct answer',
                kz: 'Құсбелгі дұрыс жауаптың таңдалғанын білдіреді',
            },
            {
                description: 'input-placeholder',
                ru: 'Вариант ответа',
                en: 'Possible answer',
                kz: 'Мүмкін болатын жауап',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'question_plus_text',
        translation: [
            {
                description: 'button-name',
                ru: 'Вопрос + текст',
                en: 'Question + text',
                kz: 'Сұрақ + мәтін',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: вопрос + текст',
                en: 'New question: question + text',
                kz: 'Жаңа сұрақ: сұрақ + мәтін',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Жасыңызды таңдаңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос:',
                en: 'Question:',
                kz: 'Сұрақ:',
            },
            {
                description: 'text-info-one',
                ru: 'Ответ:',
                en: 'Answer:',
                kz: 'Жауап:',
            },
            {
                description: 'text-info-two',
                ru: 'Текстовое поле ответа должно быть идентично ответу клиента',
                en: 'The response text field must be identical to the customers response',
                kz: 'Жауап мәтіні өрісі тапсырыс берушінің жауабымен бірдей болуы керек',
            },
            {
                description: 'textarea-placeholder-two',
                ru: 'Правильный ответ',
                en: 'Correct answer',
                kz: 'Дұрыс жауап',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'text_missing_words',
        translation: [
            {
                description: 'button-name',
                ru: 'Пропущенные слова',
                en: 'Missing words',
                kz: 'Жоқ сөздер',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: пропущенные слова',
                en: 'New question: missing words',
                kz: 'Жаңа сұрақ: жетіспейтін сөздер',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'text-info-one',
                ru: 'Метки нужно поставить в местах где необходимо пропустить слово. Количество меток не ограничено.',
                en: 'Labels must be placed in places where it is necessary to skip a word. The number of tags is not limited.',
                kz: 'Белгілерді сөзді өткізіп жіберу керек жерлерге қою керек. Тегтер саны шектелмеген.',
            },
            {
                description: 'text-info-two',
                ru: 'Метки',
                en: 'Tags',
                kz: 'Тегтер',
            },
            {
                description: 'text-info-three',
                ru: 'Варианты ответов вводятся через запятую. Первый вариант - всегда верный',
                en: 'Answer options are entered separated by commas.',
                kz: 'Жауаптар үтірмен бөлінген.',
            },
            {
                description: 'textarea-placeholder-two',
                ru: 'Варианты ответов, через запятую',
                en: 'Answer options, separated by commas',
                kz: 'Үтірмен бөлінген жауап нұсқалары',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },

        ]
    },
    {
        link: 'tests/questions',
        tag: 'image_plus_text',
        translation: [
            {
                description: 'button-name',
                ru: 'Картинка + текст',
                en: 'Picture + text',
                kz: 'Сурет + мәтін',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос:',
                en: 'New question:',
                kz: 'Жаңа сұрақ:',
            },
            {
                description: 'title-modal-2',
                ru: 'Редактировать вопрос:',
                en: 'Edit question:',
                kz: 'Сұрақты өңдеу:',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'text-info-one',
                ru: 'Ответ:',
                en: 'Answer:',
                kz: 'Жауап:',
            },
            {
                description: 'image',
                ru: 'изображение',
                en: 'image',
                kz: 'таңдаңыз',
            },
            {
                description: 'button-image-one',
                ru: 'Выбрать',
                en: 'Select',
                kz: 'Суретті',
            },
            {
                description: 'button-image-two',
                ru: 'Вы не выбрали',
                en: 'You have not selected an ',
                kz: 'Сіз кескінді ',
            },
            {
                description: 'button-image-three',
                ru: 'Выбран файл',
                en: 'Selected file',
                kz: 'Суретті',
            },
            {
                description: 'text-ifno-two',
                ru: 'Текстовое поле ответа должно быть идентично ответу клиента',
                en: 'The response text field must be identical to the customer\'s response',
                kz: 'Жауап мәтіні өрісі тапсырыс берушінің жауабымен бірдей болуы керек',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'image_plus_variants',
        translation: [
            {
                description: 'button-name',
                ru: 'Картинка + варианты',
                en: 'Picture + options',
                kz: 'Сурет қосу опциялары',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: картинка + варианты',
                en: 'New question: picture + options',
                kz: 'Жаңа сұрақ: сурет + опциялар',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'button-image-one',
                ru: 'Выбрать изображение',
                en: 'Select image',
                kz: 'Суретті таңдаңыз',
            },
            {
                description: 'button-image-two',
                ru: 'Вы не выбрали изображение',
                en: 'You have not selected an image',
                kz: 'Сіз кескінді таңдамадыңыз',
            },
            {
                description: 'text-info-one',
                ru: 'Варианты ответов:',
                en: 'Answer options:',
                kz: 'Жауап беру нұсқалары:',
            },
            {
                description: 'text-info-two',
                ru: 'Галочка указывает на выбор правильного варианта ответа',
                en: 'A check mark indicates the choice of the correct answer',
                kz: 'Құсбелгі дұрыс жауаптың таңдалғанын білдіреді',
            },
            {
                description: 'input-placeholder',
                ru: 'Вариант ответа',
                en: 'Possible answer',
                kz: 'Мүмкін болатын жауап',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'build_sentence',
        translation: [
            {
                description: 'button-name',
                ru: 'Собрать предложение',
                en: 'Collect proposal',
                kz: 'Сөйлем құрастыру',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: собрать предложение',
                en: 'New question: collect proposal',
                kz: 'Жаңа сұрақ: Сөйлем құрастыру',
            },
            {
                description: 'text-info-one',
                ru: 'Введите предложение, для разбивки на слова или словосочетания используйте символ: |',
                en: 'Enter a sentence, to break down into words or phrases use the symbol: |',
                kz: 'Сөздерді немесе сөз тіркестерін ажырату үшін сөйлемді енгізіңіз: | таңбасын қолданыңыз',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'audio_plus_variants',
        translation: [
            {
                description: 'button-name',
                ru: 'Аудио + варианты',
                en: 'Audio + options',
                kz: 'Аудио + опциялар',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: аудио + варианты',
                en: 'New question: audio + options',
                kz: 'Жаңа сұрақ: аудио + опциялар',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'audio',
                ru: 'аудиофайл',
                en: 'audio file',
                kz: 'Дыбыстық файлды',
            },
            {
                description: 'button-image-one',
                ru: 'Выбрать аудиофайл',
                en: 'Select audio file',
                kz: 'Дыбыстық файлды таңдаңыз',
            },
            {
                description: 'button-image-two',
                ru: 'Вы не выбрали аудиофайл',
                en: 'You have not selected an audio file',
                kz: 'Сіз аудио файлды таңдамадыңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'text-info-one',
                ru: 'Варианты ответов',
                en: 'Answer options',
                kz: 'Жауап беру нұсқалары',
            },
            {
                description: 'text-info-two',
                ru: 'Галочка указывает на выбор правильного варианта ответа',
                en: 'A check mark indicates the choice of the correct answer',
                kz: 'Құсбелгі дұрыс жауаптың таңдалғанын білдіреді',
            },
            {
                description: 'input-placeholder',
                ru: 'Вариант ответа',
                en: 'Possible answer',
                kz: 'Мүмкін болатын жауап',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/questions',
        tag: 'video_plus_variants',
        translation: [
            {
                description: 'button-name',
                ru: 'Видео + варианты',
                en: 'Video + options',
                kz: 'Бейне + опциялары',
            },
            {
                description: 'title-modal',
                ru: 'Новый вопрос: видео + варианты',
                en: 'New question: video + options',
                kz: 'Жаңа сұрақ: видео + опциялар',
            },
            {
                description: 'select-one',
                ru: 'Выберите программу',
                en: 'Select program',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'select-two',
                ru: 'Выберите возраст',
                en: 'Choose your age',
                kz: 'Бағдарламаны таңдаңыз',
            },
            {
                description: 'video',
                ru: 'видеофайл',
                en: 'video file',
                kz: 'Бейне файлын',
            },
            {
                description: 'button-image-one',
                ru: 'Выбрать видеофайл',
                en: 'Select video file',
                kz: 'Бейне файлын таңдаңыз',
            },
            {
                description: 'button-image-two',
                ru: 'Вы не выбрали видеофайл',
                en: 'You have not selected a video file',
                kz: 'Сіз бейне файлды таңдамадыңыз',
            },
            {
                description: 'textarea-placeholder-one',
                ru: 'Вопрос',
                en: 'Question',
                kz: 'Сұрақ',
            },
            {
                description: 'text-info-one',
                ru: 'Варианты ответов',
                en: 'Answer options',
                kz: 'Жауап беру нұсқалары',
            },
            {
                description: 'text-info-two',
                ru: 'Галочка указывает на выбор правильного варианта ответа',
                en: 'A check mark indicates the choice of the correct answer',
                kz: 'Құсбелгі дұрыс жауаптың таңдалғанын білдіреді',
            },
            {
                description: 'input-placeholder',
                ru: 'Вариант ответа',
                en: 'Possible answer',
                kz: 'Мүмкін болатын жауап',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'tests/settings',
        tag: 'add_bundle',
        translation: [
            {
                description: 'title-modal-1',
                ru: 'Добавление новой связки',
                en: 'Adding a new bundle',
                kz: 'Жаңа байлам қосу',
            },
            {
                description: 'title-modal-2',
                ru: 'Редактирование связки',
                en: 'Bundle editing',
                kz: 'Бумаларды редакциялау',
            },
            {
                description: 'select-one',
                ru: 'Программа обучения',
                en: 'Training program',
                kz: 'Жаңа байлам қосу',
            },
            {
                description: 'select-two',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'select-three',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'title-question-1',
                ru: 'Вопрос + варианты',
                en: 'Question + options',
                kz: 'Сұрақ + опциясы',
            },
            {
                description: 'title-question-2',
                ru: 'Пропущеные слова',
                en: 'Missing words',
                kz: 'Жоқ сөздер',
            },
            {
                description: 'title-question-3',
                ru: 'Картинка + варианты',
                en: 'Picture + options',
                kz: 'Сурет + опциялары',
            },
            {
                description: 'title-question-4',
                ru: 'Аудио + Варианты',
                en: 'Audio + Options',
                kz: 'Дыбыс + Опциялар',
            },
            {
                description: 'title-question-5',
                ru: 'Вопрос + текст',
                en: 'Question + text',
                kz: 'Сұрақ + мәтін',
            },
            {
                description: 'title-question-6',
                ru: 'Картинка + текст',
                en: 'Picture + text',
                kz: 'Сурет + мәтін',
            },
            {
                description: 'title-question-7',
                ru: 'Собрать предложение',
                en: 'Collect proposal',
                kz: 'Сөйлем құрастыру',
            },
            {
                description: 'title-question-8',
                ru: 'Видео + Варианты',
                en: 'Video + Options',
                kz: 'Бейне + Опциялар',
            },
            {
                description: 'title-all-questions',
                ru: 'Общее количество вопросов',
                en: 'Total number of questions',
                kz: 'Жалпы сұрақтар саны',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'button-three',
                ru: 'Обновить',
                en: 'Update',
                kz: 'Деректерді',
            },
            {
                description: 'button-four',
                ru: 'Подтвердить',
                en: 'Confirm',
                kz: 'Растау',
            },
            {
                description: 'button-five',
                ru: 'Отправить документ на подписание',
                en: 'Send a document for signing',
                kz: 'Құжатты қол қоюға жіберіңіз',
            },
        ]
    },
    {
        link: 'docs/push-clients-docs',
        translation: [
            {
                description: 'title-modal',
                ru: 'Назначение документа для студента',
                en: 'Assigning a document to a student',
                kz: 'Оқушыға құжат тапсыру',
            },
            {
                description: 'textarea-placeholder',
                ru: 'Начните вводить имя телефон или E-mail клиента',
                en: 'Start entering the customer\'s name, phone number or E-mail',
                kz: 'Клиенттің атауы, телефон нөмірін немесе электрондық поштаны теруді бастаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Болдырмау',
            },
            {
                description: 'button-two',
                ru: 'Отправить договор',
                en: 'Send a contract',
                kz: 'Келісімшартты жіберіңіз',
            },
            {
                description: 'text-info',
                ru: 'Код подтверждения отправлен на номер',
                en: 'Confirmation code sent to number',
                kz: 'Растау коды нөмірге жіберілді',
            },
            {
                description: 'text-info-2',
                ru: 'Отправить код повторно',
                en: 'Resend code',
                kz: 'Кодты қайта жіберіңіз',
            },
            {
                description: 'text-info-3',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
        ]
    },
    {
        link: 'push_pool_test',
        translation: [
            {
                description: 'title-modal-1',
                ru: 'Создание тестирования',
                en: 'Test creation',
                kz: 'Тест құру',
            },
            {
                description: 'title-modal-2',
                ru: 'Введите список email студентов, или воспользуйтесь формой выше для быстрого поиска',
                en: 'Enter the list of emails or use the form above for quick search',
                kz: 'Емэйл тізімін енгізіңіз немесе жоғарыдағы іздеу орыннын қолданыңыз',
            },
            {
                description: 'title-modal-3',
                ru: 'Название тестирования',
                en: 'Test name',
                kz: 'Тест аты',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
        ]
    },
    {
        link: 'pays/all-pays',
        tag: 'issuing_new_invoice',
        translation: [
            {
                description: 'title-modal',
                ru: 'Новый счет',
                en: 'New invoice',
                kz: 'Шот-фактураны ресімдеу',
            },
            {
                description: 'select-one',
                ru: 'Программа обучения',
                en: 'Training program',
                kz: 'Оқу бағдарламасы',
            },
            {
                description: 'select-two',
                ru: 'Возраст',
                en: 'Age',
                kz: 'Жасы',
            },
            {
                description: 'select-three',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Начните вводить имя телефон или E-mail клиента',
                en: 'Start entering the customer\'s name, phone number or E-mail',
                kz: 'Клиенттің атауы, телефон нөмірін немесе электрондық поштаны теруді бастаңыз',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Сумма, тенге',
                en: 'Amount, tenge',
                kz: 'Сома, теңге',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Описание платежа',
                en: 'Payment Description',
                kz: 'Төлем сипаттамасы',
            },
            {
                description: 'input-placeholder-4',
                ru: 'Срок оплаты',
                en: 'Due date',
                kz: 'Мерзімнің өту күні',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Отправить счет',
                en: 'Send invoice',
                kz: 'Шот-фактураны жіберу',
            },
        ]
    },
    {
        link: 'company/companies',
        tag: 'add_company',
        translation: [
            {
                description: 'title-modal',
                ru: 'Добавить компанию',
                en: 'Add company',
                kz: 'Компанияны қосыңыз',
            },
            {
                description: 'select-placeholder-5',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отмена ',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Отправить счет',
                en: 'Send invoice',
                kz: 'Шот-фактураны жіберу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите название компании',
                en: 'Enter your company name',
                kz: 'Сіздің компанияңыздың атын енгізіңіз',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Введите ссылку на виджет',
                en: 'Enter the link to the widget',
                kz: 'Виджет сілтемесін енгізіңіз',
            },
            {
                description: 'button-image-1',
                ru: 'Выбрать изображение',
                en: 'Select image',
                kz: 'Суретті таңдаңыз',
            },
            {
                description: 'button-image-2',
                ru: 'Вы не выбрали изображение',
                en: 'You have not selected an image',
                kz: 'Сіз кескінді таңдамадыңыз',
            },
            {
                description: 'textarea-placeholder',
                ru: 'Комментарий',
                en: 'Add comment',
                kz: 'Пікір',
            },
        ]
    },
    {
        link: 'company/locations',
        tag: 'add_location',
        translation: [
            {
                description: 'title-modal',
                ru: 'Добавить локацию',
                en: 'Add location',
                kz: 'Орынды қосыңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Название локации',
                en: 'Location name',
                kz: 'Орын атауы',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Адрес',
                en: 'Address',
                kz: 'Мекен-жайы',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Телефон',
                en: 'Telephone',
                kz: 'Телефон',
            },
            {
                description: 'input-placeholder-4',
                ru: 'Сотовый телефон',
                en: 'Cellular telephone',
                kz: 'Ұялы телефон',
            },
            {
                description: 'input-placeholder-5',
                ru: 'Начало работы',
                en: 'Beginning of work',
                kz: 'Жұмыстың басталуы',
            },
            {
                description: 'input-placeholder-6',
                ru: 'Конец работы',
                en: 'End of work',
                kz: 'Жұмыстың соңы',
            },
            {
                description: 'input-placeholder-7',
                ru: 'E-mail',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'checkbox-info',
                ru: 'Онлайн локация',
                en: 'Offline location',
                kz: 'Желіден тыс орналасуы',
            },
            {
                description: 'select-placeholder',
                ru: 'Выберите часовой пояс',
                en: 'Select your time zone',
                kz: 'Уақыт белдеуін таңдаңыз',
            },
            {
                description: 'text-info',
                ru: 'время начала и окончания рабочего дня локации необходимо вводить в формате: 00:00',
                en: 'the start and end time of the working day of the location must be entered in the format: 00:00',
                kz: 'орналасқан жердегі жұмыс күнінің басталу және аяқталу уақыты 00:00 форматында енгізілуі керек',
            },
            {
                description: 'text-info-error',
                ru: 'ВНИМАНИЕ: ',
                en: 'ATTENTION: ',
                kz: 'НАЗАР АУДАРЫҢЫЗ: ',
            },
            {
                description: 'name_1',
                ru: 'Аудитории: ',
                en: 'Classrooms: ',
                kz: 'Аудитория: ',
            },
            {
                description: 'name_2',
                ru: 'Компании',
                en: 'Companies',
                kz: 'Компаниялар',
            },
            {
                description: 'name_3',
                ru: 'Адрес',
                en: 'Address',
                kz: 'Мекен-жайы',
            },
            {
                description: 'name_4',
                ru: 'Телефон',
                en: 'Phone',
                kz: 'Телефон',
            },
            {
                description: 'name_5',
                ru: 'Мобильный телефон',
                en: 'Mobile phone',
                kz: 'Ұялы телефон',
            },
            {
                description: 'name_6',
                ru: 'E-mail',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
        ]
    },
    {
        link: 'company/profiles',
        tag: 'add_user',
        translation: [
            {
                description: 'title-modal',
                ru: 'Регистрация пользователя',
                en: 'User registration',
                kz: 'Пайдаланушыны тіркеу',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'gender-1',
                ru: 'Мужской',
                en: 'Male',
                kz: 'Еркек',
            },
            {
                description: 'gender-2',
                ru: 'Женский',
                en: 'Female',
                kz: 'Әйел',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Отчество',
                en: 'middle name',
                kz: 'әкенің есімі',
            },
            {
                description: 'input-placeholder-4',
                ru: 'E-mail',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'input-placeholder-5',
                ru: 'Мобильный телефон',
                en: 'Mobile phone',
                kz: 'Ұялы телефон',
            },
            {
                description: 'checkbox-info',
                ru: 'Отправить пароль для входа в систему на e-mail?',
                en: 'Send your login password to your e-mail?',
                kz: 'Логин паролін электрондық поштаңызға жібересіз бе?',
            },
            {
                description: 'select-placeholder-1',
                ru: 'Тип пользователя',
                en: 'User type',
                kz: 'Пайдаланушы түрі',
            },
            {
                description: 'select-placeholder-2',
                ru: 'Выберите пол',
                en: 'Select gender',
                kz: 'Жынысты таңдаңыз',
            },
        ]
    },
    {
        link: 'company/programs',
        tag: 'add_program',
        translation: [
            {
                description: 'title-modal',
                ru: 'Добавить программу обучения',
                en: 'Add training program',
                kz: 'Оқу бағдарламасын қосыңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите название программы',
                en: 'Enter the name of the program',
                kz: 'Бағдарламаның атын енгізіңіз',
            },
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
        ]
    },
    {
        link: 'company/age',
        tag: 'add_age',
        translation: [
            {
                description: 'title-modal',
                ru: 'Добавить возрастную группу',
                en: 'Add age group',
                kz: 'Жас тобын қосыңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите название возрастной группы',
                en: 'Enter the name of the age group',
                kz: 'Жас тобының атын енгізіңіз',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Возраст, от',
                en: 'Age from',
                kz: 'Жасы',
            },
            {
                description: 'input-placeholder-3',
                ru: 'до',
                en: 'before',
                kz: 'дейін',
            },
            {
                description: 'input-placeholder-4',
                ru: 'Возраст, до',
                en: 'Age before',
                kz: 'Жасы дейін',
            },
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
        ]
    },
    {
        link: 'company/profiles/account',
        tag: 'add_category',
        translation: [
            {
                description: 'title-modal',
                ru: 'Изменение пароля учетной записи',
                en: 'Change Password',
                kz: 'Құпия сөзді өзгерту',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'edit_book',
                ru: 'Редактирование книги',
                en: 'Editing a book',
                kz: 'Кітапты өңдеу',
            },
            {
                description: 'button-two',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'whois',
                ru: 'Кем приходится',
                en: 'Who invoice for',
                kz: 'Кімнің есебі',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Отчество',
                en: 'middle name',
                kz: 'әкенің есімі',
            },
            {
                description: 'input-placeholder-4',
                ru: 'Текущий пароль:',
                en: 'Old Password',
                kz: 'Ескі Құпия сөз',
            },
            {
                description: 'input-placeholder-5',
                ru: 'Новый пароль:',
                en: 'New password',
                kz: 'Жаңа пароль',
            },
            {
                description: 'input-placeholder-6',
                ru: 'Повторите пароль:',
                en: 'Confirm the password',
                kz: 'Құпия сөзді растаңыз',
            },
            {
                description: 'text-info-2',
                ru: 'Основные данные',
                en: 'Main data',
                kz: 'Негізгі деректер',
            },
            {
                description: 'select-placeholder-1',
                ru: 'Выберите часовой пояс',
                en: 'Select your time zone',
                kz: 'Уақыт белдеуін таңдаңыз',
            },
            {
                description: 'select-placeholder-2',
                ru: 'Выберите пол',
                en: 'Select gender',
                kz: 'Жынысты таңдаңыз',
            },
        ]
    },
    {
        link: 'docs/signing',
        tag: 'add_category',
        translation: [
            {
                description: 'title-modal',
                ru: 'Данные для заключения договора',
                en: 'These are for the conclusion of the contract',
                kz: 'Шарт жасасу үшін мәліметтер',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Отчество',
                en: 'middle name',
                kz: 'әкенің есімі',
            },
            {
                description: 'input-placeholder-4',
                ru: 'Инн',
                en: 'Inn',
                kz: 'қонақ үй',
            },
            {
                description: 'input-placeholder-5',
                ru: 'Домашний телефон',
                en: 'Home phone',
                kz: 'Үй телефоны',
            },
            {
                description: 'input-placeholder-6',
                ru: 'Домашний адрес',
                en: 'Home address',
                kz: 'Үй мекенжайы',
            },
            {
                description: 'input-placeholder-7',
                ru: 'мобильный телефон',
                en: 'mobile phone',
                kz: 'ұялы телефон',
            },
            {
                description: 'input-placeholder-8',
                ru: 'e-mail',
                en: 'e-mail',
                kz: 'электрондық пошта',
            },
            {
                description: 'input-placeholder-9',
                ru: 'дата рождения',
                en: 'date of birth',
                kz: 'туған күні',
            },
            {
                description: 'text-info',
                ru: 'сотовый телефон необходим для подтверждения договора',
                en: 'Cell phone is required to ratify the contract',
                kz: 'келісімшартты растау үшін ұялы телефон қажет',
            },
            {
                description: 'text-info-error',
                ru: 'ВНИМАНИЕ: ',
                en: 'ATTENTION: ',
                kz: 'НАЗАР АУДАРЫҢЫЗ: ',
            },
            {
                description: 'checkbox-info',
                ru: 'Согласие на участие в фото и видеосъемке',
                en: 'Agree to participate in photo and video shooting',
                kz: 'Фото және бейне түсірілімге қатысуға келісім беру',
            },
            {
                description: 'text',
                ru: 'Добавить данные контактного лица',
                en: 'Add these contact faces',
                kz: 'Байланыс мәліметтерін қосыңыз',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Перейти к подписанию',
                en: 'Go to the signature',
                kz: 'Қол қоюға өтіңіз',
            },
            {
                description: 'text-info',
                ru: 'Официальный представитель (родитель)',
                en: 'Official representative (parent)',
                kz: 'Ресми өкіл (ата-ана)',
            },

        ]
    },
    {
        link: 'index',
        tag: 'sidebar_main',
        translation: [
            {
                description: 'subtitle-question',
                ru: 'Учебное тестирование',
                en: 'Educational testing',
                kz: 'Оқу тесті',
            },
            {
                description: 'subtitle-question-1',
                ru: 'Виджеты',
                en: 'Widgets',
                kz: 'Виджеттер',
            },
            {
                description: 'subtitle-question-2',
                ru: 'Тесты',
                en: 'Tests',
                kz: 'Тесттер',
            },
            {
                description: 'subtitle-question-3',
                ru: 'Назначенные клиентам',
                en: 'Assigned to clients ',
                kz: 'Клиенттерге тағайындалған ',
            },
            {
                description: 'subtitle-question-4',
                ru: 'Назначенные тесты',
                en: 'Assigned tests ',
                kz: 'Тағайындалған сынақтар ',
            },
            {
                description: 'subtitle-question-5',
                ru: 'Запрошенные тесты',
                en: 'Requested Tests ',
                kz: 'Сұралған сынақтар ',
            },
            /*{
                description: 'subtitle-question-2',
                ru: 'Редактирование виджета',
                en: 'Editing a widget',
                kz: 'Виджетті өңдеу',
            },*/
            {
                description: 'status_1',
                ru: 'Обновить статус',
                en: 'Update status',
                kz: 'Күйді жаңарту',
            },
            {
                description: 'status_2',
                ru: 'Просмотр',
                en: 'View',
                kz: 'Көру',
            },
            {
                description: 'status_3',
                ru: 'Дата отправки',
                en: 'Departure date',
                kz: 'Шығу күні',
            },
            {
                description: 'status_4',
                ru: 'Открыто',
                en: 'Open',
                kz: 'Ашық',
            },
            {
                description: 'status_5',
                ru: 'Клик',
                en: 'Cry',
                kz: 'Жылама',
            },
            {
                description: 'status_6',
                ru: 'Тема письма',
                en: 'Letter subject',
                kz: 'Хат тақырыбы',
            },
            {
                description: 'status_7',
                ru: 'Отправленные сообщения',
                en: 'Sent messages',
                kz: 'Хабарламалар жіберді',
            },
            {
                description: 'status_8',
                ru: 'Сообщение',
                en: 'Message',
                kz: 'Жылама',
            },
            {
                description: 'status_9',
                ru: 'Номер телефона',
                en: 'Phone number',
                kz: 'Phone number',
            },
            {
                description: 'sub-title-question-1',
                ru: 'Вопросы',
                en: 'Questions',
                kz: 'Сұрақтар',
            },
            {
                description: 'sub-title-question-3',
                ru: 'Клиенты',
                en: 'Clients',
                kz: 'Клиенттер',
            },
            {
                description: 'title-online-booking',
                ru: 'Онлайн запись',
                en: 'Online booking',
                kz: 'Онлайн брондау',
            },
            {
                description: 'sub-title-online-1',
                ru: 'Настройка слотов',
                en: 'Configuring slots',
                kz: 'Слоттарды конфигурациялау',
            },
            {
                description: 'sub-title-online-2',
                ru: 'Запись нового клиента',
                en: 'Customer record',
                kz: 'Тапсырыс берушінің жазбасы',
            },
            {
                description: 'sub-title-online-3',
                ru: 'Настройка расписания',
                en: 'Schedule settings',
                kz: 'Кестені реттеу',
            },
            {
                description: 'sub-title-online-6',
                ru: 'Расписание клиентов',
                en: 'Clients schedule',
                kz: 'Клиенттер кестесі',
            },
            {
                description: 'sign-up-group',
                ru: 'Записаться в группу',
                en: 'Assign to group',
                kz: 'Топқа тіркелу',
            },
            {
                description: 'sub-title-online-7',
                ru: 'Расписание преподавателей',
                en: 'Time schedule of teachers\'',
                kz: 'Оқытушылар кестесі',
            },
            {
                description: 'sub-title-online-8',
                ru: 'Группы',
                en: 'Groups',
                kz: 'Топ',
            },
            {
                description: 'sub-title-online-9',
                ru: 'История расписания преподавателей',
                en: 'Teacher schedule history',
                kz: 'Мұғалім кестеден тарих',
            },
            {
                description: 'sub-title-online-10',
                ru: 'История записей клиентов',
                en: 'Customer record history',
                kz: 'Тұтынушылардың жазбалар тарихы',
            },
            {
                description: 'sub-title-online-11',
                ru: 'Мое расписание',
                en: 'My schedule',
                kz: 'Менің кестем',
            },
            {
                description: 'sub-title-online-12',
                ru: 'Будущие записи',
                en: 'Future entries ',
                kz: 'Болашақ жазбалар',
            },
            {
                description: 'sub-title-online-13',
                ru: 'Прошедшие записи',
                en: 'Past entries ',
                kz: 'Өткен  жазбалар ',
            },
            {
                description: 'title-finance',
                ru: 'Финансы',
                en: 'Finance',
                kz: 'Қаржысы',
            },
            {
                description: 'sub-title-finance-1',
                ru: 'Цены в часах',
                en: 'Hour costs',
                kz: 'Сағаттық бағасы',
            },
            {
                description: 'sub-title-finance-2',
                ru: 'Скидки',
                en: 'Discounts',
                kz: 'Жеңілдіктер',
            },
            {
                description: 'sub-title-finance-3',
                ru: 'Наценки',
                en: 'Markups',
                kz: 'Үстемесі',
            },
            {
                description: 'title-doc',
                ru: 'Документы',
                en: 'Documents',
                kz: 'Құжаттар',
            },
            {
                description: 'sub-title-doc-1',
                ru: 'Клиентские документы',
                en: 'Client documents',
                kz: 'Клиенттің құжаттары',
            },
            {
                description: 'sub-title-doc-2',
                ru: 'Подписание договоров',
                en: 'Contract signature',
                kz: 'Келісімшартқа қол қою',
            },
            {
                description: 'sub-title-doc-4',
                ru: 'Список документов',
                en: 'List of documents',
                kz: 'Құжаттар тізімі',
            },
            {
                description: 'title-pay',
                ru: 'Счета',
                en: 'Invoice',
                kz: 'Шоттар',
            },
            {
                description: 'sub-title-pay-1',
                ru: 'Все счета',
                en: 'All invoice',
                kz: 'Барлық шоттар',
            },
            {
                description: 'sub-title-pay-2',
                ru: 'Мои счета',
                en: 'My bills',
                kz: 'Түбіртектерім',
            },
            {
                description: 'sub-title-pay-3',
                ru: 'Списания',
                en: 'Write-offs',
                kz: 'Есептен алуы',
            },
            {
                description: 'title-company',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'sub-title-company-1',
                ru: 'Компании',
                en: 'Companies',
                kz: 'Компаниялар',
            },
            {
                description: 'sub-title-company-2',
                ru: 'Локации',
                en: 'Locations',
                kz: 'Орындар',
            },
            {
                description: 'sub-title-company-3',
                ru: 'Сотрудники',
                en: 'Staff',
                kz: 'Қызметкерлер',
            },
            {
                description: 'sub-title-company-4',
                ru: 'Группы',
                en: 'Groups',
                kz: 'Топ',
            },
            {
                description: 'sub-title-company-5',
                ru: 'Обучение',
                en: 'Training',
                kz: 'Оқыту',
            },
            {
                description: 'sub-title-company-6',
                ru: 'Курсы',
                en: 'Courses',
                kz: 'Курстар',
            },
            {
                description: 'sub-title-company-7',
                ru: 'Клиенты',
                en: 'Clients',
                kz: 'Клиенттер',
            },
            {
                description: 'sub-title-company-8',
                ru: 'Клиенты вступившие в группы',
                en: 'Clients joined the groups',
                kz: 'Топтарға қосылған клиенттер',
            },
            {
                description: 'sub-title-company-9',
                ru: 'Отправленные письма',
                en: 'Sent emails',
                kz: 'Жіберілген хаттамалар',
            },
            {
                description: 'sub-title-company-10',
                ru: 'Отправленные SMS',
                en: 'Sent SMS',
                kz: 'Жіберілген SMS',
            },
            {
                description: 'title-settings',
                ru: 'Настройки',
                en: 'Settings',
                kz: 'Параметрлер',
            },
            {
                description: 'sub-title-settings-7',
                ru: 'Общие настройки',
                en: 'General settings',
                kz: 'Жалпы параметрлер',
            },
            {
                description: 'sub-title-settings-8',
                ru: 'Работа с группами',
                en: 'Work with groups',
                kz: 'Топтармен жұмыс істеу',
            },
            {
                description: 'sub-title-settings-1',
                ru: 'Программы обучения',
                en: 'Studying programs',
                kz: 'Оқу бағдарламалары',
            },
            {
                description: 'sub-title-settings-2',
                ru: 'Возрастные категориии',
                en: 'Age categories',
                kz: 'Жас категориялары',
            },
            {
                description: 'sub-title-settings-3',
                ru: 'Дисциплины (языки)',
                en: 'Disciplines (languages)',
                kz: 'Пәндер (тілдер)',
            },
            {
                description: 'sub-title-settings-4',
                ru: 'Уровни групп',
                en: 'Group levels',
                kz: 'Топ деңгейлері',
            },
            {
                description: 'sub-title-settings-5',
                ru: 'Праздники',
                en: 'Holidays',
                kz: 'Мереке',
            },
            {
                description: 'sub-title-settings-6',
                ru: 'Категории групп',
                en: 'Group categories',
                kz: 'Топ категориялары',
            },
            {
                description: 'vidget',
                ru: 'Виджет',
                en: 'Widget',
                kz: 'Виджет',
            },
            {
                description: 'sub-test-1',
                ru: 'Список тестов',
                en: 'List of tests',
                kz: 'Тесттер тізімі',
            },
            {
                description: 'sub-test-2',
                ru: 'Прошедшие тесты',
                en: 'Passed tests',
                kz: 'Өткен тесттер',
            },
            {
                description: 'sub-test-3',
                ru: 'Все тесты',
                en: 'All tests',
                kz: 'Барлық тесттер',
            },
            {
                description: 'text-box-1',
                ru: 'Изменить?',
                en: 'Edit?',
                kz: 'Өңдеу?',
            },
            {
                description: 'text-box-2',
                ru: 'Профиль',
                en: 'Profile',
                kz: 'профилім',
            },
            {
                description: 'text-box-3',
                ru: 'Выйти',
                en: 'Log off',
                kz: 'Шығу',
            },
            {
                description: 'delete-1',
                ru: 'Удалить запись',
                en: 'Delete entry',
                kz: 'Жазбаны жою',
            },
            {
                description: 'delete-2',
                ru: 'Редактирование',
                en: 'Editing',
                kz: 'Редакциялау',
            },
            {
                description: 'time-zone',
                ru: 'Рекомендуемая временная зона',
                en: 'Recommended time zone',
                kz: 'Ұсынылатын уақыт белдеуі',
            },
            {
                description: 'button-save',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'staff_schedule',
                ru: 'Расписание сотрудников',
                en: 'Staff Schedule',
                kz: 'Қызметкерлер кестесі',
            }
        ]
    },
    {
        link: 'pagination',
        translation: [
            {
                description: 'number-lines',
                ru: 'Строк',
                en: 'Number of lines',
                kz: 'Жолдар саны',
            },
            {
                description: 'page',
                ru: 'Страница',
                en: 'Page',
                kz: 'Бет',
            },
            {
                description: 'shown',
                ru: 'Показано',
                en: 'Shown',
                kz: 'Көрсетілген',
            },
            {
                description: 'of',
                ru: ' из ',
                en: ' of ',
                kz: ' туралы ',
            },
        ]
    },
    {
        link: 'auth',
        translation: [
            {
                description: 'label-1',
                ru: 'Email',
                en: 'Email',
                kz: 'Электрондық пошта',
            },
            {
                description: 'label-5',
                ru: 'Проблемы соединения с сервером',
                en: 'server connection error',
                kz: 'серверге қосылу қатесі',
            },
        ]
    },
    {
        link: 'modal-bts',
        translation: [
            {
                description: 'text-1',
                ru: 'Вы хотите удалить забронированный учителем слот?',
                en: 'Do you want to delete slot booked by teacher?',
                kz: 'Сіз мұғалімның тіркеген слотын өшіресіз бе?',
            },
            {
                description: 'text-2',
                ru: 'На этот слот записан студент',
                en: 'There is student assigned to this slot',
                kz: 'Осы слотқа студент жазылып қойылған',
            },
            {
                description: 'text-3',
                ru: 'Вы можете перезаписать клиента на новый слот, или удалить вместе с забронированным преподавателем слотом.',
                en: 'You may overwrite client to a new slot or delete entry with slot booked by teacher',
                kz: ' Студентті жаңа слотқа қайта жаза аласыз немесе мұғалімның тіркеген слотымен бірге өшіре аласыз',
            },
            {
                description: 'text-4',
                ru: 'Удалить слот и запись ученика',
                en: 'Delete slot and student entry',
                kz: 'Оқушы мен оқушы жазбасын жою',
            },
            {
                description: 'text-5',
                ru: 'Удалить слот и перезаписать ученика',
                en: 'Delete slot and overwrite student',
                kz: 'Слотты өшіріп студентты қайта жазу',
            },
            {
                description: 'text-6',
                ru: 'Удалить слот',
                en: 'Delete slot',
                kz: 'Ұяшықты жою',
            },
        ]
    },
    {
        link: 'modal-profile',
        translation: [
            {
                description: 'text-1',
                ru: 'Выберите',
                en: 'Please select',
                kz: 'Таңдаңыз',
            },
            {
                description: 'text-2',
                ru: 'Подраздедление',
                en: 'Subdivision',
                kz: 'Бөлім',
            },
            {
                description: 'text-3',
                ru: 'Должность',
                en: 'Position',
                kz: 'Лауазымы',
            },
            {
                description: 'button-one',
                ru: 'Отмена',
                en: 'Cancel',
                kz: 'бас тарту',
            },
            {
                description: 'button-two',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'text-4',
                ru: 'Основная локация',
                en: 'Main location',
                kz: 'Негізгі орналасқан жері',
            },
            {
                description: 'text-5',
                ru: 'Подразделение и должность',
                en: 'Division and position',
                kz: 'Бөлу және лауазым',
            },
            {
                description: 'text-6',
                ru: 'Статус сотрудника',
                en: 'Employee status',
                kz: 'Қызметкер мәртебесі',
            },
        ]
    },
    {
        link: 'time-zone',
        translation: [
            {
                description: 'name-1',
                ru: '(GMT-5) Монтеррей',
                en: '(GMT-5) Monterrey',
                kz: '(GMT-5) Монтеррей',
            },
            {
                description: 'name-2',
                ru: '(GMT-4) Монреаль',
                en: '(GMT-4) Montreal',
                kz: '(GMT-4) Монреаль',
            },
            {
                description: 'name-3',
                ru: '(GMT+0) Среднее время по Гринвичу"',
                en: '(GMT+0) Greenwich"',
                kz: '(GMT+0) Гринвич"',
            },
            {
                description: 'name-4',
                ru: '(GMT+1) Дублин, Лондон',
                en: '(GMT+1) Dublin, London',
                kz: '(GMT+1) Дублин, Лондон',
            },
            {
                description: 'name-5',
                ru: '(GMT+2) Амстердам',
                en: '(GMT+2) Amsterdam',
                kz: '(GMT+2) Амстердам',
            },
            {
                description: 'name-6',
                ru: '(GMT+3) Стамбул, Москва, Никосия, Киев, Минск',
                en: '(GMT+3) Istanbul, Moscow, Nicosia, Kiev, Minsk',
                kz: '(GMT+3) Стамбул, Мәскеу, Никосия, Киев, Минск',
            },
            {
                description: 'name-7',
                ru: '(GMT+5) Екатеринбург, Ташкент',
                en: '(GMT+5) Yekaterinburg, Tashkent',
                kz: '(GMT+5) Екатеринбург, Ташкент',
            },
            {
                description: 'name-8',
                ru: '(GMT+6) Алматы, Нур-Султан',
                en: '(GMT+6) Almaty, Nur-Sultan',
                kz: '(GMT+6) Алматы, Нұр-Сұлтан',
            },
            {
                description: 'name-9',
                ru: '(GMT+7) Новосибирск, Красноярск',
                en: '(GMT+7) Novosibirsk, Krasnoyarsk',
                kz: '(GMT+7) Новосибирск, Красноярск',
            },
            {
                description: 'name-10',
                ru: '(GMT+8) Пекин, Шанхай, Куала-Лумпур, Сингапур',
                en: '(GMT+8) Beijing, Shanghai, Kuala-Lumpur, Singapore',
                kz: '(GMT+8) Пекин, Шанхай, Куала-Лумпур, Сингапур',
            },
            {
                description: 'name-11',
                ru: '(GMT+9) Сеул',
                en: '(GMT+9) Seoul',
                kz: '(GMT+9) Сеул',
            },
            {
                description: 'name-12',
                ru: '(GMT+10) Владивосток',
                en: '(GMT+10) Vladivostok',
                kz: '(GMT+10) Владивосток',
            },
            {
                description: 'name-13',
                ru: '(GMT+12) Камчатка',
                en: '(GMT+12) Kamchatka',
                kz: '(GMT+12) Камчатка',
            },
        ]
    },
    {
        link: 'pay/pay-answer',
        translation: [
            {
                description: 'answer-1',
                ru: 'Не оплачен',
                en: 'Not paid',
                kz: 'Ақылы емес',
            },
            {
                description: 'answer-2',
                ru: 'Оплачен',
                en: 'Pay',
                kz: 'Ақылы',
            },
            {
                description: 'answer-3',
                ru: 'Отменить счет',
                en: 'Cancel payment',
                kz: 'Есептік жазбадан бас тарту',
            },
            {
                description: 'answer-4',
                ru: 'Оплатить',
                en: 'Pay',
                kz: 'Ақылы',
            },
        ]
    },
    {
        link: 'filter',
        translation: [
            {
                description: 'text-1_1',
                ru: 'Введите название теста',
                en: 'Enter question text',
                kz: 'Сұрақтың мәтінін енгізіңіз',
            },
            {
                description: 'text-2',
                ru: 'Введите ФИО, Email или телефон',
                en: 'Enter name, email or phone',
                kz: 'Аты жөніңізді, емэйлды немесе телефонды енгізіңіз',
            },
            {
                description: 'text-2_1',
                ru: 'ФИО, email или телефон клиента',
                en: 'Name, email or phone of client',
                kz: 'Клиенттің толық аты-жөні, электрондық поштасы немесе телефон',
            },
            {
                description: 'text-3',
                ru: 'Выберите тип пользователя',
                en: 'Choose user type',
                kz: 'Қолданушының түрін таңдаңыз',
            },
            {
                description: 'text-4',
                ru: 'Фильтр',
                en: 'Filter',
                kz: 'Сүзгі',
            },
            {
                description: 'text-5',
                ru: 'Открыть',
                en: 'Open',
                kz: 'Ашу',
            },
            {
                description: 'text-6',
                ru: 'Закрыть',
                en: 'Close',
                kz: 'Жабу',
            },
            {
                description: 'text-7',
                ru: 'Применить фильтр',
                en: 'Apply filter',
                kz: 'Фильтрді қосу',
            },
            {
                description: 'text-8',
                ru: 'Выберите статус сотрудника',
                en: 'Apply filter',
                kz: 'Фильтрді қосу',
            },
            {
                description: 'text-9',
                ru: 'Выберите статус клиента',
                en: 'Apply filter',
                kz: 'Фильтрді қосу',
            },
            {
                description: 'text-10',
                ru: 'Сбросить',
                en: 'Reset',
                kz: 'анық',
            },
            {
                description: 'text-11',
                ru: 'до',
                en: 'to',
                kz: 'дейін',
            },
            {
                description: 'text-12',
                ru: 'Свернуть все',
                en: 'Collapse all',
                kz: 'Барлығын жию',
            },
            {
                description: 'text-13',
                ru: 'Развернуть все',
                en: 'Expand all',
                kz: 'Барлығын кеңейту',
            },
        ],
    },
    {
        link: 'months',
        translation: [
            {
                description: 'months_1',
                ru: 'Января',
                en: 'January',
                kz: 'Қаңтар',
            },
            {
                description: 'months_2',
                ru: 'Февраля',
                en: 'February',
                kz: 'Ақпан',
            },
            {
                description: 'months_3',
                ru: 'Марта',
                en: 'March',
                kz: 'Наурыз',
            },
            {
                description: 'months_4',
                ru: 'Апреля',
                en: 'April',
                kz: 'Сәуір',
            },
            {
                description: 'months_5',
                ru: 'Мая',
                en: 'May',
                kz: 'Мамыр',
            },
            {
                description: 'months_6',
                ru: 'Июня',
                en: 'June',
                kz: 'Маусым',
            },
            {
                description: 'months_7',
                ru: 'Июля',
                en: 'July',
                kz: 'Шілде',
            },
            {
                description: 'months_8',
                ru: 'Августа',
                en: 'August',
                kz: 'Тамыз',
            },
            {
                description: 'months_9',
                ru: 'Сентября',
                en: 'September',
                kz: 'Қыркүйек',
            },
            {
                description: 'months_10',
                ru: 'Октября',
                en: 'October',
                kz: 'Қазан',
            },
            {
                description: 'months_11',
                ru: 'Ноября',
                en: 'November',
                kz: 'Қараша',
            },
            {
                description: 'months_12',
                ru: 'Декабря',
                en: 'December',
                kz: 'Желтоқсан',
            },
        ]
    },
    {
        link: 'lessons/course',
        translation: [
            {
                description: 'empty_item_text',
                ru: 'Не указано',
                en: 'Not specified',
                kz: 'Көрсетілмеген',
            },
            {
                description: 'text_1',
                ru: 'Добавить урок',
                en: 'Add a lesson',
                kz: 'Сабақ қосу',
            },
            {
                description: 'text_2',
                ru: 'Название урока',
                en: 'Lesson title',
                kz: 'Сабақтың тақырыбы',
            },
            {
                description: 'text_3',
                ru: 'Краткое описание урока',
                en: 'Short lesson description',
                kz: 'Сабақтың қысқаша сипаттамасы',
            },
            {
                description: 'text_4',
                ru: 'Краткое домашнее задание',
                en: 'Short homework',
                kz: 'Үй жұмысының қысқа түрі',
            },
            {
                description: 'text_5',
                ru: 'Редактировать курс',
                en: 'Edit course',
                kz: 'Курсты өңдеу',
            },
            {
                description: 'text_6',
                ru: 'Введите название курса',
                en: 'Enter course name',
                kz: 'Курстың атауын енгізіңіз',
            },
            {
                description: 'text_7',
                ru: 'Уровень курса',
                en: 'Course level',
                kz: 'Курс деңгейі',
            },
            {
                description: 'text_8',
                ru: 'Уровень навыка',
                en: 'Skill level',
                kz: 'Дағды деңгейі',
            },
            {
                description: 'text_9',
                ru: 'Редактировать курс',
                en: 'Edit course',
                kz: 'Курсты өңдеу',
            },
            {
                description: 'text_10',
                ru: 'Детали',
                en: 'Details',
                kz: 'Егжейі',
            },
            {
                description: 'text_11',
                ru: 'Редактировать',
                en: 'Edit',
                kz: 'Өңдеу',
            },
            {
                description: 'text_13',
                ru: 'Удалить',
                en: 'Delete',
                kz: 'Жою',
            },
            {
                description: 'text_14',
                ru: 'Программа урока:',
                en: 'Lesson program:',
                kz: 'Сабақтың бағдарламасы:',
            },
            {
                description: 'text_15',
                ru: 'Домашнее задание:',
                en: 'Homework:',
                kz: 'Үй жұмысы:',
            },
            {
                description: 'text_16',
                ru: 'Скачать',
                en: 'Download',
                kz: 'Жүктеу',
            },
            {
                description: 'text_17',
                ru: 'Файлы:',
                en: 'Files:',
                kz: 'Файлдар:',
            },
            {
                description: 'text_18',
                ru: 'Загрузить',
                en: 'Upload',
                kz: 'Жүктеу',
            },
            {
                description: 'text_19',
                ru: 'скрыть',
                en: 'hide',
                kz: 'жасыру',
            },
            {
                description: 'text_20',
                ru: 'показать',
                en: 'show',
                kz: 'көрсету',
            },
            {
                description: 'modal_doc_text_1',
                ru: 'Добавление нового файла',
                en: 'Add new file',
                kz: 'Жаңа файл қосу',
            },
            {
                description: 'modal_doc_text_2',
                ru: 'Имя документа',
                en: 'Document name',
                kz: 'Құжат атауы',
            },
            {
                description: 'modal_doc_text_3',
                ru: 'Короткое описание',
                en: 'Short description',
                kz: 'Қысқаша сипаттамасы',
            },
            {
                description: 'modal_doc_text_4',
                ru: 'Сохранить как шаблон',
                en: 'Save as template',
                kz: 'Шаблон ретінде сақтау',
            },
            {
                description: 'modal_doc_text_5',
                ru: 'Выберите файл',
                en: 'Select file',
                kz: 'Файлды таңдау',
            },
            {
                description: 'modal_doc_text_6',
                ru: 'Любой файл кроме .bat и .exe',
                en: 'Any file except .bat and .exe',
                kz: 'Қез келген файл тек .bat және .exe қоспағанда',
            },
            {
                description: 'edit_course',
                ru: 'Редактирование курса',
                en: 'Edit course',
                kz: 'Курсты өңдеу',
            },
            {
                description: 'share_course',
                ru: 'Поделиться курсом',
                en: 'Share course',
                kz: 'Поделиться курсом',
            },
            {
                description: 'content',
                ru: 'Содержание',
                en: 'Content',
                kz: 'Мазмұны',
            },
        ]
    },
    {
        link: 'lessons/course',
        tag: 'share_course',
        translation: [
            {
                description: 'all_course',
                ru: 'Весь курс, все уроки',
                en: 'All course, all lessons',
                kz: 'Барлық сабақ, барлық сабақтар',
            },
            {
                description: 'partially_select_lessons',
                ru: 'Частично, выбрать уроки',
                en: 'Partially, select lessons',
                kz: 'Ішінара, сабақты таңдаңыз',
            },
            {
                description: 'text-1',
                ru: 'По этой ссылке вы можете продемонстрировать этот материал и другие преподаватели смогут сохранить этот материал в своем профиле',
                en: 'By this link you can demonstrate this material and other teachers will be able to save this material in their profile.',
                kz: 'Осы сілтеме арқылы сіз осы материалды көрсете аласыз, ал басқа мұғалімдер бұл материалды өз профилінде сақтай алады.',
            },
            {
                description: 'get',
                ru: 'Получить',
                en: 'Get',
                kz: 'Алыңыз',
            },
            {
                description: 'send_email_notification',
                ru: 'Отправить уведомление по e-mail',
                en: 'Send e-mail notification',
                kz: 'Хабарламаны электрондық пошта арқылы жіберіңіз',
            },
            {
                description: 'email_address',
                ru: 'E-mail адреса',
                en: 'E-mail addresses',
                kz: 'Электрондық поштаның адресі',
            },
            {
                description: 'specify_3_addresses',
                ru: 'Укажите не более 3х адресов, перечислив их через запятую',
                en: 'Specify no more than 3 addresses, listing them separated by commas',
                kz: 'Үтірлермен бөлінген тізімде 3-тен аспайтын мекен-жайларды көрсетіңіз',
            },
            {
                description: 'link_copied',
                ru: 'Ссылка скопирована в буфер обмена',
                en: 'Link copied to clipboard',
                kz: 'Сілтеме буферге көшірілді',
            },
        ]
    },
    {
        link: 'study/test',
        translation: [
            {
                description: 'text_1',
                ru: 'Страница теста',
                en: 'Test page',
                kz: 'Сынақ беті',
            },
            {
                description: 'text_2',
                ru: 'Окончание через',
                en: 'Ending in',
                kz: 'Аяқталады',
            },
            {
                description: 'text_3',
                ru: 'Время теста истекло',
                en: 'Test time expired',
                kz: 'Сынақ уақыты аяқталды',
            },
            {
                description: 'text_4',
                ru: 'До начала теста',
                en: 'Before the start of the test',
                kz: 'Тест басталар алдында',
            },
            {
                description: 'text_5',
                ru: 'Время выполнения',
                en: 'Lead time',
                kz: 'Тоқтау',
            },
            {
                description: 'text_6',
                ru: 'Попыток прохождения',
                en: 'Attempts to pass',
                kz: 'Өту әрекеті',
            },
            {
                description: 'text_7',
                ru: 'Порядок вопросов',
                en: 'Question order',
                kz: 'Сұрақ тәртібі',
            },
            {
                description: 'text_8',
                ru: 'Потеря фокуса с окна теста',
                en: 'Loss of focus from the test window',
                kz: 'Сынақ терезесінен фокустың жоғалуы',
            },
            {
                description: 'text_9',
                ru: 'Смена фокуса с окна теста',
                en: 'Changing focus from the test window',
                kz: 'Сынақ терезесінен фокусты өзгерту',
            },
            {
                description: 'text_11',
                ru: 'Замирание мыши, не более',
                en: 'Mouse freeze, no more',
                kz: 'Тінтуір қатып қалады, енді жоқ ',
            },
            {
                description: 'text_12',
                ru: 'Попытка',
                en: 'Attempt',
                kz: 'Әрекет ',
            },
            {
                description: 'text_13',
                ru: 'Нарушения',
                en: 'Violations',
                kz: 'Бұзушылықтар',
            },
            {
                description: 'text_14',
                ru: 'Выберите вопрос для отображения',
                en: 'Select a question to display',
                kz: 'Көрсетілетін сұрақты таңдаңыз ',
            },
            {
                description: 'text_15',
                ru: 'Клиент еще не начинал попыток прохождения',
                en: 'The client has not yet started attempts to pass',
                kz: 'Клиент өту әрекетін әлі бастаған жоқ',
            },
            {
                description: 'text_16',
                ru: 'Замирание мыши',
                en: 'Mouse freeze',
                kz: 'Тінтуірдің қатуы',
            },
            {
                description: 'text_17',
                ru: 'Потеря фокуса',
                en: 'Loss of focus',
                kz: 'Фокустың жоғалуы',
            },
            {
                description: 'text_18',
                ru: 'без учета',
                en: 'excluding',
                kz: 'есепке алмағанда',
            },
            {
                description: 'sec',
                ru: 'cек',
                en: 'sec',
                kz: 'сек',
            },
            {
                description: 'd',
                ru: 'д.',
                en: 'd.',
                kz: 'д.',
            },
            {
                description: 'ch',
                ru: 'ч.',
                en: 'ch.',
                kz: 'ч.',
            },
            {
                description: 'min',
                ru: 'мин.',
                en: 'min.',
                kz: 'мин.',
            },
            {
                description: 'no',
                ru: 'нет',
                en: 'no',
                kz: 'жоқ',
            },

        ]
    },
    {
        link: 'study/test',
        tag: 'result',
        translation: [
            {
                description: 'text_1',
                ru: 'Без раздела',
                en: 'No section',
                kz: 'Бөлім жоқ',
            },
            {
                description: 'text_2',
                ru: 'Правильных ответов',
                en: 'Correct answers',
                kz: 'Дұрыс жауаптар',
            },
            {
                description: 'text_3',
                ru: 'Количество баллов',
                en: 'Number of points',
                kz: 'Ұпай саны',
            },
            {
                description: 'text_4',
                ru: 'Не учитывались',
                en: 'Not taken into payment',
                kz: 'Есепке алынбаған',
            },
            {
                description: 'text_5',
                ru: 'Всего вопросов',
                en: 'Total questions',
                kz: 'Жалпы сұрақтар',
            },
            {
                description: 'text_6',
                ru: 'Вопросов отвечено',
                en: 'Questions answered',
                kz: 'Барлық сабақ, барлық сабақтар',
            },
            {
                description: 'text_7',
                ru: 'всего/верные',
                en: 'total/faithful',
                kz: 'толық/сенімді',
            },
            {
                description: 'text_8',
                ru: 'Разделы',
                en: 'Sections',
                kz: 'Бөлімдер',
            },
            {
                description: 'text_9',
                ru: 'Краткий результат',
                en: 'Short result',
                kz: 'Қысқа нәтиже',
            },
            {
                description: 'text_10',
                ru: 'Полный результат',
                en: 'Full result',
                kz: 'Толық нәтиже',
            },
            {
                description: 'text_11',
                ru: 'Время ответа',
                en: 'Response time',
                kz: 'Жауап беру уақыты',
            },
            {
                description: 'text_12',
                ru: 'Баллы',
                en: 'Points',
                kz: 'Ұпайлар',
            },
            {
                description: 'text_13',
                ru: 'Баллы от сотрудника',
                en: 'Points from an employee',
                kz: 'Қызметкердің ұпайлары',
            },
            {
                description: 'text_14',
                ru: 'Смена фокуса',
                en: 'Focus change',
                kz: 'Фокустың өзгеруі',
            },
            {
                description: 'text_15',
                ru: 'Поддерживаемые форматы: PDF, JPG, PNG, DOC, DOCX, TXT',
                en: 'Supported formats: PDF, JPG, PNG, DOC, DOCX, TXT ',
                kz: 'Қолдау көрсетілетін пішімдері: PDF, JPG, PNG, DOC, DOCX, TXT',
            },
            {
                description: 'text_16',
                ru: 'Загрузить файл',
                en: 'Upload file',
                kz: 'Файлды жүктеп салу',
            },
            {
                description: 'text_17',
                ru: 'попытка',
                en: 'attempt',
                kz: 'әрекет',
            },
            {
                description: 'text_18',
                ru: 'Результат теста не доступен',
                en: 'Test result not available',
                kz: 'Сынақ нәтижесі қолжетімді емес',
            },
        ]
    },
    {
        link: 'study/study-test',
        translation: [
            {
                description: 'add_test',
                ru: 'Добавить тест',
                en: 'Add test',
                kz: 'Тест қосыңыз',
            },
            {
                description: 'text_1',
                ru: 'Добавление нового тестирования',
                en: 'Adding a new test',
                kz: 'Жаңа сынақ қосу',
            },
            {
                description: 'text_2',
                ru: 'Учебные тесты',
                en: 'Study tests',
                kz: 'Оқу сынақтары',
            },
            {
                description: 'text_3',
                ru: 'Страница теста',
                en: 'Test page',
                kz: 'Сынақ беті',
            },
            {
                description: 'text_4',
                ru: 'Назначить группе',
                en: 'Assign to group',
                kz: 'Топқа тағайындау',
            },
            {
                description: 'text_5',
                ru: 'Назначить студентам',
                en: 'Assign students',
                kz: 'Оқушыларды тағайындау',
            },
            {
                description: 'text_6',
                ru: 'Удалить учебный тест',
                en: 'Delete practice test',
                kz: 'Тәжірибелік сынақты жою',
            },
            {
                description: 'text_7',
                ru: 'Краткое описание',
                en: 'Short description',
                kz: 'Қысқаша сипаттамасы',
            },
            {
                description: 'text_8',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'text_9',
                ru: 'Добавление вопроса в тест',
                en: 'Adding a question to a test',
                kz: 'Тестке сұрақ қосу',
            },
            {
                description: 'text_10',
                ru: 'Вопросы для теста',
                en: 'Questions for the test',
                kz: 'Тестке арналған сұрақтар',
            },
            {
                description: 'text_11',
                ru: 'Удалить вопрос',
                en: 'Delete question',
                kz: 'Сұрақты жою',
            },
            {
                description: 'text_12',
                ru: 'Тесты, назначенные клиентам',
                en: 'Tests Assigned to Clients',
                kz: 'Клиенттерге тағайындалған сынақтар',
            },
            {
                description: 'text_13',
                ru: 'Повторно отправить приглашение по e-mail',
                en: 'Resend invitation email',
                kz: 'Шақыру электрондық поштасын қайта жіберу',
            },
            {
                description: 'text_14',
                ru: 'Клиентов',
                en: 'Clients',
                kz: 'Клиенттер',
            },
            {
                description: 'text_15',
                ru: 'Начало теста',
                en: 'Test start',
                kz: 'Сынақ бастау',
            },
            {
                description: 'text_16',
                ru: 'Окончание теста',
                en: 'End of the test',
                kz: 'Сынақтың соңы',
            },
            {
                description: 'text_17',
                ru: 'Параметры',
                en: 'Parameters',
                kz: 'Параметрлер',
            },
            {
                description: 'text_18',
                ru: 'Название теста',
                en: 'Test name',
                kz: 'Сынақ атауы',
            },
            {
                description: 'text_19',
                ru: 'Баллов',
                en: 'Points',
                kz: 'Ұпай',
            },
            {
                description: 'text_20',
                ru: 'Выберите уровень',
                en: 'Choose a level',
                kz: 'Деңгейді таңдаңыз',
            },
            {
                description: 'text_21',
                ru: 'Ваши тесты',
                en: 'Your tests',
                kz: 'Сіздің сынақтарыңыз',
            },
            {
                description: 'text_22',
                ru: 'Перейти к тесту',
                en: 'Go to test',
                kz: 'Тестке өтіңіз',
            },
            {
                description: 'text_23',
                ru: 'Попыток',
                en: 'Attempts',
                kz: 'Әрекеттері',
            },
            {
                description: 'text_24',
                ru: 'Длительность теста, мин.',
                en: 'Test duration, min.',
                kz: 'Сынақ ұзақтығы, мин.',
            },
            {
                description: 'text_25',
                ru: 'Отклонить',
                en: 'Reject',
                kz: 'Қабылдамау',
            },
            {
                description: 'text_26',
                ru: 'Запросить заново',
                en: 'Request again',
                kz: 'Қайтадан сұрау',
            },
            {
                description: 'text_27',
                ru: 'Срок действия доступа',
                en: 'Access validity period',
                kz: 'Қолжетімділік мерзімі',
            },
            {
                description: 'text_28',
                ru: 'Уровень доступа к тесту',
                en: 'Test access level',
                kz: 'Сынақ қолжетімділік деңгейі',
            },
            {
                description: 'text_29',
                ru: 'Доступен всем преподователям',
                en: 'Available to all teachers',
                kz: 'Барлық мұғалімдерге қолжетімді',
            },
            {
                description: 'text_30',
                ru: 'Доступен по запросу',
                en: 'Available upon request',
                kz: 'Сұраныс бойынша қол жетімді',
            },
            {
                description: 'text_32',
                ru: 'Раздел',
                en: 'Chapter',
                kz: 'Бөлім',
            },
            {
                description: 'text_33',
                ru: 'Редактирование вопроса',
                en: 'Question editing',
                kz: 'Сұрақты өңдеу',
            },
            {
                description: 'text_34',
                ru: 'Добавление вопроса в тест',
                en: 'Adding a question to a test',
                kz: 'Тестке сұрақ қосу',
            },
            {
                description: 'text_35',
                ru: "Текстовое поле ответа должно быть идентично ответу клиента",
                en: "The response text field must be identical to the client's response",
                kz: "Жауап мәтінінің өрісі клиенттің жауабымен бірдей болуы керек ",
            },
            {
                description: 'text_36',
                ru: 'Предложение',
                en: 'Sentence',
                kz: 'Сөйлем',
            },
            {
                description: 'text_37',
                ru: 'Напишите предложения и разделите слова вертикальной чертой. Слова автоматически перемешаются после сохранения.',
                en: 'Write sentences and separate the words with a vertical bar. Words will automatically shuffle after saving.',
                kz: 'Сөйлемдерді жазыңыз және сөздерді тік жолақпен ажыратыңыз. Сөздер сақталғаннан кейін автоматты түрде араласады.',
            },
            {
                description: 'text_38',
                ru: 'Пример',
                en: 'Example',
                kz: 'Мысал',
            },
            {
                description: 'text_39',
                ru: 'Запишите в квадратных скобках только правильное слово (выражение).',
                en: 'Write in square brackets only the correct word (expression).',
                kz: 'Шаршы жақшаға тек дұрыс сөзді (өрнекті) жаз.',
            },
            {
                description: 'text_40',
                ru: 'При ответе будет пустое текстовое поле в которое клиент сможет внести текст или форма для загрузки сочинения в формате: JPG, PNG, PDF.',
                en: 'When answering, there will be an empty text field in which the client can enter text or a form to upload an essay in the format: JPG, PNG, PDF.',
                kz: 'Жауап беру кезінде бос мәтін өрісі болады, оған клиент мәтінді немесе эссені JPG, PNG, PDF форматында жүктеп салу үшін пішінді енгізе алады.',
            },
            {
                description: 'text_41',
                ru: 'Напишите слово или фразу. Слово будет автоматически разбито на буквы после сохранения.',
                en: 'Write a word or phrase. The word will be automatically split into letters after saving.',
                kz: 'Сөз немесе сөз тіркесін жазыңыз. Сөз сақталғаннан кейін автоматты түрде әріптерге бөлінеді',
            },
            {
                description: 'text_42',
                ru: 'Запишите варианты ответа в квадратных скобках и отметьте правильный вариант звездочкой *.',
                en: 'Write the answer options in square brackets and mark the correct answer with an asterisk *.',
                kz: 'Жауап нұсқаларын төртбұрышты жақшаға жазып, дұрыс жауапты * жұлдызшамен белгілеңіз.',
            },
            {
                description: 'text_43',
                ru: 'Изображения и описания',
                en: 'Images and descriptions',
                kz: 'Суреттер мен сипаттамалар',
            },
            {
                description: 'text_44',
                ru: 'Добавьте изображения с подписями. Ваши ученики должны будут их соединить.',
                en: 'Add images with captions. Your students will have to put them together.',
                kz: 'Жазбалары бар суреттерді қосыңыз. Сіздің студенттеріңіз оларды біріктіруі керек.',
            },
            {
                description: 'text_45',
                ru: 'Выберите тип вопроса',
                en: 'Select question type',
                kz: 'Сұрақ түрін таңдаңыз',
            },
            {
                description: 'text_46',
                ru: 'Выберите раздел',
                en: 'Choose a section',
                kz: 'Бөлімді таңдаңыз',
            },
            {
                description: 'text_47',
                ru: 'Вопрос или условие',
                en: 'Question or condition',
                kz: 'Сұрақ немесе шарт',
            },
            {
                description: 'text_48',
                ru: 'По вашему запросу нет соответствий',
                en: 'There are no matches for your search',
                kz: 'Іздеуіңізге сәйкестік жоқ',
            },
        ]
    },
    {
        link: 'study/study-test',
        tag: 'modal',
        translation: [
            {
                description: 'text_1',
                ru: 'Название тестирования для студентов',
                en: 'Name of test for students',
                kz: 'Оқушыларға арналған тест атауы',
            },
            {
                description: 'text_2',
                ru: 'Поиск группы по номеру или названию',
                en: 'Search for a group by number or name',
                kz: 'Топты нөмірі немесе аты бойынша іздеңіз',
            },
            {
                description: 'text_3',
                ru: 'Поиск студента по имени, e-mail или телефону',
                en: 'Search for a student by name, e-mail or phone ',
                kz: 'Оқушыны аты, электрондық поштасы немесе телефоны бойынша іздеңіз',
            },
            {
                description: 'text_4',
                ru: 'Вводите имя, e-mail или телефон студента',
                en: 'Enter the name, e-mail or phone number of the student ',
                kz: 'Студенттің атын, электрондық поштасын немесе телефон нөмірін енгізіңіз ',
            },
            {
                description: 'text_5',
                ru: 'Текущие студенты группы',
                en: 'Current group students',
                kz: 'Қазіргі топ студенттері',
            },
            {
                description: 'text_6',
                ru: 'Срок действия доступа для тестирования',
                en: 'Expiry date for testing access',
                kz: 'Сынақ рұқсатының жарамдылық мерзім',
            },
            {
                description: 'text_7',
                ru: 'Вы запрашиваете время тестирования по вашей часовой зоне',
                en: 'You are requesting a test time in your time zone ',
                kz: 'Уақыт белдеуіңізде сынақ уақытын сұрап жатырсыз',
            },
            {
                description: 'text_8',
                ru: 'Настройка безопасности тестирования',
                en: 'Setting up test security ',
                kz: 'Сынақ қауіпсіздігін орнату ',
            },
            {
                description: 'text_9',
                ru: 'Подробнее о безопасности тестов',
                en: 'Learn more about test security ',
                kz: 'Сынақ қауіпсіздігі туралы көбірек біліңіз',
            },
            {
                description: 'text_10',
                ru: 'Время выполнения теста, мин.',
                en: 'Test execution time, min.',
                kz: 'Тесттің орындалу уақыты, мин.',
            },
            {
                description: 'text_11',
                ru: 'Количество попыток прохождения',
                en: 'Number of pass attempts',
                kz: 'Өту әрекеттерінің саны',
            },
            {
                description: 'text_12',
                ru: 'Порядок вопросов',
                en: 'Question Order',
                kz: 'Сұрақ тәртібі',
            },
            {
                description: 'text_13',
                ru: 'Потеря фокуса окон, не более сек.',
                en: 'Loss of window focus, no more than sec.',
                kz: 'Терезе фокусының жоғалуы, секундтан аспайды.',
            },
            {
                description: 'text_14',
                ru: 'Количество раз',
                en: 'Number of times',
                kz: 'Уақыт саны',
            },
            {
                description: 'text_15',
                ru: 'Действие тестирования для клиентов',
                en: 'Testing action for clients',
                kz: 'Клиенттер үшін тестілеу әрекеті',
            },
            {
                description: 'text_16',
                ru: 'Тип отображения результатов',
                en: 'Results display type',
                kz: 'Нәтижелерді көрсету түрі',
            },
            {
                description: 'text_17',
                ru: 'Замирание мыши, не более сек.',
                en: 'Mouse freeze, no more than sec.',
                kz: 'Тінтуірдің қатуы, секундтан аспайды.',
            },
            {
                description: 'text_18',
                ru: 'Перемешать',
                en: 'Mix',
                kz: 'Араластырыңыз',
            },
            {
                description: 'text_19',
                ru: 'По порядку',
                en: 'In order',
                kz: 'Қалпында',
            },
            {
                description: 'text_20',
                ru: 'Только результат',
                en: 'Only result',
                kz: 'Тек нәтиже',
            },
            {
                description: 'text_21',
                ru: 'Результат по вопросам',
                en: 'Result by questions',
                kz: 'Сұрақтар бойынша нәтиже',
            },
            {
                description: 'text_22',
                ru: 'Назначить тест для ',
                en: 'Schedule a test for ',
                kz: 'Үшін сынақ жоспарлаңыз ',
            },
            {
                description: 'text_23',
                ru: 'Тест назначен',
                en: 'Test assigned',
                kz: 'Тест тағайындалды',
            },
            {
                description: 'text_24',
                ru: 'успешно назначен',
                en: 'successfully appointed',
                kz: 'табысты тағайындалды',
            },
            {
                description: 'text_25',
                ru: 'Перейти',
                en: 'Go',
                kz: 'Барыңыз',
            },
            {
                description: 'text_26',
                ru: 'Поддерживаемые форматы: JPG, GIF, PNG, PDF, DOC, DOCX, XLS, XLCX',
                en: 'Supported formats: JPG, GIF, PNG, PDF, DOC, DOCX, XLS, XLCX',
                kz: 'Қолдау көрсетілетін пішімдері: JPG, GIF, PNG, PDF, DOC, DOCX, XLS, XLCX ',
            },
            {
                description: 'text_27',
                ru: 'Выбрать файл',
                en: 'Select a file',
                kz: 'Файлды таңдаңыз',
            },
            {
                description: 'text_28',
                ru: 'Поддерживаемые форматы',
                en: 'Supported formats',
                kz: 'Қолдау көрсетілетін пішімдер',
            },
            {
                description: 'text_29',
                ru: 'Добавление нового раздела',
                en: 'Adding a new section',
                kz: 'Жаңа бөлім қосу',
            },
            {
                description: 'text_30',
                ru: 'Редактирование раздела',
                en: 'Section editing',
                kz: 'Бөлімді өңдеу',
            },
            {
                description: 'text_31',
                ru: 'Процент раздела от всего теста, %',
                en: 'The percentage of the section of the entire test,%',
                kz: 'Бүкіл сынақ бөлімінің пайызы,%',
            },
            {
                description: 'text_32',
                ru: 'только результат',
                en: 'only result',
                kz: 'тек нәтиже',
            },
            {
                description: 'text_33',
                ru: 'перемешать вопросы',
                en: 'shuffle questions',
                kz: 'аралас сұрақтар',
            },
            {
                description: 'text_34',
                ru: 'вопросы по порядку',
                en: 'questions in order',
                kz: 'сұрақтар ретімен',
            },
            {
                description: 'text_35',
                ru: 'фокус окон',
                en: 'windows focus',
                kz: 'терезелердің фокусы',
            },
            {
                description: 'text_36',
                ru: 'замирание мыши',
                en: 'mouse freeze',
                kz: 'тінтуір қатып қалады',
            },
            {
                description: 'text_37',
                ru: 'раз',
                en: 'once',
                kz: 'бір рет',
            },
        ]
    },
    {
        link: 'alert',
        translation: [
            {
                description: 'text_1',
                ru: 'Сохранить данные ?',
                en: 'Save data ?',
                kz: 'Деректерді сақтау ?',
            },
            {
                description: 'button_1',
                ru: 'Да',
                en: 'Yes',
                kz: 'Иә',
            },
            {
                description: 'button_2',
                ru: 'Нет',
                en: 'No',
                kz: 'Жоқ',
            },
            {
                description: 'getout',
                ru: 'Отмена',
                en: 'No',
                kz: 'Жоқ',
            },
            {
                description: 'text_2',
                ru: 'Вы уверены?',
                en: 'Are you sure?',
                kz: 'Сенімдісіз бе?',
            },
            {
                description: 'text_3',
                ru: 'Запись',
                en: 'Record',
                kz: 'Жазуы',
            },
            {
                description: 'text_4',
                ru: 'удалена',
                en: 'deleted',
                kz: 'жойылды',
            },
            {
                description: 'text_5',
                ru: 'Удаление отменено',
                en: 'Deletion canceled',
                kz: 'Жою тоқтатылды,',
            },
            {
                description: 'text_6',
                ru: 'Запись удалена',
                en: 'Entry deleted ',
                kz: 'Жазба жойылды',
            },
            {
                description: 'canceled',
                ru: 'Действие отменено',
                en: 'Action canceled',
                kz: 'Әрекет жойылды',
            },
            {
                description: 'paytoclient',
                ru: 'Выписать счет клиенту! ',
                en: 'Issue an invoice to the client!',
                kz: 'Клиентке есепшот беріңіз!',
            },
            {
                description: 'paytoclient',
                ru: 'Выписать счет клиенту! ',
                en: 'Issue an invoice to the client!',
                kz: 'Клиентке есепшот беріңіз!',
            },
        ]
    },
    {
        link: 'modal_templates',
        translation: [
            {
                description: 'text_1',
                ru: 'Вы можете загрузить изображение в формате JPG, GIF или PNG.',
                en: 'You can upload the image in JPG, GIF or PNG format.',
                kz: 'Сіз кескінді JPG, GIF немесе PNG форматында жүктей аласыз.',
            },
            {
                description: 'deleteContactPerson',
                ru: 'Удаление контактного лица',
                en: 'Delete contact person',
                kz: 'Байланыс жасайтын адамды алып тастау',
            },
            {
                description: 'Main',
                ru: 'Основное',
                en: 'Main',
                kz: 'Басты',
            },
            {
                description: 'needINN',
                ru: 'Укажите ИИН/ИНН',
                en: 'Specify IIN / INN',
                kz: 'ЖСН / ЖСН көрсетіңіз',
            },
            {
                description: 'text_2',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'date_study',
                ru: 'Дата начала обучения',
                en: 'Start date of study',
                kz: 'Оқу басталатын күн',
            },
            {
                description: 'tg_nik',
                ru: 'Telegram никнейм',
                en: 'Telegram nikname',
                kz: 'Telegram лақап ат',
            },
            {
                description: 'sex',
                ru: 'Пол',
                en: 'Sex',
                kz: 'Жынысы',
            },
            {
                description: 'email-created',
                ru: 'Такой E-mail уже зарегистрирован',
                en: 'This E-mail is already registered',
                kz: 'Бұл электронды пошта бұрын тіркелген',
            },
            {
                description: 'time-zone',
                ru: 'Часовой пояс',
                en: 'Time zone',
                kz: 'Сағаттық белдеу',
            },
            {
                description: 'inn',
                ru: 'этот ИНН уже существует',
                en: 'this TIN already exists',
                kz: 'бұл СТН бұрыннан бар',
            },
            {
                description: 'text_3',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'text_4',
                ru: 'Вместимость:',
                en: 'Capacity:',
                kz: 'Сыйымдылығы:',
            },
            {
                description: 'text_5',
                ru: 'Начало работы',
                en: 'Beginning of work',
                kz: 'Жұмыстың басталуы',
            },
            {
                description: 'text_6',
                ru: 'Конец работы',
                en: 'End of work',
                kz: 'Жұмыстың соңы',
            },
            {
                description: 'text_8',
                ru: 'Имя',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'text_9',
                ru: 'Фамилия',
                en: 'Surname',
                kz: 'Тегі',
            },
            {
                description: 'text_10',
                ru: 'Отчество',
                en: 'Middle name',
                kz: 'әкенің есімі',
            },
            {
                description: 'text_11',
                ru: 'Выберите пол',
                en: 'Select gender',
                kz: 'Жынысты таңдаңыз',
            },
            {
                description: 'text_12',
                ru: 'Часовой пояс',
                en: 'Timezone',
                kz: 'Сағаттық белдеу',
            },
            {
                description: 'text_13',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
            {
                description: 'text_14',
                ru: 'Выберите ответственного',
                en: 'Responsible',
                kz: 'Жауапкершісі',
            },
            {
                description: 'text_15',
                ru: 'Контакты',
                en: 'Contacts',
                kz: 'Байланыстар',
            },
            {
                description: 'text_16',
                ru: 'Домашний телефон',
                en: 'Home phone',
                kz: 'Үй телефоны',
            },
            {
                description: 'text_17',
                ru: 'Адрес проживания',
                en: 'Residence address',
                kz: 'Тұрғын үй мекен-жайы',
            },
            {
                description: 'text_18',
                ru: 'Обучение',
                en: 'Training',
                kz: 'Тренинг',
            },
            {
                description: 'text_19',
                ru: 'Контактное лицо',
                en: 'Contact person',
                kz: 'Байланыс жасайтын адам',
            },
            {
                description: 'text_20',
                ru: 'Статус ученика',
                en: 'Student status',
                kz: 'Студент мәртебесі',
            },
            {
                description: 'text_21',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'text_22',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
            {
                description: 'text_23',
                ru: 'Укажите имя группы',
                en: 'Arbitrary name',
                kz: 'Ерікті атау',
            },
            {
                description: 'text_23_1',
                ru: 'Номер',
                en: 'Number',
                kz: 'Number',
            },
            {
                description: 'text_24',
                ru: 'Вместимость',
                en: 'Offline',
                kz: 'Желіде тыс',
            },
            {
                description: 'text_25',
                ru: 'Выберите дисциплину',
                en: 'Discipline',
                kz: 'Тәртіп',
            },
            {
                description: 'text_26',
                ru: 'Выберите категорию',
                en: 'Category',
                kz: 'Категориясы',
            },
            {
                description: 'text_26_1',
                ru: 'Категория:',
                en: 'Category:',
                kz: 'Категориясы:',
            },
            {
                description: 'text_27',
                ru: 'Выберите уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'text_28',
                ru: 'Выберите тип группы',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'text_29',
                ru: 'Документ',
                en: 'Document',
                kz: 'Таңдаңыз',
            },
            {
                description: 'text_30',
                ru: 'Группа онлайн',
                en: 'Online group',
                kz: 'Онлайн топ',
            },
            {
                description: 'text_31',
                ru: 'Выберите цену по умолчанию',
                en: 'Default price',
                kz: 'Әдепкі бағасы',
            },
            {
                description: 'text_32',
                ru: 'Способ оплаты',
                en: 'Payment method',
                kz: 'Төлем әдісі',
            },
            {
                description: 'text_33',
                ru: 'Пакетная',
                en: 'Package',
                kz: 'Пакетпен',
            },
            {
                description: 'text_34',
                ru: 'Почасовая',
                en: 'Hourly',
                kz: 'Сағатпен',
            },
            {
                description: 'text_35',
                ru: 'Цена',
                en: 'Price',
                kz: 'Бағасы',
            },
            {
                description: 'text_36',
                ru: 'Пакетный / почасовой',
                en: 'Package / Hourly',
                kz: 'Пакетпен / Сағатпен',
            },
            {
                description: 'text_37',
                ru: 'Тип часов',
                en: 'Hour types',
                kz: ' Сағат түрі',
            },
            {
                description: 'text_38',
                ru: 'Всего часов',
                en: 'Total hours',
                kz: 'Бар сағаты',
            },
            {
                description: 'text_39',
                ru: 'Перенести на другой день',
                en: 'Shift to another day',
                kz: 'Басқа күнге ауыстыру',
            },
            {
                description: 'text_40',
                ru: 'Действие',
                en: 'Action',
                kz: 'Әрекет',
            },
            {
                description: 'text_41',
                ru: 'День переноса',
                en: 'Shifted day',
                kz: 'Ауыстыру күні',
            },
            {
                description: 'text_42',
                ru: 'Конечный день',
                en: 'End day',
                kz: 'Аяқталу күні',
            },
            {
                description: 'text_43',
                ru: 'Без преподавателя и аудитории',
                en: 'Without teacher and classroom',
                kz: 'Мұғалімсіз және аудиториясіз',
            },
            {
                description: 'text_44',
                ru: 'Выберите преподавателя',
                en: 'Select a teacher',
                kz: 'Мұғалімді таңдаңыз',
            },
            {
                description: 'text_45',
                ru: 'Аудитория',
                en: 'Classrooms',
                kz: 'Аудитория',
            },
            {
                description: 'text_48',
                ru: 'Часов в день',
                en: 'Hours per day',
                kz: 'Тәулігіне жұмыс уақыты',
            },
            {
                description: 'text_49',
                ru: 'Перерыв',
                en: 'Break',
                kz: 'Үзіліс',
            },
            {
                description: 'text_55',
                ru: 'Телефон',
                en: 'Telephone',
                kz: 'Телефон',
            },
            {
                description: 'text_56',
                ru: 'Дата рождения',
                en: 'Date of Birth',
                kz: 'туған күні',
            },
            {
                description: 'text_57',
                ru: 'Дата рождения',
                en: 'Date of Birth',
                kz: 'туған күні',
            },
            {
                description: 'text_58',
                ru: 'Выберите компанию',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'text_59',
                ru: 'Тип группы и вместимость',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'text_60',
                ru: 'Изменение дат обучения может привести к изменению проводок и баланса клиента!',
                en: "Changing the training dates can lead to a change in the customer's transactions and balance! ",
                kz: 'Оқу күндерін өзгерту клиенттің транзакциялары мен теңгерімінің өзгеруіне әкелуі мүмкін! ',
            },
            {
                description: 'text_61',
                ru: 'Подтверждаю присутствие учеников',
                en: "I confirm the presence of students",
                kz: 'Оқушылардың қатысуын растаймын',
            },
            {
                description: 'text_62',
                ru: 'Провести только общее расписание, без клиентов.',
                en: "Carry out only the general schedule, without clients.",
                kz: 'Клиенттерсіз тек жалпы кестені орындаңыз. ',
            },
            {
                description: 'text_63',
                ru: 'Списать с плательщиков',
                en: "Write off from payers",
                kz: 'Төлеушілерден есептен шығару  ',
            },
            {
                description: 'text_64',
                ru: 'Начислить преподавателям',
                en: "Charge teachers ",
                kz: 'Мұғалімдерді айыптау  ',
            },
            {
                description: 'text_65',
                ru: 'Списать с плательщика',
                en: "Write off from the payer",
                kz: 'Төлеушіден есептен шығару ',
            },
            {
                description: 'text_66',
                ru: 'Тип часов',
                en: "Hours  type  ",
                kz: 'Сағат түрі ',
            },
            {
                description: 'day',
                ru: 'День',
                en: 'Day',
                kz: 'Күн',
            },
            {
                description: 'month',
                ru: 'Месяц',
                en: 'Month',
                kz: 'Ай',
            },
            {
                description: 'year',
                ru: 'Год',
                en: 'Year',
                kz: 'Жыл',
            },
        ]
    },

    {
        link: 'groups/all-groups',
        translation: [
            {
                description: 'text_1',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'text_2',
                ru: 'Создание новой группы',
                en: 'New group',
                kz: 'Жаңа топ',
            },
            {
                description: 'sign-up',
                ru: 'Вы успешно записались в группу',
                en: 'You have successfully joined the group',
                kz: 'Сіз топқа сәтті қосылдыңыз',
            },
            {
                description: 'text_3',
                ru: 'В работе',
                en: 'in work state',
                kz: 'Жұмыста',
            },
            {
                description: 'text_4',
                ru: 'Формирующиеся',
                en: 'Forming',
                kz: 'Жиналуда',
            },
            {
                description: 'text_5',
                ru: 'Остановленные',
                en: 'Stopped',
                kz: 'Тоқтатылған',
            },
            {
                description: 'text_6',
                ru: 'Локация',
                en: 'Location',
                kz: 'Мекені',
            },
            {
                description: 'text_7',
                ru: 'Уровень группы',
                en: 'Group level',
                kz: 'Топ деңгейі',
            },
            {
                description: 'text_8',
                ru: 'Уровень курса',
                en: 'Course level',
                kz: 'Курс деңгейі',
            },
            {
                description: 'text_9',
                ru: 'Формат обучения',
                en: 'Education format',
                kz: 'Оқу форматы',
            },
            {
                description: 'text_10',
                ru: 'Тип группы',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'text_11',
                ru: 'Добавить группу',
                en: 'Add group',
                kz: 'Қосу деңгейі',
            },
            {
                description: 'text_12',
                ru: 'Учебные группы',
                en: 'Study groups',
                kz: 'Оқу топтары',
            },
            {
                description: 'text_12_1',
                ru: 'группы',
                en: 'group',
                kz: 'деңгейі',
            },
            {
                description: 'text_13',
                ru: 'Групп',
                en: 'Groups',
                kz: 'Топ',
            },
            {
                description: 'text_13_1',
                ru: 'студентов',
                en: 'students',
                kz: 'оқушылар',
            },
            {
                description: 'text_16',
                ru: 'Актуaльные',
                en: 'Actuals',
                kz: 'Нақтылары',
            },
            {
                description: 'text_17',
                ru: 'Закончившие',
                en: 'Finished',
                kz: 'Аяқтағандары',
            },
            {
                description: 'text_18',
                ru: 'Без занятий',
                en: 'No lessons',
                kz: 'Сабақсыз',
            },
            {
                description: 'text_19',
                ru: 'Студентов в данной группе пока нет',
                en: 'No student in this group yet',
                kz: 'Бұл топта оқушы жоқ әзірге',
            },
            {
                description: 'text_20',
                ru: 'Формирование группы',
                en: 'Group forming',
                kz: 'Топ жиналуда',
            },
            {
                description: 'text_21',
                ru: 'Нет подходящих групп',
                en: 'No available group',
                kz: 'Лайықты топ жоқ',
            },
            {
                description: 'text_22',
                ru: 'ПН',
                en: 'Mon',
                kz: 'Дс',
            },
            {
                description: 'text_23',
                ru: 'ВТ',
                en: 'Tue',
                kz: 'Сс',
            },
            {
                description: 'text_24',
                ru: 'СР',
                en: 'Wed',
                kz: 'Ср',
            },
            {
                description: 'text_25',
                ru: 'ЧТ',
                en: 'Thi',
                kz: 'Бсен',
            },
            {
                description: 'text_26',
                ru: 'ПТ',
                en: 'Fri',
                kz: 'Жм',
            },
            {
                description: 'text_27',
                ru: 'СБ',
                en: 'Sat',
                kz: 'Сн',
            },
            {
                description: 'text_28',
                ru: 'ВС',
                en: 'Sun',
                kz: 'Жк',
            },
            {
                description: 'text_29',
                ru: 'Онлайн ',
                en: 'Online',
                kz: 'Желіде',
            },
            {
                description: 'text_29a',
                ru: 'Офлайн ',
                en: 'Offline',
                kz: 'Желіден тыс',
            },
            {
                description: 'text_30',
                ru: 'Дисциплина',
                en: 'Discipline',
                kz: 'Тәртіп',
            },
            {
                description: 'text_31',
                ru: 'Выбирете дисциплину',
                en: 'Choose discipline',
                kz: 'Пәнді таңдаңыз',
            },
            {
                description: 'text_32',
                ru: 'Локация обучения',
                en: 'Location of education',
                kz: 'Оқыту мекені',
            },
            {
                description: 'text_33',
                ru: 'Выберите локацию',
                en: 'Choose location',
                kz: 'Оқыту мекенін таңдаңыз',
            },
            {
                description: 'text_34',
                ru: 'Удобное время обучения',
                en: 'Suitable education time',
                kz: 'Лайық оқыту уақыты',
            },
            {
                description: 'text_35',
                ru: 'Группа онлайн',
                en: 'Online group',
                kz: 'Онлайн топ',
            },
            {
                description: 'text_36',
                ru: 'Любой день недели',
                en: 'Any weekday',
                kz: 'Аптаның әр күні',
            },
            {
                description: 'text_37',
                ru: 'Сборосить фильтры',
                en: 'Reset filters',
                kz: 'Сүзгіні тазалау',
            },
            {
                description: 'text_38',
                ru: 'Вы выбрали группу:',
                en: 'You choose group:',
                kz: 'Сіз таңдаған топ:',
            },
            {
                description: 'text_39',
                ru: 'Эта группа занимается по расписанию:',
                en: 'This group works according schedule:',
                kz: 'Бұл топтың оқу кестесі:',
            },
            {
                description: 'text_40',
                ru: 'После записи в группу, необходимо в течении',
                en: 'After assigned to group need during',
                kz: 'Топқа тіркелген соң міндетті түрде',
            },
            {
                description: 'text_41',
                ru: '3-х дней',
                en: '3 days',
                kz: '3 күнде',
            },
            {
                description: 'text_42',
                ru: 'произвести предоплату, не менее',
                en: 'provide payment not less',
                kz: 'алдын ала төлем жасау керек, кемінде',
            },
            {
                description: 'text_43',
                ru: '5 000тг',
                en: '5 000tg',
                kz: '5 000тг',
            },
            {
                description: 'text_44',
                ru: 'и подписать заявку на обучение.',
                en: 'and sign request for education.',
                kz: 'және оқу туралы өтінішке қол қою керек.',
            },
            {
                description: 'text_45',
                ru: 'Произвести предоплату вы можете на сайте:',
                en: 'Advance payment is available on site:',
                kz: 'Төлемді сайтта жасай аласыз:',
            },
            {
                description: 'text_46',
                ru: 'Для подписания заявки на обучение обратитесь на ресепшн:',
                en: 'To sign the request for education apply to reception:',
                kz: 'Оқу туралы өтінішке қол қою үшін қабылдау бөліміне хабарласыңыз:',
            },
            {
                description: 'text_47',
                ru: 'Записаться в группу',
                en: 'Assign to group',
                kz: 'Топқа тіркелу',
            },
            {
                description: 'text_472',
                ru: 'Записать в группу',
                en: 'Join the group',
                kz: 'Топқа қосылыңыз',
            },
            {
                description: 'text_48',
                ru: 'Формируется',
                en: 'Forming',
                kz: 'Жиналуда',
            },
            {
                description: 'text_49',
                ru: 'Расписание:',
                en: 'Schedule:',
                kz: 'Кестесі:',
            },
            {
                description: 'text_50',
                ru: 'Выбрать эту группу',
                en: 'Schedule:',
                kz: 'Select this group',
            },
            {
                description: 'text_51',
                ru: 'Расписание не назначено',
                en: 'Schedule not assigned',
                kz: 'Кесте тағайындалмады',
            },
            {
                description: 'text_52',
                ru: 'Добавить ученика',
                en: 'Add student',
                kz: 'Оқушыны қосу',
            },
            {
                description: 'text_53',
                ru: 'Редактирование расписания',
                en: 'Edit schedule',
                kz: 'Кестесін сабақтау',
            },
            {
                description: 'text_53a',
                ru: 'Добавить расписания',
                en: 'Add schedule',
                kz: 'Кестесін сабақтау',
            },
            {
                description: 'text_54',
                ru: 'Сохранить',
                en: 'Save',
                kz: 'Сақтау',
            },
            {
                description: 'text_56',
                ru: 'Развернуть расписание',
                en: 'Enlarge schedule',
                kz: 'Кестені кеңінен ашу',
            },
            {
                description: 'text_56_less',
                ru: 'Свернуть расписание',
                en: 'Minimize schedule',
                kz: 'Minimize schedule',
            },
            {
                description: 'text_57',
                ru: 'Удалить запись?',
                en: 'Delete record?',
                kz: 'Жазбаны өшіресіз ба?',
            },
            /*{
                description: 'text_58',
                ru: 'с ',
                en: 'from ',
                kz: 'басы ',
            },*/
            /*{
                description: 'text_59',
                ru: ' по ',
                en: ' till ',
                kz: ' аяғы ',
            },*/
            {
                description: 'text_60',
                ru: 'Параметры класса',
                en: 'Class parameters',
                kz: 'Сыныптың параметрлері',
            },
            {
                description: 'text_61',
                ru: 'Комментарий для группы',
                en: 'Comments for group',
                kz: 'Топқа арналған пікір',
            },
            {
                description: 'text_62',
                ru: 'Коментарий',
                en: 'Comment',
                kz: 'Пікір',
            },
            {
                description: 'text_63',
                ru: 'Документы',
                en: 'Documents',
                kz: 'Құжаттар',
            },
            {
                description: 'text_64',
                ru: 'Подписанные:',
                en: 'Signed:',
                kz: 'Қол қойылғаны:',
            },
            {
                description: 'text_65',
                ru: 'Ожидают подписание:',
                en: 'Awating signature:',
                kz: 'Қол қоюын күткені:',
            },
            {
                description: 'text_66',
                ru: 'Начислено студентам',
                en: 'Accrued to students',
                kz: 'Оқушыларға есептеледі',
            },
            {
                description: 'text_67',
                ru: 'Задолженности',
                en: 'Debts',
                kz: 'Қарыздар',
            },
            {
                description: 'text_68',
                ru: 'ВСЕГО',
                en: 'TOTAL',
                kz: 'БАРЛЫҒЫ',
            },
            {
                description: 'text_69',
                ru: 'Вместимость:',
                en: 'Capacity:',
                kz: 'Сыйымдылығы:',
            },
            {
                description: 'text_70',
                ru: '- численность',
                en: '- quantity',
                kz: '- саны',
            },
            {
                description: 'text_71',
                ru: '- должников',
                en: '- debtors',
                kz: '- қарызы',
            },
            {
                description: 'text_72',
                ru: 'Посещений:',
                en: 'Visits:',
                kz: 'Келгендері:',
            },
            {
                description: 'text_73',
                ru: '- оплачиваемых',
                en: '- paid',
                kz: '- ақылы',
            },
            {
                description: 'text_74',
                ru: '- бесплатных',
                en: '- free',
                kz: '- тегін',
            },
            {
                description: 'text_75',
                ru: 'Пропусков:',
                en: 'Not attended:',
                kz: 'Қатыспағандар:',
            },
            {
                description: 'text_76',
                ru: 'Параметры группы',
                en: 'Group parameters',
                kz: 'Тобтың параметрлері',
            },
            {
                description: 'text_77',
                ru: 'Компания:',
                en: 'Company:',
                kz: 'Компания:',
            },
            {
                description: 'text_78',
                ru: 'Локация:',
                en: 'Location:',
                kz: 'Мекені:',
            },
            {
                description: 'text_79',
                ru: 'Ответственный',
                en: 'Responsible',
                kz: 'Жауапкершісі',
            },
            {
                description: 'text_80',
                ru: 'Дисциплина:',
                en: 'Discipline:',
                kz: 'Пәні:',
            },
            {
                description: 'text_81',
                ru: 'Категория',
                en: 'Category',
                kz: 'Категориясы',
            },
            {
                description: 'text_82',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгей',
            },
            {
                description: 'text_83',
                ru: 'Способ оплаты:',
                en: 'Payment method:',
                kz: 'Төлем әдісі:',
            },
            {
                description: 'text_84',
                ru: 'По занятиям',
                en: 'per lessons',
                kz: 'сабағымен',
            },
            {
                description: 'text_85',
                ru: 'Цена:',
                en: 'Price:',
                kz: 'Бағасы:',
            },
            {
                description: 'text_86',
                ru: 'Средний возраст:',
                en: 'Average age:',
                kz: 'Орташа жасы:',
            },
            {
                description: 'text_87',
                ru: 'Комментарий',
                en: 'Comment',
                kz: 'Пікір',
            },
            {
                description: 'text_88',
                ru: 'Курс обучения',
                en: 'Education course',
                kz: 'Оқыту курсы',
            },
            {
                description: 'text_89',
                ru: 'Всего',
                en: 'Total',
                kz: 'Барлығы',
            },
            {
                description: 'text_90',
                ru: 'долг',
                en: 'debt',
                kz: 'қарызы',
            },
            {
                description: 'text_91',
                ru: 'астр.ч',
                en: 'astr.h',
                kz: 'астр.с',
            },
            {
                description: 'text_92',
                ru: 'Статистика на',
                en: 'Statistics for',
                kz: 'Статистикасы',
            },
            {
                description: 'text_93',
                ru: 'Не заполнено',
                en: 'Not filled',
                kz: 'Толтырылмады',
            },
            {
                description: 'teachers',
                ru: 'Преподаватели',
                en: 'Teachers',
                kz: 'Мұғалімдер',
            },
            {
                description: 'text_94',
                ru: 'Имя или номер группы',
                en: 'Group name or number',
                kz: 'Топтың атауы немесе нөмірі',
            },
            {
                description: 'text_95',
                ru: 'Имя, телефон, e-mail или ИИН студента',
                en: 'Name, phone, e-mail or IIN student',
                kz: 'Оқушының аты-жөні, телефон нөмірі, электрондық поштасы немесе ЖСН',
            },
            {
                description: 'text_96',
                ru: 'Без расписания',
                en: 'No schedule ',
                kz: 'Кесте жоқ',
            },
            {
                description: 'text_97',
                ru: 'Без учителя',
                en: 'Without a teacher',
                kz: 'Мұғалімсіз',
            },
            {
                description: 'text_98',
                ru: 'Преподаватели и расписания',
                en: 'Teachers and schedules ',
                kz: 'Мұғалімдер және кестелер ',
            },
            {
                description: 'text_99',
                ru: 'Статистика',
                en: 'Statistics',
                kz: 'Статистика',
            },
            {
                description: 'text_100',
                ru: 'Всего учеников',
                en: 'Total students ',
                kz: 'Оқушылардың жалпы саны ',
            },
            {
                description: 'text_101',
                ru: 'Должников',
                en: 'Debtors',
                kz: 'Борышкерлер ',
            },
            {
                description: 'text_102',
                ru: 'Актуальные',
                en: 'Actual',
                kz: 'Нақты',
            },
            {
                description: 'text_103',
                ru: 'Расписание и преподаватели отсутствуют',
                en: 'Schedule and teachers are missing ',
                kz: 'Сабақ кестесі мен мұғалімдер жетіспейді ',
            },
            {
                description: 'text_104',
                ru: 'Не выбран',
                en: 'Not selected',
                kz: 'Таңдалмаған  ',
            },
            {
                description: 'text_105',
                ru: 'Присутствие студентов',
                en: 'Student presence',
                kz: 'Студенттің қатысуы ',
            },
            {
                description: 'all_groups',
                ru: 'Все группы',
                en: 'All groups',
                kz: 'Барлық топтар',
            },
            {
                description: 'debtors',
                ru: 'С должниками',
                en: 'Debtors',
                kz: 'Борышкерлермен',
            },
        ]
    },
    {
        link: 'groups/group',
        translation: [
            {
                description: 'astr_h',
                ru: 'астр.ч.',
                en: 'astr.h.',
                kz: 'астр.с.',
            },
            {
                description: 'ac_h',
                ru: 'ак.ч.',
                en: 'ac.h.',
                kz: 'ак.с.',
            },
            {
                description: 'yr',
                ru: 'г.',
                en: 'y.',
                kz: 'ж.',
            },
            {
                description: 'tg',
                ru: 'тг',
                en: 'tg',
                kz: 'тг',
            },
            {
                description: 'no_course',
                ru: 'Курс пока не назначен',
                en: 'No course assigned yet',
                kz: 'Әзірге курс тағайындалмаған',
            },
            {
                description: 'lessons',
                ru: 'Уроков',
                en: 'Lessons',
                kz: 'Дәрістер',
            },
            {
                description: 'group_type',
                ru: 'Тип группы',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'teacher_options',
                ru: 'Параметры преподавателя',
                en: 'Teacher options',
                kz: 'Мұғалімнің нұсқалары',
            },
            {
                description: 'group_rate',
                ru: 'Ставка по группе',
                en: 'Group rate',
                kz: 'Топтық ставка',
            },
            {
                description: 'total_ac_h',
                ru: 'Всего ак.ч.',
                en: 'Total ac.h.',
                kz: 'Жалпы ак.с.',
            },
            {
                description: 'payment_for_all_time',
                ru: 'Оплата за всё время',
                en: 'Payment for all time',
                kz: 'Барлық уақытта төлеу',
            },
            {
                description: 'timetable',
                ru: 'Расписание',
                en: 'Timetable',
                kz: 'Кесте',
            },
            {
                description: 'mins',
                ru: 'мин',
                en: 'min',
                kz: 'мин',
            },
            {
                description: 'lesson',
                ru: 'Занятие',
                en: 'Lesson',
                kz: 'Сабақ',
            },
            {
                description: 'holiday',
                ru: 'Праздничный день',
                en: 'Holiday',
                kz: 'Мереке',
            },
            {
                description: 'complete_lesson_information',
                ru: 'Полная информация об уроке',
                en: 'Complete lesson information',
                kz: 'Сабақ туралы толық ақпарат',
            },
            {
                description: 'view_lesson',
                ru: 'Посмотреть урок',
                en: 'View lesson',
                kz: 'Сабақты қарау',
            },
            {
                description: 'start_lesson',
                ru: 'Начать урок',
                en: 'Start lesson',
                kz: 'Сабақты бастау',
            },
            {
                description: 'teacher_not_selected',
                ru: 'Учитель не выбран',
                en: 'Teacher not selected',
                kz: 'Мұғалім таңдалмаған',
            },
            {
                description: 'spent_this_day',
                ru: 'Списано за этот день',
                en: 'Spent this day',
                kz: 'Осы күнді өткізді',
            },
            {
                description: 'home_tasks',
                ru: 'Домашние задания',
                en: 'HomeTasks',
                kz: 'HomeTasks',
            },
            {
                description: 'rate_list',
                ru: 'Отчет об успеваемости',
                en: 'Progress report',
                kz: 'Барысы туралы есеп',
            },
            {
                description: 'homework_lesson_plan',
                ru: 'Домашнее занятие / план занятия',
                en: 'Homework / Lesson Plan',
                kz: 'Үй жұмысы / сабақ жоспары',
            },
            {
                description: 'lesson_date',
                ru: 'День занятий',
                en: 'Lesson date',
                kz: 'Сабақ күні',
            },
            {
                description: 'tests',
                ru: 'Тесты',
                en: 'Tests',
                kz: 'Тесттер',
            },
            {
                description: 'story',
                ru: 'Последняя история действий',
                en: 'Last story of action',
                kz: 'Соңғы әрекеттің тарихы ',
            },
            {
                description: 'adding_personal_test_results',
                ru: 'Добавление результатов персонального теста',
                en: 'Adding personal test results',
                kz: 'Жеке тест нәтижелерін қосу',
            },
            {
                description: 'select_student',
                ru: 'Выберите студента',
                en: 'Select student',
                kz: 'Студентті таңдаңыз',
            },
            {
                description: 'select_categories',
                ru: 'Выберите категории',
                en: 'Select categories',
                kz: 'Санаттарды таңдаңыз',
            },
            {
                description: 'select_test',
                ru: 'Выберите тест',
                en: 'Select test',
                kz: 'Тестті таңдаңыз',
            },
            {
                description: 'points',
                ru: 'Количество баллов',
                en: 'Points',
                kz: 'Ұпай саны',
            },
            {
                description: 'date',
                ru: 'Дата теста',
                en: 'Date of test',
                kz: 'Дата теста',
            },
            {
                description: 'spent',
                ru: 'проведено',
                en: 'spent',
                kz: 'орындалған',
            },
            {
                description: 'scheduled',
                ru: 'по расписанию',
                en: 'scheduled',
                kz: 'жоспарланған',
            },
            {
                description: 'tg_for',
                ru: 'тг за',
                en: 'tg for',
                kz: 'tg үшін',
            },
            {
                description: 'group_status',
                ru: 'Статус группы',
                en: 'Group status',
                kz: 'Топ мәртебесі',
            },
            {
                description: 'extend_schedule',
                ru: 'Продлить расписание',
                en: 'Extend schedule',
                kz: 'Кестені ұзарту',
            },
            {
                description: 'close_schedule',
                ru: 'Закрыть расписание',
                en: 'Close schedule',
                kz: 'Кестені жабу',
            },
            {
                description: 'cl',
                ru: 'ауд',
                en: 'cl',
                kz: 'ауд',
            },
            {
                description: 'interrupted_training',
                ru: 'Прервал обучение',
                en: 'Interrupted training',
                kz: 'Жаттығулар үзілді',
            },
            {
                description: 'deleted',
                ru: 'Удалённый',
                en: 'Deleted',
                kz: 'Қашықтан басқару',
            },
            {
                description: 'edit_profile',
                ru: 'Редактировать профиль',
                en: 'Edit profile',
                kz: 'Профильді өңдеу',
            },
            {
                description: 'change_status',
                ru: 'Изменить статус',
                en: 'Change status',
                kz: 'Мәртебесін өзгерту',
            },
            {
                description: 'discount',
                ru: 'Скидка',
                en: 'Discount',
                kz: 'Жеңілдік',
            },
            {
                description: 'extra_charge',
                ru: 'Наценка',
                en: 'Extra charge',
                kz: 'Қосымша төлем',
            },
            {
                description: 'certificate',
                ru: 'Сертификат',
                en: 'Certificate',
                kz: 'Сертификат',
            },
            {
                description: 'study_dates',
                ru: 'Даты обучения',
                en: 'Dates of study',
                kz: 'Оқу күндері',
            },
            {
                description: 'astr_h',
                ru: 'астр.ч.',
                en: 'astr.h.',
                kz: 'астр.с.',
            },
            {
                description: 'ac_h',
                ru: 'ак.ч.',
                en: 'ac.h.',
                kz: 'ак.с.',
            },
            {
                description: 'tg',
                ru: 'тг',
                en: 'tg',
                kz: 'тг',
            },
            {
                description: 'no_course',
                ru: 'Курс пока не назначен',
                en: 'No course assigned yet',
                kz: 'Әзірге курс тағайындалмаған',
            },
            {
                description: 'lessons',
                ru: 'Уроков',
                en: 'Lessons',
                kz: 'Дәрістер',
            },
            {
                description: 'group_type',
                ru: 'Тип группы',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'teacher_options',
                ru: 'Параметры преподавателя',
                en: 'Teacher options',
                kz: 'Мұғалімнің нұсқалары',
            },
            {
                description: 'group_rate',
                ru: 'Ставка по группе',
                en: 'Group rate',
                kz: 'Топтық ставка',
            },
            {
                description: 'total_ac_h',
                ru: 'Всего ак.ч.',
                en: 'Total ac.h.',
                kz: 'Жалпы ак.с.',
            },
            {
                description: 'payment_for_all_time',
                ru: 'Оплата за всё время',
                en: 'Payment for all time',
                kz: 'Барлық уақытта төлеу',
            },
            {
                description: 'timetable',
                ru: 'Расписание',
                en: 'Timetable',
                kz: 'Кесте',
            },
            {
                description: 'mins',
                ru: 'мин',
                en: 'min',
                kz: 'мин',
            },
            {
                description: 'lesson',
                ru: 'Занятие',
                en: 'Lesson',
                kz: 'Сабақ',
            },
            {
                description: 'holiday',
                ru: 'Праздничный день',
                en: 'Holiday',
                kz: 'Мереке',
            },
            {
                description: 'view_lesson',
                ru: 'Посмотреть урок',
                en: 'View lesson',
                kz: 'Сабақты қарау',
            },
            {
                description: 'start_lesson',
                ru: 'Начать урок',
                en: 'Start lesson',
                kz: 'Сабақты бастау',
            },
            {
                description: 'teacher_not_selected',
                ru: 'Учитель не выбран',
                en: 'Teacher not selected',
                kz: 'Мұғалім таңдалмаған',
            },
            {
                description: 'spent_this_day',
                ru: 'Списано за этот день',
                en: 'Spent this day',
                kz: 'Осы күнді өткізді',
            },
            {
                description: 'transferring_lesson',
                ru: 'Перенос занятия',
                en: 'Transferring a lesson',
                kz: 'Сабақты ауыстыру',
            },
            {
                description: 'transfer_to',
                ru: 'на',
                en: 'to',
                kz: '',
            },
            {
                description: 'home_tasks',
                ru: 'Домашние задания',
                en: 'HomeTasks',
                kz: 'HomeTasks',
            },
            {
                description: 'rate_list',
                ru: 'Отчет об успеваемости',
                en: 'Progress report',
                kz: 'Барысы туралы есеп',
            },
            {
                description: 'homework_lesson_plan',
                ru: 'Домашнее занятие / план занятия',
                en: 'Homework / Lesson Plan',
                kz: 'Үй жұмысы / сабақ жоспары',
            },
            {
                description: 'lesson_date',
                ru: 'День занятий',
                en: 'Lesson date',
                kz: 'Сабақ күні',
            },
            {
                description: 'tests',
                ru: 'Тесты',
                en: 'Tests',
                kz: 'Тесттер',
            },
            {
                description: 'adding_personal_test_results',
                ru: 'Добавление результатов персонального теста',
                en: 'Adding personal test results',
                kz: 'Жеке тест нәтижелерін қосу',
            },
            {
                description: 'select_student',
                ru: 'Выберите студента',
                en: 'Select student',
                kz: 'Студентті таңдаңыз',
            },
            {
                description: 'select_categories',
                ru: 'Выберите категории',
                en: 'Select categories',
                kz: 'Санаттарды таңдаңыз',
            },
            {
                description: 'select_test',
                ru: 'Выберите тест',
                en: 'Select test',
                kz: 'Тестті таңдаңыз',
            },
            {
                description: 'points',
                ru: 'Количество баллов',
                en: 'Points',
                kz: 'Ұпай саны',
            },
            {
                description: 'date',
                ru: 'Дата',
                en: 'Date',
                kz: 'Дата',
            },
            {
                description: 'spent',
                ru: 'проведено',
                en: 'spent',
                kz: 'орындалған',
            },
            {
                description: 'scheduled',
                ru: 'по расписанию',
                en: 'scheduled',
                kz: 'жоспарланған',
            },
            {
                description: 'tg_for',
                ru: 'тг за',
                en: 'tg for',
                kz: 'tg үшін',
            },
            {
                description: 'group_status',
                ru: 'Статус группы',
                en: 'Group status',
                kz: 'Топ мәртебесі',
            },
            {
                description: 'status-1',
                ru: 'В работе',
                en: 'In work',
                kz: 'Жұмыста',
            },
            {
                description: 'status-2',
                ru: 'Формируется',
                en: 'Formed',
                kz: 'Қалыптасқан',
            },
            {
                description: 'status-3',
                ru: 'Остановлена',
                en: 'Stopped',
                kz: 'Тоқтады',
            },
            {
                description: 'status-4',
                ru: 'В расписании',
                en: 'In the schedule',
                kz: 'Кестеде',
            },
            {
                description: 'status-5',
                ru: 'Закрыта',
                en: 'Closed',
                kz: 'Жабық',
            },
            {
                description: 'extend_schedule',
                ru: 'Продлить расписание',
                en: 'Extend schedule',
                kz: 'Кестені ұзарту',
            },
            {
                description: 'close_schedule',
                ru: 'Закрыть расписание',
                en: 'Close schedule',
                kz: 'Кестені жабу',
            },
            {
                description: 'cl',
                ru: 'ауд',
                en: 'cl',
                kz: 'ауд',
            },
            {
                description: 'interrupted_training',
                ru: 'Прервал обучение',
                en: 'Interrupted training',
                kz: 'Жаттығулар үзілді',
            },
            {
                description: 'deleted',
                ru: 'Удалённый',
                en: 'Deleted',
                kz: 'Қашықтан басқару',
            },
            {
                description: 'edit_profile',
                ru: 'Редактировать профиль',
                en: 'Edit profile',
                kz: 'Профильді өңдеу',
            },
            {
                description: 'change_status',
                ru: 'Изменить статус',
                en: 'Change status',
                kz: 'Мәртебесін өзгерту',
            },
            {
                description: 'discount',
                ru: 'Скидка',
                en: 'Discount',
                kz: 'Жеңілдік',
            },
            {
                description: 'extra_charge',
                ru: 'Наценка',
                en: 'Extra charge',
                kz: 'Қосымша төлем',
            },
            {
                description: 'study_dates',
                ru: 'Даты обучения',
                en: 'Dates of study',
                kz: 'Оқу күндері',
            },
            {
                description: 'text_1',
                ru: 'Общий',
                en: 'General',
                kz: 'Жалпы',
            },
            {
                description: 'text_2',
                ru: 'Итого',
                en: 'Total',
                kz: 'Барлығы ',
            },
            {
                description: 'text_3',
                ru: 'Присутствие студентов',
                en: 'Student presence',
                kz: 'Студенттің қатысуы',
            },
            {
                description: 'text_4',
                ru: 'Отметить присутствие',
                en: 'Mark presence',
                kz: 'Болуды белгілеу',
            },
            {
                description: 'text_5',
                ru: 'Отметить',
                en: 'Mark',
                kz: 'Белгі',
            },
            {
                description: 'text_6',
                ru: 'Редактировать отчет',
                en: 'Edit report',
                kz: 'Есепті өңдеу ',
            },
            {
                description: 'text_7',
                ru: 'Цена для студента в группе',
                en: 'Price per student in a group ',
                kz: 'Топтағы бір оқушының бағасы',
            },
            {
                description: 'text_8',
                ru: 'Добавление нового ученика',
                en: 'Adding a new student  ',
                kz: 'Жаңа оқушыны қосу ',
            },
            {
                description: 'text_9',
                ru: 'Еще не начал заниматься',
                en: "Haven't started practicing yet ",
                kz: 'Әлі жаттығуды бастаған жоқпын  ',
            },
            {
                description: 'text_10',
                ru: 'Внимание',
                en: 'Attention',
                kz: 'Назар аударыңыз  ',
            },
            {
                description: 'text_11',
                ru: 'Выбранный день вне дат обучения! Редактирование запрещено. Измените даты обучения.',
                en: 'The selected day is outside the training dates! Editing is prohibited. Change training dates.',
                kz: 'Таңдалған күн жаттығу күндерінен тыс! Өңдеуге тыйым салынады. Жаттығу күндерін өзгерту.',
            },


        ]
    },
    {
        link: 'groups/group',
        tag: 'group_form',
        translation: [
            {
                description: 'arbitrary_name',
                ru: 'Произвольное имя',
                en: 'Arbitrary name',
                kz: 'Ерікті атау',
            },
            {
                description: 'number_and_arbitrary_name',
                ru: 'Номер и произвольное имя группы',
                en: 'Arbitrary name',
                kz: 'Ерікті атау',
            },
            {
                description: 'group_format',
                ru: 'Формат группы',
                en: 'Group format',
                kz: 'Топтық формат',
            },
            {
                description: 'default_price',
                ru: 'Цена по умолчанию',
                en: 'Default price',
                kz: 'Әдепкі баға',
            },
            {
                description: 'app_training',
                ru: 'Заявка на обучение',
                en: 'Application for training',
                kz: 'Тренингке өтініш',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'course_to_group',
        translation: [
            {
                description: 'choose_course',
                ru: 'Выберите курс',
                en: 'Choose a course',
                kz: 'Курсты таңдаңыз',
            },
            {
                description: 'count_lessons',
                ru: 'Количество уроков',
                en: 'Number of lessons',
                kz: 'Сабақтар саны',
            },
            {
                description: 'course_start_date',
                ru: 'Дата начала курса',
                en: 'Course start date',
                kz: 'Курстың басталу күні',
            },
            {
                description: 'course_end_date',
                ru: 'Дата окончания курса',
                en: 'Course end date',
                kz: 'Әрине аяқталу күні',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'group_schedule',
        translation: [
            {
                description: 'group_color',
                ru: 'Цвет расписания',
                en: 'Schedule color',
                kz: 'Кесте түсі',
            },
            {
                description: 'add_schedule',
                ru: 'Добавить расписание',
                en: 'Add schedule',
                kz: 'Кестені қосу',
            },
            {
                description: 'edit',
                ru: 'Отредактировать',
                en: 'Edit',
                kz: 'Өңдеу',
            },
            {
                description: 'nonstop',
                ru: 'Без перерыва',
                en: 'Nonstop',
                kz: 'Үздіксіз',
            },
            {
                description: 'extend',
                ru: 'Продлить',
                en: 'Extend',
                kz: 'Ұзарту',
            },
            {
                description: 'academic_hours',
                ru: 'Академических часов',
                en: 'Academic hours',
                kz: 'Академиялық сағаттар',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'time_table_day',
        translation: [
            {
                description: 'change_teacher_time',
                ru: 'Замена учителя / времени занятия',
                en: 'Change of teacher / class time',
                kz: 'Мұғалімнің / сабақ уақытын өзгерту',
            },
            {
                description: 'notify_clients_teachers',
                ru: 'Уведомить клиентов и преподавателей об изменениях',
                en: 'Notify clients and teachers of changes',
                kz: 'Клиенттер мен мұғалімдерге өзгерістер туралы хабарлаңыз',
            },
            {
                description: 'notify_client',
                ru: 'Уведомить клиента об изменениях',
                en: 'Notify customer of changes',
                kz: 'Уведомить клиента об изменениях',
            },
            {
                description: 'missed',
                ru: 'Пропуск',
                en: 'Missed',
                kz: 'Өткізу',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'modal',
        translation: [
            {
                description: 'text_1',
                ru: 'Актуальные студенты',
                en: 'Actual students ',
                kz: 'Нақты студенттер ',
            },
            {
                description: 'text_2',
                ru: 'Посещение',
                en: 'Visit',
                kz: 'Бару',
            },
            {
                description: 'text_3',
                ru: 'Остальные студенты',
                en: 'Other students ',
                kz: 'Басқа студенттер ',
            },
            {
                description: 'text_4',
                ru: 'Подтверждаю присутствие учеников',
                en: 'I confirm the presence of students',
                kz: 'Оқушылардың қатысуын растаймын ',
            },
            {
                description: 'text_8',
                ru: 'Имя рассылки',
                en: 'Mailing Name  ',
                kz: 'Пошта атауы  ',
            },
            {
                description: 'text_9',
                ru: 'Дата начала урока',
                en: 'Lesson start date ',
                kz: 'Сабақтың басталу күні  ',
            },
            {
                description: 'text_10',
                ru: 'Время',
                en: 'Time  ',
                kz: 'Уақыт  ',
            },
            {
                description: 'text_11',
                ru: 'Сейчас',
                en: 'Now  ',
                kz: 'Қазір  ',
            },
            {
                description: 'text_12',
                ru: 'E-mail письмо',
                en: 'E-mail letter ',
                kz: 'Электрондық хат ',
            },
            {
                description: 'text_13',
                ru: 'SMS сообщение',
                en: 'SMS message  ',
                kz: 'SMS хабарлама  ',
            },
            {
                description: 'text_14',
                ru: 'Дата начала рассылки',
                en: 'Sending start date  ',
                kz: 'Жіберілу басталу күні',
            },
            {
                description: 'text_15',
                ru: 'Студенты не выбраны',
                en: 'Students not selected',
                kz: 'Студенттер таңдалмаған ',
            },
            {
                description: 'text_16',
                ru: 'Кому отправляем',
                en: 'To whom we send',
                kz: 'Кімге жібереміз  ',
            },
            {
                description: 'text_17',
                ru: 'Выберите шаблон',
                en: 'Choose a template  ',
                kz: 'Үлгіні таңдаңыз ',
            },
            {
                description: 'text_19',
                ru: 'Без занятий',
                en: 'No classes',
                kz: 'Сабақтар жоқ',
            },
            {
                description: 'text_20',
                ru: 'Прервал обучение',
                en: 'Interrupted training',
                kz: 'Жаттығулар үзілді',
            },
            {
                description: 'text_21',
                ru: "Закончил обучение",
                en: 'Graduated',
                kz: 'Бітірген',
            },
            {
                description: 'text_22',
                ru: "Активный",
                en: 'Active',
                kz: 'Белсенді',
            },
            {
                description: 'text_23',
                ru: "Выберите статус студентов в группе",
                en: 'Select the status of the students in the group',
                kz: 'Топтағы студенттердің күйін таңдаңыз ',
            },
            {
                description: 'text_24',
                ru: "Ученик",
                en: 'Student ',
                kz: 'Cтудент',
            },

            {
                description: 'text_25',
                ru: "Ученики",
                en: 'Students',
                kz: 'Студенттер',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'group_log',
        translation: [
            {
                description: 'absolute',
                ru: 'Абсолютная',
                en: 'Absolute',
                kz: 'Абсолютті',
            },
            {
                description: 'interest',
                ru: 'Процентная',
                en: 'Interest',
                kz: 'Қызығушылық',
            },
            {
                description: 'add',
                ru: 'Добавлено',
                en: 'Added',
                kz: 'Қосылды',
            },
            {
                description: 'add_1',
                ru: 'Добавлена',
                en: 'Added',
                kz: 'Қосылды',
            },
            {
                description: 'add_2',
                ru: 'Добавлен',
                en: 'Added',
                kz: 'Қосылды',
            },
            {
                description: 'removed',
                ru: 'Удалено',
                en: 'Removed',
                kz: 'Жойылған',
            },
            {
                description: 'removed_1',
                ru: 'Удалена',
                en: 'Removed',
                kz: 'Жойылған',
            },
            {
                description: 'removed_2',
                ru: 'Удален',
                en: 'Removed',
                kz: 'Жойылған',
            },
            {
                description: 'status',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй ',
            },
            {
                description: 'changed',
                ru: 'Изменено',
                en: 'Changed',
                kz: 'Өзгертілді',
            },
            {
                description: 'changed_1',
                ru: 'Изменена',
                en: 'Changed',
                kz: 'Өзгертілді',
            },
            {
                description: 'changed_2',
                ru: 'Изменен',
                en: 'Changed',
                kz: 'Өзгертілді',
            },
            {
                description: 'set',
                ru: 'Установлен',
                en: 'Set',
                kz: 'Орнату',
            },
            {
                description: 'text_1',
                ru: 'Всего часов',
                en: 'Total hours',
                kz: 'Жалпы сағаттар',
            },
            {
                description: 'text_2',
                ru: 'Учитель',
                en: 'Teacher',
                kz: 'Мұғалім',
            },
            {
                description: 'text_3',
                ru: 'Аудитория',
                en: 'Lecture hall',
                kz: 'Дәріс залы',
            },
            {
                description: 'text_4',
                ru: 'Active since',
                en: 'Skip holidays',
                kz: 'Бері белсенді',
            },
            {
                description: 'text_5',
                ru: 'расписание',
                en: 'timetable',
                kz: 'кесте',
            },
            {
                description: 'text_6',
                ru: 'Было',
                en: 'It was',
                kz: 'Ол болды',
            },
            {
                description: 'text_7',
                ru: 'Стало',
                en: 'It became',
                kz: 'Болды',
            },
            {
                description: 'text_8',
                ru: 'цена группы для студента',
                en: 'group price for a student ',
                kz: 'студент үшін топтық баға',
            },
            {
                description: 'text_9',
                ru: 'скидка группы для студента',
                en: 'student group discount ',
                kz: 'студенттер тобына жеңілдік',
            },
            {
                description: 'text_10',
                ru: 'наценка группы для студента',
                en: 'group surcharge for a student',
                kz: 'студент үшін топтық қосымша ақы',
            },
            {
                description: 'text_11',
                ru: 'Изменен статус студента',
                en: 'Student status changed',
                kz: 'Студент мәртебесі өзгерді',
            },
            {
                description: 'text_12',
                ru: 'Изменены даты обучения студента',
                en: 'Student study dates changed',
                kz: 'Студенттердің оқу мерзімі өзгерді ',
            },
            {
                description: 'text_13',
                ru: 'Начало обучения',
                en: 'Start date',
                kz: 'Басталатын күн',
            },
            {
                description: 'text_14',
                ru: 'Конец обучения',
                en: 'End of training',
                kz: 'Тренингтің аяқталуы',
            },
            {
                description: 'text_15',
                ru: 'статус учебного дня',
                en: 'school day status',
                kz: 'мектеп күні жағдайы',
            },
            {
                description: 'text_16',
                ru: 'статус учебного дня для студента',
                en: 'school day status for student',
                kz: 'студент үшін мектеп күні мәртебесі',
            },
            {
                description: 'text_17',
                ru: 'Изменены параметры группы',
                en: 'Group settings changed',
                kz: 'Топ параметрлері өзгертілді',
            },
            {
                description: 'text_18',
                ru: 'Создание новой группы',
                en: 'New group',
                kz: 'Жаңа топ',
            },
            {
                description: 'text_19',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'text_20',
                ru: 'Локация',
                en: 'Location',
                kz: 'Орналасуы',
            },
            {
                description: 'text_21',
                ru: 'Компания',
                en: 'Company',
                kz: 'Компания',
            },
            {
                description: 'text_22',
                ru: 'Номер',
                en: 'Number',
                kz: 'Number',
            },
            {
                description: 'text_23',
                ru: 'Статус',
                en: 'Status',
                kz: 'Күй',
            },
            {
                description: 'text_24',
                ru: 'Дисциплина',
                en: 'Discipline',
                kz: 'Тәртіп',
            },
            {
                description: 'text_25',
                ru: 'Тип группы',
                en: 'Group type',
                kz: 'Топ түрі',
            },
            {
                description: 'text_26',
                ru: 'Вместимость',
                en: 'Interest',
                kz: 'Мерекелерді өткізіп жіберіңіз',
            },
            {
                description: 'text_27',
                ru: 'Заявка на обучение',
                en: 'Application for training',
                kz: 'Тренингке өтініш',
            },
            {
                description: 'text_28',
                ru: 'Категория',
                en: 'Category',
                kz: 'Категориясы',
            },
            {
                description: 'text_29',
                ru: 'Уровень',
                en: 'Level',
                kz: 'Деңгейі',
            },
            {
                description: 'text_30',
                ru: 'Комментарий',
                en: 'Comment',
                kz: 'Пікір',
            },
            {
                description: 'text_31',
                ru: 'Цена по умолчанию',
                en: 'Default price',
                kz: 'Әдепкі баға',
            },
            {
                description: 'text_32', //
                ru: 'Расписания',
                en: 'Schedules',
                kz: 'Кестелер',
            },
            {
                description: 'text_33',
                ru: 'Группа',
                en: 'Group',
                kz: 'Топтау',
            },
            {
                description: 'text_34',
                ru: 'Студенты',
                en: 'Students',
                kz: 'Студенттер',
            },
            {
                description: 'text_35',
                ru: 'Статусы преподавателей',
                en: 'Status of teachers',
                kz: 'Мұғалімдердің мәртебесі',
            },
            {
                description: 'text_36',
                ru: 'Статусы студентов',
                en: 'Students of students',
                kz: 'Студенттердің студенттер',
            },


        ]
    },
    {
        link: 'groups/group',
        tag: 'statuses',
        translation: [
            {
                description: 'training_status',
                ru: 'Статус обучения',
                en: 'Training status',
                kz: 'Жаттығу мәртебесі',
            },
            {
                description: 'actual',
                ru: 'Актуальный',
                en: 'Actual',
                kz: 'Нақтылары',
            },
            {
                description: 'trained',
                ru: 'Закончил обучение',
                en: 'Trained',
                kz: 'Оқуды бітірген',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'student',
        translation: [
            {
                description: 'text_1',
                ru: 'Задолженность',
                en: 'Debt ',
                kz: 'Қарыз ',
            },
            {
                description: 'text_2',
                ru: 'Отстаток',
                en: 'Remainder ',
                kz: 'Қалдық ',
            },
            {
                description: 'text_4',
                ru: 'Занимается',
                en: 'Engaged',
                kz: 'Айналысады',
            },
            {
                description: 'text_5',
                ru: 'К начислению',
                en: 'To accrual ',
                kz: 'Есептеу ',
            },
            {
                description: 'text_6',
                ru: 'Договор',
                en: 'Treaty ',
                kz: 'Шарт ',
            },
            {
                description: 'text_7',
                ru: 'Вы действительно хотите удалить',
                en: 'You really want to remove  ',
                kz: 'Сіз шынымен жойғыңыз келеді ',
            },
            {
                description: 'text_8',
                ru: 'Вы успешно удалили',
                en: 'You have successfully deleted  ',
                kz: 'Сіз сәтті жойдыңыз  ',
            },
            {
                description: 'text_10',
                ru: 'Вы действительно хотите удалить цену',
                en: 'You really want to delete the price ',
                kz: 'Сіз шынымен бағаны жойғыңыз келеді ',
            },
            {
                description: 'text_11',
                ru: 'Вы успешно удалили цену',
                en: 'You have successfully deleted the price',
                kz: 'Сіз бағаны сәтті жойдыңыз',
            },
            {
                description: 'text_12',
                ru: 'скидку',
                en: 'discount',
                kz: 'бағалар',
            },
            {
                description: 'text_13',
                ru: 'наценку',
                en: 'extra charge',
                kz: 'косымша төлем',
            },
            {
                description: 'text_14',
                ru: 'цена обучения',
                en: 'training price',
                kz: 'оқыту бағасы ',
            },
            {
                description: 'text_15',
                ru: 'Скидка',
                en: 'Discount',
                kz: 'Бағалар',
            },
            {
                description: 'text_16',
                ru: 'Наценка',
                en: 'Extra charge',
                kz: 'Қосымша төлем',
            },
            {
                description: 'text_17',
                ru: 'Активна с',
                en: 'Active',
                kz: 'Белсенді',
            },
            {
                description: 'text_18',
                ru: 'по',
                en: 'on',
                kz: 'ішінде ',
            },
            {
                description: 'text_19',
                ru: 'c',
                en: 'with ',
                kz: 'бірге ',
            },
            {
                description: 'text_20',
                ru: 'не заключен',
                en: 'not concluded  ',
                kz: 'корытынды жасамайды ',
            },
            {
                description: 'text_21',
                ru: 'Цена студента',
                en: 'Student price',
                kz: 'Студенттік баға ',
            },
        ]
    },
    {
        link: 'groups/group',
        tag: 'dors',
        translation: [
            {
                description: 'start_date',
                ru: 'Дата начала',
                en: 'Start date',
                kz: 'Басталу күні',
            },
            {
                description: 'end_date',
                ru: 'Дата окончания',
                en: 'End date',
                kz: 'Мерзімнің өту күні',
            },
            {
                description: 'select_from_list',
                ru: 'Выберите из списка',
                en: 'Choose from the list',
                kz: 'Тізімнен таңдаңыз',
            },
            {
                description: 'select',
                ru: 'Выберите',
                en: 'Select',
                kz: 'Таңдаңыз',
            },
            {
                description: 'discount',
                ru: 'скидку',
                en: 'discount',
                kz: 'жеңілдік',
            },
            {
                description: 'surcharge',
                ru: 'наценку',
                en: 'surcharge',
                kz: 'белгілеуді',
            },
        ]
    },
    {
        link: 'groups/disciplines',
        translation: [
            {
                description: 'title',
                ru: 'Дисциплины',
                en: 'Disciplines',
                kz: 'Пәндер',
            },
            {
                description: 'button-title',
                ru: 'Добавить дисциплину',
                en: 'Add discipline',
                kz: 'Тәртіп қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'groups/disciplines',
        tag: 'add_discipline',
        translation: [
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'title-modal',
                ru: 'Добавить дисциплину (язык)',
                en: 'Add discipline (language)',
                kz: 'Пән қосу (тіл)',
            },
            {
                description: 'title-modal-2',
                ru: 'Добавить язык',
                en: 'Add language',
                kz: 'Пән тіл',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите название дисциплины',
                en: 'Enter the name of the discipline',
                kz: 'Пәннің атын енгізіңіз',
            },
        ]
    },
    {
        link: 'groups/group-levels',
        translation: [
            {
                description: 'title',
                ru: 'Уровни',
                en: 'Levels',
                kz: 'Деңгейлер',
            },
            {
                description: 'button-title',
                ru: 'Добавить уровень',
                en: 'Add level',
                kz: 'Деңгей қосыңыз',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'groups/group-levels',
        tag: 'add_level',
        translation: [
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'title-modal',
                ru: 'Добавить уровень',
                en: 'Add level',
                kz: 'Деңгей қосыңыз',
            },
            {
                description: 'input-placeholder-3',
                ru: 'Подуровни',
                en: 'Sublevels',
                kz: 'Подклав',
            },
        ]
    },
    {
        link: 'groups/statuses',
        translation: [
            {
                description: 'title',
                ru: 'Статусы',
                en: 'Statuses',
                kz: 'Статустар',
            },
            {
                description: 'button-title',
                ru: 'Добавить статус',
                en: 'Add status',
                kz: 'Күй қосу',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'groups/statuses',
        tag: 'add_status',
        translation: [
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'title-modal',
                ru: 'Добавить статус',
                en: 'Add status',
                kz: 'Күй қосу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите статус',
                en: 'Enter status',
                kz: 'Күйді енгізіңіз',
            },
            {
                description: 'input-placeholder-2',
                ru: 'Выберите статус',
                en: 'Choose a status',
                kz: 'Күйді енгізіңіз',
            },
        ]
    },
    {
        link: 'groups/categories',
        translation: [
            {
                description: 'title',
                ru: 'Категории',
                en: 'Categories',
                kz: 'Категориялар',
            },
            {
                description: 'button-title',
                ru: 'Добавить категорию',
                en: 'Add category',
                kz: 'Санат қосу',
            },
            {
                description: 'table-row-title-1',
                ru: 'Название',
                en: 'Name',
                kz: 'Атауы',
            },
            {
                description: 'table-row-title-2',
                ru: 'Действия',
                en: 'Actions',
                kz: 'Әрекеттер',
            },
        ]
    },
    {
        link: 'groups/categories',
        tag: 'add_category',
        translation: [
            {
                description: 'select-placeholder-1',
                ru: 'Выберите компанию',
                en: 'Choose a company',
                kz: 'Компанияны таңдаңыз',
            },
            {
                description: 'button-one',
                ru: 'Отменить',
                en: 'Cancel',
                kz: 'Күшін жою',
            },
            {
                description: 'button-two',
                ru: 'Добавить',
                en: 'Add',
                kz: 'Қосу',
            },
            {
                description: 'title-modal',
                ru: 'Добавить категорию',
                en: 'Add category',
                kz: 'Санат қосу',
            },
            {
                description: 'input-placeholder-1',
                ru: 'Введите название категории',
                en: 'Enter category name',
                kz: 'Санат атауын енгізіңіз',
            },
        ]
    },
    {
        link: 'groups/calendar',
        translation: [
            {
                description: 'text_1',
                ru: 'Праздники',
                en: 'Holidays',
                kz: 'Мерекелер',
            },
            {
                description: 'text_2',
                ru: 'Отметьте праздничные дни.',
                en: 'Select holidays.',
                kz: 'Мерекелерді белгілеңіз.',
            },
            {
                description: 'text_3',
                ru: ' Указанные дни будут автоматически помечены в новых расписаниях как неоплачиваемые пропуски.',
                en: ' The specified days will be automatically marked as unpaid absence on new schedules.',
                kz: ' Көрсетілген күндер жаңа кестелерде төленбейтін күн болып автоматты түрде белгіленеді.',
            },
            {
                description: 'text_4',
                ru: 'На существующие расписания данная настройка не повлияет.',
                en: 'This setting will not affect existing schedules.',
                kz: 'Бұл параметр бар болған кестелерге әсер етпейді.',
            },
        ]
    },
    {
        link: 'searchGlobal',
        translation: [
            {
                description: 'showAll',
                ru: 'Показать все',
                en: 'Show all',
                kz: 'Барлығын көрсету',
            },
            {
                description: 'clients',
                ru: 'Клиенты',
                en: 'Clients',
                kz: 'Клиенттер',
            },
            {
                description: 'search',
                ru: 'Поиск...',
                en: 'Search...',
                kz: 'Іздеу...',
            },
            {
                description: 'Staff',
                ru: 'Сотрудники',
                en: 'Staff',
                kz: 'Қызметкерлер',
            },
            {
                description: 'clientafter',
                ru: 'Клиенты письменного теста',
                en: 'Clients of the written test',
                kz: 'Жазбаша тест тапсырушылары',
            },
        ]
    },
    {
        link: 'DayOfWeek',
        translation: [
            {
                description: 'monday',
                ru: 'ПН',
                en: 'MO',
                kz: 'ПН',
            },
            {
                description: 'tuesday',
                ru: 'ВТ',
                en: 'TU',
                kz: 'ВТ',
            },
            {
                description: 'wednesday',
                ru: 'СР',
                en: 'WE',
                kz: 'СР',
            },
            {
                description: 'thursday',
                ru: 'ЧТ',
                en: 'TH',
                kz: 'ЧТ',
            },
            {
                description: 'friday',
                ru: 'ПТ',
                en: 'FR',
                kz: 'ПТ',
            },
            {
                description: 'saturday',
                ru: 'СБ',
                en: 'SA',
                kz: 'СБ',
            },
            {
                description: 'sunday',
                ru: 'ВС',
                en: 'SU',
                kz: 'ВС',
            },
        ]
    },
    {
        link: 'question-types',
        translation: [
            {
                description: 'question_plus_text',
                ru: 'Вопрос с текстовым ответом',
                en: 'MO',
                kz: 'ПН',
            },
            {
                description: 'variants',
                ru: 'Вопрос с вариантами ответов',
                en: 'TU',
                kz: 'ВТ',
            },
            {
                description: 'audio_plus_variants',
                ru: 'Аудиофайл с вариантами ответов',
                en: 'WE',
                kz: 'СР',
            },
            {
                description: 'video_plus_variants',
                ru: 'Видеофайл с вариантами ответов',
                en: 'TH',
                kz: 'ЧТ',
            },
            {
                description: 'image_plus_variants',
                ru: 'Изображение с вариантами ответов',
                en: 'FR',
                kz: 'ПТ',
            },
            {
                description: 'image_plus_text',
                ru: 'Изображение с текстовым ответом',
                en: 'SA',
                kz: 'СБ',
            },
            {
                description: 'complete_sentence',
                ru: 'Собрать предложение',
                en: 'SU',
                kz: 'ВС',
            },
            {
                description: 'text_missing_words',
                ru: 'Вставить пропущенные слова в пропусках',
                en: 'SU',
                kz: 'ВС',
            },
        ]
    },
    {
        link: 'reports/teacher-statistics',
        translation: [
            {
                description: 'title',
                ru: 'Статистика по преподавателям',
                en: 'Teacher statistics',
                kz: 'Мұғалімдер статистикасы',
            },
            {
                description: 'filter_text_1',
                ru: 'Преподаватель',
                en: 'Teacher',
                kz: 'Мұғалім',
            },
            {
                description: 'text_1',
                ru: 'Статус дня',
                en: 'Day status',
                kz: 'Күн күйі'
            },
            {
                description: 'text_2',
                ru: 'Начислено',
                en: 'Assessed',
                kz: 'Бағаланды'
            },
            {
                description: 'text_3',
                ru: 'Цена группы',
                en: 'Group price',
                kz: 'Топтық баға'
            },
            {
                description: 'text_4',
                ru: 'Ставка преподавателя',
                en: 'Teacher rate',
                kz: 'Мұғалімнің көрсеткіші'
            },
            {
                description: 'text_5',
                ru: 'За',
                en: 'per',
                kz: 'үшін'
            }
        ],
    },
    {
        link: 'reports/groups-money',
        translation: [
            {
                description: 'text_1',
                ru: 'Студент',
                en: 'Student',
                kz: 'Оқушы'
            },
            {
                description: 'text_2',
                ru: 'Тип проводки',
                en: 'Wiring type',
                kz: 'Сымның түрі'
            },
            {
                description: 'text_3',
                ru: 'Поступление',
                en: 'Admission',
                kz: 'Қабылдау'
            },
            {
                description: 'text_4',
                ru: 'Списание',
                en: 'Write-off',
                kz: 'Есеп шоттан шығарып тастау'
            },
            {
                description: 'text_5',
                ru: 'Зачисление',
                en: 'Enrollment',
                kz: 'Тіркелу'
            },
            {
                description: 'text_6',
                ru: 'Все транзакций',
                en: 'All transactions',
                kz: 'Барлық Транзакциялар',
            },
            {
                description: 'title',
                ru: 'Поступления и списание по группе',
                en: 'Receipts and write-offs by group',
                kz: 'Топтар бойынша түсімдер мен есептен шығару',
            },
            {
                description: 'total',
                ru: 'Итого',
                en: 'Total',
                kz: 'Барлығы',
            }
        ],
    },
    {
        link: 'c/widget/bundles',
        translation: [
            {
                description: 'title',
                ru: 'Связки с вопросами',
                en: 'Bundles with questions',
                kz: 'Сұрақтары бар топтамалар'
            },
            {
                description: 'text_1',
                ru: 'Вопросы к связке',
                en: 'Questions to the bundle',
                kz: 'Бумаға сұрақтар',
            },
            {
                description: 'header_button',
                ru: 'Добавить виджет',
                en: 'Add widget',
                kz: 'Виджет қосу'
            },
        ]
    },
    {
        link: 'teacher_schedule',
        translation: [
            {
                description: 'title',
                ru: 'Настройка моего расписания',
                en: 'Setting up my schedule',
                kz: 'менің кестемді орнату'
            },
            {
                description: 'warning',
                ru: 'Обратите внимание',
                en: 'note',
                kz: 'назар аударыңыз'
            },
            {
                description: 'working_location',
                ru: 'данная локация работает с',
                en: 'this location works with',
                kz: 'бұл орын жұмыс істейді'
            },
            {
                description: 'in_timezone',
                ru: 'в часовом поясе',
                en: 'in the time zone',
                kz: 'уақыт белдеуінде'
            },
            {
                description: 'booked_slots',
                ru: 'Забронировано слотов',
                en: 'Booked slots',
                kz: 'Брондаған слоттар',
            },
            {
                description: 'booking',
                ru: 'Бронирование',
                en: 'Booking',
                kz: 'Брондау'
            },
            {
                description: 'unavailable',
                ru: 'недоступно',
                en: 'unavailable',
                kz: 'қолжетімсіз'
            },
            {
                description: 'slots',
                ru: 'Слотов',
                en: 'Slots',
                kz: 'Слоттар'
            },
            {
                description: 'available_booking',
                ru: 'Доступно для бронирования',
                en: 'Available for booking',
                kz: 'Брондау үшін қол жетімді'
            },
            {
                description: 'no_entries',
                ru: 'Записей нет',
                en: 'No entries',
                kz: 'Жазбалар жоқ'
            },
            {
                description: 'clients',
                ru: 'Клиентов',
                en: 'Clients',
                kz: 'Клиенттер'
            },
            {
                description: 'recurring_schedule',
                ru: 'Повторяющееся расписание',
                en: 'Recurring Schedule',
                kz: 'Қайталанатын кесте'
            },
            {
                description: 'non_recurring_schedule',
                ru: 'Неповторяющееся расписание',
                en: 'Non-recurring schedule',
                kz: 'Қайталанбайтын кесте'
            },
            {
                description: 'armor',
                ru: 'Бронь',
                en: 'Booking',
                kz: 'Брондау'
            },
            {
                description: 'there_is',
                ru: 'есть',
                en: 'there is',
                kz: 'Сонда бар'
            },
            {
                description: 'bookeds_slots',
                ru: 'Забронированные слоты',
                en: 'Booked slots',
                kz: 'Брондаған слоттар'
            },
            {
                description: 'text_1',
                ru: 'Для редактирования слотов нажмите на нужном времени и внесите корректировки',
                en: 'To select slots, click on the desired time and make adjustments',
                kz: 'Слоттарды таңдау үшін қажетті уақытты басып, реттеулерді жасаңыз'
            },
            {
                description: 'text_2',
                ru: 'Редактировать занятые клиентами слоты',
                en: 'Edit slots occupied by clients',
                kz: 'Клиенттер алып жатқан ұяшықтарды өңдеу'
            },
            {
                description: 'text_3',
                ru: 'невозможно',
                en: 'impossible',
                kz: 'мүмкін емес'
            },
            {
                description: 'text_4',
                ru: 'Редактирование слота невозможно',
                en: 'Slot editing is not possible',
                kz: 'Слотты өңдеу мүмкін емес'
            },
            {
                description: 'text_5',
                ru: 'На этот слот зарегистрирован клиент. Обратитесь к администратору для редактирования данного слота.',
                en: 'A client is registered to this slot. Contact your administrator to edit this slot.',
                kz: 'Клиент осы ұяшыққа тіркелген. Бұл ұяшықты өңдеу үшін әкімшіңізге хабарласыңыз.'
            },
            {
                description: 'text_6',
                ru: 'Удаленный слот',
                en: 'Remote slot',
                kz: 'Қашықтағы ұяшық'
            },
            {
                description: 'delete_slot',
                ru: 'Удаленный слот',
                en: 'Remote slot',
                kz: 'Қашықтағы ұяшық'
            },
            {
                description: 'busy_slot',
                ru: 'Занятый слот',
                en: 'Busy slot',
                kz: 'Бос емес слот'
            },
            {
                description: 'registered_client',
                ru: 'Записан клиент',
                en: 'Registered client',
                kz: 'Тіркелген клиент'
            },
            {
                description: 'text_7',
                ru: 'Доступен клиенту',
                en: 'Available to the client',
                kz: 'Клиентке қол жетімді'
            },
            {
                description: 'text_8',
                ru: 'Онлайн + Школа',
                en: 'Online + School',
                kz: 'Онлайн + Мектеп'
            },
            {
                description: 'text_9',
                ru: 'Только онлайн',
                en: 'Online only',
                kz: 'Тек онлайн'
            },
            {
                description: 'text_10',
                ru: 'Только школа',
                en: 'School only',
                kz: 'Тек мектеп'
            },
            {
                description: 'text_11',
                ru: 'Групповой слот',
                en: 'group slot',
                kz: 'топтық ұяшық'
            },
            {
                description: 'text_12',
                ru: 'Одиночный слот',
                en: 'single slot',
                kz: 'жалғыз ұяшық'
            },
            {
                description: 'zoom_link',
                ru: 'Ссылка на конференцию',
                en: 'Link to the conference',
                kz: 'Конференцияға сілтеме'
            },
            {
                description: 'text_13',
                ru: 'Восстановить',
                en: 'Restore',
                kz: 'Қалпына келтіру'
            },
            {
                description: 'text_14',
                ru: 'Ссылка на ZOOM скопирована',
                en: 'ZOOM link copied',
                kz: 'ZOOM сілтемесі көшірілді'
            },
            {
                description: 'text_15',
                ru: 'Ссылка на ZOOM успешно создана',
                en: 'Link to ZOOM successfully created',
                kz: 'ZOOM сілтемесі сәтті жасалды'
            },
            {
                description: 'text_16',
                ru: 'Не удалось создать ссылку на ZOOM',
                en: 'Failed to create ZOOM link',
                kz: 'ZOOM сілтемесін жасау сәтсіз аяқталды'
            },
            {
                description: 'text_17',
                ru: 'удаления',
                en: 'removal',
                kz: 'жою'
            },
            {
                description: 'text_18',
                ru: 'сохранения',
                en: 'conservation',
                kz: 'сақтау'
            },
            {
                description: 'text_19',
                ru: 'Повторите позже',
                en: 'Try again later',
                kz: 'Әрекетті кейінірек қайталаңыз'
            },
            {
                description: 'text_20',
                ru: 'Редактирование слота',
                en: 'Editing a slot',
                kz: 'Слотты өңдеу'
            },
            {
                description: 'text_21',
                ru: 'Выберите тип слота',
                en: 'Select slot type',
                kz: 'Слот түрін таңдаңыз'
            },
            {
                description: 'text_22',
                ru: 'Комментарий причины удаления',
                en: 'Comment on the reason for deletion',
                kz: 'Жою себебіне түсініктеме беріңіз'
            },
            {
                description: 'text_23',
                ru: 'В школе',
                en: 'At school',
                kz: 'Мектепте'
            },
            {
                description: 'text_24',
                ru: 'Ссылка не создана',
                en: 'Link not created',
                kz: 'Сілтеме жасалмады'
            },
            {
                description: 'text_25',
                ru: 'Скопировать',
                en: 'Copy',
                kz: 'Көшіру'
            },
            {
                description: 'text_26',
                ru: 'Создать',
                en: 'Create',
                kz: 'Жасау'
            },
            {
                description: 'text_27',
                ru: 'На этот день нет свободных сотрудников',
                en: 'No employees for this day',
                kz: 'Бұл күні қызметкерлер жоқ'
            },
            {
                description: 'text_28',
                ru: 'Могут записываться несколько человек на одно время',
                en: 'Multiple people can book at the same time',
                kz: 'Бірнеше адам бір уақытта тапсырыс бере алады'
            },
            {
                description: 'type_slots',
                ru: 'Тип слотов',
                en: 'slot type',
                kz: 'ұяшық түрі'
            },
            {
                description: 'text_29',
                ru: 'Нет доступных типов слотов',
                en: 'No slot types available',
                kz: 'Слот түрлері жоқ'
            },
            {
                description: 'text_30',
                ru: 'Комментарий обязателен',
                en: 'Comment is required',
                kz: 'Түсініктеме қажет'
            },
            {
                description: 'text_31',
                ru: 'Выберите место проведения',
                en: 'Choose a venue',
                kz: 'Орын таңдаңыз'
            },
            {
                description: 'text_32',
                ru: 'Онлайн (ZOOM)',
                en: 'Online (ZOOM)',
                kz: 'Онлайн (ZOOM)'
            },
            {
                description: 'text_33',
                ru: 'Запланировать встречи в системе ZOOM при сохранении времен',
                en: 'Schedule ZOOM meetings while saving times',
                kz: 'Уақытты үнемдей отырып, ZOOM кездесулерін жоспарлаңыз'
            },
            {
                description: 'text_34',
                ru: 'Ссылки на времена будут созданы сразу после сохранения времени автоматически.',
                en: 'Links to the times will be created immediately after saving the time automatically.',
                kz: 'Уақыттарға сілтемелер уақытты автоматты түрде сақтағаннан кейін бірден жасалады.'
            },
            {
                description: 'text_35',
                ru: 'Слоты успешно сохранены',
                en: 'Slots saved successfully',
                kz: 'Слоттар сәтті сақталды'
            },
            {
                description: 'text_36',
                ru: 'Ошибка сохранения',
                en: 'Save error',
                kz: 'Қатені сақтау'
            },
            {
                description: 'text_37',
                ru: 'Отменить изменения',
                en: 'cancel changes',
                kz: 'өзгертулерден бас тарту'
            },
            {
                description: 'text_38',
                ru: 'Пожалуйста заполните поля',
                en: 'Please fill in the fields',
                kz: 'Өрістерді толтырыңыз'
            },
            {
                description: 'text_39',
                ru: 'тип слота',
                en: 'slot type',
                kz: 'ұяшық түрі'
            },
        ]
    },
    {
        link: 'slots_listing',
        translation: [
            {
                description: 'sorting',
                ru: 'Сортировать',
                en: 'Sort',
                kz: 'Сұрыптау'
            },
            {
                description: 'text_1',
                ru: 'Детали теста',
                en: 'Test details',
                kz: 'Сынақ мәліметтері'
            },
            {
                description: 'text_2',
                ru: 'Скопировать клиентскую ссылку Zoom',
                en: 'Copy Client Zoom Link',
                kz: 'Клиент масштабтау сілтемесін көшіріңіз',
            },
            {
                description: 'text_3',
                ru: 'только в школе',
                en: 'only at school',
                kz: 'тек мектепте'
            },
            {
                description: 'text_4',
                ru: 'онлайн и в школе',
                en: 'online and at school',
                kz: 'Интернетте және мектепте'
            },
            {
                description: 'text_5',
                ru: 'Место встречи',
                en: 'Venue',
                kz: 'Кездесу орны'
            },
        ]
    },

    {
        link: 'month',
        translation: [
            {
                description: 'month_1',
                ru: 'Январь',
                en: 'January',
                kz: 'Қаңтар',
            },
            {
                description: 'month_2',
                ru: 'Февраль',
                en: 'February',
                kz: 'Ақпан',
            },
            {
                description: 'month_3',
                ru: 'Март',
                en: 'March',
                kz: 'Наурыз',
            },
            {
                description: 'month_4',
                ru: 'Апрель',
                en: 'April',
                kz: 'Сәуір',
            },
            {
                description: 'month_5',
                ru: 'Май',
                en: 'May',
                kz: 'Мамыр',
            },
            {
                description: 'month_6',
                ru: 'Июнь',
                en: 'June',
                kz: 'Маусым',
            },
            {
                description: 'month_7',
                ru: 'Июль',
                en: 'July',
                kz: 'Шілде',
            },
            {
                description: 'month_8',
                ru: 'Август',
                en: 'August',
                kz: 'Тамыз',
            },
            {
                description: 'month_9',
                ru: 'Сентябрь',
                en: 'September',
                kz: 'Қыркүйек',
            },
            {
                description: 'month_10',
                ru: 'Октябрь',
                en: 'October',
                kz: 'Қазан',
            },
            {
                description: 'month_11',
                ru: 'Ноябрь',
                en: 'November',
                kz: 'Қараша',
            },
            {
                description: 'month_12',
                ru: 'Декабрь',
                en: 'December',
                kz: 'Желтоқсан',
            },
        ]
    },
]

export const dict = {
    work_table_listing: {
        title: {
            ru: 'Табель учета рабочего времени',
            en: 'Time sheet',
            kz: 'Уақыт парағы',
        },
        show_employees: {
            ru: 'показать сотрудников',
            en: 'show employees',
            kz: 'қызметкерлерді көрсету',
        },
        employees: {
            ru: 'Сотрудников',
            en: 'Employees',
            kz: 'Қызметкерлер',
        },
        reset_btn: {
            ru: 'cбросить',
            en: 'reset',
            kz: 'қалпына келтіру',
        },
        choose_position: {
            ru: 'выберите должность',
            en: 'select position',
            kz: 'позицияны таңдаңыз',
        },
        choose_company: {
            ru: 'Выберите компанию',
            en: 'Choose a company',
            kz: 'Компанияны таңдаңыз',
        },
        choose_location: {
            ru: 'Выберите локацию',
            en: 'Choose a location',
            kz: 'Орынды таңдаңыз',
        },
        choose_department: {
            ru: 'выберите департамент',
            en: 'choose a department',
            kz: 'бөлімін таңдаңыз',
        },
        working_days: {
            ru: 'Рабочие дни, всего',
            en: 'Working days, total',
            kz: 'Жұмыс күндері, барлығы',
        },
        days_worked: {
            ru: 'Отработано дней',
            en: 'Days worked',
            kz: 'Күндер жұмыс істеді',
        },
        vacation: {
            ru: 'Отпуск, Б/С',
            en: 'Vacation, B/S',
            kz: 'Демалыс, B/S',
        },
        leaving: {
            ru: 'Опоздания / Уходы раньше',
            en: 'Late / Leaving Early',
            kz: 'Кеш / Ерте кету',
        },
        vacation_days: {
            ru: 'Отпускные дни',
            en: 'Vacation days',
            kz: 'Демалыс күндері',
        },
        sick_days: {
            ru: 'Больничные дни',
            en: 'Sick days',
            kz: 'ауырған күндер',
        },
        warning: {
            ru: 'График не составлен или отсутствует контракт',
            en: 'No schedule or no contract',
            kz: 'Кесте жоқ немесе келісімшарт жоқ',
        },
        working_day: {
            ru: 'Рабочий день',
            en: 'Working day',
            kz: 'Жұмыс күні',
        },
        worked_day: {
            ru: 'Отработанный день',
            en: 'Worked day',
            kz: 'Жұмыс күні',
        },
        day_off: {
            ru: 'Выходной день',
            en: 'Day off',
            kz: 'Демалыс күні',
        },
        holiday: {
            ru: 'Праздничный день',
            en: 'Holiday',
            kz: 'Мереке',
        },
        sick_day: {
            ru: 'Больничный день',
            en: 'sick day',
            kz: 'ауырған күн',
        },
        vacation_day: {
            ru: 'Отпускной день',
            en: 'vacation day',
            kz: 'демалыс күні',
        },
    },
    company_locations: {
        contact_test: {
            ru: 'Связать с письменным и устным тестом',
            en: 'Associate with written and oral test',
            kz: 'Жазбаша және ауызша тестпен байланыстырыңыз'
        },
        add_location: {
            ru: 'Добавление локаций',
            en: 'Adding locations',
            kz: 'Орындарды қосу',
        },
        edit_location: {
            ru: 'Редактирование локаций',
            en: 'Editing locations',
            kz: 'Орындарды өңдеу',
        },
        currency: {
            ru: 'Валюта',
            en: 'Currency',
            kz: 'Валюта',
        },
        edit_company: {
            ru: 'Редактирование компаний',
            en: 'Editing companies',
            kz: 'Өңдеу компаниялары'
        },
        add_company: {
            ru: 'Добавление компаний',
            en: 'Adding companies',
            kz: 'Компанияларды қосу',
        },
    },
    notifications: {
        written_test: {
            ru: 'Письменный тест',
            en: 'Written test',
            kz: 'Жазбаша тест '
        },
        new_testing: {
            ru: 'Новое тестирование',
            en: 'New testing',
            kz: 'Жаңа тестілеу'
        },
        start_testing: {
            ru: 'Начало тестирование',
            en: 'Start testing',
            kz: 'Тестілеуді бастаңыз'
        },
        finish_testing: {
            ru: 'Окончание тестирования',
            en: 'End of testing',
            kz: 'Тестілеудің аяқталуы'
        },
        will_start_in: {
            ru: 'начнется через',
            en: 'will start in',
            kz: 'жылы басталады'
        },
        will_end_in: {
            ru: 'закончится через',
            en: 'will end in',
            kz: 'жылы аяқталады'
        },
        minutes: {
            ru: ' минут',
            en: ' minutes',
            kz: ' минут',
        },
        today: {
            ru: 'Сегодня',
            en: 'Today',
            kz: 'Бүгін',
        },
    },
    history_clients: {
        title: {
            ru: 'История записи клиентов',
            en: 'Customer record history',
            kz: 'Тұтынушы жазбасының тарихы'
        },
        past_entries: {
            ru: 'Прошедшие записи',
            en: 'Past entries',
            kz: 'Өткен жазбалар'
        },
        all_entries: {
            ru: 'Все записи',
            en: 'All entries',
            kz: 'Барлық жазбалар',
        },
        future_entries: {
            ru: 'Будущие записи',
            en: 'Future entries',
            kz: 'Болашақ жазбалар',
        },
        missed_entries: {
            ru: 'Не состоявшиеся',
            en: 'Failed ',
            kz: 'Сәтсіз',
        },
        placeholder_1: {
            ru: 'Имя, телефон или email клиента',
            en: 'Name, phone or email of the client ',
            kz: 'Клиенттің аты, телефоны немесе электрондық поштасы',
        },
        placeholder_2: {
            ru: 'Программа обучения',
            en: 'Training program ',
            kz: 'Оқыту бағдарламасы',
        },
        placeholder_3: {
            ru: 'Сотрудник',
            en: 'Employee',
            kz: 'Қызметкер',
        },
        placeholder_4: {
            ru: 'Преподаватель',
            en: 'Teacher',
            kz: 'Мұғалім ',
        },
        placeholder_5: {
            ru: 'Возрастная категория',
            en: 'Age category',
            kz: 'Жас санаты',
        },
        placeholder_6: {
            ru: 'Уровень клиента',
            en: 'Client level',
            kz: 'Клиент деңгейі',
        },
        find_group: {
            ru: 'Подобрать группу',
            en: 'Pick up a group',
            kz: 'Топты алыңыз',
        },
        profile: {
            ru: 'Профиль клиента',
            en: 'Client profile',
            kz: 'Клиент профилі',
        },
        info: {
            ru: 'Подробнее о записи',
            en: 'More about recording',
            kz: 'Жазу туралы толығырақ',
        },
        rewrite: {
            ru: 'Записать заново',
            en: 'Re-record',
            kz: 'Қайта жазу',
        },
    },
    month: {
        january: {
            ru: 'Января',
            en: 'January',
            kz: 'Қаңтар',
        },
        february: {
            ru: 'Февраля',
            en: 'February',
            kz: 'Ақпан',
        },
        march: {
            ru: 'Марта',
            en: 'March',
            kz: 'Наурыз',
        },
        april: {
            ru: 'Апреля',
            en: 'April',
            kz: 'Сәуір',
        },
        may: {
            ru: 'Мая',
            en: 'May',
            kz: 'Мамыр',
        },
        june: {
            ru: 'Июня',
            en: 'June',
            kz: 'Маусым',
        },
        july: {
            ru: 'Июля',
            en: 'July',
            kz: 'Шілде',
        },
        august: {
            ru: 'Августа',
            en: 'August',
            kz: 'Тамыз',
        },
        september: {
            ru: 'Сентября',
            en: 'September',
            kz: 'Қыркүйек',
        },
        october: {
            ru: 'Октября',
            en: 'October',
            kz: 'Қазан',
        },
        november: {
            ru: 'Ноября',
            en: 'November',
            kz: 'Қараша',
        },
        december: {
            ru: 'Декабря',
            en: 'December',
            kz: 'Желтоқсан',
        },
    },
    main_page: {
        work_with: {
            ru: 'Работает с',
            en: 'Work with',
            kz: 'Жұмыс істеу',
        },
        department: {
            ru: 'Департамент',
            en: 'Department',
            kz: 'Бөлім',
        },
        position: {
            ru: 'Должность',
            en: 'Position',
            kz: 'Позиция',
        },
        telegram_bot: {
            ru: 'Телеграм-бот',
            en: 'Telegram bot',
            kz: 'Telegram бот',
        },
        telegram: {
            ru: 'Телеграм',
            en: 'Telegram',
            kz: 'Телеграм',
        },
        notification: {
            ru: 'С его помощью вы сможете получать уведомления и отслеживать рабочий процесс',
            en: 'With it, you will be able to receive notifications and track the workflow',
            kz: 'Оның көмегімен сіз хабарландыруларды алып, жұмыс процесін бақылай аласыз',
        },
        notification_1: {
            ru: 'Утренняя рассылка расписания слотов',
            en: 'Morning slot schedule mailing list',
            kz: 'Таңертеңгілік слот кестесін жіберу тізімі',
        },
        more: {
            ru: 'Подробнее',
            en: 'More',
            kz: 'Көбірек',
        },
        not_specified: {
            ru: 'Не указано',
            en: 'Not specified',
            kz: 'Көрсетілмеген',
        },
        phone: {
            ru: 'Домашний или рабочий телефон',
            en: 'Home or work phone',
            kz: 'Үй немесе жұмыс телефоны',
        },
        mobile_phone: {
            ru: 'Мобильный телефон',
            en: 'Mobile phone',
            kz: 'Ұялы телефон',
        },
        email: {
            ru: 'Адрес электронной почты',
            en: 'E-mail address',
            kz: 'Электрондық пошта',
        },
        whatsapp_number: {
            ru: 'WhatsApp номер',
            en: 'whatsapp number',
            kz: 'Whatsapp нөмірі',
        },
        telegram_nick: {
            ru: 'Telegram ник-нейм',
            en: 'Telegram nickname',
            kz: 'Telegram лақап аты',
        },
        iin: {
            ru: 'ИИН',
            en: 'INN',
            kz: 'ЖСН',
        },
        specify_status: {
            ru: 'Укажите статус',
            en: 'Specify status',
            kz: 'Күйді көрсетіңіз',
        },
        enter_your_age: {
            ru: 'Укажите возраст',
            en: 'Enter your age',
            kz: 'Жасыңызды енгізіңіз',
        },
        balance: {
            ru: 'Баланс',
            en: 'Balance',
            kz: 'Баланс',
        },
        to_plug: {
            ru: 'Подключить',
            en: 'To plug',
            kz: 'Қосу үшін',
        },
        tune: {
            ru: 'Настроить',
            en: 'Tune',
            kz: 'Баптау',
        },
        needINN: {
            ru: 'Укажите ИИН/ИНН',
            en: 'Specify IIN / INN',
            kz: 'ЖСН / ЖСН көрсетіңіз',
        },
        INN_IIN: {
            ru: 'ИИН/ИНН',
            en: 'IIN / INN',
            kz: 'ЖСН / ЖСН',
        },
        specify_nickname_telegram: {
            ru: 'Укажите ник Telegram',
            en: 'Specify nickname Telegram',
            kz: 'Telegram лақап атын көрсетіңіз',
        },
        specify_whatsapp: {
            ru: 'Укажите WhatsApp',
            en: 'Specify whatsapp',
            kz: 'Whatsapp-ты көрсетіңіз',
        },
        add_phone: {
            ru: 'Добавить телефон',
            en: 'Add phone',
            kz: 'Телефон қосыңыз',
        },
        specify_phone: {
            ru: 'Указать телефон',
            en: 'Specify phone',
            kz: 'Телефонды көрсетіңіз',
        },
        test: {
            ru: 'Тесты',
            en: 'Tests',
            kz: 'Тесттер',
        },
        schedule: {
            ru: 'График работы',
            en: 'Schedule',
            kz: 'Жұмыс кестесі',
        },
        company: {
            ru: 'Компания',
            en: 'Company',
            kz: 'Компания',
        },
        location: {
            ru: 'Локация',
            en: 'Location',
            kz: 'Орналасуы',
        },
        start_date: {
            ru: 'Дата начала работы',
            en: 'Start date',
            kz: 'Басталатын күн',
        },
        comment: {
            ru: 'Комментарий',
            en: 'Comment',
            kz: 'Пікір',
        },
        select_company_first: {
            ru: 'Сначала выберите компанию',
            en: 'Select a company first',
            kz: 'Алдымен компанияны таңдаңыз',
        },
        select_department_first: {
            ru: 'Сначала выберите департамент',
            en: 'Select a department first',
            kz: 'Алдымен бөлімді таңдаңыз',
        },
        age_categories: {
            ru: 'Возрастные категории',
            en: 'Age categories',
            kz: 'Жас категориялары',
        },
        add: {
            ru: 'Добавить',
            en: 'Add',
            kz: 'Қосу',
        },
        contract_name: {
            ru: 'Название контракта',
            en: 'Contract name',
            kz: 'Шарт атауы',
        },
        contract_duration: {
            ru: 'Срок действия контракта',
            en: 'Contract duration',
            kz: 'Келісімшарт мерзімі',
        },
        type_salary: {
            ru: 'Тип и сумма заработной платы',
            en: 'Type and amount of salary',
            kz: 'Жалақының түрі мен мөлшері',
        },
        monthly: {
            ru: 'Месячная',
            en: 'Monthly',
            kz: 'Ай сайын',
        },
        daily: {
            ru: 'Ежедневная',
            en: 'Daily',
            kz: 'Қосу',
        },
        hourly: {
            ru: 'Почасовая',
            en: 'Hourly',
            kz: 'Cағат сайын',
        },
        wage_amount: {
            ru: 'Сумма заработной платы',
            en: 'Wage amount',
            kz: 'Жалақы мөлшері',
        },
        text_1: {
            ru: 'Подробнее о заработных платах сотрудников',
            en: 'Learn more about employee salaries',
            kz: 'Қызметкерлердің жалақысы туралы көбірек біліңіз',
        },
        text_2: {
            ru: 'Количество рабочих часов в день',
            en: 'Number of working hours per day',
            kz: 'Тәулігіне жұмыс уақытының саны',
        },
        text_3: {
            ru: 'Количество отпускных дней',
            en: 'Vacation days',
            kz: 'Демалыс күндері',
        },
        text_4: {
            ru: 'Количество часов',
            en: 'Number of hours',
            kz: 'Сағат саны',
        },
        text_5: {
            ru: 'Тип часов',
            en: 'Watch type',
            kz: 'Сағат түрі',
        },
        text_6: {
            ru: 'Подробнее о плане онлайн записи',
            en: 'Learn more about the online enrollment plan',
            kz: 'Онлайн тіркелу жоспары туралы көбірек біліңіз',
        },
        text_6_1: {
            ru: 'Подробнее о плане по учебным занятиям',
            en: 'Learn more about the online enrollment plan',
            kz: 'Онлайн тіркелу жоспары туралы көбірек біліңіз',
        },
        text_7: {
            ru: 'Введите сумму',
            en: 'Enter amount',
            kz: 'Cоманы енгізіңіз',
        },
        text_8: {
            ru: 'Академические',
            en: 'Academic',
            kz: 'Академиялық',
        },
        text_9: {
            ru: 'Астрономические',
            en: 'Astronomical',
            kz: 'Астрономиялық',
        },
        text_10: {
            ru: 'Месячный план по онлайн записям',
            en: 'Online Enrollment Plan',
            kz: 'Онлайн тіркелу жоспары',
        },
        text_10_1: {
            ru: 'Месячный план по учебным занятиям',
            en: 'Online Enrollment Plan',
            kz: 'Онлайн тіркелу жоспары',
        },
        text_11: {
            ru: 'Ставки для новых групп',
            en: 'Rates for new groups',
            kz: 'Жаңа топтар үшін тарифтер',
        },
        text_12: {
            ru: 'Стандартные',
            en: 'Standard',
            kz: 'Стандартты',
        },
        text_13: {
            ru: 'Мини',
            en: 'Mini',
            kz: 'Шағын',
        },
        text_14: {
            ru: 'Индивидуальные',
            en: 'Individual',
            kz: 'Жеке',
        },
        text_15: {
            ru: 'Онлайн',
            en: 'Online',
            kz: 'Онлайн',
        },
        text_16: {
            ru: 'Офлайн',
            en: 'Offline',
            kz: 'Желіден тыс',
        },
        text_17: {
            ru: 'Выберите ставку',
            en: 'Choose a rate',
            kz: 'Бағаны таңдаңыз ',
        },
        text_18: {
            ru: 'группы',
            en: 'groups',
            kz: 'топтар',
        },
        text_19: {
            ru: 'Нет действующего контракта',
            en: 'No current contract',
            kz: 'Белсенді келісімшарт жоқ',
        },
        text_20: {
            ru: 'не установлено',
            en: 'not installed',
            kz: 'орнатылмаған',
        },
        text_21: {
            ru: 'Установлен',
            en: 'Set',
            kz: 'Орнату',
        },
        text_22: {
            ru: 'Как телефон 1',
            en: 'Like phone 1',
            kz: 'Телефон 1 сияқты',
        },
        text_30: {
            ru: 'Сохранить',
            en: 'Save',
            kz: 'Сақтау',
        },
        text_36: {
            ru: 'клиентом',
            en: 'client',
            kz: 'клиент',
        },
        text_39: {
            ru: 'Перейти к тест',
            en: 'Go to test',
            kz: 'Тестке өтіңіз',
        },
        text_40: {
            ru: 'Студенты',
            en: 'Students',
            kz: 'Студенттер',
        },
        text_41: {
            ru: 'Дата',
            en: 'Date of',
            kz: 'Kүні',
        },
        text_42: {
            ru: 'Окончен',
            en: 'Finished',
            kz: 'Аяқталды',
        },
        text_43: {
            ru: 'В работе',
            en: 'In work',
            kz: 'Жұмыста',
        },
        text_44: {
            ru: 'Показать все тесты',
            en: 'Show all tests',
            kz: 'Барлық сынақтарды көрсетіңіз ',
        },
        text_47: {
            ru: 'Выберите',
            en: 'Select',
            kz: 'Таңдаңыз',
        },
        text_48: {
            ru: 'Без преподователя',
            en: 'Without a teacher',
            kz: 'Мұғалімсіз',
        },
        text_49: {
            ru: 'Расписание и посещаемость',
            en: 'Schedule and attendance',
            kz: 'Кесте және сабаққа қатысу',
        },
        text_50: {
            ru: 'по',
            en: 'to',
            kz: 'ішінде ',
        },
        text_51: {
            ru: 'до',
            en: 'to',
            kz: 'дейін',
        },
        text_52: {
            ru: 'c',
            en: 'with ',
            kz: 'бірге ',
        },
        text_53: {
            ru: 'Срок действия контракта',
            en: 'Contract duration ',
            kz: 'Келісімшарт мерзімі ',
        },
        text_54: {
            ru: 'Ставки для новых групп',
            en: 'Rates for new groups',
            kz: 'Жаңа топтар үшін тарифтер',
        },
        text_55: {
            ru: 'Язык системы',
            en: 'System language',
            kz: 'Жүйе тілі',
        },
        text_56: {
            ru: 'Временная зона',
            en: 'Time zone',
            kz: 'Уақыт белдеуі',
        },
        ac_h: {
            ru: 'ак.ч.',
            en: 'ac.h.',
            kz: 'ак.с.',
        },
        astr_h: {
            ru: 'астр.ч.',
            en: 'astr.h.',
            kz: 'астр.с.',
        },

    },
    group: {
        actual: {
            ru: 'Актуальные',
            en: 'Actual',
            kz: 'Нақты',
        },
        graduates: {
            ru: 'Закончившие',
            en: 'Graduates',
            kz: 'Түлектер',
        },
        formation: {
            ru: 'Формирование',
            en: 'Formation',
            kz: 'Қалыптастыру',
        },
        scheduled: {
            ru: 'В расписании',
            en: 'Scheduled',
            kz: 'Жоспарланған',
        },
        show_all_group: {
            ru: 'Показать все группы',
            en: 'Show all groups',
            kz: 'Барлық топтарды көрсету',
        },
        text_1: {
            ru: 'Ауд',
            en: 'Aud',
            kz: 'Ауд',
        },
        text_2: {
            ru: 'Без аудитории',
            en: 'Without an audience',
            kz: 'Аудиториясыз',
        },
        text_3: {
            ru: 'У данной группы еще не назначено расписание',
            en: 'This group has not yet been assigned a schedule',
            kz: 'Бұл топқа әлі кесте тағайындалмаған',
        },
        text_4: {
            ru: 'Нет учителя',
            en: 'No teacher',
            kz: 'Мұғалім жоқ',
        },
        text_5: {
            ru: 'Дополнительные контакты',
            en: 'Additional contacts',
            kz: 'Қосымша контактілер'
        },
        text_6: {
            ru: 'В группе нет такого студента',
            en: 'There is no such student in the group',
            kz: 'Топта ондай студент жоқ'
        },
        type_program: {
            ru: 'Тип программы',
            en: 'Program type',
            kz: 'Бағдарлама түрі',
        },
        self_recording: {
            ru: 'Самозапись',
            en: 'Self-recording',
            kz: 'Өзін-өзі жазу',
        },
        available: {
            ru: 'Доступно',
            en: 'Қол жетімді',
            kz: 'Өзін-өзі жазу',
        },
        not_available: {
            ru: 'Недоступно',
            en: 'Not available',
            kz: 'Жоқ',
        },
        free_lesson: {
            ru: 'Бесплатное занятие',
            en: 'Free lesson',
            kz: 'Тегін сабақ'
        },
        paid_pass: {
            ru: 'Оплачиваемый пропуск',
            en: 'Paid pass',
            kz: 'Ақылы билет'
        },
        unpaid_pass: {
            ru: 'Неоплачиваемый пропуск',
            en: 'Unpaid pass',
            kz: 'Төленбеген билет'
        },
        lesson: {
            ru: 'Занятие',
            en: 'Lesson',
            kz: 'Сабақ',
        },
        closed_lesson: {
            ru: 'Отменённый урок',
            en: 'Canceled lesson',
            kz: 'Сабақ тоқтатылды'
        },
        valid_from: {
            ru: 'Действует с',
            en: 'Valid from',
            kz: 'Бастап жарамды'
        },
        show_all_schedules: {
            description: 'text_106',
            ru: 'Показать все расписания',
            en: 'Show all schedules',
            kz: 'Барлық кестені көрсету',
        },
        hide_all_schedules : {
            description: 'text_107',
            ru: 'Скрыть все расписания',
            en: 'Hide all schedules',
            kz: 'Барлық кестелерді жасыру  ',
        },
    },
    pays: {
        text_1: {
            ru: 'Пока не добавлено ни одного номера',
            en: 'No numbers added yet',
            kz: 'Нөмірлер әлі қосылмаған',
        },
        text_2: {
            ru: 'Направление платежа',
            en: 'Direction of paymen',
            kz: 'Төлем бағыты',
        },
        text_3: {
            ru: 'Пополнить баланс компаний',
            en: 'Top up the balance of companies',
            kz: 'Компаниялардың балансын толтыру',
        },
        text_4: {
            ru: 'Сумма пополнения',
            en: 'Top-up amount',
            kz: 'Толықтыру сомасы',
        },
        text_5: {
            ru: 'тг. к оплате',
            en: 'tg. to pay',
            kz: 'тг. төлемге',
        },
        balance_replenishment: {
            ru: 'Пополнение баланса',
            en: 'Balance replenishment',
            kz: 'Балансты толтыру',
        },
    },
    customer_data: {
        text_1: {
            ru: 'Дата рождения',
            en: 'Date of Birth',
            kz: 'Tуған күні',
        },
        text_2: {
            ru: 'Телефон',
            en: 'Phone',
            kz: 'Телефон',
        },
    },
    time_zones: {
        text_1: {
            ru: 'Линия перемены дат',
            en: 'Dateline',
            kz: 'Күні',
        },
        text_2: {
            ru: 'Джарвис, Мидуэй',
            en: 'Jarvis, Midway',
            kz: 'Джарвис, Мидуэй',
        },
        text_3: {
            ru: 'Гавайи',
            en: 'Hawaii',
            kz: 'Гавайи',
        },
        text_4: {
            ru: 'Аляска',
            en: 'Alaska',
            kz: 'Аляска',
        },
        text_5: {
            ru: 'Нижняя Калифорния',
            en: 'Baja California',
            kz: 'Төменгі Калифорния',
        },
        text_6: {
            ru: 'Юкон',
            en: 'Yukon',
            kz: 'Юкон',
        },
        text_7: {
            ru: 'Центральная Америка',
            en: 'Central America',
            kz: 'Орталық Америка',
        },
        text_8: {
            ru: 'Монтеррей',
            en: 'Monterrey',
            kz: 'Монтеррей',
        },
        text_9: {
            ru: 'Монреаль',
            en: 'Montreal',
            kz: 'Монреаль',
        },
        text_10: {
            ru: 'Сальвадор, Гренландия, Бразилия',
            en: 'Salvador, Greenland, Brazil',
            kz: 'Сальвадор, Гренландия, Бразилия',
        },
        text_11: {
            ru: 'Среднеатлантическое время',
            en: 'Mid-atlantic time',
            kz: 'Орта-атлантикалық уақыт',
        },
        text_12: {
            ru: 'Кабо-Верде',
            en: 'Cape Verde',
            kz: 'Кабо-Верде',
        },
        text_13: {
            ru: 'Среднее время по Гринвичу',
            en: 'Greenwich Mean Time',
            kz: 'Гринвичтің орташа уақыты',
        },
        text_14: {
            ru: 'Дублин, Лондон',
            en: 'Dublin, London',
            kz: 'Дублин, Лондон',
        },
        text_15: {
            ru: 'Амстердам',
            en: 'Amsterdam',
            kz: 'Амстердам',
        },
        text_16: {
            ru: 'Москва, Киев, Минск, Никосия, Стамбул',
            en: 'Moscow, Kyiv, Minsk, Nicosia, Istanbul',
            kz: 'Мәскеу, Киев, Минск, Никосия, Стамбул',
        },
        text_17: {
            ru: 'Ереван, Ежевск, Самара , Саратов, Порт-Луи, Тбилиси',
            en: 'Yerevan, Yezhevsk, Samara , Saratov, Port Louis, Tbilisi',
            kz: 'Ереван, Ежевск, Самара, Саратов, Порт-Луис, Тбилиси',
        },
        text_18: {
            ru: 'Екатеринбург, Ташкент',
            en: 'Yekaterinburg, Tashkent',
            kz: 'Екатеринбург, Ташкент',
        },
        text_19: {
            ru: 'Алматы, Нур-Султан',
            en: 'Almaty, Nur-Sultan',
            kz: 'Алматы, Нұр-Сұлтан',
        },
        text_20: {
            ru: 'Новосибирск, Красноярск',
            en: 'Novosibirsk, Krasnoyarsk',
            kz: 'Новосибирск, Красноярск',
        },
        text_21: {
            ru: 'Пекин, Шанхай,Куала-Лумпур, Сингапур',
            en: 'Beijing, Shanghai, Kuala Lumpur, Singapore',
            kz: 'Пекин, Шанхай, Куала-Лумпур, Сингапур',
        },
        text_22: {
            ru: 'Сеул',
            en: 'Seoul',
            kz: 'Сеул',
        },
        text_23: {
            ru: 'Владивосток',
            en: 'Vladivostok',
            kz: 'Владивосток',
        },
        text_24: {
            ru: 'Камчатка',
            en: 'Kamchatka',
            kz: 'Камчатка',
        },
        text_25: {
            ru: "Самоа, Нукуалофа",
            en: "Samoa, Nuku'alofa",
            kz: "Самоа, Нукуалофа",
        },
        text_26: {
            ru: 'остров Киритимати',
            en: 'Kiritimati island',
            kz: 'Киритимати аралы',
        },
    },
    contract: {
        adding_contract: {
            ru: 'Добавление контракта',
            en: 'Adding a contract',
            kz: 'Келісімшартты қосу',
        },
        d: {
            ru: 'д.',
            en: 'd.',
            kz: 'д.',
        },
        tg: {
            ru: 'тг',
            en: 'KZT',
            kz: 'тг',
        },
        text_1: {
            ru: 'Пока нет ни одного контракта',
            en: 'There are no contracts yet',
            kz: 'Әзірге келісімшарттар жоқ',
        },
        text_2: {
            ru: 'Создать контракт',
            en: 'Create a contract',
            kz: 'Келісімшарт жасаңыз',
        },
        text_3: {
            ru: 'Дата подписания',
            en: 'Date of signing',
            kz: 'Кол қойылған күні',
        },
        text_4: {
            ru: 'Дата окончания',
            en: 'Expiration date',
            kz: 'Мерзімнің өту күні',
        },
        text_5: {
            ru: 'Заработная плата',
            en: 'Wage',
            kz: 'Еңбекақы',
        },
        text_8: {
            ru: 'рабочих часов в день',
            en: 'working hours per day',
            kz: 'тәулігіне жұмыс уақыты',
        },
        text_9: {
            ru: 'дней в год',
            en: 'days per year',
            kz: 'жылына күндер',
        },
        text_10: {
            ru: 'Остаток на сегодня',
            en: 'Remainder for today',
            kz: 'Бүгінгі күнге қалдық',
        },
        text_11: {
            ru: 'история изменений',
            en: 'history of changes',
            kz: 'өзгерістер тарихы',
        },
        text_12: {
            ru: 'Редактировать контракт',
            en: 'Edit contract',
            kz: 'Келісімшартты өңдеу',
        },
        text_13: {
            ru: 'Продлить контракт',
            en: 'To extend the contract',
            kz: 'Келісімшартты ұзарту үшін',
        },
        text_14: {
            ru: 'Редактировать',
            en: 'Edit',
            kz: 'Өңдеу',
        },
        text_15: {
            ru: 'Ежемесячная',
            en: 'Monthly',
            kz: 'Ай сайын',
        },
        text_16: {
            ru: 'Ежедневная',
            en: 'Daily',
            kz: 'Күнделікті',
        },
        text_17: {
            ru: 'Почасовая',
            en: 'Hourly',
            kz: 'Cағат сайын',
        },
    },
    study_test: {
        current_test: {
            ru: 'Текущие',
            en: 'Current',
            kz: 'Ағымдағы',
        },
        past_test: {
            ru: 'Прошедшие',
            en: 'Past',
            kz: 'Өткен',
        },
        schedule_test: {
            ru: 'Назначить тест',
            en: 'Schedule a test',
            kz: 'Сынақты жоспарлаңыз',
        },
        show_all_tests: {
            ru: 'Показать все тесты',
            en: 'Show all tests',
            kz: 'Барлық сынақтарды көрсетіңіз',
        },
        classroom: {
            ru: 'ауд',
            en: 'cl',
            kz: 'ауд',
        },
        name: {
            ru: 'Тесты',
            en: 'Tests',
            kz: 'Тесттер',
        },
    },
    monthly_plan: {
        monthly_plan: {
            ru: 'Месячный план',
            en: 'Monthly plan',
            kz: 'Айлық жоспар',
        },
        actual_plan: {
            ru: 'План на текущий месяц',
            en: 'Plan for the current month',
            kz: 'Ағымдағы айға жоспар жасаңыз',
        },
        work_in: {
            ru: 'Отработано в',
            en: 'Worked out in',
            kz: 'Жұмыс істеген',
        },
        about_work: {
            ru: 'К отработке в',
            en: 'To work out in',
            kz: 'Жаттығу үшін',
        },
        text_1: {
            ru: 'Месячный план и статистика отработанного времени',
            en: 'Monthly plan and statistics of hours worked',
            kz: 'Айлық жоспар және жұмыс уақытының статистикасы',
        },
        text_2: {
            ru: 'Данных нет',
            en: 'No data',
            kz: 'Деректер жоқ',
        },
        ch: {
            ru: 'ч.',
            en: 'h.',
            kz: 'ч.',
        },
        min: {
            ru: 'мин.',
            en: 'min.',
            kz: 'мин.',
        },
    },
    in_month: {
        months_1: {
            ru: 'январе',
            en: 'january',
            kz: 'қаңтар',
        },
        months_2: {
            ru: 'феврале',
            en: 'february',
            kz: 'ақпан',
        },
        months_3: {
            ru: 'марте',
            en: 'march',
            kz: 'наурыз',
        },
        months_4: {
            ru: 'апреле',
            en: 'april',
            kz: 'сәуір',
        },
        months_5: {
            ru: 'мае',
            en: 'May',
            kz: 'мамыр',
        },
        months_6: {
            ru: 'июне',
            en: 'june',
            kz: 'маусым',
        },
        months_7: {
            ru: 'июле',
            en: 'july',
            kz: 'шілде',
        },
        months_8: {
            ru: 'августе',
            en: 'august',
            kz: 'тамыз',
        },
        months_9: {
            ru: 'сентябре',
            en: 'september',
            kz: 'қыркүйек',
        },
        months_10: {
            ru: 'октябре',
            en: 'october',
            kz: 'қазан',
        },
        months_11: {
            ru: 'ноябре',
            en: 'november',
            kz: 'қараша',
        },
        months_12: {
            ru: 'декабре',
            en: 'december',
            kz: 'желтоқсан',
        },
    },
    alert: {
        yes: {
            ru: 'Да',
            en: 'Yes',
            kz: 'Иә',
        },
        no: {
            ru: 'Нет',
            en: 'No',
            kz: 'Жоқ',
        },
        show: {
            ru: 'Показать',
            en: 'Show',
            kz: 'Көрсету',
        },
    },
    register_listing: {
        text_1: {
            ru: 'Добавить регистр',
            en: 'Add register',
            kz: 'Тіркеуді қосу',
        },
        text_2: {
            ru: 'Домашнее задание:',
            en: 'Homework:',
            kz: 'Үй жұмысы:',
        },
        text_3: {
            ru: 'План урока',
            en: 'Lesson plan',
            kz: 'Сабақ жоспары',
        },
        text_4: {
            ru: 'Вы точно хотите безвозвратно удалить урок',
            en: 'Are you sure you want to permanently delete the lesson',
            kz: 'Сабақты мүлдем жойғыңыз келетініне сенімдісіз бе',
        },
        text_5: {
            ru: 'Редактирование урока:',
            en: 'Editing a lesson:',
            kz: 'Сабақты өңдеу:',
        },
    },
    client_card: {
        text_1: {
            ru: 'Ссылка на Zoom',
            en: 'Zoom Link',
            kz: 'Zoom сілтемесі',
        },
        text_2: {
            ru: 'Акционный тип обучения',
            en: 'Promotional type of training ',
            kz: 'Тренингтің жарнамалық түрі',
        },
        text_3: {
            ru: 'Тип обучения',
            en: 'Type of training',
            kz: 'Тренинг түрі',
        },
        text_4: {
            ru: 'изменить тип обучения',
            en: 'change learning type',
            kz: 'оқыту түрін өзгерту',
        },
    },
    groups_discount: {
        actual_students: {
            ru: 'Только актуальные',
            en: 'Only relevant',
            kz: 'Тек қатысты',
        },
        all_students: {
            ru: 'Все клиенты',
            en: 'All clients',
            kz: 'Барлық клиенттер',
        },
        title_students: {
            ru: 'Клиенты со скидками',
            en: 'Clients with discounts',
            kz: 'Жеңілдіктері бар клиенттер',
        }
    },
    menu: {
        books: {
            ru: 'Книги',
            en: 'Books',
            kz: 'Кітаптар',
        },
        online_settings: {
            ru: 'Настройки онлайн-записи',
            en: 'Online recording settings',
            kz: 'Онлайн жазу параметрлері',
        },
        employee_record_history: {
            ru: 'История записи сотрудников',
            en: 'Employee Record History',
            kz: 'Қызметкерлердің жазбалары',
        },
        reports: {
            ru: 'Отчеты',
            en: 'Reports',
            kz: 'Есептер',
        },
        customers_with_debts: {
            ru: 'Клиенты с долгами',
            en: 'Customers with Debts',
            kz: 'Қарызы бар тұтынушылар',
        },
        students_with_certificates: {
            ru: 'Студенты с сертификатами',
            en: 'Students with Certificates',
            kz: 'Сертификаттары бар студенттер',
        },
        certificates_connected_to_groups: {
            ru: 'Подключенные сертификаты к группам',
            en: 'Certificates Connected to Groups',
            kz: 'Топтарға қосылған сертификаттар',
        },
        paid_time: {
            ru: 'Оплаченное время',
            en: 'Paid Time',
            kz: 'Төлену уақыты',
        },
        transactions: {
            ru: 'Проводки',
            en: 'Transactions',
            kz: 'Транзакциялар',
        },
        refunds: {
            ru: 'Возвраты средств',
            en: 'Refunds',
            kz: 'Қайтарулар',

        },
        fund_transfers: {
            ru: 'Переводы средств',
            en: 'Fund Transfers',
            kz: 'Қаражат аударымдары',

        },
        cash_inflows_: {
            ru: 'Приход денежных средств',
            en: 'Cash Inflows ',
            kz: 'Ақша түсімдері ',

        },
        evaluation_criteria: {
            ru: 'Критерии оценок',
            en: 'Evaluation criteria',
            kz: ' Бағалау критерийлері',

        },
        teacher_rates: {
            ru: 'Ставки преподователей',
            en: 'Teacher rates',
            kz: 'Мұғалім бағалары',

        },

    },

    books: {
        ebooks: {
            ru: 'Электронные книги',
            en: 'Electronic books',
            kz: 'Электронды кітаптар'
        },
        adding_book: {
            ru: 'Добавление книги',
            en: 'Adding a book',
            kz: 'Кітап қосу'
        },
        personal_courses: {
            ru: 'Личные курсы',
            en: 'Personal courses',
            kz: 'Жеке курстар'
        },
        sections: {
            ru: 'Разделы',
            en: 'Sections',
            kz: 'Бөлімдер',
        },
        sections_: {
            ru: 'Разделов',
            en: 'Sections',
            kz: 'Бөлімдер',
        }

    },
    placement_test: {
        title: {
            ru: 'Письменные тесты',
            en: 'Written tests',
            kz: 'Жазбаша тесттер',
        },
        name_test: {
            ru: 'Название теста',
            en: 'Test name',
            kz: 'Сынақ атауы',
        },
        level: {
            ru: 'Уровень',
            en: 'Level',
            kz: 'Деңгейі',
        },
    },
    groups_discounts: {
        discount: {
            ru: 'Скидка',
            en: 'Discount',
            kz: 'Жеңілдік',
        },
    },
    employee_skills: {
        test_programs: {
            ru: 'Программы тестирования',
            en: 'Test programs',
            kz: 'Сынақ бағдарламалары',
        },
        disciplines: {
            ru: 'Дисциплины',
            en: 'Disciplines',
            kz: 'Оқытатын пәндері',
        },
        locations: {
            ru: 'Локации',
            en: 'Locations',
            kz: 'Орындар',
        }
    },
    shedules: {
        edit_schedule: {
            ru: 'Редактировать график',
            en: 'Edit schedule',
            kz: 'Кестені өңдеу',

        },
        employee_profile: {
            ru: 'Профиль сотрудника',
            en: 'Employee profile',
            kz: 'Қызметкер профилі',

        },
        unchecked: {
            ru: 'Не отметился',
            en: 'Unchecked',
            kz: 'Белгіленбеген',

        },
        sick_leave: {
            ru: 'Больничный',
            en: 'Sick leave',
            kz: 'Ауру демалысы',

        },
        vacation: {
            ru: 'Отпуск',
            en: 'Vacation',
            kz: 'Демалыс',

        },
        vacation_b: {
            ru: 'Отпуск Б/C',
            en: 'Vacation B/C',
            kz: 'Демалыс Б/C',

        },
        day_off: {
            ru: 'Выходной',
            en: 'Day off',
            kz: 'Демалыс күні',

        },
        lateness: {
            ru: 'Опоздание',
            en: 'Lateness',
            kz: 'Кешік',
        },
        early_leave: {
            ru: 'Уход раньше',
            en: 'Early leave',
            kz: 'Ерте демалыс',
        },
        holiday: {
            ru: 'выходной',
            en: 'holiday',
            kz: 'демалыс',
        },
        work_or_time_off: {
            ru: 'рабочий или отработанный',
            en: 'work or time off',
            kz: 'жұмыс немесе демалыс',

        },
        arrival: {
            ru: 'Приход',
            en: 'Arrival',
            kz: 'Келу',

        },
        departure: {
            ru: 'Уход',
            en: 'Departure',
            kz: 'Шығу',

        },
        worked_out: {
            ru: 'Отработано',
            en: 'Worked out',
            kz: 'Жұмыс істеді',

        },
        add_schedule: {
            ru: 'Добавить график',
            en: 'Add schedule',
            kz: 'Кесте қосу',

        },
        work: {
            ru: 'работы',
            en: 'work',
            kz: 'жұмыс',

        },
        undefined: {
            ru: 'Не определено',
            en: 'Undefined',
            kz: 'Анықталмаған',

        },
        break: {
            ru: 'Перерыв',
            en: 'Break',
            kz: 'Үзіліс',
        },
    },
    study_test_client_page: {
        validity_date: {
            ru: 'Дата действия',
            en: 'Validity date',
            kz: 'Жарамдылық мерзімі',
        },
        clients: {
            ru: 'Клиенты',
            en: 'Clients',
            kz: 'Клиенттер',
        },
        group: {
            ru: 'Группа',
            en: 'Group',
            kz: 'Топ',
        }
    },
    schedule_settings: {
        setting_the_slot: {
            ru: 'Настройка календаря слотов',
            en: 'Setting up the slot calendar',
            kz: 'Слот күнтізбесін орнату',
        }
    },
    company: {
        student_discount: {
            start_date: {
                ru: 'Дата начала',
                en: 'Start date',
                kz: 'Басталу күні',
            },
            end_date: {
                ru: 'Дата окончания',
                en: 'End date',
                kz: 'Мерзімнің өту күні',
            },
        }
    }
}
