import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import Layouts from './layouts/Layouts';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Loader from 'components/UI/Loader/Loader';
import styles from './SlotSettings.module.scss';

const TITLE_POSTFIX = 'настройка онлайн записи';

function SlotSettings() {
  const {id} = useParams();
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({isError: false, message: ''});

  useEffect(() => {
    slotsSettingsStore.fetchCompanies(id, setCompanyName);
    slotsSettingsStore.fetchData(id, setIsLoading, setError);
  }, [id]);

  // TODO: add error component
  const Content = () => !error.isError ? <Layouts /> : <div>{error.message}</div>;

  return (
    <>
      <ContentHeader title={`${companyName} ${TITLE_POSTFIX}`}/>
      <form className={styles.form}>{isLoading ? <Loader /> : <Content />}</form>
    </>
  );
}

export default SlotSettings;

