import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Avatar from './TeacherAvatar';
import styles from './TeacherCards.module.scss';


function TeacherCard({id, name, avatar, onClick, selected}) {
  return (
    <li className={styles.teacher_wrapper}>
      <input
        id={`teacher-${id}`}
        type='radio'
        name='slot-teacher'
        className={`visually-hidden ${styles.radio}`}
        checked={selected === id}
        onChange={() => onClick(id)}
      />
      <label className={styles.teacher} htmlFor={`teacher-${id}`} onClick={() => onClick(id)}>
        <Avatar name={name} avatar={avatar} />
        <span className={styles.name}>{name}</span>
        <span className={styles.custom_radio} />
      </label>
    </li>
  );
}

TeacherCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default observer(TeacherCard);
