import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import st from "./EmailRead.module.scss";
import mailImg from "components/ContentBox/img/mail.svg";

const EmailRead = props => {
  const [email, setEmail] = useState([false, null])
  const [errorEmail, setErrorEmail] = useState(false)

  useEffect(() => {
    const apiConf = {
      method: 'get',
      link: 'telegram/pulse/' + props.emailKey,
    }
    superAxiosRequest(apiConf).then(res => {
      setEmail([true, res.data])
    }).catch(() => {
      setErrorEmail(true)
    })

  }, [props.emailKey])

  function createMarkup() {
    return {__html: email[1]};
  }

  return (
    <div className={st.emailReader}>
      {email[0] &&
        <div className={st.content}>
          <div dangerouslySetInnerHTML={createMarkup()}/>
        </div>}
      {errorEmail && <div className={st.notFound}>
        <img src={mailImg} alt="alt"/>
        <span className={st.strikethrough}/>
        <span>Просмотр данного письма недоступен</span>
      </div>}
    </div>
  )
}
export default observer(EmailRead);
