import React from "react";
import styles from "./PageHeader.module.scss";

const PageHeader = ({title, children}) => (
  <div className={styles.pageHeader}>
    <h1 className={styles.title}>{title}</h1>
    {children}
  </div>
)

export default PageHeader;
