import React, { useEffect, useState } from 'react'

export default function VIdeoLinks(props) {
    const [videos, setvideos] = useState([])

    const changeHandler = (e, index, key) =>{
        let arr = videos;
        arr[index][key] = e.target.value
        setvideos([...arr])
    }

    const addLIne = () =>{
        let arr = videos;
        arr.push(
            {video_link: '', description: ''}
        )
        setvideos([...arr])
    }

    useEffect(() => {
        props.objectChangeHandler(videos, 'items')
    }, [videos])

    useEffect(() => {
        if(props.update){
            setvideos(props.values)
        } else {
            setvideos([{video_link: '', description: ''}])
        }
    }, [])
    return (
        <>
            {
                videos.map((item, index)=>{
                return(
                    <div className="video_links-box" key={index}>
                        <input type="text"  value={item.video_link} placeholder="Ссылка на видео" onChange={(e=>changeHandler(e, index, 'video_link'))}/>
                        <p>Вставьте ссылку на видео из Youtube, Google Drive, Vkontakte, Vimeo
                            или TED. Ознакомиться с <span>инструкцией</span></p>
                        <input type="text" value={item.description} placeholder="Название видео" onChange={(e=>changeHandler(e, index, 'description'))}/>
                    </div>
                )    
            })
                
            }
        <div className="modal-question-add-button-add" onClick={()=>addLIne()}>+</div>
        </>
    )
}
