import {observable, action, computed, toJS, makeObservable} from 'mobx';
import Stores from '.';
import {superAxiosRequest} from 'axiosApi';

class UsersStore {
  infoProfile = []
  userDocs = []
  userCerts = []
  userContractsData = []
  userSalaryData = []
  isError = false

  constructor() {
    makeObservable(this, {
      infoProfile: observable,
      userDocs: observable,
      userContractsData: observable,
      userCerts: observable,
      userSalaryData: observable,

      getProfileData: action,
      updateProfile: action,
      setUserDocs: action,
      addUserDoc: action,
      setUserCerts: action,
      addCert: action,
      deleteUserCert: action,
      setUserContracts: action,
      setUserSalary: action,

      userDocslist: computed,
      userContracts: computed,
      userSalary: computed,
      userCertObject: computed,
      userProfile: computed,
    })
  }

  addCert = (row) => {
    let Data = Stores.observableArray(this.userCerts)
    Data.push(row)
    this.userCerts = Data
  }

  get userCertObject() {
    return Stores.observableArray(this.userCerts)
  }

  setUserSalary = data => {
    this.userSalaryData = data
  }

  setUserCerts = (id) => {
    superAxiosRequest({method: 'get', link: 'groups/get_student_certs', params: {student: id}})
      .then(res => this.userCerts = res.data.result || [])
  }

  deleteUserCert = id => {
    let Data = Stores.observableArray(this.userCerts)
    Data = Data.filter(x => x.id !== id)
    this.userCerts = Data
  }

  getProfileData = id => {
    superAxiosRequest({
      method: 'get',
      link: 'accounts/employers_detail/' + id,
    }).then(res => {
      this.infoProfile = res.data
    }).catch(error => {
      if (error)
        this.isError = true
    })
  }


  updateProfile = (data) => {
    this.infoProfile = data
  }

  deleteProfile = () => {
    this.infoProfile = []
  }

  get userDocslist() {
    return toJS(this.userDocs)
  }

  setUserDocs = id => {
    const conf = {
      link: 'employees/appendix',
      method: 'get',
      params: {
        employee_id: id,
      }
    }
    superAxiosRequest(conf).then(res => this.userDocs = res.data.result)
  }

  addUserDoc = data => {
    this.userDocs = this.userDocs.map(doc => doc.id === data.id ? data : doc)
  }

  setUserContracts = id => {
    superAxiosRequest({
      link: 'employees/contract',
      method: 'get',
      params: {
        employee: id,
        limit: 999
      }
    }).then(({data}) => {
      this.userContractsData = data.result
    }).catch(() => {
      this.userContractsData = []
    })
  }

  get userProfile() {
    return toJS(this.infoProfile)
  }

  get userSalary() {
    return toJS(this.userSalaryData)
  }

  get userContracts() {
    return toJS(this.userContractsData)
  }

}

export default UsersStore = new UsersStore()
