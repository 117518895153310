import React from 'react';
import PropTypes from "prop-types";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";
import VariantAdd from "../../../StudyTest/TestQuestion/components/VariantAdd";
import st from './TestQuestion.module.scss';
import VariantDel from "../../../StudyTest/TestQuestion/components/VariantDel";

const TestQuestion = (props) => {

    const isError = props.isError && (!props.question.text || props.question.variants.some(item => item.text === '') || props.question.variants.length <= 1)
    return (
        <div className={`${st.ask} ${isError ? st.isError : ''}`}>
            <div className={st.askHeader}>
                <p className={st.header}>{props.translate.question} {props.pos}</p>
                {
                    props.questions.length > 1 &&
                    <VariantDel onClick={props.delete}/>
                }

            </div>
            <input type="text"
                   placeholder={props.translate.enter_the_question}
                   value={props.question.text}
                   onChange={e => {
                       props.onChange({...props.question, text: e.target.value})
                   }}/>

            {props.isPoint &&
                <div className={st.point}>
                    <p>{props.translate.num_point_question}:</p>
                    <input type="text"
                           value={+props.question.points || ''}
                           onChange={e => {
                               isFinite(e.target.value) && props.onChange({...props.question, points: e.target.value})
                           }}/>
                </div>}

            <ul className={st.variants}>{props.question.variants.map((variant, idx) =>
                <li key={idx}>
                    <Checkbox checked={variant.is_correct} onChange={() => {
                        props.onChange({...props.question,
                            variants: props.question.variants.map((v, i) => i === idx ? {
                                ...variant,
                                is_correct: !variant.is_correct
                            } : v)
                        })
                    }}/>

                    <input placeholder={props.translate.enter_the_number} type="text" value={variant.text} onChange={e => props.onChange({
                        ...props.question, variants: props.question.variants.map((v, i) => i === idx ? {
                            ...variant,
                            text: e.target.value
                        } : v)
                    })}/>

                    {idx > 0 &&
                        <div className={st.close} onClick={() => props.onChange({
                            ...props.question,
                            variants: props.question.variants.filter((v, i) => i !== idx)
                        })}/>}
                </li>)}
            </ul>
            <VariantAdd onClick={() => props.onChange({
                ...props.question,
                variants: [...props.question.variants, {id: props.question.variants.length + 1, text: '', is_correct: false}]
            })}/>
        </div>
    )
}

TestQuestion.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string,
    points: PropTypes.string,
    variants: PropTypes.array
  }),
  pos: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isPoint: PropTypes.bool,
}

export default TestQuestion;
