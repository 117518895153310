import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import TeacherCards from 'components/Modules/Slots/components/TeacherCards/TeacherCards';
import ClientData from 'components/Modules/Slots/components/ClientData/ClientData';
import PaymentFields from 'components/Modules/Slots/RecordCustomer/components/PaymentFields/PaymentFields';
import { scrollToRef } from 'helpers';
import slotsRecordCustomerStore, { DataType, ActionType } from 'Stores/slotsRecordCustomerStore';

function ClientDataLayout() {
  const teacherRef = useRef(null);
  const {
    teachers,
    selectedTeacher,
    clientData,
    guardianData,
    isGuardian,
    setData,
    setClientData,
    resetData,
    isClientOverwrite,
  } = slotsRecordCustomerStore;

  const handleClientDataChange = (value, type, isGuardian) => {
    setClientData(value, type, isGuardian);
  };

  useEffect(() => {
    if (teacherRef) {
      scrollToRef(teacherRef, 100);
    }
  }, []);
  
  return (
    <>
      <Paper ref={teacherRef}>
        <SectionTitle title="Выберите преподавателя" secondary />
        <TeacherCards
          teachers={teachers}
          selectedTeacher={selectedTeacher}
          onSetData={(id) => setData(id, DataType.selectedTeacher)}
        />
      </Paper>
      {selectedTeacher &&
        <>
          <ClientData
            isGuardian={isGuardian}
            clientData={clientData}
            guardianData={guardianData}
            onSetClientData={handleClientDataChange}
            onSetIsGuardian={(flag) => setData(flag, DataType.isGuardian)}
            onResetData={() => resetData(ActionType.resetGuardianData)}
            isOverwrite={isClientOverwrite}
          />
          <PaymentFields />
        </>
      }
    </>
  );
}

export default observer(ClientDataLayout);
