import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import { 
        classNames as cn,
        goodTime
    } from "helpers";
import lessonStore from '../../../Stores/lessonStore';
import Stores from "Stores";
import modalStore from 'Stores/modalStore';
import coursesStore from 'Stores/coursesStore';
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import SelectCustom from "../Global/Select/SelectCustom";
import ExerciseShowToStudents from "../Global/GlobalModalSingle/templates/ExerciseShowToStudents";
import { 
    QuestionImagesList,
    QuestionImagesThumb,
    QuestionImagesCarousel,
    QuestionYoutube,
    QuestionDragWords,
    QuestionWriting,
    QuestionSimpleText,
    QuestionImagesMatchText,
    QuestionSelectWords,
    QuestionMatchWords,
    QuestionMakeWord,
    QuestionGroupWords,
    QuestionAudios,
    QuestionUploadedVideo,
    QuestionTest,
    QuestionLink,
    QuestionAttention,
    QuestionMakeSentence,
} from './questions';
//import QuestionSimpleVideo from "./questions/QuestionSimpleVideo";
import './Question.scss'
import { ReactComponent as ShowToStudentsIcon } from "../img/four-squares-button-of-view-options.svg";
import { ReactComponent as FocusOnExerciseIcon } from "../img/focus.svg"
import { ReactComponent as TrashIcon } from "../img/trash.svg"
import { ReactComponent as ArrowUpIcon } from "../img/up-arrow.svg"
import { ReactComponent as ArrowDownIcon } from "../img/arrow-down.svg"
import { stringToBoolean } from '../../../helpers';


const Question = props => {
    const tq = useRef();

    const {
        updateExercise,
        deleteExercise,
        exerciseListObject,
        setExerciseList,
    } = coursesStore

    const isTeacher = Stores.Rules.checkRule2('lessons', 'initlessonforstudents', 'add_init_lesson')
    const isStudent = Stores.isClient

    const questionsWithAnswers = [
        'ex_essay',
        'ex_make_sentence',
        'ex_make_word_letters',
        'ex_phrases_in_blanks',
        'ex_correct_form',
        'ex_sort_words_in_columns',
        'ex_match_description_picture',
        'ex_word_matching',
        'ex_words_in_blanks',
        'ex_test'
    ]

    const isResponseRequired = questionsWithAnswers.includes(props.item.type_exercise)

    const [studentID, setStudentID] = useState()
    const [answers, setAnswers] = useState([])
    const [sendAnswersButtonActive, setSendAnswersButtonActive] = useState(true)
    const [activeTry, setActiveTry] = useState(1)
    const [tryHistory, setTryHistory] = useState([])
    const [timer, setTimer] = useState(0)
    const [isTimerOn, setIsTimerOn] = useState(false)

    let allAttempts = props.initLesson ? props.initLesson.number_of_attempts + props.initLesson.add_number_of_attempts : 0
    const activeStudentID = Stores.isClient ? Stores.profileId : studentID
    const studentsInfo = props.init ? props.answerList.filter(student => student.student === activeStudentID) : []
    const answersInCurrentQuestion = props.init ? studentsInfo.find(answer => answer.exercise === props.item.id) : []

    const readyAnswers = tryHistory ? tryHistory[activeTry - 1] : false
    const readyAnswersArray = readyAnswers ? readyAnswers.asw_student.split('|') : []
    const correctAnswersArray = readyAnswers ? readyAnswers.is_correct.split('|') : []
    
    
    // Меню урока
    /*const menu = [
        {name: 'Редактировать', action:'edit', permission: ['forward']},
        {name: 'Удалить', action: 'delete', permission: ['forward']},
        {name: 'Копировать', permission: ['forward']},
        {name: 'Переместить', permission: ['forward']},
        {name: 'Добавить в домашнее задание', permission: ['forward']}
    ]*/
    // Меню преподавателя
    /*const teacherMenu = [
        {name: 'Показать студентам', action: 'show', permission: ['forward']},
        {name: 'Фокус на упражнении', action: 'focus', permission: ['forward']},
    ]*/

    // Действия по упражнению
    const action = action => {
        switch (action) {
            case 'edit':
                modalStore.activity({
                    name: 'lessons_question',
                    link: 'lessons/exercise/' + props.item.id,
                    button: 'Сохранить',
                    title: 'Редактирование упражнения',
                    type: 'lessons_question',
                    is_put: true,
                    data: 'json',
                    func: updateExercise,
                })
                break;
            case 'delete':
                swal({
                    title: localisationMaster('text_2', 'alert'),
                    text: `Вы действительно хотите удалить упражнение?`,
                    icon: "warning",
                    buttons: [localisationMaster('button_2', 'alert'), localisationMaster('button_1', 'alert')],
                    dangerMode: true,
                }).then(yes => {
                    if (yes) {
                        const apiConf = {
                            method: 'delete',
                            link: 'lessons/exercise/' + props.item.id,
                        }
                        superAxiosRequest(apiConf).then(() => deleteExercise(props.item.id))
                    }
                })
                break;
            case 'up':
            case 'down':
                const move = (array, from, to) => {
                    array.splice(to, 0, array.splice(from, 1)[0]);
                    return array;
                };
                let arr = exerciseListObject.exercises.map(x => x.id)
                arr = move(arr, arr.indexOf(props.item.id), arr.indexOf(props.item.id) + (action === 'up' ? -1 : 1))

                const apiConf = {
                    method: 'put',
                    link: 'lessons/exercise_position/' + exerciseListObject.id
                }
                let body = {
                    exercise_position: arr
                }

                superAxiosRequest(apiConf, body).then(res => {
                    setExerciseList(res.data)
                    props.scrollTo(res.data.exercises.findIndex(x => x.id === props.item.id))
                })
                break;
            default:
                break;
        }
    }

    // Действия преподавателя
    const teacherAction = action => {
        switch (action) {
            case 'show':
                const ms = {
                    //name: 'lessons_question',
                    link: 'lessons/assign_exercise',
                    button: 'Показать',
                    title: 'Выберите учеников',
                    //type: 'lessons_question',
                    modalBodyComponent: () => <ExerciseShowToStudents allStudents={props.students}/>,
                    //is_put: true,
                    data: 'json',
                    //func: coursesStore.updateExercise,
                }
                const md = {
                    init_lesson: props.uuid,
                    exercise: props.item.id,
                    section: props.sectionID
                }
                modalStore.activity(ms, md)
                break;
            case 'focus':
                const mbody = {
                    "type": "lessons.notify_focus_on_exercise", "message": {
                        exersice: props.item.id
                    },
                };
                props.sendMessage(mbody)
                cogoToast.info(`Ученики сфокусировались на упражнении ${props.number}`, { position: "bottom-right" })
                break;
            default:
                break;
        }
    }
    const dataForExTemplate = {
        try: activeTry,
        history: tryHistory,
        isStudent: isStudent,
        isTeacher: isTeacher,
        init: props.init,
        ...(tryHistory && {is_checked_try: activeTry <= tryHistory.length}),
        ...(tryHistory && {at: tryHistory[activeTry - 1]}),
        is_student_checked_try: (props.init && isStudent && tryHistory[activeTry - 1]),
        is_teacher_view: (props.init && isTeacher),
        is_clean_ex: (!props.init || (isStudent && !tryHistory[activeTry - 1])),
        answ_array: readyAnswersArray,
        correct_answ: correctAnswersArray.map(el => stringToBoolean(el)),
        handleAnswers: setAnswersFromQuestion,
        answers: answers,
    }

    const returnTemplate = (type, values) => {
        let body;
        switch (type) {
            case 'ex_image':
                switch (values.show_like) {
                    case 'sequence':
                        body = <QuestionImagesList images={values.items} text={values.text_exercise} />
                        break;
                    case 'mini':
                        body = <QuestionImagesThumb images={values.items} text={values.text_exercise} />
                        break;
                    case 'slider':
                        body = <QuestionImagesCarousel images={values.items} text={values.text_exercise} />
                        break;

                    default:
                        break;
                }
                break;
            case 'ex_gif':
                body = <QuestionImagesList images={values.items} text={values.text_exercise} />
                break;
            case 'ex_video_link':
                body = <QuestionYoutube videos={values.items} text={values.text_exercise} />
                break;
            case 'ex_essay':
                body = <QuestionWriting
                    img={values.items[0]}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_text':
                body = <QuestionSimpleText text={values.items[0].text} />
                break;
            case 'ex_match_description_picture':
                body = <QuestionImagesMatchText
                    items={values.items}
                    text={values.text_exercise}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_make_sentence':
                body = <QuestionMakeSentence
                    sentence={values.items[0].text_mix}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_sort_words_in_columns':
                body = <QuestionGroupWords
                    items={values.items}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_phrases_in_blanks':
                body = <QuestionDragWords
                    items={values.items[0]}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_correct_form':
                body = <QuestionSelectWords
                    items={values.items[0]}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_link':
                body = <QuestionLink {...values.items[0]} />
                break;

            case 'ex_video':
                body = <QuestionUploadedVideo videos={values.items} v_link={values.items[0]?.file || ''} text={values.text_exercise} />
                break;
            case 'ex_audio':
                body = <QuestionAudios items={values.items} condition_exercise={values.condition_exercise} text={values.text_exercise} />
                break;
            case 'ex_make_word_letters':
                body = <QuestionMakeWord
                    words={values.items}
                    {...dataForExTemplate}
                />
                break;
            case 'ex_word_matching':
                body = <QuestionMatchWords
                    item={values.items}
                    {...dataForExTemplate}
                />
                break;
            //только для преподавателя
            case 'ex_test':
                body = <QuestionTest
                    questions={values.items}
                    isTimer={values.is_timer}
                    time={values.time}
                    setTimer={handleSetTimer}
                    {...dataForExTemplate} />
                break;
            case 'ex_attention':
                body = <QuestionAttention images={values.items} text={values.text_exercise} />
                break
            default:
                break;
        }
        return body;
    }

    const viewAnswers = o => {
        setStudentID(o.id)
        const studentsInfo = props.answerList.filter(student => student.student === o.id)
        const answersInCurrentQuestion = studentsInfo.find(answer => answer.exercise === props.item.id)
        if (answersInCurrentQuestion) {
            setTryHistory(answersInCurrentQuestion.answers)
        }
        else setTryHistory([])
        setActiveTry(1)
        /*         }) */

    }

    function setAnswersFromQuestion(answ, btnactive) {
        setAnswers([...answ]) //устанавливаем ответы из шаблона задания
        if (btnactive === undefined) return; //если в задании нет проверки на активность кнопки оставляем ее активной
        setSendAnswersButtonActive(btnactive) //если проверка есть присваиваем значение из шаблона вопроса
    }

    useEffect(() => {
      if(answers.length) return;
      setSendAnswersButtonActive(false)
    }, [answers])

    function saveTry() {
        setSendAnswersButtonActive(false)
        let answersInRightFormat = []
        const apiConf = {
            method: 'post',
            link: 'lessons/answ_exrs',
        }
        switch (props.item.type_exercise) {
            case 'ex_words_in_blanks':
                answersInRightFormat = answers;
                break;
            case 'ex_word_matching':
                answersInRightFormat = answers;
                break;
            case 'ex_match_description_picture':
                for (let i = 0; i < answers.length; i++) {
                    answersInRightFormat[i] = `${answers[i].link}~~~${answers[i].description}`
                }
                break;
            case 'ex_sort_words_in_columns':
                for (let i = 0; i < answers.length; i++) {
                    answers[i].words.forEach(item => {
                        answersInRightFormat.push(`${item}~~~${answers[i].description}`)
                    })
                }
                break;
            case 'ex_correct_form':
                answersInRightFormat = answers;
                break;
            case 'ex_phrases_in_blanks':
                answersInRightFormat = answers;
                break;
            case 'ex_make_word_letters':
                for (let i = 0; i < answers.length; i++) {
                    answersInRightFormat[i] = answers[i].join('')
                }
                break;
            case 'ex_make_sentence':
                answersInRightFormat = answers;
                break;
            case 'ex_essay':
                answersInRightFormat[0] = answers.join('');
                break;
            case 'ex_test':
                let item;
                setTimer(0)
                setIsTimerOn(false)
                for (let i = 0; i < answers.length; i++) {
                    item = answers[i].split('-')
                    answersInRightFormat[item[0]] ?
                        answersInRightFormat[item[0]] = `${answersInRightFormat[item[0]]},${item[1]}` :
                        answersInRightFormat[item[0]] = item[1]
                }
                break;
            default:
                break;
        }
        const request = {
            "assign_exercise": props.item.assign_id,
            "exercise": props.item.id,
            "answers": [
                {
                    "asw_student": answersInRightFormat.join('|')
                }
            ]
        }

        superAxiosRequest(apiConf, request)
            .then((res) => {
                const returnedAnswers = res.data.result.find(answer => answer.assign_exercise === props.item.assign_id)
                setTryHistory(returnedAnswers.answers)
                if (activeTry < allAttempts) {
                    setAnswers([])
                    setActiveTry(activeTry + 1)
                }
            })
            .finally(() => setSendAnswersButtonActive(true)
            )
    }

    let arrayOfAttempts = [];

    if (Stores.isClient && allAttempts) {
        for (let i = 1; i <= allAttempts; i++) {
            arrayOfAttempts.push(i);
        }
    }

    if (Stores.isTeacher && answersInCurrentQuestion) {
        if(JSON.stringify(tryHistory) !== JSON.stringify(answersInCurrentQuestion.answers)){
            setTryHistory(answersInCurrentQuestion.answers)
        }
        for (let i = 1; i <= answersInCurrentQuestion.answers?.length; i++) {
            arrayOfAttempts.push(i);
        }
    }


    function activateTry(number) {
        setActiveTry(number)
    }

    function handleSetTimer(min) {
        setTimer(min * 60)
        setIsTimerOn(true)
    }

    //проверка истории сделанных попыток и запись ответов на нужный вопрос в стейт
    useEffect(() => {
        if (!isStudent || !props.init) return;
        const apiConf = {
            method: 'get',
            link: 'lessons/answ_exrs',
        }
        superAxiosRequest(apiConf).then((res) => {
            let answers = res.data.result.find(answer => answer.assign_exercise === props.item.assign_id)
            if (!answers) return;
            setTryHistory(answers.answers)
            if (answers.answers.length <= allAttempts) {
                setActiveTry(answers.answers.length)
            }
        }
        )
    }, [])

    //если количество использованных попыток равно количеству выданных попыток, блокировать кнопку отправки
    useEffect(() => {
        if(!props.init) return
        if (tryHistory.length < allAttempts) return;
        setSendAnswersButtonActive(false)
    }, [tryHistory])

    useEffect(() => {
        if(!props.init) return
        timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
    }, [timer]);


    const bdy = (ln, fn, allAttempts, id) => {
        let line = `${ln} ${fn} `
        const a = props.init ? props.answerList.filter(student => student.student === id) : []
        const v = props.init ? a.find(answer => answer.exercise === props.item.id) : []
        for (let i = 1; i <= allAttempts; i++) {
            if (!!v) {
                if (!!v.answers[i - 1]) {
                    switch (v.exercise_type) {
                        case 'ex_test':
                            let s_answers = v.answers[i - 1].asw_student.split(/(?:,|\|)+/)
                            let list = v.answers[i - 1].is_correct.split(/(?:,|\|)+/).map(x => s_answers.includes(x.split('-')[0]) ? ({ id: +x.split('-')[0], is_correct: stringToBoolean(x.split('-')[1]) }) : false)
                            .filter(x => !!x)
                            line += list.find(el => !el.is_correct) ? '🔴 ' : '🟢 '
                            break;
                        default:
                            if (v.answers[i - 1].is_correct.split('|').includes('false')) {
                                line += '🔴 '
                            } else {
                                line += '🟢 '
                            }
                            break;
                    }
                } else {
                    line += '⚪️ '
                }
            } else {
                if (id > 0) line += '⚪️ '
            }
        }
        return `${line}`
    }

    useEffect(() => {
        if (!props.focused) return;
        tq.current.scrollIntoView({ behavior: "smooth" });
        lessonStore.dropFocused()
    }, [props.focused])

    return (
        <div className={cn({
            lessonQuestion: true,
            ['lessonQuestion--first']: props.isFirst,
            ['lessonQuestion--last']: props.isLast,
        })} ref={tq}>

            {/*=== Шапка вопроса для учителя ===*/}

            {(props.init && isTeacher) &&
                <div className="lessonQuestion__teacherPanel">
                    {isResponseRequired ?
                        <>
                            <div className="lessonQuestion__teacherPanelStudents">
                                <SelectCustom placeholder="Без ученика"
                                    //apiLink={`lessons/get_free_students/${props.uuid}/${props.id}`}
                                    options={props.students}
                                    getOptionLabel={opt => bdy(opt.last_name, opt.first_name, allAttempts, opt.id)}
                                    valueID={studentID}
                                    onChange={o => viewAnswers(o)}
                                />
                            </div>
                            <ul className="lessonQuestion__teacherPanelAttempts">
                                {props.item.type_exercise !== 'ex_essay' ?
                                    (answersInCurrentQuestion?.answers || []).map((item, index) =>
                                        <li key={index}>
                                            {index + 1 <= answersInCurrentQuestion.answers.length &&
                                                <div className={item === activeTry ? 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' : 'lessonQuestion__studentPanelAttemptsButton'} onClick={() => activateTry(index + 1)}>{index + 1}</div>
                                            }
                                            {index + 1 > answersInCurrentQuestion.answers.length &&
                                                <div className={item === activeTry ? 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' : 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--inactive'} onClick={() => activateTry(index + 1)}>{index + 1}</div>
                                            }
                                        </li>
                                    ) :
                                    <li>
                                        <div className='lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' onClick={() => activateTry(1)}>1</div>
                                    </li>
                                }
                            </ul>
                        </> :
                        null
                    }
                    <div className="lessonQuestion__teacherMenu">
                        <div
                            className="lessonQuestion__teacherMenuButtons"
                            onClick={() => teacherAction('show')}>
                            <ShowToStudentsIcon />
                        </div>
                        <div
                            className="lessonQuestion__teacherMenuButtons"
                            onClick={() => teacherAction('focus')}>
                            <FocusOnExerciseIcon />
                        </div>
                    </div>
                </div>}

            {/*=== Шапка вопроса для ученика ===*/}

            {(props.init && Stores.isClient) &&
                isResponseRequired ?
                <div className='lessonQuestion__studentPanel'>
                    <ul className="lessonQuestion__studentPanelAttempts">
                        {props.item.type_exercise !== 'ex_essay' ?
                            arrayOfAttempts.map(item =>
                                <li key={item}>
                                    {item <= tryHistory.length &&
                                        <div className={item === activeTry ? 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' : 'lessonQuestion__studentPanelAttemptsButton'} onClick={() => activateTry(item)}>{item}</div>
                                    }
                                    {item > tryHistory.length &&
                                        <div className={item === activeTry ? 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' : 'lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--inactive'} onClick={() => activateTry(item)}>{item}</div>
                                    }
                                </li>
                            ) :
                            <li>
                                <div className='lessonQuestion__studentPanelAttemptsButton lessonQuestion__studentPanelAttemptsButton--checked' onClick={() => activateTry(1)}>1</div>
                            </li>
                        }
                    </ul>

                    {(props.item.type_exercise === 'ex_test' && isTimerOn) &&
                        <div className='lessonQuestion__studentPanelTimer'>{`${goodTime(timer)}`}</div>}

                    {isStudent && <button
                        className={`lessonQuestion__studentPanelSaveResultsButton ${sendAnswersButtonActive ? '' : 'lessonQuestion__studentPanelSaveResultsButton--inactive'}`}
                        onClick={saveTry}
                        disabled={!sendAnswersButtonActive}
                    >Сохранить попытку</button>
                    }
                </div> :
                null}

            {/*=== Шапка вопроса для режима редактирования урока ===*/}

            {(!props.init) &&
                <div className="lessonQuestion__editModePanel">
                    <div
                        className="lessonQuestion__editModePanelEditButton"
                        onClick={() => action('edit')}>
                        Редактировать
                    </div>
                    <div className="lessonQuestion__editModePanelMenu">
                        <div
                            className="lessonQuestion__editModePanelMenuButtons"
                            onClick={() => action('delete')}>
                            <TrashIcon />
                        </div>

                        {/*<div
                            className="lessonQuestion__editModePanelMenuButtons"
                            onClick={() => action('')}>
                            <CopyIcon />
                        </div>*/}

                        {!props.isFirst &&
                            <div className="lessonQuestion__editModePanelMenuButtons" onClick={() => action('up')}>
                                <ArrowUpIcon />
                            </div>}

                        {!props.isLast &&
                            <div className="lessonQuestion__editModePanelMenuButtons" onClick={() => action('down')}>
                                <ArrowDownIcon />
                            </div>}
                    </div>
                </div>}

            <div className="lessonQuestion__header">
                <span className="lessonQuestion__number">{props.number}</span>

                <h3 dangerouslySetInnerHTML={{ __html: props.item.condition_exercise }} />
            </div>

            <div className="lessonQuestion__content">
                {returnTemplate(props.item.type_exercise, props.item)}
            </div>

        </div>
    )
}

export default observer(Question);
