import React, { useState, useEffect } from 'react';
import Stores from 'Stores'
import st from './MatchWordsImages.module.scss';

const MatchWordsImages = (props) => {
    const images   = props.cols[0].value.split('|')
    const variants = props.cols[1].value.split('|')
    const [answers, setAnswers] = useState(variants.map((el, idx) => ({ id: idx, value: null, img: images[idx] })))

    const addAnswer = (data) => {
        const index = answers?.findIndex(el => !el.value);
        const newAnswers = [...answers];
        newAnswers[index].value = data;
        setAnswers(newAnswers);
    }

    const deleteAnswer = (id) => {
        const index = answers.findIndex(el => el.id === id);
        const newAnswers = [...answers];
        newAnswers[index].value = null;
        setAnswers(newAnswers);
    }

    useEffect(() => {
        const actualAnswers = answers.filter(el => el.value);
        if (actualAnswers.length !== images.length) {
            props.onComplete([]); return;
        } 
        props.onComplete(answers.map(el => `${el.img}|${el.value}`))
    }, [answers])

    useEffect(() => {
        if (props.answers?.length !== variants.length) return;
        setAnswers(props.answers.map((el, idx) => {
            const [img, value] = el.split("|")
            return { id: idx, value, img };
        }));
    }, [props.answers])
    return (
        <>
            <div className={st.variants}>
                {variants.map((el, idx) => {
                    if(answers.filter(el => el.value).some(item => item.id === idx)) return;
                    return <span onClick={() => addAnswer(el)} key={idx}>{el}</span>;
                })}
            </div>
            <div className={st.images}>
                {images.map((el, idx) => 
                    <div className={st.answer} key={idx}>
                        <img src={Stores.baseLink() + el} alt="icon" />
                        <div onClick={() => answers[idx]?.value && deleteAnswer(idx)}>{answers[idx]?.value || ""}</div>
                    </div>
                )}
            </div>  
        </>
    )
};

export default MatchWordsImages;