import React, {useState, useEffect} from 'react'
import './RadioGroup.scss'

export default function RadioGroup(props) {
    const [list, setlist] = useState([])

    useEffect(() => {
        props.list && setlist(props.list)
    }, [props.list])

    return (
        <div className="radioBox" onChange={(e) => {
            props.backFunction(e)
        }}>
            <ul className={props.line && 'radioBox-ulLine'}>
                {list.map((item, index) =>
                    <li key={index}>
                        <input type="radio"
                               className="radioBox--crb"
                               id={'id_' + index}
                               value={item.value}
                               defaultChecked={item.id === props.checked}
                               name={item.name}/>
                        <label htmlFor={'id_' + index}>
                            {item.label}
                        </label>
                    </li>
                )}
            </ul>
        </div>
    )
}
