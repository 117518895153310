import React, { useEffect, useState } from 'react'
import { superAxiosRequest } from 'axiosApi'
import LocalStorage from 'services/LocalStorage';
import TestRules from "./components/TestRules";
import QuestionsLine from './components/QuestionsLine'
import TestQuestion from './components/TestQuestion/TestQuestion'
import PassingWindow from './components/PassingWindow/PassingWindow'
import Loader from 'components/ContentBox/Global/Loader/Loader'
import TestInfo from "./components/TestInfo";
import PrevNextButtons from "./components/PrevNextButtons";
import TestResult from './components/TestResult/TestResult';
import st from './InitTest.module.scss'

export default function InitTest(props) {
  const initState = {
    focus_live_during: 0,
    focus_live_count: 0,
    mouse_freeze_during: 0,
    mouse_freeze_count: 0,
  }

  const [testInfo, setTestInfo] = useState([false, []])
  const [initTest, setInitTest] = useState([false, []])
  const [question, setQuestion] = useState([0, []])
  const [answer, setAnswer] = useState([0, []])
  const [userAnswers, setUserAnswers] = useState([])
  const [result, setResult] = useState(false);
  const [advQ, setAdvQ] = useState(null)
  const [advAll, setAdvAll] = useState(initState)
  const [loader, setLoader] = useState(false)
  const [transition, setTransition] = useState({ status: false, text: '' });

  const PID = +props.match.params.id
  let apiConf, body;

  const clearTransition = () => setTransition({ status: false, text: '' });

  const getTime = (date = new Date()) => date.getTime();

  const beginTest = () => {
    if (result) setResult(false);

    apiConf = {
      method: 'post',
      link: 'study/student_init_test'
    }
    body = {
      purposed_test: PID
    }
    superAxiosRequest(apiConf, body).then(res => {
      setInitTest([true, res.data])
      
      const arr = res.data.questions.map(id => ({ id }));
      arr[0] = { ...arr[0], question_time: getTime() }
      setQuestion([res.data.questions[0], []])
      setUserAnswers(arr)
      setAdvAll(initState)
    }).catch(() => { })
  }

  const getAnswer = (id, answers, files) => {
    if (files)
      setAnswer([id, answers, files])
    else
      setAnswer([id, answers])
  }

  const getBack = () => {
    const index = userAnswers.findIndex(a => a.id === question[0]);
    if (index < 1) return
    setTransition({ ...transition, text: 'Переходим к предыдущему вопросу' })
    setQuestion([userAnswers[index - 1].id, []])
  }

  const checkIsTestReady = () => {
    let arr = userAnswers?.filter(x => !x.answers || x.answers.length < 1) || []
    return userAnswers && arr.length < 1
  }

  const sendAnswer = () => {
    if (initTest[0] && checkLeftTime()) {
      getResult(initTest[1].id, 'time_out')
      return
    }
    if (answer[1]?.length > 0) {
      if (!advQ) return
      const c = {
        focus_live_during: advAll.focus_live_during + advQ.focus_live_during,
        focus_live_count: advAll.focus_live_count + advQ.focus_live_count,
        mouse_freeze_during: advAll.mouse_freeze_during + advQ.mouse_freeze_during,
        mouse_freeze_count: advAll.mouse_freeze_count + advQ.mouse_freeze_count,
      }
      setAdvAll({ ...c })
      //let encodedString = btoa(JSON.stringify(c));
      //LocalStorage.set('c', encodedString)

      apiConf = {
        method: 'post',
        link: 'study/answers_student'
      }

      const currentQuestion = userAnswers?.find(q => q.id === answer[0]);
      const diff = new Date(new Date() - new Date(currentQuestion.question_time));

      body = {
        question: answer[0],
        init_test: initTest[1].id,
        ...advQ,
        answers: answer[1],
        ...(answer[2] && { files: answer[2] }),
        question_time: Math.floor(diff.getTime() / 1000),
      }
      superAxiosRequest(apiConf, body).then(() => {
        const arr = [...userAnswers]
        const index = arr.findIndex(item => item.id === answer[0]);

        arr[index].answers = answer[1]
        if (answer[2])
          arr[index].files = answer[2]

        //Найти следующий не отвеченный
        const next = userAnswers.find(x => !x.answers || x.answers.length < 1)

        if(next?.id) {
          const idxNextQuestion = userAnswers.findIndex(q => q.id === next.id);
          arr[idxNextQuestion].question_time = getTime();
        }

        setUserAnswers(arr)

        setAnswer([])
        if (next) {
          setTransition({ ...transition, text: 'Переходим к следующему вопросу' })
          setQuestion([next.id, []])
        } else {
          getResult(initTest[1].id, 'finished')
        }

      }).catch(() => { })
    }
  }

  const removeData = () => {
    LocalStorage.remove('itest')
    LocalStorage.remove('q')
    LocalStorage.remove('arra')
    LocalStorage.remove('c')
  }

  const getResult = (init_test, status) => {
    if (result) return
    setTransition({ status: true, text: 'Готовим страницу результатов' });
    setTimeout(() => {
      superAxiosRequest(
        {
          method: 'post',
          link: 'study/student_test_result',
        },
        {
          init_test,
          status
        }
      ).then(res => {
        setResult(res.data)
        clearTransition();
      }).catch(clearTransition)
        .finally(() => {
          removeData();
          clearTransition();
          if (status === 'student_left_test')
            window.close()
        })
    }, 1000)
  }

  const checkLeftTime = () => {
    if (!initTest[0]) return true
    const now = new Date().getTime()
    const start = new Date(initTest[1]?.start_at).getTime()
    const delta = initTest[1].during * 60
    const time_in_test = Math.round((now - start) / 1000)
    return time_in_test > delta
  }

  useEffect(() => {
    if (LocalStorage.get('itest')?.purpose_id !== PID) {
      removeData();
      apiConf = {
        method: 'get',
        link: 'study/purposed_test_info/' + PID,
      }
      superAxiosRequest(apiConf).then(res => {
        setTestInfo([true, res.data])
      })
    }
    else {
      setInitTest([true, LocalStorage.get('itest')])
      setQuestion(LocalStorage.get('q'))
      setUserAnswers(LocalStorage.get('arra'))
      setAdvQ(initState);
      setAdvAll(atob(LocalStorage.get('c')))
    }
  }, [])

  useEffect(() => {
    if (initTest[0] && initTest[1].purpose_id !== PID) {
      removeData();
    }
  }, [initTest, PID])

  useEffect(() => {
    if (question[0] > 0 && question[1].length === 0) {
      setTransition({ ...transition, status: true })
      setAdvQ(initState)
      apiConf = {
        method: 'get',
        link: 'study/question_for_student/' + question[0]
      }
      setTimeout(() => {
        superAxiosRequest(apiConf).then(res => {
          setQuestion([question[0], res.data])
          clearTransition();
        })
      }, 1000)
    }
  }, [question])

  useEffect(() => {
    if (!initTest[0] || advQ === null) return
    if (testInfo[1].focus_live & (advAll.focus_live_count >= testInfo[1].focus_live_count && testInfo[1].focus_live_count > 0)
      || (testInfo[1].mouse_freeze && advAll.mouse_freeze_count >= testInfo[1].mouse_freeze_count && testInfo[1].mouse_freeze_count > 0)) {
      getResult(initTest[1].id, 'auto_proctor')
    }
  }, [advAll])

  useEffect(() => {
    if (initTest[0] && checkLeftTime())
      getResult(initTest[1].id, 'time_out')
  }, [answer])

  useEffect(() => {
    LocalStorage.set('itest', initTest[1])
    LocalStorage.set('q', question)
    LocalStorage.set('arra', userAnswers)
    LocalStorage.set('c', btoa(JSON.stringify(advAll)))
  }, [userAnswers, initTest, question, advAll])

  const isAgain = testInfo[1].try_count - testInfo[1].attempts_count

  return (
    result ?
      <TestResult {...result}
        beginTest={beginTest}
        isBigResult={testInfo[1].type_show_test}
        isAgain={isAgain - 1 > 0} />
      :
      <div className={st.wrap}>
        <div className={st.container}>{!initTest[0] && testInfo[0] ?
          <TestRules testInfo={testInfo[1]} beginTest={beginTest} />
          :
          initTest[0] ?
            <>
              <TestInfo initTest={initTest[1]} getResult={getResult} />
              <PassingWindow rules={testInfo[1]}
                qid={question[0]}
                setAdditioonals={setAdvQ}>

                <QuestionsLine questions={initTest[1].questions}
                  userAnswers={userAnswers}
                  question={question} />

                {question[0] > 0 &&
                  <div className={st.question}>
                    {transition.status
                      ? (
                        <div className={st.delay}>
                          <Loader />
                          <p>{transition.text}</p>
                        </div>
                      )
                      : <TestQuestion
                        answer={userAnswers?.find(a => a.id === question[0])}
                        question={question}
                        getAnswer={getAnswer}
                        loader={loader || question[1].length === 0}
                      />}

                    <PrevNextButtons isFirst={initTest[1].questions.findIndex(q => q === question[0]) === 0}
                      getBack={getBack}
                      answer={answer}
                      checkIsTestReady={checkIsTestReady}
                      sendAnswer={sendAnswer} />
                  </div>}
              </PassingWindow>
            </>
            :
            <Loader />
        }
        </div>
      </div>
  )
}