import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Flex from 'components/UI/Flex/Flex';
import Tab from 'components/UI/Tab/Tab';
import Hr from 'components/UI/Hr/Hr';
import Button from 'components/UI/Button/Button';
import ModalWrapper from 'components/UI/ModalWrapper/ModalWrapper';
import { superAxiosRequest } from 'axiosApi';

const notifies = [
  {id: 0, value: 'email', text: 'E-mail'},
  {id: 1, value: 'sms', text: 'SMS'},
];

export default function ClientNotify({slotClientIDs, onClose}) {
  const [activeNotifyType, setActiveNotifyType] = useState(0);

  const handleSubmit = () => {
    onClose();
    const data = {
      slot_client: slotClientIDs,
      target: activeNotifyType === 0 ? 'email' : 'phone',
    };
    superAxiosRequest({
      method: 'put',
      link: 'online_schedule/resend_notification',
    }, data)
      .then(() => swal('', 'Сообщение успешно отправлено', 'success'))
      .catch(() => swal('', 'Ошибка при отправке сообщения', 'warning'));
  };

  return (
    <ModalWrapper
      title="Отправка напоминания о записи"
      onClose={() => onClose()}
    >
      <Flex tag='ul'>
        {notifies.map(({id, text, value}) => (
          <Tab
            key={id.toString()}
            id={id}
            isActive={activeNotifyType === id}
            text={text}
            value={value}
            onClick={() => setActiveNotifyType(id)}
          />
        ))}
      </Flex>
      <Hr withTopMargin={false} />
      <Flex withGap justify='end' md='center'>
        <Button variant='white' onClick={() => onClose()}>Отменить</Button>
        <Button onClick={handleSubmit}>Отправить</Button>
      </Flex>
    </ModalWrapper>
  );
}

ClientNotify.propTypes = {
  slotClientIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
};

