import React from 'react';
import styles from "./Test.module.scss";
import {Link} from "react-router-dom";
import {SuperButton} from "components/UI/SuperButton/SuperButton";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";

const Test = (props) => {
  const {test, translate} = props;

  const menu = [
    {name: translate.edit, action: 'edit'},
    {name: translate.delete, action: 'delete'},
  ]
  const action = (actionType) => {
    switch (actionType) {
      case 'delete':
        break;
      case 'edit':
        break;
      default:
        break;
    }
  }

  return (
    <li className={styles.card}>
      <div className={styles.item}>
        <div className={styles.info}>
          <div className={styles.level}>
            {(test?.study_test?.sub_lvl?.lvl?.name || test?.study_test?.sub_lvl?.name) &&
              `${test?.study_test?.sub_lvl?.lvl?.name || ""} ${
                test?.study_test?.sub_lvl?.lvl?.name && test?.study_test?.sub_lvl?.name ? "|" : ""
              } ${test?.study_test?.sub_lvl?.name || ""}`}
          </div>
          <div className={styles.testLink}>
            <Link to={`/study/my-test/${test.id}`} className={styles.title}>
              {test.name}
            </Link>
          </div>
        </div>

        <div className={styles.questionBlock}>
          <div className={styles.question}>
            {translate.questions}: {test.study_test.quantity_questions}
          </div>
          <div className={styles.goToLinkBlock}>
            {test.seconds_until_finish > 0 ? (
              <>
                <SuperButton variant={"link"} type={"linkColored"} path={`/study/my-test/${test.id}`}>
                  {translate.testing_link}
                </SuperButton>
                {!Stores.isClient &&
                  <ActionMenu menu={menu} action={action}/>
                }
              </>
            ) : (
              <SuperButton variant={"link"} path={`/study/my-test/${test.id}`}>
                {translate.history_link}
              </SuperButton>
            )}
          </div>
        </div>

        <div className={styles.data}>
          <div className={styles.start}>
            <strong>{test.start_at}</strong>
            <span>,{" "} {dateFormat(test.date_from, "dd MMM YYYY")}</span>
          </div>
          <div className={styles.finish}>
            <strong>{test.finish_at}</strong>
            <span>,{" "} {dateFormat(test.date_until, "dd MMM YYYY")}</span>
          </div>
        </div>
        <div className={styles.status}>
          {test.seconds_until_finish > 0 ? (
            <SuperButton variant={"badge"} type={"badgeColored"}>
              {translate.in_progress_badge}
            </SuperButton>
          ) : (
            <SuperButton variant={"badge"}>
              {translate.finished_badge}
            </SuperButton>
          )}
        </div>

        <div className={styles.dataStatusBlock}>
          <div className={styles.dataTest}>
            <div className={styles.startTest}>
              <strong>{test.start_at}</strong>,{" "}
              {dateFormat(test.date_from, "dd MMM YYYY")}
            </div>
            <div className={styles.finishTest}>
              <strong>{test.finish_at}</strong>,{" "}
              {dateFormat(test.date_until, "dd MMM YYYY")}
            </div>
          </div>
          <div className={styles.statusTest}>
            {test.seconds_until_finish > 0 ? (
              <SuperButton variant={"badge"} type={"badgeColored"}>
                {translate.in_progress_badge}
              </SuperButton>
            ) : (
              <SuperButton variant={"badge"}>
                {translate.finished_badge}
              </SuperButton>
            )}
          </div>
        </div>

        <div className={styles.goToLink}>
          {test.seconds_until_finish > 0 ? (
            <>
              <SuperButton variant={"link"} type={"linkColored"} path={`/study/my-test/${test.id}`}>
                {translate.testing_link}
              </SuperButton>
              {!Stores.isClient &&
                <ActionMenu menu={menu} action={action}/>
              }
            </>
          ) : (
            <SuperButton variant={"link"} path={`/study/my-test/${test.id}`}>
              {translate.history_link}
            </SuperButton>
          )}
        </div>
      </div>
    </li>
  );
};

export default Test;