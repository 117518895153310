import React, { useEffect, useState } from 'react';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import DateTime from 'components/ContentBox/Global/DateTime/DateTime';
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";

export default function OneShift(props) {
    const days = [
        { id: 8, name: 'ПН-ПТ' },
        { id: 9, name: 'ПН-СБ' },
        { id: 1, name: 'Понедельник' },
        { id: 2, name: 'Вторник' },
        { id: 3, name: 'Среда' },
        { id: 4, name: 'Четверг' },
        { id: 5, name: 'Пятница' },
        { id: 6, name: 'Суббота' },
        { id: 7, name: 'Воскресенье' },
    ]

    const [values, setValues] = useState(props.values)

    const returnValue = key => props.values[key] ? props.values[key] : key === 'date_until' ? null : ''

    const objectChangeHandler = (value, key) => setValues({ ...values, [key]: value })

    useEffect(() => {
        setValues(props.values)
    }, [props.values])

    useEffect(() => {
        props.updateList(values)
    }, [values])

    return (
        <>
            <div className='workSchedule-box__cardRow workSchedule-box__cardRow--three'>
                <div>
                    <div className="workSchedule-box__label">Период графика:</div>
                    <div>
                        <DataPickerSplit returnValue={returnValue}
                                         objectChangeHandler={objectChangeHandler}
                                         minDate={props.edit ? new Date() : false}
                        />
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Дни недели:</div>
                    <div>
                        <SelectCustom
                            placeholder="Выберите день"
                            options={days}
                            valueID={props.values.loop_type}
                            onChange={o => objectChangeHandler(o.id, 'loop_type')}
                        />
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Время работы:</div>
                    <div>
                        <DateTime laceholder='9:00'
                                  errorCheker={() => { }}
                                  start_at={values.start_at}
                                  finish_at={values.finish_at}
                                  cap1='Приход'
                                  cap2='Уход'
                                  objectChangeHandler={objectChangeHandler}
                                  returnValue={returnValue} />
                    </div>
                </div>
            </div>
            <div className="workSchedule-box__cardRow workSchedule-box__cardRow--two">
                <div>
                    <div className="workSchedule-box__label">Перерыв, мин:</div>
                    <div>
                        <DummyCounter name="lunch" value={values.lunch} onChange={objectChangeHandler} max={120}
                                      step={1}/>
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Повторять каждую неделю:</div>
                    <div>
                        <DummyCounter name="period" value={values.period} onChange={objectChangeHandler} max={4}
                                      step={1}/>
                    </div>
                </div>
            </div>
        </>
    )
}
