import React from 'react';
import DatePicker from "react-datepicker";
import {observer} from "mobx-react";
import {dateFormat} from "helpers/date";
import modalStore from "Stores/modalStore";
import TypeSelector from "../GlobalModalSingle/templates/WorkPlain/TypeSelector";
import DummyCounter from "../Counter/DummyCounter";

const ModalCorrectVacationDays = () => {
    const types = [
        {id: 2, name: 'Списание'},
        {id: 1, name: 'Добавление'},
    ]

    const {modalValue, changeValues, check} = modalStore

    return (
        <div className="super-modal__form-column">
            <div>
                <TypeSelector values={types}
                              like_obj
                              name="type"
                              selected={modalValue.type}
                              objectChangeHandler={(val, key) => {
                                  changeValues(val, key)
                                  // Корректировка количества дней
                                  changeValues(Math.abs(modalValue.correction) * (val === 1 ? 1 : -1), 'correction')
                              }}/>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">Количество дней:</div>
                    <div>
                        <DummyCounter value={Math.abs(modalValue.correction)}
                                      onChange={val => {
                                          changeValues(modalValue.type === 1 ? val : -val, 'correction')
                                      }}
                                      min={0}
                                      step={.5}
                        />
                    </div>
                </div>
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">Дата:</div>
                    <div className="super-modal__date-picker">
                        <DatePicker selected={new Date(modalValue.date)} dateFormat="dd.MM.yyyy" onChange={date => changeValues(dateFormat(date, 'YYYY-mm-dd'), 'date')} />
                    </div>
                </div>
            </div>
            <div>
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label super-modal__form-label--required">Комментарий:</div>
                    <div>
                        <textarea value={modalValue.comment || ''}
                                  onChange={e => changeValues(e.target.value, 'comment')}
                                  className={check('comment') ? "super-modal__input--error" : undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ModalCorrectVacationDays)
