import React from 'react';
import styles from './SearchList.module.scss';
import { ReactComponent as Arrow } from 'components/HeadBox/img/arrow.svg';
import { ReactComponent as Box } from 'components/HeadBox/components/Search/img/box.svg';
import { Link } from 'react-router-dom';
import { LanguageContext } from 'components/HeadBox/HeadBox';
import { useContext } from 'react';
import Stores from 'Stores';

const SearchList = ({ className, list = [], link }) => {
    const { translate } = useContext(LanguageContext);
    return (
        <div className={`${styles.searchList} ${className}`}>
            <ul className={styles.searchList__list}>
                {list.map((item) => (
                    <li className={styles.searchList__item} key={item.id}>
                        <div className={styles.searchList__title}>
                            <p>{Stores.transliterate(item.full_name)}</p>
                            <p>{item.date_of_birth}</p>
                        </div>
                        <p className={styles.searchList__description}>
                            {item?.phone?.length > 0 && <span>{item.phone}</span>}
                            {item?.phone?.length > 0 && item?.email?.length > 0 && <span>|</span>}
                            {item?.email?.length > 0 && <span>{item.email}</span>}
                        </p>
                        <Link to={item.link} target="_blank">
                            <Box className={styles.searchList__icon} />
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to={link} target="_blank">
                <button className={styles.searchList__btn}>
                    {translate.view_all} <Arrow />
                </button>
            </Link>
        </div>
    )
};

export default SearchList;