import React, { useContext } from 'react';
import { localisationMaster } from "Localisation/Localisation";
import { ReactComponent as RepeatSVG } from 'components/UI/Calendar/components/CalendarItem/repeat.svg';
import { ReactComponent as CalendarSVG } from 'components/UI/Calendar/components/CalendarItem/calendar.svg';
import { getAdaptedTime } from 'components/Modules/Slots/utils';
import Context from '../../context';
import styles from './CalendarItem.module.scss';

const CalendarItemBody = ({ currentTime, data }) => {
  const { type, timeZone } = useContext(Context);
  const { dayNumber, isToday } = currentTime;
  const {
    // for schedule-settings
    is_available,
    start_at,
    finish_at,
    is_active,
    is_booked,
    repeat,
    test_long,
    // for teacher-schedule
    booked,
    available_slots,
    // record-customer
    slots,
    // booked-slots
    booked_slots,
  } = data;
  const isAvailable = is_available === true || is_available === undefined;

  switch (type) {
    case 'teacher-schedule':
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
          </p>
          <p className={styles.data}>
            { available_slots.length || booked > 0 ?
              <>
                {booked ? <span className={styles.data_booked}>{localisationMaster('booked_slots', 'teacher_schedule')}:<span>{booked}</span></span> : null}
              </>
              : <span>{localisationMaster('booking', 'teacher_schedule')} <span className={styles.data_warning}>{localisationMaster('unavailable', 'teacher_schedule')}</span></span>
            }
          </p>
        </>
      );

    case 'record-customer':
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
          </p>
          <p className={styles.data}>
            <span className={styles.data_booked}>
              {localisationMaster('slots', 'teacher_schedule')}{slots ? <>: <span>{slots}</span></> : ` ${localisationMaster('button_2', 'alert').toLowerCase()}`}
            </span>
          </p>
        </>
      );

    case 'record-online':
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
          </p>
          <p className={styles.data}>
            {localisationMaster('available_booking', 'teacher_schedule')}:
            {is_available ? <span className={styles.data_yes}>{localisationMaster('button_1', 'alert').toUpperCase()}</span> : <span className={styles.data_no}>{localisationMaster('button_2', 'alert').toUpperCase()}</span>}
          </p>
        </>
      );


    case 'booked-slots':
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
          </p>
          <p className={styles.data}>
            {booked_slots ? <span className={styles.data_booked}>{localisationMaster('clients', 'teacher_schedule')}: <span>{booked_slots}</span></span> : localisationMaster('no_entries', 'teacher_schedule')}
          </p>
        </>
      );

    case 'schedule-settings':
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
            {isAvailable && is_active && repeat && <RepeatSVG title={localisationMaster('recurring_schedule', 'teacher_schedule')}/>}
            {isAvailable && is_active && !repeat && <CalendarSVG title={localisationMaster('non_recurring_schedule', 'teacher_schedule')}/>}
          </p>
          {
            isAvailable && is_active &&
            <p className={styles.data}>
              {start_at && finish_at && <span>{`${getAdaptedTime(start_at, timeZone)} - ${getAdaptedTime(finish_at, timeZone)}`}</span>}
              {test_long && <span>{`${test_long} мин.`}</span>}
              <span>{localisationMaster('armor', 'teacher_schedule')}: {is_booked ? localisationMaster('there_is', 'teacher_schedule') : localisationMaster('button_2', 'alert').toLowerCase()}</span>
            </p>
          }
        </>
      );

    default:
      return (
        <>
          <p className={styles.day_header}>
            <span className={styles.day_number}>
              {dayNumber}
              {isToday && <span>, {localisationMaster('Today', 'online-schedule/time-slots')}</span>}
            </span>
          </p>
          <p className={styles.data}>
          </p>
        </>
      );
  }
};

export default CalendarItemBody;
