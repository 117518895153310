import React, {useRef, useState} from 'react';
import Stores from 'Stores';
import styles from './QuestionAttention.module.scss'
import QuestionSimpleText from "./QuestionSimpleText";
import HoverPreview from "../components/HoverPreview/HoverPreview";
import {ReactComponent as FullImageIcon} from "components/HeadBox/img/full_image_icon.svg";
import FileSelectModal from "components/UI/FileSelector/components/FileSelectModal";
import {MainExerciseImage} from "../../../Modules/Ebook/Common/MainExerciseImage/MainExerciseImage";


export default function QuestionAttention(props) {
    const [showModal, setShowModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);

    const box = useRef()

    const handleEyeClick = (imageFile) => {
        setShowModal(true);
        setSelectedImage(imageFile);
    };
    return (
        <div className={styles.content}>
            {props.image && <MainExerciseImage image={props.image}/>}
            <QuestionSimpleText text={props.text} />
            {props.images.length > 0 &&
                <div className={styles.questionImagesThumb}>
                    <ul ref={box}>
                        {props.images.map((img, index) =>
                            <li key={index}>
                                <HoverPreview
                                    childrenPhoto={
                                        <div>
                                            <img src={Stores.baseLink() + img.file} alt={img.description}/>
                                        </div>
                                    }
                                    childrenIcon={
                                        <FullImageIcon className={styles.fullImageIcon}
                                                       onClick={() => handleEyeClick(img.file)}/>
                                    }
                                />
                                <div className={styles.description}>{img.description}</div>
                            </li>
                        )}
                    </ul>
                </div>
            }
            {showModal && <FileSelectModal
                img={Stores.baseLink() + selectedImage}
                setOpen={() => setShowModal(false)} />}
        </div>
    )
}
