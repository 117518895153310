import React, {useEffect, useState} from 'react'
import Stores from '../../../../../../../Stores';
import basket from '../../../../../img/deleteNEwColor.svg';
import basketNew from '../../../../../img/deleteIcon.svg';


export default function MultiUploadAudio(props) {
    const [files, setfiles] = useState([])
    const [inputsValues, setinputsValues] = useState({})
    const [hoverDelete, sethoverDelete] = useState(false)


    const addAudio = (e) => {
        let arr = files;
        arr.push(e.target.files[0])
        setfiles([...arr])
    }

    const changeDescription = (e, index) => {
        setinputsValues({...inputsValues, [index]: e.target.value})
    }
    const changeDescriptionUpdate = (e, index, key_name) => {
        let arr = inputsValues;
        arr.items[index][key_name] = e.target.value
        setinputsValues({...arr})
    }


    useEffect(() => {
        let array = []
        let key;
        if (Object.keys(inputsValues).length > 0) {
            for (key in inputsValues) {
                array[key] = inputsValues[key]
            }
        }
        array['files'] = files

        props.arrayChangeHandler(array)
    }, [inputsValues, files])

    useEffect(() => {
        if (props.update && props.inputsValues) {
            let arr = inputsValues;
            arr.items = props.inputsValues.items;
            arr.multiupload = true
            setinputsValues({...arr})
        }
    }, [])

    const deleteOld = id => {
        let arr = inputsValues;
        if (arr.items.length > 1) {
            arr.items = arr.items.filter(x => x.id !== id)
            setinputsValues({...arr})
        }
    }

    const deleteFile = name => {
        let arr = files;
        let arrIv = inputsValues;
        if (arr.length > 1) {
            let mv = arr.filter(x => x.name !== name)
            delete arrIv[name]
            delete arrIv[name + '_script']
            setinputsValues({...arr})
            setfiles([...mv])
        } else {
            setfiles([])
        }
    }

    return (
        <div className="multi_upload_audio super-modal__form-column">
            {files.map((item, index) =>
                <div key={index} className="multi_upload_audio-box">
                    <div className="multi_upload_audio-box--audio">
                        <audio
                            controls
                            src={URL.createObjectURL(item)}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                        <div className="multi_upload_audio-box--audio--fix">
                            <img src={!hoverDelete ? basket : basketNew} alt=""
                                 onClick={() => {
                                     deleteFile(item.name)
                                 }}
                                 onMouseOver={() => sethoverDelete(true)}
                                 onMouseOut={() => sethoverDelete(false)}/>
                        </div>
                    </div>
                    <input placeholder={'Название аудио'} value={inputsValues[item.name]}
                           onChange={(e) => changeDescription(e, item.name)}/>
                    <textarea style={{resize: 'none'}} placeholder={'Скрипт аудио'}
                              value={inputsValues[item.name + '_script']}
                              onChange={(e) => changeDescription(e, item.name + '_script')}/>
                </div>
            )}
            {(props.update && inputsValues.items) &&
            inputsValues.items.map((item, index) => {
                return (
                    <div className="multi_upload_audio-box">
                        <div className="multi_upload_audio-box--audio">
                            <audio
                                controls
                                src={Stores.baseLink() + item.file}>
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                            <div className="multi_upload_audio-box--audio--fix">
                                <img src={basket} onClick={() => {
                                    deleteOld(item.id)
                                }} alt=""/>
                            </div>
                        </div>
                        <input placeholder={'Название аудио'} value={inputsValues.items[index].description}
                               onChange={(e) => changeDescriptionUpdate(e, index, 'description')}/>
                        <textarea style={{resize: 'none'}} placeholder={'Скрипт аудио'}
                                  value={inputsValues.items[index].script}
                                  onChange={(e) => changeDescriptionUpdate(e, index, 'script')}/>
                    </div>
                )
            })
            }
            <input id="file" name="file" type="file"
                   onChange={(e) => addAudio(e)}
            />
            <label className="modal-question-add-button-add" htmlFor="file"> Добавить аудиофайл</label>
            <p className="multi_upload__types">
                {/*Вы можете загрузить изображение в формате JPG, GIF или PNG.*/}
                {props.textInfo}
            </p>
        </div>
    )
}
