import React, {Fragment, useEffect} from 'react';
import {superAxiosRequest} from "axiosApi";
import {splitNumberDigits} from "helpers/math";
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import modalStore from "Stores/modalStore";
import ContentHeader from "../ContentHeader";
import Filter from "../Filter/Filter";
import THead from "./THead";
import Loader from "../Loader/Loader";
import ModalCorrectVacationDays from "./ModalCorrectVacationDays";
import st from "./Table.module.scss"

export default function VacationDays(props) {
    const cfg = {
        cols: [
            {name: 'Месяц', group: true, dataField: 'name', classes: [st.color, st.fw500]},
            {name: 'День', dataField: 'day', align: 'center', classes: [st.fz12, st.color, st.fw500]},
            {
                name: 'Отработано, мин / часов',
                align: 'center',
                classes: [st.fz12],
                formatter: row =>
                    `${formatNumber(row.worked_minutes)} мин. / ${formatTime(row.worked_hours)} ч.`
            },
            {name: 'Изменения', dataField: 'comment', classes: [st.fz12]},
            {
                name: 'Начислено отпускных дней',
                align: 'center',
                formatter: row =>
                    <span className={row.vacation_days >= 0 ? st.tdContentVacPos : st.tdContentVacNeg}>
                        {formatNumber(row.vacation_days, 2)}
                    </span>
            },
        ]
    }

    const {
        refWrapper,
        refSticky,
        refThead,
        filter,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
        getTdClassNames,
    } = useTableFixedHeader()

    const filterCfg = [
        {type: 'month-range-picker', nameState: ['date_from', 'date_until']},
    ]

    const formatNumber = (num, frac) => splitNumberDigits(num.toFixed(frac || 0).replace('.', ','))

    const formatTime = num => splitNumberDigits(num.toFixed(2).replace('.', ':'))

    const openModalCorrect = () => {
        const ms = {
            title: 'Корректировка отпускных дней',
            modalBodyComponent: () => <ModalCorrectVacationDays/>,
            link: 'employees/vacation_munutes_correction/' + props.match.params.id,
            button: 'Сохранить',
            is_put: true,
            data: 'json',
            valid: {
                comment: 'required'
            },
            func: () => setFilter({})
        }
        const md = {
            type: 2,
            correction: -6,
            date: dateFormat(new Date(), 'YYYY-mm-dd')
        }
        modalStore.activity(ms, md)
    }

    useEffect(() => {
        setIsLoading(true)
        setData([])
        const apiConf = {
            method: 'get',
            link: 'employees/report_vacation_time/' + props.match.params.id,
            params: {
                ...(filter.date_from && {date_from: dateFormat(filter.date_from, 'YYYY-mm-dd')}),
                ...(filter.date_until && {date_until: dateFormat(filter.date_until, 'YYYY-mm-dd')}),
            }
        }
        superAxiosRequest(apiConf).then(res => {
            setData(res.data)
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            {isLoading ? <Loader/> : data ?
                <>
            <ContentHeader title={`${data.last_name || ''} ${data?.first_name || ''}: История начисления отпускных дней`}
                           textButton={{text: 'Корректировка', onClick: openModalCorrect}}/>

            <Filter config={filterCfg} drop={() => setFilter({})} getFilterParams={setFilter}/>
            <div className={st.tableWrapper} ref={refWrapper}>
                <div ref={refSticky} className={st.sticky}>
                    <table className={st.table}>
                        <THead cols={cfg.cols}/>
                    </table>
                </div>
                <table className={`${st.table} ${st.tbodyStriped}`}>
                    <THead cols={cfg.cols} ref={refThead}/>
                    <tbody className={st.tbody}>
                    {data.total &&
                    <>
                        <tr className={st.bdt}>
                            <td colSpan={cfg.cols.length - 1} className={st.tdTotal}>ИТОГО, начислено</td>
                            <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                {formatNumber(data.total.days, 2)} д.
                            </td>
                        </tr>
                        <tr className={st.bdt}>
                            <td colSpan={cfg.cols.length - 1} className={st.tdTotal}>ИТОГО, остаток дней</td>
                            <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                {formatNumber(data.total.days_remain, 2)} д.
                            </td>
                        </tr>
                    </>}

                    {Array.isArray(data.months) && data.months.map((row, idxRow) => (
                        <Fragment key={idxRow}>
                            {row.days?.map((subRow, idxSubRow) =>
                                <tr key={`${idxRow}-${idxSubRow}`} className={idxSubRow === 0 ? st.bdt : undefined}>
                                    {cfg.cols.map((col, idxCol) =>
                                        col.group ?
                                            idxSubRow === 0 &&
                                            <td key={idxCol} rowSpan={row.days.length + 1}
                                                className={getTdClassNames(col)}>
                                                {typeof col.formatter === 'function' ? col.formatter(row) : row[col.dataField]}
                                            </td>
                                            :
                                            <td key={idxCol} className={getTdClassNames(col)}>
                                                {typeof col.formatter === 'function' ? col.formatter(subRow) : subRow[col.dataField]}
                                            </td>
                                    )}
                                </tr>
                            )}
                            <tr>
                                <td colSpan={cfg.cols.length - 2} className={st.tdTotal}>Итог: {row.name}</td>
                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                    {formatNumber(row.total.days, 2)} д.
                                </td>
                            </tr>
                        </Fragment>
                    ))}

                    {data.total &&
                    <>
                        <tr className={st.bdt}>
                            <td colSpan={cfg.cols.length - 1} className={st.tdTotal}>ИТОГО, начислено</td>
                            <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                {formatNumber(data.total.days, 2)} д.
                            </td>
                        </tr>
                        <tr className={st.bdt}>
                            <td colSpan={cfg.cols.length - 1} className={st.tdTotal}>ИТОГО, остаток дней</td>
                            <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                {formatNumber(data.total.days_remain, 2)} д.
                            </td>
                        </tr>
                    </>}
                    </tbody>
                </table>
            </div>
                </>
                : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </>
    );
};

