import React, { useEffect, useRef, useState } from "react";
import { superAxiosRequest } from "axiosApi";
import { localisationMaster } from "Localisation/Localisation";
import Select from "../Select/Select";
import SelectCustom from "../Select/SelectCustom";
import TypeSelector from "../GlobalModalSingle/templates/WorkPlain/TypeSelector";
import DatePicker from "react-datepicker";
import listingStore from "Stores/listingStore";
import LocalStorage from "services/LocalStorage";
import "./Filter.scss";
import { translate as loc, dict } from "Localisation";
import MultiSelect from "../Idlist/MultiSelect";
import Checkbox from "../Checkbox/Checkbox";
import DateTime from "../DateTime/DateTime";


export default function Filter(props) {
  const dataArr = [
    { id: 1, name: "Прошли тест сегодня" },
    { id: 2, name: "Прошли тест вчера" },
    { id: 3, name: "Прошли тест позавчера" },
    { id: 4, name: "Прошли тест раньше" },
  ];
  const subLevels = [
    false,
    [
      {
        id: 0,
        name: localisationMaster("table-row-title-6", "tests/past-tests"),
      },
    ],
  ];
  const professions = [
    false,
    [{ id: 0, name: "Сначала выберите департамент" }],
  ];

  const [inputsValues, setInputsValues] = useState({});
  const [levels, setLevels] = useState([false, []]);
  const [employment, setEmployment] = useState([false, []]);
  const [thisPage, setThisPage] = useState(null);
  const [open, setOpen] = useState(props.openFilter === true);
  const [loadLevel, setLoadLevel] = useState(false);
  const [loadEmployment, setLoadEmployment] = useState(false);
  const [level_id, setLevel_id] = useState([false, 0]);
  const [employment_id, setEmployment_id] = useState([false, 0]);

  const dropFilter = () => {
    setInputsValues([])
    props.drop()
  }

  const getFilterArr = () => {
    const selectArr = [];
    const data = LocalStorage.get('ud');
    const lang = (data?.user_data.profile.localization || "en").toLowerCase();

    props.config.forEach((item, index) => {
      switch (item.type) {
        case "select":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                apiLink={item.apiLink}
                apiGetResult={
                  item.key_name ? (data) => data[item.key_name].items : null
                }
                valueID={returnValue(item.nameState)}
                onChange={(o) =>
                  changeHandler({ target: o }, item.nameState, item.subMenu)
                }
                isSearchable
              />
            </div>
          );
          break;
        case "selectStatus":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                apiLink={item.apiLink}
                optionValueKey={item.new_value}
                apiGetResult={
                  item.key_name ? (data) => data[item.key_name].items : null
                }
                valueID={returnValue(item.nameState)}
                onChange={(o) =>
                  changeHandler({ target: o }, item.nameState, item.subMenu)
                }
                isSearchable
              />
            </div>
          );
          break;
        case "selectLvl":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                apiLink={item.apiLink}
                valueID={returnValue(item.nameState)}
                onChange={(o) =>
                  changeLevel({ target: o }, item.nameState, item.subMenu)
                }
                isSearchable
              />
            </div>
          );
          break;
        case "selectSubLvl":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                options={getSubLevel()}
                noOptionsMessage={() => "Сначала выберите уровень"}
                valueID={returnValue(item.nameState, 1)}
                onChange={(o) =>
                  changeHandler({ target: o }, item.nameState, item.subMenu)
                }
                error={props.program_error}
              />
            </div>
          );
          break;
        case "selectEmplouee":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                apiLink={item.apiLink}
                optionValueKey={item.new_value}
                apiGetResult={item.key_name ? (data) => data : null}
                valueID={returnValue(item.nameState)}
                onChange={(o) =>
                  chengeEmplouee({ target: o }, item.nameState, item.subMenu)
                }
                isSearchable
              />
            </div>
          );
          break;
        case "multi-select":
          selectArr.push(
              <div className="filter-box__multi_select" key={index}>
                   <MultiSelect options={item.values? item.values :[]}
                                     sortKey={item.sort_key}
                                     values={inputsValues[item.nameState]?inputsValues[item.nameState]: [] }
                                     onChange={ (o) => changeHandler({target:{value:o}}, item.nameState) } />
            </div>
          );
          break;
        case "selectProfessions":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <SelectCustom
                placeholder={item.text}
                options={getProfessions()}
                noOptionsMessage={() => "Сначала выберите департамент"}
                valueID={returnValue(item.nameState, 1)}
                onChange={(o) =>
                  changeHandler({ target: o }, item.nameState, item.subMenu)
                }
                error={props.program_error}
              />
            </div>
          );
          break;
        case "input":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <input
                type="text"
                placeholder={item.placeholder}
                value={returnValue(item.nameState)}
                onChange={(e) => changeHandler(e, item.nameState, item.subMenu)}
                className="filter-box__input"
              />
            </div>
          );
          break;
        case "selectData":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <Select
                onChange={props.ChangeError}
                value_error={props.program_error}
                valueId={returnValue(item.nameState, 1)}
                nameState={item.nameState}
                selected={0}
                stateError={"selectValue_error"}
                myChangeHandler={(e) =>
                  changeHandler(e, item.nameState, item.subMenu)
                }
                text={item.text}
                value={dataArr}
                s_value={returnValue(item.nameState, 1)}
                ChangeError={props.ChangeError}
                width="100%"
              />
            </div>
          );
          break;
        case "select-ready-data":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <Select
                onChange={props.ChangeError}
                value_error={props.program_error}
                valueId={returnValue(item.nameState, 1)}
                nameState={item.nameState}
                selected={0}
                stateError={"selectValue_error"}
                myChangeHandler={(e) =>
                  changeHandler(e, item.nameState, item.subMenu)
                }
                text={item.text}
                value={item.values}
                newSort_key={item.sort_key ? item.sort_key : "name"}
                s_value={returnValue(item.nameState, 1)}
                ChangeError={props.ChangeError}
                new_key={item.new_key}
                width="100%"
              />
            </div>
          );
          break;
        case "select-custom":
          selectArr.push(
            <div key={index} className="filter-box__field">
              <SelectCustom
                placeholder={item.placeholder}
                options={item.values}
                optionLabelKey={item.option_label_key}
                optionValueKey={item.option_value_key}
                onChange={o =>
                  item.is_multi ?
                    changeHandler(
                      {target: {value: o.map(item => item.value)}},
                      item.nameState,
                      item.subMenu
                    ) :
                    changeHandler(
                      {target: {value: o.value}},
                      item.nameState,
                      item.subMenu
                    )
                }
                apiLink={item.apiLink}
                apiGetResult={item.apiGetResult}
                sortKey={item.sort_key}
                valueID={item.is_multi ? (inputsValues[item.nameState] || []) : returnValue(item.nameState, 1)}
                isSearchable={item.is_search}
                isMulti={item.is_multi}
              />
            </div>
          )
          break;
        case "select-bound":
          selectArr.push(
              <div key={index} className="filter-box__field">
              <SelectCustom
                placeholder={item.placeholder}
                      options={inputsValues[item.bindingToState] ? item.values.find(itemArray => itemArray.id === inputsValues[item.bindingToState])[item.bindingKey] :
                          [{id: 1, name: item.noOptionsMessage}]}
                optionLabelKey={item.option_label_key}
                optionValueKey={item.option_value_key}
                onChange={(o) =>
                  changeHandler(
                    { target: { value: o.value } },
                    item.nameState,
                    item.subMenu
                  )
                }
                apiLink={item.apiLink}
                apiGetResult={item.apiGetResult}
                sortKey={item.sort_key}
                valueID={inputsValues[item.bindingToState] ? returnValue(item.nameState, 1): 1}
                isSearchable={item.is_search}
                isDisabled = {!inputsValues[item.bindingToState]}
              />
            </div>
            
          );
          break;
        case "type-selector":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <TypeSelector
                values={item.values}
                like_obj={true}
                objectChangeHandler={objectChangeHandler}
                selected={returnValue(item.nameState)}
                name={item.nameState}
                //width={'240px'}
              />
            </div>
          );
          break;
        case "month-range-picker":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <div className="filter-box__month-range-picker react-datepicker-custom">
                <div>
                  <DatePicker
                    selected={inputsValues[item.nameState[0]]}
                    onChange={(date) =>
                      objectChangeHandler(date, item.nameState[0])
                    }
                    dateFormat="MMM, yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    locale={lang}
                    maxDate={inputsValues[item.nameState[1]]}
                  />
                </div>
                <div>{loc(dict.main_page.text_51)}</div>
                <div>
                  <DatePicker
                    selected={inputsValues[item.nameState[1]]}
                    onChange={(date) =>
                      objectChangeHandler(date, item.nameState[1])
                    }
                    dateFormat="MMM, yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    locale={lang}
                    minDate={inputsValues[item.nameState[0]]}
                  />
                </div>
              </div>
            </div>
          );
          break;
        case "days-range-picker":
          selectArr.push(
            <div className="filter-box__field" key={index}>
              <div className="filter-box__month-range-picker react-datepicker-custom">
                <div>
                  <DatePicker
                    selected={inputsValues[item.nameState[0]] || item?.defaultDate?.after}
                    onChange={(date) =>
                      objectChangeHandler(date, item.nameState[0])
                    }
                    dateFormat={item.format}
                    locale={lang}
                    maxDate={inputsValues[item.nameState[1]]}
                  />
                </div>
                <div>{loc(dict.main_page.text_51)}</div>
                <div>
                  <DatePicker
                    selected={inputsValues[item.nameState[1]] || item?.defaultDate?.before}
                    onChange={(date) =>
                      objectChangeHandler(date, item.nameState[1])
                    }
                    dateFormat={item.format}
                    locale={lang}
                    minDate={inputsValues[item.nameState[0]]}
                  />
                </div>
              </div>
            </div>
          );
          break;
        case "times-range-picker":
          console.log(item)
          selectArr.push(
              <div className="filter-box__field" key={index}>
                <div className="filter-box__month-range-picker react-datepicker-custom">
                  <DateTime placeholder='9:00'
                            errorCheker={() => {
                            }}
                            start_at={inputsValues[item.nameState[0]]}
                            finish_at={inputsValues[item.nameState[1]]}
                            objectChangeHandler={objectChangeHandler}/>
                </div>
              </div>
          );
          break;
        case "checkbox":
          selectArr.push(
              <div key={index} className="filter-box__field filter-box__field--checkbox">
                <Checkbox
                    label={item.label}
                    checked={inputsValues[item.name]}
                    name={item.name}
                    onChange={() => objectChangeHandler(!inputsValues[item.name], item.name)}
                />
              </div>
          );
          break;
        default:
          break;
      }
    });

    return selectArr;
  };

  const changeHandler = (e, key_name, subMenu_key) => {
    setInputsValues({
      ...inputsValues,
      [key_name]: e.target.value,
      ...(subMenu_key !== undefined && {[subMenu_key]: props.subMenu}),
      //is_active: true,
    });
  }

  const objectChangeHandler = (value, key_name, subMenu_key) => {
    if (subMenu_key !== undefined) {
      setInputsValues({
        ...inputsValues,
        [key_name]: value,
        [subMenu_key]: props.subMenu,
        is_active: true,
      });
    } else {
      setInputsValues({ ...inputsValues, [key_name]: value });
    }
  };

  const returnValue = (key_name, int) => {
    if (
      inputsValues !== null &&
      inputsValues !== undefined &&
      inputsValues.length !== 0 &&
      inputsValues[key_name] !== undefined
    ) {
      return inputsValues[key_name];
    } else {
      if (!int) {
        return "";
      } else {
        return 0;
      }
    }
  };

  const getFilterValue = (link) => {
    if (props.getFilterParams) {
      props.getFilterParams(inputsValues);
      return;
    }

    let paramsValue = inputsValues;
    let params = props.params;
    if (params) paramsValue = Object.assign(params, inputsValues);
    for (let key in paramsValue) {
      if (typeof paramsValue[key] === "string")
        paramsValue[key] = paramsValue[key].trim();
    }
    if (props.setPage) props.setPage(1);
    listingStore.toFilter = paramsValue;
    let apiConf = {
      method: "get",
      params: paramsValue,
      link: link,
    };
    listingStore.setFilterConf(inputsValues);
    // TODO: какой Store заполнять?
    listingStore.fillData(apiConf);
    //coursesStore.fillData(apiConf)
    //if (props.fillData) props.fillData(apiConf)

    /*superAxiosRequest(apiConf).then(res => {
                if (res)
                    setDisabled(false)
                if (props.surfCouner) props.surfCouner(res.data._meta.group_statuses)
                //CLEANTHIS
                props.changeItems(res.data.result, res.data._meta.count_page, res.data._meta.count_items, paramsValue, inputsValues)
                return res.data.result
            }
        )*/
  };

  const getSubLevel = () => {
    if (level_id[1] > 0) {
      let sub_levels = levels[1].filter((x) => x["id"] === +level_id[1]);
      return sub_levels[0].sub_lvl;
    } else {
      return subLevels[1];
    }
  };

  const changeLevel = (e, item_nameState) => {
    setLevel_id([true, e.target.value]);
    changeHandler(e, item_nameState);
  };

  const getProfessions = () => {
    if (employment_id[1] > 0) {
      let professions = employment[1].filter(
        (x) => x["id"] === +employment_id[1]
      );
      return professions[0].professions;
    } else {
      return professions[1];
    }
  };

    const chengeEmplouee = (e, item_nameState) => {
    setEmployment_id([true, e.target.value]);
    changeHandler(e, item_nameState);
  };

  const openFilter = () => {
    setOpen(!open);
    setLoadLevel(!loadLevel);
    setLoadEmployment(!loadEmployment);
  };

  useEffect(() => {
    if (props.thisPage !== thisPage) {
      setInputsValues([]);
      dropFilter();
      setThisPage(props.thisPage);
    }
    if (!levels[0] && loadLevel === true) {
      let apiConf = {
        method: "get",
        link: "study/levels",
      };
      superAxiosRequest(apiConf).then((response) => {
        setLevels([true, response.data.result]);
      });
      //     .catch(response=>{
      //     setLoadLevel(!loadLevel)
      // })
    } else {
    }
    if (!employment[0] && loadEmployment === true) {
      let apiConf = {
        method: "get",
        link: "accounts/employment_types_catalog",
      };
      superAxiosRequest(apiConf).then((response) => {
        setEmployment([true, response.data]);
      });
      //     .catch(response=>{
      //     setLoadLevel(!loadLevel)
      // })
    } else {
    }
  })

  useEffect(() => {
    refSlider.current.style.height = open
      ? refSlide.current.offsetHeight + "px"
      : "";
  }, [open, inputsValues]);

  useEffect(() => {
    props.config.forEach(item => {
      if (item.data) {
        setInputsValues({ ...inputsValues, ...item.data })
      }
    })
    return () => {
      listingStore.toFilter = false;
      listingStore.setFilterConf([]);
    };
  }, []);

  const refSlider = useRef();
  const refSlide = useRef();

  return (
    <div className="filter-box">
      <div className="filter-box__header" onClick={() => openFilter()}>
        <span className="filter-box__header-title">
          {localisationMaster("text-4", "filter")}
        </span>
        <span className="filter-box__toggle-button">
          {!open
            ? localisationMaster("text-5", "filter")
            : localisationMaster("text-6", "filter")}
        </span>
      </div>
      <div className="filter-box__slider" ref={refSlider}>
        <div ref={refSlide} className="filter-box__slide">
          <div className="filter-box__fields">{getFilterArr()}</div>
          <div className="filter-box__buttons">
            <div>
              <button
                className="filter-box__apply-button"
                onClick={() => getFilterValue(props.link)}
              >
                {localisationMaster("text-7", "filter")}
              </button>
            </div>
            <span className="filter-box__reset-button" onClick={dropFilter}>
              {localisationMaster("text-10", "filter")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
