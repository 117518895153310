import React from 'react';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import TimeTabs from 'components/Modules/Slots/ScheduleSettings/components/TimeTabs/TimeTabs';
import Flex from 'components/UI/Flex/Flex';

function Duration() {
  return (
    <Flex>
      <SectionTitle
        tag='h3'
        secondary
        title='Длительность'
        description='Не более 480 мин. (8 ч., кратно 15 мин.)'
      />
      <TimeTabs />
    </Flex>
  );
}

export default Duration;

