import React, {useEffect, useState} from 'react';
import { superAxiosRequest } from 'axiosApi';
import CourseDocs from './CourseDocs';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import BlueLine from '../Global/BlueLine/BlueLine';
import SharedLessonCard from "./SharedLessonCard";
import './Course.scss';

const SharedCourse = props => {
    const [sharedCourse, setSharedCourse] = useState()
    const [selected, setSelected] = useState([])

    const selectLesson = (val, id) => {
        setSelected(val ? [...selected, id] : selected.filter(x => x !== id))
    }

    const acceptCourse = () => {
        const apiConf = {
            method: 'put',
            link: 'lessons/share_course/' + props.match.params.id
        }
        const body = {
            lessons: selected
        }
        superAxiosRequest(apiConf, body).then(res => {
            window.location.href = '/lessons/course/' + res.data.course_id
        }).catch()
    }

    useEffect(() => {
        const apiConf = {
            method: 'get',
            link: 'lessons/share_course/' + props.match.params.id
        }
        superAxiosRequest(apiConf).then(res => {
            setSharedCourse(res.data)
            setSelected(res.data.course.lessons.map(x => x.id))
        }).catch()
    }, [])

    return (
        <>
            {sharedCourse &&
            <>
                <ContentHeader title={sharedCourse.course && `${sharedCourse.course.name} (${sharedCourse.course.course_level}, ${sharedCourse.course.skill_level})`}/>
                <div className="coursePage">

                    <CourseDocs
                        //id={+props.match.params.id}
                        items={sharedCourse.course.docs || []}
                        //drop={() => CourseStore.fetchCourse(+props.match.params.id)}
                    />

                    {sharedCourse.course.lessons &&
                    <div className="coursePage__blueLine">
                        <BlueLine items={sharedCourse.course.lessons.length || 0}/>
                    </div>}

                    <button className="coursePage__addLessonButton" onClick={acceptCourse}>
                        Сохранить курс с выбранными уроками
                    </button>

                    {sharedCourse.course.lessons &&
                    <>
                        <div className="coursePage__lessons">
                            {sharedCourse.course.lessons
                                .sort((a, b) => a.position > b.position ? 1 : -1)
                                .map(item =>
                                    <SharedLessonCard key={item.id} lesson={item} check={selected.includes(item.id)}
                                                      onChange={val => selectLesson(val, item.id)}/>
                                )}
                        </div>

                        {sharedCourse.course.lessons.length > 0 &&
                        <button className="coursePage__addLessonButton" onClick={acceptCourse}>
                            Сохранить курс с выбранными уроками
                        </button>}
                    </>}
                </div>
            </>}
        </>
    )
}

export default SharedCourse
