import React from "react";
import PropTypes from "prop-types";
import AsideFilterStore from "./asideFilterStore";
import {SuperButton} from "../SuperButton/SuperButton";
import st from "./FilterTextValues.module.scss"

const FilterTextValues = props => {
  const {filterTextValues, deleteTextValue} = AsideFilterStore

  return (
    <div className={st.filterTextValues}>{filterTextValues.map(field =>
      <SuperButton key={field.key}
                   variant="filter"
                   type="filterColored"
                   callback={() => {
                     deleteTextValue(field.key)
                     props.onDelete(field.key)
                   }}>
        {field.label}:&nbsp;<b>{field.value}</b>
      </SuperButton>)}
    </div>
  )
}

FilterTextValues.propTypes = {
  onDelete: PropTypes.func.isRequired
}

export default FilterTextValues;
