import React, {useCallback, useEffect, useState} from 'react';
import {permissions} from "settings/permissions";
import Stores from "Stores";
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import useLocalization from "hooks/useLocalization";
import Loader from "components/UI/Loader/Loader";
import Book from "components/Modules/Ebook/Books/components/Book";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import {GoToLink} from "components/UI/SuperButton/SuperButton";
import styles from "../Books/Books.module.scss";
import not_found_books from "components/UI/illustrations/streamline-work-from-home.svg";
import {ReactComponent as AddIcon} from "components/UI/icons/add-line.svg";
import AddBookByCode from "../../../../Global/GlobalModalSingle/templates/Ebooks/AddBookByCode";
import asideModalStore from "Stores/asideModalStore";



const Books = () => {
  const [books, setBooks] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const ud = JSON.parse(localStorage.getItem('ud'))
  const userID =  ud.user_data.profile.id


  const {translate} = useLocalization({
    section: 'profile', subsection: 'books', dict: {
      "title": "Books",
      "books_not_found": "You don't have any books yet.",
      "edit": "Edit",
      "copy": "Copy",
      "delete": "Delete",
      "additional_materials": "Additional materials"
    }
  })
  const listMenu = [
    {
      name: translate.edit,
      action: 'edit',
      permission: permissions.change_course
    },
    {
      name: translate.delete,
      action: 'delete',
      permission: permissions.delete_course
    },
    {
      name: translate.copy,
      action: 'copy',
      permission: permissions.change_course
    }
  ]

  const fetchBooks = useCallback(() => {
    setIsLoading(true);

    if (Stores.isClient) {
      const requests = [
        superAxiosRequest({
          method: 'get',
          link: 'ebooks/students_ebooks',
          ...(checkPermList(permissions.delete_student_book) && {params: {user_id: ud.user_data.id}})
        }),
        superAxiosRequest({
          method: 'get',
          link: 'ebooks/students_ebooks_qr',
          ...(checkPermList(permissions.delete_student_book) && {params: {user_id: ud.user_data.id}})
        })
      ];

      Promise.all(requests)
          .then(results => {
            const combinedBooks = [...results[0].data, ...results[1].data];
            setBooks(combinedBooks);
          })
          .catch(() => {
            setBooks([]);
          })
          .finally(() => setIsLoading(false));
    } else {
      const apiConf = {
        method: 'get',
        link: 'ebooks/books',
        params: {limit: 999},
      };
      superAxiosRequest(apiConf)
          .then(res => {
            setBooks(res.data.result);
          })
          .catch(() => {
            setBooks([]);
          })
          .finally(() => setIsLoading(false));
    }
  }, [ud.user_data.id]);




  const modalAddBook = () => {
    const ms = {
      title: 'Adding a Book',
      modalBodyComponent: () => <AddBookByCode />,
      valid: {
        ebook_id: 'required',
      },
      button: 'Add Book',
      link: 'ebooks/book_add_by_code',
    }
    asideModalStore.activity(ms, {
      client: userID,

    })

  }
  useEffect(() => {
    fetchBooks()

  }, [fetchBooks])

  // console.log(bookId)
  return (
      <>
        {isLoading ?
            <Loader/> :
            <div className={styles.container}>
              <BlockHeader title={translate.title}>
                {Stores.isClient && <button className={styles.addButton} onClick={modalAddBook}><AddIcon/></button>}
                <GoToLink to={Stores.isClient ? '/ebooks/my-books' : '/ebooks/books'} target="_blank"/>
              </BlockHeader>
              {books.length > 0 ?
                  <div className={styles.body}>
                    {books.map((book) => (
                        <Book
                            key={book.id}
                            book={book}
                            books={books}
                            setBooks={setBooks}
                            fetchBooks={fetchBooks}
                            translate={translate}
                            listMenu={listMenu}
                        />
                    ))}
                  </div> :
                  <div className={styles.bodyNotFound}>
                    <div className={styles.notFound}>
                      <img className={styles.imgNotFound} src={not_found_books} alt="books not found"/>
                      <p>{translate.books_not_found}</p>
                    </div>
                  </div>}
            </div>
        }
      </>
  )
}

export default Books;