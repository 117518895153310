import React from 'react';
import style from './ActionWithEx.module.scss'
import {ReactComponent as SubmitReview} from "components/UI/icons/revision_info.svg";
import Marks from "./Marks/Marks";

const ActionWithEx = ({setMark, homework, addMark, sendToRevision, pastLesson}) => {
    return (
        <div className={style.container}>
            <div>
                <h3>Action With Homework</h3>
            </div>
            <div className={style.review}>
                <div className={style.reviewBtn} onClick={sendToRevision}>
                    <SubmitReview/>
                    <span>Submit Homework For Revision</span>
                </div>
            </div>
            <div className={style.delimiter}>
                <div className={style.line}></div>
                <div className={style.text}>OR RATE</div>
            </div>
            <div className={style.marks}>
                <Marks type={homework?.company_mark_type || pastLesson?.company_mark_type} setMark={setMark} addMark={addMark}/>
            </div>
        </div>
    );
};

export default ActionWithEx;