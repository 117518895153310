import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import BookedSlotModal from './BookedSlotModal';
import Menu from './Menu';
import Tooltip from 'components/Modules/Slots/components/Tooltip/Tooltip';
import { classNames as cn } from 'helpers';
import { calcTimeWithTimeZoneShift } from 'components/Modules/Slots/utils';
import { localisationMaster } from "Localisation/Localisation";
import LocalStorage from 'services/LocalStorage';
import styles from './BookedSlots.module.scss';
import slotsTeacherScheduleStore from 'Stores/slotsTeacherScheduleStore';

function BookedSlot({
  id,
  start_at,
  finish_at,
  color,
  is_client,
  is_deleted,
  is_accessible,
  is_online,
  is_school,
  type_name,
  slot_type_id,
  is_comment,
  slot_booking,
  zoom_url,
  group_clients,
  comment,
  onClick,
  isMenuShown,
}) {
  const timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  const { updateSlot } = slotsTeacherScheduleStore;
  const [isModalShown, setIsModalShown] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [modalType, setModalType] = useState(null);
  const currentColor = (is_deleted && '#ffdae7') || (is_client && '#b1f5cf') || color;

  const handleMouseEnter = () => {
    if (isMenuShown) {
      return;
    }
    setIsTooltipShown(true);
  };

  const handleSlotClick = () => {
    if (is_client) {
      return swal(localisationMaster('text_4', 'teacher_schedule'), localisationMaster('text_5', 'teacher_schedule'), 'warning');
    }
    setIsTooltipShown(false);
    onClick(id);
  };

  const handleMenuClick = (type) => {
    onClick(id);

    if (!type) {
      return;
    }
    if (type === 'restore') {
      updateSlot({id, is_active: true});
      return;
    }
    setModalType(type);
    setIsModalShown(true);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsModalShown(false);
  };

  const handleClose = () => {
    setIsModalShown(false);
  };

  return (
    <>
      <li
        className={cn({
          [styles.booked_item]: true,
        })}
        style={{backgroundColor: currentColor}}
        onClick={handleSlotClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsTooltipShown(false)}
      >
        <p className={styles.booked_time}>
          {calcTimeWithTimeZoneShift(start_at, timeZone)} - {calcTimeWithTimeZoneShift(finish_at, timeZone)}
        </p>
        {isTooltipShown &&
          <Tooltip
            text={(is_client && localisationMaster('busy_slot', 'teacher_schedule')) || (is_deleted && localisationMaster('text_6', 'teacher_schedule')) || type_name}
            isAvailable={!is_deleted && is_accessible}
            isOnline={is_online}
            isSchool={is_school}
            isGroup={group_clients}
            isLink={slot_booking}
          />
        }
        {isMenuShown &&
        <Menu
          isDeleted={is_deleted}
          onClick={handleMenuClick}
        />
        }
      </li>
      {isModalShown &&
        <BookedSlotModal
          id={id}
          type={modalType}
          slotType={slot_type_id}
          isComment={is_comment}
          isOnline={is_online}
          isSchool={is_school}
          isGroup={group_clients}
          zoomUrl={zoom_url}
          comment={comment}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      }
    </>
  );
}

BookedSlot.propTypes = {
  id: PropTypes.number.isRequired,
  slot_type_id: PropTypes.number.isRequired,
  start_at: PropTypes.string.isRequired,
  finish_at: PropTypes.string.isRequired,
  is_client: PropTypes.bool.isRequired,
  is_deleted: PropTypes.bool.isRequired,
  type_name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isMenuShown: PropTypes.bool,
  is_comment: PropTypes.bool,
  is_accessible: PropTypes.bool,
  is_online: PropTypes.bool,
  is_school: PropTypes.bool,
  comment: PropTypes.string,
  group_clients: PropTypes.bool,
  slot_booking: PropTypes.bool,
  zoom_url: PropTypes.string,
};

export default BookedSlot;
