import React, {useState, useEffect} from 'react'
import {localisationMaster} from "Localisation/Localisation";

import './ContentPagination.scss';

export default function ContentPagination(props) {

    const
        // Ограничение
        iLimit = +props.per_page,
        // Количество элементов
        iCount = props.allItems,
        // Всего страниц
        iTotalPages = Math.max(Math.ceil(iCount/iLimit), 1),
        // Текущая страница
        iPage = Math.min(props.page, iTotalPages),
        // Смещение
        iStart = (iPage-1)*iLimit,

        offset = 1,
        separator = '...',

        pageLimits = [10, 20, 30, 40, 50]

    const [customPage, setCustomPage] = useState(iPage)

    const getPages = () => {
        const aRange = getRange()

        let aPage = [];
        let li;

        if (iPage>1)
            aPage.push(<li key={'prev'} className="contentPagination__pageLink contentPagination__pageLink--prev" onClick={()=>props.changePage(iPage-1)} />)

        aRange.map(i=>{


            if (i===iPage)
                li = <li key={i} className="contentPagination__pageLink contentPagination__pageLink--current">{i}</li>
            else if (i===separator)
                li = <li key={separator+Math.random()} className="contentPagination__pageLink contentPagination__pageLink--sep"><i /></li>
            else
                li = <li key={i} className="contentPagination__pageLink" onClick={()=>props.changePage(i)}>{i}</li>

            aPage.push(li)
        })

        if (iPage<iTotalPages)
            aPage.push(<li key={'next'} className="contentPagination__pageLink contentPagination__pageLink--next" onClick={()=>props.changePage(iPage+1)} />)

        return aPage;
    }

    const getRange = () => {
        let left = Math.max(iPage - offset, 1);
        let right = Math.min(iPage + offset, iTotalPages);

        let startRange = [];
        if (left>1){
            //$startRange = range(1, min($offset, $left-1));
            for (let i=1; i<=Math.min(offset, left-1); i++)
                startRange.push(i)

            if (left - Math.max(...startRange) > 1)
                startRange.push(separator);
        }

        let middleRange = [];
        for(let i=left; i<=right; i++)
            middleRange.push(i)

        let endRange = [];
        if (iTotalPages > right){
            //$endRange = range(max($this->iTotalPages-$offset+1, $right+1), $this->iTotalPages);
            for (let i=Math.max(iTotalPages-offset+1, right+1); i<=iTotalPages; i++)
                endRange.push(i)

            if (Math.min(...endRange)-right > 1)
                endRange.unshift(separator);
        }

        return [...startRange, ...middleRange, ...endRange];
    }

    const onChangeCustomPageHandler = value => {
        if (value){
            value = Math.max(value, 1)
            value = Math.min(value, iTotalPages)
        }

        setCustomPage(value)
    }

    const onChangePageLimit = e => props.perPage(e.target.value)

    useEffect(()=>{
        setCustomPage(iPage)
    }, [props])

    if (iTotalPages<2 && !props.show) return null

    return (
        <div className="contentPagination" style={{paddingTop: props.paddingTop}}>

            <div className="contentPagination__params">
                <span>{localisationMaster('number-lines', 'pagination')}</span>
                <div className="contentPagination__inputNumber">
                    <select
                        //defaultValue={props.perPage}
                        value={iLimit}
                        onChange={(e)=>onChangePageLimit(e)}
                    >
                        <option value={0} disabled>Стр.</option>
                        {pageLimits.map(item =>
                            <option key={item} value={item}>{item}</option>
                        )}
                    </select>
                </div>
            </div>

            <div className="contentPagination__info">
                {iCount>0 &&
                <span>{iStart+1} - {Math.min(iStart+iLimit, iCount)} {localisationMaster('of', 'pagination')} {iCount}</span>
                }
            </div>

            <div className="contentPagination__right">
                <div className="contentPagination__pages">
                    <ul>
                        {iTotalPages>1 && getPages()}
                    </ul>
                </div>

                {iTotalPages > 4 &&
                <div className="contentPagination__customPage">
                    <input
                        type="number"
                        value={customPage ? customPage : ""}
                        min="1"
                        max={iTotalPages}
                        step="1"
                        onChange={({target})=>onChangeCustomPageHandler(+target.value)}
                    />
                    <button onClick={()=>customPage ? props.changePage(customPage) : null}>ок</button>
                </div>
                }
            </div>

        </div>
    )
}
