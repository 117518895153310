import swal from "sweetalert";
import { superAxiosRequest } from 'axiosApi';
let apiConf

export const colsSettings = {
    items: ['ФИО', 'Email', 'Телефон', 'Группа', 'Локация', 'Действия'],
    action: [
        { name: 'Ссылка на ZOOM', action: 'zoom', permission: ['forward'] },
        { name: 'Отправить SMS', action: 'sms', permission: ['forward']},
        { name: 'Отправить e-mail', action: 'email', permission: ['forward'] },
        { name: 'Перезаписать', action: 'edit', permission: ['forward']}, 
        { name: 'Удалить', action: 'delete', permission: ['forward'] },
        { name: 'Письма', action: 'checkEmail', permission: ['forward']},
        { name: 'Сообщения', action: 'checkSms' ,permission: ['forward']}

    ],
    prefix: 'time_slots',
    link: 'clients',
    id: { name: 'id' },
    main_rows: [
        {
            compiled:
                [
                    { name: '_last_name', updir: 'student', past: ' ', function: 'transliterate' },
                    { name: '_first_name', updir: 'student', function: 'transliterate' },
                ]
        },
        {
            compiled: [
                { name: 'time_start', updir: 'teacher_slot', sub_dir: 'slot', past: ', ', function: 'slice_time' },
                { name: 's_date', updir: 'teacher_slot', function: 'date_without_year' },
            ]
        },
        { name: 'result' },
    ],
    sub_rows: [
        { name: 'student_phone', description: 'Телефон  ' },
        { name: 'email', updir: 'student', description: 'E-mail' },
        { name: 'teacher_fullname', description: 'Преподаватель', function: 'transliterate' },
        {
            compiled: [
                { name: 'created_at', function: 'date_time', past: ' ' },
                { name: '_last_name', updir: 'created_by', prev: '( ', past: ' ', function: 'transliterate' },
                { name: '_first_name', updir: 'created_by', function: 'transliterate', past: ' )' },
            ],
            description: 'Дата создания'
        },
        { name: 'name', updir: 'program', description: 'Программа'},
        { name: 'name', updir: 'age', description: 'Возраст'},
        { name: 'payment_comment', description: 'Комментарий оплаты', function: 'payment_comment'},
        

    ]
}

export const reSend = (id, type) => {
    let params = {
        slot_client_id: id,
    }
    if (type === 'sms') {
        params['sms_notification'] = true
    } else if (type === 'email') {
        params['email_notification'] = true
    }
    // let confirm = window.confirm("Отправить повторно?")
    swal({
        title: "Отправить повторно?",
        // text: "Отправить повторно?",
        icon: "warning",
        buttons: ["Нет", "Да"],
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            apiConf = {
                method: 'get',
                link: 'time_slots/resend_client_notification',
                params: params,
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                // window.alert('Сообщение отправлено повторно');
                swal("", 'Сообщение отправлено повторно', "success")
            })
                .catch(error => {
                    // window.alert('Сбой при отправке сообщения');
                    swal("", 'Сбой при отправке сообщения', "warning")
                });
        }
    })
}

