import React, { useEffect, useRef, useCallback } from 'react';
import { observer } from "mobx-react";
import LessonStore from "Stores/lessonStore"
import ContentHeader from "../Global/ContentHeader";
import BlueLine from "./BlueLine";
import Question from "./Question";
import Loader from "../Global/Loader/Loader";
import SectionList from "./components/SectionList";
import Stores from 'Stores';


const LessonInit = props => {
  const wsUrl = `wss://phoenix.ru.net:443/ws/lessons/init_lesson/${props.match.params.id}/?token=${localStorage.getItem('access')}`;

  const connection = useRef();
  const shouldKeepWSAlive = useRef(false);
  const {
    lesson,
    section,
    activeSection,
    fetchLesson,
    isSectionLoading,
    fetchSection,
    exerciseList,
    setActiveSection,
    lessonStudents,
    initLesson,
    addExersize,
    deleteExercise,
    setAnswerList,
    answerList,
    addAnswers,
    focused,
    setFocused,
  } = LessonStore

  useEffect(() => {
    if(Object.keys(initLesson).length < 1) return
    if (!(connection &&
      connection.current &&
      connection.current.readyState === 1)) {
      connection.current = new WebSocket(wsUrl);
      connection.current.onopen = () => {
        //connection.current.send('asdasdasdasdasd')
      }
      connection.current.onclose = () => {
      };
      connection.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        switch (message.type) {
          case 'assigned_exercise':
            addExersize(message)
            break;
          case 'deassigned_exercise':
            deleteExercise(message.message.exercise.id)
            break;
          case 'exercise_answer':
            addAnswers(message.message)
            break;
          case 'lessons.notify_focus_on_exercise':
            setFocused(message)
            break;
          default:
            break;
        }
      };
    }
  }, [initLesson]);

  
  const sendMessage = (body) => {
    connection.current.send(JSON.stringify({...body}))
  }

  const keepAlive = useCallback(() => {
    if (shouldKeepWSAlive.current) {
      if (connection.current !== undefined &&
        connection.current !== null &&
        connection.current.readyState === 1) {
        connection.current.send("");
      }
      setTimeout(() => {
        keepAlive();
      }, 20000);
    }
  }, []);
  
  
  /*   useEffect(() => {
      if (isWaitingVendi) {
        shouldKeepWSAlive.current = true;
        keepAlive();
      } else {
        shouldKeepWSAlive.current = false;
      }
    }, [keepAlive]); */

  useEffect(() => {
    fetchLesson(props.match.params['id']);
    setAnswerList(props.match.params['id']);
  }, [])

  useEffect(() => {
    if (lesson?.section_list?.length > 0)
      fetchSection(lesson.section_list[activeSection - 1].id)
  }, [activeSection, lesson])

  const onChangeSectionHandler = direction => {
    setActiveSection(direction)
    window.scrollTo({ top: 0 })
  }

  return (
    <>
      <ContentHeader title={lesson?.name} />
      <div className="lessonPage">

        <div className="lessonPage__container">

          <BlueLine><b>Это режим проведения интерактивного урока</b></BlueLine>

          {!Stores.isClient && 
          <div className="lessonPage__lessonSectionHeader">
            <h2>{lesson.section_list?.[activeSection - 1]?.name || ''}</h2>
          </div>}

          <div className="lessonPage__exercisesSectionsBox">
            <div className="lessonPage__questionsCreateBox">
              {isSectionLoading ?
                <Loader /> :
                <div className="lessonPage__questions">
                  {(section?.length > 0) &&
                    exerciseList.map((item, index) =>
                    
                      <div key={index} className="lessonPage__question">
                        <Question
                          focused={item.id === focused}
                          number={`${activeSection}.${index + 1}`}
                          init
                          uuid={props.match.params['id']}
                          students={lessonStudents}
                          sectionID={section.id}
                          item={item}
                          sendMessage={sendMessage}
                          answerList={answerList}
                          initLesson={initLesson}
                          isFirst={index === 0}
                          isLast={index === exerciseList.length - 1}
                        />
                      </div>
                    )}
                </div>}
            </div>
            <div>
              {!Stores.isClient ? <SectionList sections={lesson.section_list || []} activeSection={activeSection}
                setActiveSection={setActiveSection} update={() => fetchLesson(props.match.params['id'])} />
              : <div className="lessonPage__zoomFrame">
                  Поместите окно <br /> ZOOM/Skype
                </div>
              }
            </div>
          </div>

        </div>

        {(!Stores.isClient && lesson?.section_list?.length > 0) &&
          <div className="lessonPage__sectionToggleBox">
            <div className="lessonPage__sectionToggle">
              <div className="lessonPage__sectionToggleButton lessonPage__sectionToggleButton--prev"
                onClick={() => {
                  onChangeSectionHandler('prev')
                }} />
              <span>{activeSection} из {lesson?.section_list.length}</span>
              <div className="lessonPage__sectionToggleButton lessonPage__sectionToggleButton--next"
                onClick={() => {
                  onChangeSectionHandler('next')
                }} />
            </div>
          </div>
        }

      </div>
    </>
  );
}

export default observer(LessonInit);
