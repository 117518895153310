import InteractiveTimeTable from "./components/InteractiveTimeTable/InteractiveTimeTable"
import React, {useEffect, useState} from 'react';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import {superAxiosRequest} from 'axiosApi';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import "./VisualScheduleTestPage.scss"
import Filter from "../../../ContentBox/Global/Filter/Filter";

export const getWeekDay = (day, type) => { // возвращает дату первого/последнего дня недели
    const dayNumber = day.getDay() === 0 ? 7 : day.getDay()
    const firstWeekDay = (new Date(day - ((dayNumber - 1) * 24 * 60 * 60 * 1000)))
    const lastWeekDay = new Date(firstWeekDay - (7 * -24 * 60 * 60 * 1000))
    switch (type) {
        case 'firstWeekDay':
            return firstWeekDay
        case 'lastWeekDay':
            return lastWeekDay
    }
}


const VisualScheduleTestPage = (props) => {
    const [loaderState, setLoaderState] = useState(true)
    const [filterState, setFilterState] = useState()
    const [pageState, setPageState] = useState({
        locations: [],
        classrooms: [],
        schedules: [],
        teachers: [],
    })

    
    const getTodayZeroHourse = () => {
        return new Date((new Date).setHours(0, 0, 0, 0))
    }
    const getData = (apiConf) => {
        setLoaderState(true)
        superAxiosRequest(apiConf).then(({data}) => {
            let schedulesArray = []
            let schooldaysArray = []
            data.groups.forEach(item => {
                schedulesArray.push(...item.schedules)
                schooldaysArray.push(...item.schooldays)
            })
            setPageState({schedules: schedulesArray, schooldays: schooldaysArray, teachers: data.teachers, locations: data.locations, classrooms: data.classrooms})
            setLoaderState(false)
        }).catch(() => { });
    }

    const [time, setTime] = useState({
        date: getTodayZeroHourse(),
        firstWeekDay: getWeekDay(getTodayZeroHourse(), 'firstWeekDay'),
        lastWeekDay: getWeekDay(getTodayZeroHourse(), 'lastWeekDay')
    })
    let apiConf = {
        method: 'get',
        link: props.match.params.id ? `schedule/visual_schedule/${props.match.params.id}` : `schedule/visual_schedule`,
        params: {
            date: timeConverter(time.firstWeekDay, 'today'),
        }
    }
    const filterConfig = [
        {
            type: "select-custom",
            placeholder: 'Выберите локацию',
            values: pageState.locations,
            sort_key: 'name',
            nameState: 'location'
        },
        {
            type: "select-bound",
            placeholder: 'Выберите аудиторию',
            values: pageState.locations,
            sort_key: 'name',
            bindingToState: 'location',
            bindingKey: 'classrooms',
            nameState: 'classroom',
            noOptionsMessage: 'Сначала выберите локацию'
        },
        {
            type: 'select-custom',
            placeholder: 'Выберите преподавателя',
            values: pageState.teachers,
            sort_key: 'name',
            nameState: 'teacher'
        },
        {
            type: 'select-custom',
            placeholder: 'Статусы групп',
            values: [
                {
                    "id": 1,
                    "name": "Актуальные"
                },
                {
                    "id": 2,
                    "name": "Формирование"
                },
            ],
            sort_key: 'id',
            nameState: 'status'
        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 1, name: "Все уроки"},
                {id: 2, name: "Только пробные"},
            ]
        },

    ];

    const setFilterParams = (param) => {
        const frontFilterParams = [
            {name: 'online', filterKey: 'status', filterType: 'status'},
            {name: 'teacher', filterKey: 'teachers' ,filterType: 'teacher'},
        ]
        const requestParam = {...param}
        let frontFilterArray = []

        frontFilterParams.forEach(filterParam => param[filterParam.name] && frontFilterArray.push({
            key: filterParam.filterKey,
            value: param[filterParam.name],
            filterType: filterParam.filterType
        }))

        delete requestParam.is_active
        delete requestParam.online

        setFilterState(frontFilterArray)
        getData({...apiConf, params: {...apiConf.params, ...requestParam}})
    }

    const filterDrop = () => {
        setFilterState()
    }
    const changeLesson = (data, item) => {
        const newSchooldaysId = data.schooldays.map(schoolday => schoolday.schedule_id )
        const newShedulesId = data.schedules.map(schedule => schedule.id )
        setPageState({
            ...pageState,
            schedules: [...pageState.schedules.filter(sch => !newShedulesId.includes(sch.id)), ...data.schedules],
            schooldays: [...pageState.schooldays.filter(scd => !newSchooldaysId.includes(scd.schedule_id)), ...data.schooldays] 
        })
    }

    useEffect(() => {
        getData(apiConf)
    }, [time])

    return (
        <>
            <ContentHeader
                title={`Интерактивный календарь ${props.match.params.id ?` группы:${props.match.params.id}` : ''}`} />
            <Filter
                config={filterConfig}
                drop={() => filterDrop()}
                getFilterParams={e => setFilterParams(e)}
            />
            <div className='visualScheduleTestPage'>
                <InteractiveTimeTable
                    data={pageState}
                    date={time}
                    loader={loaderState}
                    setDate={setTime}
                    filterStatusData={filterState}
                    changeLesson={changeLesson}
                />
            </div>
        </>
    )
}

export default VisualScheduleTestPage