import React, {useEffect, useState} from 'react'
import st from './TimeLeft.module.scss'
import {localisationMaster} from "Localisation/Localisation";


const TimeLeft = ({delta}) => {
    const [sec, setSec] = useState(delta)

    let left = sec
    const days = Math.floor(left / 86400);
    left -= days * 86400;

    const hours = Math.floor(left / 3600) % 24;
    left -= hours * 3600;

    const minutes = Math.floor(left / 60) % 60;

    useEffect(() => {
        const interval = setInterval(() => {
            setSec(state => state - 1)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (sec < 1) window.location.reload()
    }, [sec])

    return (
        <div className={st.timeLeft}>
            <div><strong>{days}</strong><span>{localisationMaster('d', 'study/test')}</span></div>
            <div><strong>{hours}</strong><span>{localisationMaster('ch', 'study/test')}</span></div>
            <div><strong>{sec < 60 ? 1 : minutes}</strong><span>{localisationMaster('min', 'study/test')}</span></div>
        </div>
    )
}

export default TimeLeft
