import { observer } from "mobx-react";
import React from "react";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import BundleLevels from "./BundleLevels";
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import "./AddBundle.scss";

const AddBundle = () => {
  const { getModalValues: { name, age, program, test_duration }, changeValues, check } = modalStore;
  return (
    <div className="placementTestAddBundle">
      <div className="modalAddPayment">
        <div className="modalAddPayment__field">
          <p className="superModalGroup__label">Название:</p>
          <input
            placeholder="Введите название связки"
            value={name}
            onChange={(e) => changeValues(e.target.value, "name")}
            className={check("name") ? "super-modal__input--error" : undefined}
          />
        </div>
        <div className="modalAddPayment__row">
          <div className="modalAddPayment__field">
            <p className="superModalGroup__label">Возрастная категория:</p>
            <SelectCustom
              placeholder="Выберите возраст"
              apiLink="db/age"
              valueID={age}
              apiConfParams={{ limit: 999 }}
              sortKey="name"
              onChange={(o) => changeValues(o.id, "age")}
              isSearchable
              error={check("age")}
            />
          </div>
          <div className="modalAddPayment__field">
            <p className="superModalGroup__label">Программа обучения:</p>
            <SelectCustom
              placeholder="Выберите программу"
              apiLink="db/programs"
              valueID={program}
              apiConfParams={{ limit: 999 }}
              sortKey="name"
              onChange={(o) => changeValues(o.id, "program")}
              isSearchable
              error={check("program")}
            />
          </div>
        </div>
        <div className="placementTestAddBundle__row placementTestAddBundle__testTime">
          <p className="superModalGroup__noReqLabel">
            Продолжительность теста (мин.):
          </p>
          <DummyCounter
            value={test_duration}
            onChange={(val, name) => changeValues(val <= 0 ? test_duration : val, name)}
            name="test_duration"
          />
        </div>
        <BundleLevels />
      </div>
    </div>
  );
};
export default observer(AddBundle);
