function daysInMonth(anyDateInMonth) {
    return new Date(anyDateInMonth.getFullYear(),
        anyDateInMonth.getMonth() + 1,
        0).getDate();
}

const ifZero = value => {
    if (+value < 10) {
        return '0' + value
    } else return value
}

const getDow = date => {
    if (+date === 0) {
        return 7
    } else {
        return date
    }
}

function getLastDayOfMonth(year, month) {
    //let date = new Date(year, month + 1, 0);
    return 32 - new Date(year, month, 32).getDate()
}

export const checkTrack = (tracks, vector, time) => {
    let result = false;
    if (tracks.length && time) {
        // опоздание
        if (vector < 0) {
            if (new Date(`${tracks[0].day} ${tracks[0].in_time}`) > new Date(new Date(`${tracks[0].day} ${time}`) - (- 60000)))
                result = true
        }
        // уход раньше
        if (vector > 0) {
            if (
                tracks[tracks.length - 1].out_time === null ||
                new Date(`${tracks[0].day} ${tracks[tracks.length - 1].out_time}`) < new Date(`${tracks[0].day} ${time}`)
            )
                result = true
        }
    } else {
        result = true
    }
    return result;
}

export const diffTime = (from, to) => {
    let delta = 0
    if (from && to) {
        const getDate = str => new Date(0, 0, 0, str.split(':')[0], str.split(':')[1]);
        delta = getDate(to) - getDate(from)
    }
    return delta;
}

export const createWorkDays = (date, workD, drop) => {
    let lastDay = daysInMonth(date)
    let arr = []
    let dow;
    let week = 1;
    for (let i = 0; i < lastDay; i++) {
        dow = getDow(new Date(date.getFullYear() + '-' + ifZero(date.getMonth() + 1) + '-' + ifZero(i + 1)).getDay())
        arr.push(
            {
                dm: i + 1,
                week: week,
                period: week * 7 - 1,
                dow: dow,
                date: date.getFullYear() + '-' + ifZero(date.getMonth() + 1) + '-' + ifZero(i + 1),
                type: '',
                later: false,
                tracks: null,
                before: false
            },
        )
        if (week === 4 && dow === 7) {
            week = 1
        } else {
            if (dow === 7) week += 1
        }
    }

    const a = workD.rules
    let calendar = []
    let date_end = date.getFullYear() + '-' + ifZero(date.getMonth() + 1) + '-' + ifZero(getLastDayOfMonth(new Date(date).getFullYear(), new Date(date).getMonth()))
    for (let i = 0; i < a.length; i++) {
        let loop_type = a[i].loop_type

        let start_at = new Date(a[i].date_from)
        let end_at = new Date(date_end)
        if (a[i].date_until !== null && a[i].date_until && (new Date(a[i].date_until <= new Date(date_end)))) {
            end_at = new Date(a[i].date_until)
        }
        let y, m, d, day;
        if (loop_type === 8) {
            let work = a[i].count;
            let rest = a[i].period;
            while (start_at <= end_at) {
                var next_date = start_at.setDate(start_at.getDate());
                var next_days_date = new Date(next_date);
                m = next_days_date.getMonth() + 1;
                d = next_days_date.getDate();
                y = next_days_date.getFullYear();
                day = next_days_date.getDay();
                if (+day === 0) day = 7
                if (+d < 10) {
                    d = '0' + d
                }
                if (+m < 10) {
                    m = '0' + m
                }
                if (work > 0) {
                    calendar.push({
                        dow: day,
                        dm: next_days_date.getDate(),
                        later: false,
                        before: false,
                        date: y + '-' + m + '-' + d,
                        type: 1,
                        time: a[i].start_at.slice(0, 5) + ' - ' + a[i].finish_at.slice(0, 5),
                        lunch: a[i].lunch,
                        tracks: null,
                        slide: true,
                    });
                    work -= 1
                } else if (rest > 0) {
                    calendar.push({
                        dow: day,
                        dm: next_days_date.getDate(),
                        later: false,
                        before: false,
                        date: y + '-' + m + '-' + d,
                        type: 2,
                        time: '',
                        lunch: '',
                        tracks: null,
                        slide: true,
                    });
                    rest -= 1
                    if (rest === 0 && work === 0) {
                        rest = a[i].period
                        work = a[i].count
                    }
                }
                start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                start_at = new Date(start_at.setHours(0));
            }
        } else if (loop_type < 8) {
            let start_at = new Date(a[i].date_from)
            let period = 0;
            while (start_at <= end_at) {
                var next_date = start_at.setDate(start_at.getDate());
                var next_days_date = new Date(next_date);
                m = next_days_date.getMonth() + 1;
                d = next_days_date.getDate();
                y = next_days_date.getFullYear();
                day = next_days_date.getDay();

                if (+day === 0) day = 7
                if (+d < 10) {
                    d = '0' + d
                }
                if (+m < 10) {
                    m = '0' + m
                }
                if (day === loop_type && period === 0) {
                    calendar.push({
                        dow: day,
                        dm: next_days_date.getDate(),
                        later: false,
                        before: false,
                        date: y + '-' + m + '-' + d,
                        type: 1,
                        time: a[i].start_at.slice(0, 5) + ' - ' + a[i].finish_at.slice(0, 5),
                        lunch: a[i].lunch,
                        tracks: null
                    });
                    period = a[i].period
                } else if (period > 0) {
                    /* if(period === 0) period = a[i].period */
                    calendar.push({
                        dow: day,
                        dm: next_days_date.getDate(),
                        later: false,
                        before: false,
                        date: y + '-' + m + '-' + d,
                        type: 2,
                        time: '',
                        lunch: '',
                        tracks: null
                    });
                    period -= 1
                }
                start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                start_at = new Date(start_at.setHours(0));
            }
        }
        for (let x = 0; x < arr.length; x++) {
            const work = calendar.filter(p => p.date === arr[x].date && p.type === 1)
            const off = calendar.filter(p => p.date === arr[x].date && p.type === 2)

            if (work.length < 1) {
                arr[x].type = 2
            } else {
                arr[x].time = work[work.length - 1].time
                arr[x].type = work[work.length - 1].type
                arr[x].lunch = work[work.length - 1].lunch
                if (work[work.length - 1].slide) arr[x].slide = work[work.length - 1].slide
            }

        }
    }

    if (!drop && workD.holidays.length > 0) {
        for (let x = 0; x < arr.length; x++) {
            if (workD.holidays.filter(a => a.date === arr[x].date).length > 0 && !arr[x].slide) arr[x].type = 'holiday'
        }
    }
    if (!drop && workD.tabels.length > 0) {
        for (let x = 0; x < arr.length; x++) {
            let a = workD.tabels.filter(a => a.day === arr[x].date)
            if (a.length > 0) {
                arr[x].type = a[0].day_type
            }
        }
    }
    if (!drop && workD.tracks.length > 0) {
        for (let x = 0; x < arr.length; x++) {
            let tr = workD.tracks.filter(a => a.date === arr[x].date)
            if (tr.length > 0) {
                arr[x].tracks = tr.sort((a, b) => a.start_at > b.start_at ? 1 : -1)
            } else {
                arr[x].tracks = null
            }
        }
    }
    return arr
}