import React from "react";
import {translate as loc, dict} from "Localisation";
import st from "./DaysExplain.module.scss";
import {ReactComponent as IconInformation} from "components/ContentBox/img/information.svg";

export default function DaysExplain() {
  return (
    <div className={st.daysExplain}>
      <div className={st.daysExplain__icon}><IconInformation/></div>
      <div className={st.daysExplain__items}>
        <div className={st.daysExplain__item}><span>{loc(dict.work_table_listing.working_day)}</span></div>
        <div className={st.daysExplain__item_6}><span>{loc(dict.work_table_listing.worked_day)}</span></div>
        <div className={st.daysExplain__item_2}><span>{loc(dict.work_table_listing.day_off)}</span></div>
        <div className={st.daysExplain__item_holiday}><span>{loc(dict.work_table_listing.holiday)}</span></div>
        <div className={st.daysExplain__item_4}><span>{loc(dict.work_table_listing.sick_day)}</span></div>
        <div className={st.daysExplain__item_3}><span>{loc(dict.work_table_listing.vacation_day)}</span></div>
        <div className={st.daysExplain__item_otpuskbs}><span>{loc(dict.work_table_listing.vacation_day)}, Б/С</span></div>
      </div>
    </div>
  )
}
