import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {superAxiosRequest} from 'axiosApi'
import swal from "sweetalert";
import Stores from 'Stores';
import ebookBookStore from "Stores/ebookBookStore";
import {checkPermList, classNames as cn, getWordEnd} from "helpers"
import st from './ChapterCard.module.scss';
import {ReactComponent as NoPhotoIcon} from "../img/no-photo-icon.svg";
import {ReactComponent as ArrowDown} from "../img/arrow-down.svg";
import {SuperButton} from "components/UI/SuperButton/SuperButton";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import {permissions} from "settings/permissions";


function ChapterCard({translate, ...props}) {

  const {editChapter, removeChapter, addChapter} = ebookBookStore
  const exerciseWordForms = [translate.exercises_1, translate.exercises_2, translate.exercises_3];
  const menu = [
    {name: translate.edit_lesson, action: 'edit'},
    {name: translate.copy_lesson, action: 'copy'},
    {name: translate.delete_lesson, action: 'delete'},
  ]
  const action = data => {
    switch (data) {
      case 'delete':
        deleteChapter()
        break;
      case 'copy':
        copyChapter()
        break;
      case 'edit':
        editChapter(props.chapter.id, translate, props.msChapter)
        break;
      default:
        break;
    }
  }

  const copyChapter = () => {
    swal({
      text: `${translate.copy_chapter} ${props.chapter.name} ?`,
      buttons: {
        cancel: translate.cancel,
        confirm: {
          value: props.chapter.name + ' copy',
          text: translate.yes
        }
      },
      content: {
        element: "input",
        attributes: {
          value: props.chapter.name + ' copy'
        },
      },
    }).then(value => {
      if (value !== null) {
        const apiConf = {
          method: 'post',
          link: 'ebooks/copy_chapter'
        }
        superAxiosRequest(apiConf, {
          chapter_id: props.chapter.id,
        }).then(({data}) => {
          addChapter(data)
        })
      }
    })
  }

  // Удаление главы
  const deleteChapter = () => {
    swal({
      text: `${translate.delete_chapter} "${props.chapter.name}"?`,
      icon: "warning",
      buttons: [translate.no, translate.yes],
      dangerMode: true,
    }).then(yes => {
      if (yes) {
        const apiConf = {
          method: 'delete',
          link: 'ebooks/chapters/' + props.chapter.id
        }
        superAxiosRequest(apiConf).then(() => {
          removeChapter(props.chapter.id)
        })
      }
    })
  }

  return (
    <div className={st.card}>
      <div className={st.header}>
        <div className={st.section}>
          {(checkPermList(permissions.change_chapter) &&
           <div className={st.arrows}>
                <div className={cn({
                  [st.arrow]: true,
                  [st.arrowRotate]: true,
                  [st.arrowHide]: props.position === 1,
                })}>
                  <ArrowDown className={st.arrowIcon}
                             onClick={() => props.position > 1 && props.changePosition(props.chapter.id, -1)}/>
                </div>
                <div className={cn({
                  [st.arrow]: true,
                  [st.arrowHide]: props.last === props.position
                })}>
                  <ArrowDown onClick={() => props.last !== props.position && props.changePosition(props.chapter.id, 1)}/>
                </div>
          </div>
          )}

          <div className={st.pic}>
            {props.chapter.image ?
              <img src={Stores.baseLink() + props.chapter.image} alt={props.chapter.name}/>
              :
              <div className={st.noPhoto}>
                <NoPhotoIcon/>
              </div>
            }
          </div>
          <Link to={`/ebooks/${Stores.isClient ? 'my-' : ''}chapter/${props.chapter.id}`} className={st.chapterName}
                target="_blank">
            {props.chapter.name}
          </Link>
        </div>
        <SuperButton variant="badge" type="badgeColored" className={st.exercisesCountBadge}>
          {props.chapter.exercises_count} {getWordEnd(props.chapter.exercises_count, exerciseWordForms)}
        </SuperButton>
        <div className={st.buttonsBlock}>
          {/* EDITP*/}
          {(checkPermList(permissions.change_chapter)) && <ActionMenu menu={menu} action={action}/>}
          <SuperButton variant="link" type="linkColored" path={`/ebooks/${Stores.isClient ? 'my-' : ''}chapter/${props.chapter.id}`}
                       className={st.linkBtn}>
            <span className={st.linkText}>{translate.open_lesson}</span>
          </SuperButton>
        </div>
      </div>
    </div>
  )
}

ChapterCard.propTypes = {
  chapter: PropTypes.object,
}

export default ChapterCard
