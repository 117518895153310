import {dictionary} from './dictionary'

export const localisationMaster =  (description, link, tag) =>{
    let lang;
    let dataStorage = localStorage.getItem('ud');
    let data = JSON.parse(dataStorage);
    if(data !== null && data.user_data.profile.localization !== undefined){
        lang = data.user_data.profile.localization;
    }else{
        lang = 'RU';
    }
    //var default_lang = 'en';

    let returned, ready;
    returned = dictionary.filter(x=>x['link'] === link)
  
    if(tag){
        returned = dictionary.filter(x=>x['link'] ===  link && x['tag'] ===  tag);
    }
    
    if(returned.length > 0){
        returned = returned[0]['translation'].filter(x=>x['description'] === description)
        if(returned.length > 0){
        ready = returned[0][lang.toLowerCase()]
        } else {
            ready = 'lang error';
        }
    }
    return ready
};

const typeLang = JSON.parse(localStorage.getItem('ud') || '{}')?.user_data?.profile?.localization;
const languages = { KZ: 'kz', RU: 'ru', EN: 'en' };
const lang = languages[typeLang || 'RU'];

export const translate = (data, word) => { 
    if(!(data || data?.[lang])) return word || 'lang error';
    return data[lang];
}

// Документация:
// 1) translate(dict.work_table_listing.show_employees, "показать сотрудников")
// Описание: Первый аргумент это путь до перевода. Второй аргумент какое слово там лежит


