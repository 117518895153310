import React from 'react';
import PropTypes from 'prop-types';
import st from "./VariantDel.module.scss";

const VariantDel = ({onClick, isDisabled}) => {
    return (
        <div className={st.del} onClick={onClick} disabled={isDisabled}>&#215;</div>
    );
};

VariantDel.propTypes = {
    onClick: PropTypes.func.isRequired
}

VariantDel.defaultProps = {
    onClick: () => {}
}

export default VariantDel
