import React from 'react';
import PropTypes from "prop-types";
import {classNames as cn} from "helpers"
import styles from'./ToggleCheckbox.module.scss'
import {ReactComponent as CrossIcon} from 'components/UI/icons/close-line.svg';
import {ReactComponent as CheckMarkIcon} from 'components/UI/icons/check-fill.svg';

export default function ToggleCheckbox(props) {
  return (
    <div className={cn({
      [styles.toggleCheckbox]: true,
      [styles.toggleCheckbox__disabled]: props.disabled,
    })}
         onClick={() => !props.disabled && props.onChange(!props.checked)}>

      <span className={cn({
        [styles.toggle]: true,
        [styles.toggle__checked]: props.checked
      })}>
        {props.checked ? <CheckMarkIcon className={styles.checkMark}/> : <CrossIcon className={styles.cross}/>}
      </span>

      {props.label && <div
        className={`${styles.label} ${props.labelClassName || ''}`}>{props.label}</div>
      }

    </div>
  );
}

ToggleCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
