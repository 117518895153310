import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './TextArea.module.scss';

const TextArea = forwardRef(({
  tag,
  name,
  label,
  value,
  placeholder,
  onChange,
  withLabel,
  isError,
  isResize,
}, ref) => {
  const Tag = tag;

  return (
    <Tag ref={ref} className={styles.wrapper}>
      <label aria-label={label}>
        {withLabel && <span className={styles.label}>{`${label}:`}</span>}
        <textarea
          name={name}
          className={cn({
            [styles.textarea]: true,
            [styles.error]: isError,
            [styles.resize]: isResize,
          })}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </label>
    </Tag>
  );

});

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.oneOf(['div', 'li']),
  withLabel: PropTypes.bool,
  isError: PropTypes.bool,
  isResize: PropTypes.bool,
};

TextArea.defaultProps = {
  value: '',
  tag: 'div',
  withLabel: true,
  isError: false,
  isResize: true,
};

export default TextArea;
