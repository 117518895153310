import React from "react";
import {dateFormat} from "helpers/date";
import groupStore from "Stores/groupStore";
import st from "./ModalStudyPeriod.module.scss";
import asideModalStore from "Stores/asideModalStore";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";
import {observer} from "mobx-react";

const getExcludeDates = workshops => {
  const
      startDate = new Date(workshops[0].date),
      endDate = workshops[workshops.length-1].date,
      dates = workshops.map(ws => ws.sDate),
      excludeDates = []

  let start
  while (startDate <= endDate){
    start = dateFormat(startDate, "YYYY-mm-dd")
    !dates.includes(start) && excludeDates.push(new Date(startDate))
    startDate.setDate(startDate.getDate() + 1)
  }

  return excludeDates
}

const ModalStudyPeriod = () => {
  const {wsData: {workshops: wsDays}} = groupStore
  const {modalValues, changeValues, changeValue, check} = asideModalStore

  const workshops = wsDays.map(ws => ({
    ...ws,
    date: new Date(`${ws.date}T${ws.start_at}`),
    sDate: ws.date
  }))

  const workshop_ids = workshops.map(x => x.id)
  const excludeDates = getExcludeDates(workshops)

  const onChangeDate = (val, key) => {
    const index = workshops.findIndex(x => dateFormat(x.date) === dateFormat(val))

    if (key === 'start_study_at'){
      changeValues({
        ...modalValues,
        start_study_at: dateFormat(val, "YYYY-mm-dd"),
        start_workshop_id: workshop_ids[index],
        workshop_id: workshop_ids.slice(0, index)
      })
    } else if (key === 'finish_study_at'){
      changeValues({
        ...modalValues,
        finish_study_at: val ? dateFormat(val, "YYYY-mm-dd") : null,
        finish_workshop_id: workshop_ids[index],
        workshop_id: [...modalValues.workshop_id, ...workshop_ids.slice(index + 1)],
      })
    }
  }

  return (
      <div className={st.box}>
        <FormDateSelector
            placeholder="Start Date"
            excludeDates={excludeDates}
            isRequired={true}
            button={{name: "Today", action: "today"}}
            label="Start Date"
            onChange={date => onChangeDate(date, 'start_study_at')}
            value={modalValues.start_study_at || ''}
            minDate={workshops[0].date}
            maxDate={workshops[workshops.length-1].date}
            error={check('start_study_at')}
        />
        <FormDateSelector
            placeholder="End Date"
            excludeDates={excludeDates}
            isRequired={false}
            button={{name: "Reset", action: "reset"}}
            label="End Date"
            onChange={date => onChangeDate(date, 'finish_study_at')}
            minDate={modalValues.start_study_at ? new Date(modalValues.start_study_at) : workshops[0].date}
            maxDate={workshops[workshops.length-1].date}
            value={modalValues.finish_study_at || ''}
        />
        <div className={st.attention}>
          Changing training dates may lead to changes in the client's transactions and balance!
        </div>
        <ToggleCheckbox
            label="Send notification"
            checked={modalValues.send_email}
            onChange={() => changeValue(!modalValues.send_email, "send_email")}
        />
      </div>
  )
}

export default observer(ModalStudyPeriod)