import React, { useState, useEffect } from 'react';
import { parcedSec } from '../Online-schedule/components/functions';
import NoResults from './NoResults';
import AnswerQuestion from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerQuestion";
import AnswerFiles from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerFiles";
import './ShowInitTest.scss';
import {localisationMaster} from "Localisation/Localisation";


export default function ShowInitTest(props) {
    const [showedTry, setshowedTry] = useState(1)
    const [qValue, setqValue] = useState({ id: 0, value: null })

    const changeQuestionHandler = id => {
        let a = props.item.list[showedTry - 1].answers_student.find(el => el.question.id === id);
        if (!a) return;
        setqValue({ id, value: a });
    }

    const getQuestionButtonClassname = id => {
        let a = props.item.list[showedTry - 1].answers_student.find(el => el.question.id === id);
        if (!a || props.disableAnswers) return 'showInitTest__questBtn';
        if (Array.isArray(a.correct_answers)) {
            return `showInitTest__questBtn showInitTest__questBtn--many ${qValue.id === id ? 'showInitTest__questBtn--many--selected' : ''}`
        } else {
            return `${a.correct_answers
                ? `showInitTest__questBtn showInitTest__questBtn--correct ${qValue.id === id ? 'showInitTest__questBtn--correct--selected' : ''}`
                : `showInitTest__questBtn showInitTest__questBtn--wrong ${qValue.id === id ? 'showInitTest__questBtn--wrong--selected' : ''}`
                }`
        }
    }

    const checkCount = (key) => {
        let count = 0;
        let list = props.item.list[showedTry - 1]?.answers_student || []
        for (let x = 0; x <= list.length; x++) {
            count += list[x]?.[key] || 0
        }
        return count > 0 ? count : 'Нет'
    }

    const checkBadBoy = () => {
        if (!props.item.mouse_freeze && !props.item.focus_live) {
            return <strong>{localisationMaster('text_18', 'study/test')}</strong>
        } else {
            return <>
                {!!props.item.focus_live &&
                <strong>{`${localisationMaster('text_17', 'study/test')}    ${checkCount('focus_live_count')}`}</strong>}
                {!!props.item.mouse_freeze &&
                <strong>{`${localisationMaster('text_16', 'study/test')}  ${checkCount('mouse_freeze_count')}`}</strong>}
            </>
        }
    }

    useEffect(() => {
        props.changeWait()
    }, [qValue])

    return (
        props.item.list.length ?
            <div className="showInitTest" style={{ height: props.disableAnswers ? '300px' : '' }} >
                <div className="showInitTest_tries">
                {props.item.list.map((el, index) => (
                    <div key={el.id}
                        className={`${showedTry -1 === index ? 'showInitTest_tries--selected' : 'showInitTest_tries--unit'}`}
                        onClick={() => {setshowedTry(index + 1);}}
                    >
                        {localisationMaster('text_12', 'study/test')} {index + 1}
                    </div>
                ))}
                </div>
                <div className="showInitTest_showTries">
                    <div className="showInitTest__info">
                        <div>
                            <div className="showInitTest__infoLine">
                                <span>{localisationMaster('text_5', 'study/test')}: </span>
                                <span><strong>{parcedSec(props.item.list[showedTry - 1]?.time_taken) || 0}</strong></span>
                            </div>
                            {!props.disableAnswers &&
                            <div className="showInitTest__infoLine">
                                <span>{localisationMaster('text_13', 'study/test')}: </span>
                                <div className="showInitTest__infoFail">{checkBadBoy()}</div>
                            </div>}
                        </div>
                        <a href={`/study/simple-result/${props.item.list[showedTry - 1].id}`} target="_blank">
                            <strong>{localisationMaster('table-row-title-5', 'tests/widget-clients')}</strong>
                        </a>
                    </div>

                    <div className="showInitTest__questions" style={{ border: props.disableAnswers ? 'none' : '' }}>
                        {props.item.list[showedTry - 1]?.is_correct_answers.map((el, index) => (
                            <div key={index} className={getQuestionButtonClassname(el.id)} onClick={() => changeQuestionHandler(el.id)}>{index + 1}</div>
                        ))}
                    </div>

                    <div className="showInitTest_question">
                        <div className="showInitTest_questionBody">{!props.disableAnswers && qValue.value !== null ?
                            <AnswerQuestion answer={qValue.value} modal={true} /> :
                            <NoResults text={localisationMaster('text_14', 'study/test')} />}
                        </div>
                    </div>
                </div>
            </div>
            : <NoResults text={localisationMaster('text_14', 'study/test')} />
    )
}

