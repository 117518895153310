import React, {useState} from 'react';
import {parcedSec} from "components/ContentBox/Online-schedule/components/functions";
import {localisationMaster} from "Localisation/Localisation";
import StudyTestResultStore from "Stores/studyTestResultStore";
import ResultSwitcher from "./ResultSwitcher";
import BriefAnswers from "./BriefAnswers";
import CompleteAnswers from "./CompleteAnswers/CompleteAnswers";
import st from "./ResultAnswers.module.scss";
import {ReactComponent as TimerIcon} from "components/ContentBox/img/clock.svg";
import {ReactComponent as TrueAnswer} from "components/ContentBox/img/true.svg";
import {ReactComponent as WrongAnswer} from "components/ContentBox/img/false.svg";

export default function ResultAnswers() {
    const {result} = StudyTestResultStore

    const [type, setType] = useState(0)

    return (
        <>
            <div className={st.switcher}>
                <ResultSwitcher value={type} onChange={setType}/>
            </div>
            <div className={st.result}>
                <div className={st.header}>
                    <p className="placementTestResult__total--time">
                        <TimerIcon/>
                        {result.test_duration
                            ? parcedSec(result.test_duration)
                            : localisationMaster("text_3", "placement_test/result")}
                    </p>
                    <p className="placementTestResult__total--correct">
                        <TrueAnswer/>
                        {result.stats.correct_answers}
                    </p>
                    <p className="placementTestResult__total--mistake">
                        <WrongAnswer/>
                        {result.stats.incorrect_answers}
                    </p>
                </div>
                {type === 1 ? <CompleteAnswers/> : <BriefAnswers/>}
            </div>
        </>
    )
}
