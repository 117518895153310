import React from 'react';
import PropTypes from 'prop-types';
import Stores from "Stores";
import useFileUpload from "hooks/useFileUpload";
import st from "./ExerciseFile.module.scss";
import FileSelector from "components/UI/FileSelector/FileSelector";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import VariantDelete from "../VariantDelete/VariantDelete";

const ExerciseFile = props => {
    const {refInput, progress, setFile} = useFileUpload({
        onUploadEnd: file => props.onChange({...props.file, file,})
    })
    return (
        <>
                {props.file.file ?
                    <div className={st.container}>
                        <div className={st.box}>
                            <div className={st.variant}>
                                <div className={st.wrapImg}>
                                    {props.accept === 'video/*' ?
                                      <video src={`${Stores.baseLink()}${props.file.file}`}/>
                                      :
                                      <img src={`${Stores.baseLink()}${props.file.file}`} alt=""/>}
                                </div>
                                <div className={st.remove}>
                                    <VariantDelete onClick={props.remove}/>
                                </div>
                            </div>
                        </div>
                        <FormField>
                            <FormInput isError={props.error}>
                                <input type="text"
                                       placeholder={props.type === 'video' ? 'Video Description' : 'Image Description'}
                                       name="description"
                                       value={props.file.description}
                                       onChange={e => props.onChange({...props.file, description: e.target.value})}
                                />
                            </FormInput>
                        </FormField>
                    </div>
                    :
                    // <div className={st.choose}>
                    //     <input type="file"
                    //            ref={refInput}
                    //            onChange={e => {setFile(e.target.files[0])}}
                    //            accept={props.accept}/>
                    //
                    //     <button onClick={() => {refInput.current.click()}}>
                    //         {localisationMaster('modal_doc_text_5', 'lessons/course')}
                    //     </button>
                    //
                    //     <div className={st.tip}>Поддерживаемые форматы: <span>{props.allowedExtension.join(', ')}</span></div>
                    //
                    //     {progress > 0 && progress < 100 &&
                    //     <div className={st.progress}>
                    //         <progress max="100" value={progress}/>
                    //         <div>{progress} %</div>
                    //     </div>}
                    // </div>

                    <FileSelector
                        error={props.error}
                        type={props.type}
                        types={props.allowedExtension}
                        value={''}
                        onChange={(file) => props.onChange({...props.file, file})}
                    />
                }

        </>
    );
};

ExerciseFile.propTypes = {
    file: PropTypes.shape({
        file: PropTypes.string,
        description: PropTypes.string,
    }),
    onChange: PropTypes.func,
    error: PropTypes.bool,
    allowedExtension: PropTypes.arrayOf(PropTypes.string),
    accept: PropTypes.string,
}

export default ExerciseFile;
