import React, {useEffect, useRef, useState} from 'react';
import styles from "./SigningBlock.module.scss"
import mobileConfirm from "../../img/mobileConfirm.png"
import {superAxiosRequest} from '../../../../axiosApi';


const SigningBlock = ({userData,
    documentTemplateId, className,
    docsData, json_text, apiLink}) => {
    const [state, setState] = useState(
        {
            sendCode: false,
            confirmCode: false,
            success: false,

        }
    )
    const [inputsState, setInputsState] = useState({
        value: [
            {value: '', maxLength: 1, autoFocus: false},
            {value: '', maxLength: 1, autoFocus: false},
            {value: '', maxLength: 1, autoFocus: false},
            {value: '', maxLength: 1, autoFocus: false},
            {value: '', maxLength: 1, autoFocus: false},
            {value: '', maxLength: 1, autoFocus: false},
        ],
        valueLenght: 0,
    })


    const checkCondition = (localStorageData) => {
        let isDirty = {
            value: false,
            massage: ''
        }

        if (localStorageData) {
            const checkList = [
                {condition: localStorageData[documentTemplateId] ? ((new Date() - new Date(localStorageData[documentTemplateId].time)) / 60000) < 5 : false, massage: 'Еще не прошло 5 мин'},
                {condition: localStorageData[documentTemplateId] ? localStorageData[documentTemplateId].attemptsToday >= 3 : false, massage: 'Превышенно количество попыток за сегодня'},
            ]
            checkList.forEach(item => {
                if (item.condition) {
                    isDirty = {value: true, massage: item.massage}
                }
            })
        }


        return isDirty
    }

    const getCode = () => {
        const requestCondition = checkCondition(state.localStorageData)

        if (!requestCondition.value) {
            superAxiosRequest({
                method: 'put',
                link: `${apiLink}/${state.documentId}`
            }, {
                json_text: {...json_text}
            }).then(res => {
                superAxiosRequest({
                    method: 'put',
                    link: `${apiLink}/${state.documentId}`
                }, {
                    send_email: true,
                }).then(res => {
                    let newLocalStorageData = {
                        [documentTemplateId]: {
                            id: state.documentId,
                            time: new Date(),
                            attemptsToday: state.localStorageData && state.localStorageData[documentTemplateId] ?
                                state.localStorageData[documentTemplateId].attemptsToday + 1 : 1,
                        },
                        ...state.localStorageData
                    }
                    localStorage.setItem('signedDocs', JSON.stringify(newLocalStorageData))
                    setState({
                        ...state,
                        sendCode: false,
                        confirmCode: true,
                        success: false,
                        localStorageData: newLocalStorageData
                    })
                })

            })

        }
        else {
            setState(prev => ({...prev, error: {value: true, massage: requestCondition.massage}}))
        }

    }
    const confirmCode = () => {
        let body = {
            email_code: '',
        }

        inputsState.value.forEach(item => body.email_code = `${body.email_code}${item.value}`)
        superAxiosRequest({
            method: 'put',
            link: `${apiLink}/${state.documentId}`,
        }, {
            email_code: Number(body.email_code),
            json_text: {...json_text}
        }).then(res => {
            setState({
                ...state,
                sendCode: false,
                confirmCode: false,
                success: true
            })
        }).catch(err => {
            switch (err.response.status) {
                case 409:
                    setState({...state, alert: {value: true, massage: 'Неверный код'}})
                    break;

                default:
                    setState({...state, error: {value: true, massage: 'Ошибка при отправке кода на сервер'}})
                    break;
            }
        })

    }

    useEffect(() => {
        let newInputState = [...inputsState.value]
        inputsState.value.forEach((item, id) => {
            const inputRef = React.createRef()
            newInputState[id] = {...newInputState[id], ref: inputRef}
        })
        setInputsState({
            ...inputsState,
            value: newInputState,
            length: 0
        })
    }, [])

    useEffect(() => {
        setState(docsData)
    }, [docsData])


    return (
        <div className={ docsData.isMyDocs ? className : styles.display_none}>
            {state.sendCode && (
                <div className={styles.confirmBox}>
                    <img src={mobileConfirm} alt="" className={styles.confirmBox__img} />
                    <div className={styles.confirmBox__title}>Подписать договор</div>
                    <div className={styles.confirmBox__text}>Для подтверждения подписания договора, мы отправим вам код на email:</div>
                    <div className={styles.confirmBox__number}>{userData.email}</div>
                    <div className={styles.confirmBox__change}><a target='_blank' href={`/account/${userData.id}`} className="">изменить email</a></div>
                    {state.error.value && <div className={styles.error}> {state.error.massage}</div>}
                    {state.alert.value && <div className={styles.error}> {state.alert.massage}</div>}

                    <button
                        disabled={state.error.value}
                        onClick={() => getCode(state)}
                        className={styles.confirmBox__btn}>Отправить код</button>
                </div>
            )}
            {state.confirmCode && (
                <div className={styles.confirmBox}>
                    <img src={mobileConfirm} alt="" className={styles.confirmBox__img} />
                    <div className={styles.confirmBox__title}>Подписать договор</div>
                    <div className={styles.confirmBox__text}>Для подтверждения подписания договора, мы отправим вам код на email:</div>
                    <div className={styles.confirmBox__number}>{userData.email}</div>
                    <div className={styles.confirmBox__change}><a target='_blank' href={`/account/${userData.id}`} className="">изменить email</a></div>
                    <div className={styles.input__box}>

                        {inputsState.value.map((input, id) => <input key={id}
                            onChange={e => {
                                let newState = [...inputsState.value]
                                let valueLenght = inputsState.valueLenght
                                newState[id] = {...newState[id], value: e.target.value, autoFocus: false}
                                if (String(e.target.value).length !== 0) { //перенаправляет focus между инпутами
                                    if (id !== inputsState.value.length - 1) newState[id + 1].ref.current.focus()
                                    valueLenght = valueLenght + 1
                                }
                                else {
                                    if (id !== 0) newState[id - 1].ref.current.focus()
                                    valueLenght = valueLenght - 1
                                }

                                setInputsState({
                                    ...inputsState,
                                    value: newState,
                                    valueLenght: valueLenght
                                })
                            }}

                            ref={input.ref}
                            value={input.value}
                            maxLength={input.maxLength} className={styles.input} type="text" />)}
                    </div>
                    {state.error.value && <div className={styles.error}> {state.error.massage}</div>}
                    {state.alert.value && <div className={styles.error}> {state.alert.massage}</div>}

                    <button
                        disabled={state.error.value || inputsState.value.length > inputsState.valueLenght}
                        onClick={() => confirmCode()}
                        className={styles.confirmBox__btn}>Подтвердить код</button>
                </div>
            )}
            {state.success && (
                <div className={styles.confirmBox}>
                    <img src={mobileConfirm} alt="" className={styles.confirmBox__img} />
                    <div className={styles.confirmBox__title_success}>Договор подписан!</div>
                    <div className={styles.confirmBox__text}>Вы можете ознакомиться с ним в любое время на странице списка договоров.</div>

                </div>
            )}
        </div>
    )
}

export default SigningBlock