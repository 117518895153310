import React, { useContext } from 'react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import {classNames as cn } from 'helpers';
import { getLevels } from 'components/Modules/Slots/utils';
import { Context } from '../SlotDetails';
import styles from '../SlotDetails.module.scss';

export default function Results() {
  const { slot_client } = useContext(Context);
  const {is_missed, training_level_id, training_level_name, comment } = slot_client;

  return (
    <Paper
      classes={cn({
        [styles.results]: true,
        [styles.results__missed]: is_missed,
      })}
    >
      <SectionTitle title='Итоги записи' secondary />
      {!is_missed &&
        <Flex align='start' withGap>
          {training_level_id &&
            <div className={styles.results_level}>
              <p className={styles.results_subtitle}>Уровень:</p>
              <ul>
                {
                  getLevels(training_level_name).map(({ name, level }, index) => (
                      <li className={styles.results_level_item} key={index.toString()}>
                        <span className={styles.results_level_name}>{name}: </span>
                        <span>{level}</span>
                      </li>
                    ))
                }
              </ul>
            </div>
          }
          {comment &&
            <div className={styles.results_comment}>
              <p className={styles.results_subtitle}>Комментарий:</p>
              <p>{comment}</p>
            </div>
          }
          {!training_level_id && !comment &&
            <p className={styles.results_no_data}>Данных еще нет</p>
          }
        </Flex>
      }
      {is_missed && <p>Клиент пропустил запись!</p>}
    </Paper>
  );
}

