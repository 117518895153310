import React, {useEffect, useState} from "react";
import {superAxiosRequestEasy} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import { timeConverter } from "../functions";
import { addZero } from "helpers/math";
import Stores from "Stores";
import Checkbox from "components/ContentBox/Global/Checkbox";
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import TimeSlot from "../TimeSlot/TimeSlot";
import "./SlotSelector.scss"

const SlotSelector = React.forwardRef((props, ref) => {

    const [slotId, setSlotId] = useState(0)
    const [isOnline, setIsOnline] = useState(true)
    const [slots, setSlots] = useState([])
    const [timeZone, setTimeZone] = useState(props.location?.time_zone || 6)

    const changeIsOnline = () => {
        setIsOnline(!isOnline);
        setSlotId(0);
    }

    useEffect(()=>{
        props.onChangeSlot(slotId)
    }, [slotId])

    useEffect(()=>{
        if (props.day && props.location){
            const
                day = props.day,
                locationId = props.location.id

            let apiConf = {
                method: 'get',
                link: 'time_slots/student/free_slots',
                params: {
                    page: 1,
                    location: locationId,
                    date: day,
                    is_active: true,
                    age: props.age_id,
                    program: props.program_id
                }
            }
            superAxiosRequestEasy(apiConf, 'data').then(response=>{
                setSlots(response.data);
                setSlotId(0);
            });
        }
    }, [props.day, props.location])

    const onlineFilter = array => {
        if (isOnline){
            return array
        } else {
            array = array.filter(x => x.is_online === false)
            return array
        }
    }

    const offsetTime = time => {
        let baseTZ = props.location.time_zone;

        let ts = new Date(`${timeConverter(new Date(), 'today')}T${time}`)
            if (baseTZ > 0){
                ts = ts.setHours(ts.getHours() - baseTZ + timeZone)
            } else if (baseTZ < 0){
                ts = ts.setHours(ts.getHours() + baseTZ - timeZone)
            } else {
                ts = ts.setHours(ts.getHours() + timeZone)
            }
            ts = new Date(ts)
            return `${addZero(ts.getHours())}:${addZero(ts.getMinutes())}`
    }


    return (
        <div className="record-customer-fieldset-container" ref={ref}>

            {slots.length>0 &&
            <div className="slotSelector__header">
                <div className="slotSelector__title">
                    {localisationMaster('time', 'online-schedule/record-customer')}
                </div>
                <div>
                    {!props.widget &&
                    <Checkbox
                        id="duas"
                        label={localisationMaster('checkbox-info', 'online-schedule/record-customer')}
                        checked={isOnline}
                        isChecked={changeIsOnline}
                    />
                    }
                </div>
                <div className="slotSelector__selectTimeZone">
                    <SelectCustom
                        placeholder="Выберите часовой пояс"
                        valueID={+timeZone}
                        className='w100'
                        options={Stores.timeZones}
                        onChange={o => setTimeZone(o.id)}
                    />
                </div>
            </div>}

            {props.day && slots.length === 0
                ?
                <div className="slotSelector__error">
                    <p>{localisationMaster('error-text', 'online-schedule/record-customer')}</p>
                    {props.widget &&
                    <>
                        <p>Для записи на удобное время, вам необходимо связаться с администратором.</p>
                        <p>Единый Call-центр:</p>
                        <p><strong><a href="tel:+7 (777) 757 57 57">+7 (777) 757 57 57</a></strong></p>
                    </>}
                </div>
                :
                <div className="slotSelector__slots">
                    {onlineFilter(slots).map(item =>
                        <TimeSlot key={item.id} slot={item} slotId={slotId} setSlotId={setSlotId}
                                  tz={props.location.time_zone} offsetTime={offsetTime} />
                    )}
                </div>
            }
        </div>
    )
})

export default SlotSelector
