import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import Stores from "Stores";
import lessonStudentStore from "./lessonStudentStore";
import Loader from "components/UI/Loader/Loader";
import {LessonContext} from "../BookChapter/LessonContext";
import Exercise from "../Common/Exercise/Exercise";
import BookPaper from "../Common/BookPaper/BookPaper";
import LessonToolbar from "./LessonToolbar";
import st from "./LessonStudent.module.scss";
import noExercisesImg from "components/UI/illustrations/streamline-work-from-home.svg";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import useLocalization from "../../../../hooks/useLocalization";
import DrawingBoard from "../LessonTeacher/DrawingBoard/DrawingBoard";

function LessonStudent(){
  const {uuid} = useParams()

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "save_answer": "Save Answer",
      "add_to_favorites": "Add to Favorites",
      "delete": "Delete",
      "active_lesson": "Active Lesson"
    }
  })

  const {fetchLesson, errorStatus, lesson, focusExercise, setFocus, clearLesson} = lessonStudentStore

  const context = {mode: 'student'}
  const [showBoard, setShowBoard] = useState(false)

  useEffect(() => {
    fetchLesson(uuid)

    const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/ebooks/init_lesson/${uuid}/?token=${localStorage.getItem('access')}`;
    const con = new WebSocket(wsUrl)
    con.onmessage = ({data}) => {
      const message = JSON.parse(data)
      if (message.type === 'assigned_exercise'){
        //setLessonExercises(message.message)
        fetchLesson(uuid)
      } else if (message.type === 'notifications.notify_focus_on_exercise'){
        setFocus(message.message.exercise_id)
      }
    }

    return () => {
      clearLesson()
    }
  }, [uuid, fetchLesson, clearLesson, setFocus])

  return (
    lesson ?
      <div className={st.lessonStudent}>
        <LessonToolbar homeworkLink={lesson.homework ? `/ebooks/my-homework/${lesson.homework}` : ''} showBoard={showBoard} setShowBoard={setShowBoard} isDrawingBoard={true}/>
        <div className={st.bookContainer}>
          <BookPaper>
            <div className={st.bookColumn}>
              <LessonHomeworkHeader title={translate.active_lesson}
                                    createdAt={lesson.created_at}
                                    ebookName={lesson.ebook_name}
                                    chapterName={lesson.name}/>
              {lesson.exercises.length > 0 ?
                <LessonContext.Provider value={context}>
                  <DrawingBoard uuid={uuid} mode={context.mode} showBoard={showBoard} setShowBoard={setShowBoard}/>
                  {lesson.exercises?.map((ex, index) =>
                    <div key={ex.id}>
                      <Exercise number={`1.${index + 1}`}
                                ex={ex}
                                isFirst={index === 0}
                                isLast={index === lesson.exercises.length - 1}
                                focused={ex.id === focusExercise}
                                translate={translate}
                      />
                    </div>)}
                </LessonContext.Provider> :
                <div className={st.noExercises}>
                  <div>
                    <img src={noExercisesImg} alt=""/>
                  </div>
                  <p>Your teacher hasn't assigned you any exercises yet.</p>
                  <p>&nbsp;</p>
                  <p>Please wait for the teacher to assign you the exercises.</p>
                </div>
              }
            </div>
          </BookPaper>
        </div>
      </div> :
      <div className={st.lessonStudent}>
        <LessonToolbar/>
        <div className={st.bookContainer}>
          <BookPaper>{errorStatus === 404 ?
            <div className={st.noExercises}>
              <div>
                <img src={noExercisesImg} alt=""/>
              </div>
              <p>404</p>
              <p>&nbsp;</p>
              <p>Lesson not found</p>
            </div> : <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(LessonStudent)
