import {action, makeObservable, observable} from "mobx";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";

export const sortingOptions = [
  {value: 0, ordered_by: 'name', reverse: false, label: localisationMaster('text_34', 'online-schedule/future-slots')},
  {value: 1, ordered_by: 'name', reverse: true, label: localisationMaster('text_35', 'online-schedule/future-slots')},
  {value: 2, ordered_by: 'date', reverse: false, label: localisationMaster('text_36', 'online-schedule/future-slots')},
  {value: 3, ordered_by: 'date', reverse: true, label: localisationMaster('text_37', 'online-schedule/future-slots')},
];

class SlotsListingStore {
  levels = [];
  clients = [];
  totalClientsCount = 0;
  sortingType = 3;

  constructor() {
    makeObservable(this, {
      clients: observable,
      levels: observable,
      totalClientsCount: observable,
      sortingType: observable,

      fetchClients: action.bound,
      fetchLevels: action.bound,
      updateSlot: action.bound,
      setSortingType: action.bound,
    });
  }

  setSortingType(option) {
    this.sortingType = option.value;
  }

  fetchClients(params, onFinally) {
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/slots',
      params,
    })
      .then(({ data }) => {
        const {result, _meta} = data;
        this.clients = result;
        this.totalClientsCount = _meta.count_items;
      })
      .finally(() => onFinally())
  }

  fetchLevels() {
    superAxiosRequest({
      method: 'get',
      link: 'groups/training_levels_catalog',
      params: {
        limit: 100,
      },
    })
      .then(({data}) => {
        this.levels = [
          {label: localisationMaster('text_6', 'online-schedule/future-slots'), options: [{ value: 0, label: localisationMaster('text_1', 'online-schedule/future-slots')}]},
          {
            label: localisationMaster('text_7', 'online-schedule/future-slots'),
            options: data.result.map((item) => ({ ...item, value: item.id, label: item.level }))
          },
        ];
      });
  }

  updateSlot(id, index, data, pageType, onSuccess, onFail) {
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/booked_schedule/${id}`,
    }, data)
      .then(() => {
        onSuccess();

        if (data.comment) {
          this.clients[index] = {...this.clients[index], comment: data.comment };
          return;
        }

        if (data.training_level) {
          if (pageType === 'future') {
            this.clients.splice(index, 1);
            this.totalClientsCount -= 1;
          } else {
            this.clients[index].training_level_id = data.training_level;
            this.clients[index].is_missed = false;
          }
          return;
        }

        if (data.is_missed) {
          if (pageType === 'future') {
            this.clients.splice(index, 1);
            this.totalClientsCount -= 1;
          } else {
            this.clients[index].is_missed = true;
          }
        }
      })
      .catch(() => onFail());
  }
}

export default new SlotsListingStore();
