import React from "react";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import st from "./MakeWords.module.scss";


const MakeWords = props => {
  return (
    <div className={st.wordForm}>
      <div className={st.wordForm_card}>
        <FormInput isError={props.error}>
          <input type="text"
                 placeholder={props.translate.words}
                 value={props.value.word}
                 className={props.error && props.value.word.length < 1 ? st.error : undefined}
                 onChange={e => props.onChange({
                   ...props.value,
                   word: e.target.value
                 })}/>
        </FormInput>
        <FormInput>
          <input type="text"
                 placeholder={props.translate.clue}
                 value={props.value.description}
                 onChange={e => props.onChange({
                   ...props.value,
                   description: e.target.value
                 })}/>
        </FormInput>
      </div>
    </div>
  )
}

export default MakeWords
