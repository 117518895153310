import React  from 'react'
import {localisationMaster} from "Localisation/Localisation";
import style from './CounterOnline.module.scss'

export default function CounterOnline(props) {
    return (
        <div className={style.counterBox}>
            <div className={`${props.value === false ? style.counterBox__buttonTrue : style.counterBox__buttonFalse }`}
                 onClick={() => {props.onChange(false, props.name)}}>
                {localisationMaster('text_29a', 'groups/all-groups')}
            </div>
            <div className={`${props.value === true ? style.counterBox__buttonTrue : style.counterBox__buttonFalse }`}
                 onClick={() => {props.onChange(true, props.name)}}>
                {localisationMaster('text_29', 'groups/all-groups')}
            </div>
        </div>
    )
}
