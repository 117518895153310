import React from "react";
import {NavLink} from "react-router-dom";
import Stores from "Stores";
import Avatar from "components/HeadBox/components/Avatar/Avatar";
import st from "./TeacherCard.module.scss";

export default function TeacherCard({teacher}) {
  return (
    <div className={st.container}>
      <div className={st.card}>
        <div className={st.avatar}>{teacher.avatar ?
          <img src={Stores.baseLink() + teacher.avatar} alt={teacher.first_name}/> :
          <Avatar first_name={teacher.first_name} last_name={teacher.last_name}/>}
        </div>
        <div className={st.info}>
          <span>
            <NavLink to={'/account/' + teacher.user_id}>
              {Stores.transliterate(teacher.last_name + ' ' + teacher.first_name)}
            </NavLink>
          </span>
        </div>
      </div>
    </div>
  )
}
