import { localisationMaster } from 'Localisation/Localisation';
import listingStore from 'Stores/listingStore';

export const study = [
    {
        link: 'study-test',
        folder: 'study',
        permission: ['studytest', 'view'],
        button: [localisationMaster('add_test', 'study/study-test'), 'studytest', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'add-study-test',
            link: 'study/study_test',
            button: localisationMaster('text_1', 'groups/all-groups'),
            title: localisationMaster('text_1', 'study/study-test'),
            type: 'add-study-test',
            filter: true,
            func: listingStore.updateData,
            valid: {
                company: "required",
                name: "required",
                level: "required",
                sub_lvl: "required",
            },
        },
        title: localisationMaster('text_2', 'study/study-test'),
        colsSettings: {
            action: [
                //{ name: 'Страница теста', nav_link: true, link: '/c/study/study-test-questions', permission: ['studytest', 'add'] },
                { name: localisationMaster('text_3', 'study/study-test'), nav_link: true, link: '/study/test-questions', permission: ['studytest', 'view'] },
                { name: localisationMaster('text_4', 'study/study-test'), action: 'purpose_test', permission: ['purposetestteacher', 'add'] },
                { name: localisationMaster('text_5', 'study/study-test'), action: 'purpose_test_st', permission: ['purposetestteacher', 'add'] },
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_forward', permission: ['studytest', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['studytest', 'delete'], deletePrev: `${localisationMaster('text_6', 'study/study-test')}:` },
            ],
            link: 'study_test',
            prefix: 'study',
            id: { name: 'id' },
            main_rows: [
                { name: 'name',  },
                {
                    compiled: [
                        { name: 'name', updir: 'sub_lvl', sub_dir: 'lvl', past: ' | '},
                        { name: 'name', updir: 'sub_lvl'},
                    ],
                },
                { name: 'quantity_questions', prev: `${localisationMaster('table-row-title-3', 'tests/settings')}: `, classname: 'tar'},
            ],
            is_open: false,
            sub_rows: [
                { name: 'description', description: localisationMaster('text_7', 'study/study-test'), },
            ]
        },
        surfMenu: true,
        apiParams: {filter_type: 1},
        filterLinerLink: 'study/study_test',
        filterLiner: true,
        config: [
            { id: 2, nameState: 'name', placeholder: localisationMaster('text_18', 'study/study-test'), type: 'input' },
            { id: 3, apiLink: 'db/companies', nameState: 'company', placeholder: localisationMaster('text_8', 'study/study-test'), type: "select_request" },
            { id: 4, apiLink: 'study/levels', nameState: 'level', placeholder: localisationMaster('text_12', 'online-schedule/future-slots'), type: "select_father" },
            { id: 5, nameState: 'sub_level', optionsKey: 'sub_lvl',fatherName:'level',  placeholder: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "select_son" },

        ],
    },
    {
        link: 'study-test-questions',
        folder: 'study',
        permission: ['purposetestteacher', 'view_own'],
        button: [localisationMaster('button-title', 'tests/questions'), 'studytest', 'add'],
        //button: ['Добавить тест', 'program', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'add-question-study-test',
            link: 'study/question_test',
            button: localisationMaster('text_1', 'groups/all-groups'),
            title: localisationMaster('text_9', 'study/study-test'),
            type: 'add-question-study-test',
            data: 'json',
            getMatchId: 'study_test',
            func: listingStore.updateData,
            valid: {
                point: 'required',
                answers: 'required',
                type: 'required',
                text_question: 'required',
            }
        },
        modalValues: {point: 1},
        title: localisationMaster('text_10', 'study/study-test'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit_forward', permission: ['studytest', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['studytest', 'delete'], deletePrev: `${localisationMaster('text_11', 'study/study-test')}: `, field: 'text_question' },
            ],
            link: 'question_test',
            prefix: 'study',
            id: { name: 'id' },
            is_study_questions: true,
            main_rows: [
                //{ name: 'text_question',  function: 'sliced_text', classname: 'w100'},
                { name: 'text_question',  function: 'html', classname: 'w100'},
                { name: 'point', classname: 'tar', prev: `${localisationMaster('text_19', 'study/study-test')}: `},
                { name: 'type', classname: 'tar', function: 'qtype', },

            ],
            is_open: false,
            sub_rows: [
                //{ name: 'text_question',  },
                //{ name: 'name', description: localisationMaster('table-row-title-3', 'tests/questions'), },
            ]
        },
        filter_by_id: 'study_test',
        
        apiSettings: {}
    },
    {
        link: 'purpose-test',
        folder: 'study',
        permission: ['studytest', 'view'],
        title: localisationMaster('text_12', 'study/study-test'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_3', 'study/study-test'), link: '/study/test', nav_link: true, permission: ['purposetestteacher', 'add'] },
                { name: localisationMaster('text_13', 'study/study-test'), action: 'send_email', permission: ['purposetestteacher', 'add'] },
                { name: localisationMaster('text_8', 'online-schedule/future-slots'), action: 'detail' },
            ],
            link: 'purpose_test',
            prefix: 'study',
            id: { name: 'id' },
            main_rows: [
                { name: 'name',  },
                {
                    compiled: [
                        { name: 'name', updir: 'sub_lvl', sub_dir: 'lvl', past: ' | '},
                        { name: 'name', updir: 'sub_lvl'},
                    ],
                    classname: 'tar'
                },
                { name: 'students', function: 'array_count', arr: true, f_title: `${localisationMaster('text_14', 'study/study-test')}: `, classname: 'tar' },

            ],
            is_open: false,
            sub_rows: [
                { description: localisationMaster('text_15', 'study/study-test'),
                compiled: [
                    { name: 'start_at', past: ', ' },
                    { name: 'date_from', function: 'date', },
                ]
                },
                { description: localisationMaster('text_16', 'study/study-test'),
                compiled: [
                    { name: 'finish_at', past: ', ' },
                    { name: 'date_until', function: 'date', },
                    
                ]
                },
                { description: localisationMaster('text_17', 'study/study-test'), function: 'rules', name: 'name' },
                { name: 'company', description: localisationMaster('text_21', 'modal_templates') },
                { name: 'comment', description: localisationMaster('textarea-placeholder', 'company/companies', 'add_company' ) },
                { name: 'students', description: localisationMaster('sub-title-question-3', 'index', 'sidebar_main' ), function: 'u_list', },

            ]
        },
        surfMenu: true,
        filterLiner: true,
        config: [
            { id: 1, nameState: 'search', placeholder: localisationMaster('name_11', 'tests/widget-clients'), type: 'input' },
            { id: 2, nameState: 'name', placeholder: localisationMaster('text_18', 'study/study-test'), type: 'input' },
            { id: 3, apiLink: 'db/companies', nameState: 'company', placeholder: localisationMaster('text_8', 'study/study-test'), text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "select_request" },
            { id: 4, apiLink: 'study/levels', nameState: 'level', placeholder: localisationMaster('text_20', 'study/study-test'), text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "select_father" },
            { id: 5, nameState: 'sub_level', optionsKey: 'sub_lvl', placeholder: localisationMaster('table-row-title-6', 'tests/past-tests'), fatherName: 'level', text: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "select_son" },

        ],
        filterLinerLink: 'study/purpose_test',
        apiSettings: {fiter_type: 1}
    },
    {
        link: 'my-tests',
        folder: 'study',
        permission: ['purposetestteacher', 'view_own'],
        title: localisationMaster('text_21', 'study/study-test'),
        colsSettings: {
            action: [
                { 
                    name: localisationMaster('text_22', 'study/study-test'),
                    nav_link: true,
                    blank: true,
                    link: '/init-placement-test',
                    permission: ['purposetestteacher', 'view_own'], 
                    unactive: ['available', false],
                    smaller: ['seconds_until_start' , 0],
                    more: ['seconds_until_finish' , 0],
                },
                {
                    name: localisationMaster('text_3', 'study/study-test'),
                    nav_link: true,
                    blank: true,
                    link: '/study/my-test',
                    permission: ['purposetestteacher', 'view_own'], 
                },
                { name: localisationMaster('text_8', 'online-schedule/future-slots'), action: 'detail',permission: ['forward'], },
                
            ],
            link: 'purpose_test_student',
            prefix: 'study',
            id: { name: 'id' },
            main_rows: [
                { name: 'name',  },
                {
                    compiled: [
                        { name: 'test_level', past: ' | '},
                        { name: 'test_sub_level'},
                    ],
                    classname:'tar',
                },
                {
                    compiled: [
                        { name: 'attempts_count', prev: `${localisationMaster('text_23', 'study/study-test')}: `, past: localisationMaster('of', 'pagination')},
                        { name: 'attempts_max'},
                    ],
                    classname:'tar'
                }
            ],
            is_open: false,
            sub_rows: [
                { description: localisationMaster('text_15', 'study/study-test'),
                compiled: [
                    { name: 'start_at', past: ', ' },
                    { name: 'date_from', function: 'date', },
                ]
                },
                { description: localisationMaster('text_16', 'study/study-test'),
                compiled: [
                    { name: 'finish_at', past: ', ' },
                    { name: 'date_until', function: 'date', },
                    
                ]
            },
            {name: 'test_duration', description: localisationMaster('text_24', 'study/study-test'), function: 'sec_to_min'}
            ]
        },
        surfMenu: true,
        filterLiner: true,
        config: [
            { id: 1, nameState: 'name', placeholder: localisationMaster('text_18', 'study/study-test'), type: 'input' },
        ],
        filterLinerLink: 'study/purpose_test_student',
        apiSettings: {fiter_type: 1}
    },
    {
        link: 'request-test',
        folder: 'study',
        permission: ['studytest', 'view'],
        title: localisationMaster('subtitle-question-5', 'index', 'sidebar_main' ),
        colsSettings: {
            action: [
                { name: localisationMaster('button-four', 'tests/settings', 'add_bundle' ), action: 'approve_study_request', permission: ['program', 'edit'],  unactive: ['status', 'declined'], active: ['status', 'pending']  },
                { name: localisationMaster('text_19', 'online-schedule/future-slots', ), action: 'edit_study_test', permission: ['purposetestteacher', 'add'],  unactive: ['status', 'declined'], active: ['status', 'pending'] },
                { name: localisationMaster('text_25', 'study/study-test'), action: 'close_study_request', permission: ['purposetestteacher', 'add'],  unactive: ['status', 'approved'], active: ['status', 'pending']},
                { name: localisationMaster('text_26', 'study/study-test'), action: 'new_study_request', permission: ['purposetestteacher', 'add'],  active: ['status', 'declined']},
                { name: localisationMaster('text_8', 'online-schedule/future-slots'), action: 'detail', permission: ['forward'] },

            ],
            link: 'request_test_purpose',
            prefix: 'study',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', updir: 'purposed_test'  },
                { classname: 'tar',
                    compiled: [
                    { name: 'last_name', updir: 'purposed_test', sub_dir: 'teacher' },
                    { name: 'first_name', updir: 'purposed_test', sub_dir: 'teacher', prev: ' '  },
                ],}
                // { name: 'status_request', function: 'request_test_status',  },

            ],
            is_open: false,
            sub_rows: [
                { description: localisationMaster('text_27', 'study/study-test'),
                    compiled: [
                        {name: 'date_from', function: 'date'},
                        {name: 'date_until', function: 'date', prev: ' - '}
                    ]
                },
                { name: 'name', updir: 'purposed_test', description: localisationMaster('text_3', 'modal_templates')  },
                { name: 'students', updir: 'purposed_test', description: localisationMaster('sub-title-question-3', 'index', 'sidebar_main' ), function: 'u_list_r', },
                { description: localisationMaster('text_28', 'study/study-test'),
                    compiled: [
                        {name: 'date_from', updir: 'purposed_test', function: 'date'},
                        {name: 'date_until', updir: 'purposed_test',  function: 'date', prev: ' - '}
                    ]
                },
                { description: localisationMaster('text_17', 'study/study-test'), updir: 'purposed_test', function: 'rules_r', name: 'name' },
                { name: 'company', updir: 'purposed_test', description: localisationMaster('text_21', 'modal_templates') },
                { name: 'comment', updir: 'purposed_test', description: localisationMaster('textarea-placeholder', 'company/companies', 'add_company' ) },
            ]
        },
        surfMenu: true,
        apiParams: {filter_type: 1},
        filterLinerLink: 'study/request_test_purpose',
        filterLiner: true,
        config: [
            { id: 1, nameState: 'search', placeholder: localisationMaster('name_11', 'tests/widget-clients'), type: 'input' },
            { id: 2, nameState: 'name', placeholder: localisationMaster('text_18', 'study/study-test'), type: 'input' },
            { id: 3, apiLink: 'db/companies', nameState: 'company', placeholder: localisationMaster('text_8', 'study/study-test'), text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "select_request" },
            { id: 4, apiLink: 'study/levels', nameState: 'level', placeholder: localisationMaster('text_20', 'study/study-test'), text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "select_father" },
            { id: 5, nameState: 'sub_level', optionsKey: 'sub_lvl', placeholder: localisationMaster('table-row-title-6', 'tests/past-tests'), fatherName: 'level', text: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "select_son" },

        ],
    },
]