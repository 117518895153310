import React, {useEffect, useState} from 'react'
import styles from "./LineChartTable.module.scss"
import {NavLink} from 'react-router-dom';





const LineChartTable = ({title, chartData}) => {
    const [data, setData] = useState(false)


    useEffect(() => {
        if (chartData) {
            let sectionsArry = []
            let someValue = 0
            for (let i = 0; i <= 10; i++) {

                sectionsArry.push(someValue)
                someValue = someValue + chartData.settings.interval
            }
            setData({rows: chartData.rows, sections: sectionsArry})
        }
    }, [chartData])

    return (
        <div className={styles.box}>
            <h3 className={styles.title}>{title}</h3>
            {data ? <div className={styles.chart} >
                {data.rows.map((row, rowId) =>
                    <div key={rowId} className={styles.chart__row}>
                        <div className={styles.chart__section_name}>
                            <NavLink to={'/account/' + row.user_id} target="_blank">
                                <span>{row.title}</span>
                            </NavLink>

                        </div>

                        <div className={styles.chart__subrow}>
                            <div style={{width: `${row.lenght * 82}px`}} className={styles.chart__line}>
                                <div className={styles.chart__wrapper_i}>
                                    <span className={styles.chart__info} >{row.hours}</span>
                                </div>
                            </div>
                            {data.sections.map((sec, secId) => <div key={secId} className={styles.chart__section}></div>)}
                        </div>

                    </div>

                )}

                <div className={styles.chart__row_numbers}>
                    <div className={styles.chart__sp}>

                    </div>
                    <div className={styles.chart__subrow}>
                        {data.sections.map((sect, sectId) => <div key={sectId} className={styles.chart__sectItem}>{sect}</div>)}
                    </div>

                </div>
            </div> :
                <h4> Нет данных </h4>
            }

        </div>
    )
}

export default LineChartTable