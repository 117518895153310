import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import FilterSection from './components/FilterSection/FilterSection';
import TabSection from './components/TabSection/TabSection';
import ClientList from './components/ClientsList/ClientList';
import ContentPagination from 'components/ContentBox/Global/ContentPagination/ContentPagination';
import Loader from 'components/UI/Loader/Loader';
import slotsHistoryListingStore from 'Stores/slotsHistoryListingStore';
import {translate as loc, dict} from "Localisation";



function HistoryListing() {
  const {fetchClients, totalClientsCount, activeTab, page, filtersArray, clearFilters, setPage} = slotsHistoryListingStore;
  const [isLoading, setIsLoading] = useState(true);
  const [clientsPerPage, setClientsPerPage] = useState(10);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const params = {
      offset: clientsPerPage * (page - 1),
      limit: clientsPerPage,
      ...filtersArray,
    };
    if (activeTab === 'future') {
      params.future = true;
    }
    if (activeTab === 'missed') {
      params.is_missed = true;
    }
    if(activeTab === 'past') {
      params.is_missed = false;
      params.future = false;
    }
    fetchClients(
      params,
      () => setIsLoading(false),
    );

  }, [clientsPerPage, page, activeTab, filtersArray]);

  useEffect(() => {
    return () => clearFilters();
  }, []);

  return (
    <>
      <ContentHeader title={loc(dict.history_clients.title, 'Customer record history')} />
      <FilterSection />
      <TabSection />
      {isLoading
        ? <Loader />
        : <>
            <ClientList />
            <ContentPagination
              page={page}
              allItems={totalClientsCount}
              per_page={clientsPerPage}
              changePage={(value) => setPage(value)}
              perPage={(value) => setClientsPerPage(value)}
            />
          </>
      }
    </>
  );
}

export default observer(HistoryListing);
