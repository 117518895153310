import React from "react"
import PropTypes from "prop-types";
import st from "./ExportExcelButton.module.scss";
import {ReactComponent as ExportIcon} from "components/UI/icons/file-copy-2-line.svg";

const ExportExcelButton = props => {
  return (
    <button onClick={() => {props.downloadExcel(props.data)}} className={st.button}>
      <ExportIcon/>
      <span>Выгрузить в Excel</span>
    </button>
  )
}

ExportExcelButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  downloadExcel: PropTypes.func,
}

export default ExportExcelButton
