import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {addZero} from "helpers/math";
import "./BirthdaySelect.scss";

// Дни
let aDay = Array.from({ length: 31 }, (v, i) => ++i);

// Месяцы
const aMonth = [
  { id: 1, name: "January", days: 31 },
  { id: 2, name: "February", days: 28 },
  { id: 3, name: "March", days: 31 },
  { id: 4, name: "April", days: 30 },
  { id: 5, name: "May", days: 31 },
  { id: 6, name: "June", days: 30 },
  { id: 7, name: "July", days: 31 },
  { id: 8, name: "August", days: 31 },
  { id: 9, name: "September", days: 30 },
  { id: 10, name: "October", days: 31 },
  { id: 11, name: "November", days: 30 },
  { id: 12, name: "December", days: 31 },
];

const BirthdaySelect = ({onChange, ...props}) => {
  const [birthday, setBirthday] = useState({
    day: new Date(props.value).getDate() ||  null,
    month: new Date(props.value).getMonth() + 1 || null,
    year: new Date(props.value).getFullYear() || null,
  })
  const [error, serError] = useState([]);
  // Годы
  const aYear =
    props.years === "currentPlus3"
      ? Array.from({ length: 3 }, (v, i) => i + new Date().getFullYear())
      : Array.from({ length: 122 }, (v, i) => i + 1900).reverse()

  useEffect(() => {
    const {year, month, day} = birthday;
    if (month !== null && day !== null) {
      const selectedMonth = aMonth.find(x => x.id === month);
      let isLeap = selectedMonth.id === 2 && year % 4 === 0;
      let daysInMonth = selectedMonth.days;

      if (isLeap) {
        daysInMonth += 1;
      }

      if (day > daysInMonth) {
        setBirthday({ ...birthday, day: daysInMonth });
        return;
      }

      if (year !== null) {
        const returnValue = `${year}-${addZero(month)}-${addZero(day)}`
        if (returnValue !== props.value)
          onChange(returnValue)
      }

      let updatedDays = [];
      for (let i = 1; i <= daysInMonth; i++) {
        updatedDays.push(i);
      }
      aDay = [...updatedDays];
    }
  }, [birthday, onChange, props.value]);

  useEffect(() => {
    if (props.error) {
      const values = Object.keys(birthday).filter(key => birthday[key] === null)
      serError(values);
    } else {
      serError([]);
    }
  }, [props.error, birthday]);

  return (
    <div className="birthdaySelect">
      <div className="birthdaySelect__item">
        <SelectCustom
          isDisabled={props.disabled}
          placeholder="Day"
          options={aDay.map((day) => ({
            id: day,
            name: addZero(day),
          }))}
          onChange={(o) => setBirthday({ ...birthday, day: o.id })}
          valueID={birthday.day}
          error={error.includes("day")}
          className="birthdaySelect__selectCustom"
        />
      </div>
      <div className="birthdaySelect__item">
        <SelectCustom
          isDisabled={props.disabled}
          placeholder="Month"
          options={aMonth}
          onChange={(o) => setBirthday({ ...birthday, month: o.id })}
          valueID={birthday.month}
          error={error.includes("month")}
          className="birthdaySelect__selectCustom"
        />
      </div>
      <div className="birthdaySelect__item">
        <SelectCustom
          isDisabled={props.disabled}
          placeholder="Year"
          options={aYear.map((year) => ({ id: year, name: year }))}
          onChange={(o) => setBirthday({ ...birthday, year: o.id })}
          valueID={birthday.year}
          error={error.includes("year")}
          className="birthdaySelect__selectCustom"
        />
      </div>
    </div>
  );
}

BirthdaySelect.propTypes = {
  onChange: PropTypes.func
}

export default BirthdaySelect
