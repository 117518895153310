import React, { useState } from 'react';
import CustomerItem from "./CustomerItem";
import BookedSlot from 'components/Modules/Slots/AdvancedTeachersHistory/components/CustomerList/BookedSlot';
import styles from './CustomerList.module.scss';

const CustomerList = ({ list }) => {
    const [menuID, setMenuID] = useState(null);

    const sortNames = (a, b) => {
        if(a.employee_name < b.employee_name) return -1;
        if(a.employee_name > b.employee_name) return 1; 
        return 0;
    }

    const sortSlots = (slots) => {
        return slots.map(slot => ({  ...slot, sort_date: new Date(`${slot.slot_date} ${slot.start_at}`) }))
                    .sort((a, b) => new Date(a.sort_date) - new Date(b.sort_date));
    }

    return (
        <ul className={styles.teachers_list}>
            {list.sort(sortNames).map(teacher =>
                <CustomerItem key={teacher.employee_id} fullname={teacher.employee_name}>
                    <ul className={styles.slot}>
                        {sortSlots(teacher.items[0].employee_booked_slots).map(slot =>
                            <BookedSlot
                                key={slot.id.toString()}
                                onClick={() => menuID === slot.id ? setMenuID(null) : setMenuID(slot.id)}
                                isMenuShown={slot.id === menuID}
                                slot_type_id={slot.type_id}
                                employee_id={teacher.employee_id}
                                is_deleted={!slot.is_active}
                                is_client={slot.slot_client}
                                {...slot}
                            />
                        )}
                    </ul>
                </CustomerItem>
            )}
        </ul>
    )
}

export default CustomerList;

