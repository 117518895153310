import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconSvg } from './icon.svg';
import {classNames as cn} from '../../../helpers';
import styles from './InfoIcon.module.scss';

function InfoIcon({ title, subtitle, type, isRequired, amo }) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.wrapper__active]: isShown,
      })}
      onTouchStart={() => setIsShown(true)}
      onTouchEnd={() => setIsShown(false)}
    >
      <IconSvg />
      <div className={styles.modal_wrapper}>
        <div className={styles.modal}>
          <p className={styles.title}>{title}</p>
          { type && <p className={styles.text}>Тип: {type}</p> }
          { subtitle && <p className={styles.text}>Заголовок: {subtitle}</p> }
          {isRequired !== null && <p className={styles.text}>Заполнение: {isRequired ? 'Обязательное' : 'Необязательное'}</p>}
          {amo !== null && <p className={styles.text}>Название в amoCRM: {amo || 'неизвестно'}</p>}
        </div>
      </div>
    </div>
  );
}

InfoIcon.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  amo: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['', null])]),
  isRequired: PropTypes.bool,
};

InfoIcon.defaultProps = {
  subtitle: '',
  type: '',
  amo: null,
  isRequired: null,
};

export default InfoIcon;
