import React from 'react';
import modalStore from "../../../../../../../Stores/modalStore";
import {classNames as cn} from "../../../../../../../helpers";

const ChangingBarcodeClientUnidance = () => {
    const { check, getModalValues, changeValues } = modalStore;

    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-field">
                <p className="super-modal__form-label--required">Штрих код</p>
                <input
                    onChange={e => changeValues(e.target.value.replace(/\D/g, ""), 'barcode')}
                    placeholder="Штрих код"
                    value={getModalValues?.barcode || ''}
                    maxLength={15}
                    className={cn({
                        'super-modal__input--error': check('barcode')
                    })}
                />
            </div>
        </div>
    );
};

export default ChangingBarcodeClientUnidance;