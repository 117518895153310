import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {templates} from "../../ContentBox/Global/GlobalModalSingle/templates";
import {superAxiosRequest} from "axiosApi";
import {classNames as cn} from "helpers";
import asideModalStore from "Stores/asideModalStore";
import useModalValidate from "hooks/useModalValidate";
import {CloseButton, SuperButton} from "../SuperButton/SuperButton";
import st from "./AsideModal.module.scss"
import Loader from "../Loader/Loader";
import useLocalization from "hooks/useLocalization";
import cogoToast from "cogo-toast";
import {ReactComponent as Error} from "components/ContentBox/img/error.svg"
const AsideModal = () => {
  const {modalSettings, modalValues, loading, activity, setLoading} = asideModalStore

  const {isValid} = useModalValidate(asideModalStore)

  const refModal = useRef(null)

  const closeModal = () => {
    refModal.current.addEventListener('transitionend', () => {
      activity()
    }, {once: true})
    refModal.current.classList.add(st.modalWindowHide)
  }

  const {translate} = useLocalization({
    section: 'profile',
    subsection: 'main_info',
    dict: {
      "cancel": "Cancel"
    }
  })

  const onSubmitHandler = async () => {
    if (!isValid()) return

    // Перед отправкой данных
    let data = modalValues
    if (modalSettings.onBeforeSubmit) data = await modalSettings.onBeforeSubmit(modalValues)

    setLoading(true)

    // Отправка данных
    superAxiosRequest({
      method: modalSettings.is_put ? 'put' : 'post',
      link: modalSettings.link
    }, data).then(({data}) => {
      if (modalSettings.onSubmitSuccess) modalSettings.onSubmitSuccess(data)
    }).catch(error => {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        const errors = error.response.data;
        const currentToast = cogoToast.error(
            <div className='cogo_toast_error'>
              <div className='cogo_toast_message'>
                <Error />
                <span>{translate.error}</span>
                <p>{JSON.stringify(errors)}</p>
                <button className="cogo_toast_button" onClick={() => currentToast.hide() }>OK</button>
              </div>
              <div className='cogo_toast_overlay' onClick={() => currentToast.hide() }></div>
            </div>,
            {
              hideAfter: 0,
            });
      }
    })
        .finally(() => {
      closeModal()
    })
  }

  useEffect(() => {
    refModal.current.classList.remove(st.modalWindowHide)
  }, []);

  return (
    <div className={cn({
      [st.asideModal]: true,
      [st.asideModal__center]: !!modalSettings.isCenter
    })}>
      <div className={cn({
        [st.modalWindow]: true,
        [st.modalWindowResponsive]: !!modalSettings.isResponsive,
        [st.modalWindowHide]: true
      })} ref={refModal}>
        <div className={st.modalHeader}>
          <h4 className={st.modalTitle}>{modalSettings.title}</h4>
          <CloseButton onClick={closeModal}/>
        </div>
        <div className={st.modalBody}>{loading ? <Loader/> : templates(modalSettings)}</div>
        <div className={st.modalFooter}>
          <SuperButton onClick = {closeModal} variant="reset">
            {modalSettings.cancelButtonName || translate.cancel}
          </SuperButton>
          {modalSettings.button &&
            <SuperButton onClick = {onSubmitHandler} variant="confirm">
              {modalSettings.button}
            </SuperButton>}
        </div>
      </div>
    </div>
  )
}

export default observer(AsideModal)
