import React from 'react';
import PropTypes from 'prop-types';
import VariantAdd from '../../../StudyTest/TestQuestion/components/VariantAdd';
import VariantDel from '../../../StudyTest/TestQuestion/components/VariantDel';
import st from './WordCompare.module.scss'

const WordCompare = (props) => {
  const deleteFields = (currentIdx) => {
    props.onChange(props.values.filter((_, idx) => idx !== currentIdx));
  }
  return (
    <div className={st.wordCompare}>
      {props.values.map((val, idx) => (
        <div className={st.wordCompare_row} key={idx}>
          <input
            type="text"
            placeholder={props.translate.first_word} 
            name="word_1"
            value={val.word_1}
            onChange={e => props.onChange(props.values.map((v, i) => i === idx ? {...val, word_1: e.target.value} : v))}
            className={props.error ? st.error : ''}
          />
          <input
            type="text"
            placeholder={props.translate.second_word} 
            name="word_2"
            value={val.word_2}
            onChange={e => props.onChange(props.values.map((v, i) => i === idx ? {...val, word_2: e.target.value} : v))}
            className={props.error ? st.error : ''}
          />
          <VariantDel isDisabled={idx === 0} onClick={() => deleteFields(idx)} />
        </div>
      ))}
      <VariantAdd onClick={() => {props.onChange([...props.values, {word_1: '', word_2: ''}])}}/>
    </div>
  )
}

WordCompare.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    word_1: PropTypes.string,
    word_2: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired
}

export default WordCompare
