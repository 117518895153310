import React, { useContext } from 'react';
import { Context } from '../SlotDetails';
import Paper from 'components/UI/Paper/Paper';
import Avatar from 'components/Modules/Slots/components/TeacherCards/TeacherAvatar';
import styles from '../SlotDetails.module.scss';

export default function Employee() {
  const { employee_slot } = useContext(Context);
  const { employee_name, avatar} = employee_slot;

  return (
    <Paper classes={`${styles.item} ${styles.employee}`}>
      <div className={styles.img_wrapper} >
        <Avatar name={employee_name} avatar={avatar} />
      </div>
      <h2 className={styles.title}>
        {employee_name}
      </h2>
    </Paper>
  );
}

