import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react"
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions'
import {localisationMaster} from "Localisation/Localisation"
import {superAxiosRequest} from "axiosApi";
import modalStore from 'Stores/modalStore'
import MainSelector from '../../MainSelector'
import DateSpliter from 'components/ContentBox/Global/DateSpliter/DateSpliter'
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom"
import st from './AddEbook.module.scss'
import icon from 'components/ContentBox/img/layout.svg'
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";

const AddEbook = props => {
  const [ebook, setEbook] = useState({})
  const [registerSections, setRegisterSections] = useState([])

  const {data: ms, modalValues, changeValuesArray, changeValues, check} = modalStore

  const arrayChangeHandler = ({start_date, end_date}) => {
    if (start_date)
      changeValuesArray({...modalValues, start_date, end_date})
  }

  useEffect(() => {
    if (!modalValues.ebook_id) return

    const apiConf = {
      method: 'get',
      link: 'ebooks/ebook_register_chapters',
      params: {
        ebook_id: modalValues.ebook_id,
        limit: 999,
      }
    }

    superAxiosRequest(apiConf).then(res => {
      setRegisterSections(res.data.result)
    }).catch(() => {})
  }, [modalValues.ebook_id])

  return (
    <div className="super-modal__form-column">
      <MainSelector image={icon}>
        <div className={st.addEbook}>
          <SelectCustom
            placeholder={localisationMaster('choose_course', 'groups/group', 'course_to_group')}
            apiLink="ebooks/books"
            apiConfParams={{
              is_catalog: true,
              limit: 999
            }}
            sortKey="name"
            valueID={modalValues.ebook_id}
            onChange={o => {
              changeValues(o.id, 'ebook_id')
              setEbook(o)
            }}
            error={check('ebook_id')}
            isSearchable
          />
          <div>
            <div className={st.info}>
              <span>{localisationMaster('text_81', 'groups/all-groups')}</span>
              <span>{ebook.course_level || '...'}</span>
            </div>
            <div className={st.info}>
              <span>{localisationMaster('text_82', 'groups/all-groups')}</span>
              <span>{ebook.skill_level || '...'}</span>
            </div>
            <div className={st.info}>
              <span>{localisationMaster('count_lessons', 'groups/group', 'course_to_group')}</span>
              <span>{ebook.chapters_count || '...'}</span>
            </div>
          </div>
          <SelectCustom
              placeholder="Раздел"
              options={registerSections}
              valueID={modalValues.registerchapters_id}
              onChange={o => changeValues(o.id, 'registerchapters_id')}
              error={check('registerchapters_id')}
          />
        </div>
      </MainSelector>
      <DateSpliter
        titles={[
          localisationMaster('course_start_date', 'groups/group', 'course_to_group'),
          localisationMaster('course_end_date', 'groups/group', 'course_to_group')
        ]}
        keys={['start_date', 'end_date']}
        arrayChangeHandler={arrayChangeHandler}
        fix_data={{
          start_date: modalValues ? modalValues.start_date : timeConverter(new Date(), 'today'),
          end_date: modalValues.end_date
        }}
      />
      {!ms.is_put &&
        <div>
          <Checkbox name="add_to_student"
                    label="Добавить электронную книгу для всех актуальных студентов группы"
                    checked={modalValues.add_to_student}
                    onChange={() => {
                      changeValuesArray({
                        ...modalValues,
                        add_to_student: !modalValues.add_to_student,
                        students: props.students,
                      })
                    }}/>
        </div>}
    </div>
  )
}

export default observer(AddEbook)
