import React, {useEffect, useState} from 'react'
import "../record-customer/RecordCustomer.css"
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import Calendar from 'components/ContentBox/Online-schedule/components/Calendar';
import {superAxiosRequest} from 'axiosApi';
import modalStore from 'Stores/modalStore';
import {localisationMaster} from "../../../../Localisation/Localisation";
import {timeConverter2} from '../components/functions';

import ChoiceLocation from '../components/ChoiceLocation/ChoiceLocation';
import TeachersSelector from '../components/TeachersSelector/TeachersSelector';
import SlotSelector from "../components/SlotSelector/SlotSelector";
import FieldsetCustomer from "../components/FieldsetCustomer/FieldsetCustomer";
import SubmitBlock from "../components/SubmitBlock/SubmitBlock";
import Loader from '../../Global/Loader/Loader';

let apiConf;
//let Link = 'online-schedule/record-customer-error'
/**
 * Компонент записи студента подбор свободных дней
 * ' Запись клиента '
 * http://localhost:3000/online-schedule/record-customer
 */
export default function NewRecord() {
    const [disabled, setdisabled] = useState(false)
    const [location, setlocation] = useState(0);
    const [locationData, setLocationData] = useState(null);
    const [editMode, seteditMode] = useState([false, []])
    const [daysInfo, setDaysInfo] = useState([])
    const [slotId, setSlotId] = useState(0)
    const [BigTrouble, setBigTrouble] = useState([false,[]])
    const [sms, setSms] = useState(false)
    const [range, setrange] = useState([false,[]])
    //const [isCreatedUser, setisCreatedUser] = useState([false,0])
    const [createWU, setcreateWU] = useState([false,[]])
    const [notate, setNotate] = useState([false,[]])
    const [payed, setpayed] = useState(false)
    const [pay_comment, setpay_comment] = useState('')
    const [payErr, setpayErr] = useState(false)

    const ref = React.createRef();
    const slot = React.createRef();
    const teach = React.createRef();
    const [data, setData] = useState( {student_data:   {
                                                    first_name: '',
                                                    last_name: '',
                                                    date_of_birth: '',
                                                    email:  '',
                                                    phone: '',
                                                    },
                                    slot_search_data:{
                                                        date: '',
                                                        slot_id: 0,
                                                        teacher_id: 0,
                                                        },
                                    client_model_data:{
                                                        program: 0,
                                                        age: 0,
                                                        result: '',
                                                        },
                                    sms_notifications: sms})
    var tmp_array;
    if(localStorage.getItem('tmp_array') !==null && !editMode[0]){
        tmp_array = JSON.parse(localStorage.getItem('tmp_array'))
        setlocation(tmp_array.teacher_slot.location.id)
        let arr = {student_data:   {
                                    first_name: tmp_array.student._first_name,
                                    last_name: tmp_array.student._last_name,
                                    father_name: tmp_array.student._father_name,
                                    date_of_birth: tmp_array.student_date_of_birth,
                                    email:  tmp_array.student.email,
                                    phone: tmp_array.student_phone,
                                    },
                    slot_search_data:{
                                    date: tmp_array.teacher_slot.s_date,
                                    slot_id: 0,
                                    teacher_id: 0,
                                    },
                    client_model_data:{
                                    program: tmp_array.program.id,
                                    age: tmp_array.age.id,
                                    result: tmp_array.result,
                                    },
                    sms_notifications: sms}


        seteditMode([true, tmp_array]);
        setNotate([true, [  tmp_array.student._first_name, 
                            tmp_array.student._last_name,
                            tmp_array.student_phone,
                            tmp_array.student.email,
                        ]])
        setData({...arr})

    }
    if(localStorage.getItem('tmp_array_ts') !==null && !editMode[0]){
        tmp_array = JSON.parse(localStorage.getItem('tmp_array_ts'))
        setlocation(tmp_array.location_id)
        let arr = {student_data:   {
                                    first_name: tmp_array.first_name,
                                    last_name: tmp_array.last_name,
                                    father_name: tmp_array.father_name,
                                    date_of_birth: tmp_array.date_of_birth,
                                    email:  tmp_array.email,
                                    phone: tmp_array.student_phone,
                                    },
                    slot_search_data:{
                                    date: tmp_array.s_date,
                                    slot_id: 0,
                                    teacher_id: 0,
                                    },
                    client_model_data:{
                                    program: tmp_array.program,
                                    age: tmp_array.age,
                                    result: tmp_array.result,
                                    },
                    sms_notifications: sms}

        seteditMode([true, tmp_array]);
        setNotate([true, [  tmp_array.student.first_name, 
                            tmp_array.student.last_name,
                            tmp_array.student_phone,
                            tmp_array.student.email,
        ]])
        setData({...arr})
    }
    if(localStorage.getItem('tmp_array_wu') !==null && !createWU[0]){
        tmp_array = JSON.parse(localStorage.getItem('tmp_array_wu'))
        setlocation(tmp_array.location_id)
        let arr = {student_data:   {
                                first_name: tmp_array.name,
                                last_name: tmp_array.last_name,
                                date_of_birth: tmp_array.birthday,
                                email:  tmp_array.email,
                                phone: tmp_array.phone,
                                },
                    slot_search_data:{
                                date: '',
                                slot_id: 0,
                                teacher_id: 0,
                                },
                    client_model_data:{
                                program: tmp_array.questions[0].program_id,
                                age: tmp_array.questions[0].age_id,
                                result: tmp_array.result_lvl,
                                },
                    sms_notifications: sms}
        setData({...arr})
        setNotate([true, [  tmp_array.name,
                            tmp_array.last_name,
                            tmp_array.phone,
                            tmp_array.email,
        ]])
        setcreateWU([true, tmp_array]);
    }
    if(localStorage.getItem('tmp_array_new_wu') !==null && !createWU[0]){
        tmp_array = JSON.parse(localStorage.getItem('tmp_array_new_wu'))
        if(tmp_array?.location) {
            setlocation(tmp_array.location.id)
        }
        let arr = {student_data:   {
                                first_name: tmp_array.first_name,
                                last_name: tmp_array.last_name,
                                date_of_birth: tmp_array.birth_date,
                                email:  tmp_array.email,
                                phone: tmp_array?.phone?.startsWith('+') ? tmp_array?.phone : `+${tmp_array?.phone}`,
                                },
                    slot_search_data:{
                                date: '',
                                slot_id: 0,
                                teacher_id: 0,
                                },
                    client_model_data:{
                                program: tmp_array?.bundle?.program?.id,
                                age: tmp_array?.bundle?.age?.id,
                                result: tmp_array.level ? tmp_array.level : 'В процессе',
                                },
                    sms_notifications: sms}
        setData({...arr})
        setNotate([true, [  tmp_array.first_name,
                            tmp_array.last_name,
                            tmp_array.phone,
                            tmp_array.email,
        ]])
        setcreateWU([true, tmp_array]);
    }


    /* const createTitle=()=>{
        if(!notate[0]){
            return localisationMaster('title-1', 'online-schedule/record-customer')
        }else if(createWU[0]){
            return <span style={{fontWeight: '400'}}><strong>Запись клиента:</strong> {notate[1][1]} {notate[1][0]} ({notate[1][2]}, {notate[1][3]})</span>
        } else {
            return `${localisationMaster('title-2', 'online-schedule/record-customer')} ${notate[1][1]} ${notate[1][0]} (${notate[1][2]}, ${notate[1][3]})`
        }
    } */

    const createDaysInformation = (array) => {
        let daysInformation = [];
        var newDate = new Date(Date.now());
        //var NOW = new Date(Date.now());
        var day = (new Date(Date.now())).getDay()
        if(day === 0){day=7}
        var start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
        //var end = newDate.setDate(newDate.getDate() + 28 - 1);
        var inject, i, somd, dow, c, time, fullDate, time_some_day, begin_at, end_at, fullDate_timestampt, count;

        for (i = 0; i < 28; i++) {
            c = i * 86400;
            somd = Math.round(new Date(start).getTime() / 1000);
            somd = (somd + c) * 1000;

            dow = (new Date(somd)).getDay();

            if (dow === 0) {
                dow = 7
            }
            ;

            fullDate_timestampt = (new Date(somd)).getTime();
            fullDate = timeConverter2(fullDate_timestampt)
            count = range[1].filter(x => x.date === fullDate)
            somd = (new Date(somd)).getDate();
            if (array['days']) {
                if (array['days'].length > 0) {
                    time_some_day = array['days'].filter(item => item['day_date'] === fullDate);
                    if (time_some_day.length > 0) {
                        inject = {
                            date: somd,
                            time: time_some_day[0]['start_time'].slice(0, -3) + '-' + time_some_day[0]['end_time'].slice(0, -3),
                            dow: dow,
                            count: count[0].teacher_slots_count,
                            slotSize: time_some_day[0]['test_long'] + ' ' + localisationMaster('minutes', 'online-schedule/record-customer'),
                            date_full: fullDate
                        }
                        daysInformation.push(
                            inject
                        )
                        continue
                    }
                }
            }
            if (array['weeks']) {
                time = array['weeks'].filter(item => item['dow'] === dow);
                if (time.length !== 0) {
                    begin_at = new Date(time[0]['begin_at'])
                    end_at = time[0]['end_at']
                    if (end_at !== null) {
                        if (new Date(time[0]['end_at']).getTime() > fullDate_timestampt) {
                            end_at = true
                        } else {
                            end_at = false
                        }
                    } else {
                        end_at = true
                    }
                }
                if (time.length !== 0 && begin_at.getTime() <= fullDate_timestampt && end_at) {
                    inject = {
                        date: somd,
                        time: time[0]['start_time'].slice(0, -3) + '-' + time[0]['end_time'].slice(0, -3),
                        dow: dow,
                        count: count[0].teacher_slots_count,
                        slotSize: time[0]['test_long'] + ' ' + localisationMaster('minutes', 'online-schedule/record-customer'),
                        date_full: fullDate
                    }
                } else {
                    inject = {date: somd, time: localisationMaster('indicated', 'online-schedule/record-customer'), dow: dow, slotSize: '', date_full: fullDate}
                }
            } else {
                inject = {date: somd, time: localisationMaster('indicated', 'online-schedule/record-customer'), dow: dow, slotSize: '', date_full: fullDate}
            }
            daysInformation.push(
                inject
            )

        }
        setDaysInfo(daysInformation)
    }

    useEffect(() => {
        if(editMode[0]){
            localStorage.removeItem('tmp_array');
            localStorage.removeItem('tmp_array_ts');
        }
        if(createWU[0]){
            localStorage.removeItem('tmp_array_wu');
        }
        

        if (location !== 0) {
            /*var newDate = new Date(Date.now());
            var day = newDate.getDay()
            if(day === 0){day=7}
            var start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
            apiConf = {
                method: 'get',
                link: 'time_slots/admin_slots',
                params: {
                        page: 1,
                        location_id: location,
                        start_from: timeConverter2(start),
                        is_active: true,
                        }
            }
            superAxiosRequest(apiConf, 'data').then(response=>{
                createDaysInformation(response.data)
            });*/
        }
    }, [location])

    useEffect(() => {
        if(range[0]){
            var newDate = new Date(Date.now());
            var day = newDate.getDay()
            if(day === 0){day=7}
            var start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
            apiConf = {
                method: 'get',
                link: 'time_slots/admin_slots',
                params: {
                        page: 1,
                        location_id: location,
                        start_from: timeConverter2(start),
                        is_active: true,
                        }
            }
            superAxiosRequest(apiConf, 'data').then(response=>{
                createDaysInformation(response.data)
            });
        }
    }, [range])


    useEffect(() => {
        if(location !==0 && data.client_model_data.age !== 0 && data.client_model_data.program !== 0){
            var newDate = new Date(Date.now());
            var day = newDate.getDay()
            if(day === 0){day=7}
            var from_date = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
            //var to_date = (newDate.setDate(newDate.getDate() - parseInt(day)) + 27)
            var to_date= newDate.setDate(newDate.getDate() + 28 - 1);
            apiConf = {
                method: 'get',
                link: 'time_slots/student/teachers_date',
                params: {
                        location: location,
                        from_date: timeConverter2(from_date),
                        to_date: timeConverter2(to_date),
                        age:  +data.client_model_data.age,
                        program: +data.client_model_data.program,
                        is_active: true,
                        }
            }
            superAxiosRequest(apiConf, 'data').then(response=>{
                setrange([true, response.data])
                let inp_data = {
                    date: 0,
                    slot_id: 0,
                    teacher_id: 0,
                }
                //setResponsClient(null)
                setData({...data, slot_search_data: inp_data})
            });
        }
    }, [location, data.client_model_data.age, data.client_model_data.program])



    const updateLocation = (location) =>{
        if(location.is_active){
            setlocation(location.id)
            setLocationData(location)    
        } else {
            setlocation(0)
        }
    }

    const updateAge = (age) =>{
       let arr = data;
       arr.client_model_data.age = age
       setData({...arr})
    }

    const updatePrograms = (program) =>{
        let arr = data;
        arr.client_model_data.program = program
        setData({...arr})
    }

    const updateAvailible = (day) => {
        let inp_data = {
                    date: day,
                    slot_id: 0,
                    teacher_id: 0,
                }
        //setResponsClient(null)
        setData({...data, slot_search_data: inp_data})
        //setTimeIdIsLoaded(false)
    }

    const updateTeacherId = (id) => {
        let inp_data = data.slot_search_data
        inp_data.teacher_id = +id
        setData({...data, slot_search_data: inp_data})
    }

    const updateSlotId = id=>{
        setSlotId(id)

        let inp_data = data.slot_search_data
        inp_data.slot_id = +id
        inp_data.teacher_id = 0
        setData({...data, slot_search_data: inp_data})
    }

    const drop_me = () =>{
        let droped = {  student_data:   {
                                        first_name: '',
                                        last_name: '',
                                        date_of_birth: '',
                                        email:  '',
                                        phone: '',
                                        },
                        slot_search_data:{
                                        date: '',
                                        slot_id: 0,
                                        teacher_id: 0,
                                        },
                        client_model_data:{
                                        program: 0,
                                        age: 0,
                                        result: '',
                                        },
                        sms_notifications: sms} 
        setData({...droped})
        setNotate([false,[]])
        seteditMode([false, []])
        setpayErr(false)
        setpay_comment('')
        setpayed(false)
    }
    const softDrop = () =>{
        setSlotId(0);
        setLocationData(null)
        setData({...data, date: '', slot_id: 0, teacher_id: 0})
    }

    const checkValues = (array) => {
        let fraud = 0;
        for(let key in array.student_data){
            if(array.student_data[key]?.length < 2)
            {fraud +=1}
        }
        if(array.slot_search_data.date.length < 8){fraud +=1}
        if(array.slot_search_data.slot_id < 1){fraud +=1}
        if(array.slot_search_data.teacher_id < 1){fraud +=1}
        if(array.client_model_data.program < 1){fraud +=1}
        if(array.client_model_data.age < 1){fraud +=1}
        if(array.client_model_data.result.length < 2){fraud +=1}
        if(payed) if(pay_comment.length < 30){fraud +=1; setpayErr(true)}
        if(fraud > 0){
            return false
        } else {
            return true
        }
    }

    const add = (e) => {
        if(checkValues(data)){
        setBigTrouble([false, []])
        let qJson = data
        if(payed){
            qJson.is_payed = payed
            qJson.payment_comment = pay_comment
        } else {
            qJson.is_payed = false
            qJson.payment_comment = 'Бесплатное занятие'
        }
        e.preventDefault();
        setdisabled(true)

        let link = 'time_slots/admin';
        let method = 'post';
        if(editMode[0]){
            link  = `time_slots/teacher/clients/${editMode[1].id}`;
            method = 'put';
        }
        apiConf = {
            method: method,
            link: link,
        }
        if(sms){
            qJson['sms_notifications'] =  sms
        }
         //-----КОСТЫЛЬ
         delete qJson.date
         delete qJson.slot_id
         delete qJson.teacher_id
         //-----КОСТЫЛЬ*/
        superAxiosRequest(apiConf, qJson).then(response=>{
            let txt = `${localisationMaster('client-text-3', 'online-schedule/record-customer')}
             ${qJson.student_data.last_name}  ${qJson.student_data.first_name} 
             ${localisationMaster('client-text-4', 'online-schedule/record-customer')} 

                        ${localisationMaster('client-text-1', 'online-schedule/record-customer')} ${qJson.student_data.email}.
                        ${localisationMaster('client-text-2', 'online-schedule/record-customer')}`
            let data = {
                name: 'informer',
                link: 'db/programs',
                button: localisationMaster('button-close', 'online-schedule/record-customer'),
                title: localisationMaster('button-text-title', 'online-schedule/record-customer'),
                type: 'Informer',
                data: 'json',
                text: txt,
                }
            modalStore.activity(data)
            drop_me();
            setdisabled(false)
        })
            .catch(error=>{
                setdisabled(false)
                if (error.response && error.response.status === 400) {
                    let errors = error.response.data;
                    let error_str ='';
                    for (var key in errors) {
                        error_str += [key] + " : " + errors[key] + '\r\n';
                    }
                    setBigTrouble([true, error_str])
                }
            })
        } else {

        }

    };


    useEffect(() => {

        if(data.slot_search_data.teacher_id !==0 && !disabled) ref.current.scrollIntoView({ behavior: "smooth" });
        if(slot.current !== null && location !==0  && data.slot_search_data.date) slot.current.scrollIntoView({ behavior: "smooth" });
        if(teach.current !== null && data.slot_search_data.slot_id !==0) teach.current.scrollIntoView({ behavior: "smooth" });
    })




    return (
        <div className="content-box">
            <ContentHeader title={
                notate[0] ?
                    <span style={{fontWeight: '400'}}><strong>{localisationMaster('title-2', 'online-schedule/record-customer')} </strong> {notate[1][1]} {notate[1][0]} ({notate[1][2]}, {notate[1][3]})</span>
                    :
                    createWU[0] ? <span style={{fontWeight: '400'}}><strong>Запись клиента: </strong> {notate[1][1]} {notate[1][0]} ({notate[1][2]}, {notate[1][3]})</span>
                        :
                        localisationMaster('title-1', 'online-schedule/record-customer')
            }/>
            {disabled ? <Loader /> :
                <div className="record-customer-page">
                    <ChoiceLocation
                        locationId={location}
                        soft_drop={softDrop}
                        suppotSelectors={true}
                        age={data.client_model_data.age}
                        program={data.client_model_data.program}
                        onChangeLocation={ (newLocation) => {updateLocation(newLocation)}}
                        onChangeAge={ (newAge) => {updateAge(newAge)}}
                        onChangeProgram={ (newProgram) => {updatePrograms(newProgram)}}
                    />

                    {location !==0 && data.client_model_data.age !== 0 && data.client_model_data.program !== 0 ?
                        <Calendar
                            loaded={daysInfo}
                            calendarType="slots_admin_booking"
                            someDayValue={data.slot_search_data.date}
                            setSomeDay={updateAvailible}
                        />
                        : null}

                    {location !==0 && data.slot_search_data.date ?
                        <SlotSelector   ref={slot}
                                        day={data.slot_search_data.date}
                                        location={locationData}
                                        onChangeSlot={updateSlotId}
                                        age_id={data.client_model_data.age}
                                        program_id={data.client_model_data.program}
                        />
                        : null
                    }

                    {data.slot_search_data.slot_id !== 0 ?
                        <TeachersSelector
                            ref={teach}
                            slotId={slotId}
                            age_id={data.client_model_data.age}
                            program_id={data.client_model_data.program}
                            teacher_id={data.slot_search_data.teacher_id}
                            someDay={data.slot_search_data.date}
                            setDataTeacherId={updateTeacherId}
                        />
                        :null}

                    {data.slot_search_data.teacher_id !==0 || editMode[0]  || data.student_data.email.length > 0 ?
                        <FieldsetCustomer
                            ref={ref}
                            notate={notate}
                            student_data={data.student_data}
                            client_model_data={data.client_model_data}
                            editMode={editMode}
                            createWU={createWU}
                            onChangeUser={customer=>{setData(Object.assign(data, customer))}}
                            BigTrouble={BigTrouble[1]}
                        /> : null
                    }
                    {data.slot_search_data.teacher_id !==0 || editMode[0]  || data.student_data.email.length > 0 ?
                        <SubmitBlock    add={(e)=>{add(e)}}
                                        isdisabled={disabled}
                                        sms={sms}
                                        setSms={setSms}
                                        setpayed={setpayed}
                                        payed={payed}
                                        pay_comment={pay_comment}
                                        payErr={payErr}
                                        setpay_comment={setpay_comment}
                        />
                        : null
                    }

                </div> }
        </div>
    )
}
