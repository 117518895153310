import React, {useState, useEffect, useContext} from 'react';
import {LessonContext} from "components/Modules/Ebook/BookChapter/LessonContext";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";
import st from "./QuestionTest.module.scss"
import imgStopWatch from "components/ContentBox/img/timer.svg"
import {ReactComponent as StarIcon} from "components/UI/icons/star-line.svg";
import {ReactComponent as AttentionIcon} from "components/ContentBox/img/attention.svg";


export default function QuestionTest(props) {
  const [timer, setTimer] = useState(0)
  const [/*isTimerOn*/, setIsTimerOn] = useState(false)
  const context = useContext(LessonContext)
  const [isTime, setIsTime] = useState(props.isTimer || false)
  const [activeTry, setActiveTry] = useState(1)
  const [answers, setAnswers] = useState([])
  const [answersButton, setAnswersButton] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isStopWatchStarted, setIsStopWatchStarted] = useState(false)

  const [seconds, setSeconds] = useState(0);

  const totalPoints = props.questions.reduce((acc, el) => acc + +el.points, 0)
  const totalQuestions = props.questions.length
  const totalScoredPoints = props.attempts[props.try - 1]?.result_in_detail ? Object.values(props.attempts[props.try - 1].result_in_detail).reduce((acc, el) => acc + el.points, 0) : 0
  const totalCorrect = props.attempts[props.try - 1] ? Object.values(props.attempts[props.try - 1].result_in_detail).filter(el => el.result === true).length : 0

  useEffect(() => {
    let interval
    if(isStopWatchStarted) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isStopWatchStarted, seconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;

    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');

    return `${minutesStr}:${secondsStr}`;
  }

  useEffect(() => {
    if (context.mode === 'edit') return
    const timerId = timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [context.mode, timer]);

  function handleSetTimer(min) {
    setTimer(min * 60)
    setIsTimerOn(true)
  }
  const handleAnswers = (q_idx, a_text) => {
    let arr = [...answers]
    if (arr[q_idx]) {
      if (arr[q_idx].includes(a_text))
        arr[q_idx] = arr[q_idx].filter(a => a !== a_text)
      else {
        arr[q_idx].push(a_text)
      }
    } else {
      arr[q_idx] = [a_text]
    }
    setAnswers([...arr])
  }

  const startTest = () => {
    setIsStopWatchStarted(true)
    setIsTime(false)
    handleSetTimer(props.time)
  }

  const checkAnswers = (q, a) => {
    for (let k in q) {
      if (!(a[k] && a[k].length))
        return false
    }
    return true
  }
  useEffect(() => {
    if(timer < 1 && props.isStudent && !isTime && props.isTimer) {
      props.handleAnswers([seconds, ...answers])
      setIsTime(true)
      props.saveTry()
    }
  }, [timer])

  useEffect(() => {
    props.try !== activeTry && setAnswers([])
    setActiveTry(props.try)
    setIsTime(true)
    setIsStopWatchStarted(false)
    setTimer(0)
    setSeconds(0)
  }, [props.try])

  useEffect(() => {
    setAnswersButton(checkAnswers(props.questions, answers))
    props.handleAnswers([seconds, ...answers], answersButton)
  }, [answers, answersButton, seconds])

  return (
    <div className={st.questions}>

      {(props.isStudent && isTime && !props.studentAnswer) ?
        props.questions &&
        <div className={st.timerBox}>
          {showModal && (
              <div className={st.testModal}>
                <AttentionIcon/>
                <h3 className={st.title}>Start Test</h3>
                <p className={st.text}>Once you start the test, you will start counting down the time it takes to complete
                  it.</p>
                <div className={st.btns}>
                  <button onClick={() => setShowModal(false)} className={`${st.btn} ${st.cancel}`}>Cancel
                  </button>
                  <button onClick={startTest} className={`${st.btn} ${st.start}`}>Start Test</button>
                </div>
              </div>
            )
          }
          <img src={imgStopWatch} alt=""/>
          <h3>Time Test</h3>
          <div className={st.timerBoxCols}>
            <div>
              <span>Number of Questions</span>
              <div className={st.value}>{totalQuestions}</div>
            </div>
            <div className={st.verticalLine}></div>
            <div>
              <span>Execution time</span>
              <div className={st.value}>{props.time}</div>
            </div>
          </div>
          <button onClick={() => setShowModal(true)} disabled={props.try > 10} className={st.showModalBtn}>Start
            Testing
          </button>
        </div>
        :
        <>
          {(props.isTimer || props.isPoint) &&
              <div className={st.paramsBox}>
                {props.isTimer &&
               <div className={`${st.paramsValues} ${props.isTimer && !props.isPoint? st.time : ''}`}>
                  Time:
                  <div className={st.boxInfo}>{props.time} min</div>
               </div>
                }
               {props.isPoint &&
               <div className={`${st.paramsValues} ${!props.isTimer && props.isPoint ? st.points : ''}`}>
                  Points:
                 <div className={st.boxInfo}>{totalPoints}</div>
               </div>
               }
             </div>
          }
          {props.try <= props.attempts.length &&
          <div className={`${st.results} ${props.attempts[props.try - 1] && props.attempts[props.try - 1].result ? '' :  st.mistake}`}>
            <p className={st.resultsTitle}>Test Results</p>
            <div className={st.resultValues}>
              {props.isPoint &&
              <div>
                <p className={st.valueTitle}>Points</p>
                <p className={st.resultValues}>{`${totalScoredPoints} / ${totalPoints}`}</p>
              </div>
              }
              <div>
                <p className={st.valueTitle}>Time</p>
                <p className={st.resultValues}>{formatTime(props.attempts[props.try - 1].time)}</p>
              </div>
              <div>
                <p className={st.valueTitle}>Correct</p>
                <p className={st.resultValues}>{`${totalCorrect} / ${totalQuestions}`}</p>
              </div>
            </div>
          </div>
          }
          {props.questions.map((question, index) =>
            <div key={index} className={st.question}>
              <div className={st.questionHeader}>
                <p className={st.questionText}>{question.text}</p>
                {props.isPoint && props.questions[index]?.points &&
                  <div className={st.scoreBlock}>
                    <StarIcon/>
                    <p>{props.questions[index]?.points}</p>
                  </div>
                }
              </div>
              <div className={st.answers}>{question?.variants?.map(answer => props.studentAnswer ?
                  <Checkbox key={`${Date.now()}_${index}answer${answer.id || answer.text}`}
                            label={answer.text}
                            checked={props.studentAnswer.answer[index]?.variants?.map(v => v.text).includes(answer.text)}
                            onChange={() => {}}
                            isCorrect={props.studentAnswer.result_in_detail[question.text]?.variants[answer.text] === true}
                            isMistake={props.studentAnswer.result_in_detail[question.text]?.variants[answer.text] === false}
                            className={st.test}
                  />
                  :
                  <Checkbox key={`${Date.now()}_${index}answer${answer.id || answer.text}`}
                            label={answer.text}
                            onChange={() => handleAnswers(index, answer.text)}
                            checked={answers[index]?.includes(answer.text)}
                            className={st.test}
                  />
              )}
              </div>
            </div>
          )}
        </>
      }
    </div>
  )
}
