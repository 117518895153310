import {localisationMaster} from "../Localisation/Localisation";
import {addZero} from "./math";

const link = 'months'
const months = [
    localisationMaster('months_1', link),
    localisationMaster('months_2', link),
    localisationMaster('months_3', link),
    localisationMaster('months_4', link),
    localisationMaster('months_5', link),
    localisationMaster('months_6', link),
    localisationMaster('months_7', link),
    localisationMaster('months_8', link),
    localisationMaster('months_9', link),
    localisationMaster('months_10', link),
    localisationMaster('months_11', link),
    localisationMaster('months_12', link)
];

export function dateFormat(oDate = new Date(), format="dd.mm.YYYY") {
    try {
        if (typeof oDate === 'string' || typeof oDate === 'number') oDate = new Date(oDate)

        format = format
          .replace('dd', addZero(oDate.getDate()))
          .replace('d', oDate.getDate())
          .replace('mm', addZero(oDate.getMonth() + 1))
          .replace('MMMM', months[oDate.getMonth()].toLowerCase())
          .replace('MMM', months[oDate.getMonth()])
          .replace('YYYY', oDate.getFullYear())
          .replace('YY', String(oDate.getFullYear()).slice(2))

          .replace('hh', addZero(oDate.getHours()))
          .replace('ii', addZero(oDate.getMinutes()))
          .replace('ss', addZero(oDate.getSeconds()))
        return format
    } catch (e) {
        return 'Invalid date'
    }
}

export const scheduleDays = arr => {
    const days = [
        localisationMaster('text_22', 'groups/all-groups'),
        localisationMaster('text_23', 'groups/all-groups'),
        localisationMaster('text_24', 'groups/all-groups'),
        localisationMaster('text_25', 'groups/all-groups'),
        localisationMaster('text_26', 'groups/all-groups'),
        localisationMaster('text_27', 'groups/all-groups'),
        localisationMaster('text_28', 'groups/all-groups')
    ]

    return arr.map(wd => days[--wd]).join('/');
}
