import React from "react";

import "./SlotsExplain.scss"

import {ReactComponent as IconInformation} from "../../../img/information.svg";

export default function SlotsExplain(props) {
    return (
        <div className="slotsExplain">
            {!props.off_pic && <div className="slotsExplain__icon"><IconInformation/></div>}
            <div className="slotsExplain__items" style={{padding: props.off_pic && '0px', border: props.off_pic && '0px'}}>
                <div className="slotsExplain__item"><span>свободный слот</span></div>
                <div className="slotsExplain__item--deleted"><span>удаленный слот</span></div>
                <div className="slotsExplain__item--booked"><span>занятый слот</span></div>
                <div className="slotsExplain__item--hc"><span>закрытый слот</span></div>
            </div>
        </div>
    )
}
