import React, {useEffect, useState} from 'react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import slotsAdvancedTeachersHistoryStore from 'Stores/slotsAdvancedTeachersHistoryStore';
import SlotColorTypes from 'components/Modules/Slots/components/SlotColorTypes/SlotColorTypes';
import CompanyLocationSelects from '../components/CompanyLocationSelects/CompanyLocationSelects';
import styles from '../BookedSchedule/BookedSchedule.module.scss';
import TimeLine from './components/TimeTable';
import {
    timeConverter2,
} from "components/ContentBox/Online-schedule/components/functions";



const TimelineTeachersHistory = () => {
    const {
        companiesList,
        locations,
        selectedLocation,
        selectedCompany,
        setData,
        resetData,
    } = slotsAdvancedTeachersHistoryStore;
    const [timeLineData, setTimeLineData] = useState()

    let workStartTime = (new Date()).setHours(0, 0, 0, 0)
    let workFinishTime = (new Date()).setHours(24, 0, 0, 0)
    // if (selectedLocation && selectedCompany && timeLineData) {
    //     workStartTime = (new Date()).setHours(selectedLocation.start_at.split(':')[0], selectedLocation.start_at.split(':')[1], 0, 0)
    //     workFinishTime = (new Date()).setHours(selectedLocation.end_at.split(':')[0], selectedLocation.end_at.split(':')[1], 0, 0)
    // }



    const [date, setDate] = useState(new Date())
    const getData = () => {
        superAxiosRequest({
            method: 'get',
            link: 'online_schedule/admin/advanced-history',
            params: {
                date: timeConverter2(date),
                location: selectedLocation.id,
                company: selectedCompany.id,
                time: new Date().getTime(),
            },
        }).then(response => {
            setTimeLineData({data: response.data.booked_slots, slotTypes: response.data.slot_types})
        })
    }
    const updateTimeSlot = (data, onSuccess = () => { }, onFail = () => { }) => {
        superAxiosRequest({
            method: 'put',
            link: `online_schedule/admin/advanced-history/${data.id}`,
        }, {...data, location: selectedLocation.id})
            .then(() => {
                getData()
                onSuccess();
            })
            .catch(() => onFail());
    }

    const deleteTimeSlot = (data, onSuccess = () => { }, onFail = () => { }) => {
        const apiConf = {
            method: 'delete',
            link: `online_schedule/admin/advanced-history/${data.id}`,
        };
        superAxiosRequest(apiConf, {data: {comment: data.comment}})
            .then(() => {
                getData()
                onSuccess();
            })
            .catch(() => onFail());
    }

    const createZoomLink = (slot_id, onSuccess, onFail) => {
        superAxiosRequest({
            method: 'put',
            link: `online_schedule/zoom/${slot_id}`,
        }).then(({data}) => {
            const {zoom_link} = data
            if (zoom_link === null) {
                onFail();
                return;
            }
            getData()
            onSuccess(zoom_link);
        })
            .catch(() => onFail());
    }
    useEffect(() => {
        selectedLocation && selectedCompany && getData()
    }, [selectedLocation, selectedCompany, date])
    return (
        <>
            <ContentHeader title='Расписание преподователей' />
            <div className={styles.selectors_wrapper}>
                <CompanyLocationSelects
                    companiesList={companiesList}
                    locationsList={locations}
                    selectedLocation={selectedLocation}
                    selectedCompany={selectedCompany}
                    onResetData={resetData}
                    onSetData={setData}
                />
            </div>
            {selectedLocation && selectedCompany && timeLineData &&
                <div className={styles.selectors_wrapper}>
                    <TimeLine
                        slotTypes={timeLineData?.slotTypes}
                        data={timeLineData.data} date={date}
                        updateTimeSlot={updateTimeSlot}
                        deleteTimeSlot={deleteTimeSlot}
                        createZoomLink={createZoomLink}
                        workStartTime={workStartTime}
                        workFinishTime={workFinishTime}
                        setDate={setDate} />
                    {timeLineData.data.length !== 0 ? <SlotColorTypes
                        types={timeLineData.slotTypes || []}
                        withIcon={true}
                    /> : <h1 className={styles.calendar_wrapper}>Забронированных слотов сотрудниками на выбранную дату нет</h1>
                    }

                </div>
            }
        </>
    );
}

export default observer(TimelineTeachersHistory);



