import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import {permissions} from 'settings/permissions';
import {checkPermList} from 'helpers';
import {dateFormat} from "helpers/date";
import {formatOptionStatus} from "components/ContentBox/Global/GlobalModalSingle/templates/groups/GroupsForm";
import useLocalization from "hooks/useLocalization";
import Stores from "Stores";
import groupStore from "Stores/groupStore";
import asideModalStore from "Stores/asideModalStore";
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import MainInfo from "./components/MainInfo/MainInfo";
import TimeTable from "components/ContentBox/Groups/components/TimeTable";
import SurfMenu from "components/ContentBox/Groups/components/SurfMenu";
import StudentCard from "components/Modules/Groups/TeacherGroup/components/StudentCard";
import Loader from "components/ContentBox/Global/Loader/Loader";
import HomeTasks from "components/Modules/Groups/components/HomeTasks/HomeTasks";
import TestResults from "components/Modules/Groups/components/TestResults/TestResults";
import StudentsProgressReports
  from "components/Modules/Groups/components/StudentsProgressReports/StudentsProgressReports";
import GroupLog from "components/ContentBox/Groups/components/GroupLogs/GroupLog";
import WorkshopTimeTable from "./components/Workshop/WorkshopTimeTable";
import TeachersRatings from "../ClientGroupInfo/components/TeachersRatings/TeachersRatings";
import ActiveLessonsGroup from "./components/MainInfo/ActiveLessonsGroup/ActiveLessonsGroup";
import st from "./TeacherGroup.module.scss";
import EbookHomeworks from "../components/EbookHomeworks/EbookHomeworks";

const menu_add = [
  { name: localisationMaster('text_52', 'groups/all-groups'), action: 'new_student'},
]

let ms

function TeacherGroup(props) {
  const gid = +props.match.params.id
  
  const {group, ebooks} = groupStore

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group',
    dict: {
      "accrued_to_students": "Accrued to Students",
      "duty": "Duty",
      "group_capacity": "Group Capacity",
      "total": "Total",
      "in_group": "In group",
      "actual": "Actual",
      "debtors": "Debtors",
      "class_attendance": "Class attendance",
      "paid": "Paid",
      "free": "Free",
      "absences": "Absences",
      "astronomical_hours": "astronomical hours",
      "academic_hours": "academic hours",
      "spent": "spent",

      "group_teachers": "Group Teachers",
      "total_hours": "Total hours",
      "payment": "Payment",
      "rate": "Rate",
      "show_all_teachers": "Show All Teachers",
      "online": "Online",
      "offline": "Offline",
      "active_since": "Active since",
      "accrued": "Accrued",

      "group_options": "Group Options",
      "location": "Location",
      "group_type": "Group Type",
      "discipline": "Discipline",

      "group_comment": "Group Comment",
      "no_comment": "No comment has been added yet.",
      "add_comment": "Add a Comment",

      "course_of_study": "Course of Study",
      "view_course": "View Course",

      "group_schedules": "Group Schedules",
      "edit_time": "Edit Time",
      "schedule_extension": "Schedule extension",
      "closing_the_schedule": "Closing the schedule",
      "auditory": "Aud",
      "without_teacher": "Without Teacher",
      "no_course_in_group": "There is no course assigned to this group",
      "add_course": "Add a Course to a Group",

      "signed": "Signed",
      "awaiting": "Awaiting",
      "group_parameters": "Group parameters",
      "save": "Save",
      "comments_for_group": "Comments for group",
      "comment": "Comment",
      "add_new_schedule":"Add New Schedule",

      "application_teens": "Application for training Teens",

      "tg": "tg",
      "ac_h": "ac. h.",
      "astr_h": "astr. h.",

      "edit_profile": "Edit profile",
      "go_to_profile" : "Go to profile",
      "change_status" : "Change status",
      "surcharge" : "Surcharge",
      "discount" : "Discount",
      "price" : "Price",
      "study_dates" : "Dates of study",
      "details" : "Details",
      "study_type" : "Study Type",
      "add_certificate" : "Add Certificate",


    }
  })

  const [studentStatus, setStudentStatus] = useState(1)
  const [activeLessons, setActiveLessons] = useState([])
  const history = useHistory();

  const getSchedules = () => {
    return group.schedules.map(sch => ({
      ...sch,
      teachers: sch.teachers.map(id => group.teachers.find(t => t.id === id)),
      weekdays: sch.weekdays.map(day => ({ id: day, day })),
    }))
  }

  const getStudents = () => group.students
    .filter(x => x.status === studentStatus)
    .sort((a, b) => a.last_name > b.last_name ? 1 : -1)

  const openModalAddStudent = action => {
    switch (action) {
      case 'new_student':
        ms = {
          name: 'user_to_group',
          link: 'accounts/clients',
          button: localisationMaster('text_54', 'groups/all-groups'),
          title: localisationMaster('text_8', 'groups/group'), 
          type: 'user_to_group',
          add_to_group: true,
          group_id: gid,
          isResponsive: true,
          valid: {
            first_name: 'required',
            last_name: 'required',
            inn: 'required',
            date_of_birth: 'required',
            phone: 'required',
            email: 'required',
          },
          onSubmitSuccess: (data) => {
            superAxiosRequest({method: "post", link: "groups/admin_add_client_to_group"}, {
              group_id: gid,
              client_id: data.profile.id,
              start_study_at: dateFormat(new Date(), 'YYYY-mm-dd'),
              student_status: 1
            }).then((res) => {
              groupStore.updateGroupData({students: res.data.students})
            }).catch(() => {

            })
          }
        }

        asideModalStore.activity(ms, {
          start_study_at: dateFormat(new Date(), "YYYY-mm-dd"),
        })
        break;
      default:
        break;
    }
  }

  const getCount = sid => {
    let arr = []
    if (Array.isArray(group.students)) {
      arr = group.students.filter(st => st.status === sid);
    }
    return ` ${arr.length}`
  }

  const menu = [
    { name: localisationMaster('text_16', 'groups/all-groups'), active: true, link: 1, count: getCount(1) },
    { name: localisationMaster('text_18', 'groups/all-groups'), active: false, link: 4, count: getCount(4) },
    { name: localisationMaster('interrupted_training', 'groups/group'), active: false, link: 3, count: getCount(3) },
    { name: localisationMaster('text_17', 'groups/all-groups'), active: true, link: 2, count: getCount(2) },
    { name: localisationMaster('deleted', 'groups/group'), active: false, link: 5, count: getCount(5) },
  ]

  const openModalStatus = () => {
    const ms = {
      title: localisationMaster('group_status', 'groups/group'),
      type: 'generator',
      fields: [
        {
          type: 'select',
          name: 'status',
          apiLink: 'groups/groups_catalog',
          apiGetResult: data => data.statuses.items,
          formatOptionLabel: formatOptionStatus
        }
      ],
      button: localisationMaster('text_54', 'groups/all-groups'),
      is_put: true,
      link: 'groups/' + gid,
      onSubmitSuccess: groupStore.updateGroupData,
    }

    asideModalStore.activity(ms, { status: group.group.status.id })
  }

  useEffect(() => {
    groupStore.fetchGroup(gid)

    return () => {
      groupStore.deleteGroupData()
      history.replace({ state: null })
    }
  }, [gid, history])

  useEffect(() => {
    let apiActiveGroupLessons = {
      method: 'get',
      link: 'ebooks/active_lessons_group',
      params: {
        group: gid,
        today: true,
      }
    }

    superAxiosRequest(apiActiveGroupLessons).then(res => {
      setActiveLessons(res.data.result)
    })
  }, [gid])

  return (
    Object.keys(group).length === 0 ?
      <Loader /> :
      <>
        <ContentHeader
          title={`${group.group.number} - ${group.group.name}`}
          userID = {group?.group?.id}
          breadСrumbs={[
            { title: 'Учебные группы', route: history.location.state?.prevPath || '/groups/all-groups' },
          ]}
          btnGroupStatus={checkPermList(permissions.change_groups_statuses) && {
            status: {...group.group.status},
            onClick: openModalStatus,
          }}
        />
        {activeLessons.map((item, index) => <ActiveLessonsGroup item={item} key={index}/>)}

        <div className={st.teacherGroup}>
          <div className={`${st.container} ${st.flexColumn}`}>

            <MainInfo translate={translate}/>

            {group.group.new_schedule ?
              <WorkshopTimeTable location={group.group.location}
                                 translate={translate} 
                                 holidays={group.holidays}
                                 groupID={gid}/>
              :
              <TimeTable
                holidays={group.holidays}
                schedule={getSchedules()}
                statuses={group.schoolday_statuses}
                schooldays={group.schooldays}
                location={group.group.location}
                id={gid}
                ebooks={ebooks}
              />
            }
          </div>

          <SurfMenu menu={menu}
                    type={studentStatus}
                    changeType={setStudentStatus}
                    button={Stores.Rules.checkRule('studygroup', 'add_client')}
                    menu_add={menu_add}
                    action_add={openModalAddStudent}/>

          <div className={`${st.container} ${st.flexColumn}`}>{group.students.length > 0 ?
            getStudents().map(student =>
              <StudentCard
                translate={translate}
                key={student.id}
                student={student}
                school_day_notes={group.school_day_notes}
                schooldays={group.schooldays}
                schedule={getSchedules()}
                statuses={group.schoolday_note_statuses}
                holidays={group.holidays}
                groupID={+props.match.params.id}
              />)
            :
            <div className={st.noStudents}>
              {localisationMaster('text_19', 'groups/all-groups')}
            </div>}

            {Date.now() < 0 &&
              <HomeTasks homeworks={group.homeworks} gid={group.group.id} />}

            <EbookHomeworks groupID={group.group.id}/>

            {Date.now() < 0 &&
              <>
                <TestResults tests={group.test_results} gid={group.group.id} students={group.students} />

                {checkPermList(permissions.view_progress_report) &&
                  <StudentsProgressReports id={group.group.id} />}

                {checkPermList(permissions.view_teacher_report) &&
                  <TeachersRatings groupID={gid} students={group.students}/>}

                {Stores.isAdmin &&
                  <GroupLog gid={group.group.id} isWorkshops={group.group.new_schedule} />}
              </>}
          </div>
        </div>
      </>
  )
}

export default observer(TeacherGroup)
