import React, { useEffect, useState } from 'react';
import {superAxiosRequest} from 'axiosApi';
import Idlist from '../../Idlist/idlist';
import {localisationMaster} from "Localisation/Localisation";
import {observer} from "mobx-react";
import modalStore from "Stores/modalStore";
import SelectCustom from '../../Select/SelectCustom';

const SettingPayments = () => {

    const {changeValues, modalValue, check, modalValues} = modalStore

    const [locations, setLocations] = useState([false, []])

    const paymentType = [
        {id: 1, name: localisationMaster('text_33', 'modal_templates')},
        {id: 2, name: localisationMaster('text_34', 'modal_templates')},
    ]

    const getIds = data => {
        const cleanId = data.map(x => x.id)
        changeValues(cleanId, 'locations');
    }

    const changeTypeHandler = id => {
        changeValues(id, 'type')
        // Если Почасовая, то количество часов только 1
        if (id === 2) changeValues(1, 'hours_sum')
    }

    useEffect(() => {
        let apiConf = {
            method: 'get',
            link: 'db/locations'
        }
        superAxiosRequest(apiConf).then(res => {
            setLocations([true, res.data.result])
        })

        if (modalValue.type === undefined) changeValues(1, 'type')
        if (!modalValue.hours_sum) changeValues(1, 'hours_sum')
        changeValues(modalValue.hours_type?.id ? modalValue.hours_type.id : 1, 'hours_type')
    }, [])
    return (
        <div className='superModalGroup'>
            <div className="superModalGroup__modalLine superModalGroup__modalLine--first">
                <div style={{width: '100%'}}>
                    <p className="superModalGroup__label">Название:</p>
                    <input type="text"
                           placeholder={localisationMaster('text_3', 'modal_templates')}
                        //name="inputValueSecond"
                           value={modalValue.name || ''}
                           onChange={e => changeValues(e.target.value, 'name')}
                           className={check('name') ? 'super-modal__input--error' : ''}
                    />
                </div>
            </div>

            <div className="superModalGroup__modalLine">
                <div>
                    <p className="superModalGroup__label">Сумма:</p>
                    <input placeholder={localisationMaster('text_35', 'modal_templates')}
                           //name="inputValueSecond"
                           value={modalValue.price_sum || ''}
                           onChange={e => changeValues(e.target.value, 'price_sum')}
                           className={check('price_sum') ? 'super-modal__input--error' : ''}
                    />
                </div>

                <div>
                    <p className="superModalGroup__label">Тип:</p>
                    <SelectCustom placeholder={localisationMaster('text_36', 'modal_templates')}
                                  options={paymentType}
                                  valueID={modalValue.type}
                                  onChange={o => changeTypeHandler(o.id)}
                                  error={check('type')}
                    />
                </div>
            </div>
            <div className="superModalGroup__modalLine">
                <div>
                    <p className="superModalGroup__label">Тип часов:</p>
                    <SelectCustom
                        placeholder={localisationMaster('text_37', 'modal_templates')}
                        apiLink={'groups/hours_types'}
                        valueID={modalValue.hours_type}
                        onChange={o => changeValues(o.id, 'hours_type')}
                        error={check('hours_type')}
                    />
                </div>

                <div>
                    <p className="superModalGroup__label">Всего часов:</p>
                    <input placeholder={localisationMaster('text_38', 'modal_templates')}
                           type="text"
                           disabled={+modalValue.type !== 1}
                           value={modalValue.hours_sum !== undefined ? modalValue.hours_sum : ''}
                           onChange={e => changeValues(e.target.value, 'hours_sum')}
                           className={check('hours_sum') ? 'super-modal__input--error' : ''}
                    />
                </div>
            </div>
            <div className="superModalGroup__modalLine">
                <div style={{width: '100%'}}>
                    <p className="superModalGroup__label">Компания:</p>
                    <SelectCustom
                        placeholder={localisationMaster('text_21', 'modal_templates')}
                        apiLink={'db/companies'}
                        valueID={modalValue.company}
                        onChange={o => changeValues(o.id, 'company')}
                        error={check('company')}
                    />
                </div>
            </div>
            <div className="superModalGroup__modalLine">
                <div style={{width: '100%'}}>
                    <p className="superModalGroup__noReqLabel">Локации:</p>
                    <Idlist height="200px" items={locations[1]} selected={modalValue.locations} getIds={getIds}/>
                </div>
            </div>
        </div>
    )
}

export default observer(SettingPayments);
