import React from 'react';
import style from './Message.module.scss'
import Avatar from "components/HeadBox/components/Avatar/Avatar";
import {dateFormat} from "helpers/date";
import Stores from "Stores";

const Message = ({message}) => {
    return (
        <div className={style.container}>
            <div className={style.personalInfo}>
                <div className={style.person}>
                    <div className={style.avatar}>
                        <Avatar first_name={message?.sender_info.first_name} last_name={message?.sender_info?.last_name} link={message?.sender_info?.avatar}/>
                    </div>
                    <div className={style.info}>
                        <span className={style.name}>
                            {Stores.transliterate(`${message?.sender_info?.first_name} ${message?.sender_info?.last_name}`)}
                        </span>
                        <span className={style.title}>{message.sender_type}</span>
                    </div>
                </div>
                <div className={style.date}>
                    {
                        dateFormat(message.created_at, 'dd MMM YYYY hh:ii')
                    }
                </div>
            </div>
            <div className={style.message}>
                    {
                        message.text
                    }
            </div>
        </div>
    );
};

export default Message;