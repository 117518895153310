import React from 'react';
import { observer } from 'mobx-react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Flex from 'components/UI/Flex/Flex';
import SelectorLayout from 'components/Modules/Slots/TeacherSchedule/layouts/SelectorLayout';
import CalendarLayout from 'components/Modules/Slots/TeacherSchedule/layouts/CalendarLayout';
import SlotsLayout from 'components/Modules/Slots/TeacherSchedule/layouts/SlotsLayout';
import SubmitLayout from 'components/Modules/Slots/TeacherSchedule/layouts/SubmitLayout';
import { localisationMaster } from "Localisation/Localisation";
import slotsTeacherSchedule from 'Stores/slotsTeacherScheduleStore';
import styles from './TeacherSchedule.module.scss';
import BookedSlots from 'components/Modules/Slots/TeacherSchedule/components/BookedSlots/BookedSlots';
import MonthlyPlan from './components/MonthlyPlan/MonthlyPlan';

function TeacherSchedule() {
  const { location, selectedDates, slots, selectedSlots, bookedSlots } = slotsTeacherSchedule;

  return (
    <>
      <ContentHeader title={localisationMaster('title', 'teacher_schedule')} />
      <Flex asColumn withGap classes={styles.wrapper}>
        <MonthlyPlan />
        <SelectorLayout />
        { location && <CalendarLayout /> }
        { selectedDates.size > 0 &&
          <>
            { bookedSlots.length > 0 && <BookedSlots /> }
            { slots.length > 0 && <SlotsLayout/> }
            { selectedSlots.size > 0 && <SubmitLayout /> }
          </>
        }
      </Flex>
    </>
  );
}

export default observer(TeacherSchedule);
