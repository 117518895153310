import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react";
import modalStore from "Stores/modalStore";
import InputPhone from 'components/ContentBox/Global/InputPhone/InputPhone';
import './MultiPhoneNumbers.scss';
import { translate as loc, dict } from "Localisation";

const MultiPhoneNumbers = observer( () => {

    const {modalValue, changeValues} = modalStore

    const [phones, setPhones] = useState(modalValue.additional_phones);

    const incrementInput = () => {
        if (phones.length === 5) return
        setPhones(phones.concat(''));
    }

    const decrementInput = index => {
        const arr = phones;
        arr.splice(index, 1);
        setPhones([...arr]);
    }

    const onPhoneChangeHandler = (val, idx) => {
        const arr = phones;
        arr[idx] = val;
        setPhones([...arr]);
    }

    useEffect(() => {
        const aNotEmpty = phones.filter(x => x.length)
        changeValues(aNotEmpty, 'additional_phones')
    }, [phones])

    return (
        <div className='modalMultiPhoneNumbers'>

            {phones.length < 1 &&
            <span>{loc(dict.pays.text_1)}</span>}

            {phones.map((item, idx) =>
                <div key={idx} className="modalMultiPhoneNumbers__phone">
                    <InputPhone value={phones[idx]} onChange={val => onPhoneChangeHandler(val, idx)}/>
                    <div className="modalMultiPhoneNumbers__btnDel" onClick={() => decrementInput(idx)}>&times;</div>
                </div>
            )}

            <button className="modalMultiPhoneNumbers__btnAdd" onClick={incrementInput}>+</button>
        </div>
    )
})

export default MultiPhoneNumbers;
