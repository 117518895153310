import React from 'react';
import styles from "./EmptyChapterCard.module.scss";
import emptyChapterCard from "../img/empty_chapter_card.svg";

const EmptyChapterCard = ({translate}) => {
  return (
    <div className={styles.emptyChapterCardBlock}>
      <img className={styles.emptyChapterCardImg} src={emptyChapterCard} alt="Empty chapter"/>
      <p className={styles.text}>{translate.empty_chapters}.</p>
    </div>
  );
};

export default EmptyChapterCard;