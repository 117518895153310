import React, { useEffect, useState } from 'react';
import styles from './MakeSentence.module.scss';
import { classNames as cn } from "helpers";

export default function MakeSentence(props) {
  //console.log(props.studentAnswer)
  const [words, setWords] = useState([])
  const [answers, setAnswers] = useState([])
  const [selected, setSelected] = useState([])

  const [touchIndex, setTouchIndex] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  let dragged, dragIndex

  const onDragStartHandler = (e, i) => {
    dragged = e.target
    dragIndex = i
    e.target.classList.add(styles.onDrag)
  }

  const onDragEndHandler = e => {
    e.target.classList.remove(styles.onDrag)
  }

  const onDragEnterHandler = e => {
    e.target.classList.add(styles.onDragOver)
  }

  const onDragLeaveHandler = e => {
    e.target.classList.remove(styles.onDragOver)
  }

  const onDragOverHandler = e => {
    // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
    if (dragged)
      // prevent default to allow drop
      e.preventDefault()
  }

  const onTouchStartHandler = (e, i) => {
    setIsTouched(true)
    e.target.classList.add('onDrag')
    setTouchIndex(i);
  }

  const onTouchEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const onDropOrTouchHandler = ({ target }, index) => {
    target.classList.remove('onDragOver')
    if(touchIndex === null && !dragged) return
    // Удаляем элемент из слов
    const newSelecteds = [...selected];
    const wordIndex = isTouched ? touchIndex : dragIndex
    newSelecteds[wordIndex].state = true;
    setSelected(newSelecteds);
    setWords(newSelecteds.filter(item => !item.state).map(item => item.word))

    const arr = answers.slice()
    arr[index] = selected[wordIndex].word;
    setAnswers(arr)
  }

  const dropZone = (index, isCorrect) => answers[index] ?
    <span key={index}
          onTouchStart={() => setIsTouched(false)}
          className={cn({
            [styles.show]: true,
            [styles.correct]: isCorrect,
            [styles.mistake]: isCorrect === false
          })}
          onClick={() => {
            if (isTouched) {
              setTouchIndex(null)
              return
            }
            setAnswers(answers.map((ans, idx) => idx === index ? '' : ans))
            setWords([...words, answers[index]])

            const idxSelected = selected?.findIndex(item => item.word === answers[index] && item.state);
            if (idxSelected >= 0) {
              const newSelecteds = [...selected];
              newSelecteds[idxSelected].state = false;
              setSelected(newSelecteds);
            }
          }}>
      {answers[index]}
    </span>
    :
    <span key={index}
          className={styles.hide}
          onDragEnter={onDragEnterHandler}
          onDragLeave={onDragLeaveHandler}
          onDragOver={onDragOverHandler}
          onDrop={e => onDropOrTouchHandler(e, index)}
          onTouchStart={e => onDropOrTouchHandler(e, index)}/>

  useEffect(() => {
    if (props.studentAnswer?.answer){
      const answers = props.studentAnswer.answer.split('|');
      setWords([])
      setAnswers(answers)
      setSelected(answers.map(word => ({ word, state: true })));
    } else {
      const arr = props.sentence.split('|')
      setWords(arr)
      setSelected(arr.map(word => ({ word, state: false })))
      setAnswers(new Array(arr.length).fill(''))
    }
  }, [props.sentence, props.try, props.studentAnswer?.answer])

  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    const isActive = props.studentAnswer ? false : !words.length
    props.handleAnswers(answers, isActive)
  }, [answers, words])

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
      {
        <>
          <div className={styles.top}>
            {selected.map(({ word, state }, index) =>
              <span
                key={index}
                draggable
                onDragStart={e => onDragStartHandler(e, index)}
                onDragEnd={onDragEndHandler}
                onTouchStart={e => onTouchStartHandler(e, index)}
                onTouchEnd={onTouchEndHandler}
                className={state ? styles.selected : ''}
              >
                <div className={styles.dote}>
                  <div></div>
                </div>
                {word}
              </span>
            )}
          </div>
          <div className={cn({
            [styles.bottom]: true,
          })}>
            {answers.map((word, index) => dropZone(index, props.studentAnswer?.result_in_detail[index]))}
          </div>
        </>
      }
      </div>
    </div>
  )
}
