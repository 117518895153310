import React, {useEffect, useReducer, useState} from 'react';
import { superAxiosRequest } from "axiosApi";
import { timeConverter } from '../../Online-schedule/components/functions';
import DatePicker from 'react-datepicker';
import ContentHeader from "../../Global/ContentHeader";
import DaysExplain from '../Employee/Tabs/Schedule/DaysExplain';
import "./WorkTable.scss"
import SelectCustom from "../../Global/Select/SelectCustom";
import MultiSelect from "../../Global/Idlist/MultiSelect";
import Schedule from "../Employee/Tabs/Schedule/Schedule";
import { translate as loc, dict } from "Localisation";
import { useHistory } from 'react-router-dom';


const initFilter = {
    company: 0,
    location: 0,
    department: 0,
    profession: 0,
    date: new Date(),
    teachers: []
}

export default function WorkTable() {
    const [selectedTeachers, setSelectedTeachers] = useState([])
    const [locationTeachers, setLocationTeachers] = useState([])
    const [catalog, setCatalog] = useState({})
    const [filter, dispatch] = useReducer(reducer, initFilter, () => initFilter)
    const history = useHistory();

    const getLocations = id => catalog.companies?.items.find(x => x.id === id)?.locations || []

    const getProfessions = id => catalog.professions?.employments.find(x => x.id === id)?.professions || []

    const getReport = () => {
        const uri = `?date=${timeConverter(filter.date, 'today')}&employee_id=${filter.teachers.map(el => el.id).join(',')}`;
        history.push('/company/attendance-records' + uri)
    }

    function reducer (state, action) {
        switch (action.type) {
            case 'company':
                return {...state, company: action.id, location: 0}
            case 'location':
                return {...state, location: action.id}
            case 'department':
                return {...state, department: action.id, profession: 0}
            case 'profession':
                return {...state, profession: action.id}
            case 'date':
                return {...state, date: action.date}
            case 'teachers':
                return {...state, teachers: action.teachers}
            case 'reset':
            default:
                setSelectedTeachers([])
                return initFilter
        }
    }

    useEffect(() => {
        // Каталог
        let apiConf = {
            method: 'get',
            link: 'accounts/employers_catalog'
        }
        superAxiosRequest(apiConf).then(res => {
            setCatalog(res.data)
        })

        // Учителя
        apiConf = {
            method: 'get',
            link: 'accounts/employers_flat_list',
        }
        superAxiosRequest(apiConf, 'data').then(response => {
            const emp = response.data.result.map(x => ({ id: x.id, name: x.full_name, user_id: x.user_id  }))
            setLocationTeachers(emp)
        })
    }, [])

    useEffect(() => {
        if (filter.department !== 0 || filter.location !== 0 || filter.company !== 0 || filter.profession !== 0) {
            const apiConf = {
                method: 'get',
                link: 'accounts/employers_flat_list',
                params: {
                    ...(filter.department !== 0 && {employment: filter.department}),
                    ...(filter.location !== 0 && {location: filter.location}),
                    ...(filter.company !== 0 && {company: filter.company}),
                    ...(filter.profession !== 0 && {profession: filter.profession}),
                }
            }
            superAxiosRequest(apiConf).then(response => {
                const teachers = response.data.result.map(x => ({ id: x.id, name: x.full_name, user_id: x.user_id}))
                dispatch({type: 'teachers', teachers})
            })
        }
    }, [filter.department, filter.location, filter.company, filter.profession])

    const DpCustomInput = React.forwardRef(({value, onClick}, ref) => (
        <div className="workTable__dpInputButton" onClick={onClick} ref={ref}>
            {timeConverter(value, 'month_year')}
        </div>
    ));

    return (
        <>
            <ContentHeader title={loc(dict.work_table_listing.title)} />
            <div className="onlineSchedulePage workTable">
                <div className="workTable__filter">
                    <div className="workTable__filterCol">
                        <div>
                            <SelectCustom placeholder={loc(dict.work_table_listing.choose_company)}
                                          apiLink="db/companies"
                                          sortKey="name"
                                          valueID={filter.company}
                                          onChange={o => dispatch({type: 'company', id: o.id})}/>
                        </div>
                        <div>
                            <SelectCustom placeholder={loc(dict.work_table_listing.choose_location)}
                                          options={getLocations(filter.company)}
                                          valueID={filter.location}
                                          onChange={o => dispatch({type: 'location', id: o.id})}
                                          isDisabled={!filter.company}
                            />
                        </div>
                        <div>
                            <SelectCustom placeholder={loc(dict.work_table_listing.choose_department, 'firstUpperCase')}
                                          apiLink="accounts/employment_types_catalog"
                                          sortKey="name"
                                          valueID={filter.department}
                                          onChange={o => dispatch({type: 'department', id: o.id})}/>
                        </div>
                        <div>
                            <SelectCustom placeholder={loc(dict.work_table_listing.choose_position, 'firstUpperCase')}
                                          options={getProfessions(filter.department)}
                                          valueID={filter.profession}
                                          onChange={o => dispatch({type: 'profession', id: o.id})}
                                          isDisabled={!filter.department}
                            />
                        </div>
                        <div>
                            <div className="workTable__dpInput">
                                <DatePicker
                                    selected={filter.date}
                                    onChange={date => dispatch({type: 'date', date})}
                                    showMonthYearPicker
                                    locale={'ru'}
                                    customInput={<DpCustomInput />}
                                />
                            </div>
                        </div>
                        <div className="workTable__buttonsPlace"/>
                    </div>
                    <div>
                        <MultiSelect options={locationTeachers}
                                     sortKey="name"
                                     values={filter.teachers}
                                     onChange={teachers => dispatch({type: 'teachers', teachers})}/>
                    </div>
                    <div className="workTable__filterButtons">
                        <button onClick={() => setSelectedTeachers(filter.teachers)}>
                           Применить
                        </button>
                        <button disabled={filter.teachers.length <= 0} onClick={getReport}>Отчет</button>
                        <button className='workTable__clear' onClick={() => dispatch({type: 'reset'})}>&#x2715;</button>
                    </div>
                </div>

                {selectedTeachers.length > 0 &&
                <>
                    <div className="extendedTeachersSchedule__count">
                        {loc(dict.work_table_listing.employees, 'Сотрудников')}: {selectedTeachers.length}
                    </div>

                    <div className="onlineSchedulePage__mt20">{selectedTeachers.map(item =>
                        <div className="workTable__card" key={item.id}>
                            <Schedule name={item.name}
                                      date={filter.date}
                                      id={item.id}
                                      userID={item.user_id}
                                      selectedTeachers={selectedTeachers}
                            />
                        </div>)}
                    </div>

                    <div className="onlineSchedulePage__mt20">
                        <DaysExplain />
                    </div>
                </>}
            </div>
        </>
    )
}
