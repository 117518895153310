import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Avatar from "components/ContentBox/Company/Teachers/Avatar";
import {colors} from "components/ContentBox/Global/ContentTable_test/avatar";
import {dateFormat} from "helpers/date";
import {localisationMaster, translate as loc} from "Localisation/Localisation";
import {classNames as cn} from "helpers";
import Stores from "Stores";
import st from "./UserInfo.module.scss";
import {ReactComponent as CameraIcon} from "../Employee/img/camera-switch-line.svg";
import {ReactComponent as UserIsActiveIcon} from "../Employee/img/user_isActive_status.svg";
import {ageToStr} from "../functions";
import {dict} from "Localisation";
import ModalStore from "../../../../Stores/modalStore";
import swal from "sweetalert";
import {superAxiosRequest} from "../../../../axiosApi";
import kyndelikSrc from "../../../ContentBox/img/kyndelik.svg";


const UserInfo = ({user: {profile, ...user}, updateProfile, ...props}) => {
  // Модальное окно аватара
  const modalAvatarSettings = {
    name: 'companies',
    link: 'accounts/account_update/' + user.id,
    media: true,
    is_put: true,
    button: localisationMaster('modal_title_1', 'company/profiles/account'),
    title: localisationMaster('modal_title_2', 'company/profiles/account'),
    type: 'AddAvatar',
    data: 'media',
    pl_textarea_second: localisationMaster('select-placeholder-5', 'company/companies', 'add_company'),
    name_text: 'name',
    name_text_second:'widget_link',
    name_textarea: 'comment',
    name_media: 'avatar'
  }

  const [kundelikUrl, setKundelikUrl] = useState('')

  // Пополнение баланса
  const addBalance = () => {
    const body = {
      name: 'addPayment',
      link: 'payments/pay_orders',
      button: loc(dict.main_page.add),
      title: `${localisationMaster('title', 'pays/create-pay')}: ${profile.full_name}`,
      type: 'addPayment',
      data: 'json',
      width: '650px',
      client_id: profile.id,
      func: res => window.location.href = '/pays/invoice/' + res.order_id
    }
    ModalStore.activity(body, {status_sending_mail: 'need_mail', amount: 0})
  }

  const changeActiveHandler = () => {
    const is_active = profile.is_active
    swal({
      title: localisationMaster('text_2', 'alert'),
      text: `Вы действительно хотите перевести клиента ${profile.full_name} в статус ${is_active ? 'неактивный' : 'активный'}? ${!is_active ? '' : 'Клиент утратит доступ в личный кабинет'}`,
      icon: "warning",
      buttons: [
        localisationMaster('button_2', 'alert'),
        localisationMaster('button_1', 'alert')
      ],
      dangerMode: true,
    }).then(yes => {
      if (yes) {
        const json = {
          is_active: !is_active
        }
        let apiConf = {
          method: 'put',
          link: 'accounts/account_update/' + user.id
        }
        superAxiosRequest(apiConf, json).then(res => {
          const is_active = res.data.profile.is_active
          //setActive(is_active)
          updateProfile(res.data)
          swal(
              "",
              `Вы перевели клиента ${profile.full_name} в статус ${is_active ? 'активный' : 'неактивный'}`,
              "success"
          )
        })
      } else {
        swal('Действие отменено')
      }
    })
  }

  useEffect(() => {
    if (!props.isShowKundelik) return
    superAxiosRequest({
      method: 'get',
      link: 'kundelik/get_url'
    }).then(({data}) => {
      setKundelikUrl(data.url)
    }).catch(() => {

    })
  }, [props.isShowKundelik]);

  return (
    <div className={st.userInfo}>
      <div className={st.header}>
        <div className={st.avatar}>
          {profile.avatar ?
            <Avatar link={profile.avatar}/>
            :
            <div className={st.nameAvatar}
                 style={{backgroundColor: colors(profile.first_name + profile.last_name)}}>
              <div>{Stores.transliterate(profile.first_name[0].toUpperCase() +  profile.last_name[0].toUpperCase())}</div>
            </div>}
          <div className={st.cameraBtn} onClick={()=> Stores.SuperModal.activity(modalAvatarSettings)}>
            <CameraIcon/>
          </div>
        </div>
        <div className={st.info}>
          <div>{`${Stores.transliterate(profile.first_name)} ${Stores.transliterate(profile.last_name)}`}</div>
          <div>id: {profile.id}</div>
          <div>{`${ageToStr(profile.date_of_birth)} | ${dateFormat(profile.date_of_birth, "d MMM YYYY")}`}</div>
        </div>
        {Date.now() < 0 &&
          <div className={st.topUpBalance} onClick={addBalance}>{props.translate.top_up_balance}</div>}
        {Stores.Rules.checkRule('user_is_active', ['view', 'view_own']) &&
          <div onClick={changeActiveHandler} className={`${st.statusBtn} ${profile.is_active ? st.green : st.red}`}>
            <UserIsActiveIcon/>
          </div>}
        {kundelikUrl &&
          <div className={st.kyndelik}>
            <a href={kundelikUrl} target="_blank" className={st.kyndelikButton}>
              <img src={kyndelikSrc} alt="kyndelik"/>
            </a>
          </div>}
      </div>
      <ul className={st.menu}>{props.tabs.map(tab =>
        <li key={tab.id} className={cn({
          [st.menuItem]: true,
          [st.menuItemSelected]: tab.id === props.currentTab,
        })} onClick={() => props.onChangeTab(tab.id)}>{tab.name}</li>)}
      </ul>
    </div>
  )
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    tab: PropTypes.element,
  })),
  currentTab: PropTypes.number,
  onChangeTab: PropTypes.func,
}

export default UserInfo
