import React, {useEffect, useRef, useState} from "react";
import "./QuestionYoutube.scss"
import {ReactComponent as IconArrow} from "components/ContentBox/img/left-arrow-2.svg";

export default function QuestionYoutube(props) {

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

    useEffect(() => {
        const slides = refSlides.current
        const slide = slides.children[currentSlideIndex]
        const offset = Math.min(slide.offsetLeft, slides.scrollWidth - slides.parentElement.offsetWidth)
        slides.style.left = -offset + 'px'
    }, [currentSlideIndex])

    const refSlides = useRef()

    const onSlideHandler = direction => {
        if (direction === 'prev')
            setCurrentSlideIndex(Math.max(currentSlideIndex - 1, 0))
        if (direction === 'next')
            setCurrentSlideIndex(Math.min(currentSlideIndex + 1, props.videos.length - 1))
    }

    const returnLink = link => {
        let res = link.split('/')
        if (link.includes('you')) {
            //link = `https://www.youtube.com/embed/` + res[res.length - 1]
            const uid = res[res.length - 1].split('v=')[1]
            link = `https://www.youtube.com/embed/` + uid
        }
        if (link.includes('vimeo')) {
            link = `https://player.vimeo.com/video/` + res[res.length - 1]
        }
        if (link.includes('ted')) {
            link = `https://embed.ted.com/talks/` + res[res.length - 1]
        }
        if (link.includes('vk')) {
            link = `https:` + test.match(/\"(.+?)\"/g)[0].split("\"")[1]
        }
        return link
    }

    return (
        <>
            <div className="questionYoutube">
                <div className="questionYoutube__slider">
                    <div>
                        <ul ref={refSlides}>
                            {props.videos.map((video, index) =>
                                <li key={index}>
                                    <div className="questionYoutube__videoBox">
                                        <iframe
                                            src={returnLink(video.video_link)}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                    <span>{video.description}</span>
                                </li>
                            )}
                        </ul>
                    </div>

                </div>
                {props.videos.length > 1 &&
                <div className="questionYoutube__control">
                    <div onClick={() => {
                        onSlideHandler('prev')
                    }} data-disabled={currentSlideIndex < 1}><IconArrow/></div>
                    <span>{`${currentSlideIndex + 1}`.padStart(2, '0') + ' из ' + `${props.videos.length}`.padStart(2, '0')}</span>
                    <div onClick={() => {
                        onSlideHandler('next')
                    }} data-disabled={currentSlideIndex > props.videos.length - 2}><IconArrow/></div>
                </div>
                }

            </div>
            <div className="lessonQuestion__contentTextOnly" dangerouslySetInnerHTML={{__html: props.text}}/>
        </>
    )
}