import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimeSlots from 'components/Modules/Slots/components/TimeSlots/TimeSlots';
import Flex from 'components/UI/Flex/Flex';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import { ObservableSet } from 'mobx';

const ToggleType = {
  isOnline: 'is_online',
  isSchool: 'is_school',
};

function TimeSlotsWrapper({slots, selectedSlots, toggles, defaultTimeZone, onReset, onClick, onTimeZoneChange, onTogglesChange}) {
  const [sortedSlots, setSortedSlots] = useState(slots);

  const handleToggleChange = () => {
    onTogglesChange({
      [ToggleType.isOnline]: !toggles[ToggleType.isOnline],
      [ToggleType.isSchool]: !toggles[ToggleType.isSchool],
    });
  };

  useEffect(() => {
    let sorted;
    const selectedSlotID = [...selectedSlots.values()][0];

    if (toggles.is_school && toggles.is_online) {
      sorted = slots;
    } else {
      if (toggles.is_online) {
        sorted = slots.filter(({is_online}) => is_online);
      }
      if (toggles.is_school) {
        sorted = slots.filter(({is_school}) => is_school);
      }
    }

    const isSelectedSlotsIncluded = sorted.find(({id}) => id === selectedSlotID);
    if (selectedSlots && !isSelectedSlotsIncluded) {
      onReset();
    }

    setSortedSlots(sorted);
  }, [toggles, slots]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <TimeSlots
      slots={sortedSlots}
      toggles={toggles}
      selectedSlots={selectedSlots}
      defaultTimeZone={defaultTimeZone}
      withMultipleSelect={false}
      onClick={onClick}
      onTimeZoneChange={onTimeZoneChange}
    >
      <Flex asColumn withGap align='start' tag='ul'>
        <InputToggle
          tag='li'
          label='В школе'
          name={ToggleType.isSchool}
          selected={toggles.is_school}
          onChange={handleToggleChange}
        />
        <InputToggle
          tag='li'
          label='Онлайн (ZOOM)'
          name={ToggleType.isOnline}
          selected={toggles.is_online}
          onChange={handleToggleChange}
          withMargin
        />
      </Flex>
    </TimeSlots>
  );
}

TimeSlotsWrapper.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_at: PropTypes.string.isRequired,
    finish_at: PropTypes.string.isRequired,
  })).isRequired,
  selectedSlots: PropTypes.oneOfType([
    PropTypes.instanceOf(Set),
    PropTypes.instanceOf(ObservableSet),
  ]).isRequired,
  onReset: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onTimeZoneChange: PropTypes.func,
  onTogglesChange: PropTypes.func,
  toggles: PropTypes.shape({
    is_online: PropTypes.bool,
    is_school: PropTypes.bool,
  }),
  defaultTimeZone: PropTypes.number,
};

export default TimeSlotsWrapper;
