import React from 'react';
import { localisationMaster } from "Localisation/Localisation";
import styles from './WeekDays.module.scss';

const weekDays = [
  {id: 1, text: localisationMaster('day-1', 'online-schedule/time-slots')},
  {id: 2, text: localisationMaster('day-2', 'online-schedule/time-slots')},
  {id: 3, text: localisationMaster('day-3', 'online-schedule/time-slots')},
  {id: 4, text: localisationMaster('day-4', 'online-schedule/time-slots')},
  {id: 5, text: localisationMaster('day-5', 'online-schedule/time-slots')},
  {id: 6, text: localisationMaster('day-6', 'online-schedule/time-slots')},
  {id: 7, text: localisationMaster('day-7', 'online-schedule/time-slots')},
];

function WeekDays() {
  return (
    <ul className={styles.weekdays}>
      {weekDays.map(({id, text}) => (
        <li key={id.toString()} className={styles.weekday}>
          {text}
        </li>
      ))}
    </ul>
  );
}

export default WeekDays;

