import {superAxiosRequest} from 'axiosApi';
import {timeConverter} from '../../Online-schedule/components/functions';
import groupStore from 'Stores/groupStore';
import modalStore from 'Stores/modalStore';
import slotsStore from 'Stores/slotsStore';

export const makeid = () => {
    var text = "";
    var possible = "1234567890abcdefghijklmnopqrstuvwxyz";
    for (var i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

export const ttd = (qJson, apiConf, ms) => {
    if (qJson.status) {
        apiConf.link = 'schedule/schooldays_notes'
        if (ms.main_s) {
            apiConf.link = 'schedule/schooldays'
        }
    }
    if (ms.is_put && !qJson.date_from) {
        if (ms.main_s) {
            apiConf.link = 'schedule/schooldays/' + ms.is_put[1]
        } else {
            apiConf.link = 'schedule/schooldays_notes/' + ms.is_put[1]
        }
    }
    if (qJson.date_from) {
        apiConf.method = 'post'
        delete qJson.dates
    }

    return {
        qJson,
        apiConf,
    }
}

export const trio = (qJson, apiConf, ms) => {
    let result = {
        qJson: { ...qJson },
        apiConf: { ...apiConf },
        type: ms.data || 'data'
    }
    switch (ms.name) {
        case 'certificate-to-user':
            let certs = ms.select_array
            let active = certs.find(x => x.id === qJson.cert)
            let end_date = new Date(qJson.start_at);
            end_date.setDate(end_date.getDate() + active.days_active);
            result.qJson.end_at = timeConverter(end_date, 'today')
            break;
        case 'lessons-levels':
            if (result.qJson.sub_lvl.length > 0) {
                let subLevels = result.qJson.sub_lvl.split(',');
                result.qJson.sub_lvl = subLevels.map(item => ({name: item}));
            }
            break;
        case 'teacher_booked':
            let apiConf_teacher_booked, body;
            if (ms.id !== undefined) {
                switch (result.qJson.action) {
                    case 1:
                        body = {
                            is_active: !ms.data.is_active
                        }
                        apiConf_teacher_booked = {
                            method: 'put',
                            link: 'time_slots/teacher/slots/' + ms.id
                        }
                        superAxiosRequest(apiConf_teacher_booked, body).then(res => {
                            if (ms.tag === 'admin') {
                                slotsStore.updateSlot(res.data)
                            } else {
                                slotsStore.updateBookedSlot(res.data)
                            }
                            modalStore.activity();
                        }).catch(() => {})
                        break;
                    case 2:
                        body = {
                            deleted_reason: result.qJson.comment
                        }
                        apiConf_teacher_booked = {
                            method: 'delete',
                            link: 'time_slots/teacher/slots/' + ms.id
                        }
                        superAxiosRequest(apiConf_teacher_booked, body).then(() => {
                            let tmp_array_ts = {
                                id: ms.data.slot_client_id,
                                location_id: ms.location_id,
                                s_date: ms.data.s_date,
                                student: +ms.data.student_data.profile.id,
                                age: +ms.data.client_age.id,
                                program: +ms.data.client_program.id,
                                father_name: ms.data.student_data.profile.full_name,
                                last_name: ms.data.student_data.profile.last_name,
                                first_name: ms.data.student_data.profile.first_name,
                                email: ms.data.student_data.profile.email,
                                date_of_birth: ms.data.student_data.profile.date_of_birth,
                                student_phone: ms.data.student_data.profile.phone,
                                result: ms.data.client_result
                            }
                            localStorage.setItem('tmp_array_ts', JSON.stringify(tmp_array_ts))
                            window.location = '/online-schedule/record-customer'
                            modalStore.activity();
                        }).catch(() => {})

                        break;
                    case 3:
                        body = {
                            deleted_reason: result.qJson.comment
                        }
                        apiConf_teacher_booked = {
                            method: 'delete',
                            link: 'time_slots/teacher/slots/' + ms.id
                        }
                        superAxiosRequest(apiConf_teacher_booked, body).then(() => {
                            if (ms.tag === 'admin') {
                                slotsStore.makeDisactiveSlot(ms.t_id, ms.id);
                            } else {
                                slotsStore.makeDisactiveTSlot(ms.id);
                            }
                            modalStore.activity();
                        }).catch(() => {})
                        break;
                    case 4:
                    case 5:
                        body = {
                            is_visitor: !ms.data.is_visitor
                        }
                        apiConf_teacher_booked = {
                            method: 'put',
                            link: 'time_slots/teacher/slots/' + ms.id
                        }
                        superAxiosRequest(apiConf_teacher_booked, body).then(res => {
                            if (ms.tag === 'admin') {
                                slotsStore.updateSlot(res.data)
                            } else {
                                slotsStore.updateBookedSlot(res.data)
                            }
                            modalStore.activity();
                        }).catch(() => {})
                        break;
                    default:
                        break;
                }
            }
            result = false;
            break;
        case 'groups_add_course':
            result.qJson.group = ms.g_id
            result.qJson.start_date = timeConverter(result.qJson.start_date, 'today')
            break;
        case 'course-lesson':
        //case 'lessons_docs':
            result.type = 'media'
            // Почему сюда попадает image?
            //if(result.qJson.image === null) delete result.qJson.image
            if (!result.qJson.image) delete result.qJson.image
            break;
        default:
            break;
    }
    return result
}

export const forward = (qJson, apiConf, type) => {
    return {
        qJson: {...qJson},
        apiConf: {...apiConf},
        type: type || 'data'
    }
}

export const userToGroup = (qJsonO, apiConfO, ms) => {
    let qJson = { ...qJsonO }
    let apiConf = { ...apiConfO }
    let discount = qJson.discount;
    let surcharge = qJson.surcharge;
    let gr_status = qJson.gr_status;
    let start_study_at = qJson.start_study_at;
    delete qJson.gr_status;
    delete qJson.start_study_at;

    if (modalStore.tags.coru) {
        apiConf = {
            method: 'post',
            link: 'groups/admin_add_client_to_group'
        }
        qJson = {
            group_id: +ms.put_id,
            client_id: +modalStore.tags.coru,
            start_study_at: timeConverter(start_study_at, 'today'),
            student_status: +gr_status,
            default_price: qJson.default_price
        }
        if (discount) qJson.discount = discount
        if (surcharge) qJson.surcharge = surcharge
        superAxiosRequest(apiConf, qJson).then(() => {
            //groupStore.scheduleData = response.data
            groupStore.fetchGroup(+ms.put_id)
            modalStore.activity();
        }).catch(() => {
            modalStore.loadingHandler(false)
            /*if (error.response && error.response.status === 400) {
                let errors = error.response.data;
                let error_str = '';
                let err_arr = []
                for (var key in errors) {
                    error_str += [key] + " : " + errors[key] + '\r\n';
                    err_arr.push(key)
                }
                //seterrors([true, error_str, err_arr])
            }*/
        })
    } else {
        qJson['password'] = makeid()
        superAxiosRequest(apiConf, qJson).then(response => {
            apiConf = {
                method: 'post',
                link: 'groups/admin_add_client_to_group'
            }
            qJson = {
                group_id: +ms.put_id,
                client_id: +response.data.profile.id,
                start_study_at: timeConverter(start_study_at, 'today'),
                student_status: +gr_status
            }
            if (discount) qJson.discount = discount
            if (surcharge) qJson.surcharge = surcharge
            superAxiosRequest(apiConf, qJson).then(() => {
                //groupStore.scheduleData = response.data
                groupStore.fetchGroup(+ms.put_id)
                modalStore.activity();
            })
        }).catch(error => {
            modalStore.loadingHandler(false)
            if (error.response && error.response.status === 400) {
                let errors = error.response.data;
                //let errorsArray = Object.values(error.response.data)
                let error_str = '';
                //let error_str_text = '';
                let err_arr = []
                for (let key in errors) {
                    error_str += [key] + " : " + errors[key] + '\r\n';
                    err_arr.push(error_str)
                    //error_str_text += errors[key] + '\r\n';
                }
                modalStore.changeErrors(err_arr)
                err_arr.forEach(e => {
                    modalStore.ShowToast(e, 'error');
                })
            }
        })
    }
}

export const filesArr = (qJson, apiConf) => {
    const files = new Proxy(qJson.files, {})
    qJson.file_count = files.length
    const titles = new Proxy(qJson.titles, {})
    delete qJson.files;
    delete qJson.titles;
    for (let a = 0; a < files.length; a++) {
        qJson['file_' + (a + 1)] = files[a]
    }
    for (let b = 0; b < titles.length; b++) {
        qJson['file_' + (b + 1) + '_title'] = titles[b]
    }
    return {
        qJson: {...qJson},
        apiConf: {...apiConf},
        type: 'media'
    }
}

export const createPool = (qJson, apiConf, ms) => {
    let result = {
        qJson: { ...qJson },
        apiConf: { ...apiConf },
        type: 'data'
    }
    if (result.qJson.email_list.length > 0) {
        result.qJson.email_list = result.qJson.email_list.split(',');
    }
    result.qJson.program_age_relation_id = ms.id;
    return result
}

export const clientInfo = (qJson, apiConf, ms) => {
    let result = {
        qJson: { ...qJson },
        apiConf: { ...apiConf },
        type: 'data'
    }
    if (ms.create) result.qJson.password = makeid()
    if (!result.qJson.mobile_phone || result.qJson.mobile_phone.length === 0) delete result.qJson.mobile_phone
    if (!result.qJson.phone || result.qJson.phone.length === 0) delete result.qJson.phone
    if (result.qJson.date_of_birth === null) delete result.qJson.date_of_birth
    if (result.qJson.responsible === null || result.qJson.responsible === 0) delete result.qJson.responsible
    /* if (result.qJson.contact_persons && Object.keys(result.qJson.contact_persons[0]).length < 1 ) {
        delete result.qJson.contact_persons
    } else {
        for (let i = 0; i < result.qJson.contact_persons.length; i++) {
            if (result.qJson.contact_persons[i].mobile_phone && result.qJson.contact_persons[i].mobile_phone.length === 0) delete result.qJson.contact_persons[i].mobile_phone
            if (result.qJson.contact_persons[i].phone && result.qJson.contact_persons[i].phone.length === 0) delete result.qJson.contact_persons[i].phone
        }
    } */
    return result
}

export const lessonQuestion = (qJson, apiConf) => {
    let formData = new FormData(),
        body;

    switch (qJson.type_exercise) {
        case 'ex_audio':
        case 'ex_gif':
        case 'ex_video':
        case 'ex_image':
        case 'ex_attention':
        case 'ex_match_description_picture':
            for (let key in qJson) {
                if (key === 'files') {
                    for (let keyx in qJson['files']) {
                        if (!isNaN(+keyx)) formData.append('items', qJson['files'][keyx])
                    }
                } else if (qJson['multiupload'] && key === 'items') {
                    for (let i = 0; i < qJson.items.length; i++) {
                        formData.append(qJson['items'][i]['id'] + '_desc', qJson['items'][i]['description'])
                        formData.append(qJson['items'][i]['id'] + '_link', qJson['items'][i]['file'])
                        if (qJson.items[i].script) formData.append(qJson['items'][i]['id'] + '_script', qJson['items'][i]['script'])
                    }
                } else {
                    formData.append(key, qJson[key])
                }
            }
            body = formData
            break;
        case 'ex_essay':
            for (let key in qJson) {
                if (key === 'items') {
                    formData.append(qJson['items'][0]['id'] + '_title', qJson.items[0].title)
                    formData.append(qJson['items'][0]['id'] + '_link', qJson.items[0].file)
                    delete qJson.title
                } else if (key === 'image') {
                    formData.append('items', qJson.image)
                    delete qJson.image
                } else {
                    formData.append(key, qJson[key])
                }
            }
            body = formData
            break
        case 'ex_video_link':
        case 'ex_link':
        case 'ex_text':
        case 'ex_phrases_in_blanks':
        case 'ex_words_in_blanks':
        case 'ex_make_sentence':
        case 'ex_correct_form':
        case 'ex_word_matching':
        case 'ex_sort_words_in_columns':
        case 'ex_make_word_letters':
        case 'ex_test':
            body = qJson
            break;

        default:
            break;
    }
    return {
        body: body,
        apiConf: apiConf,
        type: 'ready'
    }
}

export const noApi = (qJson) => ({ qJson, type: 'noApi' });