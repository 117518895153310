import React from "react";
import {permissions} from "settings/permissions";
import {checkPermList} from "helpers";
import Stores from "Stores";
import {ReactComponent as BuildingIcon} from "./img/building-2-line.svg";
import {ReactComponent as CalendarIcon} from "./img/calendar-check-line.svg";
import {ReactComponent as DashboardIcon} from "./img/dashboard-line.svg";
import {ReactComponent as OpenArmIcon} from "./img/open-arm-line.svg";
import {ReactComponent as ParentIcon} from "./img/parent-line.svg";
import {ReactComponent as RefundIcon} from "./img/refund-2-line.svg";
import {ReactComponent as SettingsIcon} from "./img/settings-5-line.svg";
import {ReactComponent as TeamIcon} from "components/UI/icons/team-line.svg";
import {ReactComponent as TodoListIcon} from "components/UI/icons/todo-line.svg";
import {ReactComponent as BookIcon} from "components/UI/icons/book-2-line.svg";
import {ReactComponent as PieIcon} from "components/UI/icons/pie-chart-line.svg";
import {ReactComponent as WalkIcon} from "components/UI/icons/walk-line.svg";

export const checkBigMenu = items => {
    let count = 0
    let parent, key;
    for (let a in items) {
        for (let b in items[a]) {
            if (b === 'items') {
                for (let c in items[a][b]) {
                    for (let d in items[a][b][c]) {
                        if (d === 'parent_key') parent = items[a][b][c][d]
                        if (d === 'key') key = items[a][b][c][d]
                        if (d === 'permissions') {
                            if (Stores.Rules.checkRule2(parent, key, items[a][b][c][d])) count += 1
                        }
                    }
                }
            }
        }
    }
    return count > 0
}

export const checkCats = array => array.some(checkPermList)

export const menuSettings = [
    {
        id: 1,
        title: 'online_booking',
        cats: [
            {
                title: 'online_booking',
                roles: ['admin', 'teacher', 'students', 'clients'],
                icon: <CalendarIcon />,
                items: [
                    // {
                    //     title:'schedule_settings', // объеденить с configuring_slots
                    //     ...permissions.view_schedule_settings
                    // },
                    // {
                    //     title: 'configuring_slots',
                    //     ...permissions.view_time_slots
                    // },
                    // {
                    //     title:'customer_record',
                    //     ...permissions.record_online_customer
                    // },
                    // {
                    //     title: 'clients_schedule',
                    //     ...permissions.view_booked_online_scedule
                    // },
                    // {
                    //     title: 'time_schedule_of_teachers',
                    //     ...permissions.view_booked_teacher_scedule
                    // },
                    // /* {
                    //     title : localisationMaster('sub-title-online-8', Link),
                    //     link  : '/groups/sign-up',
                    //     //svg   : profiles,
                    //     permissions: ['studygroup', 'add_myself']
                    // }, */
                    //
                    // {
                    //     title: 'teacher_schedule_history',
                    //     ...permissions.view_extended_teacher_scedule
                    // },
                    // {
                    //     title: 'customer_record_history',
                    //     ...permissions.view_clients_after_online
                    // },
                    {
                        title: 'online_booking_settings', // объеденить с schedule_settings
                        ...permissions.view_company_settings,
                    },
                    {
                        title: 'schedule_settings',
                        ...permissions.view_schedule_settings_v2,
                    },
                    {
                        title: 'my_schedule',
                        ...permissions.view_teacher_schedule_v2,
                    },
                    {
                        title: 'customer_booking',
                        ...permissions.record_online_customer_v2,
                    },
                    {
                        title: 'customer_booking_schedule',
                        ...permissions.view_booked_online_schedule_v2,
                    },
                    {
                        title: 'future_bookings',
                        ...permissions.view_online_slots_v2,
                        link: 'online-schedule-v2/slots/future/',
                    },
                    {
                        title: 'past_bookings',
                        ...permissions.view_online_slots_v2,
                        link: 'online-schedule-v2/slots/past/',
                    },
                    {
                        title: 'customer_booking_history',
                        ...permissions.view_clients_after_online_v2
                    },
                    {
                        title: 'employee_booking_history',
                        ...permissions.view_teachers_history,
                    },
                    {
                        title: 'employee_schedule_calendar',
                        ...permissions.edit_advanced_teachers_history,
                    },
                    // {
                    //     title:'configuring_slots',
                    //     ...permissions.view_my_slot_settings,
                    // }
                ]
            },
        ]
    }, // Онлайн запись
    {
        id: 2,
        name: 'education',
        title: 'training',
        roles: ['admin', 'teacher', 'students', 'clients'],
        cats: [
            {
                title: 'learning',
                roles: ['admin', 'teacher', 'students', 'clients'],
                icon: <ParentIcon />,
                items: [
                    {
                        title: 'groups',
                        ...permissions.view_group_list
                    },
                    // {
                    //     title: 'clients_joined_groups',
                    //     ...permissions.view_group_sign_up
                    // },
                    {
                        title: 'clients_joined_groups',
                        //link: '/c/groups/self-sign-up/',
                        ...permissions.view_group_sign_up_clients
                    },
                    {
                        title: 'group_status_change',
                        ...permissions.change_groups_statuses
                    },
                    // {
                    //     title: localisationMaster('sub-title-company-6', Link),
                    //     ...permissions.view_course_list
                    // },

                ]
            },
        ]
    }, // Обучение + Дополнительно(убрал) + подпункты книги
    {
        id: 3,
        name: 'tests',
        title: 'Educational testing',
        roles: ['admin', 'teacher', 'students', 'clients'],
        cats: [
            {
                title: 'educational_testing',
                roles: ['admin', 'teacher', 'students', 'clients'],
                icon: <TodoListIcon />,
                items: [
                    {
                        title: 'tests',
                        ...permissions.view_studytest_list
                    },
                    /*{
                        title: 'tests_v2',
                        ...permissions.view_study_tests
                    },*/
                    {
                        title: 'assigned_to_clients',
                        ...permissions.view_studytest_purpose_test_list
                    },
                    /*{
                        title: 'assigned_to_clients',
                        ...permissions.view_purpose_tests
                    },*/
                    {
                        title: 'view_studytest',
                        ...permissions.view_studytest_purpose_student_test_list
                    },
                    {
                        title: 'requested_tests',
                        ...permissions.view_request_test
                    },
                    /*{
                        title : 'Назначенные тесты',
                        link  : '/c/study/purpose-test',
                        //svg   : groupLevels,
                        permissions: ['purposetestteacher', 'view_own']
                    },
                    {
                        title : 'Запрошенные тесты',
                        link  : '/c/study/request-test',
                        //svg   : groupLevels,
                        permissions: ['studytest', 'view']
                    },
                    {
                        title : localisationMaster('sub-test-1', Link),
                        link  : '/c/tests/past-tests-students',
                        //svg   : settings,
                        permissions: ['teachertestresult', 'view']
                    },
                    {
                        title : localisationMaster('sub-test-2', Link),
                        link  : '/c/tests/past-tests',
                        //svg   : settings,
                        permissions: ['teachertestpoll', 'view']
                    },
                    {
                        title : localisationMaster('sub-test-3', Link),
                        link  : '/c/tests/tests',
                        //svg   : settings,
                        permissions: ['teacherprogramagerelationstable', 'view']
                    } */
                ]
            }
        ]
    }, // Учебное тестирование
    {
        id: 4,
        name: 'ebooks',
        isCollapsable: false,
        roles: ['admin', 'teacher', 'students', 'clients'],
        cats: [
            {
                title: 'ebooks',
                roles: ['admin', 'teacher', 'students', 'clients'],
                icon: <BookIcon />,
                items: [
                    {
                        title: 'ebooks',
                        ...permissions.view_ebook_books
                    },
                    // {
                    //     title: 'ebooks',
                    //     ...permissions.view_ebook_books_student
                    // },
                ]
            },
        ]
    }, // Книги
    // {
    //     id: 41,
    //     name: 'ebooks',
    //     isCollapsable: false,
    //     roles: ['admin', 'teacher', 'students', 'clients'],
    //     cats: [
    //         {
    //             title: 'ebooks',
    //             roles: ['admin', 'teacher', 'students', 'clients'],
    //             icon: <BookIcon />,
    //             items: [
    //                 {
    //                     title: 'ebooks',
    //                     ...permissions.view_ebook_books_student
    //                 },
    //             ]
    //         },
    //     ]
    // }, // Мои книги
    {
        id: 5,
        name: 'widget',
        cats: [
            {
                title: 'widget',
                roles: ['admin'],
                icon: <DashboardIcon />,
                items: [
                    /*   {
                          title   : localisationMaster('title-question', Link),
                          ...permissions.view_widget_question_list
                      }, */
                    // {
                    //     title: 'clients_old',
                    //     ...permissions.view_widget_list_result,
                    // },
                    {
                        title: 'passed_tests',
                        ...permissions.view_placement_test_list_result,
                    },
                    {
                        title: 'test_settings',
                        ...permissions.view_widget_bundle_list
                    },
                    /*{
                        title: localisationMaster('subtitle-question-2', Link),
                        ...permissions.edit_placement_test_create,
                    },*/
                    {
                        title: 'widgets_settings',
                        ...permissions.view_placement_test_widget_list,
                    },
                ],
            },
        ]
    }, // Виджет
    {
        id: 6,
        name: 'reports',
        cats: [
            {
                title: 'reports',
                icon: <PieIcon />,
                roles: ['admin', 'teacher', 'students', 'clients'],
                items: [
                    {
                        title: 'clients_with_debts',
                        ...permissions.view_debetors_list
                    },
                    {
                        title: 'clients_with_discounts',
                        ...permissions.view_student_discount_list
                    },
                    // {
                    //     title: 'Студенты с сертификатами',
                    //     ...permissions.view_student_cert_list

                    // },
                    {
                        title: 'students_with_certificates',
                        ...permissions.view_cert_in_groups_list
                    },
                    {
                        title: 'paid_hours',
                        ...permissions.view_report_worked_hours
                    },
                    {
                        title: 'average_check',
                        ...permissions.view_average_check_report
                    },
                    {
                        title: 'student_lifetime',
                        ...permissions.view_client_lifetime_report
                    },
                    {
                        title: 'dropped_students',
                        ...permissions.view_dropped_students_report
                    },
                    {
                        title: 'new_students',
                        ...permissions.view_new_students_report
                    },
                    {
                        title: 'teacher_rating',
                        ...permissions.view_teacher_rating_report
                    },
                    {
                        title: 'employee_rating',
                        ...permissions.view_employee_rating_report
                    },
                    {
                        title: 'students_with_trial_lesson',
                        ...permissions.view_trial_lesson_report
                    },
                    {
                        title: 'student_attendance_map',
                        ...permissions.view_attendance_map_report
                    },
                    {
                        title: 'teacher_workload',
                        ...permissions.view_teacher_loading_report
                    },
                    {
                        title: 'classroom_workload',
                        ...permissions.view_audiences_loading_report
                    },
                    {
                        title: 'profitability',
                        ...permissions.view_profitability_programs
                    },
                    {
                        title: 'teacher_statistics',
                        ...permissions.view_ledger_teacher_statistics
                    },
                    {
                        title: 'client_stages',
                        ...permissions.view_report_clients_stages
                    },
                    {
                        title: 'conversion_report',
                        ...permissions.view_conversion_report
                    },
                    {
                        title: 'client_occupancy',
                        ...permissions.view_client_occupancy_report,
                    },
                    {
                        title: 'group_stages',
                        ...permissions.view_group_stages_report,
                    },
                    {
                        title: 'conversion',
                        ...permissions.view_students_group_conversion_report,
                    },
                    {
                        title: 'formed_groups',
                        ...permissions.view_formed_groups_report,
                    },
                    {
                        title: 'groups_launch_start',
                        ...permissions.view_group_launch_speed_report,
                    },
                ]
            }
        ]
    }, // Отчеты
    {
        id: 7,
        name: 'clients',
        cats: [
            {
                title: 'clients',
                roles: ['admin', 'teacher'],
                icon: <OpenArmIcon />,
                items: [
                    {
                        title: 'all_clients',
                        ...permissions.view_clients_list
                    },
                    {
                        title: 'training_application_templates',
                        ...permissions.view_docs_template_list
                    },
                    {
                        title: 'signed_requests',
                        ...permissions.view_docs_signed
                    },
                    {
                        title: 'requests_for_signature',
                        ...permissions.view_docs_not_signed
                    },
                    {
                        title: 'training_application_builder',
                        ...permissions.view_docs_constructor
                    },
                ]
            },
        ]
    }, // Клиенты
    {
        id: 8,
        name: 'invoices',
        cats: [
            {
                title: 'invoice',
                roles: ['admin'],
                icon: <RefundIcon />,
                items: [
                    {
                        title: 'all_invoices',
                        ...permissions.view_client_orders_history,
                    },
                    /*                     {
                                            title : localisationMaster('sub-title-pay-2', Link),
                                            ...permissions.view_orders,
                                        }, */
                    {
                        title: 'entries_1c',
                        ...permissions.view_1C_history
                    },
                    {
                        title: 'refunds',
                        ...permissions.view_refunds,
                    },
                    {
                        title: 'funds_transfers',
                        ...permissions.view_transfer,
                    },
                    /*  {
                         title : localisationMaster('sub-title-pay-3', Link),
                         ...permissions.view_pays_history
                     }, */
                    {
                        title: 'receipt_of_funds',
                        ...permissions.view_report_receipt_of_funds,
                    }
                ]
            }
        ]
    }, // Счета
    {
        id: 9,
        name: 'employees',
        cats: [
            {
                title: 'employees',
                roles: ['admin'],
                icon: <TeamIcon />, // Нужна новая картинка
                items: [
                    {
                        title: 'employees',
                        ...permissions.view_employers_list
                    },
                    {
                        title: 'employee_schedule',
                        ...permissions.view_worktable
                    },
                    {
                        title: 'birthdays',
                        ...permissions.view_report_birthday
                    },
                    {
                        title: 'templates_of_contracts',
                        ...permissions.view_contract_template_list
                    },
                    {
                        title: 'employees_contracts',
                        ...permissions.view_contract_signed
                    },
                    {
                        title: 'contracts_for_signature',
                        ...permissions.view_contract_not_signed
                    },
                    {
                        title: 'contract_builder',
                        ...permissions.view_contract_constructor
                    },
                ]
            },
        ]
    }, // Сотрудники
    {
        id: 10,
        name: 'team',
        title: 'company',
        roles: ['admin', 'teacher'],
        cats: [
            {
                title: 'company',
                roles: ['admin'],
                icon: <BuildingIcon />,
                items: [
                    {
                        title: 'companies',
                        ...permissions.view_company_list
                    },
                    {
                        title: 'locations',
                        ...permissions.view_locations_list

                    },
                    {
                        title: 'sent_letters',
                        ...permissions.view_email_list
                    },
                    {
                        title: 'sent_sms',
                        ...permissions.view_sms_list
                    },
                ]
            },
        ]
    }, // Компания
    {
        id: 11,
        name: 'settings',
        roles: ['admin'],
        cats: [
            {
                title: 'study_group_settings',
                roles: ['admin'],
                icon: <SettingsIcon />,
                items: [
                    {
                        title: 'training_programs',
                        ...permissions.view_programs_list
                    },
                    {
                        title: 'age_categories',
                        ...permissions.view_age_list
                    },
                    {
                        title: 'disciplines_languages',
                        ...permissions.view_disciplines_list
                    },
                    {
                        title: 'group_levels',
                        ...permissions.view_levels_list
                    },
                    {
                        title: 'holidays',
                        ...permissions.view_calendar_list,
                    },
                    {
                        title: 'group_categories',
                        ...permissions.view_levels_list
                    },
                    {
                        title: 'hourly_rates',
                        ...permissions.view_settings_payment_list
                    },
                    {
                        title: 'discounts',
                        ...permissions.view_discount_list
                    },
                    {
                        title: 'teacher_salaries',
                        ...permissions.view_salary_list
                    },
                    {
                        title: 'certificates',
                        ...permissions.view_certificates_list
                    },
                    {
                        title: 'evaluation_criteria',
                        ...permissions.view_evaluation_list
                    },
                    {
                        title: 'ratings',
                        ...permissions.view_rating_list
                    },
                ]

            },
        ]
    }, // Настройки групп
    {
        id: 12,
        name: 'unidance',
        title: 'Unidance',
        roles: ['admin'],
        cats: [
            {
                title: "fitness_center",
                roles: ['admin'],
                icon: <WalkIcon />,
                items: [
                    {
                        title: "clients",
                        ...permissions.view_client_barcode
                    },
                    {
                        title: "instructors",
                        ...permissions.view_master
                    },
                    {
                        title: "schedules",
                        ...permissions.page_dance_schedules
                    },
                    {
                        title: "training_lessons",
                        ...permissions.page_training_lessons
                    },
                    {
                        title: "subscriptions_settings",
                        ...permissions.page_dance_subscriptions
                    },
                ]

            },
            {
                title: "fitness_center_reports",
                roles: ['admin'],
                icon: <PieIcon />,
                items: [
                    {
                        title: 'statistics_by_coaches',
                        ...permissions.view_stats_coaches_report
                    },
                    {
                        title: 'sold_subscriptions',
                        ...permissions.view_subscriptions_sold_report
                    },
                    {
                        title: 'financial_report',
                        ...permissions.view_finreport_report
                    },
                    {
                        title: 'zero_balance_subscription',
                        ...permissions.view_mailing_report
                    },
                ]

            }
        ]
    }, // Фитнес центр + Отчеты фитнес-центра

    // {
    //     id: 6,
    //     name: 'settings',
    //     title: 'common_settings',
    //     roles: ['admin'],
    //     cats: [
    //         {
    //             title: 'common_settings',
    //             roles: ['admin'],
    //             icon: <SettingsIcon />,
    //             items: [
    //                 {
    //                     title: 'training_programs',
    //                     ...permissions.view_programs_list
    //                 },
    //                 {
    //                     title: 'age_categories',
    //                     ...permissions.view_age_list
    //                 },
    //             ]
    //
    //         },
    //         {
    //             title: 'work_with_groups',
    //             roles: ['admin'],
    //             icon: <BuildingIcon />,
    //             items: [
    //                 {
    //                     title: 'disciplines_languages',
    //                     ...permissions.view_disciplines_list
    //                 },
    //                 {
    //                     title: 'group_levels',
    //                     ...permissions.view_levels_list
    //                 },
    //                 {
    //                     title: 'holidays',
    //                     ...permissions.view_calendar_list,
    //                 },
    //                 {
    //                     title: 'group_categories',
    //                     ...permissions.view_levels_list
    //                 },
    //             ]
    //         }
    //     ]
    // }, // Общие настройки + работы с группами
    /* {
       id: 3,
       name: 'docs',
       title: localisationMaster('title-doc', Link),
       icon: <Docs />,
       roles: ['admin', 'students', 'clients'],
       cats: [
           {
               title : localisationMaster('title-doc', Link),
               roles: ['admin', 'students', 'clients'],
               items: [
                   {  title : localisationMaster('sub-title-doc-2', Link),
                       link  :  '/c/docs/signing',
                       //svg   :   programs,
                       permissions: ['widgetquestion', 'view']
                   },
                   {  title : localisationMaster('sub-title-doc-1', Link),
                       link  :  '/c/docs/clients-docs',
                       //svg   :   clientsDocs,
                       permissions: ['widgetquestion', 'view']
                   },
                   {  title : localisationMaster('sub-title-doc-4', Link),
                       link  :  '/c/docs/push-clients-docs',
                       //svg   :   PushClientsDocs,
                       permissions: ['widgetquestion', 'view']
                   },
               ]
           }
       ]
   }, */
]
