import React, { useEffect, useRef, useState } from 'react';
import SectionTitle from '../../../../UI/SectionTitle/SectionTitle';
import Paper from '../../../../UI/Paper/Paper';
import Flex from '../../../../UI/Flex/Flex';
import SlotTypesList from '../components/SlotTypesList/SlotTypesList';
import SlotTypesModal from '../components/SlotTypesList/SlotTypesModal';
import Button from '../../../../UI/Button/Button';
import slotsSettingsStore from '../../../../../Stores/slotsSettingsStore';
import styles from '../SlotSettings.module.scss';
import { observer } from 'mobx-react';
import { hideElem, showElem } from 'components/Modules/Slots/utils';

const MAX_SLOT_TYPES = 10;

function TypeSection() {
  const wrapperRef = useRef(null);
  const innerRef = useRef(null);
  const isMaxTypesCount = slotsSettingsStore.slotTypes.length >= MAX_SLOT_TYPES;
  const [isModalShown, setIsModalShown] = useState(false);
  const [isNeedToShow, setIsNeedToShow] = useState(slotsSettingsStore.isOnlineAllowed);

  const handleClick = () => {
    setIsModalShown(true);
  };

  useEffect(() => {
    if (slotsSettingsStore.isOnlineAllowed && !isNeedToShow) {
      setIsNeedToShow(true);
    }

    if (!slotsSettingsStore.isOnlineAllowed && isNeedToShow) {
      hideElem(wrapperRef, innerRef, true, setIsNeedToShow);
    }
  }, [slotsSettingsStore.isOnlineAllowed]);

  useEffect(() => {
    if (isNeedToShow) {
      showElem(wrapperRef, innerRef, isNeedToShow);
    }
  }, [isNeedToShow]);

  return (
    <div ref={wrapperRef} style={{transition: 'height 0.4s ease-in-out'}}>
      {isNeedToShow &&
        <Paper tag='fieldset' ref={innerRef}>

          <SectionTitle title='Типы слотов' />
          <SlotTypesList />

          <Flex justify='center' asColumn>
            <Button
              isWide
              onClick={handleClick}
              isDisabled={isMaxTypesCount}
            >
              Добавить новый тип слотов
            </Button>
            <span className={styles.button_description}>
          Не более 10 типов
        </span>
          </Flex>

          {
            isModalShown &&
            // TODO: replace with UI modal
            <SlotTypesModal
              isAccessible={true}
              isComment={true}
              onClose={() => setIsModalShown(false)}
              id={`new-${Date.now()}`}
              asNew
            />
          }

        </Paper>
      }
    </div>
  );
}

export default observer(TypeSection);
