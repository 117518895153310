import React, {useEffect, useState} from 'react';
import {localisationMaster} from "Localisation/Localisation";
import ReactCrop from "react-image-crop";
import modalStore from "../../../../../Stores/modalStore";
import {observer} from "mobx-react";

const AddAvatar = observer((props) => {
    const ms = props.modal_settings

    const [media, setMedia] = useState([])
    const [url, setUrl] = useState(null)
    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState({aspect: 1, width: 250, height: 250})
    const [finishImg, setFinishImg] = useState(null)

    const {changeValues, changeValuesArray} = modalStore

    const changeHandler = e => {
        const file = e.target.files[0];
        setUrl(URL.createObjectURL(file));
        setMedia([file, file.name]);
    }

    const cropImage = () => {
        const newFileName = Date.now() + media[1];
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        ctx.canvas.toBlob((blob) => {
            const file = new File([blob], newFileName, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            setFinishImg(file)
        }, 'image/jpeg', 1);
    }

    useEffect(() => {
        if(image && crop.width && crop.height)
            cropImage()
    }, [image, crop])

    useEffect(() => {
        changeValues(finishImg, ms.name_media)
    }, [finishImg])

    return (
        <div>
            <div className="super-modal-media">
                <div>
                    <input id="file" name="file" type="file" onChange={changeHandler} className="inputfile"/>

                    <label className="labelFile" htmlFor="file">
                        {!media[0] ?
                            localisationMaster('button-image-one', 'tests/questions', 'image_plus_variants') :
                            localisationMaster('button-image-three', 'tests/questions', 'image_plus_text') + ' ' + media[1]
                        }
                    </label>

                    <p className="super-modal_bold">{localisationMaster('text_1', 'modal_templates')}</p>
                </div>

                {url &&
                <div className="reactCropImage">
                    <ReactCrop src={url} crop={crop} onChange={setCrop} onImageLoaded={setImage} ruleOfThirds />
                </div>
                }
            </div>
        </div>
    )
})

export default AddAvatar
