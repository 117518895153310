import React, {useEffect, useState} from 'react';
import modalStore from "Stores/modalStore";
import SmartFilter from "../../Global/Filter/SmartFilter";

export default function ModalAddChildAccount() {

    const {modalValue, changeValues} = modalStore

    const [user, setUser] = useState()

    const onChangeClient = val => {
        setUser(val[0])
    }

    useEffect(() => {
        if (user) {
            const arr = modalValue.children
            arr.push(user.profile.id)
            changeValues(arr, 'children')
        }
    }, [user])

    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-field">
                <div className="super-modal__form-label">Поиск клиента:</div>
                <div>
                    <SmartFilter link="accounts/clients"
                                 placeholder="Вводите имя, e-mail или телефон студента"
                                 new_key="full_name"
                                 sub_key="profile"
                                 backFunction={onChangeClient}
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">Имя:</div>
                    <input type="text" defaultValue={user?.profile.first_name} disabled/>
                </div>
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">Фамилия:</div>
                    <input type="text" defaultValue={user?.profile.last_name} disabled/>
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">ИИН/ИНН:</div>
                    <input type="text" defaultValue={user?.profile.inn} disabled/>
                </div>
                <div className="super-modal__form-field">
                    <div className="super-modal__form-label">E-mail:</div>
                    <input type="text" defaultValue={user?.email} disabled/>
                </div>
            </div>
        </div>
    );
}
