import React, {useState, useEffect} from "react";
import {superAxiosRequestEasy} from "axiosApi";
import swal from "sweetalert";
import {classNames as cn} from "helpers";
import useLocalization from "hooks/useLocalization";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import BirthdaySelect from "components/Modules/Auth/SignUp/BirthdaySelect/BirthdaySelect";
import InputInn from "./InputInn/InputInn";
import CompanyLogo from "../Common/CompanyLogo";
import AuthFooter from "../Common/AuthFooter";
import st from "./SignUp.module.scss";
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss";
import {ReactComponent as EyeLine} from "./img/eye-line.svg";
import {ReactComponent as EyeOffLine} from "./img/eye-off-line.svg";
import srcStudying1 from "../img/studying-1.png";
import SexSelector from "./SexSelector/SexSelector";


const passwordText = [
  {
    content: "Consist of at least 8 characters",
    key: "minSymbol",
    localization: 'pass_min_symbol',
  },
  {
    content: "Contain at least 1 letter (a, b, c...)",
    key: "minLetters",
    localization: 'pass_min_letters',
  },
  {
    content: "Contain at least 1 digit (1, 2, 3...)",
    key: "minNumbers",
    localization: 'pass_min_numbers',
  },
]

export default function SignUp() {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    date_of_birth: "",
    inn: "",
    sex: "M",
    password: "",
    email: "",
  })
  const [innTypeID, setInnTypeID] = useState(1)
  const [validPhone, setValidPhone] = useState(false)
  const [validPassword, setValidPassword] = useState({
    minSymbol: false,
    minLetters: false,
    minNumbers: false,
  })
  const [errors, setErrors] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  const {translate} = useLocalization({section: 'user', subsection: 'sign_up'})

  const innOptions = [
    {id: 1, name: translate.iin},
    {id: 2, name: translate.inn},
  ]
  const hasCyrillicCharacters = inputString => /[а-яА-ЯЁё]+/.test(inputString)
  const isPunycodeEncoded = str => /xn--[0-9a-z-]+/i.test(str)

  const submitHandler = () => {
    const emptyFields = Object.keys(user).filter(key => user[key] === "");
    const checkPassword = Object.values(validPassword);
    setErrors(emptyFields);

    const kundelikUserId = localStorage.getItem('kundelik_user_id');
    if (!kundelikUserId) {
      console.error('kundelik_user_id не найден в localStorage');
      return;
    }
    const updatedUser = {
      ...user,
      kundelik_user_id: kundelikUserId
    };

    if (checkPassword.includes(false)) {
      setErrors([...emptyFields, 'password']);
    }
    if (emptyFields.length === 0 && !(checkPassword.includes(false))) {
      const { inn, email, date_of_birth } = user;

      if(!validPhone) {
        return setErrors([...errors, 'phone']);
      }

      const birthday = date_of_birth
        .split("-")
        .map((str, index) => (index === 0 ? str.substring(2) : str))
        .join("");
      if (inn.length < 12 || isNaN(+inn) || !inn.startsWith(birthday)) {
        swal({
          text: `Вы ввели неверный ${innTypeID === 1 ? 'ИИН' : 'ИНН'}`,
          icon: "warning",
        }).then(() => {})
      }
      else if (hasCyrillicCharacters(email) ||  isPunycodeEncoded(user.email)) {
        swal({
          text: "Вы ввели неверный email",
          icon: "warning",
        }).then(() => {})
      }
      else {
        superAxiosRequestEasy(
          { method: "post", link: "accounts/register_client" },
            updatedUser
        ).then(() => {
          swal({
            text: `На вашу электронную почту ${email} было отправлено письмо с подтверждением регистрации.
              Пожалуйста зайдите в электронную почту и нажмите на ссылку в письме для завершения регистрации.`,
            icon: "success",
          }).then((result) => {
            if (result) {
              window.location.href = "/";
            }
          })
        }).catch(({response}) => {
          if (response?.data?.non_field_errors) {
            swal({
              text: "Вы ввели неверный ИИН",
              icon: "warning",
            }).then(() => {})
          } else if (response?.data?.email) {
            swal({
              text: response.data.email[0],
              icon: "warning",
            }).then(() => {})
          } else if(response?.data?.phone) {
            swal({
              text: response.data.phone[0],
              icon: "error",
            }).then(() => {})
          } else {
            swal({
              text: "Произошла ошибка",
              icon: "error",
            }).then(() => {})
          }
        })
      }
    }
  }

  useEffect(() => {
    const {password} = user
    setErrors([])
    setValidPassword({
      minSymbol: password.length >= 8,
      minLetters: /(?=.*[a-z])/.test(password),
      minNumbers: /(?=.*\d)/.test(password),
    })
  }, [user])

  return (
    <>
      <article className={stWrapperAuth.content}>
        <div className={stWrapperAuth.authBox}>
          <CompanyLogo/>

          <h1 className={stWrapperAuth.heading1}>
            {translate.create_account} <span role="img" aria-label={translate.create_account}>&#9996;&#127995;</span>
          </h1>

          <div className={`${stWrapperAuth.form} ${st.formSignUp}`}>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.name}:</div>
              <input
                type="text"
                value={user.first_name}
                onChange={e => {
                  setUser({...user, first_name: e.target.value})
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes("first_name"),
                })}
                maxLength={100}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.surname}:</div>
              <input
                type="text"
                value={user.last_name}
                onChange={e => {
                  setUser({ ...user, last_name: e.target.value })
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes("last_name"),
                })}
                maxLength={100}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.phone}:</div>
              <div>
                <InputFlagPhone
                  value={user.phone}
                  onChange={val => setUser({ ...user, phone: val })}
                  error={errors.includes("phone")}
                  onValid={valid => setValidPhone(valid)}
                />
              </div>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.date_of_birth}:</div>
              <BirthdaySelect
                value={user.date_of_birth}
                onChange={val => setUser({ ...user, date_of_birth: val })}
                error={errors.includes("date_of_birth")}/>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.iin} / {translate.inn}:</div>
              <InputInn
                options={innOptions}
                onChange={val => setUser({...user, inn: val})}
                value={`${user.inn}`}
                onChangeType={obj => setInnTypeID(obj.id)}
                error={errors.includes("inn")}/>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.sex}:</div>
              <div>
                <SexSelector
                  options={[
                    {id: "M", name: translate.male},
                    {id: "F", name: translate.female},
                  ]}
                  value={user.sex}
                  onChange={val => setUser({...user, sex: val})}/>
              </div>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.email}:</div>
              <input
                type="email"
                value={user.email}
                onChange={e => {
                  setUser({...user, email: e.target.value});
                }}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [stWrapperAuth.inputError]: errors.includes("email"),
                })}
              />
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.labelRequired}>{translate.password}:</div>
              <div className={st.inputPassword}>
                <input
                  type={showPassword ? "text" : "password"}
                  value={user.password}
                  onChange={e => {
                    setUser({...user, password: e.target.value.trim()})
                  }}
                  className={cn({
                    [stWrapperAuth.input]: true,
                    [stWrapperAuth.inputError]: errors.includes("password"),
                  })}
                />
                {showPassword ?
                  <EyeLine onClick={() => setShowPassword(!showPassword)}/> :
                  <EyeOffLine onClick={() => setShowPassword(!showPassword)}/>}
              </div>
              <div>{passwordText.map(item =>
                <p key={item.key} className={cn({
                  [st.checkPasswordItemCorrect]: validPassword[item.key],
                  [st.checkPasswordItem]: true,
                })}>
                  {translate[item.localization] || item.content}
                </p>)}
              </div>
            </div>

            <div className={stWrapperAuth.submit}>
              <div onClick={submitHandler}>
                <button>{translate.sign_up}</button>
              </div>
              <div className={stWrapperAuth.haveAccount}>
                <p>{translate.have_account}? <a href="/" >{translate.login_here}</a></p>
              </div>
            </div>
          </div>
        </div>
      </article>
      <AuthFooter srcPromo={srcStudying1}/>
    </>
  )
}
