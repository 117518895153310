import React from "react";
import {ReactComponent as Edit} from "components/UI/icons/edit-line.svg";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";
import st from "./TestSectionControlPanel.module.scss";


export const TestSectionControlPanel = ({action, className}) => {
    const onClickHandler = (e, act) => {
        e.stopPropagation()
        action(act)
    }

    return (
        <div className={`${st.control} ${className ? className : ''}`}>
            <div onClick={e => onClickHandler(e, 'edit')} className={st.icon_container}>
                <Edit/>
            </div>

            <div onClick={e => onClickHandler(e, 'delete')} className={st.icon_container}>
                <Delete/>
            </div>
        </div>
    )
}
