import React from "react";
import { dateFormat } from "helpers/date";
import MyGroups from "components/Modules/User/MyGroups/MyGroups";

const Groups = (props) => {
  const apiConf = {
    method: 'get',
    link: 'groups',
    params: {
      limit: 50,
      teacher: props.id,
      date_from: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 15 ), "YYYY-mm-dd")  ,
      date_until: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate() + 15 ), "YYYY-mm-dd") 
    }
  }

  const checkUniqueness = (array) => {
    let uniquenessId = []
    let result = []
    array.forEach(item => {
      if (!uniquenessId.includes(item.id)) {
        uniquenessId.push(item.id)
        result.push(item)
      }
    })
    return result
  }

  return (
    <MyGroups
      apiConf={apiConf}
      getResultApi={data => checkUniqueness(data.result)}
    />
  )
}

export default Groups;