import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {observer} from 'mobx-react';
import Loader from "components/UI/Loader/Loader";
import Exercise from "../Common/Exercise/Exercise";
import {LessonContext} from "../BookChapter/LessonContext";
import BookPaper from '../Common/BookPaper/BookPaper';
import useLocalization from "hooks/useLocalization";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import st from "../LessonTeacher/LessonTeacher.module.scss";
import LessonStudentStore from "../LessonStudent/lessonStudentStore";
import LessonToolbar from "../LessonStudent/LessonToolbar";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import StudentSelector from "../HomeworkTeacher/StudentSelector";
import LessonLogs from "../LessonLogs/LessonLogs";
import ActionWithEx from "../ActionWithEx/ActionWithEx";
import swal from "sweetalert";
import {localisationMaster} from "../../../../Localisation/Localisation";
import {superAxiosRequest} from "../../../../axiosApi";
import CreateDiscussionPastLesson from "../LessonDiscussion/CreateDiscussionPastLesson/CreateDiscussionPastLesson";
import ShowMark from "../ShowMark/ShowMark";

const PastLessonTeacher = () => {
  const {uuid} = useParams()

  const [currentRef, setCurrentRef] = useState(null)

  const [mark, setMark] = useState(null)
  const [studentMark, setStudentMark] = useState(null)
  const [review, setReview] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)

  const {
    lesson,
    fetchPastLesson,
    errorStatus
  } = LessonStudentStore

  const context = {mode: 'teacher-homework'}

  const scrollToBlock = () => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  const [selectedStudent, setSelectedStudent] = useState(null)

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "provide_exercise": "Provide An Exercise",
      "show_answers": "Show Answers",
      "focus": "Focus On Exercise",
      "add_to_homework": "Add to Homework",
      "select_student": "Select Student",
      "past_lesson": "Past Lesson",
      "homework": "Homework",
      "exit": "Exit",
      "no_exercises" : "No exercises",
      "no_ex_descr" : "During the lesson you did not provide any exercises to the students"
    }
  })

  const sendToRevision = () => {

    swal({
      text: `Вы действительно хотите отправить домашнее задание на доработку?`,
      icon: "warning",
      buttons: [
        localisationMaster('button_2', 'alert'),
        localisationMaster('button_1', 'alert')
      ],
      dangerMode: true
    }).then((yes) => {
      if (yes) {
        superAxiosRequest({
          method: 'post',
          link: 'ebooks/stud_les_grades'
        }, {
          student_id: selectedStudent?.id,
          uuid: uuid,
        }).then(() => setIsSubmit(true))
      } else {
        swal('Действие отменено')
      }
    })
  }
  const addMark = (mark) => {
    superAxiosRequest({
      method: 'post',
      link: 'ebooks/student_lesson_marks'
    }, {
      uuid: uuid,
      student_id: selectedStudent.id,
      mark: mark
    }).then((req) => {
      setMark(req.data?.mark)
      setIsSubmit(true)
      fetchPastLesson(uuid)
    })
  }

  useEffect(() => LessonStudentStore.clearLesson(), []);

  useEffect(() => {
    fetchPastLesson(uuid)
  }, [uuid, fetchPastLesson])

  useEffect(() => {
    setStudentMark(lesson?.students_marks?.find(el => el.student_id === selectedStudent?.id))
    setReview(lesson?.students_reviews?.find((el) => el.student_id === selectedStudent?.id))
  },[lesson, selectedStudent, studentMark])

  return (
    lesson ?
      <>
        <LessonToolbar homeworkLink={lesson.homework ? `/ebooks/homework/${lesson.homework}` : ''} />
        <div className={st.page}>
          <div className={st.container}>   
            <BookPaper>
              <div className={st.bookColumn}>
                <LessonHomeworkHeader title={translate.past_lesson}
                                      createdAt={lesson.created_at}
                                      ebookName={lesson.ebook_name}
                                      chapterName={lesson.chapter_name}/>
                <StudentSelector students={lesson.students}
                                 student={selectedStudent}
                                 onChange={setSelectedStudent}
                                 translate={translate}/>
                {
                    (!!studentMark?.mark) &&
                    <ShowMark mark={Number(studentMark?.mark)} type={lesson?.company_mark_type}/>
                }
                {lesson.exercises.length > 0 ?
                    <LessonContext.Provider value={context}>
                      <div className={st.questions}>{lesson.exercises.map((ex, index) =>
                          <Exercise key={ex.id}
                                    number={`1.${index + 1}`}
                                    ex={ex}
                                    student={selectedStudent}
                                    answers={lesson.assign_exercises
                                        .find(ae => ae.exercise_id === ex.id)?.students
                                        .find(st => st.student_id === selectedStudent?.id)?.answers || []}
                                    translate={translate}
                          />)}
                      </div>
                    </LessonContext.Provider> :
                    <WithoutExercises text1={`${translate.no_exercises}`}
                                      text2={`${translate.no_ex_descr}.`}/>}
              </div>
            </BookPaper>
            {
              lesson.company_marking &&
              <>
                  {
                      (review?.review && !studentMark?.mark && !isSubmit) &&
                      <ActionWithEx setMark={setMark} pastLesson={lesson} addMark={addMark} sendToRevision={sendToRevision}/>
                  }
                  {
                      selectedStudent && <CreateDiscussionPastLesson isHomework={false} context={'teacher'} studentId={selectedStudent?.id} setRef={setCurrentRef}/>
                  }
                  {
                      selectedStudent && <LessonLogs context={'teacher'} isHomeWork={false} studentId={selectedStudent?.id}/>
                  }
              </>
            }

          </div>
        </div>
      </> :
      <div className={st.page}>
        <div className={st.container}>
          <BookPaper>{errorStatus === 404 ?
            <WithoutExercises text1="404" text2="Lesson not found"/> : <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(PastLessonTeacher);
