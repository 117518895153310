import React from 'react';
import { observer } from 'mobx-react';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import Paper from 'components/UI/Paper/Paper';
import slotsRecordCustomerStore, { DataType } from 'Stores/slotsRecordCustomerStore';
import styles from './PaymentOrder.module.scss';

function PaymentOrder() {
  const { isPaymentOrder, isComment, sum, payDate, setData } = slotsRecordCustomerStore;
  const handleChange = () => {
    setData(!isPaymentOrder, DataType.isPaymentOrder);
    setData(!isComment, DataType.isComment);
  };

  return (
    <>
      <InputToggle
        label='Забронировать слот и выставить счет на оплату'
        selected={isPaymentOrder}
        onChange={handleChange}
        withMargin
      />
      {isPaymentOrder &&
        <Paper classes={styles.order_wrapper}>
          <dl className={styles.list}>
            <dt>Назначение платежа</dt>
            <dd className={styles.bold}>Устное тестирование</dd>
            <dt>Сумма</dt>
            <dd>{sum} тг.</dd>
            <dt>Всего к оплате</dt>
            <dd className={styles.big}>{sum} тг.</dd>
            <dt>Оплатить до</dt>
            <dd>{payDate.full}</dd>
          </dl>
        </Paper>
      }
    </>
  );
}

export default observer(PaymentOrder);
