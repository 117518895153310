import {localisationMaster} from 'Localisation/Localisation';
import listingStore from '../../../../../Stores/listingStore';

export const pays = [
    {
        link: 'setting-payments',
        folder: 'pays',
        permission: ['grouppricesum', 'add'],
        button: [localisationMaster('text_4', 'company/locations'), 'grouppricesum', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_placeholder: '',
        title: localisationMaster('sub-title-finance-1', 'index', 'sidebar_main'),
        modal_settings: {
            name: 'setting-payments',
            link: 'groups/price_sums',
            button: localisationMaster('add', 'company/profiles/account'),
            title: 'Стоимость курса',
            type: 'setting-payments',
            data: 'json',
            func: listingStore.updateData,
            valid: {
                name: 'required',
                //price_sum: 'required',
                price_sum: 'positive',
                hours_type: 'required',
                hours_sum: 'positive',
                company: 'required',
            }
        },
        colsSettings: {
            action: [
                {
                    name: localisationMaster('text_11', 'lessons/course'),
                    action: 'edit',
                    permission: ['grouppricesum', 'edit']
                },
                {
                    name: localisationMaster('text_13', 'lessons/course'),
                    action: 'delete',
                    permission: ['grouppricesum', 'delete']
                },
                {
                    name: localisationMaster('text_10', 'lessons/course'),
                    action: 'detail',
                    permission: ['grouppricesum', 'view']
                },
            ],
            prefix: 'groups',
            link: 'price_sums',
            id: {name: 'id'},
            main_rows: [
                {
                    compiled: [
                        {name: 'name', past: ', '},
                        {name: 'price_sum', past: ' тг. '},
                        {name: 'hours_sum'},
                        {prev: ' ', name: 'short_name', updir: 'hours_type', past: '/ч'}
                    ],
                },
                // { name: 'name', depence: false},
            ],
            is_open: false,
            sub_rows: [
                {name: 'name', updir: 'company', description: localisationMaster('text_21', 'modal_templates'), },
                {name: 'price_sum', description: 'Сумма: ', },
                {name: 'hours_sum', description: 'Количество часов: ', },
            ]
        },
        multitype: true,
        mulitype_values: {
            name: 'type',
            init: 1,
            values: [
                {id: 1, name: 'Пакетные цены'},
                {id: 2, name: 'Почасовые цены'},
            ],
        },
        // 
        // filter: true,
        // filterLink: "groups/price_sums",
        // config: [
        //     {id:7, values:[
        //             {id: 1, name: 'Пакетные цены'},
        //             {id: 2, name: 'Почасовые цены'}
        //         ], nameState: 'type', text: localisationMaster('text_9', 'groups/all-groups'), type: "type-selector", key_name: 'type'},,
        // ],
        apiSettings: {
            is_teacher: true,
        }
    },
    {
        link: 'history',
        folder: 'pays',
        permission: ['sendmobileservicemessage', 'edit'],
        button: '',
        modal_type: '',
        modal_placeholder: '',
        title: localisationMaster('sub-title-company-9', 'index', 'sidebar_main'),
        colsSettings: {
            action: [
                {
                    name: localisationMaster('status_1', 'index', 'sidebar_main'),
                    action: 'update',
                    permission: ['sendmobileservicemessage', 'edit']
                },
            ],
            prefix: 'payments',
            link: 'orders',
            id: {name: 'id'},
            main_rows: [
                /*  {
                     compiled: [
                         { name: 'id', past: ' - ' },
                         { name: 'group_name' },
                     ],
                 },
                 {name: 'created_at', function: 'date_time'},
                 {name: 'sum', function: 'group_balance'}, */
            ],
            is_open: false,
            sub_rows: [
                //{ name: 'sent', description: 'Дата отправки'},
                //{ name: 'open', description: 'Открыто',  function: 'openOrClick_email'},
                //{ name: 'click', description: 'Клик', function: 'openOrClick_email'},
            ]
        },
        /*filter: true,
        filterLink: "lessons/teacher_poll_test",
        config: [
            { id: 2, apiLink: 'db/age', nameState: 'age', text: localisationMaster('select-two', 'tests/questions', 'question_plus_variants'), type: "select" },
            { id: 3, apiLink: 'db/programs', nameState: 'program', text: localisationMaster('select-one', 'tests/questions', 'question_plus_variants'), type: "select" },
            { id: 4, apiLink: 'lessons/levels', nameState: 'lvl_id', text: localisationMaster('text_12', 'online-schedule/future-slots'), type: "selectLvl" },
            { id: 5, apiLink: 'lessons/levels', nameState: 'lvl', text: localisationMaster('select-five', 'tests/questions', 'question_plus_variants'), type: "selectSubLvl" },
            { id: 1, nameState: 'search', placeholder: localisationMaster('text-1_1', 'filter'), type: "input" },
        ],*/
        apiSettings: {}
    },
    {
        link: 'discounts',
        folder: 'pays',
        permission: ['discount', 'view'],
        button: [localisationMaster('add', 'company/profiles/account'), 'discount', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'discounts',
            link: 'groups/discounts',
            button: localisationMaster('button-two', 'groups/categories', 'add_category'),
            title: 'Добавить скидку',
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs: ['name-number', 'select'],
            data: 'json',
            pl_text: localisationMaster('text_3', 'modal_templates'),
            pl_nubmer: 'Размер',
            pl_select: 'Тип скидки',
            name_text: 'name',
            name_number: 'count',
            name_select: 'type',
            select_array: [
                {
                    id: 1, name: 'Абсолютная'
                },
                {
                    id: 2, name: 'Процентная'
                }
            ],
            func: listingStore.updateData,
        },
        title: 'Скидки',
        colsSettings: {
            action: [
                {name: `Детали`, action: 'detail', permission: ['discount', 'view']},
                {name: `Редактировать`, action: 'edit', permission: ['discount', 'edit']},
            ],
            prefix: 'groups',
            link: 'discounts',
            id: {name: 'id'},
            main_rows: [
                {name: 'name'},
                {name: 'type_name'},
                {name: 'count'},
            ],
            is_open: false,
            sub_rows: [
                {name: 'count', description: 'Размер скидки'},
            ]
        },

        apiSettings: {}
    },
    {
        link: 'surcharges',
        folder: 'pays',
        permission: ['surcharge', 'view'],
        button: ['Добавить', 'surcharge', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'discounts',
            link: 'groups/surcharges',
            button: localisationMaster('button-two', 'groups/categories', 'add_category'),
            title: 'Добавить наценку',
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs: ['name-number', 'select'],
            data: 'json',
            pl_text: 'Название',
            pl_nubmer: 'Размер',
            pl_select: 'Тип наценки',
            name_text: 'name',
            name_number: 'count',
            name_select: 'type',
            select_array: [
                {
                    id: 1, name: 'Абсолютная'
                },
                {
                    id: 2, name: 'Процентная'
                }
            ],
            func: listingStore.updateData,
        },
        title: 'Наценки',
        colsSettings: {
            action: [
                {name: `Детали`, action: 'detail', permission: ['surcharge', 'view']},
                {name: `Редактировать`, action: 'edit', permission: ['surcharge', 'edit']},
            ],
            prefix: 'groups',
            link: 'surcharges',
            id: {name: 'id'},
            main_rows: [
                {name: 'name'},
                {name: 'type_name'},
                {name: 'count'},
            ],
            is_open: false,
            sub_rows: [
                {name: 'count', description: 'Размер наценки'},
            ]
        },

        apiSettings: {}
    },
    {
        link: 'client-orders',
        folder: 'pays',
        permission: ['orders', 'view'],
        title: localisationMaster('title', 'pays/all-pays'),
        sec_title: {
            link: 'accounts/client_info/',
            field: 'full_name',
        },
        colsSettings: {
            action: [
                {
                    name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'),
                    action: 'detail_order',
                    permission: ['orders', ['view', 'view_own']]
                },
                {
                    name: localisationMaster('answer-3', 'pay/pay-answer'),
                    action: 'cancel_order',
                    active: ['status_order', 'not_paid'],
                    unactive: ['payment_confirmed', true],
                    dateControl: ['payment_expires', new Date()],
                    permission: ['orders', 'edit']
                },
                {
                    name: localisationMaster('button-four', 'tests/settings', 'add_bundle'),
                    action: 'confirm_order',
                    active: ['status_order', 'not_paid'],
                    unactive: ['payment_confirmed', true],
                    dateControl: ['payment_expires', new Date()],
                    permission: ['orders', 'edit']
                },
                {
                    name: localisationMaster('status_1', 'index', 'sidebar_main'),
                    action: 'checkOrder',
                    active: ['status_order', 'not_paid'],
                    dateControl: ['payment_expires', new Date()],
                    permission: ['orders', 'edit']
                },
                {
                    name: localisationMaster('menuName_2', 'online-schedule/clients-after-online'),
                    action: 'profile_client',
                    permission: ['forward'],
                }
            ],
            link: 'pay_orders',
            prefix: 'payments',
            id: {name: 'id'},
            main_rows: [
                {
                    classname: 'wm40',
                    compiled: [
                        {name: 'order_id', past: ' • '},
                        {name: 'description'},
                    ],
                },
                {name: 'created_at', function: 'name_date_time'},
                {name: 'amount', function: 'group_balance', classname: 'strong tar'},
                // { name: 'status_order_name', function: 'payButton' } // remove later
            ],
            is_open: false,
            sub_rows: [
                {
                    name: 'comment',
                    description: localisationMaster('text_87', 'groups/all-groups'),
                    function: 'comment',
                    hidden_if_empty: true
                },
                {
                    name: 'approved_comment',
                    description: localisationMaster('approved_comment', 'pays/client-orders'),
                    hidden_if_empty: true
                },
                {
                    name: 'approved_at',
                    description: localisationMaster('approved_date', 'pays/client-orders'),
                    function: 'date_time',
                    hidden_if_empty: true
                },
                {
                    name: 'approved_name',
                    description: localisationMaster('approved', 'pays/client-orders'),
                    hidden_if_empty: true
                },
                {name: 'user_name', description: localisationMaster('table-row-title-3', 'pays/all-pays')},
                {
                    name: 'is_cash',
                    description: localisationMaster('table-row-title-4', 'pays/all-pays'),
                    hidden_if_empty: true
                },
                {
                    name: 'payment_expires',
                    description: localisationMaster('account_validity_date', 'pays/client-orders'),
                    function: 'date_time'
                },
                {
                    name: 'bank',
                    description: "Банк",
                    function: 'bank_bin'
                },
                {
                    name: 'location_name',
                    description: "Локация",
                    hidden_if_empty: true
                },
                {
                    name: 'receipt',
                    description: "Чек",
                    function: 'receipt',
                    hidden_if_empty: true
                },
                {name: 'status_order_name', description: localisationMaster('table-row-title-7', 'pays/all-pays')},
            ]
        },

        pays: true,
        filter_by_id: 'client_id',

        // Filter config
        filterLiner: true,
        config: [
            /*{id: 1, type: 'input', nameState: 'search', placeholder: 'Описание оплаты'},*/
            {id: 1, nameState: 'search', placeholder: localisationMaster('fio', 'pays/client-orders'), type: 'input'},
            {
                id: 2,
                apiLink: 'db/companies',
                nameState: 'company',
                placeholder: localisationMaster('select-three', 'tests/settings', 'add_bundle'),
                type: "select_request"
            },
            {
                id: 3,
                apiLink: 'payments/banks',
                nameState: 'bank',
                placeholder: localisationMaster('text_32', 'modal_templates'),
                type: "select_request",
                apiGetResult: (data) => data.map(bank => ({id: bank.id, name: `${bank.name} (${bank.bin})`}))
            },
            {
                id: 4,
                nameState: 'amount',
                placeholder: localisationMaster('table-row-title-6', 'pays/all-pays'),
                type: 'input',
                intype: 'number'
            },
            {
                id: 5,
                nameState: 'order_id',
                placeholder: localisationMaster('order_number', 'pays/client-orders'),
                type: 'input',
                intype: 'number'
            },
            {
                id: 6,
                nameState: 'group',
                placeholder: localisationMaster('group_number', 'pays/client-orders'),
                type: 'input'
            },
            {
                id: 7,
                type: 'select',
                nameState: 'status_order',
                placeholder: localisationMaster('text-info-3', 'docs/push-clients-docs'),
                values: [
                    {id: 'paid', name: 'Оплачен'},
                    {id: 'not_paid', name: 'Не оплачен'},
                    {id: 'cancelled', name: 'Отменен'},
                ]
            },
            {id: 8, type: 'date_range_picker', nameState: ['date_from', 'date_until']},
        ],
        filterLinerLink: 'payments/pay_orders',
        apiSettings: {},

        // Кнопка в шапке
        headerLinkButton: {
            link: '/reports/client-transactions/:id',
            text: localisationMaster('transactions', 'pays/all-pays'),
            id: true,
        }
    },
    /*{
        link: 'orders',
        folder: 'pays',
        permission: ['orders', 'view'],
        button: [localisationMaster('balance-button', 'pays/all-pays'), 'orders', 'add'],
        modal_placeholder: '',
        title: localisationMaster('my-bills', 'pays/all-pays'),
        modal_type: 'pays',
        colsSettings: {
            action: [
                {name: localisationMaster('answer-4', 'pay/pay-answer'), action: 'pay', permission: ['orders', 'add']},
                {
                    name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'),
                    action: 'detail',
                    permission: ['orders', 'view']
                },
            ],
            link: 'orders_client',
            prefix: 'payments',
            id: {name: 'id'},
            arr: true,
            main_rows: [
                {
                    classname: 'wm40',
                    compiled: [
                        {name: 'order_id', past: ' • '},
                        {name: 'description'},
                    ],
                },
                {name: 'created_at', function: 'date_time', classname: 'tar'},
                {name: 'amount', function: 'cleanSum', classname: 'strong tar'},
                {name: 'status_order_name', function: 'payButton'}
            ],
            is_open: false,
            sub_rows: [
                {name: 'order_id', description: localisationMaster('table-row-title-1', 'pays/all-pays')},
                {name: 'description', description: localisationMaster('table-row-title-2', 'pays/all-pays')},
                {name: 'users', description: localisationMaster('table-row-title-3', 'pays/all-pays')},
                {name: 'is_cash', description: localisationMaster('table-row-title-4', 'pays/all-pays')},
                {name: 'payments_term', description: localisationMaster('table-row-title-5', 'pays/all-pays')},
                {name: 'amount', description: localisationMaster('table-row-title-6', 'pays/all-pays')},
                {name: 'status_order_name', description: localisationMaster('table-row-title-7', 'pays/all-pays')},
            ]
        },

        pays: true,
        paysButton: true,
        apiSettings: {}
    },*/
    {
        link: '1с-payments',
        folder: 'pays',
        permission: ['surcharge', 'view'],
        title: 'Проводки 1с',
        colsSettings: {
            action: [
                {name: `Детали`, action: 'detail', permission: ['surcharge', 'view']},
                {name: `Редактировать`, action: 'edit', permission: ['surcharge', 'edit']},
            ],
            prefix: 'payments',
            link: 'incoming_payments',
            id: {name: 'id'},
            main_rows: [
                {name: 'client_name'},
                {name: 'created_at', function: 'date_time'},
                {name: 'sum', function: 'group_balance'},
            ],
            is_open: false,
            sub_rows: [
                {name: 'client_email', description: 'Email клиента'},
                {name: 'client_inn', description: 'ИНН клиента'},
                {name: 'payer_inn', description: 'ИНН плательщика'},
                {name: 'payer_name', description: 'Имя плательщика'},
                {name: 'comission', description: 'Комиссия'},
                {name: 'final_sum', description: 'Финальная сумма'},
                {name: 'document_1c', description: 'Номер документа 1с'},
                {name: 'document_type', description: 'Тип документа'},
                {name: 'group_name', description: 'Название группы'},
                {name: 'bank_bin', description: 'BIN банка'},
                {name: 'operation_type', description: 'Тип операции'},
                {name: 'organization_bin', description: 'BIN организации'},
                {name: 'student_1c_id', description: '1с id студента'},
                {name: 'location_name', description: 'Локация'},
            ]
        },
        filterLiner: true,
        config: [
            {id: 1, nameState: 'search', placeholder: 'Название, введите от 3х символов', type: 'input'},
            {id: 2, apiLink: 'db/locations', nameState: 'location', placeholder: 'Локация', type: "select_request"},
            /*  {
                 values: [{id: 0, name: 'Активные'},{id: , name: 'Не активные'}],
                 nameState: 'is_active',
                 text: localisationMaster('text_9', 'groups/all-groups'),
                 type: "type_selector",
             }, */
            /* {
                id: 3,
                apiLink: 'groups/groups_catalog',
                key_name: 'course_levels',
                nameState: 'course_level',
                placeholder: 'Тип обучения',
                type: "select_request"
            },  
            {
                id: 4,
                type: "select_request",
                placeholder: 'Уровень группы',
                apiLink: 'groups/groups_catalog',
                key_name: 'skill_levels',
                sort_key: 'name',
                nameState: 'skill_level',
                is_search: true
            }, */
        ],
        filterLinerLink: 'payments/incoming_payments',

        apiSettings: {}
    },
    {
        link: 'refunds',
        folder: 'pays',
        permission: ['surcharge', 'view'],
        title: 'Возвраты средств',
        colsSettings: {
            action: [
                {name: `Детали`, action: 'detail', permission: ['forward']},
                {name: `Добавить документ`, action: 'add_doc_refund', permission: ['cashflows', 'edit'], },
                {name: `Подтвердить`, action: 'approve_refund', permission: ['cashflows', 'edit'], active: ['is_confirmed', false], unactive: ['is_cancelled', true]},
                {name: `Отменить`, action: 'cancel_refund', permission: ['cashflows', 'edit'], active: ['is_cancelled', false], unactive: ['is_confirmed', true]},
                {name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile_client', permission: ['cashflows', 'edit']},
            ],
            prefix: 'payments',
            link: 'refunds',
            id: {name: 'id'},
            main_rows: [
                {name: 'user_name', classname: 'wm40'},
                {name: 'created_at', function: 'date_time'},
                {name: 'sum', function: 'group_balance', classname: 'strong tar'},
                {name: 'is_confirmed', function: 'is_confirmed', classname: 'tar'},
            ],
            is_open: false,
            sub_rows: [
                {name: 'comment', description: 'Комментарий'},
                {name: 'user_name', description: 'Клиент', classname: 'wm40'},
                {name: 'created_at', description: 'Дата создания', function: 'date_time'},
                {name: 'sum', description: 'Сумма', function: 'group_balance', classname: 'strong tar'},
                {name: 'is_confirmed', description: 'Подтверждение', function: 'is_confirmed'},
                {name: 'documents', description: 'Документы', function: 'refund_docs'},
            ]
        },
        filterLiner: true,
        config: [
            {id: 1, nameState: 'search', placeholder: 'Название, введите от 3х символов', type: 'input'},
        ],
        filterLinerLink: 'payments/refunds',
        filter_by_id: 'client_id',

        apiParams: {refund: true}
    },
    {
        link: 'transfer',
        folder: 'pays',
        permission: ['surcharge', 'view'],
        title: 'Переводы средств',
        colsSettings: {
            action: [
                {name: `Детали`, action: 'detail', permission: ['forward']},
                {name: `Добавить документ`, action: 'add_doc_refund', permission: ['cashflows', 'edit'], },
                {name: `Подтвердить`, action: 'approve_refund', permission: ['cashflows', 'edit'], active: ['is_confirmed', false], unactive: ['is_cancelled', true]},
                {name: `Отменить`, action: 'cancel_refund', permission: ['cashflows', 'edit'], active: ['is_cancelled', false], unactive: ['is_confirmed', true]},
                {name: localisationMaster('text_4', 'online-schedule/clients-after-online'), action: 'go_profile_client', permission: ['cashflows', 'edit']},
            ],
            prefix: 'payments',
            link: 'refunds',
            id: {name: 'id'},
            main_rows: [
                {name: 'user_name', classname: 'wm40'},
                {name: 'created_at', function: 'date_time'},
                {name: 'sum', function: 'group_balance', classname: 'strong tar'},
                {name: 'is_confirmed', function: 'is_confirmed'},
            ],
            is_open: false,
            sub_rows: [
                {name: 'comment', description: 'Комментарий'},
                {name: 'user_name', description: 'Клиент', classname: 'wm40'},
                {name: 'created_at', description: 'Дата создания', function: 'date_time'},
                {name: 'sum', description: 'Сумма', function: 'group_balance', classname: 'strong tar'},
                {name: 'is_confirmed', description: 'Подтверждение', function: 'is_confirmed'},
                {name: 'documents', description: 'Документы', function: 'refund_docs'},
            ]
        },
        filterLiner: true,
        config: [
            {id: 1, nameState: 'search', placeholder: 'Название, введите от 3х символов', type: 'input'},
        ],
        filterLinerLink: 'payments/refunds',
        filter_by_id: 'client_id',

        apiParams: {transfer: true}
    },
]