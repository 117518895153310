import React, {useState} from 'react';
import styles from "./DocsCreator.module.scss"
import delete_icon from "../../img/deleteIcon.svg"
import edit_icon from "../../img/editIcon.svg"
import upArrow_icon from "../../img/up-arrow.svg"
import downArrow_icon from "../../img/arrow-down.svg"
import {timeConverter} from '../../Online-schedule/components/functions';
import {superAxiosRequest} from '../../../../axiosApi';
import InputFile from '../../../UI/InputFile/InputFile';
import ActionBar from './component/ActionBar';
import Checkbox from '../../Global/Checkbox/Checkbox';
import Textarea from "components/ContentBox/Global/Textarea/Textarea";
import TextField from './component/TextField/TextField';
import PdfField from './component/PdfField/PdfField';



const DocsCreator = ({docsCreatorData, mode, setDocsCreatorData, getStopEditState}) => {
    const {hostname, protocol} = window.location;

    const deleteElement = (key) => {
        let newState = {...docsCreatorData}
        delete newState[key]
        setDocsCreatorData(newState)

    }

    const sendFile = (data) => {
        const formData = new FormData();
        formData.append("file", data);

        return superAxiosRequest(
            {
                method: "post",
                link: "files/image_upload",
            },
            formData,
            "media"
        ).then((res) => {
            return res.data.file
        });
    };
    const sendPdf = (data) => {
        const formData = new FormData();
        formData.append("file", data);

        return superAxiosRequest(
            {
                method: "post",
                link: "files/file_upload",
            },
            formData,
            "media"
        ).then((res) => {
            return res.data.file
        });
    };

    const changeElementStatus = (element_key, e) => {
        e.stopPropagation()
        let newState = getStopEditState()
        newState[element_key].is_edit = !newState[element_key].is_edit
        setDocsCreatorData(newState)
    }
    const changeElementStyles = (element_key, value) => {
        let newState = {...docsCreatorData}
        newState[element_key].styles = value
        setDocsCreatorData(newState)
    }
    const changeElementValue = (element_key, value) => {
        let newState = {...docsCreatorData}
        newState[element_key].value = value
        setDocsCreatorData(newState)
    }
    const changeUserValue = (element_key, value) => {
        let newState = {...docsCreatorData}
        newState[element_key].user_value = value
        setDocsCreatorData(newState)
    }
    const changeElementPosition = (element_key, way) => {
        let newState = {...docsCreatorData}
        const thisEllement = {...newState[element_key]}
        switch (way) {
            case 'up':
                if (newState[Number(element_key) - 1]) {
                    const prevEllement = {...newState[Number(element_key) - 1]}
                    newState[element_key] = prevEllement
                    newState[Number(element_key) - 1] = thisEllement
                }
                break;
            case 'down':
                if (newState[Number(element_key) + 1]) {
                    const nextEllement = {...newState[Number(element_key) + 1]}
                    newState[element_key] = nextEllement
                    newState[Number(element_key) + 1] = thisEllement
                }
                break;

            default:
                break;
        }

        setDocsCreatorData(newState)
    }

    const listCreator = (row) => {
        let array = row.split('\n')
        return array
    }
    const JSXCreator = (elementData, element_key) => {
        let element
        let baseActionList = [
            {
                action(element_key, e) {
                    deleteElement(element_key, e)
                },
                icon: delete_icon
            },
            {
                action(element_key, e) {
                    changeElementPosition(element_key, 'down')
                },
                icon: downArrow_icon
            },
            {
                action(element_key, e) {
                    changeElementPosition(element_key, 'up')
                },
                icon: upArrow_icon
            },
        ]
        let actionsList = [
            {
                action(element_key, e) {
                    changeElementStatus(element_key, e)
                },
                icon: edit_icon
            },
            ...baseActionList
        ]

        switch (elementData.type) {
            case 'text':
                element =
                    mode === 'edit' ?
                        <div className={`${styles.element__box} ${styles.element__text}`}
                            onClick={(e) => changeElementStatus(element_key, e)}
                            style={{
                                "backgroundColor": !elementData.is_edit && elementData.styles.backgroundColor,
                                "color": !elementData.is_edit && elementData.styles.color,
                                'border': !elementData.is_edit && elementData.styles.border,
                                'borderColor': !elementData.is_edit && elementData.styles.borderBlockColor,
                            }}
                        >
                            <div className={elementData.is_edit ? styles.display_none : ''}>
                                <ActionBar
                                    classes={styles.element__wrapper_icon}
                                    element_key={element_key}
                                    list={actionsList}
                                />
                                <div >
                                    <TextField
                                        value={elementData.value || elementData.placeholder} />
                                </div>
                            </div>

                            <form className={elementData.is_edit ? styles.element__form : styles.display_none} action="">
                                <Textarea
                                    onChange={e => {
                                        changeElementValue(element_key, e)
                                    }}
                                    value={elementData.value || ''}
                                    stateName="comment"
                                    placeholder={elementData.placeholder} />
                            </form>
                        </div> :
                        <div className={`${styles.element__box_view} ${styles.element__text}`}>
                            <div >
                                <TextField value={elementData.value} />
                            </div>
                        </div>
                break;
            case 'text_input':
                element =
                    mode === 'edit' ?
                        <div className={`${styles.element__box} ${styles.element__text}`}
                            onClick={(e) => changeElementStatus(element_key, e)}>
                            <div className={elementData.is_edit ? styles.display_none : ''}>
                                <ActionBar
                                    classes={styles.element__wrapper_icon}
                                    element_key={element_key}
                                    list={actionsList} />
                                <div className={`${styles.element__userForm__box}`}>
                                    <p >{elementData.value || elementData.placeholder}</p>
                                    <input
                                        className={styles.element__userForm__input}
                                        onClick={e => {
                                            e.stopPropagation()
                                        }}
                                        placeholder='Текст пользователя' type="text" />
                                </div>
                            </div>


                            <form className={elementData.is_edit ? styles.element__form : styles.display_none} action="">
                                <textarea
                                    placeholder={elementData.placeholder}
                                    value={elementData.value}
                                    onClick={e => e.stopPropagation()}
                                    onChange={(e) => changeElementValue(element_key, e.target.value)}
                                />
                            </form>
                        </div> :
                        <div className={`${styles.element__box_view} ${styles.element__text}`}>
                            <div className={`${styles.element__userForm__box}`}>
                                <p>
                                    {elementData.value || elementData.placeholder}
                                </p>
                                <input
                                    className={styles.element__userForm__input}
                                    value={elementData.user_value}
                                    onClick={e => {
                                        e.stopPropagation()
                                    }}
                                    onChange={e => changeUserValue(element_key, e.target.value)}
                                    placeholder='Введите текст' type="text" />
                            </div>
                        </div>
                break;

            case 'title_h1':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}
                    onClick={(e) => changeElementStatus(element_key, e)}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <h1 className={styles.element__title_h1}>
                            {elementData.value || elementData.placeholder}
                        </h1>
                    </div>
                    <textarea
                        placeholder={'Введите заголовок'}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <h1 className={styles.element__title_h1}>
                            {elementData.value || elementData.placeholder}
                        </h1>
                    </div>
                break;

            case 'title_h2':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}
                    onClick={(e) => changeElementStatus(element_key, e)}>

                    <div className={elementData.is_edit ? styles.display_none : ""}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <h2 className={styles.element__title_h2}>
                            {elementData.value || elementData.placeholder}
                        </h2>
                    </div>
                    <textarea
                        placeholder={'Введите заголовок'}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <h2 className={styles.element__title_h2}>
                            {elementData.value || elementData.placeholder}
                        </h2>
                    </div>
                break;

            case 'title_h3':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}
                    onClick={(e) => changeElementStatus(element_key, e)}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <h3 className={styles.element__title_h3}>
                            {elementData.value || elementData.placeholder}
                        </h3>
                    </div>
                    <textarea
                        placeholder={'Введите заголовок'}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <h3 className={styles.element__title_h3}>
                            {elementData.value || elementData.placeholder}
                        </h3>
                    </div>
                break;

            case 'link':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <a target='_blank'
                            href={elementData.value.link} >
                            {`${elementData.value.text || elementData.value.placeholder}`}
                        </a>
                    </div>
                    <form className={elementData.is_edit ? styles.element__form : styles.display_none} action="">
                        <input
                            placeholder={elementData.placeholder}
                            value={elementData.value.text}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => changeElementValue(element_key, {...elementData.value, text: e.target.value})}
                        />
                        <input
                            placeholder={'Введите значение'}
                            value={elementData.value.link}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => changeElementValue(element_key, {...elementData.value, link: e.target.value})}
                        />
                    </form>
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={elementData.is_edit ? styles.display_none : ''}>
                            <a target='_blank'
                                href={elementData.value.link} >
                                {`${elementData.value.text || elementData.placeholder}`}
                            </a>
                        </div>
                    </div>
                break;

            case 'sign':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={baseActionList}
                        />
                        <div className={styles.element__sign}>
                            {`${elementData.value.text}`}
                        </div>
                    </div>
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={styles.element__sign}>
                            {`${elementData.value.text}`}
                        </div>
                    </div>
                break;

            case 'initials':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={baseActionList}
                        />
                        <div className={styles.element__initials}>
                            {`${elementData.value.text}`}
                        </div>
                    </div>
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={styles.element__initials}>
                            {`${elementData.value.text}`}
                        </div>
                    </div>
                break;

            case 'link_btn':
                element = mode === 'edit' ? <div className={`${styles.element__box} `}>
                    <div className={elementData.is_edit ? styles.display_none : styles.element__wrapper_flex}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <a target="_blank"
                            href={elementData.value.link} >
                            <button className={styles.button_link}>{elementData.value.text || elementData.value.placeholder}</button>
                        </a>
                    </div>
                    <form className={elementData.is_edit ? styles.element__form : styles.display_none} action="">
                        <input
                            placeholder={'Введите значение'}
                            value={elementData.value.text}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => changeElementValue(element_key, {...elementData.value, text: e.target.value})}
                        />
                        <input
                            placeholder={'Введите значение'}
                            value={elementData.value.link}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => changeElementValue(element_key, {...elementData.value, link: e.target.value})}
                        />
                    </form>
                </div> :
                    <div className={`${styles.element__box_view} `}>
                        <div className={styles.element__wrapper_flex}>
                            <a target="_blank"
                                href={elementData.value.link} >
                                <button className={styles.button_link}>{elementData.value.text || elementData.placeholder}</button>
                            </a>
                        </div>
                    </div>

                break;

            case 'listing_numbered':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}
                    onClick={(e) => changeElementStatus(element_key, e)}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <ol className={styles.element__listingNumbered_list}>
                            {listCreator(elementData.value).map((item, id) =>
                                <li className={styles.element__listingNumbered_item}
                                    key={id}><b className={styles.element__listingNumbered_num}>{id + 1}.</b> {item || elementData.placeholder}</li>)}
                        </ol>
                    </div>

                    <textarea
                        placeholder={'Введите заголовок'}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />

                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <ol className={styles.element__listingNumbered_list}>
                            {listCreator(elementData.value).map((item, id) =>
                                <li className={styles.element__listingNumbered_item}
                                    key={id}><b className={styles.element__listingNumbered_num}>{id + 1}.</b> {item}</li>)}
                        </ol>
                    </div>
                break;

            case 'listing_marked':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}
                    onClick={(e) => changeElementStatus(element_key, e)}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <ul className={styles.element__listingMarked_list}>
                            {listCreator(elementData.value).map((item, id) =>
                                <li className={styles.element__listingMarked_item}
                                    key={id}>{item || elementData.placeholder}</li>)}
                        </ul>
                    </div>

                    <textarea
                        placeholder={elementData.placeholder}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />



                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <ul className={styles.element__listingMarked_list}>
                            {listCreator(elementData.value).map((item, id) =>
                                <li className={styles.element__listingMarked_item}
                                    key={id}>{item}</li>)}
                        </ul>
                    </div>

                break;

            case 'checkbox_сonsent':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <div className={elementData.is_edit ? styles.display_none : ''}>
                            <Checkbox
                                name={element_key}
                                label={elementData.value || elementData.placeholder}
                                checked={true}
                                onChange={() => { }} />

                        </div>
                    </div>
                    <textarea
                        placeholder={'Введите заголовок'}
                        value={elementData.value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => changeElementValue(element_key, e.target.value)}
                        className={elementData.is_edit ? '' : styles.display_none}
                    />
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={elementData.is_edit ? styles.display_none : ''}>
                            <Checkbox
                                name={element_key}
                                label={elementData.value}
                                checked={elementData.user_value ? elementData.user_value : false}
                                onChange={(e) => {
                                    changeUserValue(element_key, elementData.user_value ? !elementData.user_value : true)
                                }
                                }
                            />

                        </div>
                    </div>
                break;

            case 'checkbox_list':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }} className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <div
                            style={{'--gridTemplateColumns': `${elementData.styles.grid_column}`}}
                            className={elementData.is_edit ? styles.display_none : styles.element__checkBox_list}>
                            {elementData.value.map((checkBox, key) =>
                                <Checkbox
                                    key={key}
                                    name={`${element_key}${key}`}
                                    label={checkBox.value || checkBox.placeholder}
                                    checked={true}
                                    onChange={() => { }} />)}
                        </div>
                    </div>
                    <div className={elementData.is_edit ? styles.element__form : styles.display_none}>
                        {elementData.value.map((checkBox, key) => <input
                            key={key}
                            placeholder={'Введите заголовок'}
                            value={checkBox.value}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => {
                                let newData = [...elementData.value]
                                newData[key] = {value: e.target.value}
                                changeElementValue(element_key, newData)
                            }}
                        />)}
                        <div className={styles.element__row}>
                            <label className={`${styles.element__form__text} ${styles.element__form__label}`}
                                htmlFor="checkboxInput">Колличество столбцов:</label>
                            <input className={styles.element__input_number}
                                onClick={e => e.stopPropagation()}
                                value={elementData.styles.grid_column}
                                onChange={e => {
                                    if ((e.target.value < 4 && e.target.value > 0) || e.target.value === '') {
                                        changeElementStyles(element_key,
                                            {...elementData.styles, grid_column: e.target.value})
                                    }
                                }}
                                type="number" id="checkboxInput" name="checkboxInput"
                            />
                        </div>
                        <div className={styles.element__row}>
                            <button
                                onClick={e => {
                                    e.stopPropagation()
                                    let newData = [...elementData.value]
                                    newData.push({value: '', placeholder: 'текст галочки'})
                                    changeElementValue(element_key, newData)
                                }}
                                className={`${styles.element__form__btn}`} >Добавить чекбокс</button>
                            <button
                                onClick={e => {
                                    e.stopPropagation()
                                    let newData = [...elementData.value]
                                    newData.pop()
                                    changeElementValue(element_key, newData)
                                }}
                                className={`${styles.element__form__btn}`} >Убрать чекбокс</button>
                        </div>

                    </div>
                </div>
                    :
                    <div onClick={(e) => {
                        e.stopPropagation()
                    }}
                        className={`${styles.element__box_view}`}>
                        <div style={{'--gridTemplateColumns': `${elementData.styles.grid_column}`}}
                            className={elementData.is_edit ? styles.display_none : styles.element__checkBox_list}>
                            {elementData.value.map((checkBox, key) => {
                                const checkBoxKey = `${element_key}_${key}`
                                return <Checkbox
                                    key={key}
                                    name={checkBoxKey}
                                    label={checkBox.value}
                                    checked={elementData.user_value ? elementData.user_value[key] : false}
                                    onChange={(e) => {
                                        changeUserValue(element_key, elementData.user_value ? {...elementData.user_value, [key]: !elementData.user_value[key]} : {[key]: true})
                                    }
                                    }
                                />
                            }
                            )}
                        </div>
                    </div>
                break;

            case 'date':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={baseActionList}
                        />
                        <div className={elementData.is_edit ? styles.display_none : ''}>
                            {timeConverter(elementData.value, 'dd.mm.yyyy')}
                        </div>
                    </div>

                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={elementData.is_edit ? styles.display_none : ''}>
                            {timeConverter(elementData.value, 'dd.mm.yyyy')}
                        </div>
                    </div>
                break;

            case 'img':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />
                        <img className={styles.element__img} src={elementData.value} alt="изображение" />
                    </div>
                    <div onClick={e => e.stopPropagation()}
                        className={elementData.is_edit ? styles.element__imgForm : styles.display_none}>
                        <img className={styles.element__img} src={elementData.value} alt="изображение" />
                        <InputFile
                            label='Загрузить изображение'
                            onChange={file => sendFile(file).then(fileUrl => {
                                const path = hostname.includes('localhost') ? 'https://dev.phoenix24.kz' : `${protocol}//${hostname}`;
                                changeElementValue(element_key, `${path}${fileUrl}`)
                            })}
                        />
                    </div>
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <img className={styles.element__img} src={elementData.value} alt="изображение" />
                    </div>
                break;
            case 'pdf':
                element = mode === 'edit' ? <div className={`${styles.element__box}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={actionsList}
                        />

                        <div className={styles.element__pdf_wrapper}>
                            <img className={styles.element__pdf} src={elementData.placeholder} alt="изображение" />
                            {elementData.value ? <a target="_blank" href={`${elementData.value.path}`}>{elementData.value.name }</a> : <div className="">Выберите файл</div>}
                        </div>
                    </div>

                    <div onClick={e => e.stopPropagation()}
                        className={elementData.is_edit ? styles.element__imgForm : styles.display_none}>
                        <div className={styles.element__pdf_wrapper}>
                            <img className={styles.element__pdf} src={elementData.placeholder} alt="изображение" />
                            <PdfField onChange={(fileUrl, fileName) => {
                                const path = hostname.includes('localhost') ? 'https://dev.phoenix24.kz' : `${protocol}//${hostname}`;
                                changeElementValue(element_key, {path: `${path}${fileUrl}`, name: fileName})
                            }} />
                        </div>

                    </div>
                </div> :
                    <div className={`${styles.element__box_view}`}>
                        <div className={styles.element__pdf_wrapper}>
                            <img className={styles.element__pdf} src={elementData.placeholder} alt="изображение" />
                            {elementData.value ? <a target="_blank" href={`${elementData.value.path}`}>{elementData.value.name }</a> : <div className="">Выберите файл</div>}
                        </div>

                    </div>
                break;

            case 'footer':
                element = mode === 'edit' ? <div className={`${styles.element__box} ${styles.element__footer}`}>
                    <div className={elementData.is_edit ? styles.display_none : ''}>
                        <ActionBar
                            classes={styles.element__wrapper_icon}
                            element_key={element_key}
                            list={baseActionList}
                        />
                        <div className={styles.element__footer_text}>
                            <div>{elementData.value}</div>
                        </div>
                    </div>
                </div> :
                    <div className={`${styles.element__box_view} ${styles.element__footer}`}>
                    </div>
                break;

            default:
                break;
        }
        return element
    }

    return (
        <>
            {Object.keys(docsCreatorData).map((key, i) =>
                <div key={i} className="">
                    {JSXCreator(docsCreatorData[key], key)}
                </div>)

            }
        </>
    )
}

export default DocsCreator