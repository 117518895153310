import React from 'react';
import {observer} from "mobx-react";
import styles from './HeadBox.module.scss';
import logo from './img/new-logo.png';
import { ReactComponent as SearchIcon } from 'components/UI/icons/curved-search.svg';
import { ReactComponent as NotificationIcon } from 'components/UI/icons/curved-notification.svg';
import Stores from 'Stores';
import { useState, useEffect } from 'react';
import { superAxiosRequest } from 'axiosApi';
import Avatar from './components/Avatar/Avatar';
import { classNames as cn } from "helpers";
import Menu from './components/Menu/Menu';
import ModalChangePassword from 'components/ContentBox/Company/Teachers/ModalChangePassword';
import Notifications from './components/Notifications/Notifications';
import HeadBoxStore from "Stores/headBoxStore";
import notificationsMusic from "./notifications.mp3";
import { useHistory } from "react-router-dom";
import { allWsTypes } from "Stores/headBoxStore";
import Search from './components/Search/Search';
import { createContext } from 'react';
import useLocalization from 'hooks/useLocalization';

export const LanguageContext = createContext();

const dict = {
    "my_profile": "My Profile",
    "my_settings": "My Settings",
    "my_password": "My Password",
    "my_status": "My Status",
    "language": "Language",
    "time": "Time",
    "sign_out": "Sign Out",
    "notifications": "Notifications",
    "view_all": "View All",
    "search_placeholder": "Search...",
    "clients": "Clients",
    "teachers": "Teachers",
    "written_test": "Written test",
    "new_testing": "New testing",
    "start_testing": "Start testing",
    "will_start_in": "will start in",
    "minutes": "minutes",
    "finish_testing": "End of testing",
    "will_end_in": "will end in",
    "debitors_responsible": "Debtors by groups",
    "debtors_on": "Debtors on",
    "change_group_status_one": "You have been assigned to the group",
    "change_group_status_two": "Group status changed to",
    "group": "Group",
    "add_client_to_group": "You are enrolled in a group",
    "start_lesson": "Group lesson",
    "add_new_bill": "New invoice issued",
    "currency": "тг",
    "no_unread_notifications": "No unread notifications"
};

const HeadBox = () => {
    const dataStorage = localStorage.getItem('ud');
    const profile = JSON.parse(dataStorage);
    const id = profile.user_data.id;
    const required_password_change = profile?.required_password_change;
    
    const notifications = Stores.observableArray(HeadBoxStore.allMessage);
    const isNotification = HeadBoxStore.isNotification;
    const isSound = HeadBoxStore.sound;
    const audio = new Audio(notificationsMusic);

    const [avatar, setAvatar] = useState(null);
    const [navbar, setNavbar] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const history = useHistory();
    const [modalNotifications, setModalNotifications] = useState('close');
    const { translate } = useLocalization({ section: 'global', subsection: 'header', dict });

    const changeNavbar = () => setNavbar(!navbar);

    const deleteMessage = (target, item) => {
        if (!target.className.includes('notificationsMenuItem__checkbox')) { // если кликнули по checkbox
          history.push(item.link.data) //  то перенаправляем клиента на другую страницу
        };
        HeadBoxStore.deleteMessage(item.id); // и удаляем сообщения 
        setTimeout(() => {
          HeadBoxStore.sendMessage(item.id, item.wsType); // отправляем на бек сообщение что уведомление прочитано (is_read: true)
        }, 400);
    };
    

    useEffect(() => {
        if (!Stores.reloadAvatar) return;

        const Link = profile.user_data.groups[0].name === 'clients' ? 'accounts/clients/' : 'accounts/employers_detail/';

        superAxiosRequest({
            method: 'get',
            link: Link + id
        }, 'data')
            .then(({ data }) => {
                setAvatar(data.user_data.profile.avatar);
                Stores.reloadAvatar = true;
            });
    }, [Stores.reloadAvatar]);

    useEffect(() => {
        if (!isNotification) return;
        // если уведомление пришло 
        isSound && audio.play().catch(() => {}); // 1) включаем звук если переменная isSound = true
        if (modalNotifications !== 'open') { // 2) если менюшка закрыта то 
            setModalNotifications('is_message') // показываем последнее уведомление
        }
        HeadBoxStore.setNotification(false);
        setTimeout(() => {
            setModalNotifications(modalNotifications === 'is_message' ? 'close' : modalNotifications); // если мы показали последнее уведомление то
        }, 5000); // через 5 секунд обратно закрываем менюшку
    }, [isNotification]);

    useEffect(() => {
        if (Stores.isClient && required_password_change) setPasswordModal(true)
    }, [required_password_change]);

    useEffect(() => {
        // подключаем сокеты
        HeadBoxStore.openConnect(allWsTypes.notifications);
        HeadBoxStore.openConnect(allWsTypes.groups_notifications);
        HeadBoxStore.openConnect(allWsTypes.orders);
      }, []);

    return (
        <LanguageContext.Provider value={{ translate }}>
            <header className={styles.header}>
                {modalNotifications !== 'close' && (
                    <Notifications
                        notifications={notifications.slice(0, modalNotifications === 'is_message' ? 1 : 5)}
                        className={styles.header__notification}
                        isNotifications={!notifications.length}
                        deleteMessage={deleteMessage}
                    />
                )}
                <span></span>
                {/* <img
                    src={logo} 
                    alt="logo"
                    className={styles.header__logo}
                /> */}
                <nav className={styles.headerNavbar}>
                    {Stores.Rules.checkRule('user', ['view']) && (
                        <SearchIcon
                            className={styles.headerNavbar__search}
                            onClick={() => setIsSearch(true)}
                        />
                    )}
                    <div
                        onClick={() => setModalNotifications(navbar === 'open' ? 'close' : 'open')}
                        className={cn({
                            [styles.headerNavbar__notification]: true,
                            [styles.headerNavbar__notification_active]: notifications.length > 0
                        })}
                    >
                        <NotificationIcon />
                    </div>
                    <div
                        className={styles.headerNavbar__avatar}
                        onClick={changeNavbar}
                    >
                        <Avatar
                            link={avatar}
                            first_name={profile.user_data.profile?.first_name || ''}
                            last_name={profile.user_data.profile?.last_name || ''}
                        />
                    </div>
                    <div 
                        onClick={() => {
                            setNavbar(false);
                            setModalNotifications('close');
                        }}
                        className={cn({
                            [styles.headerNavbar__popup]: true,
                            [styles.headerNavbar__popup_active]: navbar || modalNotifications !== 'close'
                        })}
                    ></div>
                    {navbar && (
                        <Menu
                            link={avatar}
                            className={styles.headerNavbar__menu}
                            closeNavbar={() => setNavbar(false)}
                            openPasswordModal={() => setPasswordModal(true)}
                        />
                    )}
                </nav>
                {isSearch && <Search onClose={() => setIsSearch(false)}/>}
                {passwordModal &&
                    <ModalChangePassword
                        id={id}
                        modal={() => setPasswordModal(false)}
                        required={required_password_change}
                    />
                }
            </header>
        </LanguageContext.Provider>
    )
}

export default observer(HeadBox)
