import React from "react"
import {observer} from "mobx-react";
import {dateFormat} from "helpers/date";
import {classNames as cn} from "helpers";
import modalStore from "Stores/modalStore";
import st from "./ModalWorkshopClose.module.scss"


const ModalWorkshopClose = props => {
  const {modalValues, changeValues} = modalStore

  const workshops = props.workshops.map(ws => ({
    ...ws,
    date: new Date(`${ws.date}T${ws.start_at}`)
  }))

  const onClickDay = ws => {
    const index = workshops.findIndex(x => x.id === ws.id)
    changeValues(workshops.map(x => x.id).slice(index), 'workshop_id')
  }

  return (
    <div className="super-modal__form-field">
      <div className="super-modal__form-label">Выберите дату закрытия расписания</div>
      <div className={st.days}>{workshops.map(ws =>
        <div key={ws.id}
             className={cn({
               [st.day]: true,
               [st.dayGood]: !modalValues.workshop_id.includes(ws.id),
               [st.dayBad]: modalValues.workshop_id.includes(ws.id),
             })}
             onClick={() => onClickDay(ws)}>
          {dateFormat(ws.date, "dd.mm.YY")}
        </div>)}
      </div>
    </div>
  )
}

export default observer(ModalWorkshopClose)
