import { observer } from "mobx-react";
import React, { useState } from "react";
import modalStore from "Stores/modalStore";
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import { classNames as cn } from "helpers";
import "./BundleLevels.scss";

const BundleLevels = () => {
  const { changeValues, getModalValues } = modalStore;
  const [level, setLevel] = useState({ name: "", scores_from: 0, scores_to: 10 });
  const [error, setError] = useState("");
  const levels = getModalValues?.levels || [];

  const handleChange = (value, key) => {
    setLevel({ ...level, [key]: value });
  };

  const setScoreLevel = (score, key) => {
    handleChange(score >= 0 ? score : 0, key);
  };

  const addLevel = () => {
    let list = getModalValues.levels || [];
    const levelValid = [];

    list.forEach((item) => {
      for (let i = item.scores_from; i <= item.scores_to; i++) {
        for (let j = level.scores_from; j <= level.scores_to; j++) {
          if (i === j) {
            levelValid.push(true);
          }
        }
      }
    });

    if (level.name.length <= 2) {
      setError("name"); return;
    }
    if (levelValid.includes(true)) {
      setError("level"); return;
    }
    list.push(level);
    changeValues([...list], "levels");
    setLevel({ 
      name: "", 
      scores_from: level.scores_to + 1, 
      scores_to: level.scores_to + 11, 
    });
    setError("");
  };

  const deleteLevel = (name) => {
    let list = getModalValues.levels || [];
    list = list.filter((x) => x.name !== name);
    changeValues([...list], "levels");
  };

  return (
    <div className="bundleLevels">
      <div className="bundleLevels__line">
        <p className="bundleLevels__title">Шкала баллов</p>
        <p className="bundleLevels__text">
          Если нет необходимо указать уровень в зависимости от количества
          правильных ответов, то оставьте этот блок пустым
        </p>
      </div>
      <div className="bundleLevels__line">
        <p className="superModalGroup__label">Название уровня</p>
        <input
          type="text"
          placeholder="Введите название уровня"
          value={level.name}
          onChange={(e) => handleChange(e.target.value, "name")}
          className={cn({
            "super-modal__input--error": error.includes("name"),
          })}
        />
      </div>
      <div className="bundleLevels__line bundleLevels__scores">
        <div className="bundleLevels__row">
          <div className="bundleLevels__field">
            <p className="super-modal__form-label">Баллы, от:</p>
            <DummyCounter
              step={1}
              value={level.scores_from}
              onChange={(val, key) => {
                setScoreLevel(val > level.scores_to ? level.scores_from : val, key);
              }}
              name="scores_from"
            />
          </div>
          <div className="bundleLevels__field">
            <p className="super-modal__form-label">Баллы, до:</p>
            <DummyCounter
              step={1}
              value={level.scores_to}
              onChange={(val, key) => {
                setScoreLevel(val < level.scores_from ? level.scores_to : val, key);
              }}
              name="scores_to"
            />
          </div>
        </div>
      </div>
      <div className="bundleLevels__line">
        <button className="bundleLevels__button" onClick={addLevel}>
          Добавить уровень
        </button>
      </div>
      {levels.length ? (
        <div className="bundleLevelsList">
          <div className="bundleLevels__title">Уровни:</div>
          <div className="bundleLevelsList__wrapper">
            {getModalValues?.levels?.map((item) => (
              <div className="bundleLevelsList__item" key={`${item.scores_to} - ${item.scores_from}`}>
                <p className="bundleLevelsList__title">{item.name}</p>
                <div className="bundleLevelsList__options">
                  <p className="bundleLevelsList__score">
                    {item.scores_from} - {item.scores_to}
                  </p>
                  <div
                    className="bundleLevelsList__delete"
                    onClick={() => deleteLevel(item.name)}
                  >
                    &#10006;
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default observer(BundleLevels);
