import React from 'react'
import './TypeSelector.scss'

export default function TypeSelector(props) {

    return (
        <div className={`type-selector-box ${props.values.length === 0 ? 'modal-selectors--disabled' : ''}`}>
            {!props.like_obj ?
            props.values && props.values.map((item, index)=>{
                return(
                    <div style={{width: (100 / props.values.length) + '%'}} key={index} onClick={()=>props.objectChangeHandler(index +1, props.name)} className={props.selected - 1 === index ? 'type-selector-selected' : null}>
                        {item}
                    </div>
                )
            })
            :
            props.values && props.values.map((item, index)=>{
                return(
                    <div style={{width: (100 / props.values.length) + '%'}} key={index} onClick={()=>props.objectChangeHandler(item.id, props.name)} className={props.selected === item.id ? 'type-selector-selected' : null}>
                        {item.name}
                    </div>
                )
            })
            }
        </div>
    )
}
