import {observable, action, computed, toJS, makeObservable} from 'mobx';

class AsideModalStore {
  open = false;
  loading = false;
  config = {};
  errors = [];
  values = {};
  formErrors = [];

  constructor() {
    makeObservable(this, {
      open: observable,
      loading: observable,
      config: observable,
      values: observable,
      errors: observable,
      formErrors: observable,

      activity: action,
      changeValue: action,
      changeValues: action,
      setLoading: action,
      setValid: action,
      addValidField: action,
      deleteValidField: action,
      //setFormErrors: action,
      deleteErrors: action,

      modalSettings: computed,
      modalValues: computed,
    })
  }

  activity = (config, values) => {
    if (this.open) {
      this.open = false

      if (this.loading) this.loading = false
      this.config = {}
      this.values = {}
      this.formErrors = []
      this.errors = []
    } else {
      if (config) {
        this.config = config;
        if (values) this.values = values
      }
      this.open = true
    }
  }

  changeValue = (val, key) => {
    this.values = {...this.values, [key]: val}
    // Эксперимент
    if (this.formErrors.includes(key)) {
      this.formErrors = this.formErrors.filter(x => x !== key)
    }
  }

  changeValues = obj => {
    this.values = obj
  }

  /*deleteKey = key => {
    delete this.values[key]
  }*/

  setLoading = val => {
    this.loading = val
  }

  setValid = valid => {
    this.deleteErrors()
    this.config = {...this.config, valid}
  }

  addValidField = obj => {
    this.config = {
      ...this.config,
      valid: {...this.config.valid, ...obj}
    }
  }

  deleteValidField = key => {
    this.deleteErrors()
    const {valid: {[key]: _, ...valid}} = this.config
    this.config = {...this.config, valid}
  }

  //setFormErrors = errors => this.formErrors = errors

  deleteErrors = () => {
    this.errors = []
    this.formErrors = []
  }

  get modalSettings() {
    return toJS(this.config)
  }

  get modalValues() {
    return toJS(this.values)
  }

  check = key => Object.values(this.formErrors).includes(key)
}

AsideModalStore = new AsideModalStore()

export default AsideModalStore
