import React from 'react'
const reactStringReplace = require('react-string-replace')

export default function MainTableQuestion(props) {

    function getWordsBetweenCurlies(str) {
        var results = [], re = /\[(.*?)\]/g, text;
      
        while(text = re.exec(str)) {
          results.push(text[1]);
        }
        return results;
    }

    const qText = (item, type) =>{
        let result = ''
        switch (type) {
            case 'build_sentence':
                result = item.replaceAll('|', ' ')
                break;
            case 'text_missing_words':
                let inject;
                let arrResult = getWordsBetweenCurlies(item)
                result = item
                for(let i = 0; i < arrResult.length; i++){
                    inject = '[' + arrResult[i] + ']'
                    //injector = <strong>[{arrResult[i]}]</strong>
                    result = reactStringReplace(result, inject, (match, i) => (
                        <strong>[{arrResult[i]}]</strong>
                      ))
                }
                break;
            default:
                result = item
                break;
        }
        return result
    }

    const aText = (item,type) =>{
        let result = ''
        let answers;
        let arr = []
        let correct = true
        switch (type) {
            case 'image_plus_variants':
                answers = item.split('|')
                correct = true;
                for(let i = 0; i < answers.length; i++){
                    if( i > 0 ) correct = false
                    arr.push(
                        {text: answers[i], correct: correct}
                    )
                }
                result = arr
                break;
            case 'question_plus_variants':
                answers = item.split('|')
                arr = []
                correct = true;
                for(let i = 0; i < answers.length; i++){
                    if( i > 0 ) correct = false
                    arr.push(
                        {text: answers[i], correct: correct}
                    )
                }
                result = arr
                break;
            case 'text_missing_words':
                answers = item.split('|')
                arr = []
                for(let i = 0; i < answers.length; i++){
                    arr.push(
                        {text: answers[i], correct: true}
                    )
                }
                result = arr
                break;
        
            default:
                break;
        }
        return result;
    }
    return (
        <>  {
            props.items.type === 'image_plus_variants' || props.items.type === 'image_plus_text' ?
            <div className="main-table-card-box__body-unit">
            <img src={props.items.image} width='400px' alt="pic"/>
            </div>
            :null
            }
            <div className="main-table-card-box__body-unit" style={{display: 'block', whiteSpace: 'break-spaces'}}>
                {qText(props.items.question_text, props.items.question_type)}
            </div>
            <div className="main-table-card-box__body-unit" style={{flexDirection: 'column'}}>
                {typeof aText(props.items.answer, props.items.type) === 'object' ? 
                aText(props.items.answer, props.items.type).map((item, index)=>{
                    return(
                        <div key={index} style={{fontWeight: item.correct && '600'}}>
                            {item.text}
                        </div>
                    )
                })
                :
                <strong>{props.items.answer}</strong>
                }
            </div>
        </>
    )
}
