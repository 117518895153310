import React, {Fragment, useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import cogoToast from "cogo-toast";
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import ContentHeader from "../ContentHeader";
import Filter from "../Filter/Filter";
import THead from "./THead";
import Loader from "../Loader/Loader";
import st from "./Table.module.scss"
import {splitNumberDigits} from "../../../../helpers/math";

export default function ReceiptFunds() {
    const cfg = {
        cols: [
            {
                name: 'Компания',
                rowSpan: row => {
                    let rs = row.locations.length // для Итоговых полос по локациям
                    row.locations.forEach(loc => {
                        rs += loc.banks.length
                        loc.banks.forEach(bank => {
                            rs += bank.months.length
                            bank.months.forEach(month => {
                                rs += month.days.length
                                month.days.forEach(day => {
                                    rs += day.operations.length
                                })
                            })
                        })
                    })
                    return rs
                }
            },
            {
                name: 'Локация',
                rowSpan: row => {
                    let rs = row.banks.length
                    row.banks.forEach(bank => {
                        rs += bank.months.length
                        bank.months.forEach(month => {
                            rs += month.days.length
                            month.days.forEach(day => {
                                rs += day.operations.length
                            })
                        })
                    })
                    return rs
                }
            },
            {
                name: 'Банк',
                classes: [st.fz12],
                rowSpan: row => {
                    let rs = row.months.length
                    row.months.forEach(month => {
                        rs += month.days.length
                        month.days.forEach(day => {
                            rs += day.operations.length
                        })
                    })
                    return rs
                }
            },
            {
                name: 'Месяц',
                rowSpan: row => row.days.reduce((sum, day) => sum + day.operations.length, row.days.length)
            },
            {
                name: 'День',
            },
            {
                name: 'Студент, описание платежа',
            },
            {
                name: 'Сумма, тг',
            },
        ]
    }

    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader()

    const today = new Date()

    const [groupCatalog, setGroupCatalog] = useState({})
    const [filter, setFilter] = useState({
        date_from: new Date(today.getFullYear(), today.getMonth(), 1),
        date_until: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    })


    const filterCfg = [
        {
            type: 'select-custom',
            placeholder: 'Компания',
            values: groupCatalog.companies?.items,
            sort_key: 'name',
            nameState: 'company'
        },
        {
            type: 'select-custom',
            placeholder: 'Локация',
            values: groupCatalog.locations?.items,
            sort_key: 'name',
            nameState: 'location'
        },
        {type: 'month-range-picker', nameState: ['date_from', 'date_until']},
    ]

    const formatNumber = num => splitNumberDigits((num / 100).toFixed(2).replace('.', ','))

    useEffect(() => {
        const apiConf = {
            method: 'get',
            link: 'groups/groups_catalog',
        }
        superAxiosRequest(apiConf).then(res => {
            setGroupCatalog(res.data)
        })
    }, [])

    useEffect(() => {
        setIsLoading(true)
        setData(null)

        const
            y = filter.date_until.getFullYear(),
            m = filter.date_until.getMonth();

        const params = {
            ...filter,
            date_from: dateFormat(filter.date_from, 'YYYY-mm-dd'),
            date_until: dateFormat(new Date(y, m+1, 0), 'YYYY-mm-dd'),
        }
        const apiConf = {
            method: 'get',
            link: 'payments/funds_receipt_report',
            params,
        }
        superAxiosRequest(apiConf).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(() => {
            setData({})
            setIsLoading(false)
            cogoToast.warn("Что-то пошло не так :(", {heading: "Ошибка сервера"})
        })
    }, [filter, setData, setIsLoading])

    return (
        <>
            <ContentHeader title="Приход денежных средств"/>
            <Filter config={filterCfg} drop={() => {}} getFilterParams={setFilter}/>

            {data &&
            <div className={st.tableWrapper} ref={refWrapper}>
                <div ref={refSticky} className={st.sticky}>
                    <table className={st.table}>
                        <THead cols={cfg.cols}/>
                    </table>
                </div>
                <table className={st.table}>
                    <THead cols={cfg.cols} ref={refThead}/>
                    <tbody className={`${st.tbody} ${st.tbodyStriped}`}>
                    {Array.isArray(data.companies) && data.companies.map((company, ic) =>
                        <Fragment key={ic}>{Array.isArray(company.locations) && company.locations.map((location, il) =>
                            <Fragment key={il}>{Array.isArray(location.banks) && location.banks.map((bank, ib) =>
                                <Fragment key={ib}>{Array.isArray(bank.months) && bank.months.map((month, im) =>
                                    <Fragment key={im}>{Array.isArray(month.days) && month.days.map((day, id) =>
                                        <Fragment
                                            key={id}>{Array.isArray(day.operations) && day.operations.map((oper, io) =>
                                            <tr key={io}>
                                                {il === 0 && ib === 0 && im === 0 && id === 0 && io === 0 &&
                                                <td rowSpan={cfg.cols[0].rowSpan(company)} className={st.bgw}>
                                                    {company.name}
                                                </td>}

                                                {ib === 0 && im === 0 && id === 0 && io === 0 &&
                                                <td rowSpan={cfg.cols[1].rowSpan(location)} className={st.bgw}>
                                                    {location.name}
                                                </td>}

                                                {im === 0 && id === 0 && io === 0 &&
                                                <td rowSpan={cfg.cols[2].rowSpan(bank)} className={st.bgw}>
                                                    <div className={st.tdContentBank}>
                                                        <div>БИН: {bank.name}</div>
                                                        <div>{bank.bin}</div>
                                                        <div>{bank.payment_type}</div>
                                                    </div>
                                                </td>}

                                                {id === 0 && io === 0 &&
                                                <td rowSpan={cfg.cols[3].rowSpan(month)} className={`${st.bgw} ${st.fw500}`}>
                                                    {month.name}
                                                </td>}

                                                {io === 0 &&
                                                <td rowSpan={day.operations.length}
                                                    className={`${st.textAlignCenter} ${st.fz12} ${st.bgw}`}>
                                                    {day.name}
                                                </td>}

                                                <td className={`${st.fz12} ${st.bdb}`}>
                                                    <div>
                                                        <NavLink to={'/account_clients/' + oper.student.id} target="_blank">
                                                            {oper.student.last_name + ' ' + oper.student.first_name}
                                                        </NavLink>
                                                    </div>
                                                    <div>{oper.description}</div>
                                                    <div>Ордер: {oper.order}</div>
                                                </td>

                                                <td className={`${st.textAlignCenter} ${st.fz12} ${st.bdb}`}>
                                                    {formatNumber(oper.sum)}
                                                </td>

                                            </tr>)}
                                            {/* Итого за день */}
                                            {day.total &&
                                            <tr className={`${st.bdt} ${st.nobr}`}>
                                                <td colSpan={2} className={st.tdTotal}>ИТОГО:</td>
                                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                                    {formatNumber(day.total)}
                                                </td>
                                            </tr>}
                                        </Fragment>)}
                                        {/* Итого за месяц */}
                                        {month.total &&
                                        <tr className={`${st.bdt} ${st.nobr}`}>
                                            <td colSpan={3} className={st.tdTotal}>ИТОГО, {month.name}</td>
                                            <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                                {formatNumber(month.total)}
                                            </td>
                                        </tr>}
                                    </Fragment>)}
                                    {/* Итого по банку */}
                                    {bank.total &&
                                    <tr className={`${st.bdt} ${st.nobr}`}>
                                        <td colSpan={4} className={st.tdTotal}>ИТОГО, {bank.name}</td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {formatNumber(bank.total)}
                                        </td>
                                    </tr>}
                                </Fragment>)}
                                {/* Итого по локации */}
                                {location.total &&
                                <tr className={`${st.bdt} ${st.nobr}`}>
                                    <td colSpan={5} className={st.tdTotal}>ИТОГО, {location.name}</td>
                                    <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                        {formatNumber(location.total)}
                                    </td>
                                </tr>}
                            </Fragment>)}

                            {/* Итого по компании */}
                            {company.total &&
                            <tr className={`${st.bdt} ${st.nobr}`}>
                                <td colSpan={6} className={st.tdTotal}>ИТОГО, {company.name}</td>
                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                    {formatNumber(company.total)}
                                </td>
                            </tr>}
                        </Fragment>)}

                    {/* Итого */}
                    {/*{data.total &&
                    <tr className={`${st.bdt} ${st.nobr}`}>
                        <td colSpan={6} className={st.tdTotal}>ИТОГО</td>
                        <td className={st.tdTotal}>{data.total}</td>
                    </tr>}*/}
                    </tbody>
                </table>
            </div>}

            {isLoading && <Loader/>}
        </>
    );
};
