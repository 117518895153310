import React, {useEffect, useState} from 'react';
import {localisationMaster} from "Localisation/Localisation";
import {permissions} from "settings/permissions";
import {checkPermList} from "helpers";
import {addZero} from "helpers/math";
import groupStore from 'Stores/groupStore';
import modalStore from "Stores/modalStore";
import TimeTableCalendar from './TimeTableCalendar/TimeTableCalendar';
import TimeTableList from './TimeTableList';
import './TimeTable.scss';
import plus from '../../img/plus.svg'

export default function TimeTable(props) {
    const [calendar, setCalendar] = useState([false, []])

    const createCalendar = (start, end, weekdays) => {
        //start = correctDate(start)
        //end = correctDate(end)
        let y,m,d;
        let calendar = [];
        let start_at = new Date(`${start}T12:00:00`);
        let end_at = new Date(`${end}T12:00:00`);
        let true_days = weekdays.map(({day}) => day);

        while(start_at <= end_at){
            //const next_date = start_at.setDate(start_at.getDate());
            //const next_date = start_at;
            const next_day_date = new Date(start_at);
            let day_index = next_day_date.getDay() || 7;
            if (true_days.includes(day_index)){
                d = addZero(next_day_date.getDate());
                m = addZero(next_day_date.getMonth() + 1);
                y = next_day_date.getFullYear();
                calendar.push([y, m, d].join('-'));
            }
            // increment the date
            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
        }
        return calendar
    }

    const addTimeTable = () => {
        const ms = {
            type: 'time_table',
            name: 'time_table',
            title: localisationMaster('text_53a', 'groups/all-groups'),
            button: localisationMaster('add_schedule', 'groups/group', 'group_schedule'),
            link: 'schedule',
            data: 'json',
            width: '800px',
            payload: {
                holidays: props.holidays,
                location: props.location,
                g_id: +props.id
            },
            func: groupStore.updateGroupData,
        }
        modalStore.activity(ms, {teachers: []})
    }

    useEffect(() => {
        const createDateDelta = array => {
            if (Array.isArray(array)) {
                let max_array = [];
                let new_arr = [];

                for (let i = 0; i < array.length; i++) {
                    new_arr = createCalendar(array[i].date_from, array[i].date_until, array[i].weekdays)

                    for (let y = 0; y < new_arr.length; y++) {
                        max_array.push(
                            {
                                date: new_arr[y],
                                schedule_id: array[i].id,
                                classroom: array[i].classroom && array[i].classroom.name,
                                teachers: array[i].teachers,
                                start_at: array[i].start_at,
                                finish_at: array[i].finish_at,
                                hours_type_minutes: array[i].hour_type.minutes
                            }
                        )
                    }
                }
                setCalendar([true, max_array])
            } else {
                setCalendar([false, []])
            }
        }

        createDateDelta(props.schedule)
    }, [props.schedule])

    return (
        <div className="timetable-box">
            <div className="timetable-box__header">
                <h3>{localisationMaster('timetable', 'groups/group')}</h3>
                {checkPermList(permissions.add_schedule) &&
                  <div onClick={addTimeTable}><img src={plus} alt="plus"/></div>}
            </div>

            <TimeTableList holidays={props.holidays} gid={props.id} location={props.location} data = {props.schedule} />

            {calendar[0] ?
                <TimeTableCalendar
                    ebooks={props.ebooks}
                    holidays={props.holidays}
                    items={calendar[1]}
                    schooldays={props.schooldays}
                    shedule={props.schedule}
                    statuses={props.statuses}
                    gid={props.id}/>
                :
                <div className="sg-probka">
                    {localisationMaster('text_51', 'groups/all-groups')}
                </div>}
        </div>
    )
}
