import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './ColorPicker.module.scss';

const colors = [
  '#ebc05f', '#fff863', '#998de0', '#55a77a', '#eae6d2', '#c7cedf', '#ec8c64', '#798081', '#0be7fb'
];

function ColorPicker({ title, color, onChange, tag, usedColors }) {
  const Tag = tag;
  const [toggleColorsList, setToggleColorsList] = useState(false);
  const uniqueColors = usedColors
    ? colors.filter((color) => usedColors.indexOf(color) === -1)
    : colors;
  const [colorValue, setColorValue] = useState(color || uniqueColors[0]);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setColorValue(value);
    setToggleColorsList(false);
    onChange(value);
  };

  useEffect(() => {
    if (!color) {
      onChange(uniqueColors[0]);
    }
  }, []);

  return (
    <Tag className={styles.wrapper}>
      <span className={styles.title}>
        {title}:
      </span>
      <button
        className={cn({
          [styles.button]: true,
          [styles.button__open]: toggleColorsList,
        })}
        type='button'
        aria-label='Выбрать цвет'
        title='Выбрать цвет'
        onClick={() => setToggleColorsList(!toggleColorsList)}
      >
        <span className={styles.active_color} style={{backgroundColor: colorValue}} />
      </button>

      <ul className={cn({
        [styles.colors_list]: true,
        [styles.colors_list__open]: toggleColorsList,
      })}>
        {uniqueColors.map((color) => (
          <li key={color}>
            <label aria-label={`Цвет ${color}`}>
              <input
                className={styles.input}
                type='radio'
                name='color'
                value={color}
                checked={color === colorValue}
                onChange={handleChange}
              />
              <span className={styles.color} style={{backgroundColor: color}} />
            </label>
          </li>
        ))}
      </ul>
    </Tag>
  );
}

ColorPicker.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.oneOf(['div', 'li']),
  usedColors: PropTypes.arrayOf(PropTypes.string),
};

ColorPicker.defaultProps = {
  tag: 'div',
};

export default ColorPicker;
