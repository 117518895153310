import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './InputWithControls.module.scss';

const DEFAULT_STEP = 5;

function InputWithControls({
  label,
  value,
  type,
  onChange,
  step,
  min,
  max,
  asColumn,
  justify,
}) {
  const handleButtonClick = (evt) => {
    evt.target.name === 'decrement'
      ? onChange(value - step)
      : onChange(value + step);
  };

  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.wrapper__column]: asColumn,
        [styles.wrapper__center]: justify === 'center',
        [styles.wrapper__end]: justify === 'end',
        [styles.wrapper__stretch]: justify === 'stretch',
      })}
    >
      { label && <span className={styles.label}>{ label }:</span> }
      <div className={styles.inner}>
        <button
          className={`${styles.control} ${styles.control__left}`}
          type='button'
          name='decrement'
          aria-label='Уменьшить'
          disabled={value <= min}
          onClick={handleButtonClick}
        >
          -
        </button>
        <span className={styles.value}>{value}</span>
        <span className={styles.type}>{type}</span>
        <button
          className={`${styles.control} ${styles.control__right}`}
          type='button'
          name='increment'
          aria-label='Увеличить'
          disabled={value >= max}
          onClick={handleButtonClick}
        >
          +
        </button>
      </div>
    </div>
  );
}

InputWithControls.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  asColumn: PropTypes.bool,
  justify: PropTypes.oneOf(['center', 'end', 'stretch', '']),
};

InputWithControls.defaultProps = {
  label: '',
  step: DEFAULT_STEP,
  min: 0,
  max: Infinity,
  asColumn: false,
  justify: '',
};

export default InputWithControls;
