import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './Select.module.scss';

const customStyles = {
  control: (provided, {menuIsOpen, isFocused}) => ({
    ...provided,
    minHeight: 44,
    boxShadow: menuIsOpen || isFocused ? '0 0 0 3px #b8d4ff' : 'none',
    cursor: 'pointer',
    borderColor: '#bdbdbd',
    borderRadius: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 20,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#bdbdbd',
  }),
  option: (provided) => ({
    ...provided,
    minHeight: 35,
    paddingTop: 10,
  }),
}

function Select({
  onChange,
  isDisabled,
  label,
  placeholder,
  options,
  defaultValue,
  value,
  isLoading,
  withMargin,
  isError,
  isRequired,
  tag,
  classes,
  noOptionsText,
  ...attrs
}) {
  const Tag = tag;

  return (
    <Tag className={cn({
      [styles.wrapper]: true,
      [styles.wrapper__margin]: withMargin,
      [styles.wrapper__error]: isError,
      [classes]: classes,
    })}>
      { label &&
        <p className={styles.label}>
          {isRequired && <span>* </span>}
          {label}:
        </p>
      }
      <ReactSelect
        className={styles.select}
        placeholder={placeholder}
        onChange={onChange}
        isDisabled={isDisabled}
        options={options}
        defaultValue={defaultValue}
        value={value || defaultValue}
        isLoading={isLoading}
        styles={customStyles}
        noOptionsMessage={() => <span>{noOptionsText}</span>}
        {...attrs}
      />
    </Tag>
  );
}

const selectOption = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
});

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    selectOption,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(selectOption),
    }),
  ])).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    selectOption,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    selectOption,
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  withMargin: PropTypes.bool,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  tag: PropTypes.oneOf(['div', 'li']),
  classes: PropTypes.string,
  noOptionsText: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  label: '',
  placeholder: '',
  defaultValue: null,
  value: null,
  isLoading: false,
  withMargin: false,
  isError: false,
  isRequired: false,
  onChange: () => {},
  tag: 'div',
  classes: '',
  noOptionsText: 'Нет доступных опций',
};

export default Select;
