import React, {useEffect, useRef, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import st from "./FileUploader.module.scss"

export default function FileUploader(props) {
    const refInput = useRef()

    const [file, setFile] = useState()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (file){
            const formData = new FormData()
            formData.append('file', file)
            const apiConf = {
                method: 'post',
                //link: 'lessons/doc',
                link: 'lessons/file_upload',
            }
            const axiosConfig = {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    // do whatever you like with the percentage complete
                    // maybe dispatch an action that will update a progress bar or something
                    setProgress(percentCompleted)
                }
            }
            superAxiosRequest(apiConf, formData, 'media', axiosConfig).then(res => {
                props.onUploadEnd(res.data)
            }).catch(() => {

            })
        }
    }, [file])

    return (
        <div className={props.error ? st.boxError : st.box}>
            <input type="file" ref={refInput} onChange={e => {setFile(e.target.files[0])}}/>

            <button onClick={() => {refInput.current.click()}}>
                {localisationMaster('modal_doc_text_5', 'lessons/course')}
            </button>

            {file &&
            <div className={st.progress}>
                <progress max="100" value={progress}/>
                <div>{progress} %</div>
            </div>}

            <div>
                {file?.name}
            </div>
        </div>
    );
};