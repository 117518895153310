import React, {useState} from "react"
import {dateFormat} from "helpers/date";
import {observer} from "mobx-react";
import {showDisabledComment} from "components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendar";
import Stores from "Stores";
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import {classNames as cn} from "helpers";
import groupStore, {schoolDayStatuses as sds} from "Stores/groupStore";
import DayStatus from "./DayStatus";
import Loader from "components/UI/Loader/Loader";
import st from "./Calendar.module.scss"
import {beginLesson} from "components/ContentBox/Groups/components/DayInfo";
import {ReactComponent as ArrowBtn} from "components/UI/icons/arrow-right-line.svg";
import asideModalStore from "Stores/asideModalStore";

const Calendar = props => {
  const {group, wsData: {workshops: wsDays, teachers, schoolday_statuses, students}, updateWSDays} = groupStore
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startSelectedDate, setStartSelectedDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([])
  const { isTeacher } = Stores

  const onClickDay = () => {
    const day = selectedDates[selectedDates.length - 1]
    if (!day) return

    // Если студент еще не начал заниматься
    if (day.status === 11){
      showDisabledComment()
      return
    }

    const date = day.date

    // Перенос и Замена учителя
    const actions = [
      {id: 777, name: localisationMaster('text_39', 'modal_templates')},
      {id: 999, name: localisationMaster('change_teacher_time', 'groups/group', 'time_table_day')},
    ]

    let statuses = props.isStudent ? [...group.schoolday_note_statuses] : [...group.schoolday_statuses]

    // На будущий день нельзя установить Занятие
    if (new Date(date) > new Date()) statuses = statuses.filter(item => item.id !== 7)

    if (Stores.Rules.checkRule('schoolday', 'edit_absence')) {
      statuses = [
        {id: 7, name: localisationMaster('lesson', 'groups/group')},
        {id: 3, name: localisationMaster('missed', 'groups/group', 'time_table_day')}
      ]
    }
    if (!props.isStudent && !Stores.Rules.checkRule('schoolday', 'edit_absence')) {
      statuses = [...statuses, ...actions]
    }

    const ms = {
      isCenter: true,
      title: `${timeConverter(date, 'age')}`,
      modalBodyComponent: () => <DayStatus statuses={statuses}
                                           isStudent={!!props.isStudent}
                                           groupID={group.group.id}
                                           days={selectedDates}
                                           lastDay={wsDays[wsDays.length - 1]}
      />,
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: props.isStudent ? "schedule/ws_studentschooldaynote" : "schedule/ws_teacherschooldaynote",
      onSubmitSuccess: data => updateWSDays(data.workshops),
    }

    const md = {
      workshop_id: selectedDates.map(d => d.id),
      ...(props.studentID && {user_id: +props.studentID}),
      ...(day.status && {status: day.status}),
      comment: day.comment,
    }

    asideModalStore.activity(ms, md)
  }

  const handleMouseDown = day => {
    setIsMouseDown(true)
    setStartSelectedDate(wsDays.findIndex(d => d.id === day.id))
    setSelectedDates([day])
  }

  const handleMouseEnter = day => {
    if (!isMouseDown) return
    const currentIndex = wsDays.findIndex(d => d.id === day.id)

    const cond = currentIndex > startSelectedDate ?
      (d, i) => i >= startSelectedDate && i <= currentIndex :
      (d, i) => i <= startSelectedDate && i >= currentIndex
    setSelectedDates(wsDays.filter(cond))
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    setSelectedDates([])
    setStartSelectedDate(null)
    onClickDay()
  }

  const getDays = () => {
    let
      arr = [],
      arrReverse = [],
      isHoliday,
      title,
      num = 0,
      isNotSkip,
      student, studentStatus,
      duration,
      daySum,
      debit,
      debtor,
      credit,
      creditor,
      ebooks,
      ebook

    if (props.studentID) {
      student = students?.find(st => st.id === +props.studentID)

      if (student?.balance > 0) {
        debit = student.balance
        debtor = 0
      }
      else {
        credit = -student?.balance || 0
        creditor = 0
      }
    }

    // Регистры
    if (Array.isArray(props.ebooks)){
      ebooks = props.ebooks.map(eb => ({
        registers: [...eb.registers],
        ebook_id: eb.ebook_id,
        start_date: eb.start_date,
        end_date: eb.end_date
      }))
    }

    wsDays.forEach(day => {
      title = 'Занятие'

      isHoliday = group.holidays.some(holiday => holiday.date === day.date)

      duration = (new Date(`${day.date}T${day.finish_at}`) - new Date(`${day.date}T${day.start_at}`)) / 1000 / 60

      if (day.status) {
        title = schoolday_statuses.find(st => st.id === day.status)?.name;
      }

      isNotSkip = ![sds.NOT_PAID_SKIP, sds.PAID_SKIP, sds.PART_PAID_SKIP, sds.CANCELED].includes(day.status)

      if (student) {
        studentStatus = day.student_statuses.find(sdn => sdn.student_id === student.id)

        if (studentStatus){
          title = group.schoolday_note_statuses.find(st => st.id === studentStatus.status_sdn)?.name || title
        }

        daySum = student.default_price.price_per_minute * duration

        if (debit > 0 && !studentStatus?.status_sdn && !day.status){
          debtor = 0
          if (debit > 0.99 * daySum)
            debtor = 2
          else if (debit > 0.01 * daySum)
            debtor = 1

          debit -= daySum
        } else {
          debtor = 0
        }
      }

      if (isHoliday && !studentStatus) {
        title =  `Праздничный день`
        ebook = null
      } else if (isNotSkip) {
        num++
        title += ` №${num}`

        // Добавление регистров
        if (ebooks) {
          const date = new Date(day.date)
          const index = ebooks.findIndex(eb => {
            const checkEndDate = eb.end_date ? new Date(eb.end_date) >= date : true
            return (new Date(eb.start_date) <= date && checkEndDate)
          })

          if (index !== -1) {
            ebook = {
              group_id: group.group.id,
              date: `${day.date} ${day.start_at}`,
              register: ebooks[index].registers.shift(),
              ebook_id: ebooks[index].ebook_id
            }
          } else {
            ebook = null
          }
        }
      } else {
        ebook = null
      }

      arr.push({
        ...day,
        isHoliday,
        status: studentStatus?.status_sdn || day.status,
        tooltip: {
          title,
          ...((!isHoliday || studentStatus) && {duration}),
          ...((!isHoliday || studentStatus) && {
            teachers: day.teachers.map(teacher_id => teachers.find(t => t.id === teacher_id))
          }),
          ...(studentStatus?.sum !== undefined && {sum: studentStatus.sum}),
          ...(studentStatus?.bonus_sum && {bonusSum: studentStatus.bonus_sum}),
          comment: studentStatus?.comment_sdn || day.comment || '',
          ...(ebook && {ebook})
        },
        ...(student && {debtor}),
      })
    })

    if (student && credit > 0){
      arr.reverse().forEach(day => {
        creditor = 0

        if (day.tooltip.sum > 0 && credit > day.tooltip.sum) {
          creditor = 2
          credit -= day.tooltip.sum
        } else if (day.tooltip.sum > 0 && credit > 0) {
          creditor = 1
          credit -= day.tooltip.sum
        }

        arrReverse.push({
          ...day,
          creditor,
        })
      })

      arr = arrReverse.reverse()
    }

    return arr
  }

  return (
    wsDays ?
      <div className={st.days}>{getDays().map(d => {
        return <div key={d.id}
             className={props?.student?.finish_study_at && new Date(d.date) > new Date(props.student.finish_study_at) ?
               cn({
                 [st.day]: true,
                 [st[`dayStatus5`]]: true,
               }) : cn({
                 [st.day]: true,
                 [st[`dayStatus${d.status}`]]: !!d.status,
                 [st.dayCreditor]: d.creditor === 2 && d.status === 7 && !d.isHoliday,
                 [st.dayCreditorHalf]: d.creditor === 1 && d.status === 7 && !d.isHoliday,
                 [st.dayDebtor]: d.debtor === 2 && !d.isHoliday,
                 [st.dayDebtorHalf]: d.debtor === 1 && !d.isHoliday,
                 [st.dayHoliday]: d.isHoliday,
                 [st.selectedDay]: !!selectedDates.find(x => x.id === d.id),
               })}>
          <span onMouseDown={() => handleMouseDown(d)}
                onMouseEnter={() => handleMouseEnter(d)}
                onMouseUp={() => handleMouseUp(d)}>
            {dateFormat(d.date, "dd.mm.YY")}
          </span>
          <div className={st.tooltip}>
            <div className={st.tooltipContent}>
              <div className={st.tooltipTitleDur}>
                {d.status === 11 ?
                  <span className={st.tooltipTitle}>Еще не начал заниматься</span> :
                  <span className={st.tooltipTitle}>{d.tooltip.title}</span>}
                {d.tooltip.duration &&
                  <span className={st.tooltipDuration}>({d.tooltip.duration} мин.)</span>}
              </div>

              {Array.isArray(d.tooltip.teachers) &&
                <div className={st.tooltipTeachers}>{d.tooltip.teachers.map(teacher =>
                  <div key={teacher.id}>{teacher.full_name}</div>)}
                </div>}
              {(!isTeacher && d.tooltip.sum !== undefined ) &&
                <div className={st.tooltipTeachers}>
                  Списано за этот день: {(d.tooltip.sum / 100).toFixed(2)} тг
                </div>}

              {(!isTeacher && d.tooltip.bonusSum) &&
                <div className={st.tooltipTeachers}>
                  Списано с бонусного счета: {(d.tooltip.bonusSum / 100).toFixed(2)} тг
                </div>}

              {d.tooltip.comment &&
              <div className={st.tooltipTeachers} dangerouslySetInnerHTML={{__html: d.tooltip.comment}}/>}

              {d.tooltip.ebook &&
                <div className={st.tooltipRegisters}>
                  {d.tooltip.ebook?.register &&
                    <>
                      <div className={st.tooltipRegister}>
                        <b>Программа урока:</b>
                        <div>{d.tooltip.ebook.register.lesson_plan}</div>
                      </div>
                      <div className={st.tooltipRegister}>
                        <b>Домашнее задание:</b>
                        <div>{d.tooltip.ebook.register.homework}</div>
                      </div>
                    </>}
                  <div className={st.tooltipRegister}>
                    <div>
                      <button className={st.startLessonBtn} onClick={() => {beginLesson(d.tooltip.ebook)}}>
                        <span>Start Lesson</span>
                        <ArrowBtn/>
                      </button>
                    </div>
                  </div>
                </div>}
            </div>
          </div>
        </div>})}
      </div> :
      <Loader/>
  )
}

export default observer(Calendar)
