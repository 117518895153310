import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { superAxiosRequest } from "axiosApi";
import { timeConverter, timeConverter2 } from 'components/ContentBox/Online-schedule/components/functions';
import { createWorkDays } from 'components/ContentBox/Company/Employee/Tabs/scheduleFunctions';
import TypeSelector from './TypeSelector';
import MainSelector from '../MainSelector';
import OneShift from './templates/OneShift';
import DayByDay from './templates/DayByDay';
import SlideSchedule from './templates/SlideSchedule';
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import './WorkSchedule.scss';
import someImg from 'components/ContentBox/img/time-management.svg';
import { ReactComponent as CalendarIcon } from "components/ContentBox/img/calendar-icon.svg"
import { ReactComponent as Arrow } from "components/ContentBox/img/right-arrow-1.svg";

export default function WorkSchedule(props) {
    let temp = {
        date_from: timeConverter2(new Date()),
        period: 1,
        count: 1,
        loop_type: 1,
        lunch: 0,
        start_at: '9:00',
        finish_at: '18:00',
    };

    const templateTypes = [
        { id: 0, name: 'Выбрать шаблон' },
        { id: 1, name: 'Сохранить как шаблон' }
    ]

    const [type, setType] = useState(1)
    const [date, setDate] = useState(new Date())
    const [list, setList] = useState([])
    const [calendar, setCalendar] = useState([false, []])
    const [calOpen, setCalOpen] = useState(true)
    const [isTemplate, setIsTemplate] = useState(false)
    const [templateType, setTemplateType] = useState(0)
    const [templateName, setTemplateName] = useState('')
    const [templateOptions, setTemplateOptions] = useState([])
    const [templateId, setTemplateId] = useState()
    //const [contract, setContract] = useState(0)

    const addCard = () => setList([...list, temp])

    const updateList = (index, value) => {
        let arr = list;
        arr[index] = value
        setList([...arr])
    }

    const deleteFromList = index => {
        if (list.length > 1) {
            const arr = [...list]
            arr.splice(index, 1)
            setList(arr)
        }
    }

    const editFilter = array => {
        let arr = []
        if (array.length > 0) {
            let last = array[array.length - 1].date_until
            arr = array.filter(x => x.date_until === last)
        }
        return arr
    }

    const setRules = arr => {
        if (arr.length > 0) {
            let now = new Date()
            now = now.setDate(now.getDate() + 1);
            now = new Date(now)
            if (arr[0].loop_type < 8) {
                setType(1)
            } else if (arr[0].loop_type === 8) {
                setType(2)
            } else {
                setType(3)
            }
            for (let i = 0; i < arr.length; i++) {
                delete arr[i].id
                arr[i].date_from = timeConverter(now, 'today')
                if (arr[i].loop_type < 8)
                    arr[i].period = (arr[i].period + 1) / 7
                delete arr[i].date_until
            }

            setList([...arr])
        }
    }

    const createDate = (value, c) => {
        const now = new Date(value);
        let expiry;
        if (c > 0) {
            expiry = now.getMonth() === 11 ?
                new Date(now.getFullYear() + 1, 0, 1) :
                new Date(now.getFullYear(), now.getMonth() + 1, 1);
        } else {
            expiry = now.getMonth() === 0 ?
                new Date(now.getFullYear() - 1, 11, 1) :
                new Date(now.getFullYear(), now.getMonth() - 1, 1);
        }
        return expiry
    }

    const addNull = array => {
        let count = array[0].dow - 1
        let arr = []
        for (let i = 0; i < count; i++) {
            arr.push('+')
        }
        return arr
    }

    useEffect(() => {
        setList([temp])
        setCalendar([false, []])
    }, [type])

    useEffect(() => {
        if (props.fix_data && JSON.stringify(props.fix_data) !== JSON.stringify(list)) {
            let arr = editFilter(props.fix_data)
            setRules(arr)
            /* if(arr.length > 0){
                if(arr[0].loop_type < 8){settype(1)} else if(arr[0].loop_type === 8 ){settype(2)} else {settype(3)}
                 for(let i = 0; i < arr.length; i++){
                     delete arr[i].id
                     arr[i].date_from = timeConverter(now, 'today')
                     if(arr[i].loop_type < 8) arr[i].period = (arr[i].period + 1) / 7
                     if(arr[i].date_until === null){
                         delete arr[i].date_until
                     }
                 }
             setlist(arr)
            } */
        } else {
            setList([temp])
        }

        const apiConf = {
            method: 'get',
            link: 'employees/rule_templates'
        }
        superAxiosRequest(apiConf).then(res => {
            setTemplateOptions(res.data.result)
        })
    }, [])

    useEffect(() => {
        let qJson = {
            //type : type,
            //contract_id: contract,
            employee_id: props.modal_settings.profile_id,
            list: list
        }
        if (isTemplate && templateType === 1) {
            qJson.is_template = true;
            qJson.template_name = templateName
        }

        switch (type) {
            case 1:
                let tmp = []
                for (let i = 0; i < qJson.list.length; i++) {
                    if (qJson.list[i].loop_type < 8) {
                        let x = {
                            date_from: qJson.list[i].date_from,
                            period: (qJson.list[i].period * 7) - 1,
                            count: qJson.list[i].count,
                            loop_type: qJson.list[i].loop_type,
                            lunch: qJson.list[i].lunch,
                            start_at: qJson.list[i].start_at,
                            finish_at: qJson.list[i].finish_at,
                        }
                        if (qJson.list[i].date_until) x.date_until = qJson.list[i].date_until
                        tmp.push(x)
                    } else {
                        let daysLong = ''
                        if (qJson.list[i].loop_type === 8) daysLong = 5
                        if (qJson.list[i].loop_type === 9) daysLong = 6
                        for (let y = 0; y < daysLong; y++) {
                            let x = {
                                date_from: qJson.list[i].date_from,
                                period: (qJson.list[i].period * 7) - 1,
                                count: qJson.list[i].count,
                                loop_type: y + 1,
                                lunch: qJson.list[i].lunch,
                                start_at: qJson.list[i].start_at,
                                finish_at: qJson.list[i].finish_at,
                            }
                            if (qJson.list[i].date_until) x.date_until = qJson.list[i].date_until
                            tmp.push(x)
                        }
                    }
                }
                qJson.list = tmp
                break;
            case 2:
                for (let i = 0; i < qJson.list.length; i++) {
                    qJson.list[i].loop_type = 8
                }
                break;
            case 3:
                for (let i = 0; i < qJson.list.length; i++) {
                    qJson.list[i].loop_type = 9
                }
                break;
            default:
                break;
        }
        let forCalendar = {
            rules: qJson.list
        }
        if (qJson.list.length > 0) setCalendar([true, createWorkDays(date, forCalendar, true)])
        props.arrayChangeHandler(qJson)
    }, [list, type, date, isTemplate, templateName])

    return (
        <div className="workSchedule-box">
         {/*    <div className="workSchedule-box__label">Выберите контракт:</div>
            <SelectCustom
                placeholder="Выберите контракт"
                apiLink="employees/contract"
                //apiGetResult={item.key_name ? data => data[item.key_name].items : null}
                valueID={contract}
                apiConfParams={{ employee: props.modal_settings.profile_id }}
                optionLabelKey="title"
                onChange={o => setContract(o.id)}
                isSearchable
            /> */}
            <div className="workSchedule-box__label">Тип рабочего графика:</div>
            <MainSelector image={someImg}>
                <div className='workSchedule-box__ms-box'>
                    <div className="workSchedule-box__ms-box--row-line">
                        <TypeSelector values={['Односменный', 'Сменный', 'Скользящий']}
                            objectChangeHandler={setType}
                            selected={type}
                            name='loop_type' />
                    </div>

                    {type === 1 && <p><b>Односменный график</b> применяется при 5-и или 6-и днейвной рабочей неделе.</p>}
                    {type === 2 &&
                        <p><b>Сменный график</b> работы предполагает, что человек работает в разное время в разные дни.</p>}
                    {type === 3 &&
                        <p><b>Скользящий или свободный график работы</b> – это такой график, при котором работник отработал
                            оговоренное количество рабочего времени</p>}

                    <p><span className="workSchedule-box__ms-box--info">Подробнее о графиках работы</span></p>
                </div>
            </MainSelector>

            <div className="workSchedule-box__labelCheck">
                <div className="workSchedule-box__label">Шаблоны:</div>
                <CheckboxToggle checked={isTemplate} onChange={() => {
                    setIsTemplate(!isTemplate);
                    setTemplateType(0)
                }} />
            </div>
            {isTemplate &&
                <MainSelector image={someImg}>
                    <div className="workSchedule-box__ms-box workSchedule-box__ms-box--templates">
                        <div>
                            <TypeSelector
                                like_obj
                                values={templateTypes}
                                selected={templateType}
                                objectChangeHandler={setTemplateType}
                            />
                        </div>
                        <div>
                            {templateType === 0 ?
                                <SelectCustom placeholder="Выберите шаблон"
                                    options={templateOptions}
                                    valueID={templateId}
                                    onChange={o => {
                                        setTemplateId(o.id)
                                        setRules(o.list)
                                    }}
                                />
                                :
                                <input onChange={e => setTemplateName(e.target.value)} value={templateName}
                                    disabled={!isTemplate} style={{ marginBottom: '0' }} />

                            }
                        </div>
                    </div>
                </MainSelector>
            }

            {list.map((item, index) =>
                <div className='workSchedule-box__card' key={index}>
                    {index > 0 &&
                        <div className="workSchedule-box__cardCloseButton" onClick={() => deleteFromList(index)} />}

                    {type === 1 &&
                        <OneShift updateList={list => updateList(index, list)}
                            values={item}
                            edit={props.modal_settings.edit} />
                    }
                    {type === 2 && <DayByDay updateList={list => updateList(index, list)} values={item} />}
                    {type === 3 && <SlideSchedule updateList={list => updateList(index, list)} values={item} />}
                </div>
            )}

            <div style={{ marginTop: '20px' }} className="modal-question-add-button-add"
                onClick={() => addCard()}> +
            </div>

            <div>
                {calendar[0] &&
                    <>
                        <div className={'modalClientInfo__acHead' + (!calOpen ? ' modalClientInfo__acHead--open' : '')}
                            onClick={() => setCalOpen(!calOpen)}>
                            <span>{!calOpen ? 'Скрыть' : 'Показать'} итоговый график работы</span>
                        </div>
                        <div className={'wt-calendar-box' + (!calOpen ? ' wt-calendar-box--active' : '')}>
                            <div
                                className={"employeeTabSchedule__nav " + (calOpen ? 'employeeTabSchedule__nav--open' : '')}>
                                <div className="employeeTabSchedule__prev" onClick={() => {
                                    setDate(createDate(date, -1))
                                }}>
                                    <Arrow />
                                    <span>{timeConverter(createDate(date, -1), 'MMYY')}</span>
                                </div>
                                <div className="employeeTabSchedule__currentMonth">
                                    <span>{timeConverter(date, 'MMYY')}</span>
                                    <div><DatePicker
                                        selected={date}
                                        locale="ru"
                                        onChange={date => setDate(date)}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        customInput={<CalendarIcon />}
                                    />
                                    </div>
                                </div>
                                <div className="employeeTabSchedule__next" onClick={() => {
                                    setDate(createDate(date, +1))
                                }}>
                                    <span>{timeConverter(createDate(date, +1), 'MMYY')}</span>
                                    <Arrow />
                                </div>
                            </div>

                            <div className="wt-calendar">
                                {addNull(calendar[1]).map((item, index) => {
                                    return (
                                        <div className={"wt-calendar__day wt-calendar__day--transparent"} key={index}>

                                        </div>
                                    )
                                })}
                                {calendar[1].map((item, index) => {
                                    return (
                                        <div className={"wt-calendar__day " + (item.type === 1 ? 'wt-calendar__green' : '')}
                                            key={index}>
                                            <span>{item.dm} ({timeConverter(item.date, 'dow')})</span>
                                            <span>{item.time}</span>
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </div>
                    </>
                }
            </div>
        </div>
    )
}
