import React from "react";
import { localisationMaster } from "Localisation/Localisation";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";

const Letter = ({ form, setForm, letterRef, validate }) => {
  return (
    <div className="placementTestBox" ref={letterRef}>
      <div className="placementTestCreate__letter">
        <div className="placementTestBox__mainBoxLine">
          <p className="placementTestBox__title">
            {localisationMaster(
              "text_30",
              "c/tests/placement-test/create-widget"
            )}
          </p>
        </div>
        {form.is_data_collect
          ? <div className="placementTestBox__mainBoxLine">
            <p className="super-modal__form-label">
              Уведомление клиента о сборе данных
            </p>
            <SelectCustom
              placeholder=""
              apiLink="mailings/templates"
              valueID={form.data_collect_email}
              apiConfParams={{ sub_type: 1 }}
              onChange={(o) => setForm({ ...form, data_collect_email: o.id })}
              error={validate("data_collect_email")}
              optionLabelKey="title"
            />
          </div>
          : <>
            <div className="placementTestBox__mainBoxLine">
              <p className="super-modal__form-label">
                {localisationMaster("text_31", "c/tests/placement-test/create-widget")}:
              </p>
              <SelectCustom
                placeholder=""
                apiLink="mailings/templates"
                valueID={form.finished_email}
                apiConfParams={{ sub_type: 1 }}
                onChange={(o) => setForm({ ...form, finished_email: o.id })}
                error={validate("finished_email")}
                optionLabelKey="title"
              />
            </div>
            <div className="placementTestBox__mainBoxLine">
              <p className="super-modal__form-label">
                {localisationMaster("text_32", "c/tests/placement-test/create-widget")}:
              </p>
              <SelectCustom
                placeholder=""
                apiLink="mailings/templates"
                valueID={form.timeout_email}
                apiConfParams={{ sub_type: 1 }}
                onChange={(o) => setForm({ ...form, timeout_email: o.id })}
                error={validate("timeout_email")}
                optionLabelKey="title"
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Letter;
