import React, {useEffect, useRef, useState} from 'react';
import styles from "./FileUploader.module.scss"
import {superAxiosRequest} from 'axiosApi';
import fileAdd from "components/ContentBox/img/file-add-line.svg"


const FileUploader = ({onChange, params, data}) => {
    const refInput = useRef()
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    const onUploadEnd = path => {
        onChange(path, file.name)
    }

    const checkExtension = (extension) => {
        const extensionsArray = [
            'image/gif',
            'application/pdf',
            'image/jpeg',
            'image/png'
        ]
        return extensionsArray.includes(extension)
    }

    useEffect(() => {
        if (file) {
            if (!checkExtension(refInput.current.files[0].type)) {
                setError('Неверный формат файла')
                return
            }
            const formData = new FormData()
            formData.append(
                'file', file
            )
            formData.append(
                'user', data.user
            )
            formData.append(
                'name', refInput.current.files[0].name
            )
            const apiConf = {
                method: 'post',
                link: 'employees/file_documents',
                params: params
            }

            superAxiosRequest(apiConf, formData, 'media').then(res => {
                onUploadEnd(res.data)
            }).catch(() => {setError('Ошибка сервера')})
        }
    }, [file])



    return (
        <div className={styles.box}>
            <label  >
                <input style={{display: 'none'}} type="file" ref={refInput} onChange={e => {
                    setError(false)
                    setFile(e.target.files[0])
                }} />
                <img src={fileAdd} alt=""/>
            </label>
            {error && <div className = {styles.error}>{error}</div>}
        </div>

    )
}

export default FileUploader

