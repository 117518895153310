import React from "react";
import Stores from "Stores"

export const formatSalaryOptionLabel = (opt, {context}) => {
    const {cleanSumm} = Stores

    const formatSalary = arr => arr.length > 1 ? `${cleanSumm(arr[0])} - ${cleanSumm(arr[arr.length - 1])}` : cleanSumm(arr[0])

    const str = context === 'menu' ?
        `<b>${opt.name}</b><br />
            Онлайн: ${formatSalary(opt.academ_hour.rate_online)} тг. / ак.ч.<br />
            Офлайн: ${formatSalary(opt.academ_hour.rate)} тг. / ак.ч.<br />
            Онлайн: ${formatSalary(opt.astro_hour.rate_online)} тг. / астр.ч.<br />
            Офлайн: ${formatSalary(opt.astro_hour.rate)} тг. / астр.ч.<br />
            `
        :
        opt.name

    return <span dangerouslySetInnerHTML={{__html: str}}/>
}
