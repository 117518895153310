import {localisationMaster} from "Localisation/Localisation";
import {addZero} from "helpers/math";
import { translate as loc, dict } from "Localisation";


export const createTimePart =(time, step)=>{
    let CurrentTime = new Date('01.01.2020 ' + time);
    CurrentTime.setMinutes(CurrentTime.getMinutes() + step);
    let minutes = CurrentTime.getMinutes();
    let hour = CurrentTime.getHours();
    if (+hour < 10) hour = '0' + hour
    if (minutes === 0) {
        minutes = '00'
    }
    return (hour + ":" + minutes);
}

export const createJobTime = () => {
    let array = [];
    let start = '00:00';
    let i;
    let inject;
    let last;
    for (i = 0; i < 48; i++) {
        if (i === 0) {
            last = start;
            inject = {time: last};
        } else {
            last = createTimePart(last, 30)
            inject = {time: last};
        }
        array.push(
            inject
        )
        //last = last;
    }
    return array;
}

//CARDS
export const get_current_age = (date) => {
    return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0;
}

export const agetostr = (dob_in) => {
    if (dob_in) {
        let dob = new Date(dob_in);
        let age = get_current_age(dob);
        var txt;
        let count = age % 100;
        if (count >= 5 && count <= 20) {
            txt = localisationMaster('text-6', 'online-schedule/future-slots');
        } else {
            count = count % 10;
            if (count === 1) {
                txt = localisationMaster('text-4', 'online-schedule/future-slots');
            } else if (count >= 2 && count <= 4) {
                txt = localisationMaster('text-5', 'online-schedule/future-slots');
            } else {
                txt = localisationMaster('text-6', 'online-schedule/future-slots');
            }
        }
        return age + " " + txt;
    } else {
        return 'Invalid'
    }
}

//CARDS
// @deprecated 2021-08-16
/*export const timeConverterX = (UNIX_timestamp, type) => {
    var a = new Date(UNIX_timestamp);
    var months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    var month = months[a.getMonth()];
    var date = a.getDate();
    var year = a.getFullYear();
    if (date < 10) {
        date = '0' + date;
        var time = date + ' ' + month;
        if (type === 'age') {
            time = date + ' ' + month + ' ' + year + 'г.'
        }
        return time;
    }
}*/

export const parcedSec = (seconds) => {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600*24));
    let h = Math.floor(seconds % (3600*24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d === 1 ? ` ${localisationMaster('d', 'study/test')} ` : ` ${localisationMaster('d', 'study/test')}, `) : "";
    let hDisplay = h > 0 ? h + (h === 1 ? ` ${localisationMaster('ch', 'study/test')} ` : ` ${localisationMaster('ch', 'study/test')} `) : "";
    let mDisplay = m > 0 ? m + (m === 1 ? ` ${localisationMaster('min', 'study/test')} ` : ` ${localisationMaster('min', 'study/test')} `) : "";
    let sDisplay = s > 0 ? s + (s === 1 ? ` ${localisationMaster('sec', 'pays/invoice')}.` : ` ${localisationMaster('sec', 'pays/invoice')}.`) : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

const getLocalTime = (date) => {
    const time_zone = JSON.parse(localStorage.getItem('ud')).user_data.profile.time_zone
    const localTime = new Date(date - (-time_zone * 3600000)) 
   
    return localTime
}


export const timeConverter = (UNIX_timestamp, short) => {
    let Link_1 = 'months'
    let Link_2 = 'month'
    if (UNIX_timestamp) {
        var a = new Date(UNIX_timestamp)
        if (!UNIX_timestamp.toString().includes(' ')) a = new Date(UNIX_timestamp.replace('T', ' ').split('.')[0]);
        var months = [localisationMaster('months_1', Link_1),
        localisationMaster('months_2', Link_1),
        localisationMaster('months_3', Link_1),
        localisationMaster('months_4', Link_1),
        localisationMaster('months_5', Link_1),
        localisationMaster('months_6', Link_1),
        localisationMaster('months_7', Link_1),
        localisationMaster('months_8', Link_1),
        localisationMaster('months_9', Link_1),
        localisationMaster('months_10', Link_1),
        localisationMaster('months_11', Link_1),
        localisationMaster('months_12', Link_1)];
        var months_ = [localisationMaster('month_1', Link_2),
        localisationMaster('month_2', Link_2),
        localisationMaster('month_3', Link_2),
        localisationMaster('month_4', Link_2),
        localisationMaster('month_5', Link_2),
        localisationMaster('month_6', Link_2),
        localisationMaster('month_7', Link_2),
        localisationMaster('month_8', Link_2),
        localisationMaster('month_9', Link_2),
        localisationMaster('month_10', Link_2),
        localisationMaster('month_11', Link_2),
        localisationMaster('month_12', Link_2)];
        let in_months = [loc(dict.in_month.months_1),
        loc(dict.in_month.months_2),
        loc(dict.in_month.months_3),
        loc(dict.in_month.months_4),
        loc(dict.in_month.months_5),
        loc(dict.in_month.months_6),
        loc(dict.in_month.months_7),
        loc(dict.in_month.months_8),
        loc(dict.in_month.months_9),
        loc(dict.in_month.months_10),
        loc(dict.in_month.months_11),
        loc(dict.in_month.months_12)];
        var months_shrt = ['01','02','03','04','05','06','07','08','09','10','11','12'];
    var days = ['пн','вт','ср','чт','пт','сб','вс']
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var month_shrt = months_shrt[a.getMonth()];
    var month_ = months_[a.getMonth()];
    let in_month = in_months[a.getMonth()];
    var hourse = a.getHours();
    var min = a.getMinutes();
    var date = a.getDate();
    var day_s = a.getDay()
    if(day_s === 0) day_s = 7
    var day = days[day_s -1 ]
    if(+date < 10) date = '0' + date
    if(+hourse < 10) hourse = '0' + hourse
    if(+min < 10) min = '0' + min
    //var time = date + ' ' + month + ', ' + year;
        if(short === 'ddd'){
            return date + ' ' + month + ' ('+ day +')'
        }
        if(short === 'day'){
            return date + ' ' + month;
        }
        if (short === 'dow') {
            return day;
        }
        if (short === 'today') {
            return year + '-' + month_shrt + '-' + date;
        }
        if (short === 'age') {
            return date + ' ' + month + ' ' + year + 'г.'
        }
        if (short === 'clean_data') {
            return date + ' ' + month + ' ' + year
        }
        if (short === 'in_month') {
            return in_month;
        }
        if (short === 'month') {
            return month_;
        }
        if (short === 'month_year') {
            return month_ + ' ' + year;
        }
        if(short === 'time_slots'){
            return year + '-' + month_shrt + '-' + date + ' ' + hourse + ':' + min
        }
        if(short === 'date_time'){
            return date + ' ' + month + ' ' + year + ' ' + hourse + ':' + min
        }
        if(short === 'time'){
            return hourse + ':' + min
        }
        if (short === 'one') {
            return date
        }
        if (short === 'MMYY') {
            return month + ', ' + year
        }
        if (short === 'MMYY_') {
            return month_ + ', ' + year
        }
        if(short === 'day_month') {
            return date + ' ' + month
        }
        if (short === 'dd.mm.yyyy') return date + '.' + month_shrt + '.' + year
        if (short === 'notifications') {
            const now = new Date() 
            const notificationDate = new Date( UNIX_timestamp) 
            const localTime = getLocalTime(new Date(UNIX_timestamp.slice(0, 19)))
            const passedTime = new Date(new Date('2020 00:00:00').setMilliseconds(( (now -  notificationDate) > 0? now -  notificationDate : 0)))
            const hours = passedTime.getHours();
            const minutes = passedTime.getMinutes();
            const isNotToday = now.getDate() !== notificationDate.getDate()
            
            return hours > 12 || isNotToday ? `${addZero(localTime.getDate())}.${month_shrt}, ${ localTime.getHours()}:${min}` :
                   hours <= 12 && hours >= 1 ? `${loc(dict.notifications.today)}, ${ localTime.getHours()}:${min}` : 
                   minutes >= 5 ? `${minutes} минут` : 
                   minutes < 5  ? 'только что' : '';
        }
    } else {
        return 'Invalid'
    }

}

export const timeConverter2 = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp);
    let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return year + '-' + month + '-' + date;
  }

export const timeConverter3 = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return year + '-' + month + '-' + date;
}

export const getTimeStr = (start = '', end = '') => {
    const dateStart = new Date(start).getTime() / 1000;
    const dateEnd = new Date(end).getTime() / 1000;
    const secs = Math.abs(Math.floor(dateStart - dateEnd));

    const sec_num = parseInt(secs.toString(), 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    
    const times = [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))

    const timesText = {
        hour: `${times[0]} ${localisationMaster('hour', 'pays/invoice')}.`,
        minute: `${times[1]} ${localisationMaster('mins', 'groups/group')}.`,
        second: `${times[2]} ${localisationMaster('sec', 'pays/invoice')}.`,
    };

    return `
        ${times[0] === '00' ? '' : timesText.hour} 
        ${times[1] === '00' ? '' : timesText.minute} 
        ${timesText.second}
    `;
};