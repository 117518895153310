import {autorun, toJS} from "mobx";
import {superAxiosRequest} from "axiosApi";
import Stores from "Stores";
import cogoToast from "cogo-toast";

class LessonStore {
    withoutStudent = {id: 0, last_name: 'Без', first_name: 'ученика'}
    initLesson = {}
    lesson = {}
    section = {}
    answerListProxy = []
    activeSection = 1
    lessonStudents = []
    isSectionLoading = false
    focused = 0

    constructor() {
        autorun(() => {
            if (this.initLesson.uuid && this.isTeacher)
                this.fetchLessonStudents()
        })
    }

    get isTeacher() {
        return Stores.Rules.checkRule2('lessons', 'initlessonforstudents', 'add_init_lesson')
    }

    get exerciseList(){
        return toJS(this.section) || []
    }
    get answerList(){
        return toJS(this.answerListProxy) || []
    }

    fetchLesson = uuid => {
        const apiConf = {
            method: 'get',
            link: 'lessons/init_lesson/' + uuid
        }
        superAxiosRequest(apiConf).then(res => {
            this.initLesson = res.data
            this.lesson = this.initLesson.lesson
        }).catch(error => {
            const {response} = error;
            if (response?.status === 403){
                cogoToast.error(response.data.detail, {heading: 'Просмотр инициализированного урока'})
            }
        })
    }

    addAnswers = (data) => {
        const list = [...this.answerList]
        const index = list.findIndex(item => item.assign_exercise === data.assign_exercise && item.student === data.student);
        if(index > -1) {
            list[index] = data
        } else {
            list.push({...data})
        }
        this.answerListProxy = list
    }

    setAnswerList = (uuid) => {
        const apiConf = {
            method: 'get',
            link: 'lessons/answ_exrs',
            params: {
                init_lesson: uuid
            }
          }
          superAxiosRequest(apiConf).then(res => {
            this.answerListProxy = res.data.result
          })
    }

    fetchLessonStudents = () => {
        // Свободные студенты
        const apiConf = {
            method: 'get',
            link: 'lessons/get_free_students/' + this.initLesson.uuid,
            params: {
                all: true,
            }
        }
        superAxiosRequest(apiConf).then(res => {
            this.lessonStudents = [this.withoutStudent].concat(res.data.result)
        })
    }

    addExersize = (data) => {
        const { profileId } = Stores;
        if(data.message.students.includes(profileId)){
            const a = toJS(this.section)
            let ex = {...data.message.exercise, assign_id: data.message.assign_id}
            a.push({...ex})
            this.section = a;
        }
    }

    deleteExercise = (id) => {
        this.section = toJS(this.section).filter(x => x.id !== id)
    }

    setFocused = (data) => {
        this.focused = data.message.exersice
    }

    dropFocused = () => {
        this.focused = 0
    }

    fetchSection = id => {
        this.isSectionLoading = true
        const apiConf = {
            method: 'get'
        }
        if (this.isTeacher)
            apiConf.link = 'lessons/lesson_section/' + id
        else {
            apiConf.link = 'lessons/get_assign_exercises/' + this.initLesson.uuid
           /*  apiConf.params = {section_id: id} */
        }

        superAxiosRequest(apiConf).then(res => {
            this.section = Stores.isTeacher ? res.data.exercises : res.data
            this.isSectionLoading = false
        })
    }

    setActiveSection = (direction, num) => {
        if (num) this.activeSection = num
        if (direction === 'prev')
            this.activeSection = Math.max(this.activeSection - 1, 1)
        if (direction === 'next')
            this.activeSection = Math.min(this.activeSection + 1, this.lesson.section_list.length)
    }
}

/*LessonStore = decorate(LessonStore, {
    initLesson: observable,
    lesson: observable,
    isSectionLoading: observable,
    section: observable,
    activeSection: observable,
    lessonStudents: observable,
    isTeacher: computed,
    exerciseList: computed,
    fetchLesson: action,
    fetchSection: action,
    setActiveSection: action,
    addExersize: action,
    deleteExercise: action,
    answerListProxy: observable,
    setAnswerList: action,
    answerList: computed,
    addAnswers: action,
    focused: observable,
    setFocused: action,
})*/

export default new LessonStore()
