import React, {useEffect, useState} from 'react';
import FilterInput from 'components/ContentBox/Global/Filter/FilterInput';
import {localisationMaster} from 'Localisation/Localisation';
//import {superAxiosRequest, superAxiosRequestEasy} from "axiosApi";
import SelectDateBirth from "components/ContentBox/Global/SelectDateBirth/SelectDateBirth";
import InputPhone from "components/ContentBox/Global/InputPhone/InputPhone";
import InputInn from "components/ContentBox/Global/InputInn/InputInn";
import "./FieldsetCustomer.scss"

const FieldsetCustomer= React.forwardRef((props,ref) =>{

    const
        editMode = props.editMode,
        //BigTrouble = [false, []],
        link = 'online-schedule/record-customer-error',
        emptyUser = {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            email: '',
            phone: '',
            //age_category: 0,
            //program: 0,
            level: ''
        }

    const
        //[ageCategories, setAgeCategories] = useState([]),
        //[programs, setPrograms] = useState([]),
        [user, setUser] = useState(emptyUser),
        [aError, setErrors] = useState([]),
        [isCreatedUser, setIsCreatedUser] = useState(false),
        [BigTrouble, setBigTrouble] = useState([false,[]])


    // Добавление клиента из базы
    const pushClientData = item => {
        let {profile} = item
        setUser({
            ...user,
            first_name: profile.first_name,
            last_name: profile.last_name,
            father_name: profile.father_name,
            email: profile.email,
            phone: profile.phone,
            user_id: item.id,
            level: '',
            date_of_birth: profile.date_of_birth,
            //age_category: 0,
            //program: 0
        })
        setIsCreatedUser(true)
    }

    // Обработчик изменения полей
    const changeUserHandler = (val, name) => {
        if (name === 'age_category' || name === 'program')
            val = +val

        setUser({...user, [name]: val})
    }

    // Проверка ошибок
    const validate = ()=>{
        let aError = [];

        // Имя
        if (user.first_name.length < 2)
            aError.push({
                field: localisationMaster('text-2', link),
                desc: localisationMaster('text-3', link)
            })

        // Фамилия
        if (user.last_name.length < 2)
            aError.push({
                field: localisationMaster('text-4', link),
                desc: localisationMaster('text-5', link)
            })

        // Email
        if (user.email.length < 5 || !user.email.includes('@'))
            aError.push({
                field: 'Email',
                desc: localisationMaster('text-6', link)
            })

        // Телефон
        if (user.phone.length < 7 || user.phone.length > 15)
            aError.push({
                field: localisationMaster('text-7', link),
                desc: localisationMaster('text-8', link)
            })

        // Уровень
        if (user.level.length < 2)
            aError.push({
                field: localisationMaster('text-9', link),
                desc: localisationMaster('text-10', link)
            })

        // Дата рождения
        if (!checkDateOfBirth(user.date_of_birth))
            aError.push({
                field: localisationMaster('text-11', link),
                desc: localisationMaster('text-12', link)
            })

        // Возрастная категория
       /* if (user.age_category === 0)
            aError.push({
                field: localisationMaster('text-13', link),
                desc: localisationMaster('text-14', link)
            })

        // Программа обучения
        if (user.program === 0)
            aError.push({
                field: localisationMaster('text-15', link),
                desc: localisationMaster('text-16', link)
            })
        */
        setErrors(aError)
        
    }

    const checkDateOfBirth = str=>{
        if(str !== null){
        let date = new Date(str),
            [y, m, d] = str.split('-')

        return (parseInt(y) && parseInt(y)===date.getFullYear() && parseInt(m)===date.getMonth()+1 && parseInt(d)===date.getDate())
        } else {
            return ''
        }
    }

    // Загрузка возрастных категорий и Программ
    useEffect(() => {
        /*let apiConf;

        if (ageCategories.length === 0) {
            apiConf = {
                method: 'get',
                link: 'db/age',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            if(!props.widget){
            superAxiosRequest(apiConf, 'data').then(response=>setAgeCategories(response.data.result));
            } else {
            superAxiosRequestEasy(apiConf, 'data').then(response=>setAgeCategories(response.data.result));
            }
        }

        if (programs.length === 0) {
            apiConf = {
                method: 'get',
                link: 'db/programs',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            if(!props.widget){
            superAxiosRequest(apiConf, 'data').then(response=>setPrograms(response.data.result));
            } else {
            superAxiosRequestEasy(apiConf, 'data').then(response=>setPrograms(response.data.result));
            }

        }*/
    }, []);

    useEffect(()=>{
        let formatUser = {
            student_data: {
                first_name: user.first_name,
                last_name: user.last_name,
                date_of_birth: user.date_of_birth,
                email: user.email,
                phone: user.phone,
                inn: user.inn,
            },
            client_model_data: {
                program: props.client_model_data.program,
                age: props.client_model_data.age,
                result: user.level,
            }
        }

        if (user.father_name)
            formatUser["student_data"]["father_name"] = user.father_name
        if (user.user_id)
            formatUser["student_data"]["user_id"] = user.user_id


        props.onChangeUser(formatUser)

        validate()

    }, [user])

    useEffect(() => {
        if(BigTrouble[1] !== props.BigTrouble && props.BigTrouble.length > 0){
            setBigTrouble([true, props.BigTrouble])
        }
    }, [props.BigTrouble])

    useEffect(()=>{
        setUser({
            ...user,
            first_name: props.student_data.first_name,
            last_name: props.student_data.last_name,
            father_name: props.student_data.father_name,
            email: props.student_data.email,
            phone: props.student_data.phone,
            date_of_birth: props.student_data.date_of_birth,

            level: props.client_model_data.result,
            age_category: props.client_model_data.age,
            program: props.client_model_data.program
        })
    }, [props.student_data, props.client_model_data])


    return (
        <div className="record-customer-fieldset-container" ref={ref}>

            {!props.widget &&
            <div className="fieldsetCustomer__title">
                {props.notate && !props.notate[0] ?
                    <span>{localisationMaster('title-1', 'online-schedule/record-customer')}</span>
                    : props.createWU && props.createWU[0] ?
                        <>
                            <span>Запись клиента: </span>
                            <span style={{fontWeight: '400'}}>
                                {`${props.notate[1][1]} ${props.notate[1][0]} (${props.notate[1][2]}, ${props.notate[1][3]})`}
                            </span>
                        </>
                        :
                        <>
                            <span>{localisationMaster('title-2', 'online-schedule/record-customer')}</span>
                            <span style={{fontWeight: '400'}}>
                                    {`${props.notate[1][1]} ${props.notate[1][0]} (${props.notate[1][2]}, ${props.notate[1][3]}`}
                            </span>
                        </>
                }
            </div>}

            {props.title &&
            <div className="fieldsetCustomer__title"><span>{props.title}</span></div>}

            {!props.widget && !editMode[0] && !props.notate[0] &&
                <div>
                    <div className="container-search-input">
                        <FilterInput link='accounts/clients' clientId={user=>pushClientData(user)} placeholder={localisationMaster('placeholder-1', 'online-schedule/record-customer')}/>
                    </div>

                    <p className="fieldsetCustomer__noClientMessage">Если клиента нет в базе, то необходимо внести его данные:</p>
                </div>
            }

            <div className="fieldsetCustomer__cols">

                <div className="fieldsetCustomer__col">

                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__label">{localisationMaster('placeholder-2', 'online-schedule/record-customer')}:</p>
                        <input
                            placeholder= {localisationMaster('placeholder-2', 'online-schedule/record-customer')}
                            value={user.first_name}
                            onChange={e => changeUserHandler(e.target.value, 'first_name')}
                            className="fieldsetCustomer__input"
                        />
                    </div>

                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__label">{localisationMaster('placeholder-5', 'online-schedule/record-customer')}:</p>
                        <input
                            placeholder={localisationMaster('placeholder-5', 'online-schedule/record-customer')}
                            value={user.last_name}
                            onChange={e => changeUserHandler(e.target.value, 'last_name')}
                            className="fieldsetCustomer__input"
                        />
                    </div>

                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__noReqLabel">{localisationMaster('placeholder-8', 'online-schedule/record-customer')}:</p>
                        <input
                            placeholder={localisationMaster('placeholder-8', 'online-schedule/record-customer')}
                            value={user.father_name || ''}
                            onChange={e => changeUserHandler(e.target.value, 'father_name')}
                            className="fieldsetCustomer__input"
                        />
                    </div>

                </div>

                <div className="fieldsetCustomer__col">

                    <div className="fieldsetCustomer__field">
                    <p className="fieldsetCustomer__label">{localisationMaster('placeholder-3', 'online-schedule/record-customer')}:</p>
                        <input
                            placeholder={localisationMaster('placeholder-3', 'online-schedule/record-customer')}
                            value={user.email}
                            disabled={editMode[0] || isCreatedUser}
                            onChange={e => changeUserHandler(e.target.value.toLowerCase(), 'email')}
                            className="fieldsetCustomer__input"
                        />
                    </div>

                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__label">Телефон:</p>
                        <InputPhone value={user.phone} onChange={val=>changeUserHandler(val, 'phone')} />
                    </div>
                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__noReqLabel">ИНН:</p>
                        <InputInn value={user.inn} onChange={val=>changeUserHandler(val, 'inn')} />
                    </div>

                   
                </div>

                <div className="fieldsetCustomer__col">
                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__label">{localisationMaster('placeholder-9', 'online-schedule/record-customer')}:</p>
                        <input
                            placeholder={localisationMaster('placeholder-9', 'online-schedule/record-customer')}
                            value={user.level}
                            onChange={e => changeUserHandler(e.target.value, 'level')}
                            className="fieldsetCustomer__input"
                        />
                    </div>

                    <div className="fieldsetCustomer__field">
                        <p className="fieldsetCustomer__label">Дата рождения:</p>
                        <SelectDateBirth value={user.date_of_birth} onChange={sDate=>{changeUserHandler(sDate, 'date_of_birth')}} />
                    </div>
                    {/*props.widget &&
                    <>
                    <div className="fieldsetCustomer__field fieldsetCustomer__field--required">
                        <select value={user.age_category} onChange={e => {changeUserHandler(e.target.value, 'age_category')}} className="fieldsetCustomer__select">
                            <option value='0' disabled> {localisationMaster('placeholder-12', 'online-schedule/record-customer')}</option>
                            {ageCategories.length > 0 ?
                                ageCategories.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                                : null
                            }
                        </select>
                    </div>

                    <div className="fieldsetCustomer__field fieldsetCustomer__field--required">
                        <select value={user.program} onChange={e => changeUserHandler(e.target.value, 'program')} className="fieldsetCustomer__select">
                            <option value='0' disabled>{localisationMaster('placeholder-10', 'online-schedule/record-customer')}</option>
                            {programs.length > 0 ?
                                programs.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                                : null
                            }
                        </select>
                    </div>
                    </>
                        */}
                </div>

            </div>

            {aError.length > 0 &&
            <div className="fieldsetCustomer__errors">
                {aError.map((error, index)=>{
                    return (
                        <div key={index}><b>{error.field}</b> <span>{error.desc}</span></div>
                    )
                })}
            </div>
            }
             {BigTrouble[0] ?
                    <div className="fieldsetCustomer__errors">
                        <div><b>{localisationMaster('text-1', link)}</b> <p>{BigTrouble[1]}</p></div>
                    </div>
                    : null
                }

        </div>
    )
})
export default FieldsetCustomer
