import React from 'react';
import {timeConverter} from '../components/functions'
import Stores from 'Stores';
import './SlotResult.scss';

import Person from '../../img/person-female.svg';
import Person_mail from '../../img/person-male.svg';
import Test from 'components/ContentBox/img/test.svg';
import Comment from 'components/ContentBox/img/comment.svg';
import { NavLink } from 'react-router-dom';


export default function SlotResult(props) {

    const levels = data =>{
        if(data){
        let array = data.split('/')
        let newArray = {
            Lite: '',
            Smart: '',
            Pro: ''
        }
        var pat = /\([^()]*\)/;
        for(let i=0; i < array.length; i++){
            if(array[i].includes('Lite')){
                newArray.Lite = array[i].replace(pat, '')
            }
            if(array[i].includes('Smart')){
                newArray.Smart = array[i].replace(pat, '')
            }
            if(array[i].includes('Pro')){
                newArray.Pro = array[i].replace(pat, '')
            }
        }
        array = [];
        for(let key in newArray){
            array.push(
                {name: [key], value:  newArray[key]}
            )
        }
        return array
    } else {
        return []
    }
    }

    return (
        <div className="slot-result">

            <div className="slot-result__teacher">
                <img src={props.result.teacher_sex === 'F' ? Person : Person_mail} alt="female"/>
                <div>
                    <NavLink    to={`/account/${props.result.teacher_slot.teacher_id}/`} 
                                target="_blank" 
                                className="slot-result__teacherLink">
                                {Stores.transliterate(props.result.teacher_fullname)}
                            </NavLink>
                    <span>
                        {props.result.teacher_slot.slot.time_start.slice(0,5)}, {timeConverter(props.result.teacher_slot.s_date, 'age')}
                    </span>
                </div>
            </div>

            <div className="slot-result__level">
                <img src={Test} alt="female"/>
                <div className='slot-result__level-list'>
                {levels(props.result.level_name).map((item, index)=>{
                    return(
                    <span key={index}><strong>{item.name}:</strong> {item.value}</span>
                    )
                })}
                </div>
            </div>

            <div className="slot-result__comment">
                <img src={Comment} alt="female"/>
                <span>{props.result.comment === null ? "Комментария нет": props.result.comment}</span>
            </div>

        </div>
    )
}
