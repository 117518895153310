import React from 'react';
import style from "./TestResult.module.scss";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import {NavLink} from "react-router-dom";
import {EditIconButton} from "components/UI/SuperButton/SuperButton";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";

const TestResult = ({item: element, actions, listing, translate}) => {
    return (
        <div className={style.testResult} style={listing && {border: 'none'}}>
            <div className={style.box}>
                <div className={style.info}>
                    {element.student && element.student.last_name && element.student.first_name && (
                        <NavLink target="_blank" to={'/account_clients/' + element.student.user_id}>
                            {Stores.transliterate(`${element.student.last_name} ${element.student.first_name}`)}
                        </NavLink>
                    )}

                </div>
                <div>
                    {element.data.map(item => <div>

                        <div className={style.medium}>

                            <div
                                className={style.date}>{dateFormat(new Date(item.date), 'd MMM YYYY')} {translate?.yr}</div>

                            <span>
                      {item.category}
                  </span>

                            <span>
                      {item.test}
                  </span>

                            <div className={style.score}>
                                <span><b>{translate?.general}:</b> {item.test_scores}/100 ({item.test_scores}%)</span>
                                <span><b>{translate?.total}:</b>{item.test_scores}%</span>
                            </div>

                            <div className={style.description} dangerouslySetInnerHTML={{__html: item.description}}/>
                            {
                                !Stores.isClient && <div className={style.buttons}>
                                    <div className={style.buttonsControl}>
                                        <EditIconButton onClick={(e) => actions('edit', e, item)}/>
                                        <div className={style.deleteBasket} onClick={(e) => actions('delete', e, item)}>
                                            <Delete/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>)}
                </div>
            </div>

        </div>
    );
};

export default TestResult;