import React from 'react';
import FormLabel from "../../../../../../../UI/AsideModal/Form/FormLabel";
import TextareaMarkup from "../../../../../../../UI/TextareaMarkup/TextareaMarkup";
import FormField from "../../../../../../../UI/AsideModal/Form/FormField";

const ExampleTextArea = ({value, onChange, stateName, maxLength, translate}) => {

    
    return (
        <FormField>
            <FormLabel>{translate?.example}:</FormLabel>
            
            <TextareaMarkup
                value={value}
                onChange={onChange}
                stateName={stateName}
                maxLength={maxLength}
            />
        </FormField>
    );
};

export default ExampleTextArea;