import {observable, action, makeObservable} from 'mobx';
import LocalStorage from "services/LocalStorage";

// удаляет прочитанные уведомления и сортирует по датам
const sortNotifications = (arr) => arr.filter(el => !el.is_read).sort((a, b) => new Date(b.date) - new Date(a.date));

const defaultValues = {
  connected: false,
  ws: null,
}

export const allWsTypes = {
  notifications: {
    name: 'notifications',
    message: 'notifications.mark_notification_read',
    url: 'notifications'
  },
  groups_notifications: {
    name: 'groups_notifications',
    message: 'group_notes.mark_notification_read',
    url: 'groups/notifications'
  },
  orders: {
    name: 'orders',
    message: 'orders_notes.mark_notification_read',
    url: 'orders/notifications'
  }
}

const getAllData = (allWsTypes, defaultValues) => {
  let allData = {}
  Object.keys(allWsTypes).forEach(key => allData = {...allData, [key]: {...defaultValues}})
  return allData
}

class HeadBoxStore {
  sound = false;
  isNotification = false;
  allData = getAllData(allWsTypes, defaultValues)
  allMessage = []

  constructor() {
    makeObservable(this, {
      allData: observable,
      allMessage: observable,
      sound: observable,
      isNotification: observable,

      sendMessage: action,
      setNotification: action,
      setMessages: action,
      deleteMessage: action,
      setSound: action,
    })
  }

  openConnect(wsData) {
    const baseLink = window.location.hostname !== 'localhost' ? window.location.hostname : 'dev.phoenix24.kz';
    const token = LocalStorage.getSimple('refresh');
    const ws = new WebSocket(`wss://${baseLink}/ws/${wsData.url}/?token=${token}`);

    ws.onopen = () => {
      this.allData = {
        ...this.allData,
        [wsData.name]: {
          ...this.allData[wsData.name],
          ws,
          connected: true,
        }
      }
    }

    ws.onerror = () => {
      setTimeout(() => this.openConnect(wsData), 3000);
    };

    ws.onmessage = e => {
      const data = JSON.parse(e.data || '[]');
      if (Array.isArray(data.message)) {
        this.setMessages([
          ...this.allMessage,
          ...sortNotifications(data.message.map(item => ({...item, wsType: wsData.name, messageMark: wsData.message})))
        ])
        return
      }
      if (data.type !== 'ok') {
        this.isNotification = true;
      }
      this.allMessage = sortNotifications(this.allMessage.filter(el => el.id !== data.message.id).concat({...data.message,wsType: wsData.name, messageMark: wsData.message }))
    }
  }

  sendMessage = (id, type) => {
    const message = this.allMessage.find(el => el.id === id);
    this.allData[type].ws.send(JSON.stringify({type: message.messageMark, message: message.id}));
  }

  setNotification = (status) => {
    this.isNotification = status;
  }

  setMessages = data => this.allMessage = data

  deleteMessage = (id) => {
    const messages = [...this.allMessage];
    const index = messages.findIndex(el => el.id === id);
    messages[index].is_read = true;
    this.allMessage = [...messages];
  }

  setSound = () => {
    this.sound = !this.sound;
  }
}

export default HeadBoxStore = new HeadBoxStore()
