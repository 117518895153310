import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';
import Button from 'components/UI/Button/Button';
import {agetostr} from 'components/ContentBox/Online-schedule/components/functions';
import AdminBody from './AdminBody';
import ClientBody from './ClientBody';
import { dateFormat } from 'helpers/date';
import { maskPhone } from 'helpers/phoneMask';
import { getShiftedTime } from 'components/Modules/Slots/utils';
import {localisationMaster} from "Localisation/Localisation";
import LocalStorage from 'services/LocalStorage';
import Stores from 'Stores';
import styles from './ClientItem.module.scss';

const adminActionMenu = [
  { name: localisationMaster('text_8', 'online-schedule/future-slots'), action: 'detail', permission: ['forward'] },
];

const clientActionMenu = [
  { name: localisationMaster('text_1', 'slots_listing'), action: 'test-detail', permission: ['forward'] },
];

function ClientItem({ data, index, isClient }) {
  const {
    client,
    employee_slot,
    start_level,
    program,
    zoom_url,
    uuid_cache,
    is_online,
  } = data;
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;
  const shiftedTime = getShiftedTime(`${employee_slot.slot_date}T${employee_slot.slot_time}`, timeZone);

  const handleCopyZoomClick = () => {
    navigator.clipboard.writeText(zoom_url)
      .then(() => swal('', localisationMaster('text_14', 'teacher_schedule'), 'success'));
  };

  const handleTestButtonClick = () => {
    window.open(
      `${Stores.baseLink()}/api/v1/online_schedule/client/${uuid_cache}`,
      '_blank',
    );
  };

  const handleActionMenuClick = (actionType) => {
    switch (actionType) {
      case 'detail':
        setIsDetailsShown(!isDetailsShown);
        return;
      case 'test-detail':
        return;
      default:
        return;
    }
  };

  const LevelBlock = () => {
    return isClient
      ? <Button classes={styles.test_button} onClick={handleTestButtonClick}>{localisationMaster('text_3', 'study/study-test')}</Button>
      : <>
        {start_level &&
        <p className={styles.level_title}>
          {start_level}
        </p>
        }
        <p className={styles.subtitle}>
          {program}
        </p>
      </>
  };

  return (
    <Paper tag="li" classes={styles.client}>
      <header className={`${styles.header} ${isClient ? styles.header__client : ''}`}>
        <div className={styles.name_wrapper}>
          <p className={styles.name}>{Stores.transliterate(isClient ? employee_slot.employee_full_name : `${client.first_name} ${client.last_name}`)}</p>
          {!isClient &&
            <p
              className={styles.subtitle}>{agetostr(client.date_of_birth)}, {dateFormat(new Date(client.date_of_birth), 'dd MMMM YYYYг.')}
            </p>
          }
        </div>
        <div className={styles.date_wrapper}>
          <p className={styles.date}>
            <span>{shiftedTime.time}</span>, {shiftedTime.date}
          </p>
          {!isClient && <p className={styles.subtitle}>{employee_slot.location} {is_online && localisationMaster('text_online', 'online-schedule/future-slots')}</p>}
        </div>
        <div className={styles.level_wrapper}>
          <LevelBlock />
        </div>
        <div className={styles.menu_wrapper}>
          <ActionMenu
            menu={isClient ? clientActionMenu : adminActionMenu}
            action={handleActionMenuClick}
          />
        </div>
      </header>

      <div className={styles.body}>
        {isClient
          ? <ClientBody data={data} />
          : <AdminBody data={data} index={index} />
        }
      </div>

      {isDetailsShown &&
      <div className={styles.details}>
        <p className={styles.details_title}>{localisationMaster('text_13', 'online-schedule/future-slots')}</p>
        <ul className={styles.details_list}>
          <li><a className={styles.details_link} href={`tel:${client.phone}`}>{maskPhone(client.phone)}</a></li>
          <li><a className={styles.details_link} href={`mailto:${client.email}`}>{maskPhone(client.email)}</a></li>
          {zoom_url &&
            <li>
              <button className={styles.details_zoom} onClick={handleCopyZoomClick}>
                {localisationMaster('text_2', 'slots_listing')}
              </button>
            </li>
          }
        </ul>
      </div>
      }
    </Paper>
  );
}

export const clientDataTypes = {
  id: PropTypes.number.isRequired,
  start_level: PropTypes.string,
  program: PropTypes.string.isRequired,
  program_id: PropTypes.number.isRequired,
  training_level_id: PropTypes.number,
  is_missed: PropTypes.bool,
  comment: PropTypes.string,
  zoom_url: PropTypes.string,
  uuid_cache: PropTypes.string,
  client: PropTypes.shape({
    age: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    date_of_birth: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  employee_slot: PropTypes.shape({
    employee_full_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_online: PropTypes.bool.isRequired,
    is_school: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    location_email: PropTypes.string,
    location_address: PropTypes.string,
    location_phone: PropTypes.arrayOf(PropTypes.string).isRequired,
    slot_date: PropTypes.string.isRequired,
    slot_id: PropTypes.number.isRequired,
    slot_time: PropTypes.string.isRequired,
  }).isRequired,
};

ClientItem.propTypes = {
  index: PropTypes.number.isRequired,
  isClient: PropTypes.bool,
  data: PropTypes.shape(clientDataTypes).isRequired,
};

export default observer(ClientItem);

