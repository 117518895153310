import React from 'react';
import PropTypes from 'prop-types';
import SlotTypeOption from './SlotTypeOption';
import styles from './SlotTypeOptions.module.scss';

function SlotTypeOptions({ label, options }) {

  return (
    <ul className={styles.list}>
      <li>
        <span className={styles.label}>
          {label}
        </span>
        <ul className={styles.options_list}>
          {options.map(({name, label, value}) => (
            <SlotTypeOption key={name} label={label} value={value} />
          ))}
        </ul>
      </li>
    </ul>
  );
}

SlotTypeOptions.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  })).isRequired,
};

export default SlotTypeOptions;
