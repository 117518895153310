import React from 'react';
import PropTypes from "prop-types";
import {classNames as cn} from "helpers";
import st from './Checkbox.module.scss';

function Checkbox(props) {
  return (
    <div
      onClick={() => props.onChange(!props.checked, props.name)}
      className={cn({
        [st.checkbox]: true,
        [st.checked]: props.checked,
        [st.disabled]: props.disabled,
        [st.indeterminate]: props.indeterminate,
        [props.className]: !!props.className,
        [st.correct]: props.isCorrect,
        [st.mistake]: props.isMistake,
      })}
    >
      <div className={st.input}></div>
      {props.label && <span>{props.label}</span>}
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
}

export default Checkbox
