import React, {Fragment, useEffect} from 'react';
import {superAxiosRequest} from "axiosApi";
import Stores from "Stores";
import ContentHeader from "../ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import THead from "./THead";
import Loader from "../Loader/Loader";
import st from "./Table.module.scss"

export default function StudentTransactions(props) {

    const cfg = {
        cols: [
            {
                name: 'Месяц',
                group: true,
                rowSpan: row => row.days.reduce((sum, day) => sum + day.operations.length, 3)
            },
            {name: 'День', dataField: 'day', align: 'center', classes: [st.fz12, st.fw500, st.color]},
            /*{name: 'Длительность урока, мин', dataField: 'lesson_length', align: 'center', classes: [st.fz12]},*/
            {
                name: 'Тип проводки',
                classes: [st.fz12],
                dataField: 'name',
            },
            {
                name: 'Пополнение, тг',
                align: 'center',
                classes: [st.fz12, st.fw600],
                formatter: row => Stores.cleanSumm(row.debit / 100) || ''
            },
            {
                name: 'Списание, тг',
                align: 'center',
                classes: [st.fz12, st.fw600],
                formatter: row => Stores.cleanSumm(row.credit / 100) || ''
            },
        ]
    }

    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
        getTdClassNames,
    } = useTableFixedHeader()

    useEffect(() => {
        setIsLoading(true)
        setData([])
        const apiConf = {
            method: 'get',
            link: 'ledger/student_operations_report/' + props.match.params.id,
        }
        superAxiosRequest(apiConf).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(() => {})
    }, [])

    return (
        <>
        {isLoading ? <Loader /> : data ?
            <>
            <ContentHeader title={`${data.client.last_name} ${data.client.first_name}: Поступления и списания`}/>

            <div ref={refWrapper} className={st.tableWrapper}>
                <div className={st.sticky} ref={refSticky}>
                    <table className={st.table}>
                        <THead cols={cfg.cols}/>
                    </table>
                </div>
                <table className={st.table}>
                    <THead cols={cfg.cols} ref={refThead}/>
                    <tbody className={st.tbody}>
                    {Array.isArray(data.months) && data.months.map((row, idxRow) => (
                        <Fragment key={idxRow}>
                            <tr className={st.bdt}>
                                <td rowSpan={cfg.cols[0].rowSpan(row)} className={`${st.bgw} ${st.fw500} ${st.color}`}>
                                    {row.name}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={cfg.cols.length - 2} className={`${st.tdTotal} ${st.bdrN}`}>
                                    Баланс на начало расчетного периода:
                                </td>
                                <td className={`${st.tdTotal} ${st.textAlignCenter} ${st.bdlN}`}>
                                    {Stores.cleanSumm(row.total.start / 100)} тг.
                                </td>
                            </tr>

                            {row.days?.map((subRow, idxSubRow) => {
                                return subRow.operations.map((operation, idxOperation) => {
                                    return (
                                        <tr key={`${idxRow}-${idxSubRow}-${idxOperation}`} className={idxSubRow % 2 ? st.strip : undefined}>
                                            {cfg.cols.map((col, idxCol) =>
                                                !col.group && (
                                                    col.dataField === 'day' || col.dataField === 'lesson_length' ?
                                                        idxOperation === 0 &&
                                                        <td key={idxCol} rowSpan={subRow.operations.length}
                                                            className={getTdClassNames(col)}>
                                                            {typeof col.formatter === 'function' ?
                                                                col.formatter(subRow) :
                                                                subRow[col.dataField]}
                                                        </td>
                                                        :
                                                        <td key={idxCol} className={`${getTdClassNames(col)} ${idxOperation === 0 ? '' : st.bdt}`}>
                                                            {typeof col.formatter === 'function' ?
                                                                col.formatter(operation) :
                                                                operation[col.dataField]}
                                                        </td>
                                                )
                                            )}
                                        </tr>
                                    )
                                })
                            })}

                            <tr>
                                <td colSpan={cfg.cols.length - 2} className={`${st.tdTotal} ${st.bdrN}`}>
                                    Остаток на конец расчетного периода:
                                </td>
                                <td className={`${st.tdTotal} ${st.textAlignCenter} ${st.bdlN}`}>
                                    {Stores.cleanSumm(row.total.end / 100)} тг.
                                </td>
                            </tr>
                        </Fragment>
                    ))}

                    {data.total &&
                    <>
                        {/* Итого */}
                        <tr className={st.bdt}>
                            <td colSpan={cfg.cols.length - 2} className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>
                                Итого
                            </td>
                            <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                                {Stores.cleanSumm(data.total.debits / 100)} тг.
                            </td>
                            <td className={`${st.tdTotal} ${st.bdlN} ${st.textAlignCenter}`}>
                                {Stores.cleanSumm(data.total.credits / 100)} тг.
                            </td>
                        </tr>

                        {/* Текущий баланс */}
                        <tr className={`${st.bdt} ${st.nobr}`}>
                            <td rowSpan={data.total.balance.companies.length + data.total.balance.groups.length + 1}
                                className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>Текущий баланс</td>
                        </tr>
                        {data.total.balance.companies.map((company, idx) =>
                            <tr key={idx}>
                                <td colSpan={cfg.cols.length - 2}
                                    className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.bdt}`}>
                                    Компания {company.name}
                                </td>
                                <td className={`${st.tdTotal} ${st.bdlN} ${st.textAlignCenter} ${st.bdt}`}>
                                    {Stores.cleanSumm(company.balance / 100)} тг.
                                </td>
                            </tr>
                        )}
                        {data.total.balance.groups.map((group, idx) =>
                            <tr key={idx}>
                                <td colSpan={cfg.cols.length - 2}
                                    className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.bdt}`}>
                                    Группа {group.name}
                                </td>
                                <td className={`${st.tdTotal} ${st.bdlN} ${st.textAlignCenter} ${st.bdt}`}>
                                    {Stores.cleanSumm(group.balance / 100)} тг.
                                </td>
                            </tr>
                        )}
                    </>}

                    </tbody>
                </table>
            </div>
        </>
                : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </>
    );
};
