import {observable, action, computed, toJS, makeObservable} from 'mobx';
import Stores from '.';
import { superAxiosRequest } from '../axiosApi';



class ListingStore {
    loading = false
    meta = []
    data = []
    filterConf = []
    filterParams = {}
    page = 1
    perPage = 10
    toFilter = false
    filterType = 1
    requestNumber = 0;

    constructor() {
        makeObservable(this, {
            data: observable,
            meta: observable,
            loading: observable,
            dropData: observable,
            toFilter: observable,
            updateData: observable,
            filterConf: observable,

            setLoading: action,
            setMeta: action,
            setData: action,
            updateRow: action,
            setFilterConf: action,
            putRow: action,
            filterParams: observable,
            filterParamsObject: computed,
            setFilterParams: action,
            setFiltredList: action,
            page: observable,
            perPage: observable,
            setPage: action,
            setPerPage: action,
            dropFilterState: action,
            filterType: observable,
            setFilterType: action,
        })
    }

    setLoading = val => this.loading = val

    setMeta = data => this.meta = data

    setData = data => this.data = data

    //PAGINATION
    setPage = (num) => {
        this.page = num
    }

    setPerPage = (num) => {
        this.page = 1
        this.perPage = num
    }

    setFilterType = id => {
        this.filterType = +id
    }

    setFilterParams = (param, key) => {
        this.filterParams = { ...this.filterParamsObject, [key]: param }
    }

    dropFilterState = (link, obj, soft) => {
        this.filterParams = {}
        this.filterConf = []
        this.page = 1
        if(!soft) {
            this.setFiltredList({ link, method: 'get' }, { offset: 0, limit: this.perPage, ...obj})
        }
    }

    setFiltredList = (apiConf, params) => {
        this.page = 1
        let body = apiConf
        let defaultAPI = {
            offset: (this.page - 1) * this.perPage,
            limit: this.perPage,
            //is_active: true,
        }
        body.params = params
        this.filterParams = { ...defaultAPI, ...params }
        this.fillData(body)
    }
    //FILTER
    putRow = (row) => {
        const Data = Stores.observableArray(this.data)
        const index = Data.findIndex(item => item.id === row.id);
        Data[index] = row
        this.data = Data
    }

    updateRow = (data, keys, index) => {
        let arr = {}
        for (let key in keys) {
            arr[key] = data[key]
        }
        let oldItems = this.data
        oldItems[index] = arr
        this.data = oldItems
    }

    setFilterConf = (data) => {
        this.filterConf = data
    }

    dropData = () => {
        this.filterParams = {}
        this.data = []
        this.meta = []
        this.filterType = 1
        this.toFilter = false
        this.loading = false
    }

    updateData = (data) => {
        this.data = data.result
        this.meta = data._meta
        this.loading = false
    }

    fillData = apiConf => {
        this.setLoading(true)
        const date = Date.now();
        this.requestNumber = date;
        superAxiosRequest(apiConf).then(res => {
            if(this.requestNumber === date) { // При множественном и одинаковом запросе позволяет забирать данные с последнего запроса.
                this.setData(res.data.result)
                this.setMeta(res.data._meta)
                this.setLoading(false)
            }
        }).catch(error => {
            this.setLoading(false)
            if (error.response && error.response.status === 400) {
                Stores.error = true;
                let errors = error.response.data;
                let error_str = '';
                for (let key in errors) {
                    error_str += [key] + " : " + errors[key][0] + '\r\n';
                }
                Stores.errorTxt = error_str;
            }
            if (error.response && error.response.status === 500) {
                Stores.error = true;
                Stores.errorTxt = "\r\n Сервер не доступен";
            }
            if (error.response && error.response.status === 404) {
                Stores.error = true;
                Stores.errorTxt = "\r\n Не верный url запроса";
            }
            if (error.response && error.response.status === 403) {
                Stores.error = true;
                Stores.errorTxt = "\r\n" + error.response.data.detail;
            }
        })
    }

    get getMeta(){
        return toJS(this.meta)
    }

    //FILTER
    get filterParamsObject() {
        return Stores.observableArray(this.filterParams)
    }
}

ListingStore = new ListingStore()

export default ListingStore
