import React from 'react';
import {Link} from "react-router-dom";
import {permissions} from "settings/permissions";
import {localisationMaster, translate, translate as loc} from 'Localisation/Localisation';
import {checkPermList} from "helpers";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import {ReactComponent as IconEdit} from "../Employee/img/edit-line.svg";
import {phoneMask} from "helpers/phoneMask";
import {dict} from "Localisation";
import Stores from "Stores";
import clientStore from "Stores/clientStore";
import modalStore from "Stores/modalStore";
import ActiveLessons from "../Employee/ActiveLessons";
import st from "./MainInfo.module.scss";
import ActiveTests from "../Employee/ActiveTests";
import useLocalization from "../../../../hooks/useLocalization";
import Books from "../../../ContentBox/Company/Employee/Tabs/Books/Books";


export default function MainInfo(props) {

  const pip = props.info.profile;

  const changeResponsible = () => {

    let profile = pip.id
    let modal_settings = {
      name: 'full_name',
      link: 'accounts/account_update/' + props.info.id,
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: 'Редактирование ответственного',
      type: 'SelectorAndInput',
      active_inputs: ['SelectCustom'],
      placeholder: 'Ответственный',
      data: 'json',
      width: '520px',
      profile: profile,
      is_put: true,
      new_key: 'full_name',
      name_select: 'responsible',
      api_link: 'accounts/employers_flat_list',
      get_option_label: opt => opt.last_name + ' ' + opt.first_name,
      link_params: {limit: 999},
      is_search: true,
      func: clientStore.updateProfile
    }

    modalStore.activity(modal_settings, {responsible: pip.responsible?.id})
  }

  // Изменение дополнительных телефонов
  const changeMultiPhone = () => modalStore.activity({
    name: 'multi_phone',
    link: 'accounts/clients/' + pip.id,
    button: loc(dict.main_page.text_30),
    title: loc(dict.group.text_5),
    type: 'milti_phone',
    is_put: true,
    width: '320px',
    func: clientStore.updateProfile
  }, {
    email: pip.email,
    additional_phones: pip.additional_phones.map(ph => ph.number),
  })

  const {translate} = useLocalization({
    section: 'profile',
    subsection: 'main_info',
    dict: {
      "active_tests": "Active Tests",
    }})

  return (
    <div className={st.mainInfo}>
      <Books />
      <ActiveLessons userId={props.info.id}/>
      <ActiveTests translate={translate}/>
      {/*<div className={st.mainInfoSkills}>*/}
      {/*  <BlockHeader title={props.translate.menu_main}/>*/}
      
      {/*  <div className={st.skillRow}>*/}
      {/*    <div className={st.skillName}>*/}
      {/*      <span>{props.translate.additional_contacts}:</span>*/}
      {/*    </div>*/}
      {/*    <div className={st.skillContent}>*/}
      {/*      {pip.additional_phones.length > 0 ?*/}
      {/*          <ul className={st.skillPhones}>{pip.additional_phones.map(phone =>*/}
      {/*              <li key={phone.id}>*/}
      {/*                <a href={`tel:${phone.number}`}>{phoneMask(phone.number.slice(1))}</a>*/}
      {/*              </li>*/}
      {/*          )}*/}
      {/*          </ul> :*/}
      {/*          <span className={st.skillEmpty}>{props.translate.new_contact_detail}</span>}*/}
      {/*    </div>*/}
      {/*    <div>{checkPermList(permissions.edit_client_profile) &&*/}
      {/*        <div className={st.editButton} onClick={changeMultiPhone}>*/}
      {/*          <IconEdit/>*/}
      {/*        </div>}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      
      {/*  <div className={st.skillRow}>*/}
      {/*    <div className={st.skillName}>*/}
      {/*      <span>{props.translate.responsible}:</span>*/}
      {/*    </div>*/}
      {/*    <div className={st.skillContent}>*/}
      {/*      {pip.responsible ?*/}
      {/*        checkPermList(permissions.view_employee_profile) ?*/}
      {/*          <Link to={`/account/${pip.responsible.user}`}>*/}
      {/*            {`${Stores.transliterate(pip.responsible.last_name)} ${Stores.transliterate(pip.responsible.first_name)}`}*/}
      {/*          </Link> :*/}
      {/*          <span>{`${Stores.transliterate(pip.responsible.last_name)} ${Stores.transliterate(pip.responsible.first_name)}`}</span> :*/}
      {/*        <span className={st.skillEmpty}>*/}
      {/*          {props.translate.not_assigned}*/}
      {/*        </span>}*/}
      {/*    </div>*/}
      {/*    <div>{checkPermList(permissions.edit_client_profile) &&*/}
      {/*      <div className={st.editButton} onClick={changeResponsible}>*/}
      {/*        <IconEdit/>*/}
      {/*      </div>}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}