import {observable, action, computed, toJS, makeObservable} from 'mobx';
import {superAxiosRequest} from 'axiosApi';

class HolidaysCalendarStore {
  apiConf = {
    link: 'schedule/holidays',
    params: {
      limit: 999
    }
  }
  holidaysData = []
  year = new Date().getFullYear()

  constructor() {
    makeObservable(this, {
      holidaysData: observable,
      year: observable,

      setYear: action,

      holidays: computed,
    })
  }

  fetchHolidays = () => {
    superAxiosRequest({
      method: 'get',
      ...this.apiConf,
    }).then(this.setHolidays).catch(() => {})
  }

  updateDate = day => {
    const request = day.selected ? this.deleteDate(day.full_date) : this.addDate(day.full_date)
    request.then(this.setHolidays).catch(() => {})
  }

  addDate = date => superAxiosRequest({
    method: 'post',
    ...this.apiConf
  }, {
    date
  })

  deleteDate = date => superAxiosRequest({
    method: 'delete',
    ...this.apiConf,
    params: {
      ...this.apiConf.params,
      date
    }
  })

  setHolidays = res => this.holidaysData = res.data.result

  setYear = year => this.year = year

  get holidays() {
    return toJS(this.holidaysData)
  }
}

export default new HolidaysCalendarStore()
