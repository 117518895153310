import {useEffect, useRef, useState} from 'react';
import st from "../components/ContentBox/Global/ReportTable/Table.module.scss";

export default function useTableFixedHeader(scrollBarOffset) {
    const refWrapper = useRef()
    const refThead = useRef()
    const refSticky = useRef()

    const [filter, setFilter] = useState({})
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const getTdClassNames = col => {
        const aClass = []
        if (col.align === 'center') aClass.push(st.textAlignCenter)
        if (Array.isArray(col.classes))
            aClass.push(...col.classes)
        return aClass.join(' ') || undefined
    }

    useEffect(() => {
        if (refWrapper.current)
            refWrapper.current.addEventListener('scroll', e => refSticky.current.scrollLeft = e.target.scrollLeft)
    })

    useEffect(() => {
        const windowScrollHandler = (scrollBarOffset) => {
            if (refWrapper.current && refThead.current && refSticky.current){
                const
                    $wrapper = refWrapper.current,
                    $thead = refThead.current,
                    $sticky = refSticky.current,
                    $stickyTable = $sticky.querySelector('table'),
                    $stickyThead = $sticky.querySelector('thead'),
                    wrapperTop = $wrapper.getBoundingClientRect().top

                if (wrapperTop < 0){
                    $sticky.style.display = 'block'
                    // Для программного горизонтального скроллинга фиксированной шапки
                    $sticky.style.width = $wrapper.offsetWidth - ( scrollBarOffset || 0) + 'px'
                    $stickyTable.style.width = $thead.getBoundingClientRect().width + 'px'

                    const $ths = $thead.querySelectorAll('th')

                    for (let i = 0; i < $ths.length; i++){
                        $stickyThead.querySelectorAll('th')[i].style.width = $ths[i].getBoundingClientRect().width + 'px'
                    }
                } else {
                    $sticky.style.display = 'none'
                }
            }
        }

        window.addEventListener('scroll', () => windowScrollHandler(scrollBarOffset))

        return () => {
            window.removeEventListener('scroll', () => windowScrollHandler(scrollBarOffset))
        }
    }, [])

    return {
        refWrapper,
        refThead,
        refSticky,

        filter,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,

        getTdClassNames,
    }
};
