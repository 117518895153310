import React from 'react';
import PropTypes from 'prop-types';
import { localisationMaster } from "Localisation/Localisation";
import { classNames as cn } from 'helpers';
import styles from './ShowMoreButton.module.scss';

function ShowMoreButton({ isShown, onClick }) {

  return (
    <button
      className={cn({
        [styles.button]: true,
        [styles.button__up]: isShown,
      })}
      onClick={() => onClick(!isShown)}
    >
      <span className={styles.arrow} />
      <span className={styles.text}>
          {isShown ? localisationMaster('info-calendar-1', 'online-schedule/time-slots') : localisationMaster('info-calendar-2', 'online-schedule/time-slots')}
        </span>
      <span className={styles.arrow} />
    </button>
  );
}

ShowMoreButton.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShowMoreButton;
