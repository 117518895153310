import React from "react";
import PropTypes from "prop-types";
import st from "./SexSelector.module.scss";

const SexSelector = props => {
  return (
    <div className={st.sexSelector}>
      <ul>{props.options.map(el =>
      <li key={el.id}
          className={el.id === props.value ? st.optionSelected : st.option}
          onClick={() => {
            props.onChange(el.id)
          }}>
        {el.name}
      </li>)}
      </ul>
    </div>
  )
}

SexSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default SexSelector
