import React, {useEffect, useState} from 'react'
import Filter from '../../../ContentBox/Global/Filter/Filter'
import ContentHeader from '../../../UI/ContentHeader/ContentHeader'
import {superAxiosRequest} from 'axiosApi';
import CheckReportTable from './CheckReportTable/CheckReportTable';
import {dateFormat} from "helpers/date";
import Loader from "components/ContentBox/Global/Loader/Loader";
import {splitNumberDigits} from "helpers/math";
import {ExcelRowsParams} from '../../../utils/ExcelExport';


const AverageCheckReport = () => {
    const [groupCatalog, setGroupCatalog] = useState({})
    const [checkTableData, setCheckTableData] = useState()
    const [pageState, setPageState] = useState({firstLoad: true, isLoading: false})
    const [online, setOnline] = useState(2)
    const filterCnf = [
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: groupCatalog.locations?.items,
            sort_key: 'name',
            nameState: 'location',
        },
        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Тип группы',
            values: groupCatalog.types?.items,
            sort_key: 'name',
            nameState: 'group_type'
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень курса',
            values: groupCatalog.course_levels?.items,
            sort_key: 'name',
            nameState: 'course_level'
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: groupCatalog.skill_levels?.items,
            sort_key: 'name',
            nameState: 'skill_level'
        },

        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 2, name: "Все группы"},
                {id: 1, name: "Онлайн"},
                {id: 0, name: "Офлайн"},
            ]
        },

    ]

    const sendRequest = (params) => {
        if (groupCatalog.locations) {
            setPageState({isLoading: true})

            let apiConf = {
                method: 'get',
                link: 'progress_report/average_check_report',
                params: {
                    ...params
                }
            }

            if (apiConf.params.skill_level && apiConf.params.skill_level.length > 0) apiConf.params.skill_level = `[${apiConf.params.skill_level.map(item => item)}]`
            if (apiConf.params.course_level && apiConf.params.course_level.length > 0) apiConf.params.course_level = `[${apiConf.params.course_level.map(item => item)}]`
            if (apiConf.params.location && apiConf.params.location.length > 0) apiConf.params.location = `[${apiConf.params.location.map(item => item)}]`
            if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
            if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")
            delete apiConf.params.is_active

            superAxiosRequest(apiConf).then(res => {
                if (res.data.result.locations) {
                    let newData = {
                        ...res.data.result,
                        locations: []
                    }
                    let resultAverageCredit = 0
                    let resultAverageDebit = 0
                    res.data.result.locations.forEach(item => {
                            if (item.location_id) {
                                let location = {
                                    title: groupCatalog.locations.items.find(loc => loc.id === item.location_id).name,
                                    data: []
                                }
                                item.skill_levels.forEach(skill_lvl => {
                                    if (skill_lvl.skill_level_id) {
                                        location.data.push({
                                            ...skill_lvl,
                                            title: res.data.skill_levels.find(find_sk_lvl => find_sk_lvl.id === skill_lvl.skill_level_id).name
                                        })
                                        resultAverageCredit = resultAverageCredit + skill_lvl.aver_credit
                                        resultAverageDebit = resultAverageDebit + skill_lvl.aver_debit
                                    }
                                })
                                newData.locations.push(location)
                            }
                        }
                    )
                    if (params.online || params.online === 0) setOnline(params.online)
                    newData.resultAverageCredit = resultAverageCredit
                    newData.resultAverageDebit = resultAverageDebit
                    setCheckTableData(newData)
                } else setCheckTableData()
                setPageState({isLoading: false})

            })
        }
    }


    useEffect(() => {
        setPageState({...pageState, isLoading: false})
        const apiConf = {
            method: 'get',
            link: 'groups/groups_catalog',
        }
        superAxiosRequest(apiConf).then(res => {
            setPageState({...pageState, isLoading: false})
            setGroupCatalog(res.data)
        })

    }, [])


    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Школа",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Тип программы ",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Приход студентов",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Средний приход",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Списание со студентов",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Среднее списание",
                    ...ExcelRowsParams.headerRowParam
                },
            ]
            let footerRows = [
                [
                    {
                        v: `Итого (клиентов ${data.clients_amount})`,
                        ...ExcelRowsParams.footerRowParams.firstCell
                    },
                    {
                        v: "",
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((data.all_debit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((checkTableData.resultAverageCredit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((data.all_credit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((checkTableData.resultAverageDebit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                ],
                [
                    {
                        v: "Средние показатели",
                        ...ExcelRowsParams.footerRowParams.firstCell
                    },
                    {
                        v: "",
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((data.average_debit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: ``,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${splitNumberDigits((data.average_credit / 100).toFixed(2))}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: ``,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                ]
            ]
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 30},
                {wch: 20},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];

            let currentRow = 1
            data.locations.forEach(location => {
                if (location.data.length > 1) {
                    merges.push(
                        {s: {r: currentRow, c: 0}, e: {r: currentRow + location.data.length - 1, c: 0}}
                    )
                }
                location.data.forEach(skill_level => {
                    currentRow = currentRow + 1
                    tableData.push([
                        {
                            v: `${location.title}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${skill_level.title}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${splitNumberDigits((skill_level.debit / 100).toFixed(2))}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${splitNumberDigits((skill_level.aver_debit / 100).toFixed(2))}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${splitNumberDigits((skill_level.credit / 100).toFixed(2))}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${splitNumberDigits((skill_level.aver_credit / 100).toFixed(2))}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, ...footerRows]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Средний чек.xlsx");
        })
    };


    return (
        <div>
            <ContentHeader
                title="Средний чек проверка"
            />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => setCheckTableData({locations: []})} />
            {pageState.isLoading ? <Loader /> :
                checkTableData?.locations[0]?.data.length ?
                    <>
                        <CheckReportTable firstLoad={pageState.firstLoad} checkTableData={checkTableData} online={online} />
                        <button onClick={() => {downloadExcel(checkTableData)}} style={{
                            margin: '10px 10px 0 ',
                            width: 'calc(100% - 20px)'
                        }}>Выгрузить в Excel</button>
                    </>
                    :
                    <h1 style={{textAlign: 'center'}}>{pageState.firstLoad ? 'Сначала выберите параметры отчета' : 'Нет данных для отображения!'}</h1>
            }

        </div>
    )
}

export default AverageCheckReport;


