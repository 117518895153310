import React, {useState} from 'react';
import swal from "sweetalert";
import {superAxiosRequestEasy} from "axiosApi";
import useLocalization from "hooks/useLocalization";
import {classNames as cn} from "helpers";
import CompanyLogo from "../Common/CompanyLogo";
import AuthFooter from "../Common/AuthFooter";
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss"
import st from "./RecoverPassword.module.scss"
import srcRecover1 from "../img/recover-1.png";

export default function RecoverPassword() {
  const [email, changeEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(true);
  const [isCheck, setIsCheck] = useState(false);

  const {translate} = useLocalization({section: 'user', subsection: 'recover_password'})

  const changeEmailValue = e => {
    changeEmail(e.target.value);
  }

  const checkEmail = () => {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
    if (emailValid === null) {
      setIsEmailError(true);
      return false;
    } else {
      setIsEmailError(false);
      return true;
    }
  }

  const getTokens = user => {
    const apiConf = {
      method: 'post',
      link: `accounts/send_reset_password_link`,
    }
    superAxiosRequestEasy(apiConf, user).then(res => {
      if (res.status === 200) {
        swal("", 'На ваш E-mail отправлено письмо с ссылкой на восстановление пароля', "success")
          .then(() => {
            document.location.href = '/'
          })
      }
    }).catch(() => {
      swal("", 'Такой Email не существует', "warning").then(() => {

      })
    })
  }

  const onSubmitHandler = e => {
    e.preventDefault();
    setIsCheck(true);
    const emailChecked = checkEmail();

    if (emailChecked) {
      const jsonBody = {'email': email};
      getTokens(jsonBody);
    }
  }

  return (
    <>
      <article className={stWrapperAuth.content}>
        <div className={stWrapperAuth.authBox}>
          <CompanyLogo/>

          <h1 className={`${stWrapperAuth.heading1} ${st.title}`}>
            <nobr>{translate.recover_password} <span role="img" aria-label="Create Account">👀</span></nobr>
          </h1>

          <form onSubmit={onSubmitHandler} className={st.form}>

            <div className={st.text}>
              <p>{translate.send_link_to}:</p>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.label}>{`${translate.email}:`}</div>
              <input type="email"
                     name="email"
                     placeholder="you@example.com"
                     value={email}
                     onChange={changeEmailValue}
                     className={cn({
                       [stWrapperAuth.input]: true,
                       [stWrapperAuth.inputError]: (isEmailError && isCheck)
                     })}
              />
            </div>

            <div className={`${stWrapperAuth.submit} ${st.submit}`}>
              <div>
                <button>{translate.send_recovery_link}</button>
              </div>
              <div className={`${stWrapperAuth.haveAccount} ${st.haveAccount}`}>
                <p>{translate.go_back_to} <a href="/">{translate.login}</a></p>
              </div>
            </div>
          </form>
        </div>
      </article>
      <AuthFooter srcPromo={srcRecover1}/>
    </>
  )
}
