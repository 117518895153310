import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Flex from '../../../../../UI/Flex/Flex';
import InputToggle from '../../../../../UI/InputToggle/InputToggle';
import SlotTypeOptions from '../SlotTypeOptions/SlotTypeOptions';
import SettingsIcon from '../../../../../UI/SettingsIcon/SettingsIcon';
import Hr from '../../../../../UI/Hr/Hr';
import SlotTypesModal from './SlotTypesModal';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';
import { adaptSlotTypeOptions } from '../../adapters';

function SlotType({ id }) {
  const {name, is_active, is_comment, is_accessible, comment, color} = slotsSettingsStore.getSlotType(id);
  const isNonEditable = name === 'Стандартный тип';
  const options = adaptSlotTypeOptions(is_comment, is_accessible, color);
  const [isModalShown, setIsModalShown] = useState(false);

  const handleToggleChange = () => {
    slotsSettingsStore.toggleSlotType(id);
  };

  const handleSettingsIconClick = () => {
    setIsModalShown(!isModalShown);
  };

  return (
    <>
      <Flex justify={'start'} >
        <InputToggle
          label={name}
          name={`slot-type-${id}`}
          onChange={handleToggleChange}
          withLabel={false}
          selected={is_active}
          disabled={isNonEditable}
        />
        <SlotTypeOptions label={name} options={options} />
        { !isNonEditable && <SettingsIcon onClick={handleSettingsIconClick}/> }
      </Flex>
      <Hr withBottomMargin={false} />
      {
        isModalShown &&
        <SlotTypesModal
          id={id}
          title={name}
          isComment={is_comment}
          isAccessible={is_accessible}
          comment={comment}
          color={color}
          onClose={handleSettingsIconClick}
        />
      }
    </>
  );
}

SlotType.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default observer(SlotType);

