import React from 'react';
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {addZero} from "helpers/math";
import HolidaysCalendarStore from "./holidaysCalendarStore";
import st from './CalendarMonth.module.scss'
import CalendarDay from "./CalendarDay";


const CalendarMonth = props => {
    const {holidays, year} = HolidaysCalendarStore

    const createDays = (year, month) => {
        let mon = month - 1;
        let d = new Date(year, mon);

        // Заполняем пустые дни
        const arr = Array.from({length: (d.getDay() || 7) - 1}, () => ({
            date: '',
            disabled: true,
            selected: false
        }))

        while (d.getMonth() === mon) {
            const full_date = `${year}-${month}-${addZero(d.getDate())}`
            arr.push({
                date: d.getDate().toString(),
                disabled: false,
                selected: !!holidays.find(x => x.date === full_date),
                full_date
            })

            d.setDate(d.getDate() + 1);
        }

        return arr
    }

    return (
        <div className={st.month}>
            <div className={st.header}>
                {timeConverter(`${year}-${props.month}-01`, "month")}
            </div>
            <div className={st.weekdays}>
                <div>ПН</div>
                <div>ВТ</div>
                <div>СР</div>
                <div>ЧТ</div>
                <div>ПТ</div>
                <div>СБ</div>
                <div>ВС</div>
            </div>
            <div className={st.days}>{createDays(year, props.month).map((day, index) =>
                <CalendarDay key={index} day={day}/>)}
            </div>
        </div>
    )
}

CalendarMonth.propTypes = {
    month: PropTypes.string.isRequired
}

export default observer(CalendarMonth)
