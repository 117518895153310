import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {superAxiosRequest} from 'axiosApi';
import {observer} from 'mobx-react';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {checkTrack, diffTime} from '../scheduleFunctions';
import {permissions} from "settings/permissions";
import {checkPermList} from "helpers";
import {addZero} from "helpers/math";
import {dict, translate as loc} from "Localisation";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import scheduleStore from 'components/ContentBox/Company/Employee/Tabs/Schedule/scheduleStore';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';
import RedesignActionMenu from 'components/UI/ActionMenu/ActionMenu';
import Tracker from 'components/ContentBox/Company/WorkTable/Tracker';
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import "./Schedule.scss";
import st from "./Schedule.module.scss";
import {ReactComponent as Arrow} from "components/ContentBox/img/arrow-right-line.svg";
import {ReactComponent as Dot} from "components/ContentBox/img/threeDot.svg";
import {dateFormat} from "helpers/date";
import Loader from "components/UI/Loader/Loader";
import WorkTimeInfo from "./WorkTimeInfo/WorkTimeInfo";
import useLocalization, {getLang} from "hooks/useLocalization";
import kk from 'date-fns/locale/kk';

const Schedule = ({userID, selectedTeachers, ...props}) => {
  const today = new Date()

  const [date, setDate] = useState(props.date || today)
  const [tracks, setTracks] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentDay, setCurrentDay] = useState({})

  const {translate} = useLocalization({
    section: 'profile', subsection: 'work_schedule', dict: {
      "header_title": "Work Schedule",
      "today": "Today",
      "remainder_for_today": "Remainder For Today",
      "worked_out_today": "Worked Out Today",
      "estimated_day_time": "Estimated Departure Date & Time:",
      "working_days_total": "Working days, total",
      "days_worked": "Days worked",
      "late_leaving_early": "Late / Leaving Early",
      "sick_days": "Sick day",
      "workday_half": "Workday (Half)",
      "vacations_half": "Vacation (Half)",
      "vacation_w_p": "Vacations (W.P.)",
      "arrival": "Arrival",
      "departure": "Departure",
      "break": "Break",
      "holiday": "Weekend",
      "unchecked": "Unchecked",
      "days_week": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      "days_week_short": ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      "vacation_days": "Vacations days",
      "vacation": "Vacation",
      "working_day": "Working day",
      "worked_out": "Worked out",
      "minutes": "minutes",
      "undefined_time": "Time undefined"
    }
  })

  const formDateForTooltip = (day) => {
    const date = new Date(day)
    if (translate.days_week_short) return translate.days_week_short[date.getDay()]
  }

  const divRef = useRef(null);
  const modalRefs = [];

  /**
   * Проверяет, выходит ли модальное окно за пределы видимой области и добавляет соответствующие классы и стили для его позиционирования.
   *
   * @param {string} date - Дата, для которой выполняется проверка.
   * @param {HTMLElement} $day - Конкретный день, для которого выполняется проверка позиции.
   */
  const checkOverflow = (date, {target: $day}) => {
    // Поиск модального окна, соответствующего дате.
    const $modal = modalRefs.find(el => Object.keys(el)[0] === date)?.[date];
    // Если модальное окно не найдено, завершаем выполнение функции.
    if (!$modal) return;

    const dayRect = $day.getBoundingClientRect();

    if (divRef.current) {
      const modalRect = $modal.getBoundingClientRect();
      const divRect = divRef.current.getBoundingClientRect();
      // Половина ширины стрелочки у модального окна.
      const halfWidthArrowInModal = 7;

      // Проверяем, выходит ли модальный элемент за правую границу контейнера.
      if (modalRect.right > divRect.right) {
        // Добавляем класс "Right" для модального окна.
        $modal.classList.add("employeeTabSchedule__dayTooltipRight");
        // Вычесляем свойство right для модального окна.
        $modal.style.setProperty("--position-right", (dayRect.right - divRect.right) + 'px');
        // Вычесляем свойство right для стрелочки модального окна.
        $modal.style.setProperty("--arrow-position-right", (divRect.right - dayRect.right + dayRect.width / 2 - halfWidthArrowInModal) + 'px');
        // Вычесляем свойство left для стрелочки модального окна.
        $modal.style.setProperty("--arrow-position-left", "auto");
      }
      // Проверяем, выходит ли модальный элемент за левую границу контейнера.
      else if (modalRect.left < divRect.left) {
        // Добавляем класс "Left" для модального окна.
        $modal.classList.add("employeeTabSchedule__dayTooltipLeft");
        // Вычесляем свойство left для модального окна.
        $modal.style.setProperty("--position-left", (divRect.left - dayRect.left) + 'px');
        // Вычесляем свойство left для стрелочки модального окна.
        $modal.style.setProperty("--arrow-position-left", (dayRect.left - divRect.left + dayRect.width / 2 - halfWidthArrowInModal) + 'px');
        // Вычесляем свойство right для стрелочки модального окна.
        $modal.style.setProperty("--arrow-position-right", "auto");
      }
    }
  }

  const menu = [
    // Создать контракт
    ...(userID && !scheduleStore.scheduleListObject[props.id]?.length ? [{
      btn_link: {
        name: loc(dict.contract.text_2),
        link: '/account/' + userID,
        target: '_blank'
      }, permission: ['forward']
    }] : []),
    // Профиль сотрудника
    ...(userID ? [{
      btn_link: {
        name: loc(dict.shedules.employee_profile),
        link: '/account/' + userID,
        target: '_blank'
      }, permission: ['forward']
    }] : []),
    // Добавить график
    ...(!userID && scheduleStore.scheduleListObject[props.id] ? [{
      name: loc(dict.shedules.add_schedule),
      action: 'add',
      perm: permissions.add_work_graphic_rules
    }] : []),
    // Редактировать график
    ...(!userID && scheduleStore.scheduleListObject[props.id] ? [{
      name: loc(dict.shedules.edit_schedule),
      action: 'edit',
      perm: permissions.edit_work_graphic_rules
    }] : []),
    // Отпускные дни
    ...(checkPermList(permissions.view_report_vacation_days) ? [userID ? {
      btn_link: {
        name: loc(dict.work_table_listing.vacation_days),
        link: '/reports/vacation-days/' + props.id,
        target: '_blank'
      },
      permission: ['forward']
    } : {
      name: loc(dict.work_table_listing.vacation_days),
      link: {
        to: '/reports/vacation-days/' + props.id,
        target: '_blank'
      }
    }] : [])
  ]

  const getTrack = (arr, pos, type) => {
    let result = translate.unchecked
    if (arr.length && arr[pos][type]) {
      result = arr[pos][type].slice(0, 5)
    }
    return result
  }

  const getDayTpl = day => {
    let text
    let dopText
    let breakTime

    switch (day.status) {
      case '':
        text = ''
        break;
      case 1:
      case 6:
        text = day.start_at.slice(0, 5) + ' - ' + day.finish_at.slice(0, 5)
        breakTime = day.lunch
        break;
      case 4:
        text = translate.sick_days
        break;
      case 3:
        text = translate.vacation
        break;
      case 5:
        text = translate.vacation_w_p
        break;
      case 2:
        text = translate.holiday
        break;
      case 11:
        text = translate.vacation_w_p
        break;
      case 7 :
        text = translate.workday_half
        dopText = translate.vacations_half
        break
      case 9 :
        text = translate.workday_half
        break
      case 8 :
        text = translate.workday_half
        dopText = translate.vacation_w_p
        break
      case 10 :
        text = translate.vacations_half
        break
      default:
        break
    }

    if (new Date(day.date + ' 23:59:59') < new Date() && day.status === 1) day.status = 6
    const aClassName = [
      'employeeTabSchedule__day',
      (day.holiday && day.status < 1 ? 'employeeTabSchedule__day--holiday' : 'employeeTabSchedule__day--' + day.status)]
    // Опоздание
    if (checkTrack(day.tracks, -1, day.start_at) && (day.status === 1 || day.status === 6) && (new Date(day.date + ' 23:59:59') < new Date())) aClassName.push('employeeTabSchedule__day--later')
    // Уход раньше
    if (checkTrack(day.tracks, 1, day.finish_at) && (day.status === 1 || day.status === 6) && (new Date(day.date + ' 23:59:59') < new Date())) aClassName.push('employeeTabSchedule__day--before')

    return <div key={day.date}
                className={aClassName.join(' ')}
                onClick={() => {
                  checkPermList(permissions.change_day_types) && setStatus(day)
                }}
                onMouseEnter={(e) => checkOverflow(day.date, e)}
    >
      <span>{day.date.split('-')[2]}</span>
      <div className="dayInfo">
        <small>{text}</small>
        {(day.status === 6 || day.status === 1) && <small>{translate.break}: {breakTime} min</small>}
        {(day.status === 7 || day.status === 8) && <small>{dopText}</small>}
      </div>

      {day.status === 2 && // выходной
        <div className={`employeeTabSchedule__dayTooltip`} ref={(div) => (modalRefs.push({[day.date]: div}))}>
          <div className='employeeTabSchedule__holiday'>
            <b>{`${timeConverter(day.date, 'day')} (${formDateForTooltip(day.date)})`}</b></div>
          <div className='employeeTabSchedule__holiday'><b>{translate.holiday}</b></div>
          {day?.tracks?.length > 0 && (<div>
              <div className="employeeTabSchedule__dayTooltipFlexRow">
                <span>{translate.arrival}: </span>
                <span
                  className={checkTrack(day.tracks, -1, day.start_at) ? 'employeeTabSchedule__dayTooltipRed' : undefined}>
                            {getTrack(day.tracks, 0, 'in_time')}
                        </span>
              </div>

              <div className="employeeTabSchedule__dayTooltipFlexRow">
                <span>{translate.departure}: </span>
                <span
                  className={checkTrack(day.tracks, 1, day.finish_at) ? 'employeeTabSchedule__dayTooltipRed' : undefined}>
                            {getTrack(day.tracks, day.tracks.length - 1, 'out_time')}
                        </span>
              </div>

              <div className="employeeTabSchedule__dayTooltipFlexRow">
                <span>{translate.worked_out}: </span>
                <span>
                            {getWorkedTime(day.tracks)}
                        </span>
              </div>
            </div>)}
        </div>}

      {(day.status === 6 || day.status === 1) && // рабочий или отработанный
        <div className={`employeeTabSchedule__dayTooltip`} ref={(div) => (modalRefs.push({[day.date]: div}))}>
          <div className="employeeTabSchedule__dayTooltip__header">
            <div><b>{`${timeConverter(day.date, 'day')} (${formDateForTooltip(day.date)})`}</b></div>
            <div>
              <b>{translate.working_day}: </b><span>{day.start_at.slice(0, 5) + ' - ' + day.finish_at.slice(0, 5)}</span> | <b>{translate.break}: </b><span>{day.lunch} {translate.minutes}</span>
            </div>
            <div></div>
          </div>

          {day.status === 6 && <div>
            <div className="employeeTabSchedule__dayTooltipFlexRow">
              <span>{translate.arrival}: </span>
              <span
                className={checkTrack(day.tracks, -1, day.start_at) ? 'employeeTabSchedule__dayTooltipRed' : undefined}>
                            {getTrack(day.tracks, 0, 'in_time')}
                        </span>
            </div>

            <div className="employeeTabSchedule__dayTooltipFlexRow">
              <span>{translate.departure}: </span>
              <span
                className={checkTrack(day.tracks, 1, day.finish_at) ? 'employeeTabSchedule__dayTooltipRed' : undefined}>
                            {getTrack(day.tracks, day.tracks.length - 1, 'out_time')}
                        </span>
            </div>

            <div className="employeeTabSchedule__dayTooltipFlexRow">
              <span>{translate.worked_out}: </span>
              <span>
                            {getWorkedTime(day.tracks)}
                        </span>
            </div>
          </div>}
        </div>}
    </div>
  }

  const createDate = (value, c) => {
    const now = new Date(value);
    let expiry;
    if (c > 0) {
      expiry = now.getMonth() === 11 ? new Date(now.getFullYear() + 1, 0, 1) : new Date(now.getFullYear(), now.getMonth() + 1, 1);
    } else {
      expiry = now.getMonth() === 0 ? new Date(now.getFullYear() - 1, 11, 1) : new Date(now.getFullYear(), now.getMonth() - 1, 1);
    }
    return expiry
  }

  const addSchedule = () => {
    let data = {
      name: 'workSchedule',
      link: 'employees/graphic/many',
      button: loc(dict.main_page.add),
      title: loc(dict.main_page.schedule),
      type: 'workSchedule',
      data: 'json',
      profile_id: props.id,
      width: '710px',
    }
    Stores.SuperModal.activity(data)
  }

  const editSchedule = () => {
    const apiConf = {
      method: 'get', link: 'employees/list', params: {
        date: timeConverter(date, 'today'), employee_id: props.id,
      }
    }
    superAxiosRequest(apiConf).then(res => {
      let data = {
        name: 'workSchedule',
        link: 'employees/graphic/many',
        button: loc(dict.main_page.add),
        title: loc(dict.main_page.schedule),
        type: 'workSchedule',
        data: 'json',
        profile_id: props.id,
        width: '710px',
        edit: true,
      }
      Stores.SuperModal.activity(data, res.data.rules)
    })
  }

  const setStatus = day => {
    const ms = {
      link: 'employees/tabel',
      button: loc(dict.main_page.add),
      title: timeConverter(day.date, 'age'),
      type: 'day-status',
      payload: {day, selectedTeachers},
      func: data => {
        if (Array.isArray(data)) {
          scheduleStore.updateScheduleList(props.id, data)
        } else if (typeof (data) === 'object') {
          for (const [key, val] of Object.entries(data)) {
            scheduleStore.updateScheduleList(key, val)
          }
        }
      }
    }
    const md = {
      employee_id: props.id,
      day: day.date,
      day_type: day.status,
      start_at: day.start_at,
      finish_at: day.finish_at,
      comment: day.comment || '',
      lunch: day.lunch,
    }
    modalStore.activity(ms, md)
  }

  const action = data => {
    switch (data) {
      case 'add':
        addSchedule()
        break;
      case 'edit':
        editSchedule()
        break;
      default:
        break;
    }
  }

  const returnCount = status => {
    const list = scheduleStore.scheduleListObject[props.id] || []
    //if (status !== 99) return list.filter(x => x.status === status).length
    //if (status === 99) return list.filter(x => x.status === 6 && new Date(x.date) < new Date(Date.now())).length
    return status ? Array.isArray(status) ? list.filter(x => status.includes(x.status)).length : list.filter(x => x.status === status).length : list.length
  }

  const returnCountLaterBefore = () => {
    const days = scheduleStore.scheduleListObject[props.id] || []
    const count = days.reduce((count, day) => {
      const {status, start_at, finish_at, tracks} = day
      if (status === 6) {
        // если не отметился, то наказать
        if (tracks.length === 0) {
          count.later++
          count.before++
        } else if (tracks.length > 0) {
          if (checkTrack(tracks, -1, start_at)) count.later++
          if (checkTrack(tracks, 1, finish_at)) count.before++
        }
      }
      return count
    }, {later: 0, before: 0})

    return `${count.later}/${count.before}`
  }

  const getWorkedTime = tracks => {
    const ms = tracks.reduce((ms, track) => {
      return ms + diffTime(track.in_time, track.out_time)
    }, 0)

    let hours = Math.floor((ms % 86400000) / 3600000);
    let minutes = Math.round(((ms % 86400000) % 3600000) / 60000);

    return (hours > 0 || minutes > 0) ? hours + ':' + addZero(minutes) : translate.undefined_time
  }

  useEffect(() => {
    superAxiosRequest({
      method: 'get', link: 'employees/calendar', params: {
        date: timeConverter(date, 'today'), employee: props.id,
      }
    }).then(res => {
      setLoading(true)
      scheduleStore.updateScheduleList(props.id, res.data)
      const day = res.data.find((d) => d.date === dateFormat(today, 'YYYY-mm-dd'))
      if (day) setCurrentDay(day)
      const tr = res.data.reduce((arr, day) => arr.concat(day.tracks), [])
      setTracks(tr)
    }).catch(() => {
      scheduleStore.deleteSchedule(props.id)
      setTracks([])
    }).finally(() => setLoading(false))
  }, [date, props.id])

  useEffect(() => {
    if (props.date) setDate(props.date)
  }, [props.date])

  return (loading ?
    <Loader/> :
    <div className="employeeTabSchedule">
      <div className="employeeTabSchedule__scheduleBox">{(userID && props.name) ?
        <div className={st.header}>
          <div>
            <NavLink to={'/account/' + userID} target="_blank" className={st.employeeName}>
              {props.name}
            </NavLink>
          </div>
          {menu.length > 0 && <div className={st.actionMenu}>
            <ActionMenu menu={menu} action={action}/>
          </div>}
        </div> :
        <BlockHeader title={translate.header_title}>
          {Stores.isAdmin && <RedesignActionMenu menu={menu} action={action}/>}
        </BlockHeader>}
        <div className={st.top}>
          <WorkTimeInfo currentDay={currentDay} today={today} translate={translate}/>
          <div className={st.daysInfo}>
            <div>
              <b>{translate.working_days_total}:</b>
              <span>
                                {returnCount() - returnCount([0, 2])} / {returnCount([1, 6]) + returnCount([7, 8, 9]) / 2 - returnCount(10) / 2}
                            </span>
            </div>
            <div><b>{translate.days_worked}:</b><span>{returnCount(6)}</span></div>
            <div><b>{translate.late_leaving_early}:</b><span>{returnCountLaterBefore()}</span></div>
            <div>
              <b>{translate.vacation_w_p}:</b>
              <span>{returnCount(5) + returnCount([8, 11]) / 2}</span>
            </div>
            <div>
              <b>{translate.vacation_days}:</b>
              <span>{returnCount(3) + returnCount([7, 10, 11]) / 2}</span>
            </div>
            <div><b>{translate.sick_days}:</b><span>{returnCount(4)}</span></div>
          </div>
        </div>
        <div className={st.bottom}>
          <div className={st.monthNav}>
            <div className={st.prevMonth} onClick={() => {
              setDate(createDate(date, -1))
            }}>
              <Arrow/>
              <span>{timeConverter(createDate(date, -1), 'MMYY_')}</span>
            </div>
            <div className={st.currentMonth}>
              <span>{timeConverter(date, 'MMYY_')}</span>
              <div>
                <DatePicker
                  selected={date}
                  locale={getLang() === 'kz' ? kk : getLang()}
                  onChange={date => setDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  customInput={<Dot className='dot'/>}
                />
              </div>
            </div>
            <div className={st.nextMonth} onClick={() => {
              setDate(createDate(date, +1))
            }}>
              <span>{timeConverter(createDate(date, +1), 'MMYY_')}</span>
              <Arrow/>
            </div>
          </div>

          {scheduleStore.scheduleListObject[props.id]?.length ?
            <div ref={divRef} className={st.days}>
            {scheduleStore.scheduleListObject[props.id].map(getDayTpl)}
          </div> :
            <span className="sg-probka">{loc(dict.work_table_listing.warning)}</span>}
        </div>

        {(userID && tracks.length > 0) && <Tracker days={scheduleStore.scheduleListObject[props.id]}
                                                   getWorkedTime={getWorkedTime}/>}
      </div>
    </div>);
}

Schedule.propTypes = {
  id: PropTypes.number.isRequired,
  userID: PropTypes.number,
  name: PropTypes.string,
  selectedTeachers: PropTypes.arrayOf(PropTypes.object)
}

export default observer(Schedule);
