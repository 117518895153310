import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {classNames as cn} from "helpers";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import InputInn from "components/ContentBox/Global/InputInn/InputInn";
import InputPhone from "components/ContentBox/Global/InputPhone/InputPhone";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import st from "./ConfirmPayment.module.scss";

export const PAYERS = {
  NEW_PAYER: 9000000,
  CLIENT_PAYER: 9000001,
}

const initOptionsPayers = [
  {id: PAYERS.NEW_PAYER, label: 'Создание нового плательщика'},
  {id: PAYERS.CLIENT_PAYER, label: 'Клиент - плательщик'},
]

const formatOptionBank = (opt, {context}) => {
  const str = context === 'menu' ?
    `<b>${opt.name}</b> (${opt.organization})<br/>${opt.bin}<br/>${opt.payment_type}` :
    opt.name

  return <span dangerouslySetInnerHTML={{__html: str}}/>
}

const ConfirmPayment = () => {
  const {
    changeValue,
    modalValues,
    check,
    modalSettings: {payload: {order}},
    changeValues,
    addValidField,
    deleteValidField
  } = asideModalStore

  const [contacts, setContacts] = useState(initOptionsPayers)

  const changePayerHandler = o => {
    let obj
    if (o.id === PAYERS.NEW_PAYER) {
      obj = {
        ...modalValues,
        payer_id: o.id,
        first_name: '',
        last_name: '',
        who_is_it: '',
        email: '',
        phone: ' ',
        inn: ' ',
      }
    } else if (o.id === PAYERS.CLIENT_PAYER) {
      obj = {
        ...modalValues,
        payer_id: o.id,
        first_name: order.user_name.split(' ')[0],
        last_name: order.user_name.split(' ')[1],
        who_is_it: '',
        email: order.email,
        phone: order.phone,
        inn: order.inn,
      }
    } else {
      obj = {
        ...modalValues,
        payer_id: o.id,
        first_name: o.data.first_name,
        last_name: o.data.last_name,
        who_is_it: o.data.who_is_it,
        email: o.data.email,
        phone: o.data.phone,
        inn: o.data.inn,
      }
    }
    changeValues(obj);
  }

  useEffect(() => {
    superAxiosRequest({
      link: 'accounts/contact_persons',
      method: 'get',
      params: {
        client_id: order.client_id,
      }
    }).then(res => {
      const list = res.data.result.map(el => ({id: el.id, label: `${el.first_name} ${el.last_name}`, data: el}))
      setContacts(prev => [...prev, ...list])
    }).catch()
  }, [order.client_id])

  const isDisabled = modalValues.payer_id !== PAYERS.NEW_PAYER

  return (
    <FormFieldset>
      <FormField>
        <FormLabel>Плательщики клиента:</FormLabel>
        <FormSelectCustom>
          <SelectCustom placeholder="Плательщики клиента"
                        options={contacts}
                        valueID={modalValues.payer_id}
                        onChange={changePayerHandler}
                        isSearchable/>
        </FormSelectCustom>
      </FormField>

      <div className={st.fieldset}>
        <FormField>
          <FormLabel>Фамилия плательщика:</FormLabel>
          <FormInput>
            <input disabled={isDisabled}
                   value={modalValues.last_name}
                   onChange={e => changeValue(e.target.value, 'last_name')}/>
          </FormInput>
        </FormField>
        <FormField>
          <FormLabel>Имя плательщика:</FormLabel>
          <FormInput>
            <input
              disabled={isDisabled}
              value={modalValues.first_name}
              onChange={e => changeValue(e.target.value, 'first_name')}/>
          </FormInput>
        </FormField>
        <FormField>
          <FormLabel>Кем приходится:</FormLabel>
          <FormInput>
            <input disabled={isDisabled}
                   value={modalValues.who_is_it}
                   onChange={e => changeValue(e.target.value, 'who_is_it')}/>
          </FormInput>
        </FormField>
        <FormField>
          <FormLabel>E-mail:</FormLabel>
          <FormInput>
            <input disabled={isDisabled}
                   value={modalValues.email || ''}
                   onChange={e => changeValue(e.target.value, 'email')}/>
          </FormInput>
        </FormField>
        <FormField>
          <FormLabel>ИНН/ИИН:</FormLabel>
          <InputInn value={modalValues.inn} onChange={e => changeValue(e, 'inn')}
                    disabled={isDisabled}/>
        </FormField>
        <FormField>
          <FormLabel>Телефон:</FormLabel>
          <InputPhone value={modalValues.phone} onChange={e => changeValue(e, 'phone')}
                      disabled={isDisabled}/>
        </FormField>
      </div>

      <FormField>
        <FormLabel isRequired>Способ оплаты:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder="Способ оплаты"
            apiLink="payments/banks"
            getOptionLabel={opt => `${opt.name} ${opt.bin}`}
            formatOptionLabel={formatOptionBank}
            valueID={modalValues.bank}
            onChange={o => {
              changeValue(o.id, 'bank');
              changeValue(o.id === 3, 'is_cash')
            }}
            isSearchable
            error={check('bank')}
            sortKey="name"/>
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel isRequired>Где была принята оплата?</FormLabel>
        <FormSelectCustom>
          <SelectCustom placeholder="Локация"
                        apiLink="payments/pay_locations"
                        apiGetResult={data => data.locations.sort((a, b) => a.name > b.name ? 1 : -1)}
                        valueID={modalValues.paid_location_id}
                        onChange={o => {changeValue(o.id, 'paid_location_id')}}
                        error={check('paid_location_id')}
          />
        </FormSelectCustom>
      </FormField>

      <div className={st.switch}>
        <div className={cn({
          [st.case]: true,
          [st.case__active]: !modalValues.is_cash,
        })} onClick={() => changeValue(false, 'is_cash')}>
          Безналичный расчёт
        </div>
        <div className={cn({
            [st.case]: true,
            [st.case__active]: modalValues.is_cash,
          })}
             onClick={() => changeValue(true, 'is_cash')}>
          Наличный расчёт
        </div>
      </div>

      <div className={st.switch}>
        <div className={cn({
            [st.case]: true,
            [st.case__active]: !modalValues.receipt,
          })}
             onClick={() => {
               changeValue(false, 'receipt')
               deleteValidField('receipt_number')
             }}>
          Без чека
        </div>
        <div className={cn({
            [st.case]: true,
            [st.case__active]: modalValues.receipt,
          })}
             onClick={() => {
               changeValue(true, 'receipt')
               addValidField({
                 receipt_number: 'required'
               })
             }}>
          С чеком
        </div>
      </div>

      <FormField>
        <FormLabel isRequired={modalValues.receipt}>Номер чека:</FormLabel>
        <FormInput isError={check('receipt_number')}>
          <input value={modalValues.receipt_number || ''}
                 onChange={e => changeValue(e.target.value, 'receipt_number')}
                 disabled={!modalValues.receipt}/>
        </FormInput>
      </FormField>

      <FormField>
        <FormLabel>Комментарий:</FormLabel>
        <FormInput>
          <textarea value={modalValues.approved_comment}
                    onChange={e => changeValue(e.target.value, 'approved_comment')}/>
        </FormInput>
      </FormField>
    </FormFieldset>
  )
}

export default observer(ConfirmPayment);