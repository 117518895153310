
export default function useModalValidate(modalStore) {

    const {modalValues, setFormErrors} = modalStore
    const ms = modalStore.data || modalStore.modalSettings

    const isValid = () => {
        if (ms.valid){
            const cfg = {...ms.valid}
            const arr = []
            Object.keys(cfg).forEach(key => {
                if (!checker(modalValues[key], cfg[key]))
                    arr.push(key)
            })

            //modalStore.formErrors = arr
            setFormErrors ? setFormErrors(arr) : modalStore.formErrors = arr
            return arr.length < 1
        } else {
            return true
        }
    }

    const checker = (val, rule) => {
        if (typeof rule === 'function')
            return rule(val)

        switch(rule){
            case 'required':
                return !!val || val === 0
            case 'not_empty_array':
                return val.length > 0
            case 'variants':
                return val?.filter(x => x.value.length < 1).length > 0 || false
            case 'widget_question_variants':
                const valids = [
                    val?.some(x => !x.name.length), 
                    (new Set(val?.map(element => element.name))).size !== val?.length,
                    !val?.some(x => x.is_correct)
                ];
                return !valids.includes(true);
            case "widget_make_sentence":
                const value = `${val.correct_answer}${val.correct_answer.includes("|") ? "" : "|"}`.split("|");
                return value[1].length;
            case 'widget_text_field':
                return val?.correct_answer?.length || false
            case 'widget_tag_variants':
                return !val?.filter(x => x.variants.some(y => !y.name.length))?.length || false
            case 'positive':
                return val > 0
            case 'valid_inn':
                return val.length > 5
            default:
                return true;
        }
    }

    return {
        isValid,
    }
};