import React, {useEffect} from "react";
import {superAxiosRequest} from "axiosApi";
import Paper from "components/UI/Paper/Paper";
import OpenHeader from "../../Groups/components/OpenHeader";
import styles from "./Visits.module.scss";

export default function Visits({visits, fetchVisits, fetchSubscriptionHistory, fetchVisitHistory}) {

  useEffect(() => {
    fetchVisits();
  }, []);

  const onClickHandler = (id) => {
    superAxiosRequest({
      method: "put",
      link: `unidance/visit_report/${id}`,
    })
      .then(() => {
        fetchVisits();
        fetchSubscriptionHistory()
        fetchVisitHistory();
      })
  };

  return (
    <Paper classes={styles.box}>
      <OpenHeader title="Посещения"/>
      <div>
        <div className={styles.visitRow}>
          <div>Клиент</div>
          <div>Дата</div>
          <div>Время</div>
          <div>Мастер</div>
          <div>Тип</div>
          <div></div>
        </div>
        <div>
          {visits?.map(visit =>
            <div key={visit.id} className={styles.visitRow}>
              <div>{visit.client?.full_name}</div>
              <div>{visit.entry_date}</div>
              <div>
                <div>{visit.start_at} - {visit.finish_at}</div>
              </div>
              <div>{visit.master?.full_name}</div>
              <div>{visit.type}</div>
              <div>
                <button
                  onClick={() => onClickHandler(visit.id)}>
                  Возврат
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Paper>
  )
}
