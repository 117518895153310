import React from 'react'
import st from './QuestionsLine.module.scss'
import {classNames as cn} from "helpers";

export default function QuestionsLine(props) {

    const checkReady = item => {
        const arr = props.userAnswers?.filter(x => x.id === item && x.answers && x.answers.length > 0) || []
        return arr.length > 0
    }

    return (
        <div className={st.questions}>{props.questions.map((item, index) =>
            <div key={index} className={cn({
                [st.qn]: true,
                [st.qnActive]: item === props.question[0],
                [st.qnAnswered]: checkReady(item),
            })}>
                {index + 1}
            </div>)}
        </div>
    )
}
