import React, { useState } from 'react';
import { ReactComponent as TelegramAtIcon } from 'components/ContentBox/img/telegram-at.svg';
import { classNames as cn } from "helpers";
import styles from './InputTelegram.module.scss';

const InputTelegram = ({ value = '', onChange, placeholder, error, disabled }) => {
    const [classes, setClasses] = useState('');
    return (
        <div
            className={cn({
                [styles.telegram]: true,
                [styles.error]: error,
                [styles.disabled]: disabled,
                [classes]: classes.length > 0,
            })}
        >
            <div className={styles.telegram__email}>
                <TelegramAtIcon className={styles.telegram__icon} />
            </div>
            <input
                type="text"
                value={value.replaceAll('@', '')}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                onFocus={() => setClasses(styles.focus)}
                onBlur={() => setClasses('')}
            />
        </div>
    )
};

export default InputTelegram;