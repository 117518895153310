import React, { useState, useEffect } from 'react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Paper from 'components/UI/Paper/Paper';
import Button from 'components/UI/Button/Button';
import Flex from 'components/UI/Flex/Flex';
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import InputUrl from "./components/InputUrl";
import Loader from "components/ContentBox/Global/Loader/Loader";
import Stores from 'Stores';
import { superAxiosRequest } from "axiosApi";
import swal from "sweetalert";
import LocalStorage from 'services/LocalStorage';
import styles from "./TeacherSlotSettings.module.scss";

const TeacherSlotSettings = () => {
    const defaultSlot = { is_active: true, url: '' };
    const defaultApiConf = { link: 'online_schedule/teacher/slot-settings', method: 'post' };
    const link = `${Stores.baseLink()}/teacher-record-online-test/`;
    const employee_id = LocalStorage.get('ud').user_data.profile.id;

    const [slotUrl, setSlotUrl] = useState(defaultSlot);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [apiConf, setApiConf] = useState(defaultApiConf);

    const getSetting = () => {
        superAxiosRequest({
            link: 'online_schedule/teacher/slot-settings/' + employee_id,
            method: "get",
        }).then(({ data }) => {
            setSlotUrl({ url: data.url.replace(link, ''), is_active: data.is_active });
            setApiConf({ link: `${defaultApiConf.link}/${employee_id}`, method: 'put' });
        }).catch(() => {
            setSlotUrl(defaultSlot);
            setApiConf(defaultApiConf);
        }).finally(() => setIsLoading(false))
    }

    const sendSlotUrl = () => {
        const valid = slotUrl.url.length;
        if (valid < 3 || valid > 100) {
            setError(true); return;
        }
        const body = { ...slotUrl, url: `${link}${slotUrl.url}`, employee_id };
        setError(false);
        superAxiosRequest(apiConf, body)
            .then(() => {
                swal({ title: 'Настройки успешно сохранены', icon: "success" })
            })
            .catch(({ response }) => {
                if (response.status === 400) {
                    swal({ title: `${response.data.url[0]}`, icon: "error" }); return;
                }
                swal({ title: 'Ошибка!', icon: "error" });
                getSetting();
            });
    };

    useEffect(() => {
        getSetting();
    }, [])

    if (isLoading) return <Loader />
    return (
        <>
            <ContentHeader title="Настройки слотов сотрудника" />
            <div className={styles.wrapper}>
                <Paper classes={styles.settings}>
                    <p className={styles.title}>Основные настройки</p>
                    <Flex>
                        <CheckboxToggle
                            right="Разрешить личную запись на слоты"
                            checked={slotUrl.is_active}
                            onChange={() => setSlotUrl({ ...slotUrl, is_active: !slotUrl.is_active })}
                        />
                    </Flex>
                    <p className={styles.text}>Вы сможете поделиться ссылкой на страницу записи на которой будет только ваши временные слоты</p>
                    <p className={styles.subtitle}>Собственный URL</p>
                    <InputUrl
                        url={slotUrl.url}
                        onChange={(value) => setSlotUrl({ ...slotUrl, url: value.replace(link, '') })}
                        error={error}
                    />
                    <p className={styles.text}>Ваш собственный URL должен содержать от 3 до 100 букв или цифр. Пожалуйста, не используйте пробелы, кириллицу или специальные символы.</p>
                </Paper>
                <Paper>
                    <Flex classes={styles.buttons}>
                        <Button onClick={sendSlotUrl}>Сохранить настройки</Button>
                        <Button onClick={() => window.location.reload()} variant="secondary">Отменить изменения</Button>
                    </Flex>
                </Paper>
            </div>
        </>
    )
}

export default TeacherSlotSettings;