import React, {useEffect, useState} from 'react';
import st from "./TextAnswer.module.scss"

export default function TextAnswer(props) {
  const [text, setText] = useState(props.text)

  useEffect(() => {
    props.onChange(text)
  }, [text])

  return (
    <div className={st.textAnswer}>
      <textarea onChange={e => setText(e.target.value)} value={text} autoFocus onFocus={({target}) => {
        target.setSelectionRange(target.value.length, target.value.length)
      }}/>
    </div>
  );
};
