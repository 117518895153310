import React from "react";
import MyGroups from "components/Modules/User/MyGroups/MyGroups";

const ClientGroups = ({ id }) => {
    const apiConf = {
        method: 'get',
        link: `groups/client_groups${id ? `/${id}` : ''}`,
    };
    return (
        <MyGroups
            apiConf={apiConf}
            getResultApi={data => {
                const result = data.map(item => ({
                    ...item,
                    company: { name: item?.company_name },
                    course_level: { name: item?.course_level_name },
                    location: { name: item?.location_name },
                    status: { id: item?.status },
                    ws_schedules: {
                        ...item?.schedule,
                        online: item?.online
                    },
                }));

                return result;
            }}
        />
    );
}

export default ClientGroups;