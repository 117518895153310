import React, { useState, useEffect } from 'react';
import './Calendar.scss'
import Arrow from '../../img/br_down.png';
import ArrowSlide from '../../img/right-arrow.svg';

import { localisationMaster } from "../../../../Localisation/Localisation";


export default function Calendar(props) {
    const [showDays, setShowdays] = useState(14);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [isLoadDate, setisLoadDate] = useState(false)
    // const [loaded, setLoaded] = useState(null)
    // const [itemsLoaded, setItemsLoaded] = useState(false);
    const [someDay] = useState(props.someDayValue)
    //const [calendar, setCalendar] = useState([]);
    //const [dayCreated, setDayCreated] = useState(false);
    const today = Math.round(new Date(Date.now()).getTime());

    const changeSomeDay = (day, past) => {
        if (props.someDayValue === day) {
            props.setSomeDay(0)
        }
        if (checkToday(day, 0, 'yesterday') && !past) {
        }
        else {
            //setSomeDay(day)
            props.setSomeDay(day);
        }
    }

    function timeConverter(UNIX_timestamp, short) {
        var a = new Date(UNIX_timestamp);
        var months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
        var months_shrt = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var month_shrt = months_shrt[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ', ' + year;
        if (short === 'day') {
            return date + ' ' + month;
        } if (short === 'today') {
            if (date < 10) {
                date = '0' + date
            }
            return year + '-' + month_shrt + '-' + date;
        } else {
            return time
        }
    }


    const makePoolDates = () => {
        if (props.SaE) {
            setisLoadDate(true)
            setStartDate(props.SaE[0]);
            setEndDate(props.SaE[1]);
        } else {
            setisLoadDate(true)
            var newDate = new Date(Date.now());
            var day = (new Date(Date.now())).getDay();
            var start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
            //var start = newDate.setDate(newDate.getDate() - parseInt(day));
            var end = newDate.setDate(newDate.getDate() + showDays - 1);
            setStartDate(start);
            setEndDate(end);
        }

    }


    const moreLess = () => {
        if (props.loaded.length === 0) {
            return (
                <div className="shedule-calendar-more" >
                    {localisationMaster('Choose-a-location', 'online-schedule/time-slots')}
                </div>
            )
        }
        else {
            if (showDays === 28) {
                return (
                    <div className="shedule-calendar-more" onClick={() => { setShowdays(14); setisLoadDate(false) }}>
                        <img src={Arrow} alt="" />
                        {localisationMaster('info-calendar-1', 'online-schedule/time-slots')}
                        <img src={Arrow} alt="" />
                    </div>
                )
            } else if (showDays === 14) {
                return (
                    <div className="shedule-calendar-more" onClick={() => { setShowdays(28); setisLoadDate(false) }}>
                        <img src={Arrow} alt="" />
                        {localisationMaster('info-calendar-2', 'online-schedule/time-slots')}
                        <img src={Arrow} alt="" />
                    </div>
                )
            }
        }
    }
    const checkToday = (full_date, date, type) => {
        switch (type) {
            case 'today':
                if (full_date === timeConverter(today, 'today')) {
                    return <strong>{date}, {localisationMaster('Today', 'online-schedule/time-slots')}</strong>
                } else {
                    return <span className="shedule-calendar__dayNumber"><strong>{date}</strong></span>
                }
            case 'yesterday':
                let now = new Date(Date.now()).getFullYear();
                now += '-' + (new Date(Date.now()).getUTCMonth() + 1);
                now += '-' + new Date(Date.now()).getDate();
                now = new Date(now).getTime() / 1000;
                let date_to_check = new Date(full_date).getTime() / 1000;
                if (date_to_check < now) {
                    return true
                } else {
                    return false
                }
            default: break
        }
    }

    const checkPoolDay = (dow, date) => {
        let now = new Date(Date.now()).getFullYear();
        now += '-' + (new Date(Date.now()).getUTCMonth() + 1);
        now += '-' + (new Date(Date.now()).getDate());
        now = (new Date(now).getTime() + (604800 * 1000));
        var ckecked = new Date(date).getTime();
        if (props.dayInAction !== null) {
            let dayss = props.days.filter(item => item['id'] === +props.dayInAction)
            if (dayss[0]['compiled'] === false) {
                if (dow === dayss[0]['first'] && ckecked > now) {
                    return true;
                } else {
                    return false;
                }
            } else if (dayss[0]['compiled'] === true) {
                if (dow >= dayss[0]['first'] && dow <= dayss[0]['last'] && ckecked > now) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }


    const calendarBodyCreate = (type, items) => {
        var body;
        switch (type) {
            case 'slots_admin':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday') ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full || checkPoolDay(item.dow, item.date_full) ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => { changeSomeDay(item.date_full); props.checkSavedCounts(item.date_full, item.dow, item.slotSize) }}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            <div>{item.time}</div>
                            <div>{item.slotSize}</div>
                        </div>
                    )
                })
                return (body)
            case 'slots_admin_booking':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday') ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => changeSomeDay(item.date_full)}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            <div>{item.time}</div>
                            <div className="slots-count">Слотов: {item.count ? item.count : '0'}</div>
                            <div>{item.slotSize}</div>
                        </div>
                    )
                })
                return (body)
            case 'slots_teacher':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday') ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => changeSomeDay(item.date_full)}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            {item.time.length > 0 ?
                                <>
                                    <div className="shedule-calendar-items-list-item__slots-title">Слотов:</div>
                                    <div className="shedule-calendar-items-list-item__slots-length">{item.time.length}</div>
                                </>
                                :
                                <div>Слотов нет</div>}

                            {/* {item.time.length > 0 ? item.time.map((item, index) => {
                        return(
                        <div key={index} style={{textDecoration: !item.is_active && 'line-through'}}>
                            {item.t}
                            {!item.is_visitor && '*'}
                            </div>
                        )
                    }) : item.time} */}
                        </div>
                    )
                })
                return (body)

            case 'slots_widget_booking':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday') ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => changeSomeDay(item.date_full)}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            {
                                !checkToday(item.date_full, 0, 'yesterday') ?
                                    <>
                                        <div >Доступные <br /> времена:</div>
                                        <div> {item.count > 0 ?
                                            <span style={{ color: "#00cc00", fontWeight: '600', fontSize: '14px' }}>Есть</span>
                                            :
                                            <span style={{ color: "#cc1d00", fontWeight: '600', fontSize: '14px' }}>Нет</span>}
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                    )
                })
                return (body)

            case 'slots_client_count':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday')
                            && props.someDayValue !== item.date_full
                            ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => changeSomeDay(item.date_full, true)}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            {
                                item.clients_count > 0 ?
                                    <div>{localisationMaster('clients', 'online-schedule/time-slots')}<br />
                                        <span className="shedule-calendar-items-list-item-count">{item.clients_count}</span></div>
                                    :
                                    <div>{localisationMaster('entries', 'online-schedule/time-slots')}</div>
                            }
                        </div>
                    )
                })
                return (body)
            case 'clean':
                body = items.slice(0, showDays).map((item, index) => {
                    return (
                        <div style={checkToday(item.date_full, 0, 'yesterday')
                            && props.someDayValue !== item.date_full
                            ? { backgroundColor: "#f1f1f1" } : null}
                            className={props.someDayValue === item.date_full ?
                                "shedule-calendar-items-list-item scil-active" :
                                "shedule-calendar-items-list-item"}
                            key={index}
                            onClick={() => changeSomeDay(item.date_full, true)}>
                            <div>{checkToday(item.date_full, item.date, 'today')}</div>
                            <div></div>
                        </div>
                    )
                })
                return (body)
            default:
                break;

        }
    }


    useEffect(() => {
        if (!isLoadDate) {
            makePoolDates();
        }

    }, [someDay, isLoadDate]);

    useEffect(() => {
        makePoolDates();
    }, [props.SaE])


    return (
        <>
            <div className="shedule-calendar shedule-calendar_margin shedule-calendar_width">
                <div className="shedule-calendar-header">
                    {timeConverter(startDate, 'day')} - {timeConverter(endDate)} года
                    {props.slide &&
                        <div className="shedule-calendar-header__slide">
                            <div className="shedule-calendar-header__slide--left_but"
                                onClick={() => props.slideAction(new Date(props.SaE[0]), 'prev')}>
                                <img src={ArrowSlide} alt="alt" />
                            </div>
                            <div className="shedule-calendar-header__slide--right_but"
                                onClick={() => props.slideAction(new Date(props.SaE[0]), 'next')}>
                                <img src={ArrowSlide} alt="alt" />
                            </div>
                        </div>
                    }
                </div>
                <div className="shedule-calendar-days-list">
                    <div>{localisationMaster('day-1', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-2', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-3', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-4', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-5', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-6', 'online-schedule/time-slots')}</div>
                    <div>{localisationMaster('day-7', 'online-schedule/time-slots')}</div>
                </div>
                <div className="shedule-calendar-items-list">
                    {
                        calendarBodyCreate(props.calendarType, props.loaded)
                    }
                </div>
            </div>
            {moreLess()}
        </>
    )
}
