import React from 'react';
import style from './ActiveLessonsGroup.module.scss'
import {ReactComponent as ActiveLessonImg} from "./img/online-lesson.svg";
import {SuperButton} from "components/UI/SuperButton/SuperButton";

const ActiveLessonsGroup = ({item,isClient}) => {

    return (
        <div className={style.container}>
            <div>
                <ActiveLessonImg/>
            </div>
            <div className={style.lessonInfo}>
                <div className={style.item}>
                    <div className={style.lessonStatus}></div>
                    <h3>{`${item.ebook.name} - ${item.chapter.name}`}</h3>
                </div>

                <SuperButton
                    variant='link'
                    type={'linkColored'}
                    path={isClient ? `/ebooks/my-lesson/${item.uuid}` :`/ebooks/lesson/${item.uuid}`}
                >
                    Go to Lesson
                </SuperButton>
            </div>
        </div>
    );
};

export default ActiveLessonsGroup;