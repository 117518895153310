import React from 'react';
import { Link } from "react-router-dom";
import logo from "components/Auth/logo.png";
import styles from "./ErrorPage.module.scss";

const ErrorPage = () => {
    return (
        <div className={styles.page}>
           <img src={logo} alt="logo" />
           <div className={styles.content}>
               <h1>404</h1>
               <p className={styles.subtitle}>Страница не найдена</p>
               <p className={styles.text}>Неправильно набран адрес, или такой страницы больше не существует.</p>
               <div></div>
               <Link to="/">Вернуться на главную страницу</Link>
           </div>
        </div>
    )
}

export default ErrorPage;