import React from 'react';
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import {SuperButton} from 'components/UI/SuperButton/SuperButton';
import ActionMenu from 'components/UI/ActionMenu/ActionMenu';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import Stores from "Stores";
import swal from "sweetalert";
import {permissions} from "settings/permissions";
import styles from "./Lesson.module.scss";

const Lesson = ({group, chapter, is_finish, start_date, end_date, translate, uuid, ebook, updateLessons}) => {

  const menu = [
    {name: translate.copy_link, action: 'copy'},
    {name: translate.cancel_lesson, action: 'cancel'},
  ]

  const apiConf = {
    method: 'delete',
    link: `ebooks/active_lessons/${uuid}`
  }

  const action = (actionType) => {
    switch (actionType) {
      case 'cancel':
        superAxiosRequest(apiConf).then(() => {
          swal(
            translate.lesson_canceled,
            {icon: "success"}
          )
          updateLessons()
        })
        break;
      case 'copy':
        const linkToCopy = `${Stores.baseLink()}/ebooks/my-lesson/${uuid}`
        navigator.clipboard.writeText(linkToCopy).then(() => {
          swal(
            translate.link_copied,
            {icon: "success"}
          );
        })
        break;
      default:
        break;
    }
  }

  return (
    <div className={styles.row}>
      <div className={`${styles.col} ${styles.titleBox}`}>
        <Link className={styles.linkWrapper} to={`/groups/group/${group.id}`}>
          <div className={styles.subtitle}>{`${group.number} - ${group.name}`}</div>
        </Link>
        <Link className={styles.linkWrapper} to={`/ebooks/${Stores.isClient ? 'my-' : ''}lesson/${uuid}`}>
          <div className={styles.title}>{`${ebook.name} - ${chapter.name}`}</div>
        </Link>
      </div>
      <div className={`${styles.col} ${styles.dateBox}`}>
        <div className={styles.date}>
          <b>{timeConverter(start_date, 'time')}</b>, {timeConverter(start_date, 'clean_data')}
        </div>
        <div className={styles.date}>
          <b>{timeConverter(end_date, 'time')}</b>, {timeConverter(end_date, 'clean_data')}
        </div>
      </div>
      <div className={styles.badgeWrapper}>
        <SuperButton variant="badge" type={!is_finish ? 'badgeColored' : undefined}>
          {is_finish ? translate.finished_filter : translate.in_progress_badge}
        </SuperButton>
      </div>
      <div className={styles.btnBox}>{!is_finish ?
        <>
          <SuperButton variant="link"
                       type="linkColored"
                       path={`/ebooks/${Stores.isClient ? 'my-' : ''}lesson/${uuid}`}>
            {translate?.lesson_link}
          </SuperButton>
          {!Stores.isClient && checkPermList(permissions.delete_init_lesson) &&
            <ActionMenu menu={menu} action={action}/>}
        </>
        :
        <SuperButton variant="link"
                     path={`/ebooks/${Stores.isClient ? 'my-' : ''}past-lesson/${uuid}`}>
          {translate?.history_link}
        </SuperButton>
      }
      </div>
    </div>
  )
}

export default Lesson;
