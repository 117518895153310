import React from 'react';
import st from './GeneralPoints.module.scss'

export default function GeneralPoints({stats}) {
    const answers = Math.round(stats.correct_answers / stats.total_answers * 100);
    return (
        <div className={st.box}>
            <header>
                <div>Правильных ответов</div>
                <div className={st.points}>
                    <span>{stats.correct_answers}</span>
                    <span>/</span>
                    <span>{isNaN(answers) ? 0 : answers}%</span>
                </div>
            </header>
            <footer>
                <div>Количество баллов</div>
                <div className={st.points}>
                    <span>{stats.points}</span>
                </div>
            </footer>
        </div>
    );
};
