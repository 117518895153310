import React from 'react';
import Select from '../../Select/Select';
//import {superAxiosRequest} from 'axiosApi';

//import Stores from 'Stores';

export default function BaseTemplate(props) {


    const changeHandler = (e, key_name, type) =>{
        props.changeHandler(e, key_name, type);
    }
   
    const returnValue =(key_name)=>{
        if(props.values !== null && props.values !== undefined && props.values.lenght !== 0){
            return props.values[key_name]
        } else { 
            return ''
        }
    }


    return ( 
       <div>
            <div className="super-modal-label">{props.lable_text}</div>
            <input
                placeholder={props.pl_text}
                name="valueInput"
                type="text"
                value={returnValue(props.name_text)}
                onChange={(e)=>changeHandler(e, props.name_text)}
                style={{borderColor: props.errorCheker(props.name_text)}}
                />
            
            <div className="super-modal-label">{props.lable_select}</div>
            <Select onChange={props.ChangeError} 
                value_error={props.errorCheker(props.name_select) === '#db3031' && true}
                valueId={returnValue(props.name_select)}
                nameState={props.name_select}
                selected={0} stateError={props.name_select + "_error"}
                myChangeHandler={(e)=>changeHandler(e, props.name_select, 'int')} 
                text={props.pl_select} 
                value={props.items}
                ChangeError={props.ChangeError}
                width="100%"/>
       </div>
    )
}
