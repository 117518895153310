import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import {permissions} from 'settings/permissions';
import StudentStudyTypes from 'components/ContentBox/Global/GlobalModalSingle/templates/groups/StudentStudyTypes';
import swal from 'sweetalert'
import {observer} from "mobx-react";
import {checkPermList} from 'helpers';
import {dateFormat} from "helpers/date";
import {localisationMaster} from "Localisation/Localisation";
import {agetostr, timeConverter} from 'components/ContentBox/Online-schedule/components/functions'
import {correctDate} from 'components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendarFunctions';
import Stores from 'Stores';
import groupStore from 'Stores/groupStore';
import modalStore from "Stores/modalStore";
import TimeTableCalendar from 'components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendar';
import InfoCardStudent from 'components/ContentBox/Company/Employee/Tabs/InfoCardStudent';
import StudentPrice from "components/ContentBox/Global/GlobalModalSingle/templates/groups/StudentPrice";
import Rating from "components/ContentBox/Global/GlobalModalSingle/templates/groups/Rating";
import bcake from 'components/ContentBox/img/birthday-cake.svg'
import {translate as loc, dict} from "Localisation";
import Calendar from "./Workshop/Calendar";
import ModalStudyPeriod from "./Workshop/ModalStudyPeriod";
import {useMemo} from "react";
import asideModalStore from "Stores/asideModalStore";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import {EditIconButton} from "components/UI/SuperButton/SuperButton";
import style from './StudentCard.module.scss'
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";
import {ReactComponent as ArrowIcon} from "components/UI/icons/arrow-down-s-line.svg";
import {ReactComponent as ChatIcon} from "components/UI/icons/chat-1-line.svg";

const StudentCard = props => {
  const isAdmin = Stores.isAdmin
  const {activity} = modalStore;
  const {group, wsData: {workshops: wsDays}, fetchGroup} = groupStore
  const [calendar, setCalendar] = useState([false, []])
  const [open, setOpen] = useState(false)
  const [lessonsLeft, setLessonsLeft] = useState(0)
  const [comment, setComment] = useState(props?.student?.comment);

  const isNewSchedule = group.group.new_schedule;
  const menu = useMemo(() => {
    return [
      {
        name: props.translate.edit_profile,
        action: 'edit_profile',
        perm: permissions.edit_client_profile
      },
      {
        name: props.translate.go_to_profile,
        action: 'profile',
        link: {
          to: `/account_clients/${props.student.user_id}`,
          target: '_blank'
        } ,
        perm: permissions.view_client_profile
      },
      {
        name: props.translate.change_status,
        action: 'statuses',
        perm: permissions.add_student_to_group
      },
      {
        name: props.translate.surcharge,
        action: 'surcharge',
        perm: permissions.add_discount_in_group
      },
      {
        name: props.translate.discount,
        action: 'discount',
        perm: permissions.add_discount_in_group
      },
      {
        name: props.translate.price,
        action: 'price',
        perm: permissions.add_student_default_price
      },
      {
        name: props.translate.study_dates,
        action: 'date-range',
        perm: permissions.add_student_to_group
      },
      {
        name: props.translate.details,
        action: 'detail',
        perm: permissions.add_student_to_group
      },
      {
        name: props.translate.study_type,
        action: 'study-type',
        perm: permissions.add_student_to_group
      },
      {
        name: props.translate.add_certificate,
        action: 'addCertificate',
        perm: permissions.add_progress_report
      },
      ...(comment === null ? [{
        name: props.translate.add_comment,
        action: 'change-comment-client',
        perm: permissions.add_client_group_comment
      }] : []),
    ]
  }, [comment, props.student.user_id]);

  const createCalendar = (start, end, weekdays) => {
    start = correctDate(start)
    end = correctDate(end)
    let y, m, d;
    let calendar = [];
    let start_at = new Date(start + ' 12:00:00');
    let end_at = new Date(end + ' 12:00:00');
    let true_days = [];
    for (let i = 0; i < weekdays.length; i++) {
      true_days.push(
        weekdays[i].day
      )
    }
    while (start_at <= end_at) {
      var next_date = start_at.setDate(start_at.getDate());

      var next_days_date = new Date(next_date);
      var day_index = next_days_date.getDay();
      if (day_index === 0) {
        day_index = 7
      }
      if (true_days.includes(day_index)) {
        m = next_days_date.getMonth() + 1;
        d = next_days_date.getDate();
        y = next_days_date.getFullYear();
        if (+d < 10) {
          d = '0' + d
        }
        if (+m < 10) {
          m = '0' + m
        }
        calendar.push(y + '-' + m + '-' + d);
      }
      // increment the date
      start_at = new Date(start_at.setDate(start_at.getDate() + 1));
    }
    return calendar
  }

  const clientInfo = (id, profile) => {
    let modal_settings = {
      link: 'accounts/clients/' + profile,
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: localisationMaster('text_3', 'online-schedule/clients-after-online'),
      type: 'client_info',
      client_id: id,
      profile: profile,
      is_put: true,
      isResponsive: true,
      valid: {
        first_name: 'required',
        last_name: 'required',
        inn: 'required',
        date_of_birth: 'required',
        phone: 'required',
        email: 'required',
      },
      onSubmitSuccess: () => groupStore.fetchGroup(props.groupID)
    }
    asideModalStore.activity(modal_settings)
  }

  const changeCommentClient = (is_put = false) => {
    const modal_settings = {
      name: 'change_client_group_comment',
      link: is_put ? `groups/client_group_comment/${comment?.id}` : 'groups/client_group_comment',
      button: is_put ? 'Редактировать' : 'Добавить',
      title: is_put ? 'Редактировать комментарий' : 'Добавить комментарий',
      type: 'change_client_group_comment',
      data: 'json',
      width: '620px',
      is_put,
      valid: { text: 'required' },
      onSubmitSuccess: (data) => setComment({ ...comment, text: data.text, id: data.id })
    }

    asideModalStore.activity(
      modal_settings,
      {
        student: props.student.id,
        group: props.groupID,
        ...(is_put && { text: comment?.text }),
      }
    );
  };

  const createDateDelta = useCallback(array => {
    const start = props.student.start_study_at
    const finish = props.student.finish_study_at
    if (array !== undefined && array.length !== 0) {
      let max_array = [];
      let new_arr = [];
      for (let i = 0; i < array.length; i++) {
        new_arr = createCalendar(array[i].date_from, array[i].date_until, array[i].weekdays, finish)
        for (let y = 0; y < new_arr.length; y++) {
          max_array.push(
            {
              disabled: new Date(start) > new Date(new_arr[y]) || (!!finish && new Date(new_arr[y]) > new Date(finish)),
              date: new_arr[y],
              schedule_id: array[i].id,
              classroom: array[i].classroom && array[i].classroom.name,
              teachers: array[i].teachers,
              start_at: array[i].start_at,
              finish_at: array[i].finish_at,
              comment: array[i].comment,
            }
          )
        }
      }
      setCalendar([true, max_array])
    } else {
      setCalendar([false, []])
    }
  }, [props.student.finish_study_at, props.student.start_study_at])

  const openModalStatus = student => {
    const statuses = [
      {id: 1, name: localisationMaster('actual', 'groups/group', 'statuses')},
      {id: 2, name: localisationMaster('trained', 'groups/group', 'statuses')},
      {id: 3, name: localisationMaster('interrupted_training', 'groups/group')},
      {id: 4, name: localisationMaster('text_18', 'groups/all-groups')},
      {id: 5, name: localisationMaster('deleted', 'groups/group')},
    ]
    const ms = {
      type: 'generator',
      title: `${Stores.transliterate(`${student.last_name} ${student.first_name}`)}: ${localisationMaster('training_status', 'groups/group', 'statuses')}`,
      button: localisationMaster('text_54', 'groups/all-groups'),
      fields: [
        {
          type: 'select',
          label: 'Training Status:',
          name: 'student_status',
          options: statuses,
          onChange(data, changeValues) {
            changeValues(data.id , 'student_status')
            if(data.id === 1) changeValues(null , 'finish_study_at')
          }
        },
        {
          type: 'toggle-checkbox',
          name: 'send_email',
          label: 'Отправить уведомление'
        },
      ],
      link: 'groups/admin_edit_client_to_group',
      is_put: true,
      //func: groupStore.updateSchedule
      // TODO: change response
      onSubmitSuccess: () => groupStore.fetchGroup(group.group.id)
    }
    const md = {
      group_id: group.group.id,
      client_id: student.id,
      student_status: student.status,
      start_study_at: student.start_study_at,
      finish_study_at: dateFormat(new Date(), "YYYY-mm-dd")
    }

    asideModalStore.activity(ms, md)
  }

  const openModalDorS = (client, action) => {
    const isDiscount = action === 'discount'
    const ms = {
      type: 'add-dors',
      link: `${isDiscount ? 'groups/student_discounts' : 'groups/student_surcharges'}`,
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: `${Stores.transliterate(`${client.last_name} ${client.first_name}`)}: ${isDiscount ? 'Discount' : 'Surcharge'}`,
      action,
      valid: {
        [isDiscount ? 'discount_id' : 'surcharge_id']: 'required',
        date_from: 'required'
      },
      onSubmitSuccess: (data) => isDiscount ? groupStore.updateDiscountOrSurcharge(data, client.id, true, 'discount') : groupStore.updateDiscountOrSurcharge(data, client.id, true, 'surcharge'),
      // onSubmitError: (err) => {
      //   if(err.response.status !== 400) return;
      //   swal({
      //     text: err.response.data,
      //     icon: "error",
      //   });
      // }
    }
    const md = {
      group_id: group.group.id,
      client_id: client.id,
      date_from: dateFormat(new Date(), "YYYY-mm-dd"),
    }
    asideModalStore.activity(ms, md)
  }

  const openModalPrice = data => {
    const ms = {
      title: `${Stores.transliterate(`${data.last_name} ${data.first_name}`)}: Prices`,
      modalBodyComponent: () => <StudentPrice/>,
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: 'groups/student_default_price',
      onSubmitSuccess: () => {
        groupStore.fetchGroup(group.group.id)
      },
      valid: {
        default_price_id: 'required',
        date_from: 'required'
      }
    }
    const md = {
      group_id: group.group.id,
      client_id: +data.id,
      date_from: dateFormat(new Date(), "YYYY-mm-dd"),
    }
    asideModalStore.activity(ms, md)
  }

  const openModalStudyPeriod = student => {
    const ms = isNewSchedule ? {
      title: ` ${Stores.transliterate(`${student.last_name} ${student.first_name}`)}: ${localisationMaster('study_dates', 'groups/group')}`,
      modalBodyComponent: () => <ModalStudyPeriod/>,
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: "schedule/ws_change_status_student_sdn",
      valid: {
        start_study_at: 'required',
      },
      onSubmitSuccess: data => {
        groupStore.updateGroupData({students: data.students})
      },
    } : {
      type: 'set-data-range',
      name: 'set-data-range',
      button: localisationMaster('text_54', 'groups/all-groups'),
      title: ` ${Stores.transliterate(`${student.last_name} ${student.first_name}`)}: ${localisationMaster('study_dates', 'groups/group')}`,
      is_put: true,
      link: 'groups/admin_edit_client_to_group',
      width: '620px',
      payload: {
        lastDate: calendar[1][calendar[1].length - 1].date,
      },
      valid: {
        start_study_at: 'required',
      },
      onSubmitSuccess: groupStore.updateGroupData,
    }
    const md = isNewSchedule ? {
      group_id: group.group.id,
      client_id: props.student.id,
      workshop_id: [],
      start_study_at: null,
      finish_study_at: null,
    } : {
      group_id: group.group.id,
      client_id: student.id,
      start_study_at: student.start_study_at,
      ...(student.finish_study_at && {finish_study_at: student.finish_study_at})
    }
    asideModalStore.activity(ms, md)
  }
    
  const openModalStudyType = student => {
    const ms = {
      title: `${Stores.transliterate(`${student.last_name} ${student.first_name}`)}: Study Types`,
      modalBodyComponent: () => <StudentStudyTypes />,
      button: localisationMaster('text_54', 'groups/all-groups'),
      is_put: true,
      link: 'groups/admin_edit_client_to_group',
      onSubmitSuccess: () => {
        groupStore.fetchGroup(group.group.id)
      },
  
    }
    const md = {
      group_id: group.group.id,
      client_id: student.id,
      payment_type: student.education_type,
      start_study_at: student.start_study_at,
    }
    asideModalStore.activity(ms, md)
  }

  const openModalRating = data => {
    const ms = {
      title: `${localisationMaster('rate_list', 'groups/group')}: ${data.last_name} ${data.first_name}`,
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: 'progress_report/report',
      modalBodyComponent: () => <Rating/>,
      payload: {
        student_name: `${data.last_name} ${data.first_name}`,
      },
      func: () => fetchGroup(group.group.id),
      width: 600,
    }
    modalStore.activity(ms, {
      client: props.student.id,
      group: group.group.id,
      evaluation_date: dateFormat(new Date(), 'YYYY-mm-dd')
    })
  }

  const addCertificate = () => {
    const ms = {
      title: `Добавить сертификат`,
      button: localisationMaster('text_54', 'groups/all-groups'),
      link: 'groups/add_student_cert_to_group',
      type: 'AddCertificate',
      name: 'AddCertificate',
      valid: {
        certificate: 'required',
        date_from: 'required',
      },
      onSubmitSuccess: () => fetchGroup(group.group.id),
    }
    asideModalStore.activity(ms, {
        student: props.student.id,
        group: group.group.id,
    })
  }

  const action = (action, e, item) => {
    switch (action) {
      case 'date-range':
        openModalStudyPeriod(item)
        break;
      case 'edit_profile':
        clientInfo(props.student.user_id, props.student.id)
        break;
      case 'discount':
        openModalDorS(item, action)
        break;
      case 'surcharge':
        openModalDorS(item, action)
        break;
      case 'price':
        openModalPrice(item)
        break;
      case 'statuses':
        openModalStatus(item)
        break;
      case 'detail':
        setOpen(!open)
        break;
      case 'rating':
        openModalRating(item)
        break;
      case 'addCertificate':
        addCertificate()
        break;
      case 'study-type':
        openModalStudyType(item)
        break;
      case 'change-comment-client':
        changeCommentClient();
        break;
      default:
        break;
    }
  }

  const checkDoB = (dob) => {
    if (typeof dob !== 'object') {
      let bday = new Date(dob)
      bday.setYear(new Date().getUTCFullYear());

      if (new Date() > bday) {
        let currentDate = Date.parse(new Date());
        let days = (currentDate - Date.parse(bday)) / 86400000;       //86400000 - ms в дне
        if (Math.round(days) <= 4) {
          return true
        }
      } else if (new Date() < bday) {
        let currentDate = Date.parse(new Date())
        let days = (currentDate - Date.parse(bday)) / 86400000       //86400000 - ms в дне
        let clean = Math.round(days) * -1
        if (clean <= 4) {
          return true
        }
      } else {
        return false
      }
    }
  }

  const topUp = (name, student_group_rel) => {
    const ms = {
      link: 'payments/pay_orders',
      button: 'Добавить',
      title: `Пополнение баланса: ${name}`,
      type: 'addPayment',
      width: '650px',
      client_id: props.student.id,
      func: (data) => {
        swal("", `Оплата с номером ${data?.order_id} успешно создана`, "success")
      }
    }
    activity(
        ms,
        {
          student_group_rel,
          status_sending_mail: 'need_mail',
          amount: 0
        })
  }

  useEffect(() => {
    createDateDelta(props.schedule)
  }, [createDateDelta, props.schedule, props.student])

  const getSchoolDaysSummaryInfo = () => {
    const studentId = props.student.id
    const schoolDayStatuses = props.statuses
    const studentDays = wsDays?.reduce((acc, current) => {
      return acc.concat(current.student_statuses);
    }, []).filter(days => days.student_id === studentId).reduce((acc, current) => {
      acc[current.status_sdn] = (acc[current.status_sdn] || 0) + 1;
      return acc;
    }, {})
    return Object.entries(studentDays)
        .filter(([key]) => schoolDayStatuses.find(el => el.id == key))
        .map(([key, value]) => {
      return `${schoolDayStatuses.find(el => el.id == key).name} - ${value}`
    })
  }

  return (
      <>
        <div className={style.studentCardBox}>
          <div className={style.header}>
            <div className={style.units}>
              <div className={style.unitName}>
                {checkDoB(props.student.date_of_birth) &&
                  <div>
                    <img src={bcake} alt='bday' className={style.birthdayIcon}/>
                  </div>}
                <span className={style.studentName}
                      style={{
                        pointerEvents: checkPermList(permissions.view_client_profile) ? '' : 'none'
                      }}>
                  <NavLink to={'/account_clients/' + props.student.user_id} target='_blank'>
                    {Stores.transliterate(props.student.last_name + ' ' + props.student.first_name)}
                  </NavLink>
                  {wsDays && getSchoolDaysSummaryInfo().length > 0 &&
                    <div className={`${style.studentTooltip}`}>
                      {wsDays && getSchoolDaysSummaryInfo().map((el, index) => (
                          <div key={index}>
                            {el}
                          </div>
                      ))}
                    </div>
                  }
                </span>
                <div className={`${style.dateOfBirth} ${checkDoB(props.student.date_of_birth) && style.birthday}`}>
                  <span>{agetostr(props.student.date_of_birth)}</span>
                  <div>{timeConverter(props.student.date_of_birth, 'age')}</div>
                </div>
              </div>
              <span className={style.phone}>
                  <a href={`tel:${props.student.phone}`}>{props.student.phone}</a>
                </span>
              <div className={style.unitAmount} style={{color: props.student.balance <= 0 ? 'var(--color-danger-1)' : ''}}>
                {Stores.Rules.checkRule('studygroup', 'add_client') && Date.now() < 0 &&
                    <>
                      {Stores.cleanSumm(props.student.balance !== 0 ? props.student.balance / 100 : 0) + ' ₸'}
                      <button onClick={() => topUp(props.student.full_name, props.groupID)} className={`${style.addBalanceBtn} ${props.student.balance <= 0 && style.debt}`}>
                        <PlusIcon/>
                      </button>
                    </>}
              </div>
            </div>
            {isAdmin &&
              <div className={style.headerActions}>

                <span className={style.openInfo}
                      onClick={() => setOpen(!open)}>
                  <ArrowIcon style={{transform: open ? 'rotate(180deg)' : '', transition: 'all .3s ease'}}/>

                </span>
                <ActionMenu menu={menu} action={action} items={props.student} id={props.student.user_id}/>
              </div>}
          </div>

          {(comment !== null && checkPermList(permissions.view_client_group_comment)) && (
            <div className={style.header}>
              <div className={style.headerComment}>
                <div>
                  <ChatIcon/>
                  <p>{comment?.text}</p>
                </div>
                {checkPermList(permissions.change_client_group_comment) && (
                    <EditIconButton onClick={() => changeCommentClient(true)}/>)}
              </div>
            </div>)}

          {(calendar[0] || isNewSchedule) ?
            <>
              {(open && checkPermList(permissions.add_student_to_group)) &&
                <InfoCardStudent
                  default_price={props.student.default_price}
                  student={props.student}
                  lessonsLeft={lessonsLeft}
                  group_id={group.group.id}
                />}

              {isNewSchedule ?
                <Calendar student={props.student} isStudent studentID={props.student.id}/>
                :
                <TimeTableCalendar
                  items={calendar[1]}
                  clickDisabled={props.clickDisabled}
                  holidays={props.holidays}
                  statuses={props.statuses}
                  school_day_notes={props.school_day_notes}
                  schooldays={props.schooldays}
                  uid={props.student.id}
                  isStudent
                  lessonsLeft={setLessonsLeft}
                  gid={group.group.id}
                  student={props.student}/>}
            </>
            : <div className="sg-probka">
              {localisationMaster('text_51', 'groups/all-groups')}
            </div>}
        </div>
      </>
  )
}

StudentCard.propTypes = {
  student: PropTypes.object.isRequired,
}

export default observer(StudentCard)
