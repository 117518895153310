import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import Stores from "Stores";
import style from './StudentsProgressReport.module.scss'
import RatingStars from "../AverageProgressReport/RatingStars/RatingStars";
import {EditIconButton} from "components/UI/SuperButton/SuperButton";
import {ReactComponent as Delete} from "components/UI/icons/delete-bin-6-line.svg";
import ModalMoreInfo from "./ModalMoreInfo/ModalMoreInfo";

const StudentsProgressReport = ({item, actions,listing}) => {
    const [isOpen, setIsOpen] = useState(false)
    const ratings = item.rate_list.map(item => item.value); // Получаем массив всех значений rating
    const averageRating = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length; // Считаем среднее значение
    const isClient = Stores.isClient

    return (
        <div key={item.id} className={style.report} style={listing && {border: 'none'}}>
            <div className={style.box}>

            {!isClient &&   
                <div className={style.info}>
                    <NavLink target="_blank" to={'/account/' + item.user_id}>
                        {Stores.transliterate(`${ Stores.transliterate(item.client_name)}`)}
                    </NavLink>
                </div>
            }
                <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    <RatingStars rating={averageRating} />
                    {   
                        isOpen && <div>
                            <ModalMoreInfo item={item} averageRating={averageRating}/>
                        </div>
                    }

                </div>

                <div className={style.description}>
                    <div dangerouslySetInnerHTML={{__html: item.comment}}/>
                </div>

            </div>

            {!isClient &&
                    <div className={style.buttons}>
                        <EditIconButton onClick={(e) => actions('edit', e, item)}/>
                        <div className={style.deleteBasket} onClick={(e) => actions('delete', e, item)}>
                        <Delete/>
                        </div>
                    </div>
            }
            
        </div>
    );
};

export default StudentsProgressReport;