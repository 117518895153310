import React, {Fragment, useState, useEffect} from 'react';
import {shuffle} from 'helpers';
import st from "./SelectWords.module.scss"

export default function SelectWords(props) {
    const [answers, setAnswers] = useState(props.answers || [])

    let text = props.text

    const reg = /\[(.*?)]/g;
    let m, arr;
    const insertVariants = [];
    text = text.replaceAll('<div>', '<br/>')
    text = text.replaceAll('<br>', '')
    text = text.replaceAll('</div>', '')
    text = text.replaceAll('\\', '|')
    for (m = reg.exec(text); m; m = reg.exec(text)) {
        arr = m[1].split('|')
        insertVariants.push(shuffle(arr))
    }
    const aCleanText = text.replace(reg, '@').split('@').filter(t => t.length)

    const myChangeHandler = (e, index) => {
        const arr = [...answers];
        arr[index] = e.target.value
        setAnswers(arr)
    }

    useEffect(() => {
        props.handleAnswers(answers)
    }, [answers])

    return (
        <div className={st.text}>
            {aCleanText.map((item, index) =>
                <Fragment key={index}>
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                    {!!insertVariants[index] &&
                    <select
                      value={answers[index] || 0}
                      //defaultValue={0}
                      className="select-type2"
                      onChange={e => myChangeHandler(e, index)}>
                        <option value="0" disabled/>
                        {insertVariants[index].map((item, index) =>
                          <option key={index} value={item}>{item}</option>
                        )}
                    </select>}
                </Fragment>
            )}
        </div>
    )
};
