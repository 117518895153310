import React from "react";
import Checkbox from "components/ContentBox/Global/Checkbox";
import { localisationMaster } from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import { observer } from "mobx-react";
import { requiredFields } from "components/ContentBox/PlacementTest/CreatePlacementTest/data";

const ModalFields = () => {
  const { modalValues, changeValues } = modalStore;
  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-field">
        <p className="super-modal__form-label">
          {localisationMaster(
            "text_11",
            "c/tests/placement-test/create-widget"
          )}
          :
        </p>
        <input
          type="text"
          value={modalValues.placeholder}
          onChange={(e) => changeValues(e.target.value, "placeholder")}
        />
      </div>
      <div>
        <Checkbox
          checked={modalValues.is_required}
          isChecked={() =>  ![...requiredFields, "allowed_data_processing"].includes(modalValues.name) && changeValues(!modalValues.is_required, "is_required")}
          label={localisationMaster(
            "text_14",
            "c/tests/placement-test/create-widget"
          )}
        />
      </div>
    </div>
  );
};

export default observer(ModalFields);
