import React, {useEffect, useRef, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import LogRow from "./LogRow";
import style from './GroupLog.module.scss';
import TabMenu from "components/Modules/Ebook/Common/TabMenu/TabMenu";
import HeaderContentBox from "../../../../Modules/Groups/components/HeaderContentBox";

export default function GroupLog({gid, isWorkshops}) {
    const tabsRef = useRef([
        {
            id: 1,
            title: localisationMaster('text_32', 'groups/group', 'group_log'),
            name: isWorkshops ? 'Workshop' : 'Schedule'
        },
        {id: 2, title: localisationMaster('text_33', 'groups/group', 'group_log'), name: 'StudyGroup'},
        {
            id: 3,
            title: localisationMaster('text_34', 'groups/group', 'group_log'),
            name: 'Student'
        },
        {
            id: 4,
            title: localisationMaster('text_35', 'groups/group', 'group_log'),
            name: isWorkshops ? 'Workshop_SchoolDay' : 'SchoolDay'
        },
        {
            id: 5,
            title: localisationMaster('text_36', 'groups/group', 'group_log'),
            name: isWorkshops ? 'Workshop_SchoolDayNote' : 'SchoolDayNote'
        },
    ])

    const [currentTab, setCurrentTab] = useState(null)
    const [data, setData] = useState([])
    const [meta, setMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!currentTab) return
        setLoading(true)
        setMeta(null)
        currentTab?.page === 1 && setData([])
        const apiConf = {
            method: 'get',
            link: 'groups/logs',
            params: {
                group_id: gid,
                tab_name: tabsRef.current.find(x => x.id === currentTab?.tab)?.name,
                offset: (currentTab.page - 1) * 10,
                limit: 10,
            }
        }
        superAxiosRequest(apiConf, null, null, {timeout: 90000}).then(res => {
            setData(prevData => [...prevData, ...res.data.result])
            setMeta(res.data._meta)
        }).catch(() => {
            setData([])
        }).finally(() => {
            setLoading(false)
        })
    }, [gid, currentTab])

    return (
        <HeaderContentBox className={style.groupLog}>
            <OpenHeader title={localisationMaster('story', 'groups/group')}/>

            <TabMenu tabs={tabsRef.current} onChange={(tab) => setCurrentTab({tab: tab.id, page: 1})} tabID={currentTab?.tab}/>

            {currentTab ?
              <div className={style.list}>{data.map((row, idx) =>
                <LogRow key={idx} row={row}/>)}
                  {meta?.count_items > data.length &&
                    <button className={style.moreBtn} onClick={() => {
                        setCurrentTab({...currentTab, page: currentTab.page + 1})
                    }}>
                        Показать еще
                    </button>}
              </div>
              :
              <div className={style.notSelected}>Select a section to display history.</div>}


            {loading && <div className={style.list}><Loader/></div>}
        </HeaderContentBox>
    );
}
