import React from 'react';
import styles from "./TestsNotFound.module.scss";
import testsNotFound from "components/ContentBox/img/testsNotFound.svg";

const TestsNotFound = (props) => {
  const {translate} = props;
  return (
    <div className={styles.notFoundTestsBlock}>
      <img className={styles.notFoundTestsImg} src={testsNotFound} alt="Tests not found"/>
      <p className={styles.text}>{translate}.</p>
    </div>
  );
};

export default TestsNotFound;