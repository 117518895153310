import React from "react";
import PropTypes from "prop-types";
import st from "./WithoutExercises.module.scss";
import noExercisesImg from "components/UI/illustrations/streamline-work-from-home.svg";

const WithoutExercises = ({text1, text2}) => {
  return (
    <div className={st.noExercises}>
      <div>
        <img src={noExercisesImg} alt=""/>
      </div>
      <h3>{text1} :(</h3>
      <p>{text2}</p>
    </div>
  )
}

WithoutExercises.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
}

export default WithoutExercises;
