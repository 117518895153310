import React from 'react';
import PropTypes from 'prop-types';
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import useFileUpload from "../../../../../../../../../hooks/useFileUpload";
import st from './ImageSelector.module.scss'
import {ReactComponent as Basket} from "components/ContentBox/img/basket.svg";

const ImageSelector = props => {
    const {refInput, progress, file, setFile} = useFileUpload({onUploadEnd: props.onChange})

    const deleteHandler = () => {
        setFile(null)
        props.onChange(null)
    }

    return (
        <div className={st.box}>
            {(file || props.value) ?
                <div className={st.wrapImg}>
                    <img src={file ? URL.createObjectURL(file) : `${Stores.baseLink()}${props.value}`} alt=""/>
                    <div className={st.delete}><Basket onClick={deleteHandler}/></div>
                </div>
                :
                <div className={st.choose}>
                    <input type="file" ref={refInput} onChange={e => {setFile(e.target.files[0])}} accept="image/*"/>

                    <button onClick={() => {refInput.current.click()}}>
                        {localisationMaster('modal_doc_text_5', 'lessons/course')}
                    </button>

                    <div className={st.tip}>Поддерживаемые форматы: <span>JPG, GIF, PNG.</span></div>

                    {progress > 0 && progress < 100 &&
                    <div className={st.progress}>
                        <progress max="100" value={progress}/>
                        <div>{progress} %</div>
                    </div>}
                </div>
            }
        </div>
    );
};

ImageSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default ImageSelector
