import React from 'react'
import Picker, { registerLocale } from "react-datepicker";
import { timeConverter2 } from '../../Online-schedule/components/functions'
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import "./DataPickerSplit.scss"

registerLocale('ru', ru)

export default function DataPicker(props) {
    return (
        <div className="dataPickerSplit react-datepicker-custom">
            <Picker
                locale="ru"
                selected={new Date(props.selected)}
                onChange={date => props.onChange(timeConverter2(date))}
                dateFormat={props.format || "dd.MM.yyyy"}
                disabled={props.disabled}
                startDate={props.startDate}
                dayClassName={props.dayClassName}
                selectsEnd={props.selectsEnd}
                minDate={props.minDate}
                popperPlacement={props.popperPlacement}
                selectsStart={props.selectsStart}
                endDate={props.endDate}
            />
        </div>
    );
}
