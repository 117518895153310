import React, {useEffect, useState} from 'react';
import Stores from 'Stores';
import st from "./ImagesMatchText.module.scss"
import {classNames as cn} from "helpers";


export default function ImagesMatchText(props) {
  const [words, setWords] = useState([])
  const [result, setResult] = useState([])
  const [selectedWords, setSelectedWords] = useState([]);

  const [touchIndex, setTouchIndex] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  let dragged, dragIndex
  const onDragStartHandler = (e, i) => {
    dragged = e.target
    dragIndex = i
    e.target.classList.add('onDrag')
  }

  const onDragEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const onDragEnterHandler = e => {
    e.target.classList.add(st.onDragOver)
  }

  const onDragLeaveHandler = e => {
    e.target.classList.remove(st.onDragOver)
  }

  const onDragOverHandler = e => {
    // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
    if (dragged)
      // prevent default to allow drop
      e.preventDefault()
  }

  const onTouchStartHandler = (e, i) => {
        setIsTouched(true)
        e.target.classList.add('onDrag')
        setTouchIndex(i);
  }
  const onTouchEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const getWordIndex = () => dragged ? dragIndex : touchIndex

  const onDropOrTouchHandler = ({target}, index) => {
    target.classList.remove(st.onDragOver)
    if(touchIndex === null && !dragged) return
    // Удаляем элемент из слов
    const word = words[getWordIndex()]
    setSelectedWords([...selectedWords, { word, wordIndex: getWordIndex() }])

    const arr = result.slice()
    arr[index].description = word
    setResult(arr)
  }

  useEffect(() => {
    if (props.studentAnswer){
      setWords([])
        setSelectedWords([]);
      setResult(props.studentAnswer.answer.map(a => ({link: a.image, description: a.text})))
    } else {
      setWords(props.data.words_list)
      setResult(props.data.images_list.map(link => ({link, description: ''})))
      setSelectedWords([]);
    }
  }, [props.data, props.studentAnswer])
  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    props.handleAnswers(result, result.length === selectedWords.length)
  }, [result])

  const dropZone = index =>
    result[index].description ?
      <div
          key={index}
          className={st.unit}
          onTouchStart={() => setIsTouched(false)}
          onClick={() => {
             if (props.studentAnswer) return
               if (isTouched) {
                   setTouchIndex(null)
                   return
               }
               const word = result[index].description
               const selectedWord = selectedWords?.find(item => item.word === word);

               if(selectedWord) {
                   const newSelectedWords = selectedWords.filter(
                       item => item.word !== selectedWord.word || item[getWordIndex()] !== selectedWord[getWordIndex()]
                   );
                   setSelectedWords(newSelectedWords);
               }

             setResult(result.map((r, i) => i === index ? {link: r.link, description: ''} : r))
           }}>
        <figure>
          <img src={Stores.baseLink() + result[index].link} alt=""/>
        </figure>
        <div className={cn({
            [st.mistake]:props.studentAnswer?.result_in_detail !== null && props.studentAnswer?.result_in_detail[result[index].link] === false,
            [st.correct]:props.studentAnswer?.result_in_detail !== null && props.studentAnswer?.result_in_detail[result[index].link] === true || props?.studentAnswer?.result === true
        })}>{result[index].description}</div>
      </div>
      :
      <div key={index} className={st.unit}
           onDragEnter={onDragEnterHandler}
           onDragLeave={onDragLeaveHandler}
           onDragOver={onDragOverHandler}
           onDrop={e => {onDropOrTouchHandler(e, index)}}
           onTouchStart={e => onDropOrTouchHandler(e, index)}
      >
        <figure>
          <img src={Stores.baseLink() + result[index].link} alt=""/>
        </figure>
        <div/>
      </div>

  return (
    <div className={st.imageMathText}>
      {!props.studentAnswer &&
        <div className={st.words}>
          {words.map((desc, index) => {
              const selected = selectedWords.some(item => item.wordIndex === index && item.word === desc);
              return (
                  <span
                      key={index}
                      draggable
                      onDragStart={e => {onDragStartHandler(e, index)}}
                      onDragEnd={onDragEndHandler}
                      onTouchStart={e => onTouchStartHandler(e, index)}
                      onTouchEnd={onTouchEndHandler}
                      className={selected ? st.selected : ''}
                  >
                <div className={st.dote}>
                  <div></div>
                </div>
                      {desc}
                </span>
              )
          }

          )}
        </div>}
      <div className={st.units}>
        {result.map((unit, index) =>
          dropZone(index))}
      </div>
    </div>
  )
}
