import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';
import { hideElem, showElem } from 'components/Modules/Slots/utils';
import styles from 'components/Modules/Slots/TeachersHistory/components/TeacherHistoryCard.module.scss';

const CustomerItem = ({ children, fullname = '' }) => {
    const ref = useRef(null);
    const wrapperRef = useRef(null);
    const [isBodyOpen, setIsBodyOpen] = useState(false);

    const actionMenu = [
        { name: `${isBodyOpen ? 'Скрыть' : 'Показать'} расписание`, action: 'detail', permission: ['forward'] },
    ];

    const handleHeaderClick = () => hideElem(wrapperRef, ref, isBodyOpen, setIsBodyOpen);

    useEffect(() => showElem(wrapperRef, ref, isBodyOpen), [isBodyOpen]);

    return (
        <li className={`${styles.teachers_item} ${isBodyOpen ? styles.card_open : ''}`}>
            <div className={styles.card_header} onClick={handleHeaderClick}>
                <p className={styles.card_header_name}>{fullname}</p>
                <div className={styles.card_menu} onClick={(evt) => evt.stopPropagation()}>
                    <ActionMenu menu={actionMenu} action={handleHeaderClick} />
                </div>
            </div>
            <div ref={wrapperRef} className={styles.card_body}>
                {isBodyOpen &&
                    <div ref={ref}>
                        {children}
                    </div>
                }
            </div>
        </li>
    )
}

CustomerItem.propTypes = {
    children: PropTypes.any,
    fullname: PropTypes.any,
}

export default CustomerItem;
// point_uluk