import styles from './TimeTableSlot.module.scss';
import React, {useEffect, useRef, useState} from 'react';
import VisualSheduleTooltip from './Tooltip/VisualSheduleTooltip';

const TimeTableSlot = ({
    slotData,
    startRowId,
    totalRows,
    transferLesson
}) => {

    const refTarget = useRef()
    const [slotState, setSlotState] = useState({
        left: Math.floor((slotData.timeStart - slotData.workStartTime) / 60000) + 202,
        bottom: (totalRows - (startRowId + 1)) * (40) + 1,
        focusWrapperDisplay: "none",
        rowId: startRowId,
        timeStart: slotData.timeStart,
        timeEnd: slotData.timeEnd,
        tooltipMove: false,
        tooltipInfo: false,
        tooltipStyles: {},
        scale: 1
    })

    const getStyles = (targetRect, type) => {
        let blocksWidth = {
            tooltip: 95,
            menu: 80,
        }
        let sidePanelWidth = window.document.documentElement.clientWidth > 766 ? 107 + blocksWidth[type] : 0
        let adaptiveWidth = window.document.documentElement.clientWidth > 766 ? 0 : 7 + blocksWidth[type]
        let leftCoordinate = (window.document.documentElement.clientWidth < 1170) ? (targetRect.left - sidePanelWidth - adaptiveWidth + Math.floor((slotData.durationMinutes - 4) / 2)) : (
            (targetRect.left - ((window.document.documentElement.clientWidth - 1170) / 2)) - sidePanelWidth + Math.floor((slotData.durationMinutes - 4) / 2)
        )
        let topCoordinate = (totalRows - (slotState.rowId + 1)) * (-40)
        let leftBefore = blocksWidth[type] - 14
        let leftAfter = blocksWidth[type] - 14
        if (leftCoordinate < 0) {
            leftBefore = leftBefore + leftCoordinate
            leftAfter = leftAfter + leftCoordinate
            leftCoordinate = 0
            if (leftBefore < 0) {
                leftBefore = 1
                leftAfter = 0
            }
        }
        if (leftCoordinate > (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth))) {
            leftBefore = leftBefore + (leftCoordinate - (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)))
            leftAfter = leftAfter + (leftCoordinate - (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)))
            leftCoordinate = window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)
            if (leftBefore > (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth))) {
                leftBefore = window.document.documentElement.clientWidth - (blocksWidth[type] - 1 + sidePanelWidth)
                leftAfter = window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)
            }
        }

        return ({
            left: leftCoordinate, top: topCoordinate,
            "--leftBefore": `${leftBefore}px`, "--leftAfter": `${leftAfter}px`, '--arrowDisplay': 'block'
        })
    }
    const getBottomPos = (e) => {
        const posBottom = slotState.startItemPosY + Math.round((slotState.startCursorPosY - e.clientY) / 45) * 40
        return (posBottom > 0) ? (posBottom > 241 ? 241 : posBottom) : 1
    }
    const getTopPos = (e) => {
        const posBottom = slotState.startItemPosY + Math.round((slotState.startCursorPosY - e.clientY) / 45) * 40
        return (-posBottom < 0) ? (-posBottom < -240 ? -240 : -posBottom) : 0
    }
    const getLeftPos = (e, startPosX) => {
        const posLeft = startPosX + (e.clientX - slotState.startCursorPosX)
        return slotState.left <= 200 && posLeft <= 200? 200: posLeft
    }

    const showHandler = () => {
        const targetRect = refTarget.current.getBoundingClientRect()
        // setTooltipStyles(getStyles(targetRect, 'tooltip'))
        setSlotState({
            ...slotState,
            tooltipStyles: getStyles(targetRect, 'tooltip'),
            tooltipInfo: true
        })
        // setIsTooltipShown(true)
    }
    const hideHandler = () => {
        // setIsTooltipShown(false)
        setSlotState({
            ...slotState,
            tooltipInfo: false
        })
    }


    const mouseDownHandler = (e) => {
        e.persist()
        setSlotState((prev) => ({
            ...prev,
            startCursorPosX: e.clientX,
            startCursorPosY: e.clientY,
            startItemPosX: slotState.left,
            startItemPosY: slotState.bottom,
            focusWrapperDisplay: 'block',
            scale: 1.05,
            tooltipMove: true,
            tooltipStyles: {
                ...slotState.tooltipStyles,
                startTooltipPosX: slotState.tooltipStyles.left,
                startTooltipPosY: slotState.tooltipStyles.top,
                '--arrowDisplay': 'none'
            }
        }))
    }
    const mouseMoveHandler = (e) => {
        if (slotState.startCursorPosX) {
            const newItemPosX = getLeftPos(e, slotState.startItemPosX,)
            const newRowId = (slotState.rowId - Math.round((slotState.startCursorPosY - e.clientY) / 45))
            setSlotState({
                ...slotState,
                left: newItemPosX,
                bottom: getBottomPos(e),
                timeStart: new Date(((Math.round((newItemPosX - 200) / 5)) * 300000) + slotData.workStartTime - (- (newRowId - slotState.rowId) * 24 * 60 * 60 * 1000)),
                tooltipStyles: {
                    ...slotState.tooltipStyles,
                    left: `${getLeftPos(e, slotState.tooltipStyles.startTooltipPosX,)}px`,
                    top: `${getTopPos(e, slotState.tooltipStyles.startTooltipPosY)}px`,

                }
            })
        }
    }
    const mouseUpHandler = (e) => {
        const newPosX = (slotState.startItemPosX + (e.clientX - slotState.startCursorPosX))
        const item = {
            newPosX: newPosX,
            newRowId: (slotState.rowId - Math.round((slotState.startCursorPosY - e.clientY) / 45)),
            rowId: slotState.rowId,
            ...slotData
        }
        setSlotState({
            left: Math.floor((slotData.timeStart - slotData.workStartTime) / 60000) + 202,
            bottom: (totalRows - (startRowId + 1)) * (40) + 1,
            focusWrapperDisplay: "none",
            rowId: startRowId,
            timeStart: slotData.timeStart,
            timeEnd: slotData.timeEnd,
            tooltipMove: false,
            scale: 1
        })
        transferLesson(item)

    }


    useEffect(() => {
        setSlotState({
            left: Math.floor((slotData.timeStart - slotData.workStartTime) / 60000) + 202,
            bottom: (totalRows - (startRowId + 1)) * (40) + 1,
            focusWrapperDisplay: "none",
            rowId: startRowId,
            timeStart: slotData.timeStart,
            timeEnd: slotData.timeEnd
        })
    }, [slotData])

    return (
        <>
            <div onMouseEnter={() => showHandler()}
                onMouseOut={() => hideHandler()}
                onMouseDown={(e) => mouseDownHandler(e)}
                onMouseMove={(e) => mouseMoveHandler(e)}
                onMouseUp={e => mouseUpHandler(e)}
                className={styles.timeTableSlot}
                style={{
                    backgroundColor: slotData.color, width: `${slotData.durationMinutes - 4}px`,
                    left: `${slotState.left}px`,
                    bottom: `${slotState.bottom}px`,
                    border: slotData.border,
                    zIndex: slotState.zindex,
                }}
                ref={refTarget}
            >
                <div style={{display: slotState.focusWrapperDisplay}} className={styles.timeTableSlot__focusWrapper}></div>
            </div>
            {slotState.tooltipInfo && slotState.tooltipStyles &&
                <VisualSheduleTooltip
                    propsStyles={{
                        left: `${slotState.tooltipStyles.left}px`,
                        top: `${slotState.tooltipStyles.top}px`,
                        ...slotState.tooltipStyles
                    }}
                    timeStart={slotState.timeStart}
                    durationMinutes={slotData.durationMinutes}
                    status={slotData.textStatus}
                    timeDuration={`${slotData.hours_per_day} ${slotData.hour_type.short_name}.ч./ ${slotData.hour_type.minutes * slotData.hours_per_day} мин.`}
                    date={true}
                    teachers={slotData.teachers}
                    comment={slotData.comment}
                />
            }
            {slotState.tooltipMove &&
                <VisualSheduleTooltip
                    timeStart={slotState.timeStart}
                    durationMinutes={slotData.durationMinutes}
                    propsStyles={{
                        left: `${slotState.tooltipStyles.left}px`,
                        top: `${slotState.tooltipStyles.top}px`,
                        ...slotState.tooltipStyles
                    }}
                    teachers = {[]}
                />
            }
        </>
    )
}
export default TimeTableSlot;


