import React from "react";
import st from "./ChaptersPopup.module.scss";
import {ReactComponent as Arrow} from "components/UI/icons/arrow-right-s-line.svg";

const ChaptersPopup = ({data, value, setActiveSection, setClose}) => {

  return (
    <>
      <div className={st.box}>{data.map((item, i) =>
          <div onClick={() => {
            setActiveSection('', i + 1)
            setClose()
          }}
               key={i}
               className={i + 1 === value ? `${st.item} ${st.active}` :
            st.item}>
            <Arrow/><span>{item.name}</span>
          </div>)}
      </div>
      <div onClick={() => setClose()} className={st.popWrapper}/>
    </>
  )
}

export default ChaptersPopup
