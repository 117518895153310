import React from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Select from 'components/UI/Select/Select';
import Flex from 'components/UI/Flex/Flex';
import { findOption } from 'components/Modules/Slots/utils';
import styles from './SlotSettings.module.scss';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';

const breakOptions = [
  {value: 0, label: 'Без перерыва'},
  {value: 5, label: '5 мин'},
  {value: 10, label: '10 мин'},
  {value: 15, label: '15 мин'},
  {value: 20, label: '20 мин'},
  {value: 30, label: '30 мин'},
  {value: 60, label: '60 мин'},
];

function Break() {
  const { breakTime, setTime } = slotsScheduleSettingsStore;
  const handleSelectChange = (option) => {
    setTime(option.value, 'breakTime');
  };

  return (
    <Flex>
      <SectionTitle title='Перерыв' secondary tag='h3' />
      <Select
        placeholder='Выберите перерыв'
        options={breakOptions}
        value={findOption(breakTime, breakOptions)}
        onChange={handleSelectChange}
        classes={styles.break_selector}
      />
    </Flex>
  );
}

export default observer(Break);

