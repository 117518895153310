import React from 'react';
import KyrgyzstanFlag from "components/ContentBox/img/kyrgyzstan.svg";
import RussiaFlag from "components/ContentBox/img/russia.svg";
import UkraineFlag from "components/ContentBox/img/ukraine.svg";
import BelarusFlag from "components/ContentBox/img/belarus.svg";
import UzbekistanFlag from "components/ContentBox/img/uzbekistan.svg";
import KazakhstanFlag from "components/ContentBox/img/kazakhstan.svg";

export const countries = [
  {
    type: "+7",
    maxLength: 15,
    minLength: 15,
    validLength: 11,
    icon: () => <img src={KazakhstanFlag} alt="flag/icon"/>,
    title: "Kazakhstan",
  },
  {
    type: "+7",
    maxLength: 15,
    minLength: 15,
    validLength: 11,
    icon: () => <img src={RussiaFlag} alt="flag/icon" />,
    title: "Russia",
  },
  {
    type: "+375",
    maxLength: 14,
    minLength: 14,
    validLength: 12,
    icon: () => <img src={BelarusFlag} alt="flag/icon" />,
    title: "Belarus",
  },
  {
    type: "+380",
    maxLength: 14,
    minLength: 14,
    validLength: 12,
    icon: () => <img src={UkraineFlag} alt="flag/icon"  />,
    title: "Ukraine",
  },
  {
    type: "+996",
    maxLength: 13,
    minLength: 13,
    validLength: 12,
    icon: () => <img src={KyrgyzstanFlag} alt="flag/icon" />,
    title: "Kyrgyzstan",
  },
  {
    type: "+998",
    maxLength: 11,
    minLength: 11,
    validLength: 12,
    icon: () => <img src={UzbekistanFlag} alt="flag/icon"  />,
    title: "Uzbekistan",
  },
];
