import React, { useState } from "react";
import { BlockHeader } from 'components/UI/BlockHeader/BlockHeader';
import { GoToLink, SuperButton } from 'components/UI/SuperButton/SuperButton';
import Empty from './components/Empty/Empty';
import styles from './MyGroups.module.scss';
import { superAxiosRequest } from "axiosApi";
import { useEffect } from "react";
import Loader from "components/ContentBox/Global/Loader/Loader";
import Group from './components/Group/Group';
import Stores from "Stores";
import useLocalization from "hooks/useLocalization";
import { LanguageContext, dict, statusList } from "./constants";
import LocalStorage from "services/LocalStorage";
import { useMemo } from "react";

const MyGroups = ({ apiConf, getResultApi }) => {
    const [list, setList] = useState({ loading: false, data: [] });
    const [statuses, setStatuses] = useState(statusList);
    const { translate } = useLocalization({ section: 'profile', subsection: 'my_groups', dict });
    const weekdays = useMemo(() => {
        return [
            { id: 1, name: translate.monday },
            { id: 2, name: translate.tuesday },
            { id: 3, name: translate.wednesday },
            { id: 4, name: translate.thursday },
            { id: 5, name: translate.friday },
            { id: 6, name: translate.saturday },
            { id: 7, name: translate.sunday },
        ];
    }, [translate]);
    const STATUSES_LIST = useMemo(() => {
        return [
            { 
                name: translate.in_progress_name, 
                type: 'in_progress', 
                types: [1],
                text: translate.in_progress_text,
                isShowCross: true
            },
            { 
                name: translate.scheduled_name, 
                type: 'scheduled', 
                types: [4],
                text: translate.scheduled_text,
                isShowCross: false
            },
            { 
                name: translate.formation_name, 
                type: 'formation', 
                types: [2],
                text: translate.formation_text,
                isShowCross: false
            },
            { 
                name: translate.finished_name, 
                type: 'finished', 
                types: [6],
                text: translate.finished_text,
                isShowCross: false
            },
        ];
    }, [translate]);

    const profile = LocalStorage.get('ud').user_data.profile;
    const language = profile.localization.toLowerCase();
    const tabs = STATUSES_LIST.filter(status => statuses.includes(status.type));

    const changeStatuses = type => {
        if(statuses.includes(type)) {
            setStatuses(statuses.filter(status => status !== type));
        } else {
            setStatuses([...statuses, type]);
        }
    };
      
    useEffect(() => {
        setList({ ...list, loading: true });

        superAxiosRequest(apiConf)
            .then(({ data }) => {
                const result = getResultApi ? getResultApi(data) : data;
                setList({ ...list, loading: false, data: result });
            }).catch(() => {
                setList({ ...list, loading: false, data: [] });
            });
    }, []);

    return  (
        <LanguageContext.Provider value={{ translate, language, STATUSES_LIST, weekdays }}>
            <div className={styles.groups}>
                <BlockHeader title={translate.title}>
                    {!Stores.isClient && <GoToLink to="/groups/all-groups"/>}
                </ BlockHeader>
                {
                    list.loading ? <Loader /> :
                    list.data.length === 0
                        ? <Empty />
                        : (
                            <>
                                <div className={styles.groups__statuses}>
                                    {STATUSES_LIST.map(item => 
                                        <SuperButton
                                            variant='filter'
                                            isShowCross={item.isShowCross}
                                            callback={() => changeStatuses(item.type)}
                                            className={styles[item.type]}
                                            key={item.type}
                                        >
                                            {item.name}
                                        </SuperButton>
                                    )}
                                </div>
                                <div className={styles.groups__list}>
                                {list.data
                                    .filter((item) => tabs.some(tab => tab.types.includes(item.status.id)))
                                    .map((item, idx) => <Group studentStatus={item.student_status?.name} key={idx} {...item} />)}
                                </div>
                            </>
                        )
                }
            </div>
        </LanguageContext.Provider>
    )
}

export default MyGroups;