import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TeacherHistoryBody from './TeacherHistoryBody';
import ActionMenu from 'components/ContentBox/Global/ActionMenu/ActionMenu';
import { calcSlotsData } from '../calcSlotsData';
import styles from './TeacherHistoryCard.module.scss';
import { hideElem, showElem } from 'components/Modules/Slots/utils';

function TeacherHistoryCard({data}) {
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const [isBodyOpen, setIsBodyOpen] = useState(false);
  const result = calcSlotsData(data.items);
  data.result = result;

  const actionMenu = [
    { name: `${isBodyOpen ? 'Скрыть' : 'Показать'} расписание`, action: 'detail', permission: ['forward'] },
  ];

  const handleHeaderClick = () => {
    hideElem(wrapperRef, ref, isBodyOpen, setIsBodyOpen);
  };

  useEffect(() => {
    showElem(wrapperRef, ref, isBodyOpen);
  }, [isBodyOpen]);
  return (
    <li className={`${styles.teachers_item} ${isBodyOpen ? styles.card_open : ''}`}>
      <div className={styles.card_header} onClick={handleHeaderClick}>
        <p className={styles.card_header_name}>{data.employee_name}</p>
        <p>{`Месячный план: ${Math.trunc(data.test_plan_mins / 60)} ч.`}</p>
        <p>{`Зарезервировано: ${Math.trunc(result.all.time / 60)} ч.`}</p>
        <div className={styles.card_menu} onClick={(evt) => evt.stopPropagation()}>
          <ActionMenu menu={actionMenu} action={handleHeaderClick}/>
        </div>
      </div>
      <div ref={wrapperRef} className={styles.card_body}>
        {isBodyOpen &&
        <div ref={ref}>
                  <TeacherHistoryBody data={data}/>
        </div>
        }
      </div>
    </li>
  );
}

const slotClientType = {
  comment: PropTypes.string,
  confirmed: PropTypes.bool.isRequired,
  deleted_at: PropTypes.string,
  deleted_by: PropTypes.number, // teacher id
  id: PropTypes.number,
  is_active: PropTypes.bool.isRequired,
  is_missed: PropTypes.bool.isRequired,
  is_online: PropTypes.bool.isRequired,
  start_level: PropTypes.string,
  student: PropTypes.number,
  training_level_id: PropTypes.number,
  training_level_name: PropTypes.string,
};

export const teacherSlotType = {
  comment: PropTypes.string,
  company: PropTypes.number.isRequired,
  finish_at: PropTypes.string.isRequired, // '03:15:00'
  id: PropTypes.number.isRequired,
  is_active: PropTypes.bool.isRequired,
  is_accessible: PropTypes.bool.isRequired,
  is_online: PropTypes.bool.isRequired,
  is_school: PropTypes.bool.isRequired,
  location: PropTypes.number.isRequired,
  slot_client: PropTypes.arrayOf(PropTypes.shape(slotClientType)),
  slot_date: PropTypes.string.isRequired, // '2021-11-05'
  start_at: PropTypes.string.isRequired, // '03:00:00'
  type_id: PropTypes.number.isRequired, // 1
  type_name: PropTypes.string.isRequired, // Стандартный тип
  color: PropTypes.string.isRequired, // #ffffff
};

export const itemsType = PropTypes.arrayOf(PropTypes.shape({
  date: PropTypes.string.isRequired,
  employee_booked_slots: PropTypes.arrayOf(PropTypes.shape(teacherSlotType)).isRequired,
}));

TeacherHistoryCard.propTypes = {
  data: PropTypes.shape({
    employee_id: PropTypes.string.isRequired, // '2'
    employee_name: PropTypes.string.isRequired,
    test_plan_mins: PropTypes.number,
    items: itemsType.isRequired,
  }).isRequired,
};

export default TeacherHistoryCard;
