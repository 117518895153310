import React from 'react';
import style from './RadioButton.module.scss'

const RadioButton = (props) => {
    return (
        <div className={`${style.typeSelectorBox}`}>
            {!props.like_obj ?
                props.values && props.values.map((item, index) => {
                    return (
                        <div style={{width: (100 / props.values.length) + '%'}} key={index}
                             onClick={() => props.objectChangeHandler(index + 1, props.name)}
                             className={props.selected - 1 === index ? style.typSelectorSelected : null}>
                            {item}
                        </div>
                    )
                })
                :
                props.values && props.values.map((item, index) => {
                    return (
                        <div style={{width: (100 / props.values.length) + '%'}} key={index}
                             onClick={() => props.objectChangeHandler(item.id, props.name)}
                             className={props.selected === item.id ? style.typSelectorSelected : null}>
                            {item.name}
                        </div>
                    )
                })
            }
        </div>
    );
};

export default RadioButton;