import React from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/UI/Flex/Flex';
import CalendarWrapper from 'components/Modules/Slots/components/CalendarWrapper/CalendarWrapper';
import Slots from 'components/Modules/Slots/components/Slots/Slots';
import ClientDataWrapper from 'components/Modules/Slots/components/ClientDataWrapper/ClientDataWrapper';
import PaymentChoice from "components/Modules/Slots/components/PaymentChoice/PaymentChoice";
import Submit from "components/Modules/Slots/components/Submit/Submit";
import OrderStatus from "components/Modules/Slots/components/OrderStatus/OrderStatus";
import Confirm from 'components/Modules/Slots/components/Confirm/Confirm';
import ConfirmAdmin from 'components/Modules/Slots/components/Confirm/ConfirmAdmin';
import slotsNewRecordOnlineStore from 'Stores/slotsNewRecordOnlineStore';

function Content() {
  const store = slotsNewRecordOnlineStore;
  const { isReadyToLoadCalendar, slots, selectedSlots, paymentType, paymentStep } = store;
  return (
    <Flex asColumn withGap>
      {isReadyToLoadCalendar && <CalendarWrapper store={store} /> }
      { slots.length > 0 && <Slots store={store} />}
      { slots.length > 0 && selectedSlots.size > 0 &&
        <>
          <ClientDataWrapper store={store} />
          {(paymentType === 1 || paymentType === 6) && <PaymentChoice store={store} />}
          <Submit store={store} />
        </>
      }
      { (paymentType === 1 || paymentType === 6) && paymentStep > 1 && <OrderStatus store={store} /> }
      { (paymentType === 2 || paymentType === 4 || paymentType === 5) && paymentStep > 1 && <Confirm store={store} /> }
      { paymentType === 3 && paymentStep > 1 && <ConfirmAdmin store={store} /> }
    </Flex>
  );
}

export default observer(Content);
