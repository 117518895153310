import React, {useEffect, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {NavLink} from 'react-router-dom';
import {ExcelRowsParams} from '../../../utils/ExcelExport';


const StudentsTrialLesson = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
        setFilter,
    } = useTableFixedHeader();
    const [catalog, setCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            placeholder: 'Локация',
            values: catalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },
        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
    ]

    const cols = [
        {
            name: 'Ф.И.О. студента',
        },
        {name: 'Группа', },
    ];

    const findClientData = (data) => {
        let clientData = [...data.client_names]
        clientData = clientData.map(client => {
            let groups = data.clients.filter(item => item.client_id === client.id)
            groups = groups.map(item => {
                let groupData = data.group_names.find(group => group.id === item.group_id)
                return {...item, name: `${groupData.number} ${groupData.name}`}
            })
            return {...client, groups: groups}
        })
        return clientData
    }
    const onChangeFilter = (obj) => {
        const filterData = {
            ...obj,
            ...(obj.date_from && { date_from: obj.date_from }),
            ...(obj.date_until && { date_until: obj.date_until }),
        }
        setFilter(filterData);
        if (obj.date_from && obj.date_until) getFilteredData(filterData);
    }
    const getFilteredData = (filter) => {
        setIsLoading(true)
        // Дату начала устанавливаем на первый день месяца
        const dateFrom = filter.date_from ? new Date(filter.date_from) : null
        // Дату конца устанавливаем на последний день месяца
        const dateUntil = filter.date_until ? new Date(filter.date_until) : null
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/trial_lesson',
            params: {
                ...filter,
                date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"), ...(dateUntil &&
                    {
                        date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                    })
            },
        }).then(({data}) => {
            setData(findClientData(data))
            setIsLoading(false)
        }).catch(() => {setIsLoading(false)})
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/trial_lesson',
        })
            .then(({data}) => {
                setIsLoading(false)
                setCatalog(data)
            })
            .catch(() => setIsLoading(false))
    }, [])

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {            
            const headRow = [
                {
                    v: "Ф.И.О. студента",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Группа",
                    ...ExcelRowsParams.headerRowParam
                }
            ]
            let footerRows = [
                {
                    v: `Итого студентов`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: `${data.length}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },

            ]
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 50},
                {wch: 50},
            ];

            data.forEach((student) => {
                const groupNames = student.groups.map(group => group.name)
                tableData.push([
                    {
                        v: `${student.full_name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: groupNames.join('\n'),
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRows]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Студенты с пробным уроком.xlsx");
        })
    };
    return (
        <div>
            <ContentHeader title="Студенты с пробным уроком" />
            <Filter config={filterCnf} getFilterParams={onChangeFilter} drop={() => setFilter({})}/>

            {isLoading
                ? <Loader />
                : data && data.length > 0 ? (
                        <>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            <>
                                               <tr className={st.bdt}>
                                                    <td className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>
                                                        Итого  студентов
                                                </td>
                                                    <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                                                        {data.length}
                                                    </td>
                                                </tr>
                                                {data.map((row) =>
                                                    row.groups.map((group, tdId) =>
                                                        <tr key={tdId} className={st.bdt}>
                                                            <td rowSpan={row.groups.length}
                                                                className={`${st.tdMiddle}  ${st.fw600}`} style={{
                                                                    width: '50%',
                                                                    background: "white",
                                                                    display: tdId === 0 ? '' : 'none'
                                                                }}>
                                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                                    <NavLink to={'/account_clients/' + row.user_id} target="_blank">
                                                                    {row.full_name}
                                                                    </NavLink>
                                                                </div>
                                                            </td>
                                                            <td className={`${st.tdMiddle} ${st.textAlignCenter}`} style={{
                                                                width: '50%',
                                                                background: tdId % 2 !== 0 ? '#f6f6f7' : "white"
                                                            }} >
                                                                <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                    <NavLink to={`/groups/group/${group.group_id}`} target="_blank">
                                                                        {group.name}
                                                                    </NavLink>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                                )}
                                             
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <button onClick={() => {downloadExcel(data)}} style={{
                                margin: '10px 10px 0 ',
                                width: 'calc(100% - 20px)'
                            }}>Выгрузить в Excel</button>
                        </>
                    ) : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </div >
    )
}

export default StudentsTrialLesson