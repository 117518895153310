import {permissions} from "settings/permissions";
import {localisationMaster} from 'Localisation/Localisation';
import {dateFormat} from "helpers/date";

export const groups_s = [ 
    {
        link:'disciplines',
        folder: 'groups',
        permission: ['discipline', 'add'],
        button: [localisationMaster('button-title', 'groups/disciplines'), 'discipline', 'add'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'disciplines',
            link: 'db/disciplines',
            button: localisationMaster('button-two', 'groups/disciplines', 'add_discipline'),
            title: localisationMaster('title-modal', 'groups/disciplines', 'add_discipline'),
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs:['text', 'select'],
            data: 'json',
            pl_text: localisationMaster('input-placeholder-1', 'groups/disciplines', 'add_discipline'),
            pl_select: localisationMaster('select-placeholder-1', 'groups/disciplines', 'add_discipline'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies'
        },
        title: localisationMaster('title', 'groups/disciplines'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['discipline', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['discipline', 'delete'] },
            ],
            link: 'disciplines',
            prefix: 'db',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('text_30', 'groups/all-groups'), },
                { name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },
        
        apiSettings: {}
    },


    // ----------------- Уровни -------------
    {
        link:'group-levels',
        folder: 'groups',
        permission: ['groupslevel', 'add'],
        button: [localisationMaster('button-title', 'groups/group-levels'), 'groupslevel', 'edit'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'group-levels',
            link: 'groups/levels',
            button: localisationMaster('button-two', 'groups/group-levels', 'add_level'),
            title: localisationMaster('title-modal', 'groups/group-levels', 'add_level'),
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs:['text', 'select'],
            data: 'json',
            pl_text: localisationMaster('title-modal', 'groups/group-levels', 'add_level'),
            pl_select: localisationMaster('select-placeholder-1', 'groups/statuses', 'add_status'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies'
        },
        title: localisationMaster('title', 'groups/group-levels'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['groupslevel', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['groupslevel', 'delete'] },
            ],
            link: 'levels',
            sub_link: 'groupLevels',
            prefix: 'groups',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-4', 'tests/past-tests'), },
                { name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },
        
        apiSettings: {}
    },

    // ----------------- Категории -------------
    {
        link:'categories',
        folder: 'groups',
        permission: ['groupcategory', 'add'],
        button: [localisationMaster('button-title', 'groups/categories'), 'groupcategory', 'edit'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            name: 'categories',
            link: 'groups/categories',
            button: localisationMaster('button-two', 'groups/categories', 'add_category'),
            title: localisationMaster('title-modal', 'groups/categories', 'add_category'),
            // type: 'BaseTemplate',
            type: 'SelectorAndInput',
            active_inputs:['text', 'select'],
            data: 'json',
            pl_text: localisationMaster('input-placeholder-1', 'groups/categories', 'add_category'),
            pl_select: localisationMaster('select-placeholder-1', 'groups/categories', 'add_category'),
            name_text: 'name',
            name_select: 'company',
            companies: true,
            select_link: 'db/companies'
        },
        title: localisationMaster('title', 'groups/categories'),
        colsSettings: {
            action: [
                { name: localisationMaster('text_11', 'lessons/course'), action: 'edit', permission: ['groupcategory', 'edit'] },
                { name: localisationMaster('text_13', 'lessons/course'), action: 'delete', permission: ['groupcategory', 'delete'] },
            ],
            link: 'categories',
            prefix: 'groups',
            id: { name: 'id' },
            main_rows: [
                { name: 'name', depence: false },
            ],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('text_26_1', 'modal_templates'), },
                { name: 'name', description: localisationMaster('text_21', 'modal_templates'), updir: 'company', key_name: 'name' },
            ]
        },
        
        apiSettings: {}
    },

    // Самостоятельно записанные клиенты в группы
    {
        link: 'self-sign-up',
        folder: 'groups',
        title: 'Самостоятельно записанные клиенты в группы',
        colsSettings: {
            prefix: 'groups',
            //link: 'independent_students_groups_list',
            link: 'self_recorded_students',

            id: { name: 'id' },
            main_rows: [
                { name: 'student_name'},
                {
                    compiled: [
                        {name: 'group_number', past: ' - '},
                        {name: 'group_name'},
                    ]
                },
                { name: 'created_at', function: 'date', classname: 'tar'},
            ],
            is_open: false,
            sub_rows: [
                { name: 'student_birth_date', description: 'Возраст', function: 'age'},
                { name: 'student_phone', description: 'Телефон', function: 'phone_mask'},
                { name: 'student_inn', description: 'ИИН', function: 'inn'},
                { name: 'student_email', description: "Email", function: 'mail_link'},
            ],
            action: [
                {
                    name: 'Одобрить',
                    action: 'group_sign_up_approve',
                    perm: permissions.view_group_sign_up_clients,
                    process: (act, row) => row.approved_at ? false : act
                },
                {
                    name: 'Отклонить',
                    action: 'group_sign_up_reject',
                    perm: permissions.view_group_sign_up_clients,
                    process: (act, row) => row.approved_at ? false : act
                },
                {
                    perm: permissions.view_group_sign_up_clients,
                    btn_link: {
                        link: '/groups/group/:id/',
                        name: 'Страница группы',
                        target: '_blank',
                    },
                    process: (act, row) => ({...act, btn_link: {...act.btn_link, link: act.btn_link.link.replace(':id', row.group_id)}})
                },
                {
                    perm: permissions.view_group_sign_up_clients,
                    btn_link: {
                        link: '/account_clients/:id/',
                        name: 'Профиль клиента',
                        target: '_blank',
                    },
                    process: (act, row) => ({...act, btn_link: {...act.btn_link, link: act.btn_link.link.replace(':id', row.student_id)}})
                },
            ],
        },

        filterLiner: true,
        filterLinerLink: 'groups/self_recorded_students',
        config :[
            { id: 1, nameState: 'name_email_phone', placeholder: 'ФИО, e-mail или телефон клиента', type: 'input' },
        ],
        //apiSettings: {fiter_type: 1},

        surfMenu: true,
    },

    {
        link: 'change-days-status',
        folder: 'groups',
        title: 'Единое проведение всех занятий',
        colsSettings: {
            link: 'change-days-status',
            prefix: 'groups',
            is_open: false,
            main_rows: [
                {
                    name: 'status_date',
                    function: 'date'
                },
                {
                    name: 'created_by_name',
                    classname: 'tar'
                },

            ],
            action: [],
            sub_rows: [
                {
                    name: 'first',
                    description: 'Когда создано:',
                    function: row => `${row.created_by_name} (${dateFormat(row.created_at, "YYYY-mm-dd, hh:ii:ss")})`
                },
                {
                    name: 'schoolday_status',
                    description: 'Статус сотрудников:',
                    compiled: [
                        {name: 'schoolday_status', past: ' ('},
                        {name: 'schoolday_status_name', past: ')'},
                    ]
                },
                {
                    name: 'schooldaynote_status',
                    description: 'Статус клиентов:',
                    function: row => row.schooldaynote_status ? `${row.schooldaynote_status} (${row.schooldaynote_status_name})` : 'Без статуса',
                },
                {name: 'comment', description: 'Комментарий:'},
            ]
        },
        button: ['Добавить статус'],
        modal_type: 'GlobalSuperModal',
        modal_settings: {
            type: 'groups_day_status',
            title: 'Новый статус дня для всех групп',
            button: 'Сохранить',
            link: 'groups/change-days-status'
        }
    }
]