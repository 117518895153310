import React, {useEffect, useState} from 'react';
import st from './MakeWord.module.scss';

export default function MakeWord(props) {
  const initWord = () => {
    if (!Array.isArray(props.answers)) return []

    const letters = [...props.letters]

    return props.answers[0].split('').map(l => {
      const i = letters.indexOf(l)
      letters[i] = null
      return i
    })
  }

  const [word, setWord] = useState(initWord)

  const isSelected = i => word.includes(i)

  useEffect(() => {
    const val = []
    if (word.length === props.letters.length)
      val.push(word.map(i => props.letters[i]).join(''))
    props.onComplete(val)
  }, [word])

  return (
    <div className={st.makeWord}>
      <ul className={st.letters}>{props.letters.map((l, i) =>
        <li key={i} className={st.letter} onClick={() => setWord(word.filter((a, b) => b !== i))}>
          {word[i] !== undefined && props.letters[word[i]]}
        </li>)}
      </ul>
      <div className={st.line}></div>
      <ul className={st.letters}>{props.letters.map((l, i) => isSelected(i) ?
        <li key={i} className={st.empty}/> :
        <li key={i} className={st.letter} onClick={() => setWord(word.concat(i))}>{l}</li>)}
      </ul>
    </div>
  );
};
