import React, { useState, useEffect } from 'react';

import './SurfMenu.scss'
import ActionMenu from "../../../UI/ActionMenu/ActionMenu";

export default function SurfMenu(props) {

    const [active, setActive] = useState(props.type)

    useEffect(() => {
        props.changeType(active)
    }, [props, active])

    useEffect(() => {
        if(props.type === active) return
        //if (active === null) setActive(props.type)
        setActive(props.type)
    }, [props.type])

    return (
        <div className="surf-menu">
            <div className="surf-menu__grad">
                <div className="surf-menu__tabs">
                    {props.menu && props.menu.map((item, index) =>
                        (item.count === undefined || (item.count > 0 || props.showAtZero)) &&
                        <div
                            key={index}
                            className={"surf-menu__tab " + (item.link === active ? " surf-menu__tab--active" : "")}
                            onClick={()=>setActive(item.link)}
                        >
                            {item.name}
                            {(item.count || props.showAtZero) && <sup className="surf-menu__count"> {item.count}</sup>}
                            {(props.counts && props.counts) && <sup className="surf-menu__count"> {props.counts[item.id] && props.counts[item.id].count}</sup>}
                        </div>
                    )}
                </div>
            </div>

            <div className="surf-menu__right" >
                {props.button &&
                <div className="surf-menu__action">
                    <ActionMenu menu={props.menu_add} action={props.action_add}/>
                </div>}
            </div>

        </div>
    )
}
