import React, { useState, useEffect } from 'react'
import Select from '../../../Select/Select'

export default function FundTransfer(props) {
    const [list, setlist] = useState([])
    const [values, setvalues] = useState({
        from_balance: {
            id: 0,
            type: "",
        },
        to_balance: {
            id: 0,
            type: "",
        },
        sum: 0

    })
    const changeHandler = (e, state) => {
        let arr = values
        let val = e.target.value
        arr.to_balance.id = +val.split(',')[0]
        arr.to_balance.type = val.split(',')[1]
        setvalues({ ...arr })
    }
    useEffect(() => {
        let groups = props.fix_data.groups
        let companies = props.fix_data.companies
        delete props.fix_data.companies
        delete props.fix_data.groups
        let arr = []
        let i
        if(groups){ for (i = 0; i < groups.length; i++) {
            arr.push(
                {
                    id: `${groups[i].id},group`,
                    name: groups[i].group.name,
                    number: groups[i].group.number
                }
            )
        }
        for (i = 0; i < companies.length; i++) {
            arr.push(
                {
                    id: `${companies[i].id},company`,
                    name: companies[i].company.name
                }
            )
        }}
        setlist(arr)
        setvalues({ ...props.fix_data })
    }, [props.fix_data])


    const amountChangeHandler = (e) => {
        let amount = +e.target.value;
        if (amount < 0) amount = 0
        if (amount > props.fix_data.sum && !props.modal_settings.fill) amount = props.fix_data.sum
        setvalues({ ...values, sum: amount })
    }

    useEffect(() => {
        props.arrayChangeHandler(values)
    }, [values])

    return (
        <div>
            {!props.modal_settings.fill && 
            <>
            <label for="way">Направление</label>
            <Select
                //value_error={props.errorCheker('status') === '#db3031' && true}
                s_value={values.to_balance.id}
                nameState={'id'}
                selected={0} stateError={'id' + "_error"}
                myChangeHandler={(e) => changeHandler(e, 'id', 'int')}
                text={'Направление перевода'}
                value={list}
                newSort_key={'number'}
                name={'way'}
                ChangeError={'id'}
                split_select={'number'}
                width="100%" />
            </>
            }
            <div style={{ marginTop: '15px' }} >
                <label for="sum" >Сумма</label>
                <input name="sum" type="number" value={values.sum} onChange={e => amountChangeHandler(e)} />
            </div>
        </div>
    )
}
