import React, {useEffect, useRef, useState} from 'react';

import './QuestionImagesCarousel.scss'

import {ReactComponent as IconArrow} from "components/ContentBox/img/left-arrow-2.svg";
import Stores from 'Stores'

export default function QuestionImagesThumb(props) {

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

    const reset = () => setCurrentSlideIndex(0)

    useEffect(() => {
        window.addEventListener('resize', reset)

        return () => {
            window.removeEventListener('resize', reset)
        }
    }, [])

    useEffect(() => {
        const slides = refSlides.current
        const slide = slides.children[currentSlideIndex]
        if (slide){
            const offset = Math.min(slide.offsetLeft, slides.scrollWidth - slides.parentElement.offsetWidth)
            slides.style.left = -offset+'px'
        }
    }, [currentSlideIndex])

    const refSlides = useRef()

    const onSlideHandler = direction => {
        if (direction === 'prev')
            setCurrentSlideIndex(Math.max(currentSlideIndex - 1, 0))
        if (direction === 'next')
            setCurrentSlideIndex(Math.min(currentSlideIndex + 1, props.images.length - 1))
    }

    return (
        <>
        <div className="lessonQuestion__contentTextOnly" dangerouslySetInnerHTML={{__html : props.text}} />
        <div className="questionImagesCarousel">
            <div className="questionImagesCarousel__slider">
                <ul ref={refSlides}>
                    {props.images.map((img, index)=>
                        <li key={index}>
                            <div>
                                <img src={Stores.baseLink() + img.file} alt={img.description} />
                            </div>
                            <div>{img.description}</div>
                        </li>
                    )}
                </ul>
                <div className="questionImagesCarousel__control">
                    <div onClick={()=>{onSlideHandler('prev')}} data-disabled={currentSlideIndex<1}><IconArrow/></div>
                    <span>{`${currentSlideIndex+1}`.padStart(2, '0') + ' из ' + `${props.images.length}`.padStart(2, '0')}</span>
                    <div onClick={()=>{onSlideHandler('next')}} data-disabled={currentSlideIndex>props.images.length-2}><IconArrow/></div>
                </div>
            </div>
        </div>
        </>
    )
}
