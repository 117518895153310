import React from 'react'
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import st from "./DiscountMarkup.module.css"
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";
import {dateFormat} from "helpers/date";
import asideModalStore from "Stores/asideModalStore";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import FormField from "components/UI/AsideModal/Form/FormField";


const DiscountMarkup = () => {
    const {changeValue, check, modalValues, modalSettings } = asideModalStore
    const isDiscountMode = modalSettings.action === 'discount'

    return (
        <div className={st.form}>
            <FormField>
                <FormLabel isRequired>{isDiscountMode ? "Discount Type" : "Surcharge Type"}:</FormLabel>
                <SelectCustom
                    placeholder={`${localisationMaster('select', 'groups/group', 'dors')} ${isDiscountMode ? localisationMaster('discount', 'groups/group', 'dors') : localisationMaster('surcharge', 'groups/group', 'dors')}`}
                    apiLink={isDiscountMode ? 'groups/discounts' : 'groups/surcharges'}
                    apiConfParams={{limit: 999}}
                    sortKey="name"
                    getOptionLabel={opt => `${opt.name} (${opt.type_name}, ${opt.count}${opt.type === 1 ? 'тг' : '%'})`}
                    valueID={modalValues[isDiscountMode ? 'discount_id' : 'surcharge_id'] || null}
                    onChange={o => changeValue(o.id, isDiscountMode ? 'discount_id' : 'surcharge_id')}
                    isSearchable
                    error={check(isDiscountMode ? 'discount_id' : 'surcharge_id')}
                />
            </FormField>
            <FormDateSelector
                placeholder="Start Date"
                isRequired={true}
                button={{name: "Today", action: "today"}}
                label="Start Date"
                onChange={val => changeValue(dateFormat(val, "YYYY-mm-dd"), "date_from")}
                value={modalValues.date_from || ''}
                error={check('date_from')}
            />
            <FormDateSelector
                placeholder="End Date"
                isRequired={false}
                button={{name: "Reset", action: "reset"}}
                label="End Date"
                onChange={val => changeValue(val ? dateFormat(val, "YYYY-mm-dd") : null, "date_until")}
                minDate={new Date(modalValues.date_from)}
                value={modalValues.date_until || ''}
            />
            <ToggleCheckbox
                label="Send notification"
                checked={modalValues.send_email}
                onChange={() => changeValue(!modalValues.send_email, "send_email")}
            />
        </div>
    )
}

export default observer(DiscountMarkup);
