import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import Paper from 'components/UI/Paper/Paper';
import Button from 'components/UI/Button/Button';
import Flex from 'components/UI/Flex/Flex';
import { dateFormat } from 'helpers/date';
import slotsRecordCustomerStore, { ActionType } from 'Stores/slotsRecordCustomerStore';
import { maskPhone } from 'helpers/phoneMask';
import styles from './SubmitLayout.module.scss';

const createContentElement = (data, slotTime) => {
  const content = document.createElement('dl');
  const {client_name, date_at, email, phone, payment_status, order, amount} = data;
  const name = `${client_name.split(' ')[0]} ${client_name.split(' ')[1]}`
  content.className = styles.swal_content;
  content.innerHTML = `
  <dt>Имя:</dt>
  <dd>${name}</dd>
  <dt>Дата:</dt>
  <dd>${slotTime}, ${dateFormat(new Date(date_at), 'dd MMMM')}</dd>
  <dt>E-mail:</dt>
  <dd>${email || 'Нет'}</dd>
  <dt>Телефон:</dt>
  <dd>${maskPhone(phone) || 'Нет'}</dd>`;

  if (payment_status && !order) {
    content.insertAdjacentHTML('beforeend', `<dt>Оплата:</dt>
  <dd>Подтверждено</dd>`);
  }

  if (order) {
    content.insertAdjacentHTML('beforeend', `<dt>Ордер:</dt>
  <dd>${order}</dd>
  <dt>Сумма:</dt>
  <dd>${amount / 100} тг.</dd>`);
  }

  content.insertAdjacentHTML(
    'beforeend',
    `<dt class=${styles.swal_content__link}><a class=${styles.swal_content__link_text} href="/online-schedule-v2/booked-schedule">Перейти на страницу "Записанные клиенты"</a></dt>`);

  return content;
};

const showSuccessSwal = (data, slotTime) => (
  swal({
    icon: 'success',
    title: 'Клиент успешно записан!',
    content: createContentElement(data, slotTime),
    button: true,
  }));

function SubmitLayout() {
  const history = useHistory();
  const { id: slotID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitDisabled, resetData, sendDataToServer, getSelectedSlotTime } = slotsRecordCustomerStore;

  const handleSubmit = () => {
    setIsLoading(true);
    sendDataToServer({
      onSuccess: (data) => {
        showSuccessSwal(data, getSelectedSlotTime()).then(() => {
          if (slotID) {
            history.push(`/online-schedule-v2/record-customer/${data.id}`);
          }
          resetData(ActionType.resetCalendar);
        });
      },
      onFail: (message) => swal('Ошибка записи клиента', message, 'warning').then(() => setIsLoading(false)),
      onFinally: () => {
        if (isLoading) {
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <Paper>
      <Flex withGap md='center'>
        <Button onClick={handleSubmit} isDisabled={isLoading || isSubmitDisabled}>
          Записать клиента
        </Button>
        <Button
          onClick={() => resetData(ActionType.resetAll)}
          isDisabled={isLoading}
          variant='secondary'
        >
          Отменить запись
        </Button>
      </Flex>
    </Paper>
  );
}

export default observer(SubmitLayout);
