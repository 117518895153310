import React, { useState, useEffect } from "react";
import { countries } from "./countries";
import { validMask } from "./validMask";
import { classNames as cn } from "helpers";
import "./InputFlagPhone.scss";
import { useRef } from "react";

const InputFlagPhone = ({
  onChange,
  error,
  value = "",
  required = false,
  disabled,
  onValid = () => {}
}) => {
  const [modal, setModal] = useState(false);
  const [numbers, setNumbers] = useState("");
  const [code, setCode] = useState(countries[0]);
  const [classes, setClasses] = useState([]);

  const getPhone = (val) => {
    const { mask } = validMask(code.type + val);
    setNumbers(mask);
  }

  const parseNumber = (type, num) => `${parseInt(`${type}${num}`.replace(/\D+/g, ""))}`;

  const changeCode = (val) => {
    setCode(val);
    setNumbers("");
    setModal(false);
  }

  const changeClasses = (type, className) => {
    if(type === 'add') {
      setClasses([...classes, className])
    }
    if(type === 'remove') {
      setClasses(classes.filter(item => item !== className))
    }
  }

  const validPhone = ({ phone, validLength }) => phone && phone.length === validLength;

  const selectorRef = useRef(null);

  const handleDocumentClick = (event) => {
    if (selectorRef.current && selectorRef.current.contains(event.target)) {
      return;
    }
    setModal(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (numbers === "") {
      onChange(""); return;
    }
    const phoneNumber = parseNumber(code.type, numbers);
    onChange(`${phoneNumber}`);
    //eslint-disable-next-line
  }, [numbers])

  useEffect(() => {
    if (value?.length && numbers === "") {
      const { type, mask } = validMask(value.startsWith("+") ? value : `+${value}`);
      const country =
        countries.find((item) => item.type === type) || countries[0];
      setCode(country);
      setNumbers(mask);
      onChange(parseNumber(type, mask));
    }
    //eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    onValid(validPhone({ phone: value, validLength: code.validLength  }));
  }, [value, code.type]);

  return (
    <div className='InputFlagPhone' ref={selectorRef}>
      <div
        className={cn({
          'InputFlagPhone__wrapper': true,
          [classes.join(' ')]: classes.length > 0,
          "InputFlagPhone__wrapper--error": error,
          "InputFlagPhone__wrapper--disabled": disabled
        })}
      >
        <div
          className={cn({
            'InputFlagPhone__modal': true,
            'InputFlagPhone__modal--open': modal
          })}
        >
          {countries.map((item) => (
            <p
              key={item.title}
              className={item.title === code.title ? "InputFlagPhone__modal--active" : ""}
              onClick={() => !disabled && changeCode(item)}
            >
              {item.icon()} {item.type}
            </p>
          ))}
        </div>
        <div
          onClick={() => setModal(!modal)}
          className={cn({
            'InputFlagPhone__btn': true,
            'InputFlagPhone__btn--active': modal,
          })}
        >
          {code.icon()} {code.type}
        </div>
        <input
          type="tel"
          onChange={(e) => !disabled && getPhone(e.target.value.replace(/\D/g, ""))}
          value={numbers}
          maxLength={code.maxLength}
          minLength={code.minLength}
          onClick={() => setModal(false)}
          onFocus={() => changeClasses('add', "InputFlagPhone__wrapper--focus")}
          onBlur={() => changeClasses('remove', "InputFlagPhone__wrapper--focus")}
          onMouseOver={()=> changeClasses('add', "InputFlagPhone__wrapper--hover")} 
          onMouseOut={()=> changeClasses('remove', "InputFlagPhone__wrapper--hover")}
          required={required}
          placeholder={disabled || error ? "(xxx) xxx-xx-xx" : "(_ _ _) _ _ _-_ _-_ _"}
        />
      </div>
    </div>
  );
};

export default InputFlagPhone;
