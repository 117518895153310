import React, {useState} from 'react';
import {localisationMaster} from "Localisation/Localisation";
import './ColorSelector.css';
import br_down from '../../img/br_down.png'

export default function ColorSelector(props) {
    const colors = [
        'ededed',
        'ff371d',
        'b37be6',
        'ff92b5',
        '00a960',
        'c3c3c3',
        '0087eb',
        'bf735d',
        'ff1865',
        'b623b6',
        'b40031',
        '009888',
        '006600',
        '482498',
        '507e8d',
        'ff8281',
        'b57800',
        '808100',
        '3f0007',
        '874500',
        '455088',
        '00555a',
    ]

    const [dropdown, setDropdown] = useState(false)

    const colorHandler = val => {
        props.objectChangeHandler(val, 'color')
        setDropdown(false);
    }

    return (
        <div className="color-selector-box">
            <span>{localisationMaster('group_color', 'groups/group', 'group_schedule')}:</span>
            <div className="color-selector-box-selector" onClick={() => setDropdown(!dropdown)}>
                <div className="color-selector-box-item" style={{backgroundColor: '#' + props.value}}/>
                <div>
                    <img width='10px' src={br_down} alt=""/>
                </div>
            </div>
            <div className={dropdown ? 'color-selector-box-drop' : 'dn'}>
                {colors.filter(x => x !== props.value).map(item =>
                    <div key={item}
                         className="color-selector-box-item"
                         onClick={() => colorHandler(item)}
                         style={{backgroundColor: '#' + item}}>
                    </div>)}
            </div>
        </div>
    )
}
