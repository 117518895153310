import { localisationMaster } from 'Localisation/Localisation';

export const docs = [
    {
        link:'push-clients-docs',
        folder: 'docs',
        permission: ['basedocmodel', 'add'],
        button: '',
        modal_type: '',
        title: localisationMaster('title', 'docs/push-clients-docs'),
        colsSettings: {
            action: [
                { name: localisationMaster('title-modal-newAction', 'docs/push-clients-docs'), action: 'pushContract', permission: ['basedocmodel', 'add']},
            ],
            prefix: 'documents',
            link: 'get_all_doc_types',
            sub_link: 'students_doc/send_document/',
            id: { name: 'type' },
            main_rows: [
                { name: 'type_description', depence: false},
            ],
            is_open: false,
            sub_rows: [
                { name: 'type_description', description: localisationMaster('table-row-title-1', 'docs/push-clients-docs'), },
            ]
        },
        
        apiSettings: {
            is_teacher: true,
        }
    },

    {
        link:'clients-docs',
        folder: 'docs',
        permission: ['basedocmodel', 'add'],
        button: '',
        modal_type: '',
        modal_placeholder: '',
        title: localisationMaster('title', 'docs/clients-docs'),
        colsSettings: {
            action: [
                { name: localisationMaster('pdf', 'docs/push-clients-docs'), action: 'download' , permission: ['basedocmodel', 'add']},
            ],
            prefix: 'documents',
            link: 'students_doc/documents_list',
            sub_link: 'students_doc/send_document/',
            id: { name: 'type' },
            main_rows: [
                { name: 'full_name', depence: false},
                { name: 'type_description', depence: false},
            ],
            is_open: false,
            sub_rows: [
                { name: 'full_name', description: localisationMaster('table-row-title-1', 'docs/clients-docs'), },
                { name: 'type_description', description: localisationMaster('table-row-title-2', 'docs/clients-docs'), },
            ]
        },
      /*   multitype: true,
        mulitype_values:["is_signed",localisationMaster('sub-menu-button-1', 'docs/clients-docs'), localisationMaster('sub-menu-button-2', 'docs/clients-docs')], */
        apiSettings: {
        }
    },
    {
        link:'signing',
        folder: 'docs',
        permission: ['teacherquestion', 'view'],
        title: localisationMaster('title', 'docs/clients-docs'),
        pagination: false,
        colsSettings: {
            action: [
                { name: localisationMaster('text-info-4', 'docs/push-clients-docs'), action: 'doc_sign' , permission: ['teachertestresult', 'view']},
            ],
            prefix: 'documents',
            link: 'students_doc/documents_list',
            sub_link: 'doc_sign',
            arr: true,
            id: { name: 'id' },
            main_rows: [
                { name: 'type_description', depence: false},
                { name: 'status', depence: false},
            ],
            is_open: false,
            sub_rows: [
                { name: 'type_description', description: localisationMaster('table-row-title-1', 'docs/push-clients-docs'), },
                { name: 'status', description: localisationMaster('text-info-3', 'docs/push-clients-docs'), },
            ]
        },
        
        apiSettings: {}
    },
]