import React, {useEffect, useRef, useState} from 'react';
import { aCountry, maskPhone } from 'helpers/phoneMask';
import './InputPhone.scss';

const getCurrentCountry = (currentCountryId) => aCountry.filter(country => country.id === currentCountryId)[0];
// Получение телефона для возврата
const getPhone = (phone, currentCountryId) => {
    let cleanPhone = '';

    if (phone.length > 0) {
        const {code} = getCurrentCountry(currentCountryId);
        cleanPhone += code;
        cleanPhone += phone.slice(0,10);
    }
    return cleanPhone;
};

export default function InputPhone({value, onChange, error, disabled}) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentCountryId, setCurrentCountryId] = useState(1);
    const [phone, setPhone] = useState('');
    const [phoneClass, setPhoneClass] = useState(false);

    const refInput = useRef();

    const changeCountryHandler = id => {
        setCurrentCountryId(id);
        setIsOpen(false);
        setPhone('')
    }

    const changePhone = val =>setPhone(val.replace(/[^\d]+/g, ''))

    useEffect(() => {
        if (value !== undefined && value !== null && value.length > 0){
            // Вырежем код страны из телефона
            let phoneWithoutCode = ''

            let country = aCountry.find(country=>value.indexOf(country.code)===0)

            if (country){
                setCurrentCountryId(country.id)
                phoneWithoutCode = value.substr(country.code.length).replace(/[^\d]+/g, '')
            }

            setPhone(phoneWithoutCode)
        }
    }, [value])

    useEffect(() => {
        const input = refInput.current;
        const maskedPhone = maskPhone(phone, currentCountryId);

        for (let i = 0; i < maskedPhone.length; i++) {
            if (/[\d]/.test(maskedPhone[i])) {
                input.setSelectionRange(i + 1, i + 1);
            }
        }

        let a = getPhone(phone, currentCountryId);
        const { length } = aCountry.find(({ id }) => currentCountryId === id);
        if (a.length === length) {
            onChange(a);
        }

    }, [phone, currentCountryId]);

    useEffect(() => {
        if (isOpen)
            document.body.addEventListener('click', ()=>setIsOpen(false), {once: true})
    }, [isOpen])

    const country = getCurrentCountry(currentCountryId)

    return (
        <div className={!phoneClass ? "input-phone" : 'input-phoneNew'} style={{borderColor: error && '#db3031'}}>

            <div
                className={"input-phone__current"+(disabled ? " input-phone__current--disabled" : "") }
                onClick={() => {disabled ? setIsOpen(false) : setIsOpen(!isOpen)}}
            >
                <span>{country.code}</span>
            </div>

            <ul className={isOpen ? "input-phone__dropdown" : "dn"} >
                {aCountry.filter(country => country.id !== currentCountryId).map(country => {
                    return (
                        <li key={country.id} onClick={() => changeCountryHandler(country.id)}>
                            {country.code}
                        </li>
                    )
                })}
            </ul>

            <input disabled={disabled && true}
                   onFocus={() => setPhoneClass(true)}
                   onBlur={() => setPhoneClass(false)}
                   type="tel"
                   value={maskPhone(phone, currentCountryId)}
                   onChange={e => changePhone(e.target.value)}
                   ref={refInput}
            />

        </div>
    )
}
