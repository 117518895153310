import { localisationMaster } from 'Localisation/Localisation';

export const online = [
    {
        folder: 'online-schedule',
        link: 'clients-after-online',
        permission: ['studytest', 'view'],
        //button: ['Добавить тест', 'program', 'add'],
        title: localisationMaster('text_1', 'online-schedule/clients-after-online'),
        colsSettings: {
            action: [],
            is_slot: true,
            link: 'clients',
            prefix: 'time_slots/admin',
            id: { name: 'id' },
            main_rows: [],
            is_open: false,
            sub_rows: [
                { name: 'name', description: localisationMaster('table-row-title-3', 'tests/questions'), },
            ]
        },
        surfMenu: 1,
        filterLiner: true,
        filterLinerLink: 'time_slots/admin/clients',
        config: [
            {
                //id: 1,
                nameState: 'search',
                placeholder: localisationMaster('placeholder_1', 'online-schedule/clients-after-online'),
                type: "input"
            },
            {
                //id: 2,
                nameState: 'date',
                placeholder: localisationMaster('placeholder_2', 'online-schedule/clients-after-online'),
                type: "select",
                values: [
                    {id: 1, name: "Прошли тест сегодня"},
                    {id: 2, name: "Прошли тест вчера"},
                    {id: 3, name: "Прошли тест позавчера"},
                    {id: 4, name: "Прошли тест раньше"}
                ]
            },
            {
                //id: 4,
                apiLink: 'accounts/teachers',
                nameState: 'teacher',
                placeholder: localisationMaster('placeholder_3', 'online-schedule/clients-after-online'),
                type: "select_request"
            },
            {
                //id: 4,
                apiLink: 'db/programs',
                nameState: 'program',
                placeholder: localisationMaster('select-one', 'tests/settings', 'add_bundle'),
                type: "select_request"
            },
            {
                //id: 5,
                apiLink: 'db/age',
                nameState: "age",
                placeholder: localisationMaster('select-two', 'tests/settings', 'add_bundle'),
                type: "select_request",
            },
            {
                nameState: 'level_name',
                placeholder: 'Уровень',
                type: "input"
            },
        ],
        apiSettings: {}
    }
]
