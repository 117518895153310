import React, { useState, useEffect } from 'react';
import Content from "./Content/Content";
import Loader from "components/ContentBox/Global/Loader/Loader";
import slotsTeacherRecordOnlineStore from "Stores/slotsTeacherRecordOnlineStore";
import AvatairCreator from "components/ContentBox/Global/AvatairCreator/AvatairCreator";
import { validImage } from "helpers";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import logo from 'components/HeadBox/img/logo.png';
import styles from './TeacherRecordOnline.module.scss';

const TeacherRecordOnline = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const link = props.match.params.link;

    const { teacher, getTeacherInfo } = slotsTeacherRecordOnlineStore;

    const splitFullName = () => {
        const arr = (teacher?.full_name || 'неизвестно неизвестно').split(' ');
        return { l_name: arr[0], name: arr[1] };
    }

    useEffect(() => {
        getTeacherInfo({
            link,
            onSucces: setIsLoading(false),
            onFail: () => {
                setIsLoading(false);
                setError(true);
            },
        });
    }, []);
    
    return (
        <div className={styles.page}>
            {isLoading
                ? <Loader />
                : (
                    <>
                        {error
                            ? <Redirect to='/404' />
                            : (
                                <>
                                    <header className={styles.header}>
                                        <h1 className={styles.title}>Онлайн запись на устное тестирование</h1>
                                        <div className={styles.teacherCard}>
                                            <div className={styles.teacherCard__wrapper}>
                                                {teacher?.avatar && validImage(teacher?.avatar)
                                                    ? <img src={teacher?.avatar} alt="photo" />
                                                    : (
                                                        <div className={styles.avatar}>
                                                            <AvatairCreator width="110px" height="110px" {...splitFullName()} squad={true} />
                                                        </div>
                                                    )
                                                }
                                                <div className={styles.teacherCard__content}>
                                                    <p>Ваш преподователь</p>
                                                    <p>{teacher?.full_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                    <div className={styles.center}>
                                        <div className={styles.wrapper}>
                                            <main>
                                                <Content />
                                            </main>
                                            <footer className={styles.footer}>
                                                <p className={styles.footer_text}>powered by</p>
                                                <img className={styles.footer_img} src={logo} width='20' alt='логотип компании в виде феникса' />
                                            </footer>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default observer(TeacherRecordOnline);


