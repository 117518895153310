import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import modalStore from 'Stores/modalStore';
import './Salary.scss'
import {ReactComponent as IconInfinity} from "components/ContentBox/img/infinity.svg";

const Salary = () => {
    const {changeValuesArray, modalValue, changeValues, check} = modalStore

    const calculateRates = (state, calculate_type) => {
        let newState = {...state}
        switch (calculate_type) {
            case 'standard':
                newState.academ_hour.rate.forEach((academ_rate, id) => {
                    if (id > 2) {
                        newState.academ_hour.rate[id] = newState.academ_hour.rate[0] + (id - 3) * 100
                    }
                    else {
                        newState.academ_hour.rate[id] = newState.academ_hour.rate[0]
                    }
                    newState.astro_hour.rate[id] = Math.round(newState.academ_hour.rate[id] * 1.33)
                })
        
                newState.academ_hour.rate_online.forEach((academ_rate, id) => {
                    if (id > 2) {
                        newState.academ_hour.rate_online[id] = newState.academ_hour.rate_online[0] + (id - 3) * 100
                    }
                    else {
                        newState.academ_hour.rate_online[id] = newState.academ_hour.rate_online[0]
                    }
                    newState.astro_hour.rate_online[id] = Math.round(newState.academ_hour.rate_online[id] * 1.33)
                })
                break;
            case 'individual':
                newState.academ_hour.rate.forEach((academ_rate, id) => {
                    newState.academ_hour.rate[id] = newState.academ_hour.rate[0]
                    newState.astro_hour.rate[id] = Math.round(newState.academ_hour.rate[id] * 1.33)
                })
                newState.academ_hour.rate_online.forEach((academ_rate, id) => {
                    newState.academ_hour.rate_online[id] = newState.academ_hour.rate_online[0]
                    newState.astro_hour.rate_online[id] = Math.round(newState.academ_hour.rate_online[id] * 1.33)
                })
                break;
        
            default:
                break;
        }       
        return newState
    }

    const editRate = (value, hourType, rateType, isIndividual) => {
        let newState = {...modalValue}
        newState[hourType][rateType][0] = Number(value)
        newState = isIndividual ? calculateRates(newState, 'individual' ) : calculateRates(newState, 'standard')
        changeValuesArray({...modalValue, astro_hour: newState.astro_hour, academ_hour: newState.academ_hour ,is_individual: isIndividual})
    }

    useEffect(() => {
        if (!modalValue.astro_hour) {
            let initial = {
                rate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                rate_online: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
            changeValuesArray({...modalValue, astro_hour: initial, academ_hour: initial})
        }
        else {
            let initial = {astro_hour: modalValue.astro_hour, academ_hour: modalValue.academ_hour, name: modalValue.name, is_individual: modalValue.is_individual}
            if (modalValue.comment) initial.comment = modalValue.comment
            changeValuesArray(initial)
        }

    }, [])




    return (
        modalValue.astro_hour && <div className="super-modal__form-column salaryBox">
            <div className="super-modal__form-field">
                <div className="super-modal__form-label super-modal__form-label--required">Название ставки:</div>
                <input type="text" value={modalValue.name || ''}
                    onChange={e => changeValues(e.target.value, 'name')}
                    className={check('name') ? 'super-modal__input--error' : undefined}
                />
            </div>
            <CheckboxToggle checked={modalValue.is_individual}
                    right={'Индивидуальные ставки'}
                    mode="inline"
                    onChange={(e) => {
                        editRate(modalValue.academ_hour.rate[0], 'academ_hour', 'rate', e)                
                }} />
            <div className="salaryBox__ratesHeader">
                <span className="super-modal__form-label salaryBox__label">Количество учеников:</span>
                <div className="salaryBox__col">
                    <span className="super-modal__form-label salaryBox__label">Академические:</span>
                    <div className="salaryBox__subrow">
                        <span className="super-modal__form-label">Онлайн:</span>
                        <span className="super-modal__form-label">Офлайн:</span>
                    </div>
                </div>
                <div className="salaryBox__col">
                    <span className="super-modal__form-label salaryBox__label">Астрономические:</span>
                    <div className="salaryBox__subrow">
                        <span className="super-modal__form-label">Онлайн:</span>
                        <span className="super-modal__form-label">Офлайн:</span>
                    </div>
                </div>
            </div>

            <div className="salaryBox__rates">
                <div className="salaryBox__rate" >
                    <span>
                        0 {typeof modalValue.academ_hour.rate[1] !== 'number' && <> - <IconInfinity />  </>}
                    </span>
                    <div className="salaryBox__subrow">
                        <input type="number" onChange={e => editRate(e.target.value, 'academ_hour', 'rate_online', modalValue.is_individual)} value={modalValue.academ_hour.rate_online[0]} />
                        <input type="number" onChange={e => editRate(e.target.value, 'academ_hour', 'rate', modalValue.is_individual)} value={modalValue.academ_hour.rate[0]} />
                    </div>
                    <div className="salaryBox__subrow">
                        <input disabled type="number" value={modalValue.astro_hour.rate_online[0]} />
                        <input disabled type="number" value={modalValue.astro_hour.rate[0]} />
                    </div>
                </div>

                {modalValue.astro_hour.rate.map((item, index) =>
                    index !== 0 && <div className="salaryBox__rate" key={index}>
                        <span>
                            {index}
                            {index === modalValue.astro_hour.rate.length - 1 && <> - <IconInfinity /></>}
                        </span>
                        <div className="salaryBox__subrow">
                            <input disabled type="number" value={modalValue.academ_hour.rate_online[index]} />
                            <input disabled type="number" value={modalValue.academ_hour.rate[index]} />
                        </div>
                        <div className="salaryBox__subrow">
                            <input disabled type="number" value={modalValue.astro_hour.rate_online[index]} />
                            <input disabled type="number" value={modalValue.astro_hour.rate[index]} />
                        </div>
                        {/* <div>
                            {index > 0 && <div className="salaryBox__close" onClick={() => deleteRate(index)}>
                                &times;
                            </div>}
                        </div> */}
                    </div>
                )}
            </div>
            {/* <div>
                <div className={"modal-question-add-button-add " + (modalValue.astro_hour.rate.includes('') ? 'disabled' : '')}
                    onClick={() => addRate()}> +
                </div>
            </div> */}
            <div>
                <div>
                    <textarea
                        placeholder="Комментарий"
                        value={modalValue.comment || ''}
                        onChange={e => changeValues(e.target.value, 'comment')} />
                </div>
            </div>
        </div>
    )
}

export default observer(Salary)




