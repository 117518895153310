import React from 'react';
import './duration.scss';
import DatePicker from "react-datepicker";
import {timeConverter} from '../../Online-schedule/components/functions';
import { localisationMaster } from 'Localisation/Localisation';



export default function Duration({handler, value, cap1}) {

    const cleanTime = (date) =>{
        let h = date.getHours()
        if(+h < 10) h = '0' + h
        let m = date.getMinutes()
        if(+m < 10) m = '0' + m
        return `${h}:${m}`
    }

    
    return (
        <div className="duration">
            <DatePicker
                locale="ru"
                selected={new Date(value.date_from)} onChange={date => handler(timeConverter(date, 'today'), 'date_from')}
                dateFormat="dd.MM.yyyy"
            />
            <div className="duration--bl">
           <DatePicker
                            selected={new Date('2020-01-01 '+ value.start_at)}
                            timeCaption="Heure"
                            onChange={date => handler(cleanTime(date), 'start_at')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={cap1 ? cap1 : 'Начало'}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                />
            </div>
            <div className="duration__delimiter">
                {localisationMaster('text_15', 'company/locations' )}
            </div>
            <div className="duration--br">
            <DatePicker
                locale="ru"
                selected={new Date(value.date_until)} onChange={date => handler(timeConverter(date, 'today'), 'date_until')}
                dateFormat="dd.MM.yyyy"
            />
            </div>
            <DatePicker
                            selected={new Date('2020-01-01 '+ value.finish_at)}
                            timeCaption="Heure"
                            onChange={date => handler(cleanTime(date), 'finish_at')}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption={cap1 ? cap1 : 'Конец'}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                />
        </div>
    )
}
