import React, { useState, useEffect, useRef } from "react";
import { Options, Fields, Ligaments, Result, Letter, CRM } from "./components";
import ContentHeader from "components/ContentBox/Global/ContentHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import { localisationMaster } from "Localisation/Localisation";
import { superAxiosRequest } from "axiosApi";
import { useHistory } from "react-router-dom";
import { defaultForm } from "./data";
import swal from "sweetalert";
import "./style.scss";

const CreatePlacementTest = ({ match: { params } }) => {
  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [loading, setLoading] = useState(!!params?.id);
  const [error, setError] = useState([]);
  const [sending, isSending] = useState(false);
  const [crmFields, setCrmFields] = useState({ data: [], loading: true });
  const [optionsBundle, setOptionsBundle] = useState({
    name: "bundle",
    is_required: true,
    type: "bundles",
    placeholder: "",
    selected: true,
  });

  const optionsRef = useRef(null);
  const resultRef  = useRef(null);
  const crmRef     = useRef(null);
  const fieldsRef  = useRef(null);
  const bundleRef  = useRef(null);
  const letterRef  = useRef(null);

  const blocks = [
    { name: "title",         ref: optionsRef }, 
    { name: "company",       ref: optionsRef }, 
    { name: "url",           ref: optionsRef },
    { name: "active_fields", ref: fieldsRef  },
    { name: "bundle",        ref: bundleRef  },
    { name: "bundles",       ref: bundleRef  },
    { name: "answers_unit",  ref: resultRef  }, 
    { name: "html_body",     ref: resultRef  }, 
    { name: "crm_fields",    ref: crmRef     }, 
    { name: "crm_slug",      ref: crmRef     }, 
    { name: "crm_widget_url", ref: crmRef     },
    { name: "finished_email", ref: letterRef },
    { name: "timeout_email",  ref: letterRef }
  ];

  useEffect(() => {
    if(error.length) {
      error[0].ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [error]);

  useEffect(() => {
    superAxiosRequest({
      method: "get",
      link: "placement_test/crm_fields",
    }).then((res) => {
      const crmFieldsData = res.data;
      if (!params?.id) {
        setCrmFields({
          data: crmFieldsData.map((item) => ({ ...item, is_enabled: false, crm_name: "" })),
          loading: false,
        }); return;
      }
      superAxiosRequest({
        method: "get",
        link: `placement_test/widget/${params.id}`,
      }).then((response) => {
        const { active_fields, company, crm_fields = [], ...data } = response.data;
        setForm({
          ...form,
          ...data,
          company: company.id,
          ...(data?.finished_email && { finished_email: data.finished_email.id }),
          ...(data?.timeout_email && { timeout_email: data.timeout_email.id }),
          ...(data?.data_collect_email && { data_collect_email: data.data_collect_email.id }),
          active_fields: form.active_fields.map((item) => {
            const field = active_fields.find((el) => el.name === item.name);
            return field?.name
              ? { ...field, selected: true, title: item.title }
              : item;
          }),
        });

        const bundleField = active_fields?.find((item) => item.name === "bundle") || {};
        setOptionsBundle({ ...optionsBundle, ...bundleField, selected: !!bundleField?.name });

        setCrmFields({
          data: crmFieldsData.map((item) => {
            const field = crm_fields?.find((el) => el.name === item.name);
            return field?.name ? field : { ...item, is_enabled: false, crm_name: "" };
          }),
          loading: false,
        });
        setLoading(false);
      });
    });
    // eslint-disable-next-line
  }, []);

  const changeForm = (data, key) => {
    setForm({ ...form, [key]: data });
  };

  const validate = (isError) => {
    return error.some((err) => err.name === isError);
  };

  const validForm = (data) => {
    const uniqueKeys = [
      "url", "crm_fields", "crm_slug", "active_fields", "bundle", "bundles", 
      "crm_widget_url", "finished_email", "timeout_email",
    ];
    const blankFields = [];
    blocks.forEach((key) => {
      const { name } = key;
      if(!uniqueKeys.includes(name)) {
        if(data[name] === "" || data[name] === 0) {
          blankFields.push(key);
        }
      }
      if(name === "url") {
        if(data.url.length < 10) {
          blankFields.push(key);
        }
      }
      if (name === "crm_slug"){
        if(data.crm_send && data.crm_slug.length < 3) {
          blankFields.push(key);
        }
      }
      if (name === "crm_fields" && data.crm_send) {
        const fields = data.crm_fields.filter((item) => item.crm_name === "");
        if (fields.length) {
          blankFields.push(key);
        }
      }
      if (name === "active_fields") {
        const fields = data.active_fields.filter((item) => {
          if (item.placeholder.length < 2 && item.name !== "bundle") return item;
          if ((item.name === "agreement" && item.data.length === 0) || 
              (item.name === "locations" && item.data.length === 0)) return item;
          return false
        })
        if (fields.length) {
          blankFields.push(key);
        }
      }
      if(optionsBundle.selected) {
        if(name === "bundle") {
          if(!optionsBundle.placeholder.length) {
            blankFields.push(key);
          }
        }
        if(name === "bundles") {
          if(!data.bundles.length) {
            blankFields.push(key);
          }
        }
      }
    });
    return blankFields;
  };

  const sendRequest = () => {
    const { active_fields, bundles = [] } = form;
    let fields = [...active_fields];
    if(optionsBundle.selected) {
      fields = [...fields, { ...optionsBundle, data: [...bundles] }];
    }
    const formRequest = {
      // формирование данных для бека
      ...form,
      active_fields: fields
        .filter((item) => item.selected) // берем только активные поля
        .map((item) => {
          // в поле локаций отправляем только айдишники
          if (item.name === "locations")
            return { ...item, data: item.data.map((item) => item.id) };
          return item;
        }),
      crm_fields: crmFields.data.filter((item) => item.is_enabled),
      bundles: optionsBundle.selected ? bundles.map((item) => item.id) : [],
    };
    // удаляем все пустые данные в полях
    fields.forEach((item, index) => {
      if (fields[index].data === null) {
        delete fields[index].data;
      }
    });
    // если логотип уже есть виджете то не отправляет повторно
    if ((formRequest.logo || "").startsWith("http")) {
      delete formRequest.logo;
    }
    const valid = validForm(formRequest);
    if (valid.length) {
      setError(valid); return;
    }
    isSending(true);
    superAxiosRequest(
      {
        method: params?.id ? "put" : "post",
        link: `placement_test/widget${params?.id ? `/${params?.id}` : ""}`,
      }, formRequest)
      .then(() => {
        setError([]);
        swal({
          title: localisationMaster(
            "success",
            "c/tests/placement-test/create-widget"
          ),
          icon: "success",
        }).then(okay => {
          okay && history.push("/c/placement_test/widget-list");  
        });
        isSending(false);
      })
      .catch(({ response }) => {
        const resError = Object.keys(response.data)[0];
        isSending(false);
        if (response.status === 400) {
          const result = blocks.find((item) => item.name === resError);
          setError([result]);
        } else {
          swal({
            title: localisationMaster(
              "error",
              "c/tests/placement-test/create-widget"
            ),
            icon: "error",
          }).then(() => {})
        }
      });
  };

  if (loading) return <Loader />;
  return (
    <div className="content-box">
      <div className="placementTestCreate">
        <ContentHeader
          title={localisationMaster(
            params?.id ? "title_2" : "title_1",
            "c/tests/placement-test/create-widget"
          )}
          breadСrumbs={[
            { title: 'Виджеты', route: '/c/placement_test/widget-list' }
          ]}
        />
        <Options // основные настройки виджета
          form={form}
          changeForm={changeForm}
          validate={validate}
          optionsRef={optionsRef}
        />
        <Fields // поля для формирование формочки виджета
          validate={validate}
          form={form}
          fieldsRef={fieldsRef}
          setForm={setForm}
        />
        <Ligaments // связки для виджета
          form={form}
          setForm={setForm}
          validate={validate}
          idTest={params?.id}
          optionsBundle={optionsBundle}
          setOptionsBundle={setOptionsBundle}
          bundleRef={bundleRef}
        />
        <Result // верстки для виджета
          form={form}
          changeForm={changeForm}
          validate={validate}
          resultRef={resultRef}
        />
        <Letter form={form} setForm={setForm} letterRef={letterRef} validate={validate} />
        <CRM
          validate={validate}
          form={form}
          setForm={setForm}
          crmFields={crmFields}
          setCrmFields={setCrmFields}
          crmRef={crmRef}
        />
        <div className="placementTestBox">
          <button
            className="placementTestBox__editBtn"
            onClick={sendRequest}
            disabled={sending}
          >
            {localisationMaster(
              params?.id ? "btn_request_edit" : "btn_request_create",
              "c/tests/placement-test/create-widget"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePlacementTest;
