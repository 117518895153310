import React, { useContext } from 'react';
import Paper from 'components/UI/Paper/Paper';
import { Context } from '../SlotDetails';
import styles from '../SlotDetails.module.scss';

const Status = {
  not_paid: 'Не оплачен',
  cancelled: 'Отменен',
  paid: 'Оплата прошла успешно',
};

export default function Payment() {
  const { slot_client } = useContext(Context);
  const {payment_status, payment_sum, sms_code, email_code, order_id} = slot_client;

  return (
    <Paper classes={`${styles.item} ${styles.payment}`}>
      {order_id
        ? <>
            <p>{Status[payment_status]}</p>
            <p>Ордер: {order_id}, Сумма: {payment_sum /100} тг.</p>
            <a className={styles.link} href={`/pays/invoice/${order_id}`} target='_blank' rel='noopener noreferrer'>
              (посмотреть ордер)
            </a>
          </>
        : <>
            <p>{sms_code ? 'СМС' : 'Email'} код подтверждения:</p>
            <p className={styles.code}>
              {sms_code ? sms_code : email_code}
              {!sms_code && !email_code && 'нет'}
            </p>
          </>
      }
    </Paper>
  );
}

