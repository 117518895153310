import React, {useEffect, useState} from 'react';
import Stores from 'Stores'
import Checkbox from 'components/ContentBox/Global/Checkbox'
import st from './CheckVariants.module.scss'

export default function CheckVariants(props) {
  const [values, setValues] = useState(props.question.answers)

  const checkHandler = index => {
    let arr = [...values];
    arr[index].is_correct = !arr[index].is_correct
    setValues([...arr])
  }

  useEffect(() => {
    if (props.answers){
      const arr = props.question.answers.map(a => ({value: a.value, is_correct: props.answers.includes(a.value)}))
      setValues([...arr])
    }
  }, [props.answers])

  useEffect(() => {
    const arr = values.filter(x => x.is_correct).map(value => value.value)
    props.onChange(arr)
  }, [values])

  return (
    <div className={st.variants}>{values.map((item, index) =>
      <div className={st.variant} key={index} onClick={() => checkHandler(index)}>
        <div>
          <Checkbox id={index} isChecked={() => {}} checked={item.is_correct || false} extraClass={st.checkbox}/>
        </div>
        <div className={st.img}>
          <img src={Stores.baseLink() + item.value.split('|')[0]} alt=""/>
        </div>
        <div>
          {item.value.split('|')[1]}
        </div>
      </div>)}
    </div>
  )
}
