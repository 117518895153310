import React, { useState } from 'react';
import swal from 'sweetalert';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import Button from 'components/UI/Button/Button';
import Flex from 'components/UI/Flex/Flex';
import slotsScheduleSettingsStore, { ActionType } from 'Stores/slotsScheduleSettingsStore';

function SaveSettings() {
  const { sendDataToServer, resetData } = slotsScheduleSettingsStore;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitClick = () => {
    setIsLoading(true);
    sendDataToServer(
      ActionType.sendAllDays,
      () => swal('', 'Настройки успешно сохранены', 'success'),
      (message) => swal(message, 'Ошибка сохранения, проверьте все данные', 'warning'),
    );
  };

  return (
    <Paper>
      <Flex withGap justify = "end" md='center'>
        <Button onClick={handleSubmitClick} isDisabled={isLoading}>
          Сохранить настройки
        </Button>
        <Button onClick={() => resetData()} isDisabled={isLoading} variant='secondary'>
          Отменить изменения
        </Button>
      </Flex>
    </Paper>
  );
}

export default observer(SaveSettings);

