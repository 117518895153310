import React from 'react';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Tabs from '../components/Tabs/Tabs';

const PaymentSection = () => (
  <>
    <SectionTitle title='Оплата' description='Выберите вариант регистрации клиентов на слоты' />
    <Tabs />
  </>
)

export default PaymentSection;
