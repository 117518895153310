import React, {useEffect} from 'react';
import {localisationMaster} from "Localisation/Localisation";
import {translate as loc, dict} from "Localisation";
import {splitNumberDigits} from "helpers/math";
import {phoneMask} from "helpers/phoneMask";
import modalStore from "Stores/modalStore";
//import ContactPersons from '../../../ContentBox/Company/Teachers/DetailsStudent/ContactPersons';
import st from "./PersonalInfo.module.scss";
import {ReactComponent as SettingIcon} from "../Employee/img/settings-4-line.svg"
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";


export default function PersonalInfo({info: {profile, ...info}, openModalClientInfo, translate}) {
  const pip = profile;
  const user = info;
  //const contactPersonArr = info.contact_persons;
  const personals = [
    {name: 'ID', value: splitNumberDigits(profile.inn)},
    {name: 'E-mail', value: <a href={`mailto:${user.email}`}>{user.email}</a>},
    {
      name: translate.phone,
      value: profile.phone ?
        <a href={`tel:${profile.phone}`}>{phoneMask(profile.phone)}</a> :
        <span className={st.edit} onClick={openModalClientInfo}>{loc(dict.main_page.add_phone)}</span>},
    {
      name: translate.address,
      value: profile.address
    },
    {
      name: 'WhatsApp',
      value: profile.whatsapp ?
        <a href={'https://wa.me/' + profile.whatsapp}>{profile.whatsapp}</a> :
        <span className={st.edit} onClick={openModalClientInfo}>{loc(dict.main_page.specify_whatsapp)}</span>},
    {
      name: 'Telegram',
      value: profile.telegram ?
        <a href={`https://telegram.me/${profile.telegram}`}>{`@${profile.telegram}`}</a>
        :
        <span className={st.edit} onClick={openModalClientInfo}>{loc(dict.main_page.specify_nickname_telegram)}</span>
    },
  ]

  // Проверка, что ДР в ближайшие дни
  //const checkDoB = dob => Math.abs(new Date() - new Date(dob).setFullYear(new Date().getUTCFullYear())) / 86400000 <= 4;

  // Настройки модалки Загрузки аватара
  /*const modal_settings = {
      name: 'companies',
      link: 'accounts/account_update/' + props.ID,
      media: true,
      is_put: 'put',
      button: localisationMaster('modal_title_1', 'company/profiles/account'),
      title: localisationMaster('modal_title_2', 'company/profiles/account'),
      type: 'AddAvatar',
      data: 'json',
      pl_textarea_second: localisationMaster('select-placeholder-5', 'company/companies', 'add_company'),
      name_text: 'name',
      name_text_second: 'widget_link',
      name_textarea: 'comment',
      name_media: 'avatar'
  }*/

  // Открыть окно Контактного лица
  /*const openModalContactPerson = index => {
    const is_put = index !== undefined
    const ms = {
      type: 'client_contact_person',
      link: 'accounts/contact_persons',
      is_put,
      data: 'json',
      title: localisationMaster('text_19', 'modal_templates'),
      button: localisationMaster('button-two', 'company/profiles/account'),
      width: '620px',
      func: is_put ? clientStore.editContactPerson : clientStore.addContactPerson,
      valid: {
        last_name: 'required',
        first_name: 'required',
      },
    }
    if (is_put) ms.link += '/' + contactPersonArr[index].id
    const md = {
      user: props.info.id,
      ...contactPersonArr[index]
    }

    modalStore.activity(ms, md)
  }*/

  // Удаление контактного лица
  /*const deleteContactPerson = idx => {
      const arr = [...contactPersonArr]
      const [del] = arr.splice(idx, 1)

      swal({
          dangerMode: true,
          icon: 'warning',
          title: localisationMaster('deleteContactPerson', 'modal_templates'),
          text:  localisationMaster('areYouSure', 'company/profiles/account') + '"' + (del.who_is_it ||  localisationMaster('text_15', 'modal_templates') + (idx + 1)) + '"?',
          buttons: [localisationMaster('button-one', 'modal-profile'), localisationMaster('text_13', 'lessons/course')],
      }).then(yes => {
          if (yes) {
              const apiConf = {
                  method: 'delete',
                  link: 'accounts/contact_persons/' + del.id,
              }
              superAxiosRequest(apiConf).then(() => {
                  setActiveTab(null)
                  clientStore.deleteContactPerson(idx)
              })
          }
      })
  }*/

  useEffect(() => {
    if (!pip.inn) {
      modalStore.activity({
        link: 'accounts/clients/' + profile.id,
        button: localisationMaster('add', 'company/profiles/account'),
        title: localisationMaster('needINN', 'modal_templates'),
        is_put: 'put',
        type: 'SelectorAndInput',
        active_inputs: ['multi_inn'],
        data: 'json',
        name_inn: 'inn',
        width: '350px',
      })
    }
  }, [pip.inn, profile.id])

  return (
    <div className={st.personalInfo}>
      <BlockHeader title={translate.personal_info}>
        <div className={st.button} onClick={openModalClientInfo}><SettingIcon/></div>
      </BlockHeader>

      <div className={st.body}>
        <ul>{personals.map((item,index) =>
          <li key={index}>
            <span className={st.paramName}>{item.name}:</span>
            <span className={st.paramValue}>{item.value}</span>
          </li>)}
        </ul>

        {profile.comment &&
          <div>
            <div className={st.paramName}>{translate.comment}:</div>
            <div dangerouslySetInnerHTML={{__html: profile.comment}}/>
          </div>}
      </div>

      {/*<div className="student-card__tabs">
                <div className="student-card__tabs-inner">
                    <span className={'student-card__tab ' + (activeTab === null ? 'student-card__tab--active' : '')}
                        onClick={() => setActiveTab(null)}>
                        {localisationMaster('additionalContacts', 'company/profiles/account')}
                    </span>

                    {contactPersonArr.map((item, idx) =>
                        <span key={idx}
                            onClick={() => setActiveTab(idx)}
                            className={'student-card__tab ' + (idx === activeTab ? 'student-card__tab--active' : '')}>
                            <span>{item.who_is_it || 'Контакт ' + (idx + 1)}</span>
                            <span className="student-card__del-btn"
                                onClick={() => deleteContactPerson(idx)}>&times;</span>
                        </span>
                    )}

                    <span className="student-card__tab"
                        onClick={() => openModalContactPerson()}>{` ${localisationMaster('addContactPErson', 'company/profiles/account')}`}</span>

                </div>
            </div>*/}

      {/*{activeTab !== null &&
        <ContactPersons
          person={contactPersonArr[activeTab]}
          formatPhone={formatPhone}
          formatInn={formatInn}
          formatDoB={formatDoB}
          openModalContactPerson={() => openModalContactPerson(activeTab)}
        />}*/}

    </div>
  )
}
