import React from 'react';
import PropTypes from 'prop-types';
import { itemsType } from './TeacherHistoryCard';
import { dateFormat } from 'helpers/date';
import TeacherHistorySlot from './TeacherHistorySlot';
import styles from './TeacherHistoryCard.module.scss';

const getSlotData = ({count, time}) => {
  const hours = Math.trunc(time / 60).toString();
  const minutes = (time % 60).toString().padStart(2, '0');

  return (
    <p className={styles.data_time}>
      <span>{count}</span>
      <span>{time} мин.</span>
      <span>{hours}:{minutes} ч.</span>
    </p>);
};

function TeacherHistoryBody({data}) {
  const {all, availableForClients, deleted, booked, missed, worked, slotTypes} = data.result;
  return (
    <>
      <ul className={styles.data_list} style={{gridTemplateRows: `repeat(${3 + Math.round(slotTypes.length / 2)}, auto)`}}>
        <li><span>Всего слотов: </span>{getSlotData(all)}</li>
        <li><span>Доступные для клиентов: </span>{getSlotData(availableForClients)}</li>
        <li><span>Удаленные: </span>{getSlotData(deleted)}</li>
        {slotTypes.map((item, index) => (
          <li key={index.toString()}>
            <span>{item[0]}: </span>{getSlotData(item[1])}
          </li>
        ))}
        <li><span>Занятые клиентами: </span>{getSlotData(booked)}</li>
        <li><span>Пропущенные клиентом: </span>{getSlotData(missed)}</li>
        <li><span>Отработанные: </span>{getSlotData(worked)}</li>
      </ul>

      <ul className={styles.slots_daylist}>
        {data.items.map(({ date, employee_booked_slots }) => (
          <li key={date}>
            <p className={styles.slot_date}>
              {dateFormat(date, 'dd MMMM')}:
            </p>
                <ul className={styles.slots_list}>
              {employee_booked_slots.map((slot) => (
                <TeacherHistorySlot  employee_id={data.employee_id} key={slot.id} data={slot} />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
}

const resultItemProps = PropTypes.shape({
  time: PropTypes.number,
  count: PropTypes.number,
});

TeacherHistoryBody.propTypes = {
  data: PropTypes.shape({
    items: itemsType?.isRequired,
    result: PropTypes.shape({
      all: resultItemProps.isRequired,
      availableForClients: resultItemProps.isRequired,
      deleted: resultItemProps.isRequired,
      booked: resultItemProps.isRequired,
      missed: resultItemProps.isRequired,
      worked: resultItemProps.isRequired,
      slotTypes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, resultItemProps]))).isRequired,
    }).isRequired,
  }),
};

export default TeacherHistoryBody;
