import React from 'react';
import InputsWrapper from '../../../../../UI/InputsWrapper/InputsWrapper';
import AmoCrmToggle from './AmoCrmToggle';
import { adaptAmoToggles } from '../../adapters';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

const amoToggles = adaptAmoToggles();

function AmoCrmToggles() {
  const { amoToggles: data } = slotsSettingsStore;


  return (
    <InputsWrapper>
      {
        amoToggles.map(({name, label}) => (
          <AmoCrmToggle
            key={name}
            name={name}
            label={label}
            data={data[name]}
          />
        ))
      }
    </InputsWrapper>
  );
}

export default AmoCrmToggles;
