import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Paper from 'components/UI/Paper/Paper';
import { getWeekDayName } from 'components/UI/Calendar/utils';
import { classNames as cn } from 'helpers';
import Context from 'components/UI/Calendar/context';
import styles from './ContextMenu.module.scss';

export const Action = {
  EDIT_DAYS: 'edit-days',
  EDIT_WEEKDAYS: 'edit-weekdays',
  REMOVE_DAYS: 'remove-days',
  REMOVE_WEEKDAYS: 'remove-weekdays',
};

function ContextMenu({ day, onClose }) {
  const itemRef = useRef();
  const [classes, setClasses] = useState(styles.wrapper);
  const { isSelectedDaysAvailable, selectedDates, onClick } = useContext(Context);

  useEffect(() => {
    const { right } = itemRef.current.getBoundingClientRect();
    const windowWidth = document.documentElement.clientWidth;
    const isRight = (right >= windowWidth);

    setClasses(cn({
      [styles.wrapper]: true,
      [styles.show]: true,
      [styles.wrapper__right]: isRight,
    }));
  }, []);

  return (
    <Paper
      tag="ul"
      classes={classes}
      onMouseDown={(evt) => evt.stopPropagation()}
      onMouseUp={(evt) => evt.stopPropagation()}
    >

      <li className={styles.item} ref={itemRef} onClick={() => onClick(Action.EDIT_DAYS, onClose)}>
        <p>Редактировать <strong>{selectedDates.size > 1 ? 'даты' : 'дату'}</strong></p>
      </li>

      {selectedDates.size === 1 &&
      <li className={styles.item} onClick={() => onClick(Action.EDIT_WEEKDAYS, onClose)}>
        <p>Редактировать <strong>все <span className={styles.dayname}>{getWeekDayName(day)}</span></strong></p>
      </li>
      }

      {isSelectedDaysAvailable &&
      <li className={`${styles.item} ${styles.item__warning}`} onClick={() => onClick(Action.REMOVE_DAYS, onClose)}>
        <p>Отключить <strong>{selectedDates.size > 1 ? 'даты' : 'дату'}</strong></p>
      </li>}

      {isSelectedDaysAvailable && selectedDates.size === 1 &&
      <li className={`${styles.item} ${styles.item__warning}`} onClick={() => onClick(Action.REMOVE_WEEKDAYS, onClose)}>
        <p>Отключить <strong>все <span className={styles.dayname}>{getWeekDayName(day)}</span></strong></p>
      </li>
      }

    </Paper>
  );
}

ContextMenu.propTypes = {
  day: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default observer(ContextMenu);

