import React from 'react';
import styles from "./ActionBar.module.scss"



const ActionBar = ({classes, element_key, list}) => {

    return (
        <div className={`${classes} ${styles.box}`}>
            {list.map((item, key) => <img key={key} onClick={(e) => {
                e.stopPropagation()
                item.action(element_key, e)
            }} className={styles.icon} src={item.icon} alt="" />)}
        </div>
    )
}

export default ActionBar