import React from 'react';
import PropTypes from 'prop-types';
import TeacherCard from './TeacherCard';
import styles from './TeacherCards.module.scss';

function TeacherCards({teachers, selectedTeacher, onSetData}) {

  const handleCardClick = (id) => {
    onSetData(id);
  };

  return (
    <ul className={styles.list}>
      {teachers.map(({ id, name, avatar }, index) => (
        <TeacherCard
          key={index.toString()}
          id={id}
          name={name}
          avatar={avatar}
          onClick={handleCardClick}
          selected={selectedTeacher}
        />
      ))}
    </ul>
  );
}

TeacherCards.propTypes = {
  teachers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  })).isRequired,
  selectedTeacher: PropTypes.number,
  onSetData: PropTypes.func.isRequired,
};

export default TeacherCards;
