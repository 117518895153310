import React, {useEffect} from 'react'
import img from "./img/img.svg";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import style from './ActionPicture.module.scss'


export default function ActionPicture(props) {
    const actPic = [
        {id: 0, pic: img, desc: props.translate.under_each_other, name: 'sequence' },
        {id: 1, pic: img, desc: props.translate.miniatures, name: 'mini'},
        {id: 2, pic: img, desc: props.translate.scroll_slider, name: 'slider'}
    ]

    useEffect(() => {
        if (!props.state)
            props.objectChangeHandler('mini', 'show_like')
    }, [])

    return (
        <FormField>
            <FormLabel isRequired>{props.translate.display_images}:</FormLabel>
            <div className={style.galeryContainer}>
                {actPic.map((item, index) =>
                    <div
                        key={index}
                        className={`${style.block} ${item.name === props.state ? style.selected : ''}`}
                        onClick={() => props.objectChangeHandler(item.name, 'show_like')}>
                        <img src={item.pic} alt="alt"/>
                        <p>{item.desc}</p>
                    </div>
                )}
            </div>
        </FormField>
    )
}
