import React, { useState, useEffect } from 'react'
import { superAxiosRequest } from 'axiosApi'
import { observer } from 'mobx-react'
import { stringToBoolean } from 'helpers'
import modalStore from 'Stores/modalStore'
import RadioGroup from 'components/ContentBox/Global/RadioGroup/RadioGroup'
import MainSelector from '../MainSelector'
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom'
import './studytest.scss';
import shield from 'components/ContentBox/img/shield.svg'
import { localisationMaster } from 'Localisation/Localisation';


const AddTest = () =>  {
    const { getModalValues, data, changeValues,  check, changeValuesArray } = modalStore;
    const [levels, setLevels] = useState([false, []])
    const rgList = [
        {id: 1, value: false, name: 'request', label: localisationMaster('text_29', 'study/study-test'), checked: true },
        {id: 2, value: true, name: 'request', label: localisationMaster('text_30', 'study/study-test'), checked: false }

    ]

    useEffect(() => {
        let apiConf = {
            method: 'get',
            link: 'study/levels'
        }
        superAxiosRequest(apiConf).then(res => {
            setLevels([true, res.data.result])
        })
        if(data.is_put){
            let arr = {
                company: getModalValues.company.id,
                description: getModalValues.description,
                name: getModalValues.name || '',
                level: getModalValues.sub_lvl.lvl.id,
                sub_lvl: getModalValues.sub_lvl.id,
                under_request: getModalValues.under_request,
            }
            changeValuesArray(arr)
        }
    }, [])

    const checkedRadio = bool => !bool ? 1 : 2

    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label super-modal__form-label--required">{localisationMaster('text_21', 'modal_templates')}:</p>
                    <SelectCustom
                        placeholder={localisationMaster('text_8', 'study/study-test')}
                        apiLink="db/companies"
                        sortKey="name"
                        error={check('company')}
                        valueID={getModalValues.company}
                        onChange={o => changeValues(+o.id, 'company')}
                        isSearchable
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label super-modal__form-label--required">{localisationMaster('table-row-title-1', 'company/age')}:</p>
                    <input value={getModalValues.name}
                           className={check('name') ? 'super-modal__input--error' : undefined}
                           placeholder={localisationMaster('table-row-title-1', 'company/age')} onChange={(e) => changeValues(e.target.value, 'name')} />
                </div>
            </div>

            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label super-modal__form-label--required">{localisationMaster('name_4', 'tests/widget-clients')}:</p>
                    <SelectCustom
                        error={check('level')}
                        placeholder={localisationMaster('name_4', 'tests/widget-clients')}
                        options={levels[1] || []}
                        sortKey="name"
                        valueID={getModalValues.level}
                        onChange={o => changeValues(o.id, 'level')}
                        isSearchable
                        />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label super-modal__form-label--required">{localisationMaster('table-row-title-5', 'tests/tests')}:</p>
                    <SelectCustom
                        placeholder={localisationMaster('table-row-title-5', 'tests/tests')}
                        error={check('sub_lvl')}
                        options={levels[1].find(x => x.id === getModalValues.level)?.sub_lvl || []}
                        sortKey="name"
                        isDisabled={!getModalValues.level}
                        valueID={getModalValues.sub_lvl}
                        onChange={o => changeValues(o.id, 'sub_lvl')}
                        isSearchable
                        />
                </div>
            </div>

            <div className="super-modal__form-field">
                <p className="super-modal__form-label">{localisationMaster('table-row-title-2', 'company/companies')}:</p>
                <textarea onChange={(e) => changeValues(e.target.value, 'description')} value={getModalValues.description} />
            </div>

            <div className="super-modal__form-field">
                <p className="super-modal__form-label">{localisationMaster('text_28', 'study/study-test')}:</p>
                <MainSelector image={shield}>
                    <RadioGroup list={rgList}
                                checked={checkedRadio(getModalValues.under_request)}
                                backFunction={(e) => changeValues(stringToBoolean(e.target.value), 'under_request')}/>
                </MainSelector>
            </div>
        </div>
    )
}
export default observer(AddTest);
