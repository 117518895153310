import React from 'react';
import {getPeriodStr} from "../functions";
import Stores from "Stores";
import TimeLeft from "../TimeLeft";
import st from "./StudyTestInfoStudent.module.scss"

export default function StudyTestInfoStudent({values}) {
    return (
        <div className={st.wrap}>
            <div className={st.period}>
                <strong>{getPeriodStr(values)}<br/>(GMT {Stores.utz})</strong>
            </div>
            <div className={st.time}>
                {values.seconds_until_finish > 0 && values.seconds_until_start < 0
                    ?
                    <>
                        <span className={st.str}>Окончание через:</span>
                        <TimeLeft delta={values.seconds_until_finish}/>
                    </>
                    :
                    (values.seconds_until_finish < 0 && values.seconds_until_start < 0)
                        ?
                        <span className={st.testEnd}>Время теста истекло!</span>
                        :
                        <>
                            <span className={st.str}>До начала теста:</span>
                            <TimeLeft delta={values.seconds_until_start}/>
                        </>}
            </div>
        </div>
    );
}
