import {schoolDayStatuses as sds} from "Stores/groupStore";

export const dateConvert = date => {
    date = date.split('-');
    return date[2] + '.' + date[1] + '.' + date[0].slice(2)
}

export const getClassName = (status, debtor, holydays, student, disabled, creditor) => {
    let base = 'timetable-calendar__day'
    const aClass = [base]

    if (holydays && !status)
        aClass.push(base + "--holiday")

    if (status && creditor === 0)
        aClass.push(base + '--' + status[0])
    if (debtor > 0) {
        if (debtor === 2) {
            aClass.push(base + '--debet-full')
        } else {
            aClass.push(base + '--debet-half')
        }
    }

    if (creditor > 0) {
        if (creditor === 2) {
            aClass.push(base + '--creditor-full')
        } else {
            aClass.push(base + '--creditor-half')
        }
    }

    if (disabled)
        aClass.push(base + '--5')

    return aClass.join(' ')
}

export const sorter = (arr, rev) => {
    return arr.sort(function (a, b) {
        a = new Date(a.date)
        b = new Date(b.date)
        return !rev ? a - b : b - a;
    })
}

export const sorterRev = arr => {
    let answer = arr.sort(function (a, b) {
        a = new Date(a.date + ' 12:00:00')
        b = new Date(b.date + ' 12:00:00')
        return b - a;
    });
    let req_day = new Date(answer[0].date + ' 12:00:00')
    req_day = new Date(req_day.setDate(req_day.getDate() + 1))
    return req_day
}

export const checkHolyDays = (date, holidays) => holidays.some(x => x.date === date)

export const sumPerDay = (price, type) => {
    if (type) {
        switch (type[0]) {
            case 'discount':
                if (type[1].type === 2) {
                    price = price - (price / 100 * type[1].count)
                } else {
                    price -= type[1].count
                }
                break;
            case 'surcharge':
                if (type[1].type === 2) {
                    price = price + (price / 100 * type[1].count)
                } else {
                    price += type[1].count
                }
                break;

            default:
                break;
        }
    }
    return price
}

const checkIsPerconalStatus = (sd, sdn, s, date) => {
    if (!s || !sdn || !sd || !date) return;
    const id = s.id
    const inSdn = sdn.find(x => x.date === date && x.user === id)
    const inSd = sd.find(x => x.date === date)
    return !!(!inSd && inSdn)
}

export const createDays = (items, student, isStudent, holidays, schooldays, schooldays_notes) => {
    let sum_per_minute,
        new_arr = [],
        last = 0,
        balance = 0,
        debtor = 0,
        durMin = 0,
        allTime = 0,
        uid = 0,
        perPay = 0,
        credit = 0

    if (isStudent) {
        uid = student.id
        sum_per_minute = sumPerDay(
            student.default_price.price_per_minute,
            (student.surcharge ? ['surcharge', student.surcharge] : student.discount ? ['discount', student.discount] : false),
        )

        if (student.balance && student.balance >= 0)
            balance = student.balance
        if (student.balance && student.balance < 0) {
            balance = student.balance
            credit = -balance
        }
    }

    if (items) {
        const arr = sorter(items)
        for (let i = 0; i < arr.length; i++) {
            durMin = (new Date(arr[i].date + ' ' + arr[i].finish_at) - new Date(arr[i].date + ' ' + arr[i].start_at)) / 1000 / 60
            let day_sum = sum_per_minute * durMin
            let newStatus = checkSchooldaysStatuses(arr[i], isStudent, schooldays, schooldays_notes, uid)
            let hd = checkHolyDays(arr[i].date, holidays)

            //let backSum = schooldays_notes?.find(x => x.date === arr[i].date && x.user === uid)?.sum
            let backSum = newStatus[4]

            if (!newStatus && !arr[i].disabled && !hd && balance > 0) {
                if (balance >= 0.99 * day_sum) {
                    debtor = 2
                } else if ((balance - day_sum * 0.01) > 0) {
                    debtor = 1
                } else {
                    debtor = 0
                }
                balance -= day_sum
            } else {
                debtor = 0
            }

            // Нумерация уроков
            if ((hd && newStatus) || !hd) last += 1
            if (
                [sds.PAID_SKIP, sds.PART_PAID_SKIP, sds.NOT_PAID_SKIP, sds.CANCELED].includes(newStatus[0])
                && !checkIsPerconalStatus(schooldays, schooldays_notes, student, arr[i].date)
            ) last -= 1

            new_arr.push(
                {
                    date: arr[i].date,
                    teachers: arr[i].teachers,
                    start_at: arr[i].start_at,
                    finish_at: arr[i].finish_at,
                    schedule: arr[i].schedule_id,

                    id: last,
                    ...(isStudent && {balance: balance + ' ' + day_sum}),
                    disabled: arr[i].disabled || false,
                    //transfers: false,
                    day_sum: +backSum > 0 ? +backSum / 100 : backSum,
                    debtor: debtor,
                    perPay,
                    status: newStatus,
                    creditor: 0,
                    //comment: arr[i].comment,
                    holydays: hd,
                    hours_type_minutes: arr[i].hours_type_minutes,
                    school_day: schooldays.find(x => x.date === arr[i].date),
                    school_day_note: schooldays_notes?.find(x => x.date === arr[i].date && x.user === uid)
                }
            )
            if (!arr[i].disabled && !hd && !newStatus) allTime += durMin
        }

        if (isStudent && credit > 0) {
            const arr = sorter(new_arr, true)
            let creditor
            for (let i = 0; i < arr.length; i++) {
                let {status, day_sum} = arr[i]
                day_sum = day_sum * 100
                creditor = 0

                if ((status[0] === 7 || status[0] === 6 || status[0] === 3 || status[0] === 4) && day_sum > 0 && credit > 0) {
                    if (credit >= day_sum) {
                        if(status[0] !== 3 && status[0] !== 4) {
                            creditor = 2
                        }
                        credit -= day_sum
                    } else if (credit > 0) {
                        if(status[0] !== 3 && status[0] !== 4)
                            creditor = 1
                        credit -= day_sum
                    } else if (credit > 0 && day_sum > 0) {
                        if(status[0] !== 3 && status[0] !== 4)
                            creditor = 1
                        credit -= day_sum
                    }
                }
                arr[i].creditor = creditor
            }
            new_arr = sorter(arr)
        }
    
        return [sorter(new_arr), isStudent && allTime]
    }
}

export const checkSchooldaysStatuses = (day, student, schooldays, school_day_notes, uid) => {
    let el

    if (student && school_day_notes) {
        el = school_day_notes.find(x => x.date === day.date && x.user === uid && x.schedule_id === day.schedule_id)
    }
    if (!student && schooldays) {
        el = schooldays.find(x => x.date === day.date && x.schedule_id === day.schedule_id)
    }
    if (el) {
        return [el.status, el.id, el.comment, el.share, el.sum, el.fine]
    } else {
        return false
    }
}

export const correctDate = dateString => dateString.replace(/-/g, '/');
