import React from 'react';
import Store from "Stores";
import {dateFormat} from "helpers/date";
import {NavLink} from "react-router-dom";
import {localisationMaster} from 'Localisation/Localisation';
import Stores from 'Stores';
import style from './GroupLog.module.scss'

export default function LogRow({row}) {
    const getOperation = op => {
        let rows = []

        const cud = () => Object.keys(op.old).length === 0 ? 1 : Object.keys(op.new).length === 0 ? -1 : 0

        const cleanSum = Store.cleanSumm

        const schedule = sch => {
            const rows = [];

            (sch.start_at && sch.finish_at) &&
            rows.push(`
            ${sch.start_at.slice(0, 5)} - ${sch.finish_at.slice(0, 5)} 
            ${sch.hours_per_day ? 
              ` (${+sch.hours_per_day} ${+sch.hour_type_id === 1 ? 
                localisationMaster('ac_h', 'groups/group') : 
                localisationMaster('astr_h', 'groups/group')
            })` : ''}`);

            (sch.date_from && sch.date_until) &&
            rows.push(`${dateFormat(new Date(sch.date_from), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)} - ${dateFormat(new Date(sch.date_until), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`)

            sch.hours_total &&
            rows.push(`${localisationMaster('text_1', 'groups/group', 'group_log')}: ${+sch.hours_total} ${+sch.hour_type_id === 1 ? localisationMaster('ac_h', 'groups/group',) : localisationMaster('astr_h', 'groups/group',)}`)

            Array.isArray(sch.teachers_name) &&
            rows.push(`${localisationMaster('text_2', 'groups/group', 'group_log')}: ${Stores.transliterate(`${sch.teachers_name.join(', ')}`)}`)

            sch.classroom_name &&
            rows.push(`${localisationMaster('text_3', 'groups/group', 'group_log')}: ${sch.classroom_name}`)

            return rows
        }

        const price = price => `${price.default_price} (${cleanSum(price.default_price_sum)}${localisationMaster('tg_for', 'groups/group')} ${price.default_price_hours_sum} ${price.default_price_type_id === 1 ? localisationMaster('ac_h', 'groups/group',) : localisationMaster('astr_h', 'groups/group',)})`  

        const student_price = price => {
            const rows = []

            rows.push(`${price.default_price_name} (${cleanSum(price.default_price_sum)}${localisationMaster('tg_for', 'groups/group')} ${price.default_price_hours_sum} ${price.default_price_type === 1 ? localisationMaster('ac_h', 'groups/group',) : localisationMaster('astr_h', 'groups/group',)})`)  
            rows.push(`${localisationMaster('text_4', 'groups/group', 'group_log')} ${dateFormat(new Date(price.date_from), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}${price.date_until && ` ${localisationMaster('text_18', 'groups/group', 'student')} ${dateFormat(new Date(price.date_until), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`}`) 

            return rows
        }

        const student_discount = dc => {
            const rows = []

            rows.push(`${dc.discount_name} (${dc.discount_type === 1 ? `${localisationMaster('absolute', 'groups/group', 'group_log')}, ${cleanSum(dc.discount_sum)}тг` : `${localisationMaster('interest', 'groups/group', 'group_log')}, ${dc.discount_sum}%`})`)
            rows.push(`${localisationMaster('text_4', 'groups/group', 'group_log')}: ${dateFormat(new Date(dc.date_from), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}${dc.date_until && ` ${localisationMaster('text_18', 'groups/group', 'student')} ${dateFormat(new Date(dc.date_until), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`}`) 

            return rows
        }

        const student_surcharge = sc => {
            const rows = []
            rows.push(`${sc.surcharge_name} (${sc.surcharge_type === 1 ? `${localisationMaster('absolute', 'groups/group', 'group_log')}, ${cleanSum(sc.surcharge_sum)}тг` : `${localisationMaster('interest', 'groups/group', 'group_log')}, ${sc.surcharge_sum}%`})`)
            rows.push(`${localisationMaster('text_17', 'groups/group', 'student')} Активна с: ${dateFormat(new Date(sc.date_from), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}${sc.date_until && ` ${localisationMaster('text_18', 'groups/group', 'student')} ${dateFormat(new Date(sc.date_until), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`}`) 

            return rows
        }

        try {
            switch (op.operation_type){
                // Расписания
                case 'schedule':
                    rows.push(`${cud() === 1 ? 
                      localisationMaster('add', 'groups/group', 'group_log') : (
                        cud() === -1 ? localisationMaster('removed', 'groups/group', 'group_log') : 
                          localisationMaster('changed', 'groups/group', 'group_log')
                      )} ${localisationMaster('text_5', 'groups/group', 'group_log')}`)
                    if (cud() === 1){
                        rows = rows.concat(schedule(op.new))
                    } else if (cud() === 0){
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(schedule(op.old))
                        rows.push(<span>&nbsp;</span>)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(schedule(op.new))
                    } else {
                        rows = rows.concat(schedule(op.old))
                    }
                    break
                case 'workshop':
                    rows.push(`${cud() === 1 ? 
                      localisationMaster('add', 'groups/group', 'group_log') : (
                        cud() === -1 ? localisationMaster('removed', 'groups/group', 'group_log') : 
                          localisationMaster('changed', 'groups/group', 'group_log')
                      )} ${localisationMaster('text_5', 'groups/group', 'group_log')}`)
                    if (cud() === 1){
                        rows = rows.concat(schedule(op.new))
                    } else if (cud() === 0){
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(schedule(op.old))
                        rows.push(<span>&nbsp;</span>)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(schedule(op.new))
                    } else {
                        rows = rows.concat(schedule(op.old))
                    }
                    break
                // Группа
                case 'name':
                case 'location':
                case 'company':
                case 'number':
                case 'status':
                case 'discipline':
                case 'type':
                case 'size':
                case 'doc_type':
                case 'doc_type_description':
                case 'course_level':
                case 'skill_level':
                case 'default_price':
                    const whats = {
                        name: localisationMaster('text_19', 'groups/group', 'group_log'),
                        location: localisationMaster('text_20', 'groups/group', 'group_log'),
                        company: localisationMaster('text_21', 'groups/group', 'group_log'),
                        number: localisationMaster('text_22', 'groups/group', 'group_log'),
                        status: localisationMaster('text_23', 'groups/group', 'group_log'),
                        discipline: localisationMaster('text_24', 'groups/group', 'group_log'),
                        type: localisationMaster('text_25', 'groups/group', 'group_log'),
                        size: localisationMaster('text_26', 'groups/group', 'group_log'),
                        doc_type: localisationMaster('text_27', 'groups/group', 'group_log'),
                        course_level: localisationMaster('text_28', 'groups/group', 'group_log'),
                        skill_level: localisationMaster('text_29', 'groups/group', 'group_log'),
                        comment: localisationMaster('text_30', 'groups/group', 'group_log'),
                        default_price: localisationMaster('text_31', 'groups/group', 'group_log'),
                    }

                    if (cud() === 1){
                        rows.push(`${localisationMaster('text_18', 'groups/group', 'group_log')}:`)
                        rows.push(`${whats[op.operation_type]}: ${op.operation_type === 'default_price' ? price(op.new) : op.new[op.operation_type]}`)
                    } else {
                        rows.push(`${localisationMaster('text_17', 'groups/group', 'group_log')}: ${whats[op.operation_type]}`)
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}: ${op.operation_type === 'default_price' ? price(op.old) : op.old[op.operation_type]}`)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}: ${op.operation_type === 'default_price' ? price(op.new) : op.new[op.operation_type]}`)
                    }
                    break;
                    // Комментарий к группе (м.б. с тегами)
                case 'comment':
                    const what = localisationMaster('text_30', 'groups/group', 'group_log')

                    if (cud() === 1){
                        rows.push(`${localisationMaster('text_18', 'groups/group', 'group_log')}:`)
                        rows.push(`${what}: ${op.new[op.operation_type]}`)
                    } else {
                        rows.push(`${localisationMaster('text_17', 'groups/group', 'group_log')}: ${what}`)
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}: ${op.old[op.operation_type]}`)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}: ${op.new[op.operation_type]}`)
                    }
                    break;
                // Студенты
                case 'new_client':
                    rows.push(`${localisationMaster('add_2', 'groups/group', 'group_log')}  ${Stores.transliterate(`${op.new.client_name }`)}`)  
                    rows.push(`${localisationMaster('text_13', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.new.start_study_at), "d MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`)  
                    rows.push(`${localisationMaster('status', 'groups/group', 'group_log')}: «${op.new.student_status_name}»`) 
                    break
                case 'student_default_price':
                    rows.push(`${cud() === 1 ? localisationMaster('add_1', 'groups/group', 'group_log') : cud() === -1 ? localisationMaster('removed_1', 'groups/group', 'group_log') : localisationMaster('changed_1', 'groups/group', 'group_log')} ${localisationMaster('text_8', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.student_name || op.old.student_name}`)}`) 
                    if (cud() === 1){
                        rows = rows.concat(student_price(op.new))
                    } else if (cud() === 0){
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_price(op.old))
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_price(op.new))
                    } else {
                        rows = rows.concat(student_price(op.old))
                    }
                    break
                case 'student_discounts':
                    rows.push(`${cud() === 1 ? localisationMaster('add_1', 'groups/group', 'group_log') : cud() === -1 ? localisationMaster('removed_1', 'groups/group', 'group_log') : localisationMaster('changed_1', 'groups/group', 'group_log')} ${localisationMaster('text_9', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.student_name || op.old.student_name}`)}`) 
                    if (cud() === 1){
                        rows = rows.concat(student_discount(op.new))
                    } else if (cud() === 0) {
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_discount(op.old))
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_discount(op.new))
                    } else {
                        rows = rows.concat(student_discount(op.old))
                    }
                    break
                case 'student_surcharges':
                    rows.push(`${cud() === 1 ? localisationMaster('add_1', 'groups/group', 'group_log') : cud() === -1 ? localisationMaster('removed_1', 'groups/group', 'group_log') : localisationMaster('changed_1', 'groups/group', 'group_log')} ${localisationMaster('text_9', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.student_name || op.old.student_name}`)}`) 
                    if (cud() === 1){
                        rows = rows.concat(student_surcharge(op.new))
                    } else if (cud() === 0) {
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_surcharge(op.old))
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`);
                        rows = rows.concat(student_surcharge(op.old))  
                    } else {
                        rows = rows.concat(student_surcharge(op.old))
                    }
                    break
                case 'client_status':
                    rows.push(`${localisationMaster('text_11', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.client_name }`)}`)
                    rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}: «${op.old.student_status_name}»`)
                    rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}: «${op.new.student_status_name}»`)
                    break
                case 'client_study_dates':
                    rows.push(`${localisationMaster('text_12', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.client_name }`)}`)
                    rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}:`)
                    rows.push(`${localisationMaster('text_13', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.old.start_study_at), 'd MMM YYYY')} ${localisationMaster('yr', 'groups/group',)}`)  
                    op.old.finish_study_at &&
                    rows.push(`${localisationMaster('text_14', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.old.finish_study_at), 'd MMM YYYY')} ${localisationMaster('yr', 'groups/group',)}`) 
                    rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}:`)
                    rows.push(`${localisationMaster('text_13', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.new.start_study_at), 'd MMM YYYY')} ${localisationMaster('yr', 'groups/group',)}`) 
                    op.new.finish_study_at && 
                    rows.push(`${localisationMaster('text_14', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.new.finish_study_at), 'd MMM YYYY')} ${localisationMaster('yr', 'groups/group',)}`) 
                    break
                // Статусы преподавателей
                case 'teacher_status':
                    rows.push(`${cud() === 1 ? localisationMaster('set', 'groups/group', 'group_log') : cud() === -1 ? localisationMaster('removed_2', 'groups/group', 'group_log') : localisationMaster('changed_2', 'groups/group', 'group_log')} ${localisationMaster('text_15', 'groups/group', 'group_log')}: ${dateFormat(new Date(op.new.date), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`) 
                    if (cud() === 1) {
                        rows.push(`${op.new.status_name}`)
                    }
                    else {
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}: «${op.old.status_name}»`)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}: «${op.new.status_name}»`)
                    }
                    break;
                // Статусы студентов
                case 'student_status':
                    rows.push(`${cud() === 1 ? localisationMaster('set', 'groups/group', 'group_log') : cud() === -1 ? localisationMaster('removed_2', 'groups/group', 'group_log') : localisationMaster('changed_2', 'groups/group', 'group_log')} ${localisationMaster('text_16', 'groups/group', 'group_log')}: ${Stores.transliterate(`${op.new.student_name}`)}, ${dateFormat(new Date(op.new.date), "dd MMM YYYY")} ${localisationMaster('yr', 'groups/group',)}`) 
                    if (cud() === 1) {
                        rows.push(`${op.new.status_name}`)
                    } else {
                        rows.push(`${localisationMaster('text_6', 'groups/group', 'group_log')}: «${op.old.status_name}»`)
                        rows.push(`${localisationMaster('text_7', 'groups/group', 'group_log')}: «${op.new.status_name}»`)
                    }
                    break;
                default:
                    break
            }
        } catch (e) {
            rows.push(e.toString())
        }

        return rows
    }

    return (
        <div  className={style.row}>
            <div className={style.time}>
                <span className={style.clock}>
                    {row.updated_at?.split('T')[1].slice(0, 5)}
                </span>
                <span>
                    {dateFormat(new Date(row.updated_at?.split('T')[0]), `dd MMM YYYY ${localisationMaster('yr', 'groups/group',)}`)}
                </span>
            </div>
            <div className={style.name}>
                <NavLink to={`/account/${row.updated_by}/`}>{Stores.transliterate(row.updated_by_name)}</NavLink>
            </div>
            <div className={style.operation}>
                {getOperation(row).map((r, idx) => row.operation_type === 'comment' ?
                  <div className={style.operationName} key={idx} dangerouslySetInnerHTML={{__html: r}}/>
                    :
                  <div className={idx === 0 ? style.operationName : ''} key={idx}>{r}</div>)
                }
            </div>
        </div>
    );
};