import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from "Router/routes";
import st from "./WrapperAuth.module.scss"

export default function WrapperAuth() {
  return (
    <div className={st.page}>
      <Switch>
        {routes.public.map(route => <Route {...route} key={route.path}/>)}
      </Switch>
    </div>
  )
}
