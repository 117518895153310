import React from 'react';
import PropTypes from 'prop-types';
import VariantAdd from '../../../StudyTest/TestQuestion/components/VariantAdd';
import st from './GroupWords.module.scss'
import GroupColumn from "./GroupColumn";

function GroupWords(props) {
  const add = () => {
    props.onChange([...props.values, {value: '', description: ''}], 'data')
  }

  const changeHandler = (val, index) => {
    props.onChange(props.values.map((v, i) => i === index ? val : v), 'data')
  }

  const remove = idx => {
    props.onChange(props.values.filter((v, i) => i !== idx), 'data')
  }

  return (
    <div className={st.videoLinks}>{props.values.map((item, index) =>
      <GroupColumn translate={props.translate} key={index} index={index} value={item} onChange={val => changeHandler(val, index)} onDelete={() => remove(index)} error={props.error} values={props.values}/>)}
      {props.values.length < 4 &&
      <VariantAdd onClick={add}/>}
    </div>
  )
}

GroupWords.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
}

export default GroupWords
