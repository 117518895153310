import React from 'react';
import st from './SentencesForm.module.scss'
import FormInput from "../../../../../../../UI/AsideModal/Form/FormInput";
import asideModalStore from "../../../../../../../../Stores/asideModalStore";
import FormSelectCustom from "../../../../../../../UI/AsideModal/Form/FormSelectCustom";
import SelectCustom from "../../../../../Select/SelectCustom";
import VariantDel from "../../../StudyTest/TestQuestion/components/VariantDel";


const SentencesForm = props => {
    const options = [{id: 1, name: 'true'}, {id: 2, name: 'false'}, {id: 3, name: 'unknown'}]

    const {changeValue, modalValues, check} = asideModalStore

    return (
        <div className={st.sentencesForm}>
            <div className={st.container}>
            <div className={st.card}>
                <FormInput>
                    <input type="text"
                           placeholder={props.translate.choose_sesentences}
                           value={props.value.sentence}
                           className={props.error ? st.error : undefined}
                           onChange={e => props.onChange({
                               ...props.value,
                               sentence: e.target.value
                           })}/>
                </FormInput>
                <FormSelectCustom>
                    <SelectCustom placeholder={props.translate.select_form}
                                  options={options}
                                  valueID={options.findIndex(el => el.name === modalValues.data[props.index].form) + 1}
                                  onChange={(value) => {
                                      changeValue(
                                          modalValues.data.map((obj, i) => (i === props.index ? { ...obj, form: value.name} : obj)),
                                          'data'
                                      )
                                  }}
                                  error={check('data')}
                    />
                </FormSelectCustom>
            </div>
                {props.index > 0  &&
                    <div className={st.delete}>
                        <VariantDel onClick={() => props.removeSentence(props.index)}/>
                    </div>
                }
            </div>
        </div>
    )
};

export default SentencesForm;
