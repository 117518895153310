import React, {useEffect, useState} from "react"
//import {localisationMaster} from "Localisation/Localisation";
import {superAxiosRequest} from "axiosApi";
import {timeConverter} from "../components/functions";
import ContentHeader from "../../Global/ContentHeader";
import Idlist from "components/ContentBox/Global/Idlist/idlist";
import SlotsExplain from "../components/SlotsExplain/SlotsExplain";
import TeacherCard from "./TeacherCard";
import Loader from "../../Global/Loader/Loader";
//import SelectRequest from "../../Global/Select/SelectRequest";
import SelectCustom from "../../Global/Select/SelectCustom";
import DataPickerSplit from "../../Global/DatePicker/DataPickerSplit";

import "./ExtendedTeachersSchedule.scss"


export default function ExtendedTeachersSchedule() {
    // Сегодня
    const dateToday = new Date()
    // Месяц назад
    const dateMonthAgo = new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, dateToday.getDate())

    const [locationId, setLocationId] = useState(0)
    const [dateFrom, setDateFrom] = useState(timeConverter(dateMonthAgo, 'today'))
    const [dateTo, setDateTo] = useState(timeConverter(dateToday, 'today'))
    const [locationTeachers, setLocationTeachers] = useState([])
    const [selectedTeachers, setSelectedTeachers] = useState([])
    const [teachers, setTeachers] = useState([false, []])

    const returnValue = key => {
        switch (key) {
            case 'date_from':
                return dateFrom;
            case 'date_until':
                return dateTo;

            default:
                return ''
        }
    }

    const onChangeHandler = (key, val) => {
        switch (key) {
            case 'date_from':
                setDateFrom(val)
                break;
            case 'date_until':
                setDateTo(val)
                break;
        }
    }

    useEffect(() => {
        // Учителя
        const apiConf = {
            method: 'get',
            link: 'accounts/employers_flat_list',
            params: {
                user_group: 'teacher&user_group=senior_teacher',
            }
        }
        superAxiosRequest(apiConf, 'data').then(response => {
            let teachers = response.data.result.map(item => ({id: item.user_id, name: item.full_name}))
            setLocationTeachers(teachers)
        })
    }, [])

    useEffect(() => {
        if (locationId !== 0) {
            // Учителя
            const apiConf = {
                method: 'get',
                link: 'accounts/employers_flat_list',
                params: {
                    location: locationId
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                let teachers = response.data.result.map(item => ({id: item.user_id, name: item.full_name}))
                //setLocationTeachers(teachers)
                setSelectedTeachers(teachers)
            })
        }
    }, [locationId])

    const showTeachers = () => {
        setTeachers([true, []])
        const apiConf = {
            method: 'get',
            link: 'time_slots/admin/teachers_stats',
            params: {
                date_from: dateFrom,
                date_to: dateTo,
                teachers: '[' + selectedTeachers.map(x => x.id).join(',') + ']',
            }
        }
        superAxiosRequest(apiConf).then(response => {
            setTeachers([true, response.data.sort((a, b) => a.teacher_name > b.teacher_name ? 1 : a.teacher_name < b.teacher_name ? -1 : 0)])
        })
    }

    return (
        <>
            <ContentHeader title="Расписание преподавателей"/>
            <div className="onlineSchedulePage extendedTeachersSchedule">

                <div className="extendedTeachersSchedule__filter">

                    <div className="extendedTeachersSchedule__locationPeriod">

                        {/*<SelectRequest
                            link={'db/locations'}
                            extendedParams={{is_active: true}}
                            text={'Выберите локацию'}
                            valueId={locationId}
                            myChangeHandler={({target}) => {
                                setLocationId(+target.value)
                            }}/>*/}
                        <SelectCustom
                            placeholder="Выберите локацию"
                            apiLink="db/locations"
                            apiConfParams={{is_active: true}}
                            sortKey="name"
                            valueID={locationId}
                            onChange={loc => setLocationId(loc.id)}
                        />

                        <div className="extendedTeachersSchedule__notice">
                            <b>Внимание:</b> после выбора локации, автоматически выбираются учителя привязанные к этой
                            локации
                        </div>

                        <DataPickerSplit returnValue={returnValue} objectChangeHandler={(val, key) => {
                            onChangeHandler(key, val)
                        }}/>

                    </div>

                    <Idlist items={locationTeachers} teacher={true} selected={selectedTeachers}
                            getIds={setSelectedTeachers} sortKey={'name'}/>

                    <button className="extendedTeachersSchedule__showButton" disabled={selectedTeachers.length < 1}
                            onClick={showTeachers}>Показать учителей
                    </button>

                </div>

                <div className="extendedTeachersSchedule__count">Учителей: {teachers[1].length}</div>

                {teachers[0] &&
                <div className="onlineSchedulePage__mt20">
                    {teachers[1].length > 0 ?
                        teachers[1].map(item => <TeacherCard key={item.teacher_id} item={item}/>)
                        :
                        <Loader/>
                    }
                </div>
                }

                <div className="onlineSchedulePage__mt20">
                    <SlotsExplain/>
                </div>

            </div>
        </>
    )
}