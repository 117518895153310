import React, {useEffect, useState} from 'react';
import st from './OrderSentences.module.scss';
import { ReactComponent as DownIcon } from 'components/UI/icons/arrow-down-s-line.svg';
import { classNames as cn } from 'helpers';

export default function OrderSentences(props) {
  const [data, setData] = useState(props.data);

  const moveUp = (index) => {
    if (index > 0) {
      const updatedData = [...data];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index - 1];
      updatedData[index - 1] = temp;
      setData(updatedData);
    } else if (index === 0) {
      const updatedData = [...data];
      const temp = updatedData[0];
      updatedData[0] = updatedData[data.length - 1];
      updatedData[data.length - 1] = temp;
      setData(updatedData);
    }
  };

  const moveDown = (index) => {
    if (index < data.length - 1) {
      const updatedData = [...data];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index + 1];
      updatedData[index + 1] = temp;
      setData(updatedData);
    } else if (index === data.length - 1) {
      const updatedData = [data[data.length - 1], ...data.slice(0, data.length - 1)];
      setData(updatedData);
    }
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    props.handleAnswers(data, true);
  }, [data]);

  const answers = props.studentAnswer ? props.studentAnswer.answer : data;

  return (
    <div className={cn({
      [st.sentencesBlock]: true,
      [st.right]: props.studentAnswer && props.studentAnswer?.result,
      [st.wrong]: props.studentAnswer && !props.studentAnswer?.result
    })}
    >
      {answers.map((sentence, index) => (
        <div
            key={index}
            className={cn({
              [st.sentence]: true,
              [st.right]: props.studentAnswer && props.studentAnswer?.result_in_detail[index].result,
              [st.wrong]: props.studentAnswer && !props.studentAnswer?.result_in_detail[index].result
            })}
        >
          <div className={st.arrows}>
            <div className={cn({
              [st.arrow]: true,
              [st.arrowRotate]: true,
            })}>
              <DownIcon onClick={() => moveUp(index)} />
            </div>
            <div className={cn({
              [st.arrow]: true,
            })}>
              <DownIcon onClick={() => moveDown(index)} />
            </div>
          </div>
          <div>
            {sentence}
          </div>
        </div>
      ))}
    </div>
  );
}
