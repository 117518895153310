import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter2} from 'components/ContentBox/Online-schedule/components/functions';
import {dateFormat} from "helpers/date";
import {addZero} from "helpers/math";
import Stores from "Stores";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import HourCounter from 'components/ContentBox/Global/HourCounter/HourCounter';
import DateTime from 'components/ContentBox/Global/DateTime/DateTime';
import DayOfWeek from 'components/ContentBox/Global/DayOfWeek/DayOfWeek';
import ColorSelector from 'components/ContentBox/Global/ColorSelector/ColorSelector';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import './TimeTableForm.scss';

const lunch_break_list = [0, 5, 10, 15, 20, 30, 45, 60, 90];

const TimeTableForm = () => {
    const {data: ms, modalValue, modalValues, changeValuesArray} = modalStore;
    const {holidays, location, g_id, prolong: is_prolong} = ms.payload;
    const locked = !!is_prolong; // продление

    const getInitFinishAt = () => {
        const start = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")} ${location.start_at}`);
        start.setMinutes(start.getMinutes() + 45);
        return `${addZero(start.getHours())}:${addZero(start.getMinutes())}`;
    }

    const [catalog, setCatalog] = useState([false, {}, ''])
    const [loading, setLoading] = useState(true)
    const [teachers, setTeachers] = useState(modalValue.teachers.length > 0 ? modalValue.teachers : [null])

    const [inputsValues, setInputsValues] = useState({
        location: location.id,
        group: g_id,
        date_from: modalValue.date_from || dateFormat(new Date(), "YYYY-mm-dd"),
        ...(modalValue.date_until && {date_until: modalValue.date_until}),
        hour_type: modalValue.hour_type || 1,
        hours_total: modalValue.hours_total || 3,
        hours_per_day: modalValue.hours_per_day || 1,
        start_at: modalValue.start_at || location.start_at,
        finish_at: modalValue.finish_at || getInitFinishAt(),
        weekdays: modalValues.weekdays || [1, 3, 5],
        lunch_break: modalValue.lunch_break,
        teachers: modalValues.teachers,
        ...(modalValue.classroom && {classroom: modalValue.classroom}),
        ...(is_prolong && {prolong: modalValue.is_prolong}),
    })

    const checkHolyDays = date => holidays.some(x => x.date === timeConverter2(date));

    const objectChangeHandler = (value, key_name) => {
        calculator(value, key_name);
    }

    const calculator = (value, key_name) => {
        const array = {...inputsValues};
        let day_index, start_at, end_at, count, last_date, next_date, next_days_date, result, new_time;
        switch (key_name) {
            case 'hour_type':
                if (catalog[0] && array.hours_per_day > 0) {
                    let houre_type_f = catalog[1].hour_types.items.filter(x => x['id'] === +value)
                    let minutes = houre_type_f[0].minutes
                    let delta = (array.hours_per_day * minutes)
                    let CurrentTime = new Date(new Date(array.date_from + ' ' + array.start_at));
                    CurrentTime.setMinutes(CurrentTime.getMinutes() + delta);
                    new_time = addZero(CurrentTime.getHours()) + ":" + addZero(CurrentTime.getMinutes());
                    array.hour_type = value;
                    array.finish_at = new_time;
                }

                break;
            case 'date_from':
                if (array.hours_per_day > 0) {
                    count = Math.ceil(array.hours_total / array.hours_per_day)
                    start_at = new Date(value);
                    if (start_at) {
                        while (count > 0) {
                            day_index = start_at.getDay() || 7;
                            if (array.weekdays.includes(day_index) && !checkHolyDays(start_at)) {
                                count--
                            }
                            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                        }
                        array.date_from = value
                        array.date_until = timeConverter2(start_at)
                    }
                }
                break;
            case 'date_until':
                if ((new Date(value)) < (new Date(array.date_from))) {
                    count = Math.ceil(array.hours_total / array.hours_per_day)
                    start_at = new Date(value);
                    if (start_at) {
                        while (count > 0) {
                            next_date = start_at.setDate(start_at.getDate());
                            next_days_date = new Date(next_date);
                            day_index = next_days_date.getDay();
                            if (day_index === 0) { day_index = 7 }
                            if (array.weekdays.includes(day_index) && !checkHolyDays(next_days_date)) {
                                count = count - 1
                                last_date = next_days_date
                            }
                            start_at = new Date(start_at.setDate(start_at.getDate() - 1));
                        }
                        array.date_from = timeConverter2(start_at)
                        array.date_until = value
                    }
                } else {
                    start_at = new Date(array.date_from);
                    end_at = new Date(value);
                    count = 0;
                    while (start_at <= end_at) {
                        next_date = start_at.setDate(start_at.getDate());
                        next_days_date = new Date(next_date);
                        day_index = next_days_date.getDay();
                        if (day_index === 0) { day_index = 7 }
                        if (array.weekdays.includes(day_index) && !checkHolyDays(next_days_date)) {
                            count += 1
                        }
                        start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                    }
                    result = array.hours_per_day * count;
                    array.date_until = value;
                    array.hours_total = Math.ceil(result)
                }
                break;
            case 'hours_total':
                start_at = new Date(array.date_from);
                count = Math.ceil(value / array.hours_per_day)
                if (start_at) {
                    while (count > 0) {
                        next_date = start_at.setDate(start_at.getDate());
                        next_days_date = new Date(next_date);
                        day_index = next_days_date.getDay();
                        if (day_index === 0) { day_index = 7 }
                        if (array.weekdays.includes(day_index) && !checkHolyDays(next_days_date)) {
                            count = count - 1
                            last_date = next_days_date
                        }
                        start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                    }
                    array.date_until = timeConverter2(last_date)
                    array.hours_total = value
                }
                break;
            case 'weekdays':
                start_at = new Date(array.date_from);
                count = Math.ceil(array.hours_total / array.hours_per_day)
                if (start_at) {
                    while (count > 0) {
                        next_date = start_at.setDate(start_at.getDate());
                        next_days_date = new Date(next_date);
                        day_index = next_days_date.getDay();
                        if (day_index === 0) { day_index = 7 }
                        if (value.includes(day_index) && !checkHolyDays(next_days_date)) {
                            count = count - 1
                            last_date = next_days_date
                        }
                        start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                    }
                    array.date_until = timeConverter2(last_date)
                    array.weekdays = value
                }
                break;
            case 'hours_per_day':
                if (catalog[0] && array.hours_per_day > 0) {
                    const minutes = catalog[1].hour_types.items.find(x => x.id === +array.hour_type).minutes
                    const delta = value * minutes + (array.lunch_break || 0)
                    start_at = new Date(`${array.date_from} ${array.start_at}`);
                    start_at.setMinutes(start_at.getMinutes() + delta);
                    end_at = dateFormat(start_at, "hh:ii");
                    array.hours_per_day = value;
                    array.finish_at = end_at;
                    //////////////////////////////
                    start_at = new Date(array.date_from);
                    count = Math.ceil(array.hours_total / value)
                    if (start_at) {
                        while (count > 0) {
                            next_days_date = new Date(start_at);
                            day_index = next_days_date.getDay() || 7;
                            if (array.weekdays.includes(day_index) && !checkHolyDays(next_days_date)) {
                                count = count - 1
                                last_date = next_days_date
                            }
                            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                        }
                        array.date_until = dateFormat(last_date, "YYYY-mm-dd")
                    }
                }
                break;
            case 'lunch_break':
                let CurrentTime = new Date(array.date_from + ' ' + array.start_at);
                if (catalog[0] && array.hours_per_day > 0) {
                    const minutes = catalog[1].hour_types.items.find(x => x.id === +array.hour_type).minutes
                    const delta = array.hours_per_day * minutes + value
                    CurrentTime.setMinutes(CurrentTime.getMinutes() + delta);
                    new_time = addZero(CurrentTime.getHours()) + ":" + addZero(CurrentTime.getMinutes());
                }
                array.lunch_break = value;
                array.finish_at = new_time;
                break;
            case 'start_at':
                if (catalog[0] && array.hours_per_day > 0 && value !== undefined && catalog[1].hour_types) {
                    start_at = new Date(array.date_from + ' ' + value);
                    const minutes = catalog[1].hour_types.items.find(x => x.id === +array.hour_type).minutes
                    const delta = array.hours_per_day * minutes + (array.lunch_break || 0)
                    start_at.setMinutes(start_at.getMinutes() + delta);
                    //end_at = addZero(start_at.getHours()) + ":" + addZero(start_at.getMinutes());
                    end_at = dateFormat(start_at, "hh:ii");
                    array.finish_at = end_at;
                }
                array.start_at = value;
                break;
            case 'finish_at':
                if (catalog[0] && array.hours_per_day > 0) {
                    let CurrentTime = new Date(array.date_from + ' ' + value);
                    let StartTime = new Date(array.date_from + ' ' + array.start_at);
                    if (CurrentTime > StartTime) {
                        let delta = CurrentTime.getTime() - StartTime.getTime()
                        let hour_type = catalog[1].hour_types.items.find(x => x.id === +array.hour_type)
                        let minutes = hour_type.minutes
                        delta = (delta / 1000 / 60) / minutes
                        start_at = new Date(array.date_from);
                        count = Math.ceil(array.hours_total / delta.toFixed(2))
                        if (start_at) {
                            while (count > 0) {
                                next_date = start_at.setDate(start_at.getDate());
                                next_days_date = new Date(next_date);
                                day_index = next_days_date.getDay();
                                if (day_index === 0) { day_index = 7 }
                                if (array.weekdays.includes(day_index) && !checkHolyDays(next_days_date)) {
                                    count = count - 1
                                    last_date = next_days_date
                                }
                                start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                            }
                            array.date_until = timeConverter2(last_date)
                            //array.hours_per_day = +delta.toFixed(2)
                            array.hours_per_day = Math.round(delta / .5) * .5
                        }
                    }
                    array.finish_at = value;
                }
                break;
            case 'init':
                if (array.hours_per_day > 0) {
                    count = Math.ceil(array.hours_total / array.hours_per_day)
                    start_at = new Date(value);
                    if (start_at) {
                        while (count > 0) {
                            day_index = start_at.getDay() || 7;

                            if (array.weekdays.includes(day_index) && !checkHolyDays(start_at)) {
                                count--
                            }
                            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
                        }
                        array.date_from = value
                        array.date_until = timeConverter2(start_at)
                    }
                }
                break
            default:
                array[key_name] = value
                break;
        }

        setInputsValues({ ...array });
    }

    const returnValue = key_name => {
        if (inputsValues !== undefined) {
            return inputsValues[key_name]
        } else {
            return ''
        }
    }

    const changeTeacherHandler = (teacher, idx) => {
        let arr = [...modalValue.teachers]
        if (arr?.[idx])
            arr[idx] = teacher.id
        else
            arr.push(teacher.id)

        objectChangeHandler(arr, 'teachers')
    }

    const deleteTeacherHandler = idx => {
        const func = (x, i) => i !== idx
        setTeachers(teachers.filter(func))
        objectChangeHandler(modalValue.teachers.filter(func), 'teachers')
    }

    useEffect(() => {
        if (!ms.is_put || is_prolong)
            calculator(inputsValues.date_from, 'date_from')
    }, [])

    useEffect(() => {
        let mount = true
        if (
          inputsValues.date_from
          && inputsValues.date_until
          && inputsValues.start_at
          && inputsValues.finish_at
          && Array.isArray(inputsValues.weekdays) && inputsValues.weekdays.length > 0
        ) {
            const apiConf = {
                method: 'get',
                link: 'schedule/schedule_catalog/' + g_id,
                params: {
                    date_from: inputsValues.date_from,
                    date_until: inputsValues.date_until,
                    start_at: inputsValues.start_at,
                    finish_at: inputsValues.finish_at,
                    weekdays: `[${inputsValues.weekdays}]`,
                    ...(ms.payload.schedule_id && {schedule_id: ms.payload.schedule_id}),
                    ...(ms.payload.teachers_ids && {teachers: `[${ms.payload.teachers_ids}]`}),
                    ...(ms.payload.classroom_id && {classroom: ms.payload.classroom_id}),
                }
            }

            if (catalog[2] !== JSON.stringify(apiConf.params)) {
                setLoading(true)
                superAxiosRequest(apiConf).then(response => {
                    if (mount) {
                        setCatalog([true, response.data, JSON.stringify(apiConf.params)])
                        setLoading(false)
                    }
                })
            }
        }
        return () => {
            mount = false
        }
    }, [inputsValues])

    useEffect(() => {
        changeValuesArray(inputsValues)
    }, [inputsValues])

    return (
        <div className="timeTableForm">
            <div className="timeTableForm__row">
                <div>
                    <input disabled value={location.name} />
                </div>
                <ColorSelector value={returnValue('color')} objectChangeHandler={objectChangeHandler} />
            </div>

            <div className="timeTableForm__row">
                <div className="timeTableForm__col">
                    <SelectCustom
                        placeholder={localisationMaster('text_37', 'modal_templates')}
                        options={catalog[1].hour_types?.items || []}
                        valueID={modalValue.hour_type}
                        onChange={o => objectChangeHandler(o.id, 'hour_type')}
                        isLoading={loading}
                    />
                </div>

                <div className="timeTableForm__col">
                    <HourCounter
                        note={localisationMaster('text_38', 'modal_templates')}
                        name="hours_total"
                        start_position={3}
                        objectChangeHandler={objectChangeHandler}
                        returnValue={returnValue} />
                </div>

                <div className="timeTableForm__col">
                    <DataPickerSplit
                        returnValue={returnValue}
                        disable_from={locked}
                        objectChangeHandler={objectChangeHandler} />
                </div>
            </div>

            <div className="timeTableForm__row">
                <DayOfWeek bl={returnValue('weekdays')} objectChangeHandler={objectChangeHandler} disabled={locked}/>
            </div>

            <div className="timeTableForm__row">
                <div className="timeTableForm__col">
                    <SelectCustom
                        placeholder={localisationMaster('nonstop', 'groups/group', 'group_schedule')}
                        options={lunch_break_list.map(i => ({ value: i, label: i }))}
                        getOptionLabel={opt => `${opt.label} ${localisationMaster('mins', 'groups/group')}`}
                        valueID={inputsValues.lunch_break || null}
                        onChange={o => calculator(o.value, 'lunch_break')}
                        isDisabled={locked}
                    />
                </div>

                <div className="timeTableForm__col">
                    <HourCounter
                        note={localisationMaster('text_48', 'modal_templates')}
                        start_position={1}
                        name="hours_per_day"
                        returnValue={returnValue}
                        half
                        disabled={locked}
                        objectChangeHandler={objectChangeHandler}
                        readOnly
                    />
                </div>

                <div className="timeTableForm__col">
                    <DateTime
                        placeholder='9:00'
                        errorCheker={() => {}}
                        start_at={inputsValues.start_at}
                        finish_at={inputsValues.finish_at}
                        disabled={locked}
                        objectChangeHandler={objectChangeHandler}
                    />
                </div>
            </div>

            <div className="timeTableForm__row">
                <div className="timeTableForm__teachers">{teachers.map((val, idx) =>
                    <div className="timeTableForm__teacher" key={idx}>
                        <div>
                            <SelectCustom
                                placeholder={localisationMaster('text_44', 'modal_templates')}
                                options={catalog[1].teachers?.items.map(x => ({
                                    ...x,
                                    full_name: Stores.transliterate(x.full_name)
                                })) || []}
                                optionLabelKey="full_name"
                                sortKey="full_name"
                                valueID={modalValue.teachers.length > idx ? modalValue.teachers[idx] : null}
                                onChange={o => changeTeacherHandler(o, idx)}
                                isLoading={loading}
                                isSearchable
                                isDisabled={locked}
                            />
                        </div>
                        <div className="timeTableForm__teacherAdd" onClick={() => setTeachers([...teachers, null])}/>
                        <div className="timeTableForm__teacherDel"
                             onClick={() => teachers.length > 1 && deleteTeacherHandler(idx)}/>
                    </div>)}
                </div>

                <div className="timeTableForm__col">
                    <SelectCustom
                        placeholder={localisationMaster('text_45', 'modal_templates')}
                        options={catalog[1].classrooms?.items || []}
                        sortKey="name"
                        onChange={o => objectChangeHandler(o.id, 'classroom')}
                        valueID={inputsValues.classroom}
                        isLoading={loading}
                        isDisabled={locked}
                    />
                </div>
            </div>

            <div className="timeTableForm__note">
                {localisationMaster('academic_hours', 'groups/group', 'group_schedule')}:
                <strong> {catalog[0] ?
                  (modalValue.hours_total * catalog[1].hour_types.items.find(x => x.id === modalValue.hour_type).minutes / 45).toFixed(2) :
                  'рассчитывается...'}</strong>
            </div>

        </div>
    )
}

export default observer(TimeTableForm)
