import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {dateFormat} from "helpers/date";
import {localisationMaster} from "Localisation/Localisation";
import asideModalStore from "Stores/asideModalStore";
import {EditIconButton, SuperButton} from "components/UI/SuperButton/SuperButton";
import EbookHomeworkStudent from "./EbookHomeworkStudent";
import ModalHomeWork from "components/ContentBox/Global/GlobalModalSingle/templates/groups/ModalHomeWork";
import st from "./EbookHomework.module.scss";

const EbookHomework = ({groupID, homework: {teacher, students, ...homework}, fetchHomeworks}) => {
  const [open, setOpen] = useState(false)

  const refSlider = useRef(null)
  const refSlide = useRef(null)

  const editHomework = () => {
    asideModalStore.activity({
      isResponsive: true,
      title: localisationMaster('homework_lesson_plan', 'groups/group'),
      modalBodyComponent: () => <ModalHomeWork/>,
      button: localisationMaster('text_54', 'groups/all-groups'),
      valid: {
        description: 'required',
      },
      is_put: true,
      link: `groups/interim_homework/${homework.id}`,
      onSubmitSuccess: fetchHomeworks,
    }, {
      date: homework.date,
      description: homework.comment,
      group: groupID,
      homework_hash: homework.hash,
      //teacher: teacher.profile_id,
    })
  }

  useEffect(() => {
    const $slide = refSlide.current
    const resizeObserver = new ResizeObserver(entries => {
      setTimeout(() => {
        refSlider.current.style.height = open ? entries[0].contentRect.height + 'px' : 0
      }, 0)
    })
    resizeObserver.observe($slide)

    return () => {
      resizeObserver.unobserve($slide)
    }
  }, [open])

  return (
    <div className={`${st.ebookHomework} ${open ? st.ebookHomeworkOpened : ''}`}>
      <div className={st.homeworkHeader} onClick={() => setOpen(!open)}>
        <div className={st.teacherDate} onClick={e => e.stopPropagation()}>
          <Link to={`/account/${teacher.user_id}`} target="_blank">{teacher.full_name}</Link>
          <time>{dateFormat(homework.date, "dd MMM YYYY")}</time>
        </div>
        <div className={st.counts}>
          {homework.hask && <>
            <span>Passed / Pending</span>
            <b>{students.filter(x => x.mark !== null).length} / {students.filter(x => x.mark === null).length}</b>
          </>}
        </div>
        <div className={st.buttons} onClick={e => e.stopPropagation()}>
          {homework.hash &&
            <SuperButton variant="redirect" type="linkColored" path={`/ebooks/homework/${homework.hash}`}>
              View
            </SuperButton>}
          <EditIconButton onClick={editHomework}/>
        </div>
        <div className={st.comment} dangerouslySetInnerHTML={{__html: homework.comment}}/>
      </div>
      <div ref={refSlider} className={st.slider}>
        <div ref={refSlide}>
          <div className={st.students}>{students.map(student =>
            <EbookHomeworkStudent key={student.user_id} student={student} homework={homework}/>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EbookHomework;
