import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {superAxiosRequest} from 'axiosApi';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import StudyTestInfoStudent from "./components/StudyTestInfoStudent";
import NoResults from './NoResults';
import TestStudentCard from "./components/TestStudentCard";
import Loader from "components/ContentBox/Global/Loader/Loader";
import './StudyTestMain.scss'

export default function StudyTestMainStudent(props) {
    let apiConf;
    const [testInfo, setTestInfo] = useState([])
    const [initTestsList, setInitTestsList] = useState([false, []])
    const [filtredList, setFiltredList] = useState([])

    useEffect(() => {
        apiConf = {
            method: 'get',
            link: 'study/purpose_test/' + props.match.params['id']
        }
        superAxiosRequest(apiConf).then(res => setTestInfo(res.data))

        apiConf = {
            method: 'get',
            link: 'study/student_init_test',
            params: {
                //is_finish: false,
                purpose_test: props.match.params['id'],
                limit: 999,
            }
        }
        superAxiosRequest(apiConf).then(res => setInitTestsList([true, res.data.result]))
    }, [])

    useEffect(() => {
        if (!initTestsList[0]) return;
        let studentList = []
        let tryList = []
        initTestsList[1].map(el => !studentList.includes(el.student.id) && studentList.push(el.student.id))
        studentList.map(el => {
            let a = initTestsList[1].filter(x => x.student.id === el)
            if (a.length) tryList.push({...a[0], list: a})
        })
        setFiltredList([...tryList])
    }, [initTestsList, testInfo])

    return (
        !(testInfo.length !== 0 && initTestsList[0]) 
        ?  <Loader />
        : <>
            <ContentHeader 
                title={testInfo.name}
                breadСrumbs={[
                    { title: 'Ваши тесты', route: '/c/study/my-tests' }
                ]}
            />
            <div className="studyTestMain">
                <StudyTestInfoStudent values={testInfo}/>

                {(testInfo.try_count > initTestsList[1].length && testInfo.seconds_until_finish > 0 && testInfo.seconds_until_start < 0) &&
                <div className="studyTestMain__again">
                    <Link to={`/init-placement-test/${props.match.params.id}`} target="_blank"
                          className="studyTestMain__againButton">Пройти тест</Link>
                    <div className="studyTestMain__againCount">{`Попытки: ${initTestsList[1].length} из ${testInfo.try_count}`}</div>
                </div>}

                <div className="studyTestMain__studentCard">{filtredList.length > 0 ?
                    filtredList.map((item, index) =>
                        <TestStudentCard key={index} item={{...item, tryCount: testInfo.try_count}}/>
                    )
                    :
                    <NoResults
                        text={testInfo.seconds_until_start > 0 ? 'Возможность пройти тест откроется в назначенное время!' : 'Вы еще не проходили тест. Результатов нет'}/>
                }
                </div>
            </div>
        </>
    )
}
