import React from 'react';
import {classNames as cn} from 'helpers';
import styles from './CalendarDay.module.scss';
import bcake from "components/ContentBox/img/birthday-cake.svg";

const CalendarDay = ({
    date,
    notActual,
    isSelected,
    isToday,
    selectDay,
    employees
}) => {

    return (
        <div
            onClick={() => !notActual && selectDay()}
            className={cn({
                [styles.wrapper]: true,
                [styles.past]: notActual,
                [styles.today]: isToday,
                [styles.selected]: isSelected,
            })}>
            <div className={styles.header}>
                <span>{`${date.getDate()} ${isToday && !notActual ? ', cегодня' : ''}`}</span>
            </div>
            {!notActual &&
                <div className={styles.body}>
                    {employees &&
                        <><p className={styles.text_bold}>{employees.length}</p> <img className={styles.icon} src={bcake} alt="" /></>
                    }
                </div>
            }
        </div>
    );
}

export default CalendarDay;
