import React from "react";
import {observer} from "mobx-react";
import DatePicker from "react-datepicker";
import {dateFormat} from "helpers/date";
import {localisationMaster} from "Localisation/Localisation";
import asideModalStore from "Stores/asideModalStore";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import TextareaMarkup from "components/UI/TextareaMarkup/TextareaMarkup";

function ModalHomeWork() {
  const {modalValues, changeValue, check} = asideModalStore

  return (
    <FormFieldset>
      <div>
        <FormField>
          <FormLabel>{localisationMaster('lesson_date', 'groups/group')}</FormLabel>
          <div className="react-datepicker-custom">
            <FormInput>
              <DatePicker selected={new Date(modalValues.date || Date.now())}
                          onChange={date => changeValue(dateFormat(date, 'YYYY-mm-dd'), 'date')}
                          dateFormat="dd.MM.yyyy" />
            </FormInput>
          </div>
        </FormField>
      </div>
      <div>
        <TextareaMarkup stateName="description"
                        value={modalValues.description}
                        onChange={changeValue}
                        error={check('description')}
                        placeholder={localisationMaster('table-row-title-2', 'pays/all-pays')}/>
      </div>
    </FormFieldset>
  )
}

export default observer(ModalHomeWork)
