import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import {superAxiosRequest} from 'axiosApi'
import {formatSalaryOptionLabel} from 'components/ContentBox/Global/Select/SelectCustomFunctions'
import {translate as loc, dict} from "Localisation";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom'
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import Loader from "components/UI/Loader/Loader";

const MultiSalary = () => {
  const {modalSettings, modalValues, changeValues} = asideModalStore;
  const [salaries, setSalaries] = useState(null)

  const handler = (salary, program_type) => {
    changeValues(
      modalValues.find(el => el.program_type === program_type) ?
        modalValues.map(sal => sal.program_type === program_type ? {...sal, salary} : sal) :
        [...modalValues, {
          salary,
          program_type,
          employee: modalSettings.pid
        }]
    )
  }

  useEffect(() => {
    superAxiosRequest({
      link: 'ledger/salary_v2',
      method: 'get',
      params: {
        limit: 999
      }
    }).then(res => {
      setSalaries(res.data)
    })
  }, [])

  return (
    salaries?._meta ?
      <FormFieldset>
        {salaries._meta?.program_types.map((el) =>
          <FormField key={el.id}>
            <FormLabel>
              {el.name}:
            </FormLabel>
            <FormSelectCustom>
              <SelectCustom
                placeholder={loc(dict.main_page.text_17)}
                options={salaries.result}
                formatOptionLabel={formatSalaryOptionLabel}
                getOptionLabel={opt => opt.name}
                valueID={modalValues.find(sal => sal.program_type === el.id)?.salary || null}
                onChange={o => handler(o.id, el.id)}
                isSearchable
              />
            </FormSelectCustom>
          </FormField>
        )}
      </FormFieldset> : <Loader/>
  )
}

export default observer(MultiSalary);
