import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import InputToggle from '../../../../../UI/InputToggle/InputToggle';
import Flex from '../../../../../UI/Flex/Flex';
import InfoIcon from '../../../../../UI/InfoIcon/InfoIcon';
import SettingsIcon from '../../../../../UI/SettingsIcon/SettingsIcon';
import ContactFormModal from './ContactFormModal';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';
import styles from './ContactFormToggle.module.scss';

const Types = {
  text: 'Текст',
  email: 'E-mail',
  date: 'Дата',
  phone: 'Телефон',
};

const nonEditableToggles = ['first_name', 'last_name', 'birthday', 'email', 'phone'];

function ContactFormToggle({name, fixedLabel, label, mandatory, active, type}) {
  const { toggleRegistrationFormCheckBox } = slotsSettingsStore;
  const [isModalShown, setIsModalShown] = useState(false);
  const isNonEditable = nonEditableToggles.includes(name);

  const handleModalClose = () => setIsModalShown(false);

  const handleToggleChange = ({ target }) => {
    if (isNonEditable) {
      return;
    }
    toggleRegistrationFormCheckBox(target.name)
  };

  return (
    <li className={styles.form_fields__item}>
      <InputToggle
        label={fixedLabel}
        selected={active}
        name={name}
        onChange={handleToggleChange}
        disabled={isNonEditable}
      />
      <Flex withGap align='start' style={{minWidth: 60}}>
        <InfoIcon
          title={fixedLabel}
          subtitle={label}
          type={Types[type]}
          isRequired={mandatory}
        />
        <SettingsIcon onClick={() => setIsModalShown(!isModalShown)} />
      </Flex>
      {isModalShown &&
        <ContactFormModal
          name={name}
          label={label}
          fixedLabel={fixedLabel}
          type={type}
          isRequired={mandatory}
          isNonEditable={isNonEditable}
          onClose={handleModalClose}
          isDisabled={!name.includes('add_field')}
        />
      }
    </li>
  );
}

ContactFormToggle.propTypes = {
  name: PropTypes.string.isRequired,
  fixedLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  mandatory: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
};


export default observer(ContactFormToggle);
