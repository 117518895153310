import React from "react";
import {observer} from "mobx-react";
import {permissions} from "settings/permissions";
import {localisationMaster} from "Localisation/Localisation";
import {checkPermList} from "helpers";
import {dateFormat} from "helpers/date";
import groupStore from "Stores/groupStore";
import modalStore from "Stores/modalStore";
import CreateTimeTable from "components/ContentBox/Global/GlobalModalSingle/templates/groups/CreateTimeTable/CreateTimeTable";
import WorkshopSchedule from "./WorkshopSchedule";
import Calendar from "./Calendar";
import st from "./WorkshopTimeTable.module.scss"
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";

const WorkshopTimeTable = props => {

  const {wsData: {workshops, teachers}, ebooks, updateWSData} = groupStore

  const addTimeTable = () => {
    const ms = {
      title: localisationMaster('add_schedule', 'groups/group', 'group_schedule'),
      modalBodyComponent: () => <CreateTimeTable/>,
      button: localisationMaster('add_schedule', 'groups/group', 'group_schedule'),
      link: 'schedule/workshops',
      payload: {
        holidays: props.holidays,
        location: props.location,
        group_id: +props.groupID,
      },
      valid: {
        classroom_id: 'required',
      },
      width: '800px',
      func: updateWSData
    }

    const startAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${props.location.start_at}`)

    const md = {
      hour_type: 1,
      hours_total: 3,
      date_from: dateFormat(startAt, "YYYY-mm-dd"),
      start_at: dateFormat(startAt, "hh:ii"),
      finish_at: dateFormat(+startAt + 45 * 60 * 1000, "hh:ii"),
      group_id: +props.groupID,
      teachers: [],
      weekdays: [1, 3, 5],
      hours_per_day: 1,
    }

    modalStore.activity(ms, md)
  }

  const getSchedules = () => {
    if (!workshops) return
    const teachers = []
    workshops.forEach(d => {
      const day = {...d}
      const teacher = teachers.find(t => t.teachers.join() === day.teachers.join())
      if (teacher){
        teacher.days.push(day)
      } else {
        teachers.push({
          teachers: [...day.teachers],
          days: [day],
        })
      }
    })

    teachers.forEach(teacher => {
      let schedules = []
      teacher.days.forEach(day => {
        const schedule = schedules.find(sch => sch.start_at === day.start_at && sch.finish_at === day.finish_at)
        if (schedule){
          schedule.days.push(day)
        } else {
          schedules.push({
            start_at: day.start_at,
            finish_at: day.finish_at,
            classroom_id: day.classroom,
            classroom_name: day.classroom_name,
            days: [day]
          })
        }
      })

      schedules = schedules.map(sch => ({
        ...sch,
        weekdays: Array.from(new Set(sch.days.map(d => (new Date(d.date)).getDay() || 7))).sort((a, b) => a>b ? 1 : -1),
        dateFrom: new Date(sch.days[0].date),
        dateUntil: new Date(sch.days[sch.days.length-1].date),
      }))

      teacher.schedules = schedules
    })

    return teachers
  }

  return (
    <div className="timetable-box">
      <div className="timetable-box__header">
        <h3>{props.translate.group_schedules}</h3>
        {checkPermList(permissions.add_schedule) &&
          <div onClick={addTimeTable}>
            <button className={st.addBtn}>
              <PlusIcon/>
              <span className={st.headerBtnName}>{props.translate.add_new_schedule}</span>
            </button>
          </div>
        }
      </div>
      {
        <div className={st.timetables}>{getSchedules()?.map(sch =>
          <WorkshopSchedule key={sch.teachers.join()} schedule={sch} teachers={teachers}/>)}
        </div>
      }
      <Calendar ebooks={ebooks}/>
    </div>
  )
}

export default observer(WorkshopTimeTable)
