import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {observer} from 'mobx-react';
import Stores from "Stores";
import LessonTeacherStore from "./lessonTeacherStore";
import Loader from "components/UI/Loader/Loader";
import Exercise from "../Common/Exercise/Exercise";
import {LessonContext} from "../BookChapter/LessonContext";
import BookPaper from '../Common/BookPaper/BookPaper';
import PaperHeader from '../Common/PaperHeader/PaperHeader';
import TeacherToolbar from "components/Modules/Ebook/LessonTeacher/TeacherToolbar/TeacherToolbar";
import useLocalization from "hooks/useLocalization";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import st from "./LessonTeacher.module.scss";
import DrawingBoard from "./DrawingBoard/DrawingBoard";
import {exerciseTypes} from "Stores/ebookChapterStore";

const LessonTeacher = () => {
  const {uuid} = useParams()

  const context = {mode: 'teacher'}
  const [showBoard, setShowBoard] = useState(false)

  const {
    chapter,
    section,
    fetchLesson,
    fetchSection,
    activeSection,
    errorStatus,
    lessonData,
  } = LessonTeacherStore

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "provide_exercise": "Provide An Exercise",
      "show_answers": "Show Answers",
      "focus": "Focus On Exercise",
      "add_to_homework": "Add to Homework",
      "select_student": "Select Student",
      "lesson": "Lesson",
      "homework": "Homework",
      "exit": "Exit",
      "save": "Save",
      "add_exercise": "Add an exercise to homework",
      "no": "No",
      "yes": "Yes",
      "focused_exercise" :"Students focused on the exercise",
      "are_you_sure": "Are you sure",

    }
  })

  const refs = []
  const wsInitLessonRef  = useRef()

  const sendMessageInitLesson = body => {
    wsInitLessonRef.current.send(JSON.stringify(body))
  }
  useEffect(() => LessonTeacherStore.clearLesson(), []);

  useEffect(() => {
    const link = 'ebooks/init_lesson'
    fetchLesson(link, uuid)

    const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/${link}/${uuid}/?token=${localStorage.getItem('access')}`;
    wsInitLessonRef .current = new WebSocket(wsUrl)
    wsInitLessonRef .current.onmessage = ({data}) => {
      const msg = JSON.parse(data)
      if (msg.type === "answered_exercise") {
        LessonTeacherStore.setAssignExercises(msg.message)
      }
    }
  }, [uuid, fetchLesson])

  useEffect(() => {
    if (chapter?.sections.length > 0) {
      fetchSection(chapter.sections[activeSection - 1].id)
    }
  }, [activeSection, lessonData, fetchSection])


  useEffect(() => {
    return () => {
      // Закрываем соединения при размонтировании компонента
      wsInitLessonRef.current.close()
    }
  }, [])


  let num = 0;
  const calcNumOfExercise = ex => ex.type_exercise === exerciseTypes.TITLE ? "" : `${activeSection}.${num += 1}`;

  return (
    chapter ?
      <>
        <TeacherToolbar translate={translate} showBoard={showBoard} setShowBoard={setShowBoard}/>
        <div className={st.page}>
          <div className={st.container}>   
            <BookPaper>
              <DrawingBoard uuid={uuid} mode={context.mode} showBoard={showBoard} setShowBoard={setShowBoard}/>
            <PaperHeader chapterName={`${translate.lesson}: ${chapter.name}`} section={section}/>
            <div className={st.exercisesSectionsBox}>
              {section?.exercises.length > 0 &&
                <LessonContext.Provider value={context}>
                  <>
                  <div className={st.questions}>{section.exercises.map((ex, index) =>
                    <div key={ex.id} ref={div => {refs.push(div)}}>
                      <Exercise number={calcNumOfExercise(ex)}
                                ex={ex}
                                sendMessage={sendMessageInitLesson}
                                isFirst={index === 0}
                                isLast={index === section.exercises.length - 1}
                                translate={translate}
                      />
                    </div>)}
                  </div>
                  </>
                </LessonContext.Provider>}
            </div>
            </BookPaper>
          </div>
        </div>
      </> :
      <div className={st.page}>
        <div className={st.container}>
          <BookPaper>{errorStatus === 404 ?
            <WithoutExercises text1="404" text2="Lesson not found"/> : <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(LessonTeacher);
