import React from 'react';
import PropTypes from 'prop-types';
import st from './HourCounterReb.module.scss';

const HourCounterReb = ({name, value, onChange, note, step, min, max}) => {
    const onChangeHandler = val => {
        if (min) val = Math.max(val, min)
        if (max) val = Math.min(val, max)
        if (!isNaN(val))
            onChange(val, name)
    }

    return (
        <div className={st.counter}>
            <div className={st.btn} onClick={() => onChangeHandler(value - step)}>-</div>

            <div className={st.input}>
                <input type="number" value={value} onChange={e => onChangeHandler(+e.target.value)} />
            </div>

            <div className={st.info}>{note}</div>

            <div className={st.btn} onClick={() => onChangeHandler(value + step)}>+</div>
        </div>
    )
}

HourCounterReb.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    note: PropTypes.string,
}

HourCounterReb.defaultProps = {
    step: 1
}

export default HourCounterReb;
