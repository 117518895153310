import React, { useEffect, useState, createContext } from 'react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Loader from 'components/UI/Loader/Loader';
import Client from './components/Client';
import Location from './components/Location';
import Employee from './components/Employee';
import Level from './components/Level';
import Payment from './components/Payment';
import Results from './components/Results';
import { useParams } from 'react-router-dom';
import { superAxiosRequest } from 'axiosApi';
import styles from './SlotDetails.module.scss';

export const Context = createContext({});

function SlotDetails() {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
    }

    superAxiosRequest({
      method: 'get',
      link: `online_schedule/admin/client/${id}`,
    })
      .then(({data}) => setData(data))
      .finally(() => setIsLoading(false));
  }, [id]);

  return (
    <Context.Provider value={data}>
      <ContentHeader title={data.client
        ? `Данные о записи: ${data.client.last_name} ${data.client.first_name}`
        : 'Данные о записи'}
      />
      {isLoading
        ? <Loader fullWidth />
        : <>
          <div className={styles.layout}>
            <Client id={id} />
            <Location />
            <Level />
            <Employee />
            <Payment />
          </div>
          <Results />
          </>
      }
    </Context.Provider>
  );
}

export default SlotDetails;
