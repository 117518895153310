import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import slotsTeacherSchedule, { ActionType, DataType } from 'Stores/slotsTeacherScheduleStore';
import Calendar from 'components/UI/Calendar/Calendar';
import { adaptDateToServer, sortSlotByStartTime } from 'components/Modules/Slots/utils';

function CalendarLayout() {
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { company, location, dates, selectedDates, resetData, getDataFromServer, setData } = slotsTeacherSchedule;

  const handleClick = (id) => {
    if (selectedDates.size === 0) {
      resetData(ActionType.resetSlots);
      return;
    }
    resetData(ActionType.resetSelectedSlot);
    const bookedSlots = dates[id].booked_slots.slice().sort(sortSlotByStartTime);
    setData(dates[id].available_slots, DataType.slots);
    setData(bookedSlots, DataType.bookedSlots)
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (!startDate) {
      return;
    }
    setIsLoading(true);
    const params = {
      company,
      location: location.value,
      date_from: adaptDateToServer(startDate),
      step: 1,
    };
    getDataFromServer({
      type: ActionType.getDates,
      params,
      onFinally: () => setIsLoading(false),
    });
  }, [location.value, startDate]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Calendar
      startDate={startDate}
      isLoading={isLoading}
      dates={dates}
      selectedDates={selectedDates}
      resetData={() => {resetData(ActionType.resetSlots)}}
      onStartDateChange={handleStartDateChange}
      onClick={handleClick}
      type='teacher-schedule'
    />
  );
}

export default observer(CalendarLayout);
