import React, {useState, useEffect} from 'react';
import './HourCounter.scss';

export default function HourCounter(props) {
    const [count, setCount] = useState(props.returnValue(props.name) ? props.returnValue(props.name) : props.start_position)

    const countHandler = (vector) => {
        if (vector === "+") {
            if (props.half) {
                if (count < props.returnValue('hours_total') && count < 8) {
                    setCount(+count + 0.5)
                }
                if (count > props.returnValue('hours_total')) {
                    setCount(+props.returnValue('hours_total'))
                }
            } else {
                setCount(+count + 1)
            }

        } else {
            if (+count > 0) {
                if (props.half) {
                    setCount(+count - 0.5)
                } else {
                    if (count > props.returnValue('hours_per_day')) {
                        setCount(+count - 1)
                    }
                }
            }
        }
    }

    useEffect(() => {
        if(count < 0.1 || count === ''){
            setCount(0.5)
        } else if(count > 0 && count[0] === 0 ){
            setCount(+count.slice(1))            
        } else {
         if(props.half){
            props.objectChangeHandler(+count, props.name)
         } else {
            props.objectChangeHandler(Math.ceil(+count), props.name)
         }
        }
    }, [count])

    useEffect(() => {
        if(props.returnValue(props.name)){
       setCount(+props.returnValue(props.name).toFixed(2))
        } else {
            setCount(+props.start_position)
        }
    }, [props.returnValue(props.name)])

    const handleChange = e => {
        let value = e.target.value
        if (value > props.returnValue('hours_total') && props.half) {
            value = +props.returnValue('hours_total')
        }
        setCount(value)
    }

    return (
        <div className="hour-counter">
            <div className="hour-counter-but unselectable" onClick={!props.disabled ? countHandler : undefined}>-</div>
            <div className="hour-counter__input">
                <input type="number"
                       value={props.returnValue(props.name)}
                       disabled={props.disabled}
                       onChange={handleChange}
                       readOnly={props.readOnly || false}
                />
            </div>
            <div className="hour-counter-info">
                {props.note}
            </div>
            <div className="hour-counter-but unselectable"
                 onClick={!props.disabled ? () => countHandler('+') : undefined}>
                +
            </div>
        </div>
    )
}
