import React from 'react';
import st from "./ExerciseHeader.module.scss";

const ExerciseHeader = ({section, ...props}) => {
  const sectionColor = section?.color || (section?.exercises ? section?.exercises[0]?.section?.color : "");

  const headerStyles = sectionColor
    ? {
      borderColor: sectionColor?.body_color,
      backgroundColor: sectionColor?.background_color,
    }
    : {};

  const textColor = sectionColor ? { color: sectionColor?.body_color } : {};
    const conditionExerciseWithoutSpans = props.data.ex.condition_exercise.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
  return (
    <div className={st.header} style={headerStyles}>
      <p style={textColor}>{props.data.number}</p>
      <h3 dangerouslySetInnerHTML={{__html: conditionExerciseWithoutSpans}} />
    </div>
  );
};

export default ExerciseHeader;
