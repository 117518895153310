import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {classNames as cn} from "helpers";
import st from "./InputInn.module.scss";

const InputInn = props => {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(props.options[0])
  const [classes, setClasses] = useState([])
  const selectorRef = useRef(null)

  const changeClasses = (type, className) => {
    if (type === 'add') {
      setClasses([...classes, className])
    }
    if (type === 'remove') {
      setClasses(classes.filter(item => item !== className))
    }
  }

  const handleDocumentClick = (event) => {
    if (selectorRef.current && selectorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  return (
    <div ref={selectorRef}
         className={cn({
           [st.inputInn]: true,
           [st.error]: props.error,
           [st.disabled]: props.disabled,
           [classes.join(' ')]: classes.length > 0,
         })}>
      <div className={st.selector}>
        <div onClick={() => setOpen(!open)}
          className={cn({
            [st.current]: true,
            [st.current_active]: open
          })}>
          {type.name || props.options[0].name}
        </div>
        {open &&
          <ul>{props.options.map(el =>
            <li key={el.id}
                className={el.id === type.id ? st.active : ''}
                onClick={() => {
                  setType(el)
                  setOpen(false)
                  props.onChangeType && props.onChangeType(el)
                }}>
              {el.name}
            </li>)}
          </ul>}
      </div>
      <input onClick={() => setOpen(false)}
        maxLength="12"
        value={props.value || ''}
        placeholder="111  222  333  444"
        onChange={e => props.onChange(e.target.value.replace(/\D/g, ''))}
        onFocus={() => changeClasses('add', st.focus)}
        onBlur={() => changeClasses('remove', st.focus)}
        onMouseOver={()=> changeClasses('add', st.hover)} 
        onMouseOut={()=> changeClasses('remove', st.hover)}
      />
    </div>
  )
}

InputInn.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default InputInn
