import {action, observable, toJS, computed, makeObservable} from 'mobx';
import { superAxiosRequest } from 'axiosApi';

const getMockData = (id) => ({
  main_settings: {
    is_active: true,
    company: id,
    online_system: 1,
  },
  slot_types: [],
  client_registration: {
    choose_teacher: true,
    client_multiple_register: true,
    client_time_register: true,
    register_page_header: "Онлайн запись",
    register_page_contacts: {
      first_name: {
        active: true,
        type: "text",
        name: "Имя",
        mandatory: true
      },
      last_name: { active: true, type: "text", name: "Фамилия", mandatory: true },
      father_name: {
        active: true,
        type: "text",
        name: "Отчество",
        mandatory: true
      },
      birthday: { active: true, type: "date", name: "Дата рождения", mandatory: true },
      email: { active: true, type: "email", name: "E-mail", mandatory: true },
      phone: {
        active: true,
        type: "phone",
        name: "Номер телефона",
        mandatory: true
      },
      inn: { active: true, type: "text", name: "ИНН/ИИН", mandatory: false },
      sex: { active: false, type: "text", name: "Пол", mandatory: false },
      level: { active: false, type: "text", name: "Уровень письменного теста", mandatory: false },
      add_field1: {
        active: false,
        type: "text",
        name: "Дополнительное поле",
        mandatory: false
      },
      add_field2: {
        active: false,
        type: "text",
        name: "Дополнительное поле",
        mandatory: false
      },
      add_field3: {
        active: false,
        type: "text",
        name: "Дополнительное поле",
        mandatory: false
      }
    }
  },
  payments_all: {
    sum: 50000,
    payment_type: 1,
    payment_type_slots: 1,
    sms_text: "test sms",
    sms_time_booking: 30,
    pay_time_booking: 20,
    admin_time_booking: 300,
    email_time_booking: 30,
    email_template: 8,
  },
  payments: [],
  locations: [],
  amo_crm: {
    amocrm_is_active: false,
    amocrm_slug: "Заголовок для AmoCRM",
    amocrm_url: "https://www.google.com",
    amocrm_contacts: {
      full_name: { active: true, type: "text", name: "full_name", mandatory: true },
      email: { active: true, type: "email", name: "email", mandatory: true },
      birthday: { active: true, type: "date", name: "birthday", mandatory: true },
      inn: { active: true, type: "text", name: "inn", mandatory: false },
      sex: { active: true, type: "text", name: "sex", mandatory: false },
      date: { active: true, type: "text", name: "date", mandatory: false },
      level: { active: true, type: "text", name: "level", mandatory: false },
      phone: { active: true, type: "phone", name: "phone", mandatory: false },
      comment: { active: true, type: "text", name: "comment", mandatory: false },
      teacher: { active: true, type: "text", name: "teacher", mandatory: false },
      add_field1: {
        active: true,
        type: "text",
        name: "add_field1",
        mandatory: false
      },
      add_field2: {
        active: true,
        type: "text",
        name: "add_field2",
        mandatory: false
      },
      add_field3: {
        active: true,
        type: "text",
        name: "add_field3",
        mandatory: false,
      }
    }
  }
});

const getDefaultSlotType = (companySettingID) => ({
  id: `new-${Date.now()}`,
  color: '#ffffff',
  comment: '',
  company_settings: companySettingID,
  is_accessible: true,
  is_active: true,
  is_comment: false,
  name: 'Стандартный тип',
});

class SettingsStore {
  companyID = null;
  companySettings = null;
  isOnlineAllowed = false;
  onlineSystem = 1;
  slotTypes = [];
  registrationTitle = '';
  registrationLogo = null;
  registrationOptions = {};
  registrationFormFields = {};
  paymentsOptions = {};
  paymentsEmployees = [];
  paymentsLocations = [];
  emailTemplates = [];
  isAmoActive = false;
  amoInputs = {};
  amoToggles = {};
  isGroupsCatalogLoading = true;
  employees = [];
  locations = [];

  constructor() {
    makeObservable(this, {
      isOnlineAllowed: observable,
      onlineSystem: observable,
      slotTypes: observable,
      registrationTitle: observable,
      registrationLogo: observable,
      registrationOptions: observable,
      registrationFormFields: observable,
      paymentsOptions: observable,
      paymentsEmployees: observable,
      paymentsLocations: observable,
      isAmoActive: observable,
      amoInputs: observable,
      amoToggles: observable,
      isGroupsCatalogLoading: observable,
      employees: observable,
      emailTemplates: observable,

      toggleIsOnlineAllowed: action.bound,
      toggleOnlineSystem: action,
      toggleSlotType: action,
      addSlotType: action,
      setSlotTypes: action,
      setData: action,
      //sendData: action,
      setRegistrationTitle: action.bound,
      setRegistrationLogo: action.bound,
      toggleRegistrationCheckBox: action.bound,
      toggleRegistrationFormCheckBox: action.bound,
      setRegistrationFormField: action,
      setPaymentType: action,
      setPaymentTime: action,
      setPaymentSms: action,
      toggleIsAmoActive: action.bound,
      toggleAmoToggles: action,
      setAmoTogglesName: action,
      setAmoInput: action.bound,
      setIsGroupsCatalogLoading: action,
      setEmployees: action,
      setLocations: action,
      setPaymentOptions: action.bound,
      setPaymentsEmployeesOptions: action.bound,
      addPaymentsEmployees: action.bound,
      removePaymentsEmployees: action.bound,
      setPaymentsLocationsOptions: action.bound,
      addPaymentsLocation: action.bound,
      removePaymentsLocation: action.bound,
      setEmailTemplates: action.bound,
      setEmailTemplateID: action.bound,
      fetchData: action.bound,
      fillEmptyData: action.bound,
      fetchCompanies: action.bound,

      getSlotType: observable,
      slotTypesArray: computed,
      usedColors: computed,
      registrationToggles: computed,
      registrationOptionsData: computed,
      employeesOptions: computed,
      locationsOptions: computed,
      paymentsEmployeesData: computed,
      paymentsLocationsData: computed,
      isSubmitDisabled: computed,
    });
  }

  toggleIsOnlineAllowed() {
    this.isOnlineAllowed = !this.isOnlineAllowed;
  }

  toggleOnlineSystem(id) {
    this.onlineSystem = id;
  }

  toggleSlotType(id) {
    this.slotTypes = this.slotTypes
      .map((item) => item.id === id ? {...item, is_active: !item.is_active} : item);
  }

  setData(data) {
    const {
      amo_crm: {amocrm_is_active, amocrm_slug, amocrm_url, amocrm_contacts},
      client_registration: {
        choose_teacher,
        client_multiple_register,
        register_page_header,
        client_time_register,
        register_page_contacts,
        register_page_icon
      },
      main_settings: {company, company_settings, is_active, online_system},
      slot_types,
      payments_all,
      payments,
      locations
    } = data;

    this.companyID = company;
    this.companySettings = company_settings;
    this.isOnlineAllowed = is_active;
    this.onlineSystem = online_system;
    this.slotTypes = slot_types.length === 0 ? [getDefaultSlotType(company_settings)] : slot_types;

    this.registrationTitle = register_page_header;
    this.registrationLogo = register_page_icon;
    this.registrationFormFields = register_page_contacts;
    this.registrationOptions = { choose_teacher, client_multiple_register, client_time_register };

    this.paymentsOptions = payments_all;
    this.paymentsEmployees = payments;
    this.paymentsLocations = locations;

    this.isAmoActive = amocrm_is_active;
    this.amoInputs = {amoSlug: amocrm_slug, amoUrl: amocrm_url};
    this.amoToggles = amocrm_contacts;
  }

  fetchData(id, onSuccess, onFail) {
    superAxiosRequest({
      method: 'get',
      link: `online_schedule/${id}`
    }).then(({data}) => {
      if (data.main_settings.company === null) {
        this.fillEmptyData(id, onSuccess, onFail);
      } else {
        this.setData(data);
        onSuccess(false);
      }
    }).catch(({message}) => {
      onFail({isError: true, message});
      onSuccess(false);
    });
  }

  fillEmptyData(id, onSuccess, onFail) {
    const dataForFill = getMockData(id);
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/${id}`,
    }, dataForFill)
      .then(({data}) => {
        this.setData(data);
        onSuccess(false);
      })
      .catch(({message}) => {
        onFail({ isError: true, message });
        onSuccess(false);
      });
  }

  fetchCompanies(id, onSuccess) {
    superAxiosRequest({
      method: 'get',
      link: `db/companies/${id}`,
    })
      .then(({data}) => onSuccess(`${data.name}:`));
  }

  addSlotType(slotType) {
    this.slotTypes = [...this.slotTypes, slotType];
  }

  setSlotTypes(data) {
    this.slotTypes = this.slotTypes
      .map((item) => item.id === data.id ? data : item);
  }

  setRegistrationTitle(title) {
    this.registrationTitle = title;
  }

  setRegistrationLogo(link) {
    this.registrationLogo = link;
  }

  toggleRegistrationCheckBox(name) {
    this.registrationOptions[name] = !this.registrationOptions[name];
  }

  toggleRegistrationFormCheckBox(name) {
    this.registrationFormFields[name].active = !this.registrationFormFields[name].active;
  }

  setRegistrationFormField(name, data) {
    this.registrationFormFields[name] = {...data, active: this.registrationFormFields[name].active};
  }

  setPaymentType(id) {
    this.paymentsOptions.payment_type = id;
  }

  setPaymentTime(name, value) {
    this.paymentsOptions[name] = value < 0 ? 0 : value;
  }

  setPaymentSms(text) {
    this.paymentsOptions.sms_text = text;
  }

  toggleIsAmoActive() {
    this.isAmoActive = !this.isAmoActive;
  }

  setAmoInput(name, value) {
    this.amoInputs[name] = value;
  }

  toggleAmoToggles(name) {
    this.amoToggles[name].active = !this.amoToggles[name].active;
  }

  setAmoTogglesName(name, value) {
    this.amoToggles[name].id = value;
  }

  setIsGroupsCatalogLoading(flag) {
    this.isGroupsCatalogLoading = flag;
  }

  setEmployees(data) {
    this.employees = data;
  }

  setLocations(data) {
    this.locations = data;
  }

  setPaymentOptions(name, value) {
    this.paymentsOptions[name] = value;
  }

  setPaymentsEmployeesOptions(id, option, value) {
    this.paymentsEmployees = this.paymentsEmployees.map((item) => item.id === id
      ? {...item, [option]: value}
      : item
    );
  }

  addPaymentsEmployees() {
    this.paymentsEmployees = [...this.paymentsEmployees, {
      id: `new-${Date.now()}`,
      employee_id: null,
      company_settings: this.companySettings,
      payment_type: 1,
      payment_type_slots: 2,
      is_active: true,
      sum: 50000,
    }];
  }

  removePaymentsEmployees(id) {
    // remove new employee from array
    if (id.toString().includes('new')) {
      this.paymentsEmployees = this.paymentsEmployees.filter((item) => item.id !== id);
      return;
    }
    // or mark existing employee as non active
    this.paymentsEmployees = this.paymentsEmployees.map((item) => item.id === +id
      ? {...item, is_active: false}
      : item
    );
  }

  setPaymentsLocationsOptions(id, key, value) {
    this.paymentsLocations = this.paymentsLocations.map(item => item.id === id
      ? {...item, [key]: value}
      : item
    );
  }

  addPaymentsLocation() {
    this.paymentsLocations = [...this.paymentsLocations, {
      id: `new-${Date.now()}`,
      location_id: null,
      company_settings: this.companySettings,
      payment_type: 6,
      payment_type_slots: 2,
      is_active: true,
      sum: 50000,
    }];
  }

  removePaymentsLocation(id) {
    // remove new employee from array
    if (id.toString().includes('new')) {
      this.paymentsLocations = this.paymentsLocations.filter(item => item.id !== id);
      return;
    }
    // or mark existing employee as non-active
    this.paymentsLocations = this.paymentsLocations.map(item => item.id === +id
      ? {...item, is_active: false}
      : item
    );
  }

  setEmailTemplates(data) {
    this.emailTemplates = data;
  }

  setEmailTemplateID(id) {
    this.paymentsOptions.email_template = id;
  }

  getSlotType(id) {
    return this.slotTypes.find((item) => item.id === id);
  }

  get slotTypesArray() {
    return toJS(this.slotTypes);
  }

  get usedColors() {
    return this.slotTypes.map(({color}) => color);
  }

  get registrationToggles() {
    return Object.entries(this.registrationFormFields);
  }

  get registrationOptionsData() {
    return toJS(this.registrationOptions);
  }

  get employeesOptions() {
    return toJS(this.employees)
      .sort((a, b) => a.full_name > b.full_name ? 1 : -1)
      .map(({id, full_name}) => ({
        value: id,
        label: full_name,
      }));
  }

  get locationsOptions() {
    return toJS(this.locations)
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(({id, name}) => ({
        value: id,
        label: name,
      }));
  }

  get paymentsEmployeesData() {
    return toJS(this.paymentsEmployees);
  }

  get paymentsLocationsData() {
    return toJS(this.paymentsLocations);
  }

  get emailTemplatesOptions() {
    return toJS(this.emailTemplates).map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }

  get isSubmitDisabled() {
    const isEmployeeNameNotEmpty = this.paymentsEmployees.every((item) => item.employee_id);
    const requiredFields = [
      this.registrationTitle ? '' : 'заголовок страницы, ',
      this.amoInputs.amoSlug ? '' : 'адрес SLUG, ',
      this.amoInputs.amoUrl ? '' : 'URL откуда поступают заявки, ',
      this.paymentsOptions.sms_text ? '' : 'текст SMS, ',
      this.paymentsOptions.sum ? '' : 'стоимость слотов, ',
      isEmployeeNameNotEmpty ? '' : 'имя сотрудника, '
    ];
    // create a string with no ', ' at the end
    const text = requiredFields.join('').replace(/,\s$/, '');

    return {
      isDisabled: Boolean(text),
      message: text,
    };
  }

  get data() {
    // delete empty id key
    const addFields = {
      add_field1: {...this.amoToggles.add_field1},
      add_field2: {...this.amoToggles.add_field2},
      add_field3: {...this.amoToggles.add_field3},
    };
    for (const key in addFields) {
      if (!addFields[key].id) {
        addFields[key] = {...addFields[key]};
        delete addFields[key].id;
      }
    }

    // delete empty email_template key
    const paymentOptions = {...toJS(this.paymentsOptions)};
    if (!paymentOptions.email_template) {
      delete paymentOptions.email_template;
    }

    //delete new id and comment
    const slotTypesArr = [...toJS(this.slotTypes)];
    slotTypesArr.map((item) => {
      if (item.id.toString().includes('new')) {
        delete item.id;
      }
      if (!item.comment) {
        delete item.comment
      }
      return item;
    });

    //delete new id
    const employeesArr = [...toJS(this.paymentsEmployees)];
    employeesArr.map((item) => {
      if (item.id.toString().includes('new')) {
        delete item.id;
      }
      return item;
    });

    //delete new id
    const locations = [...toJS(this.paymentsLocations)].map((item) => {
      if (item.id.toString().includes('new')) {
        delete item.id;
      }
      return item;
    });

    return {
      main_settings: {
        company: this.companyID,
        is_active: this.isOnlineAllowed,
        online_system: this.onlineSystem,
      },
      slot_types: slotTypesArr,
      client_registration: {
        choose_teacher: this.registrationOptions.choose_teacher,
        client_multiple_register: this.registrationOptions.client_multiple_register,
        client_time_register: this.registrationOptions.client_time_register,
        register_page_header: this.registrationTitle,
        register_page_icon: this.registrationLogo,
        register_page_contacts: toJS(this.registrationFormFields),
      },
      payments_all: paymentOptions,
      payments: employeesArr,
      locations,
      amo_crm: {
        amocrm_is_active: this.isAmoActive,
        amocrm_slug: this.amoInputs.amoSlug,
        amocrm_url: this.amoInputs.amoUrl,
        amocrm_contacts: {...toJS(this.amoToggles), add_field1: addFields.add_field1, add_field2: addFields.add_field2, add_field3: addFields.add_field3},
      },
    };
  }
}

export default new SettingsStore();
