import React from 'react';
import style from './ModalMoreInfo.module.scss'
import {dateFormat} from "../../../../../../../helpers/date";
import RatingStars from "../../AverageProgressReport/RatingStars/RatingStars";
import Stores from "../../../../../../../Stores";
import {ReactComponent as StarHalf} from "../../../../../../UI/icons/star-half-fill.svg";
import {ReactComponent as Star} from "../../../../../../UI/icons/star-fill.svg";

const ModalMoreInfo = ({item, averageRating, isTeacher , studentCreatedRating}) => {

    const showRating = (item) => {
        let stars = []

        for (let i = 0; i < 5; i++) {
            if (item >= 1) {
                stars.push(<Star className={style.filled}/>)
            }else if (item > 0 && item < 1) {
                stars.push(<StarHalf className={style.filled}/>)
            }else {
                stars.push(<Star className={style.empty}/>)
            }
            item--
        }
        return stars
    }

    return (
        <div className={style.container}>
            <div className={style.info}>
                <h2>
                    {Stores.transliterate(`${ Stores.transliterate(item.client_name || item.teacher_name)}`)}
                </h2>
                <span>
                    {dateFormat(new Date(item.evaluation_date), 'MMM, YYYY')}
                </span>
                <span>
                    {isTeacher ? item.teacher_name : studentCreatedRating}
                </span>
                <RatingStars rating={averageRating} />
            </div>

            <div className={style.ratings}>
                {
                    item.rate_list.map((el, index) => {
                        return (
                         <div key={index} className={style.rating}>
                             <span>{el.evaluation_name}:</span>
                             <div className={style.stars}>
                                 {showRating(el.value).map((item, index) => <div key={index}>{item}</div>)}
                                 <span>{el.value}</span>
                             </div>
                         </div>
                        )
                    })
                }
            </div>

        </div>
    );
};

export default ModalMoreInfo;