import React, { useEffect, useState } from 'react';
import Stores from 'Stores';
import st from './ImagesTextWords.module.scss';
import {ExerciseSelect} from "../../ExerciseSelect/ExerciseSelect";

export default function ImagesSelectText(props) {
	const [result, setResult] = useState([]);

	useEffect(() => {
		if (props.studentAnswer) {
			setResult(props.studentAnswer.answer.map((a) => ({ link: a.image, description: a.text })));
		} else {
			setResult(props.data.images_list.map(link => ({ link, description: '' })));
		}
	}, [props.data, props.studentAnswer, props.try]);

	useEffect(() => {
		if (props.studentAnswer) return;
		props.handleAnswers(result, result.every((el) => el.description !== ""));
	}, [result]);

	const onInputChange = (index, selectedOption) => {
		const updatedResult = [...result];
		updatedResult[index].description = selectedOption.value;
		setResult(updatedResult);
	};

	const renderImageUnit = (unit, index) => {
		const wordsList = props.data.words_list[index].split('|');
		const answers = props.studentAnswer
			? {label: props.studentAnswer.answer[index].text, value: props.studentAnswer.answer[index].text}
			: null;

		return (
			<div
				key={index}
				className={st.unit}
			>
				<figure>
					<img src={Stores.baseLink() + unit.link} alt="Image"/>
				</figure>
				<ExerciseSelect
					key={props.try}
					options={wordsList.map(el => {
						return {
							label: el,
							value: el
						}
					})}
					value={answers || null}
					onChange={(selectedOption) => onInputChange(index, selectedOption)}
					placeholder="Select text"
					isImagesSelectText={true}
					isCorrect={
						props.studentAnswer &&
						props.studentAnswer.result_in_detail &&
						(Array.isArray(props.studentAnswer.result_in_detail)
								? props.studentAnswer.result_in_detail[index][unit.link]
								: props.studentAnswer?.result_in_detail[unit.link]
						)
					}
				/>
			</div>
		)
	}

	const renderImageUnits = () => result.map((unit, index) => renderImageUnit(unit, index));

	return (
		<div className={st.units}>
			{renderImageUnits()}
		</div>
	);
}