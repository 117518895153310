import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../../UI/ModalWrapper/ModalWrapper';
import Select from '../../../../../UI/Select/Select';
import Button from '../../../../../UI/Button/Button';
import InputsWrapper from '../../../../../UI/InputsWrapper/InputsWrapper';
import Input from '../../../../../UI/Input/Input';
import InputCheckbox from '../../../../../UI/InputCheckbox/InputCheckbox';
import Flex from '../../../../../UI/Flex/Flex';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

const options = [
  {value: 'text', label: 'Текст'},
  {value: 'date', label: 'Дата'},
  {value: 'email', label: 'E-mail'},
  {value: 'phone', label: 'Телефон'},
];

function ContactFormModal({
  name,
  label,
  fixedLabel,
  type,
  isRequired,
  isNonEditable,
  isDisabled,
  onClose,
}) {
  const defaultOption = options.find(({value}) => value === type);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [inputValue, setInputValue] = useState(label);
  const [checked, setChecked] = useState(isRequired);

  const handleInputChange = (evt) => {
    setInputValue(evt.target.value);
  };
  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };
  const handleSubmit = () => {
    const data = {
      type: selectedOption.value,
      name: inputValue,
      mandatory: checked,
    };
    slotsSettingsStore.setRegistrationFormField(name, data);
    onClose();
  };

  return (
    <ModalWrapper
      title={`Настройка поля: ${fixedLabel}`}
      onClose={onClose}
    >
      <InputsWrapper>
        <Select
          tag='li'
          label='Тип поля'
          placeholder='Выберите тип поля'
          defaultValue={selectedOption}
          options={options}
          onChange={handleSelectChange}
          isDisabled={isDisabled}
        />
        <Input
          tag='li'
          label="Заголовок"
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          placeholder="заголовок"
          value={inputValue}
        />
        { !isNonEditable &&
          <InputCheckbox
          tag="li"
          label="Обязательно для заполнения"
          name="isRequired"
          selected={checked}
          onChange={() => setChecked(!checked)}
        />
        }
      </InputsWrapper>
      <Flex justify='end' withGap>
        <Button onClick={onClose} variant="white">Отменить</Button>
        <Button
          onClick={handleSubmit}
          isDisabled={!inputValue}
        >
          Сохранить
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

ContactFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  fixedLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isNonEditable: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ContactFormModal;
