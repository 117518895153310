import React from 'react';
import styles from './EditBox.module.scss';
import { classNames as cn } from "helpers";

const EditBox = ({ children, className }) => {
    return (
        <div
            className={cn({
                [styles.wrapper]: true,
                [className]: className
            })}
        >
            {children}
        </div>
    )
}

export default EditBox;