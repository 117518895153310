import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Select from 'components/UI/Select/Select';
import Flex from 'components/UI/Flex/Flex';
import WorkingHoursNotice from 'components/Modules/Slots/components/WorkingHoursNotice/WorkingHoursNotice';
import { superAxiosRequest } from 'axiosApi';
import styles from './CompanyLocationSelects.module.scss'
import { translate as loc, dict } from "Localisation";

const DataType = {
  selectedLocation: 'selectedLocation',
  selectedCompany: 'selectedCompany',
  companies: 'companies',
};

function CompanyLocationSelects({
  companiesList,
  locationsList,
  selectedCompany,
  selectedLocation,
  onSetData,
  onResetData,
}) {
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false);

  const handleCompanyChange = (option) => {
    onSetData(null, DataType.selectedLocation);
    onResetData();
    onSetData(option, DataType.selectedCompany);
  };

  const handleLocationChange = (option) => {
    onSetData(option, DataType.selectedLocation);
  };

  useEffect(() => {
    if (companiesList.length === 0) {
      setIsCompaniesLoading(true);
      superAxiosRequest({
        method: 'get',
        link: 'db/companies',
      })
        .then(({data}) => {
          data.result.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          onSetData(data.result, DataType.companies);
          if (data.result.length === 1) {
            onSetData({ value: data.result[0].id, label: data.result[0].name }, DataType.selectedCompany);
          }
        })
        .finally(() => setIsCompaniesLoading(false));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Flex withGap justify='start' tag='ul'>
        {companiesList.length > 1 &&
          <Select
            tag="li"
            placeholder={loc(dict.work_table_listing.choose_company)}
            options={companiesList}
            value={selectedCompany}
            onChange={handleCompanyChange}
            isLoading={isCompaniesLoading}
            classes={styles.select}
          />
        }
        <Select
          tag='li'
          placeholder={loc(dict.work_table_listing.choose_location)}
          options={locationsList}
          value={selectedLocation}
          onChange={handleLocationChange}
          classes={styles.select}
          isDisabled={!selectedCompany}
        />
      </Flex>
      {
        selectedLocation &&
        <WorkingHoursNotice
          startAt={selectedLocation.start_at}
          endAt={selectedLocation.end_at}
          timeZone={selectedLocation.time_zone}
          address={selectedLocation.address}
        />
      }
    </>
  );
}

const optionTypes = PropTypes.shape({
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

CompanyLocationSelects.propTypes = {
  companiesList: PropTypes.arrayOf(optionTypes).isRequired,
  locationsList: PropTypes.arrayOf(PropTypes.shape({
    optionTypes,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired,
    time_zone: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
  })).isRequired,
  selectedCompany: optionTypes,
  selectedLocation: optionTypes,
  onSetData: PropTypes.func.isRequired,
  onResetData: PropTypes.func.isRequired,
};

export default observer(CompanyLocationSelects);
