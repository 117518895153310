import React from 'react';
import PropTypes from "prop-types";
import {classNames as cn} from "helpers"
import './CheckboxToggle.scss'

export default function CheckboxToggle(props) {
    return (
        <div className={cn({
            "checkboxToggle": true,
            "checkboxToggle--disabled": props.disabled,
            ["checkboxToggle--" + props.mode]: props.mode
        })}
             onClick={() => !props.disabled && props.onChange(!props.checked)}>

            {props.left && <div className={`checkboxToggle__label ${(props.checked && props.status) ?  'checkboxToggle--unselected' : '' }`}>{props.left}</div>}

            <span className={cn({
                "checkboxToggle__toggle": true,
                "checkboxToggle__toggle--checked": props.checked
            })}/>

            {props.right && <div className={`checkboxToggle__label ${(!props.checked && props.status) ?  'checkboxToggle--unselected' : '' }`}>{props.right}</div>}

        </div>
    );
}

CheckboxToggle.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    left: PropTypes.string,
    right: PropTypes.string,
    disabled: PropTypes.bool,
    mode: PropTypes.oneOf(['inline'])
};
