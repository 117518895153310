import React, {Fragment, useEffect, useState} from 'react';
import st from "./DragWords.module.scss";

export default function DragWords(props) {
  let text = props.text
  text = text.replaceAll('<div>', '<br/>')
  text = text.replaceAll('<br>', '')
  text = text.replaceAll('</div>', '')

  const initAnswers = () => {
    const reg = /\[(.*?)]/g;
    const arr = [];
    const arr2 = [];

    for (let m = reg.exec(props.answers?.[0]); m; m = reg.exec(props.answers?.[0])){
      arr2.push(m[1])
    }

    for (let i = 0; i < text.match(reg).length; i++) {
      arr.push(arr2[i] || '')
    }

    return arr
  }

  const [words, setWords] = useState(props.answers?.length ? [] : props.words)
  const [answers, setAnswers] = useState(initAnswers)

  // добавляем ответ в первое пустое место
  const insert = idx => {
    setWords(words.filter((w, i) => i !== idx))
    const arr = [...answers]
    const emptyIndex = answers.findIndex(a => a.length < 1)
    if (emptyIndex !== -1)
      arr[emptyIndex] = words[idx]
    else
      arr.push(words[idx])
    setAnswers(arr)
  }

  // при удалении ответа оставляем пустую строку
  const remove = idx => {
    setAnswers(answers.map((a, i) => i === idx ? '' : a))
    setWords([...words, answers[idx]])
  }

  useEffect(() => {
    if (words.length === 0){
      const body = aPartText.reduce((acc, part, idx) => `${acc}${part}${answers[idx] ? `[${answers[idx]}]` : ''}`, '')
      props.onComplete([body])
    } else {
      props.onComplete([])
    }
  }, [words, answers])

  const aPartText = text.split(/\[.*?]/);

  return (
    <div className={st.dragWords}>
      <div className={st.words}>{words.map((word, idx) =>
        <div key={idx} onClick={() => insert(idx)}>
          {word}
        </div>)}
      </div>
      <div className={st.text}>{aPartText.map((p, i) =>
        <Fragment key={i}>
          <span dangerouslySetInnerHTML={{__html: p}}/>
          {i < aPartText.length - 1 &&
          <input key={i} readOnly value={answers[i]} onClick={(e) => e.target.value.length > 0 && remove(i)}/>}
        </Fragment>
      )}
      </div>
    </div>
  );
}
