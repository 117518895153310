import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react";
import st from "./StudentStudyTypes.module.scss";
import asideModalStore from "Stores/asideModalStore";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import { superAxiosRequest } from 'axiosApi';

const StudentStudyTypes = () => {
    const [types, setTypes] = useState([])
  
    const {modalValues, changeValue} = asideModalStore

    useEffect(() => {
        superAxiosRequest({link: 'groups/payment_type' , method: 'get'}).then((res) => {
          setTypes(res.data.result)
        }).catch(()=> {
        
          })
    },[])

  return (
        <div className={st.toggleRadioBox}>
            {types.map((item, key) => (
              <ToggleCheckbox
                key={key}
                label={item.label}
                labelClassName={st.toggleRadioBoxLabel}
                checked={item.value === modalValues.payment_type}
                onChange={() => changeValue(item.value, 'payment_type')}
              />
            ))}
        </div>
        
  )
}

export default observer(StudentStudyTypes)
