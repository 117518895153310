import React from 'react';
import { observer } from 'mobx-react';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import slotsRecordCustomerStore, {DataType} from 'Stores/slotsRecordCustomerStore';
import styles from './Sms.module.scss';
import { maskPhone } from 'helpers/phoneMask';

const Label = () => (
  <span className={styles.label}>
    <strong>Отправить SMS</strong> с информацией о бронировании времени (платно)
  </span>
);

function Sms() {
  const {clientData, isSms, setData} = slotsRecordCustomerStore;

  return (
    <>
      <InputToggle
        label={<Label />}
        selected={isSms}
        onChange={() => {setData(!isSms, DataType.isSms)}}
        withMargin
      />
      {isSms &&
        <p className={styles.text}>
          <span>SMS будет отправлено на номер:</span> {
            clientData?.phone?.value
             ? maskPhone(clientData.phone.value)
             : <span className={styles.error}>Укажите номер в данных клиента</span>}
        </p>
      }
    </>
  );
}

export default observer(Sms);
