import React from 'react';
import {superAxiosRequest} from "axiosApi";
import Stores from "Stores"
import clientStore from "Stores/clientStore";
import ActionMenu from "components/ContentBox/Global/ActionMenu/ActionMenu";
import st from "./LinkedAccounts.module.scss"

export default function LinkedAccounts(props) {
    const menu = [
        { name: "Перейти в аккаунт", action: 'login', permission: ['forward'] },
    ]
    if (Stores.Rules.checkRule2('users', 'personal_information', 'change_personal_information')){
        menu.push({ name: "Удалить", action: 'delete', permission: ['forward'] },)
    }

    const {fetchClient} = clientStore

    const action = (action, e, payload) => {
        let apiConf;
        switch (action) {
            case 'login':
                apiConf = {
                    method: 'get',
                    link: 'accounts/clients/login_as/' + payload.id,
                }
                superAxiosRequest(apiConf).then(res => {
                    if (res.status === 200){
                        localStorage.setItem('access', res.data.access);
                        localStorage.setItem('refresh', res.data.refresh);
                        localStorage.setItem('ud', JSON.stringify(res.data))
                        document.location.href = "/"
                    }
                }).catch()
                break;
            case 'delete':
                apiConf = {
                    method: 'put',
                    link: 'accounts/clients/' + props.client.profile.id + '/children'
                }

                const body = {children: props.client.profile.children_id.filter(x => x.id !== payload.id).map(x => x.id)}
                superAxiosRequest(apiConf, body).then(() => {
                    fetchClient(props.client.id)
                })
                break;
        }
    }

    return (
        <div className={`phoenixBox ${st.box}`}>
            <main>{props.client.profile.children_id.map(a =>
            <div key={a.id} className={st.row}>
                <div>
                    {`${a.last_name} ${a.first_name}`}
                </div>
                <div>
                    <ActionMenu menu={menu} action={action} items={a}/>
                </div>
            </div>
            )}

            </main>
            <footer>
                <span onClick={props.openModalAddChildUser}>Добавить аккаунт в "Родительский контроль"</span>
            </footer>
        </div>
    );
}
