import React from 'react';
import PropTypes from "prop-types"
import ActionMenu from "components/ContentBox/Global/ActionMenu/ActionMenu";
import {Link, useHistory} from "react-router-dom";
import st from "./Document.module.scss"
import documentImg from "../../../img/Document.png"
import modalStore from 'Stores/modalStore';





const Document = ({data, listMenu, docType, modalInfo, documentInfo, confirmField,userNameField}) => {
    const history = useHistory()
    const action = (action) => {
        switch (action) {
            case 'open':
                history.push(docType === 'template' ? `${documentInfo.baseLink}/viewer/template/${data.id}` : `${documentInfo.baseLink}/viewer/contract/${data.id}`)
                break
            case 'download':
                history.push(docType === 'template' ? `${documentInfo.baseLink}/viewer/template-print/${data.id}` : `${documentInfo.baseLink}/viewer/print/${data.id}`)
                break
            case 'edit_template':
                history.push(`${documentInfo.baseLink}/constructor/${data.id}`)
                break
            case 'send_to_user':
                const ms = {
                    link: modalInfo.requestLink,
                    button: 'Отправить документ',
                    title: modalInfo.title,
                    type: 'SendDocsToUser',
                    active_inputs: ['text', 'textarea'],
                    data: 'media',
                    fieldName: modalInfo.fieldName.user,
                    fieldLink: modalInfo.link,
                    pl_text: 'Имя документа',
                    name_text: 'title',
                    user: documentInfo.user,
                    pl_textarea_tags: 'Комментарий',
                    name_textarea_tags: 'description',
                }
                const md = {
                    [modalInfo.fieldName.temtlate]: data.id
                }
                modalStore.activity(ms, md)
                break
        }
    }
    return (
        <div className={st.book}>
            <Link to={docType === 'template' ? `${documentInfo.baseLink}/viewer/template/${data.id}` : `${documentInfo.baseLink}/viewer/contract/${data.id}`}>
                <div className={st.wrapper_img}>
                    <img className={st.img} src={documentImg} alt="" />
                </div>
                {docType === 'template' ? <div className={st.params}>
                    <div className={st.chapters}>Шаблон договора</div>
                </div> :
                    <div className={st.chapters}>{data[confirmField] ? 'Подписан' : 'Не подписан'} </div>}

                <div className={st.name}>
                    {data.name}
                    {data[userNameField] && <div className={st.name_client}>
                        {data[userNameField]}
                    </div>}
                </div>
            </Link>
            <div className={st.footer}>
                {docType === 'template' ? <div className={st.params}>
                    <div></div>
                </div> :
                    <div>
                        <div className={st.params}>
                            <div>Дата подписания:</div>
                            <div>{data[confirmField] ? data.signed_date : 'не подписан'} </div>
                        </div>
                    </div>}
                <div>
                    <ActionMenu menu={listMenu} action={action} />
                </div>
            </div>
        </div>
    );
}

Document.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Document
