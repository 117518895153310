import React, { useEffect, useState } from 'react';
import { superAxiosRequestEasy } from "axiosApi";
import { Link } from "react-router-dom";
import st from "./TestLogin.module.scss"
import logo from "../logo.png";

export default function TestLogin() {
  const [digits, setDigits] = useState(Array(9).fill(''))
  const [error, setError] = useState(false)
  const refs = Array(9).fill(null).map(() => React.createRef())

  const focusIn = i => {
    refs[i].current.focus()
    setError(false)
  }
  
  const handleChange = (e, i) => {
    const val = e.target.value.replace(/\D/g, '').slice(-1)
    setDigits(digits.map((x, y) => y === i ? val : x))
    if (val.length && i < 8) focusIn(i + 1)
  }

  const handleKeyDown = (e, i) => {
    if (e.key === 'Backspace') {
      e.preventDefault()
      setDigits(digits.map((x, y) => y === i ? '' : x))
      if (i > 0) focusIn(i - 1)
    }

    if (e.key === 'Enter') handleSubmit()
  }

  const handlePaste = e => {
    e.preventDefault()
    const str = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 9)
    setDigits(str.padEnd(9, ' ').split(''))
    focusIn(Math.min(str.length, 8))
  }

  const handleSubmit = () => {
    setError(false)
    const apiConf = {
      method: 'post',
      link: 'study/code_test_access',
    }
    const body = {
      code: digits.join('')
    }

    superAxiosRequestEasy(apiConf, body).then(res => {
      const { access, refresh, purposed_test, user_data } = res.data;
      const end = new Date();
      end.setDate(end.getDate() + 14);

      const body = { 
        access, access_token_death: Math.floor(end.getTime() / 1000), 
        refresh, refresh_token_death: Math.floor(end.getTime() / 1000), 
        user_data, permissions: { study: [{ studentinittest: ['add_student_init_test | study | Учебное тестирование (инициализация) | Может ли проходить учебное тестирование'] }] } 
      };
      localStorage.setItem('ud', JSON.stringify(body))
      localStorage.setItem('refresh', refresh);
      
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `/init-placement-test/${purposed_test.id}`;
      a.click();
    }).catch(() => {
      setError(true)
    })
  }

  useEffect(() => {
    if (refs[0]) focusIn(0)
  }, []);
  
  return (
    <div className="authorization-wrapper">
      <div className={st.testLogin}>
        <img src={logo} className="phoenix-logo" alt="logo" width="70px" />

        <h1>Вход для клиента на тестирование</h1>

        <div className={st.digitsBox}>
          <p>Секретный ключ тестирования:</p>
          <ul className={st.digits}>{digits.map((d, i) =>
            <li key={i}>
              <input className={st.digit}
                ref={refs[i]}
                value={d}
                onChange={e => handleChange(e, i)}
                onKeyDown={e => handleKeyDown(e, i)}
                onPaste={handlePaste}
              />
            </li>)}
          </ul>
        </div>

        <div className={error ? st.errorVis : st.error}>
          <p>Доступного тестирования не найдено!</p>
          <p> Проверьте правильность введенного номера.</p>
        </div>

        <button className="button-type1" onClick={handleSubmit}>Перейти к тестированию</button>

        <div className={st.footer}>
          Также, можете пройти тестирование из личного кабинета!<wbr />
          <Link to="/">Войти в систему</Link>
        </div>
      </div>
    </div>
  );
};
