import React, {useEffect, useState} from 'react';
import styles from './SingleWorkTableModal.module.scss'
import modalStore from "Stores/modalStore";
import DatePicker from "react-datepicker";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {superAxiosRequest} from "axiosApi";
import {addZero} from "helpers/math";


const SingleWorkTableModal = ({
    actionType
}) => {
    const {modalValue, changeValues} = modalStore
    const [locations, setLocation] = useState()

    const fieldNamesTemplates = {
        in: {
            latitude: "in_latitude",
            longitude: "in_longitude",
            time: "start_at",
            location: {value: "in_location_id", label: "in_loc_name"},
        },
        out: {
            latitude: "out_latitude",
            longitude: "out_longitude",
            time: "finish_at",
            location: {value: "out_location_id", label: "out_loc_name"},
        }
    }

    const reasonsArray = [
        {value: 1 , label: 'Домой'},
        {value: 2 , label: 'Перерыв'},
        {value: 3 , label: 'По рабочим делам'},
        {value: 4 , label: 'Личное дело'},
    ]

    const timeCaptionTemplates = {
        in: 'Приход',
        out: 'Уход'
    }

    useEffect(() => {
        let locationApiConf = {
            method: 'get',
            link: `db/locations`
        }
        superAxiosRequest(locationApiConf).then(res => {
            setLocation(res.data.result.map(item => ({label: item.name, value: item.id})))
        })
    }, [])

    return (
        <div className={styles.box}>
            <div className={styles.item}>
                <DatePicker
                    selected={new Date(`2022-5-10 ${modalValue[fieldNamesTemplates[actionType].time] ? modalValue[fieldNamesTemplates[actionType].time] : '00:00:00' }`)}
                    onChange={date => {
                        changeValues(timeConverter(date, 'time') + `:${addZero(date.getSeconds())}`, fieldNamesTemplates[actionType].time)
                    }}
                    disabled={false}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption={timeCaptionTemplates[actionType]}
                    timeFormat="HH:mm:ss"
                    dateFormat="HH:mm:ss"
                    className={styles.input}
                />
            </div>
            <SelectCustom
                placeholder='Выберите локацию'
                options={locations && locations}
                onChange={(option) => changeValues(option.value, fieldNamesTemplates[actionType].location.value)}
                valueID={modalValue[fieldNamesTemplates[actionType].location.value]}
            />
            <div className={styles.item}>
                <input onChange={(e) => {changeValues(e.target.value, fieldNamesTemplates[actionType].latitude)}}
                    value={modalValue[fieldNamesTemplates[actionType].latitude] ? modalValue[fieldNamesTemplates[actionType].latitude]: ''}
                    className={styles.input}  type="number" />
            </div>
            <div className={styles.item}>
                <input onChange={(e) => {changeValues(e.target.value, fieldNamesTemplates[actionType].longitude)}}
                    value={modalValue[fieldNamesTemplates[actionType].longitude] ? modalValue[fieldNamesTemplates[actionType].longitude]: ''}
                    className={styles.input} type="number" /></div>
            {actionType === 'out' && <div className={styles.select_reason}>
                <SelectCustom
                    placeholder='Причина ухода'
                    options={reasonsArray}
                    onChange={(option) => changeValues(option.value, 'out_reason')}
                    valueID={modalValue.out_reason}
                />
            </div>}

        </div>
    );
}



export default SingleWorkTableModal;


