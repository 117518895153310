import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Loader from 'components/UI/Loader/Loader';
import Flex from 'components/UI/Flex/Flex';
import Select from 'components/UI/Select/Select';
import ClientAbout from '../components/ClientAbout/ClientAbout';
import WorkingHoursNotice from 'components/Modules/Slots/components/WorkingHoursNotice/WorkingHoursNotice';
import styles from './SelectorsLayout.module.scss';
import slotsRecordCustomerStore, { ActionType, DataType, getOptions } from 'Stores/slotsRecordCustomerStore';

function SelectorsLayout() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [workingHours, setWorkingHours] = useState(null);
  const {
    companies,
    locations,
    ages,
    programs,
    getDataFromServer,
    fetchDataWhenOverwritingClient,
    selectedCompany,
    selectedLocation,
    selectedProgram,
    selectedAge,
    setData,
    resetData,
  } = slotsRecordCustomerStore;

  const handleSelectChange = (option) => {
    const { selectType, value } = option;
    const selectedList = {
      selectedCompany,
      selectedLocation,
      selectedProgram,
      selectedAge,
    };
    if (value === selectedList[selectType]?.value) {
      return;
    }
    switch (selectType) {
      case DataType.selectedCompany:
        resetData(ActionType.resetAll);
        setWorkingHours(null);
        setData(getOptions(option.locations), DataType.locations)
        break;
      case DataType.selectedLocation:
        break;
    }

    setData(option, selectType);
  };

  useEffect(() => {
    if (selectedLocation === null) {
      return;
    }

    getDataFromServer({
      type: ActionType.getLocation,
      params: {
        id: selectedLocation.value,
      },
      onSuccess: ({ location }) => setWorkingHours(location),
    })}, [selectedLocation]);

  useEffect(() => {
    Boolean(id)
      ? fetchDataWhenOverwritingClient(
        id,
        () => setIsError(true),
        () => setIsLoading(false))
      : getDataFromServer({
          type: ActionType.getSelectorsData,
          onFinally: () => setIsLoading(false),
        });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (id && isLoading) {
    return <Loader fullWidth />;
  }

  if (id && isError) {
    return <p className={styles.error}>Клиент не найден</p>;
  }

  return (
    <>
      {Boolean(id) &&
        <ClientAbout />
      }
      <Flex withGap tag='ul' classes={styles.wrapper}>
        {(companies.length > 1 || companies.length === 0) &&
          <Select
            tag="li"
            placeholder="Компания"
            options={companies}
            value={selectedCompany}
            onChange={handleSelectChange}
            isLoading={isLoading}
            isDisabled={isLoading}
            classes={styles.select}
          />
        }
        <Select
          tag='li'
          placeholder='Локация'
          isLoading={isLoading}
          isDisabled={isLoading || !selectedCompany}
          options={getOptions(locations, DataType.selectedLocation)}
          onChange={handleSelectChange}
          value={selectedLocation}
          classes={styles.select}
        />
        <Select
          tag='li'
          placeholder='Программа обучения'
          isLoading={isLoading}
          isDisabled={isLoading}
          options={getOptions(programs, DataType.selectedProgram)}
          onChange={handleSelectChange}
          value={selectedProgram}
          classes={styles.select}
        />
        <Select
          tag='li'
          placeholder='Возраст клиента'
          isLoading={isLoading}
          isDisabled={isLoading}
          options={getOptions(ages, DataType.selectedAge)}
          onChange={handleSelectChange}
          value={selectedAge}
          classes={styles.select}
        />
      </Flex>
      { workingHours && selectedLocation &&
        <WorkingHoursNotice
          endAt={workingHours.end_at}
          timeZone={workingHours.time_zone}
          startAt={workingHours.start_at}
          address={workingHours.address}
        />
      }
    </>
  );
}

export default observer(SelectorsLayout);
