import React, {useEffect, useRef, useState} from 'react';
//import {observer} from "mobx-react";
import ReactCrop from "react-image-crop";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores"
//import modalStore from "Stores/modalStore";

import "./ImageSelector.scss"

export default function ImageSelector (props) {
    const refInput = useRef()

    const [media, setMedia] = useState([])
    const [url, setUrl] = useState()
    const [image, setImage] = useState()
    const [crop, setCrop] = useState({})
    const [finishImg, setFinishImg] = useState()

    //const {changeValues, changeValuesArray} = modalStore

    const changeHandler = e => {
        const file = e.target.files[0];
        setUrl(URL.createObjectURL(file));
        setMedia([file, file.name]);
    }

    const cropImage = () => {
        const newFileName = Date.now() + media[1];
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        ctx.canvas.toBlob(blob => {
            const file = new File([blob], newFileName, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            setFinishImg(file)
        }, 'image/jpeg', 1);
    }

    const onClickHandler = () => refInput.current.click()

    const onImageLoaded = image => {
        const aspect = image.width > image.height
        const obj = {
            aspect: 1,
            x: 0,
            y: 0,
            width: aspect ? image.height : image.width,
            height: aspect ? image.height : image.width
        }
        if (aspect)
            obj.x = (image.width - obj.width) / 2
        else
            obj.y = (image.height - obj.height) / 2

        setCrop(obj)
        setImage(image)
        return false // Return false when setting crop state in here.
    }

    const deleteImage = () => {

    }

    useEffect(() => {
        if(image && crop.width && crop.height)
            cropImage()
    }, [image, crop])

    useEffect(() => {
        //changeValues(finishImg, ms.name_media)
        props.onChange(finishImg)
    }, [finishImg])

    return (
        <div className="imageSelector">

            <input type="file" onChange={changeHandler} ref={refInput} accept="image/x-png,image/gif,image/jpeg"/>

            {/*<label className="labelFile" htmlFor={inputID}>
                        {!media[0] ?
                            localisationMaster('button-image-one', 'tests/questions', 'image_plus_variants') :
                            localisationMaster('button-image-three', 'tests/questions', 'image_plus_text') + ' ' + media[1].slice(0, 50)
                        }
                    </label>*/}

            {(props.currentPicture && (!url)) ?
            <div className='imageSelector__buttons'>
                <button className="imageSelector__newImageButton" onClick={onClickHandler}>Выбрать новое изображение</button>
                <button className='imageSelector__deleteButton' onClick={deleteImage}>Удалить</button>
            </div> :
            <button onClick={onClickHandler}>
                {localisationMaster('button-image-one', 'tests/questions', 'image_plus_variants')}
            </button>}       

            <p className="imageSelector__tip">{localisationMaster('text_1', 'modal_templates')}</p>

            {(props.currentPicture && (!url)) ?
            <img className="imageSelector__currentImage" src={Stores.baseLink() + props.currentPicture}/> :
            null
            }

            {url &&
            <div className="imageSelector__cropper">
                <ReactCrop src={url} crop={crop} onChange={setCrop} onImageLoaded={onImageLoaded} ruleOfThirds />
            </div>}        

        </div>
    )
}