import React, {useState, useEffect} from 'react';
import MainTableRow from './MainTableRow/MainTableRow';
import Stores from 'Stores';
import {localisationMaster} from "Localisation/Localisation";
import ContentPagination from 'components/ContentBox/Global/ContentPagination/ContentPagination';
import Filter from "../Filter/Filter";
import Loader from '../Loader/Loader';
import SubMenu from "../SubMenu/SubMenu";
import listingStore from 'Stores/listingStore';
import {observer} from 'mobx-react';
import './MainTable.scss'
import FilterLiner from '../Filter/FilterLiner';
import SlideMenuListing from '../../Groups/components/SlideMenuListing';
import Card from 'components/ContentBox/Online-schedule/ClientsAfterOnline/Card.jsx';
import {ExcelRowsParams, setCellParams} from '../../../utils/ExcelExport';
import ExportExcelButton from '../../../Modules/Reports/ExportExcelButton/ExportExcelButton';



const MainTable = props => {

    const {page, perPage, setPage, setPerPage, filterParamsObject, dropData, setFilterConf, filterType} = listingStore
    const data = props.data
    const colsSettings = data.colsSettings;

    const [openAllRows, setOpenAllRows] = useState(props.data.colsSettings.is_open)
    const [link, setLink] = useState(null)
    const [subMenu, setSubmenu] = useState(1);
    const [subMenuType, setSubmenuType] = useState(props.mulitype_values ? props.mulitype_values[0] : null);

    const getData = () => {
        const defaultAPI = {
            offset: (page - 1) * perPage,
            limit: perPage,
            ...((props.filter_by_id && props.id) && {[props.filter_by_id]: props.id}),
            ...(props.apiParams && {...props.apiParams}),
            ...(props.surfMenu && {filter_type: filterType})
        }

        let apiConf = {
            method: 'get',
            link: colsSettings['prefix'] + '/' + colsSettings['link'],
            params: defaultAPI,
        }

        if (props.multitype) {
            defaultAPI[subMenuType] = subMenu;
        }
        apiConf.params = {...filterParamsObject, ...defaultAPI}
        listingStore.fillData(apiConf)
    }

    const drop = () => {
        listingStore.dropData()
        setPage(1)
        setPerPage(10)
        //setfiltred([])
    }

    const openAll = () => {
        setOpenAllRows(!openAllRows)
    }

    const myChangeState = (value, type) => {
        setSubmenu(value)
        setSubmenuType(type)
    }

    useEffect(() => {
        let apiConf = {
            method: 'get',
            link: colsSettings['prefix'] + '/' + colsSettings['link'],
        }
        !!props.apiParams && setFilterConf(apiConf, props.apiParams)
        props.thisPage === link ? getData() : dropData()
    }, [perPage, page, link, subMenuType, subMenu, filterType])

    //PAGE VS FILTER
    useEffect(() => {
        if (props.thisPage !== link) {
            listingStore.data = []
            setLink(props.thisPage)
            listingStore.setPage(1);
        }
    }, [link, props.thisPage])

    useEffect(() => {
        listingStore.dropData()
        return () => {
            listingStore.dropData()
        }
    }, [])

    const exportTemlates = {
        'student-discount'(data) {
            import('xlsx-js-style').then(XLSX => {
                let headRow = [
                    {
                        v: "Ф.И.О. студента",
                    },
                    {
                        v: "Группа",
                    },
                    {
                        v: "Cкидка",
                    }
                ]
                headRow = setCellParams(headRow, ExcelRowsParams.headerRowParam)
    
                let tableData = []
    
                let merges = []
                let wscols = [
                    {wch: 30},
                    {wch: 30},
                    {wch: 30},
                ];
                let rows = [
                    {hpx: 30},
                ]
    
                data.forEach(studentItem => {
                    tableData.push([
                        {
                            v: `${studentItem.student_name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${studentItem.group_name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${studentItem.discount_name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
    
                let wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
                ws['!cols'] = wscols;
                ws["!merges"] = merges
                ws['!rows'] = rows
                XLSX.utils.book_append_sheet(wb, ws, "List 1");
    
                XLSX.writeFile(wb, "Клиенты со скидками.xlsx");
            })
        },
        'debetors'(data) {
            import('xlsx-js-style').then(XLSX => {
                let headRow = [
                    {
                        v: "Ф.И.О. студента",
                    },
                    {
                        v: "Группа",
                    },
                    {
                        v: "Долг",
                    }
                ]
                headRow = setCellParams(headRow, ExcelRowsParams.headerRowParam)
    
                let tableData = []
    
                let merges = []
                let wscols = [
                    {wch: 30},
                    {wch: 30},
                    {wch: 30},
                ];
                let rows = [
                    {hpx: 30},
                ]
    
                data.forEach(studentItem => {
                    tableData.push([
                        {
                            v: `${studentItem.student.first_name} ${studentItem.student.last_name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${studentItem.group.name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${Stores.cleanSumm(studentItem.group_balance / 100)} тг`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
    
                let wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
                ws['!cols'] = wscols;
                ws["!merges"] = merges
                ws['!rows'] = rows
                XLSX.utils.book_append_sheet(wb, ws, "List 1");
    
                XLSX.writeFile(wb, "Список должников.xlsx");
            })
            
        },
        'cert-in-group'(data) {
            import('xlsx-js-style').then(XLSX => {
                let headRow = [
                    {
                        v: "Ф.И.О. студента",
                    },
                    {
                        v: "Группа",
                    },
                    {
                        v: "Баланс сертификата",
                    }
                ]
                headRow = setCellParams(headRow, ExcelRowsParams.headerRowParam)
                let tableData = []

                let merges = []
                let wscols = [
                    {wch: 30},
                    {wch: 30},
                    {wch: 30},
                ];
                let rows = [
                    {hpx: 30},
                ]

                data.forEach(studentItem => {
                    tableData.push([
                        {
                            v: `${studentItem.student_certificate.student.name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${studentItem.group_name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${studentItem.student_certificate.amount}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })

                let wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
                ws['!cols'] = wscols;
                ws["!merges"] = merges
                ws['!rows'] = rows
                XLSX.utils.book_append_sheet(wb, ws, "List 1");

                XLSX.writeFile(wb, "Список студентов с сертификатами.xlsx");
            })
        },

    }

    const downloadExcel = (data, exportTemlate) => {
        exportTemlates[exportTemlate](data)
    }

    return (
        <div>
            {props.filter &&
                <Filter link={props.filterLink} drop={drop} config={props.config} setPage={setPage} subMenu={subMenu}
                    thisPage={props.thisPage} />}
            {props.filterLiner &&
                <FilterLiner link={props.filterLinerLink}
                    drop={drop} config={props.config} setPage={setPage} subMenu={subMenu} thisPage={props.thisPage}
                    getFilterValue={false} baseApi={{...props.apiParams, ...((props.filter_by_id && props.id) && {[props.filter_by_id]: props.id})}}
                />}
            {props.multitype ?
                <SubMenu
                    titles={props.mulitype_values}
                    myChangeState={myChangeState}
                /> : null}
            {props.surfMenu ?
                <SlideMenuListing list={Stores.observableArray(listingStore.meta).group_statuses} link={props.filterLink || props.filterLinerLink} />
                : null}
            <div className="main-table__count">
                {localisationMaster('text_2', 'online-schedule/clients-after-online')} {listingStore.meta.count_items}
                {!colsSettings.noOpener &&
                  <span onClick={() => openAll()}>
                    {openAllRows ?
                      localisationMaster('text-12', 'filter') :
                      localisationMaster('text-13', 'filter')}
                </span>}
            </div>

            {listingStore.loading ?
                <Loader />
                :
                props.thisPage === link && Stores.observableArray(listingStore.data).length > 0 ?
                    <>
                        {Stores.observableArray(listingStore.data).length > 0 ?
                            Stores.observableArray(listingStore.data).map((item, index) =>
                              (colsSettings.is_slot && item) ?
                                <Card items={item} key={index} drop={() => { }} />
                                :
                                <MainTableRow items={item}
                                              openAllRows={openAllRows}
                                              colsSettings={colsSettings}
                                              drop={drop}
                                              index={index}
                                              key={index}
                                              pays={props.pays}
                                              paysButton={props.paysButton}
                                              data={data}
                                              paysScore={props.paysScore}
                                              updateRow={listingStore.updateRow}
                                              getData={getData}
                                />
                            ) :
                          props.errorText ? <div className='groups-listing-header'>{props.errorText}</div> : null}

                        {data.exportToExcel &&
                             <div style={{padding: '10px'}}>
                                <ExportExcelButton data={data} downloadExcel={() => downloadExcel(Stores.observableArray(listingStore.data), data.link)} />
                            </div>
                        }


                        <ContentPagination
                            page={page}
                            allItems={listingStore.meta.count_items}
                            per_page={perPage}
                            //pages={pages}
                            changePage={setPage}
                            perPage={setPerPage}
                        />



                    </>
                    :
                    <div className="main-table__empty-result">{localisationMaster('text_48', 'study/study-test')}</div>
            }

        </div>
    )
}

export default observer(MainTable)
