import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import VideoThumbnail from "react-video-thumbnail";
import {localisationMaster} from "Localisation/Localisation";
import useFileUpload from "hooks/useFileUpload";
import Stores from "Stores"
import VariantDel from "./VariantDel";
import st from "./MediaSelector.module.scss"
import {ReactComponent as Basket} from "components/ContentBox/img/basket.svg";

const MediaSelector = props => {
    const {refInput, progress, file, setFile} = useFileUpload({onUploadEnd: props.onChange})

    const [src, setSrc] = useState('')

    const deleteHandler = () => {
        setFile(null)
        refInput.current.value = '';
        props.onChange(null)
    }

    const renderPreview = () => {
        switch (props.type){
            case 'image':
                return (
                    <div className={st.preview}>
                        <div><img src={src} alt=""/></div>
                        <div className={st.delete}>
                            <Basket onClick={deleteHandler}/>
                        </div>
                    </div>
                )
            case 'audio':
                return (
                    <div className={st.previewAudio}>
                        <audio controls src={src}>
                            Your browser does not support the <code>audio</code> element.
                        </audio>
                        <VariantDel onClick={deleteHandler}/>
                    </div>
                )
            case 'video':
                return (
                    <div className={st.preview}>
                        <div><VideoThumbnail videoUrl={src}/></div>
                        <div className={st.delete}>
                            <Basket onClick={deleteHandler}/>
                        </div>
                    </div>
                )
          default:
            return null
        }
    }

    useEffect(() => {
        const src = file ? URL.createObjectURL(file) : Stores.baseLink() + props.value
        setSrc(src)
    }, [file, props.value])

    return (
        <div className={props.error ? st.boxError : st.box}>
            <input type="file" ref={refInput} onChange={e => {setFile(e.target.files[0])}} accept={`${props.type}/*`}/>

            <button onClick={() => {refInput.current.click()}}>
                {localisationMaster('modal_doc_text_5', 'lessons/course')}
            </button>

            <div className={st.tip}>{localisationMaster('text_28', 'study/study-test', 'modal')}: <span>{props.types.join(', ')}</span></div>

            {file && progress < 100 &&
            <div className={st.progress}>
                <progress max="100" value={progress}/>
                <div>{progress} %</div>
            </div>}

            {(file || props.value) && renderPreview()}

        </div>
    );
};

MediaSelector.propTypes = {
    type: PropTypes.oneOf(['image', 'audio', 'video']).isRequired,
    value: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    onChange: PropTypes.func,
}

MediaSelector.defaultProps = {
    type: 'image'
}

export default MediaSelector
