import React from "react";
import st from "./CPTBooks.module.scss";

const brandBooks = [{
  name: "Family and Friends 1 for Grade 3 Kazakhstan Edition",
  folder: "English/Branded",
  links: [{
    name: "FAM AND FRNDS G3 CLB L1",
    size: 212838015,
    url: "4158619 FAM AND FRNDS G3 CLB L1 (KZ)(2).pdf",
  }, {
    name: "FAF KZ WB LV1 GR3",
    size: 29578396,
    url: "4158633 FAF KZ WB LV1 GR3.pdf",
  }, {
    name: "FAM AND FRNDS G3 TG L1 KZ",
    size: 17201483,
    url: "4158664 FAM AND FRNDS G3 TG L1 KZ.pdf",
  }]
}, {
  name: "Les Loustics 1 Édition pour le Kazakhstan",
  folder: "French/Branded",
  links: [{
    name: "Class Book",
    size: 1002258885,
    url: "ClassBook.pdf"
  }, {
    name: "Student Book",
    size: 862533382,
    url: "StudenBook.pdf"
  }, {
    name: "Teacher Book",
    size: 24503521,
    url: "TeacherBook.pdf"
  }]
}, {
  name: "Die Deutschprofis A1.1",
  folder: "German/Branded",
  links: [{
    name: "Course Book D",
    size: 481484136,
    url: "CourseBook-D.pdf"
  }, {
    name: "Exercise Book D",
    size: 81190824,
    url: "EcxerciseBook-D.pdf"
  }, {
    name: "Teacher Book D",
    size: 11187464,
    url: "TeacherBook_D.pdf"
  }]
}]

const nonBrandBooks = [{
  name: "Gateway Gold  Grade 3 Level 1",
  folder: "English_2/NON Branded",
  links: [{
    name: "Activity Book Grade 3 Level 1",
    size: 2934399,
    url: "KAZAKH Grade 3_NON BRAND_AB_PRINT.pdf"
  }, {
    name: "Student’s Book Grade 3 Level 1",
    size: 55126067,
    url: "KAZAKH Grade 3_NON BRAND_SB_PRINT.pdf"
  }, {
    name: "Teacher’s book Grade 3 Level 1",
    size: 1754653,
    url: "KAZAKH Grade 3_NON BRAND_TB_PRINT.pdf"
  }]
}]

const sizeExt = ['bytes', 'KB', 'MB']

const getSize = size => {
  let i = 0;
  while (size > 999){
    size /= 1024;
    i++
  }
  return `${(Math.round(size*10)/10).toString().replace('.', ',')} ${sizeExt[i]}`
}

const CPTBooks = props => {
  const logo = props.match.params.logo

  return (
    <div className={st.page}>
      <div className={`${st.logo} ${logo === 's' ? st.logoHidden : ''}`}>
        <img src="https://phoenix.interpress.kz/media/uploads/interpress.png" alt=""/>
        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
      </div>
      <div className={st.books}>{(logo === 's' ? nonBrandBooks : brandBooks).map((book, idx) =>
        <div key={idx}>
          <h3>{book.name}</h3>
          <ul>{book.links.map((link, idx) =>
            <li key={idx}>
              <a href={`https://phoenix24.kz/media/uploads/Books/Books/${book.folder}/${link.url}`} target="_blank">
                <b>{link.name}</b><span> ({getSize(link.size)})</span>
              </a>
            </li>)}
          </ul>
        </div>)}
      </div>
    </div>
  )
}

export default CPTBooks;
