import React, {useState} from 'react';
import useFileUpload from "hooks/useFileUpload";
import styles from "./PdfField.module.scss"


const PdfField = ({onChange}) => {
    const {hostname, protocol} = window.location;
    const [path, setPath] = useState()
    const [error, setError] = useState(false)
    const onUploadEnd = path => {
        setPath(path)
        onChange(path, file.name)
    }

    const {refInput, file, setFile} = useFileUpload({url: 'files/file_upload', onUploadEnd})


    return (
        <div className={styles.box}>
            {path ? <div className="">Выбран файл: <a target="_blank" href={`${hostname.includes('localhost') ? 'https://dev.phoenix24.kz' : `${protocol}//${hostname}`}${path}`}>{file.name}</a></div> :
                <div className="">{ error ? <span style = {{color: 'red'}}>Неверный формат файла</span> : 'Файл не выбран'}</div>}
            <label  >
                <input accept = 'application/pdf' style={{display: 'none'}} type="file" ref={refInput} onChange={e => {
                    if (e.target.files[0].type === 'application/pdf') {
                        setError(false)
                        setFile(e.target.files[0])    
                    }
                    else {
                        setError(true)
                    }
                }} />
                <span className={styles.btn}> {file ? 'Выбрать другой PDF' : 'Загрузить PDF'}</span>
            </label>
        </div>

    )
}

export default PdfField

