import React from 'react';
import styles from './BirthdayInfo.module.scss';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {NavLink} from 'react-router-dom';
import bcake from "components/ContentBox/img/birthday-cake.svg";
import {ageToStr} from '../../Birthday';

const BirthdayInfo = ({
    date,
    isToday,
    employees
}) => {

    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <h3 className={styles.title}>{timeConverter(date, 'day')}</h3>
                {isToday && <div className="">сегодня</div>}
            </div>
            {employees ?
                <div className={styles.body}>
                    <ul className={styles.list} >
                        {employees.map((employee, idx) =>
                            <li className={styles.row} key={idx}>
                                <div className={styles.dateBox}>  <img className={styles.icon} src={bcake} alt="" /> <span>{ageToStr(employee.date)}</span></div>
                                <NavLink className={styles.link} to={'/account/' + employee.user_id} target="_blank">
                                    {employee.full_name}
                                </NavLink>
                                <div className="">({timeConverter(employee.date, 'age')})</div>
                            </li>)}
                    </ul>
                </div>
                :
                <div className="">Нет дней рождения</div>
            }
        </div>
    );
}

export default BirthdayInfo;
