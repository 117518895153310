import React from 'react';
import PropTypes from 'prop-types';
import loaderLogo from './loader.gif';
import { classNames as cn } from 'helpers';
import styles from './Loader.module.scss';

const Loader = ({fullWidth, withOverlay, classes}) => (
  <div className={cn({
    [styles.wrapper]: true,
    [styles.w100]: fullWidth,
    [styles.overlay]: withOverlay,
    [classes]: classes,
  })}>
    <img
      className={styles.loader_logo}
      src={loaderLogo}
      alt="loader"
    />
  </div>
);

Loader.propTypes = {
  fullWidth: PropTypes.bool,
  withOverlay: PropTypes.bool,
};

Loader.defaultProps = {
  fullWidth: false,
  withOverlay: false,
};

export default Loader;
