import React, {useState} from "react";
import PropTypes from "prop-types";
import st from "./StudentSelector.module.scss";
import {ReactComponent as ParentIcon} from "components/UI/icons/parent-line.svg";

const StudentSelector = ({students, student, onChange, translate}) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={st.studentSelector}>
      <span className={st.chooseStudent}>{student ? student.full_name : translate?.choose_student}</span>
      <button className={`${st.button} ${student ? st.buttonSelected : ''}`} onClick={() => setOpen(!open)}><ParentIcon/></button>
      {open &&
        <>
          <div className={st.popupClose} onClick={() => setOpen(false)}/>
          <div className={st.popup}>{students.map(item =>
            <div key={item.id}
                 className={`${st.student} ${item.id === student?.id ? st.studentSelected : ''}`}
                 onClick={() => {
                   onChange(item)
                   setOpen(false)
                 }}
            >
              {`${item.last_name} ${item.first_name[0]}.`}
            </div>)}
          </div>
        </>}
    </div>
  )
}

StudentSelector.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
  student: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default StudentSelector
