import React from 'react';
import PropTypes from "prop-types";
import VariantAdd from "../../../StudyTest/TestQuestion/components/VariantAdd";
import TestQuestion from "./TestQuestion";
import st from './TestQuestions.module.scss';

const TestQuestions = (props) => {
    const remove = idx => {
        props.onChange(props.questions.filter((v, i) => i !== idx), 'data')
    }

  return (
    <>
      {props.questions.map((q, idx) =>
        <TestQuestion question={q}
                      translate={props.translate}
                      onChange={val => props.onChange(props.questions.map((q, i) => i === idx ? val : q))}
                      pos={idx + 1}
                      isPoint={props.isPoint}
                      key={idx}
                      isError={props.isError}
                      questions={props.questions}
                      delete={() => remove(idx)}
        />)}

      <VariantAdd onClick={() => {
        props.onChange([...props.questions, {text: '', points: '', variants: [{id: 1, text: '', is_correct: false}]}])
      }}/>
      {props.isPoint &&
        <div className={st.result}>
          <span>{props.translate.total_points}</span>
          <b>{props.questions.reduce((prev, cur) => +cur.points + prev , 0)}</b>
        </div>}
    </>
  )
}

TestQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  isPoint: PropTypes.bool,
}

export default TestQuestions;
