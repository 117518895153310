import React, {useEffect, useRef, useState} from 'react';
import Stores from 'Stores';
import { correctStyleLessons } from './edit';

export default function QuestionMatchWords(props) {

    let clicked

    const [result, setResult] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    const onClickLeftHandler = (e, index) => {
        clicked = index
        e.target.classList.add("clicked")
    }
    
    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        (props.item.word_list_1 &&
        setAnswersButton(result.length === props.item.word_list_1.length))
        props.handleAnswers(result, answersButton)
    }, [result, answersButton])

    useEffect(() => {
        if(props.answers.length === result.length) return
        setResult([])
    }, [props.answers])

    const onClickRightHandler = (e, index) => {
        if (clicked !== undefined) {
            let arr = result.slice()
            arr[clicked] = [props.item.word_list_1[clicked], props.item.word_list_2[index]].join('~~~')
            setResult(arr)

            e.target.classList.add("clicked")

            const leftBlock = refLeft.current.children[clicked]
            const from = {
                x: leftBlock.offsetLeft + leftBlock.offsetWidth,
                y: leftBlock.offsetTop + leftBlock.offsetHeight / 2
            }
            const rightBlock = refRight.current.children[index]
            const to = {
                x: rightBlock.offsetLeft,
                y: rightBlock.offsetTop + rightBlock.offsetHeight / 2
            }

            const svg = refLines.current.children[clicked]
            const path = svg.firstChild

            path.setAttribute('d', `M${from.x},${from.y} L${to.x},${to.y}`)
        }
    }

    const drawLines = (index) => {
        
        const leftBlock = refLeft.current.children[index]
        const from = {
            x: leftBlock.offsetLeft + leftBlock.offsetWidth,
            y: leftBlock.offsetTop + leftBlock.offsetHeight / 2
        }
        const rightBlock = refRight.current.children[index]
        const to = {
            x: rightBlock.offsetLeft,
            y: rightBlock.offsetTop + rightBlock.offsetHeight / 2
        }
        
        //const svg = refLines.current.children[index]
        //const path = svg.firstChild

        return <path d={`M${from.x},${from.y} L${to.x},${to.y}`}/>
    }


    useEffect(() => {
        setResult([])
        for (let svg of refLines.current.children) {
            svg.firstChild.setAttribute('d', 'M0,0 L0,0')
        }
    }, [Stores.reload])

    const refLeft = useRef()
    const refRight = useRef()
    const refLines = useRef()
    
    return (
        <div className="questionMatchWords">
            {((props.is_clean_ex || (props.is_teacher_view && (!props.at))) ??
            props.item) ?
            <>
            <div ref={refLeft} className="questionMatchWords__col">
                {props.item.word_list_1.map((word, index) =>
                    <span 
                        key={index} 
                        className="questionMatchWords__word" 
                        onClick={e => {onClickLeftHandler(e, index)}}>
                            {word}
                    </span>
                )}
            </div>
            <div ref={refRight} className="questionMatchWords__col">
                {props.item.word_list_2.map((word, index) =>
                    <span 
                        key={index} 
                        className="questionMatchWords__word" 
                        onClick={e => {onClickRightHandler(e, index)}}>
                            {word}
                    </span>
                )}
            </div>
            <div ref={refLines}>
                {props.item.word_list_1.map((word, index) =>
                    <svg key={index} preserveAspectRatio="none">
                        <path d="M0,0 L0,0" />
                    </svg>
                )}
            </div>
            </> :
            null
            }    
            {((props.is_student_checked_try || (props.is_teacher_view && props.at)) &&
            props.at) ?
            <>
            <div ref={refLeft} className="questionMatchWords__col">
                {props.answ_array.map((word, index) =>
                    <span 
                        key={index} 
                        className={`questionMatchWords__word ${correctStyleLessons(props.correct_answ[index])}`}>
                            {(word.split('~~~'))[0]}
                    </span>
                )}
            </div>
            <div ref={refRight} className="questionMatchWords__col">
                {props.answ_array.map((word, index) =>
                    <span 
                        key={index} 
                        className={`questionMatchWords__word ${correctStyleLessons(props.correct_answ[index])}`}>
                            {(word.split('~~~'))[1]}
                    </span>
                )}
            </div>
            <div ref={refLines}>
                {props.answ_array.map((word, index) =>
                    <svg key={index} preserveAspectRatio="none">
                        {drawLines(index)}
                    </svg>
                )}
            </div>
            </> :
            null
            }
        </div>
    );
};