import React from 'react';
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";
import StudyStore from "Stores/studyStore";
import TestCategory from "./TestCategory/TestCategory";
import {observer} from "mobx-react";
import st from './TestCategories.module.scss'

const TestCategories = ({filterTestQuestions}) => {
    const {testSections, testQuestions, addSection} = StudyStore


    const noCategoryQuestions = testQuestions.filter(question => !question.test_section).length


    const mappedTestCategories = () => {
        return testSections.map(section => {
            const questionsFilteredBySection = testQuestions.filter(question => question?.test_section?.id === section.id).length
            return <TestCategory key={section.id}
                                 filterTestQuestions={filterTestQuestions}
                                 sectionName={section.name}
                                 sectionId={section.id}
                                 percentage={section.percentage}
                                 questionsCount={questionsFilteredBySection}
            />
        })
    }

    return (
        <div className={st.wrapper}>
            <div className={st.row}>
                <div>{testSections.length ? `Categories: ${testSections.length}` : 'No Category'}</div>
                <button className={st.btn} onClick={addSection}>
                    <PlusIcon/>
                </button>
            </div>
            {
                testSections.length ?
                    <>
                        {mappedTestCategories()}
                        {noCategoryQuestions > 0 &&
                            <TestCategory
                                filterTestQuestions={filterTestQuestions}
                                sectionName={'No Category'}
                                sectionId={null}
                                percentage={(noCategoryQuestions / testQuestions.length) * 100}
                                questionsCount={noCategoryQuestions}
                            />}
                    </>
                    :
                    <div className={st.empty}>The categories of questions will help separate the questions.</div>
            }
        </div>
    );
};

export default observer(TestCategories);