import React from "react";
import {Link} from "react-router-dom";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import {SuperButton} from "components/UI/SuperButton/SuperButton";
import st from "./PastLesson.module.scss";
import useLocalization from "hooks/useLocalization";
import swal from "sweetalert";


const PastLesson = ({lesson: {group, is_finish, ...lesson}}) => {
  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'ebooks_past_lessons',
    dict: {
      "finished": "Finished",
      "in_progress": "In Progress", 
      "lesson_history": "Lesson History", 
      "go_to_lesson": "Go to Lesson", 
      "homework": "Homework",
      "copy": "Copy",
      "link_copied":"Link was successfully copied"

    }
  })

  const isClient = Stores.isClient

   const handleCopyLink = () => {
     const linkToCopy = `${Stores.baseLink()}/ebooks/${isClient ? 'my-' : ''}homework/${lesson.homework_hash}`
     navigator.clipboard.writeText(linkToCopy).then(() => {
       swal(
           translate.link_copied,
           {icon: "success"}
       );
     })

   }


  return (
    <div className={st.pastLesson}>
      <div className={st.groupCourse}>
        <span className={st.group}>{group.number} - {group.name}</span>
        <Link to={`/ebooks/chapter/${lesson.chapter.id}`}>{lesson.ebook.name} - {lesson.chapter.name}</Link>
      </div>
      <div className={st.timeStatus}>
        <time>
          <b>{dateFormat(new Date(lesson.start_date), "hh:ii")}</b>,&nbsp;
          {dateFormat(new Date(lesson.start_date), "dd MMM YYYY")}
        </time>
        <time>
          <b>{dateFormat(new Date(lesson.end_date), "hh:ii")}</b>,&nbsp;
          {dateFormat(new Date(lesson.end_date), "dd MMM YYYY")}
        </time>
        <div className={st.status}>
          <SuperButton variant="badge" type={!is_finish ? 'badgeColored' : undefined}>
            {is_finish ? translate.finished : translate.in_progress}
          </SuperButton>
        </div>
      </div>
      <div className={st.buttons}>
        <SuperButton variant="link" type={is_finish ? undefined : 'linkColored'}
                     path={`/ebooks/${isClient ? 'my-' : ''}${is_finish ? 'past-' : ''}lesson/${lesson.uuid}`}>
          {is_finish ? translate.lesson_history : translate.go_to_lesson}
        </SuperButton>
        <SuperButton variant="link" type="linkColored"
                     path={`/ebooks/${isClient ? 'my-' : ''}homework/${lesson.homework_hash}`}>
          {translate.homework}
        </SuperButton>
        {!Stores.isClient &&
        <button type="button"
                className={`${st.link} ${st.linkColored}`}
                onClick={handleCopyLink}>
          {translate.copy}
        </button>
        }
      </div>
    </div>
  )
}

export default PastLesson;
