import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import SortingBlock from 'components/Modules/Slots/SlotsListing/components/SortingBlock/SortingBlock';
import ClientsList from 'components/Modules/Slots/SlotsListing/components/ClientsList/ClientsList';
import Loader from 'components/UI/Loader/Loader';
import ContentPagination from 'components/ContentBox/Global/ContentPagination/ContentPagination';
import slotsListingStore, {sortingOptions} from 'Stores/slotsListingStore';
import Stores from 'Stores';
import {localisationMaster} from "Localisation/Localisation";
import Filter from 'components/ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {superAxiosRequest} from "axiosApi";


function SlotListing() {
  const { id: pageType } = useParams();
  const {levels, totalClientsCount, sortingType, fetchClients, fetchLevels} = slotsListingStore;
  const {isClient} = Stores;
  const [isLoading, setIsLoading] = useState(true);
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filterParams, setFilter] = useState()
  const [filterData, setFilterData] = useState()

  const filterCnf = [
    {
      type: 'input',
      data: '',
      placeholder: 'Имя клиента',
      nameState: 'name'
    },
    {
        type: 'month-range-picker',
        data: {
            date_from: '',
            date_until: ''
        },
        nameState: ['date_from', 'date_until']
    },
    {
        type: 'select-custom',
        placeholder: 'Локация',
        values: filterData?.locations,
        sort_key: 'name',
        nameState: 'location',
    },
]

  useEffect(() => {
      if (!isLoading) {
        setIsLoading(true);
      }
    const { ordered_by, reverse } = sortingOptions.find((item) => item.value === sortingType);
    fetchClients(
      {
        is_level: pageType === 'past',
        offset: clientsPerPage * (page - 1),
        limit: clientsPerPage,
        ordered_by,
        reverse,
        ...filterParams
      },
      () => setIsLoading(false),
    );
  }, [clientsPerPage, page, pageType, sortingType, totalClientsCount, filterParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (levels.length > 0 || isClient) {
      return;
    }
    fetchLevels();
    superAxiosRequest({
      method: 'get',
      link: 'groups/student_status_changes'
  }).then (({data}) => setFilterData(data))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeFilterParams = (e) => {
    let params = {...e} 
    if(e.date_from)  params.date_from = dateFormat(e.date_from, "YYYY-mm-dd")
    if(e.date_until)  params.date_until = dateFormat(e.date_until, "YYYY-mm-dd")
    setFilter(params)
  }

  return (
    <>
      <ContentHeader
        title={pageType === 'future' ? localisationMaster('text_32', 'online-schedule/future-slots') : localisationMaster('text_33', 'online-schedule/future-slots')}
      />
      <Filter config={filterCnf} getFilterParams={e => changeFilterParams(e)} drop={ ()=> setFilter() } thisPage={null} />
      {isLoading
        ? <Loader />
        : (
          <>     
            <SortingBlock />
            <ClientsList />
            <ContentPagination
              page={page}
              allItems={totalClientsCount}
              per_page={clientsPerPage}
              changePage={(value) => setPage(value)}
              perPage={(value) => setClientsPerPage(value)}
            />
          </>
        )
      }
    </>
  );
}

export default observer(SlotListing);
