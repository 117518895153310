import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import styles from './Tab.module.scss';

function Tab({id, value, text, onClick, isActive}) {
  return (
    <li className={styles.tab}>
      <button
        data-id={id}
        type='button'
        className={cn({
          [styles.button]: true,
          [styles.button__active]: isActive,
        })}
        name={value}
        onClick={onClick}
      >
        {text}
      </button>
    </li>
  );
}

Tab.propTypes = {
  id: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

Tab.defaultProps = {
  id: null,
};

export default Tab;
