import React, { useState, useEffect } from "react";
import ActiveField from "./ActiveField/ActiveField";
import { localisationMaster } from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import { superAxiosRequest } from "axiosApi";
import Error from "../Error/Error";
import swal from "sweetalert";
import { observer } from "mobx-react";
import "./style.scss";

const Fields = ({ form, setForm, validate, fieldsRef }) => {
  const [required, setRequired] = useState([]);
  const [locations, setLocations] = useState([false, []]);

  useEffect(() => {
    superAxiosRequest({
      method: "get",
      link: "db/locations",
    })
      .then((res) => {
        setLocations([true, res.data.result]);
      })
      .catch(() => [false, []]);
  }, []);

  useEffect(() => {
    const validFields = form.active_fields
      .filter((item) => {
        if (item.placeholder.length < 2 && item.selected) return item;
        if (
          // если поля agreement и locations активны то проверяем в них данные
          (item.name === "agreement" &&
            item.selected &&
            item.data.length === 0) ||
          (item.name === "locations" && item.selected && item.data.length === 0)
        )
          return item;
      })
      .map((item) => item.title);
    setRequired(validFields);
  }, [form.active_fields]);

  const changeField = (index, el) => {
    const fields = [...form.active_fields];
    fields[index] = el;
    setForm({ ...form, active_fields: fields });
  };

  const openModal = (element) => {
    if (!element.selected) {
      swal({
        title: "Это поле отключено",
        icon: "warning",
      });
    } else {
      modalStore.activity(
        {
          name: "active_fields",
          title: `${localisationMaster(
            "text_13",
            "c/tests/placement-test/create-widget"
          )}: ${element.title}`,
          button: localisationMaster("text_2", "company/locations"),
          type: "change_active_fields",
          func: (result) => {
            const index = form.active_fields.findIndex(
              (item) => item.name === result.name
            );
            changeField(index, result);
          },
        },
        element
      );
    }
  };

  const toggleField = (index, el) => {
    changeField(index, { ...el, selected: !el.selected });
  };

  return (
    <div className="placementTestBox" ref={fieldsRef}>
      <div className="placementTestCreate__fields">
        <div className="placementTestBox__mainBoxLine">
          <p className="placementTestBox__title">
            {localisationMaster(
              "text_8",
              "c/tests/placement-test/create-widget"
            )}
          </p>
          <p className="placementTestBox__text">
            {localisationMaster(
              "text_9",
              "c/tests/placement-test/create-widget"
            )}
          </p>
        </div>
        <div className="placementTestBox__mainBoxLine">
          {validate("active_fields") && required.length ? (
            <Error
              text={`${localisationMaster(
                "text_40",
                "c/tests/placement-test/create-widget"
              )}: ${required.join(", ")}`}
            />
          ) : null}
        </div>
        <div className="placementTestBox__list">
          {form.active_fields.map((el, index) => {
            return (
              <ActiveField
                key={index}
                el={el}
                index={index}
                toggleField={toggleField}
                openModal={openModal}
                changeField={changeField}
                locations={locations}
              />
            );
          })}
          <div className="placementTestBox__safeUser">
            <p className="super-modal__form-label">
              {localisationMaster(
                "text_37",
                "c/tests/placement-test/create-widget"
              )}
              :
            </p>
            <input
              type="text"
              className="placementTestBox__input"
              value={form?.save_client_data_button_title || ""}
              onChange={(e) =>
                setForm({
                  ...form,
                  save_client_data_button_title: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Fields);
