import React from 'react';
import {observer} from 'mobx-react';
import {translate as loc, dict} from "Localisation";
import modalStore from 'Stores/modalStore';
import MainSelector from '../MainSelector';
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import threeImg from 'components/ContentBox/img/agenda.svg'

const hourTypeOptions = [
  {id: 45, name: loc(dict.main_page.text_8)},
  {id: 60, name: loc(dict.main_page.text_9)}
]

const ContractPlans = () => {
  const {modalValues, changeValues} = modalStore

  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-field">
        <p className="super-modal__form-label">{loc(dict.main_page.text_10)}</p>
        <div>
          <MainSelector image={threeImg}>
            <div className="super-modal__form-column">
              <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                  <p className="super-modal__form-label">{loc(dict.main_page.text_4)}:</p>
                  <DummyCounter name="test_plan_mins"
                                value={modalValues.test_plan_mins / modalValues.hour_type || 0}
                                onChange={(val, key) => changeValues(val * modalValues.hour_type, key)}
                                min={.5}
                                step={.5}/>
                </div>
                <div className="super-modal__form-field">
                  <p className="super-modal__form-label">{loc(dict.main_page.text_5)}:</p>
                  <SelectCustom
                    placeholder={loc(dict.main_page.text_5)}
                    options={hourTypeOptions}
                    valueID={modalValues.hour_type}
                    onChange={o => changeValues(o.id, 'hour_type')}
                  />
                </div>
              </div>
              <div><p className="modalEmployerContract__link">{loc(dict.main_page.text_6)}</p></div>
            </div>
          </MainSelector>
        </div>
      </div>
      <div className="super-modal__form-field">
        <p className="super-modal__form-label">{loc(dict.main_page.text_10_1)}</p>
        <div>
          <MainSelector image={threeImg}>
            <div className="super-modal__form-column">
              <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                  <p className="super-modal__form-label">{loc(dict.main_page.text_4)}:</p>
                  <DummyCounter name="teaching_plan_mins"
                                value={modalValues.teaching_plan_mins / modalValues.teaching_plan_hour_type || 0}
                                onChange={(val, key) => changeValues(val * modalValues.teaching_plan_hour_type, key)}
                                min={.5}
                                step={.5}/>
                </div>
                <div className="super-modal__form-field">
                  <p className="super-modal__form-label">{loc(dict.main_page.text_5)}:</p>
                  <SelectCustom
                    placeholder={loc(dict.main_page.text_5)}
                    options={hourTypeOptions}
                    valueID={modalValues.teaching_plan_hour_type}
                    onChange={o => changeValues(o.id, 'teaching_plan_hour_type')}
                  />
                </div>
              </div>
              <div><p className="modalEmployerContract__link">{loc(dict.main_page.text_6_1)}</p></div>
            </div>
          </MainSelector>
        </div>
      </div>
    </div>
  )
}

export default observer(ContractPlans)
