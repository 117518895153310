import React from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import { routes } from "Router/routes";
import Sidebar from "./Sidebar/Sidebar";
import Stores from "Stores";
import modalStore from "Stores/modalStore";
import asideModalStore from "Stores/asideModalStore";
import Hello from "components/ContentBox/Global/Hello.jsx";
import SuperModal from "./Global/GlobalModalSingle/SuperModal";
import GlobalSuperModal from "./Global/GlobalModalSingle/GlobalSuperModal";
import AsideModal from "../UI/AsideModal/AsideModal";
import PrivateRoute from "Router/PrivateRoute";
import Footer from "./Global/Footer/Footer";
import "components/ContentBox/ContentBox.scss";
import "components/ContentBox/content.css";
import st from "./ContentBox.module.scss";
import HeadBox from 'components/HeadBox/HeadBox'


const ContentBox = props => {
  const wrapperRender = ({wrapper, ...route}) => {
    let page
    switch (wrapper) {
      case 'InitLessonWrapper':
        page = <main className={st.mainInitLesson}>
          {asideModalStore.open ? <AsideModal /> : null}
          <Route path={route.path} component={route.component} />
        </main>
        break;
      default:
        page = <>
        <HeadBox />
        <div className={st.contentBoxWrapper}>
          <Sidebar />
          <>
            {/*Stores.ModalSingle.open ? <ModalSingle /> : null*/}
            {Stores.SuperModal.open ? <SuperModal /> : null}
            {modalStore.open ? <GlobalSuperModal /> : null}
            {asideModalStore.open ? <AsideModal /> : null}
            <main className={st.main}>
              <div>
                <Switch>
                  <Route path='/' exact component={Hello} />
                  {routes.private.map((el, idx) => <PrivateRoute {...el} key={idx} />)}
                </Switch>
              </div>
              <Footer />
            </main>
          </>
        </div>
      </>
        break;
    }

    return page
  }

  return wrapperRender(props)
}

export default observer(ContentBox);
