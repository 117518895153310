import React, {useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {ExcelRowsParams} from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";


const SubscriptionsSold = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();

    const filterCnf = [
        {
            type: 'days-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
    ]

    const cols = [
        {name: 'Абонемент'},
        {name: 'Стоимость', rowSpan: 3},
        {name: 'Кол-во', rowSpan: 3},
        {name: 'Итого', rowSpan: 3},
    ];

    const parseData = (res) => {
        let newState = []
        res.forEach((item => {
            let newItem = {
                ...item,
                sum: (item.sum / 100).toFixed(),
                total: (item.total / 100).toFixed(),
            }
            newState.push(newItem)
        }))
        return newState
    }

    const sendRequest = (params) => {
        setIsLoading(true)
        let apiConf = {
            method: 'get',
            params: {
                ...params
            }
        }
        if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
        if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")
        superAxiosRequest({...apiConf, link: 'unidance/subscription_price'}).then(res => {
            setData(parseData(res.data.result))
            setIsLoading(false)
        })
    }
    const getData = () => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'unidance/subscription_price',
            params: {
                date_from: dateFormat(new Date(new Date().setDate(1)), "YYYY-mm-dd")
            }
        })
            .then((res) => {
                setData(parseData(res.data.result))
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Абонемент",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Стоимость",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Кол-во",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Итого",
                    ...ExcelRowsParams.headerRowParam
                },
            ]
            let footerRow = [
            ]
            let tableData = []

            let merges = []
            let wscols = [
                {wch: 40},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];
            let rows = [
                {hpx: 30},
            ]

            data.forEach(item => {
                tableData.push([
                    {
                        v: `${item.name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${item.sum}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.count_pay}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.total}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Проданные абонементы.xlsx");
        })
    }

    return (
        <div>
            <ContentHeader title="Проданные абонементы" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => getData()} />

            {isLoading
                ? <Loader />
                : Array.isArray(data) && data.map(el => el.dates).flat().length === 0 ? <h1 style={{textAlign: 'center'}}>Нет данных для отображения!</h1>
                    : (
                        <>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            <>
                                                {data.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={st.fw600} style={{background: "white"}}>
                                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                                {row.name}
                                                            </div>
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.sum}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.count_pay}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.total}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{padding: '10px'}}>
                                <ExportExcelButton data={data} downloadExcel={downloadExcel} />
                            </div>
                        </>
                    )}
        </div >
    )
}

export default SubscriptionsSold