import React, {useState} from 'react';
import groupStore from "Stores/groupStore";
import style from './MainInfo.module.scss'

export default function GroupsHours(props) {
    const {group} = groupStore
    const [hourType, setHourType] = useState(45)

    const getHoursTotal = () => {
        if (isNaN(group.workshops.total)) return 0
        const frac = group.workshops.total / hourType === parseInt(group.workshops.total / hourType) ? 0 : 2
        return (group.workshops.total / hourType).toFixed(frac).replace('.', ',')
    }

    const getHoursSpent = () => {
        if (isNaN(group.workshops.spent)) return 0
            const frac = group.workshops.spent / hourType === parseInt(group.workshops.spent / hourType) ? 0 : 2
            return (group.workshops.spent / hourType).toFixed(frac).replace('.', ',')
    }

    return (
        <div className={`${style.groupSimpleMainBox__card}  ${style.groupSimpleBox} ${style.hours}`}
             onClick={() => {
                 setHourType(hourType === 45 ? 60 : 45)
             }}>
            <div className={style.groupSimpleBox__container}>
                <div className={style.groupSimpleBox__container__value}>
                    <span>{getHoursTotal()}</span>
                    <span className={style.delimiter}></span>
                    <span>{getHoursSpent()}</span>
                </div>
                <div className={style.progress}>
                    <div className={style.progress__completed} style={{width: `${props.getPercent(getHoursTotal(), getHoursSpent())}%`}}></div>
                    <div className={style.progress__remaind}
                         style={{width: `${100 - props.getPercent(getHoursTotal(), getHoursSpent())}%`, background: "red"}}></div>
                </div>
                <div className={style.groupSimpleBox__container__valueName}>
                    <div className={style.groupSimpleBox__container__valueName__optionValue}>
                        <span>
                            {
                                hourType === 45 ?
                                    props.translate.academic_hours :
                                    props.translate.astronomical_hours
                            }
                        </span>
                    </div>
                    <div>
                        <span>{props.translate.total}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.spent}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
