import React from 'react';
import PropTypes from "prop-types"
import st from "./Document.module.scss"
import documentImg from "components/ContentBox/img/new_document_img.svg"
import {dateFormat} from "helpers/date";


const Document = ({data}) => {
    function getFileExtensionFromPath(filePath) {
        return filePath.split('/').pop().split('.').pop() || '';
    }

    const deleteFileExtension = (fileName) => {
        return fileName.replace(/\.[^.]+$/, "");
    }

    return (
        <div className={data?.comment != null ? st.book : st.bookWithoutComment}>
            <div className={st.documentImg}>
                <a href={data.file} target="_blank">
                    <div className={st.imgLink}>
                        <img className={st.img} src={documentImg} alt=""/>
                        <div className={st.imgText}>{getFileExtensionFromPath(data.file)} </div>
                    </div>
                </a>
            </div>

            <div className={st.body}>
                <div className={st.info}>
                    <div className={st.name}>{deleteFileExtension(data.name)}</div>
                    <div>
                        <b>{dateFormat(new Date(data.uploaded_at), 'hh:ii')},</b> {dateFormat(new Date(data.uploaded_at), 'dd MMM YYYY')}
                    </div>
                </div>
                <div className={st.textBody}>
                    {data?.comment}
                </div>
            </div>

        </div>
    );
}

Document.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Document
