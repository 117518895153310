import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {observer} from "mobx-react";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import CounterOnline from "components/ContentBox/Global/Counter/CounterOnline/CounterOnline";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import CounterInput from "components/UI/CounterInput/CounterInput";
import useLocalization from "hooks/useLocalization";
import st from "./GroupsForm.module.scss";
import {ReactComponent as GroupStatusIcon} from "components/UI/icons/group-status-icon.svg";


export const formatOptionStatus = opt =>
  <span className={`${st.optionsStatus} ${st[`optionsStatus--${opt.id}`]}`}><GroupStatusIcon/>{opt.label}</span>

const GroupsForm = () => {
  const {modalValues, changeValue: changeValue, check} = asideModalStore
  const [catalog, setCatalog] = useState([false, []])

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_form_modal',
    dict: {
      "add_group": "Add Group",
      "add_new_group": "Add New Group",
      "company": "Company",
      "select_company": "Select Company",
      "location": "Location",
      "select_location": "Select Location",
      "group_number": "Group Number",
      "group_name": "Group Name",
      "arbitrary_group_name": "Arbitrary Group Name",
      "type_capacity": "Type & Capacity",
      "discipline": "Discipline",
      "select_discipline": "Select Discipline",
      "category": "Category",
      "select_category": "Select Category",
      "level": "Level",
      "select_level": "Select Level",
      "status": "Status",
      "select_status": "Select Status",
      "format": "Format",
      "offline": "Offline",
      "online": "Online",
      "cancel": "Cancel",
      "save": "Save"
    }
  })

  const typeValue = {
    "3": 13,
    "1": 6,
    "2": 1,
  }

  const onChangeType = (type) => {
    changeValue(type.id || type.value, 'type')
    changeValue(typeValue[type.id] || typeValue[type.value], 'size')
  }

  useEffect(() => {
    const apiConf = {
      method: 'get',
      link: 'groups/groups_catalog'
    }
    superAxiosRequest(apiConf).then(response => {
      setCatalog([true, response.data])
    }).catch(() => {
      setCatalog([true, []])
    })
  }, [])

  return (
    <div className={st.groupForm}>
      <div className={st.fieldSet}>
        <FormField>
          <FormLabel isRequired>{translate?.location}</FormLabel>
          <SelectCustom
            placeholder={translate?.select_location}
            options={catalog[1].locations?.items || []}
            sortKey="name"
            valueID={modalValues.location}
            onChange={o => changeValue(o.id, 'location')}
            isLoading={!catalog[0]}
            error={check('location')}
          />
        </FormField>
        <FormField>
          <FormLabel isRequired>
            {translate?.status}:
          </FormLabel>
          <SelectCustom
            placeholder={translate?.select_status}
            options={catalog[0] ? catalog[1].statuses.items : []}
            valueID={modalValues.status}
            onChange={o => changeValue(o.id, 'status')}
            isLoading={!catalog[0]}
            formatOptionLabel={formatOptionStatus}
            error={check('status')}
          />
        </FormField>

        <FormField>
          <FormLabel isRequired>
            {translate?.group_number}:
          </FormLabel>
          <FormInput isError={check('number')}>
            <input
              placeholder={translate?.group_number}
              type="text"
              value={modalValues.number || ''}
              onChange={e => changeValue(e.target.value, 'number')}
            />
          </FormInput>
        </FormField>
        <FormField>
          <FormLabel isRequired>
            {translate?.group_name}:
          </FormLabel>
          <FormInput isError={check('name')}>
            <input
              placeholder={translate?.arbitrary_group_name}
              type="text"
              value={modalValues.name || ''}
              onChange={e => changeValue(e.target.value, 'name')}
            />
          </FormInput>
        </FormField>

        <FormField>
          <FormLabel isRequired>
            {translate?.type_capacity}:
          </FormLabel>
          <div className={st.fieldTypeCapacity}>
            <SelectCustom
              options={catalog[0] ? catalog[1].types.items : []}
              valueID={modalValues.type}
              onChange={o => onChangeType(o)}
              error={check('type')}
            />
            <CounterInput
              value={modalValues.size || 0}
              min={1}
              max={modalValues.type === 2 ? 1 : 200}
              name="size"
              onChange={(e) => changeValue(e, 'size')}
            />
          </div>

        </FormField>
        <FormField>
          <FormLabel isRequired>
            {translate?.discipline}:
          </FormLabel>
          <SelectCustom
            placeholder={translate?.select_discipline}
            options={catalog[0] ? catalog[1].disciplines.items : []}
            sortKey="name"
            valueID={modalValues.discipline}
            onChange={o => changeValue(o.id, 'discipline')}
            isLoading={!catalog[0]}
            error={check('discipline')}
          />
        </FormField>

        <div className={st.fieldFormat}>
          <FormField>
            <FormLabel isRequired>
              {translate?.format}:
            </FormLabel>
            <CounterOnline
              name="online"
              value={modalValues.online}
              onChange={changeValue}
            />
          </FormField>
        </div>
      </div>
    </div>
  )
}

export default observer(GroupsForm)
