import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import LocalStorage from "services/LocalStorage";
import useLocalization from "hooks/useLocalization";
import {localisationMaster} from "Localisation/Localisation";
import {classNames as cn} from "helpers";
import Store from 'Stores';
import Checkbox from 'components/ContentBox/Global/Checkbox/Checkbox';
import AuthFooter from "../Common/AuthFooter";
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss";
import st from "./UserLogin.module.scss";
import srcStudying1 from "../img/studying-1.png";
import { ReactComponent as EyeOffLineIcon } from "../img/eye-off-line.svg";
import { ReactComponent as EyeLineIcon } from "../img/eye-line.svg";
import kyndelikSrc from "components/ContentBox/img/kyndelik.svg";


export default function UserLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [isEmailError, setIsEmailError] = useState(true);
  const [isPasswordError, setIsPasswordError] = useState(true);
  const [isCheck, setIsCheck] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isHidePassword, setIsHidePassword] = useState(true)

  const {translate} = useLocalization({
    section: 'user', subsection: 'login', dict: {
      "login": "Login",
      "sign_in": "Sign in",
      "password": "Password",
      "remember_me": "Remember me",
      "email_inn_iin": "E-mail / INN / IIN",
      "forgot_password": "Forgot Password?",
      "create_an_account": "Create an account",
      "dont_have_account": "Don’t you have an account?",
      "error": "Enter correct data"
    }
  })

  const checkLogin = () => {
    const emailReg = /^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i
    const innReg = /^\d{12}$/i

    if (emailReg.test(email) || innReg.test(email)) {
      setIsEmailError(false);
      return true;
    } else {
      setIsEmailError(true);
      return false;
    }
  }

  const checkPassword = () => {
    if (password.length < 8) {
      setIsPasswordError(true)
      return false;
    } else {
      setIsPasswordError(false)
      return true;
    }
  }

  const getTokens = user => {
    setLoading(true)

    Axios.post(Store.apiUrl + 'log_in/', user).then(response => {
      if (response.status === 200) {
        setError(false);
        let lifeTime = Math.floor(Date.now() / 1000)
        Store.AuthUsers.setIsAuth(true);
        Store.AuthUsers.createUserData(JSON.stringify(response.data));
        //localStorage.setItem('isAuth', true);
        LocalStorage.set('isAuth', true);
        //localStorage.setItem('access_token_death', lifeTime);
        LocalStorage.set('access_token_death', lifeTime);
        //localStorage.setItem('life_time', lifeTime);
        LocalStorage.set('life_time', lifeTime);
        //localStorage.setItem('access', response.data.access);
        LocalStorage.setSimple('access', response.data.access);
        //localStorage.setItem('refresh', response.data.refresh);
        LocalStorage.setSimple('refresh', response.data.refresh);
        //localStorage.setItem('ud', JSON.stringify(response.data))
        LocalStorage.set('ud', response.data)
        document.location.href = window.location.href
      }
    }).catch(err => {
      if (err && err.response.status === 401) {
        setIsCheck(true);
        setIsEmailError(true);
        setIsPasswordError(true);
      }
      if (err && err.response.status === 500) {
        setIsCheck(true);
        setError(true);
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const getAuth = e => {
    e.preventDefault();
    setIsCheck(true);

    if (checkLogin() && checkPassword())
      getTokens({email: email.toLocaleLowerCase(), password})
  }

  useEffect(() => {
    const referrer = document.referrer;
  


    // Шаг 2 и 3: Проверка источника и перенаправление
      if (referrer.includes( 'kundelik.kz' && 'interpress.kz')) {
        localStorage.setItem('originalPath', window.location.pathname)

      Axios.get(Store.apiUrl + 'kundelik/get_url')
      .then((res) => {
        if (res.data && res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((error) => {
        console.error('Ошибка при получении URL: ', error);
      });  
    }
  }, []);
  

  return (
    <>
      <article className={stWrapperAuth.content}>
        <div className={stWrapperAuth.authBox}>
          {/* <CompanyLogo/> */}

          <h1 className={cn({
            [stWrapperAuth.heading1]: true,
            [stWrapperAuth.heading1Loading]: loading,
          })}>
            {translate.login} <span role="img" aria-label="Login">🖐🏻</span>
          </h1>

          <form className={stWrapperAuth.form} onSubmit={getAuth}>

            <div className={st.kyndelik}>
              <div>
                <a href="https://login.kundelik.kz/login" className={st.kyndelikButton}>
                  <img src={kyndelikSrc} alt="kyndelik"/>
                </a>
              </div>
              <div className={st.kyndelikSeparator}>or</div>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.label}>{translate.email_inn_iin}:</div>
              <div>
                <input type="text"
                       name="email"
                       value={email}
                       onChange={e => setEmail(e.target.value.trim())}
                       className={cn({
                         [stWrapperAuth.input]: true,
                         [stWrapperAuth.inputError]: (isEmailError && isCheck),
                         [stWrapperAuth.inputSuccess]: (email.length > 0)
                       })}
                />
              </div>
            </div>

            <div className={stWrapperAuth.field}>
              <div className={stWrapperAuth.label}>{translate.password}:</div>
              <div className={stWrapperAuth.container}>
              <input
                type={isHidePassword ? "password" : "text"}
                value={password}
                className={cn({
                  [stWrapperAuth.input]: true,
                  [st.inputPassword]: true,
                  [stWrapperAuth.inputError]: (isPasswordError && isCheck),
                  [stWrapperAuth.inputSuccess]: (password.length > 0)
                })}
                onChange={e => {
                  setPassword(e.target.value.trim())
                }}
              />
                <div className={stWrapperAuth.eye} onClick={() => setIsHidePassword(!isHidePassword)}>
                  {isHidePassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                </div>
              </div>
            </div>

            {(error && isCheck) &&
              <div className={st.enterCorrectData}>
                <span>{localisationMaster('label-5', 'auth')}</span>
              </div>}

            {(isCheck && (isEmailError || isPasswordError)) &&
              <div className={st.enterCorrectData}>{translate.error}</div>}

            <div className={st.rememberMe}>
              <Checkbox
                checked={isRemember}
                label={translate.remember_me}
                onChange={() => setIsRemember(!isRemember)}
              />
              <a href="/restore-password">{translate.forgot_password}</a>
            </div>

            <div className={`${stWrapperAuth.submit} ${st.bottom}`}>
              <button>{translate.sign_in}</button>
              <div className={stWrapperAuth.haveAccount}>
                <p>{translate.dont_have_account} <a href="/signup">{translate.create_an_account}</a></p>
              </div>
            </div>

          </form>
        </div>
      </article>
      <AuthFooter srcPromo={srcStudying1}/>
    </>
  )
}
