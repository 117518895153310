import React, {useCallback, useEffect} from "react"
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import StudyStore from "Stores/studyStore";
import MainSelector from "../../MainSelector";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Variants from '../AddQuestionsTemplates/Variants';
import Textarea from 'components/ContentBox/Global/Textarea/Textarea';
import QuestionPoints from "./components/QuestionPoints";
import MediaSelector from "./components/MediaSelector";
import MatchWords from "./components/MatchWords";
import SortSentences from "./components/SortSentences";
import ImageVariants from "./components/ImageVariants/ImageVariants";
import MatchImages from "./components/MatchImages/MatchImages";
import st from './TestQuestion.module.scss'
import theme from "components/ContentBox/img/theme.svg";

export const questionsTypes = {
  TEXT: "question_plus_text", // Вопрос с текстовым ответом
  VARIANTS: "variants", // Вопрос с вариантами ответов
  COMPLETE_SENTENCE: "complete_sentence", // Собрать предложение
  MISSING_WORDS: "text_missing_words", // Выбрать пропущенные слова в тексте
  WORDS_IN_BLANKS: "words_in_blanks", // Вписать слова в пропусках
  DRAG_WORDS_IN_BLANKS: "drag_words_in_blanks", // Перетащить слова в пропуски
  AUDIO_PLUS_VARIANTS: "audio_plus_variants", // Аудиофайл с вариантами ответов
  VIDEO_PLUS_VARIANTS: "video_plus_variants", // Видеофайл с вариантами ответов
  WORD_MATCHING: "word_matching", // Сопоставление слов
  MAKE_WORD_FROM_LETTERS: "make_word_from_letters", // Составить слово из букв
  ESSAY: "essay", // Написать сочинение
  SORT_SENTENCES: "sort_sentences", // Сортировать предложения
  QUESTION_IMAGE_VARIANTS: "question_image_variants", // Вопрос с изображениями в качестве вариантов
  MATCH_WORDS_IMAGES: "match_words_images", // Сопоставить слова с изображениями
  IMAGE_MATCHING: "image_matching", // Сопоставить изображения
}

export const getUUID = () => Date.now().toString().split('').sort(() => .5 - Math.random()).join('')

export const getInitAnswer = type => {
  switch (type) {
    case questionsTypes.WORD_MATCHING:
      return {value: '|', is_correct: true}
    case questionsTypes.SORT_SENTENCES:
      return {value: '', is_correct: true}
    case questionsTypes.QUESTION_IMAGE_VARIANTS:
      return {value: '|', is_correct: false, uuid: getUUID()}
    case questionsTypes.MATCH_WORDS_IMAGES:
    case questionsTypes.IMAGE_MATCHING:
      return {value: '|', is_correct: true, uuid: getUUID()}
    default:
      return {}
  }
}

const TestQuestion = () => {
  const defaultValid = {
    type: 'required',
    text_question: 'required',
    point: 'required',
  }

  const {data: ms, modalValues, changeValues, changeValuesArray, setValid, check} = modalStore;
  const {testSections, questionTypesData} = StudyStore

  const changeTypeHandler = type => {
    const obj = {
      study_test: modalValues.study_test,
      type,
      text_question: '',
      answers: [],
      files: [],
      point: 1,
    }

    if ([questionsTypes.WORD_MATCHING, questionsTypes.SORT_SENTENCES, questionsTypes.QUESTION_IMAGE_VARIANTS,
      questionsTypes.MATCH_WORDS_IMAGES, questionsTypes.IMAGE_MATCHING].includes(type)) {
      obj.answers = [
        getInitAnswer(type),
        getInitAnswer(type),
      ]
    }

    changeValuesArray(obj)
  }

  const imageChangeHandler = file => {
    let files = modalValues.files.filter(x => !x.is_question)
    if (file)
      files = files.concat([{file, is_question: true}])
    changeValues(files, 'files')
  }

  const singleAnswer = value => {
    const answer = [{value, is_correct: true}]
    changeValues(answer, 'answers')
  }

  const mediaChangeHandler = file => {
    let files = modalValues.files.filter(x => x.is_question)
    if (file)
      files = files.concat([{file}])
    changeValues(files, 'files')
  }

  const getPointsLabel = useCallback(() => {
    const everyAnswer = [
      questionsTypes.MISSING_WORDS,
      questionsTypes.WORDS_IN_BLANKS,
      questionsTypes.DRAG_WORDS_IN_BLANKS,
      questionsTypes.WORD_MATCHING,
      questionsTypes.MATCH_WORDS_IMAGES,
      questionsTypes.IMAGE_MATCHING
    ]
    if (everyAnswer.includes(modalValues.type))
      return 'Количество баллов за каждый ответ'
    else
      return localisationMaster('points', 'groups/group')
  }, [modalValues.type])

  const renderBody = type => {
    switch (type) {
      case questionsTypes.TEXT:
        return (
          <div className="super-modal__form-field">
            <div className="super-modal-notice">
              <strong>{localisationMaster('text-info-one', 'tests/questions', 'question_plus_text')}</strong> {localisationMaster('text_35', 'study/study-test')}
            </div>
            <div>
              <textarea placeholder={localisationMaster('text-info-one', 'tests/questions', 'question_plus_text')} value={modalValues.answers[0]?.value || ''}
                        onChange={e => singleAnswer(e.target.value)}
                        className={check('answers') ? 'super-modal__input--error' : undefined}
              />
            </div>
          </div>
        )
      case questionsTypes.VARIANTS:
        return (
          <Variants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
        )
      case questionsTypes.COMPLETE_SENTENCE:
        return (
          <div className="super-modal__form-field">
            <div className={st.customTextareaWrap}>
              <Textarea
                placeholder={localisationMaster('text_36', 'study/study-test')}
                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                onChange={singleAnswer}
                error={check('answers')}
              />
            </div>
            <div className="super-modal-notice">
              <p>{localisationMaster('text_37', 'study/study-test')}</p>
              <br/>
              <p><strong>{localisationMaster('text_38', 'study/study-test')}:</strong></p>
              <p>We|moved|to|California|last|summer</p>
            </div>
          </div>
        )
      case questionsTypes.MISSING_WORDS:
        return (
          <div className="super-modal__form-field">
            <div className={st.customTextareaWrap}>
              <Textarea
                placeholder="Текст"
                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                onChange={singleAnswer}
                error={check('answers')}
              />
            </div>
            <div className="super-modal-notice">
              <p>{localisationMaster('text_42', 'study/study-test')}</p>
              <br/>
              <p><strong>{localisationMaster('text_38', 'study/study-test')}:</strong></p>
              <p>They [is|are|was|were*] on holiday yesterday</p>
            </div>
          </div>
        )
      case questionsTypes.WORDS_IN_BLANKS:
      case questionsTypes.DRAG_WORDS_IN_BLANKS:
        return (
          <div className="super-modal__form-field">
            <div className={st.customTextareaWrap}>
              <Textarea
                placeholder="Текст"
                value={modalValues.answers?.length ? modalValues.answers[0].value : ''}
                onChange={singleAnswer}
                error={check('answers')}
              />
            </div>
            <div className="super-modal-notice">
              <p>{localisationMaster('text_39', 'study/study-test')}</p>
              <br/>
              <p><strong>{localisationMaster('text_38', 'study/study-test')}:</strong></p>
              <p>In 1907 Alice [moved] to New York where she started her own film company.</p>
            </div>
          </div>
        )
      case questionsTypes.AUDIO_PLUS_VARIANTS:
        return (
          <>
            <MediaSelector
              type="audio"
              types={['mp3', 'wav']}
              value={modalValues.files?.find(x => !x.is_question)?.file || ''}
              onChange={mediaChangeHandler}
              error={check('files')}
            />
            <Variants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
          </>
        )
      case questionsTypes.VIDEO_PLUS_VARIANTS:
        return (
          <>
            <MediaSelector
              type="video"
              types={['mov', 'avi', 'mpeg']}
              value={modalValues.files?.find(x => !x.is_question)?.file || ''}
              onChange={mediaChangeHandler}
            />
            <Variants och={changeValues} data={modalValues.answers || []} error={check('answers')}/>
          </>
        )
      case questionsTypes.WORD_MATCHING:
        return <MatchWords name="answers" values={modalValues.answers} onChange={changeValues}
                           error={check('answers')}/>
      case 'make_word_from_letters':
        return (
          <div className="super-modal__form-field">
            <div>
              <input placeholder="Слово"
                     type="text"
                     value={modalValues.answers[0]?.value || ''}
                     onChange={e => singleAnswer(e.target.value)}
                     className={check('answers') ? 'super-modal__input--error' : undefined}/>
            </div>
            <div className="super-modal-notice">
              {localisationMaster('text_41', 'study/study-test')}
            </div>
          </div>
        )
      case questionsTypes.ESSAY:
        return (
          <div className="super-modal-notice">
            {localisationMaster('text_40', 'study/study-test')}
          </div>
        )
      case questionsTypes.SORT_SENTENCES:
        return <SortSentences name="answers" values={modalValues.answers} onChange={changeValues} error={check('answers')}/>
      case 'question_image_variants':
        return (
          <ImageVariants
            title={localisationMaster('text-info-one', 'tests/questions', 'question_plus_variants')}
            subtitle={localisationMaster('text-info-two', 'tests/questions', 'question_plus_variants')}
            error={check('answers')}
          />
        )
      case questionsTypes.MATCH_WORDS_IMAGES:
        return (
          <ImageVariants
            title={localisationMaster('text_43', 'study/study-test')}
            subtitle={localisationMaster('text_44', 'study/study-test')}
            error={check('answers')}
          />
        )
      case questionsTypes.IMAGE_MATCHING:
        return (
          <MatchImages/>
        )
      default:
        break;
    }
  }

  useEffect(() => {
    const valid = defaultValid

    if (modalValues.type){
      switch (modalValues.type){
        case questionsTypes.AUDIO_PLUS_VARIANTS:
            valid.answers = answers =>
              answers.length > 1 &&
              answers.some(a => a.is_correct) &&
              answers.every(a => a.value.length > 0)
            valid.files = files => files?.length > 0
          break;
        case questionsTypes.VARIANTS:
        case questionsTypes.VIDEO_PLUS_VARIANTS:
        case questionsTypes.QUESTION_IMAGE_VARIANTS:
          valid.answers = answers =>
            answers.length > 1 &&
            answers.some(a => a.is_correct) &&
            answers.every(a => a.value.length > 0)
          break;
        case questionsTypes.AUDIO_PLUS_VARIANTS:
          valid.answers = answers =>
            answers.length > 1 &&
            answers.some(a => a.is_correct) &&
            answers.every(a => a.value.length > 0);
          valid.files = (files = []) => files.some(item => item.file.includes('wav') || item.file.includes('mp3'));
          break;
        case questionsTypes.WORD_MATCHING:
        case questionsTypes.MATCH_WORDS_IMAGES:
          valid.answers = answers =>
            answers.length > 1 &&
            answers.every(a => a.value.split('|').every(v => v.length > 1))
          break;
        case questionsTypes.ESSAY:
          break;
        default:
          valid.answers = answers => answers.length > 0 && answers.every(a => a.value.length > 1)
          break;
      }
    }

    setValid(valid)
  }, [modalValues.type])

  return (
    <div className="super-modal__form-column">
      <MainSelector image={theme}>
        <div className="super-modal__form-column">
          <SelectCustom
            placeholder={localisationMaster('text_45', 'study/study-test')}
            options={questionTypesData}
            valueID={modalValues.type}
            onChange={o => changeTypeHandler(o.value)}
            error={check('type')}
            isDisabled={ms.is_put}
          />
          {testSections.length > 0 &&
          <SelectCustom placeholder={localisationMaster('text_46', 'study/study-test')}
                        options={testSections}
                        valueID={modalValues.test_section}
                        onChange={o => {
                          changeValues(o.id, 'test_section')
                        }}
            //error={check('test_section')}
          />}
        </div>
      </MainSelector>

      {modalValues.type &&
      <>
        <MediaSelector
          value={modalValues.files?.find(x => x.is_question)?.file || ''}
          types={['jpg', 'gif', 'png']}
          onChange={imageChangeHandler}
        />

        <MediaSelector
          type="audio"
          value={modalValues.audio_file || ''}
          types={['mp3']}
          onChange={file => changeValues(file, 'audio_file')}
        />

        <div className={st.customTextareaWrap}>
          <Textarea onChange={changeValues}
                    stateName="text_question"
                    error={check('text_question')}
                    value={modalValues.text_question || ''}
                    placeholder={localisationMaster('text_47', 'study/study-test')}/>
        </div>

        {renderBody(modalValues.type)}

        <QuestionPoints label={getPointsLabel()} name="point" value={modalValues.point} onChange={changeValues}/>
      </>}
    </div>
  )
}

export default observer(TestQuestion);
