import {observable, action, computed, toJS, makeObservable} from 'mobx';

class ScheduleStore {

  scheduleList = {}

  constructor() {
    makeObservable(this, {
      scheduleList: observable,
      scheduleListObject: computed,
      updateScheduleList: action,
      deleteSchedule: action,
    })
  }

  get scheduleListObject() {
    return toJS(this.scheduleList)
  }

  updateScheduleList = (id, sch) => {
    this.scheduleList = {
      ...this.scheduleList,
      [id]: sch,
    }
  }

  deleteSchedule = id => {
    let tmp = {...this.scheduleList};
    delete tmp[id];
    this.scheduleList = {...tmp}
  }
}

export default new ScheduleStore()
