import React, {useEffect, useState} from 'react';
import {localisationMaster} from "Localisation/Localisation";
import './SelectDateBirth.scss';

export default function SelectDateBirth(props) {

    const [day, setDay] = useState(0)
    const [month, setMonth] = useState(0)
    const [year, setYear] = useState(0)

    // Дни
    const aDay = Array.from({length: 31}, (v, i) => ++i)

    // Месяцы
    const aMonth = [
        {id: 1, name: localisationMaster('month_1', 'month'), days: 31},
        {id: 2, name: localisationMaster('month_2', 'month'), days: 28},
        {id: 3, name: localisationMaster('month_3', 'month'), days: 31},
        {id: 4, name: localisationMaster('month_4', 'month'), days: 30},
        {id: 5, name: localisationMaster('month_5', 'month'), days: 31},
        {id: 6, name: localisationMaster('month_6', 'month'), days: 30},
        {id: 7, name: localisationMaster('month_7', 'month'), days: 31},
        {id: 8, name: localisationMaster('month_8', 'month'), days: 31},
        {id: 9, name: localisationMaster('month_9', 'month'), days: 30},
        {id: 10, name: localisationMaster('month_10', 'month'), days: 31},
        {id: 11, name: localisationMaster('month_11', 'month'), days: 30},
        {id: 12, name: localisationMaster('month_12', 'month'), days: 31}
    ]

    // Годы
    const aYear = props.years === 'currentPlus3' ?
        Array.from({length: 5}, (v, i) => i - 2 + (new Date()).getFullYear()) :
        Array.from({length: 120}, (v, i) => i + (new Date()).getFullYear() - 118).reverse()


    useEffect(() => {
        const initDate = props.value || '0000-00-00'

        let [initYear = '0', initMonth = '0', initDay = '0'] = initDate.split('-')

        setDay(parseInt(initDay))
        setMonth(parseInt(initMonth))
        setYear(parseInt(initYear))

    }, [props.value])

    useEffect(() => {
        if (props.disabled) return;
        const selectedMonth = aMonth.find(x => x.id === month)

        if (selectedMonth) {
            let isLeap = (selectedMonth.id === 2 && year % 4 === 0)

            if (isLeap && day > selectedMonth.days + 1)
                setDay(++selectedMonth.days)
            else if (!isLeap && day > selectedMonth.days)
                setDay(selectedMonth.days)
            else if (year && day) {
                props.onChange(
                    `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
                    props.name
                )
            }
        }

    }, [day, month, year])

    return (
        <div className={props.error ? "select-date-birth error" : "select-date-birth"}>

            <select value={day}
                    onChange={e => setDay(parseInt(e.target.value))}
                    style={{width: props.width}}
                    disabled={props.disabled && true}>
                <option value={0} disabled>{localisationMaster('day', 'modal_templates')}</option>
                {aDay.map(day => <option key={day} value={day}>{day}</option>)}
            </select>

            <select value={month}
                    onChange={e => setMonth(parseInt(e.target.value))}
                    disabled={props.disabled && true}>
                <option value={0} disabled>{localisationMaster('month', 'modal_templates')}</option>
                {aMonth.map(month => <option key={month.id} value={month.id}>{month.name}</option>)}
            </select>

            <select value={year} onChange={e => setYear(parseInt(e.target.value))} disabled={props.disabled && true}>
                <option value={0} disabled>{localisationMaster('year', 'modal_templates')}</option>
                {aYear.map(year => <option key={year} value={year}>{year}</option>)}
            </select>

        </div>
    )
}
