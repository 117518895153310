import React, { useState, useEffect } from "react";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import Loader from "components/ContentBox/Global/Loader/Loader";
import CRMField from "./CRMField/CRMField";
import DefaultCRM from "./CRMField/DefaultCRM";
import Error from "../Error/Error";
import swal from "sweetalert";
import modalStore from "Stores/modalStore";
import { localisationMaster } from "Localisation/Localisation";
import { classNames as cn } from "helpers";
import "./CRM.scss";

export const CRM = ({
  form,
  setForm,
  crmFields,
  setCrmFields,
  validate,
  crmRef,
}) => {
  const [required, setRequired] = useState([]);

  useEffect(() => {
    const validFields = crmFields.data
      .filter((item) => {
        if (item.is_enabled && item.crm_name === "") return item;
      })
      .map((item) => item.placeholder);
    setRequired(validFields);
  }, [crmFields.data]);

  const changeField = (index, el) => {
    const fields = [...crmFields.data];
    fields[index] = el;
    setCrmFields({ ...crmFields, data: fields });
  };

  const openModal = (element) => {
    if(!element.is_enabled) {
      swal({
        title: "Это поле отключено",
        icon: "warning",
      }); return;
    }
    modalStore.activity(
      {
        name: "crm_fields",
        title: `${localisationMaster(
          "text_13",
          "c/tests/placement-test/create-widget"
        )}: ${element.placeholder}`,
        button: localisationMaster("text_2", "company/locations"),
        type: "change_crm_fields",
        func: (result) => {
          const index = crmFields.data.findIndex(
            (item) => item.name === result.name
          );
          changeField(index, result);
        },
      },
      element
    );
  };

  const toggleField = (index, el) => {
    changeField(index, { ...el, is_enabled: !el.is_enabled });
  };

  return (
    <div className="placementTestBox" ref={crmRef}>
      <div className="placementTestCreate__crm">
        <div className="placementTestBox__mainBoxLine">
          <p className="placementTestBox__title">
            {localisationMaster(
              "text_33",
              "c/tests/placement-test/create-widget"
            )}
          </p>
          <p className="placementTestBox__text">
            {localisationMaster(
              "text_34",
              "c/tests/placement-test/create-widget"
            )}
          </p>
        </div>
        <div className="placementTestBox__mainBoxLine placementTestBox__list">
          <CheckboxToggle
            right={localisationMaster(
              "text_33",
              "c/tests/placement-test/create-widget"
            )}
            onChange={() => setForm({ ...form, crm_send: !form.crm_send })}
            checked={form.crm_send}
          />
        </div>
        <div className="placementTestBox__optionsUrl">
          <div className="placementTestBox__mainBoxLine">
            <p className="super-modal__form-label">
              SLUG,{" "}
              {localisationMaster(
                "text_35",
                "c/tests/placement-test/create-widget"
              )}
              :
            </p>
            <input
              type="text"
              value={form.crm_slug}
              onChange={(e) => setForm({ ...form, crm_slug: e.target.value })}
              className={cn({
                "placementTestBox__input": true,
                "placementTestBox__input--error": validate("crm_slug"),
              })}
            />
          </div>
          <div className="placementTestBox__mainBoxLine">
            <p className="super-modal__form-label">
              {localisationMaster(
                "text_36",
                "c/tests/placement-test/create-widget"
              )}
            </p>
            <input
              type="text"
              value={form.crm_widget_url}
              onChange={(e) =>
                setForm({ ...form, crm_widget_url: e.target.value })
              }
              className={cn({
                "placementTestBox__input": true,
                "placementTestBox__input--error":
                validate("crm_widget_url"),
              })}
            />
          </div>
        </div>
        {validate("crm_fields") ? (
          <div className="placementTestBox__mainBoxLine">
            <Error
              text={`${localisationMaster(
                "text_41",
                "c/tests/placement-test/create-widget"
              )} ( ${required.join(", ")} )`}
            />
          </div>
        ) : null}
        <div className="placementTestBox__mainBoxLine">
          <DefaultCRM />
          {crmFields.loader ? (
            <Loader />
          ) : (
            crmFields.data.map((el, index) => {
              return (
                <CRMField
                  el={el}
                  index={index}
                  key={index}
                  toggleField={toggleField}
                  openModal={openModal}
                  changeField={changeField}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CRM;
