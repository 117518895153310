import React from 'react';
import style from './RatingStars.module.scss'
import {ReactComponent as StarHalf} from "../../../../../../UI/icons/star-half-fill.svg";
import {ReactComponent as StudentReportsRating} from "../../../../../../ContentBox/img/bigStar.svg";
import {ReactComponent as Star} from "../../../../../../UI/icons/star-fill.svg";

const RatingStars = ({rating}) => {

    
    let remainder = rating
    const stars = []

    for (let i = 0; i < 5; i++) {
        if (remainder >= 1) {
            stars.push(<Star className={style.filled}/>)
        }else if (remainder > 0 && remainder < 1) {
            stars.push(<StarHalf className={style.filled}/>)
        }else {
            stars.push(<Star className={style.empty}/>)
        }
        remainder--
    }

    return (
        <div className={style.ratingContainer}>
            <div className={style.bigStarContainer}>
                <StudentReportsRating className={style.bigStar}/>
                <span className={style.averageRating}>{rating?.toFixed(1)}</span>
            </div>
            <div className={style.stars}>
                {stars.map((item, index) => <div key={index}>{item}</div>)}
            </div>
        </div>

    );
};

export default RatingStars;