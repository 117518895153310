import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { maskPhone } from 'helpers/phoneMask';
import styles from './CustomerList.module.scss';

function SwalContent({data, type}) {
  const {date, email, phone, link, code} = data;

  return (
    <div className={styles.swal_content}>
      {type === 'zoom' &&
        <>
          <dl>
            <dt>Дата конференции:</dt>
            <dd>{date}</dd>
          </dl>
          <a className={styles.link} href={link}>{link}</a>
          <button className={styles.button} type='button' onClick={() => navigator.clipboard.writeText(link)}>
            Скопировать ссылку
          </button>
        </>
      }
      {type === 'email' &&
        <dl>
          <dt>E-mail:</dt>
          <dd>{email}</dd>
          <dt>Код:</dt>
          <dd className={styles.code}>{code}</dd>
        </dl>
      }
      {type === 'sms' &&
        <dl>
          <dt>Телефон:</dt>
          <dd>{maskPhone(phone)}</dd>
          <dt>Код:</dt>
          <dd className={styles.code}>{code}</dd>
        </dl>
      }
    </div>
  );
}

SwalContent.propTypes = {
  type: PropTypes.oneOf(['zoom', 'email', 'sms']).isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    link: PropTypes.string,
    code: PropTypes.number,
  }).isRequired,
}

export const createContentElement = (data, type) => {
  let wrapper = document.createElement('div');
  ReactDOM.render(<SwalContent data={data} type={type}/>, wrapper);
  return wrapper.firstChild;
};
