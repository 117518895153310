import React, {useState} from "react";
import {observer} from "mobx-react";
import AddClient from "./components/AddClient";
import CreateClient from "./components/CreateClient";
import st from "./ClientBarcodeForm.module.scss";

const tabs = [
  {id: 1, title: 'Добавить клиента'},
  {id: 2, title: 'Создать нового клиента'},
]

const ClientBarcodeForm = () => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <div>
      <ul className={st.tabs}>
        {tabs.map(tab =>
          <li key={tab.id} className={`${st.tabsItem} ${tab.id === currentTab ? st.tabsItem__active : ''}`}>
            <span onClick={() => setCurrentTab(tab.id)}>{tab.title}</span>
          </li>
        )}
      </ul>
      {currentTab === 1 && <AddClient />}
      {currentTab === 2 && <CreateClient setTab={() => setCurrentTab(1)} />}
    </div>
  )
}

export default observer(ClientBarcodeForm);
