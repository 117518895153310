import React, {useEffect, useState} from 'react';
import styles from "./TypeSelector.module.scss"

const TypeSelector = ({data, selectValue, value}) => {

    return (
        <div className={styles.box}>
            {data.map((item, i) =>
                <div style={{width: `${100 / data.length}%`}} key={i}
                    onClick = {() => selectValue(item.id)}
                    className={`${styles.item} ${item.id === value ? styles.select : ''}`}>
                    {item.label}
                </div>)}
        </div>
    );
}


export default TypeSelector