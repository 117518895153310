import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import swal from "sweetalert"
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import {dict, translate as loc} from "Localisation";
import modalStore from "Stores/modalStore";
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import Paper from "components/UI/Paper/Paper";
import ClientSelector from "./ClientSelector";
import VisitHistory from "./VisitHistory";
import SubscriptionHistory from "./SubscriptionHistory";
import st from "./TrainingLessons.module.scss";
import Visits from "./Visits";
import asideModalStore from "../../../../Stores/asideModalStore";
import EditSubscription from "../../../ContentBox/Global/GlobalModalSingle/templates/Pays/EditSubscription";


export default function (){
  const [schedules, setSchedules] = useState([])
  const [client, setClient] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [visits, setVisits] = useState([])
  const [visitHistory, setVisitHistory] = useState([])


  const onChooseSchedule = schedule => {
    superAxiosRequest({
      method:'post',
      link: 'unidance/schedule_client'
    }, {
      client_id: client.id,
      schedule_id: schedule.id
    }).then(() => {
      swal("Клиент добавлен", `${client.full_name} добавлен(а) в расписание: ${schedule.master.last_name} ${schedule.master.first_name} (${schedule.type_name}) на ${dateFormat(schedule.date)}`)
        .then(() => {
          fetchSubscriptionHistory()
          fetchVisits()
          fetchVisitHistory()
        })
    }).catch(({response: {data: {error}}}) => {
      swal("Ошибка", error, "error").then(() => {})
    })
  }

  const fetchSubscriptionHistory = () => {
    if (client){
      superAxiosRequest({
        method: 'get',
        link: 'unidance/history_subscriptions_client',
        params: {
          client_barcode_id: client.id
        }
      }).then(res => {
        setSubscriptions(res.data.result)
      })
    }
  }

  const fetchVisits = () => {
    const currentDate = dateFormat(new Date(), "YYYY-mm-dd")
    superAxiosRequest({
      method: "get",
      link: "unidance/visit_report",
      params: {
        corrected: false,
        operation: 2,
        entry_date: currentDate,
        limit: 999
      },
    })
        .then((response) => {
          setVisits(response.data.result);
        })
  };

  const fetchVisitHistory = () => {
    if (!client) {
      setVisits([])
      return;
    }
    superAxiosRequest({
      method: 'get',
      link: 'unidance/schedule_history',
      params: {
        client_barcode_id: client.id,
        limit: 999
      }
    }).then(res => {
      setVisitHistory(res.data.result)
    })
  }

  const addBalance = () => {
    modalStore.activity({
      name: 'addPayment',
      title: `${loc(dict.pays.balance_replenishment, 'Пополнение баланса')}: ${client.full_name}`,
      type: 'addPayment',
      button: loc(dict.main_page.add),
      link: 'payments/pay_orders',
      width: '650px',
      client_id: client.client_id,
      isUnidance: true,
      func: res => window.location.href = '/pays/invoice/' + res.order_id
    })
  }

  const editSubscription = () => {
    asideModalStore.activity({
      isResponsive: true,
      title: `Редактирование абонемента: ${client.full_name}`,
      modalBodyComponent: () => <EditSubscription subscriptions={subscriptions}/>,
      button: loc(dict.main_page.add),
      link: 'unidance/update_date_until_history_subscription',
      isUnidance: true,
      valid: {subscription: 'required', date_until: 'required'},
    }, {client_barcode: +client?.id})
  }

  useEffect(() => {
    // Расписания
    superAxiosRequest({
      method: 'get',
      //link: 'unidance/schedule_teachers'
      link: `unidance/schedule_teachers_today`,
      params: {
        date:dateFormat(new Date(), "YYYY-mm-dd")
      }
    }).then(res => {
      setSchedules(res.data.result)
    }).catch(() => {

    })
  }, [])

  return (
    <div>
      <ContentHeader title="UniDance - учебные занятия"/>
      <div className={st.page}>
        <div className={st.firstRow}>
          <ClientSelector onChange={setClient}/>
          <div className={st.manageAbonemetButtons}>
            <button className={st.balanceButton} disabled={!client} onClick={addBalance}>
              +  Пополнить баланс, абонемент
            </button>
            <button className={st.balanceButton} disabled={!client || subscriptions.length === 0} onClick={editSubscription}>
              Редактировать абонемент
            </button>
          </div>
        </div>
        <Paper classes={st.box}>
          <OpenHeader title="Расписание"/>
          <div className={st.list}>{schedules.map(sch =>
            <div className={st.scheduleRow} key={sch.id}>
              <div>
                <Link to={`/account/${sch.master.user}`} target="_blank">
                  {`${sch.master.last_name} ${sch.master.first_name}`}
                </Link>
              </div>
              <span>{sch.type_name}</span>
              <span>
                c {dateFormat(new Date(`${sch.date}T${sch.start_at}`), "hh:ii")} по {dateFormat(new Date(`${sch.date}T${sch.finish_at}`), "hh:ii")}
              </span>
              <span>{dateFormat(sch.date)}</span>
              <div>
                <button className={st.scheduleButton}
                        disabled={!client}
                        onClick={() => onChooseSchedule(sch)}
                >Выбрать</button>
              </div>
            </div>)}
          </div>
        </Paper>
        <VisitHistory client={client} fetchVisitHistory={fetchVisitHistory} visits={visitHistory}/>
        <SubscriptionHistory
            client={client}
            setSubscriptions={setSubscriptions}
            subscriptions={subscriptions}
            fetchSubscriptionHistory={fetchSubscriptionHistory}/>
        <Visits
            visits={visits}
            fetchVisits={fetchVisits}
            fetchSubscriptionHistory={fetchSubscriptionHistory}
            fetchVisitHistory={fetchVisitHistory}/>
      </div>
    </div>
  )
}
