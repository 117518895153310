import React, {useState} from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import styles from "./DoughnutChart.module.scss"

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    responsive: true,
    lineWidth: 5,
    cutout: '90%'
}

export const localTimeConverter = dt => {
    const [d, h, m, s] = [
        Math.floor(dt.getTime() / 1000 / 60 / 60 / 24),
        dt.getUTCHours(),
        dt.getUTCMinutes(),
        dt.getUTCSeconds()
    ]

    return d > 0 ? `${d}д ${h}ч ${m}м ${s}с` : h > 0 ? `${h}ч ${m}м ${s}с` : `${m}м ${s}с`
}

const addLineBreak = str => str.split('/n').map((item, i) => <span key={i}>{item}</span>)

const markBoldText = str => {
    const wordArray = str.split('/*')
    return <><strong>{wordArray[0]}</strong> {wordArray[1]} </>
}

const getOptions = (array, id) => array.filter(item => item.selectId === id)[0]?.data

const checkZero = array => {
    let zeroQuantity = 0
    array.forEach(item => {
        if (item.procent === 0) zeroQuantity = zeroQuantity + 1
    })
    if (zeroQuantity === array.length) return [{procent: 10}]
    return array
}

const DoughnutChart = ({chartData, getFilterParams, chartName}) => {
    const [chartState, setChartState] = useState({})

    const doughnutData = {
        datasets: [
            {
                data: checkZero(chartData.leftArray).map(item => item.procent),
                backgroundColor: [
                    'rgba(90, 141, 238, 1)',
                    'rgba(64, 77, 99, 1)',
                    '#ebc05f',
                    '#798081',
                    '#55a77a',
                ],
                borderWidth: 0,
            },
        ],
    }

    const selectTemplate = {
        bound(select, key) {
            return (<div className={styles.clientChart_wrapper} key={key}>
                <div className={styles.clientChart_field}>
                    <SelectCustom
                        placeholder={select.boundFilters[0].placeholder}
                        options={select.boundFilters[0].options}
                        valueID={chartState[select.boundFilters[0].nameFeild]}
                        onChange={(o) => setChartState({...chartState, [select.boundFilters[0].nameFeild]: o.value})}
                    />
                </div>
                <div className={styles.clientChart_field}>
                    <SelectCustom
                        placeholder={select.boundFilters[1].placeholder}
                        isDisabled={!chartState[select.boundFilters[0].nameFeild]}
                        valueID={chartState[select.boundFilters[1].nameFeild]}
                        onChange={o => setChartState({...chartState, [select.boundFilters[1].nameFeild]: o.value})}
                        options={chartState[select.boundFilters[0].nameFeild] ? getOptions(select.boundFilters[1].options, chartState[select.boundFilters[0].nameFeild]) : []}
                    />
                </div>
            </div>)
        },
        normal(select, key) {
            return (
                <div key={key} className={styles.clientChart_field}>
                    <SelectCustom
                        placeholder={select.placeholder}
                        options={select.options}
                        valueID={chartState[select.nameFeild]}
                        onChange={(o) => setChartState({...chartState, [select.nameFeild]: o.value})}
                    />
                </div>
            )
        }
    }

    return (
        <div className={styles.clientChart}>
            <div className={styles.clientChart_header}>
                <div className={styles.clientChart_wrapper}>
                    {chartData.filters.map((select, id) => selectTemplate[select.type](select, id))}
                </div>
                <button onClick={() => getFilterParams(chartState, chartName)} className={styles.clientChart_btn} >Применить</button>
            </div>
            <div className={styles.clientChart_title}>
                <h3> {markBoldText(chartData.label)}</h3>
            </div>
            <div className={styles.clientChart_content}>
                <div className={styles.clientChart_itemBox}>
                    {chartData.leftArray.map((item, i) => (
                        <div key={i} className={styles.clientChart_item}>
                            <div className={styles.clientChart_number}>
                                {item.procent}%
                                <span>
                                    {item.value}
                                </span>
                            </div>
                            <div className={styles.clientChart_line} />
                            <div className={styles.clientChart_label}>{item.label}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.clientChart_wrapper_doughnut}>
                    <div className={styles.doughnut_box}>
                        <div className={styles.doughnut_content}>
                            <div className={styles.doughnut_title}>
                                {chartData.total.test.value}

                                <span>
                                    {chartData.total.test.label}
                                </span>
                            </div>
                            <div className={styles.doughnut_subtite}>
                                {localTimeConverter(chartData.total.time.value)}
                                <span>
                                    {addLineBreak(chartData.total.time.label)}
                                </span>
                            </div>
                        </div>
                        <Doughnut data={doughnutData} width={400} height={400} options={options} type='doughnut' />
                    </div>
                </div>
                <div className={styles.clientChart_itemBox}>{chartData.rightArray.map((item, i) => (
                  <div key={i} className={styles.clientChart_item}>
                      <div className={styles.clientChart_number}>
                          {item.procent}%
                          <span>{item.value}</span>
                      </div>
                      <div className={styles.clientChart_line}/>
                      <div className={styles.clientChart_label}>{item.label}</div>
                  </div>))}
                </div>
            </div>
        </div>
    )
}

export default DoughnutChart
