import React from 'react';
import PropTypes from 'prop-types';
import st from './VideoLink.module.scss'
import VariantDelete from "../VariantDelete/VariantDelete";
import FormInput from "../../../../../../../UI/AsideModal/Form/FormInput";
import FormLabel from "../../../../../../../UI/AsideModal/Form/FormLabel";
import FormField from "../../../../../../../UI/AsideModal/Form/FormField";

const VideoLink = props => {
    const isError = !props.value.video_link || !['vimeo.com', 'ted.com', 'vk.com', 'youtube.com'].some(host => props.value.video_link.includes(host))


    return (
        <div className={st.videoLink}>
            {props.isRemovable && <div className={st.del}><VariantDelete onClick={() => props.remove(props.index)} /></div>}
            <FormInput>
            <input type="text" value={props.value.video_link} placeholder={props.translate.video_links}
                   onChange={e => props.onChange({...props.value, video_link: e.target.value})}
                   className={isError ? st.error : ''}
            />
            </FormInput>
            <FormField>
             <FormLabel>
               <p>{props.translate.link_to_video} <span>{props.translate.instructions}</span></p>
             </FormLabel>
            <FormInput>
            <input type="text" value={props.value.description} placeholder={props.translate.video_name}
                   onChange={e => props.onChange({...props.value, description: e.target.value})}/>
            </FormInput>
            </FormField>
        </div>
    )
}


VideoLink.propTypes = {
  value: PropTypes.shape({
    video_link: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default VideoLink
