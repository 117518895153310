import React, { useState } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import Flex from 'components/UI/Flex/Flex';
import Button from 'components/UI/Button/Button';
import ModalWrapper from 'components/UI/ModalWrapper/ModalWrapper';
import Select from 'components/UI/Select/Select';
import Hr from 'components/UI/Hr/Hr';
import TextArea from 'components/UI/TextArea/TextArea';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import { findOption } from 'components/Modules/Slots/utils';
import { localisationMaster } from "Localisation/Localisation";
import slotsTeacherScheduleStore from 'Stores/slotsTeacherScheduleStore';
import styles from './BookedSlots.module.scss';

function BookedSlotModal({id, type, slotType, zoomUrl, isComment, isOnline, isSchool, isGroup, comment, onClose}) {
  const { slotTypes, deleteSlot, updateSlot, createZoomLink } = slotsTeacherScheduleStore;
  const options = slotTypes.map((item) => ({label: item.name, value: item.id, ...item}));
  const [selectedSlot, setSelectedSlot] = useState(findOption(slotType, options));
  const [newComment, setNewComment] = useState(type === 'delete' ? '' : comment);
  const [isOnlineToggle, setIsOnlineToggle] = useState(isOnline);
  const [isSchoolToggle, setIsSchoolToggle] = useState(isSchool);
  const [isGroupToggle, setIsGroupToggle] = useState(isGroup);
  const [newZoomUrl, setNewZoomUrl] = useState(zoomUrl);
  isComment = type === 'delete' ? true : selectedSlot.is_comment;

  const handleToggles = ({target}) => {
    const {name} = target;
    switch (name) {
      case 'is-online':
        if (isOnlineToggle && !isSchoolToggle) {
          setIsSchoolToggle(true);
        }
        setIsOnlineToggle(!isOnlineToggle);
        break;
      case 'is-school':
        if (isSchoolToggle && !isOnlineToggle) {
          setIsOnlineToggle(true);
        }
        setIsSchoolToggle(!isSchoolToggle);
        break;
    }
  };

  const handleZoomButtonClick = () => {
    if (newZoomUrl) {
      navigator.clipboard.writeText(newZoomUrl)
        .then(() => swal('', localisationMaster('text_14', 'teacher_schedule'), 'success'));
    } else {
      createZoomLink(
        id,
        (data) => swal('', localisationMaster('text_15', 'teacher_schedule'), 'success').then(() => setNewZoomUrl(data)),
        () => swal('', localisationMaster('text_16', 'teacher_schedule'), 'warning'),
      )
    }
  };

  const handleSubmit = () => {
    const data = {
      id,
      type: selectedSlot.value,
      is_comment: isComment,
      is_online: isOnlineToggle,
      is_school: isSchoolToggle,
      group_clients: isGroupToggle,
    };
    if (newComment) {
      data.comment = newComment;
    }
    const onSuccess = () => onClose();
    const onFail = () => swal(`${localisationMaster('error', 'c/tests/placement-test/create-widget')} ${type === 'delete' ? localisationMaster('text_17', 'teacher_schedule') : localisationMaster('text_18', 'teacher_schedule')}`, localisationMaster('text_19', 'teacher_schedule'), 'warning');
    type === 'delete'
      ? deleteSlot(data, onSuccess, onFail)
      : updateSlot(data, onSuccess, onFail);
  };

  return (
    <ModalWrapper
      title={type === 'delete' ? localisationMaster('text-info-2', 'online-schedule/booked-teachers-schedule') : localisationMaster('text_20', 'teacher_schedule')}
      onClose={onClose}
    >
      { type === 'change' &&
        <Select
          label={localisationMaster('text_21', 'teacher_schedule')}
          placeholder={localisationMaster('text_21', 'teacher_schedule')}
          value={selectedSlot}
          options={options}
          onChange={(option) => setSelectedSlot(option)}
        />
      }
      <TextArea
        label={type === 'delete' ? localisationMaster('text_22', 'teacher_schedule') : localisationMaster('table-row-title-2', 'company/companies')}
        onChange={(evt) => setNewComment(evt.target.value)}
        value={newComment}
        placeholder={localisationMaster('table-row-title-2', 'company/companies')}
        name='slot-comment'
        isError={isComment && !newComment}
        isResize={false}
      />
      {type === 'change' &&
        <>
          <InputToggle
            selected={isOnlineToggle}
            onChange={handleToggles}
            name='is-online'
            label={localisationMaster('text_11', 'company/locations')}
          />
          <InputToggle
            selected={isSchoolToggle}
            onChange={handleToggles}
            name='is-school'
            label={localisationMaster('text_23', 'teacher_schedule')}
          />
          <InputToggle
            selected={isGroupToggle}
            onChange={() => setIsGroupToggle(!isGroupToggle)}
            name='is-group'
            label={localisationMaster('text_11', 'teacher_schedule')}
          />
          <Flex>
        <span className={styles.zoom_link}>
          {newZoomUrl ? newZoomUrl : localisationMaster('text_24', 'teacher_schedule')}
        </span>
            <Button classes={styles.modal_button} variant='accent' onClick={handleZoomButtonClick}>
              {newZoomUrl ? localisationMaster('text_25', 'teacher_schedule') : localisationMaster('text_26', 'teacher_schedule')}
            </Button>
          </Flex>
        </>
      }
      <Hr light withTopMargin={false} withBottomMargin={false} />
      <Flex justify='end' withGap>
        <Button classes={styles.modal_button} onClick={onClose} variant="white">{localisationMaster('button-one', 'docs/push-clients-docs')}</Button>
        <Button
          classes={styles.modal_button}
          onClick={handleSubmit}
          isDisabled={isComment && !newComment}
        >
          {localisationMaster('text_2', 'company/locations')}
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

BookedSlotModal.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['change', 'delete']).isRequired,
  slotType: PropTypes.number.isRequired,
  isComment: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isSchool: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool,
  zoomUrl: PropTypes.string,
  comment: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

BookedSlotModal.defaultProps = {
  isGroup: false,
};

export default BookedSlotModal;

