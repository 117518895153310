import Stores from '.';
import { superAxiosRequest } from 'axiosApi';

class CoursesStore {
    constructor() {
        this.CourseStore = new CourseStore(this)
    }

    data = []
    meta = []
    exerciseList = false
    metaCounts = new Map()
    loading = false
    toFilter = false
    ciObj = false
    lessonData = { section_list: [] }
    activeSection = 1
    //courseInformation = []

    get exerciseListObject() {
        return Stores.observableArray(this.exerciseList)
    }

    get lessonObject() {
        return Stores.observableArray(this.lessonData)
    }

    setActiveSection = (direction, num) => {
        if (num) this.activeSection = num
        if (direction === 'prev') this.activeSection = Math.max(this.activeSection - 1, 1)
        if (direction === 'next') this.activeSection = Math.min(this.activeSection + 1, this.lessonObject.section_list.length)
    }

    addSection = (row) => {
        let Data = Stores.observableArray(this.lessonData)
        Data.section_list.push({
            name: row.name,
            id: row.id,
            position: row.position
        })
        this.lessonData = Data
    }

    deleteSection = (id) => {
        let Data = Stores.observableArray(this.lessonData)
        Data.section_list = Data.section_list.filter(x => x.id !== id)
        this.lessonData = Data
        this.activeSection = Math.max(this.activeSection - 1, 1)
    }

    fetchLessonData = (id) => {
        let apiConf = {
            method: 'get',
            link: 'lessons/section_in_lessons/' + id
        }
        superAxiosRequest(apiConf).then(res => {
            this.lessonData = res.data
        })
    }

    setLessonData = data => this.lessonData = data

    updateExercise = (row) => {
        let Data = Stores.observableArray(this.exerciseList)
        const index = Data.exercises.findIndex(item => item.id === row.id);
        Data.exercises[index] = row
        this.exerciseList = Data
    }

    updateSectionName = row => {
        const obj = Stores.observableArray(this.exerciseList)
        obj.name = row.name
        this.exerciseList = obj

        // Также нужно обновить название в списке
        const arr = [...this.lessonData.section_list]
        const idx = arr.findIndex(x => x.id === row.id)
        arr[idx].name = row.name
        this.lessonData.section_list = arr
    }

    addexercise = (row) => {
        let Data = Stores.observableArray(this.exerciseList)
        Data.exercises.push(row)
        this.exerciseList = Data
    }

    deleteExercise = (id) => {
        let Data = Stores.observableArray(this.exerciseList)
        Data.exercises = Data.exercises.filter(x => x.id !== id)
        this.exerciseList = Data
    }

    fetchExerciseList = (section) => {
        const apiConf = {
            method: 'get',
            link: 'lessons/lesson_section/' + section
        }
        superAxiosRequest(apiConf).then(res => {
            this.exerciseList = res.data
        })
    }

    setExerciseList = data => this.exerciseList = data

    get getObjectCourse() {
        return Stores.observableArray(this.ciObj)
    }

    putRow = row => {
        let Data = Stores.observableArray(this.data)
        const index = Data.findIndex(item => item.id === row.id);
        Data[index] = row
        this.data = Data
    }

    putLesson = data => {
        let list = Stores.observableArray(this.ciObj)
        list.lessons.push(data)
        this.ciObj = list
    }

    deleteLesson = id => {
        let list = Stores.observableArray(this.ciObj)
        list.lessons = list.lessons.filter(x => x.id !== id)
        this.ciObj = list
    }

    updateLesson = (row) => {
        let Data = Stores.observableArray(this.ciObj)
        const index = Data.lessons.findIndex(item => item.id === row.id);
        Data.lessons[index] = row
        this.ciObj = Data
    }

    updateRow = (data, keys, index) => {
        let arr = []
        for (let key in keys) {
            arr[key] = data[key]
        }
        let oldItems = this.data
        oldItems[index] = arr
        this.data = oldItems
    }

    dropData = () => {
        this.data = []
        this.meta = []
        this.toFilter = false
        this.loading = false
    }

    updateData = data => {
        this.loading = true
        this.data = data.result
        this.meta = data._meta
        this.loading = false
    }

    fillData = apiConf => {
        this.loading = true
        superAxiosRequest(apiConf).then(({ data }) => {
            this.data = data.result
            this.meta = data._meta

            if (data._meta.group_statuses) {
                Object.keys(data._meta.group_statuses).forEach(i => {
                    const val = data._meta.group_statuses[i]
                    this.metaCounts.set(val.filter_type, val.count)
                })
            }

            this.loading = false
        }).catch(error => {
            this.loading = false
            if (error.response && error.response.status === 400) {
                Stores.error = true;
                let errors = error.response.data;
                let error_str = '';
                for (var key in errors) {
                    error_str += [key] + " : " + errors[key][0] + '\r\n';
                }
                Stores.errorTxt = error_str;
            }
            if (error.response && error.response.status === 500) {
                Stores.error = true;
                Stores.errorTxt = "\r\n Сервер не доступен";
            }
            if (error.response && error.response.status === 404) {
                Stores.error = true;
                Stores.errorTxt = "\r\n Не верный url запроса";
            }
            if (error.response && error.response.status === 403) {
                Stores.error = true;
                Stores.errorTxt = "\r\n" + error.response.data.detail;
            }
        })
    }

    getMetaCounts = key => this.metaCounts.get(key)
}

class CourseStore {
    constructor(CoursesStore) {
        this.CoursesStore = CoursesStore
    }

    course = {}

    fetchCourse = id => {
        const apiConf = {
            method: 'get',
            link: 'lessons/course/' + id
        }
        superAxiosRequest(apiConf).then(res => {
            this.course = res.data
        }).catch()
    }

    setCourse = course => this.course = course

    addLesson = data => {
        /*const course = Stores.observableArray(this.course)
        course.lessons.push(data)
        this.course = course*/
        this.course.lessons.push(data)
    }

    updateLesson = lesson => {
        /*let Data = Stores.observableArray(this.ciObj)
        const index = Data.lessons.findIndex(item => item.id === row.id);
        Data.lessons[index] = row
        this.ciObj = Data*/
        const index = this.course.lessons.findIndex(x => x.id === lesson.id)
        this.course.lessons[index] = lesson
    }

    deleteLesson = id => {
        this.course.lessons = this.course.lessons.filter(x => x.id !== id)
    }
}

export default new CoursesStore()