import React, {useState} from 'react';
import {classNames as cn} from "helpers";
import styles from './SideMenuEbook.module.scss'
import {ReactComponent as SelectStudentIcon} from "components/UI/icons/apps-2-line.svg";
import {ReactComponent as FocusIcon} from "components/UI/icons/focus-3-line.svg";
import {ReactComponent as CheckboxIcon} from "components/UI/icons/checkbox-circle-line.svg";
import {ReactComponent as HomeIcon} from "components/UI/icons/home-8-line.svg";
import {ReactComponent as UsersIcon} from "components/UI/icons/parent-line.svg";
import {ReactComponent as SaveIcon} from "components/UI/icons/save-line.svg";
import {ReactComponent as LikeIcon} from "components/UI/icons/heart-add-line.svg";
import {ReactComponent as DislikeIcon} from "components/UI/icons/heart-remove-line.svg";
import {ReactComponent as ThreeDot} from "components/ContentBox/img/threeDot.svg";
import {ReactComponent as DeleteIcon} from "components/UI/icons/delete-bin-6-line.svg";
import {ReactComponent as AIIcon} from "components/UI/icons/logos-Linereactjs-line.svg";
import GradeModal from '../GradeModal/GradeModal';


const SideMenuEbook = ({setting, setState, students, student}) => {
  const [openModal, setOpenModal] = useState(false)

  const jsxCreator = button => {
    let $button
    switch (button.type) {
      case 'select_student':
        $button = <SelectStudentIcon/>
        break;
      case 'focus':
        $button = <FocusIcon/>
        break;
      case 'checkbox':
        $button = <CheckboxIcon/>
        break;
      case 'home':
        $button = <HomeIcon/>
        break;
      case 'users':
        $button = <UsersIcon onClick={() => setOpenModal(prev => !prev)}/>
        break;
      case 'save':
        $button = <SaveIcon/>
        break;
      case 'like':
        $button = <LikeIcon/>
        break;
      case 'dislike':
        $button = <DislikeIcon/>
        break;
      case 'more':
        $button = <ThreeDot/>
        break;
      case 'delete_homework':
        $button = <DeleteIcon/>
        break;
      case 'check_via_ai':
        $button = <AIIcon/>
        break;
      default:
        break;
    }
    return $button
  }

  const getIsPushedBtnStyle = (type) => {
    if(type === 'check_via_ai') return
    return students && type === 'users' && openModal ? true : !!student
  }

  return (
      <div className={styles.menu}>
        {openModal && students && (
            <>
              <GradeModal clients={students} setClose={(user) => {
                setState(user);
                setOpenModal(false);
              }} />
              <div onClick={() => setOpenModal(false)} className={styles.popupClickOut} />
            </>
        )}
        {setting.map((item) => (
            <div
                key={item.type}
                onClick={() => item.action && item.action()}
                className={cn({
                  [styles.button]: true,
                  [styles.buttonPushed]: getIsPushedBtnStyle(item.type),
                  [styles.buttonDisabled]: item.isDisabled,
                  [styles.buttonActive]: item.isPressed,
                })}
            >
              {jsxCreator(item)}
              <div className={styles.info}>{item.text}</div>
            </div>
        ))}
      </div>
  );
};

export default SideMenuEbook;