import React, { useState, useEffect } from 'react'

export default function MakeWordLetters(props) {
    const [values, setValues] = useState([])

    function changeHandler(e, index, key) {
        let arr = values;
        let value = e.target.value
        arr[index][key] = value;
        if (key === 'value') {
            value = value.replaceAll('/', '|')
            value = value.replaceAll(',', '|')
            value = value.replaceAll('\\', '|')
            arr[index][key] = value
        }
        setValues([...arr]);
    }

    const addLine = () => {
        if (props.max && values.length < props.max)
            setValues([...values, {value: '', description: ''}])
    }

    function deleteLine(index) {
        if (values.length > 1) {
            let arr = [];
            for (let i = 0; i < values.length; i++) {
                if (i !== index) arr.push(values[i])
            }
            setValues([...arr]);
        }
    }

    useEffect(() => {
        if(!props.values)
            setValues([{ value: '', description: '' }])
        else
            setValues([{ value: '', description: '' }])
    }, [])

    useEffect(() => {
        props.objectChangeHandler(values, 'items')
    }, [values])

    useEffect(() => {
        if(props.update && values.length < 1){
            setValues(props.values)
        }
    }, [props.values])
    
    //items: [{},{}]
    //['Slovoq | closvo 2', 'asdasdas | asdasdasd', ]
    return (
        <>
            {values.map((item, index) =>
                <div className="two-line-card-box" key={index}>
                    <div className="two-line-card-box__header">
                        <span>{props.title} {index + 1}</span>
                        {values.length > 1 &&
                        <div className="two-line-card-box__close" onClick={() => deleteLine(index)}>×</div>}
                    </div>
                    <input type="text" value={item.value} placeholder={props.placeholder[0]}
                           onChange={(e => changeHandler(e, index, 'value'))}/>
                    <input type="text" value={item.description} placeholder={props.placeholder[1]}
                           onChange={(e => changeHandler(e, index, 'description'))}/>
                </div>
            )}
        <div className="modal-question-add-button-add" onClick={addLine}>+</div>
        </>
    )
}
