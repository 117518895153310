import React from "react";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as TeamIcon} from "components/UI/icons/team-line.svg";
import {ReactComponent as HomeIcon} from "components/UI/icons/home-8-line.svg";
import {ReactComponent as ExitIcon} from "components/UI/icons/close-circle-line.svg";
import {ReactComponent as Palette} from "components/UI/icons/palette-line.svg";
import st from "./LessonToolbar.module.scss";
import useLocalization from "hooks/useLocalization";

const LessonToolbar = props => {
  const history = useHistory()

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'lesson',
    dict: {
      "lesson": "Lesson",
      "homework": "Homework",
      "exit": "Exit",
    }
  })

  const lessonButton =
    <div className={st.button}>
      <TeamIcon/>
      <span>{translate?.lesson}</span>
    </div>

  const homeworkButton =
    <div className={st.button}>
      <HomeIcon/>
      <span>{translate?.homework}</span>
    </div>

  return (
    <div className={st.lessonToolbar}>
      <div className={st.buttons}>
        {!props.withoutButtons && <>
          {props.lessonLink ?
            <Link to={props.lessonLink} className={st.link}>{lessonButton}</Link> : lessonButton}
          <div className={st.separator}/>
          {props.homeworkLink ?
            <Link to={props.homeworkLink} className={st.link}>{homeworkButton}</Link> : homeworkButton}
        </>}
          {props.isDrawingBoard &&
              <>
                  <div className={st.separator}/>
                  <div className={`${st.button}  ${st.drawing_board}`}
                       onClick={() => props.setShowBoard(!props.showBoard)}>
                      <Palette/>
                      <span>{props.showBoard ? 'Закрыть доску' : 'Рисовать на доске'}</span>
                  </div>
              </>
          }
      </div>
      <div onClick={() => history.push('/')} className={st.button}>
        <span>{translate?.exit}</span>
        <ExitIcon />
      </div>
    </div>
  )
}

export default LessonToolbar;
