import React, {useEffect, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import {NavLink} from "react-router-dom";
import {ExcelRowsParams} from "../../../utils/ExcelExport";



const GroupLaunchSpeed = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
        setFilter,
    } = useTableFixedHeader();
    const [catalog, setCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            placeholder: 'Локация',
            values: catalog?.locations,
            sort_key: 'name',
            nameState: 'location',
            is_multi: true,
        },
        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: 'checkbox',
            label: 'Все даты',
            name: 'all_dates'
        }
    ]

    const cols = [
        {
            name: 'ФИО ученика',
        },
        {
            name: 'Письменный тест',
        },
        {
            name: 'Устный тест',
        },
        {
            name: 'Первое занятие',
        },
        {
            name: 'Количество дней',
        },
    ];

    const onChangeFilter = (obj) => {
        const filterData = {
            ...obj,
            ...(obj.date_from && { date_from: obj.date_from }),
            ...(obj.date_until && { date_until: obj.date_until }),
        }
        setFilter(filterData);
        if (obj.date_from && obj.date_until) getFilteredData(filterData);
    }
    const getFilteredData = (filter) => {
        setIsLoading(true)

        const dateFrom = filter.date_from ? new Date(filter.date_from) : null

        const dateUntil = filter.date_until ? new Date(filter.date_until) : null
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/group_launch_speed',
            params: {
                ...filter, // Дату начала устанавливаем на первый день месяца
                date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"),
                ...(dateUntil &&
                    { // Дату конца устанавливаем на последний день месяца
                        date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                    })
            },
        }).then(({data}) => {
            setData(data.report)
            setIsLoading(false)
        }).catch(() => {setIsLoading(false)})
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/group_launch_speed',
        })
            .then(({data}) => {
                setIsLoading(false)
                setCatalog(data)
            })
            .catch(() => setIsLoading(false))
    }, [])

    const downloadExcel = () => {
        import('xlsx-js-style').then(XLSX => {
            const headRows = [
                [
                    {
                        v: "ФИО ученика",
                        ...ExcelRowsParams.headerRowParam
                    },
                    {
                        v: "Письменный тест",
                        ...ExcelRowsParams.headerRowParam
                    },
                    {
                        v: "Устный тест",
                        ...ExcelRowsParams.headerRowParam
                    },
                    {
                        v: "Первое занятие",
                        ...ExcelRowsParams.headerRowParam
                    },
                    {
                        v: "Количество дней",
                        ...ExcelRowsParams.headerRowParam
                    },
                ],
            ]

            let wscols = [
                {wch: 40},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];

            let rows = [
                {hpx: 40},
                {hpx: 40},
            ]

            let tableData = []


            data.forEach(client => {
                    tableData.push([
                        {
                            v: `${client.name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${client.written_test_date}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${client.verbal_test_date}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${client.first_lesson_date}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${getDaysAwaitingGroup(client.first_lesson_date, client.written_test_date)}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                }
            )

            tableData.unshift([
                {
                    v: `Итого (среднее количество дней)`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${Math.round(data.reduce((acc, row) => acc + getDaysAwaitingGroup(row.first_lesson_date, row.written_test_date), 0) / data.length)}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ])


            let wb = XLSX.utils.book_new()
            const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData])
            ws['!rows'] = rows
            ws['!cols'] = wscols;

            XLSX.utils.book_append_sheet(wb, ws, "List 1")
            XLSX.writeFile(wb, "Скорость запуска групп с 1 теста до 1 занятия.xlsx");
        })
    }

    const getDaysAwaitingGroup = (first_lesson_date, test_start_date) => Math.floor((new Date(first_lesson_date) - ((test_start_date ? new Date(test_start_date) : new Date()))) / (24 * 60 * 60 * 1000))

    return (
        <div>
            <ContentHeader title="Скорость запуска групп с 1 теста до 1 занятия" />
            <Filter config={filterCnf} getFilterParams={onChangeFilter} drop={() => setFilter({})}/>

            {isLoading
                ? <Loader />
                : data && data.length > 0 ? (
                    <>
                        <div className={st.tableWrapper} ref={refWrapper}>
                            <div ref={refSticky} className={st.sticky}>
                                <table className={st.table}>
                                    <THead cols={cols} />
                                </table>
                            </div>
                            <table className={`${st.table} ${st.tbodyStriped}`}>
                                <THead cols={cols} ref={refThead} />
                                <tbody className={st.tbody}>
                                <tr>
                                    <td className={st.tdTotal}>Итого (среднее количество дней)</td>
                                    <td className={st.tdTotal}>
                                    </td>
                                    <td className={st.tdTotal}>
                                    </td>
                                    <td className={st.tdTotal}>
                                    </td>
                                    <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                        {Math.round(data.reduce((acc, row) => acc + getDaysAwaitingGroup(row.first_lesson_date, row.written_test_date), 0) / data.length)}
                                    </td>
                                </tr>
                                {Array.isArray(data) &&
                                    <>
                                        {data.map((row, index) =>
                                            <tr key={index}>
                                                <td style={{fontSize: '14px'}}>
                                                    <NavLink to={'/account_clients/' + row.user_id} target="_blank">
                                                        {row.name}
                                                    </NavLink>
                                                </td>
                                                <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.written_test_date ? row.written_test_date : '-'}</td>
                                                <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.verbal_test_date ? row.verbal_test_date : '-'}</td>
                                                <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.first_lesson_date}</td>
                                                <td className={`${st.textAlignCenter} ${st.fz12}`}>{getDaysAwaitingGroup(row.first_lesson_date, row.written_test_date)}</td>
                                            </tr>
                                        )}

                                    </>
                                }
                                </tbody>
                            </table>
                        </div>
                        <div style={{margin: '10px'}}>
                            <ExportExcelButton downloadExcel={downloadExcel} data={data}/>
                        </div>
                    </>
                ) : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </div>
    )
}

export default GroupLaunchSpeed