import React, {useEffect, useState} from 'react';
import styles from "./SingleWorkTable.module.scss"
import {superAxiosRequest} from "axiosApi";
import modalStore from 'Stores/modalStore';
import Loader from '../../../UI/Loader/Loader';


const SingleWorkTable = (props) => {
    const ID = +props.match.params['id']
    const [userData, setUserData] = useState()
    const [workTableData, setWorkTableData] = useState([])


    const changeState = (track) => {
        let newState = [...workTableData]
        newState = newState.filter(item => item.id !== track.id)
        newState.push(track)
        setWorkTableData(newState.sort((a, b) => new Date(b.date) - new Date(a.date)))
    }
    const openModal = (modalData, actionType) => {
        const modalTemlates = {
            in: {
                title: `Приход`,
                type: 'work-table-modal-in'
            },
            out: {
                title: `Уход`,
                type: 'work-table-modal-out'
            }
        }

        const ms = {
            name: 'work-table-modal',
            button: 'Сохранить',
            link: `employees/tracks/${modalData.id}`,
            title: modalTemlates[actionType].title,
            type: modalTemlates[actionType].type,
            data: 'json',
            is_put: true,
            func: (track) => changeState(track)
        }
        let md = {
            id: modalData.id,
            in_latitude: modalData.in_latitude,
            in_longitude: modalData.in_longitude,
            out_latitude: modalData.out_latitude,
            out_longitude: modalData.out_longitude,
        }

        if (modalData.in_location_id) md = {...md, in_location_id: modalData.in_location_id}
        if (modalData.out_location_id) md = {...md, out_location_id: modalData.out_location_id}
        if (modalData.finish_at) md = {...md, finish_at: modalData.finish_at}
        if (modalData.start_at) md = {...md, start_at: modalData.start_at}
        if (modalData.out_reason) md = {...md, out_reason: modalData.out_reason}

        modalStore.activity(ms, md)
    }


    useEffect(() => {
        let userDataApiConf = {
            method: 'get',
            link: 'accounts/employers_flat_list', 
        }

        let apiConf = {
            method: 'get',
            link: `employees/tracks`,
            params: {
                employee_id: ID
            }

        }
        superAxiosRequest(apiConf).then(res => {
            setWorkTableData(res.data)
        })
        superAxiosRequest(userDataApiConf).then(res => {
            setUserData(res.data.result.find(item => item.id === ID))
        })
    }, [])

    return (
        <div className={styles.box}>
            {userData ?
                <>
                    <div className={styles.title}>
                        {userData.full_name}
                    </div>
                    <table className={styles.table}>
                        <thead>
                            <tr >
                                <th className={styles.headCol}>Дата</th>
                                <th className={styles.headCol}>Приход</th>
                                <th className={styles.headCol}>Уход</th>
                            </tr>
                        </thead>
                        {workTableData.length > 0 ?
                            <tbody>
                                {workTableData.map((row, id) =>
                                    <tr key={id} >
                                        <td className={styles.col} ><div className={styles.item}>{row.date}</div></td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                <div className="">{row.start_at}</div>
                                                <div className="">{row.in_loc_name}</div>
                                                <div className="">{row.in_latitude} | {row.in_longitude}</div>
                                                <div className={styles.subrow_change} onClick={() => openModal(row, 'in')}>(изменить)</div>
                                            </div>
                                        </td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                <div className="">{row.finish_at}</div>
                                                <div className="">{row.out_loc_name}</div>
                                                <div className="">{row.out_latitude} | {row.out_longitude}</div>
                                                <div className={styles.subrow_change} onClick={() => openModal(row, 'out')}>(изменить)</div>
                                            </div>
                                        </td>
                                    </tr>)}

                            </tbody> :
                            <tbody>
                                <tr className={styles.row}>
                                    <td className={styles.col}>Данных нет</td>
                                    <td className={styles.col}>Данных нет</td>
                                    <td className={styles.col}>Данных нет</td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </> :
                <Loader />
            }

        </div>
    )
}

export default SingleWorkTable
