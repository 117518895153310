import React from 'react';
import { observer } from 'mobx-react';
import TimeSlotsWrapper from 'components/Modules/Slots/components/TimeSlotsWrapper/TimeSlotsWrapper';
import { ActionType, DataType } from 'Stores/slotsNewRecordOnlineStore';
import { sortSlotByStartTime } from 'components/Modules/Slots/utils';

function Slots({ store }) {
    const { slots, toggles, selectedLocation, selectedSlots, resetData, setData } = store;

    const handleSlotClick = () => {
        if (selectedSlots.size === 0) {
            resetData(ActionType.resetSelectedSlots);
            return;
        }
        resetData(ActionType.resetClientData);
    };

    const handleTimeZoneChange = (timeZone) => {
        setData(timeZone, DataType.timeZone);
    };

    return <TimeSlotsWrapper
        slots={slots.slice().sort(sortSlotByStartTime)}
        selectedSlots={selectedSlots}
        toggles={toggles}
        onReset={() => resetData(ActionType.resetSelectedSlots)}
        onClick={handleSlotClick}
        defaultTimeZone={selectedLocation.gmt}
        onTimeZoneChange={handleTimeZoneChange}
        onTogglesChange={(payload) => setData(payload, DataType.toggles)}
    />
}

export default observer(Slots);
