import React, {useEffect, useState} from 'react';
import { stringToBoolean } from '../../../../helpers/index';

export default function QuestionMakeSentence(props) {

    const [words, setWords] = useState([])
    const [answers, setAnswers] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        setAnswersButton(!words.length)
        props.handleAnswers(answers, answersButton)
    }, [answers, answersButton])

    let dragged, dragIndex

    const onDragStartHandler = (e, i) => {
        dragged = e.target
        dragIndex = i
        e.target.classList.add('onDrag')
    }

    const onDragEndHandler = e => {
        e.target.classList.remove('onDrag')
    }

    const onDragEnterHandler = e => {
        e.target.classList.add('onDragOver')
    }

    const onDragLeaveHandler = e => {
        e.target.classList.remove('onDragOver')
    }

    const onDragOverHandler = e => {
        // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
        if (dragged)
            // prevent default to allow drop
            e.preventDefault()
    }

    const onDropHandler = ({target}, index) => {
        target.classList.remove('onDragOver')

        // Удаляем элемент из слов
        const word = words[dragIndex]
        setWords(words.filter((x,i) => i!==dragIndex))

        const arr = answers.slice()
        arr[index] = word
        setAnswers(arr)
    }

    const dropZone = index =>
        answers[index] ?
            <span
                key={index}
                onClick={() => {
                    let arr

                    const word = answers[index]

                    arr = answers.slice()
                    arr[index] = ''
                    setAnswers(arr)

                    arr = words.slice()
                    arr.push(word)
                    setWords(arr)
                }}>
            {answers[index]}
            </span>
            :
            <span
                key={index}
                onDragEnter={onDragEnterHandler}
                onDragLeave={onDragLeaveHandler}
                onDragOver={onDragOverHandler}
                onDrop={e=>{onDropHandler(e, index)}}
            />

    const dropZoneWithAnswers = index =>
        props.answ_array[index] ?
            <span key={index} className={props.correct_answ[0] ? 'lessonQuestion__correctAnswer' : 'lessonQuestion__wrongAnswer'}>
                {props.answ_array[index]}
            </span> :
            null

    useEffect(() => {
        const arr = props.sentence.split('|')
        setWords(arr)
        setAnswers(new Array(arr.length).fill(''))
    }, [props.sentence])

    useEffect(() => {
        if (!props.answ_array?.length) return
        setWords([])
        setAnswers([...props.answ_array])
    }, [props.answ_array])

    /*useEffect(() => {
        const sentence = answers.join('|')
        let arr = [sentence]
        if(answers.length === props.sentence.split('|').length) props.answers(arr)
    }, [answers])*/

    return (
        <div>
            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) ?
            <>
            <div className="lessonQuestion__wordsBox">
                {words.map((word, index) =>
                    <span key={index} draggable onDragStart={e=>{onDragStartHandler(e, index)}} onDragEnd={onDragEndHandler}>
                        {word}
                    </span>
                )}
            </div>
            <div className="questionMakeSentence__sentence">
                {answers.map((word, index) =>
                    dropZone(index)
                )}
            </div>
            </> :
            null
            }
            {(props.is_student_checked_try || (props.is_teacher_view && props.at)) ?
            <div className="questionMakeSentence__sentence">
                {answers.map((word, index) =>
                    dropZoneWithAnswers(index)
                )}
            </div>:
            null            
            }
        </div>
    );
};