import React from 'react';
import styles from './Empty.module.scss';
import EmptyGroup from 'components/ContentBox/img/empty-group.png';
import { Link } from 'react-router-dom';
import Stores from 'Stores';
import { useContext } from 'react';
import { LanguageContext } from '../../constants';

const Empty = () => {
    const { translate } = useContext(LanguageContext);
    return (
        <div className={styles.empty}>
            <img src={EmptyGroup} alt="empty" className={styles.empty__image} />
            <p className={styles.empty__title}>{translate.empty_text}</p>
            {!Stores.isClient && (
                <Link className={styles.empty__btn} to='/groups/all-groups'>
                    {translate.empty_btn}
                </Link>
            )}
        </div>
    )
};


export default Empty;