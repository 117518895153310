import React from 'react';
import PropTypes from 'prop-types';
import Stores from "Stores";
import useFileUpload from "hooks/useFileUpload";
import st from './AudioFile.module.scss'
import FormField from "components/UI/AsideModal/Form/FormField";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FileSelector from "components/UI/FileSelector/FileSelector";
import VariantDelete from "../VariantDelete/VariantDelete";

const AudioFile = props => {
  const {refInput, progress, setFile} = useFileUpload({
    onUploadEnd: file => props.onChange({...props.value, file})
  })
  return (
    <div>
      {props.value.file ?
          <div className={st.container}>
            <div className={st.box}>
              <div>
                <audio src={`${Stores.baseLink()}${props.value.file}`} controls/>
              </div>
              <div className={st.remove}>
                <VariantDelete onClick={props.remove}/>
              </div>
            </div>
            <FormField>
              <FormInput>
                <input type="text"
                       value={props.value.description}
                       placeholder="Audio File Description"
                       onChange={e => props.onChange({...props.value, description: e.target.value})}
                />
              </FormInput>
            </FormField>

            <FormField>
              <textarea value={props.value.data}
              placeholder="Script for Audio File"
              onChange={e => props.onChange({...props.value, data: e.target.value})}
              />
            </FormField>
          </div>
        :
          <FileSelector
              error={props.isError}
              type={props.type}
              types={props.allowedExtension}
              value={''}
              onChange={(file) => props.onChange({...props.file, file})}
          />
      }


    </div>
  )
}

AudioFile.propTypes = {
  value: PropTypes.shape({
    file: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool
}

export default AudioFile
