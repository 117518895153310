import React from "react";
import st from "./BookInfo.module.scss"

export default function BookInfo({book, translate}){
  return (
    <div className={st.bookInfo}>
      <ul>
        <li>
          <span className={st.paramName}>{translate.education_type}</span>
          <span className={st.paramValue}>{book.course_level}</span>
        </li>
        <li>
          <span className={st.paramName}>{translate.group_level}</span>
          <span className={st.paramValue}>{book.skill_level}</span>
        </li>
      </ul>
    </div>
  )
}
