import React, {Fragment, useEffect, useState} from 'react'
import {superAxiosRequest} from 'axiosApi';
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import ContentHeader from 'components/UI/ContentHeader/ContentHeader'
import THead from "../../../ContentBox/Global/ReportTable/THead";
import Loader from "../../../ContentBox/Global/Loader/Loader";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import {ExcelRowsParams, setCellParams} from "../../../utils/ExcelExport";



const Conversion = () => {
    const [catalog, setCatalog] = useState({})

    const {
        refWrapper,
        refSticky,
        refThead,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader()


    const filterCfg = [
        {
            type: 'select-custom',
            nameState: 'location',
            placeholder: 'Локация',
            values: catalog.locations?.sort((a, b) => a.name > b.name ? 1 : -1) || [],
            option_value_key: 'id',
            option_label_key: 'name',
        },
        {
            type: 'select-custom',
            placeholder: 'Тип программы',
            values: catalog.program_types,
            sort_key: 'name',
            nameState: 'program_type',
            is_search: true,
        },
        {type: 'month-range-picker', nameState: ['finished_date_from', 'finished_date_until']},
    ]

    const cfg = {
        cols: [
            {
                name: 'Локация'
            },
            {
                name: 'Тип программы'
            },
            {
                name: 'Группа'
            },
            {
                name: 'Дата начала группы'
            },
            {
                name: 'Дата закрытия группы'
            },
            {
                name: 'Количество успешных студентов'
            },
            {
                name: 'Количество неуспешных студентов'
            },
            {
                name: 'Конверсия'
            },
        ]
    }

    const onChangeFilter = (obj) => {
        const filterData = {
            ...obj,
            ...(obj.finished_date_from && {finished_date_from: obj.finished_date_from}),
            ...(obj.finished_date_until && {finished_date_until: obj.finished_date_until}),
        }
        setFilter(filterData);
        if (obj.finished_date_from && obj.finished_date_until) getFilteredData(filterData);
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/students_group_conversion',
        }).then(({data}) => {
            setIsLoading(false)
            setCatalog(data)
        })
    }, [])


    const dateConverter = (date) => dateFormat(new Date(date), "YYYY-mm-dd");
    const getFilteredData = (filter) => {
        setIsLoading(true)
        // Дату начала устанавливаем на первый день месяца
        const dateFrom = filter.finished_date_from ? new Date(filter.finished_date_from) : null
        // Дату конца устанавливаем на последний день месяца
        const dateUntil = filter.finished_date_until ? new Date(filter.finished_date_until) : null
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/students_group_conversion',
            params: {
                ...filter,
                finished_date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"), ...(dateUntil &&
                    {
                        finished_date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                    })
            },
        }).then(({data}) => {
            const filteredData = data.filter(obj => {
                const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000
                return (new Date(obj.group_finished_at) - new Date(obj.group_started_at)) >= twoDaysInMillis
            })
            const groupedData = filteredData.reduce((groups, item) => {
                const key = item.location_name
                if (!groups[key]) {
                    groups[key] = []
                }
                groups[key].push(item)
                return groups
            }, {})
            setData(groupedData)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }


    const downloadExcel = () => {
        import('xlsx-js-style').then(XLSX => {
            const headRows = [
                [
                    {
                        v: "Локация",
                    },
                    {
                        v: "Тип программы",
                    },
                    {
                        v: "Группа",
                    },
                    {
                        v: "Дата начала группы",
                    },
                    {
                        v: "Дата закрытия группы",
                    },
                    {
                        v: "Количество успешных студентов",
                    },
                    {
                        v: "Количество неуспешных студентов",
                    },
                    {
                        v: "Конверсия",
                    },
                ],
            ]

            headRows[0] = setCellParams(headRows[0], ExcelRowsParams.headerRowParam)

            let wscols = [
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];

            let rows = [
                {hpx: 40},
                {hpx: 40},
            ]

            let tableData = []
            let merges = []

            let currentRow = 1

            Object.keys(data).map(location => {

                merges.push(
                    {s: {r: currentRow, c: 0}, e: {r: currentRow + data[location].length - 1, c: 0}}
                )
                data[location].forEach(group => {
                        currentRow = currentRow + 1
                        tableData.push([
                            {
                                v: `${group.location_name}`,
                                ...ExcelRowsParams.tableRowParam.firstCell
                            },
                            {
                                v: `${group.program_type}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${group.group_name}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${dateConverter(group.group_started_at)}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${dateConverter(group.group_finished_at)}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${group.count_successful_students}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${group.count_unsuccessful_students}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${group.conversion}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                        ])
                    }
                )
            })


            let wb = XLSX.utils.book_new()
            const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData])
            ws['!rows'] = rows
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1")
            XLSX.writeFile(wb, "Конверсия.xlsx");
        })
    }

    return (
        <>
            <ContentHeader title="Конверсия"/>

            <Filter config={filterCfg} getFilterParams={onChangeFilter} drop={() => {
                setFilter({})
            }}/>

            {isLoading ? <Loader/> : data ? (
                <div className={st.tableWrapper} ref={refWrapper}>
                    <div ref={refSticky} className={st.sticky}>
                        <table className={st.table}>
                            <THead cols={cfg.cols} />
                        </table>
                    </div>
                    <table className={st.table}>
                        <THead cols={cfg.cols} ref={refThead} />
                        <tbody className={`${st.tbody} ${st.tbodyStriped}`}>
                        {Object.keys(data).map((locationName, index) => (
                            <Fragment key={index}>
                                {Array.isArray(data[locationName]) ? (
                                    data[locationName].map((el, subIndex) => (
                                        <tr key={`${index}-${subIndex}`} className={subIndex === 0 ? st.bdt : ''}>
                                            {subIndex === 0 ? (
                                                <td
                                                    className={`${st.color} ${st.fw500} ${index % 2 === 0 ? st.bgw : st.bgg}`}
                                                    rowSpan={data[locationName].length}
                                                >
                                                    {locationName}
                                                </td>
                                            ) : null}
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.program_type || '-'}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.group_name}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{dateConverter(el.group_started_at)}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{dateConverter(el.group_finished_at)}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.count_successful_students}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.count_unsuccessful_students}</td>
                                            <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.conversion}</td>
                                        </tr>
                                    ))
                                ) : null}
                            </Fragment>
                        ))}
                        </tbody>
                    </table>
                    <button onClick={downloadExcel} style={{
                        margin: '10px 10px 0 ',
                        width: 'calc(100% - 20px)'
                    }}>Выгрузить в Excel
                    </button>
                </div>
            ) : (
                <h1 className={st.noData}>Нет данных для отображения!</h1>
            )}
        </>
    );
}

export default Conversion;