import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import modalStore from "Stores/modalStore";
import { superAxiosRequest } from "axiosApi";
import { localisationMaster } from 'Localisation/Localisation';
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import swal from "sweetalert";
import Loader from 'components/UI/Loader/Loader';
import style from './HomeWorks.module.scss'
import HomeTask from "components/Modules/Groups/components/HomeTasks/HomeTask/HomeTask";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";

const HomeWorks = (props) => {
    const id = props.match.params.id;
    const [homeworks, setHomeworks] = useState({ loading: true, list: [], group: null });

    const list = [
       { name: 'Редактировать', action: 'edit' },
       { name: 'Удалить', action: 'delete' }
    ];

    const modal_settings = {
        link: 'groups/interim_homework',
        button: localisationMaster('text_54', 'groups/all-groups'),
        title: localisationMaster('homework_lesson_plan', 'groups/group'),
        type: 'SelectorAndInput',
        active_inputs: ['dateG2', 'textarea_tags'],
        name_textarea_tags: 'description',
        pl_textarea_tags: localisationMaster('table-row-title-2', 'pays/all-pays'),
        date_name: 'date',
        date_label: localisationMaster('lesson_date', 'groups/group'),
        data: 'json',
        func: (data) => setHomeworks({ ...homeworks, list: data.homeworks }),
        valid: {
            description: 'required',
        },
    }

    const actions = (data, e, item) => {
        switch (data) {
            case 'edit':
                let ms = { ...modal_settings, is_put: true, link: modal_settings.link + '/' + item.id }
                let toEdit = { ...item }
                delete toEdit.group
                delete toEdit.teacher
                delete toEdit.id
                toEdit.teacher = item.teacher.id
                toEdit.group = homeworks.group.id
                modalStore.activity(ms, toEdit)
                break;
            case 'delete':
                swal({
                    title: `Вы действительно хотите удалить "Домашнее задание" за ${timeConverter(item.date, 'clean_data')} г.?`,
                    text: 'Восстановить удаленные данные будет невозможно.',
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                }).then(okay => {
                    if (!okay) return;
                    setHomeworks({ ...homeworks, loading: true });
                    superAxiosRequest({ link: modal_settings.link + '/' + item.id, method: 'delete' }).then(({data}) => {
                        setHomeworks({ ...homeworks, loading: false, list: data.homeworks });
                    })
                });
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        superAxiosRequest({ link: 'groups/interim_homework', method: "get", params: { group_id: id } }).then(({ data }) => {
            setHomeworks({ loading: false, list: data.homeworks, group: data.group });
        });
    }, [id])

    if (homeworks.loading) return <Loader />
    return (
        <>
            {/*<ContentHeader*/}
            {/*    title={`Домашние задания: ${homeworks.group?.number} - ${homeworks.group?.name}`}*/}
            {/*    modal_type={!isClient && "GlobalSuperModal"}*/}
            {/*    button={!isClient && ['Добавить', 'view_homeworks_group']}*/}
            {/*    breadСrumbs={*/}
            {/*        isClient ?*/}
            {/*            [ { title: `Группа: ${homeworks.group?.number} - ${homeworks.group?.name}`, route: `/groups/group/${homeworks.group?.id}` } ]*/}
            {/*        : [*/}
            {/*            { title: 'Учебные группы', route: '/groups/all-groups' },*/}
            {/*            { title: `Группа: ${homeworks.group?.number} - ${homeworks.group?.name}`, route: `/groups/group/${homeworks.group?.id}` },*/}
            {/*        ]*/}
            {/*    }*/}
            {/*    modal_settings={{*/}
            {/*        title: localisationMaster('homework_lesson_plan', 'groups/group'),*/}
            {/*        button: localisationMaster('text_54', 'groups/all-groups'),*/}
            {/*        modalBodyComponent: () => <ModalHomeWork />,*/}
            {/*        valid: {*/}
            {/*            description: 'required',*/}
            {/*        },*/}
            {/*        link: 'groups/interim_homework',*/}
            {/*        func: (data) => setHomeworks({ ...homeworks, list: data.homeworks }),*/}
            {/*    }}*/}
            {/*    modalValues={{*/}
            {/*        date: dateFormat(new Date(), "YYYY-mm-dd"),*/}
            {/*        group: id,*/}
            {/*    }}*/}
            {/*/>*/}
            <div className={style.homeWorksGroup}>
                {homeworks.list.length > 0 ? homeworks.list.map((el,index) =>
                    <HomeTask key={index} item={el} actions={actions} menu={list} listing={true}/>
                ) : <div className={style.empty}>
                    <EmptyData/>
                </div>
                }
            </div>
        </>
    )
}

export default observer(HomeWorks);