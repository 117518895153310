import React from 'react';
import FormLabel from "../../../../../UI/AsideModal/Form/FormLabel";
import SelectCustom from "../../../Select/SelectCustom";
import FormField from "../../../../../UI/AsideModal/Form/FormField";
import FormDateSelector from "../../../../../UI/AsideModal/Form/FormDateSelector";
import asideModalStore from "../../../../../../Stores/asideModalStore";
import {observer} from "mobx-react";
import FormFieldset from "../../../../../UI/AsideModal/Form/FormFieldset";

const EditSubscription = ({subscriptions}) => {

    const {changeValue, modalValues, check} = asideModalStore
    return (
        <FormFieldset>
            <FormField>
                <FormLabel isRequired>Выберите абонемент:</FormLabel>
                <SelectCustom placeholder="Выберите абонемент"
                              options={subscriptions}
                              valueID={modalValues.subscription}
                              onChange={o => changeValue(o.id, 'subscription')}
                              error={check('subscription')}
                />
            </FormField>
                <FormDateSelector
                    placeholder="Выберите дату"
                    label={'Продлить до:'}
                    isRequired
                    onChange={sDate => changeValue(sDate, 'date_until')}
                    value={modalValues.date_until || ""}
                    error={check('date_until')}
                />
        </FormFieldset>
    );
};

export default observer(EditSubscription);