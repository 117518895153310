import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from "axiosApi";
import modalStore from "Stores/modalStore";
import Idlist from "../../Idlist/idlist";
import { toJS } from 'mobx';
import SelectedField from '../../Idlist/SelectedField';

export default function ExerciseShowToStudents(props) {
    const {modalValue, changeValues} = modalStore
    const [prevSelected, setprevSelected] = useState([])
    /* const [students, setStudents] = useState([])
    const [selected, setSelected] = useState([])
    const [prevSelected, setprevSelected] = useState([]) */
    const [list, setList] = useState({
        available: [],
        selected: [],
    })

    useEffect(() => {
        const apiConf = {
            method: 'get',
            //link: `lessons/get_free_students/${modalValue.init_lesson}/${modalValue.exercise}`,
            link: `lessons/get_free_students/${modalValue.init_lesson}`,
            params: {
                exercise_id: modalValue.exercise,
            }
        }
        superAxiosRequest(apiConf).then(res => {
            const arr = res.data.result.map(x => ({id: x.id, name: x.full_name}))
            const selecteds = toJS(props.allStudents).filter(x => x.id !== 0 && !arr.find(a => a.id === x.id)).map(x => ({id: x.id, name: x.full_name}))
            setprevSelected([...selecteds])
            setList({available: arr, selected: selecteds})
        })
    }, [])

    useEffect(() => {
        changeValues(list.selected.map(x => x.id), 'students')
        if(prevSelected.length){
           let ids = [];
            for(let x = 0; x < prevSelected.length; x++){
                if(!list.selected.find(a => a.id === prevSelected[x].id)) ids.push(prevSelected[x].id)
           }
        changeValues(ids, 'deleted_students')
        }
    }, [list])

    return (
        <div>
            <SelectedField list={list} onChange={setList} />
        </div>
    );
};