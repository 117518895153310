import React from 'react';
import st from "./TestRules.module.scss"
import Image from "../../../../ContentBox/img/tesst_pic.svg";

export default function TestRules({testInfo, beginTest}) {
    return (
        <div className={st.wrap}>
            <div className={st.rules}>
                <img src={Image} alt=""/>

                <h1 className={st.title}><b>Информация о</b>тестировании</h1>
              
                <ul className={st.rule}>
                    <li>
                        Попыток:&nbsp;<strong>{testInfo.try_count}</strong>, по&nbsp;
                        <strong>{testInfo.during} мин</strong>.
                    </li>
                    <li>
                        Вопросов:&nbsp;<strong>{testInfo.questions_count}</strong>
                    </li>
                </ul>

                {(testInfo.focus_live || testInfo.mouse_freeze) &&
                <div className={st.denied}>
                    <span className={st.deniedTitle}>Запрещено:</span>

                    <ul className={st.deniedList}>
                        {testInfo.focus_live &&
                        <li>
                            <div>
                                <span>Менять фокус окна</span>
                                <small>(переключаться между окнами)</small>
                            </div>
                        </li>}

                        {testInfo.mouse_freeze &&
                        <li>
                            <div>
                                <span>Замирание мыши</span>
                                <small>(останавливать мышь)</small>
                            </div>
                        </li>}
                    </ul>
                </div>}

                <div className={st.buttonBox}>
                    {testInfo.try_count - testInfo.attempts_count > 0 &&
                    <div className={st.button} onClick={beginTest}>Начать тестирование!</div>}

                    <div className={st.count}>
                        {`Осталось ${testInfo.try_count - testInfo.attempts_count} из ${testInfo.try_count}`}
                    </div>
                </div>
            </div>
        </div>
    );
};
