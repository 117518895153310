import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";

import './TeachersSelector.scss';


const TeachersSelector = React.forwardRef((props, ref) => {

   const [teachers, setTeachers] = useState([]);

   useEffect(() => {
      if (props.slotId !== 0 && props.someDay) {
         const apiConf = {
            method: 'get',
            link: 'time_slots/teacher/in_slot',
            params: {
               slot_id: props.slotId,
               date: props.someDay,
               age_id: props.age_id,
               program_id: props.program_id,
            }
         }

         superAxiosRequest(apiConf, 'data').then( response => setTeachers(response.data));
      }
   },[props.slotId, props.someDay, props.age_id, props.program_id]);

   return (
      <div className="teacherSelector" ref={ref}>
         <span className="teacherSelector__text">Выберите преподавателя на это время</span>
         <div className="teacherSelector__select">
            <SelectCustom
                placeholder={localisationMaster('teacher', 'online-schedule/record-customer')}
                options={teachers}
                getOptionLabel={opt => `${opt.last_name} ${opt.first_name} ${opt.father_name}`}
                valueID={props.teacher_id}
                onChange={o => props.setDataTeacherId(o.id)}
                isLoading={teachers.length === 0}
            />
         </div>
      </div>
   )
})

export default TeachersSelector
