import React, {useEffect, useState, useRef} from 'react';
import styles from './InteractiveTimeTable.module.scss';
import Flex from 'components/UI/Flex/Flex';
import ArrowButton from 'components/UI/Calendar/components/ArrowButton/ArrowButton';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import TimeTableSlot from './TimeTableSlot';
import TimeTableDatePicker from './TimeTableDatePicker/TimeTableDatePicker';
import {localisationMaster} from "Localisation/Localisation";
import modalStore from 'Stores/modalStore';
import swal from "sweetalert";
import {getWeekDay} from '../../VisualScheduleTestPage';



const InteractiveTimeTable = ({data, date, setDate, loader, filterStatusData, changeLesson}) => {
    let workStartTime = (new Date()).setHours(5, 0, 0, 0)
    let workFinishTime = (new Date()).setHours(24, 0, 0, 0)

    const getWorkHourseArray = () => {
        const workHourseArray = []
        for (let i = 0; i <= (workFinishTime - workStartTime) / 3600000; i++) workHourseArray.push(i + (new Date(workStartTime)).getHours())
        return workHourseArray
    }
    const getDate = (firstWeekDay, rowId) => {
        return new Date(firstWeekDay - rowId * -24 * 60 * 60 * 1000)
    }

    const [timeLineState, setTimeLineState] = useState([])

    const createSheduleItems = (schedules, schooldays, teachersArray, date) => {
        const statusTemplate = {
            1: {
                color: "#626262",
                text: "Частично оплачиваемое занятие"
            },

            2: {
                color: "#626262",
                text: "Бесплатное занятие"
            },

            3: {
                color: "#626262",
                text: "Оплачиваемый пропуск"
            },

            4: {
                color: "#626262",
                text: "Частично оплачиваемый пропуск"
            },

            5: {
                color: "#626262",
                text: "Неоплачиваемый пропуск"
            },
            7: {
                color: "#626262",
                text: "Занятие"
            },
            9: {
                color: "#626262",
                text: "Отмененный урок"
            },
        }
        const getColor = (schedule, schoolday) => {
            if (schoolday) {
                return schedule.color ? schedule.color : (schoolday.status ? statusTemplate[schoolday.status].color : "rgb(235, 192, 95)")
            }
            return schedule.color ? schedule.color : "rgb(235, 192, 95)"
        }
        const getTeachers = (schedule, teachersArray) => { //возвращает массив с учителями 
            let teachers = []
            schedule.teachers.forEach(teacherId => teachers.push(...teachersArray.filter(teacher => teacher.id === teacherId)))
            return teachers
        }
        return schedules.map((schedule) => {
            const startTimeArray = schedule.start_at.split(':')
            const finishTimeArray = schedule.finish_at.split(':')
            const schoolday = schooldays.find(day => day.schedule_id === schedule.id)
            return {
                ...schedule,
                timeStart: new Date(date).setHours(startTimeArray[0], startTimeArray[1], startTimeArray[2], 0),
                timeEnd: new Date(date).setHours(finishTimeArray[0], finishTimeArray[1], finishTimeArray[2], 0),
                workStartTime: (new Date(date)).setHours(5, 0, 0, 0),
                color: getColor(schedule, schoolday),
                border: schedule.border ? schedule.border : '1px solid #bdbdbd',
                durationMinutes: (new Date(date).setHours(finishTimeArray[0], finishTimeArray[1], finishTimeArray[2], 0) - (new Date(date)).setHours(startTimeArray[0], startTimeArray[1], startTimeArray[2], 0)) / 60000,
                holidays: [],
                status: schoolday ? schoolday.status : 1,
                comment: schoolday ? schoolday.comment : '',
                textStatus: schoolday ? statusTemplate[schoolday.status].text : "Занятие",
                teachers: getTeachers(schedule, teachersArray)
            }
        })
    }
    const createShedule = (schedules, schooldays, teachers) => {
        let weekDays = [
            {title: 'ПН'},
            {title: 'ВТ'},
            {title: 'СР'},
            {title: 'ЧТ'},
            {title: 'ПТ'},
            {title: 'СБ'},
            {title: 'ВС'},
        ]

        weekDays = weekDays.map((day, id) => ({...day, date: timeConverter(new Date(date.firstWeekDay - id * -24 * 60 * 60 * 1000), "day").toLowerCase()}))
        const weekSchedules = schedules.filter(filterShedules)
        const sheduleItems = weekDays.map((day, rowId) => {
            let todaysSchedule = weekSchedules.filter(item => filterDaysShedules(item, rowId))
            todaysSchedule = createSheduleItems(todaysSchedule, schooldays, teachers, getDate(date.firstWeekDay, rowId))
            return {day: day, schedules: todaysSchedule.filter(item => filterStatusShedules(item, filterStatusData))}
        })
        return sheduleItems
    }

    const checkFreeDay = (timeLineState, newStartTime, newFinishTime) => {
        for (let i = 0; i < timeLineState.length; i++) {
            const verifyDay = timeLineState[i].schedules.find(shedule => (newFinishTime > shedule.timeStart && newStartTime < shedule.timeEnd))
            if (verifyDay) return verifyDay
        }
        return null
    }
    const transferLesson = (item) => {
        const newStartTime = new Date(((Math.round((item.newPosX - 200) / 5)) * 300000) + item.workStartTime - (- (item.newRowId - item.rowId) * 24 * 60 * 60 * 1000))
        const newFinishTime = new Date((Math.round((item.newPosX - 200 + item.durationMinutes) / 5) * 300000) + item.workStartTime - (- (item.newRowId - item.rowId) * 24 * 60 * 60 * 1000))
        const freedomTime = checkFreeDay(timeLineState, newStartTime, newFinishTime)
        if (freedomTime) {
            if (freedomTime.id !== item.id) return swal("", 'На это время уже назначен урок', "error")
        }
        const ms = {
            name: 'time_table_day',
            link: 'schedule/transfer_lesson',
            button: localisationMaster('text_54', 'groups/all-groups'),
            title: `${timeConverter((getDate(date.firstWeekDay, item.rowId)), "age")}`,
            type: 'time_table_note',
            data: 'json',
            schedule: [{
                date: timeConverter(getDate(date.firstWeekDay, item.rowId), 'today'), //today
                schedule_id: item.id,
                classroom: item.classroom,
                teachers: item.teachers,
                start_at: timeConverter(newStartTime, 'time'),
                finish_at: timeConverter(newFinishTime, 'time'),
                hours_type_minutes: item.hour_type.minutes
            }],
            g_id: item.group_id,
            is_put: false,
            sh_id: item.id,
            last: timeConverter(getDate(date.firstWeekDay, item.newRowId), 'today'),
            student: false,
            main_s: true,
            statuses: [{id: 777, name: 'Перенести на другой день'}],
            func: (data) => {
                changeLesson(data, item)
            }
        }
        modalStore.activity(ms)
    }
    const changeDate = (date) => {
        setDate({
            date: date,
            firstWeekDay: getWeekDay(date, 'firstWeekDay'),
            lastWeekDay: getWeekDay(date, 'lastWeekDay')
        })
    }
    const filterStatusShedules = (item, filterData) => {
        const filterTemplates = { // проверяет по условию фильтрации
            status(item, filterParams) {
                return item[filterParams.key] === filterParams.value
            },
            teacher(item, filterParams) {
                return Boolean(item[filterParams.key].find(teacher => teacher.id === filterParams.value))
            }
        }
        if (filterData) {
            let filterResult = true
            filterData.forEach(filterParams => { // проходиться по всем условиям фильрации  
                filterResult = filterResult * filterTemplates[filterParams.filterType](item, filterParams)
            })
            return filterResult
        }
        return true
    }

    const filterShedules = (item) => {
        const dateFrom = new Date(item.date_from)
        const dateUntil = new Date(item.date_until)
        return (dateFrom < date.lastWeekDay) && (dateUntil > date.firstWeekDay)
    }
    const filterDaysShedules = (slot, rowId) => {
        return (slot.weekdays.find(item => item === rowId + 1))
    }
    // const filterSchooldays = (item) => { 
    //     const date = new Date(item.date)
    //     return (date < date.lastWeekDay) && (date > date.firstWeekDay)
    // }


    useEffect(() => {
        if (data.schedules[0]) {
            setTimeLineState(createShedule(data.schedules, data.schooldays, data.teachers))
        }
        else {
            setTimeLineState([])
        }
    }, [data])


    return (
        <div className={styles.timeTable}>
            <div className={styles.timeTable__header}>
                <div className={styles.timeTable__date}>{`${timeConverter((date.firstWeekDay), "age")} - 
                ${timeConverter(new Date(date.lastWeekDay - (24 * 60 * 60 * 1000)), "age")}`}
                </div>
                <div className={styles.timeTable__wrapper_btn}>
                    <TimeTableDatePicker date={date.date} setDate={(date) => changeDate(date)} />
                    <Flex>
                        <ArrowButton left onClick={() => changeDate((new Date(Date.parse(date.date) - (7 * 24 * 60 * 60 * 1000))))} />
                        <ArrowButton onClick={() => changeDate((new Date(Date.parse(date.date) + (7 * 24 * 60 * 60 * 1000))))} />
                    </Flex>
                </div>
            </div>
            <div className={styles.timeTable__table}>
                {timeLineState[0] ? <>

                    {<div className={styles.timeTable__timeLine}
                        style={{width: `${200 + Math.floor(((workFinishTime - workStartTime) / 60000))}px`}}>
                        <div className={styles.timeTable__loader}>{loader && "Идет загрузка..."}</div>
                        {getWorkHourseArray().map((hour, id) => (<div key={id} className={styles.timeTable__col_hour}>{hour}</div>))}
                    </div>}
                    {timeLineState && timeLineState.map((weekDay, rowId) => (
                        <div key={rowId}
                            className={styles.timeTable__row}
                            style={{width: `${200 + Math.floor(((workFinishTime - workStartTime) / 60000))}px`}}
                        >
                            <div className={styles.timeTable__col_teacher}>{weekDay.day.title} <span>{`, ${weekDay.day.date}`}</span> </div>
                            <div className={styles.timeTable__wrapper_slot}>
                                {getWorkHourseArray().map((hour, id) => {
                                    return id + 1 < getWorkHourseArray().length && (<div key={id} className={styles.timeTable__col}></div>)
                                })}
                                {weekDay.schedules.map((slot, id) => {
                                    return <TimeTableSlot
                                        key={id}
                                        firstWeekDay={date.firstWeekDay}
                                        totalRows={timeLineState.length}
                                        startRowId={rowId}
                                        transferLesson={transferLesson}
                                        slotData={slot} />
                                })}
                            </div>
                        </div>))}

                </> :
                    <div className={styles.timeTable__row_empty} >
                        <div className={styles.timeTable__text_info}>
                            {loader ? "Идет загрузка..." : 'Нет данных'}
                        </div>
                    </div>}
            </div>
            <div className={styles.timeTable__wrapper_tooltip} id="timeTable__wrapper_tooltip"></div>
        </div>

    );
}

export default InteractiveTimeTable;



