import { timeConverter } from "../components/ContentBox/Online-schedule/components/functions";
import Stores from "../Stores";
import { addZero } from "./math";

export const findLast = array => array.length ? array[array.length - 1] : {};

export const sortASC = (array, key) => array.sort(function (a, b) { return a[key] - b[key] });

export const sortDESC = (array, key) => array.sort(function (a, b) { return b[key] - a[key] });

export const classNames = obj => {
    const arr = []
    /*for (let key in obj)
        if (obj[key])
            arr.push(key)*/
    Object.keys(obj).forEach(key => {
        if (obj[key]) arr.push(key)
    })
    return arr.join(" ")
}

export const shuffle = array => array.sort(() => Math.random() - 0.5);

export const replaceTime = (time, initTZ, timeZone) => {
    let baseTZ = initTZ;
    time = time.slice(0, 5)
    let ts = new Date(`${timeConverter(new Date(), 'today')}T${time}`)
    if (baseTZ > 0) {
        ts = ts.setHours(ts.getHours() - baseTZ + timeZone)
    } else if (baseTZ < 0) {
        ts = ts.setHours(ts.getHours() + baseTZ - timeZone)
    } else {
        ts = ts.setHours(ts.getHours() + timeZone)
    }
    ts = new Date(ts)
    return `${addZero(ts.getHours())}:${addZero(ts.getMinutes())}`
}

export const checkPermList = perms => Stores.Rules.checkRule2(perms?.parent_key, perms?.key, perms?.permissions)

export const stringToBoolean = (string) => {
    switch(string.toLowerCase().trim()){
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
    }
}

export const goodTime = secs => {
    let sec_num = parseInt(secs, 10)
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [hours, minutes, seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v, i) => v !== "00" || i > 0)
        .join(":")
}
export const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

export const scrollToRef = (ref, offset = 0) => {
    const y = ref.current.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
        top: y,
        behavior: 'smooth',
    });
};

export const validImage = (url) => {
    const img = new Image();
    img.src = url;
    return img.height;
}

export const getWordEnd = (count, wordForms) => {
    const index = (count % 100 > 4 && count % 100 < 20) ? 2 :
                          (count % 10 === 1) ? 0 :
                          (count % 10 >= 2 && count % 10 <= 4) ? 1 : 2;
    return wordForms[index];
}