import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputFile.module.scss';

const compressImage = (file, {
  onSuccess = () => {},
  quality = 1,
  maxWidth = 620,
  maxHeight = 200,
}) => {
  const types = {
    svg: 'image/svg+xml',
    gif: 'image/gif',
    jpg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
  };

  let fileName = file.name;
  // do nothing if svg
  if (fileName.endsWith('svg')) {
    onSuccess(file);
    return;
  }
  fileName = fileName.replace(/.jpeg$/, '.jpg');
  const ext = fileName.split('.').pop().toLowerCase();
  const mimeType = types[ext] || types.jpg;

  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = function (evt) {
    const imgElement = new Image();
    imgElement.src = evt.target.result;

    imgElement.onload = function (e) {
      const canvas = document.createElement('canvas');

      // return original file for small image
      if (imgElement.width <= maxWidth && imgElement.height <= maxHeight) {
        onSuccess(file);
        return;
      }

      const target = e.target;
      const oldWidth = target.width;
      const oldHeight = target.height;

      // scale width and height
      if (oldHeight > oldWidth) {
        canvas.width = oldWidth * maxHeight / oldHeight;
        canvas.height = maxHeight;
      } else {
        canvas.width = maxWidth;
        canvas.height = oldHeight * maxWidth / oldWidth;
      }

      // draw image with new width and height
      const ctx = canvas.getContext('2d');
      ctx.drawImage(target, 0, 0, canvas.width, canvas.height);

      // new image as a file
      ctx.canvas.toBlob((blob) => {
        const file = new File([blob], fileName, {
          type: mimeType,
          lastModified: Date.now(),
        });

        // return file to callback
        onSuccess(file);
      }, mimeType, quality);
    };
  };
};

function InputFile({onChange, label, accept, classes, ...props}) {
  const handleChange = (evt) => {

    const file = evt.target.files[0];
    if (!file?.type?.includes('image')) {
      return;
    }

    compressImage(file, { onSuccess: onChange, quality: 0.7 });
  };

  return (
    <label className={`${styles.label} ${classes}`}>
      <input
        type='file'
        className={styles.hidden}
        accept={accept}
        onChange={handleChange}
        {...props}
      />
      <span className={styles.text}>{label}</span>
    </label>
  );
}

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  accept: PropTypes.string,
  classes: PropTypes.string,
};

InputFile.defaultProps = {
  accept: 'image/*',
  classes: '',
};

export default InputFile;
