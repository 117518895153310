import React from 'react';
import PropTypes from 'prop-types';
import VariantAdd from '../../../StudyTest/TestQuestion/components/VariantAdd';
import st from './VideoLinks.module.scss'
import VideoLink from "./VideoLink";

function VideoLinks(props) {

  const add = () => {
    props.onChange([...props.videos, {video_link: '', description: ''}], 'data')
  }
  const remove = (position) => {
    props.onChange([...props.videos.filter((el, index) => index !== position)], 'data')
  }

  const changeHandler = (val, index) => {
    props.onChange(props.videos.map((v, i) => i === index ? val : v), 'data')
  }

  return (
    <div className={st.videoLinks}>{props.videos.map((item, index) =>
      <VideoLink translate={props.translate} key={index} value={item} onChange={val => changeHandler(val, index)} error={props.error} isRemovable={index > 0} remove={remove} index={index}/>)}
      <VariantAdd onClick={add}/>
    </div>
  )
}

VideoLinks.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({
    video_link: PropTypes.string,
    description: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
}

export default VideoLinks
