import React, {Fragment, useEffect, useState} from 'react'
import {superAxiosRequest} from 'axiosApi';
import {dateFormat} from "helpers/date";
import {splitNumberDigits} from "helpers/math";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import ContentHeader from 'components/UI/ContentHeader/ContentHeader'
import THead from "../../../ContentBox/Global/ReportTable/THead";
import Loader from "../../../ContentBox/Global/Loader/Loader";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import {ExcelRowsParams, setCellParams} from '../../../utils/ExcelExport';


const ProfitabilityPrograms = () => {
    const [catalog, setCatalog] = useState({})

    const {
        refWrapper,
        refSticky,
        refThead,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
        getTdClassNames,
    } = useTableFixedHeader()

    const filterCfg = [
        {
            type: 'select-custom',
            nameState: 'location',
            placeholder: 'Локация',
            values: catalog.locations?.sort((a, b) => a.name > b.name ? 1 : -1) || [],
            option_value_key: 'id',
            option_label_key: 'name',
        },
        {type: 'month-range-picker', nameState: ['date_from', 'date_until']},
        {
            type: 'select-custom',
            placeholder: 'Тип программы',
            values: catalog.program_types,
            sort_key: 'name',
            nameState: 'program_type',
            is_search: true,
        },
        {
            type: 'type-selector',
            values: [
                {id: 2, name: 'Все группы'},
                {id: 1, name: 'Онлайн'},
                {id: 0, name: 'Офлайн'},
            ],
            nameState: 'online'
        },
    ]

    const cfg = {
        cols: [
            {
                name: 'Школа',
                group: true,
                formatter: row => catalog.locations.find(l => l.id === row.id).name,
                classes: [st.color, st.fw500],
            },
            {
                name: 'Тип программы',
                formatter: row => catalog.program_types.find(skl => skl.id === row.program_type).name,
                classes: [st.color, st.fz12, st.fw500],
            },
            {
                name: 'Клиенты',
                sub: [
                    {
                        name: 'Приход с клиентов',
                        formatter: row => splitNumberDigits((row.student.income / 100).toFixed(2)),
                        classes: [st.fz12, st.textAlignCenter]
                    },
                    {
                        name: 'Расходы на клиентов',
                        formatter: row => splitNumberDigits((row.student.expense / 100).toFixed(2)),
                        classes: [st.fz12, st.textAlignCenter]
                    },
                ]
            },
            {
                name: 'Преподаватели',
                sub: [
                    {
                        name: 'ЗП',
                        formatter: row => splitNumberDigits((row.teacher.expense / 100).toFixed(2)),
                        classes: [st.fz12, st.textAlignCenter]
                    },
                ]
            },
            {
                name: 'Средний приход на 1 студента',
                formatter: row => splitNumberDigits((row.student.avg_income_per_person / 100).toFixed(2)),
                classes: [st.fz12, st.textAlignCenter]
            },
            {
                name: 'Средний расход на 1 студента',
                formatter: row => splitNumberDigits((row.student.avg_expense_per_person / 100).toFixed(2)),
                classes: [st.fz12, st.textAlignCenter]
            },
            {
                name: 'Остаток',
                formatter: row => splitNumberDigits((row.remains / 100).toFixed(2)),
                classes: [st.fz12, st.textAlignCenter]

            },
        ]
    }


    const onChangeFilter = (obj) => {
        const filterData = {
            ...obj,
            ...(obj.date_from && { date_from: obj.date_from }),
            ...(obj.date_until && { date_until: obj.date_until }),
        }
        setFilter(filterData);
        if (obj.date_from && obj.date_until) getFilteredData(filterData);
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/profitability_programs',
        }).then(({data}) => {
            setIsLoading(false)
            setCatalog(data)
        }).catch(() => setIsLoading(false));
    }, [])

    const getFilteredData = (filter) => {
        setIsLoading(true)
        // Дату начала устанавливаем на первый день месяца
        const dateFrom = filter.date_from ? new Date(filter.date_from) : null
        // Дату конца устанавливаем на последний день месяца
        const dateUntil = filter.date_until ? new Date(filter.date_until) : null
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/profitability_programs',
            params: {
                ...filter,
                date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"), ...(dateUntil &&
                    {
                        date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                    })
            },
        }).then(({data}) => {
            const getDataWithCorrectProgramTypes = () => {
                // Создаем Set для ускорения поиска id в массиве catalog.program_types
                const catalogProgramTypeIds = new Set(catalog.program_types.map(skl => skl.id))

                // Фильтруем locations, оставляем только те, у которых есть совпадение с program_type.id
                const filteredLocations = data.result.locations.filter(location => {
                    if (location.program_types && location.program_types.length > 0) {
                        return location.program_types.every(programType => {
                            return catalogProgramTypeIds.has(programType.program_type)
                        });
                    }
                    return false // Если program_types отсутствует или пуст, то не включаем локацию
                })
                return [...filteredLocations, data.result.locations.at(-1)] // Возвращаем отфильтрованный массив и добавляем в конец "Устное тестирование"
            }
            setData({locations: getDataWithCorrectProgramTypes()})
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    // TODO fix excel file
    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRows = [
                [
                    {
                        v: "Школа",
                    },
                    {
                        v: "Тип программы ",
                    },
                    {
                        v: "Клиенты",
                    },
                    {
                        v: "Клиенты",
                    },
                    {
                        v: "Преподаватели",
                    },
                    {
                        v: "Средний приход на 1 студента",
                    },
                    {
                        v: "Средний расход на 1 студента",
                    },
                    {
                        v: "Остаток",
                    },
                ],
                [
                    {
                        v: "Школа",
                    },
                    {
                        v: "Тип программы ",
                    },
                    {
                        v: "Приход с клиентов",
                    },
                    {
                        v: "Расходы на клиентов",
                    },
                    {
                        v: "ЗП",
                    },
                    {
                        v: "",
                    },
                    {
                        v: "",
                    },
                    {
                        v: "",
                    },
                ],
            ]
            headRows[0] = setCellParams(headRows[0], ExcelRowsParams.headerRowParam)
            headRows[1] = setCellParams(headRows[1], ExcelRowsParams.headerRowParam)

            let tableData = []

            let merges = [
                {s: {r: 0, c: 0}, e: {r: 1, c: 0}},
                {s: {r: 0, c: 1}, e: {r: 1, c: 1}},
                {s: {r: 0, c: 2}, e: {r: 0, c: 3}},
                {s: {r: 0, c: 5}, e: {r: 1, c: 5}},
                {s: {r: 0, c: 6}, e: {r: 1, c: 6}},
                {s: {r: 0, c: 7}, e: {r: 1, c: 7}},
            ]
            let wscols = [
                {wch: 20},
                {wch: 20},
                {wch: 25},
                {wch: 25},
                {wch: 20},
                {wch: 20},
                {wch: 20},
                {wch: 25},
                {wch: 20},
            ];
            let rows = [
                {hpx: 40},
                {hpx: 40},
            ]

            let currentRow = 2

            data?.locations?.filter(e => e.program_types).forEach(location => {
                    let totalData = {
                        studentIncome: 0,
                        studentExpense: 0,
                        avgStudentIncome: 0,
                        avgStudentExpense: 0,
                        teacherExpense: 0,
                        remains: 0
                    }
                    if (location.program_types.length > 1) {
                        merges.push(
                            {s: {r: currentRow, c: 0}, e: {r: currentRow + location.program_types.length - 1, c: 0}}
                        )
                    }
                    location.program_types.forEach(program_type => {
                        currentRow = currentRow + 1
                        totalData = {
                            studentIncome: totalData.studentIncome + location.total.student.income,
                            studentExpense: totalData.studentExpense + location.total.student.expense,
                            avgStudentIncome: totalData.studentIncome +  location.total.student.avg_income_per_person,
                            avgStudentExpense: totalData.studentExpense + location.total.student.avg_expense_per_person,
                            teacherExpense: totalData.teacherExpense + location.total.teacher.expense,
                            remains: totalData.remains + location.total.remains
                        }


                        let rowData = [
                            {
                                v: `${catalog.locations.find(el => el.id === location.id)?.name || ''}`,
                            },
                            {
                                v: `${catalog.program_types.find(el => el.id === program_type.program_type)?.name || ''}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.student.income / 100).toFixed(2))}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.student.expense / 100).toFixed(2))}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.teacher.expense / 100).toFixed(2))}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.student.avg_income_per_person / 100).toFixed(2))}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.student.avg_expense_per_person / 100).toFixed(2))}`,
                            },
                            {
                                v: `${splitNumberDigits((program_type.remains / 100).toFixed(2))}`,
                            },
                        ]
                        rowData = setCellParams(rowData, ExcelRowsParams.tableRowParam.cell)
                        tableData.push(rowData)
                    })
                    currentRow = currentRow + 1

                    let footerRow = [
                        {
                            v: `Итого`,
                            ...ExcelRowsParams.footerRowParams.firstCell
                        },
                        {
                            v: ``,
                        },
                        {
                            v: `${splitNumberDigits((totalData.studentIncome / 100).toFixed(2))}`,
                        },
                        {
                            v: `${splitNumberDigits((totalData.studentExpense / 100).toFixed(2))}`,
                        },
                        {
                            v: `${splitNumberDigits((totalData.teacherExpense / 100).toFixed(2))}`,
                        },
                        {
                            v: `${splitNumberDigits((totalData.avgStudentIncome / 100).toFixed(2))}`,
                        },
                        {
                            v: `${splitNumberDigits((totalData.avgStudentExpense / 100).toFixed(2))}`,
                        },
                        {
                            v: `${splitNumberDigits((totalData.remains / 100).toFixed(2))}`,
                        }
                    ]
                    footerRow = footerRow.map((item, id) => id === 0 ? item : ({...item, ...ExcelRowsParams.footerRowParams.cell}))
                    tableData.push(footerRow)
            })


            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows

            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Рентабельность программ.xlsx");
        })
    };

    const locations = data?.locations?.filter(e => e.program_types) || []
    return (
        <>
            <ContentHeader title="Рентабельность программ" />
            <Filter config={filterCfg} getFilterParams={onChangeFilter} drop={() => {setFilter({})}} />

            {isLoading ? <Loader /> : data ?
                <>
                    <button onClick={() => {downloadExcel(data)}} style={{
                        margin: '0 10px 10px',
                        width: 'calc(100% - 20px)'
                    }}>Выгрузить в Excel</button>
                    <div className={st.tableWrapper} ref={refWrapper}>
                        <div ref={refSticky} className={st.sticky}>
                            <table className={st.table}>
                                <THead cols={cfg.cols} />
                            </table>
                        </div>
                        <table className={st.table}>
                            <THead cols={cfg.cols} ref={refThead} />
                            <tbody className={`${st.tbody} ${st.tbodyStriped}`}>
                            {/*{data.locations && false &&*/}
                            {/*    <tr className={`${st.bdt} ${st.nobr}`}>*/}
                            {/*        <td className={st.tdTotal}>ИТОГО</td>*/}
                            {/*        <td className={st.tdTotal}>Плательщиков: {data.total.payers}</td>*/}
                            {/*        <td className={st.tdTotal}>Должников: {data.total.debtors}</td>*/}
                            {/*        /!*{Object.values(data.total.weeks).map((val, idx) =>*!/*/}
                            {/*        /!*    <td key={idx}*!/*/}
                            {/*        /!*        className={`${st.tdTotal} ${st.textAlignCenter}`}>{formatHours(val)}</td>*!/*/}
                            {/*        /!*)}*!/*/}
                            {/*        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>*/}
                            {/*            {formatHours(data.total.hours_paid)}*/}
                            {/*        </td>*/}
                            {/*        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>*/}
                            {/*            {formatHours(data.total.hours_total)}*/}
                            {/*        </td>*/}
                            {/*        {['paid', 'debt', 'sum'].map(item =>*/}
                            {/*            <td key={item} className={`${st.tdTotal} ${st.textAlignCenter}`}>*/}
                            {/*                {data.total[item]}*/}
                            {/*            </td>*/}
                            {/*        )}*/}
                            {/*    </tr>}*/}
                            {Array.isArray(data.locations) && locations.map(location => (
                                <Fragment key={location.id}>
                                    <tr>
                                        <td className={st.tdTotal}>Итого</td>
                                        <td className={st.tdTotal} />
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.student.income / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.student.expense / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.teacher.expense / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.student.avg_income_per_person / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.student.avg_expense_per_person / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((location.total.remains / 100).toFixed(2))}
                                        </td>
                                    </tr>
                                    {location.program_types.map((pt, index) =>
                                        <tr key={`${location.id}-${index}`}
                                            className={index === 0 ? st.bdt : undefined}>
                                            {cfg.cols.map((col, idxCol) => {
                                                if (col.group) {
                                                    if (index === 0)
                                                        return (
                                                            <td key={idxCol} rowSpan={location.program_types.length}
                                                                className={getTdClassNames(col)}>
                                                                {typeof col.formatter === 'function' ? col.formatter(location) : location[col.dataField]}
                                                            </td>
                                                        )
                                                    else return null
                                                } else {
                                                    if (Array.isArray(col.sub)) {
                                                        return col.sub.map((subCol, idxSubCol) => {
                                                            return (
                                                                <td key={`${idxCol}-${idxSubCol}`}
                                                                    className={getTdClassNames(subCol)}>
                                                                    {typeof subCol.formatter === 'function' ?
                                                                        subCol.formatter(pt, col.dataArray, subCol.dataField) :
                                                                        col.dataArray ? pt[col.dataArray]?.[subCol.dataField] : pt[subCol.dataField]}
                                                                </td>
                                                            )
                                                        })
                                                    } else {
                                                        return (
                                                            <td key={idxCol} className={getTdClassNames(col)}>
                                                                {typeof col.formatter === 'function' ? col.formatter(pt) : pt[col.dataField]}
                                                            </td>
                                                        )
                                                    }
                                                }
                                            })}
                                        </tr>
                                    )}

                                </Fragment>
                            ))}
                            <>
                                {data?.locations?.at(-1) &&
                                    <tr>
                                        <td className={st.tdTotal}>Итого</td>
                                        <td className={st.tdTotal} />
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((data.locations.at(-1).student.income / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            -
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            -
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {splitNumberDigits((data.locations.at(-1).student.avg_income_per_person / 100).toFixed(2))}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            -
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            -
                                        </td>
                                    </tr>
                                }
                                {data?.locations?.at(-1) &&
                                <tr>
                                   <td className={`${st.fw500} ${st.color}`} >{data?.locations?.at(-1).title}</td>
                                   <td className={`${st.textAlignCenter} ${st.fz12}`}>-</td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>
                                        {splitNumberDigits((data.locations.at(-1).student.income / 100).toFixed(2))}
                                    </td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>-</td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>-</td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>
                                        {splitNumberDigits((data.locations.at(-1).student.avg_income_per_person / 100).toFixed(2))}
                                    </td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>-</td>
                                    <td className={`${st.textAlignCenter} ${st.fz12}`}>-</td>
                                </tr>
                                }
                            </>
                            {/* Итого по всем группам */}

                            </tbody>
                        </table>
                    </div>
                </>
                : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </>
    )
}

export default ProfitabilityPrograms;