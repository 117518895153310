import React from 'react';
import PropTypes from "prop-types";
import asideModalStore from "Stores/asideModalStore";
import MultiCheckbox from "components/UI/Checkbox/MultiCheckbox";

function AssignExercise(props) {
  const {modalValues, changeValue} = asideModalStore
  
  const onChangeHandler = id => {
    const arr = modalValues.students
    changeValue(arr.includes(id) ? arr.filter(skID => skID !== id) : [...arr, id], 'students')
  }

  return (
    <div>
      <ul>
        {props.allStudents.map(item => {
          return (
            <MultiCheckbox
              key={item.id}
              checked={modalValues?.students?.includes(item.id)}
              label={item.full_name}
              onChange={() => onChangeHandler(item.id)}
            />)
        })}
      </ul>
    </div>
  )
}

AssignExercise.propTypes = {
  allStudents: PropTypes.arrayOf(PropTypes.object),
  assignStudentIDs: PropTypes.arrayOf(PropTypes.number),
}

export default AssignExercise
