import React, {useState} from 'react';
import PropTypes from "prop-types";
import styles from "./DummyCounter.module.scss"

const DummyCounter = props => {
    const [focused, setFocused] = useState(false)
    const onChangeHandler = val => {
        if (props.onChange) {
            if (props.min !== undefined) val = Math.max(val, props.min)
            if (props.max) val = Math.min(val, props.max)
            if (!isNaN(val))
                props.onChange(props.fixed ? +(val.toFixed(props.fixed)) : val, props.name)
        }
    }

    const getValue = () => props.value !== undefined ? props.fixed ? +props.value.toFixed(props.fixed) : props.value : ''

    return (
        <div className={`${styles.counterBox} ${focused ? styles.counterBoxFocused : ''}`}>
            <div className={styles.button}
                 onClick={() => onChangeHandler(props.value - (props.step || 1))}>-</div>
            <input type="text" value={getValue()}
                   onChange={e => onChangeHandler(+e.target.value)}
                   onFocus={() => setFocused(true)}
                   onBlur={() => setFocused(false)}
            />
            <div className={styles.button}
                 onClick={() => onChangeHandler(props.value + (props.step || 1))}>+</div>
        </div>
    )
}

DummyCounter.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default DummyCounter
