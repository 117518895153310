import React, {useEffect, useState} from 'react';
import {Parser, ProcessNodeDefinitions} from "html-to-react";
import {ExerciseSelect} from "components/Modules/Ebook/Common/ExerciseSelect/ExerciseSelect";
import TextMarkup from "../../components/TextMarkup/TextMarkup";
import st from "./SelectWords.module.scss"

const regexBrackets = /\[(.*?)]/g;

export const parseSelectWords = (value = '') => {
  const insertVariants = [];
  let text = value;
  let m, arr;

  //text = text.replaceAll(/<br\/?>/g, '')
  //text = text.replaceAll('<div>', '<br/>')
  //text = text.replaceAll('\\', '|')

  for (m = regexBrackets.exec(text); m; m = regexBrackets.exec(text)) {
    arr = m[1].split('|');
    insertVariants.push(arr);
  }

  //const cleanText = text.replace(regexBrackets, '@').split('@');

  return {insertVariants}
}

export default function SelectWords(props) {
  const text = props?.items?.text

  const {insertVariants} = parseSelectWords(text)
  const [exerciseSelectKey, setExerciseSelectKey] = useState(0)

  const myChangeHandler = (selectedValue, index) => {
    const arr = [...props.answers]
    arr[index] = selectedValue
    const ready = arr.length === insertVariants.length && arr.length === arr.filter(a => a && a.length > 0).length;
    props.handleAnswers(arr, ready)
  }

  const getAnswer = index => {
    if (props.studentAnswer) {
      return props.studentAnswer.answer.split('|')[index]
    } else {
      return props.answers[index]
    }
  }

  // I had a scenario where an HTML template was generated by a dummy teacher,
  // yet I wanted to leverage React for the parts I did have control over
  const htmlParser = new Parser(React)
  const processNodeDefinitions = new ProcessNodeDefinitions()
  const processingInstructions = [
    // Processing for my-contenteditable-component
    {
      shouldProcessNode: node => node.name === 'my-select-component',
      processNode: node => {
        const index = +node.attribs['data-index']
        const value = props.answers[index] ? {label: props.answers[index], value: props.answers[index]} : undefined;
        return (props.is_student_checked_try && props.answ_array && insertVariants[index]) ?
          <div className={st.container}>
            <ExerciseSelect
              placeholder=' '
              value={value}
              options={insertVariants[index].map(item => ({
                label: item,
                value: item,
              }))}
            />
          </div> :

        (props.is_teacher_view && props.at && insertVariants[index]) ?
          <div className={st.container}>
            <ExerciseSelect
              placeholder=' '
              value={value}
              options={insertVariants[index].map(item => ({
                label: item,
                value: item,
              }))}
              propsStyles={1}
            />
          </div> :

          <div className={st.container}>
               <ExerciseSelect
                  key={exerciseSelectKey}
                  placeholder=' '
                  value={getAnswer(index) ? {label: getAnswer(index), value: getAnswer(index)} : ''}
                  onChange={selectedOption => {
                    if (!props.studentAnswer) {
                      myChangeHandler(selectedOption.value, index);
                    }
                  }}
                  options={[{
                    label: '',
                    value: '0',
                    isDisabled: true
                  }, ...insertVariants[index].map(item => ({label: item, value: item}))]}
                  isCorrect={props.rightAnswers || props.studentAnswer?.result[index]}
                />
          </div>

        // (props.mode === 'edit' && insertVariants[index]) ?
        //   <div className={st.container}>
        //     <ExerciseSelect
        //       placeholder='Select Text'
        //       value={value}
        //       options={[
        //         // {label: '', value: '0', isDisabled: true}, // Добавляем пустой элемент с value '0' и отключенным состоянием
        //         ...insertVariants[index].map(item => ({label: item, value: item}))
        //       ]}
        //       onChange={selectedOption => myChangeHandler(selectedOption.value, index)}
        //     />
        //   </div> :
        //
        // (props.mode !== 'edit' && insertVariants[index]) &&
        // <div className={st.container}>
        //   <ExerciseSelect
        //     key={exerciseSelectKey}
        //     placeholder='Select Text'
        //     value={getAnswer(index) ? {label: getAnswer(index), value: getAnswer(index)} : ''}
        //     onChange={selectedOption => {
        //       if (!props.studentAnswer) {
        //         myChangeHandler(selectedOption.value, index);
        //       }
        //     }}
        //     options={[{
        //       label: '',
        //       value: '0',
        //       isDisabled: true
        //     }, ...insertVariants[index].map(item => ({label: item, value: item}))]}
        //     isCorrect={props.rightAnswers || props.studentAnswer?.result[index]}
        //   />
        // </div>
      }
    },
    // Default processing
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]
  const replaceBrackets = str => {
    let index = 0
    return str.replaceAll(regexBrackets, () => `<my-select-component data-index="${index++}"></my-select-component>`)
  }
  const ReactElement = htmlParser.parseWithInstructions(
    `<div>${replaceBrackets(text)}</div>`,
    () => true,
    processingInstructions
  )

  useEffect(() => {
    // Обновляем значение ключа при изменении props.try, это приводит к принудительному размонитрованию и сбросу value после ответа
    setExerciseSelectKey(exerciseSelectKey + 1)
  }, [props.try, props.studentAnswer])

  return (
    <div className={st.selectWords}>
      <TextMarkup>
        {ReactElement}
        {/*{cleanText.map((item, index) => {
          const value = props.answers[index] ? {label: props.answers[index], value: props.answers[index]} : undefined;
          return null
          return (
            <Fragment key={index}>
          <span ref={spanRef => {
            if (spanRef) {
              const brElements = spanRef.querySelectorAll('br');
              if (brElements.length > 1) {
                for (let i = 1; i < brElements.length; i++) {
                  brElements[i].remove();
                }
              }
            }
          }}>
            <span dangerouslySetInnerHTML={{__html: item}}/>
          </span>
              {(props.is_student_checked_try && props.answ_array) &&
              insertVariants[index] ?
                <div className={st.container}>
                  <ExerciseSelect
                    placeholder='Select Text'
                    value={value}
                    options={insertVariants[index].map(item => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
                : null}

              {((props.is_teacher_view && props.at) &&
                insertVariants[index]) ?
                <div className={st.container}>
                  <ExerciseSelect
                    placeholder='Select Text'
                    value={value}
                    options={insertVariants[index].map(item => ({
                      label: item,
                      value: item,
                    }))}
                    propsStyles={1}
                  />
                </div>
                : null}

              {((props.is_teacher_view && (!props.at)) &&
              {props.mode === 'edit' && insertVariants[index] ?
                <div className={st.container}>
                  <ExerciseSelect
                    placeholder='Select Text'
                    value={value}
                    options={[
                      {label: '', value: '0', isDisabled: true}, // Добавляем пустой элемент с value '0' и отключенным состоянием
                      ...insertVariants[index].map(item => ({label: item, value: item}))
                    ]}
                    onChange={selectedOption => myChangeHandler(selectedOption.value, index)}
                  />
                </div>
                : null}

              {(props.is_clean_ex && props.mode !== 'edit' && insertVariants[index]) &&
              {(props.mode !== 'edit' && insertVariants[index]) &&
                <div className={st.container}>
                  <ExerciseSelect
                    key={exerciseSelectKey}
                    placeholder='Select Text'
                    value={getAnswer(index) ? {label: getAnswer(index), value: getAnswer(index)} : ''}
                    onChange={selectedOption => {
                      if (!props.studentAnswer) {
                        myChangeHandler(selectedOption.value, index);
                      }
                    }}
                    options={[{
                      label: '',
                      value: '0',
                      isDisabled: true
                    }, ...insertVariants[index].map(item => ({label: item, value: item}))]}
                    isCorrect={props.rightAnswers || props.studentAnswer?.result[index]}
                  />
                </div>
              }
            </Fragment>
          )
        })}*/}
      </TextMarkup>
    </div>
  )
}
