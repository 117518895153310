import { createContext } from "react";

export const statusList = ['in_progress'];

export const dict = {
    "title": "My Groups",
    "empty_text": "You are not registered in any group!",
    "empty_btn": "Go To All Groups",
    "in_progress_name": "In Progress",
    "in_progress_text": "Actual",
    "scheduled_name": "Scheduled",
    "scheduled_text": "Actual",
    "formation_name": "Formation",
    "formation_text": "No Lessons",
    "finished_name": "Finished",
    "finished_text": "Deleted",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "online": "Online",
    "offline": "Offline",
    "classroom": "Aud"
};

export const LanguageContext = createContext();
