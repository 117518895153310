import React from "react";
import PropTypes from "prop-types";
import st from "./EmptyList.module.scss";
import {ReactComponent as EmptyImg} from "./no_tests.svg";

const EmptyList = ({title, text1, text2, button}) => {
  return (
    <div className={st.emptyList}>
      <EmptyImg/>
      <h3>{title}</h3>
      <p>{text1}</p>
      {text2 && <p>{text2}</p>}
      {button && <button className={st.button} onClick={() => {
        button.onClick()
      }}>{button.text}</button>}
    </div>
  )
}

EmptyList.propTypes = {
  title: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }).isRequired
}

export default EmptyList;
