import React, { useEffect, useRef, useState } from 'react';
import Stores from 'Stores'
import styles from "./QuestionImagesList.module.scss";
import FileSelectModal from "components/UI/FileSelector/components/FileSelectModal";
import {ReactComponent as FullImageIcon} from "components/HeadBox/img/full_image_icon.svg";
import HoverPreview from "../components/HoverPreview/HoverPreview";


export default function QuestionImagesList(props) {
    const [isOpen, setIsOpen] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const refSlider = useRef()
    const refSlides = useRef()
    const refSlide = useRef()

    const handleEyeClick = (imageFile) => {
        setShowModal(true);
        setSelectedImage(imageFile);
    };

    const changeHeight = () => {
        if (refSlider.current)
            refSlider.current.style.height = isOpen ? refSlides.current.offsetHeight + 'px' : refSlide.current.offsetHeight + 'px'
    }

    useEffect(() => {
        if (!props.mainImage) return
        if (props.handleAnswers) {
            props.handleAnswers([""], true)
        }
    }, [props.mainImage])

    useEffect(() => {
        if (Array.isArray(props.images)) {
            props.images.forEach(slide => {
                const image = new Image()
                image.onload = () => changeHeight()
                image.src = Stores.baseLink() + slide.file
            })
        }
    }, [props.images])

    useEffect(() => {
        changeHeight()
        // Нужно изменить высоту после загрузки изображения
        isOpen && refSlides.current.querySelector('img').addEventListener('load', changeHeight)
    }, [isOpen])

    window.addEventListener("resize", changeHeight)

    const slides = Object.assign([], props.images)
    const firstSlide = slides.shift()

    return (
        <>
            <div className={styles.questionImagesList}>
                {props.images.length ?
                    <div ref={refSlider}>
                        <ul ref={refSlides}>
                            <li key={0} ref={refSlide}>
                                <HoverPreview
                                  childrenPhoto={
                                      <div><img src={Stores.baseLink() + firstSlide.file} alt={firstSlide.description}/>
                                      </div>
                                  }
                                  childrenIcon={
                                      <FullImageIcon className={styles.fullImageIcon}
                                                     onClick={() => handleEyeClick(firstSlide.file)}/>
                                  }
                                />
                                <div>{firstSlide.description}</div>
                            </li>
                            {slides.map((img, index) =>
                                <li key={index++}>
                                    <HoverPreview
                                      childrenPhoto={
                                          <div><img src={Stores.baseLink() + img.file} alt={img.description}/></div>
                                      }
                                      childrenIcon={
                                          <FullImageIcon className={styles.fullImageIcon}
                                                         onClick={() => handleEyeClick(img.file)}/>
                                      }
                                    />
                                    <div>{img.description}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                    : null
                }
            </div>
            {showModal && <FileSelectModal
              img={Stores.baseLink() + selectedImage}
              setOpen={() => setShowModal(false)} />}
        </>
    )
}
