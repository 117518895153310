import React, { useContext } from 'react';
import Paper from 'components/UI/Paper/Paper';
import { Context } from '../SlotDetails';
import styles from '../SlotDetails.module.scss';

export default function Level() {
  const { slot_client, programs, ages } = useContext(Context);
  const programName = programs.find(({id}) => id === slot_client.program_id).name;
  const ageName = ages.find(({id}) => id === slot_client.age_id).name;

  return (
    <Paper classes={`${styles.item} ${styles.level}`}>
      {slot_client.start_level &&
        <p>slot_client.start_level</p>
      }
      <p>
        {programName}
      </p>
      <p>
        {ageName}
      </p>
    </Paper>
  );
}

