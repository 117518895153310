import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Flex from 'components/UI/Flex/Flex';
import SelectorsLayout from 'components/Modules/Slots/RecordCustomer/layouts/SelectorsLayout';
import CalendarLayout from 'components/Modules/Slots/RecordCustomer/layouts/CalendarLayout';
import SlotsLayout from 'components/Modules/Slots/RecordCustomer/layouts/SlotsLayout';
import ClientDataLayout from 'components/Modules/Slots/RecordCustomer/layouts/ClientDataLayout';
import styles from './RecordCustomer.module.scss';
import slotsRecordCustomerStore, { DataType } from 'Stores/slotsRecordCustomerStore';
import SubmitLayout from 'components/Modules/Slots/RecordCustomer/layouts/SubmitLayout';

function RecordCustomer() {
  const { id } = useParams();
  const { isCalendarReadyToShow, slots, teachers, overwritingClientData,resetData, setData } = slotsRecordCustomerStore;

  useEffect(() => {
    setData(Boolean(id), DataType.isClientOverwrite);
    return resetData();
  }, []);

  return (
    <>
      <ContentHeader title={id
        ? `Перезапись клиента${overwritingClientData?.client ? ': ' + overwritingClientData.client.last_name + ' ' + overwritingClientData.client.first_name : ''}`
        : 'Запись клиента'}
      />
      <Flex withGap classes={styles.wrapper}>
        <SelectorsLayout />
        { isCalendarReadyToShow && <CalendarLayout /> }
        { slots.length > 0 && <SlotsLayout /> }
        { teachers.length > 0 && <ClientDataLayout /> }
        { teachers.length > 0 && <SubmitLayout /> }
      </Flex>
    </>
  );
}

export default observer(RecordCustomer);
