import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import Flex from 'components/UI/Flex/Flex';
import Button from 'components/UI/Button/Button';
import Paragraph from 'components/UI/Paragraph/Paragraph';
import Loader from 'components/UI/Loader/Loader';
import { localisationMaster } from "Localisation/Localisation";
import slotsTeacherScheduleStore, { ActionType } from 'Stores/slotsTeacherScheduleStore';
import swal from 'sweetalert';
import { createContentElement } from 'components/Modules/Slots/TeacherSchedule/components/SubmitSwal/SumbitSwal';

function SubmitLayout() {
  const {resetData, sendDataToServer, isSubmitDisabled, dataToSubmitSwal} = slotsTeacherScheduleStore;
  const [isLoading, setIsLoading] = useState(false);
  const {isDisabled, message} = isSubmitDisabled;

  const handleSubmitClick = () => {
    setIsLoading(true);
    sendDataToServer({
      onSuccess: () => {
        return swal({
          icon: 'success',
          title: localisationMaster('text_35', 'teacher_schedule'),
          content: createContentElement(dataToSubmitSwal),
          button: true,
        });
      },
      onFail: (message) => {
        swal(localisationMaster('text_36', 'teacher_schedule'), message, 'warning')
          .then(() => setIsLoading(false));
      },
    });
  };

  return (
    <Paper>
      <Flex withGap md='center'>
        <Button onClick={() => resetData(ActionType.resetSlots)} isDisabled={isLoading} variant='secondary'>
          {localisationMaster('text_37', 'teacher_schedule')}
        </Button>
        <Button onClick={handleSubmitClick} isDisabled={isLoading || isDisabled}>
          {localisationMaster('button', 'online-schedule/time-slots')}
        </Button>
       
      </Flex>
      { isDisabled &&
      <Paragraph withMarginTop warning>
        {localisationMaster('text_38', 'teacher_schedule')}: <strong>{message}</strong>
      </Paragraph>
      }
      {isLoading && <Loader withOverlay />}
    </Paper>
  );
}

export default observer(SubmitLayout);
