import React, { useEffect, useState } from 'react';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import Calendar from 'components/ContentBox/Online-schedule/components/Calendar';
import ScheduleSettingSlots from "./ScheduleSettingSlots";
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import Checkbox from "../../Global/Checkbox";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Stores from 'Stores'

import './ScheduleSetting.scss'
import SlotsExplain from '../components/SlotsExplain/SlotsExplain';
import modalStore from '../../../../Stores/modalStore';
import { observer } from 'mobx-react';
import slotsStore from '../../../../Stores/slotsStore';
import { replaceTime } from '../../../../helpers';

const ScheduleSetting = () => {
    const { setBookedSlots, bookedSlots, addSlot } = slotsStore
    let apiConf;
    const Link = 'online-schedule/schedule-setting'

    const [location, setLocation] = useState(0);
    const [locationList, setLocationList] = useState(null);
    //const [isLOADED, setIsLOADED] = useState(false);
    const [daysInfo, setDaysInfo] = useState([]);
    const [someDay, setSomeDay] = useState(0);
    const [available, setAvailable] = useState([])
    const [availableIsLoaded, setAvailableIsLoaded] = useState(false)
    const [booked, setbooked] = useState([false, []])
    //const [booked, setBooked] = useState([])
    const [bookedIsLoaded, setBookedIsLoaded] = useState(false)
    const [selectedSlots, setSelectedSlots] = useState(0);
    const [isOnline, setIsOnline] = useState(true);
    const [isClosedSlot, setIsClosedSlot] = useState(false);
    const [err, setErr] = useState(null)
    const [initTz, setinitTz] = useState(0)
    const [timeZone, settimeZone] = useState(initTz)


    const updateAvailable = day => {
        setSomeDay(day)
        setAvailableIsLoaded(false)
    }

    const timeConverter = UNIX_timestamp => {
        const
            months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            a = new Date(UNIX_timestamp),
            year = a.getFullYear(),
            month = months[a.getMonth()];
        let date = a.getDate();

        if (date < 10)
            date = '0' + date;

        return year + '-' + month + '-' + date;
    }

    let inject,
        i,
        somd,
        dow,
        c,
        time,
        fullDate,
        goToWeeks,
        fullDate_timestampt;
    let newDate = new Date();
    let start = newDate.setDate(newDate.getDate());
    somd = Math.round(new Date(start).getTime());
    fullDate = (new Date(somd)).getTime();
    fullDate = timeConverter(fullDate)
    let day = (new Date()).getDay()
    if (day === 0) day = 7
    start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);

    const createCalendar = array => {
        let daysInformation = [];
        goToWeeks = true;
        for (i = 0; i < 28; i++) {
            let b = [];
            c = i * 86400;
            somd = Math.round(new Date(start).getTime() / 1000);
            somd = (somd + c) * 1000;
            dow = (new Date(somd)).getDay();
            if (dow === 0) dow = 7
            fullDate_timestampt = (new Date(somd)).getTime();
            fullDate = timeConverter(fullDate_timestampt)
            somd = (new Date(somd)).getDate();
            if (array.filter(item => item.s_date === fullDate).length > 0) {
                time = array.filter(item => item.s_date === fullDate)
                for (let x = 0; x < time.length; x++) {
                    b.push(
                        {
                            id: x,
                            t: time[x].slot.time_start.slice(0, -3) + ' - ' + time[x].slot.time_end.slice(0, -3),
                            is_visitor: time[x].is_visitor,
                            is_active: time[x].is_active
                        },
                    )
                }
                inject = {
                    date: somd,
                    time: b.length > 0 ? b.sort(((a, b) => a.t > b.t ? 1 : -1)) : [],
                    dow: dow,
                    slotSize: '',
                    date_full: fullDate
                }
            }
            else {
                inject = { date: somd, time: '', dow: dow, slotSize: '', date_full: fullDate }
            }
            daysInformation.push(inject)

        }
        setDaysInfo([...daysInformation])
    }

    const reload = () => {
        setSelectedSlots(0);
        //setIsLOADED(false);
        setAvailableIsLoaded(false);
        setBookedIsLoaded(false);
        setIsClosedSlot(false)
        setbooked([false, []])
    }

    const saveSlot = () => {
        //e.preventDefault();
        if (selectedSlots === 0) return

        const body = {
            location: parseInt(location),
            s_date: someDay,
            slot: selectedSlots,
            is_online: isOnline,
            is_visitor: !isClosedSlot
        }
        apiConf = {
            method: 'post',
            link: 'time_slots/teacher/slots',
        }
        superAxiosRequest(apiConf, body)
            .then((res) => {
                addSlot(res.data)
                if (err !== null) setErr(null)
                reload();
            })
            /* .catch(({ response }) => {
                let error = response.data.error;
                setErr(error)
            }); */
    }

    useEffect(() => {
        if (!availableIsLoaded && location !== 0 && someDay !== 0) {
            apiConf = {
                method: 'get',
                link: 'time_slots/teacher/slots',
                params: {
                    location: location,
                    date: someDay
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                setAvailable(response.data)
                setAvailableIsLoaded(true);
            });
        }



    });

    useEffect(() => {
        if (locationList === null) {
            apiConf = {
                method: 'get',
                link: 'db/locations',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                setLocationList(response.data.result);
            });
        }
    }, []);

    useEffect(() => {
        if (location === 0) return;
        apiConf = {
            method: 'get',
            link: 'time_slots/teacher/month_booked',
            params: {
                location: location
            }
        }
        superAxiosRequest(apiConf, 'data').then(response => {
            setBookedSlots(response.data)
            createCalendar(response.data)
        });
        settimeZone(initTz)
    }, [location])

    useEffect(() => {
        bookedSlots.length && createCalendar(bookedSlots)
    }, [bookedSlots])

    const getSlotClassName = slot => {
        const base = "teacher-slots-card__slot"
        const aClassName = [base]
        if (slot.is_booked) aClassName.push(base + "--booked")
        if (!slot.is_visitor) aClassName.push(base + "--hc")
        if (!slot.is_active) aClassName.push(base + "--deleted")
        return aClassName.join(" ")
    }

    const createValues = id => {
        let arr = []
        let items = bookedSlots.find(x => x.id === id)
        var conf;
        var status = '';

        if (items.is_booked && items.is_active) {
            conf = {
                name: 'teacher_booked_informet',
                title: 'Редактировать слот',
                button: 'Закрыть',
                type: 'Informer',
                text: 'Для изминения данного слота неоходимо обратиться к администратору'
            }
        } else {
            if (!items.is_active) arr.push({ id: 1, name: 'Восстановить удалённый слот' })
            if (items.is_active) arr.push({ id: 3, name: 'Удалить слот' })
            if (!items.is_visitor) arr.push({ id: 4, name: 'Удалить статус "HELP CLUB' })
            if (items.is_visitor) arr.push({ id: 5, name: 'Добавить статус "HELP CLUB' })

            conf = {
                name: 'teacher_booked',
                title: 'Редактировать слот',
                button: 'Отправить',
                type: 'SelectorAndInput',
                active_inputs: ['select', "textarea"],
                width: '650px',
                pl_select: 'Выберите действие',
                select_array: arr,
                name_select: 'action',
                location_id: location,
                data: items,
                pl_texarea: 'Введите комментарий',
                name_textarea: 'comment',
                id: id
            }
        }

        modalStore.activity(conf)

    }

    const replaceTimeZone = (array, initTz, timeZone) => {
        let tmpArr = JSON.parse(JSON.stringify(array))
        for(let i=0; i < tmpArr.length; i++){
            tmpArr[i].slot.time_start = replaceTime(tmpArr[i].slot.time_start, initTz, timeZone)
            tmpArr[i].slot.time_end = replaceTime(tmpArr[i].slot.time_end, initTz, timeZone)
        }
        
        return tmpArr;
    }
    /* useEffect(() => {
    }, [daysInfo]) */
    return (
        <>
            <ContentHeader title={localisationMaster('title', Link)} />

            <div className="scheduleSettings">
                <div className="scheduleSettings__selectBox">
                <div className="scheduleSettings__selectLocation">
                    <SelectCustom
                        placeholder={localisationMaster('select-placeholder', Link)}
                        options={locationList}
                        sortKey="name"
                        valueID={location}
                        onChange={o => { setLocation(o.id); setinitTz(o.time_zone); reload(); }}
                    />
                    
                </div>
                <div className="scheduleSettings__selectTimeZone">
                            <SelectCustom
                                placeholder="Выберите часовой пояс"
                                valueID={timeZone}
                                isDisabled={location === 0}
                                options={Stores.timeZones}
                                onChange={o => settimeZone(o.id)}
                            />
                </div>
                </div>
                

                {location !== 0 && <Calendar
                    loaded={daysInfo}
                    calendarType="slots_teacher"
                    setSomeDay={updateAvailable}
                    someDayValue={someDay}
                />}

                {(bookedSlots.length && someDay !== 0 && bookedSlots.filter(x => x.s_date === someDay).length) ?
                 <div className="scheduleSettings__slots">
                    <div className="scheduleBooked">
                        <div className="scheduleBooked__slots">
                            <span className="scheduleBooked__slots--title">
                                Забронированные слоты
                            </span>
                            <div className="scheduleBooked__slots__slots-list" style={{ marginTop: '20px' }}>
                                <div className="teacher-slots-card__slots" style={{ padding: '0px' }}>
                                    {replaceTimeZone(bookedSlots, initTz, timeZone).filter(x => x.s_date === someDay).sort((a, b) => a.slot.id > b.slot.id ? 1 : - 1).map((item, index) => {
                                        return (
                                            <div key={index} className={getSlotClassName(item)} onClick={() => !item.slot.is_booked ? createValues(item.id) : {}}>
                                                <span>{item.slot.time_start} - {item.slot.time_end}</span>
                                                {/*{!item.is_visitor && <sup>&nbsp;HC</sup>}*/}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="scheduleBooked__slots--note">
                            Для редактирования слотов нажмите на нужном времени и внесите корректировки <br />
                            Редактировать занятые слоты - <strong>невозможно</strong>
                        </div>
                        <div className="scheduleBooked__slots--exp">
                            <SlotsExplain off_pic={true} />
                        </div>

                    </div>
                </div> : null}

                <div className="scheduleSettings__slots">
                    {someDay !== 0 && <ScheduleSettingSlots
                        items={available['available_slots']}
                        items_checked={available['checked_slots']}
                        selectedSlots={setSelectedSlots}
                        selectedSlotValue={selectedSlots}
                        isOnline={isOnline}
                        initTz={initTz}
                        timeZone={timeZone}
                        setIsOnline={setIsOnline}
                        location={location}
                        locationList={locationList}
                    />}
                </div>

                <div className="scheduleSettings__saveBox">

                    {/*<div className="scheduleSettings__hoursText">
                    <p>{localisationMaster('text-slot-info-2', Link)} <b>8 часов</b></p>
                    <p>{localisationMaster('text-slot-info-3', Link)} <b>4 часа 30 минут</b></p>
                </div>*/}

                    <div className="scheduleSettings__isClosedSlot">
                        <Checkbox id={Date.now() * Math.random()} label={localisationMaster('close-slot-label', Link) + " (Help Club)"} checked={isClosedSlot} isChecked={() => setIsClosedSlot(!isClosedSlot)} />
                    </div>

                    <div
                        className={selectedSlots !== 0 ? "button_add" : " button_add disabled"}
                        onClick={() => saveSlot()}>{localisationMaster('save-settings', Link)}
                    </div>

                    <div>{err}</div>

                </div>

            </div>
        </>
    )
}

export default observer(ScheduleSetting);
