import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import modalStore from "Stores/modalStore";

import SmartFilter from '../../Filter/SmartFilter';
import styles from "./SendDocument.module.scss"

const SendDocument = () => {
    const {changeValues, data} = modalStore
    const [clientData, setClientData] = useState([])

    const onChangeClient = (e) => {
        changeValues(Number(e[0].profile.id), data.fieldName)
        setClientData([e[0].profile.full_name])
    }

    return (
        <div className={styles.box}>
            <SmartFilter
                link={data.fieldLink}
                placeholder={`Вводите имя, e-mail или телефон ${data.user}а`}
                new_key="full_name"
                sub_key="profile"
                backFunction={onChangeClient} />
            {clientData[0] && <ul className={styles.list}>
                <li className={styles.item} >Выбранные клиенты:</li>
                {clientData.map((item, key) => <li key={key}>{item}</li>)}
            </ul>}
        </div>

    )
}

export default observer(SendDocument);
