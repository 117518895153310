import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {superAxiosRequest} from 'axiosApi';
import SelectCustom from '../../Select/SelectCustom';
import Loader from '../../Loader/Loader';
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";
import {dateFormat} from "helpers/date";
import asideModalStore from "Stores/asideModalStore";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";

const AddCertificate = () => {
  const {modalValues: values, changeValue, check} = asideModalStore
  const [state, setState] = useState([])

  useEffect(() => {
    superAxiosRequest({
      method: "get",
      link: "groups/certificates",
    }).then(res => {
      setState(res.data.result.map(item => ({id: item.id, name: item.name})))
    })
  }, [])

  return (
    <div>
      {state[0] ?
        <FormFieldset>
          <FormField>
            <FormLabel isRequired>Certificate:</FormLabel>
            <FormSelectCustom>
              <SelectCustom
                placeholder="Выбирите сертификат"
                options={state}
                valueID={values.certificate}
                onChange={(o) => changeValue(o.id, "certificate")}
                error={check("certificate")}
              />
            </FormSelectCustom>
          </FormField>
          <FormDateSelector
            placeholder="Start Date"
            isRequired
            button={{name: "Today", action: "today"}}
            label="Start Date"
            onChange={val => changeValue(dateFormat(val, "YYYY-mm-dd"), "date_from")}
            value={values.date_from || ''}
            error={check("date_from")}
          />
          <ToggleCheckbox
            label="Send notification"
            checked={values.send_email}
            onChange={() => changeValue(!values.send_email, "send_email")}
          />
        </FormFieldset>
        : <Loader/>}
    </div>
  )
}

export default observer(AddCertificate)
