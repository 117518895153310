import React, {Component} from "react";
import './Player.scss';
import Play from './audio.svg'


export default class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      playing: false,
      audio: null,
    };
  }

  tryLeft = () => {
    if (this.state.count < 3) {
      //return `Нажмите на значок для проигрывания аудио, вы можете прослушать запись ${3-this.state.count} раз(а)`
      return `Нажмите на значок для проигрывания аудио`
    } else {
      return 'Вы прослушали запись максимальное количество раз'
    }
  }

  componentDidMount() {
    this.setState({audio: new Audio(this.props.a_link)})
  }

  componentWillUnmount() {
    this.state.audio.pause();
  }

  componentDidUpdate(prevState) {
    if (prevState.playing !== this.state.playing) {
      this.state.playing ? this.state.audio.play() : this.state.audio.pause();
    }
    this.state.audio.addEventListener('ended', () => this.setState({playing: false}));
  }

  render() {
    return (
      <div className="player-box-content">
        <div onClick={this.state.count < 3 ? () => {
          this.setState({playing: !this.state.playing})
        } : false}>
          {this.state.count < 3 ? !this.state.playing ? <img src={Play} alt="q"/> : "||" : "X"}</div>
        <span className="player-box__tip">{this.tryLeft()}</span>
      </div>
    )
  }
}
