import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import swal from 'sweetalert';
import Calendar from 'components/UI/Calendar/Calendar';
import { getWeekDayName, humanizeDate } from 'components/UI/Calendar/utils';
import slotsScheduleSettingsStore, { ActionType, DataType } from 'Stores/slotsScheduleSettingsStore';
import { Action } from 'components/UI/Calendar/components/ContextMenu/ContextMenu';

function CalendarWrapper() {
  const {
    startDate,
    setData,
    dates,
    datesArray,
    selectedDates,
    selectedLocation,
    isSelectedDaysAvailable,
    resetData,
    getDataFromServer,
    sendDataToServer,
  } = slotsScheduleSettingsStore;
  const [isLoading, setIsLoading] = useState(true);

  const removeDays = (isRepeatFlag, onClose) => {
    onClose(false);
    let selectedArray = [];
    for (let value of selectedDates.values()) {
      selectedArray.push(datesArray[value].date_at);
    }
    const dayNames = isRepeatFlag
      ? `все ${getWeekDayName(selectedArray[0])}`
      : `даты: ${selectedArray.map((item) => humanizeDate(item, 'withoutYear')).join(', ')}`;
    swal(
      `Вы действительно хотите отключить ${dayNames}?`,
      'На эти дни нет бронирований сотрудников',
      'warning',
      { buttons: ['Отмена', 'Подтвердить'], }
    ).then((response) => {
      if (response) {
        sendDataToServer(ActionType.sendRemovedDays);
      }
    });
  };

  const handleContextMenuClick = (type, onClose) => {
    switch (type) {
      case Action.EDIT_DAYS:
        setData(false, DataType.isRepeat);
        break;
      case Action.EDIT_WEEKDAYS:
        setData(true, DataType.isRepeat);
        break;
      case Action.REMOVE_DAYS:
        setData(false, DataType.isRepeat);
        removeDays(false, onClose);
        return;
      case Action.REMOVE_WEEKDAYS:
        setData(true, DataType.isRepeat);
        removeDays(true, onClose);
        return;
      default:
        onClose(false);
        return;
    }
    getDataFromServer(
      ActionType.getSomeDays,
      () => {},
      () => onClose(false),
    );
  };

  useEffect(() => {
    if (!startDate) {
      return;
    }
    setIsLoading(true);
    getDataFromServer(
      ActionType.getAllDays,
      () => {},
      () => setIsLoading(false),
    );
  }, [startDate, selectedLocation]);

  return (
    <Calendar
      startDate={startDate}
      dates={dates}
      selectedDates={selectedDates}
      resetData={resetData}
      isLoading={isLoading}
      isSelectedDaysAvailable={isSelectedDaysAvailable}
      onStartDateChange={(date) => setData(date, DataType.startDate)}
      onClick={handleContextMenuClick}
      withMultipleSelect
      type='schedule-settings'
    />
  );
}

export default observer(CalendarWrapper);
