import React from "react";
import PropTypes from "prop-types";
import Calendar from "./Calendar";
import WorkshopSchedule from "./WorkshopSchedule";
import st from "./WorkshopTimeTable.module.scss"

const WorkshopTimeTable = props => {

  const getSchedules = () => {
    let schedules = []
    props.workshops.map(d => {
      const day = {...d}
      const sch = schedules.find(sch => sch.teachers.join() === day.teachers.join())
      if (sch){
        sch.days.push(day)
      } else {
        schedules.push({
          teachers: [...day.teachers],
          days: [day],
        })
      }
    })
    // Поиск дней недели
    // Поиск следующего дня для отображения времени занятий
    schedules = schedules.map(sch => {
      const lastDay = sch.days[sch.days.length-1]
      const nextDay = sch.days.find(d => new Date(d.date) > new Date()) || lastDay
      return {
        ...sch,
        weekdays: Array.from(new Set(sch.days.map(d => (new Date(d.date)).getDay() || 7))).sort((a, b) => a>b ? 1 : -1),
        start_at: nextDay.start_at,
        finish_at: nextDay.finish_at,
        dateFrom: new Date(sch.days[0].date),
        dateUntil: new Date(lastDay.date),
        classroom_name: sch.days[0].classroom.name,
      }
    })

    return schedules
  }

  return (
    <div className="timetable-box">
      <div className="timetable-box__header">
        <h3>Schedule & Attendance</h3>
      </div>
      {props.teachers?.length > 0 &&
        <div className={st.timetables}>{getSchedules().map(sch =>
          <WorkshopSchedule key={sch.teachers.join()} schedule={sch} teachers={props.teachers}/>)}
        </div>}
      <Calendar workshops={props.workshops}
                holidays={props.holidays}
                teachers={props.teachers}
                student={props.student}/>
    </div>
  )
}

WorkshopTimeTable.propTypes = {
  workshops: PropTypes.array,
  holidays: PropTypes.array,
  teachers: PropTypes.array,
  student: PropTypes.object,
}

export default WorkshopTimeTable
