import React from 'react';
import ResultSection from "./ResultSection";
import st from "./ResultSections.module.scss"

export default function ResultSections(props) {

    const getSection = section => {
        const sectionAnswers = props.answers.filter(a => a.test_section_id === (section?.id || null))

        const {
            correct_answers,
            total_answers,
            points
        } = sectionAnswers.reduce((acc, {stats}) => ({
            correct_answers: acc.correct_answers + stats.correct_answers,
            total_answers: acc.total_answers + stats.total_answers,
            points: acc.points + stats.points,
        }), {
            correct_answers: 0,
            total_answers: 0,
            points: 0,
        })

     

        return {
            ...section,
            name: section?.name || 'Без раздела',
            correct_answers,
            percentage: Math.round(correct_answers / total_answers * 100) || 0,
            points,
        }
    }

    return (
        <div className={st.list}>{props.sections.map(s =>
            <ResultSection key={s.id} section={getSection(s)}/>)}

            {props.answers.filter(a => a.test_section_id === null).length > 0 &&
            <ResultSection section={getSection(null)}/>}
        </div>
    );
};
