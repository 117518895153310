import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import styles from './InputInn.module.scss';

const INN_LENGTH = 12;
const options = [
  { id: 1, name: 'ИИН' },
  { id: 2, name: 'ИНН' },
];
const inputID = `inn-${Math.random()}`;
const removeNonNumber = (value) => value.replace(/\D/g, '');

function InputInn({ tag, value, label, onChange, name, isDisabled, isError, isRequired, withLabel, classes }) {
  const Tag = tag;
  const [selectedOption, setSelectedOption] = useState(0);
  const [error, setError] = useState(false);

  const checkInn = useCallback(() => {
    value.length > 0 && value.length !== INN_LENGTH
      ? setError(true)
      : setError(false);
  }, [value.length]);

  const handleSelectChange = (evt) => {
    setSelectedOption(+evt.target.value);
    onChange('');
  };
  const handleInputChange = (evt) => {
    const value = removeNonNumber(evt.target.value);
    const name = evt.target.name;
    onChange(value, name);
  };
  const handleBlur = (evt) => {
    const value = removeNonNumber(evt.target.value);
    if ((isRequired && !value) || (value.length > 0 && value.length !== INN_LENGTH)) {
      setError(true);
    }
  };

  useEffect(() => {
    checkInn();
  }, [checkInn]);

  return (
    <Tag
      className={cn({
        [styles.inn_wrapper]: true,
        [classes]: classes,
      })}
    >
      <label
        htmlFor={inputID}
        className={cn({
          [styles.label]: true,
          [styles.label_required]: isRequired,
        })}>
        {withLabel && <span> {label}:</span>}
      </label>
      <div
        className={cn({
          [styles.input_wrapper]: true,
          [styles.input_wrapper__error]: isError || error,
          [styles.input_wrapper__disabled]: isDisabled,
        })}
      >
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          disabled={isDisabled}
          className={styles.select}
        >
          <option value={0} disabled>{options[0].name}</option>
          {options.map(({ id, name }) => (
            <option
              key={id.toString()}
              value={id}
            >
              {name}
            </option>
          ))}
        </select>
        <input
          className={styles.input}
          value={value}
          disabled={isDisabled}
          name={name}
          id={inputID}
          maxLength={INN_LENGTH}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
        {error && <span className={styles.error_text}>Введите 12 цифр</span>}
      </div>
    </Tag>
  );
}

InputInn.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  tag: PropTypes.oneOf(['div', 'li', 'p']),
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  withLabel: PropTypes.bool,
};

InputInn.defaultProps = {
  tag: 'div',
  name: 'inn',
  isDisabled: false,
  isError: false,
  withLabel: true,
  isRequired: false,
};

export default InputInn;
