import React from "react";
import {permissions} from "settings/permissions";
import {superAxiosRequest} from "axiosApi";
import {splitNumberDigits} from "helpers/math";
import {phoneMask} from "helpers/phoneMask";
import {dateFormat} from "helpers/date";
import {checkPermList} from "helpers";
import {dict, translate as loc} from "Localisation";
import Stores from "Stores";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import st from "./PersonalInfo.module.scss";

export default function PersonalInfo({user: {profile, ...user}, openModalProfile, openModalUserInfo, translate}){
  const personals = [
    {name: 'ID', value: splitNumberDigits(profile.inn)},
    {name: 'E-mail', value: <a href={`mailto:${user.email}`}>{user.email}</a>},
    {
      name: translate.phone,
      value: profile.phone ?
        <a href={`tel:${profile.phone}`}>{phoneMask(profile.phone)}</a> :
        <span className={st.edit} onClick={openModalProfile}>{loc(dict.main_page.add_phone)}</span>},
    {
      name: translate.additional_phone,
      value: profile.mobile_phone ?
        <a href={`tel:${profile.mobile_phone}`}>{phoneMask(profile.mobile_phone)}</a> :
        <span className={st.edit} onClick={openModalProfile}>{loc(dict.main_page.add_phone)}</span>
    },
    {
      name: 'WhatsApp',
      value: profile.whatsapp ?
        <a href={'https://wa.me/' + profile.whatsapp}>{profile.whatsapp}</a> :
        <span className={st.edit} onClick={openModalProfile}>{loc(dict.main_page.specify_whatsapp)}</span>},
    {
      name: 'Telegram',
      value: profile.telegram ?
        <a href={`https://telegram.me/${profile.telegram}`}>{`@${profile.telegram}`}</a>
        :
        <span className={st.edit} onClick={openModalProfile}>{loc(dict.main_page.specify_nickname_telegram)}</span>
    },
  ]

  const getCorrectWordForm = (number, one, two, five) => {
    number = Math.abs(number) % 100;
    const num = number % 10;
    if(number > 10 && number < 20) return five;
    if(num > 1 && num < 5) return two;
    if(num === 1) return one;
    return five;
  }

  const getYearAndDaysSinceDate = (dateString) => {
    const timeDifference = new Date() - new Date(dateString);
    const differenceDate = new Date(timeDifference);

    const years = differenceDate.getUTCFullYear() - 1970;
    const days =  Math.round(timeDifference / (1000 * 60 * 60 * 24)) - 1;
    // yearsWord : one , two, five
    const yearsWord = getCorrectWordForm(years, translate.year, translate.of_the_year, translate.years);
    // daysWord : one, two , five
    const daysWord = getCorrectWordForm(days, translate.day, translate.day2, translate.days);
    return [`${years} ${yearsWord}`, `${days} ${daysWord}`];
  }

  // const works = [
  //   {
  //     name: translate.main_location,
  //     value:
  //       <span className={st.cursorPointer} onClick={openModalUserInfo}>
  //         {`${profile.location.name} (${profile.company.name})`}
  //       </span>
  //   },
  //   {
  //     name: translate.department,
  //     value:
  //       <span className={st.cursorPointer} onClick={openModalUserInfo}>
  //         {profile.employment ? profile.employment.name : loc(dict.main_page.not_specified)}
  //       </span>
  //   },
  //   {
  //     name: translate.job_title,
  //     value: <span className={st.cursorPointer} onClick={openModalUserInfo}>
  //       {profile.profession ? profile.profession.name : loc(dict.main_page.not_specified)}
  //     </span>
  //   },
  //   {
  //     name: translate.work_until,
  //     value:
  //         profile.work_at ?
  //             <div className={st.workSince}>
  //               <span className={st.paramValue}>{dateFormat(profile.work_at, "d MMMM YYYY")}</span>
  //               <span
  //                   className={st.daysAndYears}>{`(${getYearAndDaysSinceDate(profile.work_at)[0]}  | ${getYearAndDaysSinceDate(profile.work_at)[1]})`}</span>
  //             </div>
  //             : <span>{loc(dict.main_page.not_specified)}</span>
  //   },
  //   {name: translate.comment, value: profile.comment || <span className={st.edit} onClick={openModalUserInfo}>{translate.add}</span>},
  // ]

  const menu = [
    ...(checkPermList(permissions.edit_personal_information) ? [{action: 'personal', name: translate.personal_info}] : []),
    ...(checkPermList(permissions.edit_profile_comment) ? [{action: 'work', name: translate.working_info}] : []),
  ];

  const getTelegram = () => {
    superAxiosRequest({
      method: 'get',
      link: 'telegram'
    }).then(({data: {token}})=> {
      window.open (`https://t.me/${Stores.botname()}/?start=${token}`)
    }).catch(error => {
      alert(error)
    })
  }

  const action = action => {
    switch (action){
      case 'personal':
        openModalProfile()
        break;
      case 'work':
        openModalUserInfo()
        break;
      default:
        break;
    }
  }

  return (
    <div className={st.personalInfo}>
      <BlockHeader title={translate.personal_info}>
        {menu.length > 0 && <ActionMenu menu={menu} action={action}/>}
      </BlockHeader>

      <div className={st.body}>
        <div>
          <ul>{personals.map((item, index) =>
            <li key={index}>
              <span className={st.paramName}>{item.name}:</span>
              <span className={st.paramValue}>{item.value}</span>
            </li>)}
          </ul>
        </div>
        {/*<div className={st.telegram}>*/}
        {/*  <p>Telegram Bot:</p>*/}
        {/*  <span onClick={getTelegram}>{translate.connect_now}!</span>*/}
        {/*</div>*/}
      </div>

      {/*<div className={st.footer}>*/}
      {/*  <ul>{works.map((item, index) =>*/}
      {/*    <li key={index}>*/}
      {/*            <span className={st.paramName}>{item.name}:</span>*/}
      {/*            <span className={st.paramValue}>{item.value}</span>*/}
      {/*    </li>)}*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </div>
  )
}
