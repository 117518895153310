import React, {useState} from 'react';
//import './SubMenu.scss';

const SubMenu =(props)=> {

    const [buttonActive, setButtonActive] = useState(props.titles.init || 0);

    const change =(value, type)=> {
        props.myChangeState(value, type);
        setButtonActive(value);
    };

    return (
        <div className="surf-menu">
            <div className="surf-menu__grad">
                <div className="surf-menu__tabs">
                 {
                     props.titles.values.map(el => (
                        <div 
                            className={"surf-menu__tab " + (buttonActive === el.id ? " surf-menu__tab--active" : "")}
                            onClick={()=>change(el.id, props.titles.name)} key={el.id}>
                            {el.name}
                            <div className={buttonActive ?'subMenuLine' : null}></div>
                        </div>
                     ))
                 }
             </div>

        </div>
    </div>
    )
};

export default SubMenu;
