import React, {useState} from "react";
import st from "./MakeSentences.module.scss";
import {ReactComponent as ClueIcon} from "components/ContentBox/img/clue.svg";
import {MakeSentence} from "./index";

export default function MakeSentences({exercise: {data: {sentences, descriptions}}, ...props}) {
  const [active, setActive] = useState(Array.from({length: sentences?.length}).fill(false))
  const handleAnswers = (answers, isActive, index) => {
    const allActive = active.map((is, i) => i === index ? isActive : is)
    const arr = [...props.answers]
    arr[index] = answers
    setActive(allActive)
    props.handleAnswers(arr, allActive.every(x => x))
  }

  return (
    <div className={st.makeSentences}>{sentences?.map((s, i) =>
      <div key={i.toString()} className={sentences?.length > 1 ? st.oneSentence : undefined}>
        {sentences?.length > 1 && <span className={st.counter}>{`${i + 1} / ${sentences?.length}`}</span>}
        {(descriptions && descriptions[i]?.length > 0) && ( // проверка descriptions осуществляется для того, чтобы не ломались уже существующие упр-я без descriptions
          <div className={st.clue}>
            <ClueIcon/>
            <p>{descriptions[i]}</p>
          </div>
        )}
        <MakeSentence {...props}
                      sentence={s}
                      handleAnswers={(answers, isActive) => handleAnswers(answers, isActive, i)}
                      studentAnswer={props.studentAnswer && {
                        ...props.studentAnswer,
                        answer: props.studentAnswer.answer.at(i),
                        result_in_detail: props.studentAnswer.result_in_detail.at(i)
                      }}
        />
      </div>)}
    </div>
  )
}
