import {action, makeObservable, observable} from "mobx";
import {translate as loc, dict} from "Localisation";



class Store {
    apiUrl = `https://${window.location.hostname !== 'localhost' ? window.location.hostname : 'dev.phoenix24.kz'}/api/v1/`

    constructor() {
        makeObservable(this, {
            apiUrl: observable,
            reload: observable,
            reloadAvatar: observable,
            loader: observable,
            error: observable,
            errorTxt: observable,
            tmp_array: observable,
            baseLink: observable,
            botname: action,
            filtred: observable,
            filtredState: observable,
            setFiltred: action,
            checkUser: action,
            profileId: observable,
        })
        this.AuthUsers = new AuthUsers()
        this.SuperModal = new SuperModal(this)
        this.Rules = new Rules()
    }

    botname = () =>{
        if(window.location.hostname === 'phoenix.ru.net'){
            return 'InterPressAccessBot'
        } else if(window.location.hostname === 'phoenix.interpress.kz'){
            return 'InterPress_bot'
        } else {
            return null
        }
    }

    baseLink =  () => {
        if(window.location.hostname !== 'localhost'){
            return 'https://' + window.location.hostname
        } else {
            return 'https://dev.phoenix24.kz'
        }
    }

    cleanSumm(summ){
        if(summ === 0 || !summ) return 0
        //if(!summ) return;
        summ = summ.toFixed(2)
        let mini = summ.toString().split('.')[1]
        let maxi = summ.toString().split('.')[0]
        return parseInt(maxi).toLocaleString('ru') + ',' + mini
    }
    filtred = null
    filtredState = false
    setFiltred(data){
        this.filtred = data
        this.filtredState = true
    }
    observableArray(array){
        return JSON.parse(JSON.stringify(array))
    }

    reload = false;
    reloadAvatar = false;

    clean = false;
    loader = false;
    error = false;
    errorTxt = '';
    tmp_array = [];

    timeZones = [
        {id: -12,name: `(GMT -12) ${loc(dict.time_zones.text_1)}`,name_tz_main: "Pacific/Baker"},
        {id: -11,name: `(GMT -11) ${loc(dict.time_zones.text_2)}`,name_tz_main: "Pacific/Jarvis"},
        {id: -10,name: `(GMT -10) ${loc(dict.time_zones.text_3)}`,name_tz_main: "Pacific/Hawaii"},
        {id: -9,name: `(GMT -9) ${loc(dict.time_zones.text_4)}`,name_tz_main: "America/Alaska"},
        {id: -8,name: `(GMT -8) ${loc(dict.time_zones.text_5)}`,name_tz_main: "America/Baja-California"},
        {id: -7,name: `(GMT -7) ${loc(dict.time_zones.text_6)}`,name_tz_main: "America/Yukon"},
        {id: -6,name: `(GMT -6) ${loc(dict.time_zones.text_7)}`,name_tz_main: "America/Central-America"},
        {id: -5,name: `(GMT -5) ${loc(dict.time_zones.text_8)}`,name_tz_main: "America/Monterrey"},
        {id: -4,name: `(GMT -4) ${loc(dict.time_zones.text_9)}`,name_tz_main: "America/Montreal"},
        {id: -3,name: `(GMT -3) ${loc(dict.time_zones.text_10)}`,name_tz_main: "America/Salvador"},
        {id: -2,name: `(GMT -2) ${loc(dict.time_zones.text_11)}`,name_tz_main: "Mid-Atlantic"},
        {id: -1,name: `(GMT -1) ${loc(dict.time_zones.text_12)}`,name_tz_main: "Atlantic/Cabo-Verde"},
        {id: 0,name: `(GMT +0) ${loc(dict.time_zones.text_13)}`,name_tz_main: "Etc/Greenwich"},
        {id: 1,name: `(GMT +1) ${loc(dict.time_zones.text_14)}`,name_tz_main: "Europe/Dublin"},
        //{id: 1,name: "(GMT +1) Лондон",name_tz_main: "Europe/London"},
        {id: 2,name: `(GMT +2) ${loc(dict.time_zones.text_15)}`,name_tz_main: "Europe/Amsterdam"},
        //{id: 3,name: "(GMT +3) Стамбул",name_tz_main: "Europe/Istanbul"},
        //{id: 3,name: "(GMT +3) Никосия",name_tz_main: "Asia/Nicosia"},
        {id: 3,name: `(GMT +3) ${loc(dict.time_zones.text_16)}`,name_tz_main: "Europe/Moscow"},
        {id: 4,name: `(GMT +4) ${loc(dict.time_zones.text_17)}`,name_tz_main: "Asia/Tbilisi"},
        //{id: 3,name: "(GMT +3) Киев",name_tz_main: "Europe/Kiev"},
        //{id: 3,name: "(GMT +3) Минск",name_tz_main: "Europe/Minsk"},
        {id: 5,name: `(GMT +5) ${loc(dict.time_zones.text_18)}`,name_tz_main: "Asia/Yekaterinburg"},
        //{id: 5,name: "(GMT +5) Ташкент",name_tz_main: "Asia/Tashkent"},
        {id: 6,name: `(GMT +6) ${loc(dict.time_zones.text_19)}`,name_tz_main: "Asia/Almaty"},
        {id: 7,name: `(GMT +7) ${loc(dict.time_zones.text_20)}`,name_tz_main: "Asia/Novosibirsk"},
        //{id: 7,name: "(GMT +7) Красноярск",name_tz_main: "Asia/Krasnoyarsk"},
        {id: 8,name: `(GMT +8) ${loc(dict.time_zones.text_21)}`,name_tz_main: "Asia/Shanghai"},
        //{id: 8,name: "(GMT +8) Куала-Лумпур",name_tz_main: "Asia/Kuala_Lumpur"},
        //{id: 8,name: "(GMT +8) Сингапур",name_tz_main: "Asia/Singapore"},
        {id: 9,name: `(GMT +9) ${loc(dict.time_zones.text_22)}`,name_tz_main: "Asia/Seoul"},
        {id: 10,name: `(GMT+10) ${loc(dict.time_zones.text_23)}`,name_tz_main: "Asia/Vladivostok"},
        {id: 12,name: `(GMT+12) ${loc(dict.time_zones.text_24)}`,name_tz_main: "Asia/Kamchatka"},
        {id: 13,name: `(GMT+13) ${loc(dict.time_zones.text_25)}`,name_tz_main: "Oceania/Samoa"},
        {id: 14,name: `(GMT+14) ${loc(dict.time_zones.text_26)}`,name_tz_main: "Pacific/Kiritimati"},
    ];

    userLocalGet(){
        let dataStorage = localStorage.getItem('ud');
        let data = JSON.parse(dataStorage);
        return data.user_data.profile.localization
    }

    checkAdmin(){
        let dataStorage = localStorage.getItem('ud');
        let data = JSON.parse(dataStorage);
        return data.user_data.groups[0].name === 'admin'
    }
    checkUser(){
        let dataStorage = localStorage.getItem('ud');
        let data = JSON.parse(dataStorage);
        return data.user_data.groups[0].name === 'clients'
    }

    isTeacher = JSON.parse(localStorage.getItem('ud'))?.user_data?.groups[0].name.includes('teacher')
    isClient = JSON.parse(localStorage.getItem('ud'))?.user_data?.groups[0].name.includes('clients')
    isAdmin = JSON.parse(localStorage.getItem('ud'))?.user_data?.groups[0].name.includes('admin')

    profileId = JSON.parse(localStorage.getItem('ud'))?.user_data?.profile.id

    userTimeZone = JSON.parse(localStorage.getItem('ud'))?.user_data?.profile.time_zone
    utz = Number(this.userTimeZone) > 0 ? `+${this.userTimeZone}` : this.userTimeZone



    transliterate(string) {
        let
                rus = "щ  ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(/ +/g),
                eng = "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(/ +/g),
                kz_eng = "a á b v g ǵ d e ıo j z ı ı k q l m n ń o ó p r s t u ú ý f h h ts ch sh sh y i e ıý ıa".split(/ +/g),
                kz = "а ә б в г ғ д е ё ж з и й к қ л м н ң о ө п р с т ұ ү у ф х һ ц ч ш щ ь ъ ы і э ю я".split(/ +/g)

               // Балаларға бүгінгі
            ;
        const isCyrillic = text => /[яәғқңұүһ]/i.test(text)

        const isEng = text => /[a-z]/i.test(text)

        if(this.userLocalGet() === 'EN'){
            let result = ''
                if(!isCyrillic(string) && !isEng(string)){
                    for(let x = 0; x < string.length;x++){
                        if(string[x] === ' '){
                            result += ' '
                        } else {
                            if(string[x] === string[x].toLowerCase()){
                                if(eng[rus.indexOf(string[x].toLowerCase())] !== undefined) result += eng[rus.indexOf(string[x].toLowerCase())]
                            } else {
                                if(eng[rus.indexOf(string[x].toLowerCase())] !== undefined) result += eng[rus.indexOf(string[x].toLowerCase())].toUpperCase()
                            }
                        }
                    }
                } else if(isCyrillic(string) && !isEng(string)){
                    for(let x = 0; x < string.length;x++){
                        if(string[x] === ' '){
                            result += ' '
                        } else {
                            if(string[x] === string[x].toLowerCase()){
                                if(kz_eng[kz.indexOf(string[x].toLowerCase())] !== undefined) result += kz_eng[kz.indexOf(string[x].toLowerCase())]
                            } else {
                                if(kz_eng[kz.indexOf(string[x].toLowerCase())] !== undefined) result += kz_eng[kz.indexOf(string[x].toLowerCase())].toUpperCase()
                            }
                        }
                    }
                } else {
                    result = string
                }
            return result

        } else{
            return string
        }
    }

    stqt = [
            { "id": 0, "name": "Вопрос с текстовым ответом", "value": "question_plus_text"},
            { "id": 1, "name": "Вопрос с вариантами ответов", "value": "variants"},
            { "id": 2, "name": "Аудиофайл с вариантами ответов", "value": "audio_plus_variants"},
            { "id": 3, "name": "Видеофайл с вариантами ответов", "value": "video_plus_variants"},
            { "id": 4, "name": "Изображение с вариантами ответов", "value": "image_plus_variants"},
            { "id": 5, "name": "Изображение с текстовым ответом", "value": "image_plus_text"},
            { "id": 6, "name": "Собрать предложение", "value": "complete_sentence"},
            { "id": 7, "name": "Вставить пропущенные слова в пропусках", "value": "text_missing_words"},
            { "id": 8, "name": "Собрать предложение", "value": "make_sentence" },
            { "id": 9, "name": "Вопрос с вариантами ответов", "value": "question_text_variants" },
            { "id": 10, "name": "Изображение с вариантами ответов", "value": "image_text_variants" },
            { "id": 11, "name": "Аудиофайл с вариантами ответов", "value": "audio_text_variants"},
            { "id": 12, "name": "Видеофайл с вариантами ответов", "value": "video_text_variants" },
            { "id": 13, "name": "Вопрос с текстовым ответом", "value": "question_text_field" },
            { "id": 14, "name": "Изображение с текстовым ответом", "value": "image_text_field" },
            { "id": 15, "name": "Аудиофайл с текстовым ответом", "value": "audio_text_field" },
            { "id": 16, "name": "Видеофайл с текстовым ответом", "value": 'video_text_field' },
            { "id": 17, "name": "Вставить пропущенные слова в пропусках", "value": "question_tag_variants" },
        ]
}

class Rules {
    rulesList = []
    permissions = new Map()
    tmp_rules = []
    cleanRules = []

    constructor() {
        makeObservable(this,{
            getRulesList: action,
            checkRule: action,
        })
    }

    checkRule(key, action){
        if(action && key && this.cleanRules[key] !== undefined){
            if(Array.isArray(action)){
                if(action.length === 1){
                    return this.cleanRules[key].indexOf(action[0]) > -1;
                } else {
                    return action.some(test => this.cleanRules[key].includes(test));
                }
            }else{
                return this.cleanRules[key].indexOf(action) > -1;
            }
        }
    }

    getRulesList(){
        let dataStorage = localStorage.getItem('ud');
        let data = JSON.parse(dataStorage);
        this.rulesList = data.permissions
            for(let key in this.rulesList){
                this.tmp_rules[key] = this.parceRules(this.rulesList[key])
            }
            for(let keyx in this.tmp_rules){
                for(let kex in this.tmp_rules[keyx]){
                    this.cleanRules[kex] =  this.tmp_rules[keyx][kex]
                }
            }
        //console.log('this.', this.cleanRules)
    }

    getRule(value){
        let start = value.split('|')[0]
        let main = start.split('_')[0]
        let sub = start.split('_')[1].trim()
        if(main === 'change') main = 'edit'
        let result = main;
        if(sub.match(/^(all|own|list|free|booked|stats|range|client|myself|absence)$/i)){
            result = `${main}_${sub}`
        }
        // Еще костылёк
        if(main === 'edit' && sub.match(/^(password[\s])$/i)){
            result = `${main}_${sub}`.trim()
        }

        return result
    }

    parceRules(array){
        let tmp = []
        let tmp_inside = []
        let readyrule = []
        for(let key in array){
            tmp = array[key]
            for(let keyx in tmp){
                tmp_inside = tmp[keyx]
                let x = []
                for(let keyy in tmp_inside){
                    x.push(this.getRule(tmp_inside[keyy]))
                   readyrule[keyx] = x
                }
            }
        }
        return readyrule
    }

    getPermissionsList(){
        const dataStorage = localStorage.getItem('ud');
        const data = JSON.parse(dataStorage);
        const permissions = data?.permissions || []
        this.permissions = new Map(
            Object.keys(permissions).map(k =>
                [k, new Map(
                    permissions[k].map((obj, index) => {
                        const key = Object.keys(obj)[0]
                        return [key, new Map(
                            permissions[k][index][key].map(val => [val.split('|')[0].trim(), val])
                        )]
                    })
                )]
            )
        )
       
    }

    checkRule2(parent_key, key, permissions){
        if (this.permissions.has(parent_key)){
            if (this.permissions.get(parent_key).has(key)) {
                if (Array.isArray(permissions)){
                    return permissions.some(permission => this.permissions.get(parent_key).get(key).has(permission))
                }
                else
                    return this.permissions.get(parent_key).get(key).has(permissions)
            }
            else return false
        } else return false
    }
}

class AuthUsers {
    isAuth = false;
    userData = null

    constructor() {
        makeObservable(this,{
            isAuth: observable,
            userData: observable,
            setIsAuth: action,
            createUserData: action,
        })
    }

    setIsAuth = val => this.isAuth = val

    createUserData = val => this.userData = val
}

class SuperModal {
    constructor(Store) {
        this.Store = Store;
        makeObservable(this,{
            open: observable,
            loading: observable,
            data: observable,
            lastAction: observable,
            activity: action,
            loadingHandler: action,
        })
    }
    open = false;
    loading = false;
    data = [];
    fix_data = [];
    lastAction = []

    loadingHandler(state){
        this.loading = state
    }

    activity = (data, fix_data, lastAction) => {
        if(data && data !== 0){
            this.data = data;
            this.fix_data = fix_data;
            if(lastAction) this.lastAction = lastAction
        }
        if(this.open && data !== 0){
            if(this.loading) this.loadingHandler(false)
            this.data = [];
            this.fix_data = [];
            this.Store.reload = false;
        }
        this.open = !this.open
    }
}

Store = new Store()

export default Store
