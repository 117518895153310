import React from 'react'

import "./TeacherCard.scss"
import {timeConverter} from "../components/functions";

export default function TeacherCardBody(props) {
    //const link = 'online-schedule/booked-teachers-schedule'
    const teacher = props.item
    const days = teacher.items

    const getSlotClassName = slot => {
        const base = "teacherCard__slot"
        const aClassName = [base]
        if (slot.is_booked) aClassName.push(base+"--booked")
        if (!slot.is_visitor) aClassName.push(base+"--hc")
        if (!slot.is_active) aClassName.push(base+"--deleted")
        return aClassName.join(" ")
    }

    const getSlotDuration = slot => (new Date('2020-11-11T'+slot.time_end) - new Date('2020-11-11T'+slot.time_start)) / 1000 / 60

    const getHours = min => Math.trunc(min/60) + ':' + (min%60<10 ? '0'+min%60 : min%60)

    const stat = {
        all: {
            count: 0,
            duration: 0
        },
        available: {
            count: 0,
            duration: 0
        },
        deleted: {
            count: 0,
            duration: 0
        },
        hc: {
            count: 0,
            duration: 0
        },
        booked: {
            count: 0,
            duration: 0
        },
        missed: {
            count: 0,
            duration: 0
        },
        worked: {
            count: 0,
            duration: 0
        },
        free: {
            count: 0,
            duration: 0
        },
    }

    days.map(day => {
        stat.all.count += day.teacher_slots.length
        day.teacher_slots.map(slot => {
            stat.all.duration += getSlotDuration(slot.slot)
            if (slot.is_active){
                if (slot.is_visitor) {
                    if (slot.is_booked){
                        stat.available.count++
                        stat.available.duration += getSlotDuration(slot.slot)
                    }
                    else {
                        stat.available.count++
                        stat.available.duration += getSlotDuration(slot.slot)

                        stat.free.count++
                        stat.free.duration += getSlotDuration(slot.slot)
                    }
                }
                else {
                    stat.hc.count++
                    stat.hc.duration += getSlotDuration(slot.slot)
                }
            }
            else {
                stat.deleted.count++
                stat.deleted.duration += getSlotDuration(slot.slot)
                if (!slot.is_visitor){
                    stat.hc.count++
                    stat.hc.duration += getSlotDuration(slot.slot)
                }
            }
            return null

        })
        return null
    })

    const aType = ['booked', 'missed', 'worked']
    aType.map(type => {
        const [sCount, sDuration] = teacher[type].split("/")
        stat[type] = {
            count: +sCount,
            duration: sDuration * 60
        }
        return null
    })

    const rowTpl = (title, name) =>
        <div>
            <b>{title}:</b>
            <span>{stat[name].count}</span>
            <i>/</i>
            <span>{stat[name].duration} мин.</span>
            <i>/</i>
            <span>{getHours(stat[name].duration)} ч.</span>
        </div>

    return (
        <div>
            <div className="teacherCard__summary">
                <div className="teacherCard__summaryCol">
                    {rowTpl('Всего слотов', 'all')}
                    {rowTpl('Доступные', 'available')}
                    {rowTpl('Удаленные', 'deleted')}
                    {rowTpl('Закрытые', 'hc')}
                </div>
                <div className="teacherCard__summaryCol">
                    {rowTpl('Занятые клиентами', 'booked')}
                    {rowTpl('Пропущенные', 'missed')}
                    {rowTpl('Отработанные', 'worked')}
                    {rowTpl('Свободные', 'free')}
                </div>
            </div>
            <div className="teacherCard__days">
                {days.sort((a,b)=>new Date(a.date)-new Date(b.date)).map((day, index) =>
                    <div key={index}>
                        <time>{timeConverter(day.date, 'day').toLowerCase()}:</time>
                        <div className="teacherCard__slots">
                            {day.teacher_slots.map(slot =>
                                <div key={slot.id} className={getSlotClassName(slot)}>
                                    <span>{slot.slot.time_start.slice(0, -3)} - {slot.slot.time_end.slice(0, -3)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
