import React, { useState } from 'react';
import Stores from 'Stores';
import styles from "./InputUrl.module.scss";

const InputUrl = ({ url, onChange, error }) => {
    const [hover, setHover] = useState(false);
    return (
        <div className={`${styles.wrapper} ${error ? styles.error : ''} ${hover ? styles.hover : ''}`}>
            <p>{Stores.baseLink()}/teacher-record-online-test/</p>
            <input 
                type="text" 
                value={url} 
                onChange={e => onChange(e.target.value)} 
                placeholder='pogodin-igor'
                onFocus={() => setHover(true)}
                onBlur={() => setHover(false)} 
            />
        </div>
    )
}

export default InputUrl;