import React, {useState, useEffect} from 'react';
import ActionMenu from '../../Global/ActionMenu/ActionMenu';
import {timeConverter, agetostr} from '../components/functions'
import SlotResult from './SlotResult';
import {superAxiosRequest} from 'axiosApi';
import Stores from 'Stores';
import {localisationMaster} from "Localisation/Localisation";

import './Card.scss'
import modalStore from '../../../../Stores/modalStore';

export default function Card(props) {
    const [detail, setDetail] = useState(props.type !== 'inGroup' && true)

    var menu = [
        {name: localisationMaster('menuName_1', 'online-schedule/clients-after-online'), action: 'edit', permission: ['forward']},
        {name: localisationMaster('menuName_2', 'online-schedule/clients-after-online'), action: 'user_profile', link: '/account_clients', nav_link: true, permission: ['forward']},
/*        {name: localisationMaster('subMenu_5', 'online-schedule/clients-after-online'), action: 'refused', permission: ['forward']},
        {name: localisationMaster('menuName_4', 'online-schedule/clients-after-online'), action: 'pending', permission: ['forward']},
        {name: localisationMaster('menuName_5', 'online-schedule/clients-after-online'), action: 'detail', permission: ['forward']}
 */    ]

    if((!!props.items.level_name && !props.items.is_missed)) menu.push({
        name: localisationMaster('menuName_3', 'online-schedule/clients-after-online'), action: 'get_group', permission: ['forward']
    })

    const clientInfo = (id, profile) => {
        let modal_settings = {
            name: 'client_info',
            link: 'accounts/clients/' + profile,
            button: localisationMaster('text_54', 'groups/all-groups'),
            title: localisationMaster('text_3', 'online-schedule/clients-after-online'),
            type: 'client_info',
            data: 'json',
            width: '800px',
            id_client: id,
            profile: profile,
            is_put: true,
        }

        modalStore.activity(modal_settings)
    }

    const action = data => {
        switch (data) {
/*             case 'pending':
                qJson =
                    {
                        "client_model_data": {
                            "is_wait": true
                        }
                    }
                apiConf = {
                    method: 'put',
                    link: 'time_slots/teacher/clients/' + props.items.id
                }
                superAxiosRequest(apiConf, qJson).then(response => {
                    props.drop([false, []])
                })
                    .catch(err => {

                    })
                break;
            case 'detail':
                setDetail(!detail)
                break;
            case 'get_group':
                let a = document.createElement('a');
                a.target = "_blank";
                a.href = '/groups/sign-up/admin/' + props.items.student.profile_id + '/' + props.items.id;
                a.click();
                //window.location = '/groups/sign-up/admin/' + props.items.student.profile_id + '/' +  props.items.id
                break; */
            case 'edit':
                clientInfo(props.items.student.id, props.items.student.profile_id)
                break;
            case 'get_group':
                let a = document.createElement('a');
                a.target = "_blank";
                a.href = '/groups/sign-up-student/' + props.items.student.profile_id;
                a.click();
                //window.location = '/groups/sign-up/admin/' + props.items.student.profile_id + '/' +  props.items.id
                break;
/*             case 'refused':
                qJson =
                    {
                        "client_model_data": {
                            "is_refused": true
                        }
                    }
                apiConf = {
                    method: 'put',
                    link: 'time_slots/teacher/clients/' + props.items.id
                }
                superAxiosRequest(apiConf, qJson).then(response => {
                    props.drop([false, []])
                })
                    .catch(err => {

                    })
                break; */
            case 'user_profile':
                window.location = '/account_clients/' + props.items.student.id
                break;
            default:
                break;
        }
    }

/*     useEffect(() => {
        switch (props.type) {
            case 'without':
                //delete menu[3]
                //delete menu[4]
                break;
            case 'inGroup':
                delete menu[3]
                delete menu[2]
                delete menu[0]
                break;
            case 'wait':
                delete menu[1]
                delete menu[3]
                delete menu[4]
                break;
            default:
                break;
        }
    }, [props.type])
 */
    return (
        <div className="main-table-card-box">
            <div className="main-table-card-box__box">
            <div className="clientCard__header">
                <div className="clientCard__client">
                    <span>{Stores.transliterate(props.items.student._last_name + ' ' + props.items.student._first_name)}</span>
                    <span
                        className="">{agetostr(props.items.student_date_of_birth) + ', ' + timeConverter(props.items.student_date_of_birth, 'age')}</span>
                </div>

                <div className="clientCard__phone">
                    <a href={`tel:${props.items.student_phone}`}>{props.items.student_phone}</a>
                </div>

                <div className="clientCard__email">
                    <a href={`mailto:${props.items.student.email}`}>{props.items.student.email.slice(0, 100)}</a>
                </div>

                <div className="clientCard__actions">
                    <ActionMenu menu={menu} action={action} id={props.items.student.id}/>
                </div>

            </div>

            <div className="clientCard__body">
                {props.type === 'inGroup' &&
                <div className="clients-after__groups-list">
                    <span>Групп: {props.items.groups.length}</span>
                    {props.items.groups.map((item, index) => {
                        return (
                            <span key={index}>
                                {item.name}
                            </span>
                        )
                    })}
                </div>
                }
                {detail && <SlotResult result={props.items} />}
            </div>
            </div>
        </div>
    )
}
