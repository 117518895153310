import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/UI/Flex/Flex';
import { localisationMaster } from "Localisation/Localisation";
import WorkingHoursNotice from 'components/Modules/Slots/components/WorkingHoursNotice/WorkingHoursNotice';
import Select from 'components/UI/Select/Select';
import styles from './SelectorLayout.module.scss';
import slotsTeacherSchedule, { ActionType, DataType } from 'Stores/slotsTeacherScheduleStore';

const getLocationOptions = (data) => data.map(({ id, name, start_at, end_at, address, time_zone}) => ({
  value: id,
  label: name,
  startAt: start_at,
  endAt: end_at,
  address: address,
  timeZone: time_zone,
}));

function SelectorLayout() {
  const [locationList, setLocationList] = useState([]);
  const [isLocationListLoading, setIsLocationListLoading] = useState(true);
  const { getDataFromServer, location, setData, resetData } = slotsTeacherSchedule;

  const handleLocationChange = (option) => {
    if (option.value === location?.value) {
      return;
    }
    resetData();
    setData(option, DataType.location);
  };

  useEffect(() => {
    if (locationList.length > 0) {
      setIsLocationListLoading(false);
      return;
    }
    getDataFromServer({
      type: ActionType.getLocations,
      onSuccess: (data) => setLocationList(getLocationOptions(data.locations)),
      onFinally: () => setIsLocationListLoading(false),
    });
  }, []);

  return (
    <Flex asColumn align='stretch' justify='start' withGap classes={styles.inner}>
      <Select
        placeholder={localisationMaster('Choose-a-location', 'online-schedule/time-slots')}
        options={locationList.sort((a, b) => a.label > b.label ? 1 : -1)}
        value={location}
        onChange={handleLocationChange}
        classes={styles.select}
        isLoading={isLocationListLoading}
        isDisabled={isLocationListLoading}
      />
      {location &&
        <WorkingHoursNotice
          startAt={location.startAt}
          endAt={location.endAt}
          timeZone={location.timeZone}
          address={location.address}
        />
      }
    </Flex>
  );
}

export default observer(SelectorLayout);
