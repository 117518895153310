import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import slotsNewRecordLessonStore from "./slotsNewRecordLessonStore";
import MainSelectors from "../components/MainSelectors/MainSelectors";
import Flex from "components/UI/Flex/Flex";
import CalendarWrapper from "../components/CalendarWrapper/CalendarWrapper";
import Slots from "../components/Slots/Slots";
import Submit from "../components/Submit/Submit";
import PaymentChoice from "../components/PaymentChoice/PaymentChoice";
import st from '../NewRecordOnline/NewRecordOnline.module.scss';
import logo from "components/HeadBox/img/logo.png";
import OrderStatus from "../components/OrderStatus/OrderStatus";


const store = slotsNewRecordLessonStore

const NewRecordLesson = () => {
  const {
    selectedLocation,
    selectedProgram,
    selectedAge,
    slots,
    selectedSlots,
    paymentStep,
  } = store

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    store.getOptionsFromServer(setError, setIsLoading);
  }, []);

  const isReadyToLoadCalendar = (selectedLocation && selectedProgram && selectedAge)

  return (
    <div className={st.page}>
      <header className={st.header}>
        <h1 className={st.title}>Онлайн запись на онлайн урок</h1>
        <MainSelectors isLoading={isLoading} store={store} className={st.wrapper__selects} />
      </header>
      <main className={st.main}>{error ||
        <Flex asColumn withGap>
          {isReadyToLoadCalendar && <CalendarWrapper store={store}/>}
          {slots.length > 0 && <Slots store={store}/>}
          {selectedSlots.size > 0 && <>
            <PaymentChoice store={store} paymentComment="Онлайн урок"/>
            <Submit store={store}/>
          </>}
          {paymentStep > 1 && <OrderStatus store={store} /> }
        </Flex>}
      </main>
      <footer className={st.footer}>
        <p className={st.footer_text}>powered by</p>
        <img className={st.footer_img} src={logo} alt="Logo" />
      </footer>
    </div>
  )
}

export default observer(NewRecordLesson)
