import React, {useEffect, useRef, useState} from 'react'
import Stores from 'Stores';
import {localisationMaster} from "Localisation/Localisation";
import ActionMenu from "../../Global/ActionMenu/ActionMenu";
//import AvatairCreator from '../../Global/AvatairCreator/AvatairCreator'

import "./TeacherSlotsCard.scss"
import modalStore from '../../../../Stores/modalStore';

export default function TeacherSlotsCard(props) {
    const link = 'online-schedule/booked-teachers-schedule';
    const item = props.item

    const menu = [
        {name: localisationMaster('text-info-4', link), action: 'schedule'},
        {name: localisationMaster('profile', link), action: 'profile', link: '/account', nav_link: true},
    ]

    const refSlider = useRef(null)
    const refSlots = useRef(null)

    const [openListing, setOpenListing] = useState(false)

    const action = type => {
        switch (type) {
            case 'schedule':
                setOpenListing(!openListing)
                break;
            /*case 'profile':
                window.location = '/account/' + props.items.student.id
                break;*/
            default:
                break;
        }
    }
    
    /*const splitName =(data, x)=>{
        data = data.split(' ');
        return data[x]
    }*/
  
    const createModal = (data) =>{
        let conf = {
            name: 'bts',
            disable_buttons: true,
            title: localisationMaster('text-info-2', link),
            type: 'bts',
            data: props.item.items[data],
            location: props.item.location,
            width: '650px',
        }
        modalStore.activity(conf)
    }

    const getSlotClassName = slot => {
        const base = "teacher-slots-card__slot"
        const aClassName = [base]
        if (slot.is_booked) aClassName.push(base+"--booked")
        if (!slot.is_visitor) aClassName.push(base+"--hc")
        if (!slot.is_active) aClassName.push(base+"--deleted")
        return aClassName.join(" ")
    }

    useEffect(()=>{
        refSlider.current.style.height = openListing ? refSlots.current.offsetHeight+'px' : ''
    }, [openListing])
    
    const createValues = index =>{
        const items = props.item.items[index];
        const arr = [];
        if(!items.is_active) arr.push({id: 1, name: 'Восстановить удалённый слот'})
        if(items.is_booked && items.is_active)  arr.push({id: 2, name: 'Удалить слот, и перезаписать клиента'})
        if(items.is_active)  arr.push({id: 3, name: 'Удалить слот'})
        if(!items.is_visitor) arr.push({id: 4, name: 'Удалить статус "HELP CLUB'})
        if(items.is_visitor) arr.push({id: 5, name: 'Добавить статус "HELP CLUB'})
        const conf = {
            name: 'teacher_booked',
            tag: 'admin',
            title: localisationMaster('text-info-2', link),
            button: 'Отправить',
            type: 'SelectorAndInput',
            active_inputs:['select', "textarea"],
            width: '650px',
            pl_select: 'Выберите действие',
            select_array: arr,
            name_select: 'action',
            location_id: props.item.location.id,
            data: items,
            t_id: props.item.teacher_id,
            pl_texarea: 'Введите комментарий',
            name_textarea: 'comment',
            id: items.id
            }
        modalStore.activity(conf)

    }

    return (
        <div className="teacher-slots-card">
            <div className="teacher-slots-card__header">
                <div className="teacher-slots-card__header-content" onClick={()=>setOpenListing(!openListing)}>

                    {/*<AvatairCreator name={splitName(Stores.transliterate(props.item.teacher_name), 1)}
                                    l_name={splitName(Stores.transliterate(props.item.teacher_name), 0)} />*/}

                    <div className="teacher-slots-card__name">{Stores.transliterate(item.teacher_name)}</div>

                    <div className="teacher-slots-card__count">{item.items.length + "/" + item.items.filter(item=>!item.is_booked && item.is_visitor).length}</div>

                </div>
                <div>
                    <ActionMenu menu={menu} action={action} id={item.teacher_id} />
                </div>
            </div>
            <div className="teacher-slots-card__slider" ref={refSlider}>
                <div className="teacher-slots-card__slots" ref={refSlots}>
                    {item.items.map((item, index) =>
                        <div key={index} className={getSlotClassName(item)} onClick={()=>createValues(index)}>
                            <span>{item.slot.time_start.slice(0, -3)} - {item.slot.time_end.slice(0, -3)}</span>
                            {/*{!item.is_visitor && <sup>&nbsp;HC</sup>}*/}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
