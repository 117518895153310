import { localisationMaster } from "Localisation/Localisation";

const MILLISECONDS_IN_DAY = 86400000;
const weekDays = ['воскресенья', 'понедельники', 'вторники', 'среды', 'четверги', 'пятницы', 'субботы'];
export const SHOWN_DAYS = 28;

const roundTimeStamp = (stamp) => Math.trunc(stamp / MILLISECONDS_IN_DAY);

export const getWeekDayName = (timestamp) => {
  const weekDayNumber = new Date(timestamp).getDay();
  return weekDays[weekDayNumber];
};

export const humanizeDate = (timestamp, type) => {
  const MONTHS = [
    localisationMaster('months_1', 'months'), 
    localisationMaster('months_2', 'months'), 
    localisationMaster('months_3', 'months'), 
    localisationMaster('months_4', 'months'), 
    localisationMaster('months_5', 'months'), 
    localisationMaster('months_6', 'months'), 
    localisationMaster('months_7', 'months'), 
    localisationMaster('months_8', 'months'), 
    localisationMaster('months_9', 'months'), 
    localisationMaster('months_10', 'months'), 
    localisationMaster('months_11', 'months'), 
    localisationMaster('months_12', 'months')
  ];

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = MONTHS[date.getMonth()];
  const dateNumber = date.getDate();

  switch (type) {
    case 'dateNumber':
      return dateNumber;
    case 'withoutYear':
      return `${dateNumber} ${month}`;
    case 'full':
    default:
      return `${dateNumber} ${month}, ${year} ${localisationMaster('text-5', 'online-schedule/future-slots')}`
  }
};

export const calcDaysRange = (timestamp) => {
  const currentDate = new Date(timestamp);
  const weekDay = currentDate.getDay();
  const todayDate = currentDate.getDate();
  const startTimestamp = currentDate.setDate(todayDate - weekDay + 1);
  const endTimestamp = currentDate.setDate(todayDate - weekDay + SHOWN_DAYS);
  return {
    startDate: startTimestamp,
    endDate: endTimestamp,
  };
};

export const shiftDaysRange = (type, startDate) => {
  const start = new Date(startDate);
  const shiftValue = type === 'left' ? start.getDate() - SHOWN_DAYS : start.getDate() + SHOWN_DAYS;
  const shiftedStamp = start.setDate(shiftValue);
  return calcDaysRange(shiftedStamp);
};

export const calcCurrentDay = (startTime, itemIndex) => {
  const startDate = new Date(startTime);
  const today = Date.now();
  const currentDateStamp = startDate.setDate(startDate.getDate() + itemIndex);
  const currentDayNumber = new Date(currentDateStamp).getDate();
  const roundedCurrentDate = roundTimeStamp(currentDateStamp);
  const roundedToday = Math.trunc(today / MILLISECONDS_IN_DAY);

  return {
    timeStamp: currentDateStamp,
    dayNumber: currentDayNumber,
    isPast: roundedCurrentDate < roundedToday,
    isToday: roundedCurrentDate === roundedToday,
  };
};

export const checkDateIsPast = (dayStamp) => {
  const roundedDate = roundTimeStamp(dayStamp);
  const roundedToday = roundTimeStamp(Date.now());
  return roundedDate <= roundedToday;
};

export const getMondayDate = (today) => {
  const todayWeekDay = today.getDay();
  const todayDayNumber = today.getDate();
  return todayWeekDay === 1
    ? today
    : new Date(today.setDate(todayDayNumber - todayWeekDay + 1));
};
