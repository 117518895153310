import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import Input from 'components/UI/Input/Input';
import Button from 'components/UI/Button/Button';
import TimeSlot from 'components/Modules/Slots/ScheduleSettings/components/TimeSlots/TimeSlot';
import styles from './TimeSlots.module.scss';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';

function TimeSlots() {
  const { slots, setSlotCount } = slotsScheduleSettingsStore;
  const [inputValue, setInputValue] = useState(0);

  const handleInputChange = (evt) => {
    const value = +evt.target.value.replace(/\D/g, '');
    setInputValue(value);
  };

  return (
    <Paper>
      <Flex>
        <SectionTitle
          title='Временные слоты и доступные учителя'
          description='Укажите количество доступных сотрудников на каждый временной слот'
        />
        <Flex withGap>
          <Input
            pattern='[0-9]*'
            inputMode='numeric'
            value={inputValue.toString()}
            onChange={handleInputChange}
            label='Применить ко всем'
            placeholder='0'
            withLabel={false}
            classes={styles.input}
          />
          <Button onClick={() => setSlotCount(inputValue, 'all')}>
            Применить ко всем
          </Button>
        </Flex>
      </Flex>

      <ul className={styles.slots}>
        {
          slots.map(({start_at, finish_at, teachers_count}) => (
            <TimeSlot
              key={start_at + finish_at}
              start={start_at}
              end={finish_at}
              count={teachers_count}
            />
          ))
        }
      </ul>

    </Paper>
  );
}

export default observer(TimeSlots);
