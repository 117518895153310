export const ExcelRowsParams = {
    headerRowParam: {
        t: 's',
        s: {
            fill: {fgColor: {rgb: "FFFFFF"}},
            font: {bold: true, sz: 14, color: {rgb: "5f6368"}},
            alignment: {vertical: "center", horizontal: "center", wrapText: true},
            border: {
                right: {style: "medium", color: {rgb: '959999'}},
                bottom: {style: "medium", color: {rgb: '959999'}},
            }
        }
    },
    tableRowParam: {
        firstCell: {
            t: 's',
            s: {
                fill: {fgColor: {rgb: "FFFFFF"}},
                font: {sz: 14, color: {rgb: "5f6368"}},
                alignment: {vertical: "center", horizontal: "left", wrapText: true},
                border: {
                    right: {style: "medium", color: {rgb: '959999'}},
                    bottom: {style: "medium", color: {rgb: '959999'}},
                }
            }
        },
        cell: {
            t: 's',
            s: {
                fill: {fgColor: {rgb: "FFFFFF"}},
                font: {sz: 14, color: {rgb: "5f6368"}},
                alignment: {vertical: "center", horizontal: "center", wrapText: true},
                border: {
                    right: {style: "medium", color: {rgb: '959999'}},
                    bottom: {style: "medium", color: {rgb: '959999'}},
                }
            }
        },
    },
    footerRowParams: {
        firstCell: {
            t: 's',
            s: {
                fill: {fgColor: {rgb: "5A8DEE"}},
                font: {bold: true, sz: 12, color: {rgb: "FFFFFF"}},
                alignment: {vertical: "center", horizontal: "left", wrapText: true},
            }
        },
        cell: {
            t: 's',
            s: {
                fill: {fgColor: {rgb: "5A8DEE"}},
                font: {bold: true, sz: 12, color: {rgb: "FFFFFF"}},
                alignment: {vertical: "center", horizontal: "center", wrapText: true},
            }
        }
    }

}

export const setCellParams = (row, params) => {
    let newRow = row.map(item => ({...item, ...params}))
    return newRow
}