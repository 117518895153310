import {company} from "./cats/company";
import {widget} from "./cats/widget";
import {mailing} from "./cats/mailing";
import {docs} from "./cats/docs";
import {groups_s} from "./cats/groups";
import {pays} from "./cats/pays";
import {study} from "./cats/study";
import {online} from "./cats/online";
import {unidance} from "./cats/unidance";

export const listingCardSettings = () => [].concat(company, widget, mailing, docs, groups_s, pays, study, online, unidance)
