import React from "react";
import st from "./EmptyBalance.module.scss";
import emptyBalance from "./img/no_balance.svg";

const EmptyBalance = (props) => (
  <div className={st.emptyBalance}>
    <img src={emptyBalance} alt="empty" className={st.image}/>
    <button className={st.btn} onClick={props.createBalances}>{props.translate.add_new_company_balance}</button>
  </div>
)


export default EmptyBalance;
