import React from 'react';
import swal from "sweetalert";
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import {NavLink} from 'react-router-dom';
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores';
import groupStore from 'Stores/groupStore';
import modalStore from 'Stores/modalStore';
import ActionMenu from "../../Global/ActionMenu/ActionMenu";
import "./TimeTableUnit.scss"
import {dateFormat} from "../../../../helpers/date";

const TimeTableUnit = props => {

    const menu = [
        {
            name: localisationMaster('extend_schedule', 'groups/group'),
            action: 'prolongation',
            permission: ['forward']
        },
        {
            name: localisationMaster('text_13', 'lessons/course'),
            action: 'delete',
            permission: ['forward']
        },
        {
            name: localisationMaster('button-one', 'company/profiles/account'),
            action: 'edit',
            permission: ['forward']
        },
        (new Date() < new Date(props.items.date_until) && new Date() > new Date(props.items.date_from)) ?
            {
                name: localisationMaster('close_schedule', 'groups/group'),
                action: 'close',
                permission: ['forward']
            } : {},
        {
            btn_link: {name: 'Визуальное расписание', link: '/group/visual-schedule/' + props.gid, target: '_blank'},
            permission: ['forward']
        },
    ]

    const action = type => {
        switch (type) {
            case 'edit':
                editSchedule(props.items)
                break;
            case 'delete':
                deleteSchedule(props.items.id)
                break;
            case 'close':
                closeSchedule(props.items)
                break;
            case 'prolongation':
                prolongSchedule(props.items)
                break;

            default:
                break;
        }
    }

    const createDates = (dates, separator) => {
        if (dates !== undefined) {
            let n_dates = dates.split('-');
            return n_dates[2] + separator + n_dates[1]
        } else {
            return ''
        }
    }

    const weekDay = array => {
        if (array !== undefined) {
            array = array.sort((a, b) => a.id > b.id ? 1 : -1)
            let days = [
                localisationMaster('text_22', 'groups/all-groups'),
                localisationMaster('text_23', 'groups/all-groups'),
                localisationMaster('text_24', 'groups/all-groups'),
                localisationMaster('text_25', 'groups/all-groups'),
                localisationMaster('text_26', 'groups/all-groups'),
                localisationMaster('text_27', 'groups/all-groups'),
                localisationMaster('text_28', 'groups/all-groups')
            ];
            let ready = [];
            let c;
            for (let i = 0; i < array.length; i++) {
                c = array[i].day
                ready.push(
                    days[c - 1]
                )
            }
            return ready.join('/')
        } else {
            return ''
        }
    }

    const deleteSchedule = id => {
        swal({
            title: localisationMaster('text_2', 'alert'),
            text: `${localisationMaster('text_57', 'groups/all-groups')}`,
            icon: "warning",
            buttons: [localisationMaster('button_2', 'alert'), localisationMaster('button_1', 'alert')],
            dangerMode: true,
        }).then(willDelete => {
            if (willDelete) {
                const apiConf = {
                    method: 'delete',
                    link: 'schedule/' + id
                }
                superAxiosRequest(apiConf).then(({data}) => {
                    groupStore.updateGroupData(data)
                })
                swal(localisationMaster('text_6', 'alert'), {
                    icon: "success",
                });
            } else {
                swal(localisationMaster('text_5', 'alert'))
            }
        })
    }

    const editSchedule = sch => {
        const ms = {
            type: 'time_table',
            name: 'time_table',
            title: localisationMaster('text_53', 'groups/all-groups'),
            button: localisationMaster('edit', 'groups/group', 'group_schedule'),
            is_put: true,
            link: 'schedule/' + props.items.id,
            data: 'json',
            width: '800px',
            payload: {
                holidays: props.holidays,
                location: props.location,
                g_id: +props.gid,
                schedule_id: sch.id,
                teachers_ids: sch.teachers.map(({id}) => id),
                ...(sch.classroom && {classroom_id: sch.classroom.id})
            },
            func: groupStore.updateGroupData
        }
        const md = {
            group: +props.gid,
            color: sch.color,
            date_from: sch.date_from,
            date_until: sch.date_until,
            start_at: sch.start_at.slice(0, 5),
            finish_at: sch.finish_at.slice(0, 5),
            hour_type: +sch.hour_type.id,
            hours_per_day: +sch.hours_per_day,
            hours_total: +sch.hours_total,
            lunch_break: +sch.lunch_break,
            teachers: sch.teachers.map(({id}) => id),
            weekdays: sch.weekdays.map(({day}) => day),
            ...(sch.classroom && {classroom: sch.classroom.id})
        }
        modalStore.activity(ms, md)
    }

    const prolongSchedule = sch => {
        const ms = {
            type: 'time_table',
            name: 'time_table',
            title: localisationMaster('extend_schedule', 'groups/group'),
            button: localisationMaster('extend', 'groups/group', 'group_schedule'),
            is_put: true,
            link: 'schedule/' + sch.id,
            data: 'json',
            payload: {
                holidays: props.holidays,
                location: props.location,
                g_id: +props.gid,
                prolong: true,
            },
            width: '800px',
            func: groupStore.updateSchedule,
        }
        const dateUntil = new Date(sch.date_until);
        dateUntil.setDate(dateUntil.getDate() + 1);
        const md = {
            group: +props.gid,
            color: sch.color,
            date_from: dateFormat(dateUntil, 'YYYY-mm-dd'),
            start_at: sch.start_at.slice(0, 5),
            finish_at: sch.finish_at.slice(0, 5),
            hour_type: +sch.hour_type.id,
            hours_per_day: +sch.hours_per_day,
            hours_total: 3,
            lunch_break: +sch.lunch_break,
            teachers: sch.teachers.map(({id}) => id),
            weekdays: sch.weekdays.map(({day}) => day),
            ...(sch.classroom && {classroom: sch.classroom.id}),
            is_prolong: true,
        }
        modalStore.activity(ms, md)
    }

    const closeSchedule = items => {
        const ms = {
            name: 'close_sch',
            type: 'close_schedule',
            title: localisationMaster('text_53', 'groups/all-groups'),
            button: 'Закрыть',
            is_put: true,
            link: 'schedule/' + props.items.id,
            payload: {
                holidays: props.holidays,
                maxDate: items.date_until,
            },
            func: groupStore.updateSchedule,
        }

        const md = {
            group: +props.gid,
            color: items.color,
            date_from: items.date_from,
            date_until: dateFormat(new Date(Date.now() - 24 * 60 * 60 * 1000), 'YYYY-mm-dd'),
            start_at: items.start_at.slice(0, 5),
            finish_at: items.finish_at.slice(0, 5),
            hour_type: +items.hour_type.id,
            hours_per_day: +items.hours_per_day,
            hours_total: +items.hours_total,
            lunch_break: +items.lunch_break,
            weekdays: items.weekdays.map(item => item.day),
            classroom: items.classroom?.id,
            ...(Array.isArray(items.teachers) && {teachers: items.teachers.map(t => t.id)}),
            is_close: true,
        }

        modalStore.activity(ms, md)
    }
    return (
        <div className="time-table-unit-box">
            <div className="time-table-unit-box__left">
                <div className="time-table-unit-box__teachers">{props.items.teachers.length > 0 ?
                    props.items.teachers.map(teacher =>
                        <NavLink className="time-table-unit-box__name" target="_blank" key={teacher.id}
                                 to={`/account/${teacher.user_id}/`}>
                            {Stores.transliterate(teacher.last_name + ' ' + teacher.first_name)}
                        </NavLink>)
                    :
                    <div className="time-table-unit-box__name">
                        {localisationMaster('teacher_not_selected', 'groups/group')}
                    </div>}
                </div>

                <div className={props.active ? "time-table-unit-box__units time-table-unit-box__units-medium ": "time-table-unit-box__units"}>

                    <div>
                        {props.items.weekdays !== undefined ? weekDay(props.items.weekdays) : ''}
                    </div>

                    <div>
                        {props.items.start_at ? props.items.start_at.substr(0, 5) : ''} - {props.items.finish_at ? props.items.finish_at.substr(0, 5) : ''}
                    </div>

                    <div>
                        {props.items.date_from === props.items.date_until ?
                            createDates(props.items.date_from, '.') :
                            createDates(props.items.date_from, '.') + ' - ' + createDates(props.items.date_until, '.')
                        }
                    </div>

                    <div>
                        {props.items.classroom ?
                            (localisationMaster('cl', 'groups/group') + '. ' + props.items.classroom.name) :
                            null}
                    </div>

                    <div className="time-table-unit-box__hours-total">
                        {`${+props.items.hours_total} ${props.items.hour_type.minutes === 45 ? localisationMaster('ac_h', 'groups/group') : localisationMaster('astr_h', 'groups/group')}`}
                    </div>

                </div>
            </div>

            <div>
                {Stores.Rules.checkRule('studygroup', 'add_client') && <ActionMenu menu={menu} action={action}/>}
            </div>

        </div>
    )
}

export default observer(TimeTableUnit)
