import React from 'react';
import { observer } from 'mobx-react';
import InputToggle from '../../../../../UI/InputToggle/InputToggle';
import InputsWrapper from '../../../../../UI/InputsWrapper/InputsWrapper';
import {adaptOnlineSystem} from '../../adapters';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

function OnlineSystemToggles() {
  const onlineSystemToggles = adaptOnlineSystem();
  const activeID = slotsSettingsStore.onlineSystem;

  return (
    <InputsWrapper>
      {
        onlineSystemToggles.map(({id, name, label}) => (
          <InputToggle
            key={`${name}-${id}`}
            tag='li'
            label={label}
            name={name}
            selected={id === activeID}
            onChange={() => slotsSettingsStore.toggleOnlineSystem(id)}
          />
        ))
      }
    </InputsWrapper>
  );
}

export default observer(OnlineSystemToggles);
