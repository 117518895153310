import React from "react";
import PropTypes from "prop-types";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import st from "./AuthFooter.module.scss";
import srcLogo from "components/Auth/logo.png";
import LanguageSelector from "./LanguageSelector";


const AuthFooter = props => {
  return (
    <footer className={st.footer}>
      <div className={st.logoLanguageRow}>
        <div className={st.footerLogo}>
          <img src={srcLogo} alt="logo"/>
        </div>
        <div className={st.language}><LanguageSelector/></div>
      </div>
      <div className={st.promo}>
        <img src={props.srcPromo} alt="promo"/>
      </div>
      <div className={st.copyrightDomain}>
        <div>
          COPYRIGHT ©{dateFormat(new Date(), "YYYY")} PHOENIX, All rights Reserved
        </div>
        <div className={st.domain}>
          {Stores.baseLink()}/
        </div>
      </div>
    </footer>
  )
}

AuthFooter.propTypes = {
  srcPromo: PropTypes.string.isRequired
}

export default AuthFooter
