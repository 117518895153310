import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainSelectors from "components/Modules/Slots/components/MainSelectors/MainSelectors";
import Content from 'components/Modules/Slots/NewRecordOnline/Content/Content';
import styles from './NewRecordOnline.module.scss';
import logo from 'components/HeadBox/img/logo.png';
import slotsNewRecordOnlineStore from 'Stores/slotsNewRecordOnlineStore';

function NewRecordOnline() {
  const [isLoading, setIsLoading] = useState(true);
  const [isParseError, setIsParseError] = useState(false);
  const [error, setError] = useState(false);

  const {data64} = useParams();

  useEffect(() => {
    let data = {};

    try {
      // const dataString = Buffer.from(data64, 'base64').toString();
      const dataString = decodeURIComponent(escape(window.atob(data64)));
      
      data = JSON.parse(dataString);

      slotsNewRecordOnlineStore.getOptionsFromServer(setError, setIsLoading);
      localStorage.setItem('tmp_array_new_wu', JSON.stringify(data));
    }
    catch {
      setIsParseError(true);
    }
  }, [data64]);

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <h1 className={styles.title}>Онлайн запись на устное тестирование</h1>
        <MainSelectors isLoading={isLoading} store={slotsNewRecordOnlineStore} className={styles.wrapper__selects} />
      </header>
      <main className={styles.main}>
        {(error || isParseError)
          ? 'При загрузке страницы возникла ошибка, обновите страницу.'
          : <Content />
        }
      </main>
      <footer className={styles.footer}>
        <p className={styles.footer_text}>powered by</p>
        <img className={styles.footer_img} src={logo} width='20' alt='логотип компании в виде феникса' />
      </footer>
    </div>
  );
}

export default NewRecordOnline;
