import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn} from '../../../helpers';
import styles from './Paragraph.module.scss';

function Paragraph({tag, children, withBigMargin, withMarginTop, warning}) {
  const Tag = tag;

  return (
    <Tag
      className={cn({
        [styles.text]: true,
        [styles.margin]: withBigMargin,
        [styles.margin_top]: withMarginTop,
        [styles.warning]: warning,
      })}
    >
      {children}
    </Tag>
  );
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOf(['div', 'p']),
  withBigMargin: PropTypes.bool,
  withMarginTop: PropTypes.bool,
  warning: PropTypes.bool,
};

Paragraph.defaultProps = {
  tag: 'p',
  withBigMargin: false,
  withMarginTop: false,
  warning: false,
};

export default Paragraph;

