import React from "react";
import {classNames as cn} from "helpers"
import st from "./ExerciseAttemptsPanel.module.scss";

const ExerciseAttemptsPanel = ({activeTry, setActiveTry, answers = []}) => {
  const attempts = Array.from({length: 10}, (v, i) => ++i).map(idx => {
    const answer = answers[idx - 1]
    return {
      attempt: idx,
      result: answer ? Array.isArray(answer.result) ? answer.result.every(item => item === null) ? 'unknown' : !answer.result.includes(false) : answer.result : null
    }
  })

  return (
    <div className={st.exerciseAttemptsPanel}>{attempts.map((attempt) =>
      <div key={attempt.attempt}
           className={cn({
             [st.attempt]: true,
             [st.attemptWrong]: attempt.result === false,
             [st.attemptRight]: attempt.result === true,
             [st.attemptUnknown]: attempt.result === 'unknown',
             [st.attemptCurrent]: attempt.attempt === activeTry
           })}
           onClick={() => setActiveTry(attempt.attempt)}/>)}
    </div>
  )
}

export default ExerciseAttemptsPanel;
