import React from 'react';
import { observer } from 'mobx-react';
import Paragraph from '../../../../../../UI/Paragraph/Paragraph';
import InputWithControls from '../../../../../../UI/InputWithControls/InputWithControls';
import slotsSettingsStore from '../../../../../../../Stores/slotsSettingsStore';

function AdminTab() {
  const { admin_time_booking: adminTime } = slotsSettingsStore.paymentsOptions;

  const handleTimeChange = (value) => {
    slotsSettingsStore.setPaymentTime('admin_time_booking', value);
  };

  return (
    <div>
      <Paragraph withBigMargin>
        <b>ВНИМАНИЕ:</b> клиент будет подтвержден на слот только после проверки администратором
      </Paragraph>
      <InputWithControls
        value={adminTime}
        type="мин."
        label="Время резервирования слота до подтверждения администратором"
        onChange={handleTimeChange}
      />
    </div>
  );
}

export default observer(AdminTab);

