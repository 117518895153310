import React, {useState} from 'react';
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import style from './Reports.module.scss';
import HeaderContentBox from 'components/Modules/Groups/components/HeaderContentBox';
import TabMenu from 'components/Modules/Ebook/Common/TabMenu/TabMenu';
import AverageProgressReport from 'components/Modules/Groups/components/StudentsProgressReports/AverageProgressReport/AverageProgressReport';
import StudentsProgressReport from 'components/Modules/Groups/components/StudentsProgressReports/StudentsProgressReport/StudentsProgressReport';
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import { dateFormat } from 'helpers/date';
import useLocalization from 'hooks/useLocalization';


const getMonth = (date) => {
  return dateFormat(new Date(date), 'MMM, YYYY')
}

const Reports = ({reports}) => {


  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_rating_select',
    dict: {
      "students_progress_report": "Students Progress Report",
    }
  })

  const getMonthData = (month) => {
    return reports.filter((item) => getMonth(item.evaluation_date) === month && item.is_active)
  }

  const extractMonthYearFromDateArray = (dateArray) => {
    const sortedArray = [...dateArray].sort((a, b) => new Date(a.evaluation_date) - new Date(b.evaluation_date))
    const extractedArray = sortedArray.map((item) => {
      const date = new Date(item.evaluation_date);
      return dateFormat(new Date(date), 'MMM, YYYY')
    });
    const arr = [...new Set(extractedArray)]
    return arr.map((item,index) => ({id: index+1, name: item})) 
  }

  const repo = [...reports]
  const tabs = extractMonthYearFromDateArray(repo)
  const [open, setOpen] = useState(false)
  const [activeMonth, setActiveMonth] = useState(tabs[0])
  const monthData = getMonthData(activeMonth?.name)
  
  const averageRatingPerMonth = (allRatings) => {
    if (allRatings.length > 1) {
      return  allRatings.reduce((sum,rating) => sum + rating, 0) / allRatings.length
    } else {
      return allRatings[0]
    }
  }

  const allRatings = monthData
      .map((item) => item.rate_list
          .map((item) => item.value)
          .reduce((sum,rating) => sum+ rating, 0) / item.rate_list.length)


  return (
    <HeaderContentBox className={style.ratingList}>
      <OpenHeader title={translate.students_progress_report}
                  onClick={() => setOpen(!open)}/>
      {open && <>
      {
         reports.length > 0 ?
          <div className={style.list}>
            <TabMenu tabs={tabs} onChange={(tab)=> setActiveMonth(tab)} tabID={activeMonth?.id}/>
            <AverageProgressReport data={monthData} activeMonth={activeMonth?.name} type='student' averageRatingPerMonth={averageRatingPerMonth(allRatings)}/>
            {monthData.map((item, index) =>
                item.is_active &&
                    <StudentsProgressReport key={index} item={item} />
                )
            }
          </div> : 
          <div className={style.empty}>
              <EmptyData/>
          </div>
        }

        </>}
    </HeaderContentBox>
  )
}

export default Reports;