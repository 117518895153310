import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const parseUrl = url => JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
// функция парсить query params в объект. Пример: ?company=1&location=1 --> { company: 1, location: 1 }.

const useUrlParams = () => {
    const { location, ...history } = useHistory();
    const baseUrl = location.search.length > 3 ? parseUrl(location.search.substring(1)) : null; // ищем наличие query params в url.
    const [params, setParams] = useState(baseUrl);

    const setUrlParams = (data, key) => setParams({ ...params, [key]: data });

    const clearUrlParams = () => {
        setParams(null);
        history.push({ search: '' }); // очищаем url от query params.
    };

    const setAllParams = data => setParams(data);

    useEffect(() => {
        // при изменений объекта params
        const keys = Object.keys(params || {});
        // превращаем его в query params
        const search = '?' + keys.map((key, idx) => `${key}=${params[key]}${idx !== keys.length - 1 ? '&' : ''}`).join(''); 
        // и пушим в url
        history.push({ search });
    }, [params]);

    return { urlParams: params, setUrlParams, clearUrlParams, setAllParams };
}

export default useUrlParams;