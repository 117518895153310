import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {superAxiosRequest} from "axiosApi";
import st from "./ClientSelector.module.scss";

const ClientSelector = props => {
  const [clients, setClients] = useState([])
  const [query, setQuery] = useState([])
  const [filterClients, setFilterClients] = useState([])

  const componentRef = useRef();

  const clickOutsideHandler = e => {
    if (!componentRef.current.contains(e.target))
      setFilterClients([])
  }

  const changeHandler = query => {
    setQuery(query)
    if (query.length < 3) {
      setFilterClients([])
    } else {
      setFilterClients([...clients].filter(client =>
        client.last_name.includes(query)
        || client.last_name.toLowerCase().includes(query)
        || client.first_name.includes(query)
        || client.first_name.toLowerCase().includes(query)
        || client.full_name.includes(query)
        || client.barcode.includes(query)
      ))
    }
    props.onChange(null)
  }

  const clickHandler = val => {
    setQuery(`${val.last_name} ${val.first_name}`)
    setFilterClients([])
    props.onChange(val)
  }

  useEffect(() => {
    // Клиенты
    superAxiosRequest({
      method: 'get',
      link: 'unidance/client_barcode',
      params: {
        limit: 999
      }
    }).then(res => {
      setClients(res.data.result)
    }).catch(() => {

    })
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', clickOutsideHandler)

    return () => document.body.removeEventListener('click', clickOutsideHandler)
  }, [])

  return (
    <div className={st.wrap} ref={componentRef}>
      <input type="text"
             placeholder="Имя ученика или штрихкод"
             className={filterClients.length > 0 ? st.inputPopup : st.input}
             value={query}
             onChange={e => changeHandler(e.target.value)}
      />
      {filterClients.length > 0 &&
        <div className={st.popup}>{filterClients.map(client =>
          <div key={client.id} className={st.row} onClick={() => clickHandler(client)}>
            <span>{`${client.last_name} ${client.first_name}`}</span>
            <span>{client.barcode}</span>
          </div>)}
        </div>}
    </div>
  )
}

ClientSelector.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default ClientSelector
