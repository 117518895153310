import React, { useMemo, useState } from 'react';
import { ReactComponent as Arrow } from 'components/HeadBox/components/Search/img/arrow.svg';
import { classNames as cn } from "helpers";
import styles from './Dropdown.module.scss';

const Dropdown = ({ className, options = [], current, setCurrent }) => {
    const [isOpen, setIsOpen] = useState(false);

    const currentOption = useMemo(() => options.find((item) => item.id === current), [current]);
    const list = useMemo(() => options.filter((item) => item.id !== current), [current]);

    return (
        <div
            className={`${className} ${styles.dropdown}`}
            onClick={() => setIsOpen(!isOpen)}
        >
            <p className={styles.dropdown__title}>{currentOption.name}</p>
            <Arrow
                className={cn({
                    [styles.dropdown__icon]: true,
                    [styles.dropdown__icon_active]: isOpen
                })}
            />
            {isOpen && (
                <ul className={styles.dropdownList} onClick={() => setIsOpen(false)}>
                    {list.map((item) => (
                        <li
                            onClick={() => setCurrent(item.id)}
                            className={styles.dropdownList__item}
                            key={item.id}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Dropdown;