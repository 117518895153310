import React, { useContext, useState } from 'react';
import SearchField from './components/SearchField/SearchField';
import SearchList from './components/SearchList/SearchList';
import styles from './Search.module.scss';
import { superAxiosRequest } from 'axiosApi';
import { useEffect } from 'react';
import { agetostr } from 'components/ContentBox/Online-schedule/components/functions';
import { phoneMask } from "helpers/phoneMask";
import { useMemo } from 'react';
import { LanguageContext } from 'components/HeadBox/HeadBox';

const getProfile = ({
    profile,
    role,
    id,
    email
}) => ({
    id: role === 'teacher' ? id : profile.user_id,
    email: role === 'teacher' ? email : profile.email,
    date_of_birth: `${agetostr(profile.date_of_birth)} (${profile.date_of_birth})`,
    full_name: profile.full_name,
    phone: profile?.phone?.length > 0 ? phoneMask(profile.phone.substring(1)) : '',
    link: role === 'teacher' ? `/account/${id}` : `/account_clients/${id}`,
});

const Search = ({ onClose }) => {
    const [current, setCurrent] = useState(1);
    const [value, setValue] = useState('');
    const [list, setList] = useState([]);
    const { translate } = useContext(LanguageContext);

    const options = [
        { id: 1, name: translate.clients, link: '/c/company/clients-profiles/' },
        { id: 2, name: translate.teachers, link: '/c/company/profiles/' },
    ];

    const currentOption = useMemo(() => options.find((option) => option.id === current), [current]);

    const searchClients = () => {
        superAxiosRequest({
            link: 'accounts/clients',
            method: 'get',
            params: {
                search: value,
                limit: 5
            },
        }).then(({ data }) => {
            if(value.length === 0) {
                return setList([]);
            }

            if(data.result.length === 0) {
                return setList([]);
            }

            const clients = data.result.map(({ profile, groups }) =>
                getProfile({
                    profile,
                    role: groups[0].name,
                    id: profile.user_id
                })
            );
            setList(clients);
        });
    };

    const searchTeachers = () => {
        superAxiosRequest({
            link: 'accounts/employers_list',
            method: 'get',
            params: {
                groups: 3,
                search: value,
                limit: 5
            },
        }).then(({ data }) => {
            if(value.length === 0) {
                return setList([]);
            }

            if(data.result.length === 0) {
                return setList([]);
            }

            const teachers = data.result.map(({ profile, groups, id, email}) =>
                getProfile({
                    profile,
                    role: groups[0].name,
                    id,
                    email
                })
            );
            setList(teachers);
        });
    };

    useEffect(() => {
        if(current === 1) {
            searchClients();
        }
        if(current === 2) {
            searchTeachers();
        }
    }, [value]);

    useEffect(() => {
        setList([]);
        setValue('');
    }, [current]);

    return (
        <>
            <div className={styles.search}>
                <SearchField
                    options={options}
                    current={current}
                    setCurrent={setCurrent}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className={list.length > 0 ? styles.search__field : ''}
                />
                {list.length > 0 && (
                    <SearchList
                        className={styles.search__list}
                        list={list}
                        link={currentOption.link}
                    />
                )}
            </div>
            <div className={styles.blur} onClick={onClose} />
        </>
    )
}

export default Search;
