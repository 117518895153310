import React from 'react';
import st from './ColorFullButton.module.scss'
import PropTypes from "prop-types";
const ColorFullButton = ({callback, children, color, isSelected, isDisabled}) => {
    const getButtonColor = () => {
        switch (color) {
            case 'green':
                return st.green
            case 'red':
                return st.red
            case 'yellow':
                return st.yellow
        }
    }

    return <button disabled={isDisabled} className={`${st.btn} ${getButtonColor()} ${isSelected ? st.selected : ''}`} onClick={callback}>{children}</button>
}


ColorFullButton.propTypes = {
    color: PropTypes.oneOf(['green', 'red', 'yellow']).isRequired,
    callback: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
}
export default ColorFullButton;