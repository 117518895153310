import React, {useEffect, useState} from 'react'
import {superAxiosRequest} from 'axiosApi';
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import ContentHeader from 'components/UI/ContentHeader/ContentHeader'
import THead from "../../../ContentBox/Global/ReportTable/THead";
import Loader from "../../../ContentBox/Global/Loader/Loader";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import {NavLink} from "react-router-dom";
import {ExcelRowsParams, setCellParams} from "../../../utils/ExcelExport";



const GroupStages = () => {
    const [catalog, setCatalog] = useState({})

    const {
        refWrapper,
        refSticky,
        refThead,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader()


    const filterCfg = [
        {
            type: 'select-custom',
            nameState: 'location',
            placeholder: 'Локация',
            values: catalog.locations?.sort((a, b) => a.name > b.name ? 1 : -1) || [],
            option_value_key: 'id',
            option_label_key: 'name',
        },
        {type: 'month-range-picker', nameState: ['date_from', 'date_until']},
    ]

    const cfg = {
        cols: [
            {
                name: 'Локация'},
            {
                name: 'Группа'},
            {
                name: 'Формирование'
            },
            {
                name: 'Период в днях'
            },
            {
                name: 'В расписании'
            },
            {
                name: 'Период в днях'
            },
            {
                name: 'В работе'
            },
            {
                name: 'Период в днях'
            },
        ]
    }

    const onChangeFilter = async (obj) => {
        const filterData = {
            ...obj,
            ...(obj.date_from && { date_from: obj.date_from }),
            ...(obj.date_until && { date_until: obj.date_until }),
        }
        setFilter(filterData);
        if (obj.date_from && obj.date_until) getFilteredData(filterData);
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/group_stages',
        }).then(({data}) => {
            setIsLoading(false)
            setCatalog(data)
        })
    }, [])

    const minutesToDays = (minutes) => minutes === null ?  "-" : Math.ceil(minutes / (60 * 24));
    const dateConverter = (date) => dateFormat(new Date(date), "YYYY-mm-dd");
    const calculateTotalDays = (data, key) => data.reduce((acc, el) => el[key] !== "-" ? acc + el[key] : acc, 0)
    const calculateTotalGroups = (key) => data && data.filter(el => el[key] !== null).length

    const totalForming = calculateTotalGroups('status_forming_at')
    const totalStatusWasChangedToInSchedule = calculateTotalGroups('status_was_changed_to_in_schedule_at')
    const totalFromFormingToWorking = calculateTotalGroups('status_was_changed_to_working_at')

    const getFilteredData = (filter) => {
        setIsLoading(true)
        // Дату начала устанавливаем на первый день месяца
        const dateFrom = filter.date_from ? new Date(filter.date_from) : null
        // Дату конца устанавливаем на последний день месяца
        const dateUntil = filter.date_until ? new Date(filter.date_until) : null
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/group_stages',
            params: {
                ...filter,
                date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"), ...(dateUntil &&
                    {
                        date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                    })
            },
        }).then(({data}) => {
            const transformedData = data.map(obj => ({
                ...obj,
                from_forming_to_working_total_minutes: minutesToDays(obj.from_forming_to_working_total_minutes),
                in_forming_total_minutes: minutesToDays(obj.in_forming_total_minutes),
                in_schedule_total_minutes: minutesToDays(obj.in_schedule_total_minutes),
            }))
            setData(transformedData)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const downloadExcel = () => {
        import('xlsx-js-style').then(XLSX => {
            const headRows = [
                [
                    {
                        v: "Локация",
                    },
                    {
                        v: "Группа",
                    },
                    {
                        v: "Формирование",
                    },
                    {
                        v: "Период в днях",
                    },
                    {
                        v: "В расписании",
                    },
                    {
                        v: "Период в днях",
                    },
                    {
                        v: "В работе",
                    },
                    {
                        v: "Период в днях",
                    },
                ]
            ]

            headRows[0] = setCellParams(headRows[0], ExcelRowsParams.headerRowParam)


            let wscols = [
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];


            let tableData = []

            data.forEach(group => {
                tableData.push([
                    {
                        v: `${group.location_name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${group.group_number} - ${group.group_name}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${dateConverter(group.status_forming_at)}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${group.in_forming_total_minutes}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${group.status_was_changed_to_in_schedule_at ? dateConverter(group.status_was_changed_to_in_schedule_at) : '-'}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${group.in_schedule_total_minutes}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${dateConverter(group.status_was_changed_to_working_at)}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${group.from_forming_to_working_total_minutes}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            tableData.unshift([
                {
                    v: `Итого`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: `${data.length}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${totalForming}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${Math.round(calculateTotalDays(data, 'in_forming_total_minutes') / totalForming)}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${totalStatusWasChangedToInSchedule}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${Math.round(calculateTotalDays(data, 'in_schedule_total_minutes') / totalStatusWasChangedToInSchedule)}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${totalFromFormingToWorking}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${Math.round(calculateTotalDays(data, 'from_forming_to_working_total_minutes') / totalFromFormingToWorking)}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ])

            let wb = XLSX.utils.book_new()
            const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData])
            ws['!cols'] = wscols;

            XLSX.utils.book_append_sheet(wb, ws, "List 1")
            XLSX.writeFile(wb, "Запуск групп.xlsx");
        })
    }
    return (
        <>
            <ContentHeader title="Запуск групп" />
            <Filter config={filterCfg} getFilterParams={onChangeFilter} drop={() => {setFilter({})}} />

            {isLoading ? <Loader /> : data && data.length > 0 ?
                <>
                    <div className={st.tableWrapper} ref={refWrapper}>
                        <div ref={refSticky} className={st.sticky}>
                            <table className={st.table}>
                                <THead cols={cfg.cols} />
                            </table>
                        </div>
                        <table className={st.table}>
                            <THead cols={cfg.cols} ref={refThead} />
                            <tbody className={`${st.tbody} ${st.tbodyStriped}`}>
                                <>
                                    <tr>
                                        <td className={st.tdTotal}>Итого</td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {data.length}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {totalForming}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {`${Math.round(calculateTotalDays(data, 'in_forming_total_minutes') / totalForming)}`}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {totalStatusWasChangedToInSchedule}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {`${Math.round(calculateTotalDays(data, 'in_schedule_total_minutes') / totalStatusWasChangedToInSchedule)}`}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {totalFromFormingToWorking}
                                        </td>
                                        <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                            {`${Math.round(calculateTotalDays(data, 'from_forming_to_working_total_minutes') / totalFromFormingToWorking)}`}
                                        </td>
                                    </tr>
                                    {data.map((el, index) => {
                                      return (
                                          <tr key={index}>
                                           <td className={`${st.color} ${st.fw500}`}>{el.location_name}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>
                                               <NavLink to={'/groups/group/' + el.group} target="_blank">
                                                   {`${el.group_number} - ${el.group_name}`}
                                               </NavLink>
                                           </td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{dateConverter(el.status_forming_at)}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.in_forming_total_minutes}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.status_was_changed_to_in_schedule_at ? dateConverter(el.status_was_changed_to_in_schedule_at) : '-'}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.in_schedule_total_minutes}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{dateConverter(el.status_was_changed_to_working_at)}</td>
                                           <td className={`${st.textAlignCenter} ${st.fz12}`}>{el.from_forming_to_working_total_minutes}</td>
                                       </tr>
                                      )
                                    })}
                                </>
                            <>
                            </>
                            </tbody>
                        </table>
                            <button onClick={downloadExcel} style={{
                                margin: '10px 10px 0 ',
                                width: 'calc(100% - 20px)'
                            }}>Выгрузить в Excel
                            </button>
                    </div>
                </>
                : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </>
    )
}

export default GroupStages;