import React, {useEffect, useRef, useState} from 'react'
import { superAxiosRequest } from 'axiosApi'
import { classNames as cn } from "helpers";
import './SmartFilter.scss'
import {ReactComponent as SearchIcon} from 'components/UI/icons/curved-search.svg';

export default function SmartFilter(props) {
    const [query, setQuery] = useState('')
    const [list, setList] = useState([false, []])

    const componentRef = useRef();

    const onChangeHandler = value => {
        setQuery(value)
        const apiConf = {
            method: 'get',
            link: props.link,
            params: {
                search: value,
                limit: 999,
            }
        }
        if (value.length > 2) {
            superAxiosRequest(apiConf).then(res => {
                let data = res.data.result;
                if(props.getApiList) {
                    data = props.getApiList(res.data.result);
                }
                setList([true, data])
            })
        }
    }

    function setFull(item) {
        //setQuery(props.sub_key ? props.new_key ? item[props.sub_key][props.new_key] : item[props.sub_key].name : props.new_key ? item[props.new_key].name : item.name)
        setQuery('')
        props.backFunction([{...item}])
        setList([false, []])
    }

    const clickOutsideHandler = e => {
        if (!componentRef.current.contains(e.target))
            setList([false, []])
    }

    useEffect(() => {
        document.body.addEventListener('click', clickOutsideHandler)

        return () => document.body.removeEventListener('click', clickOutsideHandler)
    }, [])

    return (
        <div className="smart-filter" ref={componentRef}>
            <div className="smart-filter__field">
                <SearchIcon className="smart-filter__icon" />
                <input
                    placeholder={props.placeholder}
                    value={query}
                    onChange={e => onChangeHandler(e.target.value)}
                    className={cn({ 'super-modal__input--error': props.error })}
                />
            </div>
            {list[0] &&
            <div className="smart-filter__list">
                {list[1].length > 0 ?
                    list[1].map((item, index) =>
                        <p className="smart-filter__line" key={index} onClick={() => setFull(item)}>
                            {props.sub_key ? 
                                props.new_key 
                                    ? item[props.sub_key][props.new_key] 
                                    : item[props.sub_key].name 
                                : props.new_key 
                                    ? item[props.new_key] 
                                    : item.name}
                        </p>) :
                    <p className="smart-filter__empty">По вашему запросу ничего не найдено</p>}
            </div>}
        </div>
    )
}
