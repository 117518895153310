import React from 'react';
import style from './CounterInput.module.scss'

const CounterInput = (props) => {
    const onChangeHandler = val => {
        if (props.onChange) {
            if (props.min) val = Math.max(val, props.min)
            if (props.max) val = Math.min(val, props.max)
            if (!isNaN(val))
                props.onChange(props.fixed ? +(val.toFixed(props.fixed)) : val, props.name)
        }
    }

    const getValue = () => props.value !== undefined ? props.fixed ? +props.value.toFixed(props.fixed) : props.value : ''

    return (
        <div className={style.counterBox}>
            <div className={`${style.counterBox__button} ${style.counterBox__button__minus} ${props.min === props.value ? style.counterBox__button__disabled : ''}`}
                 onClick={() => onChangeHandler(props.value - (props.step || 1))}>-</div>
            <input type="text" value={getValue()}
                   onChange={e => onChangeHandler(+e.target.value)}
            />
            <div className={`${style.counterBox__button} ${style.counterBox__button__plus} ${props.max === props.value ? style.counterBox__button__disabled : ''}`}
                 onClick={() => onChangeHandler(props.value + (props.step || 1))}>+</div>
        </div>
    )
};

export default CounterInput;