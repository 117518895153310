import React from 'react';
import styles from "./HoverPreviewPanel.module.scss";

const HoverPreviewPanel = ({childrenPhoto, childrenIcon, childrenIconsBlock}) => {
  return (
      <div className={styles.container}>
        {childrenPhoto}
        <div className={styles.box}>
          <div className={styles.preview}>
            <div className={styles.iconBox}>
              <div className={styles.viewIcon}>
                <div>
                  {childrenIconsBlock}
                </div>
                <div className={styles.listSearchPlus}>
                  {childrenIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  );
};

export default HoverPreviewPanel;