import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {sortDESC} from 'helpers';
import usersStore from 'Stores/usersStore';
import {translate as loc, dict} from "Localisation";
import st from "./EmployeeContracts.module.scss";
import EmployeeContract from "./EmployeeContract";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import {timeConverter2} from "components/ContentBox/Online-schedule/components/functions";
import modalStore from "Stores/modalStore";


const EmployeeContracts = props => {
  const {setUserContracts, userContracts, setUserDocs} = usersStore;

  const addContract = () => {
      const contract = {
          name: 'employee_contract',
          link: 'employees/contract',
          button: loc(dict.main_page.add),
          title: loc(dict.contract.adding_contract),
          type: 'employee_contract',
          data: 'json',
          profile_id: props.user.profile.id
      }
      const initStateContract = {
          date_from: timeConverter2(new Date()),
          date_until: timeConverter2(new Date().getFullYear() + '-12-31'),
          employee_id: props.user.profile.id,
      }
      modalStore.activity(contract, initStateContract)
  }

  useEffect(() => {
    if (!!props.user) {
      setUserContracts(props.user.profile.id)
      setUserDocs(props.user.profile.id)
    }
  }, [props.user, setUserContracts, setUserDocs])

  return (
    <div className={st.employeeContracts}>
        <BlockHeader title={props.translate.menu_contracts}><button onClick={addContract}>+</button></BlockHeader>

      {userContracts.length ?
        sortDESC(userContracts, 'id').map(contract =>
          <EmployeeContract translate={props.translate} key={contract.id} contract={contract} user={props.user} />) :
        <span className={st.emptyContracts}>{loc(dict.contract.text_1)}</span>}
      <footer className={st.footer}/>
    </div>
  )
}

export default observer(EmployeeContracts);
