import React, {useState, useEffect} from 'react';
import st from "./Crossword.module.scss";
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";
import {classNames as cn} from "helpers";

export default function Crossword(props) {
	const [inputList, setInputList] = useState([]);

	const handleInput = (e, index) => {
		if (props.studentAnswer) return;
		const newText = e.target.value;
		const newList = [...inputList];
		newList[index] = newText;
		setInputList(newList);
	};

	useEffect(() => {
		setInputList(['']);
	}, [props.attempts]);

	useEffect(() => {
		const ready = inputList.some((text) => text.length > 0) && !props.studentAnswer;
		props.handleAnswers(inputList, ready);
	}, [inputList]);

	useEffect(() => {
		setInputList([...props.studentAnswer?.answer ?? ['']]);
	}, [props.studentAnswer]);

	const addInput = () => {
		setInputList([...inputList, '']);
	};

	const mappingWords = inputList?.map((text, index) => {
		const inputWidth = `${7 * text.length + 65}px`;
		const resultInDetail = props.studentAnswer?.result_in_detail;
		const isCorrect = resultInDetail && resultInDetail[index] && resultInDetail[index][text];
		const isMistake = resultInDetail && resultInDetail[index] && resultInDetail[index][text] === null;

		return (
			<div className={st.writeText} key={index}>
				<input
					className={cn({
						[st.wordsInput]: true,
						[st.correct]: props.studentAnswer && isCorrect,
						[st.mistake]: props.studentAnswer && isMistake,
					})}
					placeholder=" "
					value={text}
					onChange={(e) => handleInput(e, index)}
					style={{width: inputWidth}}
				/>
				{index === inputList.length - 1 &&
					<button
						onClick={addInput}
						className={st.button}
						disabled={props.isTeacher || props?.attempts?.length >= props?.try}
					>
						<PlusIcon/>
					</button>
				}
			</div>
		);
	})

	return (
		<div className={st.writeWordsBlock}>
			{mappingWords}
		</div>
	);
}