import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {observer} from 'mobx-react';
import {permissions} from "settings/permissions";
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import Stores from 'Stores';
import groupStore from 'Stores/groupStore';
import modalStore from "Stores/modalStore";
import GroupsHours from "./GroupsHours";
import Telegram from "components/Modules/Groups/components/Telegram/Telegram";
import Zoom from "components/Modules/Groups/components/Zoom/Zoom";
import GroupTeachers from "components/Modules/Groups/TeacherGroup/components/MainInfo/GroupTeachers/GroupTeachers";
import StudentSense from "components/Modules/Groups/TeacherGroup/components/MainInfo/StudentSense";
import AddEbook from "components/ContentBox/Global/GlobalModalSingle/templates/groups/AddEbook/AddEbook";
import style from './MainInfo.module.scss'
import EbooksList from "./EbooksList";
import {EditIconButton} from "components/UI/SuperButton/SuperButton";
import asideModalStore from "Stores/asideModalStore";
import GroupsForm from "components/ContentBox/Global/GlobalModalSingle/templates/groups/GroupsForm";

const MainInfo = props => {
    const {isTeacher} = Stores
    const {group, updateGroupData, ebooks, updateEbooks, deleteEbooks, wsData} = groupStore

    const pi = group.group;

    const [activeIndex, setActiveIndex] = useState(0);

    const handleDotClick = (index) => {
        setActiveIndex(index);
    }

    const msGroup = {
        modalBodyComponent: () => <GroupsForm/>,
        link: 'groups/' + pi.id,
        button: props.translate.save,
        title: props.translate.group_parameters,
        is_put: true,
        isResponsive: true,
        valid: {
            location: 'required',
            status: 'required',
            number: 'required',
            name: 'required',
            type: 'required',
            discipline: 'required',
        },
        onSubmitSuccess: updateGroupData,
    }

    const mdGroup = {
        location: pi.location.id,
        status: pi.status.id,
        number: pi.number,
        name: pi.name,
        type: pi.type.id,
        size: pi.size,
        discipline: pi.discipline.id,
        online: pi.online,
        new_schedule: pi.new_schedule
    }

    const msComment = {
        name: 'groups_edit_comment',
        link: 'groups/' + pi.id,
        is_put: true,
        button: props.translate.save,
        title: props.translate.comments_for_group,
        type: 'SelectorAndInput',
        active_inputs: ['textarea_tags'],
        old: pi.comment,
        data: 'json',
        pl_textarea_tags: props.translate.comment,
        name_textarea_tags: 'comment',
        width: '520px',
        func: updateGroupData,
    }

    const [statuses, setStatuses] = useState({
        free: 0,
        payed: 0,
        pass: 0,
        free_pass: 0
    })
    const [zoomLink, setZoomLink] = useState(null);
    const [telegramLink, setTelegramLink] = useState(null);
    const checkTelegram = checkPermList(permissions.view_telegram_link);
    const checkZoom = checkPermList(permissions.view_zoom_link);

    const addEbook = () => {
        let start_date
        if (group.group.new_schedule) {
            start_date = wsData.workshops[0]?.date
        } else {
            const schedules = group.schedules.sort((a, b) =>
                new Date(a.date_from + ' 12:00:00') - new Date(b.date_from + ' 12:00:00'))
            start_date = schedules[0]?.date_from
        }

        if (!start_date) return

        const actualStudentsIDs = group.students.filter(st => st.status === 1).map(st => st.user_id)

        const ms = {
            link: 'ebooks/add_to_group',
            button: props.translate.save,
            title: props.translate.course_of_study,
            modalBodyComponent: () => <AddEbook students={actualStudentsIDs}/>,
            func: updateEbooks,
            valid: {
                ebook_id: 'required',
                registerchapters_id: 'required',
                start_date: 'required',
            }
        }
        const md = {
            group_id: pi.id,
            start_date,
        }
        modalStore.activity(ms, md)
    }

    const editEbook = (ebook) => {
        const ms = {
            title: props.translate.edit_course,
            modalBodyComponent: () => <AddEbook/>,
            button: props.translate.save,
            valid: {
                ebook_id: 'required',
                registerchapters_id: 'required',
                start_date: 'required',
            },
            is_put: true,
            link: 'ebooks/add_to_group/' + ebook.id,
            func: updateEbooks
        }

        const md = {
            group_id: pi.id,
            ebook_id: ebook.ebook_id,
            registerchapters_id: ebook.registerchapters_id,
            start_date: ebook.start_date,
            end_date: ebook.end_date,
        }
        modalStore.activity(ms, md)
    }

    const deleteEbook = ebook => {
        superAxiosRequest({method: 'delete', link: 'ebooks/add_to_group/' + ebook.id})
            .then(() => {
                deleteEbooks(ebook.id)
            })
    }

    const openModalTelegram = (e) => {
        const ms = {
            name: 'telegram',
            link: telegramLink ? `groups/groups-telegram/${group.group.id}` : 'groups/groups-telegram',
            title: 'Telegram канал группы',
            type: 'SelectorAndInput',
            active_inputs: ['text'],
            name_text: 'telegram_link',
            data: 'json',
            is_put: telegramLink,
            valid: {telegram_link: 'required'},
            button: 'Добавить',
            func: (data) => setTelegramLink({...data}),
        }
        if (e.target.tagName !== 'svg' && telegramLink?.telegram_link) {
            window.open(telegramLink.telegram_link, '_blank');
            return;
        }
        modalStore.activity(ms, {...telegramLink, group_id: group.group.id});
    }

    const openModalZoom = (e) => {
        const ms = {
            name: 'zoom',
            link: zoomLink ? `groups/groups-zoom/${group.group.id}` : 'groups/groups-zoom',
            title: 'Zoom канал группы',
            type: 'SelectorAndInput',
            active_inputs: ['text'],
            name_text: 'zoom_link',
            data: 'json',
            is_put: zoomLink,
            valid: {zoom_link: 'required'},
            button: 'Добавить',
            func: (data) => setZoomLink({...data}),
        }
        if (e.target.tagName !== 'svg' && zoomLink?.zoom_link) {
            window.open(zoomLink.zoom_link, '_blank');
            return;
        }
        modalStore.activity(ms, {...zoomLink, group_id: group.group.id});
    }

    useEffect(() => {
        const obj = {
            free: 0,
            payed: 0,
            pass: 0,
            free_pass: 0
        }
        group.school_day_notes.forEach(({status}) => {
            if (status === 7) obj.payed += 1
            if (status === 2) obj.free += 1
            if (status === 3) obj.pass += 1
            if (status === 5) obj.free_pass += 1
        })

        setStatuses({...obj})
    }, [group.school_day_notes])

    useEffect(() => {
        if (checkTelegram) {
            superAxiosRequest({
                method: 'get',
                link: `groups/groups-telegram/${group.group.id}`
            }).then(({data}) => {
                data.telegram_link !== null && setTelegramLink(data)
            })
        }
        if (checkZoom) {
            superAxiosRequest({
                method: 'get',
                link: `groups/groups-zoom/${group.group.id}`
            }).then(({data}) => {
                data.zoom_link !== null && setZoomLink(data)
            })
        }
    }, [checkTelegram, checkZoom, group.group.id])

    const getBalancePercent = (total, remainder) => {
            total = parseFloat(String(total).replace(/\s/g, "").replace(",", "."))
            remainder = parseFloat(String(remainder).replace(/\s/g, "").replace(",", "."))
            const difference = total - remainder;
            return (difference / total) * 100;
    }

    const divs = [
        <div>
            <div className={style.groupSimpleBox__container}>
                <h3>{props.translate.group_capacity}</h3>
                <div className={style.groupSimpleBox__container__value}>
                    <span>{pi.size}</span>
                    <span className={style.delimiter}></span>
                    <span>{group.students?.length || 0}</span>
                    <span className={style.delimiter}></span>
                    <span>{pi.actual_students_count}</span>
                    <span className={style.delimiter}></span>
                    <span className={pi.debtors_count && style.debtors}>{pi.debtors_count}</span>
                </div>
                <div className={style.progress}>
                    <div className={style.progress__completed}
                         style={{width: `${getBalancePercent(pi?.size, pi?.actual_students_count)}%`}}></div>
                    <div className={style.progress__remaind}
                         style={{width: `${100 - getBalancePercent(pi?.size, pi?.actual_students_count)}%`, background: "#FFC700"}}></div>
                </div>
                <div className={style.groupSimpleBox__container__valueName}>
                    <div>
                        <span>{props.translate.total}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.in_group}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.actual}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.debtors}</span>
                    </div>
                </div>
            </div>
        </div>,
        <div >
            <div className={style.groupSimpleBox__container}>
                <h3>
                    {props.translate.class_attendance}
                </h3>
                <div className={style.groupSimpleBox__container__value}>
                    <span>{(statuses.payed + statuses.free)}</span>
                    <span className={style.delimiter}></span>
                    <span>{statuses.payed}</span>
                    <span className={style.delimiter}></span>
                    <span>{statuses.free}</span>
                </div>
                <div className={style.progress}>
                    <div className={style.progress__completed}
                         style={{width: `${getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`}}></div>
                    <div className={style.progress__remaind}
                         style={{width: `${100 - getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`, background: "#FFC700"}}></div>
                </div>
                <div className={style.groupSimpleBox__container__valueName}>
                    <div>
                        <span>{props.translate.total}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.paid}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.free}</span>
                    </div>
                </div>
            </div>
        </div>,
        <div>
            <div className={style.groupSimpleBox__container}>
                <h3>{props.translate.absences}</h3>
                <div className={style.groupSimpleBox__container__value}>
                    <span>{(statuses.payed + statuses.free)}</span>
                    <span className={style.delimiter}></span>
                    <span>{statuses.payed}</span>
                    <span className={style.delimiter}></span>
                    <span>{statuses.free}</span>
                </div>
                <div className={style.progress}>
                    <div className={style.progress__completed}
                         style={{width: `${getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`}}></div>
                    <div className={style.progress__remaind}
                         style={{width: `${100 - getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`, background: "#FFC700"}}></div>
                </div>
                <div className={style.groupSimpleBox__container__valueName}>
                    <div>
                        <span>{props.translate.total}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.paid}</span>
                        <span className={style.delimiter}></span>
                        <span>{props.translate.free}</span>
                    </div>
                </div>
            </div>
        </div>
    ]
    return (
        <div className={style.groupSimpleMainBox}>
            {/* Когда время пойдет вспять, эти блоки вернуться, но это не точно */}
            {(!isTeacher && Date.now() < 0) &&
                <div className={style.groupSimpleMainBox__row}>
                    <div className={`${style.groupSimpleMainBox__card} ${style.groupSimpleBox} ${Stores.cleanSumm(-group.balance.credit / 100) ? style.studentMoneyDebtor : style.studentMoney}`}>
                        <div className={style.groupSimpleBox__container}>
                            <div className={style.groupSimpleBox__container__value}>
                                <span>{Stores.cleanSumm(group.balance.charged / 100)}</span>
                                <span className={style.delimiter}></span>
                                <span
                                    className={Stores.cleanSumm(-group.balance.credit / 100) && style.debtors}>{Stores.cleanSumm(-group.balance.credit / 100)}</span>
                            </div>
                            <div className={style.progress}>
                                <div className={style.progress__completed}
                                     style={{width: `${getBalancePercent(Stores.cleanSumm(group.balance.charged / 100), Stores.cleanSumm(-group.balance.credit / 100))}%`}}></div>
                                <div className={style.progress__remaind}
                                     style={{width: `${100 - getBalancePercent(Stores.cleanSumm(group.balance.charged / 100), Stores.cleanSumm(-group.balance.credit / 100))}%`, background: "red"}}></div>
                            </div>
                            <div className={style.groupSimpleBox__container__valueName}>
                                <div className={style.groupSimpleBox__container__valueName__optionValue}>₸, Tenge</div>
                                <div>
                                    <span>{props.translate.accrued_to_students}</span>
                                    <span className={style.delimiter}></span>
                                    <span>{props.translate.duty}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Capacity*/}
                    <div className={`${style.groupSimpleMainBox__card} ${style.cardHidden}`}>
                        <div className={style.groupSimpleBox__container}>
                            <h3>{props.translate.group_capacity}</h3>
                            <div className={style.groupSimpleBox__container__value}>
                                <span>{pi.size}</span>
                                <span className={style.delimiter}></span>
                                <span>{group.students?.length || 0}</span>
                                <span className={style.delimiter}></span>
                                <span>{pi.actual_students_count}</span>
                                <span className={style.delimiter}></span>
                                <span className={pi.debtors_count && style.debtors}>{pi.debtors_count}</span>
                            </div>
                            <div className={style.progress}>
                                <div className={style.progress__completed}
                                     style={{width: `${getBalancePercent(pi?.size, pi?.actual_students_count)}%`}}
                                     ></div>
                                <div className={style.progress__remaind}
                                    //  style={{width: `${100 - getBalancePercent(pi?.size, pi?.actual_students_count)}%`, background: "#FFC700"}}
                                     ></div>
                            </div>
                            <div className={style.groupSimpleBox__container__valueName}>
                                <div>
                                    <span>{props.translate.total}</span>
                                    <span className={style.delimiter}></span>
                                    <span>{props.translate.in_group}</span>
                                    <span className={style.delimiter}></span>
                                    <span>{props.translate.actual}</span>
                                    <span className={style.delimiter}></span>
                                    <span>{props.translate.debtors}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Class attendance*/}
                    {!isTeacher && checkPermList(permissions.view_docs_signed) &&
                        <>
                            <div className={`${style.groupSimpleMainBox__card} ${style.cardHidden}`}>
                                <div className={style.groupSimpleBox__container}>
                                    <h3>{props.translate.class_attendance}</h3>
                                    <div className={style.groupSimpleBox__container__value}>
                                        <span>{(statuses.payed + statuses.free)}</span>
                                        <span className={style.delimiter}></span>
                                        <span>{statuses.payed}</span>
                                        <span className={style.delimiter}></span>
                                        <span>{statuses.free}</span>
                                    </div>
                                    <div className={style.progress}>
                                        <div className={style.progress__completed}
                                             style={{width: `${getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`}}></div>
                                        <div className={style.progress__remaind}
                                             style={{width: `${100 - getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`, background: "#FFC700"}}></div>
                                    </div>
                                    <div className={style.groupSimpleBox__container__valueName}>
                                        <div>
                                            <span>{props.translate.total}</span>
                                            <span className={style.delimiter}></span>
                                            <span>{props.translate.paid}</span>
                                            <span className={style.delimiter}></span>
                                            <span>{props.translate.free}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*// Absences*/}
                            <div className={`${style.groupSimpleMainBox__card} ${style.cardHidden}`}>
                                <div className={style.groupSimpleBox__container}>
                                    <h3>{props.translate.absences}</h3>
                                    <div className={style.groupSimpleBox__container__value}>
                                        <span>{(statuses.payed + statuses.free)}</span>
                                        <span className={style.delimiter}></span>
                                        <span>{statuses.payed}</span>
                                        <span className={style.delimiter}></span>
                                        <span>{statuses.free}</span>
                                    </div>
                                    <div className={style.progress}>
                                        <div className={style.progress__completed}
                                             style={{width: `${getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`}}></div>
                                        <div className={style.progress__remaind}
                                             style={{width: `${100 - getBalancePercent((statuses.payed + statuses.free), statuses.payed)}%`, background: "#FFC700"}}></div>
                                    </div>
                                    <div className={style.groupSimpleBox__container__valueName}>
                                        <div>
                                            <span>{props.translate.total}</span>
                                            <span className={style.delimiter}></span>
                                            <span>{props.translate.paid}</span>
                                            <span className={style.delimiter}></span>
                                            <span>{props.translate.free}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}

                    <div className={style.slider}>
                        {divs.map((div, index) => (
                          <div
                            key={index}
                            className={`${style.slide} ${index === activeIndex ? `${style.active}` : ''}`}
                          >
                              {div}
                              <div className={style.dots}>
                                  {divs.map((_, index) => (
                                    <span
                                      key={index}
                                      className={`${style.dot} ${index === activeIndex ? `${style.active}` : ''}`}
                                      onClick={() => handleDotClick(index)}
                                    ></span>
                                  ))}
                              </div>
                          </div>
                        ))}
                    </div>

                    <GroupsHours getPercent={getBalancePercent} translate={props.translate}/>
                </div>}

            <div className={style.groupSimpleMainBox__cols}>
                <div className={style.groupSimpleMainBox__col}>
                    {/*Group teachers*/}
                    <GroupTeachers translate={props.translate} teachers={group.teachers}/>

                    {/*Добавление курса*/}
                    {checkPermList(permissions.view_study_course) &&
                        <div className={style.groupSimpleMainBox__col__card}>
                            <div className={style.groupSimpleMainBox__cardHeader}>
                                <span>{props.translate.course_of_study}</span>
                                {!isTeacher && <EditIconButton onClick={addEbook}/>}
                            </div>
                            <EbooksList
                                ebooks={ebooks}
                                edit={editEbook}
                                delete={deleteEbook}
                                add={addEbook}
                                translate={props.translate}
                            />
                        </div>}
                </div>

                {/*Group options*/}
                <div className={style.groupSimpleMainBox__col}>
                    {[checkTelegram, checkZoom].includes(true) &&
                      <div className={style.groupSimpleMainBox__wrapper}>
                        {checkTelegram && <Telegram onClick={openModalTelegram}/>}
                        {checkZoom && <Zoom onClick={openModalZoom}/>}
                    </div>}
                    <div className={style.groupSimpleMainBox__col__card}>
                        <div className={style.groupSimpleMainBox__cardHeader}>
                            <span>{props.translate.group_options}</span>
                            {Stores.Rules.checkRule('studygroup', 'add_client') &&
                                <EditIconButton onClick={() => {
                                    asideModalStore.activity(msGroup,mdGroup)
                                }}/>
                            }
                        </div>
                        <div className={style.groupSimpleMainBox__config}>
                            <div className={style.groupSimpleMainBox__config__container}>
                                <div className={style.item}>
                                    <span className={style.itemName}>{props.translate.location}:</span>
                                    <span className={style.itemValue}>
                                        <NavLink to={'/company/location/' + pi.location.id}>
                                            {pi.location.name}
                                        </NavLink>
                                    </span>
                                </div>

                                <div className={style.item}>
                                    <span className={style.itemName}>{props.translate.group_type}:</span>
                                    <span className={style.itemValue}>
                                    {`${pi.type && pi.type.name} (${pi.online ?
                                    props.translate.online :
                                    props.translate.offline})`}
                                    </span>
                                </div>

                                <div className={style.item}>
                                    <span className={style.itemName}>{props.translate.discipline}</span>
                                    <span className={style.itemValue}>{pi.discipline.name}</span>
                                </div>
                            </div>
                        </div>

                        {!isTeacher &&
                            <>
                                <div className={style.groupSimpleMainBox__cardHeader}>
                                    <span>{props.translate.group_comment}</span>
                                        <EditIconButton  onClick={() => {
                                            modalStore.activity(msComment, {comment: group.group.comment})
                                        }}/>
                                </div>
                                <div className={style.groupSimpleMainBoxNote}>
                                    {!group.group.comment ? <div className={style.noAddBlock}>
                                        <span>{props.translate.no_comment}.</span>
                                            <button  onClick={() => {
                                                modalStore.activity(msComment, {comment: group.group.comment})
                                            }} className={style.addButton}>
                                                {props.translate.add_comment}
                                            </button>
                                        </div>
                                        : <span dangerouslySetInnerHTML={{__html: group.group.comment}} />
                                    }
                                </div>
                            </>}
                    </div>
                    {checkPermList(permissions.view_employee_report) &&
                        <StudentSense gid={group.group.id} isNewSchedule={group.group.new_schedule}/>}
                </div>
            </div>
        </div>
    )
}

export default observer(MainInfo)
