import React, {useState} from 'react';
import { observer } from 'mobx-react';
import swal from 'sweetalert';
import SectionTitle from '../../../../UI/SectionTitle/SectionTitle';
import InputsWrapper from '../../../../UI/InputsWrapper/InputsWrapper';
import InputToggle from '../../../../UI/InputToggle/InputToggle';
import Hr from '../../../../UI/Hr/Hr';
import Paper from '../../../../UI/Paper/Paper';
import Input from '../../../../UI/Input/Input';
import Flex from '../../../../UI/Flex/Flex';
import LogoPreviewArea from '../components/LogoPreviewArea/LogoPreviewArea';
import InputFile from '../../../../UI/InputFile/InputFile';
import ContactFormTogglesSection from './ContactFormTogglesSection';
import PaymentSection from './PaymentSection';
import AmoCrmSection from './AmoCrmSection';
import { adaptRegistrationToggles } from '../adapters';
import slotsSettingsStore from '../../../../../Stores/slotsSettingsStore';
import {superAxiosRequest} from 'axiosApi';
import styles from '../SlotSettings.module.scss';

const MAX_FILE_SIZE = 2097152;

const registrationToggles = adaptRegistrationToggles();

function RegistrationSection() {
  const {
    registrationTitle,
    registrationOptionsData: data,
    toggleRegistrationCheckBox,
    setRegistrationTitle,
    setRegistrationLogo,
  } = slotsSettingsStore;
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { hostname, protocol } = window.location;

  const handleLoadFileChange = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      return swal('', 'Файл больше 2 Мб, выберите другое изображение', 'warning');
    }

    const formData = new FormData();
    formData.append("file", file);

    setIsImageLoading(true);
    superAxiosRequest(
      {
        method: 'post',
        link: `files/image_upload`,
      },
      formData,
      'media'
    )
      .then((response) => {
        const path = hostname.includes('localhost') ? 'https://dev.phoenix24.kz' : `${protocol}//${hostname}`;
        setRegistrationLogo(`${path}${response.data.file}`);
    })
      .catch(() => {
        swal('Ошибка загрузки', 'Вы пытаетесь загрузить неподдерживаемый формат', 'warning')
          .then(() => setIsImageLoading(false));
      })
      .finally(() => setIsImageLoading(false));
  };

  return (
    <Paper tag='fieldset'>
      <SectionTitle title='Регистрация клиентов' />
      <InputsWrapper>
        {registrationToggles.map(({id, name, label, description}) => (
          <InputToggle
            key={id.toString()}
            tag='li'
            label={label}
            name={name}
            description={description}
            selected={data[name]}
            onChange={() => toggleRegistrationCheckBox(name)}
          />
        ))}
      </InputsWrapper>
      <Hr />

      <SectionTitle tag='h3' secondary title='Страница регистрации клиентов' />
      <Flex asColumn align='start' withGap>
        <Input
          label='Заголовок страницы'
          placeholder='Заголовок'
          name='registrationTitle'
          value={registrationTitle}
          onChange={(evt) => setRegistrationTitle(evt.target.value)}
        />
        <InputFile
          label='Загрузить / Изменить логотип'
          onChange={handleLoadFileChange}
          classes={styles.input_file}
        />
        <LogoPreviewArea isLoading={isImageLoading} />
      </Flex>
      <Hr />

      <ContactFormTogglesSection />
      <Hr />

      <PaymentSection />
      <Hr />

      <AmoCrmSection />
    </Paper>
  );
}

export default observer(RegistrationSection);

