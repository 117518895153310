import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {splitNumberDigits} from "helpers/math";
import modalStore from 'Stores/modalStore';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import MainSelector from '../MainSelector';
import TypeSelector from './TypeSelector';
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import './EmployeeContract.scss';
import someImg from 'components/ContentBox/img/salary.svg'
import threeImg from 'components/ContentBox/img/agenda.svg'
import { translate as loc, dict } from "Localisation";


const EmployeeContract = () => {
    const { getModalValues, changeValues, changeValuesArray, data } = modalStore

    const test_plan_ht_v = [
        { id: 45, name: loc(dict.main_page.text_8) },
        { id: 60, name: loc(dict.main_page.text_9) }
    ]

    const [fields, setFields] = useState({
        salary_amount: data.is_put || data.is_prolong,
        test_plan_mins: getModalValues.test_plan_mins > 0,
    })

    const returnValue = key_name => {
        if (Object.keys(getModalValues).length) {
            return getModalValues[key_name]
        } else {
            return ''
        }
    }

    const changeHandler = (e, key_name) => {
        let value = e.target.value;
        changeValues(value, key_name)
    }

    const checkToday = () => {
        if (getModalValues.is_put) {
            let tommorow = new Date()
            tommorow = tommorow.setDate(tommorow.getDate() + 1)
            if (new Date(getModalValues.date_from) < tommorow) {
                return true
            }
        }
        return false
    }

    const getVacationHours = () => getModalValues.vacation_mins / 60 || 0

    const getSalaryAmount = () => {
        const amount = getModalValues.salary_amount || ''
        const arr = amount.split('.')
        //return arr[0].replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
        return splitNumberDigits(arr[0]);
    }

    const onChangeSalaryAmount = e => {
        changeValues(e.target.value.replace(/\s/g, '').trim(), 'salary_amount')
    }

    useEffect(() => {
        const arr = getModalValues

        arr.vacation_days = arr.vacation_days || 1
        arr.vacation_mins = arr.vacation_mins || 60

        if (data.is_prolong) {
            arr.date_from = (+arr.date_from.split('-')[0] + 1) + '-01-01'
            arr.date_until = arr.date_from.split('-')[0] + '-12-31'
        }

        changeValuesArray(arr)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const arr = {...getModalValues}
        if(fields.salary_amount){
            arr.salary_amount = arr.salary_amount || '0'
            arr.salary_type = arr.salary_type || 1
        } else {
            delete arr.salary_amount
            delete arr.salary_type
        }

        if(fields.test_plan_mins){
            arr.test_plan_mins = arr.test_plan_mins || 60
            arr.hour_type = arr.hour_type || 60
        } else {
            delete arr.test_plan_mins
            delete arr.hour_type
        }

        changeValuesArray(arr)
        // eslint-disable-next-line
    }, [fields])

    return (
        <div className="modalEmployerContract super-modal__form-column">
            <div className="super-modal__form-field">
                <div className="super-modal__form-label super-modal__form-label--required">{loc(dict.main_page.contract_name)}:</div>
                <input placeholder={loc(dict.main_page.contract_name)}
                       value={getModalValues.title || ''}
                       onChange={e => changeHandler(e, 'title')}/>
            </div>

            <div className="super-modal__form-field">
                <div className="super-modal__form-label super-modal__form-label--required">{loc(dict.main_page.contract_duration)}:</div>
                <DataPickerSplit returnValue={returnValue}
                                 disable_from={checkToday()}
                                 objectChangeHandler={changeValues}/>
            </div>

            <div className="modalEmployerContract__toggleMainSelectCol">
                <div>
                    <CheckboxToggle left={`${loc(dict.main_page.type_salary)}:`} checked={fields.salary_amount}
                                    onChange={() => setFields({...fields, salary_amount: !fields.salary_amount})}/>
                </div>
                {fields.salary_amount &&
                <div>
                    <MainSelector image={someImg}>
                        <div className="super-modal__form-column">
                            <TypeSelector values={[loc(dict.main_page.monthly), loc(dict.main_page.daily), loc(dict.main_page.hourly)]}
                                          objectChangeHandler={changeValues}
                                          selected={getModalValues.salary_type}
                                          name='salary_type'/>
                            <div className="modalEmployerContract__row2">
                                <span className="super-modal__form-label">{loc(dict.main_page.wage_amount)}:</span>
                                <input type="text"
                                       placeholder={loc(dict.main_page.text_7)}
                                       onChange={onChangeSalaryAmount}
                                       value={getSalaryAmount()}
                                       className="modalEmployerContract__inputAmount"
                                />
                            </div>
                            <div>
                                <p className="modalEmployerContract__link">{loc(dict.main_page.text_1)}</p>
                            </div>
                        </div>
                    </MainSelector>
                </div>}
            </div>

            <div className="modalEmployerContract__rowVacation">
                <p className="super-modal__form-label">{loc(dict.main_page.text_2)}:</p>
                <DummyCounter name="vacation_mins"
                              value={getVacationHours()}
                              onChange={(val, key) => {
                                  changeValues(val * 60, key)
                              }}
                              min={.5}
                              step={.5}/>
            </div>

            <div className="modalEmployerContract__rowVacation">
                <p className="super-modal__form-label">{loc(dict.main_page.text_3)}:</p>
                <DummyCounter name="vacation_days"
                              value={+getModalValues.vacation_days || 0}
                              onChange={changeValues}
                              min={.5}
                              step={.5}/>
            </div>

            <div className="modalEmployerContract__toggleMainSelectCol">
                <div>
                    <CheckboxToggle left={`${loc(dict.main_page.text_10)}:`}
                                    checked={fields.test_plan_mins}
                                    onChange={() => setFields({ ...fields, test_plan_mins: !fields.test_plan_mins })} />
                </div>
                {fields.test_plan_mins &&
                <div>
                    <MainSelector image={threeImg}>
                        <div className="super-modal__form-column">
                            <div className="super-modal__form-row2">
                                <div className="super-modal__form-field">
                                    <p className="super-modal__form-label">{loc(dict.main_page.text_4)}:</p>
                                    <DummyCounter name="test_plan_mins"
                                                  value={getModalValues.test_plan_mins / getModalValues.hour_type || 0}
                                                  onChange={(val, key) => changeValues(val*getModalValues.hour_type, key)}
                                                  min={.5}
                                                  step={.5}/>
                                </div>
                                <div className="super-modal__form-field">
                                    <p className="super-modal__form-label">{loc(dict.main_page.text_5)}:</p>
                                    <SelectCustom
                                        placeholder={loc(dict.main_page.text_5)}
                                        options={test_plan_ht_v}
                                        valueID={getModalValues.hour_type}
                                        onChange={o => changeValues(o.id, 'hour_type')}
                                    />
                                </div>
                            </div>
                            <div><p className="modalEmployerContract__link">{loc(dict.main_page.text_6)}</p></div>
                        </div>
                    </MainSelector>
                </div>}
            </div>

            <div className="super-modal__form-field">
                <p className="super-modal__form-label">{loc(dict.main_page.comment)}</p>
                <textarea
                    placeholder={loc(dict.main_page.comment)}
                    value={getModalValues.comment || ''}
                    onChange={e => changeHandler(e, 'comment')}
                />
            </div>
        </div>
    )
}

export default observer(EmployeeContract)
