import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import BookedSlot from 'components/Modules/Slots/TeacherSchedule/components/BookedSlots/BookedSlot';
import SlotColorTypes from 'components/Modules/Slots/components/SlotColorTypes/SlotColorTypes';
import Hr from 'components/UI/Hr/Hr';
import { localisationMaster } from "Localisation/Localisation";
import Paragraph from 'components/UI/Paragraph/Paragraph';
import { scrollToRef } from 'helpers';
import slotsTeacherScheduleStore from 'Stores/slotsTeacherScheduleStore';
import styles from './BookedSlots.module.scss';

function BookedSlots() {
  const ref = useRef(null);
  const { bookedSlots, slotTypes } = slotsTeacherScheduleStore;
  const [menuID, setMenuID] = useState(null);

  const handleSlotClick = (id) => {
    menuID === id ? setMenuID(null) : setMenuID(id);
  };

  useEffect(() => {
    if (ref === null) {
      return;
    }
    scrollToRef(ref, 100);
  }, []);

  return (
    <Paper ref={ref}>
      <SectionTitle title={localisationMaster('bookeds_slots', 'teacher_schedule')} secondary />
      <ul className={styles.booked_list}>
        {bookedSlots.map((item) => (
          <BookedSlot
            key={item.id.toString()}
            onClick={handleSlotClick}
            isMenuShown={item.id === menuID}
            {...item}
          />
        ))}
      </ul>
      <Hr />
      <Paragraph>
        {localisationMaster('text_1', 'teacher_schedule')}
      </Paragraph>
      <Paragraph>
        {localisationMaster('text_2', 'teacher_schedule')} - <strong>{localisationMaster('text_3', 'teacher_schedule')}</strong>
      </Paragraph>
      <Hr />
      <SlotColorTypes types={slotTypes} />

    </Paper>
  );
}

export default observer(BookedSlots);
