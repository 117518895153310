import React from "react";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import './PayService.scss'

const PayService = ({banks, onChange, value}) => (
  <div className="payService phoenixBox">

    <div className="payService__title">
      {localisationMaster('select_payment_method', 'pays/invoice')}
    </div>

    <div className="payService__servicesBox">{banks.map(el => (
      <div className={`payService__service ${el.id === value ? `payService__service--selected` : ''}`}
           key={el.id} onClick={() => onChange(el.id)}>
        <img src={`${Stores.baseLink()}/${el.logo}`} alt={el.name}/>
        <span>{el.name}</span>
      </div>))}
    </div>

  </div>
)

export default PayService;
