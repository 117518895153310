import React from "react";
import PTLogo from "./PTLogo/PTLogo";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import InputTypes from "../InputTypes/InputTypes";
import { localisationMaster } from "Localisation/Localisation";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import { classNames as cn } from "helpers";
import "./Options.scss";

const Options = ({ form, changeForm, validate, optionsRef }) => (
  <div className="placementTestBox" ref={optionsRef}>
    <div className="placementTestCreate__options">
      <p className="placementTestBox__title">
        {localisationMaster("text_1", "c/tests/placement-test/create-widget")}
      </p>
      <div className="placementTestBox__mainBoxLine">
        <div className="placementTestBox__row space-between">
          <div className="placementTestBox__list">
            <div className="placementTestBox__field">
              <p className="super-modal__form-label">
                {localisationMaster(
                  "text_2",
                  "c/tests/placement-test/create-widget"
                )}
                :
              </p>
              <input
                placeholder={localisationMaster(
                  "text_2",
                  "c/tests/placement-test/create-widget"
                )}
                type="text"
                className={cn({
                  placementTestBox__input: true,
                  "placementTestBox__input--error": validate("title"),
                })}
                value={form.title}
                onChange={(e) => changeForm(e.target.value, "title")}
              />
            </div>
            <div className="placementTestBox__field">
              <p className="super-modal__form-label">
                {localisationMaster(
                  "text_3",
                  "c/tests/placement-test/create-widget"
                )}
                :
              </p>
              <SelectCustom
                placeholder={localisationMaster(
                  "text_4",
                  "c/tests/placement-test/create-widget"
                )}
                apiLink="db/companies"
                error={validate("company")}
                valueID={form.company}
                onChange={(o) => changeForm(o.id, "company")}
              />
            </div>
          </div>
          <PTLogo
            file={form.logo}
            setFile={(val) => changeForm(val, "logo")}
            validate={validate}
          />
        </div>
      </div>
      <div className="placementTestBox__mainBoxLine">
        <div className="placementTestBox__url">
          <p className="super-modal__form-label">
            {localisationMaster(
              "text_5",
              "c/tests/placement-test/create-widget"
            )}
            :
          </p>
          <div className="placementTestBox__row">
            <InputTypes
              value={form.url}
              onChange={(value) => changeForm(value, "url")}
              placeholder={localisationMaster(
                "text_6",
                "c/tests/placement-test/create-widget"
              )}
              error={validate("url")}
            />
            <CheckboxToggle
              right={localisationMaster(
                "text_7",
                "c/tests/placement-test/create-widget"
              )}
              onChange={() => changeForm(!form.frame, "frame")}
              checked={form.frame}
            />
          </div>
        </div>
      </div>
      <div className="placementTestBox__mainBoxLine">
        <div className="placementTestBox__list">
          <CheckboxToggle
            right={localisationMaster(
              "text_27",
              "c/tests/placement-test/create-widget"
            )}
            onChange={() =>
              changeForm(!form.is_data_collect, "is_data_collect")
            }
            checked={form.is_data_collect}
          />
          <p className="placementTestBox__text">
            {localisationMaster(
              "text_28",
              "c/tests/placement-test/create-widget"
            )}
          </p>
        </div>
      </div>
      <div className="placementTestBox__mainBoxLine">
        <div className="placementTestBox__list">
          <CheckboxToggle
            right="Показывать таймер при прохождений тестирования"
            onChange={() => changeForm(!form.show_timer, "show_timer")}
            checked={form.show_timer}
          />
        </div>
      </div>
      <div className="placementTestBox__list">
        <CheckboxToggle
          right="Показывать кнопку &laquo;Завершить тестирование&raquo; при прихождении тестирования"
          onChange={() => changeForm(!form.show_end_button, "show_end_button")}
          checked={form.show_end_button}
        />
      </div>
    </div>
  </div>
);

export default Options;
