import React, {useEffect, useState} from 'react';
import st from './SortSentences.module.scss';

export default function SortSentences(props) {

  const [sentences, setSentences] = useState(props.answers)

  const handleClick = i => {
    if (i) {
      const arr = [...sentences]
      arr[i] = arr.splice(i - 1, 1, arr[i])[0]
      setSentences(arr)
    }
  }

  useEffect(() => {
    props.onChange(sentences)
  }, [sentences])

  return (
    <div>
      <ol className={st.sentences}>{sentences.map((s, i) =>
        <li key={s} className={st.sentenceRow} onClick={() => handleClick(i)}>
          <span className={st.number}>{i + 1}</span>
          <p className={st.sentence}>{s}</p>
        </li>)}
      </ol>
    </div>
  );
};
