import React, {useEffect} from "react";
import styles from './VideoLinks.module.scss'
import Plyr from 'plyr';

export default function VideoLinks(props) {

    const { innerWidth } = window;
    const isMobile = innerWidth < 768;

    useEffect(() => {
        const players = Array.from(document.querySelectorAll('.plyr-youtube'));

        players.forEach(player => {
            if (isMobile) {
                new Plyr(players, {
                    controls:['play','progress','current-time', 'mute', 'settings']
                })
            }else {
                new Plyr(player);
            }

        });
    }, []);

    function transformVideoLink(link) {
        if (link.includes('vimeo.com')) {
            return link.replace('vimeo.com', 'player.vimeo.com/video');
        } else if (link.includes('ted.com')) {
            return link.replace('ted.com', 'embed.ted.com');
        } else if (link.includes('vk.com/video-')) {
            const regex = /\/video(-\d+)_(\d+)/;
            const match = link.match(regex);
            if (match && match.length === 3) {
                const oid = match[1];
                const id = match[2];
                return `https://vk.com/video_ext.php?oid=${oid}&id=${id}`;
            }
        }
        return link;
    }

    return (
        <>
            <div className={styles.content}>{props.videos.map((video, idx) =>
                <div className={styles.youtubeContainer} key={idx}>
                    <h4>{video.description}</h4>
                    <div className="plyr-youtube">
                        <iframe
                            src={transformVideoLink(video.video_link)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className={styles.iframe}
                        />
                    </div>
                </div>
            )}
            </div>
        </>
    )
}