export function declOfNum(number, titles) {  
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    // declOfNum(count, ['найдена', 'найдено', 'найдены']);
}

export const addZero = num => +num < 10 ? `0${num}` : `${num}`;

export function range(a,b,c){c=[];while(a--)c[a]=a+b;return c}

// Разбить число на разряды (3500 -> 3 500)
export const splitNumberDigits = val => String(val).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')