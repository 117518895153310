import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {permissions} from "settings/permissions";
import {dateFormat} from "helpers/date";
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import modalStore from "Stores/modalStore";
import Rating from "components/ContentBox/Global/GlobalModalSingle/templates/groups/Rating";
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import st from "./TeachersRatings.module.scss"
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import TeacherRating from "./TeacherRating/TeacherRating";
import TabMenu from "../../../../Ebook/Common/TabMenu/TabMenu";
import AverageProgressReport
  from "../../../components/StudentsProgressReports/AverageProgressReport/AverageProgressReport";
import Loader from "components/UI/Loader/Loader";
import HeaderContentBox from "../../../components/HeaderContentBox";
import useLocalization from "hooks/useLocalization";

const getMonth = (date) => {
  return dateFormat(new Date(date), 'MMM, YYYY')
}

const TeachersRatings = ({groupID, teachers, students}) => {

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_rating_select',
    dict: {
      "teacher_rating": "Teacher Rating",
      "teacher": "Teacher",
      "teachers": "Teachers",
      "save": "Save",
    }
  })
  
  const getMonthData = (month) => {
    return rating?.filter((item) => getMonth(item.evaluation_date) === month)
  }

  const extractMonthYearFromDateArray = (dateArray) => {
    const extractedArray = dateArray.map((item) => {
      const date = new Date(item.evaluation_date);
      return dateFormat(new Date(date), 'MMM, YYYY')
    });
    const arr = [...new Set(extractedArray.reverse())]
    return arr.map((item,index) => ({id: index+1, name: item}))
  }

  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [rating, setRating] = useState([])
  const tabs = extractMonthYearFromDateArray(rating)
  const [activeMonth, setActiveMonth] = useState({})
  const monthData = getMonthData(activeMonth?.name)

  const addTeacherRating = () => {
    const ms = {
      title: translate.teacher_rating,
      button: translate.save,
      modalBodyComponent: () => <Rating
        evaluationApiLink="progress_report/teacher_evaluation"
        ratingApiLink="progress_report/teacher_rating"
        user={{
          title: translate.teacher,
          placeholder: translate.teachers,
          key: 'teacher'
        }}
      />,
      payload: {
        students: teachers.map(st => ({id: st.id, name: `${st.last_name} ${st.first_name}`}))
      },
      link: 'progress_report/teacher_report',
      valid: {
        teacher: 'required',
        rate_list: (rate_list) =>  rate_list.every((x) => x.rating_id !== null ) 
      },
      func: (data) => setRating([...data.reports]),
      width: 600,
    }
    modalStore.activity(ms, {
      group: groupID,
    })
  }

  useEffect(() => {
    if (!open)  return
    setIsLoading(true)
    superAxiosRequest({
      method: 'get',
      link: 'progress_report/teacher_report',
      params: {
        group_id: groupID
      }
    }).then(({data}) => {
      setIsLoading(true)
      setRating(data.reports)
      const extractedTabs = extractMonthYearFromDateArray(data.reports);
      setActiveMonth(extractedTabs[0]);
    }).catch(() => {})
        .finally(() => setIsLoading(false))
  }, [open, groupID])


  const teachersData = {};

  monthData.forEach((evaluation) => {
    const teacherName = evaluation.teacher_name;
    const rateList = evaluation.rate_list;

    if (!teachersData[teacherName]) {
      teachersData[teacherName] = {
        totalRating: 0,
        totalCount: 0
      };
    }

    rateList.forEach((rate) => {
      teachersData[teacherName].totalRating += rate.value;
      teachersData[teacherName].totalCount++;
    });
  });

  const averageRatings = Object.entries(teachersData).map(([teacherName, { totalRating, totalCount }]) => ({
    teacherName,
    averageRating: totalCount !== 0 ? totalRating / totalCount : 0,
  }));

  return (
    <HeaderContentBox className={st.ratingList}>
      <OpenHeader title={translate.teacher_rating}
                  onClick={() => setOpen(!open)}
                  addButton={checkPermList(permissions.add_teacher_report) ? addTeacherRating : null}/>
      {open && (
          <>
            {
              isLoading ? <Loader/> : <>
                {rating.length > 0 ? (
                    <div className={st.list}>
                      <TabMenu tabs={tabs} onChange={(tab)=> setActiveMonth(tab)} tabID={activeMonth?.id}/>
                      {
                        averageRatings.map((item, index) => {
                          return <AverageProgressReport key={index} teacherName={item.teacherName} activeMonth={activeMonth?.name} averageRatingPerMonth={item.averageRating} type={'teacher'}/>
                        })
                      }

                      {monthData.map((item, index) => (
                          <TeacherRating key={index} item={item} students={students} />
                      ))}
                    </div>
                ) : (
                    <div className={st.empty}>
                      <EmptyData />
                    </div>
                )}
              </>
            }
          </>
      )}
    </HeaderContentBox>
  )
}

TeachersRatings.propTypes = {
  groupID: PropTypes.number,
  teachers: PropTypes.arrayOf(PropTypes.object),
  students: PropTypes.arrayOf(PropTypes.object),
}

export default TeachersRatings
