import React from "react";
import {localisationMaster} from "Localisation/Localisation";
import "./TimeSlot.scss"

export default function TimeSlot (props) {

    const slot = props.slot
    const slotId = props.slotId

    const sOnline = localisationMaster('online', 'online-schedule/record-customer')
    const sSchool = localisationMaster('school', 'online-schedule/record-customer')

    return (
        <div
            className={"timeSlot " + (slotId === slot.id ? "timeSlot--active" : "")}
            onClick={() => {props.setSlotId(slot.id === slotId ? 0 : slot.id)}}>

            <div className="timeSlot__time" style={{backgroundColor: !slot.is_active && 'red' }}>
                {props.offsetTime(slot.time_start) + ' - ' + props.offsetTime(slot.time_end)}
                {/* <sup>(GMT{tz>=0 ? '+'+tz : tz})</sup> */}
            </div>

            <div className="timeSlot__desc">{(slot.is_online ? sOnline + ' + ' : '') + sSchool}</div>
        </div>
    )
}
