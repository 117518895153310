import React from 'react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Sms from 'components/Modules/Slots/RecordCustomer/components/Sms/Sms';
import PaymentComment from 'components/Modules/Slots/RecordCustomer/components/PaymentComment/PaymentComment';
import PaymentOrder from 'components/Modules/Slots/RecordCustomer/components/PaymentOrder/PaymentOrder';
import styles from './PaymentFields.module.scss';

function PaymentFields() {
  return (
    <Paper>
      <SectionTitle title='Оплата и уведомление' secondary />
      <Sms />
      <PaymentComment />
      <PaymentOrder />
      <p className={styles.text}>
        Клиент получит получит все данные о забронированном слоте на указанный адрес e-mail. <br />
        Также в письме будут данные о его личном кабинете: ссылка на кабинет, логин и пароль.
      </p>
      <p className={styles.text}>
        Если клиент не получил данное письмо, ему нужно проверить папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти. <br />
        Также необходимо добавить no-reply@interpress.kz в список разрешенных отправителей, чтобы предотвратить блокирование писем из-за настроек программного обеспечения.
      </p>
    </Paper>
  );
}

export default PaymentFields;
