import React, { createContext, useEffect, useState } from 'react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Select from 'components/UI/Select/Select';
import Button from 'components/UI/Button/Button';
import Loader from 'components/UI/Loader/Loader';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import MultiSelect from 'components/ContentBox/Global/Idlist/MultiSelect';
import SlotColorTypes from 'components/Modules/Slots/components/SlotColorTypes/SlotColorTypes';
import TeacherHistoryCard from './components/TeacherHistoryCard';
import { dateFormat } from 'helpers/date';
import { superAxiosRequest } from 'axiosApi';
import styles from './TeachersHistory.module.scss';
import { translate as loc, dict } from "Localisation";

const dateToday = new Date();
const dateMonthAgo = new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, dateToday.getDate());
export const TeachersHistoryModalContext = createContext('as');

function TeachersHistory() {

  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [dateFrom, setDateFrom] = useState(dateFormat(dateMonthAgo, 'YYYY-mm-dd'));
  const [dateTo, setDateTo] = useState(dateFormat(dateToday, 'YYYY-mm-dd'));

  const [allTeachers, setAllTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [typesColors, setTypesColors] = useState([]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(true);
  const [slotTypesArray, setSlotTypesArray] = useState();

  const returnDataPickerValue = (key) => {
    switch (key) {
      case 'date_from':
        return dateFrom;
      case 'date_until':
        return dateTo;
    }
  };

  const handleCompanySelectChange = (option) => {
    setSelectedCompany(option);
    const locations = option.locations
      .map((item) => ({...item, value: item.id, label: item[loc({'en':'name_en','ru': 'name', 'kz':'name_loc'})]}))
      .sort((a, b) => a.label > b.label ? 1 : -1);
    setLocations(locations);
    setSelectedLocation(null);
    setSelectedTeachers([]);
  };

  const handleDataPickerChange = (key, value) => {
    switch (key) {
      case 'date_from':
        setDateFrom(value)
        break;
      case 'date_until':
        setDateTo(value)
        break;
    }
  };
    
  const handleShowTeachersButton = () => {
    setIsLoading(true);
    setIsEmpty(false);
    const params = {
      start_date: dateFrom,
      end_date: dateTo,
      employee_id: `[${selectedTeachers.map(({id}) => id)}]`,
    };
    if (selectedLocation?.value) {
      params.location = selectedLocation.value;
    }
    if (selectedCompany?.value) {
      params.company = selectedCompany.value;
    }
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/teacher/history',
      params,
    })
      .then(({data}) => {
        if (data.booked_slots.length === 0) {
          setIsEmpty(true);
        }
        setTeachers(data.booked_slots.sort((a, b) => a.employee_name > b.employee_name ? 1 : -1));
        setTypesColors(data.slot_types.map(({type_id, color, type_name}) => ({id: type_id, name: type_name, color})));
        setSlotTypesArray(data.slot_types.map(item => ({id: item.type_id, value:item.type_id, label: item.type_name , name:item.type_name  }) ))
      })
      .catch(() => setIsEmpty(true))
      .finally(() => setIsLoading(false));    
  };

  const getUpdatedTeachersData = (data) => { //меняет статус слота и возврает обновленный teachers
    let updatingTeacher = teachers.filter(item => item.employee_id === data.employee_id)[0]
    updatingTeacher = {
      ...updatingTeacher, items: [{
        ...updatingTeacher.items[0],
        employee_booked_slots: [...updatingTeacher.items[0].employee_booked_slots.filter(item => item.id !== data.id),
        {...updatingTeacher.items[0].employee_booked_slots.filter(item => item.id === data.id)[0], 
          is_active: !updatingTeacher.items[0].employee_booked_slots.filter(item => item.id === data.id)[0].is_active}].sort((a, b) => {
          let timeArrayA = a.start_at.split(':')
          let timeArrayB = b.start_at.split(':')   
          return (new Date).setHours(timeArrayA[0], timeArrayA[1], timeArrayA[2], 0) > (new Date).setHours(timeArrayB[0], timeArrayB[1], timeArrayB[2], 0) ? 1 : -1
        }) 
      }]
    }
    setTeachers( [...teachers.filter(item => item.employee_id !== data.employee_id), updatingTeacher].sort((a, b) => a.employee_name > b.employee_name ? 1 : -1))
    
  }


  
  useEffect(() => {
    superAxiosRequest({
      method: 'get',
      link: 'db/companies',
    }).then(({data}) => {
      if (data.result.length === 1) {
        setSelectedCompany({value: data.result[0].id, label: data.result[0].name});
      }
      setCompanies(data.result
          .map((item) =>({...item, value: item.id, label: item.name}))
          .sort((a, b) => a.label > b.label ? 1 : -1));
    })
      .finally(() => setIsCompaniesLoading(false));
  }, []);

  useEffect(() => {
    superAxiosRequest({
      method: 'get',
      link: 'accounts/employers_flat_list',
    }).then(({ data }) => {
      setAllTeachers(data.result.map(({ id, full_name }) => ({id, name: full_name})));
    });
  }, []);

  useEffect(() => {
    if (selectedLocation === null) {
      return;
    }

    superAxiosRequest({
      method: 'get',
      link: 'accounts/employers_flat_list',
      params: {
        location: selectedLocation.value,
      },
    })
      .then(({data}) => setSelectedTeachers(data.result.map(({id, full_name}) => ({id, name: full_name}))));
  }, [selectedLocation]);

  return (
    <>
      <ContentHeader title="История записи сотрудников"/>
      <div className={styles.content}>

        <div className={styles.selectors_wrapper}>
          <div className={styles.selectors}>
            { companies.length !== 1
                ? <Select
                    options={companies}
                    value={selectedCompany}
                    onChange={handleCompanySelectChange}
                    isLoading={isCompaniesLoading}
                    placeholder={loc(dict.work_table_listing.choose_company)}
                  />
                : null
            }
            <Select
              options={locations}
              value={selectedLocation}
              onChange={(option) => setSelectedLocation(option)}
              isDisabled={selectedCompany === null}
              placeholder={loc(dict.work_table_listing.choose_location)}
            />
            <DataPickerSplit
              returnValue={returnDataPickerValue}
              objectChangeHandler={(val, key) => handleDataPickerChange(key, val)}
            />
          </div>

          <div className={styles.button_wrapper}>
            <Button
              onClick={handleShowTeachersButton}
              isDisabled={selectedTeachers.length === 0}
            >
              {loc(dict.alert.show)}
            </Button>
          </div>

          <div className={styles.teachers_filter}>
            <MultiSelect
              sortKey='name'
              options={allTeachers}
              values={selectedTeachers}
              onChange={(teachers) => setSelectedTeachers(teachers)}
            />
          </div>
        </div>

        {isEmpty &&
          <div className={styles.empty}>За выбранный период нет записей сотрудников</div>
        }

        {teachers.length > 0 && !isLoading &&    
          <TeachersHistoryModalContext.Provider value={{
            slotTypesArray: slotTypesArray,
            selectedLocation: selectedLocation,
            getUpdatedTeachersData: getUpdatedTeachersData
          }}>
            <div className={styles.teachers_count}>Учителей: {teachers.length}</div>
            <ul className={styles.teachers_list}>
              {teachers.map((item) => (
                <TeacherHistoryCard
                  key={item.employee_id.toString()}
                  data={item}
                />
              ))
              }
              </ul>
            <SlotColorTypes types={typesColors} withIcon />  
           </TeachersHistoryModalContext.Provider>
          
                  
        }

        {isLoading && <Loader fullWidth />}
      </div>
    </>
  );
}

export default TeachersHistory;
