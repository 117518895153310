import React, { useContext } from 'react';
import { agetostr } from "components/ContentBox/Online-schedule/components/functions";
import { dateFormat } from "helpers/date";
import st from "./ResultGenInfo.module.scss"
import Context from "../resultContext";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores"



export default function ResultGenInfo({ result }) {
    const { isClient } = useContext(Context)
    const { student, answers, test_sections, stats } = result;
    const answersCount = Math.round(stats.correct_answers / stats.total_answers * 100);
    
    const getSection = section => {
        const sectionAnswers = answers.filter(a => a.test_section_id === (section?.id || null))

        const {
            correct_answers,
            total_answers,
            points
        } = sectionAnswers.reduce((acc, { stats }) => ({
            correct_answers: acc.correct_answers + stats.correct_answers,
            total_answers: acc.total_answers + stats.total_answers,
            points: acc.points + stats.points,
        }), {
            correct_answers: 0,
            total_answers: 0,
            points: 0,
        })

        return {
            ...section,
            name: section?.name || localisationMaster('text_1', 'study/test', 'result'),
            correct_answers,
            percentage: Math.round(correct_answers / total_answers * 100) || 0,
            points,
        }
    }

    const sections = test_sections.map(el => getSection(el));

    return (
        <div className={st.result}>
            <div className={st.numbers}>
                <header>
                    <h4>{localisationMaster('text_2', 'study/test', 'result')}</h4>
                    <div>
                        <span>{stats.correct_answers}</span>
                        <span>/</span>
                        <span>{isNaN(answersCount) ? 0 : answersCount}%</span>
                    </div>
                </header>
                <footer>
                    <h4>{localisationMaster('text_3', 'study/test', 'result')}</h4>
                    <div>
                        <span>{stats.points}</span>
                    </div>
                </footer>
            </div>
            <div className={st.content}>
                <div className={st.top}>
                    <div className={st.fullname}>
                        <h2>{ Stores.transliterate(`${student.last_name} ${student.first_name}`)}</h2>
                        <p>{agetostr(student.date_of_birth)} ({dateFormat(student?.date_of_birth, "d MMMM YYYY г.")})</p>
                    </div>
                    <div className={st.datePassage}>
                        <p>{localisationMaster('table-row-title-2', 'tests/widget-clients')}:</p>
                        <p>{dateFormat(result.updated_at, "d MMMM YYYY г., hh:ii:ss")}</p>
                    </div>
                </div>
                <div className={st.bottom}>
                    <div className={st.info}>
                        {!isClient && <div className={st.block}>
                            <p className={st.subtitle}>{localisationMaster('text_13', 'study/test')}:</p>
                            {(!result.focus_live.enabled && !result.mouse_freeze.enabled) 
                                ? <p className={st.description}>{localisationMaster('text_4', 'study/test', 'result')}</p>
                                : ( <ul>
                                        {result.focus_live.enabled && 
                                        <li>
                                            <span>{localisationMaster('text_17', 'study/test')}:</span> 
                                            <span className={st.description}>
                                                {result.focus_live.count} ({result.focus_live.duration})
                                            </span>
                                        </li>}
                                        {result.mouse_freeze.enabled && 
                                            <li>
                                                <span>{localisationMaster('text_16', 'study/test')}:</span> 
                                                <span className={st.description}>{result.mouse_freeze.count} ({result.mouse_freeze.duration})</span>
                                            </li>
                                        }
                                    </ul>)
                            }
                        </div>}
                        {!isClient && <div className={st.block}>
                            <p className={st.subtitle}>{localisationMaster('title', 'tests/questions')} / {localisationMaster('text_23', 'c/tests/placement-test/create-widget')}:</p>
                            <ul>
                                <li><span>{localisationMaster('text_5', 'study/test', 'result')}:</span> <span className={st.description}>{result.questions.length}</span></li>
                                <li><span>{localisationMaster('text_23', 'c/tests/placement-test/create-widget')}: {localisationMaster('text_7', 'study/test', 'result')}</span> <span className={st.description}>{stats.total_answers} / {stats.correct_answers}</span></li>
                                <li><span> {localisationMaster('text_6', 'study/test', 'result')}:</span> <span className={st.description}>{(result.is_correct_answers || []).slice(0, (result.answers || []).length).length}</span></li>
                                {Boolean(result.employee_points) && <li><span>Баллы от учителя:</span> <span className={st.description}>{result.employee_points}</span></li>} 
                            </ul>
                        </div>}
                    </div>
                    {sections.length > 0 && <div className={st.sections}>
                        <p className={st.subtitle}>{localisationMaster('text_8', 'study/test', 'result')}</p>
                        <ul>
                            {sections.map(el =>  
                                <li key={el.id}>
                                    <span>{el.name}:</span> 
                                    <span className={st.description}>{el.correct_answers} / {el.percentage}% | {el.points}</span>
                                </li>)
                            }
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    );
}

