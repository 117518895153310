import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import modalStore from "Stores/modalStore";
import ColorSelector from "components/ContentBox/Global/ColorSelector/ColorSelector";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import HourCounter from "components/ContentBox/Global/HourCounter/HourCounter";
import DataPickerSplit from "components/ContentBox/Global/DatePicker/DataPickerSplit";
import WeekdaysSelector from "./WeekdaysSelector";
import DateTime from "components/ContentBox/Global/DateTime/DateTime";
import Days from "./Days";
import st from "./CreateTimeTable.module.scss"


export const hour_types = [
  {
    id: 1,
    name: "Академические",
  },
  {
    id: 2,
    name: "Астрономические",
  }
]

const lunch_break_list = [0, 5, 10, 15, 20, 30, 45, 60, 90];

export const checkHolyDays = (date, holidays) => holidays.some(x => x.date === date);

const CreateTimeTable = props => {

  const {data: ms, modalValues, changeValues, changeValuesArray, check} = modalStore
  const {location, group_id, holidays} = ms.payload

  const [availableResource, setAvailableResource] = useState({
    loading: false,
    key: '',
    data: {}
  })
  const [teachers, setTeachers] = useState(modalValues.teachers.length > 0 ? modalValues.teachers : [null])
  const [days, setDays] = useState([])

  const isNotHoliday = date => !checkHolyDays(dateFormat(date, "YYYY-mm-dd"), holidays)

  const calculator = (val, key) => {

    let obj, startAt, endAt, dateFinishAt, count, curDay, dow
    switch (key) {
      case "hour_type":
        dateFinishAt = new Date(`${modalValues.date_from}T${modalValues.start_at}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + modalValues.hours_per_day * (val === 1 ? 45 : 60))
        obj = {
          hour_type: val,
          finish_at: dateFormat(dateFinishAt, "hh:ii")
        }
        break;
      case "hours_total":
        count = Math.ceil(val / modalValues.hours_per_day)
        curDay = new Date(modalValues.date_from);
        while (count > 0) {
          dow = curDay.getDay() || 7;
          if (modalValues.weekdays.includes(dow) && isNotHoliday(curDay))
            count--
          if (count > 0)
            curDay = new Date(curDay.setDate(curDay.getDate() + 1));
        }

        obj = {
          hours_total: val,
          date_until: dateFormat(curDay, "YYYY-mm-dd"),
        }
        break;
      case "date_from":
        startAt = new Date(val)
        endAt = new Date(val)
        count = modalValues.hours_total / modalValues.hours_per_day
        curDay = new Date(startAt);
        while (count > 0) {
          dow = curDay.getDay() || 7;
          if (modalValues.weekdays.includes(dow) && isNotHoliday(curDay)) {
            count--
          }
          curDay.setDate(curDay.getDate() + 1);
        }

        obj = {
          date_from: dateFormat(val, "YYYY-mm-dd"),
          date_until: dateFormat(curDay, "YYYY-mm-dd"),
        }
        break;
      case "date_until":
        startAt = new Date(modalValues.date_from);
        endAt = new Date(val)
        count = 0
        curDay = new Date(startAt);
        while (curDay <= endAt) {
          dow = curDay.getDay() || 7;
          if (modalValues.weekdays.includes(dow) && isNotHoliday(curDay)) {
            count++
          }
          curDay.setDate(curDay.getDate() + 1);
        }

        obj = {
          hours_total: count * modalValues.hours_per_day,
          date_until: dateFormat(val, "YYYY-mm-dd"),
        }
        break;
      case "weekdays":
        count = Math.ceil(modalValues.hours_total / modalValues.hours_per_day)
        curDay = new Date(modalValues.date_from);
        while (count > 0) {
          dow = curDay.getDay() || 7;
          if (val.includes(dow) && isNotHoliday(curDay))
            count--
          if (count > 0)
            curDay = new Date(curDay.setDate(curDay.getDate() + 1));
        }
        obj = {
          weekdays: val,
          date_until: dateFormat(curDay, "YYYY-mm-dd"),
        }
        break;
      case "hours_per_day":
        count = Math.ceil(modalValues.hours_total / val)
        curDay = new Date(modalValues.date_from);

        while (count > 0) {
          dow = curDay.getDay() || 7;
          if (modalValues.weekdays.includes(dow) && isNotHoliday(curDay)) {
            count--
          }
          if (count > 0)
            curDay.setDate(curDay.getDate() + 1)
        }

        dateFinishAt = new Date(`${modalValues.date_from}T${modalValues.start_at}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + val * (modalValues.hour_type === 1 ? 45 : 60))

        obj = {
          hours_per_day: val,
          date_until: dateFormat(curDay, "YYYY-mm-dd"),
          finish_at: dateFormat(dateFinishAt, "hh:ii"),
        }
        break;
      case "start_at":
        dateFinishAt = new Date(`${modalValues.date_from}T${val}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + modalValues.hours_per_day * (modalValues.hour_type === 1 ? 45 : 60))
        obj = {
          start_at: val,
          finish_at: dateFormat(dateFinishAt, "hh:ii"),
        }
        break;
      case "finish_at":
        startAt = new Date(`${modalValues.date_from}T${val}`)
        startAt.setMinutes(startAt.getMinutes() - modalValues.hours_per_day * (modalValues.hour_type === 1 ? 45 : 60))
        obj = {
          start_at: dateFormat(startAt, "hh:ii"),
          finish_at: val,
        }
        break;
      default:
        break;
    }
    changeValuesArray({...modalValues, ...obj})
  }

  const changeTeacherHandler = (teacher, idx) => {
    let arr = [...modalValues.teachers]
    if (arr?.[idx])
      arr[idx] = teacher.id
    else
      arr.push(teacher.id)

    changeValues(arr, 'teachers')
  }

  const deleteTeacherHandler = idx => {
    const func = (x, i) => i !== idx
    setTeachers(teachers.filter(func))
    changeValues(modalValues.teachers.filter(func), 'teachers')
  }

  useEffect(() => {
    if (days.length === 0) return

    const key = days.join()
    if (key === availableResource.key) return

    setAvailableResource({
      ...availableResource,
      key,
      loading: true,
    })

    superAxiosRequest({
      method: 'post',
      link: 'schedule/available_resources',
    }, {
      group_id,
      start_at: modalValues.start_at,
      finish_at: modalValues.finish_at,
      dates: days
    }).then(({data}) => {
      setAvailableResource({
        loading: false,
        key,
        data
      })
    }).catch(() => {
      setAvailableResource({
        loading: false,
        key: '',
        data: {}
      })
    })
  }, [days])

  return (
    <div className={st.form}>
      <div className={st.rowLocationColor}>
        <div>
          <input disabled value={location.name}/>
        </div>
        <ColorSelector value={modalValues.color} objectChangeHandler={changeValues}/>
      </div>

      <div className={st.row}>
        <div>
          <SelectCustom
            placeholder={localisationMaster('text_37', 'modal_templates')}
            options={hour_types}
            valueID={modalValues.hour_type}
            onChange={o => calculator(o.id, 'hour_type')}
            //isLoading={loading}
          />
        </div>

        <div>
          <HourCounter
            note={localisationMaster('text_38', 'modal_templates')}
            name="hours_total"
            start_position={3}
            objectChangeHandler={calculator}
            returnValue={key => modalValues[key]}
          />
        </div>

        <div>
          <DataPickerSplit
            returnValue={key => modalValues[key]}
            objectChangeHandler={calculator}
            disableFrom={props.isProlong}
          />
        </div>
      </div>

      <div>
        <WeekdaysSelector days={modalValues.weekdays} name="weekdays" onChange={calculator}/>
      </div>

      <div className={st.row}>
        <div>
          <SelectCustom
            placeholder={localisationMaster('nonstop', 'groups/group', 'group_schedule')}
            options={lunch_break_list.map(i => ({value: i, label: i}))}
            getOptionLabel={opt => `${opt.label} ${localisationMaster('mins', 'groups/group')}`}
            valueID={modalValues.lunch_break || null}
            onChange={o => changeValues(o.value, 'lunch_break')}
          />
        </div>

        <div>
          <HourCounter
            note={localisationMaster('text_48', 'modal_templates')}
            start_position={1}
            name="hours_per_day"
            returnValue={key => modalValues[key]}
            half
            objectChangeHandler={calculator}
            readOnly
          />
        </div>

        <div>
          <DateTime placeholder='9:00'
                    errorCheker={() => {
                    }}
                    start_at={modalValues.start_at}
                    finish_at={modalValues.finish_at}
                    objectChangeHandler={calculator}/>
        </div>
      </div>

      <div className={st.rowTeachersRooms}>
        <div className={st.teachers}>{teachers.map((val, idx) =>
          <div className={st.teacher} key={idx}>
            <div>
              <SelectCustom
                placeholder={localisationMaster('text_44', 'modal_templates')}
                options={availableResource.data.teachers?.map(x => ({
                  ...x,
                  full_name: Stores.transliterate(x.full_name)
                })) || []}
                optionLabelKey="full_name"
                sortKey="full_name"
                valueID={modalValues.teachers.length > idx ? modalValues.teachers[idx] : null}
                onChange={o => changeTeacherHandler(o, idx)}
                isLoading={availableResource.loading}
                isSearchable
                error={check('teachers')}
              />
            </div>
            <div className={st.teacherAdd} onClick={() => setTeachers([...teachers, null])}/>
            <div className={st.teacherDel}
                 onClick={() => teachers.length > 1 && deleteTeacherHandler(idx)}/>
          </div>)}
        </div>

        <div>
          <SelectCustom
            placeholder={localisationMaster('text_45', 'modal_templates')}
            options={availableResource.data.classrooms || []}
            sortKey="name"
            onChange={o => changeValues(o.id, 'classroom_id')}
            valueID={modalValues.classroom_id}
            isLoading={availableResource.loading}
            error={check('classroom_id')}
          />
        </div>
      </div>

      <Days teachers={availableResource.data.teachers || []}
            classrooms={availableResource.data.classrooms || []}
            onChange={setDays}/>

    </div>
  )
}

export default observer(CreateTimeTable)
