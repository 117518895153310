import React, {useEffect, useState} from 'react';
import Checkbox from 'components/ContentBox/Global/Checkbox'

export default function Variants(props) {
  const [values, setValues] = useState(props.values.answers)

  const chbx = index => {
    let arr = values;
    arr[index].is_correct = arr[index].is_correct ? !arr[index].is_correct : true
    setValues([...arr])
  }

  useEffect(() => {
    let arr = values.filter(x => x.is_correct)
    arr = arr.map(value => value.value)
    props.answers(arr)
  }, [values])

  useEffect(() => {
    if (!props.selected) return;
    let arr = props.values.answers.map(el => ({value: el.value, is_correct: props.selected.includes(el.value)}))
    setValues([...arr])
  }, [props.selected])

  return (
    <>
      <div className="questions-box__variants">{values.map((item, index) =>
        <div className="questions-box__variants__line" key={index}>
          <Checkbox
            id={index}
            isChecked={() => chbx(index, item.values)}
            //checked={item.is_correct} What
            checked={item.is_correct || false}
            label={item.value}/>
        </div>)}
      </div>
    </>
  )
}
