import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import Stores from "Stores";
import lessonStudentStore from "../LessonStudent/lessonStudentStore";
import {LessonContext} from "../BookChapter/LessonContext";
import Loader from "components/UI/Loader/Loader";
import Exercise from "../Common/Exercise/Exercise";
import LessonToolbar from "../LessonStudent/LessonToolbar";
import BookPaper from "../Common/BookPaper/BookPaper";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import st from "../LessonStudent/LessonStudent.module.scss";
import noExercisesImg from "components/UI/illustrations/streamline-work-from-home.svg";
import LessonStudentStore from "../LessonStudent/lessonStudentStore";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import {ReactComponent as SubmitReview} from "components/UI/icons/review_icon.svg";
import {superAxiosRequest} from "axiosApi";
import ShowMark from "../ShowMark/ShowMark";
import swal from "sweetalert";
import {localisationMaster} from "Localisation/Localisation";
import ReviewInfoBlock from "./ReviewInfoBlock/ReviewInfoBlock";
import LessonDiscussion from "../LessonDiscussion/LessonDiscussion";
import LessonLogs from "../LessonLogs/LessonLogs";
import useLocalization from "hooks/useLocalization";

function HomeworkStudent(){
  const {hash} = useParams()

  const {lesson, errorStatus, clearLesson} = lessonStudentStore

  const context = {mode: 'student'}

  const wsRef = useRef()

  const [isSubmit, setIsSubmit] = useState(false)

  const [currentRef, setCurrentRef] = useState(null)

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "save_answer": "Save Answer",
      "add_to_favorites": "Add to Favorites",
    }
  })

  const scrollToBlock = () => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  useEffect(() => {
    lessonStudentStore.fetchHomework(hash)

    const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/ebooks/homework/${hash}/?token=${localStorage.getItem('access')}`;
    wsRef.current = new WebSocket(wsUrl)
    wsRef.current.onmessage = ({data}) => {
      const {type, message} = JSON.parse(data)
      if (type === "ebooks.updated_homework_exercises") {
        LessonStudentStore.setLessonExercises(message.exercises)
        LessonStudentStore.setLessonAssignExercises(message.assign_exercises)
      }
    }

    return () => {
      clearLesson()
    }
  }, [clearLesson, hash])


  const submitReview = () => {

    swal({
      text: `Вы действительно хотите отправить домашнее задание на проверку?`,
      icon: "warning",
      buttons: [
        localisationMaster('button_2', 'alert'),
        localisationMaster('button_1', 'alert')
      ],
      dangerMode: true
    }).then((yes) => {
      if (yes) {
        setIsSubmit(true)
        superAxiosRequest({
          method: 'post',
          link: 'ebooks/student_lesson_reviews'
        }, {
          homework_hash: hash,
        })
      } else {
        setIsSubmit(false)
        swal('Действие отменено')
      }
    })
  }

  return (
    lesson ?
      <div className={st.lessonStudent}>
        <LessonToolbar lessonLink={`/ebooks/${Stores.isClient ? 'my-' : ''}lesson/${lesson.init_lesson_uuid}`} />
        <div className={st.bookContainer}>
          <BookPaper>
            <div className={st.bookColumn}>
              <LessonHomeworkHeader title="Homework"
                                    createdAt={lesson.created_at}
                                    ebookName={lesson.chapter_ebook_name}
                                    chapterName={lesson.chapter_name}/>
              {
                  lesson?.mark && <ShowMark mark={Number(lesson?.mark)} type={lesson?.company_mark_type} scroll={scrollToBlock}/>
              }
              {
                !lesson?.mark && !isSubmit && <ReviewInfoBlock scrollToBlock={scrollToBlock}/>
              }
              {lesson.exercises.length > 0 ?
                <LessonContext.Provider value={context}>
                 {lesson.exercises.map((ex, index) =>
                    <div key={ex.id}>
                      <Exercise number={`1.${index + 1}`} ex={ex} translate={translate}/>
                    </div>)
                 }
                </LessonContext.Provider> :
                <WithoutExercises text1="No homework yet :("
                                  text2="The teacher has not yet assigned exercises for homework. Stay tuned :)"/>}
            </div>
          </BookPaper>
          {
              (lesson.exercises.length > 0 && !lesson?.review && !isSubmit) && <div className={st.review}>
                <div className={st.reviewBtn} onClick={submitReview}>
                  <SubmitReview/>
                  <span>Send Lesson For Review</span>
                </div>
              </div>
          }
          <LessonDiscussion context={'student'} hash={hash} isHomework={true} setRef={setCurrentRef}/>
          <LessonLogs context={'student'} isHomeWork={true} hash={hash}/>
        </div>
      </div>
      :
      <div className={st.lessonStudent}>
        <LessonToolbar/>
        <div className={st.bookContainer}>
          <BookPaper>{errorStatus === 404 ?
            <div className={st.noExercises}>
              <div>
                <img src={noExercisesImg} alt=""/>
              </div>
              <p>404</p>
              <p>&nbsp;</p>
              <p>Homework not found</p>
            </div> : <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(HomeworkStudent)
