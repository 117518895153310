import React from 'react';
import { observer } from 'mobx-react';
import swal from 'sweetalert';
import SurfMenu from 'components/ContentBox/Groups/components/SurfMenu';
import { dateFormat } from 'helpers/date';
import slotsBookedScheduleStore, { DataType } from 'Stores/slotsBookedScheduleStore';
import styles from './CustomerTabs.module.scss';

const actionList = [
  { name: 'Отправить всем SMS', action: 'phone', permission: ['forward'] },
  { name: 'Отправить всем e-mail', action: 'email', permission: ['forward'] },
];

function CustomerTabs() {
  const { activeTab, selectedDate, filteredData, setData, sendNotification } = slotsBookedScheduleStore;

  const tabs = [
    { name: 'Подтвержденные', link: 0, count: filteredData[0]?.length || 0 },
    { name: 'Ожидают оплаты', link: 1, count: filteredData[1]?.length || 0 },
    { name: 'Удаленные', link: 2, count: filteredData[2]?.length || 0 },
  ];

  const handleChangeType = (type) => {
    setData(type, DataType.activeTab);
  };
  const handleAction = (target) => {
    const data = {
      target,
      slot_client: filteredData[activeTab].map(({ id }) => id),
    };

    const type = target === 'email' ? 'e-mail' : 'смс';

    swal(
      '',
      `Вы действительно хотите отправить ${type} всем клиентам, записанным ${dateFormat(selectedDate, 'dd MMMM YYYY г.')}?`,
      'warning',
    )
      .then((response) => {
        if (!response) {
          return;
        }
        sendNotification(
          data,
          () => swal('', 'Сообщения успешно отправлены', 'success'),
          (error) => swal('Ошибка при отправке сообщений', error, 'warning'));
      });
  };

  return (
    <div className={styles.wrapper}>
      <SurfMenu
        changeType={handleChangeType}
        type={activeTab}
        menu={tabs}
        menu_add={actionList}
        action_add={handleAction}
        button
      />
    </div>
  );
}

export default observer(CustomerTabs);
