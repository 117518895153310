import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import Select from 'components/UI/Select/Select';
import WorkingHoursNotice from 'components/Modules/Slots/components/WorkingHoursNotice/WorkingHoursNotice';
import { DataType, ActionType } from 'Stores/slotsNewRecordOnlineStore';
import styles from './MainSelectors.module.scss';

function MainSelectors({ isLoading, store, className = '' }) {
  const {
    locations,
    ages,
    programs,
    selectedLocation,
    selectedProgram,
    selectedAge,
    setData,
    resetData,
  } = store;
  const [workingHours, setWorkingHours] = useState(null);

  const handleSelectChange = (option, type) => {
    resetData(ActionType.resetAll);
    if (type === DataType.selectedLocation) {
      setWorkingHours(option);
    }
    setData(option, type);

  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Paper classes={styles.inner}>
        <Select
          placeholder='Локация'
          isLoading={isLoading}
          isDisabled={isLoading}
          options={locations}
          onChange={(option) => handleSelectChange(option, DataType.selectedLocation)}
          value={selectedLocation}
          classes={styles.select}
        />
        <Select
          placeholder='Программа обучения'
          isLoading={isLoading}
          isDisabled={isLoading}
          options={programs}
          onChange={(option) => handleSelectChange(option, DataType.selectedProgram)}
          value={selectedProgram}
          classes={styles.select}
        />
        <Select
          placeholder='Возраст клиента'
          isLoading={isLoading}
          isDisabled={isLoading}
          options={ages}
          onChange={(option) => handleSelectChange(option, DataType.selectedAge)}
          value={selectedAge}
          classes={styles.select}
        />
        { workingHours &&
        <WorkingHoursNotice
          endAt={workingHours.end_time}
          timeZone={workingHours.gmt}
          startAt={workingHours.start_time}
          address={workingHours.gmt_name}
          fullAddress
        />
        }
      </Paper>
    </div>
  );
}

export default observer(MainSelectors);
