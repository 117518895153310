import React, {useEffect, useState} from 'react'
import {CopyToClipboard} from "react-copy-to-clipboard";
import {localisationMaster} from "Localisation/Localisation";
import {classNames as cn} from "helpers";

const TagQuestion = (props) => {
    const initState = [{
        tag: 'TAG#1',
        variants: ''
    }];
    const [tags, settags] = useState(initState);
    let Link = 'tests/questions'
    let Tag = 'text_missing_words'

    const changeTagHandler = (value, index) => {
        const list = [...tags];
        list[index].variants = value;
        settags([...list]);
    }

    const addTag = () => {
        const list = [...tags];
        list.push({
            tag: `TAG#${list.length + 1}`,
            variants: ''
        })
        settags([...list])
    }

    const varBody = (str) => {
        let a = str.split(',')
        let m = a.map((el, index) => ({is_correct: index === 0, name: el}))
        return m
    }

    const deleteTag = (tagName) => settags(tags.filter(x => x.tag !== tagName))

    useEffect(() => {
        if (tags.length < 1) return;
        let res = tags.map((el) => ({
            tag: el.tag,
            variants: varBody(el.variants)
        }))
        props.onChange(res)
    }, [tags])

    useEffect(() => {
       if(!props.value) return;
       let list = props.value.map((el, index) => ({
           tag: el.tag,
           variants: el.variants.map(el => el.name).join(',')
       }))
       settags([...list])
    }, []);

    return (
        <div className="modal-question-tags">
            <div className="modal-question-tags-text">
                <p className="modal-question-rules">
                    <span className="bold">{localisationMaster('text-info-two', Link, Tag)}</span>
                </p>
                <p className="modal-question-rules">
                    {localisationMaster('text-info-three', Link, Tag)}</p>
            </div>
            <div>
                {tags.map((el, index) => (
                     <div className="modal-question-tags-inputs" key={index}>
                     <CopyToClipboard text={el.tag}>
                         <input type="text" value={el.tag}/>
                     </CopyToClipboard>
                     <input name={el + index}
                            type="text" placeholder={localisationMaster('textarea-placeholder-two', Link, Tag)}
                            value={el.variants}
                            onChange={(e) =>changeTagHandler(e.target.value, index)}
                            className={cn({ 
                                "super-modal__input--error": props.error && props.value[index]?.variants.some(x => !x.name.length)
                            })}
                     />
                     <div className="modal-question-tags-close" onClick={() => tags.length > 1 && deleteTag(el.tag)}>&#215;</div>
                 </div>
                ))}
            </div>
            <div className="modal-question-add">
                <div className="modal-question-add-button-add" onClick={addTag}>+</div>
            </div>
        </div>
    )
};

export default TagQuestion

