import React, { Fragment } from 'react';
import styles from './Group.module.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { LanguageContext } from '../../constants';
import Stores from 'Stores';
import { useContext } from 'react';

const Group = ({ name, number, status, company, program_type, location, ws_schedules, id, discipline, studentStatus }) => {
    const { language, STATUSES_LIST, weekdays, translate } = useContext(LanguageContext);
    const statusType = STATUSES_LIST.find(item => item.types.includes(status.id));
    const langName = language === 'ru' ? 'name' : `name_${language}`;

    return (
        <div className={`${styles.box} ${styles[statusType.type]}`}>
            <div className={styles.box__top}>
                <div className={styles.box__title}>
                    <Link to={`/groups/${Stores.isClient ? 'my-' : ''}group/${id}`}>{number} - {name}</Link>
                    {studentStatus && <span className={styles.status}>{studentStatus}</span>}
                </div>
                <span className={styles.box__company}>{company.name}</span>
            </div>
            <div className={styles.box__middle}>
                <p>{program_type?.name}</p>
                <span>|</span>
                <p>{discipline?.name}</p>
                {ws_schedules?.teachers?.map(teacher => 
                    <Fragment key={teacher.id}>
                        <span>|</span>
                        <p className={styles.teacher}>
                            {Stores.isClient 
                                ? teacher.full_name
                                : <Link to={`/account/${teacher.user_id}`}>{Stores.transliterate(teacher.full_name)}</Link>
                            }
                        </p>
                    </Fragment>
                )}
            </div>
            <div className={styles.box__bottom}>
                <p>
                    {location[langName] || location.name} {' '}
                    {ws_schedules?.classroom?.name?.length > 0 && <>({translate.classroom}: {ws_schedules?.classroom.name})</>}
                </p>
                <span>|</span>
                <p>{ws_schedules?.start_at?.slice(0, 5)} - {ws_schedules?.finish_at?.slice(0, 5)}</p>
                <span>|</span>
                <p>{ws_schedules?.weekdays?.map(item => weekdays[item - 1].name).join('/')}</p>
                <span>|</span>
                <p>{ws_schedules?.online ? translate.online : translate.offline}</p>
            </div>
        </div>
    )
}

export default Group;