import React from 'react';
import { ReactComponent as SearchIcon } from 'components/UI/icons/curved-search.svg';
import styles from './SearchField.module.scss';
import Dropdown from './Dropdown/Dropdown';
import { LanguageContext } from 'components/HeadBox/HeadBox';
import { useContext } from 'react';

const SearchField = ({ 
    className,
    value,
    onChange,
    options, 
    current,
    setCurrent
}) => {
    const { translate } = useContext(LanguageContext);
    return (
        <div className={`${styles.searchField} ${className}`}>
            <SearchIcon
                className={styles.searchField__icon}
            />
            <div>
                <input
                    type="text"
                    className={styles.searchField__input}
                    value={value}
                    onChange={onChange}
                    placeholder={translate.search_placeholder}
                />
            </div>
            <Dropdown
                className={styles.searchField__dropdown}
                options={options}
                current={current} 
                setCurrent={setCurrent}
            />
        </div>
    );
};

export default SearchField;