import React, {useCallback, useEffect, useState} from 'react';
import {permissions} from 'settings/permissions';
import {checkPermList} from 'helpers';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import EbookBookStore from "Stores/ebookBookStore"
import ChapterCard from "./components/ChapterCard";
import st from "./RegisterListing.module.scss"
import RegisterSections from './components/RegisterSections';
import {ReactComponent as NoRegister} from "../../../ContentBox/img/noRegister.svg"
import Loader from "components/UI/Loader/Loader";
import asideModalStore from "Stores/asideModalStore";

const RegisterListing = ({id, translate}) => {
  const [registerItems, setRegisterItems] = useState([])
  const [registersSections, setRegistersSections] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {book} = EbookBookStore

  const getRegisterItems = useCallback(() => {
    const apiConf = {
      method: 'get',
      link: `ebooks/ebook_registers_ebook/${id}`,
      params: {
        limit: 999,
      }
    }
    superAxiosRequest(apiConf).then((response) => {
      setRegisterItems(response.data)
    }).catch(() => {

    }).finally(() => {
      setIsLoading(false)
    })
  }, [id])

  const getRegistersChapters = useCallback(() => {
    superAxiosRequest({
      method: 'get',
      link: `ebooks/ebook_register_chapters`,
      params: {
        ebook_id: id,
        limit: 999,
      }
    })
      .then(res => {
        setIsLoading(false)
        setRegistersSections(res.data.result.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)))
      })
      .finally(() => setIsLoading(false))

  }, [id])

  useEffect(() => {
    getRegisterItems()
    getRegistersChapters()
  }, [getRegisterItems, getRegistersChapters])

  const createRegister = () => {
    const ms = {
      type: 'generator',
      fields: [
        {
          type: 'text',
          name: 'name',
          label: `${translate.lesson_name}:`,
          placeholder: translate.lesson_name
        },
        {
          type: 'select',
          name: 'registerchapters_id',
          label: `${translate.register_sections}:`,
          placeholder: translate.register_sections,
          options: registersSections,

        },
        {
          type: 'textarea',
          name: 'lesson_plan',
          label: `${translate.description}:`,
          placeholder: translate.description,
        },
        {
          type: 'textarea',
          name: 'homework',
          label: `${translate.homework}:`,
          placeholder: translate.homework,
        },

      ],
      valid: {
        name: 'required',
        registerchapters_id: 'required',
        lesson_plan: 'required',
        homework: 'required'
      },
      link: `ebooks/ebook_registers`,
      title: translate.add_new_register,
      button: translate.save,
      onSubmitSuccess: getRegisterItems
    }
    const md = {
      ebook_id: id,
    }
    asideModalStore.activity(ms, md)
  }

  const handlerDeleteSection = (id) => {
    setRegistersSections(registersSections.filter(item => item.id !== id))
  }

  const handlerChangeSection = (newSectionData) => {
    let newState = [...registersSections]
    newState = newState.filter(item => item.id !== newSectionData.id)
    newState.push(newSectionData)
    setRegistersSections(newState.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)))
  }

  const handlerAddSection = (newChapter) => {
    setRegistersSections([newChapter, ...registersSections])
  }
  return (
    isLoading ? <Loader/> : book.id &&
      <div className={st.page}>
        <RegisterSections
          translate={translate}
          handlerChangeSection={handlerChangeSection}
          handlerDeleteSection={handlerDeleteSection}
          handlerAddSection={handlerAddSection}
          ebook_id={id} data={registersSections}/>
        <div className={st.lessons}>
          <div className={st.header}>
            <div>{translate.registers}: {registerItems.length}</div>
          </div>
          {registerItems.length > 0 ? registerItems.map((item, index) =>
            <ChapterCard key={index}
                         chapter={item}
                         registersSections={registersSections}
                         getRegisterItems={getRegisterItems}
                         createRegister={createRegister}
                         translate={translate}
                         registerItems={registerItems}
            />) : <div className={st.noRegister}>
            <NoRegister/>
          </div>}
          {(checkPermList(permissions.add_lesson)) &&
            <div className={st.btn}>
              <button className={registerItems.length > 0 ? st.addChapterButton : st.addChapterButtonNoChapters}
                      onClick={() => createRegister()}>
                {translate.add_new_register}
              </button>
            </div>
          }
        </div>
      </div>
  )
}

export default observer(RegisterListing)
