import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {ExcelRowsParams} from "components/utils/ExcelExport";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import Filter from 'components/ContentBox/Global/Filter/Filter'
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";


const DroppedStudents = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        setFilter,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();

    const [catalog, setCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: catalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },
        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 2, name: "Все группы"},
                {id: 1, name: "Онлайн"},
                {id: 0, name: "Офлайн"},
            ]
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень курса',
            values: catalog?.course_levels,
            sort_key: 'name',
            nameState: 'course_level'
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: catalog?.skill_levels,
            sort_key: 'name',
            nameState: 'skill_level'
        },
        {
            type: "select-custom",
            is_multi: true,
            placeholder: "Статус студента",
            nameState: "student_status",
            values: [
                {id: 2, name: "Закончил обучение"},
                {id: 3, name: "Прервал обучение"},
                {id: 4, name: "Без занятий"},
                {id: 5, name: "Удалённый"}
            ]
        },


    ]

    const cols = [
        {
            name: 'Ф.И.О. студента',
        },
        {name: 'Школа', rowSpan: 3},
        {name: 'Группа', rowSpan: 3},
    ];

    const findLocationName = ({result, locations}) =>  {
        setData(result.map(row => ({...row, location_name: locations.find(loc => loc.id === row.group.location_id).name})))
    }

    const getFilteredData = params => {
        setIsLoading(true)

        const apiConf = {
            method: 'get',
            link: 'groups/student_status_changes',
            params: {
                //...params,
                ...(!!params.location?.length && {location: `[${params.location.join()}]`}),
                ...(params.date_from && {date_from: dateFormat(params.date_from, "YYYY-mm-dd")}),
                ...(params.date_until && {date_until: dateFormat(new Date(params.date_until.getFullYear(), params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")}),
                ...(params.online && {online: params.online}),
                ...(params.course_level && {course_level: `[${params.course_level.join()}]`}),
                ...(params.skill_level && {skill_level: `[${params.skill_level.join()}]`}),
                ...(params.student_status) && {student_status: `[${params.student_status.join()}]`}
            }
        }

        superAxiosRequest(apiConf).then(res => {
            findLocationName(res.data)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const downloadExcel = data => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Ф.И.О. студента",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Школа",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Группа",
                    ...ExcelRowsParams.headerRowParam
                }
            ]

            const tableData = data.map(row => [
                {
                    v: `${row.student.name}`,
                    ...ExcelRowsParams.tableRowParam.firstCell
                },
                {
                    v: `${row.location_name}`,
                    ...ExcelRowsParams.tableRowParam.cell
                },
                {
                    v: `${row.group.name}`,
                    ...ExcelRowsParams.tableRowParam.cell
                },
            ])

            const footerRow = [
                {
                    v: `ИТОГО ВЫБЫВШИХ СТУДЕНТОВ`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: `${data.length}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ]

            const merges = []

            const cols = [
                {wch: 40},
                {wch: 30},
                {wch: 50},
            ]
            const rows = [
                {hpx: 30},
            ]

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = cols
            ws['!rows'] = rows
            ws["!merges"] = merges

            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Выбывшие студенты.xlsx");
        })
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'groups/student_status_changes'
        }).then(({data}) => {
            setCatalog(data)
            setIsLoading(false)
        }).catch(() => setIsLoading(false));
    }, [])

    return (
        <div>
            <ContentHeader title="Выбывшие студенты" />
            <Filter config={filterCnf} getFilterParams={getFilteredData} ddrop={() => {setFilter({})}} thisPage={null} />

            {isLoading ? <Loader /> : data && data.length > 0 ?
                <>
                    <div className={st.tableWrapper} ref={refWrapper}>
                        <div ref={refSticky} className={st.sticky}>
                            <table className={st.table}>
                                <THead cols={cols}/>
                            </table>
                        </div>
                        <table className={`${st.table} ${st.tbodyStriped}`}>
                            <THead cols={cols} ref={refThead}/>
                            <tbody className={st.tbody}>
                            {Array.isArray(data) &&
                              <>
                                 <tr className={st.bdt}>
                                      <td className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>
                                          Итого выбывших студентов
                                      </td>
                                      <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                                          {data.length}
                                      </td>
                                      <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}/>
                                  </tr>
                                  {data.map((row, rowIdx) =>
                                    <tr key={rowIdx} className={st.bdt}>
                                        <td className={st.fw600} style={{background: "white"}}>
                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                <NavLink to={'/account_clients/' + row.student.user_id} target="_blank">
                                                    {row.student.name}
                                                </NavLink>
                                            </div>
                                        </td>
                                        <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                {row.location_name}
                                            </div>
                                        </td>
                                        <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                            <div className={`${st.groupDescription} ${st.bottom}`}>
                                                <NavLink to={'/groups/group/' + row.group.id} target="_blank">
                                                    {row.group.name}
                                                </NavLink>
                                            </div>
                                        </td>
                                    </tr>
                                  )}
                                 
                              </>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div style={{padding: '10px'}}>
                        <ExportExcelButton data={data} downloadExcel={downloadExcel}/>
                    </div>
                </> : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </div >
    )
}

export default DroppedStudents
