import React, {useState} from 'react';
import Stores from "Stores";    
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import Flex from 'components/UI/Flex/Flex';
import Button from 'components/UI/Button/Button';
import ModalWrapper from 'components/UI/ModalWrapper/ModalWrapper';
import Select from 'components/UI/Select/Select';
import Hr from 'components/UI/Hr/Hr';
import TextArea from 'components/UI/TextArea/TextArea';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import {findOption} from 'components/Modules/Slots/utils';
import slotsAdvancedTeachersHistoryStore from 'Stores/slotsAdvancedTeachersHistoryStore';
import styles from 'components/Modules/Slots/TeacherSchedule/components/BookedSlots/BookedSlots.module.scss';
import {observer} from "mobx-react";

function TimeTableSlotModal({
    id,
    type,
    slot_date,
    slotType,
    zoomUrl,
    employee_id,
    isComment,
    isOnline,
    slot_id,
    isSchool,
    isGroup,
    comment,
    onClose,
    slotTypes,
    updateTimeSlot,
    deleteTimeSlot,
    createZoomLink,
    propsSelectedLocation
}) {
    const {selectedLocation} = slotsAdvancedTeachersHistoryStore;
    const options = slotTypes.map((item) => ({label: item.name, value: item.id, ...item}));
    const [selectedSlot, setSelectedSlot] = useState(findOption(slotType, options));
    const [newComment, setNewComment] = useState(type === 'delete' ? '' : comment);
    const [isOnlineToggle, setIsOnlineToggle] = useState(isOnline);
    const [isSchoolToggle, setIsSchoolToggle] = useState(isSchool);
    const [isGroupToggle, setIsGroupToggle] = useState(isGroup);
    const [newZoomUrl, setNewZoomUrl] = useState(zoomUrl);
    isComment = type === 'delete' ? true : selectedSlot.is_comment;
    const handleToggles = ({target}) => {
        const {name} = target;
        switch (name) {
            case 'is-online':
                if (isOnlineToggle && !isSchoolToggle) {
                    setIsSchoolToggle(true);
                }
                setIsOnlineToggle(!isOnlineToggle);
                break;
            case 'is-school':
                if (isSchoolToggle && !isOnlineToggle) {
                    setIsOnlineToggle(true);
                }
                setIsSchoolToggle(!isSchoolToggle);
                break;
        }
    };

    const handleZoomButtonClick = () => {
        if (newZoomUrl) {
            navigator.clipboard.writeText(newZoomUrl)
                .then(() => swal('', 'Ссылка на ZOOM скопирована', 'success'));
        } else {
            createZoomLink(
                id,
                employee_id,
                (data) => swal('', 'Ссылка на ZOOM успешно создана', 'success').then(() => setNewZoomUrl(data)),
                () => swal('', 'Не удалось создать ссылку на ZOOM', 'warning'),
            )
        }
    };

    const handleSubmit = () => {
        const data = {
            id,
            slot_date,
            employee_id,
            type: selectedSlot.value,
            is_comment: isComment,
            is_online: isOnlineToggle,
            is_school: isSchoolToggle,
            group_clients: isGroupToggle,
            location: selectedLocation ? selectedLocation.id : propsSelectedLocation.id,
            slot: slot_id,
            ...(zoomUrl && {zoom_url: zoomUrl})
        };
        if (newComment) {
            data.comment = newComment;
        }
        const onSuccess = () => onClose();
        const onFail = () => swal(`Ошибка ${type === 'delete' ? 'удаления' : 'сохранения'}`, 'Повторите позже', 'warning');
        type === 'delete'
            ? deleteTimeSlot(data, onSuccess, onFail)
            : updateTimeSlot(data, onSuccess, onFail);
    };

    return (
        <ModalWrapper
            title={type === 'delete' ? 'Удаление слота' : 'Изменение типа слота'}
            onClose={onClose}
        >
            {type === 'change' &&
                <Select
                    label='Выберите тип слота'
                    placeholder='Выберите тип слота'
                    value={selectedSlot}
                    options={options}
                    onChange={(option) => setSelectedSlot(option)}
                />
            }
            <TextArea
                label={type === 'delete' ? 'Комментарий причины удаления' : 'Комментарий'}
                onChange={(evt) => setNewComment(evt.target.value)}
                value={newComment}
                placeholder='Комментарий'
                name='slot-comment'
                isError={isComment && !newComment}
                isResize={false}
            />
            {type === 'change' &&
                <>
                    <InputToggle
                        selected={isOnlineToggle}
                        onChange={handleToggles}
                        name='is-online'
                        label='Онлайн'
                    />
                    <InputToggle
                        selected={isSchoolToggle}
                        onChange={handleToggles}
                        name='is-school'
                        label='В школе'
                    />
                    <InputToggle
                        selected={isGroupToggle}
                        onChange={() => setIsGroupToggle(!isGroupToggle)}
                        name='is-group'
                        label='Групповой слот'
                    />
                    <Flex>
                        <span className={styles.zoom_link}>
                            {newZoomUrl ? newZoomUrl : 'Ссылка не создана'}
                        </span>
                        <Button classes={styles.modal_button} variant='accent' onClick={handleZoomButtonClick}>
                            {newZoomUrl ? 'Скопировать' : 'Создать'}
                        </Button>
                    </Flex>
                </>
            }
            <Hr light withTopMargin={false} withBottomMargin={false} />
            <Flex justify='end' withGap>
                <Button classes={styles.modal_button} onClick={onClose} variant="white">Отменить</Button>
                <Button
                    classes={styles.modal_button}
                    onClick={handleSubmit}
                    isDisabled={isComment && !newComment}
                >
                    Сохранить
        </Button>
            </Flex>
        </ModalWrapper>
    );
}

TimeTableSlotModal.propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['change', 'delete']).isRequired,
    slotType: PropTypes.number.isRequired,
    isComment: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    isSchool: PropTypes.bool.isRequired,
    isGroup: PropTypes.bool,
    zoomUrl: PropTypes.string,
    comment: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

TimeTableSlotModal.defaultProps = {
    isGroup: false,
};

export default observer(TimeTableSlotModal);