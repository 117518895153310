import React from 'react';
import {ReactComponent as StudentReportsIcon} from "../../../../../ContentBox/img/studentIcon.svg";
import {ReactComponent as TeacherReportsIcon} from "../../../../../ContentBox/img/teacherIcon.svg";
import style from './AverageProgressReport.module.scss'
import RatingStars from "./RatingStars/RatingStars";
import useLocalization from 'hooks/useLocalization';

const AverageProgressReport = ({activeMonth,type, teacherName, averageRatingPerMonth}) => {

    const {translate} = useLocalization({
        section: 'groups',
        subsection: 'group_rating_select',
        dict: {
            "average_progress_report": "Average Progress Report"
        }
      })

    return (
        <div className={style.container}>
            <div className={style.main}>
                {
                    type === 'student' ? <StudentReportsIcon/> : <TeacherReportsIcon/>
                }
                <div className={style.info}>
                    <span className={style.date}>{activeMonth}</span>
                    <span>{translate.average_progress_report}</span>
                    {
                        teacherName && <span>{teacherName}</span>
                    }
                </div>
            </div>
            <RatingStars rating={averageRatingPerMonth}/>
        </div>
    );
};

export default AverageProgressReport;