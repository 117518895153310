import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import styles from './VideoPlayer.module.scss';

export default function VideoPlayer(props) {
    const videoRef = useRef(null);
    const { innerWidth } = window;
    const isMobile = innerWidth < 768;

    useEffect(() => {
        if (isMobile) {
            new Plyr(videoRef.current, {
                controls:['play','progress','current-time', 'mute', 'settings']
            })
        }else  {
          new Plyr(videoRef.current)
        }

    }, []);

    return (
        <div className={styles.playerContainer}>
            <h4>{props.videoName}</h4>
            <video ref={videoRef} controls>
                <source src={props.v_link} />
            </video>
        </div>
    );
}