import styles from './TimeTableSlot.module.scss';
import React, { useRef, useState} from 'react';
import TimeTableTooltip from './Tooltip/TimeTableTooltip';
import TimeTableMenu from './Menu/TimeTableMenu';
import BookedSlotModal from '../../AdvancedTeachersHistory/components/CustomerList/BookedSlotModal';
import TimeTableSlotModal from './TimeTableSlotModal';




const TimeTableSlot = ({slotData, rowId, totalRows, updateTimeSlot, deleteTimeSlot, createZoomLink, }) => {
    const [isTooltipShown, setIsTooltipShown] = useState(false)
    const [isMenuShown, setIsMenuShown] = useState(false)
    const [modalData, setModalData] = useState({isShow: false})
    const refTarget = useRef()
    const [tooltipStyles, setTooltipStyles] = useState()
    const [menuStyles, setMenuStyles] = useState()
    const getStyles = (targetRect, type) => {
        let blocksWidth = {
            tooltip: 95,
            menu: 80,
        }
        let sidePanelWidth = window.document.documentElement.clientWidth > 766 ? 107 + blocksWidth[type] : 0
        let adaptiveWidth = window.document.documentElement.clientWidth > 766 ? 0 : 7 + blocksWidth[type]
        let leftCoordinate = (window.document.documentElement.clientWidth < 1170) ? (targetRect.left - sidePanelWidth -adaptiveWidth + Math.floor((slotData.durationMinutes - 4) / 2)) : (
            (targetRect.left - ((window.document.documentElement.clientWidth - 1170) / 2)) - sidePanelWidth + Math.floor((slotData.durationMinutes - 4) / 2)
        )
        let topCoordinate = (totalRows - (rowId + 1)) * (-40)
        let leftBefore = blocksWidth[type] - 14
        let leftAfter = blocksWidth[type] - 14
        if (leftCoordinate < 0) {
            leftBefore = leftBefore + leftCoordinate
            leftAfter = leftAfter + leftCoordinate
            leftCoordinate = 0
            if (leftBefore < 0) {
                leftBefore = 1
                leftAfter = 0
            }
        }
        if (leftCoordinate > (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth))) {
            leftBefore = leftBefore + (leftCoordinate - (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)))
            leftAfter = leftAfter + (leftCoordinate - (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)))
            leftCoordinate = window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)
            if (leftBefore > (window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth))) {
                leftBefore = window.document.documentElement.clientWidth - (blocksWidth[type] - 1 + sidePanelWidth)
                leftAfter = window.document.documentElement.clientWidth - (blocksWidth[type] + sidePanelWidth)
            }
        }

        return ({
            left: `${leftCoordinate}px`, top: `${topCoordinate}px`,
            "--leftBefore": `${leftBefore}px`, "--leftAfter": `${leftAfter}px`
        })
    }
    const showHandler = () => {
        if (!isMenuShown) {
            const targetRect = refTarget.current.getBoundingClientRect()
            setTooltipStyles(getStyles(targetRect, 'tooltip'))
            setIsTooltipShown(true)
        }
    }
    const hideHandler = () => {
        setIsTooltipShown(false)
    }
    const handleMenuClick = (type) => {
        if (type) {
            if (type === "restore") {
                updateTimeSlot({
                    id: slotData.id,
                    is_active: true,
                    employee_id:slotData.employee_id,
                    slot: slotData.slot_id,
                    slot_date:slotData.slot_date,
                    type: slotData.slot_type_id,
                })
            }
            else setModalData({
                isShow: true,
                type: type
            })
        }
        setIsMenuShown(false)

    }
    const handleOnClick = () => {
        const targetRect = refTarget.current.getBoundingClientRect()
        setMenuStyles(getStyles(targetRect, 'menu'))
        setIsTooltipShown(false)
        setIsMenuShown(true)

    }
   
    const handleSubmit = (evt) => {
        evt.preventDefault();
        setModalData({isShow: false});
    }

    const handleClose = () => {
        setModalData({isShow: false})
    }
    return (
        <>
            <div  onMouseEnter={() => showHandler()} onMouseOut={() => hideHandler()}
                onClick={() => handleOnClick()}
                className={styles.timeTableSlot} style={{
                    backgroundColor: slotData.color, width: `${slotData.durationMinutes - 4}px`,
                    left: `${Math.floor((slotData.timeStart - slotData.workStartTime) / 60000) + 2}px`,
                    border: slotData.border
                }}
                ref={refTarget}
            >
            </div>
            {isTooltipShown &&
                <TimeTableTooltip
                    timeStart={slotData.timeStart}
                    timeEnd={slotData.timeEnd}
                    propsStyles={tooltipStyles}
                    totalRows={totalRows}
                    rowId={rowId}
                    text={slotData.typeName}
                    isAvailable={slotData.isAccessible}
                    isOnline={slotData.isOnline}
                    isSchool={slotData.isSchool}
                    isGroup={slotData.group_clients}
                    isLink={Boolean(slotData.zoom_url)}
                />
            }
            {isMenuShown && <TimeTableMenu
                propsStyles={menuStyles}
                isDeleted={!slotData.is_active}
                onClick={handleMenuClick}
            />
            }
            {modalData.isShow &&
                <TimeTableSlotModal
                    slotTypes={slotData.slotTypes}
                    id={slotData.id}
                    type={modalData.type}
                    slotType={slotData.slot_type_id}
                    isComment={slotData.is_comment}
                    isOnline={slotData.isOnline}
                    isSchool={slotData.isSchool}
                    isGroup={slotData.group_clients}
                    zoomUrl={slotData.zoom_url}
                    comment={slotData.comment}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                    slot_date={slotData.slot_date}
                    employee_id={slotData.employee_id}
                    slot_id={slotData.slot_id}
                    updateTimeSlot={updateTimeSlot}
                    deleteTimeSlot={deleteTimeSlot}
                    createZoomLink={createZoomLink}
                />
            }

        </>


    )
}
//timeTable__wrapper_tooltip
export default TimeTableSlot;