import React from 'react';
import {agetostr, timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import {dateFormat} from "helpers/date";
import GeneralPoints from "./GeneralPoints";
import ResultSections from "./ResultSections/ResultSections";
import './TestResult.scss'
import {ReactComponent as TrueAnswer} from 'components/ContentBox/img/true.svg';
import {ReactComponent as WrongAnswer} from 'components/ContentBox/img/false.svg';

const TestResult = ({
    name,
    student,
    answers,
    created_at,
    status,
    stats,
    test_sections,
    beginTest,
    isBigResult,
    isAgain,
}) => {
    return (
        <div className="strBox">
            <header className="strBox__header">
                <h1>Результат письменного тестирования <span>{name}</span></h1>
                <div className="strBox__student">
                    <b>{student.last_name} {student.first_name}</b>
                    {student.date_of_birth &&
                    <p>{agetostr(student.date_of_birth)}, ({timeConverter(student.date_of_birth, 'age')})</p>}
                </div>
            </header>

            <div className="strBox__testInfo">
                <GeneralPoints answers={answers} stats={stats}/>

                <div className="strBox__testData">
                    <div>
                        <span>Результат теста отправлен:</span>
                        <b>{student.email}</b>
                    </div>
                    <div>
                        <span>Дата прохождения теста:</span>
                        <b>{dateFormat(created_at, 'd MMMM YYYY г.')}</b>
                    </div>
                </div>

                <div className="strBox__buttons">
                    <div className="strBox__btn" onClick={() => window.location.href = '/c/study/my-tests'}>На главную</div>
                    <div>
                        {!!isAgain &&
                        <div className="strBox__btn" onClick={() => isAgain && beginTest()}>Пройти заново</div>}
                    </div>
                </div>
            </div>

            {status !== "finished" &&
            <div className="strBox__error">
                <p>Вы не прошли!</p>
                <p>{status === "auto_proctor" ? 'Во время тестирования системой автопрокторинга были выявлены нарушения.' : 'Время действия тестирования истекло!'}</p>
            </div>}

            <div className="strBox__sections">
                <ResultSections sections={test_sections} answers={answers}/>
            </div>

            {!isBigResult ? 
            <div className="strBox_results">
                <div className="strBox_tr">Таблица результатов</div>
                <div className="strBox_resultsList">{answers.map((el, index) =>
                    Array.isArray(el.correct_answers) ?
                        <div className="strBox_results_abox" key={index}>
                            <span>{index + 1}</span>
                            <span>{el.correct_answers.map((el, y) => el ?
                                <TrueAnswer key={y}/> :
                                <WrongAnswer key={y}/>)}
                            </span>
                        </div>
                        :
                        <div className="strBox_results_abox" key={index}>
                            <span>{index + 1}</span>
                            <div>
                                {el.correct_answers ? <TrueAnswer/> : <WrongAnswer/>}
                            </div>
                        </div>)}
                </div>
            </div> : null}
        </div>
    )
}

export default TestResult
