import React from 'react';
import { permissions } from "settings/permissions";
import { dateFormat } from "helpers/date";
import { checkPermList, classNames } from 'helpers/index'
import Stores from "Stores";
import st from './InvoiceTicket.module.scss'
import {localisationMaster} from "../../../Localisation/Localisation";

export default function InvoiceTicket(props) {
    const order = props.order

    const renderStatusDate = () => {
        switch (order.status_order) {
            case 'not_paid':
                return <span>{localisationMaster('pay_before', 'pays/invoice')}: <b>{dateFormat(new Date(order.payment_expires), "dd MMMM YYYY г.")}</b></span>
            case 'paid':
                return <span>{localisationMaster('pay-answer', 'pays/payment/answer')}: <b>{dateFormat(new Date(order.payment_date), "dd MMMM YYYY г.")}</b></span>
            case 'cancelled':
                return <span>Отменен: <b>{dateFormat(new Date(order.updated_at), "dd MMMM YYYY г.")}</b></span>
            default:
                return ''
        }
    }

    return (
        <div>
            <div className={classNames({
                [st.header]: true,
                [st.headerPaid]: order.status_order === 'paid' || order.payment_confirmed,
                [st.headerNotPaid]: order.status_order === 'not_paid',
                [st.headerCancelled]: order.status_order === 'cancelled',
            })}>
                <div>
                    <div>{order.status_text} {
                        (order.status_order === 'not_paid' && new Date(order.payment_expires) > new Date()) &&
                        <>
                            {props.timer < 1 ?
                                <span className={st.updateStatus} onClick={props.checkOrder}>
                                    {localisationMaster('status_1', 'index', 'sidebar_main')}
                                </span>
                                :
                                <span className={'slot_blink notification'}>
                                    {localisationMaster('checking_invoice_status', 'pays/invoice')}...
                                </span>}
                        </>
                    }
                    </div>

                    <div className={st.statusDate}>{renderStatusDate()}</div>
                </div>

                <div>
                    {`${Stores.cleanSumm(order.amount / 100)} ${localisationMaster('tg', 'groups/group')}`}
                </div>

                {order.status_order === 'not_paid' && checkPermList(permissions.change_invoice) &&
                <span className={st.cancelBtn} onClick={props.cancelOrder}>
                    {localisationMaster('cancel_invoice', 'pays/invoice')}
                </span>}
            </div>
            <div className={st.body}>
                <div>{localisationMaster('purpose_amount_invoice', 'pays/invoice')}:</div>
                <h3>{order.payment_type}</h3>
                <ul>
                    {[
                        localisationMaster('table-row-title-6', 'pays/all-pays'),
                        localisationMaster('total', 'pays/invoice'),
                        localisationMaster('total_payable', 'pays/invoice')
                    ].map((name, idx) =>
                        <li key={idx}>
                            <span>{name}</span>
                            <span>{`${Stores.cleanSumm(order.amount / 100)} ${localisationMaster('tg', 'groups/group')}`}</span>
                        </li>)}
                </ul>
            </div>
        </div>
    );
};