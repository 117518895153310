
export const tests = [
    {id: 1, name: 'Overall'},
    {id: 2, name: 'Kids Starter'},
    {id: 3, name: 'EOC Lite Juniors 1'},
]

export const categories = [
    {id: 1, name: 'Quick test'},
    {id: 2, name: 'MidTerm Test KET'},
    {id: 3, name: 'Final test KET'},
    {id: 4, name: 'EF'},
    {id: 5, name: 'Основная'},
    {id: 6, name: 'MidTerm Test PET'},
    {id: 7, name: 'Final test PET'},
    {id: 8, name: 'MidTerm Test FCE'},
    {id: 9, name: 'CE'},
    {id: 10, name: 'Midterm test Level 1'},
    {id: 11, name: 'Final test level 1'},
    {id: 12, name: 'Final test kids starter'},
    {id: 13, name: 'Final test kids 5'},
    {id: 14, name: 'Final test kids 6'},
    {id: 15, name: 'Final test juniors starter'},
    {id: 16, name: 'MidTerm test kids 1'},
    {id: 17, name: 'MidTerm test kids 2'},
    {id: 18, name: 'MidTerm test kids 3'},
    {id: 19, name: 'MidTerm test kids 4'},
    {id: 20, name: 'MidTerm test kids 5'},
    {id: 21, name: 'MidTerm test kids 6'},
    {id: 22, name: 'MidTerm test juniors 1'},
    {id: 23, name: 'MidTerm test juniors 2'},
    {id: 24, name: 'MidTerm test juniors 3'},
    {id: 25, name: 'MidTerm test juniors 4'},
    {id: 26, name: 'MidTerm test juniors 5'},
    {id: 27, name: 'Final test kids 1'},
    {id: 28, name: 'Final test kids 2'},
    {id: 29, name: 'Final test kids 3'},
    {id: 30, name: 'Final test kids 4'},
    {id: 31, name: 'Final test juniors 1'},
    {id: 32, name: 'Final test juniors 2'},
    {id: 33, name: 'Final test juniors 3'},
    {id: 34, name: 'Final test juniors 4'},
    {id: 35, name: 'Final test juniors 5'},
    {id: 36, name: 'MidTerm Test Teens 1'},
    {id: 37, name: 'MidTerm Test Teens 2'},
    {id: 38, name: 'MidTerm Test Teens 3'},
    {id: 39, name: 'MidTerm Test Teens 4'},
    {id: 40, name: 'Final test Teens 1'},
    {id: 41, name: 'Final test Teens 2'},
    {id: 42, name: 'Final test Teens 3'},
    {id: 43, name: 'Final test Teens 4'},
    {id: 44, name: 'MidTerm YAs 1'},
    {id: 45, name: 'MidTerm YAs 2'},
    {id: 46, name: 'MidTerm YAs '},
    {id: 47, name: 'MidTerm YAs 4'},
    {id: 48, name: 'MidTerm YAs 5'},
    {id: 49, name: 'Final test YAs 1'},
    {id: 50, name: 'Final test YAs 2'},
    {id: 51, name: 'Final test YAs 3'},
    {id: 52, name: 'Final test YAs 4'},
    {id: 53, name: 'Final test YAs 5'},
    {id: 54, name: 'MidTerm test CAE'},
    {id: 55, name: 'Final Test CAE'},
    {id: 56, name: 'Level check'},
    {id: 57, name: 'Early warning'},
    {id: 58, name: 'Final Test online'},
    {id: 59, name: 'Mid-term test online'},
    {id: 60, name: 'Mock Test 1'},
    {id: 61, name: 'Mock Test 2'},
    {id: 62, name: 'Midterm test juniors 1 Lite'},
    {id: 63, name: 'PET Teens 3 (MT)'},
    {id: 64, name: 'PET Teens 3 (EOC)'},
    {id: 65, name: 'Flyers Juniors 3 (MT)'},
    {id: 66, name: 'Flyers Juniors 3 (EOC)'},
    {id: 67, name: 'Flyers Juniors 4 (EOC)'},
    {id: 68, name: 'Flyers Juniors 4 (MT)'},
    {id: 69, name: 'KETfS Juniors 5 (MT)'},
    {id: 70, name: 'KETfS Juniors 5 (EOC)'},
    {id: 71, name: 'PET Teens 4 (MT)'},
    {id: 72, name: 'PET Teens 4 (EOC)'},
    {id: 73, name: 'CAE Y-A/Adults Level 5 (MT)'},
    {id: 74, name: 'PETfS Young Adults Level 3 (EOC)'},
    {id: 75, name: 'CEfS Young Adults Level 4 (EOC)'},
    {id: 76, name: 'CAE Y-A/Adults Level 5 (EOC)'},
    {id: 77, name: 'CEfS Young Adults Level 4 (MT)'},
    {id: 78, name: 'EOC LITE Adults 1,1+,2,2+,3,3+'},
    {id: 79, name: 'CE Adults 4 (midterm)'},
    {id: 80, name: 'Midterm test Teens 1 Lite'},
    {id: 81, name: 'EOC Lite Juniors1'},
    {id: 82, name: 'MidTerm Test Adults 1'},

]
