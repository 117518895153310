import React from 'react'
import {dateFormat} from "helpers/date";
import {localisationMaster} from "Localisation/Localisation";
import st from './InvoiceDetail.module.scss'

const InvoiceDetail = props =>
    <div className={st.invoiceDetail}>
        <h3>{localisationMaster('text-answer-1', 'pays/payment/answer')}</h3>
        <ul>
            <li>
                <span>{localisationMaster('text-answer-2', 'pays/payment/answer')}</span>
                <span>{props.id}</span>
            </li>
            <li>
                <span>{localisationMaster('invoice_id', 'pays/invoice')}</span>
                <span>{props.order_id}</span>
            </li>
            <li>
                <span>{localisationMaster('text-answer-3', 'pays/payment/answer')}</span>
                <span>{dateFormat(new Date(props.create_at_bank), 'YYYY-mm-dd')}</span>
            </li>
            <li>
                <span>{localisationMaster('text_32', 'modal_templates')}</span>
                <span>{props.name_bank}</span>
            </li>
        </ul>
    </div>

export default InvoiceDetail