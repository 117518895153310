import React, {useState, useEffect} from 'react';
import basketW from 'components/ContentBox/img/basket-w.svg';
import Stores from 'Stores';
import VideoThumbnail from 'react-video-thumbnail';
import {classNames as cn} from "helpers";


export default function AddSimpleMedia(props) {
    const [file, setFile] = useState([])
    const [toUpdate, setToUpdate] = useState([false, []])
    const [hover, setHover] = useState(false)

    useEffect(() => {
        props.objectChangeHandler(file, 'files')
    }, [file])

    useEffect(() => {
        if (props.update) {
            if (typeof props.values === 'string') {
                setToUpdate([true, props.values])
            } else {
                setToUpdate([...props.values])
            }
        }
    }, [])

    return (
        <div
            className={cn({
                'super-modal-simple-media': true,
                'super-modal-simple-media--error': props.error
            })}
        >
            <input id="file" name="file" type="file"
                   onChange={(e) => setFile(e.target.files)}
                   className="inputfile"
            />
            {(file?.length || toUpdate[1]?.length > 0) &&
            <div className="super-modal-simple-media__img-box"
                 onMouseOver={() => setHover(true)}
                 onMouseOut={() => setHover(false)}>
                {props.type === 'video' ?
                    <VideoThumbnail
                        videoUrl={
                            toUpdate[1].length && props.fullLink ? toUpdate[1] :
                                toUpdate[1].length ? Stores.baseLink() + toUpdate[1] :
                                    URL.createObjectURL(file[0])
                        }
                        thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                    />
                    :
                    props.type === 'audio' ?
                        <>
                            <audio
                                controls
                                src={
                                    toUpdate[1].length && props.fullLink ? toUpdate[1] :
                                        toUpdate[1].length ? Stores.baseLink() + toUpdate[1] :
                                            URL.createObjectURL(file[0])
                                }>
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                            <div className="super-modal-simple-media__img-box--delete" onClick={() => {
                                setFile([]);
                                setToUpdate([false, []])
                            }}>
                                &#215;
                            </div>
                        </>
                        :
                        <img src={
                            toUpdate[1].length > 0 && props.fullLink ? (toUpdate[0].image || toUpdate[1]) :
                                toUpdate[1].length > 0 ? Stores.baseLink() + (toUpdate[0].image || toUpdate[1]) :
                                    URL.createObjectURL(file[0])}
                             alt=""
                        />}
                <div
                    className={hover && props.type !== 'audio' ? "super-modal-simple-media__cover" : 'super-modal-simple-media__cover super-modal-simple-media--hide'}>
                    <img src={basketW} onClick={() => {
                        setFile([]);
                        setToUpdate([false, []])
                    }} alt=""/>
                </div>
            </div>
            }
            {
                (file?.length < 1 && toUpdate[1]?.length < 1) &&
                <>
                    <label className="labelFile" htmlFor="file">
                        Выберите файл
                    </label>
                    <p className="bold">
                        {props.types}
                    </p>
                </>
            }
        </div>
    )
}