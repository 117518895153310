import ClientCard from "components/Modules/User/Client/ClientProfile";
import GroupsList from 'components/Modules/Groups/GroupList/GroupsList';
import GroupListClients from 'components/ContentBox/Groups/GroupListClients';
import TeacherStatistics from '../components/ContentBox/Global/ReportTable/TeacherStatistics';
import TeacherBookedSlots from 'components/ContentBox/Online-schedule/booked-teachers-schedule/BookedTeachersSchedule.jsx';
import BookedOnlineSchedule from 'components/ContentBox/Online-schedule/booked-online-shedule/BookedOnlineSchedule.jsx';
import ExtendedTeachersSchedule
    from "components/ContentBox/Online-schedule/extended-teachers-schedule/ExtendedTeachersSchedule";
import ScheduleSetting from 'components/ContentBox/Online-schedule/schedule-setting/ScheduleSetting';
import NewRecord from 'components/ContentBox/Online-schedule/record-customer/NewRecord';
import EmployeeProfile from "components/Modules/User/Employee/EmployeeProfile";
import WorkTable from 'components/ContentBox/Company/WorkTable/WorkTable';
import ListingCards from 'components/ContentBox/Global/ListingCards/ListingCards';
import StudyTestMain from 'components/ContentBox/StudyTest/StudyTestMain';
import LessonInit from "components/ContentBox/Lessons/LessonInit";
import InitTest from 'components/Modules/Study/InitTest/InitTest';
import Course from 'components/ContentBox/Courses/Course';
import WorkedHours from "components/ContentBox/Global/ReportTable/WorkedHours";
import ClientTransactions from "../components/ContentBox/Global/ReportTable/ClientTransactions";
import VacationDays from "../components/ContentBox/Global/ReportTable/VacationDays";
import Invoice from "../components/ContentBox/Payment/Invoice";
import SharedCourse from "../components/ContentBox/Courses/SharedCourse";
import CreatePlacementTest from "../components/ContentBox/PlacementTest/CreatePlacementTest/CreatePlacementTest";
import ReceiptFunds from "../components/ContentBox/Global/ReportTable/ReceiptFunds";
import HolidaysCalendar from 'components/Modules/Groups/HolidaysCalendar/HolidaysCalendar';
//import Contract from 'components/ContentBox/Docs/contract/Contract';
//import StudyTestMain from './StudyTest/StudyTestMain';
//import Location from './Company/Location/Location';
import PlacementTestResult from '../components/ContentBox/PlacementTest/PlacementTestResult/PlacementTestResult';
import StudyTestMainStudent from "../components/ContentBox/StudyTest/StudyTestMainStudent";
//import StudentTestSimpleResult from "../components/ContentBox/StudyTest/StudentTestSimpleResult";
import WriteOffByGroup from "../components/ContentBox/Global/ReportTable/WriteOffByGroup";
import SlotSettings from '../components/Modules/Slots/SlotSettings/SlotSettings';
import SignUp from "../components/Modules/Auth/SignUp/SignUp";
import ConfirmRegistration from "../components/Auth/ConfirmRegistration";
import RecoverPassword from "../components/Modules/Auth/RecoverPassword/RecoverPassword";
import ResetPassword from "../components/Modules/Auth/ResetPassword/ResetPassword";
//import NewRecordWidget from "../components/ContentBox/Widget-shedule/NewRecordWidget";
import TestQuestions from "../components/Modules/Study/TestQuestions/TestQuestions";
import ScheduleSettings from 'components/Modules/Slots/ScheduleSettings/ScheduleSettings';
import RecordCustomer from 'components/Modules/Slots/RecordCustomer/RecordCustomer';
import AdvancedTeachersHistory from "components/Modules/Slots/AdvancedTeachersHistory/AdvancedTeachersHistory";
import TeacherSchedule from 'components/Modules/Slots/TeacherSchedule/TeacherSchedule';
import NewRecordOnline from 'components/Modules/Slots/NewRecordOnline/NewRecordOnline';
import BookedSchedule from 'components/Modules/Slots/BookedSchedule/BookedSchedule';
import SlotsListing from 'components/Modules/Slots/SlotsListing/SlotListing';
import TestLogin from "../components/Auth/TestLogin/TestLogin";
import HomeWorksGroup from "components/Modules/Groups/Listing/HomeWorks/HomeWorks";
import HistoryListing from 'components/Modules/Slots/HistoryListing/HistoryListing';
import TeacherResult from "../components/Modules/Study/TeacherResult/TeacherResult";
import TeacherSlotSettings from "components/Modules/Slots/TeacherSlotSettings/TeacherSlotSettings";
import Book from "../components/Modules/Ebook/Book/Book";
import BookChapter from "../components/Modules/Ebook/BookChapter/BookChapter";
import SlotDetails from 'components/Modules/Slots/SlotDetails/SlotDetails';
import TeachersHistory from 'components/Modules/Slots/TeachersHistory/TeachersHistory';
import ClientGroupInfo from "../components/Modules/Groups/ClientGroupInfo/ClientGroupInfo";
import TeacherGroup from "components/Modules/Groups/TeacherGroup/TeacherGroup";
import TestsGroup from "components/Modules/Groups/Listing/Tests/Tests";
import ReportsGroup from "components/Modules/Groups/Listing/Reports/Reports";
import Books from "../components/Modules/Ebook/Books/Books";
import TeacherRecordOnline from "components/Modules/Slots/TeacherRecordOnline/TeacherRecordOnline";
import {permissions} from "../settings/permissions";
import { rules } from "../settings/rules";
import StatisticClients from "../components/Modules/Reports/StatisticClients/StatisticClients";
import LessonTeacher from "../components/Modules/Ebook/LessonTeacher/LessonTeacher";
import LessonStudent from "../components/Modules/Ebook/LessonStudent/LessonStudent";
import TimelineTeachersHistory from "../components/Modules/Slots/TimelineTeachersHistory/TimelineTeachersHistory";
import HomeworkStudent from "../components/Modules/Ebook/HomeworkStudent/HomeworkStudent";
import VisualScheduleTestPage from "../components/Modules/Groups/TeacherGroup/VisualScheduleTestPage";
import SingleWorkTable from "../components/ContentBox/Company/WorkTable/SingleWorkTable";
import AttendanceRecords from "../components/ContentBox/Company/WorkTable/AttendanceRecords/AttendanceRecords"
import AverageCheckReport from "../components/Modules/Reports/AverageCheckReport/AverageCheckReport";
import ProfitabilityPrograms from "../components/Modules/Reports/ProfitabilityPrograms/ProfitabilityPrograms"
import LifespanReport from "../components/Modules/Reports/LifespanReport/LifespanReport";
import DroppedStudents from "../components/Modules/Reports/DroppedStudents/DroppedStudents";
import AttendanceMap from "../components/Modules/Reports/AttendanceMap/AttendanceMap";
import LoadingTeachers from "../components/Modules/Reports/LoadingTeachers/LoadingTeachers";
import NewStudents from "../components/Modules/Reports/NewStudents/NewStudents";
import StudentsTrialLesson from "../components/Modules/Reports/StudentsTrialLesson/StudentsTrialLesson";
import DocsConstructorContainer from "../components/ContentBox/Docs/DocsContainer/DocsConstructorContainer";
import DocsListContainer from "../components/ContentBox/Docs/DocsContainer/DocsListContainer";
import DocsViewerContainer from "../components/ContentBox/Docs/DocsContainer/DocsViewerContainer";
import Birthday from "../components/Modules/Reports/Birthday/Birthday";
import TrainingLessons from "../components/Modules/UniDance/TrainingLessons/TrainingLessons";
import LoadingAudiences from "../components/Modules/Reports/LoadingAudiences/LoadingAudiences";
import TeacherRating from "../components/Modules/Reports/TeacherRating/TeacherRating";
import ConversionReport from "components/Modules/Reports/ConversionReport/ConversionReport";
import StatsCoaches from "../components/Modules/Reports/StatsCoaches/StatsCoaches";
import SubscriptionsSold from "../components/Modules/Reports/SubscriptionsSold/SubscriptionsSold";
import ClientOccupancy from '../components/Modules/Reports/ClientOccupancy/ClientOccupancy';
import FinReport from "../components/Modules/Reports/FinReport/FinReport";
import NewRecordLesson from "../components/Modules/Slots/NewRecordLesson/NewRecordLesson";
import EmployeeRating from "components/Modules/Reports/EmployeeRating/EmployeeRating";
import MailingToClientsReport from "components/Modules/Reports/MailingToClientsReport/MailingToClientsReport";
import GroupStages from "../components/Modules/Reports/GroupStages/GroupStages";
import Conversion from "../components/Modules/Reports/Conversion/Conversion";
import HomeworkTeacher from "../components/Modules/Ebook/HomeworkTeacher/HomeworkTeacher";
import PastLessons from "../components/Modules/Ebook/PastLessons/PastLessons";
import PastLessonStudent from "../components/Modules/Ebook/PastLessonStudent/PastLessonStudent";
import PastLessonTeacher from "../components/Modules/Ebook/PastLessonTeacher/PastLessonTeacher";
import StudentFavorites from "../components/Modules/Ebook/StudentFavorites/StudentFavorites";
import FormedGroups from "../components/Modules/Reports/FormedGroups/FormedGroups";
import GroupLaunchSpeed from "components/Modules/Reports/GroupLaunchSpeed/GroupLaunchSpeed";
import StudyTests from "components/Modules/Study/StudyTests/StudyTests";
import PurposeTests from "components/Modules/Study/PurposeTests/PurposeTests";
import AddBook from "components/Modules/Auth/AddBook/AddBook";
import CPTBooks from "../components/Modules/CPT/CPTBooks";
import CPTNoBrandsBooks from "components/Modules/CPTNoBrands/CPTNoBrandsBooks";
import Varification from "components/Modules/Auth/Varification/Varification";

export const routes = {
    public: [
        {path: '/signup', component: SignUp, wrapper: 'WrapperUser'},
        {path: '/add_book/:uuid', component: AddBook, wrapper: 'WrapperUser'},
        {path: '/confirm-registration/:id', component: ConfirmRegistration},
        {path: '/restore-password', component: RecoverPassword, wrapper: 'WrapperUser'},
        {path: '/reset_password/:token', component: ResetPassword, wrapper: 'WrapperUser'},
        //{path: '/pays/sms/widget-shedule/:name', component: NewRecordWidget},
        //{path: '/pays/pay/widget-shedule/:name', component: NewRecordWidget},
        {path: '/new-record-online-test/:data64([\\w=\\/\\+]+)', component: NewRecordOnline},
        {path: '/new-record-lesson', component: NewRecordLesson},
        {path: '/teacher-record-online-test/:link', component: TeacherRecordOnline},
        {path: '/auth/test_by_code', component: TestLogin},
        {path: '/cpt/vninveiunevr:logo?', component: CPTBooks, wrapper: 'WrapperCPT'},
        {path: '/cpt/next:logo?', component: CPTNoBrandsBooks, wrapper: 'WrapperCPTNON'},
        {path: '/kundelik/token/', component: Varification, wrapper: 'VarificationAuth'},
    ],
    private: [
        {path: '/account_clients/:id/', component: ClientCard},
        {path: '/account/:id/', component: EmployeeProfile},
        {path: '/groups/calendar/', component: HolidaysCalendar},
        {path: '/groups/all-groups/', component: GroupsList},
        {path: '/groups/group/:id/', component: TeacherGroup},
        {path: '/group/visual-schedule/:id', component: VisualScheduleTestPage},
        {path: '/group/visual-schedule/', component: VisualScheduleTestPage},
        {path: '/groups/my-group/:id', component: ClientGroupInfo},
        {path: '/groups/sign-up-student/:id/', component: GroupListClients},
        {path: '/groups/sign-up/', component: GroupListClients},
        {path: '/groups/homeworks/:id', component: HomeWorksGroup},
        {path: '/groups/tests/:id', component: TestsGroup},
        {path: '/groups/reports/:id', component: ReportsGroup},

        {path: '/lessons/shared_course/:id/', component: SharedCourse},
        {path: '/lessons/course/:id/', component: Course},
        {path: '/lessons/init/:id/', component: LessonInit},

        {path: '/company/work-table/', component: WorkTable},
        {path: '/company/single-work-table/:id', component: SingleWorkTable},
        {path: '/company/attendance-records/', component: AttendanceRecords},

        // Онлайн запись / Слоты
        {
            path: '/online-schedule/company-setting/:id/',
            component: SlotSettings,
            permission: permissions.view_slot_company_settings
        },
        {path: '/online-schedule/schedule-setting/', component: ScheduleSetting},
        {
            path: '/online-schedule-v2/schedule-setting/',
            component: ScheduleSettings,
            permission: permissions.view_schedule_settings_v2
        },
        {path: '/online-schedule-v2/own-schedule/', component: TeacherSchedule},
        {path: '/online-schedule/record-customer/', component: NewRecord},
        {path: '/online-schedule-v2/record-customer/:id/', component: RecordCustomer},
        {path: '/online-schedule-v2/record-customer/', component: RecordCustomer},
        {path: '/online-schedule-v2/booked-schedule/', component: BookedSchedule},
        {path: '/online-schedule-v2/slots/:id/', component: SlotsListing},
        {path: '/online-schedule-v2/history-clients/', component: HistoryListing},
        {path: '/online-schedule-v2/slot-details/:id/', component: SlotDetails},
        {path: '/online-schedule-v2/teachers-history/', component: TeachersHistory},
        {path: '/online-schedule/booked-online-schedule/', component: BookedOnlineSchedule},
        {path: '/online-schedule/booked-teachers-schedule/', component: TeacherBookedSlots},
        {path: '/online-schedule/extended-teachers-schedule/', component: ExtendedTeachersSchedule},
        {path: '/online-schedule-v2/advanced-teachers-history/', component: AdvancedTeachersHistory},
        {path: '/online-schedule-v2/advanced-teachers-history-v2/', component: TimelineTeachersHistory},
        {path: '/slot-settings/', component: TeacherSlotSettings},

        {path: '/placement-test/create-widget/:id', component: CreatePlacementTest},
        {path: '/placement-test/create-widget/', component: CreatePlacementTest},
        {path: '/placement_test/result/:id/', component: PlacementTestResult},

        {path: '/c/:folder/:link/:id/', component: ListingCards},
        {path: '/c/:folder/:link/', component: ListingCards},

        {path: '/pays/invoice/:id/', component: Invoice},
        {path: '/pays/receipt-of-funds/', component: ReceiptFunds},

        {path: '/reports/vacation-days/:id/', component: VacationDays},
        {path: '/reports/client-transactions/:id/', component: ClientTransactions},
        {path: '/reports/teacher-statistics/', component: TeacherStatistics},
        {path: '/reports/groups-money/:id', component: WriteOffByGroup},
        {
            path: '/reports/profitability-programs',
            component: ProfitabilityPrograms,
            permission: permissions.view_attendance_records
        },

        {path: '/study/study-tests', component: StudyTests, permission: permissions.view_studytest_list},
        {path: '/study/test-questions/:id/', component: TestQuestions},
        {
            path: '/study/purpose-tests',
            component: PurposeTests,
            permission: permissions.view_studytest_purpose_test_list
        },
        {path: '/study/test/:id/', component: StudyTestMain},
        {path: '/study/my-test/:id/', component: StudyTestMainStudent},
        {path: '/study/simple-result/:id/', component: TeacherResult},
        {path: '/study/my-simple-result/:id/', component: TeacherResult},
        {path: '/init-placement-test/:id'},

        {path: '/ebooks/books', component: Books, permission: permissions.view_ebook_books},
        {path: '/ebooks/my-books', component: Books, permission: permissions.view_ebook_books_student},
        {path: '/ebooks/past-lessons', component: PastLessons, permission: permissions.view_ebook_books},
        {path: '/ebooks/my-past-lessons', component: PastLessons, permission: permissions.view_ebook_books_student},
        {
            path: '/ebooks/past-lesson/:uuid/',
            component: PastLessonTeacher,
            permission: permissions.view_ebook_chapter,
            wrapper: 'InitLessonWrapper'
        },
        {
            path: '/ebooks/my-past-lesson/:uuid/',
            component: PastLessonStudent,
            permission: permissions.view_init_lesson,
            wrapper: 'InitLessonWrapper'
        },
        {path: '/ebooks/book/:id/', component: Book},
        {path: '/ebooks/my-book/:id/', component: Book, permission: permissions.view_ebook_books_student},
        {
            path: '/ebooks/my-favorites/:id/',
            component: StudentFavorites,
            permission: permissions.view_init_lesson,
            wrapper: 'InitLessonWrapper'
        },
        {path: '/ebooks/chapter/:id/', component: BookChapter},
        {
            path: '/ebooks/lesson/:uuid/',
            component: LessonTeacher,
            permission: permissions.view_ebook_chapter,
            wrapper: 'InitLessonWrapper'
        },
        {
            path: '/ebooks/homework/:hash/',
            component: HomeworkTeacher,
            permission: permissions.view_ebook_chapter,
            wrapper: 'InitLessonWrapper'
        },
        {
            path: '/ebooks/my-lesson/:uuid/',
            component: LessonStudent,
            permission: permissions.view_init_lesson,
            wrapper: 'InitLessonWrapper'
        },
        {
            path: '/ebooks/my-homework/:hash/',
            component: HomeworkStudent,
            permission: permissions.view_init_lesson,
            wrapper: 'InitLessonWrapper'
        },
        {path: '/ebooks/my-chapter/:id/', component: BookChapter, permission: permissions.view_chapter},

        // Отчеты
        {path: '/reports/clients-stages/', component: StatisticClients},
        {path: '/reports/average_check_report/', component: AverageCheckReport},
        {path: '/reports/client_lifetime/', component: LifespanReport},
        {path: '/reports/dropped_students/', component: DroppedStudents},
        {path: '/reports/new_students/', component: NewStudents},
        {path: '/reports/attendance_map/', component: AttendanceMap},
        {path: '/reports/loading_teachers/', component: LoadingTeachers},
        {path: '/reports/loading_audiences/', component: LoadingAudiences},
        {path: '/reports/trial_lesson/', component: StudentsTrialLesson},
        {path: '/reports/occupancy-report/', component: ClientOccupancy},
        {path: '/reports/group_stages/', component: GroupStages},
        {path: '/reports/conversion/', component: Conversion},
        {path: '/reports/birthday/', component: Birthday},
        {path: '/reports/worked-hours/', component: WorkedHours},
        {path: '/reports/teacher_rating/', component: TeacherRating},
        {path: '/reports/employee_rating/', component: EmployeeRating},
        {path: '/reports/conversion_report/', component: ConversionReport},
        {path: '/reports/stats_coaches/', component: StatsCoaches},
        {path: '/reports/subscriptions_sold/', component: SubscriptionsSold},
        {path: '/reports/finreport/', component: FinReport},
        {path: '/reports/formed_groups/', component: FormedGroups},
        {path: '/reports/groups_launch_speed/', component: GroupLaunchSpeed},

        {path: '/docs/viewer/:pageType/:id', component: DocsViewerContainer},
        {path: '/docs/constructor/:id', component: DocsConstructorContainer},
        {path: '/docs/constructor/', component: DocsConstructorContainer},
        {path: '/docs/:type', component: DocsListContainer},

        {path: '/contract/viewer/:pageType/:id', component: DocsViewerContainer},
        {path: '/contract/constructor/:id', component: DocsConstructorContainer},
        {path: '/contract/constructor', component: DocsConstructorContainer},
        {path: '/contract/:type', component: DocsListContainer},
        {path: '/unidance/training-lessons', component: TrainingLessons},
        {path: '/reports/zero-balance-sub/', component: MailingToClientsReport},

    ],
    protected: [
        { path: '/init-placement-test/:id', component: InitTest, rules: rules.showInitTest },
    ],
}
