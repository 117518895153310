import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ContextMenu from 'components/UI/Calendar/components/ContextMenu/ContextMenu';
import { classNames as cn } from 'helpers';
import styles from './CalendarItem.module.scss';
import CalendarItemBody from 'components/UI/Calendar/components/CalendarItem/CalendarItemBody';

function CalendarItem({
  data,
  id,
  type,
  contextMenuID,
  currentTime,
  isSelected,
  withMultipleSelect,
  allowPast,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
}) {
  const { timeStamp, isPast, isToday } = currentTime;
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const isDisabled = (!data?.booked && !data?.available_slots?.length && type === 'teacher-schedule')
     || (!data?.is_available && type === 'record-online');
  const isPastAllowed = isPast && !allowPast;

  const handleMouseDown = () => {
    if (isPastAllowed || isDisabled) {
      return;
    }
    onMouseDown(id);
    setIsContextMenuShown(false);
  };
  const handleMouseUp = () => {
    if (isPastAllowed) {
      return;
    }
    onMouseUp(id);
    if (isSelected && !isPastAllowed && withMultipleSelect) {
      setIsContextMenuShown(true);
    }
  };
  const handleMouseEnter = () => {
    if (isPastAllowed) {
      return;
    }
    onMouseEnter(id);
  };

  return (
    <li
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className={cn({
        [styles.wrapper]: true,
        [styles.past]: isPastAllowed,
        [styles.today]: isToday,
        [styles.selected]: isSelected,
        [styles.disabled]: isDisabled,
      })}
    >
      <CalendarItemBody data={data} currentTime={currentTime} />
      { isContextMenuShown && id === contextMenuID &&
        <ContextMenu
          day={timeStamp}
          onClose={setIsContextMenuShown}
        />
      }
    </li>
  );
}

CalendarItem.propTypes = {
  currentTime: PropTypes.shape({
    timeStamp: PropTypes.number.isRequired,
    dayNumber: PropTypes.number.isRequired,
    isPast: PropTypes.bool.isRequired,
    isToday: PropTypes.bool.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  contextMenuID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]).isRequired,
  type: PropTypes.oneOf(['schedule-settings', 'record-customer', 'teacher-schedule', 'record-online', 'booked-slots']),
  onMouseUp: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  withMultipleSelect: PropTypes.bool,
  allowPast: PropTypes.bool,
};

CalendarItem.defaultProps = {
  isSelected: false,
  withMultipleSelect: false,
};

export default observer(CalendarItem);
