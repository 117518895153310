import React, {useState, useEffect} from "react"
import style from "./ClientMainInfo.module.scss"
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions"
import Telegram from "components/Modules/Groups/components/Telegram/Telegram"
import Zoom from "components/Modules/Groups/components/Zoom/Zoom"
import documentImg from "components/ContentBox/img/new_document_img.svg"
import { GoToLink } from "components/UI/SuperButton/SuperButton"
import Stores from "Stores"
import { superAxiosRequest } from "axiosApi"
import {ReactComponent as Certificate} from "components/ContentBox/img/certificate.svg"
import { NavLink } from "react-router-dom"
import { permissions } from "settings/permissions"
import { checkPermList } from 'helpers';
import modalStore from "Stores/modalStore"
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
const ClientMainInfo = ({id, userGroup, groupInfo, group, defaultPrice, surchargeList, discountList, translate}) => {
  
  
  const [clientBooks, setClientBook] = useState([])
  const [zoomLink, setZoomLink] = useState(null);
  const [telegramLink, setTelegramLink] = useState(null);
 


  const checkZoom = !!(
    checkPermList(permissions.view_zoom_link) && zoomLink?.zoom_link
  );
  const checkTelegram = !!(
    checkPermList(permissions.view_telegram_link) && telegramLink?.telegram_link
  );


  const openModalTelegram = (e) => {
    const ms = {
      name: 'telegram',
      link: telegramLink
        ? `groups/groups-telegram/${id}`
        : 'groups/groups-telegram',
      title: 'Telegram канал группы',
      type: 'SelectorAndInput',
      active_inputs: ['text'],
      name_text: 'telegram_link',
      data: 'json',
      is_put: telegramLink,
      valid: { telegram_link: 'required' },
      button: 'Добавить',
      func: (data) => setTelegramLink({ ...data }),
    };
    if (e.target.tagName !== 'svg' && telegramLink?.telegram_link) {
      window.open(telegramLink.telegram_link, '_blank');
      return;
    }
    modalStore.activity(ms, { ...telegramLink, group_id: id });
  };

  const openModalZoom = (e) => {
    const ms = {
      name: 'zoom',
      link: zoomLink ? `groups/groups-zoom/${id}` : 'groups/groups-zoom',
      title: 'Zoom канал группы',
      type: 'SelectorAndInput',
      active_inputs: ['text'],
      name_text: 'zoom_link',
      data: 'json',
      is_put: zoomLink,
      valid: { zoom_link: 'required' },
      button: 'Добавить',
      func: (data) => setZoomLink({ ...data }),
    };
    if (e.target.tagName !== 'svg' && zoomLink?.zoom_link) {
      window.open(zoomLink.zoom_link, '_blank');
      return;
    }
    modalStore.activity(ms, { ...zoomLink, group_id: id });
  };

  useEffect(() => {
    let apiActiveGroupLessons = {
      method: 'get',
      link: `ebooks/add_to_group`,
      params: {
        
        group_id: id,
        today: true,
      },
    };

    superAxiosRequest(apiActiveGroupLessons).then((res) => {
      setClientBook(res.data);
      
    });

  }, [id]);
  return (
      <>
        <div className={style.clientSimpleBox__cols}>
                  {/*Groups Teachers*/}
                  <div className={style.clientSimpleBox__col}>
                    {/*Добавление курса*/}
                    
                    <div className={style.clientSimpleBox__col__card}>
                      <div className={style.clientSimpleBox__cardHeader}>
                        <span>{translate.course_of_study}</span>
                      </div>
                      {/* IBooks */}
                      {
                      clientBooks && clientBooks.map((lesson , index) => (
                      <div key={index} className={style.clientSimpleBox__list}>
                        <img src={`${lesson.image}`} alt="Book" />
                        <div className={style.clientSimpleBox__listItem}>
                         
                         <NavLink target="_blank" to={`/ebooks/books/${lesson.ebook_id}`}>
                            {lesson.name}
                         </NavLink>             
                          <div className={style.clientSimpleBox__bookInfo}>
                            
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>

                  {/*Group options*/}
                  <div className={style.clientSimpleBox__col}>
                    {[checkTelegram, checkZoom].includes(true) && (
                      <div className={style.clientSimpleBox__wrapper}>
                        {checkTelegram && (
                          <Telegram onClick={openModalTelegram} />
                        )}
                        {checkZoom && <Zoom onClick={openModalZoom} />}
                      </div>
                    )}
                    <div className={style.clientSimpleBox__col__card}>
                      <div className={style.clientSimpleBox__cardHeader}>
                        <span>{translate.group_options}</span>
                      </div>
                      <div className={style.clientSimpleBox__config}>
                        <div
                          className={style.clientSimpleBox__config__container}>
                          <div className={style.item}>
                            <span className={style.itemName}>
                              {translate.start_of_training}:
                            </span>
                            <span className={style.itemValue}>
                             {timeConverter(new Date(userGroup?.status.start_study_at), 'age')}
                            </span>
                          </div>

                          <div className={style.item}>
                            <span className={style.itemName}>
                              {translate.company_location}:
                            </span>
                            <span className={style.itemValue}>
                              {groupInfo.company.name} | {groupInfo.location.name}
                            </span>
                          </div>

                          <div className={style.item}>
                            <span className={style.itemName}>
                            {translate.category_and_program}:
                            </span>
                            <span className={style.itemValue}>
                              {groupInfo.course_level.name} |  {groupInfo.program_type.name}
                            </span>
                          </div>

                          <div className={style.item}>
                            <span className={style.itemName}>{translate.responsible}:</span>

                           { group?.teacher_info && (
                                group?.teacher_info.map((teacher,index ) => {
                                  return (
                                    <span key={index} className={style.itemValue}>
                                  {teacher.full_name}
                                </span>
                                  )
                                })
                           )}
                          </div>

                          <div className={style.item}>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {Date.now() < 0 &&
                        <div className={style.clientSimpleBox__docsBox}>
                          <div className={style.clientSimpleBox__docsBox_info}>
                            <div className={style.clientSimpleBox__docsLink}>
                              <div className={style.clientSimpleBox__imgLink}>
                                <img className={style.img} src={documentImg} alt=""/>
                                <div className={style.clientSimpleBox__imgText}>
                                  PDF
                                </div>
                                <span>{groupInfo.doc_type_description}</span>
                              </div>
                            </div>
                            <div className={style.clientSimpleBox__pdf}>

                              <NavLink
                                className={style.groupSimpleMainBoxDocs__link}
                                to={`/docs/signed`} target="_blank">
                                Signed
                              </NavLink>
                              <span>/</span>
                              <NavLink
                                className={style.groupSimpleMainBoxDocs__link}
                                to={`/docs/not-signed`} target="_blank">
                                Awaiting
                              </NavLink>

                              <GoToLink onClick={()=> {}}/>

                            </div>
                          </div>
                        </div>}

                    </div>
                  </div>
                </div>
        {Date.now() < 0 &&
          <div className={style.clientPaymentInfo}>
            <div className={style.clientPaymentInfo__box}>
              <div className={style.clientPaymentInfo__item}>
                      <span className={style.clientPaymentInfo__item__title}>
                        {translate.group_prices}:
                      </span>
                {defaultPrice &&
                  <div className={style.clientPaymentInfo__item__specialPrice}>
                    <p>{defaultPrice.name}</p>
                    <span><strong>{Stores.cleanSumm(defaultPrice?.price_sum)} ₸</strong> | {defaultPrice?.hours_sum} {defaultPrice?.hours_type.short_name}.ч.</span>
                    <div className={style.clientPaymentInfo__item__activeDate}>
                      <p>Active Dates:</p>
                      <span>{timeConverter(new Date(userGroup?.status.start_study_at), 'dd.mm.yyyy')} г.</span>

                    </div>
                  </div>}
              </div>
              <div className={style.clientPaymentInfo__item}>
                      <span className={style.clientPaymentInfo__item__title}>
                        {translate.discounts}:
                      </span>
                {discountList.length > 0 ?
                  (discountList.map((discount, index) => {
                    return (
                      <div key={index} className={style.clientPaymentInfo__item__specialPrice}>
                        <p>{userGroup.status.discount.name}</p>
                        <span>{}</span>
                        <div className={style.clientPaymentInfo__item__activeDate}>
                          <p>Active Dates:</p>
                          <div> <span>{timeConverter(new Date(discount.date_from), 'dd.mm.yyyy')} г.</span></div>
                        </div>
                      </div>
                    )}))
                  :  <EmptyData />

                }
              </div>
              <div className={style.clientPaymentInfo__item}>

                      <span className={style.clientPaymentInfo__item__title}>
                        {translate.surcharges}:
                      </span>

                {surchargeList.length > 0 ?
                  (surchargeList.map((surcharge, index) => {
                    return (
                      <div key={index} className={style.clientPaymentInfo__item__specialPrice}>
                        <p>{surcharge.surcharge.name}</p>
                        <span>{surcharge.surcharge.type_name} | {surcharge.surcharge.count}%</span>
                        <div className={style.clientPaymentInfo__item__activeDate}>
                          <p>Active Dates:</p>
                          {surcharge.date_from && <p>  <span>{timeConverter(new Date(surcharge.date_from), 'dd.mm.yyyy')} г.</span></p>}
                        </div>
                      </div>
                    )
                  }))

                  :  <EmptyData />
                }
              </div>
              <div className={style.clientPaymentInfo__item}>

                      <span className={style.clientPaymentInfo__item__title}>
                        {translate.certificates}:
                      </span>

                {userGroup.status.certificate.length > 0 ?
                  <div className={style.clientPaymentInfo__item__certificate}>
                    <Certificate />
                    <div
                      className={style.clientPaymentInfo__item__certificateItem}>
                      <p>Certificate name</p>
                      <span>2 511.00 ₸ | 30 days</span>
                      <div className={ style.clientPaymentInfo__item__activeDate}>
                        <p>Active From:</p>
                        <p>07.09.2023</p>
                      </div>
                    </div>
                  </div> : <EmptyData />
                }
              </div>
            </div>
          </div>}
      </>
  )
}

export default ClientMainInfo