import React from 'react';
import styles from "./ActiveTest.module.scss"
import {SuperButton} from 'components/UI/SuperButton/SuperButton';
import ActionMenu from 'components/UI/ActionMenu/ActionMenu';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import useLocalization from "hooks/useLocalization";
import Store from "Stores";

const ActiveTest = ({
    study_test,
    name,
    test_level,
    test_sub_level,
    date_from,
    date_until,
    start_at,
    finish_at,
    questions_count
}) => {
    const isClient = Store.isClient
    const menu = [
        {name: 'Variant 1', action: 'edit'},
        {name: 'Variant 2', action: 'delete'},
    ]
    const action = (actionType) => {
        switch (actionType) {
            case 'delete':

                break;
            case 'edit':

                break;
            default:
                break;
        }
    }
    const {translate} = useLocalization({
        section: 'profile',
        subsection: 'main_info',
        dict: {
            "in_progress": "InProgress",
            "finished": "Finished"
        }})
    return (
        <div className={styles.row}>
            <div className={`${styles.titleBox}`}>
                <div className={styles.subtitleRow}>
                    {isClient ?
                        (<span> {test_level} | {test_sub_level} </span>) :
                        (<>
                            {(study_test?.sub_lvl?.lvl?.name || study_test?.sub_lvl?.name) &&
                                `${study_test?.sub_lvl?.lvl?.name || ""} ${
                                    study_test?.sub_lvl?.lvl?.name && study_test?.sub_lvl?.name ? "|" : ""
                                } ${study_test?.sub_lvl?.name || ""}`}
                        </>)
                    }
                </div>
                <Link className={styles.linkWrapper} to={'/'} >
                    <div className={styles.title}>{name}</div>
                </Link>

            </div>
            <div className={`${styles.questionBox}`}>
                Questions: {questions_count}
            </div>
            <div className={`${styles.dateBox}`}>
                <div className={styles.date}><b>{start_at}</b>, {timeConverter(date_from, 'clean_data')}</div>
                <div className={styles.date}><b>{finish_at}</b>, {timeConverter(date_until, 'clean_data')}</div>
            </div>
            <div className={styles.badgeWrapper}>
                <SuperButton
                    variant='badge'
                    type={'badgeColored'}
                >
                    {translate.in_progress}
                </SuperButton>
            </div>
            <div className={styles.btnBox}>
                <SuperButton
                    variant='link'
                    path={'/'}
                >
                    Go to Testing
                </SuperButton>
                <ActionMenu menu={menu} action={action} />
            </div>

        </div>
    );
};

export default ActiveTest;