import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ObservableSet } from 'mobx';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import Select from 'components/UI/Select/Select';
import TimeSlot from '../TimeSlot/TimeSlot';
import { scrollToRef } from 'helpers';
import { calcTimeWithTimeZoneShift } from 'components/Modules/Slots/utils';
import LocalStorage from 'services/LocalStorage';
import { localisationMaster } from "Localisation/Localisation";
import Stores from 'Stores';
import slotsTeacherScheduleStore from 'Stores/slotsTeacherScheduleStore';
import styles from './TimeSlots.module.scss';
import { ReactComponent as InfoSVG } from 'components/ContentBox/img/information.svg';

const getOptions = (data) => data.map(({ id, name, name_tz_main }) => ({value: id, label: name, name_tz_main}));
const findOption = (options, value) => options.find((item) => item.value === value);

function TimeSlots({ slots, selectedSlots, children, toggles, defaultTimeZone, withMultipleSelect, onClick, onTimeZoneChange }) {
  const ref = useRef(null);
  const userTimeZone = defaultTimeZone || LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  const timeZoneOptions = getOptions(Stores.timeZones);
  const [timeZone, setTimeZone] = useState(findOption(timeZoneOptions, userTimeZone));
  const { bookedSlots } = slotsTeacherScheduleStore;

  const handleSlotClick = (id) => {
    if (!withMultipleSelect && selectedSlots.size > 0 && !selectedSlots.has(id)) {
      selectedSlots.clear();
    }
    selectedSlots.has(id) ? selectedSlots.delete(id) : selectedSlots.add(id);
    onClick(id);
  };

  const handleTimeZoneChange = (option) => {
    setTimeZone(option);
    onTimeZoneChange(option.value);
  };

  useEffect(() => {
    if (ref !== null && bookedSlots.length === 0) {
      scrollToRef(ref, 100);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper ref={ref}>
      <Flex withGap classes={styles.title_wrapper}>
        <SectionTitle title={localisationMaster('time', 'online-schedule/record-customer')} secondary withMargin={false} />
        {/* <Select
          options={timeZoneOptions}
          value={timeZone}
          placeholder={localisationMaster('select-placeholder', 'company/locations')}
          onChange={handleTimeZoneChange}
          classes={styles.select}
              /> */}
        <div className={styles.timeZone}>
          <div className={styles.info_icon}><InfoSVG /></div>
          <div>{timeZone.label}</div>
        </div>
      </Flex>
      <div className={styles.workingHoursNotice}>
        <p>
          Обратите внимание: данная локация работает с 07:00 до 21:30 в часовом поясе:
          <strong>{timeZone.label}</strong>
        </p>
      </div>
      { children }

      {slots.length === 0
        ? <p className={styles.empty_text}>
            {localisationMaster('text_27', 'teacher_schedule')} {toggles.is_online ? localisationMaster('online', 'online-schedule/record-customer').toLowerCase() : localisationMaster('text_23', 'teacher_schedule').toLowerCase()}
          </p>
        : (<ul className={styles.slots_list}>
            {
              slots.map((slot) => (
                <TimeSlot
                  data={slot}
                  key={slot.id.toString()}
                  start={calcTimeWithTimeZoneShift(slot.start_at, timeZone.value)}
                  finish={calcTimeWithTimeZoneShift(slot.finish_at, timeZone.value)}
                  isSelected={selectedSlots.has(slot.id)}
                  onClick={() => handleSlotClick(slot.id)}
                />
              ))
            }
          </ul>)
      }
    </Paper>
  );
}

TimeSlots.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_at: PropTypes.string.isRequired,
    finish_at: PropTypes.string.isRequired,
  })).isRequired,
  selectedSlots: PropTypes.oneOfType([
    PropTypes.instanceOf(Set),
    PropTypes.instanceOf(ObservableSet),
  ]).isRequired,
  toggles: PropTypes.shape({
    is_online: PropTypes.bool,
    is_school: PropTypes.bool,
  }),
  children: PropTypes.node,
  defaultTimeZone: PropTypes.number,
  withMultipleSelect: PropTypes.bool,
  onClick: PropTypes.func,
  onTimeZoneChange: PropTypes.func,
};

TimeSlots.defaultProps = {
  children: null,
  withMultipleSelect: true,
  onClick: () => {},
  onTimeZoneChange: () => {},
};

export default observer(TimeSlots);
