import React from "react";
import st from "./PaperHeader.module.scss";
import {EbookHeaderArrow} from "./EbookHeaderArrow";
import Stores from "Stores";

const PaperHeader = ({section, chapterName}) => {
  return (
    <>
      <div className={st.chapterName}>
        <EbookHeaderArrow fill={section?.color?.background_color} stroke={section?.color?.body_color}/>
        <h3>{chapterName}</h3>
      </div>
      {/*{section?.image && <div className={st.sectionImage}><img src={Stores.baseLink() + section?.image} alt={section?.name}/></div>}*/}
    </>
  )
}

export default PaperHeader;
