import {observable, makeObservable as decorate, action, computed} from 'mobx';
import Stores from '.';
import { superAxiosRequest } from '../axiosApi';

class SlotesStore {

   dateBookedListProxy = [];

   bookedSlotsProxy = [];

   get bookedSlots() {
        return Stores.observableArray(this.bookedSlotsProxy)
}

   get dateBookedList(){
       return Stores.observableArray(this.dateBookedListProxy)
   }

   setDateBookedListProxy = (date, location) => {
    const apiConf = {
        method: 'get',
        link: 'time_slots/admin/date_booked',
        params: {
            date,
            location,
        }
    }
    superAxiosRequest(apiConf, 'data').then(response => this.dateBookedListProxy = response.data);
    }
    
    makeDisactiveSlot = (tid, sid) => {
        const arr = this.dateBookedList
        const index = arr.findIndex(item => item.teacher_id === tid);
        const subIndex = arr[index].items.findIndex(item => item.id === sid);
        arr[index].items[subIndex].is_active = false
        this.dateBookedListProxy = arr
    }

    updateSlot = (response) => {
        const arr = this.dateBookedList
        const index = arr.findIndex(item => item.teacher_id === response.teacher_id);
        const subIndex = arr[index].items.findIndex(item => item.id === response.id);
        arr[index].items[subIndex].is_active = response.is_active
        arr[index].items[subIndex].is_booked = response.is_booked
        arr[index].items[subIndex].is_visitor = response.is_visitor
        this.dateBookedListProxy = arr
    }

    setBookedSlots = res => {
        this.bookedSlotsProxy = res;
    }

    makeDisactiveTSlot = sid => {
        const arr = this.bookedSlots
        const index = arr.findIndex(item => item.id === sid);
        arr[index].is_active = false
        this.bookedSlotsProxy = arr
    }

    updateBookedSlot = (response) => {
        const arr = this.bookedSlots
        const index = arr.findIndex(item => item.id === response.id);
        arr[index].is_active = response.is_active
        arr[index].is_booked = response.is_booked
        arr[index].is_visitor = response.is_visitor
    }

    addSlot = slot =>{
        const arr = this.bookedSlots
        arr.push(slot)
        this.bookedSlotsProxy = arr
    }

    dropSlots = () => {
        this.dateBookedListProxy = []
    }
}


SlotesStore = decorate(new SlotesStore(),{
    dateBookedListProxy: observable,
    setDateBookedListProxy: action,
    makeDisactiveSlot: action,
    dateBookedList: computed,
    bookedSlots: computed,
    bookedSlotsProxy: observable,
    makeDisactiveTSlot: action,
    setBookedSlots: action,
    updateSlot: action,
    dropSlots: action,
    updateBookedSlot: action,
    addSlot: action,
})

export default SlotesStore