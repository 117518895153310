import React, { useEffect, useRef } from 'react';
import Paper from 'components/UI/Paper/Paper';
import { classNames as cn, scrollToRef } from 'helpers';
import styles from './Confirm.module.scss';

function ConfirmAdmin({ store }) {
  const {clientData, paymentTime, paymentType} = store;
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      scrollToRef(ref, 100);
    }
  }, []);

  return (
    <Paper classes={styles.wrapper} ref={ref}>
      <h2 className={cn({
        [styles.title]: true,
        [styles.title__admin]: paymentType === 3,
        [styles.title__email]: paymentType === 4,
        [styles.title__simple]: paymentType === 5,
      })}>
        Ваше бронирование ожидает подтверждения!
      </h2>
      <p className={styles.text}>
        После подтверждения бронирования, данные будут отправлены на указанный вами e-mail:
      </p>
      <p className={styles.phone}>
        {clientData.email.value}
      </p>
      <p className={styles.success_text}>
        Ожидание может составить до {paymentTime} минут.
      </p>
      <p className={styles.notice}>
        Если вы не получили данное письмо, проверьте папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти.
      </p>
      <p className={styles.notice}>
        Также добавьте <strong>phoenix@interpress.kz</strong> в список разрешенных отправителей, чтобы предотвратить блокирование писем из-за
        настроек программного обеспечения.
      </p>
    </Paper>
  );
}

export default ConfirmAdmin;