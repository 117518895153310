import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react'
import modalStore from "Stores/modalStore";
import SelectDateBirth from "../../SelectDateBirth/SelectDateBirth";
import InputInn from "../../InputInn/InputInn";
import { translate as loc, dict } from "Localisation";
import InputPhone from "../../InputPhone/InputPhone";
import { localisationMaster } from '../../../../../Localisation/Localisation';

const ClientContactPerson = () => {

    const { modalValue, changeValuesArray, check, getModalValues } = modalStore

    const [person, setPerson] = useState(getModalValues)

    const changeHandler = (val, key) => setPerson({...person, [key]: val})

    const inputField = (key, name) =>
        <div className="modalClientInfo__field">
            <p>{name}:</p>
            <input name={key}
                   placeholder={name}
                   value={person[key] || ''}
                   onChange={e => changeHandler(e.target.value, key)}
                   className={check(key) ? 'super-modal__input--error' : undefined}
            />
        </div>

    useEffect(() => {
        changeValuesArray({...modalValue, ...person})
    }, [person])

    return (
        <div className="modalClientInfo">

            <div className="modalClientInfo__fieldset">
                {inputField('last_name', localisationMaster('input-placeholder-1', 'company/profiles/account', 'add_category'))}

                {inputField('first_name', localisationMaster('input-placeholder-2', 'company/profiles/account', 'add_category'))}

                {inputField('father_name', localisationMaster('input-placeholder-3', 'company/profiles/account', 'add_category'))}

                {inputField('who_is_it', localisationMaster('whois', 'company/profiles/account', 'add_category'))}

                <div className="modalClientInfo__field">
                    <p>{localisationMaster('text_57', 'modal_templates')}:</p>
                    <SelectDateBirth value={person.date_of_birth}
                                     onChange={sDate => changeHandler(sDate, 'date_of_birth')} />
                </div>

                <div className="modalClientInfo__field">
                    <p>{localisationMaster('table-row-title-5', 'company/companies')}:</p>
                    <InputInn value={person.inn} onChange={val => changeHandler(val, 'inn')} />
                </div>


                <div className="modalClientInfo__field">
                    <p>{localisationMaster('text_55', 'modal_templates')} 1:</p>
                    <InputPhone value={person.phone}
                                onChange={val => changeHandler(val, 'phone')} />
                </div>

                <div className="modalClientInfo__field">
                    <p>{localisationMaster('text_55', 'modal_templates')} 2:</p>
                    <InputPhone
                        value={person.mobile_phone}
                        onChange={val => changeHandler(val, 'mobile_phone')} />
                </div>

                {inputField('address', localisationMaster('text_17', 'modal_templates'))}

                {inputField('email', 'E-mail')}

                {inputField('telegram', 'Telegram')}

                <div className="modalClientInfo__field">
                    <p>WhatsApp:
                        <span className="modalClientInfo__link"
                              onClick={() => person.phone && changeHandler(person.phone, 'whatsapp')}>
                            {person.phone ? loc(dict.main_page.text_22) : ''}
                        </span>
                    </p>
                    <InputPhone
                        value={person.whatsapp}
                        onChange={val => changeHandler(val, 'whatsapp')} />
                </div>

            </div>
        </div>
    )
}

export default observer(ClientContactPerson)
