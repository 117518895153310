import React from 'react';
import modalStore from "Stores/modalStore";
import AnswerQuestion from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerQuestion";
import AnswerFiles from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerFiles";
import st from "components/Modules/Study/TeacherResult/components/ResultAnswers/CompleteAnswers/CompleteAnswer/AnswerQuestion.module.scss";
import { observer } from "mobx-react";

const QuestionTestResult = () => {
    const { getModalValues } = modalStore;
    const typesColumn = ["text_missing_words", "text_missing_words", "words_in_blanks"];
    const type = getModalValues.question.type;
    const types = {
        complete_sentence: st.completeSentence,
        text_missing_words: st.textMissingWords,
        word_matching: st.wordMatching,
        words_in_blanks: st.wordsInBlanks,
    };
    return (
        <div className={`${types[type]} ${typesColumn.includes(type) && st.column}`}>
            <AnswerQuestion answer={getModalValues} modal={true} />
            <AnswerFiles answer={getModalValues} modal={true} />
        </div>
    )
}

export default observer(QuestionTestResult);

