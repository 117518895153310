import React from 'react';
import { observer } from 'mobx-react';
import TimeSlots from 'components/Modules/Slots/components/TimeSlots/TimeSlots';
import SlotsType from 'components/Modules/Slots/TeacherSchedule/components/SlotsType/SlotsType';
import slotsTeacherScheduleStore from 'Stores/slotsTeacherScheduleStore';

function SlotsLayout() {
  const { slots, selectedSlots } = slotsTeacherScheduleStore;

  return (
    <>
      <TimeSlots
        slots={slots}
        selectedSlots={selectedSlots}
      />
      {selectedSlots.size > 0 && <SlotsType/> }
    </>
  );
}

export default observer(SlotsLayout);
