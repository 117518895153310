import { observer } from "mobx-react";
import React, { useCallback } from "react";
import SmartFilter from 'components/ContentBox/Global/Filter/SmartFilter';
import {classNames as cn} from "helpers";
import modalStore from "Stores/modalStore";

const AddClient = () => {
    const { check, getModalValues, changeValues, addTag, changeValuesArray } = modalStore;

    const getStudentList = useCallback((value) => {
        const profile = value[0].profile;
    
        addTag(profile.id, 'coru');
        changeValuesArray({
            ...getModalValues,
            client: profile.id,
            first_name: profile.first_name,
            last_name: profile.last_name,
            father_name: profile.father_name,
        })
    }, [getModalValues]);

    return (
        <div className="super-modal__form-column">
        <div className="super-modal__form-field">
          <p className="super-modal__form-label--required">Клиент</p>
          <SmartFilter 
            link="accounts/clients"
            placeholder="Вводите имя, e-mail или телефон студента"
            new_key="full_name"
            sub_key="profile"
            backFunction={getStudentList}
            error={check('client')}
          />
        </div>
        {getModalValues?.client && (
          <div className="super-modal__form-field">
            <p className="super-modal__form-label">
              Клиент: {getModalValues.last_name} {getModalValues.first_name} {getModalValues.father_name}
            </p>
          </div>
        )}
        <div className="super-modal__form-field">
          <p className="super-modal__form-label--required">Штрих код</p>
          <input
            onChange={e => changeValues(e.target.value.replace(/\D/g, ""), 'barcode')}
            placeholder="Штрих код"
            value={getModalValues?.barcode || ''}
            maxLength={15}
            className={cn({
              'super-modal__input--error': check('barcode')
            })}
          />
        </div>
      </div>
    )
}

export default observer(AddClient);