import React from 'react';

export default function MainSelector(props) {
    return (
        <div className="super-modal-main-select">
            <div className="super-modal-main-select__icon">
                {props.component ?
                    props.component() :
                    <img src={props.image} alt="alt"/>
                }
            </div>
            <div className={props.className || 'super-modal-main-select__container'}>
                {props.children}
            </div>
        </div>
    )
}
