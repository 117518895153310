import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import Select from 'components/UI/Select/Select';
import InputCheckbox from 'components/UI/InputCheckbox/InputCheckbox';
import InputToggle from 'components/UI/InputToggle/InputToggle';
import TextArea from 'components/UI/TextArea/TextArea';
import { localisationMaster } from "Localisation/Localisation";
import { scrollToRef } from 'helpers';
import slotsTeacherScheduleStore, { DataType } from 'Stores/slotsTeacherScheduleStore';
import styles from './SlotsType.module.scss';

const CheckboxName = {
  is_group: 'is_group',
  is_school: 'is_school',
  is_online: 'is_online',
  is_zoom_planning: 'is_zoom_planning'
};
const getOptions = (slotTypes) => slotTypes.map(({ id, name, is_accessible, is_comment }) => ({
  value: id,
  label: name,
  is_accessible,
  is_comment,
}));
const findOption = (value, options) => options.find((item) => item.value === value);
const getLabel = () => <span><strong>{localisationMaster('text_11', 'teacher_schedule')}</strong>. {localisationMaster('text_28', 'teacher_schedule')}</span>;

function SlotsType() {
  const wrapperRef = useRef(null);
  const commentWrapperRef = useRef(null);
  const commentRef = useRef(null);

  const { checkboxesStatus, slotType, comment, setData, slotTypes } = slotsTeacherScheduleStore;
  const options = getOptions(slotTypes);
  const selectedOption = findOption(slotType, options);
  const [isCommentOpen, setIsCommentOpen] = useState(selectedOption?.is_comment);

  const handleCommentChange = (evt) => {
    setData(evt.target.value, DataType.comment);
  };
  const handleSelectChange = (option) => {
    if (option.value === slotType) {
      return;
    }
    setIsCommentOpen(option?.is_comment);
    setData(option.value, DataType.slotType);
    setData('', DataType.comment);
  };
  const handleCheckboxChange = (evt) => {
    const name = evt.target.name;
    switch (name) {
      case CheckboxName.is_school:
        setData({
          ...checkboxesStatus,
          [CheckboxName.is_school]: !checkboxesStatus[CheckboxName.is_school],
          [CheckboxName.is_online]: false,
        }, DataType.checkboxesStatus);
        break;
      case CheckboxName.is_online:
        setData({
          ...checkboxesStatus,
          [CheckboxName.is_online]: !checkboxesStatus[CheckboxName.is_online],
          [CheckboxName.is_school]: false,
        }, DataType.checkboxesStatus);
        break;
      default:
        setData({
          ...checkboxesStatus,
          [CheckboxName[name]]: !checkboxesStatus[name],
        }, DataType.checkboxesStatus);
        break;
    }
  };

  useEffect(() => {
    if (wrapperRef) {
      scrollToRef(wrapperRef, 100);
    }
  }, []);
  useEffect(() => {
    commentWrapperRef.current.style.height = isCommentOpen
      ? commentRef.current.offsetHeight + 'px'
      : '';
  }, [isCommentOpen]);

  return (
    <Paper ref={wrapperRef}>
      <SectionTitle title={localisationMaster('type_slots', 'teacher_schedule')} secondary/>
      <Flex withGap classes={styles.select_wrapper}>
        <Select
          options={options}
          value={selectedOption}
          placeholder={localisationMaster('text_21', 'teacher_schedule')}
          onChange={handleSelectChange}
          classes={styles.select}
          noOptionsText={localisationMaster('text_29', 'teacher_schedule')}
        />
        {selectedOption?.is_accessible &&
        <InputCheckbox
          label={getLabel()}
          selected={checkboxesStatus.is_group}
          name={CheckboxName.is_group}
          onChange={handleCheckboxChange}
          classes={styles.checkbox}
        />
        }
      </Flex>
      <div ref={commentWrapperRef} className={styles.comment}>
        <TextArea
          ref={commentRef}
          label={localisationMaster('table-row-title-2', 'teacher_schedule')}
          value={comment}
          onChange={handleCommentChange}
          placeholder={`* ${localisationMaster('text_30', 'teacher_schedule')}`}
          name="comment"
          withLabel={false}
          isError={selectedOption?.is_comment && !comment}
          isResize={false}
        />
      </div>

      <h3 className={styles.subtitle}>
        {localisationMaster('text_31', 'teacher_schedule')}:
      </h3>
      <Flex tag="ul" withGap asColumn align="start">
        <InputToggle
          tag="li"
          label={localisationMaster('text_23', 'teacher_schedule')}
          selected={checkboxesStatus.is_school}
          name={CheckboxName.is_school}
          onChange={handleCheckboxChange}
        />
        <InputToggle
          tag="li"
          label={localisationMaster('text_32', 'teacher_schedule')}
          selected={checkboxesStatus.is_online}
          name={CheckboxName.is_online}
          onChange={handleCheckboxChange}
        />
        <InputToggle
          tag="li"
          label={localisationMaster('text_33', 'teacher_schedule')}
          description={localisationMaster('text_34', 'teacher_schedule')}
          selected={checkboxesStatus.is_zoom_planning}
          name={CheckboxName.is_zoom_planning}
          onChange={handleCheckboxChange}
        />
      </Flex>
    </Paper>
  );
}

export default observer(SlotsType);
