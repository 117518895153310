import React from 'react';
import { observer } from 'mobx-react';
import CompanyLocationSelects from '../../components/CompanyLocationSelects/CompanyLocationSelects';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';

function SelectorsLayout() {
  const {
    companiesList,
    locationsList,
    selectedCompany,
    selectedLocation,
    setData,
    resetData,
  } = slotsScheduleSettingsStore;

  return (
    <CompanyLocationSelects
      companiesList={companiesList}
      locationsList={locationsList}
      selectedCompany={selectedCompany}
      selectedLocation={selectedLocation}
      onSetData={setData}
      onResetData={() => resetData('withDates')}
    />
  );
}

export default observer(SelectorsLayout);

