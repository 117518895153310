import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import VideoThumbnail from "react-video-thumbnail";
import useFileUpload from "hooks/useFileUpload";
import Stores from "Stores"
import VariantDel from "components/ContentBox/Global/GlobalModalSingle/templates/StudyTest/TestQuestion/components/VariantDel";
import st from "./FileSelector.module.scss"
import {ReactComponent as Basket} from "components/ContentBox/img/delete-mini-icon.svg";
import {ReactComponent as Eye} from "components/ContentBox/img/eye-mini-icon.svg";
import UploadIcon from 'components/ContentBox/img/upload-cloud-icon.svg'
import {ReactComponent as DocsIcon} from "components/ContentBox/img/new_document_img.svg"
import FileSelectModal from './components/FileSelectModal';
import useLocalization from "hooks/useLocalization";

const FileSelector = (props) => {
    const [error, setError] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const path = Stores.baseLink();
    const [serverFilePath, setServerFilePath] = useState('')
    const {refInput, progress, file, setFile} = useFileUpload({
        onUploadEnd(e) {
            setServerFilePath(`${path}${e}`)
            props.onChange(e)
        },
        url: 'ebooks/file_upload'
    })

    const {translate} = useLocalization({
      section: 'ebooks',
      subsection: 'modal_file_selector',
      dict: {
        "click_or_drag": "Click or drag file to this area to upload",
        "upload_info": "Support for a single upload",
        "prohibit_info": "Strictly prohibit from uploading company data or other band files",
        "supported_formats": "Supported formats"
      }
    })

    const getType = name => {
        let arr = []
        if (name) {
            arr = name.split('.')
            return arr[arr.length - 1].toUpperCase();
        } else {
            return 'ERR'
        }
    }

    const [src, setSrc] = useState('')
    const deleteHandler = () => {
        setFile(null)
        props.onChange(null)
    }

    const renderPreview = () => {
        switch (props.type) {
            case 'image':
                return (
                    <div className={st.preview}>
                        <div><img src={src} alt="" /></div>
                        <div className={st.iconBox}>
                            <div className={st.viewIcon}>
                                <Eye onClick={() => setShowModal(true)} />
                            </div>
                            <div className={st.deleteIcon}>
                                <Basket onClick={e => {
                                    e.stopPropagation()
                                    deleteHandler()
                                }} />
                            </div>
                        </div>
                    </div>
                )
            case 'audio':
                return (
                    <div className={st.previewAudio}>
                        <audio controls src={src}>
                            Your browser does not support the <code>audio</code> element.
                        </audio>
                        <VariantDel onClick={deleteHandler} />
                    </div>
                )
            case 'video':
                return (
                    <div className={st.preview}>
                        <div><VideoThumbnail videoUrl={src} /></div>
                        <div className={st.iconBox}>
                            <div className={st.deleteIcon}>
                                <Basket onClick={deleteHandler} />
                            </div>
                        </div>
                    </div>
                )
            case 'file':
                return (
                    <div className={st.previewFile}>
                        <div className={st.file}>
                            <DocsIcon />
                            <span>{getType(file.name)}</span>
                        </div>
                        <div>{file.name} </div>
                        <div className={st.iconBox}>
                            <div className={st.viewIcon}>
                                <a href={serverFilePath} target='_blank' >
                                    <Eye />
                                </a>
                            </div>
                            <div className={st.deleteIcon}>
                                <Basket onClick={e => {
                                    e.stopPropagation()
                                    deleteHandler()
                                }} />
                            </div>
                        </div>
                    </div>
                )
            default:
                return null
        }
    }
    const checkType = (file, allowedTypes) => allowedTypes.includes(getType(file.name).toLocaleLowerCase())
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    useEffect(() => {
        const src = file ? URL.createObjectURL(file) : Stores.baseLink() + props.value
        setSrc(src)
    }, [file, props.value])

    const onDrop = (e) => {
        e.preventDefault()
        const droppedFiles = e.dataTransfer.files
        if (droppedFiles.length > 0 && checkType(droppedFiles[0], props.types)) {
            setError(false)
            setFile(droppedFiles[0])
        } else {
            setError(true)
        }
    }
    const onDragOver = (e) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };
    return (
        <>
            {(file || props.value) && !error && !props.error ?
                <div className={st.box}
                >
                    {file && progress < 100 &&
                        <div className={st.progress}>
                            <progress max="100" value={progress} />
                            <div>{progress} %</div>
                        </div>}
                    {renderPreview()}
                    {showModal && <FileSelectModal
                        img={src}
                        setOpen={() => setShowModal(false)} />}
                </div> :
                <div onClick={() => {refInput.current.click()}} className={`${st.box} 
                ${error || props.error ? st.boxError : ''} ${isDraggingOver ? st.dragOver : ''}`}
                     onDrop={onDrop}
                     onDragOver={onDragOver}
                     onDragEnter={onDragOver}
                     onDragLeave={onDragLeave}
                >
                    <input type="file" ref={refInput} onChange={e => {
                        if (checkType(e.target.files[0], props.types)) {
                            setError(false)
                            setFile(e.target.files[0])
                        }
                        else setError(true)
                    }} accept={`${props.type}/*`} />
                    <img className={st.uploadIcon} src={UploadIcon} alt="" />
                    <div className={st.tip}>
                        <h4>
                            {translate.click_or_drag}
                        </h4>
                        <p>
                            {translate.upload_info}. {translate.prohibit_info}.
                        </p>
                        <b>{translate.supported_formats}: </b><span>{props.types.join(', ')}</span>
                    </div>
                </div>
            }
        </>
    );
};

FileSelector.propTypes = {
    type: PropTypes.oneOf(['image', 'audio', 'video', 'file']).isRequired,
    value: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    onChange: PropTypes.func,
}

FileSelector.defaultProps = {
    type: 'image'
}

export default FileSelector
