import React from 'react';
import st from "./ExerciseTitle.module.scss";

const ExerciseTitle = ({section, ...props}) => {
  const color = section?.color || (section?.exercises ? section?.exercises[0].section.color : "");

  const titleStyles = color ? { color: color?.body_color } : {};

  return (
    <>
      <h3
        className={st.title}
        style={titleStyles}
        dangerouslySetInnerHTML={{__html: props.data.ex.condition_exercise}}
      />
    </>
  );
};

export default ExerciseTitle;
