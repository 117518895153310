import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../UI/Input/Input';
import ModalWrapper from '../../../../../UI/ModalWrapper/ModalWrapper';
import Button from '../../../../../UI/Button/Button';
import InputsWrapper from '../../../../../UI/InputsWrapper/InputsWrapper';
import InputCheckbox from '../../../../../UI/InputCheckbox/InputCheckbox';
import ColorPicker from '../../../../../UI/ColorPicker/ColorPicker';
import TextArea from '../../../../../UI/TextArea/TextArea';
import Flex from '../../../../../UI/Flex/Flex';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

function SlotTypesModal({id, title, isComment, isAccessible, comment, color, onClose, asNew}) {
  const { companySettings, usedColors } = slotsSettingsStore;
  const [checkboxValues, setCheckboxValues] = useState({isComment, isAccessible});
  const [inputValue, setInputValue] = useState(asNew ? '' : title);
  const [textareaValue, setTextareaValue] = useState(comment);
  const [colorValue, setColorValue] = useState(color);

  const handleCheckboxChange = (evt) => {
    const checkboxName = evt.target.name;
    const checked = evt.target.checked;
    setCheckboxValues({
      ...checkboxValues,
      [checkboxName]: checked,
    });
  };
  const handleTextAreaChange = (evt) => {
    const value = evt.target.value;
    setTextareaValue(value);
  };
  const handleColorChange = (color) => {
    setColorValue(color);
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    const data = {
      id: id,
      name: inputValue,
      comment: textareaValue,
      is_accessible: checkboxValues.isAccessible,
      is_comment: checkboxValues.isComment,
      is_active: true,
      color: colorValue,
      company_settings: companySettings,
    };
    asNew ? slotsSettingsStore.addSlotType(data) : slotsSettingsStore.setSlotTypes(data);
    onClose();
  };

  return (
    <ModalWrapper
      title={asNew ? 'Новый тип слота' : `Редактирование: ${title}`}
      onClose={onClose}
    >
      <InputsWrapper>
        <Input
          tag='li'
          label="Название"
          onChange={(evt) => setInputValue(evt.target.value)}
          onSubmit={handleSubmit}
          placeholder="Название"
          value={inputValue}
          isError={!inputValue}
        />

        <InputCheckbox
          tag='li'
          label="Комментарий обязателен для заполнения"
          name='isComment'
          selected={checkboxValues.isComment}
          onChange={handleCheckboxChange}
        />
        <InputCheckbox
          tag='li'
          label="Слоты доступны для регистрации клиентами"
          name='isAccessible'
          selected={checkboxValues.isAccessible}
          onChange={handleCheckboxChange}
        />
        <ColorPicker
          tag='li'
          title='Цвет отображения слота в таблицах расписания'
          color={color}
          onChange={handleColorChange}
          usedColors={usedColors}
        />
        <TextArea
          tag='li'
          name='comment'
          label='Комментарий'
          placeholder='Комментарий'
          value={textareaValue || ''}
          onChange={handleTextAreaChange}
        />
      </InputsWrapper>
      <Flex justify='end' withGap>
        <Button onClick={onClose} variant="white">Отменить</Button>
        <Button onClick={handleSubmit} isDisabled={!inputValue}>Сохранить</Button>
      </Flex>
    </ModalWrapper>
  );
}

SlotTypesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isComment: PropTypes.bool.isRequired,
  isAccessible: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  comment: PropTypes.string,
  asNew: PropTypes.bool,
};

SlotTypesModal.defaultProps = {
  asNew: false,
  title: 'Название',
  comment: '',
  color: '',
};

export default SlotTypesModal;

