import React, { useEffect, Fragment } from "react";
import ContentHeader from "../ContentHeader";
import THead from "./THead";
import Filter from "../Filter/Filter";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import { superAxiosRequest } from "axiosApi";
import { Link } from "react-router-dom";
import Loader from "components/ContentBox/Global/Loader/Loader";
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import Stores from "Stores";
import { localisationMaster } from "Localisation/Localisation";
import st from "./Table.module.scss";

const WriteOffByGroup = ({ match: { params } }) => {
  const {
    refWrapper,
    refSticky,
    refThead,
    filter,
    setFilter,
    data,
    setData,
    isLoading,
    setIsLoading,
  } = useTableFixedHeader();
  const cfg = {
    cols: [
      { name: localisationMaster("month", "modal_templates") },
      { name: localisationMaster("day", "modal_templates") },
      { name: localisationMaster("text_1", "reports/groups-money") },
      { name: localisationMaster("text_2", "reports/groups-money") },
      { name: `${localisationMaster("text_3", "reports/groups-money")}, тг.` },
      { name: `${localisationMaster("text_4", "reports/groups-money")}, тг.` },
    ],
  };

  const filterCfg = [
    {
      type: "select-custom",
      placeholder: localisationMaster("text_6", "reports/groups-money"),
      nameState: "operation",
      values: [
        { id: 1, name: localisationMaster("text_5", "reports/groups-money") },
        { id: 2, name: localisationMaster("text_4", "reports/groups-money") },
      ],
    },
    {
      type: "days-range-picker",
      nameState: ["date_from", "date_until"],
      format: "dd MMMM, yyyy",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    const apiConf = {
      method: "get",
      link: `ledger/group_operations_report/${params.id}`,
      params: {
        ...filter,
        ...(filter.date_from && {
          date_from: timeConverter(filter.date_from, "today"),
        }),
        ...(filter.date_until && {
          date_until: timeConverter(filter.date_until, "today"),
        }),
      },
    };
    superAxiosRequest(apiConf).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, [filter]);

  const { cleanSumm } = Stores;
  return (
    <>
      <ContentHeader
        title={`${localisationMaster("title", "reports/groups-money")}: ${
          data?.group_number || ""
        } - ${data?.group_name || ""}`}
      />
      <Filter
        config={filterCfg}
        drop={() => setFilter({})}
        getFilterParams={setFilter}
      />
      {isLoading ? (
        <Loader />
      ) : data?.months?.length ? (
        <div className={st.tableWrapper} ref={refWrapper}>
          <div ref={refSticky} className={st.sticky}>
            <table className={st.table}>
              <THead cols={cfg.cols} />
            </table>
          </div>
          <table className={`${st.table} ${st.tbodyStriped}`}>
            <THead cols={cfg.cols} ref={refThead} />
            <tbody className={st.tbody}>
              {data?.months
                ? data.months.map((month, idxMonth) => {
                    const operations = month.days
                      .map((el) => el.operations)
                      .flat();
                    return (
                      <Fragment key={idxMonth}>
                        {month.days.map((day, idxDay) => {
                          return (
                            <Fragment key={idxDay}>
                              {day.operations.map((op, idxOp) => (
                                <tr key={idxOp} className={st.bdt}>
                                  {idxDay === 0 && idxOp === 0 ? (
                                    <td
                                      className={st.fw500}
                                      rowSpan={
                                        operations.length + month.days.length
                                      }
                                      style={{ background: "white" }}
                                    >
                                      {month.name}
                                    </td>
                                  ) : null}
                                  {idxOp === 0 ? (
                                    <td
                                      className={`${st.fw500} ${st.fz12}`}
                                      rowSpan={day.operations.length}
                                    >
                                      {day.day}
                                    </td>
                                  ) : null}
                                  <td>
                                    <Link
                                      to={`/account_clients/${op.client.id}`}
                                      target="_blank"
                                    >
                                      {op.client.last_name}{" "}
                                      {op.client.first_name}
                                    </Link>
                                  </td>
                                  <td className={`${st.fz12} ${st.tdMiddle}`}>
                                    {op.name}
                                  </td>
                                  <td
                                    className={`${st.tdContentVacPos} ${st.tdMiddle}`}
                                  >
                                    {`${cleanSumm(op.debit / 100)}  тг.`}
                                  </td>
                                  <td
                                    className={`${st.tdContentVacPos} ${st.tdMiddle}`}
                                  >
                                    {`${cleanSumm(op?.credit / 100)}  тг.`}
                                  </td>
                                </tr>
                              ))}
                              <tr className={st.bdt}>
                                <td
                                  className={`${st.tdTotal} ${st.tdMiddle} ${st.fw600}`}
                                  colSpan={3}
                                >
                                  {localisationMaster(
                                    "total",
                                    "reports/groups-money"
                                  )}
                                  : {month.name}
                                </td>
                                <td
                                  className={`${st.tdTotal} ${st.tdMiddle} ${st.fw600}`}
                                >
                                  {`${cleanSumm(
                                    month.total?.debit / 100
                                  )}  тг.`}
                                </td>
                                <td
                                  className={`${st.tdTotal} ${st.tdMiddle} ${st.fw600}`}
                                >
                                  {`${cleanSumm(
                                    month.total?.credit / 100
                                  )}  тг.`}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                        <tr className={st.bdt}>
                          <td
                            className={`${st.tdTotal} ${st.tdMiddle}`}
                            colSpan={4}
                          >
                            {localisationMaster(
                              "total",
                              "reports/groups-money"
                            )}
                          </td>
                          <td
                            className={`${st.tdTotal} ${st.tdMiddle} ${st.fw600}`}
                          >
                            {`${cleanSumm(data.total?.debits / 100)}  тг.`}
                          </td>
                          <td
                            className={`${st.tdTotal} ${st.tdMiddle} ${st.fw600}`}
                          >
                            {`${cleanSumm(data.total?.credits / 100)} тг.`}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>
          {localisationMaster(
            "warning",
            "c/tests/placement-test/create-widget"
          )}
        </h3>
      )}
    </>
  );
};

export default WriteOffByGroup;
