import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";
import {checkPermList} from "helpers";
import modalStore from "Stores/modalStore";
import st from "./ContentHeader.module.scss";
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";
import {ReactComponent as GroupStatusIcon} from "components/UI/icons/group-status-icon.svg";
import asideModalStore from "Stores/asideModalStore";


const ContentHeader = props => {
  useEffect(() => {
    document.title = `${props.title || ''} | PHOENIX # InterPress`;
  }, [props.title])

  return (
    <div className={st.contentHeader}>
      <div className={st.left}>
        <div className={st.title}>
          <h1>{props.title}</h1>
          {props.userID && <div className={st.userID}>ID: {props.userID}</div>}
        </div>
      </div>
      <div className={st.actions}>
        {(props.modal_type === 'GlobalSuperModal' &&
          ((typeof props.button[1] === "object" && checkPermList(props.button[1])) || typeof props.button[1] !== "object")) &&
          <button onClick={() => modalStore.activity(props.modal_settings, {
            ...(props.modal_settings.getMatchId && {[props.modal_settings.getMatchId]: +props.link_id}),
            ...(props.modalValues && props.modalValues),
          })}>
            <PlusIcon/>
            <span>{props.button[0]}</span>
          </button>}

        {(props.modal_type === 'asideModal' && checkPermList(props.button[1])) &&
          <button onClick={() => asideModalStore.activity(props.modal_settings, {
            ...(props.modal_settings.getMatchId && {[props.modal_settings.getMatchId]: +props.link_id}),
            ...(props.modalValues && props.modalValues),
          })}>
            <PlusIcon/>
            <span>{props.button[0]}</span>
          </button>}

        {props.btnGroupStatus &&
          <div className={`${st.groupStatus} ${st[`groupStatus--${props.btnGroupStatus.status.id}`]}`}
               onClick={props.btnGroupStatus.onClick}>
            <GroupStatusIcon/>
            <span>{props.btnGroupStatus.status.name}</span>
          </div>}

        {props.linkButton &&
          <div className={st.linkButton}>
            <NavLink to={props.linkButton.link} target="_blank">{props.linkButton.text}</NavLink>
          </div>}

        {props.textButton &&
          <div className={st.actionButton} onClick={props.textButton.onClick}>{props.textButton.text}</div>}
      </div>
    </div>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string,
  textButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
}

export default observer(ContentHeader);
