import React, {useState} from 'react';
import s from './PuzzleGrid.module.scss'

const PuzzleGrid = ({onChange, modalValues}) => {

    const rows = Array(8).fill(null)
    const cols = Array(8).fill(null)
    const [hoverS, setHoverS] = useState({r: modalValues.items[0].data.rows, c: modalValues.items[0].data.columns})

    return (
        <div className={s.container}>
            {rows.map((_, rowIndex) =>
                cols.map((_, colIndex) => (
                    <div
                        key={`${rowIndex}-${colIndex}`}
                        onClick={() => {
                            if(rowIndex < 1 || colIndex < 1) return
                            onChange([{...modalValues.items[0], data: {rows: rowIndex + 1, columns: colIndex + 1}}], 'items')
                            setHoverS({r: rowIndex + 1, c: colIndex + 1})
                        }}
                        className={`${s.item} ${hoverS.r > rowIndex && hoverS.c > colIndex ? s.hovered : ''} `}
                    />
                ))
            )}
        </div>
    );
};

export default PuzzleGrid;