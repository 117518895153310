import React, {useEffect, useState} from "react";
import {superAxiosRequest} from "axiosApi";
import {observer} from "mobx-react";
import {splitNumberDigits} from "helpers/math";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import GroupSelector from "./GroupSelector";
import CheckboxToggle from "../../../CheckboxToggle/CheckboxToggle";
import MainSelector from "components/ContentBox/Global/GlobalModalSingle/templates/MainSelector";
import paymentImg from "components/ContentBox/img/add-payment.png";
import paymentAdminImg from "components/ContentBox/img/add-payment-admin.png";
import DatePicker from "react-datepicker";
import {translate as loc, dict} from "Localisation";
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import "./AddPayment.scss";


const initValues = {
  payment_date: dateFormat(new Date(), "YYYY-mm-dd"),
  // Дата действия счета (+2 дня)
  payment_expires: dateFormat(new Date((new Date()).getTime() + 2 * 24 * 60 * 60 * 1000), "YYYY-mm-dd"),
  price_sums: 1,
  bank: 1,
  hourType: 1,
  amount: 0,
  hours: 1,
  price_per_minute: 0,
  status_sending_mail: "need_mail",
  bonus_balance: 0,
}

const AddPayment = () => {
  const {data, changeValuesArray, getModalValues, check} = modalStore;

  const {isClient} = Stores

  const [values, setValues] = useState(initValues)
  const [payData, setPayData] = useState(false)

  const changeHandler = (value, key) => {
    setValues({...values, [key]: value});
  }

  const calculator = (value, type, sgr) => {
    let obj = {...values}
    switch (type) {
      case "hours":
        obj.amount = +(
          value *
          (values.hourType === 1 ? 45 : 60) *
          values.price_per_minute
        ).toFixed(2);
        obj.hours = value;
        break;
      case "price_sums":
        obj.amount = +(
          (values.hours *
            (values.hourType === 1 ? 45 : 60) *
            value.price_per_minute) /
          100
        ).toFixed(2);
        obj.price_sums = value.id;
        obj.price_per_minute = value.price_per_minute / 100;
        if (sgr) {
          obj.student_group_rel = sgr;
          delete obj.company;
        }
        break;
      case "hourType":
        obj.amount = +(
          values.hours *
          (value === 1 ? 45 : 60) *
          values.price_per_minute
        ).toFixed(2);
        obj.hourType = value;
        break;
      case "amount":
        obj.amount = +value;
        if (values.price_per_minute > 0 && +value > 100)
          obj.hours =
            value / values.price_per_minute / (values.hourType === 1 ? 45 : 60);
        break;
      default:
        break;
    }
    setValues(obj)
  }

  const changeGroup = (data) => {
    if (data.student_group_rel > 1) {
      const ps = {
        ...data.default_price,
      };
      calculator(ps, "price_sums", data.student_group_rel);
    } else {
      const bs = {...values};
      bs.company = data.company.id;
      delete bs.student_group_rel;
      setValues({...bs});
    }
  }

  const formatOptionLabel = (opt, {context}) => {
    const str = `${opt.name} ${context === "menu" ? "<br/>" : ""
      }(<b>${splitNumberDigits(opt.price_sum)}.00</b>тг. за ${opt.hours_sum} ${opt.hours_type.id === 1 ? "ак.ч." : "астр.ч."
      })`;

    return <span dangerouslySetInnerHTML={{__html: str}} />;
  }

  useEffect(() => {
    let mount = true
    superAxiosRequest({
      method: "get",
      link: "payments/pay_orders/info",
      params: {
        client_id: data.client_id,
      },
    }).then((res) => {
      if (!mount) return
      setPayData(res.data)
      if (getModalValues.student_group_rel) {
        const g = res.data.groups.find(x => x.id === getModalValues.student_group_rel)
        if (g)
          setValues({
            ...values,
            student_group_rel: g.student_group_rel,
            price_sums: g.default_price.id,
            hours:
              getModalValues.amount / g.default_price.price_per_minute / 45,
            price_per_minute: g.default_price.price_per_minute / 100,
            amount: +g.default_price.sum_discount,
          });
      }
      if (getModalValues.company) {
        const c = res.data.company_balances.find(
          (x) => x.company.id === +getModalValues.company
        );
        if (c) setValues({...values, company: c.company.id, amount: 0});
      }
      // Если ничего не выбрано, то выбираем первую группу, а если нет группы - первую компанию
      if (!getModalValues.student_group_rel && !getModalValues.company) {
        if (res.data.groups.length)
          setValues({
            ...values,
            student_group_rel: res.data.groups?.[0]?.student_group_rel,
          });
        else
          setValues({
            ...values,
            company: res.data.company_balances?.[0]?.company.id,
          });
      }
    })

    return () => {
      mount = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let bo = {
      payment_date: values.payment_date,
      payment_expires: values.payment_expires,
      ...(values.student_group_rel && {
        student_group_rel: values.student_group_rel,
      }),
      ...(values.company && {company: values.company}),
      ...(values.location && {location: values.location}),
      study_start: values.study_start,
      study_finish: values.study_finish,
      bank: values.bank,
      amount: values.bonus_balance ? +((values.amount - values.bonus_balance) * 100).toFixed(2) : +(values.amount * 100).toFixed(2),
      user: data.client_id,
      comment: values.comment,
      status_sending_mail: values.status_sending_mail,
      bonus_balance: +(values.bonus_balance * 100).toFixed(2)
    };

    // Если выбрана компания, то Локация обязательна
    data.valid = {
      amount: "positive",
      ...(bo.company && {location: "required"}),
    }
    changeValuesArray(bo)
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    if (data.isUnidance) {
      if (values.company) {
        superAxiosRequest({
          method: "get",
          link: `db/companies/${values.company}`,
        }).then((res) => {
          setValues({...values, locations: res.data.locations})
        })
      }
    }
  }, [values.company])

  return (
    <div className="modalAddPayment">
      <GroupSelector
        onChange={changeGroup}
        groups={payData.groups || []}
        companies={payData.company_balances || []}
        value={values.student_group_rel || values.company}
      />
     {values.company  && (
        <div className="modalAddPayment__field">
          <p className="superModalGroup__label">Выберите локацию:</p>
          <SelectCustom
            placeholder="Локация"
            options={ data.isUnidance ? values.locations :  payData.company_balances.find(
              (x) => x.company.id === values.company
            ).company.locations }
            sortKey="name"
            valueID={values.location}
            onChange={(o) => {
              changeHandler(o.id, "location")
            }}
            error={check("location")}
          />
        </div>
      )}

      <MainSelector image={isClient ? paymentImg : paymentAdminImg}>
        {!isClient ? (
          <div className="modalAddPayment__row">
            <div className="modalAddPayment__field">
              <p className="superModalGroup__noReqLabel">
                Цена студента в группе:
              </p>
              <SelectCustom
                placeholder="Цена в группе"
                apiLink="groups/price_sums"
                valueID={values.price_sums}
                apiConfParams={{limit: 999}}
                getOptionLabel={opt => `${opt.name}  (${opt.price_sum}.00тг. за ${opt.hours_sum} ${opt.hours_type.id === 1 ? 'ак.ч.' : 'астр.ч.'})`}
                formatOptionLabel={formatOptionLabel}
                sortKey="name"
                onChange={o => calculator(o, 'price_sums')}
                isSearchable
              />
            </div>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__noReqLabel">Тип часов:</p>
              <SelectCustom
                placeholder="Академические / Астрономические"
                options={[
                  {id: 1, name: "Академические"},
                  {id: 2, name: "Астрономические"},
                ]}
                valueID={values.hourType}
                onChange={(o) => calculator(o.id, "hourType", "int")}
                isLoading={false}
              />
            </div>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__noReqLabel">Количество часов:</p>
              <DummyCounter name="hours" value={values.hours} onChange={calculator} step={1} min={.5} max={2000} />
            </div>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">{loc(dict.pays.text_4)}:</p>
              <input
                placeholder="Сумма"
                name="valueInput"
                type="number"
                value={values.amount}
                onChange={(e) => calculator(+e.target.value, "amount")}
                className={
                  check("amount") ? "super-modal__input--error" : undefined
                }
              />
            </div>
          </div>
        ) : (
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">{loc(dict.pays.text_4)}:</p>
              <input
                placeholder="Сумма"
                name="valueInput"
                type="number"
                value={values.amount}
                onChange={(e) => calculator(+e.target.value, "amount")}
                className={check("amount") ? "super-modal__input--error" : ""}
              />
            </div>
          )}
        <div className="modalAddPayment__total">
          {!isClient &&
            `${isNaN(+values.hours.toFixed(2)) ? '' : values.hours.toFixed(2)} ${values.hourType === 1 ? "ак" : "астр"
            }.ч. * ${Stores.cleanSumm(
              values.price_per_minute * (values.hourType === 1 ? 45 : 60)
            )} тг. =`}
          &nbsp;
          <strong>{Stores.cleanSumm(values.bonus_balance ? values.amount - values.bonus_balance : values.amount)}</strong>
          &nbsp; {loc(dict.pays.text_5)} {!isClient && loc(dict.main_page.text_36)}
        </div>
      </MainSelector>

      {!isClient && (
        <>
          <div className="modalAddPayment__row">
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">Дата оплаты:</p>
              <div className="react-datepicker-custom">
                <DatePicker
                  selected={new Date(values?.payment_date)}
                  locale="ru"
                  dateFormat="dd MMM yyyy"
                  onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), "payment_date")}
                />
              </div>
            </div>
            <div className="modalAddPayment__field">
              <p className="superModalGroup__label">Дата действия счета:</p>
              <div className="react-datepicker-custom">
                <DatePicker
                  selected={new Date(values?.payment_expires)}
                  locale="ru"
                  dateFormat="dd MMM yyyy"
                  onChange={date => changeHandler(dateFormat(date, "YYYY-mm-dd"), "payment_expires")}
                />
              </div>
            </div>
          </div>
          <div className="modalAddPayment__field">
            <p className="superModalGroup__noReqLabel">Комментарий платежа:</p>
            <textarea
              value={values.comment}
              onChange={({target: {value}}) => changeHandler(value, "comment")}
              className="placementTestBox__textarea"
              placeholder="Комментарий"
            />
          </div>
          <div className="modalAddPayment__line">
            <CheckboxToggle
              right="Уведомить клиента о выставлении счёта"
              onChange={() =>
                changeHandler(
                  values.status_sending_mail === "need_mail"
                    ? "not_mail"
                    : "need_mail",
                  "status_sending_mail"
                )
              }
              checked={values.status_sending_mail === "need_mail"}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default observer(AddPayment);
