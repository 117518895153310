import React, { useEffect, useRef, useState } from 'react';
import st from "./MatchWords.module.scss";
import { classNames as cn } from 'helpers';

export default function MatchWords(props) {
  let clicked

  const [leftWords, setLeftWords] = useState([])
  const [rightWords, setRightWords] = useState([])
  const [result, setResult] = useState([])

  const onClickLeftHandler = (e, index) => {
    clicked = index
    e.target.classList.add(st.matchWords__word_clicked)
  }

  const onClickRightHandler = (e, index) => {
    if (clicked !== undefined) {
      let arr = result.slice()
      arr[clicked] = [leftWords[clicked], rightWords[index]].join('~~~')
      setResult(arr)

      e.target.classList.add(st.matchWords__word_clicked)

      const leftBlock = refLeft.current.children[clicked]
      const from = {
        x: leftBlock.offsetLeft + leftBlock.offsetWidth,
        y: leftBlock.offsetTop + leftBlock.offsetHeight / 2
      }
      const rightBlock = refRight.current.children[index]
      const to = {
        x: rightBlock.offsetLeft,
        y: rightBlock.offsetTop + rightBlock.offsetHeight / 2
      }

      const svg = refLines.current.children[clicked]
      const path = svg.firstChild

      path.setAttribute('d', `M${from.x},${from.y} L${to.x},${to.y}`)
    }
  }

  const drawLines = index => {

    const leftBlock = refLeft.current.children[index]
    const from = {
      x: leftBlock.offsetLeft + leftBlock.offsetWidth,
      y: leftBlock.offsetTop + leftBlock.offsetHeight / 2
    }
    const rightBlock = refRight.current.children[index]
    const to = {
      x: rightBlock.offsetLeft,
      y: rightBlock.offsetTop + rightBlock.offsetHeight / 2
    }

    const svg = refLines.current.children[index]
    const path = svg.firstChild

    path.setAttribute('d', `M${from.x},${from.y} L${to.x},${to.y}`)
  }

  useEffect(() => {
    if (props.studentAnswer) {
      setLeftWords(props.studentAnswer.answer.map(a => a.word_1))
      setRightWords(props.studentAnswer.answer.map(a => a.word_2))
    } else {
      setLeftWords(props.data.word_list_1)
      setRightWords(props.data.word_list_2)
    }
  }, [props.data, props.studentAnswer])

  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    props.handleAnswers(result, result.length === leftWords.length)
  }, [result])

  useEffect(() => {
    setResult([])
    if (props.studentAnswer) {
      leftWords.forEach((a, i) => {
        drawLines(i)
      })
    } else {
      for (let svg of refLines.current.children) {
        svg.firstChild.setAttribute('d', 'M0,0 L0,0')
      }
    }
  }, [leftWords])

  const refLeft = useRef()
  const refRight = useRef()
  const refLines = useRef()

  useEffect(() => {
    if (refLeft.current && refRight.current) {
      const leftColumnChildren = Array.from(refLeft.current.children);
      const rightColumnChildren = Array.from(refRight.current.children);

      for (let index = 0; index < leftColumnChildren.length; index++) {
        const leftElement = leftColumnChildren[index];
        const rightElement = rightColumnChildren[index];

        const leftElementHeight = leftElement.clientHeight;
        const rightElementHeight = rightElement.clientHeight;

        const maxHeight = Math.max(leftElementHeight, rightElementHeight);

        leftElement.style.minHeight = `${maxHeight}px`;
        rightElement.style.minHeight = `${maxHeight}px`;
      }
    }
  }, [leftWords, rightWords]);

  const isRightAnswersList = props.studentAnswer?.answer.map(el => props.studentAnswer.result_in_detail[el.word_1])

  return (
    <div
      className={cn({
        [st.matchWords]: true,
      })}
    >
      <div className={st.matchWords__words}>
        <div ref={refLeft} className={st.matchWords__col}>{leftWords.map((word, index) =>
          <span
            key={index}
            className={`${st.matchWords__word} ${props.studentAnswer ? st.clicked : ''} ${isRightAnswersList ? (isRightAnswersList[index] ? st.right : st.mistake) : ''}`}
            onClick={e => {
              if (props.studentAnswer) return
              onClickLeftHandler(e, index)
            }}>
            {word}
          </span>
        )}
        </div>
        <div ref={refRight} className={st.matchWords__col}>
          {rightWords.map((word, index) =>
            <span
              key={index}
              className={`${st.matchWords__word} ${props.studentAnswer ? st.clicked : ''} ${isRightAnswersList ? (isRightAnswersList[index] ? st.right : st.mistake) : ''}`}
              onClick={e => {
                onClickRightHandler(e, index)
              }}
            >
              {word}
            </span>
          )}
        </div>
      </div>
      <div ref={refLines} className={st.matchWords__line}>
        {leftWords.map((_, index) =>
          <svg key={index} preserveAspectRatio="none" className={isRightAnswersList ? (isRightAnswersList[index] ? st.right_line : st.mistake_line) : ''}>
            <path d="M0,0 L0,0" />
          </svg>
        )}
      </div>
    </div>
  )
}
