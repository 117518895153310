import FileUploader from './FileUploader/FileUploader';
import React, {useEffect, useState} from 'react';
import styles from "./DocsTab.module.scss"
import Document from './Document/Document';
import {superAxiosRequest} from 'axiosApi';
import {checkPermList} from 'helpers';
import {permissions} from 'settings/permissions';
import notFoundDocs from "components/ContentBox/img/not_found_docs.svg"
import Loader from "../../../../../UI/Loader/Loader";
import {BlockHeader} from "../../../../../UI/BlockHeader/BlockHeader";
import useLocalization from "hooks/useLocalization";

const DocsTab = ({info}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const {translate} = useLocalization({section:'profile',subsection:'documents', dict: {
            "title": "Documents",
            "documents_not_found" : "You don't have any documents yet."
        }})

    useEffect(() => {
        const apiConf = {
            method: 'get',
            link: 'employees/file_documents',
            params: {
                user_id: info.id,
                limit: 999
            }
        }
        superAxiosRequest(apiConf).then(res => {
            setLoading(true)
            setData(res.data.result)
        }).finally(() => setLoading(false))
    }, [])


    return (

        loading ? <Loader/> :  <div>
            <div className={styles.container}>
                <BlockHeader title={translate.title}>
                    {checkPermList(permissions.add_filedocuments) && <FileUploader
                        params={{
                            user_id: info.id,
                        }}
                        data={{
                            user: info.id
                        }}
                        onChange={(file) => {
                            setData([...data, file])
                        }}/>}
                </BlockHeader>
                <div className={styles.body}>
                    {
                        data.length < 1 && <div className={styles.notFound}>
                            <img className={styles.imgNotFound} src={notFoundDocs} alt="not found"/>
                            <p>{translate.documents_not_found}</p>
                        </div>
                    }
                    <div>
                        {data[0] && data.map((item, i) => <Document key={i} data={item}/>)}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DocsTab;