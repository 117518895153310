import React from 'react';
import styles from './BlockHeader.module.scss'

export const BlockHeader = (props) => {
  const {title, children} = props;
  return (
    <div className={styles.header}>
      <div className={styles.headerName}>
        {title}
      </div>
      <div className={styles.headerRightBlock}>
        {children}
      </div>
    </div>
  );
};