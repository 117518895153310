import React from 'react';
import PropTypes from 'prop-types';
import {classNames as cn} from '../../../helpers';
import styles from './Flex.module.scss';

// md 768, xl 1024
function Flex({ tag, children, asColumn, withGap, gap, justify, align, md, classes, ...attrs }) {
  const Tag = tag;
  const customStyles = {
    gap: gap ? `${gap}px` : '',
  }

  return (
    <Tag
      className={cn({
        [styles.container]: true,
        [styles.column]: asColumn,
        [styles.gap]: withGap,
        [styles.center]: justify === 'center',
        [styles.start]: justify === 'start',
        [styles.end]: justify === 'end',
        [styles.align_center]: align === 'center',
        [styles.align_start]: align === 'start',
        [styles.align_end]: align === 'end',
        [styles.align_stretch]: align === 'stretch',
        [styles.md_center]: md === 'center',
        [classes]: classes,
      })}
      style={customStyles}
      {...attrs}
    >
      {children}
    </Tag>
  )
}

Flex.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
  tag: PropTypes.oneOf(['div', 'li', 'section', 'ul']),
  justify: PropTypes.oneOf(['center', 'start', 'end', '']),
  align: PropTypes.oneOf(['center', 'start', 'end', 'stretch', '']),
  md: PropTypes.oneOf(['center', 'start', 'end', 'space-between', '']),
  asColumn: PropTypes.bool,
  withGap: PropTypes.bool,
  classes: PropTypes.string,
};

Flex.defaultProps = {
  tag: 'div',
  asColumn: false,
  justify: '',
  align: '',
  md: '',
  withGap: false,
  classes: '',
};

export default Flex;
