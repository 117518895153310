import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import useModalValidate from "hooks/useModalValidate";
import {localisationMaster} from "Localisation/Localisation";
import {templates} from "./templates";
import Stores from "Stores";
import modalStore from 'Stores/modalStore'
import listingStore from 'Stores/listingStore';
import Loader from 'components/ContentBox/Global/Loader/Loader';
import cogoToast from 'cogo-toast';
import useLocalization from "hooks/useLocalization"
import {ReactComponent as Error} from "components/ContentBox/img/error.svg"
import {
    clientInfo,
    createPool,
    filesArr,
    forward,
    lessonQuestion,
    trio,
    ttd,
    userToGroup,
    noApi
} from './SuperModalFunctions';
import './SuperModal.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const GlobalSuperModal = () => {

    const {translate} = useLocalization({
        section: 'profile', subsection: 'modal_global', dict: {
          "error" : "Error",
          "cancel" : "Cancel"
        }
      })

    const modal_settings = new Proxy(modalStore.data, {})

    const {isValid} = useModalValidate(modalStore)

    const handleSubmit = (inner, setting) => {
        if (!isValid()) return

        const ms = { ...setting }

        let qJson = { ...inner },
            result = false,
            link = modal_settings.link,
            formData = new FormData(),
            apiConf,
            method = 'post';

        modalStore.loadingHandler(true)

        if (ms.is_put) method = 'put'

        apiConf = {method, link}

        switch (ms.type) {
            case 'lessons_question':
                result = lessonQuestion(qJson, apiConf)
                break;
            case 'addQuestionsPlacementTest':
                const types = ['audio', 'video', 'image'];
                types.forEach((key) => {
                    if(typeof qJson[key] === 'string' && qJson[key].startsWith('http')) {
                        qJson[key] =  qJson[key].replace("media/", "|").split("|")[1];
                    }
                });
                if(qJson.audio ||  qJson.video || qJson.image) {
                    qJson.question_data = JSON.stringify(qJson.question_data)
                    result = forward(qJson, apiConf, 'media')
                } else {
                    result = forward(qJson, apiConf)
                }
                break;
            case 'Informer':
            case 'Informer_vb':
                //Тут не понятно, разобрать позже
                //qJson = ms.forward
                modalStore.activity(0);
                break;
            case 'files-comments':
                result = filesArr(qJson, apiConf, ms)
                break;
            case 'time_table_note':
                result = ttd(qJson, apiConf, ms)
                break;
            case 'SelectorAndInput':
                result = trio(qJson, apiConf, ms)
                break;
            case 'client_info':
                result = clientInfo(qJson, apiConf, ms)
                break;
            case 'create_poll_test':
                result = createPool(qJson, apiConf, ms)
                break;
            case 'user_to_group':
                userToGroup(qJson, apiConf, ms)
                break;
            case 'AddAvatar':
            case 'AddMedia':
            case 'CourseForm':
                result = forward(qJson, apiConf, 'media')
                break;
            case 'change_active_fields':
                result = noApi(qJson)
                break;
            case 'change_crm_fields':
                result = noApi(qJson)
                break;
            case 'show_question_test_result':
                result = noApi(qJson)
                break;
            default:
                result = forward(qJson, apiConf)
                break;
        }
        if (result.type === 'noApi') {
            modalStore.activity();
            if (ms.func) ms.func(result.qJson);
        }
        else if (result) {
            if (listingStore.filterConf) {
                result.apiConf.params = Stores.observableArray(listingStore.filterConf)
                if(modalStore.data.filter) result.apiConf.params.filter_type = listingStore.filterType
            }
            let promise;
            if (result.type === 'media') {
                let arr = { ...result.qJson }
                /*for (let key in arr) {
                    formData.append(key, arr[key]);
                }*/
                Object.keys(arr).forEach(key => {
                    formData.append(key, arr[key])
                })
                promise = superAxiosRequest(result.apiConf, formData, 'media')
            }
            else if (result.type === 'ready') {
                promise = superAxiosRequest(result.apiConf, result.body)
            }
            else {
                promise = superAxiosRequest(result.apiConf, result.qJson)
            }

            promise.then(response => {
                modalStore.activity();
                if (ms.func) ms.func(response.data, result)
            }).catch(error => {
                modalStore.loadingHandler(false)
                if (ms.onSubmitError) {
                    ms.onSubmitError(error)
                }
                if (error.response && error.response.status === 400) {
                    const errors = error.response.data;

                    if (typeof errors === 'object'){
                        const errorKeys = Object.keys(errors)
                        modalStore.changeErrors(errorKeys)

                        const errorValues = Object.values(errors)
                        errorValues.forEach(e => {
                          const currentToast =  cogoToast.error(
                              <div className='cogo_toast_error'>
                                <div className='cogo_toast_message'>
                                 <Error />
                                  <span>{translate.error}</span>  
                                  <p>{e}</p>
                                  <button className="cogo_toast_button" onClick={() => currentToast.hide() }>OK</button>
                                </div>
                                <div className='cogo_toast_overlay' onClick={() => currentToast.hide() }></div>
                              </div>,
                              {
                                hideAfter: 0,
                              }
                            );
                          });
                    }
                }
            })
        }
    }

    const refInside = useRef(null)

    const closeModal = () => {
        refInside.current.addEventListener('transitionend', () => {
            modalStore.activity(0)
        }, { once: true })
        refInside.current.classList.add("super-modal-inside--slide-down")
        modalStore.deleteErrors();
        //modalStore.modalValue = {}
    }

    useEffect(() => {
        refInside.current.classList.remove("super-modal-inside--slide-down")
    }, [])

    return (
        <div className="super-modal">
            <div className="super-modal-inside super-modal-inside--slide-down"
                 style={{ width: modal_settings.width }}
                 ref={refInside}>
                <div className="super-modal-header">
                    <div className="super-modal-title">{modal_settings.title}</div>
                    <div className="super-modal-close" onClick={closeModal}>&#215;</div>
                </div>
                {!modalStore.loading ?
                <>
                <div className="super-modal-content">
                    {templates(modal_settings)}
                </div>

                <div className={modal_settings.disable_buttons ? "dn" : "super-modal__buttons"}>
                    {modal_settings.type !== 'Informer' &&
                        <div className="super-modal__cancel-button" onClick={closeModal}>
                            {translate.cancel}
                        </div>
                    }
                    {modal_settings.button !== false &&
                        <button
                            className="super-modal__add-button"
                            onClick={() => handleSubmit(modalStore.modalValue, { ...modal_settings })}
                            disabled={modalStore.loading}
                        >
                            {modal_settings.button}
                        </button>
                    }
                </div>
                </>
                : <div style={{display: 'flex', flexDirection:'row', alignItems:"center", justifyContent: 'center', height: '300px'}}><Loader /></div>}
            </div>
        </div>
    )
}

export default observer(GlobalSuperModal);
