import React, {useEffect, useRef, useState} from 'react';
import styles from "./CollapseWrapper.module.scss"
import {ReactComponent as ArrowIcon} from "components/UI/icons/arrow-down-s-line.svg";


const CollapseWrapper = ({title, children, isClosed, onClick, isOpen, isCollapsable}) => {
  const contentRef = useRef()

  const [height, setHeight] = useState()

  useEffect(() => {
    const childHeight = contentRef?.current?.children[0].clientHeight
    setHeight(childHeight)
  }, [isOpen])

  return (
    <li style={{"--height": `${height}px`}} className={styles.box}>
      <div onClick={onClick} className={styles.title}>
        {title}
        {!isClosed && isCollapsable === undefined &&
          <div className={isOpen ? `${styles.icon} ${styles.open}` : `${styles.icon}`}>
            <ArrowIcon/>
          </div>}
      </div>
      {isCollapsable === undefined &&
        <div className={isOpen ? `${styles.content} ${styles.open}` : `${styles.content}`} ref={contentRef}>
          {children}
        </div>}
    </li>
  )
}

export default CollapseWrapper
