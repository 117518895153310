import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import Paper from "components/UI/Paper/Paper";
import OpenHeader from "../../Groups/components/OpenHeader";
import st from "./TrainingLessons.module.scss";

export default function VisitHistory(props){
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open) return
    // История посещений
    props.fetchVisitHistory()
  }, [open, props.client])

  return (
    <Paper classes={st.box}>
      <OpenHeader title="История посещения" onClick={() => setOpen(!open)}/>
      {open &&
        <div>{props.visits.map(({id, schedule, client, master}) =>
          <div key={id} className={st.visitRow}>
            <div>
              <NavLink to={`/account_clients/${client?.profile_id}`} target="_blank">
                {`${client?.last_name} ${client?.first_name}`}
              </NavLink>
            </div>
            <div>{dateFormat(schedule?.date, "d MMM YYYY г")}</div>
            <div>{`с ${dateFormat(new Date(`${schedule?.date}T${schedule?.start_at}`), "hh:ii")} по ${dateFormat(new Date(`${schedule?.date}T${schedule?.finish_at}`), "hh:ii")}`}</div>
            <div>{schedule?.type}</div>
            <div>{`${master?.last_name} ${master?.first_name}`}</div>
          </div>)}
        </div>}
    </Paper>
  )
}
