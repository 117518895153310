import React, {useEffect, useState} from 'react';
import {translate as loc, dict} from "Localisation";
import TimeTableUnit from './TimeTableUnit'
import arrow from 'components/ContentBox/img/br_down.png';


const TimeTableList = props => {
    const [open, setOpen] = useState(false)
    const [listData, setListData] = useState([])

    const sorter = arr => arr.sort((a, b) => new Date(b.date_from) - new Date(a.date_from))

    const actualSorter = (arr, sortType) => { // возвращает актуальные расписания на сегодня или все расписания ( sortType = "allDay")
        let sortArray = []
        for (let i = 0; i < arr.length; i++) {
            if (new Date(arr[i].date_from) <= new Date().setHours(6, 0, 0, 0) && new Date().setHours(6, 0, 0, 0) <= new Date(arr[i].date_until)) { // проверка диапозона дат                
                if (arr[i].weekdays.find(currentValue => currentValue.day === ((new Date()).getDay() === 0 ? 7: (new Date()).getDay()) )) {
                    sortArray.push({...arr[i], active: true})
                } else sortType === 'allDay' && sortArray.push(arr[i])
            } else sortType === 'allDay' && sortArray.push(arr[i])
        }
        return sortArray
    }

    useEffect(() => {
        if (open) {
            setListData([...sorter(actualSorter(props.data, 'allDay'))])
        } else {
            setListData([...actualSorter(props.data)])
            actualSorter(props.data).length === 0 && setListData([...sorter(actualSorter(props.data, 'allDay'))])
        }

    }, [open,props.data])

    return (
        <>
            <div className="timetable-box__list ">
                {listData[0] && listData.map((item, index) =>
                    <TimeTableUnit active={item?.active} items={item} holidays={props.holidays} key={index} gid={props.gid}
                        location={props.location} />)}
            </div>
         
            {actualSorter(props.data).length !== 0 &&
              actualSorter(props.data).length !== sorter(actualSorter(props.data, 'allDay')).length  &&
              <div className={"timetable-calendar__more " + (open ? ' timetable-calendar__more--less' : '')}
                   onClick={() => setOpen(!open)}>
                <img src={arrow} alt="" />
                  {open ? loc(dict.group.hide_all_schedules) : loc(dict.group.show_all_schedules)}
                <img src={arrow} alt="" />
            </div>}
        </>
    )
}

export default TimeTableList
