import React, { useCallback, useEffect, useState } from "react";
import {localisationMaster} from "Localisation/Localisation";
import {classNames as cn} from "helpers";
import InputInn from "components/ContentBox/Global/InputInn/InputInn";
import TypeSelector from '../../../WorkPlain/TypeSelector';
import SelectDateBirth from "components/ContentBox/Global/SelectDateBirth/SelectDateBirth";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import { superAxiosRequest } from 'axiosApi';
import { makeid } from 'components/ContentBox/Global/GlobalModalSingle/SuperModalFunctions';
import Loader from "components/ContentBox/Global/Loader/Loader";
import {dict, translate as loc} from "Localisation";
import modalStore from "Stores/modalStore";

const genderList = [
    {id: 'M', name: localisationMaster('gender-1', 'company/profiles', 'add_user')},
    {id: 'F', name: localisationMaster('gender-2', 'company/profiles', 'add_user')}
]

const required = [ // Обязательные поля
    'last_name', 'first_name', 'inn',
    'date_of_birth', 'phone', 'email',
];

const body = {
    last_name: "",
    first_name: "",
    inn: "",
    date_of_birth: "",
    phone: "",
    mobile_phone: "",
    email: "",
    telegram: "",
    whatsapp: "",
};

const checkEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); // проверка символов в email

const CreateClient = ({ setTab }) => {
    const { changeValuesArray } = modalStore;
    const [newClient, setNewClient] = useState(body);
    const [errorFields, setErrorsFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [validInn, setValidInn] = useState(false); // проверка существование такого inn
    const [validEmail, setValidEmail] = useState({
        not_correct: false, // проверка символов в email
        similar: false, // проверка существование такого email
    });

    const changeClient = useCallback(
        (data, key) => setNewClient({ ...newClient, [key]: data }), 
        [newClient]
    );

    const check = useCallback((name) => errorFields.includes(name), [errorFields]);

    const generateEmail = () => changeClient(newClient.inn + "@interpress.kz", 'email');

    const generateWhatsapp = useCallback(() => {
        if (newClient.phone.length === 0) return;
        changeClient(newClient.phone, 'whatsapp');
    }, [newClient.phone]);

    const createClient = async () => {
        const requiredFields = required.filter((key) => newClient[key] === null || newClient[key] === "");
        
        if(requiredFields.length > 0) { // проверка обязательных полей
            return setErrorsFields(requiredFields);
        }

        const isEmail = checkEmail(newClient.email);

        if(!isEmail) { // проверка символов в email
            return setValidEmail({ ...validEmail, not_correct: true });
        };

        const apiValidConf = {
            method: "get",
            link: 'groups/check_inn_or_email',
        };

        const isEmailSimilar = await superAxiosRequest({ // проверка существование такого email 
            ...apiValidConf, 
            params: { email: newClient.email } 
        });
        const isInnSimilar = await superAxiosRequest({  // проверка существование такого inn
            ...apiValidConf, 
            params: { inn: newClient.inn } 
        });

        setValidEmail({ ...validEmail, similar: isEmailSimilar.data.email });
        setValidInn(isInnSimilar.data.inn);

        if(isEmailSimilar.data.email || isInnSimilar.data.inn) return; // проверка существование такого email и inn

        setIsLoading(true);

        superAxiosRequest(
            { method: 'post', link: 'accounts/clients' }, 
            { ...newClient, password: makeid() },
        ).then((response) => {
            const profile = response.data.profile;

            setIsLoading(false);
            changeValuesArray({
                client: profile.id,
                first_name: profile.first_name,
                last_name: profile.last_name,
                father_name: profile.father_name,
            });
            setTab(); // redirect => addClient component
        }); 
    }

    useEffect(() => {
        setErrorsFields([]);
        setValidEmail({ not_correct: false, similar: false });
        setValidInn(false);
    }, [newClient]);

    if(isLoading) return <Loader />
    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label--required">
                        {localisationMaster('text-4', 'online-schedule/record-customer-error')}:
                    </p>
                    <input 
                        placeholder={localisationMaster('text_9', 'modal_templates')}
                        onChange={e => changeClient(e.target.value, 'last_name')}
                        value={newClient.last_name}
                        className={cn({
                            'super-modal__input--error': check('last_name')
                        })}
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label--required">
                        {localisationMaster('text-2', 'online-schedule/record-customer-error')}:
                    </p>
                    <input
                        onChange={e => changeClient(e.target.value, 'first_name')}
                        placeholder={localisationMaster('text_8', 'modal_templates')}
                        value={newClient.first_name}
                        className={cn({
                            'super-modal__input--error': check('first_name')
                        })}
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">
                        {localisationMaster('text-44', 'online-schedule/record-customer-error')}:
                    </p>
                    <input
                        onChange={e => changeClient(e.target.value, 'father_name')}
                        placeholder={localisationMaster('text_10', 'modal_templates')}
                        value={newClient.father_name}
                    />
                </div>
                <div className="super-modal__form-field">
                    {!validInn && (
                        <p className="super-modal__form-label--required">
                            {localisationMaster('table-row-title-5', 'company/companies')}
                        </p>
                    )}
                    {validInn && (
                        <p 
                            className="super-modal__form-label--required"
                            style={{ color: '#db3031' }}
                        >
                            Такой {localisationMaster('table-row-title-5', 'company/companies')} уже существует
                        </p>
                    )}
                    <InputInn 
                        error={check('inn')}
                        value={newClient.inn}
                        onChange={(value) => changeClient(value, 'inn')} 
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label--required">
                        {localisationMaster('text-11', 'online-schedule/record-customer-error')}:
                    </p>
                    <SelectDateBirth
                        error={check('date_of_birth')}
                        value={newClient.date_of_birth}
                        onChange={(date) => changeClient(date, 'date_of_birth')}
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">
                        {localisationMaster('sex', 'modal_templates')}:
                    </p>
                    <TypeSelector
                        values={genderList}
                        like_obj
                        objectChangeHandler={(sex) => changeClient(sex, 'sex')}
                        selected={newClient.sex}
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label--required">
                        {localisationMaster('text_55', 'modal_templates')} 1:
                    </p>
                    <InputFlagPhone
                        error={check('phone')}
                        value={newClient.phone}
                        onChange={(value) => changeClient(value, 'phone')}
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">
                        {localisationMaster('text_55', 'modal_templates')} 2:
                    </p>
                    <InputFlagPhone
                        value={newClient.mobile_phone}
                        onChange={(value) => changeClient(value, 'mobile_phone')}
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    {!validEmail.similar && (
                        <p 
                            className="super-modal__form-label--required"
                            style={validEmail.not_correct ? { color: '#db3031' } : undefined}
                        >
                            E-mail: {validEmail.not_correct && <span>неправильно введен</span>}
                            {(newClient?.inn?.length > 2 && !validEmail.not_correct) && (
                                <span className="modalClientInfo__link" onClick={generateEmail}>
                                    Сгенерировать
                                </span>
                            )}
                        </p>
                    )}
                    {validEmail.similar && (
                        <p 
                            className="super-modal__form-label--required"
                            style={{ color: '#db3031' }}
                        >
                            Такой E-mail уже существует
                        </p>
                    )}
                    <input
                        onChange={(e) => changeClient(e.target.value, 'email')}
                        placeholder="E-mail" 
                        value={newClient.email}
                        className={cn({
                            'super-modal__input--error': check('email') || validEmail.not_correct
                        })}
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">
                        {localisationMaster('text_17', 'modal_templates')}:
                    </p>
                    <input 
                        onChange={(e) => changeClient(e.target.value, 'address')}
                        placeholder={localisationMaster('text_17', 'modal_templates')}
                        value={newClient.address}
                    />
                </div>
            </div>
            <div className="super-modal__form-row2">
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">Telegram:</p>
                    <input
                        placeholder={localisationMaster('tg_nik', 'modal_templates')}
                        onChange={(e) => changeClient(e.target.value, 'telegram')}
                        value={newClient.telegram}
                    />
                </div>
                <div className="super-modal__form-field">
                    <p className="super-modal__form-label">
                        WhatsApp: {' '}
                        {newClient.phone.length > 2 && (
                            <span className="modalClientInfo__link" onClick={generateWhatsapp}>
                                {loc(dict.main_page.text_22)}
                            </span>
                        )}
                    </p>
                    <InputFlagPhone
                        value={newClient?.whatsapp}
                        onChange={(value) => changeClient(value, 'whatsapp')}
                    />
                </div>
            </div>
            <button 
                className="super-modal__add-button"
                onClick={createClient}
            >
                Создать клиента
            </button>
        </div>
    )
}

export default CreateClient;
