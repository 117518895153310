import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterInput from 'components/ContentBox/Global/Filter/FilterInput';
import Flex from 'components/UI/Flex/Flex';
import Input from 'components/UI/Input/Input';
import InputPhone from 'components/ContentBox/Global/InputPhone/InputPhone';
import SelectDateBirth from 'components/ContentBox/Global/SelectDateBirth/SelectDateBirth';
import Select from 'components/UI/Select/Select';
import InputInn from 'components/UI/InputInn/InputInn';
import Hr from 'components/UI/Hr/Hr';
import { classNames as cn } from 'helpers';
import styles from './ClientData.module.scss';

const genderOptions = [
  {value: 'M', label: 'Мужской'},
  {value: 'F', label: 'Женский'},
];
const findOption = (value) => genderOptions.find((item) => item.value === value);

const checkErrors = (data, setErrors) => {
  const errors = {};
  for (let key in data) {
    if (!data[key].value && data[key].mandatory) {
      errors[key] = true;
    }
  }
  setErrors(errors);
};

function ClientFields({ type, data, onSetData, isOverwrite, withSearch, asClient }) {
  const isGuardian = type === 'guardian';
  const postfix = isGuardian ? 'опекуна' : 'клиента';
  const {
    first_name,
    last_name,
    father_name,
    email,
    phone,
    level,
    sex,
    birthday,
    inn,
    add_field1,
    add_field2,
    add_field3,
  } = data;

  const [errors, setIsErrors] = useState({})

  const fillClientData = (fillData) => {
    onSetData(fillData.profile.first_name, 'first_name', isGuardian);
    onSetData(fillData.profile.last_name, 'last_name', isGuardian);
    onSetData(fillData.profile.father_name, 'father_name', isGuardian);
    onSetData(fillData.email, 'email', isGuardian);
    onSetData(fillData.profile.phone, 'phone', isGuardian);
    onSetData(fillData.profile.date_of_birth, 'birthday', isGuardian);
    onSetData(fillData.profile?.sex, 'sex', isGuardian);
    onSetData(fillData.profile?.inn, 'inn', isGuardian);
    checkErrors(data, setIsErrors);
  };

  const handleInputChange = (evt) => {
    const type = evt.target.name;
    const value = evt.target.value;
    onSetData(value, type, isGuardian);
  };

  return (
    <>
      {isGuardian && <p className={styles.client_title}>Данные опекуна</p>}
      {type === 'client' && <p className={styles.client_title}>Данные клиента</p>}

      {withSearch &&
        <>
          <p className={`${styles.label} ${styles.custom_margin}`}>
            Поиск среди существующих {isGuardian ? 'опекунов' : 'клиентов'}
          </p>
          <FilterInput
            link="accounts/clients"
            clientId={fillClientData}
            placeholder="Начните вводить имя, телефон или e-mail клиента, минимум 2 символа"
            width="100%"
          />
        </>
      }

      <Flex withGap justify='start' classes={styles.inputs_wrapper}>
        {(!asClient || (asClient && first_name.active)) &&
          <Input
            label={asClient ? first_name.name : `Имя ${postfix}`}
            value={first_name?.value || ''}
            onChange={handleInputChange}
            placeholder={`Имя ${postfix}`}
            isRequired={first_name?.mandatory}
            name="first_name"
            disabled={isOverwrite}
            classes={styles.firstname}
            isError={errors?.first_name}
          />
        }

        {(!asClient || (asClient && phone.active)) && <div className={styles.phone}>
          <p className={styles.label}>
            {phone?.mandatory && <span>* </span>}
            {asClient ? phone.name : `Телефон ${postfix}`}
          </p>
          <InputPhone
            onChange={(value) => onSetData(value, 'phone', isGuardian)}
            value={phone?.value || ''}
            disabled={isOverwrite && phone?.value}
          />
        </div>}

        {(!isGuardian && (!asClient || (asClient && level.active))) &&
          <Input
            label={asClient ? level.name : 'Уровень письменного теста клиента'}
            value={level?.value || ''}
            onChange={handleInputChange}
            placeholder='Уровень письменного теста клиента'
            isRequired={level?.mandatory}
            name='level'
            classes={styles.level}
            isError={errors?.level}
          />
        }

        {(!asClient || (asClient && last_name.active)) && <Input
          label={asClient ? last_name.name : `Фамилия ${postfix}`}
          value={last_name?.value || ''}
          onChange={handleInputChange}
          placeholder={`Фамилия ${postfix}`}
          isRequired={last_name?.mandatory}
          name="last_name"
          disabled={isOverwrite}
          classes={styles.lastname}
          isError={errors?.last_name}
        />}

        {(!asClient || (asClient && email.active)) && <Input
          type="email"
          label={asClient ? email.name : `E-mail ${postfix}`}
          value={email?.value || ''}
          onChange={handleInputChange}
          placeholder={`E-mail ${postfix}`}
          isRequired={email?.mandatory}
          name="email"
          disabled={isOverwrite}
          classes={styles.email}
          isError={errors?.email}
        />}

        {(!asClient || (asClient && birthday.active)) && <div className={cn({
          [styles.birthday]: true,
          [styles.guardian]: isGuardian,
        })}>
          <p className={styles.label}>
            {birthday?.mandatory && <span>* </span>}
            {asClient ? birthday.name : `Дата рождения ${postfix}`}
          </p>
          <SelectDateBirth
            value={birthday?.value || ''}
            onChange={(date) => {
              onSetData(date, 'birthday', isGuardian)
            }}
            disabled={isOverwrite}
          />
        </div>}

        {(!asClient || (asClient && father_name.active)) && <Input
          label={asClient ? father_name.name : `Отчество ${postfix}`}
          value={father_name?.value || ''}
          onChange={handleInputChange}
          placeholder={`Отчество ${postfix}`}
          isRequired={father_name?.mandatory}
          name="father_name"
          classes={styles.fathername}
          disabled={isOverwrite}
          isError={errors?.father_name}
        />}

        {(!asClient || (asClient && inn.active)) && <InputInn
          label={`ИИН/ИНН ${postfix}`}
          value={inn?.value || ''}
          onChange={(inn) => onSetData(inn, 'inn', isGuardian)}
          isRequired={inn?.mandatory}
          classes={styles.inn}
          isDisabled={isOverwrite}
          isError={errors?.inn}
        />}

        {(!asClient || (asClient && sex.active)) && <Select
          options={genderOptions}
          placeholder={`Пол ${postfix}`}
          label={`Пол ${postfix}`}
          isRequired={sex?.mandatory}
          value={findOption(sex?.value)}
          onChange={(option) => {
            onSetData(option.value, 'sex', isGuardian)
          }}
          isDisabled={isOverwrite}
          classes={cn({
            [styles.sex]: true,
            [styles.guardian]: isGuardian,
          })}
        />}

        {!isGuardian &&
          <>
            {(!asClient || (asClient && add_field1.active)) && <Input
              label={asClient ? add_field1.name : 'Дополнительное поле'}
              value={add_field1?.value || ''}
              onChange={handleInputChange}
              placeholder="Дополнительное поле"
              isRequired={add_field1?.mandatory}
              name="add_field1"
              classes={styles.add1}
            />}
            {(!asClient || (asClient && add_field2.active)) && <Input
              label={asClient ? add_field2.name : 'Дополнительное поле'}
              value={add_field2?.value || ''}
              onChange={handleInputChange}
              placeholder="Дополнительное поле"
              isRequired={add_field2?.mandatory}
              name="add_field2"
              classes={styles.add2}
            />}
            {(!asClient || (asClient && add_field3.active)) && <Input
              label={asClient ? add_field3.name : 'Дополнительное поле'}
              value={add_field3?.value || ''}
              onChange={handleInputChange}
              placeholder="Дополнительное поле"
              isRequired={add_field3?.mandatory}
              name="add_field3"
              classes={styles.add3}
            />}
          </>
        }
      </Flex>
      {isGuardian && <Hr />}
    </>
  );
}

ClientFields.propTypes = {
  type: PropTypes.oneOf(['guardian', 'client', null]),
  withSearch: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onSetData: PropTypes.func.isRequired,
  isOverwrite: PropTypes.bool,
  asClient: PropTypes.bool,
};

ClientFields.defaultProps = {
  type: null,
  isOverwrite: false,
  withSearch: true,
  asClient: false,
};

export default observer(ClientFields);
