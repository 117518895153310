import React from 'react';
import {observer} from 'mobx-react';
//import modalStore from 'Stores/modalStore';
import usersStore from 'Stores/usersStore';
import ContractDoc from "./ContractDoc";
//import {checkPermList} from 'helpers';
//import {permissions} from 'settings/permissions';
import st from "./ContractDocs.module.scss";

const ContractDocs = (props) => {

  /*const addDocs = () => {
    let modal_settings = {
      name: 'course-lesson',
      link: 'employees/appendix',
      button: localisationMaster('button-two', 'company/age', 'add_age'),
      title: 'Добавить документы',
      type: 'SelectorAndInput',
      active_inputs: ['text', 'textarea', 'media'],
      data: 'media',
      media: true,
      name_media: 'file',
      pl_text: 'Имя документа',
      name_text: 'title',
      pl_textarea_tags: 'Комментарий',
      name_textarea_tags: 'description',
      media_type: 'Любой файл кроме .exe, .bat',
      func: usersStore.addUserDoc
    }
    let inner = {
      contract: props.contractID,
      employee_id: props.employeeID
    }
    modalStore.activity(modal_settings, inner)
  }*/

  return (
    <div className={st.contractDocs}>
      {/*<header>
        <div>
          {checkPermList(permissions.add_contract_docs) &&
            <button onClick={addDocs}>Загрузить</button>}
        </div>
      </header>*/}
      {usersStore.userDocslist.filter(x => x.contract === props.contractID).map(doc =>
        <ContractDoc key={doc.id} doc={doc}/>)}
    </div>
  )
}
export default observer(ContractDocs)