import React, {useEffect, useRef, useState} from 'react';
import Stores from 'Stores'
import st from './MatchItems.module.scss'

export default function MatchItems(props) {

  //let clicked

  const col1 = props.cols[0].value.split('|')
  const col2 = props.cols[1].value.split('|')

  const [clicked, setClicked] = useState()
  const [result, setResult] = useState([])

  const refLeft = useRef()
  const refRight = useRef()
  const refLines = useRef()

  const onClickLeftHandler = (e, index) => {
    //clicked = index
    setClicked(index)
    e.target.classList.add(st.clicked)
    // при повторном нажатии сбрасываем соединение
    if (result[index]) {
      setResult(result.map((r, i) => i === index ? null : r))
      const svg = refLines.current.children[index]
      const path = svg.firstChild

      path.setAttribute('d', `M0,0 L0,0`)
    }
  }

  const onClickRightHandler = (e, index) => {
    if (clicked !== undefined) {
      setResult(prevState => {
        const arr = [...prevState]
        arr[clicked] = [col1[clicked], col2[index]].join('|')
        return arr
      })

      e.target.classList.add(st.clicked)

      drawLine(clicked, index)
    }
  }

  const drawLine = (leftIndex, rightIndex) => {
    const leftBlock = refLeft.current.children[leftIndex]
    const from = {
      x: leftBlock.offsetLeft + leftBlock.offsetWidth,
      y: leftBlock.offsetTop + leftBlock.offsetHeight / 2
    }
    const rightBlock = refRight.current.children[rightIndex]
    const to = {
      x: rightBlock.offsetLeft,
      y: rightBlock.offsetTop + rightBlock.offsetHeight / 2
    }

    const svg = refLines.current.children[leftIndex]
    const path = svg.firstChild

    path.setAttribute('d', `M${from.x},${from.y} L${to.x},${to.y}`)
  }

  const layoutLines = () => {
    props.answers.forEach(a => {
      const [word1, word2] = a.split('|')
      const leftIndex = col1.findIndex(w => w === word1)
      const rightIndex = col2.findIndex(w => w === word2)
      drawLine(leftIndex, rightIndex)
    })
  }

  useEffect(() => {
    if (props.answers?.length === col1.length) {
      setResult(props.answers)
      const images = [
        ...refLeft.current.querySelectorAll('img'),
        ...refRight.current.querySelectorAll('img'),
      ]
      images.forEach(image => {
        image.addEventListener('load', layoutLines)
      })
      layoutLines()
    }
  }, [props.answers])

  useEffect(() => {
    if (result.length === col1.length)
      props.onComplete(result)
  }, [result])

  return (
    <div className={st.matchWords}>
      <div ref={refLeft} className={st.col}>
        {col1.map((word, index) => props.type === 'word_matching' ?
          <span key={index}
                className={st.word}
                onClick={e => {
                  onClickLeftHandler(e, index)
                }}>
            {word}
          </span> : <
            span key={index}
                 className={st.image}
                 onClick={e => {
                   onClickLeftHandler(e, index)
                 }}>
            <img src={Stores.baseLink() + word} alt=""/>
          </span>
        )}
      </div>
      <div ref={refRight} className={st.colRight}>
        {col2.map((word, index) => props.type === 'image_matching' ?
          <span
            key={index}
            className={st.image}
            onClick={e => {onClickRightHandler(e, index)}}>
            <img src={Stores.baseLink() + word} alt=""/>
          </span>
          :
          <span
            key={index}
            className={[st.word, result.map(r => r?.split('|')[1]).includes(word) ? st.clicked : ''].join(" ")}
            onClick={e => {onClickRightHandler(e, index)}}>
            {word}
          </span>
        )}
      </div>
      <div ref={refLines}>{col1.map((word, index) =>
        <svg key={index} preserveAspectRatio="none">
          <path d="M0,0 L0,0"/>
        </svg>)}
      </div>
    </div>
  );
};
