import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import InputToggle from '../../../../../UI/InputToggle/InputToggle';
import InfoIcon from '../../../../../UI/InfoIcon/InfoIcon';
import SettingsIcon from '../../../../../UI/SettingsIcon/SettingsIcon';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';
import styles from './AmoCrmToggle.module.scss';
import AmoCrmModal from './AmoCrmModal';
import Flex from '../../../../../UI/Flex/Flex';

function AmoCrmToggle({name, label, data}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const toggleModal = () => setIsModalShown(!isModalShown);

  return (
    <li className={styles.item}>
      { data.mandatory
        ? <span className={styles.label}>{label}</span>
        : <InputToggle
          label={label}
          name={name}
          selected={data.active}
          onChange={() => {slotsSettingsStore.toggleAmoToggles(name)}}
        />
      }
      <Flex withGap align='start' style={{minWidth: 60}}>
        <InfoIcon title={label} amo={data.id || ''} />
        { !data.mandatory && <SettingsIcon onClick={toggleModal} /> }
      </Flex>
      { isModalShown &&
        <AmoCrmModal
          label={label}
          id={data.id || ''}
          name={name}
          onClose={toggleModal}
        />
      }
    </li>
  );
}

AmoCrmToggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    mandatory: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['email', 'text', 'phone', 'date']).isRequired,
    id: PropTypes.string,
  }).isRequired,
};

export default observer(AmoCrmToggle);
