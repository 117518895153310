import React, { useEffect, useRef, useState } from 'react';
import styles from './Confirm.module.scss';

const fields = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
];

function CodeFields({ onChange, onSubmit }) {
    const [values, setValues] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
    const refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleChange = (evt) => {
        const number = +evt.target.dataset.number;
        const inputValue = evt.target.value.replace(/\D/g, '');
        if (inputValue.length > 0 && number < 5) {
            refs[number + 1].current.focus();
        }
        const result = { ...values, [number]: inputValue };
        setValues(result);
        onChange(+Object.values(result).join(''));
    };

    const handleKeyDown = (evt) => {
        if (evt.key === 'Enter') {
            onSubmit();
            return;
        }
        const isNeedToGoBack = evt.key === 'Backspace' || evt.key === 'Delete';
        const number = +evt.target.dataset.number;
        const result = { ...values, [number]: '' };
        setValues(result);
        onChange(+Object.values(result).join(''));
        if (isNeedToGoBack && number > 0) {
            evt.preventDefault();
            refs[number - 1].current.focus();
        }
    };

    const handlePaste = (evt) => {
        evt.preventDefault();
        let number = +evt.target.dataset.number;
        const dataArray = evt.clipboardData.getData('Text').replace(/\D/g, '').split('').slice(0, 6);
        const dataObj = { ...dataArray };
        const resultObj = {};
        for (let key in dataObj) {
            resultObj[number] = dataObj[key];
            number += 1;
        }
        const result = { ...values, ...resultObj };
        setValues(result);
        onChange(+Object.values(result).join(''));
        refs[dataArray.length > 5 ? 5 : dataArray.length].current.focus();
    };

    useEffect(() => {
        if (refs[0]) {
            refs[0].current.focus();
        }
    }, []);

    return (
        <ul className={styles.code_list}>
            {fields.map(({ id }) => (
                <li key={id.toString()}>
                    <input
                        ref={refs[id]}
                        data-number={id}
                        value={values[id]}
                        className={styles.code_input}
                        maxLength={1}
                        inputMode='numeric'
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                    />
                </li>
            ))}
        </ul>
    );
}

export default CodeFields;
