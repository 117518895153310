import React, {useState, useEffect} from 'react'
import { observer } from 'mobx-react'
import modalStore from '../../../../../../Stores/modalStore'
import FileList from './FileList'

const FilesAndComments = () => {
    
    return (
        <div className="filesCommens">
            <FileList />
        </div>
    )
}

export default observer(FilesAndComments);
