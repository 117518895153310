import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {dateFormat} from "helpers/date";
import modalStore from "Stores/modalStore";
import SelectCustom from "../../Select/SelectCustom";
import DatePicker from "react-datepicker";
import CheckboxToggle from "../../CheckboxToggle/CheckboxToggle";
import st from "./GroupsDayStatus.module.scss"
import { translate as loc, dict } from "Localisation";

const statuses = [
  {value: 2, label: loc(dict.group.free_lesson)},
  {value: 3, label: loc(dict.group.paid_pass)},
  {value: 5, label: loc(dict.group.unpaid_pass)},
  {value: 7, label: loc(dict.group.lesson)},
  {value: 9, label: loc(dict.group.closed_lesson)},
]

function GroupsDayStatus(){
  const {data: ms, modalValues, changeValues, changeValuesArray, check} = modalStore

  const [onlyTeacher, setOnlyTeacher] = useState(true)

  const onChangeStatus = opt => {
    changeValuesArray({
      ...modalValues,
      schoolday_status: opt.value,
      schooldaynote_status: onlyTeacher ? undefined : opt.value
    })
  }

  const onChangeOnlyTeacher = val => {
    setOnlyTeacher(val)
    changeValuesArray({
      ...modalValues,
      schooldaynote_status: val ? undefined : modalValues.schoolday_status
    })
  }

  useEffect(() => {
    ms.valid = {
      status_date: 'required',
      schoolday_status: 'required',
      comment: 'required',
    }

    changeValues(dateFormat(new Date(), "YYYY-mm-dd"), 'status_date')
  }, [])

  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-row2">
        <div className="super-modal__form-field">
          <div className="super-modal__form-label super-modal__form-label--required">Дата:</div>
          <div className={`react-datepicker-custom ${st.reactDatepickerCustom}`}>
            <DatePicker selected={new Date(modalValues.status_date || Date.now())}
                        onChange={val => changeValues(dateFormat(val, "YYYY-mm-dd"), 'status_date')}
                        dateFormat="dd.MM.yyyy"/>
          </div>
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label super-modal__form-label--required">Статус:</div>
          <div>
            <SelectCustom placeholder="Выберите статус"
                          valueID={modalValues.schoolday_status}
                          options={statuses}
                          onChange={onChangeStatus}
                          error={check('schoolday_status')}/>
          </div>
        </div>
      </div>
      <div className="super-modal__form-field">
        <CheckboxToggle right="Только для общего расписания"
                        mode="inline"
                        checked={onlyTeacher}
                        onChange={onChangeOnlyTeacher}/>
        <div>Изменения статусов дней затронут только общие расписания.
          <br/>Без изменения статусов у клиентов в группах.</div>
      </div>
      <div className="super-modal__form-field">
        <div className="super-modal__form-label super-modal__form-label--required">Комментарий:</div>
        <div>
          <textarea name="comment"
                    value={modalValues.comment}
                    onChange={e => changeValues(e.target.value, 'comment')}
                    className={check('comment') ? 'super-modal__input--error' : undefined}/>
        </div>
      </div>
    </div>
  )
}

export default observer(GroupsDayStatus)
