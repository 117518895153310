import React from 'react';
import { observer } from 'mobx-react';
import { classNames as cn } from 'helpers';
import styles from './BankList.module.scss';

function BankList({ banks, bank, setData, types: DataType }) {
  return (
    <ul className={styles.list}>
      {
        banks.map(({id, logo, name}) => (
          <li
            className={cn({
              [styles.item]: true,
              [styles.item_selected]: bank === id,
            })}
            key={id.toString()}
            onClick={() => setData(id, DataType.bank)}
          >
            <div className={styles.inner}>
              <div className={styles.image_wrapper}>
                <img src={logo} alt={name} width={150} height={55} />
              </div>
              <span className={cn({
                [styles.name]: true,
                [styles.name_kaspi]: name === 'Kaspi Bank'
              })}>
                {name}
              </span>
              <span className={styles.custom_radio} />
            </div>
          </li>
        ))
      }
    </ul>
  );
}

export default observer(BankList);
