import { countries } from './countries';

export const validMask = (val) => {
  const { type } = countries.find((item) => val.startsWith(item.type)) || countries[0];
  const result = { type, mask: val };

  let number = "";
  const cleanVal = val.replace(type, '').replace(/\D/g, "");
  if (type === "+7") {
    for (let i = 0; i < cleanVal.length; i++) {
      if (i === 0) {
        number = "(";
      } else if (i === 3) {
        number = number + ") ";
      } else if (i === 6) {
        number = number + "-";
      } else if (i === 8) {
        number = number + "-";
      } else if (i === 10) {
        number = number + "-";
      }
      number = number + cleanVal[i];
    }
    return { ...result, mask: number };
  } else if (type === "+996") {
    for (let i = 0; i < cleanVal.length; i++) {
      if (i === 0) {
        number = "(";
      } else if (i === 3) {
        number = number + ") ";
      } else if (i === 6) {
        number = number + "-";
      } else if (i === 9) {
        number = number + "-";
      }
      number = number + cleanVal[i];
    }
    return { ...result, mask: number };
  } else if (type === "+375" || type === "+380") {
    for (let i = 0; i < cleanVal.length; i++) {
      if (i === 0) {
        number = "(";
      } else if (i === 2) {
        number = number + ") ";
      } else if (i === 5) {
        number = number + "-";
      } else if (i === 7) {
        number = number + "-";
      }
      number = number + cleanVal[i];
    }
    return { ...result, mask: number };
  } else if (type === "+998") {
    for (let i = 0; i < cleanVal.length; i++) {
      if (i === 2) {
        number = number + "-";
      } else if (i === 5) {
        number = number + "-";
      }
      number = number + cleanVal[i];
    }
    return { ...result, mask: number };
  }
  return result;
}; 