import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../UI/Loader/Loader';
import { classNames as cn } from '../../../../../../helpers';
import styles from './LogoPreviewArea.module.scss';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import { observer } from 'mobx-react';

const LogoPreviewArea = ({isLoading}) => {
  const { registrationLogo, setRegistrationLogo } = slotsSettingsStore;
  const Content = () => (
    registrationLogo
      ? <img className={styles.img} src={registrationLogo} alt="Загруженный логотип"/>
      : <div className={styles.placeholder}>
          <p>Поддерживаемые форматы: <span>JPG, GIF, PNG, SVG</span></p>
          <p>Максимальный размер файла: <span>2Мб</span></p>
        </div>
  );

  return (
    <div 
      className={cn({
        [styles.wrapper]: true,
        [styles.wrapper__loading]: isLoading,
        [styles.wrapper__delete]: registrationLogo,
      })}
      onClick={() => registrationLogo && setRegistrationLogo(null)}
    >
      {isLoading ? <Loader /> : <Content />}
    </div>
  )
};

LogoPreviewArea.propTypes = {
  isLoading: PropTypes.bool,
};

export default observer(LogoPreviewArea);
