import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import modalStore from 'Stores/modalStore';
import MainSelector from '../MainSelector';
import SmartFilter from 'components/ContentBox/Global/Filter/SmartFilter';
import TypeSelector from '../WorkPlain/TypeSelector';
import CheckboxToggle from 'components/ContentBox/Global/CheckboxToggle/CheckboxToggle';
import TestRequest from './TestRequest';
import './taketest.scss'
import st from "./CreateTestPool.module.scss"
import shield from 'components/ContentBox/img/shield.svg'
import { localisationMaster } from 'Localisation/Localisation';
import { classNames as cn } from "helpers";


const CreateTestPool = () => {
    const { data, changeValues, getModalValues, check, errorColor, listInKeyChangeHandler } = modalStore;
    const [studentList, setStudentList] = useState(getModalValues.purposed_test?.students || [])
    const isRequest = data.under_request

    function getStudentList(value) {
        if(data.students) {
            setStudentList([...studentList, ...value]);
        } else {
            const { id, students } = value[0];
            changeValues(id, 'group');
            setStudentList(students.filter(el => el.status === 1));
        }
    }

    const changeHandler = (d, k, out) => {
        if(isRequest && !out){
            changeValues(d,k,'purposed_test')
        } else {
            changeValues(d,k)
        }
    }

    const returnTypeSelector = (index, sn) => {
        changeValues(index === 1, sn)
    }

    const
        dataStorage = localStorage.getItem('ud'),
        ud = JSON.parse(dataStorage),
        user_data = ud.user_data,
        profile = user_data.profile,
        time_zone = profile.time_zone

    useEffect(() => {
        if (!studentList.length) return
        if(isRequest){
            listInKeyChangeHandler(studentList.map(item => item.id_profile), 'students', 'purposed_test')
        } else {
            listInKeyChangeHandler(studentList.map(item => item.id_profile), 'students')
        }
    }, [studentList])

    useEffect(() => {
        data.valid = { ...data?.valid, name: 'required', students: 'required' };
    }, [])

    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-field">
                <div className="super-modal__form-label super-modal__form-label--required">{localisationMaster('text_1', 'study/study-test', 'modal')}</div>
                <div>
                    <input placeholder={localisationMaster('text_1', 'study/study-test', 'modal')}
                           onChange={(e) => changeHandler(e.target.value, 'name')}
                           value={isRequest ? getModalValues.purposed_test.name : getModalValues.name}
                           className={cn({
                            'super-modal__input--error': check('name') && errorColor, 
                           })}
                    />
                </div>
            </div>

            <div className="super-modal__form-field">
                <div className="super-modal__form-label super-modal__form-label--required">{!data.students ?
                    localisationMaster('text_2', 'study/study-test', 'modal') :
                    localisationMaster('text_3', 'study/study-test', 'modal')}
                </div>
                <MainSelector image={shield}>{!data.students ?
                    <SmartFilter 
                        link="groups/students"
                        placeholder={localisationMaster('text_2', 'study/study-test', 'modal')}
                        backFunction={getStudentList}
                        getApiList={list => { // показываем группы
                            const allGroups = list.filter(g => g.students.length > 0); // у которых есть студенты
                            return allGroups.filter(g => g.students.filter(s => s.status === 1).length); // и у студентов активный статус (status === 1)
                        }}
                        error={check('students')}       
                    />
                  :
                    <SmartFilter 
                        link="accounts/students_flat_list"
                        placeholder={localisationMaster('text_4', 'study/study-test', 'modal')}
                        new_key="full_name"
                        backFunction={getStudentList}
                        error={check('students')} 
                    />
                }
                </MainSelector>
            </div>

            {studentList.length > 0 &&
            <div className="cpt__students">
                <div className="cpt__students--title">{localisationMaster('text_5', 'study/study-test', 'modal')}:</div>

                <div className="cpt__students--list">
                    <ul>{studentList.map((item, index) =>
                      <li key={index}>
                          <strong>{index + 1}</strong>. {item.full_name}
                          <span onClick={() => setStudentList(studentList.filter(x => x.id !== item.id))}>х</span>
                      </li>)}
                    </ul>
                </div>
            </div>}

            {isRequest &&
            <TestRequest
                title={`${localisationMaster('text_6', 'study/study-test', 'modal')}:`}
                desctiption={`${localisationMaster('text_7', 'study/study-test', 'modal')}: GMT ${time_zone}`}
                handler={(d, k) => changeHandler(d, k, true)}
                value={getModalValues}
            />}

            <TestRequest 
                title={`${localisationMaster('text_15', 'study/study-test', 'modal')}:`}
                desctiption={`${localisationMaster('text_7', 'study/study-test', 'modal')}: GMT ${time_zone}`}
                handler={changeHandler}
                value={isRequest ? getModalValues.purposed_test : getModalValues}
            />

            <div>
                <div className="DurationNote">
                    <span className="DurationNote--title">{localisationMaster('text_8', 'study/study-test', 'modal')}</span>
                    <span className="DurationNote--note">{localisationMaster('text_9', 'study/study-test', 'modal')}</span>
                </div>
                <MainSelector image={shield} className={st.mainSelectorContent}>
                    <div className={st.option}>
                        <div className="cpt__options--line bn">
                            <span>{localisationMaster('text_10', 'study/study-test', 'modal')}:</span>
                            <div className="cpt__options--line--numbers">
                                <input type="number" value={isRequest ? getModalValues.purposed_test.during : getModalValues.during} onChange={(e) => changeHandler(e.target.value, 'during', true)} />
                            </div>
                        </div>
                        <div className="cpt__options--line bn">
                            <span>{localisationMaster('text_11', 'study/study-test', 'modal')}:</span>
                            <div className="cpt__options--line--numbers">
                                <input type="number" value={isRequest ? getModalValues.purposed_test.try_count : getModalValues.try_count} onChange={(e) => changeHandler(e.target.value, 'try_count', true)} />
                            </div>
                        </div>
                    </div>
                    <div className={st.option}>
                        <span className="cpt__options--inLIneNote">{localisationMaster('text_12', 'study/study-test', 'modal')}:</span>
                        <TypeSelector values={[localisationMaster('text_18', 'study/study-test', 'modal'), localisationMaster('text_19', 'study/study-test', 'modal')]}
                                      objectChangeHandler={returnTypeSelector}
                                      selected={isRequest ? getModalValues.purposed_test.shuffle === true ? 1 : 2 : getModalValues.shuffle === true ? 1 : 2}
                                      name='shuffle' />
                    </div>
                    <div className={st.option}>
                        <div className="cpt__options--lineIN">
                            <CheckboxToggle right={`${localisationMaster('text_13', 'study/study-test', 'modal')}:`} checked={isRequest ? getModalValues.purposed_test.focus_live : getModalValues.focus_live} onChange={(status => changeHandler(status, 'focus_live'))} />
                            <div className="cpt__options--line--numbers">
                                <input type="number"
                                       value={isRequest ? getModalValues.purposed_test.focus_live_during : getModalValues.focus_live_during}
                                       disabled={isRequest ? !getModalValues.purposed_test.focus_live : !getModalValues.focus_live}
                                       onChange={(e) => changeHandler(+e.target.value, 'focus_live_during', true)} />
                            </div>
                        </div>
                        <div className="cpt__options--lineIN">
                            <span className="cpt__options--lineIN--text">{localisationMaster('text_14', 'study/study-test', 'modal')}:</span>
                            <div className="cpt__options--line--numbers">
                                <input type="number"
                                       value={isRequest ? getModalValues.purposed_test.focus_live_count : getModalValues.focus_live_count}
                                       disabled={isRequest ? !getModalValues.purposed_test.focus_live : !getModalValues.focus_live}
                                       onChange={(e) => changeHandler(+e.target.value, 'focus_live_count', true)} />
                            </div>
                        </div>
                        <div className="cpt__options--lineIN">
                            <CheckboxToggle right={`${localisationMaster('text_17', 'study/study-test', 'modal')}:`}
                                            checked={isRequest ? getModalValues.purposed_test.mouse_freeze : getModalValues.mouse_freeze}
                                            onChange={(status => changeHandler(status, 'mouse_freeze'))} />
                            <div className="cpt__options--line--numbers">
                                <input type="number"
                                       value={isRequest ? getModalValues.purposed_test.mouse_freeze_during : getModalValues.mouse_freeze_during}
                                       disabled={isRequest ? !getModalValues.purposed_test.mouse_freeze : !getModalValues.mouse_freeze}
                                       onChange={(e) => changeHandler(+e.target.value, 'mouse_freeze_during', true)} />
                            </div>
                        </div>
                        <div className="cpt__options--lineIN cpt__options--lineIN--fs">
                            <span className="cpt__options--lineIN--text">{localisationMaster('text_14', 'study/study-test', 'modal')}:</span>
                            <div className="cpt__options--line--numbers">
                                <input
                                  type="number"
                                  value={isRequest ? getModalValues.purposed_test.mouse_freeze_count : getModalValues.mouse_freeze_count}
                                  disabled={isRequest ? !getModalValues.purposed_test.mouse_freeze : !getModalValues.mouse_freeze}
                                  onChange={(e) => changeHandler(+e.target.value, 'mouse_freeze_count', true)} />
                            </div>
                        </div>
                    </div>
                    <div className={st.option}>
                        <span className="cpt__options--inLIneNote">{localisationMaster('text_16', 'study/study-test', 'modal')}:</span>
                        <TypeSelector values={[localisationMaster('text_20', 'study/study-test', 'modal'), localisationMaster('text_21', 'study/study-test', 'modal')]}
                                      objectChangeHandler={returnTypeSelector}
                                      selected={isRequest ? getModalValues.purposed_test.type_show_test === true ? 1 : 2 : getModalValues.type_show_test === true ? 1 : 2}
                                      name='type_show_test' />
                    </div>
                </MainSelector>
            </div>
        </div>
    )
}

export default observer(CreateTestPool);
