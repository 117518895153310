import React, {useState} from "react"
import style from "./GroupsHoursBalances.module.scss"
import Stores from "Stores"

const GroupsHoursBalances = ({ groupschedules , menutesLeft, group, translate}) => {

  const [hourType, setHourType] = useState(45);

  const getBalancePercent = (total, remainder) => {
    total = parseFloat(String(total).replace(/\s/g, '').replace(',', '.'));
    remainder = parseFloat(
      String(remainder).replace(/\s/g, '').replace(',', '.')
    );
    return (remainder / total) * 100;
  };  

  const totalBalanceGroup = (group.student_info.status.default_price.price_sum / group.student_info.status.default_price.hours_sum / hourType) * (group.group_info.total_schedule_minutes)

  const convertClientBalance =  group.student_info.balance / 100
  
  const getCashBalance = (cash, maxValue) => {
    cash = parseFloat(String(cash).replace(/\s/g, '').replace(',', '.'));
    maxValue = parseFloat(
      String(maxValue).replace(/\s/g, '').replace(',', '.')
    );
    
    if(cash > 0) {
      return {
        width: `${((cash / maxValue) * 50)}% ` , 
        position: "absolute",
        right:'50%',
        backgroundColor:'#1abc97',
        height: '100%'
        

      }  
    }
    else{
      return {
        width: `${((-cash / maxValue) * 50)}%`,
        position: "absolute",
        left: '50%',
        backgroundColor:'#f02',
        height: '100%',
        borderRadius: '0 7px 7px 0'
      }
    }
  };  

  const getHoursSchedule = () => {
    if (isNaN(groupschedules)) return 0;

    const frac = groupschedules / hourType === parseInt(groupschedules / hourType) ? 0 : 2;
    return (groupschedules / hourType).toFixed(frac).replace('.', ',');
  };
  const getHoursLeft = () => {
    if (isNaN(menutesLeft)) return 0;
    const frac =
    menutesLeft / hourType === parseInt(menutesLeft / hourType) ? 0 : 2;
    return (menutesLeft / hourType).toFixed(frac).replace('.', ',');
  };

  return (
    <div className={style.groupSimpleMainBox__row}>              
        <div className={`${style.groupSimpleMainBox__card} `} onClick={() => setHourType(hourType === 45 ? 60 : 45)}  >
            <div className={style.groupSimpleBox__container} >
            <div className={style.groupSimpleBox__container__value} >
                <span> {getHoursLeft()} </span>
                <span className={style.delimiter}> </span>
                <span className={style.debtors}></span>
                <span>{getHoursSchedule()} </span>
            </div>
            <div className={style.progress}>
                <div className={style.progress__completed} style={{width: `${getBalancePercent(groupschedules, menutesLeft)}%`}}>

                </div>
                <div className={style.progress__remaind}></div>
            </div>
            <div className={style.groupSimpleBox__container__valueName}>
                <div className={style.groupSimpleBox__container__valueName__optionValue}>
                {hourType === 45 ? translate.ac_h : translate.astr_h}
                </div>
                <div>
                    <span>{hourType === 45 ?
                    translate.ac_h :
                    translate.astr_h } {translate.spent}</span>
                    <span className={style.delimiter}></span>
                    <span>{hourType === 45 ?
                translate.ac_h :
                translate.astr_h} {translate.scheduled}</span>
                </div>
            </div>
            </div>
        </div>


        <div className={`${style.groupSimpleMainBox__card} `}>
            <div className={style.groupSimpleBox__container}>
            <div className={style.groupSimpleBox__container__value}>
                <span>
                {group.student_info.balance > 0 ? Stores.cleanSumm( Math.abs(group.student_info.balance) / 100) : 0} 
                </span>
                <span className={style.delimiter}></span>
                <span>
                {group.student_info.balance < 0 ? Stores.cleanSumm((group.student_info.balance) / 100) : 0} 
                </span>
                <span className={style.debtors}></span>
            </div>
            <div className={style.progress}>
                <div className={style.progress__completed} 
                 style={getCashBalance(convertClientBalance, totalBalanceGroup)}></div>
                <div className={style.progress__remaind}></div>
            </div>
            <div
                className={style.groupSimpleBox__container__valueName}>
                <div className={style.groupSimpleBox__container__valueName__optionValue}>
                  ₸, Tenge
                </div>
                <div>
                  <span>{translate.group_balance}</span>
                  <span className={style.delimiter}></span>
                  <span>{translate.debt}</span>
                </div>
            </div>
            </div>
        </div>


     
    </div>
  )
}

export default GroupsHoursBalances