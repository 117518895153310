import React from "react";
import st from "./BlueLine.module.scss";
import {ReactComponent as AlertIcon} from "./alert-line.svg";

const BlueLine = props => <div className={st.blueLine}>
  <AlertIcon/>
  <span>{props.children}</span>
</div>

export default BlueLine
