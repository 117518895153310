import React from 'react';
import { checkPermList } from 'helpers';
import { permissions } from 'settings/permissions';
import icon from "components/ContentBox/img/telegram-white-icon.png";
import { ReactComponent as IconEdit } from 'components/ContentBox/img/edit2.svg'
import styles from './Telegram.module.scss';

const Telegram = ({ onClick }) => {
    const checkEdit = checkPermList(permissions.edit_telegram_link);
    return (
        <div className={`${styles.box} ${checkEdit ? '' : styles.client}`} onClick={onClick}>
            <img src={icon} alt="icon" />
            <div className={styles.content}>
                <p>Telegram</p>
                <p>канал группы</p>
            </div>
            {checkEdit && <IconEdit fill='white' />}
        </div>
    )
}

export default Telegram;