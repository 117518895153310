import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {localisationMaster} from "Localisation/Localisation";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import groupStore from "Stores/groupStore";
import ModalStore from "Stores/modalStore";
import ModalWorkshopTime, {initHours} from "./ModalWorkshopTime";
import ModalWorkshopClose from "./ModalWorkshopClose";
import st from "./WorkshopSchedule.module.scss"
import CreateTimeTable from "components/ContentBox/Global/GlobalModalSingle/templates/groups/CreateTimeTable/CreateTimeTable";
import Avatar from "../../../../../HeadBox/components/Avatar/Avatar";
import ActionMenu from "../../../../../UI/ActionMenu/ActionMenu";

export const weekDays = array => {
  const days = [
    localisationMaster('text_22', 'groups/all-groups'),
    localisationMaster('text_23', 'groups/all-groups'),
    localisationMaster('text_24', 'groups/all-groups'),
    localisationMaster('text_25', 'groups/all-groups'),
    localisationMaster('text_26', 'groups/all-groups'),
    localisationMaster('text_27', 'groups/all-groups'),
    localisationMaster('text_28', 'groups/all-groups')
  ];
  return array.map(w => days[w - 1])
}

const menu = [
    //permission: ['forward'] - временно убрал эти права для отображения в меню
  {action: 'edit', name: "Редактировать время"},
  {action: 'prolong', name: "Продление расписания"},
  {action: 'close', name: "Закрытие расписания"},
]

const getTotalHours = (days, ht, holidays) => {
  let total = 0
  days.forEach(day => {
    if (!holidays.some(h => h.date === day.date))
      total += new Date(`${day.date}T${day.finish_at}`) - new Date(`${day.date}T${day.start_at}`)
  })
  return Math.round(total / 1000 / 60 / ht)
}

export default function WorkshopSchedule({teachers, schedule}) {

  const {group: {holidays, group}, updateWSDays, updateWSData} = groupStore

  const [hoursType, setHoursType] = useState(45)

  const TeacherLink = ({teacher_id}) => {
    const teacher = teachers?.find(t => t.id === teacher_id)

    return (
        <div className={st.teacherInfo}>
          <div className={st.avatar}>
            {
              teacher.avatar
                  ? <img src="" alt=""/>
                  : <
                Avatar first_name={teacher.first_name} last_name={teacher.last_name}/>
            }
          </div>
          <div className={st.teacherName}>
            <NavLink to={`/account/${teacher.user_id}/`} target="_blank" key={teacher.id}>
              {Stores.transliterate(teacher.full_name)}
            </NavLink>
          </div>
        </div>
        )
  }
  const action = (type, e, sch) => {
    switch (type) {
      case "edit":
        ModalStore.activity({
          title: "Редактирование расписания",
          modalBodyComponent: () => <ModalWorkshopTime/>,
          button: "Сохранить",
          link: "schedule/update_time_ws",
          func: ({workshops}) => updateWSDays(workshops),
          width: '800px',
        }, {
          workshops: sch.days.map(d => d.id),
          start_at: sch.days[0].start_at,
          finish_at: sch.days[0].finish_at,
        })
        break;
      case "prolong":
        const weekdays = sch.weekdays
        const [hour_type, hours_per_day] = initHours(sch)
        const dateFrom = new Date(sch.dateUntil)
        do {
          dateFrom.setDate(dateFrom.getDate() + 1)
        } while (!weekdays.includes(dateFrom.getDay() || 7))
        ModalStore.activity({
          title: "Продление расписания",
          modalBodyComponent: () => <CreateTimeTable isProlong/>,
          button: "Продлить",
          link: 'schedule/workshops',
          payload: {
            holidays,
            location: group.location,
            group_id: group.id
          },
          valid: {
            classroom_id: 'required',
            teachers: 'not_empty_array',
          },
          width: '800px',
          func: updateWSData
        }, {
          hour_type,
          hours_total: 3,
          date_from: dateFormat(dateFrom, "YYYY-mm-dd"),
          start_at: sch.start_at,
          finish_at: sch.finish_at,
          group_id: group.id,
          teachers: sch.days[0].teachers,
          weekdays: sch.weekdays,
          hours_per_day,
          classroom_id: sch.classroom_id
        })
        break;
      case "close":
        const last = sch.days[sch.days.length - 1]
        const payload = {
          workshops: sch.days,
          dateUntil: new Date(`${last.date}T${last.start_at}`)
        }
        ModalStore.activity({
          title: "Редактирование расписания",
          modalBodyComponent: () => <ModalWorkshopClose {...payload}/>,
          button: "Закрыть",
          valid: {
            workshop_id: 'not_empty_array'
          },
          link: "schedule/ws_list_delete",
          func: updateWSData,
        }, {
          workshop_id: []
        })
        break
      default:
        break;
    }
  }

  return (
    <div className={st.teacherRow}>
      <div className={st.teacherRow__teachers}>
        {schedule.teachers.length ? schedule.teachers.map(teacher_id => <TeacherLink key={teacher_id} teacher_id={teacher_id}/>) : <div>Without Teacher</div>}
      </div>
      <div className={st.schedules}>{schedule.schedules.map(sch =>
        <div key={`${sch.start_at}_${sch.finish_at}`} className={st.scheduleActions}>
          <div className={st.scheduleGrid}>
            <div>
              {weekDays(sch.weekdays).join('/')}
            </div>
            <div>{sch.start_at.slice(0, 5)} - {sch.finish_at.slice(0, 5)}</div>
            <div>{dateFormat(sch.dateFrom, "dd.mm")} - {dateFormat(sch.dateUntil, "dd.mm")}</div>
            <div onClick={() => {
              setHoursType(hoursType === 45 ? 60 : 45)
            }} className={st.hoursType}>
              {getTotalHours(sch.days, hoursType, holidays)} {`${hoursType === 45 ? `ак` : `астр`}.ч.`}
            </div>
            <div>Audience: {sch.classroom_name}</div>
          </div>
          <div>
            <ActionMenu menu={menu} action={action} items={sch}/>
          </div>
        </div>)}
      </div>
    </div>
  )
}
