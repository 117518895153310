import React, {useEffect, useState} from 'react';
import styles from "./DocsConstructor.module.scss"
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import {superAxiosRequest} from "axiosApi";
import DocsCreator from '../DocsCreator/DocsCreator';
import upload_icon from "../../img/image-gallery-icon.svg"
import pdf_icon from "../../img/pdf.svg"

const DocsConstructor = (props) => {
    const [constructorState, setConstructorState] = useState({})
    const [docTitle, setDocTitle] = useState({
        value: 'Название шаблона',
        error: false
    })
    const mode = props.mode


    const elementTypesArray = [
        {title: 'Заголовок H1', type: 'title_h1'},
        {title: 'Заголовок H2', type: 'title_h2'},
        {title: 'Заголовок H3', type: 'title_h3'},
        {title: 'Текст', type: 'text'},
        {title: 'Форма ввода', type: 'text_input'},
        {title: 'Листинг нумерованный', type: 'listing_numbered'},
        {title: 'Листинг маркированный', type: 'listing_marked'},
        {title: 'Галочка согласия', type: 'checkbox_сonsent'},
        {title: 'Галочка согласия(список)', type: 'checkbox_list'},
        {title: 'Изображение', type: 'img'},
        {title: 'Pdf', type: 'pdf'},
        {title: 'Верхний колонтитул', type: 'header'},
        {title: 'Нижний колонтитул', type: 'footer'},
        {title: 'Кнопка-ссылка', type: 'link_btn'},
        {title: 'Ссылка', type: 'link'},
        {title: 'Подпись', type: 'sign'},
        {title: 'Инициалы', type: 'initials'},
        {title: 'Дата', type: 'date'},

    ]

    const saveDoc = (mode) => {
        let apiConf

        switch (mode) {
            case 'edit':
                apiConf = {
                    method: 'put',
                    link: props.link,
                }
                break;
            case 'create':
                apiConf = {
                    method: 'post',
                    link: props.link,
                }
                break;
            default:
                break;
        }

        const reqestData = Object.keys(constructorState).map(key => {
            let item = {...constructorState[key]}
            delete item.is_edit
            return item
        })
        let body = {
            name: docTitle.value,
            json_text: reqestData,
        }

        superAxiosRequest(apiConf, body).catch((err) => {
            if (err?.response.data.name[0]) {
                setDocTitle({...docTitle, error: err.response.data.name[0]})
            }
        })
    }
    const getStopEditState = () => {
        let newState = {...constructorState}
        Object.keys(newState).forEach(key => {
            newState[key].is_edit = false
        })
        return newState
    }

    const addEllement = (type) => {
        let newState = getStopEditState()
        let newEllementName
        const keysArray = Object.keys(constructorState)
        if (keysArray.length < 1) newEllementName = 1
        else newEllementName = Number(keysArray[keysArray.length - 1]) + 1

        setConstructorState({
            ...newState,
            [newEllementName]: JSONCreator(type)
        })
    }

    const JSONCreator = (type) => {
        let element
        switch (type) {
            case 'text':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'Введите текст',
                    styles: {
                        backgroundColor: 'white',
                        color: 'black',
                        borderBlockColor: 'rgba(0, 0, 0, 0)',
                        border: '1px solid rgba(0, 0, 0, 0)',
                    },
                }
                break;
            case 'text_input':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'Введите текст',
                    user_value: 'данные пользователя',
                    styles: {
                        backgroundColor: 'white',
                        color: 'black',
                        borderBlockColor: 'rgba(0, 0, 0, 0)',
                        border: '1px solid rgba(0, 0, 0, 0)',
                    },
                }
                break;
            case 'title_h1':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'Введите заголовок',
                }
                break;
            case 'title_h2':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'Введите заголовок',
                }
                break;
            case 'title_h3':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'Введите заголовок',
                }
                break;
            case 'link':
                element = {
                    type: type,
                    link: '/',
                    value: {
                        text: '',
                        placeholder: 'Текст ссылки',
                        link: '/'
                    },
                }
                break;
            case 'sign':
                element = {
                    type: type,
                    link: '/',
                    value: {
                        text: 'Фамилия И.О.',
                    },
                }
                break;
            case 'initials':
                element = {
                    type: type,
                    link: '/',
                    value: {
                        text: 'Фамилия И.О.',
                    },
                }
                break;
            case 'link_btn':
                element = {
                    type: type,
                    link: '/',
                    value: {
                        text: '',
                        placeholder: 'Текст ссылки',
                        link: '/'
                    },
                }
                break;
            case 'listing_numbered':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'элемент списка',
                }
                break;
            case 'listing_marked':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'элемент списка',
                }
                break;
            case 'checkbox_сonsent':
                element = {
                    type: type,
                    value: '',
                    placeholder: 'текст галочки',
                }
                break;
            case 'checkbox_list':
                element = {
                    type: type,
                    styles: {
                        grid_column: 1
                    },
                    value: [
                        {
                            value: '',
                            placeholder: 'текст галочки',
                        },
                    ],
                }
                break;
            case 'date':
                element = {
                    type: type,
                    value: new Date(),
                }
                break;
            case 'img':
                element = {
                    type: type,
                    value: upload_icon,
                }
                break;
            case 'pdf':
                element = {
                    type: type,
                    value: '',
                    placeholder: pdf_icon,
                }
                break;
            case 'header':
                element = {
                    type: 'footer',
                    value: 'Верхний колонтитул',
                }
                break;
            case 'footer':
                element = {
                    type: 'footer',
                    value: 'Нижний колонтитул',
                }
                break;

            default:
                break;
        }
        return {...element, is_edit: false}
    }

    useEffect(() => {
        switch (mode) {
            case 'create':
                break;
            case 'edit':
                superAxiosRequest({
                    method: 'get',
                    link: props.link
                }).then(res => {
                    let newState = {}
                    res.data.json_text.forEach((item, key) => newState[key] = item)
                    setConstructorState(newState) //res.data.name
                    setDocTitle({
                        ...docTitle, error: false,
                        value: res.data.name
                    })
                })
                break;

            default:
                break;
        }
    }, [])

    return (
        <div onClick={() => setConstructorState(getStopEditState())}>
            <ContentHeader title="Конструктор документов" />
            <div className={styles.title__box}
            >
                <input
                    maxLength="50"
                    placeholder={'Введите заголовок'}
                    value={docTitle.value}
                    type="text"
                    onClick={e => e.stopPropagation()}
                    onChange={(e) => setDocTitle({
                        ...docTitle, error: false,
                        value: e.target.value
                    })}
                    className={docTitle.error ? `filter-box__input ${styles.title__input_error}` : ` filter-box__input ${styles.title__input}`}
                />
                {docTitle.error && <div className={styles.title__error}>{docTitle.error}</div>}
            </div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.content__wrapper}>
                        <div className={styles.list}>
                            <DocsCreator
                                docsCreatorData={constructorState}
                                mode='edit'
                                setDocsCreatorData={setConstructorState}
                                getStopEditState={getStopEditState}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.sideBar}>
                    <h2 className={styles.sideBar__title}>
                        Блоки
                    </h2>
                    <div className={styles.sideBar__box}>
                        {elementTypesArray.map((element, i) => <button key={i}
                            onClick={(e) => {
                                e.stopPropagation()
                                addEllement(element.type)
                            }}
                            className={styles.button}>
                            {element.title}
                        </button>)}
                    </div>

                </div>
            </div>
            <div className={styles.footer}>
                <button onClick={() => saveDoc(mode)} className={styles.save}>Сохранить документ</button>
                <button onClick={(e) => {
                    e.stopPropagation()
                    setConstructorState({})
                    setDocTitle({
                        is_edit: false,
                        value: 'Название шаблона'
                    })
                }} className={styles.save}>Сбросить изменения</button>
            </div>
        </div >
    )
}

export default DocsConstructor