import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {classNames as cn} from "helpers";
import Colours from "./Colours/Colours";
import st from "./TextareaMarkup.module.scss";
import {ReactComponent as Bold} from "components/UI/icons/bold.svg";
import {ReactComponent as Italic} from "components/UI/icons/italic.svg";
import {ReactComponent as Heading} from "components/UI/icons/heading.svg";
import {ReactComponent as Palette} from "components/UI/icons/palette-line.svg";
import {ReactComponent as OrderedList} from "components/UI/icons/list-ordered.svg";
import {ReactComponent as UnorderedList} from "components/UI/icons/list-unordered.svg";
import {ReactComponent as Divider} from "components/UI/icons/devider-book-line.svg";
import {ReactComponent as Underline} from "components/UI/icons/underline.svg";
import {ReactComponent as Strikethrough} from "components/UI/icons/strikethrough.svg";
import {ReactComponent as Eraser} from "components/UI/icons/eraser-line.svg";
import {ReactComponent as SquareBrackets} from "components/UI/icons/data-book-line.svg";
import {ReactComponent as ConvertToHTML} from "components/UI/icons/Group.svg";


const TextareaMarkup = props => {
  const [showHTML, setShowHTML] = useState(false);
  const [showPalette, setShowPalette] = useState(false)
  const paletteRef = useRef()
  const buttons = [
    {
      icon: <Heading/>,
      name: 'Heading',
      cmd: 'formatBlock',
      val: 'h3',
    },
    {
      icon: <Bold/>,
      name: 'Bold',
      cmd: 'bold',
      state: 'bold',
    },
    {
      icon: <Italic/>,
      name: 'Italic',
      cmd: 'italic',
      state: 'italic'
    },
    {
      icon: <Underline/>,
      name: 'Underline',
      cmd: 'underline',
      state: 'underline',
    },
    {
      icon: <Strikethrough/>,
      name: 'Strikethrough',
      cmd: 'strikethrough',
      state: 'strikethrough',
    },
    {
      icon: <Eraser/>,
      name: 'Clear format',
      cmd: 'removeFormat',
    },
    {
      icon: <SquareBrackets/>,
      name: 'Add[]',
      cmd: 'insertText',
      val: () => '[' + document.getSelection().toString() + ']',
    },
    {
      icon: <Divider/>,
      name: 'Add separator',
      cmd: 'insertText',
      val: '|'
    },
    {
      icon: <OrderedList/>,
      name: 'Numerical list',
      cmd: 'insertOrderedList',
      state: 'insertOrderedList',
    },
    {
      icon: <UnorderedList/>,
      name: 'Unordered list',
      cmd: 'insertUnorderedList',
      state: 'insertUnorderedList',
    },
    {
      icon: <Palette ref={paletteRef} className={`${st.paletteIcon} ${showPalette ? st.showPalette : ''}`} onClick={() => setShowPalette(!showPalette)} />,
      name: 'Color',
      state: 'palette'
    },
    {
      icon: <ConvertToHTML onClick={() => setShowHTML(!showHTML)}/>,
      name: 'Covert to HTML',
    },

  ]
  const refValue = useRef(props.value)
  const textareaRef = useRef();

  const [states, setStates] = useState({
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
    insertOrderedList: false,
    insertUnorderedList: false,
    palette: false
  })

  // закрытие Palette при клике на свободную область
  const handleClickOutsidePalette = (e) => {
    const paletteIcon = paletteRef.current
    if (paletteIcon && !paletteIcon.contains(e.target)) {
      setShowPalette(false)
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePalette);
    return () => {
      document.removeEventListener("click", handleClickOutsidePalette);
    };
  }, [])
// закрытие Palette при клике на свободную область

  const command = (cmd, val) => {
    if (cmd === 'removeFormat') {
      const selection = window.getSelection();
      if (!selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const container = range.commonAncestorContainer;

        if (container.parentNode.nodeName.startsWith("H")) {
          const newDiv = document.createElement("div");
          newDiv.innerHTML = container.textContent;
          container.parentNode.replaceWith(newDiv);
        }
      }
    }
    document.execCommand(cmd, false, val)
  }

  const escape_text = text => {
    if(text) {
    let map = {'&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#039;'};
    text = text.replaceAll('<br>', '')
    return text.replace(/[&<>"']/g, m => map[m]);
    }
  }

  const onInputHandler = e => {
    if(e.target.innerHTML.length > props.maxLength ) return
    props.onChange(e.target.innerHTML.replace(/<span.*?>/g, '')
        .replace(/<\/span>/g, '')
        .replace('<br>', ''), props.stateName)
  }

  const onPasteHandler = e => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }

  const onSelectHandler = () => {
    setStates(Object.assign(
      {},
      ...(Object.keys(states).map(key => ({[key]: document.queryCommandState(key)}))))
    )
  }

  const onKeyDownHandler = e => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
    }
  }

  const getClassNameButton = cond => cn({
    [st.btn]: true,
    [st.btnActive]: cond,
  })

  const onDragOverHandler = (e) => e.preventDefault();

  const onDropHandler = (e) => e.preventDefault();


  return (
    <div className={`${st.container} ${props.error ? st.error : ""}`}>
      <div className={`${st.customTextarea} ${props.className || ''}`}>
        <div className={st.bar}>
          {buttons.map((btn, idx) =>
            <div
              key={idx}
              className={getClassNameButton(states[btn.state])}
              onClick={(e) => {
                e.preventDefault()
                command(btn.cmd, typeof btn.val === 'function' ? btn.val() : btn.val)
              }
              }
            >
            <span className={`${st.toolTip} ${idx < 4 ? st.firstFourToolTip : ''} ${btn.name === 'Color' ? st.palette : st.blue} ${showPalette && btn.name === 'Color' ? st.showPalette : ''}`}>
                {btn.name === 'Color' ? <Colours changeColor={command} /> : btn.name}
            </span>
              <div>{btn.icon}</div>
            </div>
          )}
        </div>
        <div className={st.textarea}
             onDragOver={onDragOverHandler}
             onDrop={onDropHandler}
             ref={textareaRef}
             onPaste={onPasteHandler}
             contentEditable="true"
             onInput={onInputHandler}
             onSelect={onSelectHandler}
             onKeyDown={onKeyDownHandler}
             dangerouslySetInnerHTML={{__html: !showHTML ? refValue.current : escape_text(refValue.current)}}
        />
      </div>
    </div>
  )
}

TextareaMarkup.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  stateName: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  className: PropTypes.string,
}

export default TextareaMarkup