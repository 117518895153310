import React from 'react';
import loader from './loader.gif';
import './loader-main.css';

export default function Loader() {
    return (
        <div className="loader-main">
            <img src={loader} alt="loader"/> 
        </div>
    )
}
