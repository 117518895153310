import React from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Select from 'components/UI/Select/Select';
import Flex from 'components/UI/Flex/Flex';
import styles from './SlotSettings.module.scss';
import {
  findOption,
  getAdaptedTime,
  getTimeInMinutes,
  humanizeTime
} from 'components/Modules/Slots/utils';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';

const getTimeOptions = (start, end, timeZone) => {
  const STEP = 15;
  const options = [];
  const startInMinutes = getTimeInMinutes(start, timeZone);
  const endInMinutes = getTimeInMinutes(end, timeZone);
  let period = startInMinutes;
  while (period <= endInMinutes) {
    const humanized = humanizeTime(period);
    options.push({value: humanized, label: humanized});
    period += STEP;
  }
  return options;
};

function Time() {
  const { startTime, endTime, setTime } = slotsScheduleSettingsStore;
  const options = getTimeOptions('00:00', '24:00');
  const startValue = findOption(getAdaptedTime(startTime), options);
  const endValue = findOption(getAdaptedTime(endTime), options);

  return (
    <Flex justify='start' withGap>
      <SectionTitle tag='h3' title='Время работы' secondary />
      <Select
        options={options}
        value={startValue}
        onChange={(option) => setTime(getAdaptedTime(option.value), 'startTime')}
        classes={styles.time_selector}
        placeholder='Время'
      />
      <Select
        options={options}
        value={endValue}
        onChange={(option) => setTime(getAdaptedTime(option.value), 'endTime')}
        classes={styles.time_selector}
        placeholder='Время'
      />
    </Flex>
  );
}

export default observer(Time);

