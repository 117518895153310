import React, {useEffect, useState} from "react";
import Stores from "Stores";
import Paper from "components/UI/Paper/Paper";
import OpenHeader from "components/Modules/Groups/components/OpenHeader";
import st from "./TrainingLessons.module.scss";

export default function SubscriptionHistory({client, setSubscriptions, subscriptions, fetchSubscriptionHistory}){
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!client && subscriptions.length) {
      setSubscriptions([])
      return;
    }
    // Если история этого клиента уже получена
    if (subscriptions.length && subscriptions[0].client_barcode === client.id) return

    // История абонемента клиента
    fetchSubscriptionHistory()
  }, [client])

  return (
    <Paper classes={st.box}>
      <OpenHeader title="История абонемента" onClick={() => setOpen(!open)}/>
      {open &&
      <div>
        <div className={st.historyRow}>
          <div>Название абонемента</div>
          <div>Сумма</div>
          <div>Дата продажи</div>
          <div>Дата начало занятий</div>
          <div>Дата окончания</div>
          <div>Осталось посещений</div>
        </div>
        <div>{subscriptions?.map(ss =>
          <div key={ss.id} className={st.historyRow}>
            <div>{ss.name}</div>
            <div>{Stores.cleanSumm(ss.sum / 100)}</div>
            <div>{ss.purchase_day}</div>
            <div>{ss.date_activation}</div>
            <div>{ss.date_until}</div>
            <div>{ss.count_training}</div>
          </div>)}
        </div>
      </div>}
    </Paper>
  )
}
