import React from 'react'
import './Select.scss'

export default function Select (props) {

    const myChangeHandler = (e, nameState) =>{
        props.myChangeHandler(e, nameState)
    }

    let new_key = 'name';
    let sort_key = new_key
    if(props.by_id) sort_key = props.by_id;
    if(props.newSort_key) sort_key = props.newSort_key;
    if(props.new_key) new_key = props.new_key;

    // let arrNewName = [
    //     {prev: ' (', newName: 'price_sum', past: 'тг '},
    //     {prev: ' за ', newName: 'hours_sum', past: ' ак.ч. )'}
    //      ]
    return (
        <div className={'modal-selectors ' + (props.value_error ? ' modal-selectors--error' : '')}>
            <select
                className={(props.value.length < 1 || props.disabled) ? 'modal-selectors--disabled' : 'modal-selectors--active'}
                name={props.name}
                onChange={(e) => myChangeHandler(e, props.nameState)}
                // value={props.s_value !== undefined && props.value.length > 1? props.s_value : 0}
                value={(props.s_value !== undefined && props.value.length > 1) ? props.s_value : (props.isZero  ? '-' : 0)}
                placeholder={props.text}
                defaultValue={0}
                disabled={(props.value.length < 1 || props.disabled) && true}
                style={{
                    width: props.width,
                    marginBottom: props.margin,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight
                }}
            >
                <option value={props.isZero  ? '—' : 0} disabled>{props.text}</option>
                {props.value.sort((a, b) => a[sort_key] > b[sort_key] ? 1 : -1).map((item, index) =>
                    props.optionsHours ?
                        <option key={index} defaultValue={0} value={item.id}>{item.name + ' (' + item.price_sum + 'тг за ' + item.hours_sum + (item.hours_type.name === 'Академические' ? ' ак.ч.)' : ' астр.ч.)')}</option> :
                        // arrNewName.map((e, index) =>
                        //     <option key={index} defaultValue={0} value={props.new_id ? item[props.new_id] : item.id}>{props.new_key ? item[props.new_key] : item.name + e.prev + item[e.newName] + e.past}</option>
                        // ):

                        props.split_select ?
                        <option key={index} defaultValue={0} value={props.new_id ? item[props.new_id] : item.id}>{props.new_key ? `${`${item[props.split_select] && item[props.split_select]} -`} ${item[props.new_key]}` : item[props.split_select] !== undefined ? item[props.split_select] + ' - ' + item.name : item.name}</option>
                        :
                        <option key={index} defaultValue={0} value={props.new_id ? item[props.new_id] : item.id}>{props.new_key ? item[props.new_key] : item.name}</option>
                )}
            </select>
        </div>
    )
}
