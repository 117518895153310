import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Calendar from 'components/UI/Calendar/Calendar';
import slotsRecordCustomerStore, { ActionType, DataType } from 'Stores/slotsRecordCustomerStore';
import { getMondayDate } from 'components/UI/Calendar/utils';
import { dateFormat } from 'helpers/date';

function CalendarLayout() {
  const [startDate, setStartDate] = useState(null);
  const {
    selectedCompany,
    selectedLocation,
    selectedProgram,
    selectedAge,
    isCalendarLoading,
    dates,
    selectedDates,
    getDataFromServer,
    setData,
    resetData,
  } = slotsRecordCustomerStore;

  const handleClick = () => {
    if (selectedDates.size === 0) {
      return;
    }
    resetData(ActionType.resetSlots);
    getDataFromServer({
      type: ActionType.getSlots,
    })
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleReset = () => {
    resetData(ActionType.resetCalendar);
  };

  useEffect(() => {
    const isReadyToLoadCalendar = selectedCompany && selectedLocation && selectedProgram && selectedAge;
    const dateFrom = startDate ? new Date(startDate) : new Date();
    if (!isReadyToLoadCalendar) {
      return;
    }
    getDataFromServer({
      type: ActionType.getCalendar,
      params: {
        date_from: dateFormat(getMondayDate(dateFrom), 'YYYY-mm-dd'),
      },
      onFinally: () => setData(false, DataType.isCalendarLoading),
    });
  }, [startDate, getDataFromServer, selectedCompany, selectedLocation, selectedProgram, selectedAge, setData]);

  return (
    <Calendar
      startDate={startDate}
      isLoading={isCalendarLoading}
      dates={dates}
      selectedDates={selectedDates}
      resetData={handleReset}
      onStartDateChange={handleStartDateChange}
      onClick={handleClick}
      type='record-customer'
    />
  );
}

export default observer(CalendarLayout);
