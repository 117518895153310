import React from 'react';
import {observer} from "mobx-react";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FileSelector from 'components/UI/FileSelector/FileSelector';
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import useLocalization from "hooks/useLocalization";
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";

const BookForm = () => {
  const {modalValues, changeValue, check} = asideModalStore

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'modal_book_form',
    dict: {
      "adding_a_book": "Adding a Book",
      "choose_company": "Choose Company",
      "select_company": "Select Company",
      "book_name": "Book Name",
      "education_type": "Education Type",
      "select_education_type": "Select Education Type",
      "group_level": "Group Level",
      "select_group_level": "Select Group Level",
      "add_book_to_general_catalog": "Add Book To General Catalog",
    }
  })

  return (
    <FormFieldset>
      <FormField>
        <FormLabel>{translate.choose_company}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={translate.select_company}
            apiLink="db/companies"
            valueID={modalValues.company || null}
            onChange={o => changeValue(o.id, 'company')}
            error={check('company')}
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel>{translate.book_name}:</FormLabel>
        <FormInput isError={check('name')}>
          <input
            placeholder={translate.book_name}
            type="text"
            value={modalValues.name || ''}
            onChange={e => changeValue(e.target.value, 'name')}
          />
        </FormInput>
      </FormField>

      <FormField>
        <FormLabel>{translate.education_type}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={translate.select_education_type}
            apiLink="groups/groups_catalog"
            apiGetResult={result => result.skill_levels.items}
            sortKey="name"
            valueID={modalValues.skill_level || null}
            onChange={o => changeValue(o.id, 'skill_level')}
            error={check('skill_level')}
            isSearchable
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel>{translate.group_level}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={translate.select_group_level}
            apiLink="groups/groups_catalog"
            apiGetResult={result => result.course_levels.items}
            valueID={modalValues.course_level || null}
            onChange={o => changeValue(o.id, 'course_level')}
            error={check('course_level')}
          />
        </FormSelectCustom>
      </FormField>
      <FileSelector types={['jpg', 'gif', 'png']}
        error= {check('image')}
        value={modalValues.image || ''}
        onChange={file => changeValue(file, 'image')} /> 

      {checkPermList(permissions.add_course_to_catalog) &&
        <div>
          <Checkbox label={translate.add_book_to_general_catalog}
                    checked={modalValues.is_catalog || false}
                    name="is_catalog"
                    onChange={changeValue}/>
        </div>
      }

    </FormFieldset>
  )
}

export default observer(BookForm)
