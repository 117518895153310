import React from 'react';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import DummyCounter from "../../../../Counter/DummyCounter";


export default function SlideSchedule(props) {

    const returnValue = key => props.values ? props.values[key] : ''

    const objectChangeHandler = (value, key) => props.updateList({...props.values, [key]: value})

    return (
        <div className="workSchedule-box__cardRow workSchedule-box__cardRow--three">
            <div>
                <div className="workSchedule-box__label">Период графика:</div>
                <div>
                    <DataPickerSplit returnValue={returnValue}
                                     objectChangeHandler={objectChangeHandler}/>
                </div>
            </div>
            <div>
                <div className="workSchedule-box__label">Часов в неделю:</div>
                <div>
                  <DummyCounter name="lunch"
                                value={props.values.lunch || 1}
                                onChange={objectChangeHandler}
                                min={1}
                                max={30}
                                step={1}/>
                </div>
            </div>
        </div>
    )
}
