import React, {useState, useEffect} from "react";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import {GoToLink, SuperButton} from "components/UI/SuperButton/SuperButton";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import Test from "./Tests/Test";
import TestsNotFound from "./TestsNotFound/TestsNotFound";
import styles from "./StudyTests.module.scss";
import useLocalization from "hooks/useLocalization";

const StudyTests = () => {
  const [tab, setTab] = useState(0);
  const [lastTests, setLastTests] = useState({loader: false, list: []});
  const isTestsEmpty = lastTests.list.length < 1;
  const [active, setActive] = useState(true);
  const [finished, setFinished] = useState(true);

  const {translate} = useLocalization({
    section: 'profile', subsection: 'study_tests', dict: {
      "title": "My Tests",
      "questions": "Questions",
      "empty_tests": "You don't have any tests yet",
      "active_filter": "Active",
      "finished_filter": "Finished",
      "in_progress_badge": "In Progress",
      "finished_badge": "Finished",
      "testing_link": "Go to Testing",
      "history_link": "Go to Testing History",
      "edit": "Edit",
      "delete": "Delete"
    }
  })

  useEffect(() => {
    setLastTests({...lastTests, loader: true});
    superAxiosRequest({
      method: "get",
      //link: 'study/view_last_test',
      link: "study/purpose_test",
      params: {
        filter_type: tab,
      }
    }).then(({data}) => setLastTests({loader: false, list: data.result}))
      .catch(() => setLastTests({...lastTests, loader: false}));
  }, [tab]);

  useEffect(() => {
    if (active && finished) {
      setTab(0);
    } else if (active) {
      setTab(1);
    } else if (finished) {
      setTab(2);
    } else {
      setTab(-1);
    }
  }, [active, finished]);

  const handleActiveClick = () => {
    setActive(!active);
  };

  const handleFinishedClick = () => {
    setFinished(!finished);
  };

  return (
    <div className={styles.main}>
      <BlockHeader title={translate.title}>
        <GoToLink to="/c/study/my-tests" target="_blank"/>
      </BlockHeader>
      <div className={styles.filterButtonsBlock}>
        <SuperButton callback={handleActiveClick} variant={"filter"} type={"filterColored"}>
          {translate.active_filter}
        </SuperButton>
        <SuperButton callback={handleFinishedClick} variant={"filter"}>
          {translate.finished_filter}
        </SuperButton>
      </div>
      {tab === -1 && <TestsNotFound translate={translate.empty_tests} tab={tab}/>}
      <ul className={tab === -1 ? styles.listHidden : ""}>
        {lastTests.loader ? (
          <Loader/>
        ) : isTestsEmpty ? (
          <TestsNotFound translate={translate.empty_tests} tab={tab}/>
        ) : (
          lastTests.list.map((test) => (
            <Test key={test.id} test={test} translate={translate}/>
          ))
        )}
      </ul>
    </div>
  );
};
export default StudyTests;

