import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../ContentBox/Global/Checkbox/Checkbox";
import st from "./MultiCheckbox.module.scss";

const MultiCheckbox = ({checked, name, label, onChange}) => {
  return (
    <li className={st.multiCheckbox}
        onClick={() => {
          onChange(!checked, name)
        }}>
      <div className={`${st.line} ${checked ? st.lineSelected : ''}`}>
        <Checkbox label={label}
                  checked={checked}
                  onChange={() => {}}
                  className={st.checkbox}
        />
      </div>
    </li>
  );
};

MultiCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default MultiCheckbox;