import React from 'react';
import PropTypes from 'prop-types';
import ExerciseFile from "./ExerciseFile";
import st from "./ExerciseFiles.module.scss";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";

function ExerciseFiles(props) {
  const onChange = val => {
    props.onChange(val, props.name)
  }
  const action = (type, pl) => {
    switch (type) {
      case 'add':
        onChange([...props.files, {file: null, description: ''}]) // вызываем onChange 2 раза, что бы демонтировать компоненту загрузки файла
        onChange([...props.files, pl])
        break;
      case 'change':
        onChange(props.files.map((f, i) => i === pl.index ? pl.value : f))
        break;
      case 'delete':
        if (props.files.length === 1) {
          onChange(props.files.map((f, i) => i === pl.index ? {...f, file: null, description: ''} : f))
          break;
        }
        onChange(props.files.filter((f, i) => i !== pl.index))
        break;
      default:
        break;
    }
  }
  const remove = (index) => {
    action('delete', {index})
  }

  return (
    <div className={st.box}>
      <FormField>
        <FormLabel>{props.label}</FormLabel>
      </FormField>
      <div className={st.variants}>{props.files.map((item, index) =>
        <div className={st.variant} key={item.id || `new${index}`}>
          <div className={st.cell}>
            <ExerciseFile file={item}
                          onChange={value => action('change', {index, value})}
                          error={props.error}
                          allowedExtension={props.allowedExtension}
                          accept={props.accept}
                          remove={() => remove(index)}
                          files={props.files}
                          type={props.type}
            />
          </div>
        </div>)}
      </div>
      {
        props.files.at(-1).file &&
          <ExerciseFile
              files={props.files}
              allowedExtension={props.allowedExtension}
              file={{file:'', description:''}}
              onChange={(file) => action('add', file)}
              accept={props.accept}
              type={props.type}
          />
      }
    </div>
  );
}

ExerciseFiles.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  allowedExtension: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
}

ExerciseFiles.defaultProps = {
  files: []
}

export default ExerciseFiles
