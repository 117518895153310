import React from 'react'
import PropTypes from 'prop-types'
import st from "./VariantAdd.module.scss"

const VariantAdd = ({onClick, isDisabled}) => <button className={st.button} onClick={onClick} disabled={isDisabled}>+</button>

VariantAdd.propTypes = {
  onClick: PropTypes.func.isRequired
}

VariantAdd.defaultProps = {
  onClick: () => {}
}

export default VariantAdd
