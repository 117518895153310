import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom';
import Stores from 'Stores';
import { checkPermList } from 'helpers';
import './ActionMenu.scss';


export default function ActionMenu(props) {
    const [open, setOpen] = useState(false)
    const refButton = useRef(null)

    const clickClack = (data, e, items, line) => {
        setOpen(false)
        if (props.forward_actions) {
            props.forward_actions(data, e, props.forward_items)
        } else {
            props.action(data, e, items, line)
        }
    }

    useEffect(() => {
        if (open)
            document.body.addEventListener(
                'click',
                ({ target }) => { if (!refButton.current.contains(target)) setOpen(false) },
                { once: true }
            )
    }, [open])
    return (
        <div className="action-menu">
            {props.img ?
                <div className="action-menu__button action-menu__button--img" onClick={() => setOpen(!open)}
                    ref={refButton}>
                    <img src={props.img} alt="get-more" />
                </div>
                :
                props.svg ?
                    <div className="action-menu__button action-menu__button--svg" onClick={() => setOpen(!open)}
                        ref={refButton}>
                        {props.svg}
                    </div>
                    :
                    <div className={'action-menu__button' + (!open ? " action-menu__button--rotate" : " ")}
                        onClick={() => setOpen(!open)} ref={refButton}>
                        <span />
                    </div>
            }
            <div className={'action-menu__modal ' + (open ? " action-menu__modal--open" : " action-menu__modal--close")}>
                <div className="action-menu__menu">
                    {props.menu.map((item, index) => {
                        if (item.permission && item.permission[0] === 'forward') {
                            return item.nav_link ?
                              <NavLink key={index} to={item.link + '/' + props.id + (props.sec_id ? + '/' + props.sec_id : '')}>
                                  {item.name}
                              </NavLink> :
                              item.btn_link ?
                                <NavLink key={index} to={item.btn_link.link} target={item.btn_link.target || ''}>
                                    {item.btn_link.name}
                                </NavLink> :
                                <div key={index} onClick={e => clickClack(item.action, e, props.items, item)}>
                                    {item.name}
                                </div>
                            //THE KOSTIL! 
                        } else if (item.permission && Stores.Rules.checkRule(item.permission[0], item.permission[1])
                            && (item.active ? props.forward_items[item.active[0]] === item.active[1] : true)
                            && (item.dateControl ? new Date(props.forward_items[item.dateControl[0]]) >= item.dateControl[1] : true)
                            && (item.more ? props.forward_items[item.more[0]] > item.more[1] : true)
                            && (item.smaller ? props.forward_items[item.smaller[0]] < item.smaller[1] : true)
                            && (item.startDate ? new Date(`${props.forward_items[item.startDate[0]]} ${props.forward_items[item.startDate[1]]}`) <= item.startDate[2] : true)
                            && (item.endDate ? new Date(`${props.forward_items[item.endDate[0]]} ${props.forward_items[item.endDate[1]]}`) >= item.endDate[2] : true)
                            && (item.unactive ? props.forward_items[item.unactive[0]] !== item.unactive[1] : true)) {
                            if (item.nav_link) {
                                return <NavLink key={index} to={item.link + '/' + props.id + (props.sec_id ? + '/' + props.sec_id : '')} target="_blank">{item.name}</NavLink>
                            } else {
                                return <div key={index} onClick={(e) => clickClack(item.action, e, props.items, item)}>{item.name}</div>
                            }
                        } else if (item.perm && checkPermList(item.perm)
                            && (item.active ? props.forward_items[item.active[0]] === item.active[1] : true)
                            && (item.dateControl ? new Date(props.forward_items[item.dateControl[0]]) >= item.dateControl[1] : true)
                            && (item.more ? props.forward_items[item.more[0]] > item.more[1] : true)
                            && (item.smaller ? props.forward_items[item.smaller[0]] < item.smaller[1] : true)
                            && (item.startDate ? new Date(`${props.forward_items[item.startDate[0]]} ${props.forward_items[item.startDate[1]]}`) <= item.startDate[2] : true)
                            && (item.endDate ? new Date(`${props.forward_items[item.endDate[0]]} ${props.forward_items[item.endDate[1]]}`) >= item.endDate[2] : true)
                            && (item.unactive ? props.forward_items[item.unactive[0]] !== item.unactive[1] : true)) {
                            if (item.nav_link) {
                                return <NavLink  key={index} to={item.link + '/' + props.id + (props.sec_id ? + '/' + props.sec_id : '') } target = "_blank">{item.name}</NavLink>
                            } else if (item.btn_link){
                                return <NavLink key={index} to={item.btn_link.link} target={item.btn_link.target || ''}>{item.btn_link.name}</NavLink>
                            } else {
                                return <div key={index} onClick={(e) => clickClack(item.action, e, props.items, item)}>{item.name}</div>
                            }
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

ActionMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.object).isRequired,
    action: PropTypes.func,
}
