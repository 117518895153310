import React from 'react';
import styles from "./VisualSheduleTooltip.module.scss"
import ReactDOM from "react-dom";
import {addZero} from "helpers/math";
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';


function VisualSheduleTooltip({
    propsStyles,
    status,
    durationMinutes,
    timeStart,
    timeDuration,
    teachers,
    comment,
    date}) {
    const dateStart = new Date(timeStart)
    const dateEnd = new Date(timeStart - (- durationMinutes * 60 * 1000))

    return ReactDOM.createPortal(
        <div style={propsStyles} className={styles.visualSheduleTooltip}>
            {status && <p className={styles.visualSheduleTooltip__text}><span>{status}</span></p>}
            <p className={styles.visualSheduleTooltip__text}>
                {`${dateStart.getHours()}:${addZero(dateStart.getMinutes())} - 
                ${dateEnd.getHours()}:${addZero(dateEnd.getMinutes())}`}
            </p>
            {timeDuration && <p className={styles.visualSheduleTooltip__text}>
                ({timeDuration})
            </p>}
            {date && <p className={styles.visualSheduleTooltip__text}>
                {timeConverter((dateStart), "age")}
            </p>} 
            {teachers[0] && teachers.map((teacher, id) => <p key={id} className={styles.visualSheduleTooltip__text}>
                {teacher.name}
            </p>)}
            {comment && <p className={styles.visualSheduleTooltip__text}>{comment}</p>}
        </div>, document.getElementById('timeTable__wrapper_tooltip')
    );
}

export default VisualSheduleTooltip;
