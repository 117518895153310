import React from 'react';
import Filter from 'components/ContentBox/Global/Filter/Filter';
import slotsHistoryListingStore from 'Stores/slotsHistoryListingStore';
import {translate as loc, dict} from "Localisation";
import Stores from "Stores";


function FilterSection() {
  const {employees, levels, ages, programs, setFilters, clearFilters} = slotsHistoryListingStore;
  const filterConfig = [
    {
      type: 'input',
      placeholder: loc(dict.history_clients.placeholder_1),
      nameState: 'search',
    },
    {
      type: "days-range-picker",
      nameState: ["date_from", "date_until"],
      format: "dd MMMM, yyyy",
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_4),
      values: Stores.observableArray(employees).map(item => ({...item, label:Stores.transliterate(item.label)})),
      nameState: 'employee',
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_2),
      values: programs,
      nameState: 'program'
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_5),
      values: ages,
      nameState: 'age'
    },
    {
      type: 'select-custom',
      placeholder: loc(dict.history_clients.placeholder_6),
      values: levels,
      nameState: 'training_level'
    },
  ];

  const handleFilterChange = (filterData) => {
    setFilters(filterData);
  };

  const handleClear = () => {
    clearFilters();
  };

  return (
    <section>
      <Filter
        thisPage={null}
        config={filterConfig}
        drop={handleClear}
        getFilterParams={handleFilterChange}
      />
    </section>

  );
}

export default FilterSection;

