import React,{useState, useEffect} from 'react';
import {localisationMaster} from "../../../../../Localisation/Localisation";
import modalStore from '../../../../../Stores/modalStore';

export default function AddMedia(props) {
    const [media, setMedia] = useState([])
    const {modalValue, changeValues, data, errorChecker, deleteWithKey} = modalStore

    const insertFile = (e) => {
        let files = e.target.files[0];
        let nameImg = files.name;
        setMedia([files, nameImg])
    };

    const changeHandler = (e, key_name, type) =>{
        if(type === 'media'){
            changeValues(e.target.files[0], key_name)
            insertFile(e)
        } else {
            changeValues(e.target.value, key_name)
        }
        
    }

    useEffect(() => {
        deleteWithKey('logo')
        // eslint-disable-next-line
    }, [])

    return (
        <div>
                            <div className="super-modal-media">
                                <input
                                    placeholder={data.pl_text}
                                    name="valueInput"
                                    type="text"
                                    value={modalValue[data.name_text]}
                                    onChange={(e)=>changeHandler(e, data.name_text)}
                                    style={{borderColor: errorChecker(data.name_text)}}
                                />
                                 <input
                                    placeholder={data.pl_text_second}
                                    name="valueInput"
                                    type="text"
                                    value={modalValue[data.name_text_second]}
                                    onChange={(e)=>changeHandler(e, data.name_text_second)}
                                    style={{borderColor: errorChecker(data.name_text_second)}}
                                />
                                <div>
                                <input id="file" name="file" type="file"
                                       //onChange={(e) => insertFile(e)}
                                       onChange={(e)=>changeHandler(e, data.name_media, 'media')}
                                       className="inputfile"
                                />
                                <label className="labelFile" htmlFor="file">
                                    {!media[0] ? localisationMaster('button-image-one', 'tests/questions', 'image_plus_variants') :
                                        localisationMaster('button-image-three', 'tests/questions', 'image_plus_text') + ' ' + media[1]}
                                </label>
                                <p className="bold">
                                    {localisationMaster('text_1', 'modal_templates')}
                                </p>
                            </div>
                                <div className="addMediaTextarea">
                             <textarea
                                 placeholder={data.pl_textarea}
                                 name="inputValueEmail"
                                 value={modalValue[data.name_textarea]}
                                 onChange={(e)=>changeHandler(e, data.name_textarea)}
                                 style={{borderColor: errorChecker(data.name_textarea)}}
                             />
                                </div>
                            </div>
        </div>
    )
}
