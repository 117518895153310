import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from 'helpers';
import { localisationMaster } from "Localisation/Localisation";
import styles from './BookedSlots.module.scss';

function Menu({ onClick, isDeleted }) {
  const [classes, setClasses] = useState(styles.menu);

  const handleClick = (evt) => {
    evt.stopPropagation();
    onClick(evt.target.dataset.name);
  };

    const handleOutsideClick = (evt) => {
    if (evt.target.dataset.name) {
      return;
    }
    onClick(null);
  };

  useEffect(() => {
    setClasses(cn({
      [styles.menu]: true,
      [styles.menu_show]: true,
    }));
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <div className={classes}>
      <ul className={styles.menu_list} onClick={handleClick}>
        {isDeleted ?
          <li className={`${styles.menu_item} ${styles.menu_item__warning}`} data-name="restore">
            {localisationMaster('text_13', 'teacher_schedule')}
          </li> :
          <>
            <li className={styles.menu_item} data-name="change">
              {localisationMaster('text_17', 'study/study-test')}
            </li>
            <li className={`${styles.menu_item} ${styles.menu_item__warning}`} data-name="delete">
              {localisationMaster('text-6', 'modal-bts')}
            </li>
          </>
        }
      </ul>
    </div>
  );
}

Menu.propTypes = {
  isDeleted: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Menu;
