import React from "react";
import st from "./VacationInfo.module.scss"

export default function VacationInfo({contract: {left_vacation_mins, vacation_mins, left_vacation_years}, translate}){
  return (
    <div className={st.vacationInfo}>
      <div className={st.header}>
        <h5>{translate.vacation_information}</h5>
      </div>
      <div className={st.balanceDays}>
        <div>
          <span>{translate.balance_today}</span><span>{(left_vacation_mins / vacation_mins).toFixed(2)} {translate.days}</span>
        </div>
        <div>
          <span>{translate.balance_for_the_year}</span><span>{left_vacation_years.toFixed(2)} {translate.days}</span>
        </div>
      </div>
    </div>
  )
}
