import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import useLocalization from "hooks/useLocalization";
import Stores from "Stores";
import HomeworkTeacherStore from "./homeworkTeacherStore";
import {LessonContext} from "../BookChapter/LessonContext";
import Loader from "components/UI/Loader/Loader";
import Exercise from "../Common/Exercise/Exercise";
import LessonToolbar from "../LessonStudent/LessonToolbar";
import BookPaper from "../Common/BookPaper/BookPaper";
import StudentSelector from "./StudentSelector";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import st from "../LessonStudent/LessonStudent.module.scss";
import ActionWithEx from "../ActionWithEx/ActionWithEx";
import {superAxiosRequest} from "../../../../axiosApi";
import ShowMark from "../ShowMark/ShowMark";
import {localisationMaster} from "../../../../Localisation/Localisation";
import swal from "sweetalert";
import LessonDiscussion from "../LessonDiscussion/LessonDiscussion";
import LessonLogs from "../LessonLogs/LessonLogs";


function HomeworkTeacher(){
  const {hash} = useParams()

  const {homework, errorStatus, connectToLesson, deleteExercise, fetchHomework} = HomeworkTeacherStore

  const context = {mode: 'teacher-homework'}

  const [selectedStudent, setSelectedStudent] = useState(null)
  const [mark, setMark] = useState(null)
  const [studentMark, setStudentMark] = useState(null)
  const [review, setReview] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)

  const [currentRef, setCurrentRef] = useState(null)

  const scrollToBlock = () => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "delete": "Delete",
      "homework": "Homework",
      "choose_student": "Choose a Student",
      "didnt_assign_homework" : "The teacher has not yet assigned homework exercises for this lesson",
      "please_wait" : "Please wait for the teacher to assign you the exercises"
    }
  })

  const addMark = (mark) => {
    superAxiosRequest({
      method: 'post',
      link: 'ebooks/homework_marks'
    }, {
      homework_hash: hash,
      student_id: selectedStudent.id,
      mark: mark
    }).then((req) => {
      setMark(req.data?.mark)
      setIsSubmit(true)
      fetchHomework(hash)
    })
  }

  const sendToRevision = () => {

    swal({
      text: `Вы действительно хотите отправить домашнее задание на доработку?`,
      icon: "warning",
      buttons: [
        localisationMaster('button_2', 'alert'),
        localisationMaster('button_1', 'alert')
      ],
      dangerMode: true
    }).then((yes) => {
      if (yes) {
        superAxiosRequest({
          method: 'post',
          link: 'ebooks/student_lesson_reviews'
        }, {
          student_id: selectedStudent?.id,
          homework_hash: hash,
          review: false
        }).then(() => setIsSubmit(true))
      } else {
        swal('Действие отменено')
      }
    })
  }

  useEffect(() => HomeworkTeacherStore.clearHomework, [])

  useEffect(() => {
    HomeworkTeacherStore.fetchHomework(hash, () => {
      connectToLesson()
    })
  }, [connectToLesson, hash])


  useEffect(() => {
    setStudentMark(homework?.students_marks?.find(el => el.student_id === selectedStudent?.id))
    setReview(homework?.students_reviews?.find((el) => el.student_id === selectedStudent?.id))
  },[homework, selectedStudent, studentMark])


  return (
    homework ?
      <div className={st.lessonStudent}>
        <LessonToolbar lessonLink={`/ebooks/${Stores.isClient ? 'my-' : ''}lesson/${homework.init_lesson_uuid}`}/>
        <div className={st.bookContainer}>
          <BookPaper>
            <div className={st.bookColumn}>
              <LessonHomeworkHeader title={translate.homework}
                                    createdAt={homework.created_at}
                                    ebookName={homework.chapter_ebook_name}
                                    chapterName={homework.chapter_name}/>
              <StudentSelector students={homework.students}
                               student={selectedStudent}
                               onChange={setSelectedStudent}
                               translate={translate}/>
              {
                (studentMark?.mark) && <ShowMark mark={Number(studentMark?.mark)} type={homework?.company_mark_type} scroll={scrollToBlock}/>
              }

              {homework.exercises.length > 0 ?
                <LessonContext.Provider value={context}>
                  <div className={st.questions}>{homework.exercises.map((ex, index) =>
                    <div key={ex.id}>
                      <Exercise number={`1.${index + 1}`}
                                ex={ex}
                                translate={translate}
                                student={selectedStudent}
                                answers={homework.assign_exercises
                                  .find(ae => ae.exercise_id === ex.id)?.students
                                  .find(st => st.student_id === selectedStudent?.id)?.answers || []}
                                deleteExerciseFromHomework={() => deleteExercise(ex.id)}
                      />
                    </div>)}
                  </div>
                </LessonContext.Provider> :
                <WithoutExercises text1={`${translate.didnt_assign_homework}.`}
                                  text2={`${translate.please_wait}.`}/>}
            </div>
          </BookPaper>
          {
            homework.company_marking &&
            <>
              {
                  (review?.review && !studentMark?.mark && !isSubmit) &&
                  <ActionWithEx setMark={setMark} homework={homework} addMark={addMark} sendToRevision={sendToRevision}/>
              }
              {
                  selectedStudent && <LessonDiscussion context={'teacher'} studentId={selectedStudent?.id} isHomework={true} setRef={setCurrentRef}/>
              }
              {
                  selectedStudent && <LessonLogs isHomeWork={true} context={'teacher'} studentId={selectedStudent?.id} hash={hash}/>
              }
            </>
          }

        </div>


      </div>
      :
      <div className={st.lessonStudent}>
        <LessonToolbar/>
        <div className={st.bookContainer}>
          <BookPaper>{errorStatus === 404 ?
            <WithoutExercises text1="404" text2="Homework not found"/> :
            <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(HomeworkTeacher)
