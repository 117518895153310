import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import swal from 'sweetalert';
import Calendar from 'components/UI/Calendar/Calendar';
import { dateFormat } from 'helpers/date';
import { getMondayDate } from 'components/UI/Calendar/utils';
import { DataType, ActionType } from 'Stores/slotsNewRecordOnlineStore';

function CalendarWrapper({ store }) {
    const [startDate, setStartDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        selectedLocation,
        selectedProgram,
        selectedAge,
        dates,
        selectedDates,
        setData,
        getDatesFromServer,
        getSlotsFromServer,
        resetData,
        isReadyToLoadCalendar,
    } = store;

    const handleClick = (index) => {
        resetData(ActionType.resetSlots);
        if (selectedDates.size === 0) return;
        getSlotsFromServer(index);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    useEffect(() => {
        const dateFrom = startDate ? new Date(startDate) : new Date();
        if (!isReadyToLoadCalendar || !startDate) return;
        setIsLoading(true);
        const date = dateFormat(getMondayDate(dateFrom), 'YYYY-mm-dd');
        getDatesFromServer(
            date,
            setIsLoading,
            () => {
                setData(null, DataType.selectedAge);
                setData(null, DataType.selectedProgram);
                return swal('', 'Ошибка загрузки расписания, повторите позже', 'warning');
            }
        );
    }, [startDate, getDatesFromServer, selectedLocation, selectedProgram, selectedAge]);

    return (
        <Calendar
            startDate={startDate}
            isLoading={isLoading}
            dates={dates}
            selectedDates={selectedDates}
            resetData={() => resetData(ActionType.resetSlots)}
            onStartDateChange={handleStartDateChange}
            onClick={handleClick}
            type='record-online'
        />
    );
}

export default observer(CalendarWrapper);