import React from 'react';
import style from './Marks.module.scss';
import WithMarks from "./types/WithMarks/WithMarks";
import WithGrades from "./types/WithGrades/WithGrades";
import Easy from "./types/Easy/Easy";

const Marks = ({ type,setMark, addMark }) => {
    const renderMarks = (type) => {
        switch (type) {
            case 1:
                return <Easy setMark={setMark} addMark={addMark}/>;
            case 2:
                return <WithMarks numberOfMarks={5} setMark={setMark} addMark={addMark}/>;
            case 3:
                return <WithMarks numberOfMarks={10} setMark={setMark} addMark={addMark}/>;
            case 4:
                return <WithGrades setMark={setMark} addMark={addMark}/>;
            default:
                return <span>Unknown content type.</span>;
        }
    };

    return <div className={style.container}>{renderMarks(type)}</div>;
};

export default Marks;