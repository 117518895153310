import React, {useState} from "react";
import {dict, translate as loc} from "Localisation";
import {dateFormat} from "helpers/date";
import {weekDays} from "components/Modules/Groups/TeacherGroup/components/Workshop/WorkshopSchedule"
import Stores from "Stores";
import st from "./WorkshopSchedule.module.scss"
import Avatar from "../../../../../HeadBox/components/Avatar/Avatar";
import groupStore from "Stores/groupStore";

export default function WorkshopSchedule({teachers, schedule}){

  const [hoursType, setHoursType] = useState(45)
  const {group: {holidays}} = groupStore
  const getTotalHours = (days, ht, holidays) => {
    let total = 0
    days.forEach(day => {
      if (!holidays?.some(h => h.date === day.date))
        total += new Date(`${day.date}T${day.finish_at}`) - new Date(`${day.date}T${day.start_at}`)
    })
    return Math.round(total / 1000 / 60 / ht)
  }
  const TeacherLink = ({teacher_id}) => {
    const teacher = teachers?.find(t => t.id === teacher_id)

    return (
        <div className={st.teacherInfo}>
          <div className={st.avatar}>
            {
              teacher.avatar
                  ? <img src={teacher.avatar} alt=""/>
                  : <
                      Avatar first_name={teacher.first_name} last_name={teacher.last_name}/>
            }
          </div>
          <div>
            <div>{Stores.transliterate(`${teacher.first_name} ${teacher.last_name}`)}</div>
          </div>
        </div>
    )
  }

  return (
    <div className={st.scheduleRow}>
      <div className={st.teachers}>
        {schedule.teachers.length ? schedule.teachers.map(teacher_id => <TeacherLink key={teacher_id} teacher_id={teacher_id}/>) : <div className={st.teacherInfo}>Without Teacher</div>}
      </div>
      <div className={st.schedule}>
        <div>
          {weekDays(schedule.weekdays).join('/')}
        </div>
        <div>
          {loc(dict.main_page.text_52)} {schedule.start_at.slice(0, 5)} {loc(dict.main_page.text_51)} {schedule.finish_at.slice(0, 5)}
        </div>
        <div>
          {loc(dict.main_page.text_52)} {dateFormat(schedule.dateFrom, "dd.mm")} {loc(dict.main_page.text_51)} {dateFormat(schedule.dateUntil, "dd.mm")}
        </div>
        <div onClick={() => {
          setHoursType(hoursType === 45 ? 60 : 45)
        }} >
          {getTotalHours(schedule.days, hoursType, holidays)} {`${hoursType === 45 ? `ак` : `астр`}.ч.`}
        </div>
        <div>Audience: {schedule.classroom_name}</div>
      </div>
    </div>
  )
}
