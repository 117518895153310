import React from 'react';
import {superAxiosRequest} from 'axiosApi';
import Stores from 'Stores';
import {localisationMaster} from "../../../../../Localisation/Localisation";

export default function BTS(props) {

    let Link = 'modal-bts'

    let apiConf = {
        method: 'delete',
        link: 'time_slots/teacher/slots/' +  props.modal_settings.id
    }
    const deleteSlot=(e, action)=>{ 
        superAxiosRequest(apiConf).then(response => {
           Stores.SuperModal.activity();
            if(action){
                updateSlot()
            }
        })
    }

    const updateSlot = () =>{
        let tmp_array_ts = {
            id: props.modal_settings.slot_client_id,
            location_id : props.location.id,
            s_date: props.modal_settings.s_date,
            student: +props.modal_settings.student_data.profile.id,
            age: +props.modal_settings.client_age.id,
            program: +props.modal_settings.client_program.id,
            father_name: props.modal_settings.student_data.profile.full_name,
            last_name: props.modal_settings.student_data.profile.last_name,
            first_name: props.modal_settings.student_data.profile.first_name,
            email: props.modal_settings.student_data.profile.email,
            date_of_birth: props.modal_settings.student_data.profile.date_of_birth,
            student_phone: props.modal_settings.student_data.profile.phone,
            result: props.modal_settings.client_result
        }
        localStorage.setItem('tmp_array_ts', JSON.stringify(tmp_array_ts))
        window.location = '/online-schedule/record-customer'
    }


    return (
        <div className="super-modal-many-buttons">
            <div className="super-modal-many-buttons-text">
            {!props.modal_settings.is_booked ? `${localisationMaster('text-1', Link)} ${props.modal_settings.s_date} 
            в ${props.modal_settings.slot.time_start.slice(0, -3)}
            `:null}
            {props.modal_settings.is_booked ? `${localisationMaster('text-1', Link)} ${props.modal_settings.s_date} 
            в ${props.modal_settings.slot.time_start.slice(0, -3)} (${props.location.name}). ${localisationMaster('text-2', Link)} 
            ${props.modal_settings.student_data.profile.full_name} (${props.modal_settings.student_data.profile.email}). 
           ${localisationMaster('text-3', Link)}
            `: null}
            </div>
            <div className="super-modal-many-buttons-buttons">
            {props.modal_settings.is_booked ? <button onClick={(e)=>deleteSlot(e, false)}>{localisationMaster('text-4', Link)}</button> :null}
            {props.modal_settings.is_booked ? <button onClick={(e)=>deleteSlot(e, true)}>{localisationMaster('text-5', Link)}</button>:null}
            {!props.modal_settings.is_booked ? <button onClick={(e)=>deleteSlot(e, false)}>
                {localisationMaster('text-6', Link)}</button>:null}

            </div>
            
           
            
            
            
        </div>
    )
}
