import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import Stores from 'Stores';
import BaseTemplate from './templates/BaseTemplate.jsx'
import AddMedia from './templates/AddMedia';
import Trio from './templates/Trio';
import CreatePoolTest from './templates/CreatePoolTest';
import BTS from './templates/BTS';
import ClassroomForm from './templates/ClassroomForm';
import ClientInformation from './templates/ClientInformation';
import MultiPhoneNumbers from './templates/MultiPhoneNumbers/MultiPhoneNumbers';
import AddAvatar from "./templates/AddAvatar";
import SettingPayments from './templates/SettingPayments';
import LessonsQuestion from './templates/LessonsQuestion/LessonsQuestion'
import { lessonQuestion, trio, ttd, userToGroup, forward, createPool, clientInfo } from './SuperModalFunctions';
import Loader from '../Loader/Loader';
import EmployeeContract from './templates/WorkPlain/EmployeeContract.jsx';
import WorkSchedule from './templates/WorkPlain/WorkSchedule';
import CreateTestPool from './templates/StudyTest/CreateTestPool';
import TestRequest from './templates/StudyTest/TestRequest';
import FundTransfer from './templates/groups/FundTransfer';
import './SuperModal.scss';

const SuperModal = () => {
    let apiConf;
    const [inputsValues, setinputsValues] = useState([]);
    const [settingsArray, setsettingsArray] = useState([])
    const [companies, setcompanies] = useState([])
    const errors = [false, '', []]
    const [createORupdate, setCORU] = useState([false, 0]);
    let handler = {};
    const modal_settings = new Proxy(Stores.SuperModal.data, handler)
    let fix_data = Stores.SuperModal.fix_data

    const errorCheker = key => {
        if (errors[2].includes(key)) return '#db3031'
    }

    const handleSubmit = (inner) => {
        let qJson = { ...inner }, result = false, link = modal_settings.link, formData = new FormData(), apiConf;
        let method = 'post';
        Stores.SuperModal.loadingHandler(true)
        const ms = { ...modal_settings }
        if (ms.is_put) method = 'put'
        apiConf = {
            method: method,
            link: link,
        }
        if (qJson['hours_per_day'] !== undefined) qJson.hours_per_day = Number(qJson.hours_per_day.toFixed())
        switch (ms.type) {
            case 'lessons_question':
                lessonQuestion(qJson, apiConf)
                break;
            case 'time_table_note':
                ttd(qJson, apiConf, ms)
                break;
            /*case 'time_table':
                result = tt(qJson, apiConf, ms)
                break;*/
            case 'SelectorAndInput':
                result = trio(qJson, apiConf, ms)
                break;
            case 'client_info':
                result = clientInfo(qJson, apiConf, ms)
                break;
            case 'create_poll_test':
                result = createPool(qJson, apiConf, ms)
                break;
            case 'user_to_group':
                userToGroup(qJson, apiConf, ms, createORupdate)
                break;
            case 'AddAvatar':
                result = forward(qJson, apiConf, 'media')
                break;
            default:
                result = forward(qJson, apiConf)
                break;
        }
        if (result) {
            if (result.type !== 'media') {
                superAxiosRequest(result.apiConf, result.qJson).then(response => {
                    if (ms.func) ms.func(response.data)
                    Stores.SuperModal.activity();
                    if (modal_settings.name === 'time_zone') {
                        let dataStorage = localStorage.getItem('ud');
                        let data = JSON.parse(dataStorage);
                        if (data.user_data.id === +modal_settings.put_id) {
                            data.user_data = response.data
                            localStorage.setItem('ud', JSON.stringify(data))
                            document.location.replace(window.location.href);
                        }
                    }
                }).catch(() => {
                    Stores.SuperModal.loadingHandler(false)
                });
            } else {
                /*for (var key in result.qJson) {
                    formData.append(key, result.qJson[key]);
                }*/
                Object.keys(result.qJson).forEach(key => formData.append(key, result.qJson[key]))
                superAxiosRequest(result.apiConf, formData, 'media').then(() => {
                    Stores.SuperModal.activity();
                }).catch(() => {
                    Stores.SuperModal.loadingHandler(false)
                });
            }

        }

    }

    const changeHandler = (e, key_name, type) => {
        let value = e.target.value;
        if (key_name === 'email') {
            value = value.toLowerCase()
        }
        if (type === 'int') {
            value = +value
        }
        if (type === 'checkbox') {
            value = e.target.checked
        }
        if (type === 'media') {
            value = e.target.files[0];
        }
        if (type === 'mediaAvatar') {
            value = e.target.files[0];
        }

        setinputsValues({ ...inputsValues, [key_name]: value });
    }

    const objectChangeHandler = (value, key_name) => {
        let arr = inputsValues;
        if (arr.discount && key_name === 'surcharge') delete arr.discount
        if (arr.surcharge && key_name === 'discount') delete arr.surcharge
        arr[key_name] = value
        setinputsValues({ ...arr });
    }

    const arrayChangeHandler = (array) => {
        setinputsValues(array)
    }

    const arrayInKeyChangeHandler = (array, key_name) => {
        setinputsValues({ ...inputsValues, [key_name]: [{ ...array }] });
    }

    /*const rollChangeHandler = array => {
        for (let key in array) {
            setinputsValues({ ...inputsValues, [key]: array[key] });
        }
    }*/

    const returnValue = (key_name) => {
        if (inputsValues !== null && inputsValues !== undefined && inputsValues.length !== 0) {
            return inputsValues[key_name]
        } else {
            return ''
        }
    }

    const refInside = useRef(null)

    const closeModal = () => {
        refInside.current.addEventListener('transitionend', () => {
            Stores.SuperModal.activity(0)
        }, { once: true })
        refInside.current.classList.add("super-modal-inside--slide-down")
    }

    const defaultPropps = {
        changeHandler: changeHandler,
        objectChangeHandler: objectChangeHandler,
        arrayChangeHandler: arrayChangeHandler,
        returnValue: returnValue,
        values: inputsValues,
        modal_settings: modal_settings,
        errorCheker: errorCheker,
        fix_data: fix_data,
    }

    const createModalBody = () => {
        if (modal_settings.link !== '') {
            if (settingsArray.length < 1) { setsettingsArray(modal_settings); }
            switch (modal_settings['type']) {
                case 'lessons_question':
                    return (
                        <LessonsQuestion arrayChangeHandler={arrayChangeHandler} objectChangeHandler={objectChangeHandler} modal_settings={modal_settings}
                        />
                    )
                case 'BaseTemplate':
                    return (
                        <BaseTemplate
                            changeHandler={changeHandler}
                            values={inputsValues}
                            items={companies}
                            name_text={modal_settings.name_text}
                            name_select={modal_settings.name_select}
                            pl_select={modal_settings.pl_select}
                            pl_text={modal_settings.pl_text}
                            err_arr={errors}
                            returnValue={returnValue}
                            errorCheker={errorCheker}
                            modal_settings={modal_settings}
                        />
                    )
                case 'AddMedia':
                    return (
                        <AddMedia changeHandler={changeHandler}
                            objectChangeHandler={objectChangeHandler}
                            returnValue={returnValue}
                            values={inputsValues}
                            modal_settings={modal_settings}
                            errorCheker={errorCheker}
                        />
                    )
                case 'AddAvatar':
                    return (
                        <AddAvatar changeHandler={changeHandler}
                            objectChangeHandler={objectChangeHandler}
                            returnValue={returnValue}
                            values={inputsValues}
                            modal_settings={modal_settings}
                            errorCheker={errorCheker}
                        />
                    )
                case 'SelectorAndInput':
                    return (
                        <Trio {...defaultPropps} />
                    )
                case 'create_poll_test':
                    return (
                        <CreatePoolTest
                            modal_settings={modal_settings}
                            changeHandler={changeHandler}
                            objectChangeHandler={objectChangeHandler}
                            returnValue={returnValue}
                            companies={companies}
                            errorCheker={errorCheker}
                        />
                    )
                case 'bts':
                    return (
                        <BTS modal_settings={modal_settings.data} location={modal_settings.location} />
                    )
                case 'classroom':
                    return (
                        <ClassroomForm
                            modal_settings={modal_settings}
                            fix_data={fix_data}
                            returnValue={returnValue}
                            changeHandler={changeHandler}
                            arrayChangeHandler={arrayChangeHandler}
                            objectChangeHandler={objectChangeHandler}
                            errorCheker={errorCheker}
                        />
                    )
                case 'client_info':
                case 'user_to_group':
                    return (
                        <ClientInformation
                            modal_settings={modal_settings}
                            arrayChangeHandler={arrayChangeHandler}
                            returnValue={returnValue}
                            changeHandler={changeHandler}
                            objectChangeHandler={objectChangeHandler}
                            arrayInKeyChangeHandler={arrayInKeyChangeHandler}
                            errorCheker={errorCheker}
                            values={inputsValues}
                            coru={setCORU}
                            is_group={true}
                        />
                    )
                case 'add_client':
                    return (
                        <ClientInformation
                            modal_settings={modal_settings}
                            arrayChangeHandler={arrayChangeHandler}
                            returnValue={returnValue}
                            changeHandler={changeHandler}
                            objectChangeHandler={objectChangeHandler}
                            arrayInKeyChangeHandler={arrayInKeyChangeHandler}
                            errorCheker={errorCheker}
                        />
                    )
                case 'milti_phone':
                    return (
                        <MultiPhoneNumbers
                            arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            objectChangeHandler={objectChangeHandler}
                            fix_data={fix_data}
                            errorCheker={errorCheker}
                        />
                    )
                case 'setting-payments':
                    return (
                        <SettingPayments
                            arrayChangeHandler={arrayChangeHandler}
                            changeHandler={changeHandler}
                            returnValue={returnValue}
                            modal_settings={modal_settings}
                            objectChangeHandler={objectChangeHandler}
                            fix_data={fix_data}
                            errorCheker={errorCheker}
                        />
                    )
                case 'employee_contract':
                    return (
                        <EmployeeContract arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            fix_data={fix_data}


                        />
                    )
                case 'workSchedule':
                    return (
                        <WorkSchedule
                            modal_settings={modal_settings}
                            fix_data={fix_data}
                            objectChangeHandler={objectChangeHandler}
                            arrayChangeHandler={arrayChangeHandler}
                        />
                    )
                case 'create-study-test':
                    return (
                        <CreateTestPool arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            fix_data={fix_data} />
                    )
                case 'request-study-test':
                    return (
                        <TestRequest arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            fix_data={fix_data} />
                    )
                /*case 'add-question-study-test':
                    return (
                        <AddQuestion arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            fix_data={fix_data} />
                    )*/
                case 'fund-transfer':
                    return (
                        <FundTransfer arrayChangeHandler={arrayChangeHandler}
                            modal_settings={modal_settings}
                            fix_data={fix_data} />
                    )
                default:
                    return "Ошибка шаблона модального окна"
            }
        }
    }

    useEffect(() => {

        if (modal_settings.companies) {
            apiConf = {
                method: 'get',
                link: 'db/companies'
            }
            superAxiosRequest(apiConf).then(response => {
                setcompanies(response.data.result)
            })
        }

        refInside.current.classList.remove("super-modal-inside--slide-down")

    }, [])


    return (
        <div className="super-modal">
            <div className="super-modal-inside super-modal-inside--slide-down" style={{ width: modal_settings.width }} ref={refInside}>
                <div className="super-modal-header">
                    <div className="super-modal-title">{modal_settings.title}</div>
                    <div className="super-modal-close" onClick={closeModal}>&#215;</div>
                </div>
                {Stores.SuperModal.loading ?
                    <div className="super-modal--wait">
                        <Loader />
                        <p>Отправка данных</p>
                    </div>
                    : <div className="super-modal-content">
                        {createModalBody()}
                    </div>
                }
                {errors[0] &&
                    <div className="super-modal-errors">
                        <span>Внимание! </span>
                        {errors[2].includes('non_field_errors') ?
                            <span>Вы не можете отправить пустую форму. Заполните все поля.</span>
                            :
                            errors[2].includes('repeat_name') ? <span>Такая аудитория уже существует</span> : <span>Корректно заполните обязательные поля</span>
                        }
                    </div>
                }
                <div className={modal_settings.disable_buttons ? "dn" : "super-modal__buttons"}>
                    {modal_settings.type !== 'Informer' &&
                        <div className="super-modal__cancel-button" onClick={closeModal}>
                            {localisationMaster('button-one', 'modal-profile')}
                        </div>
                    }
                    {modal_settings.button !== false &&
                        <button
                            className="super-modal__add-button"
                            onClick={() => handleSubmit(inputsValues)}
                            disabled={Stores.SuperModal.loading}
                        >
                            {modal_settings.button}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(SuperModal);
