import swal from 'sweetalert';
import LocalStorage from 'services/LocalStorage';
import { calcPayDate } from 'components/Modules/Slots/utils';
import { createContentElement } from './SwalContent';

const getSlotFormat = (isOnline, isSchool, name) => {
  if (isOnline && isSchool) {
    return `Онлайн | ${name}`;
  }

  if (isOnline && !isSchool) {
    return 'Только онлайн';
  }

  if (!isOnline && isSchool) {
    return `Офлайн | ${name}`;
  }
};

export const getAdaptedData = (array, selectedLocation) => {
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;

  return array.map((item) => {
    const {slot_time, employee_slot_is_school, employee_slot_is_online, student_email, student_phone} = item;
    const ISODate = `${item.slot_date}T${slot_time}.000Z`;
    const date = calcPayDate(0, timeZone, Date.parse(ISODate))

    return {
      ...item,
      date: date.withoutYear,
      format: getSlotFormat(employee_slot_is_online, employee_slot_is_school, selectedLocation.name),
      contacts: { phone: student_phone, email: student_email },
    }
  });
};

export const showSwal = (data, type) => {
  let title = '';
  switch (type) {
    case 'zoom':
      title = 'Ссылка на онлайн конференцию';
      break;
    case 'email':
      title = 'Код подтверждения по e-mail';
      break;
    case 'sms':
      title = 'Код подтверждения по SMS';
      break;
  }
  return swal({
    icon: 'success',
    title,
    content: createContentElement(data, type),
    button: true,
  });
};
