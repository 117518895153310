import React from 'react';
import MainSection from './MainSection';
import TypeSection from './TypeSection';
import RegistrationSection from './RegistrationSection';
import SubmitSection from './SubmitSection';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import { observer } from 'mobx-react';

const Layouts = () => {
  const {isOnlineAllowed} = slotsSettingsStore;
  return <>
    <MainSection/>
    {isOnlineAllowed &&
      <>
        <TypeSection/>
        <RegistrationSection/>
      </>
    }
    <SubmitSection/>
  </>
};

export default observer(Layouts);
