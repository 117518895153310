import React, { useEffect, useRef, useState } from 'react';
import Stores from 'Stores';
import styles from './QuestionAudios.module.scss';
import Plyr from 'plyr';
import ActionMenu from "components/UI/ActionMenu/ActionMenu";



const menu = [
    {name: "Открыть скрипт аудио-файла", action: 'script'},
    {name: "Отобразить QR-код аудио-файла", action: 'qrcode'},

]

export function AudioPlayer(props) {
    const [show, setShow] = useState(false);
    const [isOpen , setIsOpen] = useState(false)
    const audioRef = useRef();
    const { innerWidth } = window;
    const isMobile = innerWidth < 768;
    const [player, setPlayer] = useState(null);
    const scriptRef = useRef();


    const handleOpenScript = () => {
        setIsOpen(!isOpen)
    }

    const action = (action) => {
        switch (action) {
            case 'script':
                handleOpenScript()
                break;
            case 'qrcode':
                break;
            default:
                break;
        }
    }




    useEffect(() => {
        const newPlayer = new Plyr(audioRef.current, {
            controls: isMobile
                ? ['play', 'progress', 'current-time', 'mute', 'settings']
                : ['play', 'progress', 'current-time', 'mute','volume', 'settings'],
        });

        setPlayer(newPlayer);

        return () => {
            newPlayer.destroy();
        };
    }, [isMobile]);

    useEffect(() => {
        if (player) {
            player.on('play', () => {
                props.onPlay(props.index);
            });
        }
    }, [player, props]);

    useEffect(() => {
        if (player && props.index !== props.activeIndex) {
            player.pause();
        }
    }, [player, props.index, props.activeIndex]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (scriptRef.current && !scriptRef.current.contains(event.target)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {

            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [scriptRef]);

    return (
        <>
            <div className={styles.container}>
                <h4 className={styles.desc}>{props.description}</h4>
                <div className={styles.audioContainer}>
                    <div className={styles.audio}>
                        <audio ref={audioRef} controls>
                            <source src={Stores.baseLink() + props.file} />
                            Ваш браузер не поддерживает элемент <code>audio</code>.
                        </audio>

                    </div>
                    <ActionMenu menu={menu} action={action}/>
                </div>
                {isOpen &&
                    <div className={styles.script}>
                        {props?.script}
                    </div>
                }
            </div>
        </>
    );
}
export default function QuestionAudios(props) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handlePlay = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className={styles.content}>
            {props.items.map((audio, index) => {
                return (
                    <AudioPlayer
                        script={audio.script}
                        key={index}
                        index={index}
                        file={audio.file}
                        description={audio.description}
                        onPlay={handlePlay}
                        data={audio.data}
                        activeIndex={activeIndex}
                    />
                );
            })}
        </div>
    );
}