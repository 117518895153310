import React, { useContext } from 'react';
import { classNames as cn } from "helpers";
import StudyTestResultStore from "Stores/studyTestResultStore";
import modalStore from "Stores/modalStore";
import Context from "../../resultContext";
import st from "./BriefAnswers.module.scss";
import { ReactComponent as TrueAnswer } from "components/ContentBox/img/true.svg";
import { ReactComponent as WrongAnswer } from "components/ContentBox/img/false.svg";

export default function BriefAnswers() {
  const {result} = StudyTestResultStore
  const { isClient } = useContext(Context)
  const valid = result.is_correct_answers.some(el => el.is_correct)

  const openModal = id => {
    if (isClient) return;
    const indexQuestion = result.questions.findIndex(el => el === id);
    const answer = result.answers.find(answer => answer.question.id === id);

    modalStore.activity({
      name: "show_question_test_result",
      title: `Вопрос №${indexQuestion + 1}`,
      button: "Закрыть",
      type: "show_question_test_result",
    }, answer);
  };

  if (!valid) return <></>
  return (
    <ol className={st.answers}>{result.is_correct_answers.slice(0, result.answers.length).map((answer, index) =>
      <li
        key={answer.id}
        className={cn({ [st.answers_click]: !isClient })}
        onClick={() => openModal(answer.id)}
      >
        <p>{index + 1}</p>
        {Array.isArray(answer.is_correct) ?
          answer.is_correct.map((a, i) => a ? <TrueAnswer key={i} /> : <WrongAnswer key={i} />)
          :
          answer.is_correct ? <TrueAnswer /> : <WrongAnswer />}
      </li>)}
    </ol>
  );
}
