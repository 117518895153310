import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './InputCheckbox.module.scss';

function InputCheckbox({
  tag,
  label,
  name,
  selected,
  type,
  onChange,
  disabled,
  withLabel,
  withMargin,
  classes,
}) {
  const Tag = tag;

  return (
    <Tag
      className={cn({
        [styles.wrapper]: true,
        [styles.wrapper__margin]: withMargin,
        [classes]: classes,
      })}
    >
      <label
        className={cn({
          [styles.inner]: true,
        })}
        aria-label={label}
      >
        <input
          type={type}
          name={name}
          className={styles.checkbox}
          onChange={onChange}
          disabled={disabled}
          checked={selected}
        />
        <span className={styles.custom_checkbox}/>
        {withLabel &&
        <span className={styles.label_text}>
            {label}
          </span>
        }
      </label>

    </Tag>
  );
}

InputCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  tag: PropTypes.oneOf(['div', 'li', 'span']),
  description: PropTypes.string,
  withLabel: PropTypes.bool,
  withMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
};

InputCheckbox.defaultProps = {
  tag: 'div',
  type: 'checkbox',
  name: '',
  description: '',
  withLabel: true,
  disabled: false,
  withMargin: false,
};

export default InputCheckbox;

