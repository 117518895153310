import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from 'axiosApi';
import {permissions} from "settings/permissions";
import {checkPermList} from "helpers";
import {localisationMaster} from "Localisation/Localisation";
import useLocalization from "hooks/useLocalization";
import Stores from 'Stores';
import usersStore from "Stores/usersStore";
import asideModalStore from "Stores/asideModalStore";
import ModalChangePassword from "components/ContentBox/Company/Teachers/ModalChangePassword";
import Loader from "components/ContentBox/Global/Loader/Loader";
import LessonCard from "components/ContentBox/Company/components/LessonCard/LessonCard";
import PersonalInfo from "components/Modules/User/Employee/PersonalInfo";
import UserInfo from "components/Modules/User/Employee/UserInfo";
import EmployeeSkills from "components/ContentBox/Company/Employee/Tabs/EmployeeSkills/EmployeeSkills";
import StudyTests from "components/ContentBox/Company/components/StudyTests/StudyTests";
import Schedule from "components/ContentBox/Company/Employee/Tabs/Schedule/Schedule";
import Groups from "components/ContentBox/Company/Employee/Tabs/Groups/Groups";
import DocsTab from "components/ContentBox/Company/Employee/Tabs/Docs/DocsTab";
import Contracts from "components/ContentBox/Company/Employee/Tabs/EmployeeContracts/EmployeeContracts";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Books from "components/ContentBox/Company/Employee/Tabs/Books/Books";
import MyLessons from "components/Modules/User/MyLessons/MyLessons";
import ModalEmployerSkills from "./ModalEmployerSkills";
import ActiveLessons from "./ActiveLessons";
import ActiveTests from "./ActiveTests";
import st from "./EmployeeProfile.module.scss";


const EmployeeProfile = props => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [catalog, setCatalog] = useState([])
  const [reload, setReload] = useState(false)
  const [classNotice, setClassNotice] = useState({loading: true, list: []});
  const [currentTab, setCurrentTab] = useState(1)

  const infoProfile = usersStore.userProfile
  const ID = props.main || props.match.params['id']

  const Link = 'company/profiles/account'

  const {translate} = useLocalization({
    section: 'profile',
    subsection: 'main_info',
    dict: {
      "menu_main": "Main Information",
      "menu_tests": "Tests",
      "menu_work_schedule": "Work Schedule",
      "menu_groups": "Groups",
      "menu_documents": "Documents",
      "menu_contracts": "My Contracts",
      "menu_books": "Books",
      "menu_lessons": "My Lessons",
      "personal_info": "Personal Information",
      "balance": "Balance",
      "phone": "Phone",
      "additional_phone": "Additional Phone",
      "connect_now": "Connect now",
      "main_location": "Main Location",
      "department": "Department",
      "comment": "Comment",
      "job_title": "Job Title",
      "work_until": "Work Until",
      "disciplines": "Disciplines",
      "programs": "Test programs",
      "age_categories": "Age categories",
      "locations": "Locations",
      "contract_duration": "Contract duration",
      "rates_for_new_groups": "Rates for new groups",
      "working_info": "Working info",
      "not_installed": "Not installed",
      "program_type": "Program type",
      "bid": "Bid",
      "save": "Save",
      "online": "Online",
      "offline": "Offline",
      "ac_h": "ac.h.",
      "astr_h": "astr.h.",
      "add": "Add",
      "adding_contract": "Adding a contract",
      "valid_from": "Valid from",
      "to": "to",
      "no_current_contract": "No current contract",
      "edit_contract": "Edit contract",
      "change_the_clock": "Change Work Hours",
      "edit": "Edit",
      "to_extend_the_contract": "To extend the contract",
      "contract_details": "Contract Details",
      "active_tests": "Active Tests",
      "vacation_information": "Vacation Information",
      "balance_today": "Balance Today",
      "balance_for_the_year": "Balance for the Year",
      "days": "days",
      "day" : "day",
      "day2" : "day",
      "year" : "year",
      "of_the_year" : "of the year",
      "years" : "years"

    }
  })

  const openModalSkills = skillKey => {
    if (!skillKey) return
    asideModalStore.activity({
      title: translate[skillKey],
      modalBodyComponent: () => <ModalEmployerSkills skillKey={skillKey} catalog={catalog}/>,
      button: translate.save,
      is_put: true,
      link: 'accounts/account_update/' + ID,
      onSubmitSuccess: usersStore.updateProfile
    }, {
      [skillKey]: infoProfile.profile[skillKey].map(sk => sk.id)
    })
  }

  const openModalProfile = () => {
    const {
      email,
      profile: {
        last_name,
        first_name,
        father_name,
        inn,
        date_of_birth = null,
        phone,
        mobile_phone,
        whatsapp,
        telegram
      }
    } = infoProfile

    asideModalStore.activity({
      type: 'customerData',
      title: translate.personal_info,
      button: localisationMaster('text_54', 'groups/all-groups'),
      is_put: true,
      link: 'accounts/account_update/' + ID,
      onSubmitSuccess: usersStore.updateProfile,
      valid: {
        date_of_birth: 'required',
        email: 'required',
        phone: 'required',
        first_name: 'required',
        mobile_phone: 'required',
        last_name: 'required',
        inn: 'required',
      }
    }, {
      last_name,
      first_name,
      father_name: father_name || '',
      email,
      inn,
      date_of_birth,
      phone,
      mobile_phone,
      whatsapp,
      telegram
    })
  }

  const openModalUserInfo = () => {
    asideModalStore.activity({
      type: 'employerWorkInfo',
      title: translate.working_info,
      button: localisationMaster('text_54', 'groups/all-groups'),
      is_put: true,
      link: 'accounts/account_update/' + ID,
      onSubmitSuccess: usersStore.updateProfile,
      valid: {
        company: 'required',
        location: 'required',
        employment: 'required',
        profession: 'required',
        work_at: 'required'
      }
    }, {
      company: infoProfile.profile?.company?.id || null,
      location: infoProfile.profile.location?.id || null,
      employment: infoProfile.profile.employment?.id || null,
      profession: infoProfile.profile.profession?.id || null,
      work_at: infoProfile.profile.work_at || null,
      comment: infoProfile.profile.comment || '',
    })
  }

  const createTabs = () => [
    {
      id: 1,
      name: translate.menu_main,
      tab: <EmployeeSkills translate={translate} catalog={catalog} info={infoProfile} modal={openModalSkills}/>
    },
    ...(checkPermList(permissions.view_studytest_purpose_test_list) ? [{
      id: 2, name: translate.menu_tests, tab: <StudyTests/>
    }] : []),
    ...(checkPermList(permissions.view_work_graphic_rules) ? [{
      id: 3,
      name: translate.menu_work_schedule,
      tab: <Schedule id={infoProfile.profile.id}/>
    }] : []),
    ...(checkPermList(permissions.view_group) ? [{
      id: 4,
      name: translate.menu_groups,
      tab: <Groups info={infoProfile} id={infoProfile.profile.id} />
    }] : []),
    ...(checkPermList(permissions.view_own_filedocuments) || checkPermList(permissions.view_filedocuments) ? [{
      id: 5,
      name: translate.menu_documents,
      tab: <DocsTab info={infoProfile}/>
    }] : []),
    ...(Stores.Rules.checkRule('contracts', ["view", "view_own"]) ? [{
      id: 6,
      name: translate.menu_contracts,
      tab: <Contracts translate={translate} user={infoProfile}/>
    }] : []),
    ...(checkPermList(permissions.view_ebook_books) || checkPermList(permissions.view_ebook_book) ? [{
        id: 7,
        name: translate.menu_books,
        tab: <Books/>
      }] : []),
    {
      id: 8,
      name: translate.menu_lessons,
      tab: <MyLessons userId={ID}/>
    },
  ]

  useEffect(() => {
    if (!isLoaded || reload || !Stores.reload) {
      Stores.reload = true;
      Stores.reloadAvatar = false;
      const apiConf = {
        method: 'get',
        link: 'accounts/employers_catalog',
      }
      superAxiosRequest(apiConf, 'data').then(response => {
        setCatalog(response.data)
      })
      setIsLoaded(true)
      setReload(false)
    }
  }, [isLoaded, reload])

  useEffect(() => {
    usersStore.getProfileData(ID)
    if (checkPermList(permissions.view_group_near_schedules) && props.main) {
      superAxiosRequest({method: 'get', link: 'groups/show_my_schedule'})
        .then(({data}) => setClassNotice({list: data, loading: false}))
        .catch(() => setClassNotice({loading: false, list: []}))
    }
    return () => {
      usersStore.deleteProfile()
    }
  }, [ID, props.main])

  return (Object.keys(infoProfile).length > 0 ?
      <>
        {isLoaded &&
          <>
            {modalOpen &&
              <ModalChangePassword
                id={ID}
                modal={() => {
                  setModalOpen(!modalOpen);
                  setReload(true)
                }}
              />}

            <div className={st.employeeCard}>

              <div>
                {(checkPermList(permissions.view_group_near_schedules) && props.main) && (
                  <div className={st.notifications}>
                    {classNotice.loading
                      ? <Loader/>
                      : classNotice.list.map((el, idx) => <LessonCard key={idx} {...el} is_client={Stores.isClient}/>)
                    }
                  </div>)}

                {/*<div>
                  <EmployeeInfo
                    info={infoProfile}
                    modal={openSmallModal}
                    modal_profile={openModalProfile}
                    modal_user_info={openModalUserInfo}
                    openModalStatus={openModalStatus}
                    //catalog={catalog}
                    ID={ID}
                  />
                </div>*/}


                <div className={st.employeeCardGrid}>
                  <div>
                    <UserInfo user={infoProfile}
                              tabs={createTabs()}
                              currentTab={currentTab}
                              onChangeTab={setCurrentTab}
                              translate={translate}
                              updateProfile={usersStore.updateProfile}
                              isShowKundelik
                    />
                  </div>

                  <div className={st.employeeCardGridColumn}>
                    <PersonalInfo user={infoProfile}
                                  openModalProfile={openModalProfile}
                                  openModalUserInfo={openModalUserInfo}
                                  translate={translate}/>
                    <div className={st.menu}>
                      <SelectCustom options={createTabs()}
                                    valueID={currentTab}
                                    onChange={opt => {setCurrentTab(opt.id)}}/>
                    </div>
                    <div>{createTabs().find(tab => tab.id === currentTab).tab}</div>
                    
                    {currentTab === 1 &&
                        <>
                          <ActiveLessons userId={infoProfile.id} />
                          <ActiveTests translate={translate}/>
                        </>
                    }
                    
                  </div>
                </div>

              </div>

            </div>
          </>
        }

        {usersStore.isError &&
          <div className="content-box-none">{localisationMaster('error-text-3', Link)} <br/></div>
        }
      </> :
      <Loader/>
  )
}

export default observer(EmployeeProfile);
