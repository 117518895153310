import {action, computed, makeObservable, observable} from 'mobx';
import {calcPayDate, calcTimeDiff} from 'components/Modules/Slots/utils';
import {superAxiosRequestEasy} from 'axiosApi';
import LocalStorage from 'services/LocalStorage';

export const getOptions = (data) => data.map((item) => ({...item, value: item.id, label: item.name}));

export const DataType = {
  company: 'company',
  selectedLocation: 'selectedLocation',
  selectedAge: 'selectedAge',
  selectedProgram: 'selectedProgram',
  selectedEmployee: 'selectedEmployee',
  isGuardian: 'isGuardian',
  timeZone: 'timeZone',
  bank: 'bank',
  order: 'order',
  isOrderPayed: 'isOrderPayed',
  paymentStep: 'paymentStep',
  toggles: 'toggles',
};

export const ActionType = {
  resetSlots: 'resetSlots',
  resetSelectedSlots: 'resetSelectedSlots',
  resetClientData: 'resetClientData',
  resetGuardianData: 'resetGuardianData',
  resetAll: 'resetAll',
};

const initialGuardianData = {
  first_name: {mandatory: true},
  last_name: {mandatory: true},
  phone: {mandatory: true},
  email: {mandatory: true},
  birthday: {mandatory: true},
  sex: {mandatory: true},
  inn: {mandatory: true},
};

class SlotsNewRecordOnlineStore {
  company = 1;
  locations = [];
  ages = [];
  programs = [];
  selectedLocation = null;
  selectedAge = null;
  selectedProgram = null;
  dates = [];
  selectedDates = new Set();
  employees = [];
  selectedEmployee = null;
  slots = [];
  selectedSlots = new Set();
  paymentTime = null;
  paymentType = null; // 1 - платный, 2 - по смс, 3 - через админа, 4 - по емайл, 5 - без подтверждения, 6 - платно по локациям
  chooseTeacher = null; // возможность выбора учителя
  isGuardian = false;
  guardianData = initialGuardianData;
  clientData = null;
  timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  payDate = null;
  banks = [];
  bank = null;
  order = null;
  isOrderPayed = false;
  slotClientID = null;
  paymentStep = 1;
  toggles = null;
  locationSlotPrice = null;

  constructor() {
    makeObservable(this, {
      company: observable,
      locations: observable,
      ages: observable,
      programs: observable,
      selectedLocation: observable,
      selectedAge: observable,
      selectedProgram: observable,
      dates: observable,
      selectedDates: observable,
      employees: observable,
      slots: observable,
      paymentTime: observable,
      paymentType: observable,
      chooseTeacher: observable,
      selectedSlots: observable,
      selectedEmployee: observable,
      isGuardian: observable,
      guardianData: observable,
      clientData: observable,
      timeZone: observable,
      payDate: observable,
      bank: observable,
      order: observable,
      isOrderPayed: observable,
      slotClientID: observable,
      paymentStep: observable,
      toggles: observable,
      locationSlotPrice: observable,

      isReadyToLoadCalendar: computed,
      sum: computed,
      isSubmitAllowed: computed,
      employeesInSlot: computed,
      getBank: computed,

      setData: action.bound,
      setClientData: action.bound,
      getOptionsFromServer: action.bound,
      getDatesFromServer: action.bound,
      getSlotsFromServer: action.bound,
      resetData: action.bound,
      submitData: action.bound,
      confirmCode: action.bound,
      checkPaymentStatus: action.bound,
    });
  }

  get isReadyToLoadCalendar() {
    return Boolean(this.selectedLocation && this.selectedProgram && this.selectedAge);
  }

  get employeesInSlot() {
    return this.employees.filter((item) => item.slot_id === [...this.selectedSlots.values()][0]);
  }

  get sum() {
    if (this.paymentType === 1) {
      const {
        price,
        price_type
      } = this.employees.find((item) => item.id === (this.selectedEmployee || this.employees[0].id));

      const {start_at, finish_at} = this.slots.find(({id}) => id === [...this.selectedSlots.values()][0]);
      const timeDiff = calcTimeDiff(start_at, finish_at);
      const multiplier = price_type === '15 минут' ? Math.ceil(timeDiff / 15) : 1;
      return price * multiplier / 100;
    } else if (this.paymentType === 6){
      return this.locationSlotPrice / 100;
    }
    return 0
  }

  get isSubmitAllowed() {
    for (let key in this.clientData) {
      if (this.clientData[key].mandatory && !this.clientData[key]?.value) {
        return false;
      }
    }

    if (this.isGuardian) {
      for (let key in this.guardianData) {
        if (this.guardianData[key].mandatory && !this.guardianData[key]?.value) {
          return false;
        }
      }
    }

    const rules = [
      this.employees.length > 0 && this.selectedEmployee === null,
      this.clientData.inn?.value?.length !== 12,
      this.isGuardian && this.guardianData.inn?.value && this.guardianData.inn?.value?.length !== 12,
      this.paymentStep > 1,
    ];

    return rules.every((item) => item !== true);
  }

  get getBank(){
    return this.banks.find(b => b.id === this.bank)
  }

  setData(data, type) {
    this[type] = data;
  }

  setClientData(value, type, isGuardian) {
    isGuardian
      ? this.guardianData[type] = { ...this.guardianData[type], value }
      : this.clientData[type] = { ...this.clientData[type], value };
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetSlots:
        this.slots = [];
        this.selectedSlots.clear();
        this.selectedEmployee = null;
        this.paymentType = null;
        this.paymentStep = 1;
        this.isOrderPayed = false;
        break;
      case ActionType.resetSelectedSlots:
        this.selectedSlots.clear();
        this.selectedEmployee = null;
        break;
      case ActionType.resetClientData:
        this.selectedEmployee = this.chooseTeacher ? null : this.getRandomTeacher().id;
        break;
      case ActionType.resetGuardianData:
        this.guardianData = initialGuardianData;
        break;
      case ActionType.resetAll:
        this.clientData = null;
        this.selectedSlots.clear();
        this.selectedDates.clear();
        this.slots = [];
        this.bank = null;
        this.order = null;
        this.slotClientID = null;
        this.paymentStep = 1;
        this.paymentType = null;
        this.isOrderPayed = false;
        break;
      default:
        return;
    }
  }

  getOptionsFromServer(onFail, onFinally) {
    superAxiosRequestEasy({
      method: 'get',
      link: 'online_schedule/client',
      params: {
        company: this.company,
        step: 1,
      },
    })
      .then(({ data }) => {
        this.locations = getOptions(data.locations);
        this.ages = getOptions(data.ages);
        this.programs = getOptions(data.programs);

        const { selects = {} } = JSON.parse(localStorage.getItem('tmp_array_new_wu') || '{}');
        const selected = {
          selectedLocation: data.locations?.find(el => el.id === selects?.location),
          selectedProgram: data.programs?.find(el => el.id === selects?.program),
          selectedAge: data.ages?.find(el => el.id === selects?.age),
        };
        Object.keys(selected).forEach(key => {
          if(!selected[key]) return;
          const item = selected[key];
          this[key] = { ...item, value: item.id, label: item.name };
        })
      })
      .catch(() => onFail(true))
      .finally(() => onFinally(false));
  }

  getDatesFromServer(date, onFinally, onFail) {
    const apiConf = {
      method: 'get',
      link: 'online_schedule/client',
      params: {
        company: this.company,
        location: this.selectedLocation.value,
        age: this.selectedAge.value,
        program: this.selectedProgram.value,
        date_from: date,
        step: 2,
      },
    };
    superAxiosRequestEasy(apiConf)
      .then(({data}) => this.dates = data)
      .catch(() => onFail())
      .finally(() => onFinally(false));
  }

  getSlotsFromServer(dateIndex) {
    const apiConf = {
      method: 'get',
      link: 'online_schedule/client',
      params: {
        company: this.company,
        location: this.selectedLocation.value,
        age: this.selectedAge.value,
        program: this.selectedProgram.value,
        date_from: this.dates[dateIndex].date_at,
        step: 3,
      },
    };
    superAxiosRequestEasy(apiConf).then(({data}) => {
      const {
        banks,
        client_data,
        employees,
        slot_data,
        payment_time,
        payment_type,
        choose_teacher,
        location_slot_price
      } = data;
      const {form = {}} = JSON.parse(localStorage.getItem('tmp_array_new_wu') || '{}');

      Object.keys(form).forEach(key => {
        client_data[key].value = key === 'phone' ? `+${form[key]}` : form[key];
      });

      this.employees = employees;
      this.clientData = client_data;
      this.toggles = {'is_online': slot_data[0]?.is_online, 'is_school': !slot_data[0]?.is_online};
      this.slots = slot_data;
      this.paymentTime = payment_time;
      this.paymentType = payment_type;
      this.chooseTeacher = choose_teacher;
      this.payDate = calcPayDate(payment_time, this.timeZone);
      this.banks = banks;
      this.bank = banks[0].id;
      this.locationSlotPrice = location_slot_price
    });
  }

  submitData(onFail) {
    let client_data = {};
    for (let key in this.clientData) {
      client_data[key] = this.clientData[key].value;
    }
    client_data.date_of_birth = this.clientData.birthday.value;
    delete client_data.birthday;

    if (!this.clientData.inn.value) {
      delete client_data.inn;
    }

    if (client_data.level) {
      client_data.start_level = client_data.level;
      delete client_data.level
    }

    const data = {
      age: this.selectedAge.value,
      program: this.selectedProgram.value,
      payment_type: this.paymentType,
      payment_date: this.payDate.dateUTC,
      payment_time: this.payDate.timeUTC,
      payment_comment: 'Устное тестирование',
      is_online: this.toggles.is_online,
      employee_slot: this.employees.find(item => item.id === this.selectedEmployee &&
        item.slot_id === [...this.selectedSlots.values()][0]).employee_booked_slot_id,
      slot_id: this.slots.find(item => item.id === [...this.selectedSlots.values()][0]).id,
      client_data,
    };

    if (this.paymentType === 1 || this.paymentType === 6) {
      data.bank = this.bank;
      data.sum = this.sum * 100;
    }

    if (this.paymentType === 2) {
      data.phone = this.clientData.phone.value;
    }

    if (this.paymentType === 4) {
      data.email = this.clientData.email.value;
    }

    superAxiosRequestEasy({method: 'post', link: 'online_schedule/client'}, data)
      .then(({data}) => {
        this.slotClientID = data.slot_client_id;
        if (this.paymentType === 1 || this.paymentType === 6) {
          if (this.getBank.name.indexOf('alyk') !== -1 && data.invoice_url) {
            if (data.sum){
              window.location.href = data.invoice_url
            } else {
              this.order = data.order
              this.paymentStep = 2
              this.isOrderPayed = true
            }
          } else {
            this.order = data.order;
            this.paymentStep = 2;
          }
        }
        if (this.paymentType === 2 || this.paymentType === 4) {
          this.paymentStep = 2;
        }
        if (this.paymentType === 3 || this.paymentType === 5) {
          this.paymentStep = 3;
        }

        localStorage.removeItem('tmp_array_new_wu') // удаляем данные, чтобы при обновлении страницы были пустые
      })
      .catch(({response}) => onFail(response?.data?.error));
  }

  confirmCode(code, target, setAttempts) {
    const body = {
      code,
      target,
    };
    superAxiosRequestEasy({
      method: 'put',
      link: `online_schedule/slots/clients/${this.slotClientID}`
    }, body)
      .then(({data}) => {
        data.result ? this.paymentStep = 3 : setAttempts((prev) => prev - 1);
      })
  }

  checkPaymentStatus() {
    superAxiosRequestEasy({
      method: 'get',
      link: `payments/check_order/${this.order}`,
    })
      .then(({data}) =>this.isOrderPayed = data.paid_status);
  }

  getRandomTeacher(){
    const employees = this.employeesInSlot;
    const rand = Math.floor(Math.random() * employees.length)
    return employees[rand]
  }
}

export default new SlotsNewRecordOnlineStore();
