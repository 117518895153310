import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Flex from 'components/UI/Flex/Flex';
import FilterWrapper from 'components/Modules/Slots/BookedSchedule/components/FilterWrapper/FilterWrapper';
import CompanyLocationSelects from '../components/CompanyLocationSelects/CompanyLocationSelects';
import CalendarWrapper from 'components/Modules/Slots/BookedSchedule/components/CalendarWrapper/CalendarWrapper';
import CustomerTabs from 'components/Modules/Slots/BookedSchedule/components/CustomerTabs/CustomerTabs';
import CustomerList from 'components/Modules/Slots/BookedSchedule/components/CustomerList/CustomerList';
import slotsBookedScheduleStore, { ActionType } from 'Stores/slotsBookedScheduleStore';
import styles from './BookedSchedule.module.scss';

function BookedSchedule() {
  const {
    companiesList,
    locations,
    selectedLocation,
    selectedCompany,
    selectedDates,
    customers,
    setData,
    resetData,
  } = slotsBookedScheduleStore;

  useEffect(() => resetData(ActionType.resetCalendar), []);

  return (
    <>
      <ContentHeader title='Расписание записи клиентов' />
      <Flex withGap classes={styles.wrapper}>
        <div className={styles.selectors_wrapper}>
          <CompanyLocationSelects
            companiesList={companiesList}
            locationsList={locations}
            selectedLocation={selectedLocation}
            selectedCompany={selectedCompany}
            onResetData={resetData}
            onSetData={setData}
          />
        </div>
        {selectedLocation && selectedLocation && <CalendarWrapper classes={styles.calendar_wrapper}/>}
        { selectedDates.size > 0 &&
          <>
            { (customers[0]?.length > 0 || customers[1]?.length > 0 || customers[2]?.length > 0 ) &&
              <>
                <FilterWrapper />
                <CustomerTabs />
                <CustomerList />
              </>
            }
          </>
        }
      </Flex>
    </>
  );
}

export default observer(BookedSchedule);
