import React from "react";
import PropTypes from "prop-types";
import holidaysCalendarStore from "./holidaysCalendarStore";
import {classNames as cn} from "helpers";
import st from "./CalendarDay.module.scss";

const CalendarDay = ({day}) => {
    const {year, updateDate} = holidaysCalendarStore

    return (
        <div className={cn({
                 [st.day]: true,
                 [st.disabled]: day.disabled,
                 [st.selected]: day.selected,
             })}
             onClick={() => year >= new Date().getFullYear() && updateDate(day)}>
            <span>{day.date}</span>
        </div>
    )
}

CalendarDay.propTypes = {
    day: PropTypes.shape({
        date: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        selected: PropTypes.bool.isRequired,
        full_date: PropTypes.string
    })
}

export default CalendarDay
