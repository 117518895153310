import React, {forwardRef, useState} from 'react';
import {observer} from "mobx-react";
import {classNames as cn} from "helpers";
import {dateFormat} from "helpers/date";
import modalStore from 'Stores/modalStore';
import DateTime from 'components/ContentBox/Global/DateTime/DateTime';
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import DatePicker from "react-datepicker";


const tabs = [
  {id: 1, title: 'Для одного'},
  {id: 2, title: 'Для всех'},
]

const half = ({start_at, finish_at}) => {
  const diff = new Date(`2022-06-17T${finish_at}`) - new Date(`2022-06-17T${start_at}`) - 60 * 60 * 1000
  return dateFormat(new Date(new Date(`2022-06-17T${start_at}`).getTime() + diff/2), "hh:ii:ss")
}

const DayStatus = () => {
  const {data: {payload, ...ms}, changeValues, changeValuesArray, modalValues, setLink, setValid} = modalStore

  const isMany = !!payload.selectedTeachers

  const [currentTab, setCurrentTab] = useState(1);

  const changeStatus = id => {
    if (id === 7 || id === 8 || id === 9 || id === 10){
      changeValuesArray({
        ...modalValues,
        day_type: id,
        lunch: 0,
        finish_at: half(payload.day)
      })
    } else {
      changeValuesArray({
        ...modalValues,
        day_type: id,
        lunch: payload.day.lunch,
        finish_at: payload.day.finish_at,
      })
    }
  }

  const changeTab = id => {
    setCurrentTab(id)

    if (id === 1){
      setLink(ms.link.replace('/many', ''))
      setValid({})
      const {employees, day_from, ...obj} = {...modalValues, employee_id: payload.day.employee_id}
      changeValuesArray(obj)
    } else if (id === 2){
      setLink(ms.link + '/many')
      setValid({
        day_from: 'required'
      })
      const {employee_id, ...obj} = {
        ...modalValues,
        employees: payload.selectedTeachers.map(({id}) => id)
      }
      changeValuesArray(obj)
    }
  }

  const DayFromCustomInput = forwardRef(({value, onClick}, ref) => (
    <input type="text" value={value} onClick={onClick} readOnly ref={ref} className={cn({
      "super-modal__input": true,
      "super-modal__input--error": !modalValues.day_from,
    })}/>
  ))

  return (
    <div className='super-modal__form-column modalClientInfo'>
      {isMany &&
        <ul className="modalClientInfo__tabs">
          {tabs.map(tab =>
            <li key={tab.id}
                className={cn({
                  'modalClientInfo__tabsItem': true,
                  'modalClientInfo__tabsItem--active': tab.id === currentTab,
                })}>
              <span onClick={() => changeTab(tab.id)}>{tab.title}</span>
            </li>
          )}
        </ul>}

      <SelectCustom
        placeholder="Выберите статус"
        apiLink="employees/day_types"
        valueID={modalValues.day_type}
        onChange={o => changeStatus(o.id)}
      />

      {(modalValues.day_type === 1 || modalValues.day_type === 6) && currentTab === 1 &&
        <div className="super-modal__form-row2">
          <div className="super-modal__form-field">
            <p className="super-modal__form-label super-modal__form-label--required">Рабочее время:</p>
            <DateTime placeholder='9:00'
                      start_at={modalValues.start_at || '8:00'}
                      finish_at={modalValues.finish_at || '18:00'}
                      objectChangeHandler={changeValues}
            />
          </div>

          <div className="super-modal__form-field">
            <p className="super-modal__form-label super-modal__form-label--required">Перерыв, мин:</p>
            <DummyCounter step={1} max={60} name="lunch" value={modalValues.lunch} onChange={changeValues}/>
          </div>
        </div>}

      {currentTab === 2 && <div className="super-modal__form-field">
        <div className="super-modal__form-label super-modal__form-label--required">Перенос с:</div>
        <div className="react-datepicker-custom">
          <DatePicker locale="ru"
                      dateFormat="dd.MM.yyyy"
                      selected={modalValues.day_from && new Date(modalValues.day_from)}
                      onChange={date => changeValues(dateFormat(date, "YYYY-mm-dd"), 'day_from')}
                      customInput={<DayFromCustomInput/>}
          />
        </div>
      </div>}

      <div className="super-modal__form-field">
        <p className="super-modal__form-label">Комментарий:</p>
        <textarea value={modalValues.comment || ''}
                  placeholder="Комментарий"
                  onChange={e => changeValues(e.target.value, 'comment')}/>
      </div>
    </div>
  )
}

export default observer(DayStatus);
