import React, {useEffect, useState} from 'react';
import {classNames as cn} from 'helpers';
import {localisationMaster} from "Localisation/Localisation";
import PropTypes from 'prop-types';
import styles from './TimeTableTooltip.module.scss';
import ReactDOM from "react-dom";
import {timeConverter, timeConverter2} from 'components/ContentBox/Online-schedule/components/functions';


function TimeTableTooltip({text, isAvailable, isSchool, isOnline, isGroup, isLink, isShort, propsStyles, timeStart, timeEnd}) {
    const [open, setOpen] = useState(styles.timeTableTooltip);
    useEffect(() => {
        setOpen(styles.showTimeTableTooltip)
    }, []);
    return ReactDOM.createPortal(
        <div style={propsStyles} className={open}>
            <p className={styles.tooltip_name}>{text}</p>
            <p className={styles.tooltip_text}>
                {timeStart && timeEnd && `${timeConverter(timeStart, 'time')} - ${timeConverter(timeEnd, 'time')} `}
            </p>
            <p className={styles.tooltip_text}>{localisationMaster('text_7', 'teacher_schedule')}: <span>{isAvailable ? localisationMaster('button_1', 'alert').toLowerCase() : localisationMaster('button_2', 'alert').toLowerCase()}</span></p>
            {!isShort &&
                <>  
                    <p className={styles.tooltip_text}>
                        {isSchool && isOnline && localisationMaster('text_8', 'teacher_schedule')}
                        {!isSchool && isOnline && localisationMaster('text_9', 'teacher_schedule')}
                        {isSchool && !isOnline && localisationMaster('text_10', 'teacher_schedule')}
                    </p>
                    <p className={styles.tooltip_text}>{isGroup ? localisationMaster('text_11', 'teacher_schedule') : localisationMaster('text_12', 'teacher_schedule')}</p>
                    <p className={styles.tooltip_text}>
                        {localisationMaster('zoom_link', 'teacher_schedule')}: {isLink ? localisationMaster('there_is', 'teacher_schedule') : localisationMaster('button_2', 'alert').toLowerCase()}
                    </p>
                </>
            }
        </div>, document.getElementById('timeTable__wrapper_tooltip')
    );
}

TimeTableTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool,
    isSchool: PropTypes.bool,
    isGroup: PropTypes.bool,
    isLink: PropTypes.bool,
    isShort: PropTypes.bool,
};

export default TimeTableTooltip;
