import {observable, action, toJS, computed, makeObservable} from 'mobx';
import {
  adaptDateToServer,
  getAdaptedTime,
  getTimeInMinutes,
  humanizeTime,
} from 'components/Modules/Slots/utils';
import { superAxiosRequest } from 'axiosApi';

export const DataType = {
  companies: 'companies',
  selectedCompany: 'selectedCompany',
  selectedLocation: 'selectedLocation',
  startDate: 'startDate',
  isRepeat: 'isRepeat',
  settingsRef: 'settingsRef',
};
export const ActionType = {
  getAllDays: 'getAllDays',
  getSomeDays: 'getSomeDays',
  sendAllDays: 'sendAllDays',
  sendRemovedDays: 'sendRemovedDays',
};

class SlotsScheduleSettingsStore {
  companies = [];
  selectedCompany = null;
  selectedLocation = null;
  startDate = null;
  selectedDates = new Set();
  dates = [];
  editingData = [];
  isRepeat = false;
  startTime = null;
  endTime = null;
  duration = null;
  breakTime = null;
  timeGap = null;
  isBooked = false;
  slots = [];
  teachers = [];
  settingsRef = null;

  constructor() {
    makeObservable(this, {
      companies: observable,
      selectedCompany: observable,
      selectedLocation: observable,
      startDate: observable,
      selectedDates: observable,
      dates: observable,
      editingData: observable,
      isRepeat: observable,
      startTime: observable,
      endTime: observable,
      duration: observable,
      breakTime: observable,
      timeGap: observable,
      isBooked: observable,
      slots: observable,
      teachers: observable,
      settingsRef: observable,

      setData: action.bound,
      setDates: action.bound,
      updateDates: action.bound,
      setEditingData: action.bound,
      setTime: action.bound,
      setSlotCount: action.bound,
      resetData: action.bound,
      getDisabledDatesToServer: action.bound,
      getDataFromServer: action.bound,
      sendDataToServer: action.bound,

      companiesList: computed,
      locationsList: computed,
      slotsArray: computed,
      dataToServer: computed,
      isSelectedDaysAvailable: computed,
    });
  }

  setData(data, type) {
    this[type] = data;
  }

  setDates(data) {
    this.dates = data.map((item, index) => ({ ...item[0], id: index }));
  }

  updateDates(dates) {
    this.dates = this.dates.map((item) => {
      for (let date of dates) {
        if (date[0].date_at === item.date_at) {
          return { ...date[0], id: item.id };
        }
      }
      return item;
    });
  }

  setEditingData(data) {
    const { test_long, break_time, register_within, start_at, finish_at, slots } = data.dates[0][0];
    const isMultipleDates = data.dates.length > 1;
    this.editingData = data;
    this.duration = test_long || 15;
    this.breakTime = break_time || 0;
    this.timeGap = { value: register_within || 30, postfix: 1 };
    this.startTime = getAdaptedTime(start_at || this.selectedLocation.start_at);
    this.endTime = getAdaptedTime(finish_at || this.selectedLocation.end_at);
    this.isBooked = this.getBooked(data.dates);
    this.slots = isMultipleDates ? this.getSlots([]) : this.getSlots(slots || []);
    // TODO: add teachers when backend will return correct data
    this.teachers = [];
  }

  setTime(time, type) {
    this[type] = time;
    this.slots = this.getSlots(this.slots);
  }

  setSlotCount(value, startTime) {
    this.slots = this.slots.map((item) => (
      startTime === 'all' || item.start_at === startTime
        ? { ...item, teachers_count: value }
        : item
    ));
  }

  get companiesList() {
    return toJS(this.companies).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  get locationsList() {
    if (!this.selectedCompany) {
      return [];
    }
    const locations = this.companies.find((item) => item.id === this.selectedCompany.value).locations;
    return toJS(locations).map(({ id, name, start_at, end_at, time_zone, address }) => ({
      value: id,
      label: name,
      start_at,
      end_at,
      time_zone,
      address,
    })).sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get datesArray() {
    return toJS(this.dates);
  }

  get slotsArray() {
    return toJS(this.slots);
  }

  get dataToServer() {
    const data = {
      location: this.selectedLocation.value,
      company: this.selectedCompany.value,
      repeat: this.isRepeat,
      dates: [],
      start_from: adaptDateToServer(this.startDate),
    };

    toJS(this.dates).forEach((date, index) => {
      if (!this.selectedDates.has(index)) {
        return;
      }
      const params = {
        is_active: date.is_active || true,
        is_available: true,
        start_at: this.startTime,
        finish_at:this.endTime,
        test_long: this.duration,
        break_time: this.breakTime,
        register_within: this.timeGap.value * this.timeGap.postfix || 0,
        date_at: date.date_at,
        date_from: date.date_at,
        slots: toJS(this.slots),
      };
      if (this.isRepeat) {
        const weekdayNumber = new Date(date.date_at).getDay();
        params.dow = weekdayNumber === 0 ? 7 : weekdayNumber;
      }
      data.dates.push(params);
    });
    return toJS(data);
  }

  get isSelectedDaysAvailable() {
    const days = [];
    this.dates.forEach((item, index) => {
      if (!this.selectedDates.has(index)) {
        return;
      }
      days.push(item);
    })
    return days.every((day) => day.is_available === true || (day.is_available === undefined && day.is_active !== undefined));
  }

  getDisabledDatesToServer() {
    const data = [];
    toJS(this.dates).forEach((date, index) => {
      if (!this.selectedDates.has(index)) {
        return;
      }
      const params = {
        ...date,
        is_active: !this.isRepeat,
        is_available: false,
        date_from: date.date_at,
      };

      if (this.isRepeat) {
        params.dow = new Date(date.date_at).getDay();
      }
      data.push(params);
    })

    return {
      location: this.selectedLocation.value,
      company: this.selectedCompany.value,
      repeat: this.isRepeat,
      dates: data,
      start_from: adaptDateToServer(this.startDate),
    };
  }

  getBooked(dates) {
    let isBooked = false;
    dates.forEach((date) => {
      if (date[0]?.is_booked) {
        isBooked = true;
      }
    })
    return isBooked;
  }

  getSlots(data) {
    const slots = [];
    const startInMinutes = getTimeInMinutes(this.startTime, -this.selectedLocation.time_zone);
    let endInMinutes = getTimeInMinutes(this.endTime, -this.selectedLocation.time_zone);
    let period = startInMinutes;
    if (startInMinutes >= endInMinutes) {
      endInMinutes = endInMinutes + 24 * 60;
    }

    while (period <= endInMinutes - this.duration) {
      let teachers_count = 0;
      for (let item of data) {
        const isSlotTimeMatch = getAdaptedTime(item.start_at) === humanizeTime(period) && getAdaptedTime(item.finish_at) === humanizeTime(period + this.duration);
        if (isSlotTimeMatch) {
          teachers_count = item.teachers_count;
          break;
        }
      }
      slots.push({
        start_at: humanizeTime(period),
        finish_at: humanizeTime(period + this.duration),
        teachers_count,
      });
      period += this.breakTime + this.duration;
    }

    return slots;
  }

  resetData(type) {
    this.selectedDates = new Set();
    this.editingData = [];
    this.startTime = null;
    this.endTime = null;
    this.duration = null;
    this.breakTime = null;
    this.timeGap = null;
    this.isBooked = false;
    this.slots = [];
    this.teachers = [];
    if (type === 'withDates') {
      this.dates = [];
    }
  }

  getDataFromServer(type, onFail, onFinally) {
    const params = {
      company: this.selectedCompany.value,
      location: this.selectedLocation.value,
      start_from: adaptDateToServer(this.startDate),
    };
    let onSuccess;
    const selectedArray = [];
    for (let value of this.selectedDates.values()) {
      selectedArray.push(this.dates[value].date_at);
    }
    switch (type) {
      case ActionType.getAllDays:
        params.dates = adaptDateToServer(this.startDate);
        onSuccess = (data) => this.setDates(data.dates);
        break;
      case ActionType.getSomeDays:
        params.dates = selectedArray.join('&dates=');
        params.repeat = this.isRepeat;
        onSuccess = (data) => this.setEditingData(data);
        break;
      default:
        break;
    }
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/admin/slots',
      params,
    })
      .then(({ data }) => onSuccess(data))
      .catch(({message}) => onFail(message))
      .finally((payload) => onFinally(payload));
  }

  sendDataToServer(type, onSuccess = () => {}, onFail = () => {}, onFinally = () => {}) {
    let data;
    switch (type) {
      case ActionType.sendAllDays:
        data = this.dataToServer;
        break;
      case ActionType.sendRemovedDays:
        data = this.getDisabledDatesToServer();
        break;
      default:
        break;
    }

    superAxiosRequest({
      method: 'post',
      link: 'online_schedule/admin/slots'
    }, data)
      .then(({ data }) => {
        this.setDates(data.dates);
        onSuccess();
      })
      .catch(({message}) => onFail(message))
      .finally(() => {
        if (type === ActionType.sendAllDays) {
          this.resetData();
        }
        onFinally();
      });
  }
}

export default new SlotsScheduleSettingsStore();
