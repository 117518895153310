import React from "react";
import {observer} from "mobx-react";
import listingStore from "Stores/listingStore";
import st from "./GroupTabs.module.scss";

const GroupTabs = props => {
  const {setFilterType, filterType, setPage} = listingStore;

  const getData = type => {
    setPage(1)
    setFilterType(type)
  }

  return (
    <div className={st.groupTabs}>
      <div className={st.tabs}>{props.list && props.list.map((item, index) => (item.count > 0) &&
        <div key={index}
             className={`${st.tab} ${item.filter_type === filterType ? st.active : ''}`}
             onClick={() => getData(+item.filter_type)}>
          {item.name}
          {item.count && <sup>{item.count}</sup>}
        </div>)}
      </div>
    </div>
  )
}

export default observer(GroupTabs);
