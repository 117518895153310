import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {observer} from 'mobx-react';
import Stores from 'Stores'
import usersStore from 'Stores/usersStore';
import st from "./EmployeeSkill.module.scss";
import {ReactComponent as IconEdit} from 'components/Modules/User/Employee/img/edit-line.svg';
import {ReactComponent as IconArrow} from 'components/Modules/User/Employee/img/arrow-down-s-line.svg';
import asideModalStore from "Stores/asideModalStore";

const EmployeeSkillSalary = (props) => {
  const {userSalary} = usersStore;
  const {cleanSumm} = Stores

  const [open, setOpen] = useState(false)

  const formatPrice = arr => arr.length > 1 ? `${cleanSumm(arr[0])} - ${cleanSumm(arr[arr.length - 1])}` : cleanSumm(arr[0])

  const salaryName = (sal, key) => {
    if (!sal) return ""
    return `${key === 'rate_online' ? props.translate.online : props.translate.offline}: ${formatPrice(sal.salary[key])} тг. / ${sal.salary.hours_type === 1 ? `${props.translate.ac_h}` : props.translate.astr_h}`
  }

  const modal = {
    type: 'multiSalary',
    link: 'accounts/salary_relations_teacher/' + props.items.profile.id,
    button: props.translate.save,
    title: props.translate.rates_for_new_groups,
    pid: props.items.profile.id,
    onSubmitSuccess: usersStore.setUserSalary
  }

  const modalData = userSalary.map(item => ({
    id: item.id,
    program_type: item.program_type.id,
    salary: item.salary.id,
    employee: props.items.profile.id
  }))

  useEffect(() => {
    superAxiosRequest({
      link: 'accounts/salary_relations_teacher/' + props.items.profile.id,
      method: 'get'
    }).then(res => {
      usersStore.setUserSalary(res.data)
    })
  }, [props.items.profile.id])

  return (
    <div className={st.employeeSkill}>
      <div className={st.title}>{props.translate.rates_for_new_groups}:</div>
      {open ?
        <div className={`${st.info} ${st.salary}`}>
          {userSalary.map((el, key) => (
            <div key={key}>
              <span className={st.programType}>{props.translate.program_type}{`: ${el.program_type?.name || ''}`}</span>
              <span className={st.bid}>{props.translate.bid}{`: ${el.salary?.name || ''}`}</span>
              <span>{salaryName({salary: {...el.salary.academ_hour, hours_type: 1}}, 'rate_online')}</span>
              <span>{salaryName({salary: {...el.salary.academ_hour, hours_type: 1}}, 'rate')}</span>
              <span>{salaryName({salary: {...el.salary.astro_hour, hours_type: 0}}, 'rate_online')}</span>
              <span>{salaryName({salary: {...el.salary.astro_hour, hours_type: 0}}, 'rate')}</span>
            </div>
          ))}
        </div> : <div/>}
      <div className={st.buttons}>
        {open &&
          <div className={`${st.btn} ${st.btnEdit}`}>
            {Stores.Rules.checkRule('salarytypes', ["edit", 'edit_own']) &&
              <IconEdit onClick={() => {
                asideModalStore.activity(modal, modalData)
              }}/>}
          </div>}
        <div className={`${st.btn} ${open ? st.btnClose : st.btnOpen}`} onClick={() => setOpen(!open)}>
          <IconArrow/>
        </div>
      </div>
    </div>
  )
}

export default observer(EmployeeSkillSalary)
