import React from 'react';
import {localisationMaster} from "Localisation/Localisation";
import PropTypes from "prop-types";

const WeekdaysSelector = props => {

    const days = [
        {
            name: `${localisationMaster('monday', 'DayOfWeek')}/${localisationMaster('wednesday', 'DayOfWeek')}/${localisationMaster('friday', 'DayOfWeek')}`,
            compiled: true,
            days: [1, 3, 5]
        },
        {
            name: `${localisationMaster('tuesday', 'DayOfWeek')}/${localisationMaster('thursday', 'DayOfWeek')}`,
            compiled: true,
            days: [2, 4]
        },
        {name: localisationMaster('monday', 'DayOfWeek'), compiled: false, days: 1},
        {name: localisationMaster('tuesday', 'DayOfWeek'), compiled: false, days: 2},
        {name: localisationMaster('wednesday', 'DayOfWeek'), compiled: false, days: 3},
        {name: localisationMaster('thursday', 'DayOfWeek'), compiled: false, days: 4},
        {name: localisationMaster('friday', 'DayOfWeek'), compiled: false, days: 5},
        {name: localisationMaster('saturday', 'DayOfWeek'), compiled: false, days: 6},
        {name: localisationMaster('sunday', 'DayOfWeek'), compiled: false, days: 7},
    ]

    const dayChangeHandler = (i, c) => {
        props.onChange(c ? i : (
            !props.days.includes(i) ? [...props.days, i] : props.days.filter(x => x !== i)
        ), props.name)
    }

    return (
        <div className={`dow-box ${props.disabled ? `dow-dis` : ''}`}>
            {days.map((item, index) => (
                <div className={!props.days.includes(item.days) ? null : 'dow-box-selected'} onClick={() => {
                    !props.disabled && dayChangeHandler(item.days, item.compiled)
                }} key={index}>
                    {item.name}
                </div>
            ))}
        </div>
    )
}

WeekdaysSelector.propTypes = {
    days: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default WeekdaysSelector
