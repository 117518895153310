import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores';
import InputInn from "components/Modules/Auth/SignUp/InputInn/InputInn";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import Loader from "components/ContentBox/Global/Loader/Loader";
import SmartFilter from '../../Filter/SmartFilter';
import TypeSelector from './WorkPlain/TypeSelector';
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import styles from './ClientInformation.module.scss';
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";
import TextArea from "components/UI/TextArea/TextArea";
import asideModalStore from "Stores/asideModalStore";
import InputTelegram from "components/UI/InputTelegram/InputTelegram";
import {makeid} from "../SuperModalFunctions";
import BirthdaySelect from "components/Modules/Auth/SignUp/BirthdaySelect/BirthdaySelect";
import FormFieldset from "../../../../UI/AsideModal/Form/FormFieldset";
import useLocalization from 'hooks/useLocalization';

const ClientInformation = () => {
    const {translate} = useLocalization({
        section: 'profile_client',
        subsection: 'client_profile_modal',
        dict: {
            "surname": "Surname",
            "name": "Name",
            "father_name": "Father Name",
            "iin": "IIN",
            "inn": "INN",
            "date_of_birth": "Date of Birth",
            "sex": "Sex",
            "male": "Male",
            "female": "Female", 
            "time_zone": "Time Zone", 
            "study_level": "Study Level", 
            "status": "Status", 
            "responsible": "Responsible" , 
            "phone": "Phone", 
            "email":"E-mail" , 
            "residence_address": "Residence Address", 
            "training_status":"Training Status" , 
            "comment": "Comment" ,
            "telegram": "Telegram",
            "whatsapp": "WhatsApp",
            "email_created": "This E-mail is already registered",
            "enter_name_email": "Enter the name, e-mail or phone number of the student",
            "generate": "Generate",
            "like_phone": "Like phone",
            "select_date": "Select date",
            "education_start_date": "Education Start Date",
            "no_status": "Sorry... no statuses"
        }

      })

    const {
        changeValues: changeValuesArray,
        modalValues: modalValue,
        modalSettings: ms,
        check,
        changeValue
    } = asideModalStore

    const genderList = [
        {id: 'M', name: localisationMaster('gender-1', 'company/profiles', 'add_user')},
        {id: 'F', name: localisationMaster('gender-2', 'company/profiles', 'add_user')}
    ]

    const aTimezone = [
        {id: -5, name: localisationMaster('name-1', 'time-zone'), name_tz_main: "America/Monterrey"},
        {id: -4, name: localisationMaster('name-2', 'time-zone'), name_tz_main: "America/Montreal"},
        {id: +0, name: localisationMaster('name-3', 'time-zone'), name_tz_main: "Etc/Greenwich"},
        {id: +1, name: localisationMaster('name-4', 'time-zone'), name_tz_main: "Europe/Dublin"},
        {id: +2, name: localisationMaster('name-5', 'time-zone'), name_tz_main: "Europe/Amsterdam"},
        {id: +3, name: localisationMaster('name-6', 'time-zone'), name_tz_main: "Europe/Istanbul"},
        {id: +5, name: localisationMaster('name-7', 'time-zone'), name_tz_main: "Asia/Yekaterinburg"},
        {id: +6, name: localisationMaster('name-8', 'time-zone'), name_tz_main: "Asia/Almaty"},
        {id: +7, name: localisationMaster('name-9', 'time-zone'), name_tz_main: "Asia/Novosibirsk"},
        {id: +8, name: localisationMaster('name-10', 'time-zone'), name_tz_main: "Asia/Shanghai"},
        {id: +9, name: localisationMaster('name-11', 'time-zone'), name_tz_main: "Asia/Seoul"},
        {id: +10, name: localisationMaster('name-12', 'time-zone'), name_tz_main: "Asia/Vladivostok"},
        {id: +12, name: localisationMaster('name-13', 'time-zone'), name_tz_main: "Asia/Kamchatka"}
    ]

    const [loading, setLoading] = useState(false);
    const [oldEmail, setOldEmail] = useState('')
    const [oldInn, setOldInn] = useState('')
    const [errorInn, setErrorInn] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [udIfFind, setudIfFind] = useState(false)
    const [isShowFieldLink, setIsShowFieldLink] = useState(true)

    const getStudentList = value => {
        setIsShowFieldLink(false)
        const profile = value[0].profile
        setudIfFind(true)

        changeValuesArray({
            last_name: profile.last_name,
            first_name: profile.first_name,
            father_name: profile.father_name,
            country: profile.country,
            inn: profile.inn,
            date_of_birth: profile.date_of_birth,
            sex: profile.sex,
            time_zone: profile.time_zone,
            training_level: profile.training_level_id,
            status: profile.status,
            responsible: profile.responsible?.id,
            phone: profile.phone,
            mobile_phone: profile.mobile_phone,
            email: value[0].email,
            address: profile.address,
            telegram: profile.telegram,
            whatsapp: profile.whatsapp,
            comment: profile.comment,
            group_id: ms.group_id,
            client_id: value[0].profile.id
        })

        ms.link = 'groups/admin_add_client_to_group'
    }

    const generateEmail = () => {
        if (modalValue.inn) changeValue(modalValue.inn + "@interpress.kz", 'email')
    }

    const generateWhatsapp = () => {
        if (modalValue.phone) changeValue(modalValue.phone, 'whatsapp')
    }

    useEffect(() => {
        setErrorEmail(false)
        setErrorInn(false)
        if (ms.is_put) {
            const apiConf = {
                method: "get",
                link: 'accounts/clients/' + ms.client_id
            }
            setLoading(true)
            superAxiosRequest(apiConf).then(({data: {profile, ...data}}) => {
                setOldEmail(data.email)
                setOldInn(profile.inn)
                const newObj = {
                    last_name: profile.last_name,
                    first_name: profile.first_name,
                    father_name: profile.father_name,
                    country: profile.country,
                    inn: profile.inn,
                    date_of_birth: profile.date_of_birth,
                    sex: profile.sex,
                    time_zone: profile.time_zone,
                    training_level: profile.training_level_id,
                    status: profile.status,
                    ...(profile.responsible?.id && {responsible: profile.responsible.id}),
                    phone: profile.phone,
                    mobile_phone: profile.mobile_phone,
                    email: data.email,
                    address: profile.address,
                    telegram: profile.telegram,
                    whatsapp: profile.whatsapp,
                    comment: profile.comment,
                }
                changeValuesArray(newObj)
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        } else {
            changeValue(makeid(), "password")
        }
    }, [])

    useEffect(() => {
        if (!udIfFind && modalValue.email && !ms.is_put) {
            let emailValid = modalValue.email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (emailValid !== null) {
                let apiConf = {
                    method: "get",
                    link: 'groups/check_inn_or_email',
                    params: {
                        email: modalValue.email,
                    }
                }
                superAxiosRequest(apiConf)
                    .then(response => {
                        setErrorEmail(response.data.email)
                    })
                    .catch(() => {

                    })
            }
        }
    }, [modalValue.email])

    useEffect(() => {
        if (!udIfFind && modalValue.inn !== '') {
            if (modalValue.inn?.length > 9) {
                let apiConf = {
                    method: "get",
                    link: 'groups/check_inn_or_email',
                    params: {
                        inn: modalValue.inn,
                    }
                }
                superAxiosRequest(apiConf)
                    .then(response => {
                        setErrorInn(response.data.inn)
                    })
                    .catch(() => {

                    })
            }
        }
    }, [modalValue.inn])

    const innOptions = [
        {id: 1, name: translate.iin},
        {id: 2, name: translate.inn},
    ]

    return (
        <>
            {!loading ?
                <div className={styles.modalClientInfo}>
                    <FormFieldset>
                        {
                            ms.add_to_group &&
                            <FormField>
                                <SmartFilter
                                    link="accounts/clients"
                                    placeholder={translate.enter_name_email}
                                    new_key="full_name"
                                    sub_key="profile"
                                    backFunction={getStudentList}/>
                            </FormField>
                        }

                        <div className={styles.fieldRow}>
                            <FormField>
                                <FormLabel isRequired>{translate.surname}:</FormLabel>
                                <FormInput isError={check("last_name")}>
                                    <input
                                        type="text"
                                        disabled={udIfFind}
                                        name={"last_name"}
                                        placeholder={translate.surname}
                                        value={modalValue.last_name || ""}
                                        onChange={(e) => changeValue(e.target.value, 'last_name')}
                                    />
                                </FormInput>
                            </FormField>
                            <FormField>
                                <FormLabel isRequired>{translate.name}:</FormLabel>
                                <FormInput isError={check("first_name")}>
                                    <input
                                        type="text"
                                        disabled={udIfFind}
                                        name={"first_name"}
                                        placeholder={translate.name}
                                        value={modalValue.first_name || ""}
                                        onChange={(e) => changeValue(e.target.value, 'first_name')}
                                    />
                                </FormInput>
                            </FormField>
                            <FormField>
                                <FormLabel>{translate.father_name}:</FormLabel>
                                <FormInput>
                                    <input
                                        type="text"
                                        disabled={udIfFind}
                                        name={"father_name"}
                                        placeholder={translate.name}
                                        value={modalValue.father_name || ""}
                                        onChange={(e) => changeValue(e.target.value, 'father_name')}
                                    />
                                </FormInput>
                            </FormField>
                            <FormField>
                                <FormLabel isRequired>{translate.iin}:
                                    <span className={styles.innError}>
                                        {errorInn && modalValue.inn !== oldInn ? translate.inn : null}
                                    </span>
                                </FormLabel>
                                <InputInn
                                    disabled={modalValue.inn && udIfFind}
                                    options={innOptions}
                                    onChange={val => changeValue(val, 'inn')}
                                    value={modalValue.inn || ""}
                                    error={check('inn')}
                                />
                            </FormField>
                            <FormField>
                                <FormLabel isRequired>{translate.date_of_birth}:</FormLabel>
                                <BirthdaySelect
                                    disabled={modalValue.date_of_birth && udIfFind}
                                    error={check('date_of_birth')}
                                    value={modalValue.date_of_birth}
                                    onChange={sDate => {
                                        changeValue(sDate, 'date_of_birth')
                                    }}/>
                            </FormField>
                            <FormField>
                                <FormLabel>{translate.sex}:</FormLabel>
                                <TypeSelector
                                    values={genderList}
                                    like_obj={true}
                                    objectChangeHandler={changeValue}
                                    selected={modalValue.sex}
                                    name={'sex'}
                                />
                            </FormField>
                            <FormField>
                                <FormLabel>{translate.time_zone}:</FormLabel>
                                <SelectCustom
                                    placeholder={translate.time_zone}
                                    options={aTimezone}
                                    valueID={modalValue.time_zone}
                                    onChange={o => changeValue(o.id, 'time_zone')}
                                    isDisabled={udIfFind}
                                />
                            </FormField>
                            {
                                Stores.Rules.checkRule('clientsprofile', ['edit', 'edit_own']) &&
                                <FormField>
                                    <FormLabel>{translate.study_level}:</FormLabel>
                                    <SelectCustom
                                        placeholder={translate.study_level}
                                        apiLink="groups/training_levels_catalog"
                                        apiConfParams={{limit: 999}}
                                        optionLabelKey="level"
                                        formatOptionLabel={(opt, {context}) =>
                                            context === 'menu' ?
                                                <span
                                                    dangerouslySetInnerHTML={{__html: opt.label.replace(/\//g, '<br/>')}}/> :
                                                <span>{opt.label}</span>
                                        }
                                        sortKey="level"
                                        valueID={modalValue.training_level}
                                        onChange={o => changeValue(o.id, 'training_level')}
                                        isSearchable
                                        isDisabled={udIfFind}
                                    />
                                </FormField>
                            }
                            {
                                Stores.Rules.checkRule('clientsprofile', ['edit', 'edit_own']) &&
                                <FormField>
                                    <FormLabel>{translate.status}:</FormLabel>
                                    <SelectCustom
                                        placeholder={translate.status}
                                        apiLink={'accounts/clients_statuses'}
                                        valueID={modalValue.status}
                                        onChange={o => changeValue(o.id, 'status')}
                                        isDisabled={udIfFind}
                                        noOptionsMessage={() => translate.no_status}
                                    />
                                </FormField>
                            }
                            {
                                Stores.Rules.checkRule('clientsprofile', ['edit', 'edit_own']) &&
                                <FormField>
                                    <FormLabel>{translate.responsible}:</FormLabel>
                                    <SelectCustom
                                        error={check('responsible')}
                                        placeholder={translate.responsible}
                                        apiLink="accounts/employers_flat_list"
                                        optionLabelKey="full_name"
                                        sortKey="full_name"
                                        valueID={modalValue.responsible}
                                        onChange={o => changeValue(o.id, 'responsible')}
                                        isDisabled={modalValue.responsible && udIfFind}
                                        isSearchable
                                    />
                                </FormField>
                            }
                        </div>

                        {/*<div className="modalClientInfo__field">*/}
                        {/*    <FormField>*/}
                        {/*        <FormLabel isRequired>Country:</FormLabel>*/}
                        {/*        <FormInput>*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                disabled={udIfFind}*/}
                        {/*                name={"country"}*/}
                        {/*                placeholder={"Country"}*/}
                        {/*                value={modalValue.country || ""}*/}
                        {/*                onChange={(e) => changeValue(e.target.value, 'country')}*/}
                        {/*            />*/}
                        {/*        </FormInput>*/}
                        {/*    </FormField>*/}
                        {/*</div>*/}

                    </FormFieldset>

                    <div className={styles.separator}/>

                    <div className={styles.fieldRow}>
                        <FormField>
                            <FormLabel isRequired>{translate.phone} 1:</FormLabel>
                            <InputFlagPhone
                                error={check('phone')}
                                value={modalValue.phone}
                                disabled={udIfFind}
                                onChange={val => changeValue(val, 'phone')}
                            />
                        </FormField>
                        <FormField>
                            <FormLabel>{translate.phone} 2:</FormLabel>
                            <InputFlagPhone
                                error={check('mobile_phone')}
                                value={modalValue.mobile_phone}
                                disabled={udIfFind}
                                onChange={val => changeValue(val, 'mobile_phone')}
                            />
                        </FormField>
                        <FormField>
                            <div className={styles.modalClientInfo__blockLink}>
                                <FormLabel isRequired>{translate.email}:</FormLabel>
                                {Stores.Rules.checkRule('clientsprofile', ['edit', 'edit_own']) && isShowFieldLink &&
                                    <span className={styles.modalClientInfo__link}
                                          onClick={() => generateEmail()}>{modalValue.inn !== undefined && modalValue.inn !== '' ? translate.generate : null}</span>
                                }
                            </div>
                            {errorEmail && modalValue.email !== oldEmail ?
                                <p className={styles.innError}>{translate.email_created}</p> : null}
                            <FormInput isError={check("email")}>
                                <input name="email"
                                       onChange={(e) => changeValue(e.target.value, 'email')}
                                       placeholder={translate.email}
                                       value={modalValue.email || ""}
                                       disabled={udIfFind}
                                />
                            </FormInput>
                        </FormField>
                        <FormField>
                            <FormLabel>{translate.residence_address}:</FormLabel>
                            <FormInput>
                                <input
                                    className="address"
                                    disabled={udIfFind}
                                    onChange={(e) => changeValue(e.target.value, 'address')}
                                    name="address" placeholder={translate.residence_address}
                                    value={modalValue.address || ""}/>
                            </FormInput>
                        </FormField>
                        <FormField>
                            <FormLabel>{translate.telegram}:</FormLabel>
                            <InputTelegram
                                disabled={udIfFind}
                                placeholder={translate.telegram}
                                onChange={value => changeValue(value, 'telegram')}
                                value={modalValue.telegram || ""}
                            />
                        </FormField>
                        <FormField>
                            <div className={styles.modalClientInfo__blockLink}>
                                <FormLabel>{translate.whatsapp}:</FormLabel>
                                {isShowFieldLink &&
                                    <span className={styles.modalClientInfo__link}
                                          onClick={() => generateWhatsapp()}>{modalValue.phone !== undefined && modalValue.phone !== '' ? translate.like_phone : null}
                                        </span>
                                }
                            </div>
                            <FormInput>
                                <InputFlagPhone
                                    value={modalValue.whatsapp}
                                    disabled={udIfFind}
                                    onChange={val => changeValue(val, 'whatsapp')}
                                />
                            </FormInput>
                        </FormField>
                    </div>

                    <div className={styles.separator}/>

                    {ms.add_to_group &&
                        <div className={styles.fieldRow}>
                            <FormField>
                                <FormDateSelector
                                    placeholder={translate.select_date}
                                    isRequired={false}
                                    label={translate.education_start_date}
                                    onChange={sDate => changeValue(sDate, 'start_study_at')}
                                    value={modalValue.start_study_at || ""}
                                    error={check('start_study_at')}
                                />
                            </FormField>
                            <FormField>
                                <FormLabel>{translate.training_status}:</FormLabel>
                                <FormInput>
                                    <SelectCustom
                                        placeholder={translate.training_status}
                                        apiLink={'accounts/employers_flat_list'}
                                        apiGetResult={data => data.student_statuses.items}
                                        onChange={o => changeValue(o.id, 'gr_status')}
                                        valueID={modalValue.gr_status}
                                    />
                                </FormInput>
                            </FormField>
                        </div>
                    }

                    {ms.add_to_group && <div className={styles.separator}/>}

                        <FormField>
                            <FormLabel>{translate.comment}:</FormLabel>
                            <TextArea
                                onChange={e => changeValue(e.target.value, 'comment')}
                                withLabel={false}
                                label={''}
                                value={modalValue.comment}
                                placeholder={translate.comment}
                                isResize={true}
                                name='comment'
                            />
                        </FormField>
                </div>
                :
                <Loader/>
            }
        </>
    )
}

export default observer(ClientInformation);