import React, {useEffect} from "react";
import {observer} from "mobx-react";
import reactStringReplace from "react-string-replace";
import {superAxiosRequest} from "axiosApi";
import {exerciseTypes} from "Stores/ebookChapterStore";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import ActionPicture from "../../LessonsQuestion/templates/ActionPicture/ActionPicture";
import ExerciseFiles from "./ExerciseFiles/ExerciseFiles";
import VideoLinks from "./VideoLinks/VideoLinks";
import AudioFiles from "./AudioFiles/AudioFiles";
import Test from "./Test/Test";
import MakeWords from "./WordForm/MakeWords";
import WordCompare from "./WordForm/WordCompare";
import GroupWords from "./GroupWords/GroupWords";
import FileSelector from "components/UI/FileSelector/FileSelector";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import TextareaMarkup from "components/UI/TextareaMarkup/TextareaMarkup";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import VariantAdd from "../../StudyTest/TestQuestion/components/VariantAdd";
import st from "./ModalExercise.module.scss";
import SentencesForm from "./SentecnesForm/SentencesForm";
import ActionImageType from "../../LessonsQuestion/templates/ActionImageType/ActionImageType";
import VariantDel from "../../StudyTest/TestQuestion/components/VariantDel";
import ExampleTextArea from "./ExampleTextArea/ExampleTextArea";
import useLocalization from "hooks/useLocalization";
import PuzzleGrid from "../../../../../../Modules/Ebook/Common/Exercise/types/Puzzle/PuzzleGrid/PuzzleGrid";

const defaultValid = {condition_exercise: 'required'}
const maxExampleLength = 300 // Ограничение на беке
let subtype // подтип для упражнения "Сопоставить описание и изображение"

const isValidSelectWordsText = (text) => {
    const regex = /\[([^\]]+)\]/g;
    const matches = text.match(regex);

    if (!matches) {
        return false
    }

    for (const match of matches) {
        const starCount = (match.match(/\*/g) || []).length

        if (match.indexOf('[') !== match.lastIndexOf('[') || match.indexOf(']') !== match.lastIndexOf(']')) {
            return false
        }

        if (starCount > 1) {
            return false
        }
    }

    const textForSelectWords = /\[[^\[\]]+\|[^\[\]]+\*\]|[^\[\]]+\*\|[^\[\]]+\]/
    return textForSelectWords.test(text)
}

const isValidWriteWordsText= (text) => {
    const textForEnterWords = /\[(.*?)\]/
    return textForEnterWords.test(text)
}



const getValid = type => ({
  ...defaultValid,
  ...(type === exerciseTypes.AUDIO && {items: items => !items.some(i => i.file === null)}),
  ...(type === exerciseTypes.WRITE_PHRASES && {
    data: value => {
      const text = value?.text || '';
      const regex = /\[(.*?)]/g;
      const result = [];

      reactStringReplace(text, regex, word => result.push(word));
      return !(result.includes('') || result.length === 0)
    }
  }),
  ...(type === exerciseTypes.MATCH_WORDS_IMAGES && {
    items: value => {
      // Валидация для подтипа (select)
      if (subtype === "select") {
        const result = value.filter(item => {
          const descriptions = item?.description?.split("|")
          if (!descriptions || descriptions.length < 2) {
            return true // Не валидно, если менее двух слов
          }
          const starCount = descriptions.filter(desc => desc.includes("*")).length
          return starCount !== 1 // Не валидно, если нет звездочки или более одной звездочки
        })
        return !result.length
      }
      // Валидация для других подтипов (move, write)
      const result = value.filter(item => !item?.file || item?.description?.length === 0)
      return !result.length
    }
  }),
  ...(type === exerciseTypes.MATCH_WORDS && {
    data: value => {
      const emptyWord = !value.some(item => item.word_1 === '' || item.word_2 === '');
      const emptyWords = value.filter(item => item.word_1 !== '' && item.word_2 !== '').length > 0;
      return Boolean(emptyWord && emptyWords);
    }
  }),
  ...(type === exerciseTypes.CROSSWORD  && {data: value => value.every((el) => !!el.length)}),
  ...(type === exerciseTypes.ORDER_SENTENCES  && {data: value => value.every((el) => !!el.length) && value.length > 1}),
  ...(type === exerciseTypes.MAKE_SENTENCE && {data: value => !!value?.sentence.length}),
  ...(type === exerciseTypes.MAKE_SENTENCES && {data: value => value.sentences.every(s => !!s.length)}),
  ...(type === exerciseTypes.TEST && {
    data: value => {
      const questionsText = !value.questions.some((item) => item.text === '' || item.variants.length <= 1)
      const variants = value.questions.filter((item) => !item.variants.some((item) => item.text === '')).length > 0
      return Boolean(questionsText && variants)
    }
  }),
  ...((type === exerciseTypes.VIDEO || type === exerciseTypes.GIF || type === exerciseTypes.IMAGES) && {
    items: items => !items.some(i => i.file === null)
  }),
    ...(type === exerciseTypes.VIDEO_LINK && {
        data: values => (
            !values.some(i => i.video_link === '') &&
             values.every(i => (
                ['vimeo.com', 'ted.com', 'vk.com', 'youtube.com', 'youtu'].some(host => i.video_link.includes(host))
            ))
        ),
    }),
  ...((type === exerciseTypes.SELECT_WORDS) && {data: value => !!value.text && isValidSelectWordsText(value.text)}),
  ...((type === exerciseTypes.WRITE_WORDS) && {data: value => !!value.text && isValidWriteWordsText(value.text)}),
  ...(type === exerciseTypes.GROUP_WORDS && {data: values => !values.some(i => i.value === '' || i.description === '') && values.length > 1}),
  ...(type === exerciseTypes.MAKE_WORD && {data: value => value.word.length !== 0}),
  ...(type === exerciseTypes.MAKE_WORDS && {data: value => value.every(({word}) => word.length > 0)}),
  ...(type === exerciseTypes.CHOOSE_SENTENCES_FORM && {data: value => value.every(el => el.sentence.length > 0 && el.form.length > 0)}),
  ...(type === exerciseTypes.LINK && {data: value => value.link !== '' && value.anchor !== ''}),
  ...(type === exerciseTypes.TEXT && {text_exercise: "required"}),
  ...(type === exerciseTypes.ATTENTION && {text_exercise: "required"}),
  ...(type === exerciseTypes.PUZZLE && {items: value => !!value[0].file}),
  ...(type === exerciseTypes.COLORING_BOOK && {items: value => !!value[0].file}),
})

function ModalExercise() {


  const {translate} = useLocalization({section: 'ebooks', subsection: 'ebooks_modal_exercise', dict: {

      "image":"Images",
      "exercise_text":"Exercise text",
      "text_to_display":"Enter any text to display "  ,
      "example":"Example",
      "title_to_display":"Enter any title to display!",
      "audio_files":"Audio files",
      "video_files":"Video files",
      "video_links":"Video links",
      "link_to_material":"Link to material",
      "link_text":"Link text",
      "correct_option_asterisk ":"Write the answer options in square brackets and mark the correct option with an asterisk*",
      "example_01":"They [is|are|was|were*] on holiday yesterday",
      "three_filling_options":"Write the answer option in square brackets. In this type of exercise, you can use three options for filling out the task.",
      "example_one":"Example one",
      "example_02":"I [am|'m] Ivan - entry via | will mean that there are two correct answers.",
      "student_correct_answer":"Если студент ответит «утро» или «м», будет считаться верным.",
      "example_two":"Example two",
      "example_03":"I [am] - this entry will mean that the answer is only am.",
      "example_three":"Example three",
      "example_04":"I [] - an entry with empty brackets means that the answer is arbitrary.",
      "column_title_exercise":"Write the name of the column and then the words that apply to it. Separate words with a vertical bar |. Do this for each column. The words will be automatically shuffled after saving the exercise.",
      "attention":"Attention",
      "columns": "Columns",
      "min_columns": "Minimum 2 columns",
      "max_columns": "Maximum 4 columns",
      "sort_them_in_the_correct_order": "We add more than one sentence, their order is automatically shuffled after saving the exercise and the student needs to sort them in the correct order.",
      "words":"Words",
      "letter_breaking":"Write a word or phrase. You can also add a hint for students. Words will be automatically broken into letters after saving.",
      "sentences_in_order":"Put the sentences in order",
      "sort_correct_order":"We add N exercises, their order is automatically shuffled after saving the exercise and the student needs to sort them in the correct order",
      "sentences":"Sentences",
      "write_choose_their_form":"Write sentences and choose their form.",
      "for_transfer_to_passes":"Write in square brackets the word (expression) to be moved into the gaps.",
      "example_05":"1. I [to play] tennis.",
      "example_06":"2. This flower is [better] than that.",
      "words_to_match":"Words to match",
      "beginning_and_end_phrase":"Write the beginning and end of a phrase (or pair of words) on the same line. After saving, they will be automatically shuffled.",
      "vertical_line_options":"Write down the answer options using a vertical line - | and mark the correct option with an asterisk *.",
      "example_07":"cat|dog|cow*",
      "connect_image":"Add images with captions. Your students will have to connect them.",
      "write_words_under_the_image":"Add images with captions. Your students will have to write the words below the pictures.",
      "sentence":"Sentence",
      "separate_words_vertical_line":"Write a sentence and separate the words with a vertical line. The words will be automatically shuffled after saving.",
      "example_08":"We|moved|to|California|last|summer",
      "clue":"Clue",
      "first_select_exercise_type":"To begin, select an exercise type. You can get acquainted with all types of exercises",
      "here":"Here",
      "select_exercise_type":"Select exercise type",
      "main_image":"Main image",
      "exercise_condition":"Exercise condition",

      "link_to_video":"Insert a link to a video from Youtube, Vkontakte, Vimeo or TED. Get acquainted with the",
      "instructions" : "instructions",
      "video_name": "Video name",
      "column": "column",
      "column_title": "Column title",
      "first_word": "1st Word",
      "second_word": "2nd Word",
      "choose_word_image": "Choose an exercise option",
      "move_words": "Move words",
      "write_words":"Write words",
      "select_words":"Select words",
      "timer": "Timer",
      "minute": "minute",
      "timer_description": "A timer allows you to set the time to complete this test.",
      "point_system" : "Point system",
      "point_system_description" : "You can set the number of points the student will receive for each question",
      "total_points": "Total points",
      "question": "Question",
      "enter_the_question" : "Enter the question",
      "enter_the_number" : "Enter the answer",
      "num_point_question" : "Number of points for this question",
      "choose_sesentences" : "Sentence",
      "select_form": "Select form",
      "under_each_other" : "Under each other",
      "miniatures": "Miniatures",
      "scroll_slider": "Scroll slider",
      "display_images" : "How to Display Images",  
      "crossword": "Crossword:",
      "crossword_descr" : "Enter the correct words of the crossword puzzle (scanword), the student will have to write all the words that you wrote.",
      "word": "Word",
      "image_puzzle": "Image for the puzzle:",  
      "grid": "Specify grid:",
      "grid_descr": "Minimum number of rows and columns 2",
      "coloring": "Image for coloring:",
      
  }})

  const {
    modalSettings: ms,
    modalValues,
    changeValue,
    changeValues,
    setValid,
    check
  } = asideModalStore

  subtype = modalValues.subtype

  const changeTypeHandler = type => {
    const ex = exerciseTypes
    const obj = {
      type_exercise: type,
      section_id: modalValues.section_id,
      ...((type === ex.IMAGES || type === ex.GIF || type === ex.VIDEO || type === ex.ATTENTION || type === ex.MATCH_WORDS_IMAGES || type === ex.COLORING_BOOK)  && {
        items: [{file: null, description: ''}]
      }),
      ...(type === ex.CROSSWORD && {data: ['']}),
      ...(type === ex.ORDER_SENTENCES && {data: ['', '']}),
      ...(type === ex.AUDIO && {items: [{file: null, description: '', data: ''}]}),
      ...(type === ex.VIDEO_LINK && {data: [{video_link: '', description: ''}]}),
      ...((type === ex.SELECT_WORDS || type === ex.WRITE_WORDS || type === ex.WRITE_PHRASES) && {data: {text: ''}}),
      ...(type === ex.MAKE_WORD && {data: {word: '', description: ''}}),
      ...(type === ex.MAKE_WORDS && {data: [{word: '', description: ''}]}),
      ...(type === ex.CHOOSE_SENTENCES_FORM && {data: [{sentence: '', form: ''}]}),
      ...(type === ex.MAKE_SENTENCE && {data: {sentence: ''}}),
      ...(type === ex.MAKE_SENTENCES && {data: {sentences: [''], descriptions: ['']}}),
      ...(type === ex.MATCH_WORDS && {data: [{word_1: '', word_2: ''}, {word_1: '', word_2: ''}]}),
      ...(type === ex.GROUP_WORDS && {data: [{value: '', description: ''}]}),
      ...(type === ex.LINK && {data: {anchor: '', link: ''}}),
        ...(type === ex.TEST && {
            data: {
                is_timer: false,
                time: 1,
                is_point: false,
                questions: [{text: '', points: '', variants: [{id: 1, text: '', is_correct: false}]}]
            }
        }),
        ...(type === ex.PUZZLE && {items: [{data: {rows: 2, columns: 2}, file: ''}]})
    }

    changeValues(obj)

    setValid(getValid(type))
  }



  const renderExercise = () => {
    switch (modalValues.type_exercise) {
      case exerciseTypes.IMAGES:
        return (
          <>
            <ActionPicture translate={translate} state={modalValues.show_like} objectChangeHandler={changeValue}/>
            <ExerciseFiles label={`${translate.image}:`}
                           files={modalValues.items}
                           name="items"
                           onChange={changeValue}
                           allowedExtension={['jpg', 'gif', 'png']}
                           accept="image/*"
                           error={check('items')}
                           type='image'
            />
          </>
        )
      case exerciseTypes.TEXT:
        return (
          <div className="super-modal__form-field">
            <FormLabel isRequired>{translate.exercise_text}:</FormLabel>
            <TextareaMarkup
              stateName="text_exercise"
              value={modalValues.text_exercise}
              onChange={changeValue}
              error={check('text_exercise')}
            />
            <div className="super-modal-notice">
              <p>{translate.text_to_display}</p>
            </div>
              <FormField>
                  <FormLabel>{translate.example}:</FormLabel>
                  <TextareaMarkup
                      value={modalValues.example}
                      onChange={changeValue}
                      stateName="example"
                      maxLength={maxExampleLength}
                  />
              </FormField>
          </div>
        )
      case exerciseTypes.TITLE:
        return (
          <div className="super-modal__form-field">
            <div className="super-modal-notice">
              <p>{translate.title_to_display}</p>
            </div>
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </div>
        )
      case exerciseTypes.GIF:
        return <ExerciseFiles
          label={`${translate.image}:`}
          files={modalValues.items}
          name="items"
          onChange={changeValue}
          allowedExtension={['gif']}
          accept="image/gif"
          error={check('items')}
        />
      case exerciseTypes.AUDIO:
        return (
          <FormField>
            <FormLabel isRequired>{translate.audio_files}:</FormLabel>
            <AudioFiles
              files={modalValues.items}
              isError={check('items')}
              onChange={changeValue}
              accept='audio/*'
              allowedExtension={['mp3', 'wav']}
              type='audio'
              name='items'
            />
          </FormField>
        )
      case exerciseTypes.VIDEO:
        return <ExerciseFiles
          label={`${translate.video_files}:`}
          files={modalValues.items}
          name="items"
          onChange={changeValue}
          allowedExtension={['mp4', 'mkv']}
          accept="video/*"
          type={'video'}
          error={check('items')}
        />
      case exerciseTypes.VIDEO_LINK:
        return (
          <FormField>
            <FormLabel>{translate.video_links}:</FormLabel>
            <VideoLinks translate={translate} videos={modalValues.data} onChange={changeValue} error={check('data')}/>
          </FormField>
        )
      case exerciseTypes.LINK:
        return (
          <div className={st.modalExercise}>
            <div>
              <input type="text"
                     placeholder={translate.link_to_material}
                     value={modalValues.data?.link || ''}
                     onChange={e => changeValue({...modalValues.data, link: e.target.value}, 'data')}
                     className={check('data') && !modalValues.data.link ? 'super-modal__input--error' : undefined}
              />
            </div>
            <div>
              <input type="text"
                     maxLength={40}
                     placeholder={translate.link_text}
                     value={modalValues.data?.anchor || ''}
                     onChange={e => changeValue({...modalValues.data, anchor: e.target.value}, 'data')}
                     className={check('data') && !modalValues.data.anchor ? 'super-modal__input--error' : undefined}
              />
            </div>
          </div>
        )
      case exerciseTypes.SELECT_WORDS:
        return (
          <>
            <FormField>
              <FormLabel isRequired>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                stateName="text"
                value={modalValues.data.text}
                onChange={val => changeValue({text: val}, 'data')}
                error={check('data')}
              />
              <div className={st.tip}>
                <p>{translate.correct_option_asterisk}</p>
              </div>
            </FormField>
            <div className={st.tip}>
              <b>{translate.example}:</b>
              <p>{translate.example_01}</p>
            </div>
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </>
        )
      case exerciseTypes.WRITE_WORDS:
        return (
          <>
            <FormField>
              <FormLabel isRequired>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                stateName="text"
                value={modalValues.data.text}
                onChange={val => {
                  changeValue({text: val}, 'data')
                }}
                error={check('data')}
              />
              <div className={st.tip}>
                <p>{translate.three_filling_options}</p>
              </div>
            </FormField>
            <div className={st.tip}>
              <b>{translate.example_one}:</b>
              <p>{translate.example_02}</p>
              <p>{translate.student_correct_answer}</p>
              <b>{translate.example_two}:</b>
              <p>{translate.example_03}</p>
              <b>{translate.example_three}:</b>
              <p>{translate.example_04}</p>
            </div>
              <ExampleTextArea translate={translate}  value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </>
        )
      case exerciseTypes.GROUP_WORDS:
        return (
          <FormField>
            <FormLabel>{translate.columns}:</FormLabel>
            <div className={st.tip}>
              <p>{translate.column_title_exercise}</p>
            </div>
            <div className={st.tip}>
              <p><FormLabel>{translate.attention}:</FormLabel>{translate.min_columns}</p> {translate.max_columns}
            </div>
            <GroupWords translate={translate} values={modalValues.data} onChange={changeValue} error={check('data') && modalValues.data.values.length < 2}/>
          </FormField>
        )
      case exerciseTypes.MAKE_WORD:
        return (
          <FormField>
            <FormLabel>{translate.words}:</FormLabel>
            <div className={st.tip}>
              <p>{translate.letter_breaking}</p>
            </div>
            <MakeWords
              translate={translate}
              value={modalValues.data}
              onChange={value => changeValue(value, 'data')}
              error={check('data')}
            />
          </FormField>
        )
      case exerciseTypes.MAKE_WORDS:
        return (
          <FormField>
            <FormLabel>{translate.words}:</FormLabel>
            <div className={st.tip}>
              <p>{translate.letter_breaking}</p>
            </div>
            {modalValues.data.map((oWord, index) =>
              <MakeWords translate={translate}
                         key={index}
                         value={oWord}
                         onChange={value => {
                           changeValue(modalValues.data.map((obj, i) => i === index ? value : obj), 'data')
                         }}
                         error={check('data')}
              />)}
            <VariantAdd onClick={() => {
              changeValue([...modalValues.data, {word: '', description: ''}], 'data')
            }}/>
          </FormField>
        )
      case exerciseTypes.ORDER_SENTENCES:
        return (
          <FormField>
            <FormLabel>{translate.sentences_in_order}:</FormLabel>
            <div className={st.tip}>
              <p>{translate.sort_them_in_the_correct_order}</p>
            </div>
            {modalValues.data.map((sentence, index) => (
              <div key={index} className={st.sentenceBlock}>
                <div className={st.inputTextBlock}>
                  <FormInput isError={check('data') && !sentence}>
                    <input
                      type="text"
                      placeholder={translate.sentence}
                      value={sentence}
                      onChange={(e) => {
                        const updatedData = [...modalValues.data];
                        updatedData[index] = e.target.value;
                        changeValue(updatedData, 'data');
                      }}
                    />
                  </FormInput>
                </div>
                {modalValues.data.length > 2 && (
                  <VariantDel onClick={() => changeValue(modalValues.data.filter((v, i) => i !== index), 'data')}/>
                )}
              </div>
            ))}
            <VariantAdd onClick={() => {
              changeValue([...modalValues.data, ''], 'data');
            }}/>
          </FormField>
        );

      case exerciseTypes.CROSSWORD:
        return (
          <FormField>
            <FormLabel>{translate.crossword}</FormLabel>
            <div className={st.tip}>
              <p>{translate.crossword_descr}</p>
            </div>
            {modalValues.data.map((sentence, index) => (
              <div key={index} className={st.sentenceBlock}>
                <div className={st.inputTextBlock}>
                  <FormInput isError={check('data') && !sentence}>
                    <input
                      type="text"
                      placeholder={translate.word}
                      value={sentence}
                      onChange={(e) => {
                        const updatedData = [...modalValues.data];
                        updatedData[index] = e.target.value;
                        changeValue(updatedData, 'data');
                      }}
                    />
                  </FormInput>
                </div>
                {modalValues.data.length > 1 && (
                  <VariantDel onClick={() => {
                    changeValue(modalValues.data.filter((v, i) => i !== index), 'data')
                  }}/>
                )}
              </div>
            ))}
            <VariantAdd onClick={() => {
              changeValue([...modalValues.data, ''], 'data');
            }}/>
          </FormField>
        );

      case exerciseTypes.CHOOSE_SENTENCES_FORM:

            return (
                <FormField>
                    <FormLabel>{translate.sentences}:</FormLabel>
                    <div className={st.tip}>
                        <p>{translate.write_choose_their_form}.</p>
                    </div>
                    {modalValues.data.map((oSentence, index) =>

                            <SentencesForm
                                           translate={translate}
                                           key={index}
                                           index={index}
                                           value={oSentence}
                                           onChange={value => {
                                               changeValue(modalValues.data.map((obj, i) => i === index ? value : obj), 'data')
                                           }}
                                           error={check('data')}
                                           removeSentence={() => changeValue(modalValues.data.filter((v, i) => i !== index), 'data')}
                            />
                        )}
                    <VariantAdd onClick={() => {
                        changeValue([...modalValues.data, {sentence: '', form: ''}], 'data')
                    }}/>
                </FormField>
            )
      case exerciseTypes.WRITE_PHRASES:
        return (
          <>
            <FormField>
              <FormLabel>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                stateName="text"
                value={modalValues.data.text}
                onChange={val => {
                  changeValue({text: val}, 'data')
                }}
                error={check('data')}
              />
            </FormField>
            <FormField>
              <div className={st.tip}>
                <p>{translate.for_transfer_to_passes}</p>
              </div>
              <FormLabel>{translate.example}:</FormLabel>
              <div className={st.tip}>
                <ul>
                  <li className="">{translate.example_05}</li>
                  <li className="">{translate.example_06}</li>
                </ul>
              </div>
            </FormField>
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </>
        )
      case exerciseTypes.ATTENTION:
        return (
          <>
            <FormField>
              <FormLabel isRequired>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                stateName="text_exercise"
                value={modalValues.text_exercise}
                onChange={changeValue}
                error={check('text_exercise')}
              />
            </FormField>
            <ExerciseFiles label={`${translate.image}:`}
                           files={modalValues.items}
                           name="items"
                           onChange={changeValue}
                           allowedExtension={['jpg', 'gif', 'png']}
                           accept="image/*"
            />
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </>
        )
      case exerciseTypes.MATCH_WORDS:
        return (
          <FormField>
            <FormLabel>{translate.words_to_match}:</FormLabel>
            <div className={st.tip}>
              <p>{translate.beginning_and_end_phrase}</p>
            </div>
            <WordCompare
              translate={translate}
              values={modalValues.data}
              onChange={values => changeValue(values, 'data')}
              error={check('data')}
            />
          </FormField>
        )
      case exerciseTypes.MATCH_WORDS_IMAGES:
        return (
          <FormField>
            <ActionImageType translate={translate} state={modalValues.subtype} objectChangeHandler={changeValue}/>
            <FormLabel>{translate.image}:</FormLabel>
            <div className={st.tip}>
              {modalValues.subtype === "select" && (
                <div>
                  <p>{translate.vertical_line_options}</p>
                  <p><strong>{translate.example}:</strong> {translate.example_07}</p>
                </div>)}
              {modalValues.subtype === "move" &&
                <p>{translate.connect_image}</p>}
              {modalValues.subtype === "write" &&
                <p>{translate.write_words_under_the_image}</p>}
            </div>
            <ExerciseFiles
              files={modalValues.items}
              name="items"
              onChange={changeValue}
              allowedExtension={['jpg', 'gif', 'png']}
              accept="image/*"
              error={check('items')}
            />
          </FormField>
        )
      case exerciseTypes.MAKE_SENTENCE:
        return (
          <>
            <FormField>
              <FormLabel>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup stateName="text"
                              value={modalValues.text_exercise}
                              onChange={val => {
                                changeValue(val, 'text_exercise')
                              }}/>
            </FormField>
            <FormField>
              <FormLabel>{translate.sentence}:</FormLabel>
              <TextareaMarkup stateName="sentence"
                              value={modalValues.data.sentence}
                              error={check('data')}
                              onChange={val => {
                                changeValue({sentence: val}, 'data')
                              }}/>
            </FormField>
            <FormField>
              <div className={st.tip}>
                <p>{translate.separate_words_vertical_line}</p>
              </div>
              <FormLabel>{translate.example}</FormLabel>
              <div className={st.tip}><p>{translate.example_08}</p></div>
            </FormField>
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
          </>
        )
      case exerciseTypes.MAKE_SENTENCES:

          const handleDeleteSentence = (index) => {
              const { sentences, descriptions } = modalValues.data;
              const updatedSentences = sentences.filter((_, i) => i !== index);
              const updatedDescriptions = descriptions.filter((_, i) => i !== index);
              changeValue(
                  {
                      ...modalValues.data,
                      sentences: updatedSentences,
                      descriptions: updatedDescriptions
                  },
                  'data'
              );
          };

        return (
          <>
            <FormField>
              <FormLabel>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                stateName="text"
                value={modalValues.text_exercise}
                onChange={val => {
                  changeValue(val, 'text_exercise');
                }}
              />
            </FormField>
              <ExampleTextArea translate={translate} value={modalValues.example} onChange={changeValue} stateName='example' maxLength={maxExampleLength}/>
            <FormField>
              <FormLabel>{translate.sentences}:</FormLabel>
              <div className={st.tip}>
                <p>{translate.separate_words_vertical_line}.</p>
              </div>
              {modalValues.data.sentences.map((item, idx) => (
                <div key={idx.toString()} className={st.makeSentencesBlock}>
                    <div className={st.block}>
                      <FormInput isError={check('data') && !item}>
                        <input
                          type="text"
                          placeholder={translate.sentence}
                          value={item}
                          onChange={e => {
                            changeValue(
                              {
                                ...modalValues.data,
                                sentences: modalValues.data.sentences.map((s, i) => (i === idx ? e.target.value : s))
                              },
                              'data'
                            );
                          }}
                        />
                      </FormInput>
                      <FormInput>
                        <input
                          type="text"
                          placeholder={translate.clue}
                          value={modalValues.data?.descriptions && modalValues.data.descriptions[idx] ? modalValues.data.descriptions[idx] : ""}
                          onChange={e => {
                            const updatedDescriptions = modalValues.data?.descriptions ? [...modalValues.data.descriptions] : [];
                            updatedDescriptions[idx] = e.target.value;
                            changeValue(
                              {
                                ...modalValues.data,
                                descriptions: updatedDescriptions
                              },
                              'data'
                            );
                          }}
                        />
                      </FormInput>
                    </div>
                    {
                        idx > 0  && <VariantDel onClick={() => handleDeleteSentence(idx)}/>
                    }
                </div>
              ))}
            </FormField>
            <VariantAdd
              onClick={() =>
                changeValue(
                  {
                    ...modalValues.data,
                    sentences: [...modalValues.data.sentences, ''],
                    descriptions: [...modalValues.data.descriptions, '']
                  },
                  'data'
                )
              }
            />
            <FormField>
              <FormLabel>{translate.example}</FormLabel>
              <div className={st.tip}><p>{translate.example_08}</p></div>
            </FormField>
          </>
        );

        case exerciseTypes.TEST:
            return <Test translate={translate} data={modalValues.data} onChange={data => changeValue(data, 'data')}
                         isError={check('data')}/>
        case exerciseTypes.ESSAY:
            return
        case exerciseTypes.ENTER_WORDS:
            return
        case exerciseTypes.COLORING_BOOK:
            return <>
              <FormField>
                <FormLabel>{translate.coloring}</FormLabel>
                <FileSelector types={['svg']}
                              value={modalValues.items[0].file || ''}
                              error={check('items')}
                              onChange={val => changeValue([{...modalValues.items[0], file: val}], 'items')}
                />
              </FormField>
            </>

        case exerciseTypes.PUZZLE:
            return (
                <>
                    <FormField>
                        <FormLabel isRequired>{translate.image_puzzle}</FormLabel>
                        <FileSelector types={['jpg', 'png']}
                                      type='image'
                                      error={check('items')}
                                      value={modalValues.items[0].file || ''}
                                      onChange={val => changeValue([{...modalValues.items[0], file: val}], 'items')}
                        />
                    </FormField>
                    <FormField>
                        <FormLabel isRequired>{translate.grid}</FormLabel>
                        <PuzzleGrid onChange={changeValue} modalValues={modalValues}/>
                        <div className={st.tip}>
                            <p>{translate.grid_descr}</p>
                        </div>
                    </FormField>
                </>
            )
      default:
          return (
          <div className={st.selectTypeAttention}>
            <p>{translate.first_select_exercise_type} <span>{translate.here}</span>.</p>
          </div>
        )
    }
  }

  useEffect(() => {
    if (ms.is_put) {
      const apiConf = {
        method: 'get',
        link: ms.link
      }
      superAxiosRequest(apiConf).then(res => {
        const {section_id, type_exercise, condition_exercise, text_exercise, example, image, data, items, show_like, subtype} = res.data

        const obj = {
          section_id,
          type_exercise,
          condition_exercise,
          text_exercise,
          example,
          image,
          data,
          items,
          show_like,
          subtype
        }
        changeValues(obj)
        setValid(getValid(type_exercise))
      })
    }
  }, [changeValues, ms.is_put, ms.link, setValid])

  return (
    <FormFieldset>
      <FormField>
        <FormLabel isRequired>{translate.select_exercise_type}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom placeholder={translate.select_exercise_type}
                        apiLink="ebooks/exercise_types"
                        valueID={modalValues.type_exercise}
                        onChange={o => {
                            changeTypeHandler(o.value)
                        }}
                        isDisabled={ms.is_put}
                        error={check('type_exercise')}/>
        </FormSelectCustom>
      </FormField>

      {modalValues.type_exercise &&
        <>
          {modalValues.type_exercise !== exerciseTypes.TITLE &&
            <FormField>
              <FormLabel>{translate.main_image}:</FormLabel>
              <FileSelector types={['jpg', 'gif', 'png']}
                            value={modalValues.image || ''}
                            onChange={val => changeValue(val, 'image')}
              />
            </FormField>
          }
          <FormField>
            <FormLabel isRequired>{translate.exercise_condition}:</FormLabel>
            <TextareaMarkup
              value={modalValues.condition_exercise || ''}
              onChange={changeValue}
              stateName="condition_exercise"
              error={check('condition_exercise')}/>
          </FormField>
          {![
            exerciseTypes.TEXT,
              exerciseTypes.TITLE,
              exerciseTypes.SELECT_WORDS,
              exerciseTypes.WRITE_WORDS,
              exerciseTypes.WRITE_PHRASES,
              exerciseTypes.MAKE_SENTENCE,
              exerciseTypes.MAKE_SENTENCES,
              exerciseTypes.ATTENTION
            ].includes(modalValues.type_exercise) &&
            <FormField>
              <FormLabel>{translate.exercise_text}:</FormLabel>
              <TextareaMarkup
                value={modalValues.text_exercise || ''}
                onChange={changeValue}
                stateName="text_exercise"/>
            </FormField>

          }
            {![
                    exerciseTypes.TEXT,
                    exerciseTypes.TITLE,
                    exerciseTypes.SELECT_WORDS,
                    exerciseTypes.WRITE_WORDS,
                    exerciseTypes.WRITE_PHRASES,
                    exerciseTypes.MAKE_SENTENCE,
                    exerciseTypes.MAKE_SENTENCES,
                    exerciseTypes.ATTENTION
                ].includes(modalValues.type_exercise) &&
                <FormField>
                    <FormLabel>{translate.example}:</FormLabel>
                    <TextareaMarkup
                        value={modalValues.example}
                        onChange={changeValue}
                        stateName="example"
                        maxLength={maxExampleLength}
                    />
                </FormField>
            }

        </>}
      {renderExercise()}
    </FormFieldset>
  )
}

export default observer(ModalExercise)
