import React from "react";
import PropTypes from "prop-types";
import {dateFormat} from "helpers/date";
import st from "./LessonHomeworkHeader.module.scss";

const LessonHomeworkHeader = ({title, createdAt, ebookName, chapterName}) => {
  return (
    <div className={st.bookHeader}>
      <div className={st.activeLessonDateRow}>
        <span className={st.activeLesson}>{title}</span>
        {createdAt &&
          <span className={st.date}>{dateFormat(new Date(createdAt), "dd MMMM YYYY")}</span>}
      </div>
      <div className={st.courseName}>{ebookName}{chapterName && ` - ${chapterName}`}</div>
    </div>
  )
}

LessonHomeworkHeader.propTypes = {
  title: PropTypes.string,
  createdAt: PropTypes.string,
  ebookName: PropTypes.string,
  chapterName: PropTypes.string,
}

export default LessonHomeworkHeader;
