import React from 'react';
import PropTypes from 'prop-types';
import { localisationMaster } from "Localisation/Localisation";
import { classNames as cn } from 'helpers';
import styles from './TimeSlot.module.scss';

const ExtendedText = ({ isOnline, isSchool }) => {
  return !isOnline && !isSchool ? null : (
    <p className={styles.text}>
      {isOnline && !isSchool && localisationMaster('text_9', 'teacher_schedule')}
      {isOnline && isSchool && localisationMaster('text_8', 'teacher_schedule')}
      {isSchool && !isOnline && localisationMaster('text_10', 'teacher_schedule')}
    </p>
  );
};

function TimeSlot({ data, start, finish, isSelected, onClick }) {
  const { is_online, is_school } = data;
  const isExtended = is_online !== undefined || is_school !== undefined;

  return (
    <li
      className={cn({
        [styles.slots_item]: true,
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      <p className={styles.time}>
        {start} - {finish}
      </p>
      {
        isExtended && <ExtendedText isOnline={is_online} isSchool={is_school}/>
      }
    </li>
  );
}

TimeSlot.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_online: PropTypes.bool,
    is_school: PropTypes.bool,
  }).isRequired,
  start: PropTypes.string.isRequired,
  finish: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ExtendedText.propTypes = {
  isOnline: PropTypes.bool,
  isSchool: PropTypes.bool,
};

export default TimeSlot;
