import React from 'react';
import {observer} from 'mobx-react';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import Paragraph from 'components/UI/Paragraph/Paragraph';
import InputWithControls from 'components/UI/InputWithControls/InputWithControls';
import Flex from 'components/UI/Flex/Flex';
import LocationPrice from "./LocationPrice";

function LocationTab() {
  const {
    paymentsOptions: {pay_time_booking: payTime, sum, payment_type_slots},

    paymentsLocationsData,
    isGroupsCatalogLoading,
    addPaymentsLocation,
    removePaymentsLocation
  } = slotsSettingsStore;

  return (
    <div>
      <Paragraph withBigMargin>
        <b>ВНИМАНИЕ: </b>клиент должен пройти процедуру оплаты за слот.
      </Paragraph>
      <Flex tag='ul' withGap>
        <LocationPrice
          id={0}
          employeeID={0}
          paymentTypeID={payment_type_slots}
          price={sum}
          onAdd={addPaymentsLocation}
          mandatory
        />
        {paymentsLocationsData.map(({id, location_id, sum, payment_type_slots, is_active}) => is_active &&
          <LocationPrice
            key={id.toString()}
            id={id}
            locationID={location_id}
            paymentTypeID={payment_type_slots}
            price={sum}
            onAdd={addPaymentsLocation}
            onRemove={() => {
              removePaymentsLocation(id)
            }}
            isLoading={isGroupsCatalogLoading}
          />
        )}
      </Flex>
      <Paragraph withMarginTop>
        <b>Фиксированная стоимость</b> - одинаковая стоимость слотов разной длительности
      </Paragraph>
      <Paragraph>
        <b>Стоимость 15 минут</b> - все слоты кратны 15 минутам. Необходимо указать стоимость за 15 минут и за другие длительности слотов стоимость будет рассчитана
        автоматически
      </Paragraph>
      <InputWithControls
        value={payTime}
        type='мин.'
        label='Время резервирования слота до поступления оплаты'
        onChange={val => {
          slotsSettingsStore.setPaymentTime('pay_time_booking', val);
        }}
      />
    </div>
  )
}

export default observer(LocationTab)
