import React, {useEffect, useRef, useState} from "react";
import st from "./LanguageSelector.module.scss";
import rusIcon from "./img/rus.svg";
import ukIcon from "./img/uk.svg";
import kzIcon from "./img/kz.svg";


const langList = [
  {code: 'ru', name: 'РУС', flag: rusIcon, text: 'Русский'},
  {code: 'en', name: 'ENG', flag: ukIcon, text: 'English'},
  {code: 'kz', name: 'КАЗ', flag: kzIcon, text: 'Қазақ тілі'}
]

const storageLang = localStorage.getItem('guest_language') || 'en'

export default function LanguageSelector(){
  const [open, setOpen] = useState(false)
  const [currentLang, setCurrentLang] = useState(langList.find(lang => lang.code === storageLang))

  const refButton = useRef(null)

  const onChangeHandler = lang => {
    setCurrentLang(lang)
    setOpen(false)
    localStorage.setItem('guest_language', lang.code)
    window.location.reload()
  }

  useEffect(() => {
    if (open)
      document.body.addEventListener(
        'click', ({target}) => {
          if (!refButton.current.contains(target)) setOpen(false)
        }, {once: true}
      )
  }, [open])

  return (
    <div className={st.languageSelector}>
      <div className={st.current} onClick={() => setOpen(!open)} ref={refButton}>
        <div className={st.currentImg}><img src={currentLang.flag} alt=""/></div>
        <div className={st.arrow}/>
      </div>
      <ul className={open ? st.popup : st.popupHide}>{langList.map(lang =>
        <li key={lang.code} onClick={() => onChangeHandler(lang)}>
          <div><img src={lang.flag} alt={lang.text}/></div>
          <span>{lang.name}</span>
        </li>)}
      </ul>
    </div>
  )
}
