import {permissions} from "settings/permissions";
import {localisationMaster} from "Localisation/Localisation";
import {dateFormat} from "helpers/date";
import ListingStore from "Stores/listingStore";
import Stores from "Stores";
import {unidanceTypes} from "components/Modules/UniDance/ModalSchedule";

export const unidance = [
  // Клиенты со штрихкодами
  {
    link: 'barcode-clients',
    folder: 'unidance',
    title: "Клиенты и штрихкода",

    button: ["Добавить клиента", permissions.add_master],
    modal_type: 'GlobalSuperModal',
    modal_settings: {
      type: 'unidance_client',
      title: 'Добавление клиента',
      button: 'Добавить',
      valid: {
        client: 'required',
        first_name: 'required',
        last_name: 'required',
        barcode: 'required'
      },
      link: 'unidance/client_barcode',
      func: ListingStore.updateData
    },

    colsSettings: {
      button: localisationMaster('button-title', 'company/profiles'),
      link: 'client_barcode',
      prefix: 'unidance',
      id: { name: 'id' },
      action: [
        {
          name: "Перейти в профиль",
          perm: permissions.view_client_barcode,
          action: 'go_profile_client_unidance',
        },
        {
          name: "Изменить",
          perm: permissions.change_client_barcode,
          action: 'open_window_change_barcode_unidance',
        }
      ],
      main_rows: [
        { name: 'full_name', function: 'transliterate' },
      ],
      is_open: false,
      avatar: { name: 'avatar', ac: true, f: 'first_name', l: 'last_name' },

      sub_rows: [
        {
          name: 'barcode',
          description: "Штрихкод:",
        },
        {
          name: 'created_at',
          description: "Создан:",
          function: "dateFormat",
          dateFormat: "dd.mm.YYYY"
        },
        {
          name: 'inn',
          description: `${localisationMaster('table-row-title-5', 'company/companies')}:`,
        },
      ]
    },
  },

  // Мастера
  {
    link: 'masters',
    folder: 'unidance',
    title: "Мастера",

    button: ["Добавить мастера", permissions.add_master],
    modal_type: 'GlobalSuperModal',
    modal_settings: {
      type: 'quad',
      title: 'Добавление мастера',
      fields: [
        {
          type: 'select',
          name: 'user_id',
          placeholder: 'Сотрудник',
          required: true,
          apiLink: 'groups/groups_catalog',
          apiGetResult: ({employers: {items}}) => items,
          optionValueKey: 'user_id',
          optionLabelKey: 'full_name',
          sortKey: 'full_name',
          isSearchable: true,
        },
      ],
      button: 'Добавить',
      valid: {
        user_id: 'required',
      },
      link: 'unidance/master',
      func: ListingStore.updateData
    },

    colsSettings: {
      prefix: 'unidance',
      link: 'master',

      id: { name: 'id' },
      action: [
        {
          name: "Удалить",
          action: 'delete',
          perm: permissions.delete_master,
          field: 'last_name'
        },
      ],

      main_rows: [
        {
          compiled: [
            {name: 'last_name', past: ' '},
            {name: 'first_name'},
          ],
        },
        {name: 'name', updir: 'location'}
      ],
      avatar: { name: 'avatar', ac: true, f: 'first_name', l: 'last_name' },
      is_open: false,
      sub_rows: [
        {
          name: 'name',
          updir: 'location',
          description: localisationMaster('table-row-title-2', 'company/profiles'),
        },
        {
          name: 'phone',
          description: "Телефон",
        },
      ]
    },
  },

  // Расписания
  {
    link: 'schedules',
    folder: 'unidance',
    title: "Расписания",

    button: ["Добавить расписание", permissions.page_dance_schedules],
    modal_type: 'GlobalSuperModal',
    modal_settings: {
      type: 'unidance_schedule_form',
      title: 'Добавление расписания',
      button: 'Добавить',
      valid: {
        master: 'required',
        type: 'required',
        date: 'required',
        start_at: 'required',
        finish_at: 'required',
      },
      link: 'unidance/schedule_teachers',
      func: ListingStore.updateData
    },

    colsSettings: {
      prefix: 'unidance',
      link: 'schedule_teachers',

      action: [
        {
          name: "Редактировать",
          action: 'unidance_edit_schedule',
          permission: ['forward']
        },
        {
          name: "Удалить",
          action: 'delete',
          permission: ['forward']
        },
      ],
      id: { name: 'id' },
      main_rows: [
        {
          compiled: [
            {updir: 'master', name: 'last_name', key_name: 'last_name', past: ' '},
            {updir: 'master', name: 'first_name', key_name: 'first_name'},
          ],
        },
        {name: 'type_name'},
        {
          name: 'interval',
          function: sch => `c ${dateFormat(new Date(`${sch.date}T${sch.start_at}`), "hh:ii")} по ${dateFormat(new Date(`${sch.date}T${sch.finish_at}`), "hh:ii")}`
        },
        {name: 'date', function: 'dateFormat'},
      ],
      is_open: false,
      noOpener: true,

      sub_rows: []
    },

    filterLiner: true,
    filterLinerLink: "unidance/schedule_teachers",
    config: [
      {
        id: 1,
        apiLink: 'unidance/master',
        nameState: 'master',
        placeholder: 'Мастер',
        type: "select_request",
        apiGetResult: (data) => data.result.map(m => ({id: m.id, name: `${m.last_name} ${m.first_name}`}))
      },
      {
        id: 2,
        type: 'date_day_picker',
        nameState: ['date_from', 'date_until'],
      },
      {
        id: 3,
        values: unidanceTypes,
        nameState: 'type_id',
        placeholder: 'Тип',
        type: "select",
      },
    ],
    apiSettings: {},
  },

  // Абонементы
  {
    link: 'subscriptions',
    folder: 'unidance',
    title: "Абонементы",

    button: ["Добавить абонемент", permissions.page_dance_schedules],
    modal_type: 'GlobalSuperModal',
    modal_settings: {
      type: 'quad',
      title: 'Добавление абонемента',
      fields: [
        {
          type: 'text',
          label: 'Название',
          required: true,
          name: 'name',
          placeholder: 'Название'
        },
        {
          type: 'text',
          label: 'Сумма',
          required: true,
          name: 'sum',
          placeholder: 'Сумма',
          getValue: val => val / 100 || '',
          onChange: (old, val) => val * 100
        },
        {
          type: 'text',
          label: 'Количество посещений',
          name: 'number_of_visits',
          placeholder: 'Количество посещений'
        },
        {
          type: 'text',
          label: 'Срок действия',
          name: 'days_active',
          placeholder: 'Срок действия'
        },
      ],
      button: 'Добавить',
      valid: {
        name: 'required',
        sum: 'required',
      },
      link: 'unidance/subscription',
      func: ListingStore.updateData
    },

    colsSettings: {
      prefix: 'unidance',
      link: 'subscription',

      action: [
        {
          name: "Редактировать",
          action: 'unidance_subscription_form',
          permission: ['forward']
        },
        {
          name: "Удалить",
          action: 'delete',
          permission: ['forward']
        },
        {
          name: "Назначить клиенту",
          action: 'unidance_subscription_assign',
          permission: ['forward']
        },
      ],
      id: { name: 'id' },
      main_rows: [
        {name: 'name'},
        {name: 'sum', function: obj => Stores.cleanSumm(obj.sum / 100) + " тг."
        },
      ],
      is_open: false,

      sub_rows: [
        {
          name: 'start_date',
          description: 'Дата начала'
        },
        {
          name: 'finish_date',
          description: 'Дата окончания'
        },
        {
          name: 'days_active',
          description: 'Срок действия'
        },
        {
          name: 'number_of_visits',
          description: 'Количество посещений'
        },
      ]
    },

    filter: true,
    filterLink: "unidance/subscription",
    config: [
      {
        nameState: 'name',
        placeholder: 'Абонемент',
        type: 'input'
      },
    ],
  },
]
