import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import Stores from "Stores";
import Loader from "components/UI/Loader/Loader";
import {LessonContext} from "../BookChapter/LessonContext";
import Exercise from "../Common/Exercise/Exercise";
import BookPaper from "../Common/BookPaper/BookPaper";
import st from "../LessonStudent/LessonStudent.module.scss";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import useLocalization from "hooks/useLocalization";
import LessonToolbar from "../LessonStudent/LessonToolbar";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import lessonStudentStore from "../LessonStudent/lessonStudentStore";
import {ReactComponent as SubmitReview} from "components/UI/icons/review_icon.svg";
import swal from "sweetalert";
import {localisationMaster} from "../../../../Localisation/Localisation";
import {superAxiosRequest} from "../../../../axiosApi";
import LessonLogs from "../LessonLogs/LessonLogs";
import ShowMark from "../ShowMark/ShowMark";
import ReviewInfoBlock from "../HomeworkStudent/ReviewInfoBlock/ReviewInfoBlock";
import CreateDiscussionPastLesson from "../LessonDiscussion/CreateDiscussionPastLesson/CreateDiscussionPastLesson";

function PastLessonStudent(){
  const {uuid} = useParams()
  const [currentRef, setCurrentRef] = useState(null)

  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'exercises',
    dict: {
      "save_answer": "Save Answer",
      "add_to_favorites": "Add to Favorites",
      "delete": "Delete",
      "past_lesson": "Past Lesson"
    }
  })

  const {fetchPastLesson, errorStatus, lesson, setFocus, clearLesson} = lessonStudentStore

  const context = {mode: 'student'}
  const [isSubmit, setIsSubmit] = useState(false)

  const scrollToBlock = () => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }

  console.log(currentRef)

  useEffect(() => {
    fetchPastLesson(uuid)

    const wsUrl = `${Stores.baseLink().replace('https', 'wss')}/ws/ebooks/init_lesson/${uuid}/?token=${localStorage.getItem('access')}`;
    const con = new WebSocket(wsUrl)
    con.onmessage = ({data}) => {
      const message = JSON.parse(data)
      if (message.type === 'assigned_exercise'){
        //setLessonExercises(message.message)
        fetchPastLesson(uuid)
      } else if (message.type === 'notifications.notify_focus_on_exercise'){
        setFocus(message.message.exercise_id)
      }
    }

    return () => {
      clearLesson()
    }
  }, [uuid, fetchPastLesson, clearLesson, setFocus])

  const submitReview = () => {

    swal({
      text: `Вы действительно хотите отправить урок на проверку?`,
      icon: "warning",
      buttons: [
        localisationMaster('button_2', 'alert'),
        localisationMaster('button_1', 'alert')
      ],
      dangerMode: true
    }).then((yes) => {
      if (yes) {
        setIsSubmit(true)
        superAxiosRequest({
          method: 'post',
          link: 'ebooks/stud_les_grades'
        }, {
          uuid: uuid,
        })
      }else {
        setIsSubmit(false)
        swal('Действие отменено')
      }
    })
  }

  return (
    lesson ?
      <div className={st.lessonStudent}>
        <LessonToolbar homeworkLink={lesson.homework ? `/ebooks/my-homework/${lesson.homework}` : ''} />
        <div className={st.bookContainer}>
          <BookPaper>
            <div className={st.bookColumn}>
              <LessonHomeworkHeader title={translate.past_lesson}
                                    createdAt={lesson.created_at}
                                    ebookName={lesson.ebook_name}
                                    chapterName={lesson.chapter_name}/>
              {
                  lesson?.mark && <ShowMark mark={Number(lesson?.mark)} type={lesson?.company_mark_type}/>
              }
              {
                  lesson?.review === false && !isSubmit && <ReviewInfoBlock scrollToBlock={scrollToBlock}/>
              }
              {lesson.exercises.length > 0 ?
                <LessonContext.Provider value={context}>{lesson.exercises?.map((ex, index) =>
                  <Exercise key={ex.id}
                            number={`1.${index + 1}`}
                            ex={ex}
                            translate={translate}/>)}
                </LessonContext.Provider> :
                <WithoutExercises text1="No exercises :("
                                  text2="There were no exercises assigned during the lesson."/>}
            </div>
          </BookPaper>
          {
              (lesson.exercises.length > 0 && !lesson?.review && !isSubmit) && <div className={st.review}>
                <div className={st.reviewBtn} onClick={submitReview}>
                  <SubmitReview/>
                  <span>Send Lesson For Review</span>
                </div>
              </div>
          }
          <CreateDiscussionPastLesson context={'student'} setRef={setCurrentRef}/>
          <LessonLogs context={'student'} isHomeWork={false}/>
        </div>
      </div> :
      <div className={st.lessonStudent}>
        <LessonToolbar/>
        <div className={st.bookContainer}>
          <BookPaper>{errorStatus === 404 ?
            <WithoutExercises text1="404" text2="Lesson not found"/> : <Loader/>}
          </BookPaper>
        </div>
      </div>
  )
}

export default observer(PastLessonStudent)
