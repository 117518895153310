import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Button/Button';
import slotsListingStore from 'Stores/slotsListingStore';
import styles from './ClientItem.module.scss';
import {localisationMaster} from "Localisation/Localisation";

function Comment({comment, id, index}) {
  const { updateSlot } = slotsListingStore;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentValue, setCommentValue] = useState(comment || '');

  const handleSubmit = () => {
    setIsLoading(true);
    updateSlot(
      id,
      index,
      {comment: commentValue},
      '',
      () => {
        setIsEdit(false);
        setIsLoading(false);
      },
      () => setIsLoading(false),
    );
  };

  const handleCancel = () => {
    setCommentValue(comment || '');
    setIsEdit(false);
  };

  return (
    <div className={`${styles.comment_wrapper} ${isEdit && styles.comment_wrapper__edit}`}>
          <span className={styles.comment_title}>{ localisationMaster('comment', 'online-schedule/future-slots')}:</span>
        {!isEdit && comment &&
        <button className={styles.comment_add_button} onClick={() => setIsEdit(true)} >
          {localisationMaster('text_19', 'online-schedule/future-slots')}
        </button>
      }
      {isEdit &&
        <div className={styles.edit_comment_wrapper}>
          <textarea
            className={styles.comment_textarea}
            placeholder={localisationMaster('text_20', 'online-schedule/future-slots')}
            value={commentValue}
            onChange={({target}) => setCommentValue(target.value)}
          />
          <div className={styles.comment_buttons_wrapper}>
            <Button onClick={handleSubmit} classes={styles.comment_button} isDisabled={isLoading || !commentValue.trim()}>
              {isLoading ? localisationMaster('text_21', 'online-schedule/future-slots') : localisationMaster('text_9', 'online-schedule/future-slots')}
            </Button>
            <Button onClick={handleCancel} variant='white' classes={styles.comment_button} isDisabled={isLoading}>
              {localisationMaster('text_5', 'online-schedule/future-slots')}
            </Button>
          </div>
        </div>
      }
      {!isEdit && comment &&
        <span className={styles.comment_text} onClick={() => setIsEdit(true)}>
          {comment}
        </span>
      }
      {!isEdit && !comment &&
        <button className={styles.comment_add_button} onClick={() => setIsEdit(true)} >
          {localisationMaster('text_4', 'online-schedule/future-slots')}
        </button>
      }
    </div>
  );
}

Comment.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  comment: PropTypes.string,
};

export default observer(Comment);
