import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import coursesStore from 'Stores/coursesStore';
import CourseDocs from './CourseDocs';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import BlueLine from '../Global/BlueLine/BlueLine';
import LessonCard from './LessonCard';
import './Course.scss';
import {ReactComponent as EditIcon} from 'components/ContentBox/img/edit2.svg';
import {ReactComponent as ShareIcon} from 'components/ContentBox/img/share.svg';
import ShareCourse from "../Global/GlobalModalSingle/templates/ShareCourse";
import { permissions } from '../../../settings/permissions';
import { checkPermList } from '../../../helpers';

const Course = props => {
    const { CourseStore } = coursesStore

    const shareCourse = () => {
        const ms = {
            title: localisationMaster('share_course', 'lessons/course'),
            modalBodyComponent: () => <ShareCourse/>,
            button: false,
            //link: '',
            width: 620,
            lessons: CourseStore.course.lessons
        }
        const md = {
            course_id: +props.match.params.id,
        }
        modalStore.activity(ms, md)
    }

    const editCourse = () => {
        const ms = {
            name: 'course',
            link: 'lessons/course/' + props.match.params['id'],
            button: localisationMaster('button-two', 'company/profiles/account', 'add_category'),
            title: localisationMaster('text_5', 'lessons/course'),
            type: 'CourseForm',
            is_put: true,
            name_media: 'image',
            func: CourseStore.setCourse,
            valid: {
                name: 'required'
            }
        }
        const fd = {
            id: +props.match.params.id,
            name: CourseStore.course.name,
            company: CourseStore.course.company_id,
            course_level: CourseStore.course.course_level_id,
            skill_level: CourseStore.course.skill_level_id,
            is_catalog: CourseStore.course.is_catalog,
        }
        modalStore.activity(ms, fd)
    }

    const createLesson = () => {
        const ms = {
            name: 'course-lesson',
            link: 'lessons/lesson',
            button: localisationMaster('button-two', 'company/age', 'add_age'),
            title: localisationMaster('text_1', 'lessons/course'),
            type: 'SelectorAndInput',
            active_inputs: ['text', 'textarea_tags', 'textarea_tags_second', 'image-selector'],
            data: 'json',
            media: true,
            name_media: 'image',
            pl_text: localisationMaster('text_2', 'lessons/course'),
            name_text: 'name',
            pl_textarea_tags: localisationMaster('text_3', 'lessons/course'),
            name_textarea_tags: 'description',
            pl_textarea_tags_second: localisationMaster('text_4', 'lessons/course'),
            name_textarea_tags_second: 'home_work',
            course_id: props.match.params['id'],
            //func: putLesson,
            func: CourseStore.addLesson,
            valid: {
                name: 'required'
            }
        }
        modalStore.activity(ms)
    }

    const move = (array, from, to) => {
        array.splice(to, 0, array.splice(from, 1)[0]);
        return array;
    };

    const changePosition = (id, pos) => {
        let arr = CourseStore.course.lessons.map(item => item.id)

        arr = move(arr, arr.indexOf(id), arr.indexOf(id) + pos)

        const apiConf = {
            method: 'put',
            link: 'lessons/lesson_position/' + props.match.params['id']
        }
        const body = {
            lesson_position: arr
        }

        superAxiosRequest(apiConf, body).then(res => {
            CourseStore.setCourse(res.data)
        })
    }

    const courseName = `${localisationMaster('edit_course', 'lessons/course')}: ${CourseStore.course.name} (${CourseStore.course.course_level}, ${CourseStore.course.skill_level})`

    useEffect(() => {
        CourseStore.fetchCourse(+props.match.params.id)
        // eslint-disable-next-line
    }, [])

    const buttons = []
    checkPermList(permissions.add_share_course_link) && buttons.push({icon: () => <ShareIcon/>, onClick: shareCourse})
    checkPermList(permissions.change_course) && buttons.push({icon: () => <EditIcon/>, onClick: editCourse})

    return (
        <>
            {CourseStore.course &&
            <>
                <ContentHeader title={CourseStore.course.name && courseName}/>
                <div className="coursePage">

                    {checkPermList(permissions.view_course_docs) &&
                    <CourseDocs id={+props.match.params.id} items={CourseStore.course.docs || []}
                                drop={() => CourseStore.fetchCourse(+props.match.params.id)}/>
                    }

                    {CourseStore.course.lessons &&
                    <div className="coursePage__blueLine">
                        <BlueLine items={CourseStore.course.lessons.length || 0}/>
                    </div>}

                    {checkPermList(permissions.add_lesson) ?
                    <button className="coursePage__addLessonButton" onClick={() => createLesson(props.match.params.id)}>
                        {localisationMaster('text_1', 'lessons/course')}
                    </button> : null} 

                    {CourseStore.course.lessons &&
                    <>
                        <div className="coursePage__lessons">
                            {CourseStore.course.lessons.slice()
                                .sort((a, b) => a.position > b.position ? 1 : -1)
                                .map((item, index) =>
                                    <LessonCard key={index}
                                                items={item}
                                                last={CourseStore.course.lessons.length}
                                                changePosition={changePosition}
                                                position={index + 1}/>
                                )}
                        </div>

                        {(CourseStore.course.lessons.length > 0 && checkPermList(permissions.add_lesson)) &&
                        <button className="coursePage__addLessonButton"
                                onClick={() => createLesson(props.match.params.id)}>
                            {localisationMaster('text_1', 'lessons/course')}
                        </button>}
                    </>}
                </div>
            </>}
        </>
    )
}

export default observer(Course)
