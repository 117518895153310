import React from "react";
import st from "./BookPaper.module.scss";

const BookPaper = props => {
  return (
    <div className={st.bookPaper}>
      <div className={st.top}>
        <div className={st.topLeft}/>
        <div className={st.topMiddle}/>
        <div className={st.topRight}/>
      </div>
      <div className={st.body}>
        <div className={st.left}/>
        <div className={st.content}>
          {props.children}
        </div>
        <div className={st.right}/>
      </div>
      <div className={st.bottom}>
        <div className={st.bottomLeft}/>
        <div className={st.bottomMiddle}/>
        <div className={st.bottomRight}/>
      </div>
    </div>
  )
}

export default BookPaper;