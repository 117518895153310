import React from "react";
import Stores from 'Stores';
import VideoPlayer from 'components/ContentBox/Global/MediaPlayer/VideoPlayer'
import styles from './QuestionUploadedVideo.module.scss'


export default function QuestionUploadedVideo(props) {
    return (
        <div className={styles.content}>
            {props.videos.map((video, index) =>
                <VideoPlayer key={index} v_link={Stores.baseLink() + video.file} width={'100%'} videoName={video.description}/>
            )}
        </div>
    )
}