import React, {useEffect, useState} from 'react';
import { correctStyleLessons } from './edit';

export default function QuestionGroupWords(props) {

    const [words, setWords] = useState([])
    const [result, setResult] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    let dragged, dragIndex

    const dropZone = (col, indexCol) => (
            <div
                key={indexCol}
                className="questionGroupWords__col"
                onDragEnter={onDragEnterHandler}
                onDragLeave={onDragLeaveHandler}
                onDragOver={onDragOverHandler}
                onDrop={e=>{onDropHandler(e, indexCol)}}
            >
                <header>{col.description}</header>
                    <article>
                        <p>Перетащите слова сюда</p>
                        <div className="questionGroupWords__dropzone">
                            {col.words.map((word, indexWord) =>
                                <span
                                    key={indexWord}
                                    onClick={() => {
                                        let arr

                                        const word = result[indexCol].words[indexWord]
                                        arr = result.slice()
                                        arr[indexCol].words = arr[indexCol].words.filter((x, i) => i !== indexWord)
                                        setResult(arr)

                                        arr = words.slice()
                                        arr.push(word)
                                        setWords(arr)
                                    }}
                                >
                                    {word}
                                </span>
                            )}
                        </div>
                    </article>
                </div>)

    const dropZoneWithAnswers = (col, indexCol) => (
            <div key={indexCol} className="questionGroupWords__col">
                <header>{col.description}</header>
                    <article>
                        <div className="questionGroupWords__dropzone">
                            {props.answ_array.map((word, indexWord) =>
                                (props.answ_array[indexWord].split('~~~'))[1] === col.description ?
                                <span key={indexWord} className={correctStyleLessons(props.correct_answ[indexWord])} >
                                    {(props.answ_array[indexWord].split('~~~'))[0]}
                                </span> :
                                null                                
                            )}
                        </div>
                    </article>
                </div>)

    const onDragStartHandler = (e, i) => {
        dragged = e.target
        dragIndex = i
        e.target.classList.add('onDrag')
    }

    const onDragEndHandler = e => {
        e.target.classList.remove('onDrag')
    }

    const onDragEnterHandler = e => {
        e.target.classList.add('onDragOver')
    }

    const onDragLeaveHandler = e => {
        e.target.classList.remove('onDragOver')
    }

    const onDragOverHandler = e => {
        // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
        if (dragged)
            // prevent default to allow drop
            e.preventDefault()
    }

    const onDropHandler = ({target}, index) => {
        target.classList.remove('onDragOver')

        // Удаляем элемент из слов
        const word = words[dragIndex]
        setWords(words.filter((x,i) => i!==dragIndex))

        const arr = result.slice()
        arr[index].words.push(word)
        setResult(arr)
    }

    useEffect(() => {
        setWords(props.items.value_list)
    }, [props.items.value_list])

    useEffect(() => {
        let arr = []
        props.items.description_list.forEach(name => {
            arr.push({description: name, words: []})
        })
        setResult(arr)
    }, [props.items.description_list])

    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        setAnswersButton(!words.length)
        props.handleAnswers(result, answersButton)
    }, [result, answersButton])


    return (
        <div>
            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) ?
            <>
            <div className="lessonQuestion__wordsBox">
                {words.map((word, index) =>
                <span
                    key={index}
                    draggable
                    onDragStart={e => {
                        onDragStartHandler(e, index)
                    }}
                    onDragEnd={onDragEndHandler}
                >
                    {word}
                </span>
                )}
            </div>
            <div className="questionGroupWords__cols">
                {result.map((col, indexCol) =>
                    dropZone(col, indexCol)
                )}
            </div>
            </> :
            null
            }
            {(props.is_student_checked_try || (props.is_teacher_view && props.at)) &&
            props.at ?
            <div className="questionGroupWords__cols">
                {result.map((col, indexCol) =>
                    dropZoneWithAnswers(col, indexCol)
                )}
            </div> :
            null
            }
        </div>
    );
};