import React from 'react';
import { Link } from 'react-router-dom';
import { translate as loc, dict } from "Localisation";
import styles from './LessonCard.module.scss';

const LessonCard = ({ is_online, group_number, group_name, classroom, school, group_id, start_at, finish_at, date, is_client }) => {
    const parseTime = (time) => {
        const str = time.split(':');
        return `${str[0]}:${str[1]}`;
    }
    
    const parseDate = (date) => {
        const str = date.split('-');
        return `${str[2]}.${str[1]}`;
    }

    return (
        <div className={styles.card}>
            <div className={styles.flag}>
                <p>{parseDate(date)}</p>
                <p>{is_online ? 'online' : 'offline'}</p>
                <div className={styles.triangle}></div>
            </div>
            <div className={styles.content}>
                <div className={styles.description}>
                    <p>{parseTime(start_at)} - {parseTime(finish_at)}</p>
                    <p>{school}</p>
                    <p>{loc(dict.study_test.classroom)}: {classroom?.name || 'Не назначено'}</p>
                </div>
                <Link to={is_client ? `/groups/my-group/${group_id}` : `/groups/group/${group_id}`} target="_blank" className={styles.group}>{group_number} - {group_name}</Link>
            </div>
        </div>
    )
}

export default LessonCard;