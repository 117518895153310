import React, {useEffect, useState} from 'react';
import st from "./GroupWords.module.scss"
import {ReactComponent as DragWordsImg} from "components/ContentBox/img/dragWords.svg";
import {classNames as cn} from "helpers";

export default function GroupWords(props) {

  const [words, setWords] = useState([])
  const [result, setResult] = useState([])
  const [selectedWords, setSelectedWords] = useState([]);

  const [touchIndex, setTouchIndex] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  let dragged, dragIndex
  const onDragStartHandler = (e, i) => {
    dragged = e.target
    dragIndex = i
    //e.target.classList.add('onDrag')
  }

  const onDragEndHandler = () => {
    //e.target.classList.remove('onDrag')
  }

  const onDragEnterHandler = e => {
    e.target.classList.add(st.onDragOver)
  }

  const onDragLeaveHandler = e => {
    e.target.classList.remove(st.onDragOver)
  }

  const onDragOverHandler = e => {
    // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
    if (dragged)
      // prevent default to allow drop
      e.preventDefault()
  }

  const onTouchStartHandler = (e, i) => {
    setIsTouched(true)
    e.target.classList.add('onDrag')
    setTouchIndex(i);
  }
  const onTouchEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const getWordIndex = () => dragged ? dragIndex : touchIndex

  const onDropOrTouchHandler = ({target}, index) => {
    target.classList.remove(st.onDragOver)
    if(touchIndex === null && !dragged) return
    // Удаляем элемент из слов
    const word = words[getWordIndex()]
    setSelectedWords([...selectedWords, { word, wordIndex: getWordIndex() }])

    const arr = result.slice()
    arr[index].words.push(word)
    setResult(arr)

    setTouchIndex(null);
  }

  useEffect(() => {
    setWords(props.items.value_list)
    setResult(props.items.description_list.map(x => ({description: x, words: []})))
    setSelectedWords([]);
  }, [props.items])

  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    const allWords = props.items.value_list.length;
    const allAnswers = result.map(item => item.words).flat().length;
    props.handleAnswers(result, allAnswers === allWords)
  }, [result])

  useEffect(() => {
    if (props.studentAnswer){
      setWords([])
      setSelectedWords([]);
      setResult(props.studentAnswer.answer.map(x => ({description: x.description, words: x.value.split('|')})))
    } else {
      setWords(props.items.value_list)
      setResult(props.items.description_list.map(x => ({description: x, words: []})))
    }
  }, [props.studentAnswer])

  const dropZone = (col, indexCol) => (
      <div
          key={indexCol}
          className={st.col}
          onDragEnter={onDragEnterHandler}
          onDragLeave={onDragLeaveHandler}
          onDragOver={onDragOverHandler}
          onDrop={e => onDropOrTouchHandler(e, indexCol)}
          onTouchStart={e => onDropOrTouchHandler(e, indexCol)}
      >
        <header>{col.description}</header>
        <article>
          <DragWordsImg/>
          <p>Drag Words Here</p>
          <div className={st.dropzone}>
            {col.words.map((word, indexWord) =>
                <span
                    className={cn({
                      [st.mistake]:props.studentAnswer?.result_in_detail !== null && props.studentAnswer?.result_in_detail[word] === false,
                      [st.correct]:props.studentAnswer?.result_in_detail !== null && props.studentAnswer?.result_in_detail[word] === true
                    })}
                    key={indexWord}
                    onTouchStart={() => setIsTouched(false)}
                    onClick={() => {
                      if (props.studentAnswer) return
                      if (isTouched) return
                      let arr

                      const word = result[indexCol].words[indexWord]
                      arr = result.slice()
                      arr[indexCol].words = arr[indexCol].words.filter((x, i) => i !== indexWord)
                      setResult(arr)

                      const selectedWord = selectedWords?.find(item => item.word === word);

                      if(selectedWord) {
                        const newSelectedWords = selectedWords.filter(
                            item => item.wordIndex !== selectedWord.wordIndex || item[getWordIndex()] !== selectedWord[getWordIndex()]
                        );

                        setSelectedWords(newSelectedWords);
                      }
                    }}
                >
                                    {word}
                                </span>
            )}
          </div>
        </article>
      </div>)

  return (
      <>
        <div>
          {!props.studentAnswer &&
            <div className={st.words}>
              {words.map((word, index) => {
                const selected = selectedWords.some(item => item.wordIndex === index && item.word === word);
                return (
                  <span
                    key={index}
                    draggable
                    onDragStart={e => onDragStartHandler(e, index)}
                    onDragEnd={onDragEndHandler}
                    onTouchStart={e => onTouchStartHandler(e, index)}
                    onTouchEnd={onTouchEndHandler}
                    className={selected ? st.selected : ''}
                  >
                    <div className={st.dote}>
                      <div></div>
                    </div>
                    {word}
                  </span>
                )
              })}
            </div>
          }
          <div className={st.cols}>
            {result.map((col, indexCol) =>
                dropZone(col, indexCol))}
          </div>
        </div>
      </>

  )
}
