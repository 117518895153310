import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle/SectionTitle';
import Hr from '../Hr/Hr';
import { classNames as cn } from 'helpers';
import styles from './ModalWrapper.module.scss';

function ModalWrapper({ title, children, onClose, classes }) {
  const modalPortal = document.getElementById('portal');
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(true);
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div
        className={cn({
          [styles.wrapper]: true,
          [classes]: classes,
          [styles.shown]: isShown,
        })}
        onClick={(evt) => evt.stopPropagation()}
      >
        <div className={styles.title_wrapper}>
          <SectionTitle title={title} secondary/>
        </div>
        <Hr withTopMargin={false} light/>
        <div className={styles.content}>
          {children}
        </div>
        <button
          type="button"
          className={styles.close_button}
          aria-label="Закрыть"
          onClick={onClose}
        />
      </div>
    </div>,
    modalPortal);
}

ModalWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

ModalWrapper.defaultProps = {
  classes: '',
};

export default ModalWrapper;
