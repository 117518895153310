import React from 'react'
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import modalStore from "Stores/modalStore";
import DateSpliter from 'components/ContentBox/Global/DateSpliter/DateSpliter'


const DiscountDates = () => {
  const {modalValues, changeValues} = modalStore

  const arrayChangeHandler = val => {
    val.date_from && changeValues(val.date_from, 'date_from')
    val.date_until && changeValues(val.date_until, 'date_until')
  }

  return (
    <DateSpliter
      yearsStart="currentPlus3"
      yearsEnd="currentPlus3"
      titles={[
        `${localisationMaster('start_date', 'groups/group', 'dors')}:`,
        `${localisationMaster('end_date', 'groups/group', 'dors')}:`,
      ]}
      keys={['date_from', 'date_until']}
      arrayChangeHandler={arrayChangeHandler}
      fix_data={{
        date_from: modalValues.date_from,
        date_until: modalValues.date_until
      }}/>
  )
}

export default observer(DiscountDates);
