import React from 'react';
import {observer} from 'mobx-react';
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import Employee from './Employee';
import Paragraph from 'components/UI/Paragraph/Paragraph';
import InputWithControls from 'components/UI/InputWithControls/InputWithControls';
import Flex from 'components/UI/Flex/Flex';

function PaidTab() {
  const {pay_time_booking: payTime, sum, payment_type_slots} = slotsSettingsStore.paymentsOptions;
  const {
    paymentsEmployeesData,
    isGroupsCatalogLoading,
    addPaymentsEmployees,
    removePaymentsEmployees
  } = slotsSettingsStore;

  return (
    <div>
      <Paragraph withBigMargin>
        <b>ВНИМАНИЕ: </b>клиент должен пройти процедуру оплаты за слот.
      </Paragraph>
      <Flex tag='ul' withGap>
        <Employee
          key={'all'}
          id={0}
          employeeID={0}
          paymentTypeID={payment_type_slots}
          price={sum}
          onAdd={addPaymentsEmployees}
          mandatory
        />
        {
          paymentsEmployeesData.map(({id, employee_id, sum, payment_type_slots, is_active}) => (
            is_active &&
              <Employee
                key={id.toString()}
                id={id}
                employeeID={employee_id}
                paymentTypeID={payment_type_slots}
                price={sum}
                onAdd={addPaymentsEmployees}
                onRemove={() => {
                  removePaymentsEmployees(id)
                }}
                isLoading={isGroupsCatalogLoading}
              />
          ))
        }
      </Flex>
      <Paragraph withMarginTop>
        <b>Фиксированная стоимость</b> - одинаковая стоимость слотов разной длительности
      </Paragraph>
      <Paragraph>
        <b>Стоимость 15 минут</b> - все слоты кратны 15 минутам. Необходимо указать стоимость за 15 минут и за другие длительности слотов стоимость будет рассчитана
        автоматически
      </Paragraph>
      <InputWithControls
        value={payTime}
        type='мин.'
        label='Время резервирования слота до поступления оплаты'
        onChange={val => {
          slotsSettingsStore.setPaymentTime('pay_time_booking', val);
        }}
      />
    </div>
  )
}

export default observer(PaidTab);
