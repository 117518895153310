import React from 'react';
import InputsWrapper from '../../../../../UI/InputsWrapper/InputsWrapper';
import SlotType from './SlotType';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

function SlotTypesList() {
  const { slotTypesArray } = slotsSettingsStore;

  return (
    <InputsWrapper withMargin>
      {
        slotTypesArray.map(({id}) => (
          <li key={id.toString()}>
            <SlotType id={id} />
          </li>
        ))
      }
    </InputsWrapper>
  );
}

export default SlotTypesList;

