import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import AsideFilterStore from "components/UI/AsideFilter/asideFilterStore";
import PageHeader from "components/UI/PageHeader/PageHeader";
import EmptyList from "../Common/EmptyList/EmptyList";
import Loader from "components/UI/Loader/Loader";
import SearchBooks from "components/Modules/Ebook/Books/components/SearchBooks/SearchBooks";
import useLocalization from "hooks/useLocalization";
import TabMenu from "components/UI/TabMenu/TabMenu";
import Pagination from "components/UI/Pagination/Pagination";
import AsideFilter from "components/UI/AsideFilter/AsideFilter";
import FilterTextValues from "components/UI/AsideFilter/FilterTextValues";
import PurposeTest from "./PurposeTest";
import st from "../StudyTests/StudyTests.module.scss";
import useLocalStorageFilter from "helpers/useLocalStorageFilter";

const initPagination = {
  page: 1,
  limit: 10
}

const PurposeTests = () => {
  const filterConfig = [
    {
      nameState: 'search',
      type: 'input',
      label: localisationMaster('name_11', 'tests/widget-clients'),
      placeholder: localisationMaster('name_11', 'tests/widget-clients')
    },
    {
      nameState: 'name',
      type: 'input',
      label: localisationMaster('text_18', 'study/study-test'),
      placeholder: localisationMaster('text_18', 'study/study-test')
    },
    {
      nameState: 'company',
      type: "select",
      apiLink: 'db/companies',
      label: 'Company',
      placeholder: localisationMaster('text_8', 'study/study-test')
    },
    {
      nameState: 'level',
      type: 'select',
      apiLink: 'study/levels',
      label: 'Level',
      placeholder: localisationMaster('text_20', 'study/study-test')
    },
    {
      nameState: 'sub_level',
      type: 'select',
      label: 'Sub level',
      placeholder: localisationMaster('table-row-title-6', 'tests/past-tests'),
    },
  ]

  const {isOpenFilter, closeFilter, setTextValues, changeTextValue, filterTextValues} = AsideFilterStore

  const [data, setData] = useState(null)
  const [filter, updateFilter] = useLocalStorageFilter({ filter_type: 1 })
  const [tabs, setTabs] = useState([])
  const [pagination, setPagination] = useState(initPagination)
  const {translate} = useLocalization({
    section: 'study',
    subsection: 'study_tests',
    dict: {
      "filter": "Filter",
      "search": "Search",
      "search_placeholder": "Search for tests",
      "reset_all": "Reset All",
      "apply": "Apply"
    }
  })
  const history = useHistory()

  useEffect(() => {
    return AsideFilterStore.unmountFilter
  }, []);

  useEffect(() => {
    setData(null)
    superAxiosRequest({
      method: 'get',
      link: 'study/purpose_test',
      params: {
        ...filter,
        offset: (pagination.page - 1) * pagination.limit,
        limit: pagination.limit
      }
    }).then(({data}) => {
      setData(data)
      setTabs(data._meta.group_statuses.map(x => ({...x, id: x.filter_type})))
    })
  }, [filter, pagination]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      // Проверяем, если произошел переход на другую страницу
      if (location.pathname !== '/study/purpose-test') {
        updateFilter({})
      }
    })

    return () => {
      unlisten() // Отменяем прослушивание при размонтировании компонента
    }
  }, [history, updateFilter]);

  return (
    <div className={st.container}>
      <PageHeader title="Tests For Students"/>

      <div className={st.page}>
        <SearchBooks
          onSearch={text => {
            updateFilter({...filter, name: text})
            setPagination(initPagination)
            changeTextValue('name', {
              key: 'name',
              label: localisationMaster('text_18', 'study/study-test'),
              value: text
            })
          }}
          onClear={() => {
            const {name, ...obj} = filter
            updateFilter(obj)
            setTextValues(filterTextValues.filter(field => field.key !== 'name'))
          }}
          value={filter.name || ''}
          translate={translate}
          placeholder={`${translate.search_placeholder}...`}/>

        {!!filterTextValues.length && <FilterTextValues onDelete={key => {
          const {[key]: _, ...obj} = filter
          updateFilter(obj)
        }}/>}

        {isOpenFilter && <AsideFilter
          translate={translate}
          getFilterParams={e => {
            updateFilter(e)
            setPagination({...pagination, page: 1})
          }}
          config={filterConfig}
          filterValue={filter}
          onDropFilter={() => {
            updateFilter({filter_type: filter.filter_type})
            closeFilter()
          }}
        />}

        <TabMenu tabs={tabs} tabID={filter.filter_type} onChange={tab => {
          updateFilter({...filter, filter_type: tab.id})
          setPagination(initPagination)
        }}/>

        <div className={st.content}>
          {Array.isArray(data?.result) ?
            data.result.length > 0 ?
              <div className={st.list}>{data.result.map(item =>
                <PurposeTest key={item.id} item={item}/>)}
              </div> :
              <EmptyList
                title="No tests scheduled :("
                text1="Tests for students have not yet been scheduled. Schedule your first test!"
                button={{
                  text: 'Schedule a Test',
                  onClick: () => history.push('/study/study-tests')
                }}
              /> :
            <Loader/>}
        </div>

        {data?._meta.count_items > 10 &&
          <Pagination
            limit={pagination.limit}
            count={data._meta.count_items || 0}
            page={pagination.page}
            onChange={page => setPagination({...pagination, page})}
            onChangePerPage={limit => {
              setPagination({page: 1, limit})
            }}/>}
      </div>
    </div>
  )
}

export default observer(PurposeTests);
