import React from "react";
import st from "./FormSelectCustom.module.scss"

const FormSelectCustom = props => {
  return (
    <div className={st.formSelectCustom}>
      {props.children}
    </div>
  )
}

export default FormSelectCustom
