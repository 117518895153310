import React from 'react';
import styles from "./GradeRow.module.scss"
import {ReactComponent as CrossIcon} from "components/UI/icons/close-line.svg";

const GradeRow = ({user, setClose, activeTry, activateTry}) => {

  const getIcon = ({attempt, result}) => {
    const cond = Array.isArray(result) ? !result.includes(false) : result
    const isCurrent = attempt === activeTry ? styles.currentIcon : ''
    let icon
    switch (cond) {
      case false:
        icon = <div className={`${styles.wrongIcon} ${isCurrent}`}/>
        break;
      case true:
        icon = <div className={`${styles.rightIcon} ${isCurrent}`}/>
        break;
      case null:
        icon = <div className={`${styles.icon} ${isCurrent}`}/>
        break;
      default:
        break;
    }

    return icon
  }

  return (
    <div className={styles.gradeRow}>
      <div className={styles.name}>{`${user.last_name} ${user.first_name[0]}.`}</div>
      <div className={styles.rateList}>{user.answers.map(answer =>
        <div key={answer.attempt} onClick={() => activateTry(answer.attempt)}>
          {getIcon(answer)}
        </div>
      )}
      </div>
      <div onClick={setClose} className={styles.crossIcon}><CrossIcon/></div>
    </div>
  )
}

export default GradeRow;