import React, {useEffect, useState} from "react";
import {ReactComponent as EditIcon} from "components/UI/icons/edit-line.svg";
import {ReactComponent as DeleteIcon} from "components/UI/icons/delete-bin-6-line.svg";
import {ReactComponent as CopyIcon} from "components/UI/icons/file-copy-2-line.svg";
import {ReactComponent as TurnOffIcon} from "components/UI/icons/cube-line.svg";
import {ReactComponent as UpIcon} from "components/UI/icons/arrow-up-s-line.svg";
import {ReactComponent as DownIcon} from "components/UI/icons/arrow-down-s-line.svg";
import {ReactComponent as CheckboxIcon} from "components/UI/icons/checkbox-circle-line.svg";
import st from "./ExerciseEditPanel.module.scss";
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";

const ExerciseEditPanel = ({action, isFirst, isLast ,settings ,onlyCheckboxes }) => {

    const [showOnlyCheckboxes, setShowOnlyCheckboxes] = useState(onlyCheckboxes);

    useEffect(() => {
        setShowOnlyCheckboxes(onlyCheckboxes);
    }, [onlyCheckboxes]);


    const renderCheckboxButtons = () => {
        const filteredSettings = settings.filter(item => item.type === 'checkbox');

        return filteredSettings.map((item, index) => (
            <div key={index} className={st.checkboxButton} onClick={item.action}>
                <CheckboxIcon/>
            </div>
        ));
    };

    return (
        <div className={st.exerciseEditPanel}>
            <div className={st.exerciseLeftBtn}>
                {(checkPermList(permissions.change_exercise) &&
                    <div className={st.btn} onClick={() => action('edit')}>
                        <EditIcon/>
                    </div>
                )}
                {showOnlyCheckboxes && renderCheckboxButtons()}
            </div>

            {(checkPermList(permissions.change_exercise) &&
                <>
                    <div className={st.groupButtons}>
                        <div className={st.groupButton} onClick={() => action('copy')}>
                            <CopyIcon/>
                        </div>
                        <div className={st.groupButton} onClick={() => action('delete')}>
                            <DeleteIcon/>
                        </div>
                        <div className={st.groupButton} onClick={() => action('off')}>
                            <TurnOffIcon/>
                        </div>
                    </div>
                    {!(isFirst && isLast) &&
                        <div className={st.groupButtons}>
                            {!isLast &&
                                <div className={st.groupButton} onClick={() => action('down')}>
                                    <DownIcon/>
                                </div>}
                            {!isFirst &&
                                <div className={st.groupButton} onClick={() => action('up')}>
                                    <UpIcon/>
                                </div>}
                        </div>
                    }

                </>

            )}


        </div>
    )
}

export default ExerciseEditPanel;
