import React, {useEffect, useState} from 'react';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';
import CalendarDay from '../CalendarDay/CalendarDay';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import Flex from 'components/UI/Flex/Flex';
import ArrowButton from 'components/UI/Calendar/components/ArrowButton/ArrowButton';
import ShowMoreButton from 'components/UI/Calendar/components/ShowMoreButton/ShowMoreButton';
import styles from './BirthdayCalendar.module.scss';



const BirthdayCalendar = ({
    setSelectedDay,
    setDatesRange,
    datesRange,
    calendarData
}) => {
    const today = new Date(new Date().setHours(12, 0, 0, 0))
    const [days, setDays] = useState([])
    const [showMore, setShowMore] = useState(false)
    const weekDaysArray = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье'
    ]

    const getFirstWeekDay = (firstMonthDay) => {
        if (firstMonthDay.getDay() === 1) return new Date(firstMonthDay)
        if (firstMonthDay.getDay() === 0) return new Date(new Date(new Date(firstMonthDay).setDate(-5)))
        return new Date(new Date(firstMonthDay).setDate(1 - (firstMonthDay.getDay() - 1)))
    }
    const getLastWeekDay = (lastMonthDay) => {
        if (lastMonthDay.getDay() === 0) return lastMonthDay
        return new Date(new Date(lastMonthDay).setDate(lastMonthDay.getDate() + (7 - lastMonthDay.getDay())))
    }

    const createDates = (calendarData) => {
        const firstMonthDay = datesRange.firstMonthDay
        const lastMonthDay = datesRange.lastMonthDay
        const firstWeekDay = getFirstWeekDay(firstMonthDay) // первый день первой недели на календаре
        const lastWeekDay = getLastWeekDay(lastMonthDay) // последний день в последней неделе на календаре
        let numberOfDays = (lastWeekDay - firstWeekDay) / 86400000 + 1// колличество дней на календаре

        let daysArray = []
        for (let i = 1; i <= numberOfDays; i++) {
            let date = new Date(new Date(firstWeekDay).setDate(firstWeekDay.getDate() + (i - 1)))
            const notActual = firstMonthDay > date || lastMonthDay < date
            let dayData = calendarData.find(day => day.date === date.getDate())?.employees
            daysArray.push({
                date: date,
                isSelected: false,
                isToday: date.getDate() === today.getDate() && date.getMonth() === today.getMonth(),
                employees: dayData,
                notActual
            })

        }
        return daysArray
    }

    const selectDayHandler = (itemId, dayData) => {
        let newState = [...days]
        newState = newState.map(day => ({...day, isSelected: false}))
        newState[itemId] = {...newState[itemId], isSelected: true}
        setDays(newState)
        setSelectedDay(dayData)
    }
    const handleArrowButtonClick = (evt) => {
        const type = evt.target.dataset.arrow;
        switch (type) {
            case 'right':
                setDatesRange(new Date(new Date(datesRange.firstMonthDay).setDate(datesRange.lastMonthDay.getDate() + 4)))
                break;
            case 'left':
                setDatesRange(new Date(new Date(datesRange.firstMonthDay).setDate(-4)))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        calendarData && setDays(createDates(calendarData))
    }, [calendarData])


    return (
        <div >
            <div className={styles.wrapper}>
                <Flex classes={styles.header}>
                    <SectionTitle
                        title={`${timeConverter(datesRange.firstMonthDay, 'day')} - ${timeConverter(datesRange.lastMonthDay, 'day')}, ${datesRange.firstMonthDay.getFullYear()} года`}
                        withMargin={false}
                        adaptive
                    />
                    <Flex>
                        <ArrowButton left onClick={handleArrowButtonClick} />
                        <ArrowButton onClick={handleArrowButtonClick} />
                    </Flex>
                </Flex>
                <ul className={styles.weekDays}>
                    {weekDaysArray.map((weekDay, idx) => <li className={styles.weekday} key={idx}>{weekDay}</li>)}
                </ul>
                {Boolean(days.length) &&
                    <div className={styles.dayList}>
                        {days.map((day, idx) => showMore ? <CalendarDay selectDay={() => selectDayHandler(idx, day)} key={idx} {...day} /> : idx < 21 && <CalendarDay selectDay={() => selectDayHandler(idx, day)} key={idx} {...day} />)}
                    </div>
                }
            </div>
            <ShowMoreButton isShown={showMore} onClick={() => setShowMore(prev => !prev)} />
        </div>

    );
}

export default BirthdayCalendar;
