import React from "react";
import Checkbox from "components/ContentBox/Global/Checkbox";
import {localisationMaster} from "Localisation/Localisation";
import "./SubmitBlock.scss"

export default function SubmitBlock({add, setSms, setpayed, setpay_comment, payed, pay_comment, sms, payErr, isdisabled}) {

    //const [isSms, setIsSms] = useState(false)


    return (
        <div className="record-customer-fieldset-container submitBlock">

            <div>
                <Checkbox
                    id={'slotSelector'+new Date().getTime()+'isSMS'}
                    label={localisationMaster('info-time-sms', 'online-schedule/record-customer')}
                    checked={sms}
                    isChecked={()=>setSms(!sms)}
                    id="sms"
                />
            </div>

            <div style={{marginTop: '20px'}}>
                <Checkbox
                    id={'slotSelector'+(new Date().getTime() * Math.random())+'pay'}
                    label={'Подтвердить оплату тестирования'}
                    checked={payed}
                    isChecked={()=>setpayed(!payed)}
                    id="pay"
                />
            </div>
            {payed &&
            <div>
                <textarea onChange={(e)=>setpay_comment(e.target.value)} value={pay_comment} style={{borderColor: (payErr && pay_comment.length < 30) && 'red' }}></textarea>
                <p style={{fontSize: '12px', color:'#959595', marginTop: '10px'}}>Длинна комментария не меньше 30 символов</p>
            </div>
            }

            <div className="submitBlock__btnWrap">
                <button className={!isdisabled /*&& checkValues()*/ ? "button_add" : "button_add disabled"}
                    disabled={isdisabled}
                    onClick={e=>add(e)}>{localisationMaster('button', 'online-schedule/record-customer')}
                </button>
            </div>

            <p className="submitBlock__info">
                Клиент получит получит все данные о забронированном слоте на указанный адрес e-mail.
                <br/>
                Также в письме будут данные о его личном кабинете: ссылка на кабинет, логин и пароль.
                <br/>
                Если клиент не получил данное письмо, ему нужно проверить папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти. Также необходимо добавить phoenix@interpress.kz в список разрешенных отправителей, чтобы предотвратить блокирование писем из-за настроек программного обеспечения.
            </p>

        </div>
    )
}