import React from "react"
import PropTypes from "prop-types";
import {dateFormat} from "helpers/date";
import {classNames as cn} from "helpers";
import {schoolDayStatuses as sds} from "Stores/groupStore";
import Loader from "components/UI/Loader/Loader";
import st from "./Calendar.module.scss"

const Calendar = props => {

  const getDays = () => {
    let
      arr = [],
      isHoliday,
      title,
      num = 0,
      isNotSkip,
      duration

    props.workshops.forEach(day => {
      title = 'Занятие'

      isHoliday = props.holidays.some(holiday => holiday.date === day.date)

      duration = (new Date(`${day.date}T${day.finish_at}`) - new Date(`${day.date}T${day.start_at}`)) / 1000 / 60

      isNotSkip = ![sds.NOT_PAID_SKIP, sds.PAID_SKIP, sds.PART_PAID_SKIP, sds.CANCELED].includes(day.status)

      if (isHoliday) {
        title =  `Праздничный день`
      } else if (isNotSkip) {
        num++
        title += ` №${num}`
      }

      arr.push({
        ...day,
        isHoliday,
        tooltip: {
          title,
          ...(!isHoliday && {duration}),
          ...(!isHoliday && {
            teachers: day.teachers.map(teacher_id => props.teachers.find(t => t.id === teacher_id))
          }),
        },
      })
    })

    return arr
  }

  return (
    props.workshops ?
      <div className={st.days}>{getDays().map(d =>
        <div key={d.id}
             className={cn({
               [st.day]: true,
               [st.dayHoliday]: d.isHoliday,
               [st[`dayStatus${d.status}`]]: !!d.status,
             })}
        >
          <span>{dateFormat(d.date, "dd.mm.YY")}</span>
          <div className={st.tooltip}>
            <div className={st.tooltipContent}>
              <div className={st.tooltipTitleDur}>
                <span className={st.tooltipTitle}>{d.tooltip.title}</span>
                {d.tooltip.duration &&
                  <span className={st.tooltipDuration}>({d.tooltip.duration} мин.)</span>}
              </div>
              {Array.isArray(d.tooltip.teachers) &&
                <div className={st.tooltipTeachers}>{d.tooltip.teachers.map(teacher =>
                  <div key={teacher.id}>{teacher.full_name}</div>)}
                </div>}
              {d.tooltip.sum !== undefined &&
                <div className={st.tooltipTeachers}>
                  Списано за этот день: {(d.tooltip.sum / 100).toFixed(2)} тг
                </div>}
            </div>
          </div>
        </div>)}
      </div> :
      <Loader/>
  )
}

Calendar.propTypes = {
  workshops: PropTypes.array,
  holidays: PropTypes.array,
  teachers: PropTypes.array,
  student: PropTypes.object,
}

export default Calendar
