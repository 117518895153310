import React, {Fragment, useEffect} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import THead from "components/ContentBox/Global/ReportTable/THead";
import useUrlParams from 'hooks/useUrlParams';
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import {ExcelRowsParams} from '../../../../utils/ExcelExport';

const AttendanceRecords = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();
    const {urlParams} = useUrlParams();

    const cols = [
        {name: 'Имя'},
        {name: 'Дата'},
        {name: 'Время'}
    ];

    const parseDate = date => `${date.slice(0, 8)}01`; // функция меняет день на 1 число что бы получить отчет от первого числа до конца месяца

    useEffect(() => {
        setData([])
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'employees/vacation_time',
            params: {
                ...urlParams,
                date: parseDate(urlParams.date)
            }
        })
            .then(({data}) => {
                setData(data);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    }, [])

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Имя",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Дата",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Время",
                    ...ExcelRowsParams.headerRowParam
                }
            ]

            let tableData = []

            let merges = []
            let wscols = [
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];
            let currentRow = 1
            if(Boolean(data[0])) data.forEach((row) => {
                if(Boolean(row.dates[0])) merges.push({
                    s: {r: currentRow, c: 0},
                    e: {r: row.dates.length === 0 ?  currentRow  :  currentRow  + row.dates.length - 1, c: 0},
                })
                row.dates.forEach((item) => {
                    currentRow = currentRow + 1
                    tableData.push([
                        {
                            v: `${row.employee_name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${item.date}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `Приход ${item.start_at} Уход ${item.finish_at}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
            })            
            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Учет рабочего времени.xlsx");
        })
    };

    return (
        <div>
            <ContentHeader title="Учет рабочего времени" />
            {isLoading
                ? <Loader />
                : Array.isArray(data) && data.map(el => el.dates).flat().length === 0 ? <h1 style={{textAlign: 'center'}}>Ничего не найдено!</h1>
                    : (
                        <div className="">
                          
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            data.map((row, rowIdx) => {
                                                if (row.dates.length > 0) return (
                                                    <Fragment key={rowIdx}>
                                                        {row.dates.map((dates, datesIdx) => (
                                                            <tr key={datesIdx} className={st.bdt}>
                                                                {(datesIdx === 0) && (
                                                                    <td className={st.fw600} rowSpan={row.dates.length} style={{background: "white"}}>
                                                                        {row.employee_name}
                                                                    </td>
                                                                )}
                                                                <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                                        {timeConverter(dates.date, 'age')}
                                                                    </div>
                                                                </td>
                                                                <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                                    <div className={`${st.groupDescription} ${st.bottom}`}>
                                                                        Приход {dates.start_at?.slice(0, 5)} {dates?.finish_at && `- Уход ${dates.finish_at?.slice(0, 5)}`}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                        )}
                                                    </Fragment>
                                                )
                                                return null
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <button onClick={() => {downloadExcel(data)}} style={{
                                margin: '10px 10px 0 ',
                                width: 'calc(100% - 20px)'
                            }}>Выгрузить в Excel</button>
                        </div>

                    )
            }
        </div>
    )
}

export default AttendanceRecords;