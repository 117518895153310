import React from 'react';
import PropTypes from 'prop-types';
import st from './SectionSlider.module.scss'

function SectionSlider({value, sectionName, total, onChange}) {
    const validationForPrefButton = value === 1;
    const validationForNextButton = value === total;
    return (
        <div className={st.box}>
            <div className={st.slider}>
                <div
                    aria-disabled={validationForPrefButton}
                    className={st.buttonPrev}
                    onClick={!validationForPrefButton ? () => onChange('prev') : undefined}/>
                <div className={st.sectionInfo}>
                    <span>{value < 10 && 0}{value} of {total}</span>
                    <div>{sectionName}</div>
                </div>
                <div
                    aria-disabled={validationForNextButton}
                    className={st.buttonNext}
                    onClick={!validationForNextButton ? () => onChange('next') : undefined}/>
            </div>
        </div>
    );
}

SectionSlider.propTypes = {
    value: PropTypes.number,
    sectionName: PropTypes.string,
    total: PropTypes.number,
    onChange: PropTypes.func,
}

export default SectionSlider;