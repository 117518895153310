import React from 'react';
import {NavLink} from 'react-router-dom';
import st from "./Table.module.scss";

const THead = React.forwardRef((props, ref) => {
    const hasSub = () => props.cols.findIndex(x => x.sub) !== -1
    const chekLink = (col) => {
        if (col.link) {
            return <NavLink to={col.link} style = {{color: '#5f6368'}} >{ col.name}</NavLink>
        }
        return col.name

     }
    const renderTh = (col, idx) => {
        if (hasSub()){
            if (col.sub){
                return <th key={idx} colSpan={col.sub.length}>{chekLink(col)}</th>
            } else {
                return <th key={idx} rowSpan={2}>{chekLink(col)}</th>
            }
        } else {
            return <th key={idx} >{chekLink(col)}</th>
        }
    }

    return (
        <thead className={st.thead} ref={ref}>
        <tr>
            {props.cols.map(renderTh)}
        </tr>

        {hasSub() &&
        <tr>
            {props.cols.filter(x => x.sub).map((col, idx) =>
                col.sub.map((sub, i) => <th key={`${idx}-${i}`}>{chekLink(sub)}</th>)
            )}
        </tr>}

        </thead>
    );
});

export default THead