import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../../UI/ModalWrapper/ModalWrapper';
import Input from '../../../../../UI/Input/Input';
import Flex from '../../../../../UI/Flex/Flex';
import Button from '../../../../../UI/Button/Button';
import slotsSettingsStore from '../../../../../../Stores/slotsSettingsStore';

function AmoCrmModal({label, name, id, onClose}) {
  const [inputValue, setInputValue] = useState(id.toString());

  const handleSubmit = () => {
    slotsSettingsStore.setAmoTogglesName(name, inputValue);
    onClose();
  };

  return (
    <ModalWrapper
      title={`Настройка поля: ${label}`}
      onClose={onClose}
    >
      <Input
        label="Имя поля в amoCRM"
        onChange={(evt) => setInputValue(evt.target.value)}
        onSubmit={handleSubmit}
        placeholder="Имя поля"
        value={inputValue}
      />
      <Flex justify='end' withGap>
        <Button onClick={onClose} variant="white">Отменить</Button>
        <Button
          onClick={handleSubmit}
          isDisabled={!inputValue}
        >
          Сохранить
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

AmoCrmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]).isRequired,
};

export default AmoCrmModal;
