import React, {useEffect, useState} from 'react';
import s from './Clock.module.scss';

export default function Clock(props) {
    const BrowserTimeZone = useState(new Date().getTimezoneOffset() / 60);
    const btz = +(BrowserTimeZone > 0 ? "-" + BrowserTimeZone : "+" + (-BrowserTimeZone));
    const utc_0 = new Date().getTime() + (new Date().getTimezoneOffset() * 60000);
    const utc = "GMT" + (props.time_zone < 0 ? props.time_zone : "+" + props.time_zone);

    const [time, setTime] = useState(new Date())
    const [pip, setPip] = useState(true)

    const myFunction = (date, type) => {
        if (btz !== props.time_zone)
            date = utc_0 + (props.time_zone * 3600000)

        const d = new Date(date)
        const h = addZero(d.getHours());
        const m = addZero(d.getMinutes());
        //var s = addZero(d.getSeconds());

        if (type === 'h')
            return h
        else
            return m
    }

    const addZero = i => i < 10 ? "0" + i : i;

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    });

    const tick = () => {
        setTime(new Date());
        setPip(!pip)
    }

    const isBrowserTimeZone = () => {
        const targetTimeZone = props.time_zone;
        const localTimeZone = new Date().getTimezoneOffset() / 60;

        return localTimeZone === -targetTimeZone;
    }


    return (
        <div className={s.clock}>
            <p className={!isBrowserTimeZone() ? s.timezoneBad :  s.timezone}>
                {myFunction(Number(time), 'h')}<span
                className={pip ? s.point : s.pointOp0}>:</span>{myFunction(Number(time))}
                <span className={s.utc}>{'(' + utc + ')'}</span>
            </p>
        </div>
    )
}
