import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react'
import FileSelector from "components/UI/FileSelector/FileSelector";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import asideModalStore from "Stores/asideModalStore";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import SectionSelectStyle from "components/ContentBox/Global/GlobalModalSingle/templates/ModalEbookSection/SectionSelectStyle/SectionSelectStyle";
import {superAxiosRequest} from "axiosApi";
import useLocalization from 'hooks/useLocalization';

const ModalEbookSection = () => {
    const [colors, setColors] = useState([])
    const {modalValues: values, changeValue, check} = asideModalStore

    const {translate} = useLocalization({section: 'ebooks', subsection: 'modal_ebook_section', dict: {
        "section_name": "Section Name",
        "section_style": "Section Style"
      }})

    useEffect(() => {
        const apiConf = {
            method: 'get',
            link: `ebooks/section_colors`
        }

        superAxiosRequest(apiConf).then((res) => {
            setColors(res.data)
        })
    },[])

    return (
        <FormFieldset>
            <FormField>
                <FormLabel>{translate.section_name}</FormLabel>
                <FormInput isError={check('name')}>
                    <input type="text"
                           name={'name'}
                           placeholder={translate.section_name}
                           value={values.name || ''}
                           onChange={e => changeValue(e.target.value, 'name')}
                    />
                </FormInput>
            </FormField>
            <FormField>
                <FormLabel>{translate.section_style}:</FormLabel>
                <SectionSelectStyle isError={check('color')}
                                    colors={colors}
                                    changeValue={changeValue}
                                    value={values.color}/>
            </FormField>
            <FileSelector
                type="image"
                types={['jpg', 'gif', 'png']}
                error={check('image')}
                value={values.image || ''}
                onChange={val => changeValue(val, 'image')}/>
        </FormFieldset>
    );
}

export default observer(ModalEbookSection)
