import React, {useState} from 'react';
import {range} from "helpers/math";
import {parcedSec} from "../../Online-schedule/components/functions";
import LocalStorage from "services/LocalStorage";
import st from "./TestStudentResult.module.scss"
import imgClock from "components/ContentBox/img/clock.svg"
import imgFalse from "components/ContentBox/img/false.svg"
import imgTrue from "components/ContentBox/img/true.svg"

export default function TestStudentCard(props) {
    const [activeTry, setActiveTry] = useState(1)

    const activeTest = props.item.list[activeTry - 1];
    const is_correct_answers = activeTest.is_correct_answers
    
    const getCount = type => is_correct_answers
                                .map(x => Array.isArray(x.is_correct) ? x : { ...x, is_correct: [x?.is_correct] })
                                .filter(x => x.is_correct.includes(type)).length
    
    const setDataContinue = (data) => {
        let anwers_list = data.answers_student.map(x => ({answers: x.answers, id: x.question}))
        let notAnswerd = [];
        let get;
        for(let i = 0; i < data.questions.length; i++){
            get = anwers_list.find(x => x.id === data.questions[i])
            if(!get) notAnswerd.push({id: data.questions[i]})
        }
        LocalStorage.set('arra', [...anwers_list, ...notAnswerd])
        LocalStorage.set('itest', data)
        let list = data.is_correct_answers.find(x=> x.is_correct === null)
        let a;
        if(!list){
            a = data.questions[0]
        } else {
            a = list.id
        }
        LocalStorage.set('q', [a, ''])
        const adv = {
            focus_live_during: 0,
            focus_live_count: 0,
            mouse_freeze_during: 0,
            mouse_freeze_count: 0,
        }
        for(let a = 0; a < data.answers_student.length; a++){
            adv.focus_live_during += data.answers_student[a].focus_live_during
            adv.focus_live_count +=  data.answers_student[a].focus_live_count
            adv.mouse_freeze_during += data.answers_student[a].mouse_freeze_during
            adv.mouse_freeze_count += data.answers_student[a].mouse_freeze_count
        }
        LocalStorage.set('c', btoa(JSON.stringify(adv)))
        let link = document.createElement("a");
        link.target = "_blank";
        link.href = `/init-placement-test/${data.purpose_id}`
        link.click();
    }

    return (
        <div className={st.wrap}>
            <div className={st.tries}>{props.item.list.map((el, index) => (
                <div key={el.id}
                     className={`${activeTry - 1 === index ? st.trySelected : st.try}`}
                     onClick={() => {
                         setActiveTry(index + 1);
                     }}
                >
                    Попытка {index + 1}
                </div>
            ))}
                {range(props.item.tryCount - props.item.list.length, props.item.list.length + 1).map((el) =>
                    <div key={el} className={st.tryDisabled}>Попытка {el}</div>
                )}
            </div>
            <div>
                <div className={st.info}>
                    <div className={st.infoCell}>
                        <img src={imgClock} alt=""/>
                        {parcedSec(activeTest?.time_taken) || 0}
                    </div>
                    <div className={st.infoCell}>
                        <img src={imgTrue} alt=""/>
                        {getCount(true)}
                    </div>
                    <div className={st.infoCell}>
                        <img src={imgFalse} alt=""/>
                        {getCount(false) + getCount(null)}
                    </div>
                </div>
                <div className={st.buttons}>
                    {(new Date(activeTest.must_be_finished) > new Date() && activeTest.test_status === "in_progress") &&
                    <button className="button-type1" onClick={() => setDataContinue(activeTest || false)}>
                        Вернуться к тесту
                    </button>}
                    <a className="button-type1" href={`/study/my-simple-result/${activeTest.id}`} target="_blank">
                        <strong>Результат</strong>
                    </a>
                </div>
            </div>
        </div>
    );
};
