import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Paragraph from '../../../../../../UI/Paragraph/Paragraph';
import InputWithControls from '../../../../../../UI/InputWithControls/InputWithControls';
import Select from '../../../../../../UI/Select/Select';
import { superAxiosRequest } from '../../../../../../../axiosApi';
import slotsSettingsStore from '../../../../../../../Stores/slotsSettingsStore';

const findSelectedOption = (id, options) => options.find((item) => item.value === id);

function EmailTab() {
  const {
    paymentsOptions,
    emailTemplates,
    setEmailTemplates,
    emailTemplatesOptions,
    setEmailTemplateID,
  } = slotsSettingsStore;
  const { email_time_booking: emailTime, email_template: emailTemplateID } = paymentsOptions;
  const [isLoading, setIsLoading] = useState(false);

  const handleTimeChange = (value) => {
    slotsSettingsStore.setPaymentTime('email_time_booking', value);
  };
  const handleChange = (option) => {
    setEmailTemplateID(option.value);
  };

  useEffect(() => {
    if (emailTemplates.length === 0) {
      setIsLoading(true);
      superAxiosRequest({
        method: 'get',
        link: 'mailings/templates',
      })
        .then((response) => {
          setEmailTemplates(response.data.result);
      })
        .catch((error) => {
          // TODO: add error handle
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <div>
      <Paragraph withBigMargin>
        <b>ВНИМАНИЕ: </b>клиент должен подтвердить регистрацию на слот с помощью e-mail письма
      </Paragraph>
        <Select
          label='Шаблон e-mail'
          placeholder='Подтверждение слота по e-mail'
          withMargin
          options={emailTemplatesOptions}
          isLoading={isLoading}
          value={findSelectedOption(emailTemplateID, emailTemplatesOptions)}
          onChange={handleChange}
        />
      <InputWithControls
        value={emailTime}
        type="мин."
        label="Время резервирования слота до подтвержения e-mail"
        onChange={handleTimeChange}
      />
    </div>
  );
}

export default observer(EmailTab);