import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'
import ReactSelect from 'react-select';
import { superAxiosRequest } from "axiosApi";
import Loader from "../Loader/Loader";
import './SelectCustom.scss';

export default function SelectCustom(props) {
    const refWrapper = useRef();

    const [options, setOptions] = useState([])
    const [value, setValue] = useState(props.value || null)
    const [isLoading, setIsLoading] = useState(false)
    const [menuPlacement, setMenuPlacement] = useState("bottom")

    const onMenuOpen = () => setMenuPlacement(document.documentElement.clientHeight - refWrapper.current.getBoundingClientRect().bottom < 300 ? "top" : "bottom")

    let customComponents = { LoadingIndicator: Loader }

    useEffect(() => {
        let mount = true;
        if (props.apiLink) {
            const apiConf = {
                method: 'get',
                link: props.apiLink,
                ...(props.apiConfParams && { params: props.apiConfParams })
            }

            setIsLoading(true)
            superAxiosRequest(apiConf).then(res => {
                if (!mount) return
                const result = props.apiGetResult ? props.apiGetResult(res.data) : res.data.result ? res.data.result : res.data

                const arr = Array.isArray(result) ? result.map(opt => ({
                    ...opt,
                    value: opt.value !== undefined ? opt.value : opt[props.optionValueKey || 'id'],
                    label: opt.label !== undefined ? opt.label : opt[props.optionLabelKey || 'name']
                })) : []

                if (props.sortKey) {
                    const k = props.sortKey
                    arr.sort((a, b) => a[k] > b[k] ? 1 : -1)
                }

                setOptions(arr)
                setIsLoading(false)
            }).catch(() => {
                setOptions([])
                setIsLoading(false)
            })
        }
        return () => {
            mount = false
        }
    }, [props.apiLink, props.apiParams])

    useEffect(() => {
        if (Array.isArray(props.options)) {
            const arr = props.options.map(opt => {
                if (opt.options === undefined) {
                    return {
                        ...opt,
                        value: opt.value !== undefined ? opt.value : opt[props.optionValueKey || 'id'],
                        label: opt.label !== undefined ? opt.label : opt[props.optionLabelKey || 'name']
                    }
                }
                else {
                    // это сгруппированный селект и тут хз что делать
                    opt.options = opt.options.map(opt => ({
                        ...opt,
                        value: opt.value !== undefined ? opt.value : opt[props.optionValueKey || 'id'],
                        label: opt.label !== undefined ? opt.label : opt[props.optionLabelKey || 'name']
                    }))
                    return opt
                }
            })

            if (props.sortKey) {
                const k = props.sortKey
                arr.sort((a, b) => a[k] > b[k] ? 1 : -1)
            }

            setOptions(arr)
        }
    }, [props.optionLabelKey, props.optionValueKey, props.options, props.sortKey])

    useEffect(() => {
        // Поиск опции по значению
        let val

        if (options.length > 0) {
            // Если есть вложенные опции (сгруппированный селект)
            if (Array.isArray(options[0].options)) {
                let i = 0;
                while (i < options.length && !val) {
                    val = options[i].options.find(i => i[props.optionValueKey || 'value'] === props.valueID)
                    i++
                }
            }
            else {
                val = options.find(i => i[props.optionValueKey || 'value'] === props.valueID)
            }
        }

        setValue(val || null)
    }, [props.valueID, options, props.optionValueKey])

    return (
        <div ref={refWrapper} className={props.className || undefined}>
            <ReactSelect
                placeholder={props.placeholder}

                options={options}
                noOptionsMessage={props.noOptionsMessage}
                getOptionLabel={props.getOptionLabel}
                formatOptionLabel={props.formatOptionLabel}

                defaultValue={props.defaultValue}
                //сделано для множественного селекта
                value={props.isMulti ?
                  options.filter(opt => props.valueID.includes(opt.id)) :
                  (props.value || props.defaultValue || value)}

                onMenuOpen={onMenuOpen}
                onChange={props.onChange}

                isDisabled={props.isDisabled || props.isLoading || isLoading}
                isSearchable={props.isSearchable || false}
                isClearable={props.isClearable}
                isLoading={props.isLoading || isLoading}
                isMulti={props.isMulti || false}

                menuPortalTarget={document.getElementById('portal')}
                menuShouldBlockScroll //Whether to block scroll events when the menu is open
                menuPlacement={menuPlacement}

                //defaultMenuIsOpen // открыть меню, иначе невозможно толком проинспектировать

                className={'selectCustom' + (props.error ? ' selectCustom--error' : '')}
                classNamePrefix="selectCustom"



                styles={{
                    control: (styles, { isFocused }) => ({
                        ...styles,
                        borderColor: props.error ? 'red' : isFocused ? '#0062ba' : '#bdbdbd',
                        boxShadow: isFocused ? (props.error ? '0 0 0 3px rgba(255, 0, 0, .3)' : '0 0 0 3px #b8d4ff') : '',
                    }),

                    placeholder: styles => ({
                        ...styles,
                        color: props.error ? 'red' : '#afafaf',
                        whiteSpace: 'nowrap'
                    }),

                    option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#007be8' : isFocused ? 'rgba(0, 123, 232, .55)' : '',
                        color: isFocused || isSelected ? 'white' : 'black',
                    })
                }}

                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: props.error ? 'red' : '#bdbdbd', // Цвет границы при наведении и фокусе
                        neutral5: 'var(--color-black-20)', // Фон выключенного select
                        neutral30: props.error ? 'red' : '#bdbdbd' // Цвет границы при наведении
                    }
                })}

                components={customComponents}
            />
        </div>
    );
}

SelectCustom.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    apiLink: PropTypes.string,
    apiConfParams: PropTypes.object,
    apiGetResult: PropTypes.func,
    sortKey: PropTypes.string,
    value: PropTypes.object,
    valueID: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSearchable: PropTypes.bool,
}
