import React, { useState, useRef, useEffect } from "react";
import { localisationMaster } from "Localisation/Localisation";
import { superAxiosRequest } from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import { classNames as cn } from "helpers";
import Stores from "Stores";
import "./style.scss";

const PTLogo = ({ file, setFile }) => {
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);

  const sendFile = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    setLoading(true);
    superAxiosRequest(
        {
          method: "post",
          link: "files/image_upload",
        },
        formData,
        "media"
      ).then((res) => {
        setFile(res.data.file);
        setLoading(false);
      });
  };
  return (
    <div className="placementTestLogoBox">
      <div className="placementTestLogoBox__wrapper">
        <div className="placementTestLogoBox__logo">
          {loading ? (
            <Loader />
          ) : file ? (
            <img
              src={Stores.baseLink() + file}
              alt="file/image"
            />
          ) : <></>}
        </div>
        <input
          type="file"
          ref={inputFile}
          onChange={(e) => sendFile(e.target.files[0])}
          accept="image/*"
        />
        <button
          className="button"
          onClick={() => inputFile.current.click()}
          disabled={loading}
        >
          {localisationMaster(
            loading
              ? "loading"
              : file
              ? "PTLogo_btn_edit"
              : "PTLogo_btn_download",
            "c/tests/placement-test/create-widget"
          )}
        </button>
      </div>
    </div>
  );
};

export default PTLogo;
