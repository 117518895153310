import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import modalStore from "Stores/modalStore";
import {classNames as cn} from "helpers";
import { splitNumberDigits } from "helpers/math";

const Refunds = () => {
  const {changeValues, modalValue, data, check, deleteWithKey} = modalStore
  const [selected, setSelected] = useState("");

  const balancesData = {
    company_balances: data.payload?.companies.map(c => ({
      label: c.company.name,
      value: data.type_operation === 'transfer_bonus' ? `company_to^^${c.company.id}` : `company_to^^${c.id}`,
    })),
    group_balances: data.payload?.groups.map(gr => ({
      label: `${gr.group.number} - ${gr.group.name}`,
      value: `group_balance_to^^${gr.id}`,
    }))
  }

  data.valid = {
    sum: 'positive',
    ...(data.send && { paymentTo: 'required' })
  }

  useEffect(() => {
    if (!selected.length) return;
    const data = [selected.split("^^")[0], +selected.split("^^")[1]];
    const isGroup = data[0] === "group_balance_to";
    deleteWithKey(isGroup ? "company_to" : "group_balance_to");
    changeValues(+data[1], !isGroup ? "company_to" : "group_balance_to");
    changeValues(true, "paymentTo");
  }, [selected])

  const getValue = (val) => {
    const numbers = val === "" ? "0" : val.split(" ").join("");
    changeValues(+numbers <= data.max_sum / 100 ? +numbers * 100 : data.max_sum, "sum")
  }

  return (
    <div className="modalAddPayment">
      {data.send && data.type_operation !== 'top_up_bonus' && (
        <div className="modalAddPayment__field">
          <p className="superModalGroup__label">Направление перевода:</p>
          <SelectCustom
            placeholder="Общий баланс"
            options={[
              ...(data.type_operation !== 'bonus_balance' ?
                [{label: "Балансы компаний", options: balancesData.company_balances}] : []),
              {label: "Балансы групп", options: balancesData.group_balances},
            ]}
            valueID={selected}
            onChange={(o) => setSelected(o.value)}
            isLoading={!Object.keys(balancesData).length}
            error={check('paymentTo')}
          />
        </div>
      )}
      <div className="modalAddPayment__field">
        <p className="superModalGroup__label">Сумма в тг.:</p>
        <input
          placeholder="Сумма"
          name="valueInput"
          type="text"
          value={splitNumberDigits(modalValue.sum / 100)}
          className={cn({
            'super-modal__input--center': true,
            'super-modal__input--error': check('sum')
          })}
          onChange={(e) => getValue(e.target.value)}
        />
      </div>
      <div className="modalAddPayment__field">
        <p className="superModalGroup__noReqLabel">Комментарий:</p>
        <textarea
          value={modalValue.comment}
          className="placementTestBox__textarea"
          onChange={(e) => changeValues(e.target.value, "comment")}
          placeholder="Комментарий"
        />
      </div>
    </div>
  );
}

export default observer(Refunds);
