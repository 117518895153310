import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {ExcelRowsParams, setCellParams} from "components/utils/ExcelExport";
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import Filter from "components/ContentBox/Global/Filter/Filter";
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";

const find = (array, id) => array.find(x => x.id === id)

const processData = ({result: {clients}, names, locations, program_types: {items: programTypes}}) => clients.map(row => {
  const client = names.find(x => x.id === row.client_id)

  return {
    ...row,
    user_id: client.user_id,
    name: client.full_name,
    locations: row.locations.map(location => ({
      ...location,
      name: find(locations, location.location_id).name,
      program_types: location.program_types.map(pt => ({
        ...pt,
        name: find(programTypes, pt.id).name,
        ac_h: (pt.minutes / 45).toFixed(2),
        astr_h: (pt.minutes / 60).toFixed(2),
      })),
    })),
    groups: row.locations.map(location => ({
      id: location.group_id,
    }))
  }
})

const processTotalData = data => ({
  all_clients: data.all_clients,
  all_lessons: data.all_lessons,
  all_minutes: data.all_minutes,
  all_ac_h: (data.all_minutes / 45).toFixed(2),
  all_astr_h: (data.all_minutes / 60).toFixed(2),
  average_ac_h: (data.average_minutes / 45).toFixed(2),
  average_astr_h: (data.average_minutes / 60).toFixed(2),
  average_lessons: data.average_lessons.toFixed(2),
  average_minutes: data.average_minutes
})

const getStudentRow = ({locations, ...student}) => [
  student.name,
  locations[0].name,
  locations[0].program_types[0].name,
  locations[0].program_types[0].ac_h,
  locations[0].program_types[0].astr_h,
  locations[0].program_types[0].lessons
].map((item, idx) => ({
  v: item,
  ...ExcelRowsParams.tableRowParam[idx === 0 ? 'firstCell' : 'cell']
}))

const countLessons = locations =>
  locations.reduce((acc, loc) => acc + loc.program_types.reduce((acc, pt) => acc + pt.lessons, 0), 0)

const sortData = array => array.sort((a, b) => countLessons(b.locations) > countLessons(a.locations) ? 1 : -1)

const LifespanReport = () => {
  const {
    refWrapper,
    refSticky,
    refThead,
    data,
    setData,
    isLoading,
    setIsLoading,
  } = useTableFixedHeader()

  const [catalog, setCatalog] = useState({})
  const [totalData, setTotalData] = useState(null)

  const filterCnf = [
    {
      type: 'select-custom',
      is_multi: true,
      placeholder: 'Локация',
      values: catalog.locations,
      sort_key: 'name',
      nameState: 'location',
    },
    {
      type: 'month-range-picker',
      data: {
        date_from: new Date(new Date().setDate(1)),
        date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
      },
      nameState: ['date_from', 'date_until']
    },
    {
      type: "type-selector",
      nameState: "online",
      values: [
        {id: 2, name: "Все группы"},
        {id: 1, name: "Онлайн"},
        {id: 0, name: "Офлайн"},
      ]
    },
    {
      type: 'select-custom',
      is_multi: true,
      placeholder: 'Уровень курса',
      values: catalog.course_levels,
      sort_key: 'name',
      nameState: 'course_level'
    },
    {
      type: 'select-custom',
      placeholder: 'Тип программы',
      values: catalog.program_types?.items,
      sort_key: 'name',
      nameState: 'program_type'
    },
  ]

  const cols = [
    {name: 'Ф.И.О. студента'},
    {name: 'Школа'},
    {name: 'Тип программы'},
    {
      name: 'Количество часов',
      sub: [
        {
          name: 'ак.ч',
          dataField: 'hours_paid',
          align: 'center',
          classes: [st.fz12],
        },
        {
          name: 'аcтр.ч.',
          dataField: 'hours_paid',
          align: 'center',
          classes: [st.fz12],
        },
      ]
    },
    {name: 'Количество занятий'},
  ];

  const sendRequest = (params) => {
    if (!params.date_from || !params.date_until) return
    setIsLoading(true)
    const apiConf = {
      method: 'get',
      link: 'progress_report/client_lifetime',
      params: {
        date_from: dateFormat(new Date(params.date_from), "YYYY-mm-dd"),
        date_until: dateFormat(
          new Date(params.date_until.getFullYear(), params.date_until.getMonth() + 1, 0),
          "YYYY-mm-dd"
        ),
        ...(params.course_level?.length > 0 && {course_level: `[${params.course_level.join()}]`}),
        ...(params.location?.length > 0 && {location: `[${params.location.join()}]`}),
        ...(params.program_type && {program_type: params.program_type})
      }
    }

    superAxiosRequest(apiConf).then(({data}) => {
      if (data.result.clients?.length > 0) {
        setData(sortData(processData(data)))
        setTotalData(processTotalData(data))
      } else {
        setData([])
        setTotalData(null)
      }
      setIsLoading(false)
    }).catch(() => {

    })
  }

  const downloadExcel = () => {
    import("xlsx-js-style").then(XLSX => {
      const headRows = [
        [
          {
            v: "Ф.И.О. студента",
          },
          {
            v: "Школа",
          },
          {
            v: "Тип программы",
          },
          {
            v: "Количество часов",
          },
          {
            v: "Количество часов",
          },
          {
            v: "Количество занятий",
          },
        ],
        [
          {
            v: "Ф.И.О. студента",
          },
          {
            v: "Школа",
          },
          {
            v: "Тип программы",
          },
          {
            v: "ак.ч",
          },
          {
            v: "аcтр.ч.",
          },
          {
            v: "",
          },
        ],
      ]

      headRows[0] = setCellParams(headRows[0], ExcelRowsParams.headerRowParam)
      headRows[1] = setCellParams(headRows[1], ExcelRowsParams.headerRowParam)

      let wscols = [
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
      ];

      let rows = [
        {hpx: 40},
        {hpx: 40},
      ]

      let merges = [
        {s: {r: 0, c: 0}, e: {r: 1, c: 0}}, // Ф.И.О. студента
        {s: {r: 0, c: 1}, e: {r: 1, c: 1}}, // Школа
        {s: {r: 0, c: 2}, e: {r: 1, c: 2}}, // Тип программы
        {s: {r: 0, c: 3}, e: {r: 0, c: 4}}, // Количество часов (ак.ч и аcтр.ч)
        {s: {r: 0, c: 5}, e: {r: 1, c: 5}}, // Количество занятий
      ]

      let tableData = []

      data.forEach(student => {
        tableData.push(getStudentRow(student))
      })

      tableData.unshift([
        {
          v: `СРЕДНИЙ ПОКАЗАТЕЛЬ`,
          ...ExcelRowsParams.footerRowParams.firstCell
        },
        {
          v: ``,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: ``,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.average_ac_h}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.average_astr_h}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.average_lessons}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
      ])
      tableData.unshift([
        {
          v: `ВСЕГО (СТУДЕНТОВ ${totalData.all_clients})`,
          ...ExcelRowsParams.footerRowParams.firstCell
        },
        {
          v: ``,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: ``,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.all_ac_h}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.all_astr_h}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
        {
          v: `${totalData.all_lessons}`,
          ...ExcelRowsParams.footerRowParams.cell
        },
      ])

      let wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData])
      ws['!cols'] = wscols;
      ws['!rows'] = rows
      ws["!merges"] = merges


      XLSX.utils.book_append_sheet(wb, ws, "List 1")
      XLSX.writeFile(wb, "Продолжительность жизни студентов.xlsx");
    })
  }

  useEffect(() => {
    setIsLoading(true)
    superAxiosRequest({
      method: 'get',
      link: 'progress_report/client_lifetime'
    }).then(({data}) => {
      setCatalog(data)
      setData([])
      setIsLoading(false)
    }).catch(() => setIsLoading(false));
  }, [setData, setIsLoading])

  return (
    <div>
      <ContentHeader title="Продолжительность жизни студентов"/>
      <Filter config={filterCnf}
              getFilterParams={sendRequest}
              drop={() => {
                setData([])
              }}/>
      {isLoading ?
        <Loader/> :
        data?.length === 0 ?
          <h1 style={{textAlign: 'center'}}>Нет данных для отображения!</h1> :
          <div className={st.tableWrapper} ref={refWrapper}>
            <div ref={refSticky} className={st.sticky}>
              <table className={st.table}>
                <THead cols={cols}/>
              </table>
            </div>
            <table className={`${st.table} ${st.tbodyStriped}`}>
              <THead cols={cols} ref={refThead}/>
              <tbody className={st.tbody}>
              {totalData && <>
                <tr className={st.bdt}>
                  <td className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`} colSpan="3">
                    Всего ( студентов {totalData?.all_clients} )
                  </td>
                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.all_ac_h}
                  </td>
                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.all_astr_h}
                  </td>
                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.all_lessons}
                  </td>
                </tr>
                <tr className={st.bdt}>
                  <td colSpan={3} className={`${st.tdTotal} ${st.bdrN} ${st.ttu}`}>
                    Средний показатель
                  </td>

                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.average_ac_h}
                  </td>
                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.average_astr_h}
                  </td>
                  <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter}`}>
                    {totalData?.average_lessons}
                  </td>
                </tr>
              </>}
              {Array.isArray(data) && data.map(row => row.locations.map((location, locIdx) =>
                location.program_types.map((programType, programTypeIdx) =>
                  <tr key={`${location.location_id}_${programType.id}`} className={st.bdt}>
                    {locIdx === 0 && programTypeIdx === 0 &&
                      <td className={`${st.fw600} ${st.bgw}`}
                          rowSpan={row.locations.reduce((acc, loc) => acc + loc.program_types.length, 0)}>
                        <NavLink to={`/account_clients/${row.user_id}`} target="_blank">
                          {row.name}
                        </NavLink>
                      </td>}
                    {programTypeIdx === 0 &&
                      <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12}`}
                          rowSpan={location.program_types.length}>
                        {location.name}
                      </td>}
                    <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12}`}>{programType.name}</td>
                    <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12}`}>{programType.ac_h}</td>
                    <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12}`}>{programType.astr_h}</td>
                    <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12}`}>{programType.lessons}</td>
                  </tr>)))}
              </tbody>
            </table>

            <div style={{marginTop: 20}}>
              <ExportExcelButton data={[]} downloadExcel={downloadExcel}/>
            </div>
          </div>
      }
    </div>
  )
}

export default LifespanReport;