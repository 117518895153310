import React from 'react'
import { observer } from "mobx-react";
import {classNames as cn} from 'helpers'
import styles from 'components/UI/TextArea/TextArea.module.scss';
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormField from "components/UI/AsideModal/Form/FormField";
import asideModalStore from "Stores/asideModalStore";


const ChangeClientGroupComment = () => {
    const { modalValues, changeValue, check} = asideModalStore;
    return (
        <FormField>
          <FormLabel>{"Comment:"}</FormLabel>
          <textarea
                placeholder='Комментарий'
                value={modalValues?.text}
                onChange={(e) => changeValue(e.target.value, 'text')}
                className={cn({
                    [styles.textarea]: true,
                    [styles.error]: check('text'),
                })}
            />
        </FormField>
    )
}

export default observer(ChangeClientGroupComment)
