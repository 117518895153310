import React from 'react';
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";
import st from './AddButton.module.scss'
export const AddButton = ({action, children}) => {
    return (
        <>
            <button
                className={st.addBtn}
                onClick={() => action('add')}>
                <PlusIcon/>{children}
            </button>
        </>
    );
};

