import React, {useEffect, useState} from 'react';
import {BlockHeader} from 'components/UI/BlockHeader/BlockHeader';
import st from './ActiveLessons.module.scss';
import Lesson from "../MyLessons/Lesson/Lesson";
import useLocalization from "hooks/useLocalization";
import Loader from "components/UI/Loader/Loader";
import {superAxiosRequest} from "axiosApi";
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";
import {GoToLink} from "components/UI/SuperButton/SuperButton";

const ActiveLessons = ({userId}) => {
  const defaultLessonsPortion = 5
  const maxLessonsSize = 999

  const {translate} = useLocalization({
    section: 'profile', subsection: 'my_lessons', dict: {
      "title": "My Lessons",
      "empty_lessons": "You don't have any lessons yet",
      "active_filter": "Active",
      "finished_filter": "Finished",
      "in_progress_badge": "In Progress",
      "finished_badge": "Finished",
      "lesson_link": "Go to Lesson",
      "history_link": "Go to Lesson History",
      "active_lessons": "Active Lessons",
      "copy_link": "Copy Link",
      "cancel_lesson": "Cancel Lesson",
      "lesson_canceled": "Lesson was was successfully canceled",
      "link_copied": "Link was successfully copied"
    }
  })

  const [lessonsData, setLessonsData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [lessonsPortion, setLessonPortion] = useState(defaultLessonsPortion)


  const apiConf = {
    method: 'get',
    link: `ebooks/active_lessons`,
    params: {
      limit: lessonsPortion,
      user: userId
    }
  }
  const updateLessons = async () => {
    try {
        const res = await superAxiosRequest(apiConf)
        setLessonsData(res.data.result.filter(el => !el.is_finish))
    } catch {
        setLessonsData([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    updateLessons()
  }, [lessonsPortion])

   return isLoading ? <Loader/> : lessonsData.length > 0 && checkPermList(permissions.view_init_lesson) ? (
    <div className={st.box}>
      <BlockHeader title={translate.active_lessons}>
        <GoToLink onClick={() => setLessonPortion(maxLessonsSize)}/>
      </BlockHeader>
      <div className={st.content}>
        {isLoading ? <Loader/> : lessonsData.map((item, i) => <Lesson translate={translate} key={i} {...item} updateLessons={updateLessons}/>)}
      </div>
    </div>
  ) : <></>;
};

export default ActiveLessons;