import React, {useState} from 'react'
import DatePicker, { registerLocale } from "react-datepicker";
import {timeConverter2} from '../../Online-schedule/components/functions'
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import "./DataPickerSplit.scss"
import {localisationMaster} from "../../../../Localisation/Localisation";

registerLocale('ru', ru)

export default function DataPickerSplit(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    const changeStartHandler = date => {
        props.objectChangeHandler(timeConverter2(date), 'date_from')
        setStartDate(date)
    }
    const changeEndHandler = date => {
        props.objectChangeHandler(timeConverter2(date), 'date_until')
        setEndDate(date)
    }

    return (
        <div className="dataPickerSplit react-datepicker-custom">
            <DatePicker
                disabled={props.disableFrom}
                locale="ru"
                selected={new Date(props.returnValue('date_from')) || startDate}
                onChange={date => changeStartHandler(date)}
                minDate={props.minDate || null}
                selectsStart
                //startDate={props.returnValue('date_from') !== undefined ? new Date(props.returnValue('date_from')) : startDate}
                endDate={props.returnValue('date_until') !== undefined ? new Date(props.returnValue('date_until')) : endDate}
                dateFormat="dd.MM.yyyy"
            />
            <div className="dataPickerSplit__middle">{localisationMaster('text-11', 'filter')}</div>
            <DatePicker
                locale="ru"
                //selected={props.returnValue('date_until') !== undefined ? new Date(props.returnValue('date_until')) : endDate}
                selected={props.returnValue('date_until') === undefined ? endDate : props.returnValue('date_until') === null ? null : new Date(props.returnValue('date_until'))}
                onChange={date => changeEndHandler(date)}
                selectsEnd
                startDate={props.returnValue('date_from') !== undefined ? new Date(props.returnValue('date_from')) : startDate}
                //endDate={props.returnValue('date_until') !== undefined ? new Date(props.returnValue('date_until')) : endDate}
                minDate={props.returnValue('date_from') !== undefined ? new Date(props.returnValue('date_from')) : startDate}
                dateFormat="dd.MM.yyyy"
                popperPlacement="bottom-end"
            />
        </div>
    );
}
