import React, {useEffect, useState} from 'react';
import styles from "./MyLessons.module.scss";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import {GoToLink, SuperButton} from "components/UI/SuperButton/SuperButton";
import Lesson from "./Lesson/Lesson";
import MyLessonsEmpty from "./MyLessonsEmpty/MyLessonsEmpty";
import useLocalization from "hooks/useLocalization";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/UI/Loader/Loader";

const MyLessons = ({userId}) => {
  const defaultLessonsPortion = 5
  const maxLessonsSize = 999

  const {translate} = useLocalization({
    section: 'profile', subsection: 'my_lessons', dict: {
      "title": "My Lessons",
      "empty_lessons": "You don't have any lessons yet",
      "active_filter": "Active",
      "finished_filter": "Finished",
      "in_progress_badge": "In Progress",
      "finished_badge": "Finished",
      "lesson_link": "Go to Lesson",
      "history_link": "Go to Lesson History",
      "copy_link": "Copy Link",
      "cancel_lesson": "Cancel Lesson"
    }
  })

  const [lessonsData, setLessonsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [lessonsPortion, setLessonPortion] = useState(defaultLessonsPortion)


  const apiConf = {
    method: 'get',
    link: `ebooks/active_lessons`,
    params: {
      limit: lessonsPortion,
      user: userId
    }
  }
  const updateLessons = async () => {
    try {
      const res = await superAxiosRequest(apiConf)
      setLessonsData(res.data.result)
    } catch {
      setLessonsData([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    updateLessons()
  }, [lessonsPortion])



  const [showTests, setShowTests] = useState(true);
  const [filterButtonsStatus, setFilterButtonsStatus] = useState({
    active: true,
    finished: true,
  });
  const isMyLessonsEmpty = lessonsData.length < 1 && !isLoading;



  const getFilterLessons = () => {
    if (filterButtonsStatus.active && filterButtonsStatus.finished) {
      return lessonsData;
    } else if (filterButtonsStatus.active) {
      return lessonsData.filter((el) => !el.is_finish)
    } else if (filterButtonsStatus.finished) {
      return lessonsData.filter((el) => el.is_finish)
    } else {
      return []
    }
  }

  const handleActiveClick = () => {
    setFilterButtonsStatus({...filterButtonsStatus, active: !filterButtonsStatus.active,});
    setShowTests(true);
  };

  const handleFinishedClick = () => {
    setFilterButtonsStatus({...filterButtonsStatus, finished: !filterButtonsStatus.finished,});
    setShowTests(true);
  };

  return (
    <div className={styles.main}>
      <BlockHeader title={translate.title}>
        <GoToLink onClick={() => setLessonPortion(maxLessonsSize)}/>
      </BlockHeader>
      {!isMyLessonsEmpty ?
        <>
          <div className={styles.filterButtonsBlock}>
            <SuperButton variant={"filter"} type={"filterColored"} callback={handleActiveClick}>
              {translate.active_filter}
            </SuperButton>
            <SuperButton variant={"filter"} callback={handleFinishedClick}>
              {translate.finished_filter}
            </SuperButton>
          </div>
          {showTests &&
            <div className={styles.content}>
              {isLoading ? <Loader/> : getFilterLessons().map((item, i) => <Lesson key={i} {...item} translate={translate} updateLessons={updateLessons}/>)}
            </div>}
        </>
        :
        <MyLessonsEmpty translate={translate.empty_lessons}/>
      }
    </div>
  );
};

export default MyLessons;