import React from 'react';
import {observer} from 'mobx-react'
import FileUploader from "./files/FileUploader";
import Checkbox from "../../Checkbox";
import SelectCustom from "../../Select/SelectCustom";
import st from "./FormGenerator.module.scss"
import asideModalStore from "Stores/asideModalStore";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormInput from "components/UI/AsideModal/Form/FormInput";
import FileSelector from "components/UI/FileSelector/FileSelector";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";

const FormGenerator = () => {
    const {modalSettings: ms, modalValues: values, changeValue: changeValues, check} = asideModalStore

    const onChangeTextHandler = (e, field) => {
        let new_val = e.target.value
        if (typeof field.onChange === 'string'){
            switch (field.onChange){
                case 'int':
                    new_val = +e.target.value
                    break;
                default:
                    break;
            }
        }
        if (typeof field.onChange === 'function'){
            new_val = field.onChange(values[field.name], e.target.value)
        }
        changeValues(new_val, field.name)
    }

    const renderField = field => {
        switch (field.type){
            case 'text':
                return <FormField>
                    {field.label &&
                    <FormLabel isRequired={!!field.required}>{field.label}</FormLabel>}
                    <FormInput isError={check(field.name)}>
                        <input type="text"
                               name={field.name}
                               placeholder={field.placeholder}
                               value={field.getValue ? field.getValue(values[field.name]) : values[field.name] || ''}
                               onChange={e => onChangeTextHandler(e, field)}
                               className={check(field.name) ? 'super-modal__input--error' : undefined}
                        />
                    </FormInput>
                </FormField>
            case 'textarea':
                return <FormField>
                    {field.label &&
                    <FormLabel>{field.label}</FormLabel>}
                    <FormInput isError={check(field.name)}>
                        <textarea name={field.name}
                                  placeholder={field.placeholder}
                                  value={values[field.name] || ''}
                                  onChange={e => onChangeTextHandler(e, field)}
                        />
                    </FormInput>
                </FormField>
            case 'file-upload':
                return <FileUploader onUploadEnd={o => {changeValues(o.file, field.name)}} error={check(field.name)} />
            case 'media-upload':
                return <FileSelector
                    type={ field.fileType || 'image'}
                    types={field.types || ['jpg', 'gif', 'png']}
                    error= {check(field.name)}
                    value={values[field.name] || ''}
                    onChange={val => changeValues(val, field.name)}/>
            case 'checkbox':
                return <div>
                    <Checkbox id={field.name}
                              label={field.label}
                              checked={values[field.name] || false}
                              isChecked={() => changeValues(!values[field.name], field.name)}/>
                </div>
            case 'toggle-checkbox':
                return <div>
                    <ToggleCheckbox
                        label={field.label}
                        checked={values[field.name] || false}
                        onChange={() => changeValues(!values[field.name], field.name)}
                    />
                </div>
            case 'toggle-radio-box':
                return <div className={st.toggleRadioBox}>
                    {field.list.map((item, key) =>
                        <ToggleCheckbox
                            key={key}
                            label={item.label}
                            labelClassName={st.toggleRadioBoxLabel}
                            checked={item.value === values[field.name]}
                            onChange={() => changeValues(item.value, field.name)}/>
                    )}
                </div>
            case 'radioList':
                return <form className={st.radioList}>{field.list.map((item, key) =>
                  <div key={key} className={st.radioList__item} onClick={() => {
                      changeValues(item.value, field.name)
                  }}>
                      <input className={st.radioInput} type="radio" checked={item.value === values[field.name]}/>
                      <label>{item.name}</label>
                  </div>)}
                </form>
            case 'select':
                return <FormField>
                        <FormLabel>{field.label}</FormLabel>
                            <SelectCustom error={check(field.name)}
                                          placeholder={field.placeholder}
                                          options={field.options}
                                          apiLink={field.apiLink}
                                          apiConfParams={field.apiConfParams}
                                          apiGetResult={field.apiGetResult}
                                          optionValueKey={field.optionValueKey}
                                          optionLabelKey={field.optionLabelKey}
                                          formatOptionLabel={field.formatOptionLabel}
                                          sortKey={field.sortKey}
                                          valueID={values[field.name]}
                                          onChange={o => field.onChange ?
                                            field.onChange( o, changeValues ) :
                                            changeValues(o[field.optionValueKey || 'id'], field.name)}
                                          isSearchable={field.isSearchable}
                            />
                    </FormField>
            default:
                return null;
        }
    }

    return (
        <FormFieldset >{Array.isArray(ms.fields) && ms.fields.map((field, i) =>
            <div key={i}>{renderField(field)}</div>)}
        </FormFieldset>
    );
}

export default observer(FormGenerator)
