import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {localisationMaster} from "Localisation/Localisation";
import {colors} from "../Global/ContentTable_test/avatar";
import Stores from 'Stores';
import ActionMenu from '../Global/ActionMenu/ActionMenu';
import st from './SharedLessonCard.module.scss'
import {ReactComponent as IconGallery} from "components/ContentBox/img/image-gallery-icon.svg";

export default function SharedLessonCard(props) {

    const [open, setOpen] = useState(false)

    const menu = [
        {
            name: localisationMaster('text_10', 'lessons/course'),
            active: true,
            action: 'detail',
            permission: ['forward']
        },
    ]

    const action = data => {
        switch (data) {
            case 'detail':
                setOpen(!open)
                break;
            default:
                break;
        }
    }

    return (
        <div className={props.check ? st.boxChecked : st.box}>
            <div className={st.header}>
                <div className={st.headerLeft}>
                    <div className={props.check ? st.check : st.uncheck} onClick={() => props.onChange(!props.check)}/>
                    <div className={st.pic}>
                        {props.lesson.image ?
                            <img src={Stores.baseLink() + props.lesson.image} alt={props.lesson.name}/>
                            :
                            <div style={{backgroundColor: colors(props.lesson.id.toString())}}>
                                <IconGallery/>
                            </div>}
                    </div>
                    <Link to={'/lessons/lesson/' + props.lesson.id} target="_blank">{props.lesson.name}</Link>
                </div>
                <div>
                    <ActionMenu menu={menu} action={action}/>
                </div>
            </div>
        {open &&
        <div className="courseLessonCard__body">
            <div className="courseLessonCard__unit">
                <span>{localisationMaster('text_14', 'lessons/course')}</span>
                <span dangerouslySetInnerHTML={{__html: props.lesson.description}}/>
            </div>
            <div className="courseLessonCard__unit">
                <span>{localisationMaster('text_15', 'lessons/course')}</span>
                <span dangerouslySetInnerHTML={{__html: props.lesson.home_work || 'Не указано'}}/>
            </div>
        </div>}
    </div>
    )
}
