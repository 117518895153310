import React from 'react';
import TimeTableCalendar from "components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendar";
import { localisationMaster } from "Localisation/Localisation";
import { sorter } from 'components/ContentBox/Groups/components/TimeTableCalendar/TimeTableCalendarFunctions';
import { addZero } from "helpers/math";
import './Timetable.scss';
import { translate as loc, dict } from "Localisation";

const Timetable = (props) => {
    const createCalendar = (date, weekdays) => {
        let y, m, d;
        let calendar = [];
        let start_at = new Date(date.date_from);
        let end_at = new Date(date.date_until);
        while (start_at <= end_at) {
            const next_day_date = new Date(start_at);
            let day_index = next_day_date.getDay() || 7;
            if (weekdays.includes(day_index)) {
                d = addZero(next_day_date.getDate());
                m = addZero(next_day_date.getMonth() + 1);
                y = next_day_date.getFullYear();
                calendar.push({ date: [y, m, d].join('-'), start_at: date.start_at, finish_at: date.finish_at, teachers: date.teachers });
            }
            start_at = new Date(start_at.setDate(start_at.getDate() + 1));
        }
        return calendar;
    }

    const getTeacher = arr => props.group.teacher_info.filter(el => arr.includes(el.id));

    const parseDates = (data) => sorter(data)
        .map(el => createCalendar(el, el.weekdays))
        .flat()
        .map(({ date, ...el }) => {
            const days = props.group.school_day_notes.find(day => new Date(day.date).getTime() === new Date(date).getTime());
            return { ...el, date, status: days ? days.status : null, teachers: getTeacher(el.teachers)  };
        });

    const weekDay = arr => {
        const week = [
            localisationMaster('text_22', 'groups/all-groups'),
            localisationMaster('text_23', 'groups/all-groups'),
            localisationMaster('text_24', 'groups/all-groups'),
            localisationMaster('text_25', 'groups/all-groups'),
            localisationMaster('text_26', 'groups/all-groups'),
            localisationMaster('text_27', 'groups/all-groups'),
            localisationMaster('text_28', 'groups/all-groups')
        ];
        const weeks = arr.map(el => week[el - 1]);
        return weeks.join("/");
    }

    const createDates = (dates, separator) => {
        if (dates === undefined) return '';
        let n_dates = dates.split('-');
        return n_dates[2] + separator + n_dates[1]
    }
    
    return (
        <div className="timetable-box clientGroupInfo__timetable">
            <div className="timetable-box__header">
                <h3>{loc(dict.main_page.text_49)}</h3>
            </div>
            <div className="timetable-box__list">
                {props.group.schedules.map((el) =>
                    <div key={el.id} className="time-table-unit-box">
                        <div className="time-table-unit-box__left">
                            <div className="time-table-unit-box__teachers">
                                {el.teachers.length > 0
                                    ? (
                                        getTeacher(el.teachers).map((teacher, idx) =>
                                            <div key={idx} className="time-table-unit-box__name">
                                                {teacher.first_name} {teacher.last_name}
                                            </div>
                                        )
                                    )
                                    : (
                                        <div className="time-table-unit-box__name">
                                            {loc(dict.main_page.text_48)}
                                        </div>
                                    )
                                }
                            </div>
                            <div className="time-table-unit-box__units">
                                <div>
                                    {weekDay(el.weekdays)}
                                </div>
                                <div>
                                    {loc(dict.main_page.text_52)} {el.start_at ? el.start_at.substr(0, 5) : ''} {loc(dict.main_page.text_51)} {el.finish_at ? el.finish_at.substr(0, 5) : ''}
                                </div>
                                <div>
                                    {loc(dict.main_page.text_52)} {el.date_from === el.date_until ?
                                        createDates(el.date_from, '.') :
                                        createDates(el.date_from, '.') + ` ${loc(dict.main_page.text_50)} ` + createDates(el.date_until, '.')
                                    }
                                </div>
                                <div className="time-table-unit-box__classroom">
                                {el.classroom?.name ? `${loc(dict.group.text_1)}. ` + el.classroom?.name : loc(dict.group.text_2)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <TimeTableCalendar
                items={parseDates(props.group.schedules)}
                clickDisabled={props.clickDisabled}
                holidays={props.group.holidays}
                statuses={props.group.schoolday_note_statuses}
                school_day_notes={props.group.school_day_notes}
                schooldays={[]}
                isStudent
                lessonsLeft={() => { }}
                setsumLeft={() => { }}
                g_id={props.id}
                student={{
                    ...props.userGroup,
                    group_status: props.userGroup.status,
                }} />
        </div>
    )
}

export default Timetable;