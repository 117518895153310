import React from 'react';
import s from "./SimpleLink.module.scss";
import {SuperButton} from "components/UI/SuperButton/SuperButton";

const SimpleLink = ({data: {anchor, link}}) => {
  return (
    anchor ?
    <div className={s.link}>
      <SuperButton variant="redirect" type={'linkColored'} path={link}>
        {anchor}
      </SuperButton>
    </div> : null
  )
}

export default SimpleLink
