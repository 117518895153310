import React, {useEffect, useState} from 'react';
import {permissions} from 'settings/permissions';
import {checkPermList} from 'helpers';
import {observer} from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import {move} from "../functions"
import Stores from "Stores";
import EbookBookStore from "Stores/ebookBookStore"
import asideModalStore from "Stores/asideModalStore";
import modalStore from "Stores/modalStore";
import ShareCourse from "components/ContentBox/Global/GlobalModalSingle/templates/ShareCourse";
import BookDocs from "./components/BookDocs";
import ChapterCard from "./components/ChapterCard";
import st from "./Books.module.scss"
import TabMenu from "../Common/TabMenu/TabMenu";
import useLocalization from "hooks/useLocalization";
import RegisterListing from "../RegisterListing/RegisterListing";
import ActionMenu from "components/UI/ActionMenu/ActionMenu";
import EmptyChapterCard from "./components/EmptyChapterCard";
import ebookBookStore from "Stores/ebookBookStore";
import BookInfo from "./components/BookInfo";
import PageHeader from "components/UI/PageHeader/PageHeader";
import {useLocation} from "react-router-dom";

const Book = props => {
  const id = +props.match.params.id
  const {book, fetchBook, setBook, bookChapters, createChapter} = EbookBookStore
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabParam = searchParams.get('tab')
  
  const {translate} = useLocalization({
    section: 'ebooks',
    subsection: 'book',
    dict: {
      "chapters": "Chapters",
      "files": "Files",
      "registers": "Registers",
      "info": "Info",
      "add_new_section": "Add New Section",
      "edit_section": "Edit Section",
      "open_lesson": "Open Lesson",
      "edit_lesson": "Edit Lesson",
      "copy_lesson": "Copy Lesson",
      "delete_lesson": "Delete Lesson",
      "edit": "Edit",
      "delete": "Delete",
      "share": "Share",
      "exercises": "Exercises",
      "exercises_1": "Exercise",
      "exercises_2": "Exercises",
      "exercises_3": "Exercises",
      "empty_chapters": "The book currently has no sections",
      "empty_files": "The book has no files at the moment",
      "add_new_file": "Add New File",
      "empty_registers_section": "To add registers, first add a section for the register",
      "register_sections": "Register Sections",
      "education_type": "Education Type",
      "group_level": "Group Level",
      "add_file": "Add File",
      "file_name": "File Name",
      "description": "Description",
      "save": "Save",
      "add_new_register": "Add New Register",
      "edit_section_register": "Edit Register Section",
      "register_section_name": "Register Section Name",
      "add_register_section": "Add Register Section",
      "available_register_sections": "Available Register Sections",
      "edit_register_section": "Edit Register Section",
      "lesson_name": "Lesson Name",
      "select_sections": "Select Sections",
      "homework": "Homework",
      "add": "Add",
      "yes": "Yes",
      "no": "No",
      "cancel": "Cancel",
      "delete_register_section": "Are you sure you want to permanently delete the register section",
      "delete_register": "Are you sure you want to permanently delete the register",
      "empty_section": "To add exercises, first need to add sections",
      "delete_chapter": "Are you sure you want to permanently delete the chapter",
      "copy_chapter": "Are you sure you want to create a copy of the chapter",
      "edit_chapter": "Edit Chapter",
      "adding_a_book": "Adding a Book",
      "edit_a_book": "Edit a Book",
      "favorites": "Favorites",
    }
  })

  const msChapter = {
    type: 'generator',
    fields: [
      {
        type: 'text',
        label: `${translate.lesson_name}:`,
        name: 'name',
        placeholder: translate.lesson_name,
      },
      {
        type: 'textarea',
        label: `${translate.description}:`,
        name: 'description',
        placeholder: translate.description,
      },
      {
        type: 'textarea',
        label: `${translate.homework}:`,
        name: 'home_work',
        placeholder: translate.homework,
      },
      {
        type: 'media-upload',
        name: 'image',
      },

    ],
    valid: {
      name: 'required',
    },
    link: 'ebooks/chapters',
  }

  const tabs = [
    {id: 1, name: translate.chapters},
    {id: 2, name: translate.files},
    ...(checkPermList(permissions.add_ebook_book) ? [
      {id: 3, name: translate.registers},
      {id: 4, name: translate.info}
    ] : []),
    ...(Stores.isClient ? [{id: 5, name: translate.favorites, link: `/ebooks/my-favorites/${book.id}`}] : []),
  ];

  const [tab, setTab] = useState(tabs[0]);

  const menu = [
    ...checkPermList(permissions.change_course) ? [{
      name: translate.edit,
      action: 'edit'
    }] : [],
    ...checkPermList(permissions.add_share_course_link) ? [{
      name: translate.share,
      action: 'share'
    }] : [],
  ]

  const msBook = {
    type: 'ebookBookForm',
    title: `${translate.edit_a_book}`,
    button: localisationMaster('button-two', 'company/profiles/account', 'add_category'),
    valid: {
      name: 'required',
      company: 'required',
      course_level: 'required',
      skill_level: 'required',
    },
    link: 'ebooks/books',
  }

  const action = (actionType) => {
    switch (actionType) {
      case 'edit':
        editBook();
        break;
      case 'share':
        shareCourse();
        break;
      default:
        break;
    }
  }

  const shareCourse = () => {
    const ms = {
      title: localisationMaster('share_course', 'lessons/course'),
      modalBodyComponent: () => <ShareCourse/>,
      button: false,
      //link: '',
      width: 620,
      lessons: bookChapters,
    }
    const md = {
      course_id: +props.match.params.id,
    }
    modalStore.activity(ms, md)
  }

  const editBook = () => {
    const ms = {
      ...msBook,
      is_put: true,
      link: `${msBook.link}/${id}`,
      onSubmitSuccess: setBook
    }
    const {name, company_id, course_level_id, skill_level_id, is_catalog, image} = book
    const md = {
      name,
      company: company_id,
      course_level: course_level_id,
      skill_level: skill_level_id,
      image,
      is_catalog,
    }
    asideModalStore.activity(ms, md)
  }

  const changePosition = (id, pos) => {
    let arr = bookChapters.map(({id}) => id)

    arr = move(arr, arr.indexOf(id), arr.indexOf(id) + pos)

    const apiConf = {
      method: 'post',
      link: `ebooks/chapter_positions/${book.id}`
    }
    const body = {
      position: arr
    }

    superAxiosRequest(apiConf, body).then(() => {
      fetchBook(book.id)
    }).catch(() => {

    })
  }

  const onChangeTab = tab => {
    tab.link ? window.location = tab.link : setTab(tab)
  }

  useEffect(() => {
    ebookBookStore.fetchBook(id)
  }, [id])
  
  useEffect(() => {
    if (tabParam) {
      const selectedTab = tabs.find(t => t.id === parseInt(tabParam));
      if (selectedTab) {
        setTab(selectedTab);
      }
    }
  }, [tabParam]);

  // const bookName = () => `${book.name}${Stores.isClient ? `` : ` (${book.course_level}, ${book.skill_level})`}`
  const bookName = () => `${book.name}`
  return (
    <>
      {book.id &&
        <div className={st.container}>
          <PageHeader title={bookName()}>
            {checkPermList(permissions.change_ebook) && menu.length > 0 && <ActionMenu menu={menu} action={action}/>}
          </PageHeader>
          <div className={st.page}>
            <TabMenu tabs={tabs} onChange={onChangeTab} tabID={tab.id}/>
            {tab.id === 1 &&
              <>
                {checkPermList(permissions.add_ebook_chapter) &&
                  <button className={st.addChapterButton} onClick={() => createChapter(translate, msChapter)}>
                    {translate.add_new_section}
                  </button>}
                {bookChapters.length > 0 ?
                  <>
                    <div className={st.lessons}>{bookChapters.map((item, index) =>
                      <ChapterCard key={index}
                                   chapter={item}
                                   last={bookChapters.length}
                                   changePosition={changePosition}
                                   translate={translate}
                                   msChapter={msChapter}
                                   position={index + 1}/>)}
                    </div>
                  </>
                  :
                  <EmptyChapterCard translate={translate}/>
                }
                {(checkPermList(permissions.add_ebook_chapter)) &&
                  <button className={st.addChapterButton} onClick={() => createChapter(translate, msChapter)}>
                    {translate.add_new_section}
                  </button>
                }
              </>}
            {tab.id === 2 && <>
              {checkPermList(permissions.view_course_docs) && <BookDocs translate={translate}/>}
            </>}
            {tab.id === 3 && <RegisterListing translate={translate} id={book.id}/>}
            {tab.id === 4 && <BookInfo translate={translate} book={book}/>}
          </div>
        </div>}
    </>
  )
}

export default observer(Book)
