import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import modalStore from "Stores/modalStore";
import { superAxiosRequest } from "axiosApi";
import { localisationMaster } from 'Localisation/Localisation';
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";
import swal from "sweetalert";
import Loader from 'components/UI/Loader/Loader';
import style from './Tests.module.scss';
import TestResult from "components/Modules/Groups/components/TestResults/TestResult/TestResult";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";

const Tests = (props) => {
    const id = props.match.params.id;
    const [tests, setTests] = useState({ loading: true, list: [], group: null, students: [] });

    const groupedStudents = () => {
        const result = Object.groupBy(tests.list, (element) => element.student.id)
        return Object.keys(result).map(studentId => ({
            student: {
                ...result[studentId][0].student
            },
            data: result[studentId]
        }))
    }

    const valid = {
        category: 'required',
        description: 'required',
        student: 'required',
        test: 'required',
        test_scores: 'required',
    }

    let ms = {
        link: 'groups/interim_result_tests',
        button: localisationMaster('text_54', 'groups/all-groups'),
        title: localisationMaster('adding_personal_test_results', 'groups/group'),
        type: 'groupTestResult',
        data: 'json',
        students: tests.students,
        func: (data) => setTests({ ...tests, list: data.test_results, students: data.students }),
        valid
    }

    const actions = (action, e, item) => {
        switch (action) {
            case 'edit':
                modalStore.activity({
                    ...ms,
                    is_put: true,
                    link: `${ms.link}/${item.id}`
                }, {
                    ...item,
                    group: +id,
                    teacher: item.teacher.id,
                    student: item.student.id,
                })
                break;
            case 'delete':
                swal({
                    title: `Вы действительно хотите удалить "${item.test}, ${item.student.full_name}" за  ${timeConverter(item.date, 'clean_data')} г.?`,
                    text: 'Восстановить удаленные данные будет невозможно.',
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                }).then(okay => {
                    if (!okay) return;
                    setTests({ ...tests, loading: true });
                    superAxiosRequest({ method: 'delete', link: `${ms.link}/${item.id}` }).then(({ data }) => {
                        setTests({ ...tests, loading: false, list: data.test_results, students: data.students });
                    })
                });
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        superAxiosRequest({ link: 'groups/interim_result_tests', method: "get", params: { group_id: id } }).then(({ data }) => {
            setTests({ ...tests, loading: false, list: data.test_results, students: data?.students, group: data.group });
        });
    }, [id])

    if (tests.loading) return <Loader />
    return (
        <>
            {/*<ContentHeader*/}
            {/*    title={`Тесты: ${tests.group?.number} - ${tests.group?.name}`}*/}
            {/*    modal_type={!isClient && "GlobalSuperModal"}*/}
            {/*    button={!isClient && ['Добавить', 'view_tests_group']}*/}
            {/*    breadСrumbs={*/}
            {/*        isClient ? */}
            {/*            [ { title: `Группа: ${tests.group?.number} - ${tests.group?.name}`, route: `/groups/group/${tests.group?.id}` } ]*/}
            {/*        : [*/}
            {/*            { title: 'Учебные группы', route: '/groups/all-groups' },*/}
            {/*            { title: `Группа: ${tests.group?.number} - ${tests.group?.name}`, route: `/groups/group/${tests.group?.id}` },*/}
            {/*        ]*/}
            {/*    }*/}
            {/*    modal_settings={{*/}
            {/*        link: 'groups/interim_result_tests',*/}
            {/*        button: localisationMaster('text_54', 'groups/all-groups'),*/}
            {/*        title: localisationMaster('adding_personal_test_results', 'groups/group'),*/}
            {/*        type: 'groupTestResult',*/}
            {/*        data: 'json',*/}
            {/*        students: tests.students,*/}
            {/*        func: (data) => setTests({ ...tests, list: data.test_results, students: data.students }),*/}
            {/*        valid,*/}
            {/*    }}*/}
            {/*    modalValues={{*/}
            {/*        date: dateFormat(new Date(), "YYYY-mm-dd"),*/}
            {/*        group: id,*/}
            {/*    }}*/}
            {/*/>*/}
            <div className={style.testsGroup}>
                {tests.list.length > 0 ? groupedStudents().map((el, index) =>
                    <TestResult key={index} item={el} actions={actions} listing={true}/>
                ) : <div className={style.empty}>
                    <EmptyData/>
                </div>
                }
            </div>
        </>
    )
}

export default observer(Tests);