import React from 'react';
import style from './Log.module.scss'
import {ReactComponent as Review} from "./img/review_icon.svg";
import {ReactComponent as Revision} from "./img/revision_info.svg";
import {ReactComponent as Confirm} from "./img/confirm_icon.svg";
import {dateFormat} from "helpers/date";
import Stores from "Stores";

const Log = ({type, date, teacher}) => {

    const renderLog = () => {
        switch (type) {
            case 'Homework Submitted':
            case 'Lesson Submitted':
                return <div className={style.container}>
                    <span className={style.date}>{ dateFormat(date, 'dd MMM YYYY hh:ii')}</span>
                    <div className={style.info}>
                        <Review/>
                        <span>Homework has been sent to the teacher for checking.</span>
                    </div>
                </div>
            case 'Homework Reviewed':
            case 'Lesson Reviewed':
                return <div className={style.container}>
                    <span className={style.date}>{ dateFormat(date, 'dd MMM YYYY hh:ii')}</span>
                    <div className={style.info}>
                        <Revision/>
                        <span>Homework is checked by the teacher <b>({Stores.transliterate(`${teacher.first_name} ${teacher.last_name}`)})</b> and sent for revision.</span>
                    </div>
                </div>
            case 'Homework Graded':
            case 'Lesson Graded':
                return <div className={style.container}>
                    <span className={style.date}>{ dateFormat(date, 'dd MMM YYYY hh:ii')}</span>
                    <div className={style.info}>
                        <Confirm/>
                        <span>Homework has been checked by the teacher <b>({Stores.transliterate(`${teacher.first_name} ${teacher.last_name}`)})</b> and confirmed.</span>
                    </div>
                </div>
            default : return <div>Ошибка</div>
        }
    }

    return renderLog()
};

export default Log;