import React from 'react';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores'
import modalStore from "Stores/modalStore";
import SelectCustom from "../../Select/SelectCustom";
import ImageSelector from "../../ImageSelector/ImageSelector";
import Checkbox from "../../Checkbox";
import "./CourseForm.scss"

const CourseForm = () => {
    const {modalValue, getModalValues, changeValues, changeValuesArray, check} = modalStore

    const onChangeImage = image => {
        if (image) {
            changeValues(image, 'image')
        }
        else {
            const o = {...modalValue}
            delete o.image
            changeValuesArray(o)
        }
    }

    return (
        <div className="modalCourseForm">
            <div>
                <input
                    placeholder={localisationMaster('text_6', 'lessons/course')}
                    type="text"
                    value={modalValue.name || ''}
                    onChange={e => changeValues(e.target.value, 'name')}
                    className={check('name') ? 'super-modal__input--error' : undefined}
                />
            </div>

            <div>
                <SelectCustom
                    placeholder={localisationMaster('select-placeholder-1', 'company/age', 'add_age')}
                    apiLink="db/companies"
                    valueID={modalValue.company || null}
                    onChange={o => changeValues(o.id, 'company')}
                    error={check('company')}
                />
            </div>

            <div className="modalCourseForm__row">
                <div className="modalCourseForm__col2">
                    <SelectCustom
                        placeholder={localisationMaster('text_7', 'lessons/course')}
                        apiLink="groups/groups_catalog"
                        apiGetResult={result => result.course_levels.items}
                        valueID={modalValue.course_level || null}
                        onChange={o => changeValues(o.id, 'course_level')}
                        error={check('course_level')}
                    />
                </div>

                <div className="modalCourseForm__col2">
                    <SelectCustom
                        placeholder={localisationMaster('text_8', 'lessons/course')}
                        apiLink="groups/groups_catalog"
                        apiGetResult={result => result.skill_levels.items}
                        sortKey="name"
                        valueID={modalValue.skill_level || null}
                        onChange={o => changeValues(o.id, 'skill_level')}
                        error={check('skill_level')}
                        isSearchable
                    />
                </div>
            </div>

            <div>
                <ImageSelector onChange={onChangeImage} currentPicture={modalValue.course_image}/>
            </div>

            {Stores.Rules.checkRule2('lessons', 'course', 'add_course_to_catalog') &&
            <div>
                <Checkbox label={localisationMaster('text_22', 'lessons/course')}
                          checked={getModalValues.is_catalog || false}
                          isChecked={() => changeValues(!getModalValues.is_catalog, 'is_catalog')}/>
            </div>
            }

        </div>
    )
}

export default observer(CourseForm)
