import React from 'react';
import styles from "./HoverPreview.module.scss";

const HoverPreview = ({childrenPhoto, childrenIcon}) => {
  return (
    <div className={styles.box}>
      <div className={styles.preview}>
        {childrenPhoto}
        <div className={styles.iconBox}>
          <div className={styles.viewIcon}>
            {childrenIcon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoverPreview;