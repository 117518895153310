import React from "react";
import logo from "components/HeadBox/img/logo.png";
import styles from './Footer.module.scss';
import Stores from "Stores";

const Footer = () => (
    <footer className={styles.footer}>
        <div className={styles.footer__left}>
            <img src={logo} alt="logo" className={styles.footer__logo} />
            <div className={styles.footer__content}>
                <p>COPYRIGHT ©{new Date().getFullYear()} PHOENIX, All rights Reserved</p>
                <a href={Stores.baseLink()} target="_blank">
                    {Stores.baseLink()}
                </a>
            </div>
        </div>
        <div>
            <div className={styles.footer__version}>v.1.3 🥁️</div>
        </div>
    </footer>
);

export default Footer;
