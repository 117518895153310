import React from 'react';
import StudyTestResultStore from "Stores/studyTestResultStore";
import CompleteAnswer from "./CompleteAnswer";
import st from "./CompleteAnswers.module.scss"

export default function CompleteAnswers() {
    const {sortedAnswers} = StudyTestResultStore

    return (
        <ol className={st.answers}>{sortedAnswers.map((a, i) =>
            <li key={a.id} className={st.answer}>
                <span className={st.pos}>{i + 1}</span>
                <div>
                    <CompleteAnswer key={a.id} answer={a} number={i + 1}/>
                </div>
            </li>)}
        </ol>
    )
}
