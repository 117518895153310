import React, { useState, useEffect } from "react";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Bundles from "./Bundles/Bundles";
import Loader from "components/ContentBox/Global/Loader/Loader";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import { superAxiosRequest } from "axiosApi";
import { classNames as cn } from "helpers";
import { localisationMaster } from "Localisation/Localisation";
import Error from "../Error/Error";
import "./Ligaments.scss";

const Ligaments = ({
  setForm,
  form,
  validate,
  optionsBundle,
  setOptionsBundle,
  bundleRef,
}) => {
  const [bundles, setBundldes] = useState({ data: [], loading: true });
  const [filter, setFilter] = useState({ age: null, program: null, name: null });

  useEffect(() => {
    superAxiosRequest({
      method: "get",
      link: "placement_test/bundles",
    })
      .then(({ data }) => {
        setBundldes({ loading: false, data: data.result });
      })
      .catch(() => setBundldes({ data: [], loading: false }));
  }, []);

  const removeBundle = (id) => {
    const result = form.bundles.filter((item) => id !== item.id);
    setForm({ ...form, bundles: result });
  };

  const getBundles = (params) => {
    const array = Object.entries(params || {});
    const queryParams = Object.fromEntries(
      array.filter(([key, value]) => value)
    );
    setBundldes({ ...bundles, loading: true });
    superAxiosRequest({
      method: "get",
      link: "placement_test/bundles",
      params: queryParams,
    })
      .then(({ data }) => {
        setBundldes({ loading: false, data: data.result });
      })
      .catch(() => setBundldes({ data: [], loading: false }));
  };

  return (
    <div className="placementTestBox" ref={bundleRef}>
      <div className="placementTestCreate__ligaments">
        <div className="placementTestBox__mainBoxLine">
          <p className="placementTestBox__title">
            {localisationMaster(
              "text_15",
              "c/tests/placement-test/create-widget"
            )}{" "}
          </p>
        </div>
        <div className="placementTestBox__mainBoxLine">
          <div className="placementTestBox__safeUser">
            <p className="super-modal__form-label">Заголовок</p>
            <input
              type="text"
              className={cn({
                "placementTestBox__input": true,
                "placementTestBox__input--error":
                validate("bundle") &&
                  !optionsBundle.placeholder.length
                    ? true
                    : false,
              })}
              value={optionsBundle.placeholder}
              onChange={(e) =>
                setOptionsBundle({
                  ...optionsBundle,
                  placeholder: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="placementTestBox__mainBoxLine placementTestBox__bundleRequired">
          <CheckboxToggle
            right={"Объязательное поле"}
            onChange={() => setOptionsBundle({ ...optionsBundle, selected: !optionsBundle.selected })}
            checked={optionsBundle.selected}
          />
        </div>
        <div className="placementTestBox__mainBoxLine">
          <p className="placementTestBox__subtitle">
            {localisationMaster(
              "text_29",
              "c/tests/placement-test/create-widget"
            )}
            :
          </p>
        </div>
        <div className="placementTestBox_mainBoxLine">
          {validate("bundles") ? (
            <Error
              text={localisationMaster(
                "error_bundles",
                "c/tests/placement-test/create-widget"
              )}
            />
          ) : null}
        </div>
        <div className="placementTestBox__mainBoxLine">
          {!form.bundles.length ? (
            <div className="sg-probka">
              {localisationMaster(
                "warning",
                "c/tests/placement-test/create-widget"
              )}
            </div>
          ) : (
            form.bundles.map((item) => {
              const checked = form.bundles.some((el) => el.id === item.id);
              return (
                <Bundles
                  key={item.id}
                  {...item}
                  icon={() => (
                    <CheckboxToggle
                      checked={checked}
                      onChange={() => removeBundle(item.id)}
                    />
                  )}
                />
              );
            })
          )}
        </div>
        <div className="placementTestBox__mainBoxLine placementTestBox__filter">
          <input
            type="text"
            placeholder={localisationMaster(
              "table-row-title-1",
              "lessons/levels"
            )}
            onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            value={filter?.name || ""}
            className="placementTestBox__input"
          />
          <SelectCustom
            placeholder={localisationMaster(
              "text_17",
              "c/tests/placement-test/create-widget"
            )}
            apiLink="db/age"
            valueID={filter.age}
            onChange={(val) => setFilter({ ...filter, age: val.id })}
          />
          <SelectCustom
            placeholder={localisationMaster(
              "placeholder-10",
              "online-schedule/record-customer"
            )}
            apiLink="db/programs"
            valueID={filter.program}
            onChange={(val) => setFilter({ ...filter, program: val.id })}
          />
        </div>
        <div className="placementTestBox__mainBoxLine">
          <div className="placementTestBox__btns">
            <button onClick={() => getBundles(filter)}>
              {localisationMaster(
                "text_18",
                "c/tests/placement-test/create-widget"
              )}
            </button>
            <button
              onClick={() => {
                setFilter({ age: null, program: null, name: null });
                getBundles(null);
              }}
            >
              {localisationMaster(
                "text_19",
                "c/tests/placement-test/create-widget"
              )}
            </button>
          </div>
        </div>
        <div className="placementTestBox__mainBoxLine">
          {bundles.loading ? (
            <Loader />
          ) : bundles.data.length === 0 ? (
            <div className="sg-probka">
              {localisationMaster(
                "warning",
                "c/tests/placement-test/create-widget"
              )}
            </div>
          ) : (
            bundles.data
              .filter((item) => !form.bundles.some((el) => el.id === item.id))
              .map((item) => (
                <Bundles
                  key={item.id}
                  {...item}
                  icon={() => (
                    <div
                      onClick={() =>
                        setForm({
                          ...form,
                          bundles: [...form.bundles, item],
                        })
                      }
                      className="placementTestBundle__plus"
                    ></div>
                  )}
                />
              ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Ligaments;
