import {observable, action, computed, makeObservable} from 'mobx';
import { superAxiosRequest } from 'axiosApi';
import LocalStorage from 'services/LocalStorage';
import { localisationMaster } from "Localisation/Localisation";
import { sortSlotByStartTime } from 'components/Modules/Slots/utils';

export const DataType = {
  location: 'location',
  dates: 'dates',
  slots: 'slots',
  slotTypes: 'slotTypes',
  bookedSlots: 'bookedSlots',
  checkboxesStatus: 'checkboxesStatus',
  slotType: 'slotType',
  comment: 'comment',
};

export const ActionType = {
  resetSlots: 'resetSlots',
  resetSelectedSlot: 'resetSelectedSlots',
  getLocations: 'getLocations',
  getSlots: 'getSlots',
  getDates: 'getDates',
};

const initialCheckboxes = {
  'is_group': false,
  'is_school': false,
  'is_online': true,
  'is_zoom_planning': false,
};

class SlotsTeacherScheduleStore {
  location = null;
  company = LocalStorage.get('ud')?.user_data?.profile?.company?.id || null;
  dates = [];
  selectedDates = new Set();
  slots = [];
  slotTypes = [];
  bookedSlots = [];
  selectedSlots = new Set();
  checkboxesStatus = initialCheckboxes;
  slotType = null;
  comment = '';

  constructor() {
    makeObservable(this, {
      location: observable,
      dates: observable,
      selectedDates: observable,
      slots: observable,
      selectedSlots: observable,
      checkboxesStatus: observable,
      slotType: observable,
      comment: observable,
      bookedSlots: observable,

      setData: action.bound,
      resetData: action.bound,
      getDataFromServer: action.bound,
      sendDataToServer: action.bound,
      updateSlot: action.bound,
      deleteSlot: action.bound,
      createZoomLink: action.bound,

      isSubmitDisabled: computed,
      dataToSubmitSwal: computed,
    });
  }

  setData(data, type) {
    this[type] = data;
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetSlots:
        this.selectedDates = new Set();
        this.slots = [];
        this.selectedSlots = new Set();
        break;
      case ActionType.resetSelectedSlot:
        this.selectedSlots = new Set();
        this.slotType = this.slotTypes[0]?.id;
        this.comment = '';
        this.checkboxesStatus = initialCheckboxes;
        break;
      default:
        this.dates = [];
        this.selectedDates = new Set();
        this.slots = [];
        this.slotType = null;
        this.comment = '';
    }
  }

  get isSubmitDisabled() {
    const isCommentRequired = this.slotTypes.some(({ id, is_comment }) => id === this.slotType && is_comment) ? `${localisationMaster('table-row-title-2', 'company/companies').toLowerCase()}, ` : '';
    const requiredFields = [
      this.comment ? '' : isCommentRequired,
      this.slotType ? '' : `${localisationMaster('text_39', 'teacher_schedule')}, `,
    ];
    const text = requiredFields.join('').replace(/,\s$/, '');
    return {
      isDisabled: Boolean(text),
      message: text,
    };
  }

  get dataToSubmitSwal() {
    return {
      date: this.dates[[...this.selectedDates.values()][0]].date_at,
      slotType: this.slotTypes.find(({id}) => this.slotType === id)?.name,
      isOnline: this.checkboxesStatus.is_online,
      isSchool: this.checkboxesStatus.is_school,
      slots: [...this.selectedSlots.values()].map((item) => (this.slots.find(({id}) => id === item))),
    }
  }

  prepareDataToServer() {
    const dateID = [...this.selectedDates.values()][0];
    const slots = [...this.selectedSlots.values()].map((item) => ({
      id: item,
      slot_date: this.dates[dateID].date_at,
    }));
    return {
      type: this.slotType,
      is_online: this.checkboxesStatus.is_online,
      is_school: this.checkboxesStatus.is_school,
      slot_booking: this.checkboxesStatus.is_zoom_planning,
      group_clients: this.checkboxesStatus.is_group,
      comment: this.comment,
      company: this.company,
      location: this.location.value,
      date_from: this.dates[0].date_at,
      slots,
    };
  }

  getDataFromServer({type, params, onSuccess = () => {}, onFinally = () => {}}) {
    const link = type === ActionType.getLocations ? `db/companies/${this.company}` : 'online_schedule/teacher/slots';
    superAxiosRequest({
      method: 'get',
      link,
      params,
    })
      .then(({data}) => {
        switch (type) {
          case ActionType.getSlots:
            this.setData(data, DataType.slots);
            break;
          case ActionType.getDates:
            this.dates = data.dates;
            this.slotTypes = data.slot_types;
            break;
          default:
            onSuccess(data);
            break;
        }
      })
      .finally(() => onFinally());
  }

  updateSlot(data, onSuccess = () => {}, onFail = () => {}) {
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/teacher/slots/${data.id}`,
    }, data)
      .then(({data}) => {
        this.bookedSlots = this.bookedSlots.map((item) => item.id === data.id ? data : item);
        this.dates[[...this.selectedDates.values()][0]].booked_slots = this.bookedSlots;
        onSuccess();
      })
      .catch(() => onFail());
  }

  deleteSlot(data, onSuccess = () => {}, onFail = () => {}) {
    const apiConf = {
      method: 'delete',
      link: `online_schedule/teacher/slots/${data.id}`,
    };
    superAxiosRequest(apiConf, {data: {comment: data.comment}})
      .then(() => {
        this.bookedSlots = this.bookedSlots.map((item) => item.id === data.id ? {...item, is_deleted: true} : item);
        this.dates[[...this.selectedDates.values()][0]].booked_slots = this.bookedSlots;
        onSuccess();
      })
      .catch(() => onFail());
  }

  sendDataToServer({ onSuccess, onFail }) {
    const preparedData = this.prepareDataToServer();
    const apiConf = {
      method: 'post',
      link: 'online_schedule/teacher/slots',
    };

    superAxiosRequest(apiConf, preparedData)
      .then(({data}) => {
        const dayIndex = [...this.selectedDates.values()][0];
        this.resetData(ActionType.resetSelectedSlot);
        this.dates = data.dates;
        this.bookedSlots = data.dates[dayIndex].booked_slots.sort(sortSlotByStartTime);
        this.slots = data.dates[dayIndex].available_slots;
        onSuccess();
      })
      .catch(({response}) => {
        const message = typeof response.data === 'object'
          ? Object.values(response.data)[0]
          : ''
        onFail(message);
      });
  }

  createZoomLink(slot_id, onSuccess, onFail) {
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/zoom/${slot_id}`,
    })
      .then(({data}) => {
        const { zoom_link } = data
        if (zoom_link === null) {
          onFail();
          return;
        }
        const index = this.bookedSlots.findIndex((slot) => slot.id === slot_id);
        this.bookedSlots[index] = {...this.bookedSlots[index], slot_booking: true, zoom_url: zoom_link};
        onSuccess(zoom_link);
      })
      .catch(() => onFail());
  }
}

export default new SlotsTeacherScheduleStore();
