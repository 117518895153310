import React from "react";
import PropTypes from "prop-types";
import st from "./OpenHeader.module.scss";
import {ReactComponent as Plus} from "components/UI/icons/add-line.svg";
import {GoToLink} from "../../../UI/SuperButton/SuperButton";

const OpenHeader = props => {
  return (
    <div className={st.header}>
      <h3 onClick={props.onClick}>{props.title}</h3>
      <div className={st.buttons}>
        {props.addButton &&
            <div className={st.addButton} onClick={props.addButton}>
              <Plus/>
            </div>
        }
        {
         props.link &&
            <div>
              <GoToLink to={props.link} target={props.target}/>
            </div>
        }
      </div>
    </div>
  )
}

OpenHeader.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  addButton: PropTypes.func
}

export default OpenHeader
