import React from 'react';
import { observer } from 'mobx-react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import { timeConverter } from 'components/ContentBox/Online-schedule/components/functions'
import ebookBookStore from "Stores/ebookBookStore";
import asideModalStore from "Stores/asideModalStore";
import { ReactComponent as DocsIcon } from "components/ContentBox/img/new_document_img.svg"
import styles from './BookDocs.module.scss';
import {checkPermList, classNames as cn} from "helpers";
import {permissions} from "settings/permissions";
import not_found_documents from "components/UI/illustrations/streamline-work-from-home.svg";

function BookDocs({translate}) {
    const {book, bookDocs: docs, addDoc, setDoc, removeDoc} = ebookBookStore

    const msDoc = {
        type: 'generator',
        title: translate.add_file,
        button: translate.save,
        fields: [
            {
                type: 'text',
                name: 'name',
                label: `${translate.file_name}:`,
                placeholder: translate.file_name,
            },
            {
                type: 'textarea',
                name: 'description',
                label: `${translate.description}:`,
                placeholder: translate.description,
            },
            {
                type: 'media-upload',
                name: 'file',
                types: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'pps', 'ppsx', 'xls', 'xlsx', 'txt', 'rtf', 'zip', 'rar'],
                fileType: 'file',
            },
            // {
            //     type: 'checkbox',
            //     name: 'is_example',
            //     label: localisationMaster('modal_doc_text_4', 'lessons/course'),
            // },

        ],
        valid: {
            name: 'required',
            file: 'required',
        },
        link: 'ebooks/docs',
        onSubmitSuccess: addDoc,
    }

    const deleteFile = (id) => {
        const apiConf = {
            method: 'delete',
            link: 'ebooks/docs/' + id
        }
        superAxiosRequest(apiConf).then(() => {
            removeDoc(id)
        }).catch(() => {})
    }

    const editFile = (doc) => {
        const ms = {
            ...msDoc,
            fields: msDoc.fields.filter(f => f.name !== 'file'),
            button: localisationMaster('text_54', 'groups/all-groups'),
            is_put: true,
            link: msDoc.link + '/' + doc.id,
            valid: {
                name: 'required',
            },
            onSubmitSuccess: setDoc,
        }
        const md = {
            name: doc.name,
            description: doc.description,
            file: doc.file,
            is_example: doc.is_example,
        }
        asideModalStore.activity(ms, md)
    }

    const getType = name => {
        let arr = []
        if (name) {
            arr = name.split('.')
            return arr[arr.length - 1].toUpperCase();
        } else {
            return 'ERR'
        }
    }

    return (
        <div className={styles.bookDocs}>
            {docs.length > 0 ? (
                <ul className={styles.bookDocs__list}>
                    {docs.map(doc =>
                        <li key={doc.id} className={styles.bookDocs__item}>
                            <div className={styles.bookDocs__fileWrapper}>
                                <a href={doc.file} download>
                                    <div className={styles.bookDocs__file}>
                                        <DocsIcon />
                                        <span>{getType(doc.file)}</span>
                                    </div>
                                </a>
                            </div>
                            <div
                                className={cn({
                                    [styles.bookDocs__name]: true,
                                    [styles.bookDocs__empty]: doc?.description?.length === 0
                                })}
                            >
                                <a href={doc.file} download>
                                    {doc.name}
                                </a>
                            </div>
                            <div className={styles.bookDocs__dateName}>
                                <p className={styles.bookDocs__date}>
                                    <strong>{timeConverter(doc.created_at, 'time')}</strong>, {timeConverter(doc.created_at, 'clean_data')}
                                </p>
                                {(checkPermList(permissions.add_ebook_document)) &&
                                  <p className={styles.bookDocs__user}>{doc.full_name}</p>
                                }
                            </div>
                            <div className={styles.bookDocs__description}>
                                {doc?.description?.length > 0 && (
                                  <p>
                                      {doc.description}
                                  </p>
                                )}
                            </div>
                            <div className={styles.bookDocs__btns}>
                                {(checkPermList(permissions.add_ebook_document)) &&
                                  <>
                                    <button onClick={() => editFile(doc)}>{translate.edit}</button>
                                    <button onClick={() => deleteFile(doc.id)}>{translate.delete}</button>
                                  </>
                                }
                            </div>
                        </li>
                    )}
                </ul>
              )
            :
              (
                <div className={styles.bodyNotFound}>
                    <img className={styles.imgNotFound} src={not_found_documents} alt="Document not found"/>
                    <p className={styles.text}>{translate.empty_files}.</p>
                </div>
              )
            }
            {(checkPermList(permissions.add_ebook_document)) &&
              <button
                onClick={() => asideModalStore.activity(msDoc, {ebook: book.id})}
                className={styles.bookDocs__add}
              >
                  {translate.add_new_file}
              </button>
            }
        </div>
    )
}

export default observer(BookDocs)
