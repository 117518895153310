import React from 'react';
import st from './SectionList.module.scss'
import { ReactComponent as IconTrash } from "components/ContentBox/img/trash.svg";
import { ReactComponent as IconDown } from "components/ContentBox/img/arrow-down.svg";
import { ReactComponent as IconUp } from "components/ContentBox/img/up-arrow.svg";
import { translate as loc, dict } from "Localisation";

export default function SectionList(props) {
    return (
        <div className={'phoenixBox ' + st.box}>
            <header>
                <div className={"flexLine " + st.box}>
                    <h3>{ loc(dict.books.sections)}</h3>
                </div>
            </header>
            <main>
                <ul>{props.sections.map((s, idx) =>
                    <li key={s.id}>
                        <span className={props.activeSection === idx + 1 ? st.nameActive : st.name}
                              onClick={() => props.setActiveSection(null, idx + 1)}>
                            {s.name}
                        </span>
                        {props.action &&
                            <div className={st.panel}>
                                <div onClick={() => props.action('delete', s)}>
                                    <IconTrash />
                                </div>

                                {idx > 0 &&
                                    <div onClick={() => props.action('up', s)}>
                                        <IconUp />
                                    </div>}

                                {idx < props.sections.length - 1 &&
                                    <div onClick={() => props.action('down', s)}>
                                        <IconDown />
                                    </div>}
                            </div>}
                    </li>)}
                </ul>

                {props.action &&
                    <div className={st.createBtn} onClick={() => props.action('add')}>Добавить раздел</div>}
            </main>
        </div>
    );
};