import React from 'react';
import {observer} from "mobx-react";
import asideModalStore from "Stores/asideModalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormFieldset from "components/UI/AsideModal/Form/FormFieldset";
import FormSelectCustom from "components/UI/AsideModal/Form/FormSelectCustom";
import FormField from "components/UI/AsideModal/Form/FormField";

const BeginLesson = () => {
  const {changeValue, modalValues, check} = asideModalStore

  return (
    <FormFieldset>
      <FormField>
        <FormLabel>Курс обучения:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder="Выберите курс"
            apiLink={`ebooks/books`}
            apiConfParams={{limit: 999}}
            valueID={modalValues.course}
            isDisabled
            onChange={() => {}}
          />
        </FormSelectCustom>
      </FormField>
      <FormField>
        <FormLabel>Урок:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder="Выберите урок"
            {...(modalValues.course && {apiLink: 'ebooks/books/' + modalValues.course})}
            apiGetResult={result => result.chapters}
            sortKey="position"
            noOptionsMessage={() => "Уроков нет"}
            valueID={modalValues.chapter_id}
            onChange={o => changeValue(o.id, 'chapter_id')}
            error={check('chapter_id')}
          />
        </FormSelectCustom>
      </FormField>
      <div>
        <Checkbox label="Отправить студентам ссылку на урок по e-mail"
                  name="is_notice"
                  checked={modalValues.is_notice}
                  onChange={changeValue}/>
      </div>
    </FormFieldset>
  );
};

export default observer(BeginLesson)
