import {action, computed, makeObservable, observable, toJS} from 'mobx';
import { superAxiosRequest } from 'axiosApi';
import { timeConverter } from "components/ContentBox/Online-schedule/components/functions";

export const ActionType = {
  resetAll: 'resetAll',
  resetCalendar: 'resetCalendar',
  resetCustomers: 'resetCustomers',
};

const findTeacherSlot = (customers) => (idTeacher, idSlot) => {
  const newCustomers = [...customers];
  const teacherIndex = newCustomers.findIndex(el => el.employee_id === idTeacher);
  const slotIndex =  newCustomers[teacherIndex].items[0].employee_booked_slots.findIndex(el => el.id == idSlot);
  return { newCustomers, teacherIndex, slotIndex };
}

class SlotsAdvancedTeachersHistoryStore {
  companies = [];
  selectedCompany = null;
  selectedLocation = null;
  dates = [];
  selectedDates = new Set();
  isCustomersLoading = true;
  customers = [];
  slotTypes = null;

  constructor() {
    makeObservable(this, {
      companies: observable,
      selectedCompany: observable,
      selectedLocation: observable,
      dates: observable,
      selectedDates: observable,
      isCustomersLoading: observable,
      customers: observable,
      slotTypes: observable,

      companiesList: computed,
      locations: computed,
      selectedDate: computed,

      setData: action.bound,
      getDates: action.bound,
      getCustomersFromServer: action.bound,
      resetData: action.bound,
      updateSlot: action.bound,
      deleteSlot: action.bound,
      createZoomLink: action.bound,
    });
  }

  get companiesList() {
    if (!this.companies.length) return [];
    return toJS(this.companies)
      .map((company) => ({ ...company, value: company.id, label: company.name, }))
      .sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get locations() {
    if (!this.selectedCompany) {
      return [];
    }
    const locations = this.companies.find((item) => item.id === this.selectedCompany.value).locations;
    return toJS(locations)
      .map((location) => ({ ...location, value: location.id, label: location.name }))
      .sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get selectedDate() {
    return this.dates[[...this.selectedDates.values()][0]].date_at;
  }

  setData(data, type) {
    this[type] = data;
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetAll:
      default:
        this.selectedLocation = null;
        this.selectedDates.clear();
        this.slotTypes = null;
        this.customers = [];
        break;
      case ActionType.resetCalendar:
        this.selectedDates.clear();
        this.customers = [];
        break;
      case ActionType.resetCustomers:
        this.customers = [];
        this.slotTypes = null;
        break;
    }
  }

  getDates(dateFrom) {
    const days = 28;
    const yesterday = new Date(dateFrom);
    
    yesterday.setHours(1);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);

    const dates = [yesterday];
    for(let i = 1; i < days; i++) {
      const date = new Date(dateFrom);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    this.dates = dates.map(el => ({ date_at: timeConverter(el, 'today') }));
  }

  getCustomersFromServer() {
    this.isCustomersLoading = true;
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/admin/advanced-history',
      params: { 
        date: this.selectedDate, 
        location: this.selectedLocation.id,
        company: this.selectedCompany.id,
        time: new Date().getTime(), },
    }).then(({ data }) => {
      this.customers = data.booked_slots;
      this.slotTypes = data.slot_types;
    })
      .finally(() => this.isCustomersLoading = false);
  }

  updateSlot(data, onSuccess = () => { }, onFail = () => { }) {
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/admin/advanced-history/${data.id}`,
    }, data)
      .then((res) => {
        const { newCustomers, teacherIndex, slotIndex } = findTeacherSlot(this.customers)(data.employee_id, res.data.id)
        const slot = newCustomers[teacherIndex].items[0].employee_booked_slots[slotIndex];
        const typeSlot = this.slotTypes.find(el => el.id === data.type);

        newCustomers[teacherIndex].items[0].employee_booked_slots[slotIndex] = { 
          ...slot, 
          ...res.data,
          type_id: typeSlot.id,
          type_name: typeSlot.name,
        };
        this.customers = newCustomers;
        onSuccess();
      })
      .catch(() => onFail());
  }

  deleteSlot(data, onSuccess = () => { }, onFail = () => { }) {
    const apiConf = {
      method: 'delete',
      link: `online_schedule/admin/advanced-history/${data.id}`,
    };
    superAxiosRequest(apiConf, {data: {comment: data.comment}})
      .then(() => {
        const { newCustomers, teacherIndex, slotIndex } = findTeacherSlot(this.customers)(data.employee_id, data.id);
        const slots = newCustomers[teacherIndex].items[0].employee_booked_slots;
        const slot = slots[slotIndex];
        
        newCustomers[teacherIndex].items[0].employee_booked_slots[slotIndex] = { ...slot, is_active: false };
        this.customers = newCustomers;
        onSuccess();
      })
      .catch(() => onFail());
  }

  createZoomLink(slot_id, employee_id, onSuccess, onFail) {
    superAxiosRequest({
      method: 'put',
      link: `online_schedule/zoom/${slot_id}`,
    })
      .then(({data}) => {
        const { zoom_link } = data
        if (zoom_link === null) {
          onFail();
          return;
        }
        const { newCustomers, teacherIndex, slotIndex } = findTeacherSlot(this.customers)(employee_id, slot_id)
        newCustomers[teacherIndex].items[0].employee_booked_slots[slotIndex].zoom_url = zoom_link;

        this.customers = newCustomers;
        onSuccess(zoom_link);
      })
      .catch(() => onFail());
  }
}

export default new SlotsAdvancedTeachersHistoryStore();
