import React from "react";
import {GoToLink} from "components/UI/SuperButton/SuperButton";
import st from "./PurposeTest.module.scss";
import {ReactComponent as MailIcon} from "components/UI/icons/mail-send-line.svg";

const getDateString = (date, time) => {
  const aDate = (new Date(`${date}T${time}`).toString()).split(' ')
  return `${time}, ${aDate[1]}. ${aDate[2]}`
}

const getRemainingTime = sec => {
  return `${Math.ceil(sec / 60 / 60 / 24)} days`
}

const getStartInTime = sec => {
  const hours = Math.floor(sec / 60 / 60)
  const days = Math.floor(sec / 60 / 60 / 24)
  const remainHours = hours % 24
  return `${days} d. ${remainHours} h.`
}

const PurposeTest = ({item}) => {
  const action = action => {
    switch (action){
      case 'go_to_test':
        window.open(`/study/test/${item.id}`, '_blank')
        break;
      default:
        break;
    }
  }

  return (
    <div className={st.row}>
      <div className={st.params} onClick={() => action('go_to_test')}>
        <div className={st.paramNameLevel}>
          <a href={`/study/test/${item.id}`} onClick={e => e.preventDefault()}>
            {item.name}
          </a>
          <span>
            {item.sub_lvl.lvl.name}
            <span className={st.separator}/>
            {item.sub_lvl.name}
            <span className={st.separator}/>
            {item.sub_lvl.lvl.subname}
          </span>
        </div>
        <div className={st.param}>
          <span className={st.paramName}>Students</span>
          <span className={st.paramValue}>{item.students.length}</span>
        </div>
        <div className={st.paramTime}>
          <span>{item.seconds_until_finish < 0 ?
            <b className={st.Danger}>Time's up</b> :
            item.seconds_until_start > 0 ?
              <span>Start In: <b>{getStartInTime(item.seconds_until_start)}</b></span> :
              <span>Remaining: <b>{getRemainingTime(item.seconds_until_finish)}</b></span>}
          </span>
          <time>{getDateString(item.date_from, item.start_at)} - {getDateString(item.date_until, item.finish_at)}</time>
        </div>
      </div>
      <div className={st.buttons}>
        {item.seconds_until_finish > 0 &&
          <div className={st.button}><MailIcon/></div>
        }
        <GoToLink to={`/study/test/${item.id}`} target="_blank"/>
      </div>
    </div>
  )
}

export default PurposeTest;
