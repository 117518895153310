import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { observer } from 'mobx-react';
import Paper from '../../../../UI/Paper/Paper';
import Flex from '../../../../UI/Flex/Flex';
import Paragraph from '../../../../UI/Paragraph/Paragraph';
import Button from '../../../../UI/Button/Button';
import slotsSettingsStore from '../../../../../Stores/slotsSettingsStore';
import { superAxiosRequest } from 'axiosApi';

function SubmitSection() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitDisabled } = slotsSettingsStore;
  const {isDisabled, message} = isSubmitDisabled;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { data, companyID } = slotsSettingsStore;
    setIsLoading(true);
    superAxiosRequest(
      {
        method: 'put',
        link: `online_schedule/${companyID}`
      },
      data,
    )
      .then(() => {
        swal('', 'Настройки успешно сохранены', 'success')
          .then((response) => {
            if (response) {
              history.push('/c/online-schedule/companies');
            }
          });
      })
      .catch(() => swal('', 'Ошибка сохранения, проверьте все поля', 'warning'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Paper>
      <Flex withGap sm='center'>
        <Button
          type="submit"
          onClick={handleSubmit}
          isDisabled={isLoading || isDisabled}
        >
          Сохранить настройки
        </Button>
        <Button variant="secondary" onClick={() => history.push('/c/online-schedule/companies')}>
          Отменить изменения
        </Button>
      </Flex>
      { isDisabled &&
        <Paragraph withMarginTop warning>
          Пожалуйста заполните поля: <strong>{message}</strong>
        </Paragraph>
      }
    </Paper>
  );
}

export default observer(SubmitSection);
