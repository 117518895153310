import React from 'react';
import { observer } from 'mobx-react';
import CalendarWrapper from 'components/Modules/Slots/ScheduleSettings/components/CalendarWrapper/CalendarWrapper';
import Flex from 'components/UI/Flex/Flex';
import SlotDelete from 'components/Modules/Slots/ScheduleSettings/components/SlotDelete/SlotDelete';
import SlotSettings from 'components/Modules/Slots/ScheduleSettings/components/SlotSettings/SlotSettings';
import TimeSlots from 'components/Modules/Slots/ScheduleSettings/components/TimeSlots/TimeSlots';
import SaveSettings from 'components/Modules/Slots/ScheduleSettings/components/SaveSettings/SaveSettings';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';
import styles from './ContentLayout.module.scss';

function ContentLayout() {
  const { isBooked, editingData } = slotsScheduleSettingsStore;

  return (
    <Flex asColumn withGap classes={styles.content_wrapper} align='stretch'>
      <CalendarWrapper />
      { isBooked && <SlotDelete/> }
      { editingData?.dates?.length > 0 &&
        <>
          <SlotSettings />
          <TimeSlots />
          <SaveSettings />
        </>
      }
    </Flex>
  );
}

export default observer(ContentLayout);
