import {localisationMaster} from "Localisation/Localisation";

// Форматирование строки возраста
export const ageToStr = sDate => {
  const dob = new Date(sDate);
  const age = (Date.now() - dob.getTime()) / 1000 / 3600 / 24 / 365.2425 | 0;

  let txt;
  let count = age % 100;
  if (count >= 5 && count <= 20) {
    txt = localisationMaster('text-6', 'online-schedule/future-slots');
  } else {
    count = count % 10;
    if (count === 1) {
      txt = localisationMaster('text-4', 'online-schedule/future-slots');
    } else if (count >= 2 && count <= 4) {
      txt = localisationMaster('text-5', 'online-schedule/future-slots');
    } else {
      txt = localisationMaster('text-6', 'online-schedule/future-slots');
    }
  }
  return `${age} ${txt}`;
}
