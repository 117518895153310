import React, {useEffect, useState} from 'react';
import styles from './SearchBooks.module.scss';
import {ReactComponent as SearchIcon} from 'components/UI/icons/curved-search.svg';
import {checkPermList, classNames as cn} from "helpers";
import {ReactComponent as BookFilter} from 'components/ContentBox/img/book-filter.svg';
import {permissions} from "settings/permissions";
import AsideFilterStore from "../../../../../UI/AsideFilter/asideFilterStore";

const SearchBooks = ({placeholder = '', onClear, onSearch, onFilter, translate, ...props}) => {
  const {openFilter, filterTextValues} = AsideFilterStore

  const [value, setValue] = useState('');
  const isText = value?.length >= 3;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isText) {
      onSearch(value);
    }
  }

  useEffect(() => {
    if (typeof (props.value) === 'string') {
      setValue(props.value)
    }
  }, [props.value])

  return (
    <div className={styles.searchBooks}>
      <div
        className={cn({
          [styles.searchBooksField]: true,
          [styles.searchBooksField_active]: isText
        })}
      >
        <SearchIcon className={styles.searchBooksField__icon}/>
        <input
          type="text"
          value={value}
          className={styles.searchBooksField__field}
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
        {isText && (
          <div
            className={styles.searchBooksField__clear}
            onClick={() => {
              onClear();
              setValue('');
            }}
          />
        )}
      </div>
      {isText && (
        <button
          className={styles.searchBooks__btn}
          onClick={() => onSearch(value)}
        >
          <SearchIcon/>
          <span>{translate.search}</span>
        </button>
      )}
      {checkPermList(permissions.add_ebook_book) &&
        <button className={`${styles.filterBtn} ${!!filterTextValues.length ? styles.filterBtn__filled : ''}`}
                onClick={openFilter}>
          <BookFilter/>
          <span>{translate.filter}</span>
        </button>
      }
    </div>
  )
}

export default SearchBooks;