import React, {useEffect, useState} from 'react';
import {Parser, ProcessNodeDefinitions} from "html-to-react";
import TextMarkup from "../../components/TextMarkup/TextMarkup";
import st from "./EnterWords.module.scss";

export default function EnterWords({answers, ...props}) {

  const MAX_LENGTH = 100;
  const re = /\[(.*?)]/g; //move to CONSTANTS
  const reTag = /<(.*?)>/g; //move to constants
  const insertVariants = [];

  let m, arr;
  for (m = re.exec(props.items.text); m; m = re.exec(props.items.text)) {
    arr = m[1].split('|')
    insertVariants.push(arr)
  }
  const placeholdersArr= insertVariants.map(ans => ans[0].length > 0 ? ' ' : ' ')

  /*const getCleanText = () => {
    let text = props.items.text
    const re = /<div(.*?)>/g;
    text = text.replaceAll(re, '<br/>')
    text = text.replaceAll('</div>', '')
    text = text.replaceAll('<br>', '')

    return text
  }*/

  const onInputHandler = (text, index) => {
    text = text.replaceAll(reTag, '')
    text = text.replaceAll('|', '')
    text = text.replace(/&nbsp;/g, ' ');
    text = text.replace(/[\t]/g, ' ');
    text = text.replace(/[ ]{2,}/g, ' ');
    text = text.replace(/[\s]+([\r\n])/g, '');
    text = text.replace(/([\r\n])[\s]+/g, '');
    text = text.replace(/[\r\n]{2,}/g, '');
    text = text.replace(/&lt;/g, '');
    text = text.replace(/&gt;/g, '');
    text = text.replace(/&copy;/g, '©');
    const arr = [...answers]
    arr[index] = text
    props.handleAnswers(arr, arr.filter(field => field).length === insertVariants.length)
  }


  /*const replaceText = (text, prevText) => {
    if (!!prevText) {
      const reTag = /<(.*?)>/g; //move to constants
      let tags = prevText?.match(reTag)?.filter(x => x !== '<br/>')
      text = text.replaceAll('<br>', '')
      if (tags?.length) {
        // let prevTags = ''
        for (let a = 0; a < tags.length; a++) {
          const isOpen = !tags[a]?.includes('/')
          if (isOpen && !!tags[a]) {
            const tagName = tags[a].replace('<', '').replace('>', '').split(' ')[0] //move to helpers ?
            const inof = tags.indexOf(`</${tagName}>`)
            if (inof === -1) {
              text = tags[a] + text
              delete tags[a]
            } else {
              //prevTags += tags[a]
              delete tags[a]
              delete tags[inof]
            }
          } else {
            delete tags[a]
          }
        }
      }
    }
    return text
  }*/

  /*const setTags = () => {
    const aTxt = getCleanText().replace(re, '@').split('@')
    let result = []
    for (let b = 0; b < aTxt.length; b++) {
      result.push(replaceText(aTxt[b], result[b - 1]))
    }
    return result
  }*/

  const onBeforeInputHandler = e => {
    if (e.target.textContent.length >= MAX_LENGTH) {
      e.preventDefault()
    }
  }

  const onEnterKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault() // Предотвратить стандартное поведение Enter
    }
  };

  const onBlurHandler = index => {
    setPlaceholders(placeholders.map((el, i) => {
      if (i === index && !answers[index]) {
        if (insertVariants[index][0].length > 0) {
          return ' '
        }
        if (insertVariants[index][0].length === 0) {
          return ' '
        }
      }
      return el
    }))
  }

  const onFocusHandler = index => {
    setPlaceholders(placeholders.map((el, i) => i === index ? '' : el))
  }

  const [placeholders, setPlaceholders] = useState(placeholdersArr)
  // I had a scenario where an HTML template was generated by a dummy teacher,
  // yet I wanted to leverage React for the parts I did have control over
  const htmlParser = new Parser(React)
  const processNodeDefinitions = new ProcessNodeDefinitions()
  const processingInstructions = [
    // Processing for my-contenteditable-component
    {
      shouldProcessNode: node => node.name === 'my-contenteditable-component',
      processNode: node => {
        const index = +node.attribs['data-index']
        return props.studentAnswer ?
          <span className={`${st.view} ${props.studentAnswer.result[index] ? st.right : props.studentAnswer.result[index] === false ? st.wrong : st.unknown}`}>
            {props.studentAnswer.answer[index].replaceAll('|', ' / ')}
          </span> :
          <span contentEditable="plaintext-only"
                {...(placeholders[index] && {dangerouslySetInnerHTML: {__html: placeholders[index]}})}
                onFocus={() => onFocusHandler(index)}
                onBlur={() => onBlurHandler(index)}
                onInput={e => onInputHandler(e.target.textContent, index)}
                onBeforeInput={onBeforeInputHandler} onKeyDown={onEnterKeyPress}
                className={`${answers[index] ? st.entered : ''} ${placeholders[index] && placeholders[index].length > 0 ? st.placeholder : ''}`}
          >

          </span>
      }
    },
    // Default processing
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]
  const replaceBrackets = str => {
    let index = 0
    return str.replace(re, () => `<my-contenteditable-component data-index="${index++}"></my-contenteditable-component>`)
  }
  const ReactElement = htmlParser.parseWithInstructions(
    `<div>${replaceBrackets(props.items.text)}</div>`,
    () => true,
    processingInstructions
  )

  useEffect(() => {
    // после каждой попытки сбрасываем значение в поле ввода
    setPlaceholders(placeholdersArr)
  }, [props.try])

  useEffect(() => {
    const newPlaceholdersArr = insertVariants.map(ans => ans[0].length > 0 ? ' ' : ' ');
    setPlaceholders(newPlaceholdersArr);
  }, [props.items]);

  return (
   <TextMarkup>
    <div className={st.enterWords}>
      {ReactElement}
      {/*{setTags().map((item, index) =>
        <Fragment key={index}>
          <span dangerouslySetInnerHTML={{__html: item}}/>
          {insertVariants[index] ?
            props.studentAnswer ?
              <span className={`${st.view} ${props.studentAnswer.result[index] ? st.right : st.wrong}`}>
                {props.studentAnswer.answer.split('|')[index]}
              </span>
              :
              <span
                contentEditable="plaintext-only"
                {...(placeholders[index] && {dangerouslySetInnerHTML: {__html: placeholders[index]}})}
                onFocus={() => onFocusHandler(index)}
                onBlur={() => onBlurHandler(index)}
                onInput={e => onInputHandler(e.target.textContent, index)}
                onBeforeInput={onBeforeInputHandler}
                onKeyDown={onEnterKeyPress}
                className={answers[index] ? st.entered : ''}
              />
            : null
          }
        </Fragment>
      )}*/}
    </div>
  </TextMarkup>
  )
}
