import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import { toJS} from "mobx";
import {superAxiosRequest} from 'axiosApi';
import {sortASC} from 'helpers';
import Stores from 'Stores';
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import Textarea from 'components/ContentBox/Global/Textarea/Textarea';
import st from "./Rating.module.scss"
import {ReactComponent as IconStar} from "components/ContentBox/img/star.svg";
import {ReactComponent as IconStarFill} from "components/ContentBox/img/star-fill.svg";
import { dateFormat } from 'helpers/date';
import useLocalization from 'hooks/useLocalization';

const Rating = ({user, evaluationApiLink, ratingApiLink}) => {
  const {data: ms, modalValue, changeValues, getModalValues, listInKeyChangeHandler, check } = modalStore

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_rating_select',
    dict: {
      "textarea_placeholder": "Add comment",
      "month": "Month"
    }
  })
    
  const [hoverS, setHoverS] = useState({e: 0, r: 0})
  const [rate, setRate] = useState([])
  const [evaluationList, setEvaluationList] = useState([])
  const [ratingList, setRatingList] = useState([])

  const onClickHandler = (evaluation_id, rating_id) => {
    setRate(rate.map((obj) => obj.evaluation_id === evaluation_id ? ({evaluation_id, rating_id}) : obj ))
  }
  
  useEffect(() => {
    superAxiosRequest({
      link: evaluationApiLink,
      method: 'get',
      params: {
        limit: 999,
        ...(ms.payload.isResponsible && {
          responsible: true
        })
      }
    }).then(res => {
          setEvaluationList(res.data.result)
          if(!ms.is_put) {
            setRate(res.data.result.map((eva) => ({
              evaluation_id: eva.id ,
              rating_id : null
            
            })))
          }
        })
      .catch(() => {
        
      })
    superAxiosRequest({
      link: ratingApiLink,
      method: 'get',
      params: {
        limit: 999,
        ...(ms.payload.isResponsible && {
          responsible: true
        })
      }
    }).then(res => {
      setRatingList(res.data.result)
      })
      .catch(() => {

      })
  }, [evaluationApiLink, ratingApiLink])

  useEffect(() => {
    listInKeyChangeHandler(sortASC(rate, 'evaluation'), 'rate_list')
  }, [rate])

  useEffect(() => {
    if (!getModalValues.rate_list?.length) return
    setRate([...getModalValues.rate_list])
  }, [])

  
  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-row2">
        <div className="super-modal__form-field"> 
          <div className="super-modal__form-label">{translate.month}</div>
          <div>
            <input
              value={dateFormat(new Date(), 'YYYY.mm')}        
              disabled                
            />
          </div>
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">{user.title}</div>
          <div>
            <SelectCustom
              placeholder={user.placeholder}
              options={ms.payload?.students ? toJS(ms.payload.students).map(item => ({
                ...item,
                name: Stores.transliterate(item.name)
              })) : [{id: modalValue[user.key], name: ms.payload?.student_name || ''}]}
              sortKey="name"
              valueID={modalValue[user.key]}
              onChange={o => changeValues(o.id, user.key)}
              isDisabled={!ms.payload?.students}
              error={check(user.key)}
            />
          </div>
        </div>
      </div>

      <div>
        {evaluationList.map(eva =>
          <div key={eva.id} className={`${st.row} ${check("rate_list") && rate.find(x => x.evaluation_id === eva.id)?.rating_id === null ? st.rowError : '' }` } >
            <div>{eva.name}</div>
            {ratingList.map(r =>
              <div key={r.id}
                   onMouseEnter={() => setHoverS({e: eva.id, r: r.value})}
                   onMouseLeave={() => setHoverS({e: 0, r: 0})}
                   onClick={() => onClickHandler(eva.id, r.id)}>
                {eva.id === hoverS.e & r.value <= hoverS.r ||
                rate.find(x => x.evaluation_id === eva.id)?.rating_id >= r.id & eva.id !== hoverS.e ?
                  <IconStarFill/> :
                  <IconStar/>
                }
               
              </div>
            )}
            {/* <div>{getRatingName(eva.id)}</div> */}
          </div>
        )}
      </div>

      <div className={st.customTextarea}>
        <Textarea onChange={changeValues}
                  value={modalValue.comment}
                  stateName={'comment'}
                  placeholder={translate.textarea_placeholder}
                  error={check('comment')}/>
      </div>
    </div>
  );
}

Rating.propTypes = {
  evaluationApiLink: PropTypes.string.isRequired,
  ratingApiLink: PropTypes.string.isRequired,
  user: PropTypes.shape({
    title: PropTypes.string,
    placeholder: PropTypes.string,
    key: PropTypes.string
  }).isRequired
}

export default observer(Rating)