import React from 'react';
import modalStore from '../../../../../Stores/modalStore';


export default function Informer(props) {
    const { data } = modalStore
    const ms = data
    const ReplaseR = (data)=>{
        return {__html: data}
    }

    return (
       <div>
           <div className="super-modal-informer-text" dangerouslySetInnerHTML={ReplaseR(ms.text)}/>
       </div>
    )
}
