import React from 'react';
import { observer } from 'mobx-react';
import ClientItem from 'components/Modules/Slots/SlotsListing/components/ClientItem/ClientItem';
import slotsListingStore from 'Stores/slotsListingStore';
import Stores from 'Stores';
import styles from './ClientsList.module.scss';

function ClientsList() {
  const { clients } = slotsListingStore;
  const { isClient } = Stores;

  return (
    <ul className={styles.list}>
      {
        clients.map((item, index) => (
          <ClientItem
            key={item.id.toString()}
            data={item}
            index={index}
            isClient={isClient}
          />
        ))
      }
    </ul>
  );
}

export default observer(ClientsList);
