import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {localisationMaster} from "Localisation/Localisation";
import {addZero} from "helpers/math";
import HolidaysCalendarStore from "./holidaysCalendarStore";
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import YearSelector from "./YearSelector";
import CalendarInfo from "./CalendarInfo";
import CalendarMonth from './CalendarMonth';
import Loader from "components/ContentBox/Global/Loader/Loader";
import st from './HolidaysCalendar.module.scss'


const createYear = () => Array.from({length: 12}, (v, i) => addZero(++i))

const HolidaysCalendar = () => {
    const {year, holidays, fetchHolidays} = HolidaysCalendarStore

    useEffect(fetchHolidays, [])

    return (
        <>
            <ContentHeader title={localisationMaster('text_1', 'groups/calendar')}/>
            <div className={st.wrapper}>
                <YearSelector/>
                <CalendarInfo/>
                {holidays.length === 0 ? <Loader/> :
                    <div className={st.calendar}>{createYear(year).map(month =>
                        <CalendarMonth key={month} month={month}/>)}
                    </div>}
            </div>
        </>
    )
}

export default observer(HolidaysCalendar)
