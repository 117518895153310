import React, {useEffect, useState} from 'react'
import swal from 'sweetalert';
import Filter from 'components/ContentBox/Global/Filter/Filter'
import ContentHeader from 'components/UI/ContentHeader/ContentHeader'
import {superAxiosRequest} from 'axiosApi';
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import LineChart from '../Charts/LineChart'
import DoughnutChart, {localTimeConverter} from '../Charts/DoughnutChart'
import st from "./StatisticClients.module.scss"
import Loader from "../../../UI/Loader/Loader";

const filterTypes = [
    {id: 1, name: "По дням", key: 'days'},
    {id: 2, name: "По неделям", key: 'weeks'},
    {id: 3, name: "По месяцам", key: 'months'},
]

const filterCnf = [
    {
        type: "days-range-picker",
        nameState: [
            "date_from", "date_until"
        ]
    },
    {
        type: "type-selector",
        nameState: "type",
        values: filterTypes.map(({id, name}) => ({id, name}))
    },
]

const requestLinks = {
    lineGraph: 'groups/stat',
    writtenTest: 'placement_test/stat',
    oralTest: 'online_schedule/stat',
    signedGroup: 'progress_report/group_join',
}

const lineColors = [
    'rgb(255, 99, 132)',
    'rgb(51, 102, 13)',
    'rgb(0, 77, 194)',
]

const findData = (array, id) => array.filter(item => item.id === id)[0].total

const getPercent = (totalValue, value) => Math.round((value / totalValue) * 100) || 0

const getTotal = (arr) => arr[0].total !== undefined ? arr.map(item => item.total) : arr.map(item => item.data.total)

const getLineDataset = (keys, lineData) => keys.map((key, idx) => (
    {
        label: key,
        title: "test",
        data: getTotal(lineData[key]),
        borderColor: lineColors[idx],
        backgroundColor: lineColors[idx],
        pointRadius: 5,
        pointBorderWidth: 2,
        pointBorderColor: 'rgb(255, 255, 255)',
    }
))

const requestHandlerTemplate = {
    lineGraph({data}, typeRequest, prev) {
        const keys = Object.keys(data)
        return {
            ...prev,
            lineGraph: {
                datasets: getLineDataset(keys, data),
                labels: data[keys[0]].map(() => "")
            }
        }
    },
    writtenTest({data: {placement_test: data}}, typeRequest, prev) {
        return {
            ...prev,
            doughnutChartData: {
                ...prev.doughnutChartData,
                writtenTest: {
                    label: "ШАГ 1. /* ПИСЬМЕННЫЙ ТЕСТ",
                    filters: typeRequest === "filterRequest" ?
                      prev.doughnutChartData.writtenTest.filters :
                      [{
                          type: "bound",
                          boundFilters: [
                              {
                                  nameFeild: "widget",
                                  placeholder: "Все виджеты",
                                  options: data.widgets.map(({id, name}) => ({id, name}))
                              },
                              {
                                  nameFeild: "bundle",
                                  placeholder: "Все связки",
                                  options: data.widgets.map(item => ({
                                      selectId: item.id,
                                      data: item.bundles.map(({id, name}) => ({id, name}))
                                  }))
                              },
                          ]
                      }],
                    total: {
                        test: {
                            value: data.total_data.total,
                            label: "всего письменных тестов"
                        },
                        time: {
                            value: new Date(data.total_data.avg_time * 1000),
                            label: "среднее время /n прохождения теста"
                        },
                    },
                    rightArray: data.widgets.map(item => ({
                        procent: getPercent(data.total_data.total, findData(data.widget_data, item.id)),
                        value: findData(data.widget_data, item.id), label: item.name
                    })),
                    leftArray: [
                        {
                            procent: getPercent(data.total_data.total, data.test_data.unique),
                            value: data.test_data.unique, label: "Уникальных тестов"
                        },
                        {
                            procent: getPercent(data.total_data.total, data.test_data.time_out),
                            value: data.test_data.time_out, label: "Вышло время"
                        },
                        {
                            procent: getPercent(data.total_data.total, data.test_data.finished),
                            value: data.test_data.finished,
                            label: "Завершенных тестов"
                        },
                        {
                            procent: getPercent(data.total_data.total, data.test_data.data_collection),
                            value: data.test_data.data_collection, label: "Только сбор данных"
                        },
                    ]
                }
            },
        }
    },
    oralTest({data}, typeRequest, prev) {
        return {
            ...prev,
            doughnutChartData: {
                ...prev.doughnutChartData,
                oralTest: {
                    label: "ШАГ 2. /* УСТНЫЙ ТЕСТ",
                    filters: typeRequest === "filterRequest" ?
                      prev.doughnutChartData.oralTest.filters : [
                          {
                              nameFeild: "age",
                              placeholder: "Все возраста",
                              type: "normal",
                              options: data.ages.map(({id, name}) => ({id, name}))
                          }
                      ],
                    total: {
                        test: {
                            value: data.total_data.total,
                            label: "всего устных тестов"
                        },
                        time: {
                            value: new Date(data.total_data.avg_time * 60 * 1000),
                            label: `среднее время между /n регистрацией и тестом`
                        },
                    },
                    rightArray: [
                        ...data.type_program.map(item => ({
                            procent: getPercent(data.total_data.total, item.total),
                            value: item.total,
                            label: item.name
                        })),
                        // Конверсия в зависимости от уникальных письменных тестов
                        {
                            procent: getPercent(
                              prev.doughnutChartData.writtenTest.leftArray[0].value,
                              data.type_slots.past
                            ),
                            value: "",
                            label: "Конверсия"
                        }
                    ],
                    leftArray: [
                        {
                            procent: getPercent(data.total_data.total, data.type_slots.past),
                            value: data.type_slots.past,
                            label: "Проведенные тесты"
                        },
                        {
                            procent: getPercent(data.total_data.total, data.type_slots.cancelled),
                            value: data.type_slots.cancelled,
                            label: "Отмененные тесты"
                        },
                        {
                            procent: getPercent(data.total_data.total, data.type_slots.no_client),
                            value: data.type_slots.no_client,
                            label: "Клиент не пришел"
                        },
                    ]
                }
            }
        }
    },
    signedGroup({data}, typeRequest, prev) {
        return {
            ...prev,
            doughnutChartData: {
                ...prev.doughnutChartData,
                signedGroup: {
                    label: "ШАГ 3. /* ПЕРВОЕ ЗАНЯТИЕ В ГРУППЕ",
                    filters: typeRequest === "filterRequest" ?
                      prev.doughnutChartData.signedGroup.filters :
                      [
                          {
                              nameFeild: "discipline",
                              placeholder: "Все типы групп",
                              type: "normal",
                              options: data.discipline_split
                          }
                      ],
                    total: {
                        test: {
                            value: data.total,
                            label: "всего платных первых занятий"
                        },
                        time: {
                            value: new Date(data.avg_time * 60 * 1000),
                            label: `среднее время между тестами /n и первым занятием`
                        },
                    },
                    rightArray: [
                        ...data.discipline_split.map(item => ({
                            procent: getPercent(data.total, item.total),
                            value: item.total,
                            label: item.name
                        })),
                        // Конверсия в зависимости от проведенных устных тестов
                        {
                            procent: getPercent(prev.doughnutChartData.oralTest.leftArray[0].value, data.total),
                            value: "",
                            label: "Конверсия"
                        }
                    ],
                    leftArray: []
                }
            }
        }
    },
}

const StatisticClients = () => {
    const [isLoading, setIsLoading] = useState(false)

    const date = new Date()
    const [apiConf, setApiConf] = useState({
        method: 'get',
        params: {
            date_from: timeConverter(new Date(date - ( 24 * 60 * 60 * 1000)), "today"),
            date_until: timeConverter(date, "today"),
            type: "days", //week, month
        }
    })
    const [chartData, setChartData] = useState({
        doughnutChartData: {},
        lineGraph: {
            labels: [''],
            datasets: [
                {
                    label: 'Dataset 1',
                    data: [1],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132)',
                    pointRadius: 4,
                    pointBorderColor: 'rgb(255, 255, 255)',

                },
            ],
        }
    })

    const changeRequestCnf = (filter) => {
        if (filter.date_from && filter.date_until && filter.type) {
            setApiConf({
                method: 'get',
                params: {
                    date_from: timeConverter(filter.date_from, "today"),
                    date_until: timeConverter(filter.date_until, "today"),
                    type: filterTypes.find(t => t.id === filter.type)?.key, //weeks, months
                }
            })
        } else {
            swal("Выберите параметры отчета", "", "error").then(() => {})
        }
    }

    const getDoughnutChartData = (requestParam, chartName) => {
        setIsLoading(true)
        superAxiosRequest({
            ...apiConf,
            link: requestLinks[chartName],
            type: "days",
            params: {...apiConf.params, ...requestParam}
        }).then(response => {
            setChartData(requestHandlerTemplate[chartName](response, "filterRequest", chartData))
            setIsLoading(false)
        })
    }

    useEffect(() => {
        setIsLoading(true)
        const graphs = Object.keys(requestLinks)
        const requests = graphs.map(key => superAxiosRequest(
          {...apiConf, link: requestLinks[key]},
          null,
          null,
          {timeout: 90000}
        ))
        Promise.all(requests).then(arr => {
            setChartData(graphs.reduce((acc, key, idx) =>
              requestHandlerTemplate[key](arr[idx], null, acc), chartData)
            )
            setIsLoading(false)
        })
    }, [apiConf])

    const {lineGraph, doughnutChartData} = chartData

    return (
        isLoading ? <Loader/> :
        <div>
            <ContentHeader title="Сводный отчет: этапы работы с клиентами"/>
            <Filter config={filterCnf} getFilterParams={changeRequestCnf} drop={() => {}} />
            <div className={st.wrapper}>
                {lineGraph && <LineChart newData={lineGraph} />}
                {Object.keys(requestLinks).slice(1).map(chartName =>
                  doughnutChartData[chartName] ?
                    <DoughnutChart key={chartName}
                                   chartName={chartName}
                                   chartData={doughnutChartData[chartName]}
                                   getFilterParams={getDoughnutChartData}/> :
                    null)}
                <div className={st.total}>
                    <div className={st.totalRow}>
                        <span>Конверсия всех этапов</span>
                        <span>{getPercent(
                          doughnutChartData.writtenTest?.leftArray[0].value,
                          doughnutChartData.signedGroup?.total.test.value)}
                            &nbsp;%
                        </span>
                    </div>
                    <div className={st.totalRow}>
                        <span>Среднее время прохождения всех этапов</span>
                        <span>{localTimeConverter(new Date(
                          doughnutChartData.writtenTest?.total.time.value.getTime() +
                          doughnutChartData.oralTest?.total.time.value.getTime() +
                          doughnutChartData.signedGroup?.total.time.value.getTime()))}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticClients;
