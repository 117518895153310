import React, {useEffect, useRef} from "react";
import {localisationMaster} from "Localisation/Localisation";
import Stores from "Stores";
import asideModalStore from "Stores/asideModalStore";
import "./DayInfo.scss";

const openInitLesson = uuid => {
    window.open('/ebooks/lesson/' + uuid, '_blank')
}

export const beginLesson = ({group_id, date, ebook_id}) => {
    const ms = {
        type: 'BeginLesson',
        isCenter: true,
        title: localisationMaster('start_lesson', 'groups/group'),
        button: localisationMaster('start_lesson', 'groups/group'),
        valid: {
            chapter_id: 'required'
        },
        link: 'ebooks/init_lesson',
        onSubmitSuccess: res => openInitLesson(res.uuid)
    }
    const md = {
        group_id,
        date,
        course: ebook_id,
        is_notice: false,
    }
    asideModalStore.activity(ms, md)
}

export default function DayInfo(props) {
    const { isTeacher } = Stores;

    const refTooltip = useRef()
    const refTooltipArrow = useRef()
    const tooltip = props.tooltip

    useEffect(() => {
        const $days = props.refDaysBox.current
        const $target = tooltip.target
        const $tooltip = refTooltip.current
        const $arrow = refTooltipArrow.current

        const daysRect = $days.getBoundingClientRect()
        const targetRect = $target.getBoundingClientRect()

        const daysMetric = {
            pl: parseInt(getComputedStyle($days).paddingLeft),
            pr: parseInt(getComputedStyle($days).paddingRight),
            r: daysRect.right
        }
        const targetMetric = {
            x: $target.offsetLeft,
            y: $target.offsetTop,
            w: targetRect.width,
            h: targetRect.height
        }

        let top = targetMetric.y+targetMetric.h+10
        let left = targetMetric.x - ($tooltip.offsetWidth - targetMetric.w)/2

        if (left < daysMetric.pl)
            left = daysMetric.pl

        $tooltip.style.top = top + 'px'
        $tooltip.style.right = ''
        $tooltip.style.left = left + 'px'

        const tooltipRect = $tooltip.getBoundingClientRect()

        if (tooltipRect.right > daysMetric.r - daysMetric.pr){
            $tooltip.style.right = daysMetric.pr + 'px'
            $tooltip.style.left = ''
        }

        // Выравнивание стрелки
        $arrow.style.left = ''
        const arrowRect = $arrow.getBoundingClientRect()
        $arrow.style.left = (targetRect.left - arrowRect.left + targetMetric.w/2 - arrowRect.width/2) + 'px'

        $tooltip.style.opacity = 1
    }, [props.tooltip, props.refDaysBox, tooltip.target])

    return (
        <div className="day-info" ref={refTooltip} onMouseLeave={props.setTooltip}>
            <div>
                {tooltip.lesson &&
                <>
                    <div className="day-info__lesson-duration">
                        <ul className="day-info__lessons">
                            <li><b>{tooltip.lesson.title} </b><span>{tooltip.lesson.content}</span></li>
                        </ul>

                        {(tooltip.lesson.timeDelta) &&
                        <div className="day-info__duration">
                            ({Math.round(tooltip.lesson.timeDelta / 45 * 100) / 100} {localisationMaster('ac_h', 'groups/group')}
                            / {tooltip.lesson.timeDelta} {localisationMaster('mins', 'groups/group')}.)
                        </div>}
                    </div>

                    {Array.isArray(tooltip.lesson.teachers) && tooltip.lesson.teachers.map((teacher, ind) =>
                        <div className="day-info__teacher" key={ind}>
                            {Stores.transliterate(teacher.last_name + ' ' + teacher.first_name)}
                        </div>)}

                    {(tooltip.lesson.day_sum && !isTeacher) &&
                    <div className="day-info__teacher">
                        {localisationMaster('spent_this_day', 'groups/group')}: {tooltip.lesson.day_sum} тг.
                    </div>}

                    {(!!tooltip.bonusSum && !isTeacher) &&
                      <div className="day-info__teacher">
                          Списано с бонусного счета: {(tooltip.bonusSum / 100).toFixed(2)} тг.
                      </div>}
                </>}

                {tooltip.text &&
                <div className="day-info__text" dangerouslySetInnerHTML={{__html: tooltip.text}}/>}

                {tooltip.ebook &&
                  <div className="day-info__params">
                      {tooltip.ebook.register &&
                      <>
                          <div className="day-info__param">
                              <b>Программа урока:</b>
                              <div>{tooltip.ebook.register.lesson_plan}</div>
                          </div>
                          <div className="day-info__param">
                              <b>Домашнее задание:</b>
                              <div>{tooltip.ebook.register.homework}</div>
                          </div>
                      </>}
                      <div className="day-info__param">
                          <b>&nbsp;</b>
                          <div>
                              <button className="day-info__button" onClick={() => beginLesson(tooltip.ebook)}>
                                  {localisationMaster('start_lesson', 'groups/group')}
                              </button>
                          </div>
                      </div>
                  </div>}
            </div>

            <div className="day-info__arrow" ref={refTooltipArrow} />
        </div>
    )
}
