import * as React from "react"
import { forwardRef, memo } from "react"
const SvgComponent = (props, ref) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={88}
        fill="none"
        ref={ref}
        {...props}
    >
        <path
            fill={props.fill || '#FFF9E9'}
            stroke={props.stroke || "#FFC700"}
            strokeWidth={2}
            d="m1 3.4 35.2 40.7L1 84.7V3.4Z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgComponent)
export const EbookHeaderArrow = memo(ForwardRef)