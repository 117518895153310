import React, { useState, useEffect } from 'react'
import st from './PassingWindow.module.scss'

export default function PassingWindow(props) {
    const initState = {
        focus_live_during: 0,
        focus_live_count: 0,
        mouse_freeze_during: 0,
        mouse_freeze_count: 0,
    }
    let mv = null;
    const [controller, setController] = useState(initState)
    const [lostFocusSatrt, setLostFocusStart] = useState(0)
    const [mouseStopped, setMouseStopped] = useState(0)
    const [mouseMoved, setmouseMoved] = useState(0)
    const [isLost, setIsLost] = useState(false)
    const mouseLeaveHandler = () => {
        if (!props.rules.focus_live) return;
        setLostFocusStart(new Date().getTime() / 1000)
        setIsLost(true);
    }

    useEffect(() => {
        props.setAdditioonals({ ...controller })
    }, [controller])

    useEffect(() => {
        setController(initState)
    }, [props.qid])


    const mouseMove = () => {
        if (mv) {
            clearTimeout(mv);
            setmouseMoved(new Date().getTime())
            setMouseStopped(0)
        }
        mv = setTimeout(() => setMouseStopped(new Date().getTime()), 2000);
    }

    useEffect(() => {
        if (mouseStopped === 0 || isLost || !props.rules.mouse_freeze_during) return
        const delta = Math.round((mouseMoved - mouseStopped) / 1000)
        if (delta > props.rules.mouse_freeze_during) {
            setController({
                ...controller,
                mouse_freeze_count: controller.focus_live_count + 1,
                mouse_freeze_during: controller.mouse_freeze_during + delta,
            })
            setmouseMoved(0);
            setMouseStopped(0)
        } else {
            setController({
                ...controller,
                mouse_freeze_during: controller.mouse_freeze_during + delta,
            })
            setmouseMoved(0);
            setMouseStopped(0)
        }
    }, [mouseMoved])

    useEffect(() => {
        if (!props.rules.mouse_freeze_during) return;
        document.body.addEventListener('mousemove', mouseMove, false);
        return () => {
            document.body.removeEventListener('mousemove', mouseMove, false);
        }
    }, [props.rules.mouse_freeze_during])

    const mouseEnterHandler = () => {
        if (!props.rules.focus_live || !isLost) return;
        setController({
            ...controller,
            ...((new Date().getTime() / 1000) - lostFocusSatrt > props.rules.focus_live_during && { focus_live_count: controller.focus_live_count + 1 }),
            focus_live_during: controller.focus_live_during + Math.round((new Date().getTime() / 1000) - (lostFocusSatrt))
        })
        setIsLost(false);
    }

    return (
        <div className={st.passingWindow} onMouseLeave={mouseLeaveHandler} onMouseEnter={mouseEnterHandler}>
            {props.children}
        </div>
    )
}
