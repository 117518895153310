import {localisationMaster} from "Localisation/Localisation";

export const msBook = {
  type: 'ebookBookForm',
  title: localisationMaster('edit_book', 'company/profiles/account', 'add_category'),
  button: localisationMaster('button-two', 'company/profiles/account', 'add_category'),
  valid: {
    name: 'required',
    company: 'required',
    course_level: 'required',
    skill_level: 'required',
  },
  link: 'ebooks/books',
}
