import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { getAdaptedTime } from 'components/Modules/Slots/utils';
import { dateFormat } from 'helpers/date';
import LocalStorage from 'services/LocalStorage';
import styles from './SubmitSwal.module.scss';

function SubmitSwal({data}) {
  const timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0;
  const meetingPoint = data.isSchool && data.isOnline
    ? 'Онлайн и в школе'
    : data.isSchool && !data.isOnline ? 'Только в школе' : 'Только онлайн';

  return (
    <dl className={styles.list}>
      <dt>Дата:</dt>
      <dd>{dateFormat(data.date, 'dd MMMM YYYY г.')}</dd>
      <dt>Тип слота:</dt>
      <dd>{data.slotType}</dd>
      <dt>Место проведения:</dt>
      <dd>{meetingPoint}</dd>
      <dt className={styles.full_width}>Забронированные слоты:</dt>
      <dd className={styles.slots_list}>{data.slots.map(({id, start_at, finish_at}) => (
        <span key={id.toString()} className={styles.slot}>
          {getAdaptedTime(start_at, timeZone)} - {getAdaptedTime(finish_at, timeZone)}
        </span>
      ))

      }
      </dd>
    </dl>
  )
}

SubmitSwal.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    slotType: PropTypes.string.isRequired,
    isSchool: PropTypes.bool,
    isOnline: PropTypes.bool,
    slots: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      start_at: PropTypes.string.isRequired,
      finish_at: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
}

export const createContentElement = (data) => {
  let wrapper = document.createElement('div');
  ReactDOM.render(<SubmitSwal data={data} />, wrapper);
  return wrapper.firstChild;
};
