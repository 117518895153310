import React, { useState, useEffect } from 'react'
import swal from "sweetalert";
import { superAxiosRequest } from 'axiosApi';
import { dateFormat } from "helpers/date";
import { localisationMaster } from "Localisation/Localisation";
import ContentHeader from '../Global/ContentHeader';
import InvoiceTicket from "./InvoiceTicket";
import PayService from '../Online-schedule/components/PayService/PayService';
import CheckPayStatus from '../Online-schedule/components/CheckPayStatus';
import InvoiceDetail from './InvoiceDetail';
import SelectCustom from 'components/ContentBox/Global/Select/SelectCustom';
import InputInn from "components/ContentBox/Global/InputInn/InputInn";
import InputPhone from "components/ContentBox/Global/InputPhone/InputPhone";
import Checkbox from 'components/ContentBox/Global/Checkbox';
import './Invoice.scss';

const initOptionsPayers = [
    { id: 9000000, label: localisationMaster('create_new_payer', 'pays/invoice') },
]

const initUserData = {
    last_name: '',
    first_name: '',
    inn: '',
    who_is_it: '',
    email: '',
    phone: '',
}

const statuses = {
    PAID: "paid",
    NOT_PAID: "not_paid"
}

export default function Invoice (props) {
    const link = 'online-schedule/record-customer-error'

    const [orderData, setOrderData] = useState(false)
    const [banks, setBanks] = useState([])
    const [paymentType, setPaymentType] = useState(1)
    const [checkStatusBlock, setCheckStatusBlock] = useState(false)
    const [timer, setTimer] = useState(0)
    const [user, setUser] = useState(initUserData)
    const [clientData, setClientData] = useState({})
    const [payerID, setPayerID] = useState(0)
    const [contacts, setContacts] = useState(initOptionsPayers)
    const [aError, setErrors] = useState([])

    const isNotPaid = orderData.status_order === statuses.NOT_PAID

    const changeUserHandler = (val, name) => {
        setUser({ ...user, [name]: val })
    }

    const getOrderStatus = () => {
        const api = {
            link: 'payments/order_status/' + props.match.params.id,
            method: 'get'
        }
        superAxiosRequest(api).then(({data: {result, banks}}) => {
            const r = result
            setOrderData(r)
            const userData = {
                last_name: r.last_name,
                first_name: r.first_name,
                inn: r.inn,
                who_is_it: '',
                phone: r.phone,
                email: r.email,
            }
            setUser(userData)
            setClientData(userData)
            setPaymentType(r.bank)
            setBanks(banks)
            r.payer_id !== null && setPayerID(r.payer_id)
        }).catch();
    }

    const checkOrder = () => {
        superAxiosRequest({
            method: 'get',
            link: `payments/order_status/${props.match.params.id}`,
        }).then(res => {
            setTimer(5)
            setOrderData(res.data.result)
            setPaymentType(res.data.result.bank)
        }).catch()
    }

    const cancelOrder = () => {
        swal({
            title: localisationMaster('text_2', 'alert'),
            text: `Вы действительно хотите отменить счет?`,
            icon: "warning",
            buttons: [localisationMaster('button_2', 'alert'), localisationMaster('button_1', 'alert')],
            dangerMode: true,
        }).then(yes => {
            if (yes) {
                const apiConf = {
                    method: 'put',
                    link: `payments/pay_orders/${orderData.id}`,
                }
                superAxiosRequest(apiConf, { status_order: 'cancelled' }).then(() => {
                    getOrderStatus()
                    swal("", 'Счет успешно отменен', "success").then(() => {})
                })
            } else {
                swal('Действие отменено').then(() => {})
            }
        })
    }

    const changeHandlerPayer = o => {
        if (o.id === 9000000) {
            setUser(initUserData)
            setPayerID(o.id)
        } else {
            setUser({
                first_name: o.data.first_name,
                inn: o.data.inn,
                last_name: o.data.last_name,
                phone: o.data.phone,
                email: o.data.email,
                who_is_it: o.data.who_is_it
            })
            setPayerID(o.id)
        }
    }

    const handleSubmit = () => {
        if (payerID === 9000000) {
            superAxiosRequest({
                link: 'accounts/contact_persons',
                method: 'post'
            }, {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                inn: user.inn,
                phone: user.phone,
                who_is_it: user.who_is_it,
                user: orderData.user_id,
            }).then(() => {})
        }
        const bank = banks.find(b => b.id === paymentType)

        // Если оплата через Каспи банк, что показываем блок проверки статуса
        if (bank.name.indexOf("Kaspi") !== -1){
            setCheckStatusBlock(true)
        } else {
            let a;
            a = document.createElement('a');
            a.target = "_blank";
            a.href = orderData.invoice_url
            a.click()
        }
    }

    const validate = () => {
        const aError = [];

        // Фамилия
        if (!(!!user.last_name) || user.last_name.length < 2)
            aError.push({
                field: localisationMaster('text-4', link),
                desc: localisationMaster('text-5', link)
            })

        // Имя
        if (!(!!user.first_name) || user.first_name.length < 2)
            aError.push({
                field: localisationMaster('text-2', link),
                desc: localisationMaster('text-3', link)
            })

        // Email
        if (!(!!user.inn) || user.email.length < 11)
            aError.push({
                field: localisationMaster('table-row-title-5', 'company/companies'),
                desc: localisationMaster('text-5', 'pays/invoice')
            })

        // Телефон
        if (!(!!user.phone) || user.phone?.length < 7 || user.phone?.length > 15)
            aError.push({
                field: localisationMaster('text-7', link),
                desc: localisationMaster('text-8', link)
            })

        if (!(!!user.who_is_it) || user.who_is_it?.length < 3)
            aError.push({
                field: localisationMaster('whois', 'company/profiles/account', 'add_category'),
                desc: localisationMaster('text-6', 'pays/invoice')
            })

        setErrors(aError)
    }

    const checkboxHandler = () => {
        if (payerID === 0) {
            setUser(initUserData)
            setPayerID(9000000)
        }
        if (payerID > 0) {
            setErrors([])
            setUser({ ...clientData })
            setPayerID(0)
        }
    }

    useEffect(() => {
        if (!orderData.user_id || orderData.status_order === statuses.PAID) return
        superAxiosRequest({
            link: 'accounts/contact_persons',
            method: 'get',
            params: {
                user_id: orderData.user_id,
            }
        }).then(res => {
            const list = res.data.result.map(el => ({id: el.id, label: `${el.first_name} ${el.last_name}`, data: el}))
            setContacts([...initOptionsPayers, ...list])
        }).catch()
    }, [orderData])

    useEffect(() => {
        if(payerID !== 0 && payerID < 9000000 && contacts.length > 1) {
            setErrors([])
            const dataC = contacts.find(x => x.id === payerID)?.data
            setUser({
                first_name: dataC.first_name,
                inn: dataC.inn,
                last_name: dataC.last_name,
                phone: dataC.phone,
                email: dataC.email,
                who_is_it: dataC.who_is_it
            })
        }
    }, [payerID, contacts])

    useEffect(() => {
        payerID === 9000000 && validate()
    }, [payerID, user])

    useEffect(() => {
        getOrderStatus()
    }, [])

    useEffect(() => {
        if (timer < 1) return;
        setTimeout(() => setTimer(timer - 1), 1000);
    }, [timer])

    const isDisabled = payerID > -1 && payerID < 9000000

    return (
        <div>
            <ContentHeader
                title={`${localisationMaster('invoice', 'pays/invoice')} №: ${props.match.params.id}`} />

            <div className="invoicePage">
                {orderData &&
                <div className="invoicePage__createdAt">
                    {localisationMaster('info-date', 'pays/payment/answer')}: {dateFormat(new Date(orderData.payment_date), "dd MMMM YYYY г.")}
                </div>}

                <div className="invoicePage__invoiceInfo phoenixBox">
                    <InvoiceTicket order={orderData} checkOrder={checkOrder} cancelOrder={cancelOrder} timer={timer} />
                </div>

                {orderData.status_order === statuses.PAID &&
                <div className="invoicePage__invoiceInfo phoenixBox">
                    <InvoiceDetail {...orderData} />
                </div>}

                {isNotPaid &&
                    <>
                        <PayService banks={banks} onChange={setPaymentType} value={paymentType} />

                        <div className="invoicePage__invoiceInfo phoenixBox">
                            <div className="fieldsetCustomer__title">
                                {localisationMaster('payer_data', 'pays/invoice')}
                            </div>
                            <div className="invoicePage__cols">
                                <div className="invoicePage__field2col">
                                    <SelectCustom
                                        placeholder={localisationMaster('customer_payers', 'pays/invoice')}
                                        options={contacts}
                                        valueID={payerID}
                                        onChange={o => changeHandlerPayer(o)}
                                        isSearchable
                                    />
                                </div>
                                <Checkbox
                                    label={localisationMaster('customer_and_payer', 'pays/invoice')}
                                    id="notifyClientsID"
                                    key_name={'is_ClientPayer'}
                                    checked={payerID === 0}
                                    isChecked={checkboxHandler}
                                    isDisabled={false}
                                />
                            </div>
                            <div className="invoicePage__cols">
                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__label">
                                        {localisationMaster('text-4', 'online-schedule/record-customer-error')}:
                                    </p>
                                    <input
                                        disabled={isDisabled}
                                        placeholder={localisationMaster('placeholder-5', 'online-schedule/record-customer')}
                                        value={user.last_name}
                                        onChange={e => changeUserHandler(e.target.value, 'last_name')}
                                        className="fieldsetCustomer__input"
                                    />
                                </div>

                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__label">
                                        {localisationMaster('text-2', 'online-schedule/record-customer-error')}:
                                    </p>
                                    <input
                                        disabled={isDisabled}
                                        placeholder={localisationMaster('table-row-title-3', 'pays/all-pays')}
                                        value={user.first_name}
                                        onChange={e => changeUserHandler(e.target.value, 'first_name')}
                                        className="fieldsetCustomer__input"
                                    />
                                </div>

                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__label">
                                        {localisationMaster('table-row-title-5', 'company/companies')}:
                                    </p>
                                    <InputInn value={user.inn}
                                              onChange={e => changeUserHandler(e, 'inn')}
                                              disabled={isDisabled}/>
                                </div>
                            </div>
                            <div className="invoicePage__cols">
                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__label">
                                        {localisationMaster('whois', 'company/profiles/account', 'add_category')}:
                                    </p>
                                    <input
                                        disabled={isDisabled}
                                        placeholder={localisationMaster('whois', 'company/profiles/account', 'add_category')}
                                        value={user.who_is_it || ''}
                                        onChange={e => changeUserHandler(e.target.value, 'who_is_it')}
                                        className="fieldsetCustomer__input"
                                    />
                                </div>

                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__noReqLabel">E-mail:</p>
                                    <input
                                        disabled={isDisabled}
                                        placeholder="E-mail"
                                        value={user.email}
                                        onChange={e => changeUserHandler(e.target.value, 'email')}
                                        className="fieldsetCustomer__input"
                                    />
                                </div>

                                <div className="invoicePage__field">
                                    <p className="fieldsetCustomer__label">
                                        {localisationMaster('text_55', 'modal_templates')}:
                                    </p>
                                    <InputPhone value={user.phone}
                                                onChange={e => changeUserHandler(e, 'phone')}
                                                disabled={isDisabled}/>
                                </div>
                            </div>
                            {aError.length > 0 &&
                              <div className="fieldsetCustomer__errors">{aError.map((error, index) =>
                                <div key={index}><b>{error.field}</b> <span>{error.desc}</span></div>)}
                              </div>}
                        </div>
                    </>
                }

                {!checkStatusBlock && isNotPaid &&
                    <div className="phoenixBox invoicePage__go">
                        <div>
                            <p>{localisationMaster('text-7-1', 'pays/invoice')} <strong>20 {localisationMaster('minutes', 'online-schedule/time-slots')}</strong> {localisationMaster('text-7-2', 'pays/invoice')}</p>
                        </div>
                        <div>
                            <button disabled={aError.length > 0}
                                    className={aError.length > 0 ? "disabled" : ""}
                                    onClick={handleSubmit}>
                                {localisationMaster('go_to_payment', 'pays/invoice')}
                            </button>
                        </div>
                    </div>}

                {checkStatusBlock && <CheckPayStatus orderID={props.match.params.id} closeOrder={() => setCheckStatusBlock(!checkStatusBlock)} isDisableCounter />}
            </div>
        </div>
    )
}
