import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { superAxiosRequest } from 'axiosApi';
import modalStore from "Stores/modalStore";
import MultiUploads from './templates/MultiUploads';
import ActionPicture from './templates/ActionPicture/ActionPicture';
import MainSelector from '../MainSelector';
import Textarea from 'components/ContentBox/Global/Textarea/Textarea'
//import AddSimpleMedia from '../AddSimpleMedia';
import VideoLinks from './templates/VIdeoLinks';
import MakeWordLetters from './templates/MakeWordLetters';
import MultiUploadAudio from './templates/MultiUploadAudio';
import WordMatching from './templates/WordMatching';
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import './LessonQuestion.scss';
import theme from 'components/ContentBox/img/theme.svg';
import ImageSelector from "components/ContentBox/Global/ImageSelector/ImageSelector";
import ExTest from "./templates/ExTest/ExTest";

const LessonsQuestion = props => {
    const { data: ms, changeValuesArray, check } = modalStore

    const [inputValues, setInputsValues] = useState({
        section: ms.section,
        condition_exercise: '',
        type_exercise: '',
    })

    ms.valid = {
        type_exercise: 'required',
        ...(inputValues.type_exercise !== 'ex_essay' && {condition_exercise: 'required'}),
        ...(inputValues.type_exercise === 'ex_link' && {link: 'required', anchor: 'required'})
    }

    const myChangeHandler = (e, state) => {
        if (ms.is_put) {
            let arr = inputValues
            arr.items[0][state] = e.target.value
            setInputsValues({ ...arr })
        } else {
            setInputsValues({ ...inputValues, [state]: e.target.value })
        }
    }

    const arrayChangeHandler = array => {
        /*let arr = {}
        let key, keyx;
        for (key in inputValues) {
            arr[key] = inputValues[key]
        }
        for (keyx in array) {
            arr[keyx] = array[keyx]
        }*/

        //setInputsValues({ ...arr })
        setInputsValues({ ...inputValues, ...array })
    }

    const objectChangeHandler = (value, key_name) => {
        let arr = inputValues;
        if (key_name === "text") {
            arr[key_name] = value.replaceAll(/\[(.+?)]/g, $0 => $0.replaceAll('\\', '|').replaceAll('/', '|'))
        } else {
            arr[key_name] = value
        }

        if (ms.is_put && (key_name === 'text' || key_name === 'description' || key_name === 'link')) {
            arr.items[0][key_name] = value
        }
        setInputsValues({ ...arr });
    }

    const exerciseText = <div>
        <p className="modalLessonQuestion__description">Текст упражнения</p>
        <Textarea
            stateName="text_exercise"
            value={inputValues.text_exercise}
            onChange={objectChangeHandler}
            className="modalLessonQuestion__exerciseText"/>
    </div>

    const exerciseBody = type => {
        switch (type) {
            case 'ex_image':
                return (
                    <>
                        {exerciseText}
                        <ActionPicture state={inputValues.show_like} objectChangeHandler={objectChangeHandler} />
                        <MultiUploads
                            textInfo='Вы можете загрузить изображение в формате JPG, GIF или PNG.'
                            inputsValues={inputValues}
                            update={ms.is_put}
                            arrayChangeHandler={arrayChangeHandler}
                            type="img"
                            accept=".jpg,.gif,.png,.jpeg"
                        />
                    </>
                )
            case 'ex_video':
                return (
                    <>
                        {exerciseText}
                        <MultiUploads
                            textInfo='Вы можете загрузить видео-файл в формате MP4, AVI или MKV.'
                            inputsValues={inputValues}
                            update={ms.is_put}
                            arrayChangeHandler={arrayChangeHandler}
                            type='video'
                            getFiles={props.getFiles}
                            accept=".mp4,.avi,.mkv"
                        />
                    </>
                )
            case 'ex_match_description_picture':
            case 'ex_gif':
                return (
                    <>
                        {exerciseText}
                        <MultiUploads
                            textInfo="Вы можете загрузить изображение в формате GIF."
                            inputsValues={inputValues}
                            arrayChangeHandler={arrayChangeHandler}
                            update={ms.is_put}
                            getFiles={props.getFiles}
                            type="img"
                            accept=".gif"
                        />
                    </>
                )
            case 'ex_phrases_in_blanks':
                return (
                    <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Текст упражнения</p>
                        <Textarea onChange={objectChangeHandler}
                            stateName="text"
                            update={ms.is_put}
                            value={ms.is_put ? inputValues.items[0].text : inputValues.text}
                        />
                        <div className="super-modal-notice">
                            <p>Напишите текст. Слова и фразы, которые нужно вставить из рамочки, заключите в квадратные скобки.</p>
                            <p><strong>Пример:</strong></p>
                            <p>I like [walking] in the park in the morning.</p>
                        </div>
                    </div>
                )
            case 'ex_make_sentence':
                return (
                    <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Текст</p>
                        <textarea onChange={e => myChangeHandler(e, 'value')}
                            value={ms.is_put ? inputValues.items[0].value : inputValues.value}
                            style={{ marginTop: '20px', resize: 'none' }} />

                        <div className="super-modal-notice">
                            <p>Напишите предложения и разделите слова вертикальной чертой. Слова автоматически перемешаются после сохранения.</p>
                            <br />
                            <p><strong>Пример:</strong></p>
                            <p>We|moved|to|California|last|summer</p>
                        </div>
                    </div>
                )
            case 'ex_correct_form':
                return (
                    <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Предложение</p>
                        <Textarea onChange={objectChangeHandler}
                            update={ms.is_put}
                            value={ms.is_put ? inputValues.items[0].text : inputValues.text}
                            stateName={'text'}/>
                        <div className="super-modal-notice">
                            <p>Запишите варианты ответа в квадратных скобках и отметьте правильный вариант звездочкой *.</p>
                            <br />
                            <p><strong>Пример:</strong></p>
                            <p>They [is\are\was\were*] on holiday yesterday</p>
                        </div>
                    </div>
                )
            case 'ex_words_in_blanks':
                return (
                    <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Текст</p>
                        <Textarea onChange={objectChangeHandler}
                            update={ms.is_put}
                            value={ms.is_put ? inputValues.items[0].text : inputValues.text}
                            stateName="text"/>
                        <div className="super-modal-notice">
                            <p>Записать в квадратных скобках слово (выражение) для переноса в пропуски</p>

                            <p><strong>"Заполнить пропуски":</strong> запишите в квадратных скобках только правильное
                                слово (выражение).</p>

                            <p><strong>"Заполнить пропуски любым текстом без проверки":</strong> просто
                                запишите пустые квадратные скобки на месте пропуска (без пробела).</p>

                            <p><strong>Пример:</strong><br />
                                1. I [to play|am playing] tennis.<br />
                                2. This flower is [better] than that.</p>
                        </div>
                    </div>
                )
            case 'ex_link':
                return (
                    <div className="modalLessonQuestion__exLink">
                        <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Текст упражнения</p>
                        <Textarea
                            stateName="description"
                            value={ms.is_put ? inputValues.items[0].description : inputValues.description}
                            onChange={objectChangeHandler}
                            //update={ms.is_put}
                        />
                        </div>

                        <div>
                            <input type="text" placeholder="Ссылка на материал"
                                value={ms.is_put ? inputValues.items[0].link : inputValues.link}
                                onChange={e => myChangeHandler(e, 'link')}
                                style={{borderColor: check('link') ? 'red' : ''}}
                                />
                        </div>

                        <div>
                            <input type="text" placeholder="Текст ссылки"
                                   value={ms.is_put ? inputValues.items[0].anchor : inputValues.anchor}
                                   onChange={e => myChangeHandler(e, 'anchor')} 
                                   style={{borderColor: check('anchor') ? 'red' : ''}}
                                   />
                        </div>
                    </div>
                )
            case 'ex_essay':
                return (
                    <>
                        <div>
                            <input type="text"
                                placeholder="Заголовок"
                                value={ms.is_put && inputValues.items[0] ? inputValues.items[0].title : inputValues.title}
                                onChange={e => myChangeHandler(e, 'title')} />
                        </div>
                        {/*<AddSimpleMedia objectChangeHandler={objectChangeHandler} values={inputValues.items} update={ms.is_put} />*/}
                        <ImageSelector onChange={image => objectChangeHandler(image, 'image')}/>
                    </>
                )
            case 'ex_video_link':
                return (
                    <>
                        {exerciseText}
                        <VideoLinks objectChangeHandler={objectChangeHandler} values={inputValues.items} update={ms.is_put} />
                    </>
                )
            case 'ex_text':
                return (
                    <div>
                        <p className="modalLessonQuestion__description modalLessonQuestion__descriptionExerciseText">Текст</p>
                        <Textarea onChange={objectChangeHandler}
                            update={ms.is_put}
                            value={ms.is_put ? inputValues.items[0].text : inputValues.text}
                            stateName={'text'}/>
                        <div className="super-modal-notice">
                            <p>Поставьте любой текст для отображения</p>
                        </div>
                    </div>
                )
            case 'ex_make_word_letters':
                return (
                    <>
                        <div className="super-modal-notice">
                            <p><strong>Слова</strong></p>
                            <p>Напишите слово или фразу. Можете также добавить подсказку для
                                учеников. Слова будут автоматически разбиты на буквы после сохранения.</p>
                        </div>
                        <MakeWordLetters objectChangeHandler={objectChangeHandler}
                            title="Слово"
                            update={ms.is_put}
                            values={inputValues.items}
                            placeholder={['Слово', 'Подсказка']}
                            max={4} />
                    </>
                )
            case 'ex_audio':
                return (
                    <>
                        {exerciseText}
                        <MultiUploadAudio arrayChangeHandler={arrayChangeHandler}
                            update={ms.is_put}
                            inputsValues={inputValues}
                            textInfo='Вы можете загрузить аудиофайл в формате MP3 или WAV.'
                        />
                    </>
                )
            case 'ex_sort_words_in_columns':
                return (
                    <>
                        <div className="super-modal-notice">
                            <p><strong>Колонки</strong></p>
                            <p>Напишите название колонки, а затем слова, которые к ней относятся.
                                Разделите слова прямой линией "|". Проделайте это для каждой колонки. Слова
                                автоматически перемешаются после сохранения упражнения.</p>
                            <br />
                            <p><strong>Внимание:</strong> максимально 4 колонки</p>
                        </div>
                        <MakeWordLetters objectChangeHandler={objectChangeHandler}
                            title="Колонка"
                            update={ms.is_put}
                            values={inputValues.items}
                            placeholder={['Слова', 'Подсказка']} max={4} />
                    </>
                )
            case 'ex_word_matching':
                return (
                    <>
                        <div className="super-modal-notice">
                            <p><strong>Слова для сопостовления</strong></p>
                            <p>Напишите начало и конец фразы (или пары слов) в одной строке.
                                После сохранения они автоматически перемешаются.</p>
                        </div>
                        <WordMatching objectChangeHandler={objectChangeHandler}
                            update={ms.is_put}
                            value={inputValues.items}
                        />
                    </>
                )
            case 'ex_attention':
                return (
                    <>
                        {exerciseText}
                        <MultiUploads
                            textInfo="Вы можете загрузить изображение в формате JPG, GIF или PNG."
                            inputsValues={inputValues}
                            update={ms.is_put}
                            arrayChangeHandler={arrayChangeHandler}
                            type="img"
                            types="jp"
                        />
                    </>
                )
            case 'ex_test':
                return <ExTest ex={inputValues} values={inputValues.items} onChange={objectChangeHandler} />
            default:
                return <div className="super-modal-probe"><p>Для начала выберите тип упражнения. Ознакомиться со всеми типами упражнений можно <a className="super-modal-probe__link">здесь</a>.</p></div>
        }
    }

    useEffect(() => {
        if (ms.is_put) {
            const apiConf = {
                method: 'get',
                link: ms.link
            }
            superAxiosRequest(apiConf).then(res => {
                let arr = {
                    section: res.data.section,
                    type_exercise: res.data.type_exercise,
                    condition_exercise: res.data.condition_exercise,
                    text_exercise: res.data.text_exercise ? res.data.text_exercise : '',
                    show_like: res.data.show_like,
                    items: res.data.items,
                    text: res.data.items[0] && res.data.items[0].text,
                }
                if (res.data.type_exercise !== 'ex_image') delete arr.show_like;
                if (res.data.items[0] && res.data.items[0].title)
                    arr.title = res.data.items[0].title
                setInputsValues({ ...arr })
            })
        }
    }, [])

    useEffect(() => {
        changeValuesArray(inputValues)
    }, [inputValues])

    return (
        <div className="modalLessonQuestion">
            <MainSelector image={theme}>
                <div>
                    <SelectCustom
                        placeholder="Выберите тип упражнения"
                        apiLink="lessons/type_exercise"
                        valueID={inputValues.type_exercise}
                        onChange={o => setInputsValues({ ...inputValues, type_exercise: o.value })}
                        isDisabled={ms.is_put}
                        error={check('type_exercise')}
                    />
                </div>
            </MainSelector>

            {inputValues.type_exercise &&
                <div>
                    <p className="modalLessonQuestion__description">Условие упражнения</p>
                    <Textarea
                        value={inputValues.condition_exercise}
                        onChange={objectChangeHandler}
                        stateName="condition_exercise"
                        error={check('condition_exercise')}
                    />
                </div>
            }
            {exerciseBody(inputValues.type_exercise)}

        </div>
    )
}

export default observer(LessonsQuestion)
