import React, {Fragment, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import {dateFormat} from "helpers/date";
import Stores from "Stores";
import groupStore from "Stores/groupStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";
import MoneyBox from "components/ContentBox/Global/MoneyBox/MoneyBox";
import DateTime from "components/ContentBox/Global/DateTime/DateTime";
import st from "./DayStatus.module.scss";
import asideModalStore from "Stores/asideModalStore";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import TextArea from "components/UI/TextArea/TextArea";
import FormField from "components/UI/AsideModal/Form/FormField";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";


const hourTypes = [
  {
    id: 1,
    name: "Академические",
    short_name: "ак",
    minutes: 45
  },
  {
    id: 2,
    name: "Астрономические",
    short_name: "астр",
    minutes: 60
  },
]

const getMinDate = (dates) => {
  const arr = [...dates]
  arr.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1)

  const date = new Date(arr[0])
  date.setDate(date.getDate() + 1)
  return date
}

const getDateByWeekdays = (workshop_id, workshops, dateFrom) => {
  // Находим воркшоп
  const workshop = workshops.find(wsh => wsh.id === workshop_id)
  // Находим воркшопы с такими же учителями и временем занятия
  const sameWorkshops = workshops.filter(wsh => (
    wsh.teachers.join() === workshop.teachers.join() &&
    wsh.start_at === workshop.start_at &&
    wsh.finish_at === workshop.finish_at
  ))
  // Находим дни недели этих воркшопов
  const aWeekdays = sameWorkshops.map(wsh => (new Date(`${wsh.date}T12:00:00`)).getDay() || 7)
  // Ищем дату, подходящую по дням недели, начиная с указанной
  do {
    dateFrom.setDate(dateFrom.getDate() + 1)
  } while (!aWeekdays.includes(dateFrom.getDay() || 7))

  return dateFormat(dateFrom, "YYYY-mm-dd")
}

const DayStatus = props => {

  const {modalSettings: ms, modalValues, changeValue, changeValues, check} = asideModalStore
  const {wsData: {workshops}, updateWSData, updateWSDays} = groupStore

  const [action, setAction] = useState(modalValues.status || 0)
  const [catalog, setCatalog] = useState({})

  // Цен пока нет
  const price = {
    "hours_per_day": 2,
    "daily_minutes": 90,
    "hour_type_id": 1,
    "price_per_minute": 0
  }

  const getSum = ratio => Math.round(ratio * price.daily_minutes * price.price_per_minute)

  const actionHandler = action => {
    const newValues = {
      workshop_id: modalValues.workshop_id,
      user_id: modalValues.user_id,
    }

    // Перенос или замена учителя
    if (action === 777 || action === 999){
      const firstDay = props.days[0]
      const startAt = firstDay.start_at
      const finishAt = firstDay.finish_at
      const diffMinutes =
        (new Date(`${firstDay.date}T${finishAt}`) - new Date(`${firstDay.date}T${startAt}`))
        / 1000 / 60

      newValues.hours_type = 1
      newValues.hours_per_day = 1
      newValues.start_at = startAt
      newValues.finish_at = finishAt
      newValues.teachers = []

      if (diffMinutes%45 === 0){
        newValues.hours_type = 1
        newValues.hours_per_day = diffMinutes / 45
      } else if (diffMinutes%60 === 0){
        newValues.hours_type = 2
        newValues.hours_per_day = diffMinutes / 60
      }

      // Если перенос, то указываем нужные даты, начиная с последней в расписании, с учетом дней недели
      if (action === 777) {
        const lastDate = new Date(props.lastDay.date)

        newValues.new_dates = Array.from(modalValues.workshop_id, wsh_id => getDateByWeekdays(
          wsh_id,
          workshops,
          lastDate
        ))
      }

      // Если Замена учителя, нужно его выбрать
      if (action === 999){
        fetchCatalog(startAt, finishAt) // запрос свободных преподавателей и аудиторий
        ms.valid = {
          teachers: "not_empty_array"
        }
      } else {
        ms.valid = {}
      }

      ms.link = action === 999 ? "schedule/ws_replacing_teachers" : "schedule/ws_transfer"
      ms.onSubmitSuccess = updateWSData
    } else {
      if (action === 1 || action === 4) {
        newValues.share_ratio = 0.5
        newValues.share_sum = getSum(newValues.share_ratio)
        newValues.teacher_debit_ratio = 0.5
        newValues.teacher_debit_sum = getSum(newValues.teacher_debit_ratio)
      } else if (action === 6) {
        newValues.fine = 0.5
        newValues.fine_sum = getSum(newValues.fine)
      }
      newValues.status = action

      ms.link = props.isStudent ? "schedule/ws_studentschooldaynote" : "schedule/ws_teacherschooldaynote"
      ms.valid = {}
      ms.onSubmitSuccess = data => updateWSDays(data.workshops)
    }

    // Уведомление для клиентов
    newValues.send_email = false

    changeValues(newValues)
    setAction(action)
  }

  const calculator = (val, key) => {
     // Базовая стоимость в копейках
    const full_price = price.daily_minutes * price.price_per_minute;

    let obj = {}, startAt, finishAt, minutes
    switch (key) {
      case 'share_ratio':
        obj.share_ratio = val
        obj.share_sum = getSum(obj.share_ratio)
        break
      case 'share_sum':
        obj.share_sum = val;
        obj.share_ratio = full_price ? +(val / full_price).toFixed(2) : 5
        break;
      case 'teacher_debit_sum':
        obj.teacher_debit_sum = val;
        obj.teacher_debit_ratio = full_price ? +(val / full_price).toFixed(2) : 5
        break
      case 'teacher_debit_ratio':
        obj.teacher_debit_ratio = val
        obj.teacher_debit_sum = getSum(obj.teacher_debit_ratio)
        break
      // Тип часов
      case 'hours_type':
        finishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${modalValues.start_at}`)
        finishAt.setMinutes(finishAt.getMinutes() + modalValues.hours_per_day * val.minutes)
        obj.hours_type = val.id
        obj.finish_at = dateFormat(finishAt, "hh:ii:ss")
        break;
      // Часов в день
      case 'hours_per_day':
        finishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${modalValues.start_at}`)
        minutes = hourTypes.find(ht => ht.id === modalValues.hours_type).minutes
        finishAt.setMinutes(finishAt.getMinutes() + val * minutes)
        obj.hours_per_day = val
        obj.finish_at = dateFormat(finishAt, "hh:ii:ss")
        break;
      case 'start_at':
        finishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${val}`)
        minutes = hourTypes.find(ht => ht.id === modalValues.hours_type).minutes
        finishAt.setMinutes(finishAt.getMinutes() + modalValues.hours_per_day * minutes)
        obj.start_at = val
        obj.finish_at = dateFormat(finishAt, "hh:ii:ss")
        break;
      case 'finish_at':
        startAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${val}`)
        minutes = hourTypes.find(ht => ht.id === modalValues.hours_type).minutes
        startAt.setMinutes(startAt.getMinutes() - modalValues.hours_per_day * minutes)
        obj.start_at = dateFormat(startAt, "hh:ii:ss")
        obj.finish_at = val
        break;
      default:
        break;
    }

    changeValues({
      ...modalValues,
      ...obj
    })
  }

  const fetchCatalog = (start_at, finish_at) => {
    superAxiosRequest({
      method: 'post',
      link: 'schedule/available_resources',
    }, {
      group_id: +props.groupID,
      start_at,
      finish_at,
      dates: props.days.map(d => d.date)
    }).then(res => {
      setCatalog({
        ...res.data,
        teachers: res.data.teachers.map(x => ({id: x.id, name: Stores.transliterate(x.full_name)}))
      })
    }).catch(() => {})
  }

  useEffect(() => {
    ms.valid = {
      action: () => action !== 0
    }
  }, [])

  useEffect(() => {
    if (action === 999) fetchCatalog(modalValues.start_at, modalValues.finish_at)
  }, [modalValues.start_at, modalValues.finish_at])

  return (
    <div className={st.formColumn}>
      <FormField>
        <FormLabel isRequired>Day Type:</FormLabel>
        <SelectCustom
          placeholder={localisationMaster('text_40', 'modal_templates')}
          options={props.statuses}
          valueID={action}
          onChange={o => actionHandler(o.id)}
          error={check('action')}
        />
      </FormField>
      {modalValues.workshop_id?.length > 1 &&
        <div className="super-modal__form-field">
          <div className="super-modal__form-label">Выбранные занятия</div>
          <div className={st.days}>{workshops.map(d => modalValues.workshop_id.includes(d.id) &&
            <div key={d.id} className={st.day}>{dateFormat(d.date, "dd.mm.YY")}</div>)}
          </div>
        </div>}

      {/* Частично оплачиваемое занятие || Частично оплачиваемый пропуск*/}
      {(action === 1 || action === 4) &&
        <>
          <FormField>
            <FormLabel isRequired>Charge Students:</FormLabel>
            <div className={st.sumContainer}>
              <div className={st.sumDymmyCounter}>
                <DummyCounter
                  name="share_ratio"
                  value={modalValues.share_ratio}
                  min={0.01}
                  max={5}
                  step={0.01}
                  onChange={calculator} fixed={2}/>
              </div>
              <div className={st.sumMoneyBox}>
                <MoneyBox
                  currency={'₸'}
                  offset={100}
                  changeHandler={calculator}
                  name="share_sum"
                  returnValue={() => modalValues.share_sum || ''}/>
              </div>
            </div>
          </FormField>

          {!props.isStudent &&
            <FormField>
              <FormLabel isRequired>Credit to teachers:</FormLabel>
              <div className={st.sumContainer}>
                <div className={st.sumDymmyCounter}>
                  <DummyCounter
                    value={modalValues.teacher_debit_ratio}
                    min={0.01}
                    max={5}
                    step={0.01}
                    name="teacher_debit_ratio"
                    onChange={calculator}
                    fixed={2}/>
                </div>
                <div className={st.sumMoneyBox}>
                  <MoneyBox
                    currency={'₸'}
                    offset={100}
                    changeHandler={calculator}
                    name="teacher_debit_sum"
                    returnValue={() => modalValues.teacher_debit_sum  || ''}/>
                </div>
              </div>
            </FormField>
          }
        </>}

      {/*Перенос занятия и замена учителя*/}
      {(action === 777 || action === 999) &&
        <>
          {action === 777 && props.days.map((d, idx) => (
            <Fragment key={idx}>
              <FormDateSelector
                  selected={new Date(d.date)}
                  placeholder="Select Date"
                  isRequired
                  disabled
                  label="Transfer Day:"
                  onChange={() => {}}
              />
              <FormDateSelector
                placeholder="Select Date"
                isRequired
                dateFormat="yyyy-MM-dd"
                selected={new Date(modalValues.new_dates[idx] || d.date)}
                button={{name: "Reset", action: "reset"}}
                label="On the Day of Transfer:"
                minDate={idx === 0 ? undefined : getMinDate(modalValues.new_dates)}
                value={modalValues.date_until || ''}
                error={check("new_dates")}
                onChange={val => {
                  changeValue(modalValues.new_dates.map((x, i) => i === idx ? val : x), 'new_dates')
                }}
              />
            </Fragment>
            ))}
          <div className={st.flex}>
            <FormField>
              <FormLabel isRequired>Hours Type:</FormLabel>
              <SelectCustom
                placeholder="Тип часов"
                options={hourTypes}
                sortKey="name"
                valueID={modalValues.hours_type}
                onChange={o => calculator(o, 'hours_type')}/>
            </FormField>

            <FormField>
              <FormLabel isRequired>Total per Day:</FormLabel>
              <DummyCounter
                value={modalValues.hours_per_day}
                min={.5}
                max={8}
                step={.5}
                name="hours_per_day"
                onChange={calculator}
                fixed={2}/>
            </FormField>

            <FormField>
              <FormLabel isRequired>Start & Finish Time:</FormLabel>
              <DateTime
                placeholder='9:00'
                start_at={modalValues.start_at}
                finish_at={modalValues.finish_at}
                objectChangeHandler={calculator}/>
            </FormField>
          </div>
          {action === 999 &&
            <div className={st.teachersClassrooms}>
              <FormField>
                <FormLabel>Teacher:</FormLabel>
                <SelectCustom
                  placeholder={localisationMaster('text_44', 'modal_templates')}
                  options={catalog.teachers || []}
                  sortKey="name"
                  valueID={modalValues.teachers?.[0] || null}
                  onChange={o => changeValue([o.id], 'teachers')}
                  isLoading={!catalog.teachers}
                  isSearchable
                  error={action === 999 && check('teachers')}
                />
              </FormField>

              <FormField>
                <FormLabel>Auditory:</FormLabel>
                <SelectCustom
                  placeholder={localisationMaster('text_45', 'modal_templates')}
                  options={catalog.classrooms || []}
                  sortKey="name"
                  valueID={modalValues.classroom}
                  onChange={o => changeValue(o.id, 'classroom')}
                  isLoading={!catalog.classrooms}/>
              </FormField>
            </div>}
        </>}

      <div>
        <FormField>
          <FormLabel>Comment:</FormLabel>
          <TextArea
            onChange={e => {
              changeValue(e.target.value, 'comment')}
          }
            withLabel={false}
            label={''}
            value={modalValues.comment || ''}
            placeholder='Comment'
            isResize={true}
            name='comment'
          />
        </FormField>
      </div>

      <div>
        <ToggleCheckbox
          label={props.isStudent ?
            localisationMaster('notify_client', 'groups/group', 'time_table_day') :
            localisationMaster('notify_clients_teachers', 'groups/group', 'time_table_day')}
          checked={modalValues.send_email || false}
          onChange={() => changeValue(!modalValues.send_email, "send_email")}
        />
      </div>
    </div>
  )
}

export default observer(DayStatus)
