import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {observer} from "mobx-react";
import {translate as loc, dict} from "Localisation";
import Stores from "Stores";
import asideModalStore from "Stores/asideModalStore";
import InputInn from "components/Modules/Auth/SignUp/InputInn/InputInn";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import FormFieldset from "../Form/FormFieldset";
import FormField from "../Form/FormField";
import FormLabel from "../Form/FormLabel";
import FormInput from "../Form/FormInput";
import InputTelegram from 'components/UI/InputTelegram/InputTelegram';
import BirthdaySelect from 'components/Modules/Auth/SignUp/BirthdaySelect/BirthdaySelect';
import useLocalization from "../../../../hooks/useLocalization";





const CustomerData = observer(() => {
  const {modalValues, changeValue, check} = asideModalStore;
    const {translate} = useLocalization({
        section: 'profile',
        subsection: 'personal_info_modal',
        dict: {
            "surname" : "Surname",
            "name" : "Name",
            "middle_name" : "Middle name",
            "date_of_birth" : "Date of Birth",
            "iin_inn" : "IIN / INN",
            "iin" : "iin",
            "inn" : "inn",
            "phone" : "Phone",
            "mobile_phone" : "Mobile phone",
            "telegram" : "Telegram",

    }
    })

    const innOptions = [
        {id: 1, name: translate.iin},
        {id: 2, name: translate.inn},
    ]

  return (
    <FormFieldset>
      <FormField>
        <FormLabel isRequired>{translate.surname}:</FormLabel>
        <FormInput isError={check('last_name')}>
          <input
            onChange={e => changeValue(e.target.value, 'last_name')}
            placeholder={translate.surname}
            value={modalValues.last_name}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel isRequired>{translate.name}:</FormLabel>
        <FormInput isError={check('first_name')}>
          <input
            placeholder={translate.name}
            onChange={(e) => changeValue(e.target.value, 'first_name')}
            value={modalValues.first_name}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel>{translate.middle_name}:</FormLabel>
        <FormInput>
          <input
            onChange={e => changeValue(e.target.value, 'father_name')}
            placeholder={translate.middle_name}
            value={modalValues.father_name}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel isRequired>{translate.date_of_birth}:</FormLabel>
        <FormInput>
          <BirthdaySelect
            error={check('date_of_birth')}
            value={modalValues.date_of_birth}
            onChange={sDate => changeValue(sDate, 'date_of_birth')}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel isRequired>{translate.iin_inn}</FormLabel>
        <div>
          <InputInn
            error={check('inn')}
            value={modalValues.inn}
            options={innOptions}
            onChange={val => changeValue(val, 'inn')}
          />
        </div>
      </FormField>
      <FormField>
        <FormLabel isRequired>E-mail:</FormLabel>
        <FormInput isError={check('email')}>
          <input
            disabled={!Stores.isAdmin}
            onChange={e => changeValue(e.target.value, 'email')}
            placeholder="E-mail"
            value={modalValues.email}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel isRequired>{translate.phone}:</FormLabel>
        <FormInput>
          <InputFlagPhone
            error={check('phone')}
            value={modalValues.phone || ''}
            onChange={val => changeValue(val, 'phone')}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel>{translate.mobile_phone}:</FormLabel>
        <FormInput>
          <InputFlagPhone
            error={check('mobile_phone')}
            value={modalValues.mobile_phone || ''}
            onChange={val => changeValue(val, 'mobile_phone')}
          />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel>Whatsapp:</FormLabel>
        <FormInput>
        <InputFlagPhone
            value={modalValues.whatsapp || ''}
            onChange={val => changeValue(val, 'whatsapp')}
        />
        </FormInput>
      </FormField>
      <FormField>
        <FormLabel>{translate.telegram}:</FormLabel>
        <div>
          <InputTelegram
            placeholder={loc(dict.main_page.telegram)}
            onChange={value => changeValue(value, 'telegram')}
            value={modalValues.telegram || ''}
          />
        </div>
      </FormField>
    </FormFieldset>
  )
})

export default CustomerData
