import React from 'react';
import {classNames as cn} from "helpers";
import './Checkbox.scss';

export default function Checkbox(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>
                <symbol id="checkmark" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1">
                    </path>
                </symbol>
            </svg>
            <div className={`form-container ${props.extraClass || ''}`}>
                <div 
                    className={cn({
                        "promoted-checkbox": true,
                        "promoted-checkbox--error": props.error
                    })}
                >
                    <input id={props.id ? props.id : "tmp"}
                           type="checkbox"
                           className="promoted-input-checkbox"
                           checked={props.checked}
                           disabled={props.isDisabled}
                           onChange={(e) => props.isChecked(e, props.key_name)}
                    />
                    <label htmlFor={props.id ? props.id : "tmp"}>
                        <svg>
                            <use xlinkHref="#checkmark"/>
                        </svg>
                        {props.label}
                    </label>
                </div>
            </div>
        </div>
    )
}
