import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/UI/Flex/Flex';
import Input from 'components/UI/Input/Input';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';
import styles from './TimeSlots.module.scss';
import { getAdaptedTime } from 'components/Modules/Slots/utils';

function TimeSlot({start, end, count}) {
  const { setSlotCount, selectedLocation } = slotsScheduleSettingsStore;
  const shiftedStart = getAdaptedTime(start, selectedLocation.time_zone);
  const shiftedEnd = getAdaptedTime(end, selectedLocation.time_zone);

  const handleInputChange = (evt) => {
    const value = +evt.target.value.replace(/\D/g, '');
    setSlotCount(value, start);
  };
  return (
    <Flex tag='li' classes={styles.slot_wrapper}>
      <span className={styles.time}>
        {`${shiftedStart} - ${shiftedEnd}`}
      </span>
      <Input
        label='Число учителей на слот'
        pattern='[0-9]*'
        inputMode='numeric'
        placeholder='0'
        value={count.toString()}
        onChange={handleInputChange}
        withLabel={false}
        classes={styles.input}
        isRequired={false}
      />
    </Flex>
  );
}

TimeSlot.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  count : PropTypes.number.isRequired,
};

export default TimeSlot;

