import React from 'react';
import { observer } from 'mobx-react';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import SelectorsLayout from 'components/Modules/Slots/ScheduleSettings/layouts/SelectorsLayout';
import ContentLayout from 'components/Modules/Slots/ScheduleSettings/layouts/ContentLayout';
import Flex from 'components/UI/Flex/Flex';
import slotsScheduleSettingsStore from 'Stores/slotsScheduleSettingsStore';
import styles from './ScheduleSettings.module.scss';
import { translate as loc, dict } from "Localisation";

function ScheduleSettings() {
  const { selectedLocation } = slotsScheduleSettingsStore;

  return (
    <>
      <ContentHeader title={loc(dict.schedule_settings.setting_the_slot)} />
      <Flex withGap justify='start' classes={styles.wrapper}>
        <SelectorsLayout />
        { selectedLocation !== null && <ContentLayout /> }
      </Flex>
    </>
  );
}

export default observer(ScheduleSettings);
