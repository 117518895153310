import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {LessonContext} from "../BookChapter/LessonContext";
import LessonToolbar from "../LessonStudent/LessonToolbar";
import BookPaper from "../Common/BookPaper/BookPaper";
import LessonHomeworkHeader from "../Common/LessonHomeworkHeader/LessonHomeworkHeader";
import Exercise from "../Common/Exercise/Exercise";
import WithoutExercises from "../Common/WithoutExercises/WithoutExercises";
import st from "../LessonStudent/LessonStudent.module.scss";
import lessonStudentStore from "../LessonStudent/lessonStudentStore";
import {observer} from "mobx-react";

const StudentFavorites = () => {
  const {id} = useParams()
  const {fetchStudentFavorites, lesson} = lessonStudentStore
  const context = {mode: 'student'}

  useEffect(() => {
    fetchStudentFavorites(id)
  }, [fetchStudentFavorites, id])

  return (lesson ?
    <div className={st.lessonStudent}>
      <LessonToolbar withoutButtons />
      <div className={st.bookContainer}>
        <BookPaper>
          <div className={st.bookColumn}>
            <LessonHomeworkHeader title="Favorites" ebookName={lesson.ebook_name}/>
            {lesson.exercises.length > 0 ?
              <LessonContext.Provider value={context}>
                {lesson.exercises?.map((ex, index) => <Exercise key={ex.id} number={`1.${index + 1}`} ex={ex}/>)}
              </LessonContext.Provider> :
              <WithoutExercises
                text1="Favorites are empty :("
                text2="You have not yet added any exercises to your favorites. After adding exercises to your favorites, you can always find them on this page and return to their repetition."/>
            }
          </div>
        </BookPaper>
      </div>
    </div> : null
  )
}

export default observer(StudentFavorites);
