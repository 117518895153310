import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import Flex from 'components/UI/Flex/Flex';
import CalendarWrapper from 'components/Modules/Slots/AdvancedTeachersHistory/components/CalendarWrapper/CalendarWrapper';
import slotsAdvancedTeachersHistoryStore, { ActionType } from 'Stores/slotsAdvancedTeachersHistoryStore';
import CompanyLocationSelects from '../components/CompanyLocationSelects/CompanyLocationSelects';
import SlotColorTypes from 'components/Modules/Slots/components/SlotColorTypes/SlotColorTypes';
import Loader from "components/ContentBox/Global/Loader/Loader";
import CustomerList from "components/Modules/Slots/AdvancedTeachersHistory/components/CustomerList/CustomerList";
import styles from '../BookedSchedule/BookedSchedule.module.scss';

const AdvancedTeachersHistory = () => {
  const {
    companiesList,
    locations,
    selectedLocation,
    selectedCompany,
    setData,
    customers,
    slotTypes,
    resetData,
    selectedDates,
    isCustomersLoading,
  } = slotsAdvancedTeachersHistoryStore;

  useEffect(() => resetData(ActionType.resetCalendar), []);

  return (
    <>
      <ContentHeader title='Расписание преподователей' />
      <Flex withGap classes={styles.wrapper}>
        <div className={styles.selectors_wrapper}>
          <CompanyLocationSelects
            companiesList={companiesList}
            locationsList={locations}
            selectedLocation={selectedLocation}
            selectedCompany={selectedCompany}
            onResetData={resetData}
            onSetData={setData}
          />
        </div>
        {selectedLocation && selectedCompany && <CalendarWrapper classes={styles.calendar_wrapper} />}
        {selectedDates.size > 0 &&
          <>
            {isCustomersLoading
              ? <div className={styles.loader}><Loader /></div>
              : customers.length > 0
                ? (
                  <>
                    <CustomerList list={customers} />
                    <div className={styles.selectors_wrapper}>
                      <SlotColorTypes
                        types={slotTypes || []}
                        withIcon={true}
                      />
                    </div>
                  </>
                )
                : <h1 className={styles.calendar_wrapper}>Забронированных слотов сотрудниками на выбранную дату нет</h1>
            }
          </>
        }
      </Flex>
    </>
  );
}

export default observer(AdvancedTeachersHistory);



