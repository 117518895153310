import React, {useEffect, useRef, useState} from "react"
import ReactDOM from "react-dom";
import modalStore from "Stores/modalStore";
import {dateFormat, scheduleDays} from "helpers/date";
import st from "./DayTooltip.module.scss";

export default function DayTooltip(props){
  const {modalValues} = modalStore

  const [pos, setPos] = useState({left: 0, top: 0})

  const ref = useRef()

  useEffect(() => {
    const rectTarget = props.target.getBoundingClientRect()
    const rectTooltip = ref.current.getBoundingClientRect()
    const top = rectTarget.y + rectTarget.height + 10
    const left = rectTarget.x - rectTooltip.width/2 + rectTarget.width/2
    setPos({left, top})
  }, [])

  const getTeachers = () => {
    const day = modalValues.dates?.find(d => d.date === dateFormat(props.date, "YYYY-mm-dd"))
    return day?.teachers || modalValues.teachers
  }

  const getClassroom = () => {
    const day = modalValues.dates?.find(d => d.date === dateFormat(props.date, "YYYY-mm-dd"))
    return day?.classroom_id || modalValues.classroom_id
  }

  return ReactDOM.createPortal (
    <div className={`${st.tooltip} ${pos.left && st.tooltip__show}`}
         style={{
           left: `${pos.left}px`,
           top: `${pos.top}px`
         }}
         ref={ref}>
      <span>{dateFormat(props.date, "dd MMM YYYY")} ({scheduleDays([props.date.getDay() || 7])})</span>
      <i>{`${modalValues.start_at} - ${modalValues.finish_at}`}</i>
      {getTeachers().map(tch => (
        <i key={tch}>{props.teachers.find(t => t.id === tch)?.full_name}</i>
      ))}
      {getClassroom() &&
        <i>Аудитория: {props.classrooms?.find(cl => cl.id === getClassroom())?.name}</i>}
    </div>, document.getElementById('portal')
  )
}
