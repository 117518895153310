import React from 'react';
import {observer} from "mobx-react";
import {splitNumberDigits} from "helpers/math";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import {localisationMaster} from "Localisation/Localisation";
import st from "./DiscountMarkup.module.css";
import asideModalStore from "Stores/asideModalStore";
import FormDateSelector from "components/UI/AsideModal/Form/FormDateSelector";
import {dateFormat} from "helpers/date";
import ToggleCheckbox from "components/UI/ToggleCheckbox/ToggleCheckbox";
import FormLabel from "components/UI/AsideModal/Form/FormLabel";
import FormField from "components/UI/AsideModal/Form/FormField";

const StudentPrice = () => {
    const {modalValues, changeValue, check} = asideModalStore

    const formatOptionLabel = (opt, {context}) => {
        const str =
            `${opt.name} ${context === 'menu' ? '<br/>' : ''}(<b>${splitNumberDigits(opt.price_sum)}.00</b>тг. за ${opt.hours_sum} ${opt.hours_type.id === 1 ? localisationMaster('ac_h', 'groups/group') : localisationMaster('astr_h', 'groups/group')})`

        return <span dangerouslySetInnerHTML={{__html: str}}/>
    }

    return (
        <div className={st.form}>
            <FormField>
                <FormLabel isRequired>Price Type:</FormLabel>
                <SelectCustom
                    placeholder= {localisationMaster('text_7', 'groups/group')}
                    apiLink="groups/price_sums"
                    apiConfParams={{limit: 999}}
                    sortKey="name"
                    getOptionLabel={opt => `${opt.name}  (${opt.price_sum}.00тг. за ${opt.hours_sum} ${opt.hours_type.id === 1 ? localisationMaster('ac_h', 'groups/group') : localisationMaster('astr_h', 'groups/group')})`}
                    formatOptionLabel={formatOptionLabel}
                    valueID={modalValues.default_price_id || null}
                    onChange={o => changeValue(o.id, 'default_price_id')}
                    isSearchable
                    error={check('default_price_id')}
                />
            </FormField>
            <FormDateSelector
                placeholder="Start Date"
                isRequired
                button={{name: "Today", action: "today"}}
                label="Start Date"
                onChange={val => changeValue(dateFormat(val, "YYYY-mm-dd"), "date_from")}
                value={modalValues.date_from || ''}
                error={check("date_from")}
            />
            <FormDateSelector
                placeholder="End Date"
                isRequired={false}
                button={{name: "Reset", action: "reset"}}
                label="End Date"
                onChange={val =>  changeValue( val ? dateFormat(val, "YYYY-mm-dd") : null, "date_until")}
                minDate={new Date(modalValues.date_from)}
                value={modalValues.date_until || ''}
                error={check("date_until")}
            />
          <div>
              <ToggleCheckbox
                  label="Send notification"
                  checked={modalValues.send_email}
                  onChange={() => changeValue(!modalValues.send_email, "send_email")}
              />
          </div>
        </div>
    )
}

export default observer(StudentPrice)
