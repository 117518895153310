import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import st from "./SuperButton.module.scss";
import {ReactComponent as CrossIcon} from "components/ContentBox/img/crossIcon.svg";
import {ReactComponent as EditIcon} from "components/UI/icons/edit-line.svg";
import {ReactComponent as ExternalLinkIcon} from "./img/external-link-line.svg";
import {ReactComponent as CloseIcon} from "./img/close.svg";
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";

export const SuperButton = (props) => {
  const {onClick ,callback, variant, type, path, children, isShowCross= true, className = ''} = props;
  const [showIcon, setShowIcon] = useState(isShowCross);

  const handleButtonClick = () => {
    if (variant === "filter") {
      callback();
    }
    setShowIcon(!showIcon);
  };

  return (
    <>
      {variant === "filter" ? (
        <button className={`${st.button} ${st.filter} ${type ? st[type] : ""} ${className}`} onClick={handleButtonClick}>
          {children}
          {showIcon && variant === "filter" && (
            <CrossIcon className={st.iconImage}/>
          )}
        </button>
      ) : variant === "badge" ? (
        <span className={`${st.badge} ${type ? st[type] : ""} ${className}`}>
          {children}
        </span>
      ) : variant === "confirm" ? (
            <button className={`${st.button} ${st.confirm} ${type ? st[type] : ""} ${className}`}
              onClick={onClick}>
          {children}
        </button>
      ) : variant === "reset" ? (
              <button className={`${st.button} ${st.reset} ${type ? st[type] : ""} ${className}`}
                onClick={onClick}>
          {children}
        </button>
      ) 
      : variant === "link" ? (
        <Link className={`${st.link} ${type ? st[type] : ""} ${className}`} to={path}>
          {children}
          {/*<ArrowIcon className={styles.iconImage}/>*/}
          <span className={st.arrow}>&rarr;</span>
        </Link>
      )
       : variant === "redirect" ? (
          <a className={`${st.link} ${type ? st[type] : ""} ${className}`} href={path} target="_blank" rel="noreferrer">
             {children}
             <span className={st.arrow}>&rarr;</span>
          </a>
          )
       : (<></>)
      }
    </>
  );
}

const GoToLink = props => <Link to={props.to || ''} target={props.target} className={st.goToLink} onClick={e => {
  if(props.onClick) {
    e.preventDefault()
    props.onClick()
  }
}}><ExternalLinkIcon/></Link>
GoToLink.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func
}

const EditIconButton = props =>
  <span className={st.goToLink} onClick={props.onClick}>
    <EditIcon/>
  </span>
EditIconButton.propTypes = {
  onClick: PropTypes.func
}

const CloseButton = ({onClick}) => <div className={st.closeButton} onClick={onClick}><CloseIcon/></div>
CloseButton.propTypes = {
  onClick: PropTypes.func
}

const HeaderAddButton = props =>
  <button className={st.headerAddButton} onClick={props.onClick}>
    <PlusIcon/><span>{props.title}</span>
  </button>
HeaderAddButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export {GoToLink, CloseButton, EditIconButton, HeaderAddButton}
