export const calcSlotsData = (dates) => {
  const all = { time: 0, count: 0 };
  const availableForClients = {time: 0, count: 0};
  const booked = {time: 0, count: 0};
  const missed = {time: 0, count: 0};
  const deleted = {time: 0, count: 0};
  const worked = {time: 0, count: 0};
  const slotType = new Map();

  dates.forEach((date) => {
    date.employee_booked_slots.forEach((slot) => {
      const startMin = +slot.start_at.split(':')[0] * 60 + +slot.start_at.split(':')[1];
      const endMin = +slot.finish_at.split(':')[0] * 60 + +slot.finish_at.split(':')[1];
      const resultTime = endMin - startMin;
      all.time += resultTime;
      all.count += 1;

      if (slot.is_accessible) {
        availableForClients.time += resultTime;
        availableForClients.count += 1;
      }

      if (!slot.is_active) {
        deleted.time += resultTime;
        deleted.count += 1;
      }

      if (slotType.has(slot.type_name)) {
        slotType.get(slot.type_name).time += resultTime;
        slotType.get(slot.type_name).count += 1;
      } else {
        slotType.set(slot.type_name, {time: 0, count: 0});
      }

      if (slot.slot_client?.length > 0) {
        booked.time += resultTime;
        booked.count += 1;

        let isMissedNeedToIncrease = true;
        let isWorkedNeedToIncrease = true;
        for (let i in slot.slot_client) {
          if (slot.slot_client[i].is_missed) {
            if (isMissedNeedToIncrease) {
              missed.time += resultTime;
              missed.count += 1;
            } else {
              isMissedNeedToIncrease = false;
            }
          }

          if (slot.slot_client[i].training_level_id) {
            if (isWorkedNeedToIncrease) {
              worked.time += resultTime;
              worked.count += 1;
            } else {
              isWorkedNeedToIncrease = false;
            }
          }
        }
      }
    })
  })

  return {
    all,
    availableForClients,
    booked,
    missed,
    worked,
    deleted,
    slotTypes: [...slotType.entries()],
  };
};
