import React from 'react';
import PropTypes from 'prop-types';
import { localisationMaster } from "Localisation/Localisation";
import { ReactComponent as InfoSVG } from 'components/ContentBox/img/information.svg';
import { classNames as cn } from 'helpers';
import styles from './SlotColorTypes.module.scss';

const slotTypesDefault = [
  {id: Date.now() + 2, name: localisationMaster('delete_slot', 'teacher_schedule'), color: '#ffdae7'},
  {id: Date.now() + 3, name: localisationMaster('registered_client', 'teacher_schedule'), color: '#b1f5cf'},
];

function SlotColorTypes({types, withIcon, classes}) {
  const allSlotTypes = [...types, ...slotTypesDefault];

  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.with_icon]: withIcon,
        [classes]: classes,
      })}
    >
      {withIcon && <div className={styles.info_icon}><InfoSVG /></div>}

      <ul className={styles.color_list}>
        {allSlotTypes.map(({ id, name, color }) => (
          <li className={styles.color_item} key={id.toString()}>
            <span className={styles.color} style={{backgroundColor: color}}/>
            <span className={styles.color_label}>{name}</span>
          </li>
        ))}
      </ul>

    </div>
  );
}

SlotColorTypes.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  withIcon: PropTypes.bool,
  classes: PropTypes.string,
};

SlotColorTypes.defaultProps = {
  withIcon: false,
  types: [],
  classes: '',
};

export default SlotColorTypes
