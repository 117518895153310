import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {superAxiosRequest} from "axiosApi";
import {listingCardSettings} from './listingCardsSettings';
import Stores from 'Stores';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import MainTable from '../MainTable/MainTable';


const ListingCards = props => {
    const id = +props.match.params.id
    const {folder, link} = props.match.params

    const arr = listingCardSettings().find(item => item['link'] === link && item['folder'] === folder)

    const [title, setTitle] = useState(arr.title)

    function getTitles(type, all) {
        if (arr) {
            if (all) {
                return arr
            } else {
                if (!Stores.clean) {
                    Stores.clean = true;
                }
                if (type === 'headerLinkButton' && arr.headerLinkButton) {
                    if (arr.headerLinkButton?.id) {
                        if (id) {
                            arr.headerLinkButton.link = arr.headerLinkButton.link.replace(':id', String(id))
                            return arr.headerLinkButton
                        }
                        return false;
                    }
                    return arr.headerLinkButton
                } else {
                    return arr[type]
                }
            }
        } else {
            Stores.error = true;
            Stores.errorTxt = "Запрашиваемая страница отсутствует."
        }
    }

    useEffect(() => {
        if (arr.sec_title && id) {
            superAxiosRequest({
                method: 'get',
                link: arr.sec_title.link + id
            }).then(res =>
                setTitle(`${arr.title}: ${res.data[arr.sec_title.field]}`)
            );
        }
        setTitle(arr.title)
    }, [id, arr.title, arr.sec_title])

    return (
        Stores.error ?
            <div className="content-box-none">
                Нет данных для загрузки или произошла ошибка: <br/>
                {Stores.errorTxt}
            </div>
            :
            <>
                <ContentHeader title={title}
                               button={getTitles('button')}
                               modal_type={getTitles('modal_type')}
                               modalValues={getTitles('modalValues')}
                               modal={[
                                   getTitles('button'),
                                   link,
                                   'add',
                                   getTitles('modal_placeholder'),
                                   getTitles('colsSettings')
                               ]}
                               breadCrumbs={getTitles('breadCrumbs')}
                               modal_settings={getTitles('modal_settings')}
                               link_id={id}
                               linkButton={getTitles('headerLinkButton')}
                />
                <MainTable data={getTitles('all', true)}
                           thisPage={`${folder}/${link}`}
                           config={getTitles('config')}
                           settings={getTitles('folder')}
                           id={id}
                           filter={getTitles('filter')}
                           filterLiner={getTitles('filterLiner')}
                           filterLinerLink={getTitles('filterLinerLink')}
                           pays={getTitles('pays')}
                           surfMenu={getTitles('surfMenu')}
                           paysButton={getTitles('paysButton')}
                           multitype={getTitles('multitype')}
                           errorText={getTitles('errorText')}
                           mulitype_values={getTitles('mulitype_values')}
                           paysScore={getTitles('paysScore')}
                           filterLink={getTitles('filterLink')}
                           filter_by_id={getTitles('filter_by_id')}
                           apiParams={getTitles('apiParams')}
                           apiSettings={getTitles('apiSettings')}
                />
            </>
    )
}

export default observer(ListingCards)
