import React, {createRef, useEffect, useState} from 'react';
import {ReactSketchCanvas} from 'react-sketch-canvas';
import Stores from 'Stores';
import FileSelectModal from 'components/UI/FileSelector/components/FileSelectModal';
import styles from './MainExerciseImage.module.scss';
import {ReactComponent as PencilIcon} from 'components/UI/icons/pencil-line.svg';
import {ReactComponent as GoBackIcon} from 'components/UI/icons/arrow-go-back-line.svg';
import {ReactComponent as FullImageIcon} from 'components/UI/icons/list-search-plus.svg';
import {ReactComponent as Pencil} from 'components/UI/icons/pencil-line.svg';
import {ReactComponent as Eraser} from 'components/UI/icons/eraser-line.svg';
import {ReactComponent as Delete} from 'components/UI/icons/delete-bin-6-line.svg';
import HoverPreviewPanel from 'components/ContentBox/Lessons/components/HoverPreviewPanel/HoverPreviewPanel';

export const MainExerciseImage = ({image, exID, ...props}) => {
  const canvasRef = createRef();
  const [penColor, setPenColor] = useState('#55B1F3');
  const [penWidth, setPenWidth] = useState(5);
  const [showTools, setShowTools] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tool, setTool] = useState("pencil");

  const handleUpdate = () => {
    if (canvasRef.current) {
      canvasRef.current
        .exportSvg()
        .then((data) => {
          props.setMainImage?.(data)
        })
        .catch(() => {
        });
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.resetCanvas();
    }
  }, [props.try])

  const colours = [
    {id: 1, val: '#FFC700'},
    {id: 2, val: '#1ABC97'},
    {id: 3, val: '#1BE7FF'},
    {id: 4, val: '#FF0022'},
    {id: 5, val: '#C903FF'},
    {id: 6, val: '#55B1F3'},
  ];

  useEffect(() => {
    setSelectedImage(image);
  }, [image]);

  const handleEyeClick = (imageFile) => {
    setShowModal(true);
    setSelectedImage(imageFile);
  };

  const handleClick = (...args) => {
    const isEraser = args[0] || false;
    canvasRef.current?.eraseMode(isEraser);
    setShowTools(true);
    const tool = args[1] || "pencil";
    setTool(tool)
  };

  const onBlurHandler = () => {
    setShowTools(false);
  };

  const handlePencilClick = () => {
    handleClick(false);
    setShowColorPicker(false);
    onBlurHandler();
  };

  const handleEraserClick = () => {
    canvasRef.current?.eraseMode(true);
    setTool("eraser")
    setShowColorPicker(false);
    onBlurHandler();
  };

  const handleRemoveAllClick = () => {
    canvasRef.current.resetCanvas();
    canvasRef.current?.eraseMode(false);
    setShowColorPicker(false);
    setTool("pencil")
    onBlurHandler();
  };

  const handleColorChange = (color) => {
    setPenColor(color.val);
    setTool("pencil")
    setShowTools(false);
    setShowColorPicker(false);
    canvasRef.current?.eraseMode(false);
  };

  const onClickShowColorPickerHandler = () => {
    setShowColorPicker(prevState => !prevState);
  }

  const onClickCanvasHandler = () => {
    onBlurHandler();
    setShowColorPicker(false);
  };

  const renderColorPicker = () => (
    <div className={styles.colorPickerContainer}>
      {colours.map((color) => (
        <div
          key={color.id}
          className={styles.boxBorderCircle}
          onClick={() => handleColorChange(color)}
        >
          <div className={styles.circle} style={{backgroundColor: color.val}}></div>
        </div>
      ))}
    </div>
  );

  const renderToolsPanel = () => (
    <div className={styles.toolsPanel}>
      <div className={styles.panelContainer}>
        <div onClick={onClickShowColorPickerHandler}>
          <div className={styles.circle} style={{backgroundColor: penColor}}></div>
        </div>
        <div className={styles.verticalSeparator}></div>
        <div className={styles.inputRange}>
          <input
            type="range"
            min="0"
            max="50"
            value={penWidth}
            onChange={(e) => setPenWidth(Number(e.target.value))}
          />
          <div>{penWidth}</div>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div onClick={handlePencilClick} className={styles.tool}>
        <Pencil/>
        <div>Pencil</div>
      </div>
      <div className={styles.separator}></div>
      <div onClick={handleEraserClick} className={styles.tool}>
        <Eraser/>
        <div>Eraser</div>
      </div>
      <div onClick={handleRemoveAllClick} className={styles.tool}>
        <Delete/>
        <div>Clear All</div>
      </div>
    </div>
  );

  const renderEditBlock = () => (
    <div className={styles.editBlock}>
      <div>
        {tool === "pencil" ?
          <PencilIcon
            onClick={() => handleClick(false, "pencil")}
            onBlur={() => onBlurHandler()}/>
          : <Eraser
            onClick={() => handleClick(true, "eraser")}
            onBlur={() => onBlurHandler()}/>
        }
      </div>
      <div className={styles.verticalSeparator}></div>
      <GoBackIcon onClick={() => canvasRef.current.undo()}/>
    </div>
  );

  return (
    <div
      className={styles.MainExerciseImage}
      onMouseLeave={() => {
        setShowColorPicker(false)
        setShowTools(false)
      }}
    >
      <HoverPreviewPanel
        childrenPhoto={
          <div onClick={onClickCanvasHandler}>
            {props.studentAnswer && props.studentAnswer.painted_picture ? (
                <div dangerouslySetInnerHTML={{__html: props.studentAnswer.painted_picture}}></div>
              ) :
              (
                <div className={styles.sketchContainer} style={{position: "relative"}}>
                  <img src={`${Stores.baseLink()}${image}`} alt="image"/>
                  <div className={styles.sketchWrapper}>
                    <ReactSketchCanvas
                      id={`react-sketch-canvas_${exID}`}
                      ref={canvasRef}
                      strokeWidth={penWidth}
                      eraserWidth={20}
                      strokeColor={penColor}
                      backgroundImage={`${Stores.baseLink()}${image}`}
                      exportWithBackgroundImage={true}
                      style={{border: "none"}}
                      onStroke={handleUpdate}
                    />
                  </div>
                </div>
              )
            }
          </div>
        }
        childrenIcon={
          !props.studentAnswer &&
          <FullImageIcon
            className={styles.fullImageIcon}
            onClick={() => handleEyeClick(image)}
          />
        }
        childrenIconsBlock={
          <div className={styles.panelBlocks}>
            {showColorPicker && renderColorPicker()}
            {showTools && renderToolsPanel()}
            {!props.studentAnswer && renderEditBlock()}
          </div>
        }
      />
      {showModal && (
        <FileSelectModal
          img={Stores.baseLink() + selectedImage}
          setOpen={() => setShowModal(false)}
        />
      )}
    </div>
  );
};