import React, {useEffect, useState, useRef} from 'react';
import Stores from 'Stores';
import st from './ImagesTextWords.module.scss';
import {classNames as cn} from 'helpers';

export default function ImagesWriteText(props) {
	const [result, setResult] = useState([]);
	const textAreaRefs = useRef([]);

	useEffect(() => {
		if (props.studentAnswer) {
			setResult(
				props.studentAnswer.answer.map((a) => ({link: a.image, description: a.text}))
			)
		} else {
			setResult(
				props.data.images_list.map((link) => ({link, description: ''}))
			)
		}
	}, [props.data, props.studentAnswer])

	useEffect(() => {
		if (props.studentAnswer) return
		const trimmedResult = result.map((item) => ({
			...item,
			description: item.description.trim()
		}));
		props.handleAnswers(trimmedResult, trimmedResult.every((el) => el.description !== ''))
	}, [result])

	const onInputChange = (index, e) => {
		const updatedResult = [...result]
		updatedResult[index].description = e.target.value
		setResult(updatedResult)
	};

	const adjustTextAreaHeight = (index) => {
		const textArea = textAreaRefs.current[index]
		if (textArea) {
			textArea.style.height = '36px'
			textArea.style.height = textArea.scrollHeight + 'px'
		}
	}

	useEffect(() => {
		result.forEach((_, index) => {
			adjustTextAreaHeight(index)
		})
	}, [result])

	const renderImageUnit = (unit, index) => {
		return (
			<div key={index} className={st.unit}>
				<figure>
					<img src={Stores.baseLink() + unit.link} alt="Image"/>
				</figure>
				<textarea
					ref={(el) => (textAreaRefs.current[index] = el)}
					className={cn({
						[st.customInput]: true,
						[st.correct]: props.studentAnswer &&
						(Array.isArray(props.studentAnswer.result_in_detail)
							? props.studentAnswer.result_in_detail[index][unit.link]
							: props.studentAnswer.result_in_detail[unit.link]),
						[st.mistake]: props.studentAnswer &&
						(Array.isArray(props.studentAnswer.result_in_detail)
							? !props.studentAnswer.result_in_detail[index][unit.link]
							: !props.studentAnswer.result_in_detail[unit.link]),
					})}
					placeholder="Write Word"
					value={result[index].description}
					onChange={(e) => onInputChange(index, e)}
					disabled={props.studentAnswer}
				/>
			</div>
		)
	}

	const renderImageUnits = () => result.map((unit, index) => renderImageUnit(unit, index))

	return (
		<div className={st.units}>
			{renderImageUnits()}
		</div>
	)
}