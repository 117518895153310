import React, {useState, useEffect} from 'react';
import Stores from 'Stores';

import "./QuestionWriting.scss"

export default function QuestionWriting(props) {

    const [inputText, setInputText] = useState('')
    const [answersButton, setAnswersButton] = useState(false)

    const handleInput = (e) => {
        setInputText(e.target.value)
    }

    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        setAnswersButton(inputText.length > 0 && !props.answ_array.length)
        props.handleAnswers(inputText, answersButton)
    }, [inputText, answersButton])

    return (
        <div className="questionWriting">
            {props.img ?
            <>
            <h3>{props.img.title}</h3>
            <figure><img src={Stores.baseLink() + props.img.file} alt=""/></figure>
            <div className="questionWriting__bottom">
                {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) ?
                <textarea defaultValue='' onChange={(e) => handleInput(e)}/> :
                null
                }
                {(props.is_student_checked_try || (props.is_teacher_view && props.at)) ??
                props.at ?
                <textarea value={props.at.asw_student} onChange={(e) => handleInput(e)}/> :
                null
                }
            </div>
            </> :
            null
            }
        </div>
    );
};