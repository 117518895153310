import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './InputsWrapper.module.scss';

function InputsWrapper({ children, withMargin }) {
  return (
    <ul className={cn({
      [styles.list]: true,
      [styles.margin]: withMargin,
    })}>
      { children }
    </ul>
  );
}

InputsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  withMargin: PropTypes.bool,
};

InputsWrapper.defaultProps = {
  withMargin: false,
}

export default InputsWrapper;
