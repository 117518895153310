import React from "react";
import { ReactComponent as TrueAnswer } from "./../../../img/true.svg";
import { ReactComponent as WrongAnswer } from "./../../../img/false.svg";

const MainTableWidgetQuestions = (props) => {
  const answersList = ({ question_data, question_type }) => {
    let answers = question_data;
    if (question_type.includes("make_sentence")) {
      answers = [
        {
          is_correct: true,
          name: question_data.correct_answer.split("|").join(" "),
        },
      ];
    }
    if (question_type.includes("question_tag_variants")) {
      answers = question_data
        .map((item) => item.variants)
        .flat()
        .filter((item) => item.is_correct);
    }
    if (question_type.includes("text_field")) {
      answers = [{ name: question_data.correct_answer, is_correct: true }];
    }
    return (
      <div className="questionBody__variantsBox">
        {answers.map((item, index) => {
          return (
            <div key={index} className="questionBody__variantsUnit">
              <div>{item.is_correct ? <TrueAnswer /> : <WrongAnswer />}</div>
              <div>{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const qBody = (item) => {
    const getSubtitle = () => {
      if (item.question_type === "question_tag_variants") {
        const data = item.question_data.map((item) => {
          return {
            ...item,
            variants: item.variants.filter((item) => item.is_correct)[0],
          };
        });

        let text = item.question_text;
        data.forEach((item) => {
          text = text.replace(
            item.tag,
            `<strong>[${item.variants.name}]</strong>`
          );
        });
        return (
          <div className="questionBody__question">
            <div
              style={{ fontWeight: 400 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        );
      }
      return (
        <div className="questionBody__question">
          <div dangerouslySetInnerHTML={{ __html: item.question_text }} />
        </div>
      );
    };

    switch (item.question_type) {
      case "audio_text_variants":
      case "audio_text_field":
        return (
          <>
            <audio controls src={item.audio}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            {getSubtitle()}
            {answersList(item)}
          </>
        );
      case "image_text_variants":
      case "image_text_field":
        return (
          <div className="questionBody">
            <div className="questionBody__mediaBox">
              <img src={item.image} width="400px" alt="pic" />
            </div>
            <div className="questionBody__questionBox">
              {getSubtitle()} {answersList(item)}
            </div>
          </div>
        );
      case "video_text_variants":
      case "video_text_field":
        return (
          <div className="questionBody">
            <div className="questionBody__mediaBox">
              <video controls src={item.video}>
                Your browser does not support the
                <code>audio</code> element.
              </video>
            </div>
            <div className="questionBody__questionBox">
              {getSubtitle()} {answersList(item)}
            </div>
          </div>
        );
      case "question_text_variants":
      case "question_text_field":
        return (
          <>
            {getSubtitle()} {answersList(item)}
          </>
        );
      case "make_sentence":
        return (
          <>
            {getSubtitle()} {answersList(item)}
          </>
        );
      case "question_tag_variants":
        return (
          <>
            {getSubtitle()}
            {answersList(item)}
          </>
        );
      default:
        break;
    }
  };

  return <>{qBody(props.items)}</>;
};

export default MainTableWidgetQuestions;
