import React, {useState} from "react"
import {observer} from "mobx-react";
import {hour_types} from "components/ContentBox/Global/GlobalModalSingle/templates/groups/CreateTimeTable/CreateTimeTable"
import {localisationMaster} from "Localisation/Localisation";
import {dateFormat} from "helpers/date";
import modalStore from "Stores/modalStore";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import DateTime from "components/ContentBox/Global/DateTime/DateTime";
import HourCounterReb from "components/ContentBox/Global/HourCounter/HourCounterReb";
import st from "./ModalWorkshopTime.module.scss";
import DatePicker from "react-datepicker";

export const initHours = ({start_at, finish_at}) => {
  const
    startAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${start_at}`),
    finishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${finish_at}`),
    diff = (finishAt - startAt) / 1000 / 60,
    hoursType = diff % 45 === 0 ? 1 : 2,
    hoursPerDay = diff / (hoursType === 1 ? 45 : 60)

  return [hoursType, hoursPerDay]
}

const ModalWorkshopTime = () => {
  const {modalValues, changeValuesArray} = modalStore

  const [initHoursType, initHoursPerDay] = initHours(modalValues)

  const [hoursType, setHoursType] = useState(initHoursType)
  const [hoursPerDay, setHoursPerDay] = useState(initHoursPerDay)

  const calculator = (val, key) => {
    let obj, startAt, dateFinishAt
    switch (key) {
      case "hour_type":
        dateFinishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${modalValues.start_at}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + hoursPerDay * (val === 1 ? 45 : 60))
        obj = {
          finish_at: dateFormat(dateFinishAt, "hh:ii")
        }
        setHoursType(val)
        break;
      case "hours_per_day":
        dateFinishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${modalValues.start_at}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + val * (hoursType === 1 ? 45 : 60))

        obj = {
          finish_at: dateFormat(dateFinishAt, "hh:ii"),
        }
        setHoursPerDay(val)
        break;
      case "start_at":
        dateFinishAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${val}`)
        dateFinishAt.setMinutes(dateFinishAt.getMinutes() + hoursPerDay * (hoursType === 1 ? 45 : 60))
        obj = {
          start_at: val,
          finish_at: dateFormat(dateFinishAt, "hh:ii"),
        }
        break;
      case "finish_at":
        startAt = new Date(`${dateFormat(new Date(), "YYYY-mm-dd")}T${val}`)
        startAt.setMinutes(startAt.getMinutes() - hoursPerDay * (hoursType === 1 ? 45 : 60))
        obj = {
          start_at: dateFormat(startAt, "hh:ii"),
          finish_at: val,
        }
        break;
      default:
        break;
    }
    changeValuesArray({...modalValues, ...obj})
  }

  return (
    <div className={st.row}>
      <div>
        <SelectCustom
          placeholder={localisationMaster('text_37', 'modal_templates')}
          options={hour_types}
          valueID={hoursType}
          onChange={o => calculator(o.id, 'hour_type')}
        />
      </div>

      <div>
        <HourCounterReb name="hours_per_day"
                        value={hoursPerDay}
                        onChange={calculator}
                        min={.5}
                        max={8}
                        step={.5}
                        note="Часов в день"/>
      </div>

      <div>
        <DateTime placeholder='9:00'
                  errorCheker={() => {
                  }}
                  start_at={modalValues.start_at}
                  finish_at={modalValues.finish_at}
                  objectChangeHandler={calculator}/>
      </div>

      <div>
        <DatePicker
            placeholderText="Please select the date"
            value={modalValues.date_from}
            onChange={(e) => changeValuesArray({...modalValues, date_from: dateFormat(e,'YYYY-mm-dd')})}
        />
      </div>
    </div>
  )
}

export default observer(ModalWorkshopTime)
