import React from "react";
import st from "./FormField.module.scss"

export default function FormField(props) {
  return (
    <div className={st.formField}>
      {props.children}
    </div>
  )
}
