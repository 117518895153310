import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {classNames as cn} from "helpers";
import './Textarea.scss';
import blackImg from '../../img/07.png'
import redImg from '../../img/08.png'
import yellowImg from '../../img/10.png'
import greenImg from '../../img/09.png'
import colorImg from '../../img/11.png'
import boldImg from '../../img/01.png'
import italicImg from '../../img/02.png'
import underlineImg from '../../img/03.png'
import strikethroughImg from '../../img/04.png'
import deletedImg from '../../img/05.png'
import bracketsImg from '../../img/12.png'
import arrowImg from '../../img/13.png'

const Textarea = props => {

    const buttons = [
        {
            icon: boldImg,
            cmd: 'bold',
            state: 'bold',
        },
        {
            icon: italicImg,
            cmd: 'italic',
            state: 'italic'
        },
        {
            icon: underlineImg,
            cmd: 'underline',
            state: 'underline',
        },
        {
            icon: strikethroughImg,
            cmd: 'strikethrough',
            state: 'strikethrough',
        },
        {
            icon: deletedImg,
            cmd: 'removeFormat',
        },
        {
            icon: bracketsImg,
            cmd: 'insertText',
            val: () => '[' + document.getSelection().toString() + ']',
        },
        {
            icon: arrowImg,
            cmd: 'insertText',
            val: '|'
        },
        {
            icon: blackImg,
            cmd: 'foreColor',
            val: '#000000',
        },
        {
            icon: redImg,
            cmd: 'foreColor',
            val: '#db3031',
        },
        {
            icon: greenImg,
            cmd: 'foreColor',
            val: '#5ebd5e',
        },
        {
            icon: yellowImg,
            cmd: 'foreColor',
            val: '#ff9e00',
        },
        {
            icon: colorImg,
            cmd: 'foreColor',
            val: '#1873f2',
        },
    ]

    const refDiv = useRef()

    const [textValue, setTextValue] = useState(props.value || '');
    const [states, setStates] = useState({
        bold: false,
        italic: false,
        strikethrough: false,
        underline: false,
    })

    const onInputHandler = () => {
        setTextValue(refDiv.current.innerHTML)
    }

    const command = (cmd, val) => {
        document.execCommand(cmd, false, val)
    }

    const escape_text = text => {
        let map = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#039;' };
        text = text.replaceAll('<br>', '')
        return text.replace(/[&<>"']/g, m => map[m]);
    }

    const onPasteHandler = e => {
        e.preventDefault();
        let text = e.clipboardData.getData('text/plain');
        document.execCommand('insertHtml', false, escape_text(text));
    }

    const onSelectHandler = () => {
        setStates(Object.assign({}, ...(Object.keys(states).map(key => ({[key]: document.queryCommandState(key)})))))
    }

    const getClassNameButton = cond => cn({
        ['customTextarea__btn']: true,
        ['customTextarea__btn--active']: cond,
    })

    useEffect(() => {
        refDiv.current.innerHTML = props.value || '';
    }, [])

    useEffect(() => {
        if (props.value !== undefined && textValue !== props.value) {
            //document.getElementById(props.stateName).innerHTML = props.value;
            refDiv.current.innerHTML = props.value;
            setTextValue(props.value)
        }
    }, [props.value])

    useEffect(() => {
        props.onChange(textValue, props.stateName)
    }, [textValue])

    const onKeyDownHandler = e => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
        }
    }

    return (
        <div className={`customTextarea ${props.className || ''}`}>
            <div className="customTextarea__bar">
                {buttons.map((btn, idx) =>
                    <div key={idx} className={getClassNameButton(states[btn.state])}
                         onClick={() => command(btn.cmd, typeof btn.val === 'function' ? btn.val() : btn.val)}>
                        <img src={btn.icon} alt="btn"/>
                    </div>)}
            </div>
            <div className={"customTextarea__textarea " + (props.error ? " customTextarea__textarea--error" : "")}
                 id={props.stateName}
                 ref={refDiv}
                 onPaste={onPasteHandler}
                 contentEditable="true"
                 onInput={onInputHandler}
                 onSelect={onSelectHandler}
                 onKeyDown={onKeyDownHandler}
                 data-text={props.placeholder}/>
        </div>
    )
}

Textarea.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    className: PropTypes.string,
}

export default Textarea
