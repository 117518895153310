import React from 'react';
import st from './QuestionExample.module.scss'

const QuestionExample = ({text}) => {
    return (
        <div className={st.container}>
            <div className={st.example}>
                Example
            </div>
            <div className={st.text} dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    );
};

export default QuestionExample;