import React, {useState, useEffect} from 'react';
import st from "./WriteText.module.scss";
import {ReactComponent as PlusIcon} from "components/UI/icons/add-line.svg";

export default function WriteText(props) {
	const [inputList, setInputList] = useState(['']);

	const handleInput = (e, index) => {
		if (props.studentAnswer) return;
		const newText = e.target.value;
		const newList = [...inputList];
		newList[index] = newText;
		setInputList(newList);
	};

	useEffect(() => {
		setInputList(['']);
	}, [props.attempts]);

	useEffect(() => {
		const ready = inputList.some((text) => text.length > 0) && !props.studentAnswer;
		props.handleAnswers(inputList, ready);
	}, [inputList]);

	useEffect(() => {
		setInputList([...props.studentAnswer?.answer ?? ['']]);
	}, [props.studentAnswer]);

	const addInput = () => {
		setInputList([...inputList, '']);
	};

	const mappingWords = inputList.map((text, index) => {
		return (
			<div className={st.writeText} key={index}>
				<input
					className={st.writeTextInput}
					placeholder=" "
					value={text}
					onChange={(e) => handleInput(e, index)}
				/>
				{index === inputList.length - 1 &&
					<button
						onClick={addInput}
						className={st.button}
					>
						<PlusIcon/>
					</button>
				}
			</div>
		);
	})

	return (
		<div className={st.writeTextBlock}>
			{mappingWords}
		</div>
	);
}