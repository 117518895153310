import React from 'react'
import './moneyBox.scss'

export default function MoneyBox(props) {
    return (
        <div className="moneyBox">
            <input type='number'
                   onChange={(e) => props.changeHandler(props.reverse ? +e.target.value / props.offset : +e.target.value * props.offset, props.name)}
                   value={props.reverse ? props.returnValue(props.name) * props.offset : props.returnValue(props.name) / props.offset}
            />
            <div>
                {props.currency}
            </div>
        </div>
    )
}
