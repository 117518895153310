import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import Stores from "Stores";
import style from './TeacherRating.module.scss'
import RatingStars from "../../../../components/StudentsProgressReports/AverageProgressReport/RatingStars/RatingStars";
import ModalMoreInfo
    from "../../../../components/StudentsProgressReports/StudentsProgressReport/ModalMoreInfo/ModalMoreInfo";


const TeacherRating = ({item, students, listing}) => {
    const studentCreatedRating = Stores.transliterate(students?.find(student => student.user_id === item.created_by).full_name)
    const [isOpen, setIsOpen] = useState(false)
    const ratings = item.rate_list.map(item => item.value); // Получаем массив всех значений rating
    const averageRating = ratings.reduce((sum, rating) => sum + rating, 0) / (ratings.length || 1); // Считаем среднее значение


    return (
        <div key={item.id} className={style.report} style={listing && {border: 'none'}}>
            <div className={style.box}>
                <div className={style.info}>
                    <NavLink target="_blank" to={'/account/' + item.user_id}>
                        {`${Stores.transliterate(item.teacher_name)}`}
                    </NavLink>
                </div>

                <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
                    <RatingStars rating={averageRating} />
                    {
                        isOpen && <div>
                            <ModalMoreInfo item={item} averageRating={averageRating} isTeacher={false} studentCreatedRating={studentCreatedRating}/>
                        </div>
                    }

                </div>

                <div className={style.description}>
                    <div dangerouslySetInnerHTML={{__html: item.comment}}/>
                </div>
            </div>
        </div>
    );
};

export default TeacherRating;