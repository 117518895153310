import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {dateFormat} from "helpers/date"
import {permissions} from "settings/permissions"
import {superAxiosRequest} from "axiosApi"
import style from "./ResponsiblesRating.module.scss"
import {checkPermList} from "helpers"
import OpenHeader from "components/Modules/Groups/components/OpenHeader"
import Rating from "components/ContentBox/Global/GlobalModalSingle/templates/groups/Rating"
import modalStore from "Stores/modalStore"
import HeaderContentBox from "components/Modules/Groups/components/HeaderContentBox"
import Loader from "components/UI/Loader/Loader"
import TabMenu from "components/Modules/Ebook/Common/TabMenu/TabMenu"
import AverageProgressReport from "../../../components/StudentsProgressReports/AverageProgressReport/AverageProgressReport"
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg"
import TeacherRating from "../TeachersRatings/TeacherRating/TeacherRating"
import useLocalization from "hooks/useLocalization"

const getMonth = (date) => {
  return dateFormat(new Date(date), 'MMM, YYYY')
}

const ResponsibleRating = ({groupID, employees, students}) => {

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_rating_select',
    dict: {
      "responsible_rating": "Responsible Rating",
      "responsible": "Responsible",
      "responsibles": "Responsibles",
      "save": "Save",
    }
  })

  const getMonthData = (month) => {
    return rating?.filter((item) => getMonth(item.evaluation_date) === month)
  }

  const extractMonthYearFromDateArray = (dateArray) => {
    const extractedArray = dateArray.map((item) => {
      const date = new Date(item.evaluation_date);
      return dateFormat(new Date(date), 'MMM, YYYY')
    });
    const arr = [...new Set(extractedArray.reverse())]
    return arr.map((item,index) => ({id: index+1, name: item}))
  }
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [rating, setRating] = useState([])
  const tabs = extractMonthYearFromDateArray(rating)
  const [activeMonth, setActiveMonth] = useState({})
  const monthData = getMonthData(activeMonth?.name)
  
  const addTeacherRating = () => {
    const ms = {
      title: translate.responsible_rating,
      button: translate.save,
      modalBodyComponent: () => <Rating
        evaluationApiLink="progress_report/teacher_evaluation"
        ratingApiLink="progress_report/teacher_rating"
        user={{
          title: translate.responsible,
          placeholder: translate.responsibles,
          key: 'teacher'
        }}
      />,
      payload: {
        students: employees.map(st => ({id: st.id, name: `${st.full_name} `})),
        isResponsible: true
      },
      link: 'progress_report/teacher_report',
      valid: {
        teacher: 'required',
        rate_list: (rate_list) =>  rate_list.every((x) => x.rating_id !== null ) 
      },
      func: (data) => setRating([...data.reports]),
      width: 600,
    }
    modalStore.activity(ms, {
      group: groupID,
      responsible: true
    })
  }

  useEffect(() => {
    if (!open) return
    setIsLoading(true)
    superAxiosRequest({
      method: 'get',
      link: 'progress_report/teacher_report',
      params: {
        group_id: groupID,
        responsible: true
      }
    }).then(({data}) => {
      setIsLoading(true)
      setRating(data.reports)
      const extractedTabs = extractMonthYearFromDateArray(data.reports);
      setActiveMonth(extractedTabs[0]);
    }).catch(() => {})
        .finally(() => setIsLoading(false))
  }, [open, groupID])
 
  const employeeData = {};
  
  monthData.forEach((evaluation) => {
    const teacherName = evaluation.teacher_name;
    const rateList = evaluation.rate_list;

    if (!employeeData[teacherName]) {
      employeeData[teacherName] = {
        totalRating: 0,
        totalCount: 0
      };
    }

    rateList.forEach((rate) => {
      employeeData[teacherName].totalRating += rate.value;
      employeeData[teacherName].totalCount++;
    });
  });
  
  const averageRatings = Object.entries(employeeData).map(([teacherName, { totalRating, totalCount }]) => ({
    teacherName,
    averageRating: totalCount !== 0 ? totalRating / totalCount : 0, 
  }));  

  return (
    <HeaderContentBox className={style.ratingList}>
      <OpenHeader title={translate.responsible_rating}
                  onClick={() => setOpen(!open)}
                  addButton={checkPermList(permissions.add_teacher_report) ? addTeacherRating : null}/>
      {open && (
              <>
                {
                  isLoading ? <Loader/> : <>
                    {rating.length > 0 ? (
                        <div className={style.list}>
                          <TabMenu tabs={tabs} onChange={(tab)=> setActiveMonth(tab)} tabID={activeMonth?.id}/>
                          { averageRatings.map((item, index) => {
                              return <AverageProgressReport key={index} teacherName={item.teacherName} activeMonth={activeMonth?.name} averageRatingPerMonth={item.averageRating} type={'teacher'}/>
                            })
                          }
                          {monthData.map((item, index) => (
                              <TeacherRating key={index} item={item} students={students} />
                          ))}
                        </div>
                    ) : (
                        <div className={style.empty}>
                          <EmptyData />
                        </div>
                    )}
                  </>
                }
              </>
      )}
      
      </HeaderContentBox>
  )
}

ResponsibleRating.propTypes = {
  groupID: PropTypes.number,
  employees: PropTypes.arrayOf(PropTypes.object),
  students: PropTypes.arrayOf(PropTypes.object),
}

export default ResponsibleRating