import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import cogoToast from "cogo-toast";
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import ContentHeader from "../ContentHeader";
import Filter from "../Filter/Filter";
import THead from "./THead";
import Loader from "../Loader/Loader";
import st from "./Table.module.scss"
import ExportExcelButton from '../../../Modules/Reports/ExportExcelButton/ExportExcelButton';
import {ExcelRowsParams, setCellParams} from '../../../utils/ExcelExport';



export default function WorkedHours() {
    const cfg = {
        cols: [
            {
                name: '№ группы',
                classes: [st.bgw],
            },
            {
                name: 'Студент',
                classes: [st.fz12],
            },
            {
                name: 'Кол-во академических часов',
                classes: [st.fz12],
            },
            {
                name: 'Оплачено',
                classes: [st.fz12],
            },
            {
                name: 'Списание',
                classes: [st.fz12],
            },
            {
                name: 'Остаток',
                classes: [st.fz12]
            },
        ]
    }
    const {
        refWrapper,
        refSticky,
        refThead,
        filter,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader()
    const [groupCatalog, setGroupCatalog] = useState({})
    const filterCfg = [
        {
            type: 'select-custom',
            placeholder: 'Локация',
            values: groupCatalog.locations?.items,
            sort_key: 'name',
            nameState: 'location'
        },
        {
            type: 'select-custom',
            placeholder: 'Уровень группы',
            values: groupCatalog.skill_levels?.items,
            sort_key: 'name',
            nameState: 'group_level'
        },
        {
            type: 'select-custom',
            placeholder: 'Тип группы',
            values: groupCatalog.types?.items,
            sort_key: 'name',
            nameState: 'group_type',
        },
        {type: 'month-range-picker', nameState: ['date_from', 'date_until']},
    ]

    useEffect(() => {
        const apiConf = {
            method: 'get',
            link: 'groups/groups_catalog',
        }
        superAxiosRequest(apiConf).then(res => setGroupCatalog(res.data))
    }, [])

    const getFilteredData = (filter) => {
        if (!(filter.date_from && filter.date_until)) return
        setIsLoading(true)

        const dateFrom = filter.date_from ? new Date(filter.date_from) : null
        const dateUntil = filter.date_until ? new Date(filter.date_until) : null

        const params = {
            ...filter,
            date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"),...(dateUntil &&
                {
                    date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
                })
        }
        const apiConf = {
            method: 'get',
            link: 'groups/reports/worked_hours_full',
            params,
        }

        superAxiosRequest(apiConf).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(() => {
            setData({})
            cogoToast.warn("Что-то пошло не так :(", {heading: "Ошибка сервера"})
        })
    }
    
    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            let headRow = [
                {
                    v: '№ группы',
                },
                {
                    v: 'Студент',
                },
                {
                    v: 'Кол-во академических часов',
                },
                {
                    v: 'Оплачено',
                },
                {
                    v: 'Списание',
                },
                {
                    v: 'Остаток',
                },
            ]
            let tableData = []
            let merges = []
            let wscols = [
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];


            headRow = setCellParams(headRow, ExcelRowsParams.headerRowParam)

            let currentRow = 1
            data.groups.forEach((group) => {
                if (group.students.length > 1) {
                    merges.push(
                        {s: {r: currentRow, c: 0}, e: {r: currentRow + group.students.length - 1, c: 0}}
                    )
                }
                group.students.forEach((student) => {
                    currentRow = currentRow + 1
                    tableData.push([
                        {
                            v: `${group.groupData.name} ${group.groupData.number}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${student.name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${student.hours_academ}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${student.paid}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${student.withdraw}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${student.balance}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
                currentRow = currentRow + 1
                tableData.push([
                    {
                        v: `итого по группе`,
                        ...ExcelRowsParams.footerRowParams.firstCell
                    },
                    {
                        v: ``,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${group.total.hours_academ}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${group.total.paid}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${group.total.withdraw}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                    {
                        v: `${group.total.balance}`,
                        ...ExcelRowsParams.footerRowParams.cell
                    },
                ])
            })

            let footerRow = [
                {
                    v: `ИТОГО`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${data.total.hours_academ}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${data.total.paid}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${data.total.withdraw}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${data.total.balance}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ]

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Отчеты Оплаченное время.xlsx");
        })
    }
    return (
        <>
            <ContentHeader title="Отчеты: Оплаченное время" />
            <Filter config={filterCfg} drop={() => {setFilter({})}} getFilterParams={getFilteredData} />
            {isLoading ? <Loader /> :
                Array.isArray(data?.groups) && data.groups.length > 0 ?
                     <>
                        <div style={{padding: '10px'}}>
                            <ExportExcelButton data={data} downloadExcel={downloadExcel} />
                        </div>
                        <div className={st.tableWrapper_h} ref={refWrapper}>
                            <div ref={refSticky} className={st.sticky}>
                                <table className={st.table}>
                                    <THead cols={cfg.cols} />
                                </table>
                            </div>
                            <table className={st.table}>
                                <THead cols={cfg.cols} ref={refThead} />
                                <tbody className={`${st.tbody} ${st.tbodyStriped}`}>
                                    {/* Итого по schedules && workshops */}
                                    {(data.total) &&
                                        <tr className={st.bdt}>
                                            <td colSpan={2} className={`${st.tdTotal} ${st.fw600}`}>
                                                ИТОГО
                                    </td>
                                            <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                    {data.total.hours_academ}
                                                </div>
                                            </td>
                                            <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                    {data.total.paid}
                                                </div>
                                            </td>
                                            <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                    {data.total.withdraw}
                                                </div>
                                            </td>
                                            <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                    {data.total.balance}
                                                </div>
                                            </td>
                                        </tr>}
                                    {Array.isArray(data.groups) && data.groups.map((group, groupId) =>
                                        <React.Fragment key={`g${groupId}`}>
                                            <tr className={st.bdt}>
                                                <td colSpan={2} className={`${st.tdTotal} ${st.fw600}`}>
                                                    итого по группе
                                                </td>
                                                <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                        {group.total.hours_academ}
                                                    </div>
                                                </td>
                                                <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                        {group.total.paid}
                                                    </div>
                                                </td>
                                                <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                        {group.total.withdraw}
                                                    </div>
                                                </td>
                                                <td className={`${st.tdTotal} ${st.tdMiddle} ${st.textAlignCenter}`}>
                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                        {group.total.balance}
                                                    </div>
                                                </td>
                                            </tr>

                                            {group.students.map((student, studentId) =>
                                                <tr key={studentId} className={st.bdt}>
                                                    {studentId === 0 &&
                                                        <td rowSpan={group.students.length}
                                                            className={st.fw600} style={{background: "white"}}>
                                                            <div className={`${st.groupDescription} ${st.medium}`}>
                                                                <NavLink to={`/groups/group/${group.groupData.id}`} target="_blank">
                                                                    {`${group.groupData.name} ${group.groupData.number}`}
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                        <div className={`${st.groupDescription} ${st.bottom}`}>
                                                            <NavLink to={`/account_clients/${student.user_id}`} target="_blank">
                                                                {student.name}
                                                            </NavLink>
                                                        </div>
                                                    </td>
                                                    <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                        <div className={`${st.groupDescription} ${st.medium}`}>
                                                            {student.hours_academ}
                                                        </div>
                                                    </td>
                                                    <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                        <div className={`${st.groupDescription} ${st.medium}`}>
                                                            {student.paid}
                                                        </div>
                                                    </td>
                                                    <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                        <div className={`${st.groupDescription} ${st.medium}`}>
                                                            {student.withdraw}
                                                        </div>
                                                    </td>
                                                    <td className={`${st.tdMiddle} ${st.textAlignCenter}`}>
                                                        <div className={`${st.groupDescription} ${st.medium}`}>
                                                            {student.balance}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </>
    );
};