import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {routes} from 'Router/routes'
import WithoutAuth from './components/ContentBox/WithoutAuth';
import WrapperAuth from "./components/Wrappers/WrapperAuth";
import AuthorizedWrapper from './components/Wrappers/AuthorizedWrapper';
import ErrorPage from "components/Modules/ErrorPage/ErrorPage";
import WrapperCPT from "./components/Wrappers/WrapperCPT";
import WrapperCPTNON from 'components/Wrappers/WrapperCPTNON';
import Varification from 'components/Modules/Auth/Varification/Varification';

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={routes.public.filter(r => !r.wrapper).map(r => r.path)}>
                    <WithoutAuth/>
                </Route>

                <Route path={routes.public.filter(r => r.wrapper === 'WrapperUser').map(r => r.path)}>
                    <WrapperAuth/>
                </Route>

                {routes.public.filter(r => r.wrapper === 'WrapperCPT').map(r =>
                  <Route key={r.path} path={r.path}>
                      <WrapperCPT {...r} />
                  </Route>)}

                {routes.public.filter(r => r.wrapper === 'WrapperCPTNON').map(r =>
                  <Route key={r.path} path={r.path}>
                      <WrapperCPTNON {...r} />
                  </Route>)}

                {routes.public.filter(r => r.wrapper === 'VarificationAuth').map(r =>
                <Route key={r.path} path={r.path}>
                    <Varification {...r} />
                </Route>)}

                <Route path="/" exact>
                    <AuthorizedWrapper />
                </Route>

                {routes.private.map(r =>
                <Route key={r.path} path={r.path}>
                    <AuthorizedWrapper {...r} />
                </Route> )}

                <Route path='*' exact component={ErrorPage} />
            </Switch>
        </BrowserRouter>
    )
}

export default App;
