import React from 'react';
import { observer } from 'mobx-react';
import CustomerItem from './CustomerItem';
import { getAdaptedData } from './utils';
import Loader from 'components/UI/Loader/Loader';
import slotsBookedScheduleStore from 'Stores/slotsBookedScheduleStore';
import styles from './CustomerList.module.scss';

function CustomerList() {
  const { activeTab, filteredData, selectedLocation, isCustomersLoading } = slotsBookedScheduleStore;
  const adapted = getAdaptedData(filteredData[activeTab], selectedLocation)
    .sort((a, b) => new Date(`${a.slot_date}T${a.slot_time}`) > new Date(`${b.slot_date}T${b.slot_time}`) ? 1 : -1);

  if (isCustomersLoading) {
    return <Loader fullWidth/>
  }

  return (
    <ul className={styles.list}>
      {adapted.map((item, index) => (
        <CustomerItem
          key={index.toString()}
          data={item}
        />
      ))}
    </ul>
  );
}

export default observer(CustomerList);
