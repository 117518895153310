import React from "react";
import st from "./FormFieldset.module.scss"

export default function FormFieldset(props) {
  return (
    <div className={st.formFieldset}>
      {props.children}
    </div>
  )
}
