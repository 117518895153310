import {action, observable, computed, toJS, makeObservable} from 'mobx';
import { superAxiosRequest } from 'axiosApi';
import { dateFormat } from 'helpers/date';

class SlotsHistoryListingStore {
  clients = [];
  totalClientsCount = 0;
  activeTab = 'all';
  tabsCount = {};
  employees = [];
  ages = [];
  levels = [];
  programs = [];
  filters = {};
  page = 1;

  constructor() {
    makeObservable(this, {
      clients: observable,
      totalClientsCount: observable,
      activeTab: observable,
      tabsCount: observable,
      employees: observable,
      ages: observable,
      levels: observable,
      programs: observable,
      filters: observable,
      page: observable,

      filtersArray: computed,

      fetchClients: action.bound,
      setActiveTab: action.bound,
      setFilters: action.bound,
      clearFilters: action.bound,
      setPage: action.bound,
    });
  }

  get filtersArray() {
    return toJS(this.filters);
  }

  setActiveTab(tab) {
    if (tab !== this.activeTab) {
      this.page = 1;
    }
    this.activeTab = tab;
  }

  setPage(page) {
    this.page = page;
  }

  setFilters(data) {

    if (data.date_until) {
      data.end_date = dateFormat(data.date_until, 'YYYY-mm-dd');
      delete data.date_until;
    }
    if (data.date_from) {
      data.start_date = dateFormat(data.date_from, 'YYYY-mm-dd');
      delete data.date_from;
    }
    delete data.is_active;

    this.filters = data;
    this.page = 1;
  }

  clearFilters() {
    if (Object.keys(this.filters).length > 0) {
      this.filters = {};
    }
  }

  fetchClients(params, onFinally) {
    superAxiosRequest({
      method: 'get',
      link: 'online_schedule/info',
      params,
    })
      .then(({ data }) => {
        const {result, _meta} = data;
        const {missed, future, all, count_items, employees, ages, levels, programs, past} = _meta;
        this.clients = result;
        this.totalClientsCount = count_items;
        this.tabsCount = { all, missed, future, past };
        this.employees = this.adaptFilters(employees);
        this.ages = this.adaptFilters(ages);
        this.levels = this.adaptFilters(levels);
        this.programs = this.adaptFilters(programs);
      })
      .finally(() => onFinally());
  }

  adaptFilters(filter) {
    return filter.map(({ id, name }) => ({value: id, label: name}));
  }
}

export default new SlotsHistoryListingStore();
