import React, {useEffect, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import {superAxiosRequest} from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import {dateFormat} from "helpers/date";
import {NavLink} from 'react-router-dom';
import {ExcelRowsParams} from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";


const TeacherRating = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        setFilter,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();
    const [cols, setCols] = useState([]);
    const [catalog, setCatalog] = useState({})

    const filterCnf = [
        {
            type: 'select-custom',
            placeholder: 'Преподаватель',
            values: catalog?.teachers?.items,
            option_label_key: "full_name",
            sort_key: "full_name",
            nameState: "teacher",
        },
        {
            type: 'days-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        }
    ]

    const sortEvaluations = (arr, key) => arr.sort((a, b) => a[key] - b[key]);

    const parseData = (data) => {
        let newState = []
        Object.keys(data.results).forEach(teacherId => {
            let newItem = {
                teacher_id: data.meta.teachers[teacherId].user_id,
                teacher_name: data.meta.teachers[teacherId].name,
                students: []
            }
            Object.keys(data.results[teacherId]).forEach(studentId => {
                let studentData = {
                    student_id: data.meta.clients[studentId].user_id,
                    student_name: data.meta.clients[studentId].name
                }
                let arrayNames = [{name: 'Интересный урок'},
                {name: 'Использование ИД и доп. материалa'},
                {name: 'Развитие Speaking'},
                {name: 'Грамматика'}
                ]
                data.results[teacherId][studentId].forEach(reportItem => {
                    newItem.students.push({
                        ...studentData,
                        rate_list: [...reportItem.result],
                        comments: reportItem.result.map((item, i) => ({name: arrayNames[i].name, value: item.comment.length === 0 ? 'Нет комментария' : item.comment}))
                    })
                })
            })

            newState.push(newItem)
        })
        return newState
    }

    const onChangeFilter = (obj) => {
        const filterData = {
            ...obj,
            ...(obj.date_from && { date_from: obj.date_from }),
            ...(obj.date_until && { date_until: obj.date_until }),
        }
        setFilter(filterData);
        if (obj.date_from && obj.date_until) getFilteredData(filterData);
    }
    const getFilteredData = (filter) => {
        if (filter.date_from && filter.date_until) {
            setIsLoading(true)
            let apiConf = {
                method: 'get',
                params: {
                    ...filter
                }
            }
            if (apiConf.params.teacher && apiConf.params.teacher.length > 0) apiConf.params.teacher = `[${apiConf.params.teacher.map(item => item)}]`
            if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
            if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")
            superAxiosRequest({...apiConf, link: 'progress_report/teacher_report_full'}).then(res => {
                setData([...parseData(res.data)])
                setIsLoading(false)
                setCols([
                    {name: 'Преподаватель', rowSpan: 3},
                    {name: 'Студент', rowSpan: 3},
                    ...sortEvaluations(res.data.meta.evaluations, 'id'),
                    {name: 'Комментарий'},
                ]);
                setIsLoading(false)
            }).catch(() => setIsLoading(false))
        }
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'groups/groups_catalog',
        }).then((res) =>  {
            setCatalog(res.data)
            setIsLoading(false)
        }).catch(() => setIsLoading(false))
    }, [])

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const merges = []
            const wscols = cols.map(() => ({wch: 30}));
            const rows = [];
            const headRow = cols.map((col) => ({
                v: col.name,
                ...ExcelRowsParams.headerRowParam
            }));
            const tableData = data.map((row) => {
                return row.students.map((student) => {
                    const commentLine = student.comments.map(comment => `${comment.name}: ${comment.value}\n`).join(' ')                 
                    const result = [
                        {
                            v: row.teacher_name,
                            ...ExcelRowsParams.tableRowParam.cell,
                        },
                        {
                            v: `${student.student_name}`,
                            ...ExcelRowsParams.tableRowParam.cell,
                        },
                        ...sortEvaluations(student.rate_list, 'evaluation_id').map((rate) => ({
                            v: rate.rating_id,
                            ...ExcelRowsParams.tableRowParam.cell,
                        })),
                        {
                            v: commentLine,
                            ...ExcelRowsParams.tableRowParam.cell,
                        },
                    ];
    
                    return result;
                })
            }).flat();

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Рейтинг по преподавателям.xlsx");
        })
    }

    return (
        <div>
            <ContentHeader title="Рейтинг по преподавателям" />
            <Filter config={filterCnf} getFilterParams={e => onChangeFilter(e)} drop={() => {setFilter({})}} />

            {isLoading
                ? <Loader />
                : data && data.length > 0 ? (
                        <>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data) &&
                                            <>
                                                {data.map((row) =>
                                                    row.students.map((student, sIdx) =>
                                                        <tr key={sIdx} className={st.bdt}>
                                                            {sIdx === 0 &&
                                                                <td rowSpan={row.students.length}
                                                                    className={st.fw600}
                                                                    style={{background: "white"}}>
                                                                    <div className={`${st.groupDescription} ${st.medium}`}>
                                                                        <NavLink to={'/account/' + row.teacher_id} target="_blank">
                                                                            {row.teacher_name}
                                                                        </NavLink>
                                                                    </div>
                                                                </td>}

                                                            <td className={st.fw600} style={{background: "white", width: '15%'}}>
                                                                <div className={`${st.groupDescription} ${st.medium}`}>
                                                                    <NavLink to={'/account_clients/' + student.student_id} target="_blank">
                                                                        {student.student_name}
                                                                    </NavLink>
                                                                </div>
                                                            </td>
                                                            {sortEvaluations(student.rate_list, 'evaluation_id').map((rateItem, rIdx) =>
                                                                <td key={rIdx} className={st.fw600}
                                                                    style={{background: "white", width: '11%', textAlign: 'center'}}>
                                                                    {rateItem.rating_id}
                                                                </td>
                                                            )}
                                                            <td style={{background: "white", width: '19%'}}>
                                                                {student.comments.map(comment=> (
                                                                        <div className="">
                                                                            <strong>{`${comment.name}:`} </strong>
                                                                            <div dangerouslySetInnerHTML={{ __html: comment.value.replace(/&nbsp;/g, '') }} />
                                                                        </div>
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{padding: '10px'}}>
                                <ExportExcelButton data={data} downloadExcel={downloadExcel} />
                            </div>
                        </>
                    ) : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </div >
    )
}

export default TeacherRating