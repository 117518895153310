import {observable, action, toJS, makeObservable, computed} from "mobx";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import asideModalStore from "./asideModalStore";

class EbookBookStore {
    bookData = {}
    docsData = []
    chaptersData = []

    constructor() {
        makeObservable(this, {
            bookData: observable,
            docsData: observable,
            chaptersData: observable,

            fetchBook: action,
            fetchChapters: action,
            setBook: action,
            setDoc: action,
            removeDoc: action,
            addChapter: action,
            setChapter: action,
            removeChapter: action,

            book: computed,
            bookDocs: computed,
            bookChapters: computed,
        })
    }

    fetchBook = id => {
        const apiConf = {
            method: 'get',
            link: 'ebooks/books/' + id
        }
        superAxiosRequest(apiConf).then(({data}) => {
            this.bookData = data
            this.docsData = data.documents || []
            this.chaptersData = data.chapters || []
        }).catch(() => {})
    }

    fetchChapters = id => {
        const apiConf = {
            method: 'get',
            link: 'ebooks/chapters',
            params: {
                ebook: id,
            }
        }
        superAxiosRequest(apiConf).then(res => {
            this.chaptersData = res.data.result
        }).catch()
    }

    setBook = book => this.bookData = book

    addDoc = doc => this.docsData = [...this.docsData, doc]

    setDoc = data => this.docsData = this.docsData.map(d => d.id === data.id ? data : d)

    removeDoc = id => this.docsData = this.docsData.filter(d => d.id !== id)

    createChapter = (translate, msChapter) => {
        const ms = {
            ...msChapter,
            title: translate.add_new_section,
            button: localisationMaster('button-two', 'company/age', 'add_age'),
            onSubmitSuccess: this.addChapter
        }
        const md = {
            ebook: this.book.id,
            position: this.getMaxPosition()
        }
        asideModalStore.activity(ms, md)
    }

    addChapter = data => {
        this.chaptersData = [...this.chaptersData, data]
    }

    editChapter = (id, translate, msChapter) => {
        const ms = {
            ...msChapter,
            title: translate.edit_chapter,
            button: localisationMaster('button-two', 'company/profiles/account', 'add_category'),
            is_put: true,
            link: `${msChapter.link}/${id}`,
            onSubmitSuccess: this.setChapter,
        }
        const {name, image, description, home_work} = this.bookChapters.find(ch => ch.id === id)
        const md = {
            ebook: this.book.id,
            name,
            image,
            description,
            home_work,
        }
        asideModalStore.activity(ms, md)
    }

    setChapter = data => this.chaptersData = this.chaptersData.map(ch => ch.id === data.id ? data : ch)

    removeChapter = id => this.chaptersData = this.chaptersData.filter(ch => ch.id !== id)

    getMaxPosition = () => {
        const ids = this.chaptersData.map(ch => ch.position)
        const max = Math.max(...ids)
        return max + 1
    }

    get book() {
        return toJS(this.bookData)
    }

    get bookDocs() {
        return toJS(this.docsData).sort((a, b) => a.id > b.id ? -1 : 1)
    }

    get bookChapters() {
        return [...this.chaptersData].sort((a, b) => a.position > b.position ? 1 : -1)
    }
}

export default new EbookBookStore()

