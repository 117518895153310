import React from 'react'
import {findLast} from 'helpers'
import {timeConverter} from '../../Online-schedule/components/functions'
import './Tracker.scss'

export default function Tracker(props) {

    const getCleanDays = () => props.days
      .filter(x => x.tracks.length > 0)
      .map(day => ({
          ...day,
          tracks: day.tracks.sort((a, b) => new Date('2020-01-01 ' + a.in_time) > new Date('2020-01-01 ' + b.in_time) ? 1 : -1)
      }))

    const checkNotFair = (tracks, vector) => {
        let result = true
        if (props.days && tracks.length > 0) {
            let currDate = tracks[0].day
            let thisDay =  props.days.find(x => x.date === currDate)
            if(thisDay){
                let first_in = new Date(`${currDate} ${tracks[0].in_time}`)
                let last_out = new Date(`${currDate} ${findLast(tracks)?.out_time}`)
                let true_in = new Date(`${currDate} ${thisDay.start_at}`)
                let true_out = new Date(`${currDate} ${thisDay.finish_at}`)

                if (vector === -1) {
                    if (first_in > true_in) result = false
                } else if (vector === 1) {
                    if (last_out < true_out) result = false
                } else {
                    if (first_in > true_in) result = false
                    if (last_out < true_out) result = false
                }
            }
        }
        return result
    }

    const getMapLink = (lat, long) => `https://yandex.ru/maps/?ll=${long}%2C${lat}&mode=search&sll=${long}%2C${lat}&text=${lat}%2C${long}&z=15`

    return (
        <div className="trackerBox">
            <div className="trackerBox__header">
                <div/>
                <div>Приход</div>
                <div>Уход</div>
                <div>Причина</div>
                <div>Отработано</div>
            </div>

            {getCleanDays().map((day, index) =>
                <div className="trackerBox__line" key={index}>
                    <div className={'trackerBox__date' + (checkNotFair(day.tracks) ? '' : ' trackerBox__date--red')}>
                        {timeConverter(day.date, 'ddd')}
                    </div>

                    <div className="trackerBox__tracks">
                        {day.tracks.map((track, index) =>
                            <div className="trackerBox__track" key={index}>
                                <div>
                                    <span className={'trackerBox__time' + ((index === 0 && !checkNotFair(day.tracks, -1)) ? ' trackerBox__time--red' : '')}>
                                        {track.in_time ? track.in_time.slice(0, 5) : 'Не определено'}
                                    </span>
                                    <span>{track.in_location_name}</span>
                                    {/*<span>{tr.in_latitude ? tr.in_latitude.slice(0, 8) : 'Не определено'} | {tr.in_latitude ? tr.in_longitude.slice(0, 8) : 'Не определено'}</span>*/}
                                    {(track.in_latitude && track.in_longitude) ?
                                        <a href={getMapLink(track.in_latitude, track.in_longitude)} target="_blank">
                                            {track.in_latitude.slice(0, 8)} | {track.in_longitude.slice(0, 8)}
                                        </a> :
                                        <span>Не определено</span>}
                                </div>
                                <div>
                                    <span className={'trackerBox__time' + (index === 0 && !checkNotFair(day.tracks, 1) ? ' trackerBox__time--red' : '')}>
                                        {track.out_time ? track.out_time.slice(0, 5) : 'Не определено'}
                                    </span>
                                    <span>{track.out_location_name}</span>
                                    {/*<span>{tr.out_latitude ? tr.out_latitude.slice(0, 8) : 'Не определено'} | {tr.out_latitude ? tr.out_longitude.slice(0, 8) : 'Не определено'}</span>*/}
                                    {(track.out_latitude && track.out_longitude) ?
                                        <a href={getMapLink(track.out_latitude, track.out_longitude)} target="_blank">
                                            {track.out_latitude.slice(0, 8)} | {track.out_longitude.slice(0, 8)}
                                        </a> :
                                        <span>Не определено</span>}
                                </div>
                                <div className="trackerBox__reason">{track.reason && track.reason}</div>
                            </div>
                        )}
                    </div>

                    <div className="trackerBox__worked">{props.getWorkedTime(day.tracks)}</div>
                </div>
            )}
        </div>
    )
}
