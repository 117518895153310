import {useEffect, useState} from "react";
import {superAxiosRequestEasy} from "axiosApi";
import Stores from "Stores";


export const getLang = () => {
  let lang;
  if (Stores.profileId){
    const ud = JSON.parse(localStorage.getItem('ud'))
    lang = ud.user_data.profile.localization
    lang = lang.toLowerCase()
  } else {
    lang = localStorage.getItem('guest_language') || 'en'
  }
  return lang;
}

const getCache = () => JSON.parse(localStorage.getItem('localization'))

const addCache = (lang, section, subsection, data) => {
  const cache = getCache()
  const newCache = {
    ...cache,
    [lang]: {
      ...cache?.[lang],
      [section]: {
        ...cache?.[lang]?.[section],
        [subsection]: data
      }
    }
  }
  localStorage.setItem(
    'localization',
    JSON.stringify(newCache)
  )
}

const extendTranslateFromDict = (base, dict) => {
  if (dict) {
    Object.keys(dict).forEach(key => {
      base[key] = base[key] ?? dict[key]
    })
  }
  return base
}


export default function useLocalization({section, subsection, dict}){
  const [translate, setTranslate] = useState({})

  useEffect(() => {
    const lc = getLang()

    // Если есть в каше)
    if (getCache()?.[lc]?.[section]?.[subsection]){
      const trans = getCache()[lc][section][subsection]
      setTranslate(extendTranslateFromDict(trans, dict))
    } else {
      superAxiosRequestEasy({
        method: 'get',
        link: 'localization/translate',
        params: {
          section,
          subsection,
          lc
        }
      }).then(({data}) => {
        const trans = extendTranslateFromDict(data.localization, dict)
        setTranslate(trans)
        addCache(lc, section, subsection, trans)
      }).catch(() => {
        setTranslate(dict || {})
      })
    }
  }, [section, subsection])


  return {translate}
}
