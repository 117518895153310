import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {checkUser} from 'axiosApi';
import {checkPermList} from 'helpers';
import {permissions} from 'settings/permissions';
import LocalStorage from 'services/LocalStorage';
import Stores from 'Stores';
import ContentBox from 'components/ContentBox/ContentBox';
import UserLogin from 'components/Modules/Auth/UserLogin/UserLogin';
import InitTest from '../Modules/Study/InitTest/InitTest';
import ProtectedRoute from "Router/ProtectedRoute";
import {routes} from 'Router/routes';
import stWrapperAuth from "components/Wrappers/WrapperAuth.module.scss"


const AuthorizedWrapper = props => {
  if (LocalStorage.getSimple('isAuth') && checkUser()) {
    Stores.Rules.getRulesList();
    Stores.Rules.getPermissionsList();
  } else {
    Stores.Rules.getPermissionsList();
  }

  const baseUI = LocalStorage.getSimple('isAuth') && checkUser() ?
      <div className="wrapper">
          <ContentBox {...props} />
      </div> :
    <>
      <Route render={() => <div className={stWrapperAuth.page}><UserLogin/></div>}/>
      {routes.protected.map((el, idx) => <ProtectedRoute {...el} key={idx}/>)}
    </>

  const placementTest = checkPermList(permissions.view_init_test) ?
    <Route path='/init-placement-test/:id' component={InitTest} /> :
    <Redirect to="/" />
  
  const getWrapper = (path) => {
    if (path.includes('init-placement-test')) {
      return placementTest;
    } else {
      return baseUI;
    }
  }
  return (
      getWrapper(window.location.pathname)
  )
}

export default AuthorizedWrapper
