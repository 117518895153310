import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import swal from 'sweetalert';
import Button from 'components/UI/Button/Button';
import Comment from './Comment';
import Select from 'components/UI/Select/Select';
import { clientDataTypes } from './ClientItem';
import slotsListingStore from 'Stores/slotsListingStore';
import styles from './ClientItem.module.scss';
import {localisationMaster} from "Localisation/Localisation";

const swalParams =   {
    icon: 'warning',
    buttons: [localisationMaster('text_5', 'online-schedule/future-slots'), 'OK'],
    dangerMode: true,
}

const docsButtons = [
  { key: '1', link: 'https://www.interpress.kz/phoenix/adult.pdf', name: 'Adult', lang: [1, 3] },
  { key: '2', link: 'https://www.interpress.kz/phoenix/t1-2.pdf', name: 'YL`s', lang: [1, 3] },
  { key: '3', link: 'https://www.interpress.kz/phoenix/t3-4.pdf', name: 'T1-2', lang: [1, 3] },
  { key: '4', link: 'https://www.interpress.kz/phoenix/yas.pdf', name: 'T3-4', lang: [1, 3] },
  { key: '5', link: 'https://www.interpress.kz/phoenix/yls.pdf', name: 'YAs', lang: [1, 3] },
  { key: '6', link: 'https://www.interpress.kz/phoenix/adults-german.pdf', name: 'Adult', lang: [2] },
];

const getFilteredDocsButtons = (id) => docsButtons.filter((item) => item.lang.includes(+id));

const getFormattedOptionLabel = (option, { context }) => (
  context === 'menu'
    ? <p>
        {option.label.split('/').map((text, index) => (
          <span className={styles.label_part} key={index.toString()}>
            {text}
          </span>
        ))}
      </p>
    : <span>{option.label}</span>
);

function AdminBody({data, index, }) {
  const {
    id,
    program_id,
    comment,
    zoom_url,
    is_missed,
    training_level_id,
  } = data;
  const {id: pageType} = useParams();
  const { levels, updateSlot } = slotsListingStore;
  const [isLoading, setIsLoading] = useState(false);

  const sendToServer = (body) => {
    setIsLoading(true);
    updateSlot(
      data.id,
      index,
      body,
      pageType,
      () => setIsLoading(false),
      () => {
        setIsLoading(false);
        return swal('', localisationMaster('text_24', 'online-schedule/future-slots'));
      }
    );
  };
    const handleLevelChange = (option) => {
    if (pageType === "future" && option.value == 0) {
        swal({...swalParams,title: localisationMaster('text_25', 'online-schedule/future-slots'), text: localisationMaster('text_26', 'online-schedule/future-slots'),}).then((response) => {
            if (!response) {
              return;
            }
            option.value !== 0
              ? sendToServer({training_level: option.value, is_missed: false})
              : sendToServer({is_missed: true});
          });
    }
    else {
        swal({...swalParams, title: localisationMaster('text_27', 'online-schedule/future-slots'), text: localisationMaster('text_28', 'online-schedule/future-slots'),}).then((response) => {
            if (!response) {
              return;
            }
            option.value !== 0
              ? sendToServer({training_level: option.value, is_missed: false})
              : sendToServer({is_missed: true});
          });
    }   
  };

  return <>
    <div className={styles.first_column}>
      <ul className={styles.docs_list}>
        {getFilteredDocsButtons(program_id).map((item) => (
          <li key={item.key} className={styles.docs_item}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.name}
            </a>
          </li>
        ))}
      </ul>
      {Boolean(zoom_url) && <Button classes={styles.zoom_button} onClick={() => {
        window.open(zoom_url, '_blank')
      }}>
        <span className={styles.zoom_text_long}>{localisationMaster('text_29', 'online-schedule/future-slots')}</span>
        <span className={styles.zoom_text_short}>{localisationMaster('button', 'online-schedule/future-slots')}</span>
      </Button>}
    </div>
    <div className={styles.second_column}>
      <Comment comment={comment} id={id} index={index}/>
      <Select
        options={levels}
        isDisabled={!comment || isLoading}
        placeholder={!comment ? localisationMaster('text_30', 'online-schedule/future-slots') : ('text_31', 'online-schedule/future-slots')}
        isLoading={isLoading}
        onChange={handleLevelChange}
        value={is_missed ? levels[0].options[0] : levels[1].options.find((item) => item.value === training_level_id)}
        formatOptionLabel={getFormattedOptionLabel}
      />
    </div>
  </>
}

AdminBody.propTypes = {
  data: PropTypes.shape(clientDataTypes).isRequired,
  index: PropTypes.number.isRequired,
};

export default observer(AdminBody);
