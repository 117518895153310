import {action, computed, makeObservable, observable, toJS} from "mobx";
import {superAxiosRequest} from "axiosApi";
import {calcPayDate} from "../utils";
import {ActionType, getOptions} from "Stores/slotsNewRecordOnlineStore";
import LocalStorage from "services/LocalStorage";

class SlotsNewRecordLessonStore {
  link = 'online_schedule/online_record_lesson'

  company = 1

  locations = []
  programs = []
  ages = []
  dates = []
  slots = []
  employees = []
  banks = [];

  selectedLocation = null
  selectedProgram = null
  selectedAge = null
  selectedDates = new Set()
  timeZone = LocalStorage.get('ud')?.user_data?.profile?.time_zone || 0
  toggles = null
  selectedSlots = new Set()
  paymentType = null // 1 - платный, 2 - по смс, 3 - через админа, 4 - по емайл, 5 - без подтверждения; 6 - платный по локациям
  paymentTime = null
  payDate = null
  paymentStep = 1
  bank = null
  sum = 0

  slotClientID = null
  order = null
  isOrderPayed = false

  constructor() {
    makeObservable(this, {
      selectedLocation: observable,
      selectedProgram: observable,
      selectedAge: observable,
      selectedDates: observable,
      toggles: observable,
      employees: observable,
      slots: observable,
      selectedSlots: observable,
      paymentType: observable,
      sum: observable,
      bank: observable,
      paymentStep: observable,
      order: observable,

      isReadyToLoadCalendar: computed,
      selectedEmployee: computed,
      isSubmitAllowed: computed,
      getData: computed,

      setData: action.bound,
      resetData: action.bound,

      getOptionsFromServer: action.bound,
      getDatesFromServer: action.bound,
      getSlotsFromServer: action.bound,
      submitData: action.bound,
      checkPaymentStatus: action.bound,
    })
  }

  getOptionsFromServer(onFail, onFinally) {
    superAxiosRequest({
      method: 'get',
      link: this.link,
      params: {
        company: this.company,
        step: 1,
      },
    }).then(({data}) => {
      this.locations = getOptions(data.locations);
      this.programs = getOptions(data.programs);
      this.ages = getOptions(data.ages);

      const selected = {
        selectedLocation: this.locations[0] || null,
        selectedProgram: this.programs[0] || null,
        selectedAge: this.ages[0] || null,
      };
      Object.keys(selected).forEach(key => {
        this[key] = selected[key];
      })
    }).catch(({response}) => {
      onFail(response?.data?.detail || 'При загрузке страницы возникла ошибка, обновите страницу.')
    }).finally(() => {
      onFinally(false)
    });
  }

  getDatesFromServer(date, onFinally, onFail) {
    const apiConf = {
      method: 'get',
      link: this.link,
      params: {
        company: this.company,
        location: this.selectedLocation.value,
        program: this.selectedProgram.value,
        age: this.selectedAge.value,
        date_from: date,
        step: 2,
      },
    };
    superAxiosRequest(apiConf)
      .then(({data}) => this.dates = data)
      .catch(onFail)
      .finally(() => onFinally(false));
  }

  getSlotsFromServer(dateIndex) {
    const apiConf = {
      method: 'get',
      link: this.link,
      params: {
        company: this.company,
        location: this.selectedLocation.value,
        program: this.selectedProgram.value,
        age: this.selectedAge.value,
        date_from: this.dates[dateIndex].date_at,
        step: 3,
      },
    };
    superAxiosRequest(apiConf)
      .then(({data}) => {
        const {employees, slot_data, payment_time, payment_type, banks, location_slot_price} = data;

        this.employees = employees;
        this.toggles = {'is_online': slot_data[0]?.is_online, 'is_school': !slot_data[0]?.is_online};
        this.slots = slot_data;
        this.paymentTime = payment_time;
        this.paymentType = payment_type;
        this.payDate = calcPayDate(payment_time, this.timeZone);
        this.banks = banks;
        this.bank = banks[0].id;
        this.sum = location_slot_price / 100
      });
  }

  submitData(onFail) {
    superAxiosRequest({method: 'post', link: this.link}, this.getData)
      .then(({data}) => {
        this.slotClientID = data.slot_client_id;
        if (this.paymentType === 6) {
          if (this.getBank.name.indexOf('alyk') !== -1 && data.invoice_url) {
            window.location.href = data.invoice_url
          } else {
            this.order = data.order;
            this.paymentStep = 2;
          }
        }
      })
      .catch(({response}) => onFail(response?.data?.error || 'Что-то пошло не так 🙁'));
  }

  checkPaymentStatus() {
    superAxiosRequest({
      method: 'get',
      link: `payments/check_order/${this.order}`,
    }).then(({data}) =>this.isOrderPayed = data.paid_status);
  }

  setData(val, key) {
    this[key] = val;
  }

  resetData(type) {
    switch (type) {
      case ActionType.resetAll:
        this.selectedDates.clear();
        this.selectedSlots.clear();
        this.slots = [];
        break;
      case ActionType.resetSlots:
        this.slots = [];
        this.selectedSlots.clear();
        this.paymentType = null;
        this.paymentStep = 1;
        //this.isOrderPayed = false;
        break;
      default:
        return;
    }
  }

  get isReadyToLoadCalendar() {
    return Boolean(this.selectedLocation && this.selectedProgram && this.selectedAge);
  }

  get selectedEmployee(){
    return this.employees.find(emp => emp.slot_id === [...this.selectedSlots][0])
  }

  get isSubmitAllowed() {
    return this.paymentStep === 1
  }

  get getBank(){
    return this.banks.find(b => b.id === this.bank)
  }

  get getData() {
    return {
      program: this.selectedProgram?.value,
      age: this.selectedAge?.value,
      is_online: this.toggles?.is_online,
      slot_id: toJS(this.selectedSlots)[0],
      employee_slot: this.selectedEmployee?.employee_booked_slot_id,

      payment_type: this.paymentType,
      payment_date: this.payDate?.dateUTC,
      payment_time: this.payDate?.timeUTC,
      payment_comment: 'Онлайн урок',

      bank: this.bank,
      sum: this.sum * 100,
    }
  }
}

export default new SlotsNewRecordLessonStore()
