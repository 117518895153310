import React, {useRef} from 'react';
import {ReactComponent as SubmitReview} from "components/UI/icons/revision_info.svg";
import style from './ReviewInfoBlock.module.scss'
import DiscussionButton from "./DiscussionButton/DiscussionButton";

const ReviewInfoBlock = ({scrollToBlock}) => {

    return (
        <div className={style.container}>
            <div className={style.mainBlock}>
                <SubmitReview/>
                <span>Teacher Sent For Revision</span>
            </div>
            <DiscussionButton scrollToBlock={scrollToBlock}/>
        </div>
    );
};

export default ReviewInfoBlock;