import React, {useEffect, useState} from 'react';
import DocsViewer from '../DocsViewer/DocsViewer';


const DocsViewerContainer = (props) => {
    const [pageState, setPageState] = useState()

    let url = props.match.path
    const pages = {
        '/contract/viewer/:pageType/:id': {
            confirmedField: 'email_code_confirmed',
            templateField: 'template',
            userField: 'employee',
            baseLink: '/contract',
            apiLinks: {
                template: 'documents/template_constructor_employees',
                contract: 'documents/employees_signing_document'
            },
            ...props
        },
        '/docs/viewer/:pageType/:id': {
            confirmedField: 'sms_code_confirmed',
            templateField: 'document_flow',
            userField: 'client',
            baseLink: '/docs',
            apiLinks: {
                template: 'documents/template_constructor',
                contract: 'documents/client_signing_document'
            },
            ...props
        }
    }

    useEffect(() => {
        setPageState(pages[url])
    }, [url])

    return (
        <>
            {pageState && <DocsViewer {...pageState} />}
        </>
    )
}

export default DocsViewerContainer