import React from 'react';
import PropTypes from "prop-types";
import {classNames as cn} from "helpers";
import styles from './TabMenu.module.scss'

const TabMenu = (props) => {
  return (
    <div className={styles.tabMenu}>
      <div className={styles.tabs}>
        {props.tabs.map(tab =>
          <div
            key={tab.id}
            className={cn({
              [styles.tab]: true,
              [styles.active]: tab.id === props.tabID
            })}
            onClick={() => props.onChange(tab)}
          >
            {tab.name}
          </div>
        )}
      </div>
    </div>
  )
};

TabMenu.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  tabID: PropTypes.number,
  onChange: PropTypes.func.isRequired
}
export default TabMenu;