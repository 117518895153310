import React, { } from 'react'
import useTableFixedHeader from '../../../../../hooks/useTableFixedHeader'
import styles from "./CheckReportTable.module.scss"
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import {splitNumberDigits} from "helpers/math";

const CheckReportTable = ({checkTableData, online, firstLoad}) => {
    const onlineTemplate = {
        2: `Все группы (Онлайн + офлайн)`,
        1: 'Онлайн',
        0: 'Офлайн'
    }
    const {
        refWrapper,
        refSticky,
        refThead,
    } = useTableFixedHeader()

    
    return (
            <div ref={refWrapper} className={st.tableWrapper}>
                <div ref={refSticky} className={st.sticky}>
                    <table className={st.table}>
                        <thead >
                            <tr className={styles.headRow}>
                                <th className={styles.headCol}><div className={styles.itemHead}>Школа</div></th>
                                <th className={styles.headCol}><div className={styles.itemHead}>Тип программы</div></th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead} >Приход студентов</div></th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead} >Средний приход</div></th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead}>Списание со студентов</div> </th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead}>Среднее списание</div> </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <table className={styles.table} >
                    <thead ref={refThead} >
                        <tr className={styles.headRow}>
                            <th className={styles.headCol}><div className={styles.itemHead}>Школа</div></th>
                            <th className={styles.headCol}><div className={styles.itemHead}>Тип программы</div></th>
                            <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead} >Приход студентов</div></th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead} >Средний приход</div></th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead}>Списание со студентов</div> </th>
                                <th className={styles.headCol} style={{width: '14%'}}><div className={styles.itemHead}>Среднее списание</div> </th>
                        </tr>
                    </thead>

                    <tbody>
                        <>
                        {<>
                                <tr className={styles.row_result} >
                                    <td colSpan='2' className={styles.col_result} style={{border: 'none'}}> Итого (клиентов {splitNumberDigits(checkTableData.clients_amount)}) </td>
                                
                                    <td className={styles.col} style={{border: 'none'}} >
                                        <div className={styles.item}>
                                            {splitNumberDigits((checkTableData.all_debit / 100).toFixed(2))}
                                        </div>
                                    </td>
                                    <td className={styles.col} style={{border: 'none', textAlign: 'center '}} >
                                        {splitNumberDigits((checkTableData.resultAverageCredit / 100).toFixed(2))}
                                    </td>
                                    <td className={styles.col} style={{border: 'none'}} >
                                        <div className={styles.item}>
                                            {splitNumberDigits((checkTableData.all_credit  / 100).toFixed(2))}
                                        </div>
                                    </td>
                                    <td className={styles.col} style={{border: 'none', textAlign: 'center '}} >
                                        {splitNumberDigits((checkTableData.resultAverageDebit / 100).toFixed(2))}
                                    </td>
                                </tr>
                                <tr className={styles.row_result} >
                                    <td colSpan='2' className={styles.col_result} style={{border: 'none'}}> Средние показатели </td>
                                    <td className={styles.col} style={{border: 'none'}} >
                                        <div className={styles.item}>
                                            {splitNumberDigits((checkTableData.average_debit  / 100).toFixed(2))}
                                        </div>
                                    </td>
                                    <td className={styles.col} style={{border: 'none'}} ></td>
                                    <td className={styles.col} style={{border: 'none'}} >
                                        <div className={styles.item}>
                                            {splitNumberDigits((checkTableData.average_credit / 100).toFixed(2))}
                                        </div>
                                    </td>
                                    <td className={styles.col} style={{border: 'none'}} ></td>
                                </tr>
                            </>
                            }
                            {checkTableData.locations.map((location, id) =>
                                location.data.map((rowData, i) =>
                                    <tr 
                                        key={i} 
                                        className={`${i % 2 !== 0 ? styles.row_grey : ''} ${(i === location.data.length - 1 && id === checkTableData.locations.length - 1) ? styles.last : ''}`}
                                    >
                                        {i === 0 && <td rowSpan={location.data.length}
                                            className={styles.col_result} >
                                            <div className={styles.item__result}>
                                                {location.title && <div className="">{location.title}</div>}
                                                <div className={styles.box__online}>{onlineTemplate[online]}</div>
                                            </div>
                                        </td>}
                                        <td className={styles.col_result} >
                                            {rowData.title}
                                        </td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                {splitNumberDigits((rowData.debit / 100).toFixed(2))}
                                            </div>
                                        </td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                {splitNumberDigits((rowData.aver_debit / 100).toFixed(2))}
                                            </div>
                                        </td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                {splitNumberDigits((rowData.credit / 100).toFixed(2))}
                                            </div>
                                        </td>
                                        <td className={styles.col} >
                                            <div className={styles.item}>
                                                {splitNumberDigits((rowData.aver_credit / 100).toFixed(2))}
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                        </>
                    </tbody>
                </table >
            </div>

           

    )
}

export default CheckReportTable;




