import React, {useState} from 'react';
import Stores from 'Stores';
import './QuestionAudios.scss'
import menu_icon from '../../img/menu.svg'

export default function QuestionAudios(props) {
    const [shower, setShower] = useState(null)
    return (
        <>
            <div>
                {props.items.map((item, index) =>
                    <div className="QuestionAudios__body" key={index}>
                        <div className="QuestionAudios__body__audioBox">
                            <audio
                                controls
                                src={Stores.baseLink() + item.file}>
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                            <div className="QuestionAudios__body__audioBox--script">
                                <img src={menu_icon} alt="menu_icon" onMouseOver={() => setShower(index)}
                                     onMouseOut={() => setShower(null)}/>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: item.description}} className="QuestionAudios__audioDescription"/>
                        {shower === index &&
                            <div className="QuestionAudios__scriptBody">
                                <p>Script</p>
                                <p>{item.script}</p>
                            </div>}
                    </div>
                )}
            </div>
            <div className="lessonQuestion__contentTextOnly" dangerouslySetInnerHTML={{__html: props.text}}/>
        </>
    )
}
