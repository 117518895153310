import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {localisationMaster} from "Localisation/Localisation";
import {translate as loc, dict} from "Localisation";
import asideModalStore from "Stores/asideModalStore";
import SelectDateBirth from "components/ContentBox/Global/SelectDateBirth/SelectDateBirth";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import FormField from "../Form/FormField";
import FormLabel from "../Form/FormLabel";
import FormInput from "../Form/FormInput";
import FormFieldset from "../Form/FormFieldset";
import FormSelectCustom from "../Form/FormSelectCustom";


const EmployerWorkInfo = observer(() => {
  const [catalogs, setCatalogs] = useState([false, []])

  const {changeValue, modalValues, check} = asideModalStore

  useEffect(() => {
    superAxiosRequest({
      method: 'get',
      link: 'accounts/employers_catalog'
    }).then(res => {
      setCatalogs([true, res.data])
    }).catch(() => {
      setCatalogs([true, []])
    });
  }, [])

  const returnEmployment = id => {
    let arr = catalogs[1].professions.employments.filter(x => x.id === id)
    return arr.length > 0 ? arr[0].professions : []
  }
  const returnLocation = id => {
    let arr = catalogs[1].companies.items.filter(x => x.id === id)
    return arr.length > 0 ? arr[0].locations : []
  }

  return (
    <FormFieldset>
      <FormField>
        <FormLabel isRequired>{loc(dict.main_page.company)}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={localisationMaster('text_21', 'modal_templates')}
            options={catalogs[0] ? catalogs[1].companies.items : []}
            valueID={modalValues.company || ''}
            onChange={o => changeValue(o.id, 'company')}
            error={check('company')}
            isDisabled={true}
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel isRequired>{loc(dict.main_page.location)}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={localisationMaster('text_2', 'modal_templates')}
            options={catalogs[0] ? returnLocation(modalValues.company) : []}
            valueID={modalValues.location || ''}
            onChange={o => changeValue(o.id, 'location')}
            noOptionsMessage={() => loc(dict.main_page.select_company_first)}
            error={check('location')}
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel isRequired>{loc(dict.main_page.department)}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={loc(dict.main_page.department)}
            options={catalogs[0] ? catalogs[1].professions.employments : []}
            sortKey="name"
            valueID={modalValues.employment || ''}
            onChange={o => changeValue(o.id, 'employment')}
            error={check('employment')}
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel isRequired>{loc(dict.main_page.position)}:</FormLabel>
        <FormSelectCustom>
          <SelectCustom
            placeholder={loc(dict.main_page.position)}
            options={catalogs[0] ? returnEmployment(modalValues.employment) : []}
            sortKey="name"
            noOptionsMessage={() => loc(dict.main_page.select_department_first)}
            valueID={modalValues.profession || ''}
            onChange={o => changeValue(o.id, 'profession')}
            error={check('profession')}
          />
        </FormSelectCustom>
      </FormField>

      <FormField>
        <FormLabel isRequired>{loc(dict.main_page.start_date)}:</FormLabel>
        <FormInput>
          <SelectDateBirth
            noOptionsMessage={() => 'Сначала выберите дату'}
            error={check('work_at')}
            value={modalValues.work_at} onChange={sDate => {
            sDate && changeValue(sDate, 'work_at')
          }}/>
        </FormInput>
      </FormField>

      <FormField>
        <FormLabel>{loc(dict.main_page.comment)}:</FormLabel>
        <FormInput>
            <textarea onChange={e => changeValue(e.target.value, 'comment')}
                      value={modalValues.comment}
                      placeholder={localisationMaster('comment', 'online-schedule/future-slots')}
            />
        </FormInput>
      </FormField>

    </FormFieldset>
  )
})
export default EmployerWorkInfo
