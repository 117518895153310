import React, {useEffect, useRef} from 'react';

const TextField = ({value}) => {
    const ref = useRef()

    useEffect(() => {
        ref.current.innerHTML = value
    }, [value])

    return (
        <div ref={ref}></div>
    )
}

export default TextField