import React from "react";
import {classNames as cn} from "helpers";
import {Link} from "react-router-dom";
import {SuperButton} from "components/UI/SuperButton/SuperButton";
import st from "./EbookHomeworkStudent.module.scss";

const EbookHomeworkStudent = ({homework, student: {mark, ...student}}) => {
  const renderMark = () => {
    if (mark === null) return <span className={`${st.markLabel} ${st.markLabelRevision}`}>Revision</span>
    if (!homework.hash) return <span className={`${st.markLabel} ${st.markLabelConfirm}`}>Confirmed</span>
    switch (homework.company_mark_type){
      case 1:
        return mark === 0 ?
          <span className={`${st.markLabel} ${st.markLabelFail}`}>Failed</span> :
          <span className={`${st.markLabel} ${st.markLabelConfirm}`}>Confirmed</span>
      case 2:
        return <span className={cn({
          [st.numberMark]: true,
          [st.numberMarkMax]: mark > 3,
          [st.numberMarkMid]: mark === 3,
          [st.numberMarkMin]: mark < 3
        })}>{mark}</span>
      case 3:
        return <span>{mark}</span>
      case 4:
        return <span>{mark}</span>
      default:
        return null
    }
  }

  return (
    <div className={st.studentRow}>
      <div className={st.studentName}>
        <Link to={`/account_clients/${student.user_id}`} target="_blank">{student.full_name}</Link>
      </div>
      <div className={st.result}>{renderMark()}</div>
      <div className={st.cellButton}>
        <SuperButton variant="redirect" type="linkColored" path={`/ebooks/homework/${homework.hash}`}>
          View
        </SuperButton>
      </div>
    </div>
  )
}

export default EbookHomeworkStudent;
