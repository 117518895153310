import React from 'react';
import StudyStore from "Stores/studyStore";
import {TestSectionControlPanel} from "./TestSectionControlPanel/TestSectionControlPanel";
import st from "./TestCategory.module.scss";
import {observer} from "mobx-react";

const TestCategory = ({filterTestQuestions, sectionName, sectionId, percentage, questionsCount}) => {
    const {editSection, deleteSection} = StudyStore
    const action = type => {
        switch (type) {
            case 'edit':
                editSection(sectionId)
                break;
            case 'delete':
                deleteSection(sectionId)
                break;
            default:
                break;
        }
    }

    return (
        <div className={st.section}>
            <span className={st.name}>{sectionName}</span>
            <div className={st.questionsBlock}>
                <span>Questions</span>
                <div className={st.questionsInfo}>
                    <span className={st.questionsCount}>{questionsCount}</span>
                    <span className={st.show} onClick={() => filterTestQuestions(sectionId)}>(<span className={st.showText}>show</span>)</span>
                </div>
            </div>
            <span className={st.percent}>{`${percentage.toFixed(1)}%`}</span>
            {sectionId && <TestSectionControlPanel action={action} className={st.active}/>}
        </div>
    );
};

export default observer(TestCategory);