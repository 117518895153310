import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {superAxiosRequest} from "axiosApi";
import {dateFormat} from "helpers/date";
import modalStore from "Stores/modalStore";
import groupStore from "Stores/groupStore";
import st from './StudentSense.module.scss'
import {localisationMaster} from "Localisation/Localisation";

const StudentSense = props => {
    const [rows, setRows] = useState([])

    const fetchRows = () => {
        superAxiosRequest({
            method: 'get',
            link: props.isNewSchedule ?
              'schedule/student_in_ws_confirmation_required' :
              'schedule/student_in_class_confirmation_required',
            params: {
                group: +props.gid
            }
        }).then(res => {
            setRows(res.data.result)
        })
    }

    const openModal = item => {
        const ms = {
            link: props.isNewSchedule ? 'schedule/update_student_presence_ws' : 'schedule/update_student_presence',
            type: 'group_student_sense',
            title: `${localisationMaster('text_3', 'groups/group')}, ${dateFormat(`${item.date}T12:00:00`, 'dd MMM YYYY')}`,
            button: localisationMaster('text_4', 'groups/group'),
            func: () => {
                fetchRows()
                groupStore.fetchGroup(+props.gid)
            }
        }
        const md = {
            employee_report: item.id,
            confirmed: true,
            comment: '',
            students: []
        }
        modalStore.activity(ms, md)
    }

    useEffect(fetchRows, [])

    return (
        rows.length > 0 && Boolean(rows.find(item => new Date(new Date(item.date).setHours(23, 59)) > new Date())) &&
        <div className="group-simple-main-box__card">
            <div className="group-simple-main-box__card-header">{localisationMaster('text_105', 'groups/all-groups')}</div>
            <div>
                <ul className={st.rows}>{rows.map(row =>
                    new Date(new Date(row.date).setHours(23, 59)) > new Date() &&
                    <li key={row.id} className={st.row}>
                        <span>{dateFormat(`${row.date}T12:00:00`, 'dd MMMM YYYY')}</span>
                        <button className={st.button} onClick={() => openModal(row)}>{localisationMaster('text_5', 'groups/group')}</button>
                    </li>)}
                </ul>
            </div>
        </div>

    );
}

StudentSense.propTypes = {
    gid: PropTypes.number
}

export default StudentSense
