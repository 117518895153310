import React from 'react';
import PropTypes from 'prop-types';
import {classNames as cn} from 'helpers';
import {findSelectedOption, paymentTypeOptions} from "../Tabs";
import slotsSettingsStore from 'Stores/slotsSettingsStore';
import Select from 'components/UI/Select/Select';
import InputPrice from 'components/UI/InputPrice/InputPrice';
import styles from './Employee.module.scss';


function Employee({
  onAdd,
  onRemove,
  employeeID,
  paymentTypeID,
  price,
  id,
  isLoading,
  mandatory,
}) {
  const {employeesOptions, setPaymentOptions, setPaymentsEmployeesOptions} = slotsSettingsStore;
  const normalizedPrice = price / 100;
  const employee = findSelectedOption(employeeID, employeesOptions);
  const paymentType = findSelectedOption(paymentTypeID, paymentTypeOptions);

  const handlePriceChange = (value) => {
    const cents = value * 100;
    id === 0
      ? setPaymentOptions('sum', cents)
      : setPaymentsEmployeesOptions(id, 'sum', cents);
  };
  const handleSelectChange = (value) => {
    setPaymentsEmployeesOptions(id, 'employee_id', value.value);
  };
  const handlePaymentTypeChange = (value) => {
    id === 0
      ? setPaymentOptions('payment_type_slots', value.value)
      : setPaymentsEmployeesOptions(id, 'payment_type_slots', value.value);
  };

  return (
    <li className={styles.grid}>
      <div className={styles.grid__employees}>
        {mandatory
          ? <p className={styles.default_wrapper}>
              <span className={styles.default_label}>Все сотрудники: </span>
              <span className={styles.default_employee}>Все сотрудники</span>
            </p>
          : <Select
              placeholder='Все сотрудники'
              options={employeesOptions}
              value={employee}
              isLoading={isLoading}
              isError={!employee}
              onChange={handleSelectChange}
            />
        }
      </div>
      <div className={styles.grid__price}>
        <InputPrice
          label={mandatory ? 'Стоимость' : ''}
          value={normalizedPrice}
          currency='тг.'
          onChange={handlePriceChange}
          isError={!normalizedPrice}
        />
      </div>
      <div className={styles.grid__type}>
        <Select
          label={mandatory ? 'Тип стоимости' : ''}
          options={paymentTypeOptions}
          value={paymentType}
          onChange={handlePaymentTypeChange}
        />
      </div>
      <div className={cn({
        [styles.grid__buttons]: true,
        [styles.grid__buttons_mandatory]: mandatory,
      })}>
        <button
          className={styles.button}
          type='button'
          onClick={onAdd}
          aria-label='Добавить сотрудника'
        />
        {!mandatory &&
          <button
            className={`${styles.button} ${styles.button__remove}`}
            type="button"
            onClick={onRemove}
            aria-label="Удалить сотрудника"
            data-id={id}
          />
        }
      </div>
    </li>
  );
}

Employee.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  employeeID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['all']),
  ]),
  paymentTypeID: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool,
  mandatory: PropTypes.bool,
};

Employee.defaultProps = {
  mandatory: false,
  isLoading: true,
};

export default Employee;
