import React, { useState, useEffect } from 'react';
import Checkbox from "../../Global/Checkbox";
import "./QuestionTest.scss"
import imgStopWatch from "components/ContentBox/img/stopwatch.png"
import Stores from 'Stores';
import { ReactComponent as TrueAnswer } from "components/ContentBox/img/true.svg";
import { ReactComponent as WrongAnswer } from "components/ContentBox/img/false.svg";
import { stringToBoolean } from 'helpers';

export default function QuestionTest(props) {
    const [isTime, setIsTime] = useState(props.isTimer || false)
    const [activeTry, setActiveTry] = useState(1)
    const [answers, setAnswers] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    const handleAnswers = (answ, ind) => {
        let arr = answers
        arr.includes(`${ind}-${answ}`) ?
            (arr = arr.filter(n => n !== `${ind}-${answ}`)) :
            (arr.push(`${ind}-${answ}`))
        setAnswers([...arr])
    }

    const startTest = () => {
        setIsTime(false)
        props.setTimer(props.time)
    }

    const checkAnswers = (q, a) => {
        const keys = a.map(el => Number(el.split('-')[0]))
        for(let k in q) {
            if(!keys.includes(Number(k))) return false;
        }
        return true;
    }

    useEffect(() => {
        props.try !== activeTry && setAnswers([])
        setActiveTry(props.try)
    }, [props.try])

    useEffect(() => {
        setAnswersButton(checkAnswers(props.questions, answers))
        props.handleAnswers(answers, answersButton)
    }, [answers, answersButton])

    const checkUserAnswer = (answer) => {
        if (!props.at) return false;
        let s_answers = props.at.asw_student.split(/[,|]+/)
        let list = props.at.is_correct.split(/[,|]+/)
            .map(x => s_answers.includes(x.split('-')[0]) ? ({ id: +x.split('-')[0], is_correct: stringToBoolean(x.split('-')[1]) }) : false)
            .filter(x => !!x)
        let a = list.find(x => x.id === answer.id)
        return a !== undefined
            ?
            <div className="questionTest_variant">
                <div>
                    {a.is_correct ? <TrueAnswer /> : <WrongAnswer />}
                </div>
                <div style={{fontWeight: 700}}>
                    {answer.text}
                </div>
            </div>
            : 
            <div className="questionTest_variant">
                <div className="questionTest_emptyBox"/>
                <div>
                    {answer.text}
                </div>
            </div>
    }

    return (
        <div className="questionTest">
            {props.isStudent && isTime ?
                (
                    props.questions ?
                        <div className="questionTest__timerBox">
                            <img src={imgStopWatch} alt="" />
                            <h3>Тест на время</h3>
                            <div className="questionTest__timerBoxCols">
                                <div>
                                    <span>Количество вопросов</span>
                                    <b>{props.questions.length}</b>
                                </div>
                                <div>
                                    <span>Время на выполнение</span>
                                    <b>{props.time}</b>
                                </div>
                            </div>
                            <button onClick={startTest}>Начать тест</button>
                        </div> :
                        null
                )
                :
                (
                    props.questions.map((question, index) =>
                        <div key={index}>
                            <p className="questionTest__questionText">{question.text}</p>
                            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) ?
                                <div className="questionTest__answers">{question.variants.map(answer =>
                                    <Checkbox
                                        key={answer.id}
                                        id={`${Date.now()}answer${answer.id}`}
                                        label={answer.text}
                                        isChecked={() => { handleAnswers(answer.id, index) }} />)}
                                </div> :
                                null
                            }

                            {((props.is_student_checked_try || (props.is_teacher_view && props.at)) &&
                                props.at) ?
                                <div className="questionTest__answers">{question.variants.map(answer =>
                                    <div key={answer.id}>
                                        {
                                            !Stores.isClient
                                                ?
                                                checkUserAnswer(answer)
                                                :
                                                <Checkbox
                                                    id={`${Date.now()}answer${answer.id}`}
                                                    label={answer.text}
                                                    isChecked={() => !props.at && handleAnswers(answer.id, index)}
                                                    checked={props.answ_array.includes(answer.id.toString())}
                                                //extraClass={ correctStyleLessons(props.at.is_correct.includes(`${answer.id}-true`))}
                                                />
                                        }

                                    </div>
                                )}
                                </div> :
                                null
                            }
                        </div>)
                )}
        </div>
    );
};