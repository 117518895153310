import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {permissions} from 'settings/permissions';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from 'Localisation/Localisation';
import {checkPermList} from "helpers";
import {dateFormat} from "helpers/date";
import Stores from 'Stores';
import groupStore from 'Stores/groupStore';
import modalStore from "Stores/modalStore";
import OpenHeader from "../OpenHeader";
import style from './StudentsProgressReports.module.scss'
import StudentsProgressReport from "./StudentsProgressReport/StudentsProgressReport";
import Rating from "components/ContentBox/Global/GlobalModalSingle/templates/groups/Rating";
import AverageProgressReport from "./AverageProgressReport/AverageProgressReport";
import TabMenu from "../../../Ebook/Common/TabMenu/TabMenu";
import {ReactComponent as EmptyData} from "components/ContentBox/img/empty.svg";
import HeaderContentBox from "../HeaderContentBox";
import useLocalization from 'hooks/useLocalization';

const menu = [
  {name: localisationMaster('text_1', 'company/locations'), action: 'edit', perm: permissions.edit_propgress_report},
  {name: localisationMaster('text_13', 'lessons/course'), action: 'delete', perm: permissions.delete_propgress_report},
]

const getMonth = (date) => {
  return dateFormat(new Date(date), 'MMM, YYYY')
}
const extractMonthYearFromDateArray = (dateArray) => {
  const extractedArray = dateArray.map((item) => {
    const date = new Date(item.evaluation_date);
    return dateFormat(new Date(date), 'MMM, YYYY')
  });
  const arr = [...new Set(extractedArray.reverse())]
  return arr.map((item,index) => ({id: index+1, name: item}))
}

const StudentsProgressReports = observer(() => {

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'group_rating_select',
    dict: {
      "students_progress_report": "Students Progress Report",
      "progress_report": "Progress report",
      "save": "Save",
      "edit": "Edit",
      "delete": "Delete",
      "student": "Student",
      "students": "Students",
    }
  })


  const {group} = groupStore
  const tabs = extractMonthYearFromDateArray(group.progress_report.filter(item=> item.is_active))
  const [open, setOpen] = useState(false)
  const [activeMonth, setActiveMonth] = useState(tabs[0])
 
  const getMonthData = (month) => {
    return group.progress_report.filter((item) => getMonth(item.evaluation_date) === month && item.is_active)
  }

  const monthData = getMonthData(activeMonth?.name)
  const actions = (data, e, item) => {
    switch (data) {
      case 'edit':
        let bo = {
          comment: item.comment,
          rate_list: item.rate_list.map(({evaluation_id, rating_id}) => ({evaluation_id, rating_id})),          
          client: item.client
        }
        const ms = {
          title: `${translate.progress_report}: ${Stores.transliterate(item.client_name)}`,
          button: translate.save ,
          modalBodyComponent: () => <Rating
            evaluationApiLink="progress_report/evaluation"
            ratingApiLink="progress_report/rating"
            user={{
              title: translate.student,
              placeholder: translate.students,
              key: 'client'
            }}/>,
          payload: {student_name: `${Stores.transliterate(item.client_name) }`},
          is_put: true,
          link: 'progress_report/report/' + item.id,
          func: (data) => groupStore.updateGroupData({progress_report: data.reports}),
          width: 800
        }
        modalStore.activity(ms, bo)
        break;
      case 'delete':
        const apiConf = {
          method: 'delete',
          link: 'progress_report/report/' + item.id,
        }
        superAxiosRequest(apiConf).then((res) => {
          groupStore.updateGroupData({progress_report: res.data.reports})
          setActiveMonth(tabs[0])
        }).catch(() => {
        })
        break;
      default:
        break;
    }
  }
  const openModalRating = () => {
    const ms = {
      title: translate.progress_report,
      button: translate.save,
      modalBodyComponent: () => <Rating
        evaluationApiLink="progress_report/evaluation"
        ratingApiLink="progress_report/rating"
        user={{
          title: translate.student,
          placeholder: translate.students,
          key: 'client'
        }}
      />,
      payload: {
        students: group.students.map(st => ({id: st.id, name: `${st.last_name} ${st.first_name}`}))
      },
      link: 'progress_report/report',
      valid: {
        client: 'required',
        rate_list: (rate_list) =>  rate_list.every((x) => x.rating_id !== null ) 
      },
      // func: () => fetchGroup(group.group.id),
      func: (data) => {
        groupStore.updateGroupData({progress_report: data.reports})
        setActiveMonth(tabs[0])
      },          
      width: 600,
    }
    modalStore.activity(ms, {
      group: group.group.id,
    })
  }

  const calculateAverageRating = (ratings) => (ratings.length > 0 ? ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length : 0);

  const clientData = monthData.reduce((result, { client_name: clientName, rate_list }) => {
    const rateValues = rate_list.map((item) => item.value);
    result[clientName] = result[clientName] || { totalRating: 0, totalCount: 0 };
    result[clientName].totalRating += calculateAverageRating(rateValues);
    result[clientName].totalCount += 1;
    return result;
  }, {});
  
  const averageRatings = Object.entries(clientData).map(([clientName, { totalRating, totalCount }]) => ({
    clientName,
    averageRating: totalCount !== 0 ? totalRating / totalCount : 0,
  }));
  

  return (
    <HeaderContentBox className={style.ratingList}>
      <OpenHeader title={translate.students_progress_report}
                  link={`/groups/reports/${group.group.id}`}
                  target={'_blank'}
                  onClick={() => setOpen(!open)}
                  addButton={checkPermList(permissions.add_progress_report) ? openModalRating : null}/>
      {open && <>
        {
          group.progress_report.length > 0 ?
              <div className={style.list}>
            <TabMenu tabs={tabs} onChange={(tab)=> setActiveMonth(tab)} tabID={activeMonth?.id}/>
            {
              averageRatings.map((item, index) => {
                return <AverageProgressReport key={index} data={monthData} activeMonth={activeMonth?.name} type='student' averageRatingPerMonth={item.averageRating}/>
              })
            }
            {monthData.map((item, index) =>
                item.is_active &&
                    <StudentsProgressReport key={index} item={item} menu={menu} actions={actions}/>
                )
            }
          </div> : <div className={style.empty}>
                <EmptyData/>
              </div>
        }
      </>}
    </HeaderContentBox>
  )
})

export default StudentsProgressReports
