import React, { useState, useEffect } from 'react';
import { localisationMaster } from "Localisation/Localisation";
import Checkbox from "../../Global/Checkbox";
import { replaceTime } from '../../../../helpers';

import './ScheduleSettingSlots.scss';

export default function ScheduleSettingLocation(props) {
    const Link = 'online-schedule/schedule-setting';

    const [idArray,] = useState([]);
    const [oneSlotElement, setOneSlotElement] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [booked, setbooked] = useState([false, []])



    const selSlotTime = id => {
        if (props.selectedSlotValue === id)
            props.selectedSlots(0);
        else
            props.selectedSlots(id);
    }

    let items;
    if (props.items !== undefined) {
        items = props.items.sort(function (a, b) {
            return a.id - b.id
        })
    }

    useEffect(() => {
        let arr = []
        if (props.items_checked !== undefined) {
            for (let i = 0; i < props.items_checked.length; i++) {
                arr.push(+props.items_checked[i].slot)
            }
            setbooked([true, arr])
        }
    }, [props.items_checked])

    useEffect(() => {
        if (!isLoaded) {
            setOneSlotElement(items)
            setIsLoaded(true)
        }
    }, [isLoaded, idArray, oneSlotElement])

    const replaceTimeZone = (array, tz, timeZone) => {
        let tmpArr = JSON.parse(JSON.stringify(array))
        for (let i = 0; i < tmpArr.length; i++) {
            tmpArr[i].time_start = replaceTime(tmpArr[i].time_start, tz, timeZone)
            tmpArr[i].time_end = replaceTime(tmpArr[i].time_end, tz, timeZone)
        }

        return tmpArr;
    }

    const itemsExp = items !== undefined && items.length > 0
    return (
        <div className={"scheduleSettingSlots " + (itemsExp ? "" : " scheduleSettingSlots--error")}>
            {itemsExp ?
                <div>
                    <div className="scheduleSettingSlots__header">
                        <h3>{localisationMaster('choice-slot', Link)}</h3>
                        <div>
                            <Checkbox id={Date.now()} label={localisationMaster('checkbox-info', Link)} checked={props.isOnline} isChecked={() => props.setIsOnline(!props.isOnline)} />
                        </div>
                    </div>
                    <div className="scheduleSettingSlots__slots">
                        {replaceTimeZone(items, props.initTz, props.timeZone).map((item, index) => {
                            if (!booked[1].includes(item.id)) {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => selSlotTime(item.id)}
                                        style={{ cursor: booked[1].includes(item.id) && 'default' }}
                                        className={"scheduleSettingSlots__slot " + (props.selectedSlotValue === item.id ? " scheduleSettingSlots__slot--active" : "")}
                                    >
                                        <span>{item.time_start + ' - ' + item.time_end /*+ ' (GMT' + tz_clean + time_zone + ')'*/}</span>
                                        {/*<div className="recordCustomerLocation">{isLocOnline(props.locationList, item.is_online)}</div>*/}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                :
                <div className="scheduleSettingSlots__emptySlotsMessage">
                    {localisationMaster('text-slot-info-4', Link)}
                </div>
            }
        </div>
    )
}
