import React, { useEffect } from 'react';
import Stores from 'Stores';
import { localisationMaster } from "Localisation/Localisation";
import { translate as loc, dict } from "Localisation";
import { classNames as cn } from "helpers";
import modalStore from "Stores/modalStore";
import { observer } from "mobx-react";
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import Flex from "components/UI/Flex/Flex";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import InputFlagPhone from "components/ContentBox/Global/InputPhone/InputFlagPhone/InputFlagPhone";
import DateTime from 'components/ContentBox/Global/DateTime/DateTime';
import './LocationForm.scss';

const LocationsForm = (props) => {
    const { changeValues, modalValue, check, changeValuesArray, data } = modalStore;

    useEffect(() => {
        if (modalValue.length === 0 && data.modal_settings.is_put) {
            changeValuesArray(props.fix_data)
        }
    }, []); 
  
    return (
        <div className="super-modal__form-column">
            <div className="super-modal__form-field">
                <div className="super-modal__form-label--required">{localisationMaster('table-row-title-1', 'company/programs')}:</div>
                <input
                    type="text"
                    placeholder={localisationMaster('table-row-title-1', 'company/programs')}
                    value={modalValue.name || ''}
                    onChange={e => changeValues(e.target.value, 'name')}
                    className={cn({
                        "super-modal__input": true,
                        "super-modal__input--error": check('name'),
                    })}
                />
            </div>
            <div className="super-modal__form-field">
                <div className="super-modal__form-label--required">{localisationMaster('input-placeholder-2', 'company/locations', 'add_location')}:</div>
                <input
                    type="text"
                    placeholder={localisationMaster('input-placeholder-2', 'company/locations', 'add_location')}
                    onChange={e => changeValues(e.target.value, 'address')}
                    value={modalValue.address || ''}
                    className={cn({
                        "super-modal__input": true,
                        "super-modal__input--error": check('address'),
                    })}
                />
                <Flex>
                    <CheckboxToggle
                        checked={modalValue.is_online}
                        onChange={() => changeValues(!modalValue.is_online, 'is_online')}
                        right={localisationMaster('checkbox-info', 'company/locations', 'add_location')}
                        error={check('is_online')}
                    />
                </Flex>
            </div>
            <div className="locationForm__wrapper">
                <div className="locationForm__field">
                    <div className="super-modal__form-label--required">{localisationMaster('name_4', 'company/locations', 'add_location')}:</div>
                    <InputFlagPhone
                        value={modalValue.home_phone}
                        onChange={val => changeValues(val, 'home_phone')}
                        error={check('home_phone')}
                    />
                </div>
                <div className="locationForm__field">
                    <div className="super-modal__form-label--required">{localisationMaster('name_5', 'company/locations', 'add_location')}:</div>
                    <InputFlagPhone
                        value={modalValue.phone}
                        onChange={val => changeValues(val, 'phone')}
                        error={check('phone')}
                    />
                </div>
            </div>
            <div className="super-modal__form-field">
                <div className="super-modal__form-label--required">E-mail:</div>
                <input
                    type="email"
                    placeholder="E-mail"
                    value={modalValue.email || ''}
                    onChange={e => changeValues(e.target.value, 'email')}
                    className={cn({
                        'super-modal__input': true,
                        'super-modal__input--error': check('email'),
                    })}
                />
            </div>
            <div className="locationForm__wrapper">
                <div className="locationForm__field">
                    <div className="super-modal__form-label--required">{localisationMaster('text_22', 'company/locations')}:</div>
                    <DateTime
                        placeholder='9:00'
                        start_at={modalValue.start_at}
                        finish_at={modalValue.end_at}
                        objectChangeHandler={(data, key) => changeValues(data, key === 'finish_at' ? 'end_at' : 'start_at')}
                    />
                </div>
                <div className="locationForm__field">
                    <div className="super-modal__form-label--required">{localisationMaster('text_23', 'company/locations')}:</div>
                    <SelectCustom
                        placeholder={localisationMaster('text_23', 'company/locations')}
                        options={Stores.timeZones}
                        onChange={o => changeValues(o.id, 'time_zone')}
                        valueID={modalValue?.time_zone}
                        error={check('time_zone')}
                    />
                </div>
            </div>
            <div className="super-modal__form-field">
                <Flex>
                    <CheckboxToggle
                        checked={modalValue.only_online}
                        onChange={() => changeValues(!modalValue.only_online, 'only_online')}
                        right={loc(dict.company_locations.contact_test)}
                    />
                </Flex>
            </div>
        </div>
    )
}

export default observer(LocationsForm)
