import React, {Fragment} from 'react';
import { shuffle } from 'helpers/index';
import { correctStyleLessons } from './edit';


export default function QuestionSelectWords(props) {
    var text
    if(props.items){
        text = props.items.text
    } else {
        text = props.value
    }

    const re= /\[(.*?)]/g;
    let m, arr;
    const insertVariants = [];
    text = text.replaceAll('<div>', '<br/>')
    text = text.replaceAll('</div>', '')
    // text = text.replaceAll('/', '|')
    // text = text.replaceAll(',', '|')
    text = text.replaceAll('\\', '|')
    for(m = re.exec(text); m; m = re.exec(text)){
        arr = m[1].split('|')
        insertVariants.push(shuffle(arr))
    }

    const cleanText = text.replace(re, '@').split('@')

    const myChangeHandler = (e, index)=>{
        let arr = props.answers;
        arr[index] = e.target.value
        props.handleAnswers(arr, (props.answers.length === insertVariants.length))
    }


    return (
        <div className="questionSelectWords lessonQuestion__contentText">
            {cleanText.map((item, index) =>
                <Fragment key={index}>
                    <span dangerouslySetInnerHTML={{__html:item}} />
                    {(props.is_student_checked_try && props.answ_array) && 
                    insertVariants[index] ?
                    <select
                        value={props.answers[index]}
                        defaultValue={props.answ_array[index]}
                        className={`select-type2 ${correctStyleLessons(props.correct_answ[index])}`}
                    >
                        <option value={props.answ_array[index]}>{props.answ_array[index]}</option>
                    </select> : null}

                    {((props.is_teacher_view && props.at) && 
                    insertVariants[index]) ?
                    <select
                        value={props.answers[index]}
                        defaultValue={props.answ_array[index]}
                        className={`select-type2 ${correctStyleLessons(props.correct_answ[index])}`}
                    >
                        <option value={props.answ_array[index]}>{props.answ_array[index]}</option>
                    </select> : null}

                    {((props.is_teacher_view && (!props.at)) && 
                    insertVariants[index]) ?
                    <select
                        value={props.answers[index]}
                        defaultValue={0}
                        className="select-type2"
                        onChange={(e) => myChangeHandler(e, index)}
                    >
                        <option value="0" disabled />
                    {insertVariants[index] && insertVariants[index].map((item,index) =>
                    <option key={index} value={item}>{item}</option>
                    )}
                    </select> : null}

                    {(props.is_clean_ex && insertVariants[index]) ?
                    <select
                        value={props.answers[index]}
                        defaultValue={0}
                        className="select-type2"
                        onChange={(e) => myChangeHandler(e, index)}
                    >
                        <option value="0" disabled />
                    {insertVariants[index] && insertVariants[index].map((item,index) =>
                    <option key={index} value={item}>{item}</option>
                    )}
                    </select> : null}

                </Fragment>
                )}
             </div>
            )
};
