import React from 'react'
import Stores from 'Stores'
import EmployeeSkill from "./EmployeeSkill";
import EmployeeSkillSalary from './EmployeeSkillSalary';
import EmployeeSkillSoft from "./EmployeeSkillSoft";
import {BlockHeader} from "components/UI/BlockHeader/BlockHeader";
import st from "./EmployeeSkills.module.scss";
import Books from "../Books/Books";


export default function EmployeeSkills(props) {

  const createSkills = () => {
    const array = [];
    let defaultLinesUser = [
      {name: 'disciplines', rule: 'user_disciplines', title: props.translate.disciplines},
      {name: 'programs', rule: 'user_programs_and_age', title: props.translate.programs},
      {name: 'age_categories', rule: 'user_programs_and_age', title: props.translate.age_categories},
      {name: 'locations', rule: 'user_disciplines', title: props.translate.locations},
    ];
    const lineUsers = []
    if (props.info) {
      defaultLinesUser.forEach(line => {
        if (Stores.Rules.checkRule(line.rule, ['view', 'view_own']) &&
          (Stores.Rules.checkRule(line.rule, ['edit', 'edit_own']) ||
            props.info.profile[line.name].length > 0)) {
          lineUsers.push({
            name: line.name,
            edit: Stores.Rules.checkRule(line.rule, ['edit', 'edit_own']),
            title: line.title
          })
        }
      })
    }


    if (props.catalog !== undefined) {
      let catalog = props.catalog;
      lineUsers.forEach((item, index) => {
        for (let key in catalog) {
          if (key === item.name) {
            array.push(<EmployeeSkill key={index}
                                      title={item.title}
                                      info={props.info}
                                      ts_key={key}
                                      translate={props.translate}
                                      modal={props.modal}
                                      editable={item.edit}/>)
          }
        }
      })
      return array
    }
  }

  return (
     <Books/>
    // <div className={st.box}>
    //   <div className={st.employeeSkills}>
    //     <BlockHeader title={props.translate.menu_main}/>
    //     <div className={st.employeeSkillsBody}>
    //       {createSkills()}
    //       {Stores.Rules.checkRule('salarytypes', ["view", "view_own"]) &&
    //         <EmployeeSkillSalary translate={props.translate} items={props.info}/>}
    //       {Stores.Rules.checkRule('contracts', ["view", "view_own"]) &&
    //         <EmployeeSkillSoft translate={props.translate} items={props.info}/>}
    //     </div>
    //   </div>
    // </div>  
  )
}
