import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import SectionTitle from '../../../../UI/SectionTitle/SectionTitle';
import Paragraph from '../../../../UI/Paragraph/Paragraph';
import InputToggle from '../../../../UI/InputToggle/InputToggle';
import Input from '../../../../UI/Input/Input';
import InputsWrapper from '../../../../UI/InputsWrapper/InputsWrapper';
import AmoCrmToggles from '../components/AmoCrmToggles/AmoCrmToggles';
import { adaptAmoInputs } from '../adapters';
import slotsSettingsStore from '../../../../../Stores/slotsSettingsStore';
import { hideElem, showElem } from 'components/Modules/Slots/utils';

const amoInputs = adaptAmoInputs();

function AmoCrmSection() {
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const {isAmoActive, setAmoInput, toggleIsAmoActive} = slotsSettingsStore;

  const handleAmoInputChange = (evt) => {
    const {name, value} = evt.target;
    setAmoInput(name, value);
  };

  const handleIsAmoActive = () => {
    hideElem(wrapperRef, ref, isAmoActive, toggleIsAmoActive);
  };

  useEffect(() => {
    showElem(wrapperRef, ref, isAmoActive, true);
  }, [isAmoActive]);

  return (
    <>
      <SectionTitle title='Отправка данных в amoCRM'>
        <Paragraph>
          Настройка данных для отправки в amoCRM, предопределенные названия есть только у полей: Имя, e-mail, телефон.
          Для остальных полей необходимо указывать имя поля в amoCRM. Подробнее <b>в инструкции</b>.
        </Paragraph>
      </SectionTitle>
      <InputToggle
        label={'Отправлять данные в amoCRM'}
        selected={isAmoActive}
        name='allowed-amocrm'
        onChange={handleIsAmoActive}
        withMargin
      />
      <div ref={wrapperRef} style={{overflowY: 'hidden', transition: 'height 0.4s'}}>
        {isAmoActive &&
          <div ref={ref}>
            <InputsWrapper withMargin>
              {amoInputs.map(({id, name, label, placeholder}) => (
                <Input
                  key={id.toString()}
                  tag='li'
                  name={name}
                  value={slotsSettingsStore.amoInputs[name]}
                  label={label}
                  placeholder={placeholder}
                  onChange={handleAmoInputChange}
                />
              ))}
            </InputsWrapper>
            <AmoCrmToggles />
          </div>
        }
      </div>
    </>
  );
}

export default observer(AmoCrmSection);
