import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Paper from 'components/UI/Paper/Paper';
import Button from 'components/UI/Button/Button';
import CodeFields from './CodeFields';
import { classNames as cn, scrollToRef } from 'helpers';
import { maskPhone } from 'helpers/phoneMask';
import { ActionType } from 'Stores/slotsNewRecordOnlineStore';
import styles from './Confirm.module.scss';

const text = {
  1: 'Для подтверждения бронирования, мы отправим вам код на телефон',
  2: 'Для подтверждения бронирования, введите код, отправленный на номер',
  3: 'Данные о бронирование отправлены на указанный вами e-mail'
};

const textEmail = {
  1: 'Для подтверждения бронирования, мы отправим вам код на email',
  2: 'Для подтверждения бронирования, введите код, отправленный на email',
  3: 'Данные о бронирование отправлены на указанный вами e-mail'
};

function Confirm({ store }) {
  const ref = useRef(null);
  const {clientData, paymentStep, paymentType, resetData, submitData, confirmCode} = store;
  const [attempts, setAttempts] = useState(4);
  const [code, setCode] = useState('');
  const isSMSType = paymentType === 2;

  const handleClick = () => {
    switch (paymentStep) {
      case 1:
        submitData();
        return;
      case 2:
        const target = isSMSType ? 'phone' : 'email';
        confirmCode(code, target, setAttempts);
        return;
    }
  };

  useEffect(() => {
    if (ref) {
      scrollToRef(ref, 100);
    }
  }, []);

  useEffect(() => {
    if (attempts === 0) {
      resetData(ActionType.resetAll);
    }
  }, [attempts]);

  return (
    <Paper classes={styles.wrapper} ref={ref}>
      <h2 className={cn({
        [styles.title]: true,
        [styles.title__success]: paymentStep === 3,
        [styles.title__admin]: paymentType === 3,
        [styles.title__email]: paymentType === 4,
        [styles.title__simple]: paymentType === 5,
      })}>
        {paymentStep === 3 ? 'Бронирование подтверждено!' : 'Подтверждение бронирования'}
      </h2>
      <p className={styles.text}>
        {isSMSType ? text[paymentStep] : textEmail[paymentStep]}:
      </p>
      <p className={styles.phone}>
        {paymentStep === 3 || !isSMSType ? clientData.email.value : maskPhone(clientData.phone.value)}
      </p>
      {paymentStep === 2 &&
        <CodeFields onChange={setCode} onSubmit={handleClick}/>
      }
      {paymentStep !== 3 &&
        <Button
          onClick={handleClick}
          isWide
        >
          {paymentStep === 1 ? 'Отправить код' : 'Подтвердить код'}
        </Button>
      }
      {paymentStep === 3 &&
        <>
          <p className={styles.success_text}>
            Уведомление может прийти с задержкой до 30 минут.
          </p>
          <p className={styles.notice}>
            Если вы не получили данное письмо, проверьте папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти.
          </p>
          <p className={styles.notice}>
            Также добавьте <strong>phoenix@interpress.kz</strong> в список разрешенных отправителей, чтобы предотвратить блокирование писем из-за
            настроек программного обеспечения.
          </p>
        </>
      }
      { paymentStep !== 3 && attempts < 4 && <p className={styles.error_text}>Вы ввели неверный код, осталось {attempts} попытки</p> }
    </Paper>
  );
}

export default observer(Confirm);