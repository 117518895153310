import React from 'react'
import Stores from 'Stores'
import { ReactComponent as TrueAnswer } from './../../../img/true.svg';
import { ReactComponent as WrongAnswer } from './../../../img/false.svg';


import './studyQuestions.scss'

export default function MainTableStudyQuestions(props) {
    const replacer = (data) => {
        if (!data) return data
        data = data.replaceAll('[', '<strong>[')
        data = data.replaceAll(']', ']</strong>')
        data = data.replaceAll('\\', ', ')
        data = data.includes('|') ? data.replaceAll('|', ' ') : data
        return data
    }

    const answersList = (i) => {
        let answers = i.answers;
        let answerList = [];
        let tmp;
        if (i.type === 'text_missing_words') {
            const re = /\[(.*?)\]/g;
            const tags = i.answers[0].value?.match(re) || []
            for(let o = 0; o < tags.length; o++){
                tmp = tags[o].split('\\').filter(el => el.includes('*'))
                tmp = tmp[0]?.replaceAll('[', '').replaceAll(']', '').replaceAll('*', '') || null
                answerList.push({
                    value: `${props.is_answers ? `${props.is_answers.answers[o]}` : tmp}`,
                    is_correct: props.is_answers ? props.is_answers.correct_answers[o] : true})
            }
            answers = answerList;
        }
        if (i.type.includes('variants') && props.is_answers) {
            for(let o = 0; o < props.items.answers.length; o++){
                answerList.push({
                    value: props.items.answers[o].value,
                    is_correct: props.items.answers[o].is_correct,
                    bold: props.is_answers && props.is_answers.answers.includes(props.items.answers[o].value) 
                })
            }
            answers = answerList;
        }
        if (i.type === 'complete_sentence' && props.is_answers) {
            let list = props.items.answers[0].value.split('|');
            let t,o, color, str=''; 
            let fails = 0;
            for(o = 0; o < props.is_answers.answers.length; o++){
                t = props.is_answers.answers[o] === list[o]
                if(!t) fails += 1
                color = t ? 'green' : 'red';
                str += `${props.is_answers.answers[o]} `
            }
            answers = [{value: str, is_correct: !(fails > 0)}];
        }
        if (i.type.includes('plus_text') && props.is_answers) {
            let is_correct = i.answers[0].value === props.is_answers.answers[0]
            answers = [{
                value: props.is_answers.answers[0],
                is_correct,
            }];
        }
            return <div className="questionBody__variantsBox">
            {answers.map((item, index) => {
                return (
                    <div key={index} className="questionBody__variantsUnit">
                        <div>
                            {item.is_correct ? <TrueAnswer /> : <WrongAnswer />}
                        </div>
                        <div style={{fontWeight: item.bold ? 700: ''}} dangerouslySetInnerHTML={{ __html: replacer(item.value) }} />
                    </div>
                )
            })}
        </div>
        
    }
    const qBody = (items) => {
        const que = <div className="questionBody__question">
            <div dangerouslySetInnerHTML={{ __html: items.text_question }} />
            {(items.type === 'text_missing_words' || items.type === 'complete_sentence' || (items.type.includes('plus_text') && props.is_answers))  &&
                <>
                    <br />
                    <div style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: replacer(items.answers[0].value) }} />
                </>}
        </div>

        const variants = answersList(items);

        switch (items.type) {
            case 'image_plus_text':
            case 'image_plus_variants':
                return (
                    <div className="questionBody">
                        <div className="questionBody__mediaBox">
                            <img src={Stores.baseLink() + props.items.file} width='400px' alt="pic" />
                        </div>
                        <div className="questionBody__questionBox">
                            {que}
                            {variants}
                        </div>
                    </div>)
            case 'question_plus_text':
            case 'variants':
                return (
                    <>
                        {que}
                        {variants}
                    </>)
            case 'complete_sentence':
                return (
                    <>
                        {que}
                        {variants}
                    </>)
            case 'audio_plus_variants':
                return (
                    <>
                        <audio
                            controls
                            src={Stores.baseLink() + props.items.file}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                        {que}
                        {variants}
                    </>)
            case 'video_plus_variants':
                return (
                    <div className="questionBody">
                        <div className="questionBody__mediaBox">
                            <video
                                controls
                                src={Stores.baseLink() + props.items.file}>
                                Your browser does not support the
                                <code>audio</code> element.
                            </video>
                        </div>
                        <div className="questionBody__questionBox">
                            {que}
                            {variants}
                        </div>

                    </div>)
            case 'text_missing_words':
                return (
                    <>
                        {que}
                        {variants}
                    </>)
            default:
                break;
        }
    }


    return (
        <>  
            {qBody(props.items)}
        </>
    )
}
