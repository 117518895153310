import React from 'react';
import { observer } from 'mobx-react';
import TimeSlotsWrapper from 'components/Modules/Slots/components/TimeSlotsWrapper/TimeSlotsWrapper';
import slotsRecordCustomerStore, { ActionType } from 'Stores/slotsRecordCustomerStore';

function SlotsLayout() {
  const { slots, selectedSlots, toggles, getDataFromServer, setToggles, resetData } = slotsRecordCustomerStore;

  const handleSlotClick = (id) => {
    if (selectedSlots.size === 0) {
      resetData(ActionType.resetSlots);
      return;
    }
    resetData(ActionType.resetClientData);
    getDataFromServer({
      type: ActionType.getRegistrationData,
      params: {
        slot: id,
      },
    });
  };

  return <TimeSlotsWrapper
    slots={slots}
    selectedSlots={selectedSlots}
    toggles={toggles}
    onReset={() => resetData(ActionType.resetSlots)}
    onClick={handleSlotClick}
    onTogglesChange={setToggles}
  />;
}

export default observer(SlotsLayout);
