import React from "react";
import Flex from "../../../UI/Flex/Flex";
import LanguageSelector from "./LanguageSelector";
import Stores from "Stores";
import st from "./CompanyLogo.module.scss";

export default function CompanyLogo() {
  return (
    <Flex asColumn>
      <div className={st.languagePlaceholder}><LanguageSelector/></div>
      <div className={st.companyLogo}>
        <img src={Stores.baseLink() + '/media/uploads/interpress.png'} alt="logo" />
      </div>
    </Flex>
  )
}
