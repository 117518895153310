import React, {useEffect, useMemo, useState} from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import Filter from 'components/ContentBox/Global/Filter/Filter';
import {superAxiosRequest} from 'axiosApi';
import useTableFixedHeader from "hooks/useTableFixedHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import styles from './ClientOccupancy.module.scss';
import {dateFormat} from "helpers/date";
import {ExcelRowsParams} from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";

const cols = [
    { name: 'Локация' },
    { name: 'Количество студентов' },
    { name: 'Количество групп' },
    { name: 'Среднее значение' },
];

const ClientOccupancy = () => {
    const [error, setError] = useState(false);
    const [catalog, setCatalog] = useState({});
    const {
        refWrapper,
        refSticky,
        refThead,
        setFilter,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader()

    const totalStudents = data?.reduce((acc, item) => acc + item.result.students, 0)
    const totalGroups = data?.reduce((acc, item) => acc + item.result.groups, 0)

    const filterCnf = useMemo(() => {
        const dateFrom = new Date(new Date().setDate(1));
        const dateUntil = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0));

        return [
            {
                type: 'select-custom',
                placeholder: 'Локация',
                nameState: 'location',
                is_multi: true,
                values: catalog?.locations,
            },
            {
                type: 'select-custom',
                placeholder: 'Статус группы',
                nameState: 'group_status',
                values: catalog?.group_statuses,
                is_multi: true,
            },
            {
                type: 'select-custom',
                placeholder: 'Тип группы',
                nameState: 'group_type',
                values: catalog?.group_types,
                is_multi: true,
            },
            {
                type: 'select-custom',
                placeholder: 'Статус студента',
                nameState: 'student_status',
                values: catalog?.student_statuses,
                is_multi: true,
            },
            {
                type: 'days-range-picker',
                nameState: ['date_from', 'date_until'],
                data: {
                    date_from: dateFrom,
                    date_until: dateUntil,
                },
            },
        ]
    }, [catalog]);

    const getFilteredData = (filter) => {
        setIsLoading(true);
        // delete params.is_active;
        superAxiosRequest({ 
            method: 'get', 
            link: 'progress_report/average_group_client', 
            params: {
                ...filter,
                date_from: dateFormat(filter.date_from, "YYYY-mm-dd"),
                date_until: dateFormat(filter.date_until, "YYYY-mm-dd"),
            },
        }).then((response) => {
            setIsLoading(false);
            setData(response.data.result);
            // setOptions({ ...response.data });
        }).catch(() => {
            setIsLoading(false);
            setError(true);
        });
    };

    const downloadExcel = (value) => {
        import('xlsx-js-style').then(XLSX => {
            const merges = []
            const headRow = [
                { v: "Локация", ...ExcelRowsParams.headerRowParam },
                { v: "Количество студентов", ...ExcelRowsParams.headerRowParam },
                { v: "Количество групп", ...ExcelRowsParams.headerRowParam },
                { v: "Среднее значение", ...ExcelRowsParams.headerRowParam }
            ];
            const tableData = value.map((item) => {
                const location = catalog?.locations?.find((location) => location.id === item.location);
                return [
                    {   
                        v: location?.name || '',
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: item.result.students,
                        ...ExcelRowsParams.tableRowParam.cell,
                    },
                    {
                        v: item.result.groups,
                        ...ExcelRowsParams.tableRowParam.cell,
                    },
                    {
                        v: item.result.average.toFixed(2),
                        ...ExcelRowsParams.tableRowParam.cell,
                    },
                ]
            });


            const footerRow = [
                {
                    v: `ИТОГО`,
                    ...ExcelRowsParams.footerRowParams.firstCell
                },
                {
                    v: totalStudents,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: totalGroups,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: (totalStudents/ totalGroups).toFixed(2),
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ]

            const wscols = [
                {wch: 30},
                {wch: 30},
                {wch: 30},
                {wch: 30},
            ];
            const rows = value.map(() => ({ hpx: 30 })).concat({ hpx: 30 });

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([ headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Наполняемость клиентов.xlsx");
        })
    }

    useEffect(() => {
        setIsLoading(true)
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/average_group_client',
        }).then(({data}) => {
            setIsLoading(false)
            setCatalog(data)
        }).catch(() => {
            setIsLoading(false)
            setError(true);
        })
    }, [setIsLoading])

    useEffect(() => {
        setError(false);
    }, [data]);

    return (
        <>
            <ContentHeader title="Наполняемость клиентов" />
            <Filter 
                config={filterCnf}
                drop={() => {setFilter({})}}
                getFilterParams={getFilteredData}
            />
            {isLoading 
                ? <Loader />
                : <>
                    {error 
                        ? <h2 className={styles.title}>Произошла ошибка!</h2>
                        :  data ? (
                            <div>
                                <div className={st.tableWrapper} ref={refWrapper}>
                                    <div ref={refSticky} className={st.sticky}>
                                        <table className={st.table}>
                                            <THead cols={cols} />
                                        </table>
                                    </div>
                                    <table className={`${st.table} ${st.tbodyStriped}`}>
                                        <THead cols={cols} ref={refThead} />
                                        <tbody className={st.tbody}>
                                            {Array.isArray(data) && (
                                                data.map((item, index) =>{
                                                    const location = catalog?.locations?.find((location) => location.id === item.location);

                                                    return (
                                                        <tr className={`${st.bdt} ${st.thead}`} key={index}>
                                                            <td className={styles.item}>{location?.name}</td>
                                                            <td className={styles.item}>{item.result.students}</td>
                                                            <td className={styles.item}>{item.result.groups}</td>
                                                            <td className={styles.item}>{item.result.average.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                            <tr>
                                                <td className={st.tdTotal}>Итого</td>
                                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                                    {totalStudents}
                                                </td>
                                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                                    {totalGroups}
                                                </td>
                                                <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                                                    {(totalStudents / totalGroups).toFixed(2)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={styles.excelButton}>
                                    <ExportExcelButton data={data} downloadExcel={downloadExcel}/>
                                </div>
                            </div>
                        )
                            : <h1 className={st.noData}>Нет данных для отображения!</h1>}
                </>
            }
        </>
    )
}

export default ClientOccupancy;