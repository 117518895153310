import React from 'react';
import styles from './FileSelectModal.module.scss'
import {ReactComponent as CrossIcon} from "components/ContentBox/img/crossIcon.svg";

const FileSelectModal = ({img, setOpen}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <div className={styles.header}>
                    <div onClick={setOpen} className={styles.close}>
                        <CrossIcon />
                    </div>
                </div>
                <div className={styles.content}>
                    <img src={img} alt="image" className={styles.image} />
                </div>
            </div>
        </div>
    );
};

export default FileSelectModal;