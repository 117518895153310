import { useCallback, useEffect, useState } from "react";

const useLocalStorageFilter = (initialFilter) => {
  const [filter, setFilter] = useState(() => {
    const storedFilter = sessionStorage.getItem("filter")
    return storedFilter ? JSON.parse(storedFilter) : initialFilter
  })

  const updateFilter = useCallback((newFilter) => {
    setFilter(newFilter)
    sessionStorage.setItem("filter", JSON.stringify(newFilter))
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "filter") {
        setFilter(JSON.parse(event.newValue))
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return [filter, updateFilter]
}

export default useLocalStorageFilter