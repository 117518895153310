import React, {useState} from 'react';
import st from './ChooseSentencesForm.module.scss'
import ChooseSentenceForm from "./ChooseSentenceForm/ChooseSentenceForm";
const ChooseSentencesForm = props => {
    const [active, setActive] = useState(Array.from({length: props.data.sentences.length}).fill(false))
    const handleAnswers = (answer, isActive, index) => {
        const allActive = active.map((is, i) => i === index ? isActive : is)
        const arr = [...props.answers]
        arr[index] = answer
        setActive(allActive)
        props.handleAnswers(arr, allActive.every(x => x))
    }

    return (
        <div>{props.data.sentences.map((sentence, index, arr) =>
            <div key={index.toString()} className={arr > 1 ? st.oneSentence : undefined}>
                {arr.length > 1 && <span className={st.counter}>{`${index+1} / ${arr.length}`}</span>}
                <ChooseSentenceForm
                    {...props}
                    sentence={sentence}
                    handleAnswers={(answer, isActive) => handleAnswers(answer, isActive, index)}
                    studentAnswer={props.studentAnswer && {
                        ...props.studentAnswer,
                        answer: props.studentAnswer.answer.at(index).form,
                        result_in_detail: props.studentAnswer.result_in_detail.at(index).result
                    }}
                />
            </div>)}
        </div>
    )
};

export default ChooseSentencesForm;