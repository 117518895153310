import {action, computed, makeObservable, observable, toJS} from "mobx";

class AsideFilterStore {
  isOpenFilter = false
  filterTextValuesData = []

  constructor() {
    makeObservable(this, {
      isOpenFilter: observable,
      filterTextValuesData: observable,

      openFilter: action,
      closeFilter: action,
      setTextValues: action,
      dropTextValues: action,
      changeTextValue: action,
      deleteTextValue: action,

      filterTextValues: computed,
    })

    const storedFilterTextValues = sessionStorage.getItem("filterTextValues");
    if (storedFilterTextValues) {
      this.filterTextValuesData = JSON.parse(storedFilterTextValues);
    }

  }

  openFilter = () => this.isOpenFilter = true

  closeFilter = () => this.isOpenFilter = false

  setTextValues = arr => {
    this.filterTextValuesData = arr;
    // Сохранение значений фильтра в localStorage
    sessionStorage.setItem("filterTextValues", JSON.stringify(arr));
  }

  dropTextValues = () => {
    this.filterTextValuesData = [];
    // Удаление значений фильтра из localStorage
    sessionStorage.removeItem("filterTextValues");
  }

  changeTextValue = (key, val) => {
    if (this.filterTextValues.find(field => field.key === key)) {
      this.filterTextValuesData = this.filterTextValues.map(field => field.key === key ? val : field)
    } else {
      this.filterTextValuesData = [...this.filterTextValues, val]
    }
  }

  deleteTextValue = key => {
    this.filterTextValuesData = this.filterTextValues.filter(x => x.key !== key)
    // Обновление значений фильтра в sessionStorage
    sessionStorage.setItem("filterTextValues", JSON.stringify(this.filterTextValuesData))
  }

  unmountFilter = () => {
    this.closeFilter()
    this.dropTextValues()
  }

  get filterTextValues(){
    return toJS(this.filterTextValuesData)
  }
}

export default new AsideFilterStore()
