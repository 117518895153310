import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import swal from "sweetalert";
import {superAxiosRequest} from "axiosApi";
import {colors} from "components/ContentBox/Global/ContentTable_test/avatar";
import {localisationMaster} from "Localisation/Localisation";
import {timeConverter, agetostr} from "components/ContentBox/Online-schedule/components/functions";
import {PAYERS} from "components/ContentBox/Global/GlobalModalSingle/templates/Pays/ConfirmPayment";
import {dateFormat} from "helpers/date";
import {checkPermList, classNames as cn} from "helpers";
import {validImage} from "helpers";
import {phoneMask} from "helpers/phoneMask";
import {splitNumberDigits} from "helpers/math";
import {translate as loc, dict} from "Localisation";
import Stores from "Stores";
import listingStore from "Stores/listingStore";
import modalStore from "Stores/modalStore";
import AsideModalStore from "Stores/asideModalStore";
import ActionMenu from "components/ContentBox/Global/ActionMenu/ActionMenu";
import MainTableQuestion from "./MainTableQuestion";
import TeacherCardBody from "components/ContentBox/Online-schedule/extended-teachers-schedule/TeacherCardBody";
import MainTableStudyQuestions from "./MainTableStudyQuestions";
import ShowInitTest from "components/ContentBox/StudyTest/ShowInitTest";
import MainTableWidgetQuestions from "./MainTableWidgetQuestions";
import DiscountDates from "components/ContentBox/Global/GlobalModalSingle/templates/groups/DiscountDates";
import EmailRead from "components/ContentBox/Global/GlobalModalSingle/templates/EmailRead/EmailRead";
import "./MainTableRow.scss";
import {ReactComponent as Doc} from "components/ContentBox/img/google-docs.svg";
import {ReactComponent as IconGallery} from "components/ContentBox/img/image-gallery-icon.svg";

export default function MainTableRow(props) {
    const [open, setOpen] = useState(props.openAllRows || false);
    const [blink, setBlink] = useState(false);
    const [update, setUpdate] = useState(false);

    const refImage = useRef(null);
    const refSlide = useRef(null);
    const refBody = useRef(null);

    let apiConf;
    //let body;
    let modalSettings;

    if (props.data) modalSettings = {...props.data.modal_settings};

    const ca = props.colsSettings.avatar;

    function checkForSpam(string) {
        return new RegExp("\\bname_.", "i").test(string);
    }

    const action = (action, e, id, arr, line) => {
        let a, conf, body, ms, md;
        switch (action) {
            case "add_doc_refund":
                modalStore.activity(
                    {
                        name: "files-comments",
                        link: "payments/refunds/docs",
                        button: localisationMaster('text_1', 'groups/all-groups'),
                        title: `Добавить документ`,
                        type: "files-comments",
                        data: "media",
                        width: "650px",
                        func: (res) => props.updateRow(res, arr, props.index),
                    },
                    {
                        refund: arr.id,
                        files: [],
                        titles: [],
                    }
                );
                break;
            case "record_to_online":
                localStorage.setItem("tmp_array_wu", JSON.stringify(arr));
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/online-schedule/record-customer";
                a.click();
                break;
            case "record_to_new_online":
                body = {
                    first_name: arr.first_name,
                    email: arr.email,
                    birthday: arr.birth_date,
                    ...(arr.father_name && { father_name: arr.father_name }),
                    ...(arr.last_name && { last_name: arr.last_name }),
                    ...(arr.phone && { phone: arr.phone }),
                    ...(arr.level && { level: arr.level }),
                };
                localStorage.setItem('save_data_record_customer', JSON.stringify(body));
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/online-schedule-v2/record-customer/";
                a.click();
                break;
            case "placement_test_create_user":
                modalStore.activity(
                    {
                        name: "add_client",
                        link: "accounts/clients",
                        button: localisationMaster("text_54", "groups/all-groups"),
                        title: localisationMaster(
                            "text_3",
                            "online-schedule/clients-after-online"
                        ),
                        type: "client_info",
                        create: true,
                        data: "json",
                        width: "620px",
                        add_client: true,
                    },
                    {
                        status_order: "cancelled",
                        first_name: arr.first_name,
                        last_name: arr.last_name,
                        email: arr.email,
                        phone: `+${arr.phone}`,
                        father_name: arr.father_name,
                        date_of_birth: arr.birth_date,
                        result_lvl: arr.level,
                    }
                );
                break;
            case "detail":
                setOpen(!open);
                break;
            case "go_profile_client":
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/account_clients/" + arr.student.id_user;
                a.click();
                break;
            case "go_profile_client_unidance":
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/account_clients/" + arr.user_id;
                a.click();
                break;
            case "open_window_change_barcode_unidance":
                modalStore.activity({
                    name: "changeBarcodeClientUnidance",
                    link: `unidance/client_barcode/${id}`,
                    button: "Изменить",
                    title: "Изменить штрихкод",
                    type: "changeBarcodeClientUnidance",
                    data: "json",
                    getMatchId: "bundle",
                    is_put: true,
                    func: (res) => props.updateRow({...arr, barcode: res.barcode}, arr, props.index),
                }, {
                        barcode: arr.barcode
                    });
                break;
            case 'to_profile_client':
                a = document.createElement("a");
                a.target = "_blank";
                a.href = `/account_clients/${arr.student_certificate.student.user_id}`;
                a.click();
                break;
            case "go_group":
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/groups/group/" + arr.group.id;
                a.click();
                break;
            case "profile_client":
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/account_clients/" + arr.user;
                a.click();
                break;
            case "detail_order":
                a = document.createElement("a");
                a.target = "_blank";
                a.href = "/pays/invoice/" + arr.order_id;
                a.click();
                break;
            //checkOrder
            case "checkOrder":
                setBlink(true);
                apiConf = {
                    method: "get",
                    link: `payments/order_status/${arr.order_id}`,
                };
                superAxiosRequest(apiConf)
                    .then((res) => {
                        const result = Object.assign({}, arr, res.data.result);
                        props.updateRow(result, arr, props.index);
                        setBlink(false);
                    })
                    .catch(setBlink(false));
                break;
            case "edit-question-bundle":
                modalStore.activity({
                    name: "addQuestionsPlacementTest",
                    link: `placement_test/questions/${id}`,
                    button: 'Редактировать',
                    title: "Редактирование вопроса",
                    type: "addQuestionsPlacementTest",
                    data: "json",
                    getMatchId: "bundle",
                    is_put: true,
                    func: (res) => props.updateRow(res, arr, props.index),
                }, {
                    ...arr
                });
                break;
            case "delete_bundle":
            case "delete_question":
                const name = props.items?.name || props.items?.question_text;
                swal({
                    title: `Вы действительно хотите удалить "${name}"?`,
                    text: `Все данные, как-либо связанные с "${name}", будут удалены.
                    Восстановить удаленные данные будет невозможно.`,
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        apiConf = {
                            method: "delete",
                            link:
                                props.colsSettings.prefix +
                                "/" +
                                props.colsSettings.link +
                                "/" +
                                id,
                        };
                        superAxiosRequest(apiConf).then(() => {
                            props.drop();
                        });
                        swal(
                            `${localisationMaster("text_3", "alert")} ${name} ${localisationMaster("text_4", "alert")}`,
                            {icon: "success", }
                        );
                    } else {
                        swal(localisationMaster("text_5", "alert"));
                    }
                });
                break;
            case "cancel_order":
                modalStore.activity(
                    {
                        name: "groups_edit_comment",
                        link: `payments/pay_orders/${id}`,
                        is_put: true,
                        button: localisationMaster("text_54", "groups/all-groups"),
                        title: `Отмена ордера ${arr.order_id}`,
                        type: "SelectorAndInput",
                        active_inputs: ["textarea"],
                        data: "json",
                        pl_textarea: localisationMaster("text_62", "groups/all-groups"),
                        name_textarea: "comment",
                        valid: {
                            comment: "required",
                        },
                        width: "520px",
                        func: (res) => props.updateRow(res, arr, props.index),
                    },
                    {status_order: "cancelled"}
                );
                break;
                case "confirm_order":
                  AsideModalStore.activity({
                    isCenter: true,
                    isResponsive: true,
                    title: `Подтверждение ордера: ${arr.order_id}`,
                    type: "confirmPayment",
                    button: localisationMaster("text_54", "groups/all-groups"),
                    valid: {
                      bank: 'required',
                      receipt_number: 'required',
                      paid_location_id: 'required',
                    },
                    is_put: true,
                    link: `payments/pay_orders/${id}`,
                    payload: {
                      order: arr
                    },
                    client_data: arr,
                    onBeforeSubmit: async ({payer_id, ...values}) => {
                      const obj = {
                        payment_confirmed: values.payment_confirmed,
                        is_cash: values.is_cash,
                        receipt: values.receipt,
                        ...(!!values.receipt_number && {receipt_number: values.receipt_number}),
                        bank: values.bank,
                        paid_location_id: values.paid_location_id
                      }

                      // Если новый плательщик
                      if (payer_id === PAYERS.NEW_PAYER){
                        const res = await superAxiosRequest({
                          method: 'post',
                          link: 'accounts/contact_persons'
                        },{
                          email: values.email,
                          first_name: values.first_name,
                          last_name: values.last_name,
                          inn: values.inn,
                          phone: values.phone,
                          who_is_it: values.who_is_it,
                          user: values.user_id,
                        }).catch(() => {

                        })
                        obj.payer_id = res.data.id
                      }
                      // Если плательщик - сам клиент
                      else if (payer_id === PAYERS.CLIENT_PAYER){
                        delete obj.payer_id
                      }
                      // Если выбран плательщик
                      else {
                        obj.payer_id = payer_id
                      }

                      return obj
                    },
                    onSubmitSuccess: data => props.updateRow(data, arr, props.index),
                  }, {
                    payment_confirmed: true,
                    is_cash: false,
                    receipt: true,
                    payer_id: PAYERS.CLIENT_PAYER,
                    first_name: arr.user_name.split(" ")[0],
                    last_name: arr.user_name.split(" ")[1],
                    who_is_it: "",
                    user_id: arr.user,
                    email: arr.email,
                    phone: arr.phone,
                    inn: arr.inn,
                  })
            break;
            case "approve_refund":
                modalStore.activity(
                    {
                        name: "groups_edit_comment",
                        link: `payments/refunds/${id}`,
                        is_put: true,
                        button: localisationMaster("text_54", "groups/all-groups"),
                        title: `Подтверждение возврата ${arr.order_id}`,
                        type: "SelectorAndInput",
                        active_inputs: ["textarea"],
                        data: "json",
                        pl_textarea: localisationMaster("text_62", "groups/all-groups"),
                        name_textarea: "comment",
                        width: "520px",
                        func: (res) => props.updateRow(res, arr, props.index),
                    },
                    {is_confirmed: true}
                );
                break;
            case "cancel_refund":
                modalStore.activity(
                    {
                        name: "groups_edit_comment",
                        link: `payments/refunds/${id}`,
                        is_put: true,
                        button: localisationMaster("text_54", "groups/all-groups"),
                        title: `Отмена возврата ${arr.order_id}`,
                        type: "SelectorAndInput",
                        active_inputs: ["textarea"],
                        data: "json",
                        pl_textarea: localisationMaster("text_62", "groups/all-groups"),
                        name_textarea: "comment",
                        width: "520px",
                        func: (res) => props.updateRow(res, arr, props.index),
                    },
                    {is_cancelled: true}
                );
                break;
            case "delete":
                swal({
                    title: localisationMaster("text_2", "alert"),
                    text: `${line.deletePrev || localisationMaster("delete-1", "index", "sidebar_main")} "${line.field ? props.items[line.field] : props.items.name
                        }"?`,
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        apiConf = {
                            method: "delete",
                            link:
                                props.colsSettings.prefix +
                                "/" +
                                props.colsSettings.link +
                                "/" +
                                id,
                        };
                        superAxiosRequest(apiConf).then(({data}) => {
                          if (data.result)
                            listingStore.updateData(data)
                          else
                            props.drop();
                        });
                        swal(
                            `${localisationMaster("text_3", "alert")} ${props.items.name} ${localisationMaster("text_4", "alert")}`,
                            {icon: "success", }
                        );
                    } else {
                        swal(localisationMaster("text_5", "alert"));
                    }
                });
                break;
            case "delete_placement_test_client":
                swal({
                    title: localisationMaster("text_2", "alert"),
                    text: `${localisationMaster("delete-1", "index", "sidebar_main")} "${props.items.first_name
                        }"?`,
                    icon: "warning",
                    buttons: [
                        localisationMaster("button_2", "alert"),
                        localisationMaster("button_1", "alert"),
                    ],
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        apiConf = {
                            method: "delete",
                            link:
                                props.colsSettings.prefix +
                                "/" +
                                props.colsSettings.link +
                                "/" +
                                id,
                        };
                        superAxiosRequest(apiConf).then(() => {
                            props.drop();
                        });
                        swal(
                            `${localisationMaster("text_3", "alert")} ${props.items.name
                            } ${localisationMaster("text_4", "alert")}`,
                            {
                                icon: "success",
                            }
                        );
                    } else {
                        swal(localisationMaster("text_5", "alert"));
                    }
                });
                break;
            case "edit-bundles":
                modalStore.activity(
                    {
                        name: "addBundle",
                        link: `placement_test/bundles/${id}`,
                        button: 'Редактировать',
                        title: "Редактировать связку",
                        type: "addBundle",
                        data: "json",
                        is_put: true,
                        func: (res) => props.updateRow(res, arr, props.index),
                        valid: {
                            name: 'required',
                            age: 'required',
                            program: 'required',
                            test_duration: 'required',
                        },
                    },
                    {
                        name: arr.name,
                        test_duration: arr.test_duration,
                        age: arr.age.id,
                        program: arr.program.id,
                        levels: arr.levels,
                    }
                );
                break;
            case 'edit_location':
                modalStore.activity({
                    name: 'locations',
                    link: `db/locations/${arr.id}`,
                    button: localisationMaster('button-two', 'company/locations', 'add_location'),
                    title: loc(dict.company_locations.edit_location),
                    type: 'LocationsForm',
                    data: 'json',
                    companies: true,
                    func: (res) => props.updateRow(res, arr, props.index),
                    is_put: true,
                    valid: {
                        name: 'required',
                        address: 'required',
                        home_phone: 'required',
                        phone: 'required',
                        email: 'required',
                        start_at: 'required',
                        end_at: 'required',
                        time_zone: 'required',
                    },
                }, { ...arr });
                break;
            case 'edit_company':
              modalStore.activity({
                    name: 'companies',
                    link: `db/companies/${arr.id}`,
                    button: localisationMaster('text_4', 'company/locations'),
                    title: loc(dict.company_locations.edit_company),
                    type: 'ModalCompany',
                    data: 'json',
                    func: (res) => props.updateRow(res, arr, props.index),
                    is_put: true,
                    valid: {
                        name: 'required',
                        currency: 'required',
                        comment: 'required',
                    },
                }, {
                ...arr,
                ...(arr?.currency && {currency: arr.currency.id}),
                notification_student: {
                  sum1: arr.sum1 || 0,
                  sum2: arr.sum2 || 0,
                  wazzap: arr.wazzap || false,
                  email: arr.email || false,
                  amount_lesson: arr.amount_lesson || 0,
                  fixed_price: arr.fixed_price || 0,
                }
              });
                break;
            case 'approve_study_request':
                setBlink(true);
                apiConf = {
                    method: "put",
                    link: `study/request_test_purpose/${id}`
                };
                superAxiosRequest(apiConf, {
                    date_from: arr.date_from,
                    date_until: arr.date_until,
                    start_at: arr.start_at,
                    finish_at: arr.finish_at,
                    comment: "approved",
                    status: "approved",
                    purposed_test: {
                        ...arr.purposed_test,
                        study_test: arr.purposed_test.study_test.id,
                        students: arr.purposed_test.students.map(el => el.id),
                        comment: "approved"
                    }
                }).then((res) => {
                    props.updateRow(res.data, arr, props.index);
                    setBlink(false);
                });
                break;
            case 'close_study_request':
                const bb = {
                    date_from: arr.date_from,
                    date_until: arr.date_until,
                    start_at: arr.start_at,
                    finish_at: arr.finish_at,
                    purposed_test: {
                        ...arr.purposed_test,
                        study_test: arr.purposed_test.study_test.id,
                        students: arr.purposed_test.students.map(el => el.id),
                        comment: "declined"
                    }
                }
                modalStore.activity(
                    {
                        name: "groups_edit_comment",
                        link: `study/request_test_purpose/${id}`,
                        is_put: true,
                        button: localisationMaster("text_54", "groups/all-groups"),
                        title: `Отклонить запрос теста: ${arr.purposed_test.name}`,
                        type: "SelectorAndInput",
                        active_inputs: ["textarea"],
                        data: "json",
                        pl_textarea: localisationMaster("text_62", "groups/all-groups"),
                        name_textarea: "comment",
                        valid: {
                            comment: "required",
                        },
                        width: "520px",
                        func: (res) => props.updateRow(res, arr, props.index),
                    },
                    {...bb, status: "declined"}
                );
                break;
            case "purpose_test":
            case "purpose_test_st":
                conf = {
                    name: "create-study-test",
                    link: props.items.under_request ? "study/request_test_purpose" : "study/purpose_test",
                    button: localisationMaster('text_1', 'groups/all-groups'),
                    title:
                        localisationMaster('text_22', 'study/study-test', 'modal') +
                        (action === "purpose_test_st" ? localisationMaster('text_13_1', 'groups/all-groups') : localisationMaster('text_12_1', 'groups/all-groups')),
                    type: "create-study-test",
                    data: "json",
                    width: "520px",
                    id: props.items.id,
                    under_request: props.items.under_request,
                    func: (res) => {
                        swal({
                            title: localisationMaster('text_23', 'study/study-test', 'modal'),
                            text: `Тест "${res.name}" ${localisationMaster('text_24', 'study/study-test', 'modal')}`,
                            icon: "success",
                            buttons: [
                                'OK',
                                localisationMaster('text_25', 'study/study-test', 'modal'),
                            ],
                        }).then((go) => {
                            if(go) {
                            a = document.createElement("a");
                            a.target = "_blank";
                            a.href = "/study/test/" + res.id;
                            a.click();
                            }
                        });
                    }
                    /* valid: {
                      //is_cash: 'required',
                      //receipt: 'required',
                      //bank: 'required',
                      name: "required",
                    } */
                };
                if (action === "purpose_test_st") conf.students = true;
                const dates = {
                    date_from: timeConverter(new Date(), "today"),
                    date_until: timeConverter(new Date(), "today"),
                    start_at: "09:00",
                    finish_at: "18:00",
                }
                const test_data = {
                    study_test: props.items.id,
                    during: 30,
                    type_show_test: true,
                    focus_live: false,
                    focus_live_during: 15,
                    focus_live_count: 5,
                    mouse_freeze: false,
                    mouse_freeze_during: 15,
                    mouse_freeze_count: 5,
                    shuffle: false,
                    try_count: 3,
                }
                modalStore.activity(conf, {
                    ...dates,
                    ...(props.items.under_request ? {purposed_test: {...dates, ...test_data}} : {...test_data})
                });
                break;
            case 'new_study_request':
                conf = {
                    name: "create-study-test",
                    link: `study/request_test_purpose`,
                    button: "Подтвердить",
                    title: `Запросить тест`,
                    type: "create-study-test",
                    data: "json",
                    width: "520px",
                    id: props.items.id,
                    under_request: true,
                    students: true,
                    /* valid: {
                      //is_cash: 'required',
                      //receipt: 'required',
                      //bank: 'required',
                      name: "required",
                    } */
                };
                modalStore.activity(conf, {
                    date_from: arr.date_from,
                    date_until: arr.date_until,
                    start_at: arr.start_at,
                    finish_at: arr.finish_at,
                    purposed_test: {
                        ...arr.purposed_test,
                        study_test: arr.purposed_test.study_test.id,
                        students: arr.purposed_test.students.map(el => ({
                            id: el.user_id,
                            full_name: `${el.first_name} ${el.last_name}`,
                            id_profile: el.id
                        })),
                    }
                });
                break;
            case 'edit_study_test':
                conf = {
                    name: "create-study-test",
                    link: `study/request_test_purpose/${id}`,
                    button: "Подтвердить",
                    title: `Подтвердить тест: ${arr.purposed_test.name}`,
                    type: "create-study-test",
                    data: "json",
                    width: "520px",
                    id: props.items.id,
                    is_put: true,
                    under_request: true,
                    students: true,
                    /* valid: {
                      //is_cash: 'required',
                      //receipt: 'required',
                      //bank: 'required',
                      name: "required",
                    } */
                };
                modalStore.activity(conf, {
                    date_from: arr.date_from,
                    date_until: arr.date_until,
                    start_at: arr.start_at,
                    finish_at: arr.finish_at,
                    status: 'approved',
                    purposed_test: {
                        ...arr.purposed_test,
                        study_test: arr.purposed_test.study_test.id,
                        students: arr.purposed_test.students.map(el => ({
                            id: el.user_id,
                            full_name: `${el.first_name} ${el.last_name}`,
                            id_profile: el.id
                        })),
                        comment: "approved"
                    }
                });
                break;
            case "edit":
                modalSettings.button = "Сохранить";
                if (modalSettings.name === "lessons-levels") {
                    modalSettings.is_put = true;
                    modalSettings.link = "lessons/levels/" + id;
                    let body = {
                        name: props.items.name,
                        sub_lvl: props.items.sub_lvl.map((item) => item.name),
                    };
                    modalStore.activity(modalSettings, body);
                } else if (modalSettings.name === "setting-payments") {
                    modalSettings.is_put = true;
                    modalSettings.link =
                        props.colsSettings.prefix +
                        "/" +
                        props.colsSettings.link +
                        "/" +
                        id;
                    let bodys = {
                        name: props.items.name,
                        price_sum: props.items.price_sum,
                        type: props.items.type,
                        hours_type: props.items.hours_type,
                        hours_sum: props.items.hours_sum,
                        company: props.items.company.id,
                        locations: props.items.locations,
                    };
                    modalSettings.func = listingStore.putRow;
                    modalStore.activity(modalSettings, bodys);
                } else if (modalSettings.name === "course") {
                    modalSettings.is_put = true;
                    modalSettings.link =
                        props.colsSettings.prefix +
                        "/" +
                        props.colsSettings.link +
                        "/" +
                        id;
                    let bodys = {
                        name: props.items.name,
                        company: props.items.company_id,
                        course_level: props.items.course_level_id,
                        skill_level: props.items.skill_level_id,
                        is_catalog: props.items.is_catalog,
                    };
                    modalSettings.func = listingStore.putRow;
                    modalStore.activity(modalSettings, bodys);
                } else {
                    modalSettings.is_put = true;
                    modalSettings.func = listingStore.putRow;
                    modalSettings.link =
                        props.colsSettings.prefix +
                        "/" +
                        props.colsSettings.link +
                        "/" +
                        id;
                    let createdBody = [];
                    for (let key in modalSettings) {
                        if (checkForSpam(key)) {
                            let key_in = modalSettings[key];
                            createdBody[key_in] = props.items[modalSettings[key]];
                        }
                    }
                    if (createdBody.company) {
                        createdBody.company = createdBody.company.id;
                    }
                    modalStore.activity(modalSettings, createdBody);
                }
                break;
            case "edit_forward":
                const nms = {
                    ...modalSettings,
                    button: "Сохранить",
                    is_put: true,
                    link:
                        props.colsSettings.prefix +
                        "/" +
                        props.colsSettings.link +
                        "/" +
                        id,
                    func: (res) => props.updateRow(res, arr, props.index),
                };
                modalStore.activity(nms, props.items);
                break;
            case "update_study_test":
                setBlink(true)
                apiConf = {
                    method: 'get',
                    link: 'study/teacher_init_test',
                    params: {
                        //is_finish: false,
                        purpose_test: props.forcedID,
                        limit: 999,
                    }
                }
                superAxiosRequest(apiConf).then(res => {
                    props.updateRow(res)
                    setBlink(false)
                })
                    .catch(() => setBlink(false))
                break;
            case "update":
                setBlink(true);
                apiConf = {
                    method: "get",
                    link: props.colsSettings.update_link + id,
                };
                superAxiosRequest(apiConf).then((res) => {
                    props.updateRow(res.data, arr, props.index);
                    setBlink(false);
                });
                break;
            case "groupTest":
                ms = {
                    name: "push_pool_test",
                    link: "lessons/teacher_poll_test",
                    button: localisationMaster("button-two", "push_pool_test"),
                    title: localisationMaster("title-modal-1", "push_pool_test"),
                    type: "create_poll_test",
                    name_textarea: "email_list",
                    pl_textarea: localisationMaster("title-modal-2", "push_pool_test"),
                    id: id,
                    data: "json",
                };
                Stores.SuperModal.activity(ms);
                break;
            case "pushContract":
                let modal_settings = {
                    name: "pushContract",
                    link: "documents/students_doc/send_document",
                    button: localisationMaster(
                        "button-one",
                        "online-schedule/past-online-schedule"
                    ),
                    title: localisationMaster("title-modal", "docs/push-clients-docs"),
                    type: "SelectorAndInput",
                    placeholder: localisationMaster(
                        "textarea-placeholder",
                        "docs/push-clients-docs"
                    ),
                    active_inputs: ["filter-input"],
                    id: id,
                    data: "json",
                };
                modalStore.activity(modal_settings);
                break;
            case "download":
                break;
            case "doc_sign":
                Stores.ModalContractW.activity(arr.type, id, e);
                break;
            case "pay":
                window.location.href =
                    `payment/` +
                    arr.order_id +
                    "/" +
                    arr.payments_term +
                    "/" +
                    arr.amount +
                    "/";
                break;
            case "test":
                window.location.href = "/past-tests/test-results/" + id + "/";
                break;
            case "show_email":
              AsideModalStore.activity({
                title: arr.subject,
                modalBodyComponent: () => <EmailRead emailKey={arr.email_key}/>,
                isCenter: true,
                isResponsive: true,
                cancelButtonName: 'Закрыть'
              })
                break;
            case "group_sign_up_approve":
            case "group_sign_up_reject":
                setBlink(true)
                apiConf = {
                    method: 'put',
                    link: 'groups/self_recorded_students/' + id,
                }
                body = {approved: action === 'group_sign_up_approve'}
                superAxiosRequest(apiConf, body).then(() => {
                    //props.updateRow(res, arr, props.index)
                    props.getData()
                    setBlink(false)
                }).catch(() => setBlink(false))
                break;
          case "edit_discount_dates":
            ms = {
              title: arr.student_name,
              modalBodyComponent: () => <DiscountDates/>,
              button: localisationMaster('text_54', 'groups/all-groups'),
              is_put: true,
              link: 'groups/student_discounts/' + arr.id,
              func: data => {
                const student = data.students.find(st => st.id === arr.student_id)
                const discount = student.group_status.discount_list.find(dis => dis.id === arr.id)
                listingStore.putRow({
                  ...arr,
                  date_from: discount.date_from,
                  date_until: discount.date_until,
                })
              }
            }
            md = {
              client_id: arr.student_id,
              discount_id: arr.discount_id,
              group_id: arr.group_id,
              date_from: arr.date_from,
              date_until: arr.date_until,
            }
            modalStore.activity(ms, md)
            break;
          case "unidance_edit_schedule":
            ms = {
              type: 'unidance_schedule_form',
              title: "Редактирование расписания",
              button: 'Сохранить',
              valid: {
                master: 'required',
                type: 'required',
                date: 'required',
                start_at: 'required',
                finish_at: 'required',
              },
              is_put: true,
              link: 'unidance/schedule_teachers/' + id,
              func: listingStore.putRow
            }
            md = {
              master: arr.master.id,
              type: arr.type_id,
              date: arr.date,
              start_at: arr.start_at,
              finish_at: arr.finish_at,
            }

            modalStore.activity(ms, md)
            break;
          case "unidance_subscription_form":
            ms = {
              type: 'quad',
              title: 'Редактирование абонемента',
              fields: [
                {
                  type: 'text',
                  label: 'Название',
                  required: true,
                  name: 'name',
                  placeholder: 'Название'
                },
                {
                  type: 'text',
                  label: 'Сумма',
                  required: true,
                  name: 'sum',
                  placeholder: 'Сумма',
                  getValue: val => val / 100 || '',
                  onChange: (old, val) => val * 100
                },
                {
                  type: 'text',
                  label: 'Количество посещений',
                  name: 'number_of_visits',
                  placeholder: 'Количество посещений'
                },
                {
                  type: 'text',
                  label: 'Срок действия',
                  name: 'days_active',
                  placeholder: 'Срок действия'
                },
              ],
              button: loc(dict.main_page.text_30, 'Сохранить'),
              valid: {
                name: 'required',
                sum: 'required',
              },
              is_put: true,
              link: 'unidance/subscription/' + arr.id,
              func: listingStore.putRow
            }
            md = {
              name: arr.name,
              sum: arr.sum,
              number_of_visits: arr.number_of_visits,
              days_active: arr.days_active,
            }
            modalStore.activity(ms, md)
            break
          case "unidance_subscription_assign":
            ms = {
              type: 'quad',
              title: 'Назначение абонемента',
              fields: [
                {
                  type: 'select',
                  placeholder: 'Клиент',
                  name: 'client_barcode_id',
                  apiLink: 'unidance/client_barcode',
                  optionLabelKey: 'full_name',
                  sortKey: 'full_name',
                  apiConfParams: {
                        limit: 999
                    }
                }
              ],
              button: 'Назначить',
              valid: {
                client_barcode_id: 'required',
              },
              link: 'unidance/history_subscriptions_client',
            }
            md = {
              subscription_id: arr.id,
            }
            modalStore.activity(ms, md)
            break
            default:
                break;
        }
    }

    const parceArray = (array, key_name) =>
        array !== null ? array.map((item) => item[key_name]).join(", ") : "";

    const returnItem = (array, name, updir, sub_dir, arr, arr_key) => {
        if (array !== undefined) {
            if (updir === undefined) {
                return array[name];
            } else if (updir !== null && sub_dir === undefined) {
                if (arr) {
                    return parceArray(array[updir], arr_key);
                } else {
                    return array[updir][name];
                }
            } else if (sub_dir !== undefined) {
                if (
                    array[updir][sub_dir] !== null &&
                    array[updir][sub_dir] !== undefined
                ) {
                    if (arr) {
                        return parceArray(array[updir][sub_dir], arr_key);
                    } else return array[updir][sub_dir][name];
                } else {
                    return "";
                }
            }
        }
    }

    const returnItemTrue = (array, i, compiled) => {
        let returnedValue;
        if (array !== undefined && i !== undefined) {

            if (i.compiled) {

                let result = "";
                let arr = i.compiled;
                let prev, past;
                for (let y = 0; y < arr.length; y++) {
                    if (i.compiled[y].prev) {
                        prev = i.compiled[y].prev;
                    } else {
                        prev = "";
                    }
                    if (i.compiled[y].past) {
                        past = i.compiled[y].past;
                    } else {
                        past = "";
                    }
                    result += prev + returnItemTrue(array, i.compiled[y], true) + past;
                }
                returnedValue = result;
            } else {
                if (i.updir === undefined) {
                  returnedValue = i.function !== "array" ?
                    typeof (i.function) === 'function' ? i.function(array) : array[i.name] :
                    array
                } else if (i.updir !== null && i.sub_dir === undefined) {
                    if (i.function !== ("array" || "array_count")) {
                        if (array[i.updir] !== null && array[i.updir][i.name] !== null) {
                            returnedValue = array[i.updir][i.name];
                        } else {
                            returnedValue = "";
                        }
                    } else {
                        returnedValue = array[i.updir];
                    }
                } else if (
                    i.updir !== null &&
                    i.sub_dir !== undefined &&
                    array[i.updir] &&
                    array[i.updir][i.sub_dir] !== null &&
                    array[i.updir][i.sub_dir] !== undefined
                ) {
                    if (i.function !== ("array" || "array_count")) {
                        returnedValue = array[i.updir][i.sub_dir][i.name];
                    } else {
                        returnedValue = array[i.updir][i.sub_dir];
                    }
                } else {
                    return localisationMaster("empty_item_text", "lessons/course");
                }

                if (i.function) {
                    if (returnedValue !== null && returnedValue !== undefined) {
                        switch (i.function) {
                            case "rates_list":
                                const rates = array[i.updir][i.name]
                                //returnedValue = rate.length > 1 ? `${rate[0]} - ${rate[rate.length - 1]}` : rate[0]
                                //returnedValue = rates.join(', ')
                                returnedValue = <ul>
                                    {rates.map((r, i) => <li key={i}>{`${i} - ${Stores.cleanSumm(r)}`}</li>)}
                                </ul>
                                break;
                            case "levels_list":
                                returnedValue = array.levels.map(el => (
                                    <p key={el.name}>
                                        {el.name} ({el.scores_from} - {el.scores_to})
                                    </p>
                                ));
                                break;
                            case "sliced_text":
                                returnedValue =
                                    <span>{returnedValue.slice(0, 55)}{returnedValue.length > 55 && `...`}</span>
                                break;
                            case "html":
                                returnedValue = <div dangerouslySetInnerHTML={{__html: returnedValue}} />
                                break;
                            case "phone_mask":
                                const phone = returnedValue.startsWith('+') ? returnedValue : `+${returnedValue}`;
                                const numberPhone = phoneMask(returnedValue.replace("+", ""));
                                returnedValue = <a className="main-table-card-box__link" href={"tel:" + phone}>{numberPhone}</a>
                                break;
                            case "contacts":
                                const {phone: tel, email} = returnedValue;
                                const adaptedTel = tel?.startsWith('+') ? tel : `+${tel}`;
                                const maskedTel = phoneMask(adaptedTel.slice(1));
                                returnedValue = (
                                    <p>
                                        {Boolean(tel) &&
                                            <>
                                                <a className="main-table-card-box__link" href={"tel:" + adaptedTel}>{maskedTel}</a>&ensp;
                                        (<a className="main-table-card-box__link" href={"https://wa.me/" + adaptedTel.slice(1)}>WhatsApp</a>)&ensp;|&ensp;
                                            </>
                                        }
                                        <a className="main-table-card-box__link" href={"mailto:" + email}>{email}</a>
                                    </p>);
                                break;
                            case "qtype":
                                returnedValue = Stores.stqt.find(el => el.value === returnedValue)?.name || returnedValue
                                break;
                            case "test_valid_status":
                                if (!array?.bundle) {
                                    returnedValue = "Сбор данных о клиенте";
                                }
                                else if (returnedValue === "in_progress") {
                                    returnedValue = `В процессе: ${array.total_answers} / ${array.total_questions}`;
                                } else {
                                    returnedValue = `Correct answers: ${array.correct_answers} ${array?.level ? ", " + array.level : ""}`;
                                }
                                break;
                            case "widget_result_valid_bundle":
                                if (array?.bundle) {
                                    const {program, age} = array.bundle;
                                    returnedValue = `${program.name} | ${age.name}`;
                                } else {
                                    returnedValue = "Сбор данных о клиенте";
                                }
                                break;
                            case "slot_time":
                                returnedValue = timeConverter(returnedValue, "time_slots");
                                break;
                            case "array":
                                returnedValue = parceArray(returnedValue, i.key_name);
                                break;
                            case "array_count":
                                returnedValue = i.f_title + returnedValue.length;
                                break;
                            case "age":
                                returnedValue =
                                    timeConverter(returnedValue, "age") +
                                    ", " +
                                    agetostr(returnedValue);
                                break;
                            case 'get_currency':
                                returnedValue = `${returnedValue.name} (${returnedValue.short_name} ${returnedValue.sign})`;
                                break;
                            case 'rules':
                                returnedValue = `${localisationMaster('text_23', 'study/study-test')}: ${array.try_count} (${localisationMaster('text_18', 'groups/group', 'student')} ${array.during} ${localisationMaster('mins', 'groups/group')}.), ${!array.type_show_test ? localisationMaster('text_21', 'study/study-test', 'modal') : localisationMaster('text_32', 'study/study-test', 'modal')}
                 ${array.focus_live ? `, ${localisationMaster('text_35', 'study/study-test', 'modal')}: ${array.focus_live_during} ${localisationMaster('sec', 'pays/invoice')}., ${array.focus_live_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : ''}
                 ${array.mouse_freeze ? `, ${localisationMaster('text_36', 'study/study-test', 'modal')}: ${array.mouse_freeze_during} ${localisationMaster('sec', 'pays/invoice')}. ${array.mouse_freeze_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : ''}, ${array.shuffle ? localisationMaster('text_33', 'study/study-test', 'modal') : localisationMaster('text_34', 'study/study-test', 'modal')}`;
                                break;
                            case 'rules_r':
                                returnedValue = `${localisationMaster('text_23', 'study/study-test')}: ${array.purposed_test.try_count} (${localisationMaster('text_18', 'groups/group', 'student')} ${array.purposed_test.during} ${localisationMaster('mins', 'groups/group')}.), ${!array.purposed_test.type_show_test ? localisationMaster('text_21', 'study/study-test', 'modal') : localisationMaster('text_32', 'study/study-test', 'modal')}
                 ${array.purposed_test.focus_live ? `, ${localisationMaster('text_35', 'study/study-test', 'modal')}: ${array.purposed_test.focus_live_during} ${localisationMaster('sec', 'pays/invoice')}., ${array.purposed_test.focus_live_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : ''}
                 ${array.purposed_test.mouse_freeze ? `, ${localisationMaster('text_36', 'study/study-test', 'modal')}: ${array.purposed_test.mouse_freeze_during} ${localisationMaster('sec', 'pays/invoice')}. ${array.purposed_test.mouse_freeze_count} ${localisationMaster('text_37', 'study/study-test', 'modal')}` : ''}, ${array.purposed_test.shuffle ? localisationMaster('text_33', 'study/study-test', 'modal') : localisationMaster('text_34', 'study/study-test', 'modal')}`;
                                break;
                            case 'u_list_r':
                                returnedValue = <div className="u_list">{array.purposed_test.students.map(el => <Link
                                    target="_blank"
                                    to={`/account_clients/${el.user}`}>{Stores.transliterate(`${el.first_name + ' ' + el.last_name}`)}</Link>)}</div>
                                break;
                            case 'u_list':
                                returnedValue = (
                                    <div className="u_list">{array.students.map(el =>
                                        <Link key={el.user} target="_blank" to={`/account_clients/${el.user}`}>
                                            {Stores.transliterate(`${el.first_name + ' ' + el.last_name}`)}
                                        </Link>)}
                                    </div>)
                                break;
                            case 'date':
                                returnedValue = timeConverter(returnedValue, 'age')
                                break;
                            case 'dateFormat':
                                returnedValue = dateFormat(returnedValue, i.dateFormat)
                                break;
                            case 'bank_bin':
                                returnedValue = array.name_bank || array.bin ? array?.name_bank + " " + array?.bin : null
                                break;
                            case 'receipt':
                                returnedValue = array.status_order === "paid" ? array.receipt && array.receipt_number === !" " ? array.receipt_number : "Без чека" : null
                                break;
                            case "date_time":
                                returnedValue = timeConverter(returnedValue, "date_time");
                                break;
                            case "name_date_time":
                                returnedValue = array.user_name + ' • ' + timeConverter(returnedValue, "dd.mm.yyyy") + " " + timeConverter(returnedValue, "time");
                                break;
                            case "date_without_year":
                                returnedValue = timeConverter(returnedValue, "day");
                                break;
                            case "slice_time":
                                returnedValue = returnedValue.slice(0, 5);
                                break;
                            case "transliterate":
                                returnedValue = Stores.transliterate(returnedValue);
                                break;
                            case "course_count_lessons":
                                returnedValue = array.lessons.length || "Без уроков";
                                break;
                            case "sec_to_min":
                                returnedValue = returnedValue / 60;
                                break;
                            case "group_balance":
                                returnedValue = Stores.cleanSumm(returnedValue / 100) + " тг.";
                                break;
                            case "cleanSum":
                                returnedValue = Stores.cleanSumm(returnedValue) + " тг.";
                                break;
                            case "cert_body":
                                returnedValue = `${returnedValue} (${Stores.cleanSumm(
                                    array.amount
                                )} тг. из ${Stores.cleanSumm(array.certificate.amount)} тг.)`;
                                break;
                            case "cert_body_gr":
                                returnedValue = `${returnedValue} (${Stores.cleanSumm(
                                    array.student_certificate.certificate.amount
                                )} тг.)`;
                                break;
                            case "trim_characters":
                                returnedValue = <div dangerouslySetInnerHTML={{__html: returnedValue.substring(0, 100)}} />
                                break;
                            case "dateRange":
                                returnedValue = `${loc(dict.main_page.text_52)} ${timeConverter(
                                    array.start_at,
                                    "age"
                                )} ${loc(dict.main_page.text_51)} ${timeConverter(array.end_at, "age")}`;
                                break;
                            case "dateRangeGr":
                                returnedValue = `${loc(dict.main_page.text_52)} ${timeConverter(
                                    array.student_certificate.start_at,
                                    "age"
                                )} ${loc(dict.main_page.text_51)} ${timeConverter(
                                    array.student_certificate.end_at,
                                    "age"
                                )}`;
                                break;
                            case "leftOnCert":
                                returnedValue = Stores.cleanSumm(
                                    ((array.student_certificate.amount * 100) - array.student_certificate.debited) / 100
                                ) + " тг.";
                                break;
                            case "nameListGr":
                                returnedValue = array.student_certificate.certificate.companies
                                    .map((item) => `${item.name} `)
                                    .join(",");
                                break;
                            case "nameList":
                                returnedValue = array.certificate.companies
                                    .map((item) => `${item.name} `)
                                    .join(",");
                                break;
                            case "is_confirmed":
                                returnedValue = (
                                    <span className={`isConfirmed isConfirmed--${array.is_confirmed}`}>
                                        {array.is_cancelled
                                            ? "Отменён"
                                            : array.is_confirmed
                                                ? "Подтверждён"
                                                : "Не подтверждён"}
                                    </span>
                                );
                                break;
                            case "payButton":
                                if (array.status_order === "not_paid") {
                                    if (
                                        new Date() < new Date(array.payment_expires) &&
                                        !array.payment_confirmed
                                    ) {
                                        returnedValue = (
                                            <a
                                                href={`/pays/invoice/${array.order_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="main-table-card-box__payment-link"
                                            >
                                                {localisationMaster("button-text-1", "pays/create-pay")}
                                            </a>
                                        );
                                    } else {
                                        returnedValue = (
                                            <span
                                                key={array.id}
                                                className={`paymentButton--span paymentButton--${array.status_order}`}
                                            >
                                                {localisationMaster(
                                                    "deadline_expired",
                                                    "pays/client-orders"
                                                )}
                                            </span>
                                        );
                                    }
                                } else {
                                    returnedValue = (
                                        <span
                                            key={array.id}
                                            className={`paymentButton--span paymentButton--${array.status_order}`}
                                        >
                                            {/*{array.status_order_name}*/}
                                            {localisationMaster(
                                                array.status_order,
                                                "pays/client-orders"
                                            )}
                                        </span>
                                    );
                                }
                                break;
                            case "request_test_status":
                                returnedValue = (
                                    <span
                                        className={`reqStatus--span reqStatus--${array.status_request}`}
                                    >
                                        {array.status_request === "wait"
                                            ? "Ожидает подтверждения"
                                            : array.status_request === "forbidden"
                                                ? "Отколнен"
                                                : "Доступен"}
                                    </span>
                                );
                                break;
                            case "refund_docs":
                                returnedValue = array.documents.length ? (
                                    array.documents.map((el, i) => (
                                        <a
                                            key ={i}
                                            href={Stores.baseLink() + el.file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download={el.title}
                                        >
                                            <div className="fileList_item">
                                                <div className="courseDocs__icon">
                                                    <Doc />
                                                    <span>{el.file.split(".")[1]}</span>
                                                </div>
                                                <span>{el.title}</span>
                                            </div>
                                        </a>
                                    ))
                                ) : (
                                        <span>Нет загруженных документов</span>
                                    );
                                break;
                            case "openOrClick_email":
                                if (returnedValue) {
                                    returnedValue = "Да";
                                } else {
                                    returnedValue = "Нет";
                                }
                                break;
                            case 'mail_link':
                                returnedValue = <a className="main-table-card-box__link" href={`mailto:${returnedValue}`}>{returnedValue}</a>
                                break
                            case 'inn':
                                returnedValue = splitNumberDigits(returnedValue)
                                break
                            default:
                                break;
                        }
                    }
                }
            }
            if (i.prev && !compiled) returnedValue = i.prev + returnedValue
            return returnedValue;
        } else {
            return "Ошибка";
        }
    }

    const changeWait = () => {
        setUpdate(!update);
    }

    const renderAvatar = () => {
        if (ca && props.items) {
            const val = returnItem(props.items, ca.name, ca.updir, ca.sub_dir);

            return (
                <div className={cn({
                    "main-table-card-box__image": true,
                    "main-table-card-box__image--show": open,
                    "main-table-card-box__image--hide": !open,
                })}
                    ref={refImage}
                >
                    {val && validImage(ca.no_link ? val : Stores.baseLink() + val) ? (
                        <img
                            src={ca.no_link ? val : Stores.baseLink() + val}
                            alt=""
                        />
                    ) : (
                            ca.ac &&
                            returnItem(props.items, ca.f, ca.updir, ca.sub_dir) && (
                                <div
                                    className="main-table-card-box__ava"
                                    style={{
                                        backgroundColor: colors(
                                            returnItem(props.items, ca.f, ca.updir, ca.sub_dir) +
                                            returnItem(props.items, ca.l, ca.updir, ca.sub_dir)
                                        ),
                                    }}
                                >
                                    {ca.fill === "icon" ? (
                                        <IconGallery />
                                    ) : (
                                            returnItem(props.items, ca.f, ca.updir, ca.sub_dir)
                                                .substr(0, 1)
                                                .toUpperCase() +
                                            returnItem(props.items, ca.l, ca.updir, ca.sub_dir)
                                                .substr(0, 1)
                                                .toUpperCase()
                                        )}
                                </div>
                            )
                        )}
                </div>
            )
        }
    }

    const getCardBoxClassName = () => {
        const aClassName = ["main-table-card-box"];
        if (!returnItemTrue(props.items, props.colsSettings.is_active))
            aClassName.push("main-table-card-box--deleted");

        if (blink) aClassName.push("blink");

        return aClassName.join(" ");
    }

    const processMenu = () => {
        const arr = []
        props.colsSettings.action.forEach(act => {
            // Здесь же проверим и права
            if (act.perm) {
                if (checkPermList(act.perm)) {
                    if (typeof act.process === 'function') {
                        const result = act.process(act, props.items)
                        if (result) arr.push(result)
                    } else {
                        arr.push(act)
                    }
                }
            } else {
                arr.push(act)
            }
        })
        return arr
    }

    useEffect(() => {
        setOpen(props.openAllRows);
    }, [props.openAllRows])

    useEffect(() => {
        if (open) {
            if (refImage.current) {
                refImage.current.addEventListener(
                    "transitionend",
                    () => {
                        refSlide.current.style.height = refBody.current.offsetHeight + "px";
                    },
                    {once: true}
                );
            } else {
                refSlide.current.style.height = refBody.current.offsetHeight + "px";
            }
        } else refSlide.current.style.height = "";
    }, [open, update])

    return (
        <div className={getCardBoxClassName()}>
            {renderAvatar()}
            <div className="main-table-card-box__box">
                <div className="main-table-card-box__header">
                    {props.pays &&
                        <div className="main-table-card-box__color">
                            <div
                                className={`main-table-card-box__color__line main-table-card-box__color--${props.items.status_order}`} />
                        </div>}
                    <div className="main-table-card-box__header-array" onClick={() => {
                      !props.colsSettings.noOpener && setOpen(!open)
                    }}>
                        {props.items && props.colsSettings.main_rows.map((i, index) => (
                            <div key={index} className={i.classname || ""}>
                                {i.link ?
                                    <span>
                                        <Link to={i.link + props.items.id} target="_blank">
                                            {returnItemTrue(props.items, i) && returnItemTrue(props.items, i).toString().slice(0, 50)}
                                        </Link>
                                    </span>
                                    :
                                    <span>{returnItemTrue(props.items, i)}</span>
                                }
                            </div>
                        ))}
                    </div>
                    <div>
                        {processMenu().length > 0 &&
                            <ActionMenu
                                //menu={props.colsSettings.action}
                                menu={processMenu()}
                                id={returnItem(
                                    props.items,
                                    props.colsSettings.id.name,
                                    props.colsSettings.id.updir,
                                    props.colsSettings.id.sub_dir
                                )}
                                forward_actions={props.actions}
                                forward_items={props.items}
                                action={(data, e, i, line) =>
                                    action(
                                        data,
                                        e,
                                        returnItem(
                                            props.items,
                                            props.colsSettings.id.name,
                                            props.colsSettings.id.updir,
                                            props.colsSettings.id.sub_dir
                                        ),
                                        props.items,
                                        line
                                    )
                                }
                            />
                        }
                    </div>
                </div>

                <div
                    className={cn({
                        "main-table-card-box__body-slide": true,
                        "main-table-card-box__body-slide--open": open,
                        "main-table-card-box__body-slide--close": !open,
                    })}
                    ref={refSlide}
                >
                    <div
                        className="main-table-card-box__body"
                        ref={refBody}
                        style={{padding: props.off_padding && "0px"}}
                    >
                        {props.items && props.colsSettings.sub_rows.map((i, index) =>
                            (i.listType === 'table' ?
                                    <div key = {index} style = {{gridTemplateColumns: `repeat(${i.tableData.length}, 1fr)`}} className="main-table-card-box__subTable_header">
                                    {i.tableData.map((headerData, hid) =>
                                        <div className="main-table-card-box__subTable_header_box" key={hid} >
                                            <div className="main-table-card-box__subTable_headerCol_center" >{ headerData.description}</div>
                                            <div style={{gridTemplateColumns: `repeat(${headerData.sub.length}, 1fr)`}} className="main-table-card-box__subTable_headerCol">
                                                    {headerData.sub.map((subCol, sid) =>
                                                    <div style={{textAlign: 'center'}} key={sid} >
                                                            <div className="">{subCol.description}</div>
                                                            <span  className={i.classname || ""}>
                                                                    {returnItemTrue(props.items, subCol.data) || localisationMaster("empty_item_text", "lessons/course")}
                                                            </span>
                                                </div>)}
                                            </div>
                                            </div>
                                    )}
                                    </div>
                            :
                                ((i.hidden_if_empty && returnItemTrue(props.items, i)) || !i.hidden_if_empty) &&
                                <div key={index} className="main-table-card-box__body-unit">
                                    <span>{i.description}</span>
                                    <span className={i.classname || ""}>
                                        {returnItemTrue(props.items, i) || localisationMaster("empty_item_text", "lessons/course")}
                                    </span>
                                </div>
                            )
                        )}
                        {props.colsSettings.is_questions && (
                            <MainTableQuestion items={props.items} />
                        )}
                        {props.colsSettings.is_study_questions && (
                            <MainTableStudyQuestions items={props.items} changeWait={changeWait} />
                        )}
                        {
                            props.colsSettings.questions_the_widget && (
                                <MainTableWidgetQuestions items={props.items} changeWait={changeWait} />
                            )
                        }
                        {props.colsSettings.is_teacher_extended_schedule && (
                            <TeacherCardBody item={props.items} />
                        )}
                        {props.colsSettings.is_init_test && (
                            <ShowInitTest item={props.items} changeWait={changeWait}
                                disableAnswers={props.disableAnswers} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
