import React from "react";
import PropTypes from 'prop-types';
import st from "./QuestionPoints.module.scss"

const QuestionPoints = props => {
  return (
    <div className={st.box}>
      <span>{props.label}:</span>
      <input type="number" value={props.value} onChange={e => props.onChange(+e.target.value, props.name)}/>
    </div>
  );
}

QuestionPoints.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default QuestionPoints
