import React from 'react';
import DataPickerSplit from 'components/ContentBox/Global/DatePicker/DataPickerSplit';
import DateTime from 'components/ContentBox/Global/DateTime/DateTime';
import DummyCounter from "components/ContentBox/Global/Counter/DummyCounter";

export default function DayByDay(props) {

    const returnValue = key_name => props.values ? props.values[key_name] : ''

    const objectChangeHandler = (value, key) => props.updateList({...props.values, [key]: value})

    return (
        <>
            <div className="workSchedule-box__cardRow workSchedule-box__cardRow--three">
                <div>
                    <div className="workSchedule-box__label">Период графика:</div>
                    <div>
                        <DataPickerSplit returnValue={returnValue}
                                         objectChangeHandler={objectChangeHandler}
                                         minDate={props.edit ? new Date() : false}
                        />
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Рабочие дни:</div>
                    <div>
                        <DummyCounter step={1} max={30} value={props.values.count} onChange={objectChangeHandler}
                                      name="count"/>
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Выходные дни:</div>
                    <div>
                        <DummyCounter step={1} max={30} value={props.values.period} onChange={objectChangeHandler}
                                      name="period"/>
                    </div>
                </div>
            </div>

            <div className="workSchedule-box__cardRow workSchedule-box__cardRow--three">
                <div>
                    <div className="workSchedule-box__label">Время работы:</div>
                    <div>
                        <DateTime placeholder='9:00'
                                  errorCheker={() => { }}
                                  start_at={props.values.start_at}
                                  finish_at={props.values.finish_at}
                                  cap1='Приход'
                                  cap2='Уход'
                                  objectChangeHandler={objectChangeHandler}
                                  returnValue={returnValue} />
                    </div>
                </div>
                <div>
                    <div className="workSchedule-box__label">Перерыв:</div>
                    <div>
                        <DummyCounter value={props.values.lunch} name="lunch" onChange={objectChangeHandler} step={1}
                                      max={120}/>
                    </div>
                </div>
            </div>
        </>
    )
}
