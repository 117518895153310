import React, {useEffect, useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {checkBigMenu, checkCats, menuSettings as menu} from "./menu";
import {checkPermList, classNames as cn} from "helpers";
import CollapseWrapper from './CollapseWrapper/CollapseWrapper';
import useLocalization from "hooks/useLocalization";
import styles from './Sidebar.module.scss';
import {ReactComponent as ArrowIcon} from "components/UI/icons/arrow-right-s-line.svg";

const menuTranslate = (menu, dictionary) => menu.map(item => ({
  ...item,
  cats: item.cats.map(cat => ({
    ...cat,
    title: dictionary[cat.title],
    items: cat.items && cat.items.map(catItem => ({
        ...catItem,
        title: dictionary[catItem.title]
      }
    ))
  }))
}))

const Sidebar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState('')
  const [openedTab, setOpenedTab] = useState('')
  const refSidebar = useRef(null)
  const {translate} = useLocalization({
    section: 'global',
    subsection: 'main_menu',
    dict: {
      "educational_testing": "Educational testing",
      "tests": "Tests",
      "tests_v2": "Tests v2",
      "assigned_to_clients": "Assigned to clients",
      "view_studytest": "View studytest",
      "requested_tests": "Requested tests",
      "widget": "Widget",
      "clients_old": "Clients old",
      "test_settings": "Test Settings",
      "widgets_settings": "Widgets Settings",
      "online_booking": "Online Booking",
      "online_booking_settings": "Online Booking Settings",
      "my_schedule": "Setting up my Schedule",
      "customer_booking": "Register a New Client",
      "customer_booking_schedule": "Customer Booking Schedule",
      "future_bookings": "Future Bookings",
      "past_bookings": "Past Bookings",
      "customer_booking_history": "Customer Booking History",
      "employee_booking_history": "Employee Booking History",
      "employee_schedule_calendar": "Employee Schedule Calendar",
      "learning": "Learning",
      "groups": "Classes",
      "ebooks": "Ebooks",
      "clients_reports": "Clients",
      "clients_with_debts": "Clients with Debts",
      "clients_with_discounts": "Clients with Discounts",
      "students_with_certificates": "Students with Certificates",
      "paid_hours": "Paid Hours",
      "average_check": "Average Check",
      "student_lifetime": "Student Lifetime",
      "dropped_students": "Dropped Students",
      "new_students": "New Students",
      "teacher_rating": "Teacher StudentsProgressReport",
      "employee_rating": "Employee StudentsProgressReport",
      "students_with_trial_lesson": "Students with Trial Lesson",
      "student_attendance_map": "Student Attendance Map",
      "teacher_workload": "Teacher Workload",
      "classroom_workload": "Classroom Workload",
      "profitability": "Profitability",
      "teacher_statistics": "Teacher Statistics",
      "client_stages": "Client Stages",
      "birthdays": "Employee Birthdays",
      "conversion_report": "Conversion Report",
      "client_occupancy": "Client Occupancy",
      "group_stages": "Group's start",
      "conversion": "Conversion",
      "formed_groups": "Group's formation",
      "groups_launch_start": "Group's launch start",
      "additional": "Additional",
      "clients_joined_groups": "Clients who joined classes",
      "group_status_change": "Class status change",
      "accounts": "Accounts",
      "invoice": "Invoices",
      "all_invoices": "All invoices",
      "entries_1c": "Entries 1C",
      "refunds": "Refunds",
      "funds_transfers": "Funds transfers",
      "receipt_of_funds": "Receipt of funds",
      "company": "Company",
      "companies": "Companies",
      "locations": "Locations",
      "employees": "Employees",
      "employee_schedule": "Employee schedule",
      "sent_letters": "Sent letters",
      "sent_sms": "Sent SMS",
      "evaluation_criteria": "Evaluation criteria",
      "ratings": "Ratings",
      "finances": "Finances",
      "hourly_rates": "Hourly rates",
      "discounts": "Discounts",
      "teacher_salaries": "Teacher salaries",
      "certificates": "Certificates",
      "requests": "Requests",
      "training_application_templates": "Training application templates",
      "signed_requests": "Signed requests",
      "requests_for_signature": "Requests for signature",
      "training_application_builder": "Training application builder",
      "contracts_of_employees": "Contracts of employees",
      "templates_of_contracts": "Templates of contracts",
      "employees_contracts": "Employee Contracts",
      "contracts_for_signature": "Contracts for signature",
      "contract_builder": "Contract builder",
      "common_settings": "Common settings",
      "training_programs": "Training programs",
      "age_categories": "Age categories",
      "work_with_groups": "Work with groups",
      "disciplines_languages": "Disciplines (languages)",
      "group_levels": "Group levels",
      "holidays": "Holidays",
      "group_categories": "Group categories",
      // "barcode": "Barcode",
      "fitness_center": "Fitness Center",
      "clients": "Students",
      "all_clients": "All Clients",
      "instructors": "Instructors",
      "schedules": "Instructor Schedules",
      "training_lessons": "Training lessons",
      "subscriptions_settings": "Subscription Settings",
      "reports": "Reports",
      "fitness_center_reports": "Fitness Center Reports",
      "statistics_by_coaches": "Statistics by Coaches",
      "sold_subscriptions": "Sold Subscriptions",
      "financial_report": "Financial Report",
      "zero_balance_subscription": "Zero Balance Subscription",
      "configuring_slots": "Configuring slots",
      "customer_record": "Customer record",
      "schedule_settings": "Schedule settings",
      "clients_schedule": "Clients schedule",
      "time_schedule_of_teachers": "Time schedule of teachers",
      "teacher_schedule_history": "Teacher schedule history",
      "customer_record_history": "Customer record history",
      "training": "Training",
      "passed_tests": "Passed Tests",
      "study_group_settings": "Study Group Settings",
    }
  })

  const onClickHandler = ({target}) => {
    !refSidebar.current?.contains(target) && setIsOpenMenu('')
  }

  useEffect(() => {
    if (isOpenMenu) {
      document.body.addEventListener('click', onClickHandler)
    }
    setOpenedTab('')

    return () => {
      document.body.removeEventListener('click', onClickHandler)
    }
  }, [isOpenMenu])

  return (
    <div>
      <div className={styles.sidebar} ref={refSidebar}>
        <div className={`${styles.sidebarNav} ${isOpenMenu ? styles.sidebarNav__active : ''}`}
             onMouseLeave={() => isOpenMenu !== 'fix-open' && setIsOpenMenu('')}>
          <div onClick={() => setIsOpenMenu(prev => prev === 'fix-open' ? '' : 'fix-open')}
               className={`${styles.openButton} ${isOpenMenu === 'fix-open' ? `${styles.active}` : ''}`}>
            <ArrowIcon className={styles.openButton__icon_1}/>
            <ArrowIcon className={styles.openButton__icon_2}/>
          </div>
          <ul className={styles.mainMenu}
              onMouseEnter={() => isOpenMenu !== 'fix-open' && setIsOpenMenu('not-fix-open')}>
            {menuTranslate(menu, translate).map((item, itemKey) => checkBigMenu(item.cats) &&
              item.cats.map((category, categoryIndex) => checkCats(category.items) &&
                <CollapseWrapper
                  key={`${item.id}_${categoryIndex}`}
                  isClosed={!isOpenMenu}
                  isOpen={`${itemKey}_${categoryIndex}` === openedTab}
                  onClick={() => {
                    const uniqKey = `${itemKey}_${categoryIndex}`
                    setOpenedTab(uniqKey === openedTab ? '' : uniqKey)
                  }}
                  isCollapsable={item.isCollapsable}
                  title={
                    item.isCollapsable === undefined ?
                      <span key={item.id}
                            className={cn({
                              [styles.category]: true,
                              [styles.active]: isOpenMenu,
                              [styles.categoryOpened]: `${itemKey}_${categoryIndex}` === openedTab
                            })}>
                        {category.icon}
                        <span className={cn({
                          [styles.title]: true,
                          [styles.active]: `${itemKey}_${categoryIndex}` === openedTab,
                        })}>
                          {category.title}
                        </span>
                      </span>
                      :
                      category.items.map(subItem => checkPermList(subItem) &&
                        <span key={item.id}
                              className={cn({
                                [styles.category]: true,
                                [styles.active]: isOpenMenu,
                                [styles.categoryOpened]: `${itemKey}_${categoryIndex}` === openedTab
                              })}>
                          {category.icon}
                          <Link to={`/${subItem.link}`}
                                className={cn({
                                  [styles.title]: true,
                                  [styles.active]: `${itemKey}_${categoryIndex}` === openedTab,
                                })}
                                onClick={() => setIsOpenMenu('')}>
                            <span>
                              {category.title}
                            </span>
                          </Link>
                        </span>
                      )
                  }>
                  <ul className={styles.subList}>
                    {category.items.map((subItem, subKey) => checkPermList(subItem) &&
                      <li className={styles.subList__item} key={subKey}>
                        <NavLink
                          className={styles.subList__link}
                          to={`/${subItem.link}`} onClick={() => {
                          setIsOpenMenu('')
                        }}>
                          <div className={styles.subList__dot}></div>
                          {subItem.title}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </CollapseWrapper>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
