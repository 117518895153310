import {observable, action, computed, toJS, makeObservable} from 'mobx';
import swal from 'sweetalert';
import Stores from '.';
import listingStore from './listingStore'
import {superAxiosRequest} from 'axiosApi';
import {timeConverter} from 'components/ContentBox/Online-schedule/components/functions';

export const schoolDayStatuses = {
  PART_PAID_LESSON: 1,
  FREE_LESSON: 2,
  PAID_SKIP: 3,
  PART_PAID_SKIP: 4,
  NOT_PAID_SKIP: 5,
  LESSON: 7,
  CANCELED: 9,
}

class GroupStore {
  groupData = {}
  ratingList = []
  wsDataData = {}

  constructor() {
    makeObservable(this, {
      groupData: observable,
      ratingList: observable,
      wsDataData: observable,

      setGroupData: action,
      updateGroupData: action,
      deleteGroupData: action,
      updateSchedule: action,
      redirectToGroup: action,
      setRaitingData: action,
      updateEbooks: action,
      deleteEbooks: action,
      updateWSData: action,
      updateWSDays: action,
      updateDiscountOrSurcharge: action,
      deleteDorS: action,

      group: computed,
      groupStudents: computed,
      ratingListC: computed,
      ebooks: computed,
      wsData: computed,
    })
  }

  redirectToGroup = (indata) => {
    let data = Stores.observableArray(listingStore.data)
    data.unshift(indata)
    data.pop()
    listingStore.data = data
    let id = indata.id
    let a
    a = document.createElement('a');
    a.target = "_blank";
    a.href = '/groups/group/' + id
    a.click();
  }

  fetchGroup = id => {
    const apiConf = {
      method: 'get',
      link: `groups/employee_group/${id}`,
    }
    superAxiosRequest(apiConf).then(({data}) => {
      // Если новое расписание
      if (data.group.new_schedule){
        superAxiosRequest({
          method: 'get',
          link: 'schedule/workshops',
          params: {
            group: id,
          }
        }).then(r => {
          this.setGroupData(data)
          this.updateWSData(r.data)
        }).catch(() => {

        })
      } else {
        this.setGroupData(data)
      }
    }).catch(() => {});
  }

  setGroupData = data => {
    this.groupData = data
  }

  updateGroupData = data => {
    this.groupData = {
      ...this.groupData,
      ...data,
    }
  }

  deleteGroupData = () => {
    this.groupData = {}
    this.wsDataData = {}
  }

  updateSchedule = data => {
    this.updateGroupData(data)
  }

  updateDiscountOrSurcharge = (data, clientID, isPost, type, discountID) => {
    this.fetchGroup(this.groupData.group.id)

    const action = isPost ? 'добавлена' : 'отредактирована'
    const typeName = type === 'discount' ? 'Скидка' : 'Наценка'

    const student = data.students.find(x => x.id === clientID);
    const list = type === 'discount' ? student.group_status['discount_list'] : student.group_status['surcharge_list']

    let item = isPost ? list.pop() : list.find(x => x.id === discountID)
    const date_from = item.date_from
    item = item[type]

    const unit = item.type === 2 ? '%' : 'тг.'

    const content = document.createElement('div')
    content.className = 'swal-text'
    content.innerHTML = `<div>${typeName} <b>"${item.name}"</b> в размере <b>${item.count} ${unit}</b> успешно ${action} для <b>${student.full_name} c ${timeConverter(new Date(date_from), 'age')}</b></div>`

    swal({
      icon: 'success',
      title: `${typeName} успешно ${action}!`,
      content,
      button: true,
    })
  }

  deleteDorS = (id, type) => {
    this.groupData = {
      ...this.groupData,
      students: this.groupData.students.map(st => ({
        ...st,
        ...(type === 'discount' && {discount_list: st.discount_list.filter(x => x.id !== id)}),
        ...(type === 'surcharge' && {surcharge_list: st.surcharge_list.filter(x => x.id !== id)}),
      }))
    }
  }

  setRaitingData = (id, stid) => {
    const apiConf = {
      method: 'get',
      link: 'progress_report/report',
      params: {
        ...(!!id && {group_id: id}),
        ...(!!stid && {client: stid})
      }
    }
    superAxiosRequest(apiConf).then(response => {
      this.ratingList = response.data.result
    }).catch(() => {
    })
  }

  updateOneOfRatinf = (data) => {
    let Data = Stores.observableArray(this.ratingList)
    const index = Data.findIndex(item => item.id === data.id);
    Data[index] = data
    this.ratingList = Data
  }

  updateEbooks = data => {
    this.groupData.ebooks = data
  }

  deleteEbooks = id => {
    this.groupData.ebooks = this.groupData.ebooks.filter(eb => eb.id !== id)
  }

  updateWSData = data => {
    this.wsDataData = data
  }


  updateWSDays = days => {
    this.wsDataData = {
      ...this.wsDataData,
      workshops: this.wsDataData.workshops.map(d => days.find(x => x.id === d.id) || d)
    }
  }

  get group() {
    return toJS(this.groupData)
  }

  get groupStudents() {
    return toJS(this.groupData.students)
  }

  get ratingListC() {
    return toJS(this.ratingList)
  }

  get ebooks(){
    return toJS(this.groupData.ebooks)
  }

  get wsData(){
    return toJS(this.wsDataData)
  }
}

export default new GroupStore()
