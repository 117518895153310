import React, { useEffect, useState } from 'react';
import { correctStyleLessons } from './edit';

export default function QuestionMakeWord(props) {

    const [result, setResult] = useState([])
    const [questions, setQuestions] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    let dragged, dragIndex, dragLetterIndex

    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        setAnswersButton(!(([].concat(...questions)).join('').length))
        props.handleAnswers(result, answersButton)
    }, [result, answersButton])

    useEffect(() => {
        const res = []
        const que = []
        for (let i=0; i<props.words.length; i++) {
            que[i] = (props.words[i].value_mix).split('|')
            res[i] = new Array(que[i].length).fill('')
        }
        setResult(res)
        setQuestions(que)
    }, [props.words])

    const onDragStartHandler = (e, i, l) => {
        dragged = e.target
        dragIndex = i
        dragLetterIndex = l
        e.target.classList.add('onDrag')
        e.dataTransfer.effectAllowed = "move";
    }

    const onDragEndHandler = e => {
        e.target.classList.remove('onDrag')
    }

    const onDragEnterHandler = e => {
        e.target.classList.add('onDragOver')
    }

    const onDragLeaveHandler = e => {
        e.target.classList.remove('onDragOver')
    }

    const onDragOverHandler = e => {
        // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
        if (dragged)
            // prevent default to allow drop
            e.preventDefault()
    }

    const onDropHandler = ({ target }) => {
        target.classList.remove('onDragOver')
        const que = questions
        que[dragIndex][dragLetterIndex] = ''
        setQuestions([...que])
        const res = result
        res[dragIndex][target.id] = dragged.textContent
        setResult([...res])
    }

    const onClickHandler = (e, i, l) => {        
        const que = questions
        const res = result
        res[i][l] = ''
        setResult([...res])
        const ind = que[i].indexOf('')
        que[i][ind] = e.target.textContent
        setQuestions([...que])
    }

    return (
        <div className="questionMakeWord">

            {((props.is_clean_ex || (props.is_teacher_view && (!props.at))) &&
            props.words) ?
                (props.words.map((item, index) =>
                <div className="questionMakeWord__content" key={index} id={index}> 
                    <p><b>Подсказка:</b> {item.description}</p>
                    <div>
                        {result[index] && result[index].map((letter, letterIndex) => 
                            letter ?
                            <span 
                                key={letterIndex}
                                id={letterIndex}
                                onClick={(e) => { onClickHandler(e, index, letterIndex) }}>
                                {letter}
                            </span>:  
                            <span
                                key={letterIndex}
                                id={letterIndex}
                                onDragEnter={onDragEnterHandler}
                                onDragLeave={onDragLeaveHandler}
                                onDragOver={onDragOverHandler}
                                onDrop={e => { onDropHandler(e, letterIndex) }}
                            />
                        )}
                    </div>
                    <div>
                        {questions[index] && questions[index].map((qustionLetter, letterIndex) => 
                        qustionLetter?
                            <span key={letterIndex} id={letterIndex} draggable onDragStart={e => { onDragStartHandler(e, index, letterIndex) }} onDragEnd={onDragEndHandler}>
                                {qustionLetter}
                            </span>:
                            <span key={letterIndex} id={letterIndex}/>
                        )}
                    </div>
                </div>
                ))
            :
            null
            }            

            {((props.is_student_checked_try || (props.is_teacher_view && props.at)) &&
            props.at) ?
            (props.answ_array.map((answer, index) =>
                <div key={index}>
                    {answer.split('').map((item, itemIndex) => 
                        <span key={itemIndex} className={correctStyleLessons(props.correct_answ[index])} >
                            {item}
                        </span>
                    )}                    
                </div>
            ))
            :
            null
            }
        </div>
    );
}

