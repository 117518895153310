import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types"
import {checkPermList, classNames as cn} from "helpers";
import st from "./ActionMenu.module.scss";
import {ReactComponent as MoreIcon} from "./more-2-line.svg";
import {Link} from "react-router-dom";


export const checkActionPermission = (perm) => {
  if (perm){
    if (Array.isArray(perm)){
      return !!perm.some(p => checkPermList(p));
    } else {
      return !!checkPermList(perm);
    }
  } else {
    return true
  }
}

const ActionMenu = props => {
  const [open, setOpen] = useState(false)
  const refButton = useRef(null)

  const clickClack = (action, e, items, line) => {
    setOpen(false)
    props.action(action, e, items, line)
  }

  useEffect(() => {
    if (open)
      document.body.addEventListener(
        'click',
        ({target}) => {
          if (!refButton.current.contains(target)) setOpen(false)
        },
        {once: true}
      )
  }, [open])

  const renderAction = (action, index) => checkActionPermission(action.permission) ?
    action.link ?
      <Link key={index} to={action.link.to} target={action.link.target}>{action.name}</Link> :
      <div key={index} onClick={e => clickClack(action.action, e, props.items, action)}>
        {action.name}
      </div> :
    null

  return (
    <div className={`${st.actionMenu} ${props.className || ''}`}>
      <div className={`${st.button} ${open ? st.buttonActive : ''}`}
           onClick={() => setOpen(!open)} ref={refButton}>
        <MoreIcon/>
      </div>
      <div className={cn({
        [st.modal]: true,
        [st.modalOpen]: open,
        [st.modalClose]: !open,
      })}>
        <div className={st.menu}>{props.menu.map(renderAction)}</div>
      </div>
    </div>
  )
}

ActionMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.func,
}

export default ActionMenu