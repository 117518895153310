import React from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import { superAxiosRequest } from "axiosApi";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import Filter from '../../../ContentBox/Global/Filter/Filter'
import { dateFormat } from "helpers/date";
import { ExcelRowsParams } from '../../../utils/ExcelExport';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import {splitNumberDigits} from "../../../../helpers/math";


const FinReport = () => {
    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();

    const filterCnf = [
        {
            type: 'days-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
    ]

    const cols = [
        { name: 'ФИО клиента' },
        { name: 'Дата' },
        { name: 'Сумма' },
        { name: '№ абонемента' },
        { name: 'абонемент' },
        { name: 'Занятие' },
        { name: 'Итого' },
    ];

    const sendRequest = (params) => {
        setIsLoading(true)
        let apiConf = {
            method: 'get',
            params: {
                ...params
            }
        }
        if (apiConf.params.date_from) apiConf.params.date_from = dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")
        if (apiConf.params.date_until) apiConf.params.date_until = dateFormat(new Date(apiConf.params.date_until), "YYYY-mm-dd")
        superAxiosRequest({ ...apiConf, link: 'unidance/finreport' }).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }


    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "ФИО клиента",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Дата",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Сумма",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "№ абонемента",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "абонемент",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Занятие",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Итого",
                    ...ExcelRowsParams.headerRowParam
                },
            ]
            let footerRow = []
            let tableData = []

            let merges = []
            let wscols = [
                { wch: 40 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
            ];
            let rows = [
                { hpx: 30 },
            ]

            data.finreport.forEach(item => {
                tableData.push([
                    {
                        v: `${item.full_name}`,
                        ...ExcelRowsParams.tableRowParam.firstCell
                    },
                    {
                        v: `${item.date}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.summ}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.subscription_number}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${item.subscription}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${Boolean(item.type_activity) ? item.type_activity : ''}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    {
                        v: `${data.total_sum}`,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                ])
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData, footerRow]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            ws['!rows'] = rows
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Финансовый отчет.xlsx");
        })
    }

    return (
        <div>
            <ContentHeader title="Финансовый отчет" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => sendRequest()} />

            {isLoading ? <Loader /> : data && data.finreport.map(el => el.dates).flat().length !== 0 ?
                    (
                        <>
                            <div style={{ padding: '0 10px 10px' }}>
                                <ExportExcelButton data={data} downloadExcel={downloadExcel} />
                            </div>
                            <div className={st.tableWrapper} ref={refWrapper}>
                                <div ref={refSticky} className={st.sticky}>
                                    <table className={st.table}>
                                        <THead cols={cols} />
                                    </table>
                                </div>
                                <table className={`${st.table} ${st.tbodyStriped}`}>
                                    <THead cols={cols} ref={refThead} />
                                    <tbody className={st.tbody}>
                                        {Array.isArray(data.finreport) &&
                                            <>
                                                {data.finreport.map((row, rowIdx) =>
                                                    <tr key={rowIdx} className={st.bdt}>
                                                        <td className={`${st.fw600}`} style={{ background: "white" }}>
                                                            {row.full_name}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.date}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.summ}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.subscription_number}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.subscription}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {row.type_activity}
                                                        </td>
                                                        <td className={`${st.tdMiddle} ${st.textAlignCenter} ${st.fz12} ${st.fw500}`}>
                                                            {splitNumberDigits((data.total_sum).toFixed(2))}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : <h1 className={st.noData}>Нет данных для отображения!</h1>}
        </div >
    )
}

export default FinReport