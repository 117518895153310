import React, {useEffect, useState} from "react";
import {ReactComponent as ClueIcon} from "components/ContentBox/img/clue.svg";
import {classNames as cn} from "helpers";
import st from "./MakeWord.module.scss";

export default function MakeWord(props) {
  const [result, setResult] = useState([])
  const [letters, setLetters] = useState([])

  const [touchIndex, setTouchIndex] = useState(null);
  const [isTouched, setIsTouched] = useState(false);
  const [touched, setTouched] = useState(null)

  let dragged, dragIndex;


  const onDragStartHandler = (e, i) => {
    dragged = e.target
    dragIndex = i
    e.target.classList.add('onDrag')
    e.dataTransfer.effectAllowed = "move";
  }

  const onDragEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const onDragEnterHandler = e => {
    e.target.classList.add(st.onDragOver)
  }

  const onDragLeaveHandler = e => {
    e.target.classList.remove(st.onDragOver)
  }

  const onDragOverHandler = e => {
    // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
    if (dragged)
      // prevent default to allow drop
      e.preventDefault()
  }

  const onTouchStartHandler = (e, i) => {
    setIsTouched(true)
    setTouched(e.target)
    e.target.classList.add('onDrag')
    setTouchIndex(i);
  }
  const onTouchEndHandler = e => {
    e.target.classList.remove('onDrag')
  }

  const onDropOrTouchHandler = ({ target }) => {
    target.classList.remove(st.onDragOver)
    if(touchIndex === null && !dragged) return
    const newLetters = [...letters]

    const letterIndex = isTouched ? touchIndex : dragIndex
    const letter = isTouched ? touched : dragged

    newLetters[letterIndex].state = true;
    setLetters(newLetters)

    const res = result
    res[target.id] = letter.textContent
    setResult([...res])
  }

  const onClickHandler = (e, i) => {
    if (isTouched) {
      setTouchIndex(null)
      return
    }
    const res = result
    res[i] = ''
    setResult([...res])
    
    const idxLetter = letters?.findIndex(item => item.letter === e.target.textContent && item.state);
    if(idxLetter >= 0) {
      const newLetters = [...letters];
      newLetters[idxLetter].state = false;
      setLetters(newLetters)
    }
  }

  useEffect(() => {
    const arr = props.word.word.split('|')
    setLetters(arr.map(letter => ({ letter, state: false })))
    setResult(new Array(arr.length).fill(''))
  }, [props.word.word])

  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    const ready = letters.filter(item => !item.state).length === 0;
    props.handleAnswers(result, ready)
  }, [result, letters])

  useEffect(() => {
    if (props.studentAnswer?.answer) {
      const arr = props.studentAnswer.answer.split('|')
      setResult(arr)
      setLetters(arr.map(letter => ({ letter, state: true })))
    } else {
      const arr = props.word.word.split('|')
      setLetters(arr.map(letter => ({ letter, state: false })))
      setResult(new Array(arr.length).fill(''))
    }
  }, [props.studentAnswer?.answer, props.try])
  
  return (
    <div className={st.makeWord}>
      {props?.word?.description?.length > 0 && (
        <div className={st.makeWord__clue}>
          <ClueIcon />
          <p>{props?.word?.description}</p>
        </div>
      )}
      <div className={st.makeWord__top}>
        {letters.map(({ letter, state }, index) =>
          <span
            key={index}
            id={index}
            draggable
            onDragStart={e => onDragStartHandler(e, index)}
            onDragEnd={onDragEndHandler}
            onTouchStart={e => onTouchStartHandler(e, index)}
            onTouchEnd={onTouchEndHandler}
            className={state ? st.selected : ''}
          >
              {letter}
            </span>
        )}
      </div>
      <div className={st.makeWord__bottom}>
        {result.map((letter, index) => letter ?
          <span
            key={index}
            id={index}
            onTouchStart={() => setIsTouched(false)}
            onClick={e => onClickHandler(e, index)}
            className={cn({
              [st.makeWord__word]: true,
              [st.mistake]: props?.studentAnswer?.result === false,
              [st.right]: props?.studentAnswer?.result === true
            })}
          >
              {letter}
            </span>
          :
          <span
            key={index}
            id={index}
            onDragEnter={onDragEnterHandler}
            onDragLeave={onDragLeaveHandler}
            onDragOver={onDragOverHandler}
            onDrop={e => onDropOrTouchHandler(e, index)}
            onTouchStart={e => onDropOrTouchHandler(e, index)}
          />
        )}
      </div>
    </div>
  )
}
