import React, {useCallback, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {superAxiosRequest} from "axiosApi";
import {ExcelRowsParams} from "components/utils/ExcelExport";
import {dateFormat} from "helpers/date";
import useTableFixedHeader from "hooks/useTableFixedHeader";
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import Loader from "components/ContentBox/Global/Loader/Loader";
import THead from "components/ContentBox/Global/ReportTable/THead";
import Filter from "components/ContentBox/Global/Filter/Filter";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";


const apiConf = {
  method: 'get',
  link: 'progress_report/formed_groups_report',
}

const cols = [
  {name: 'ФИО ученика'},
  {name: 'Письменный тест'},
  {name: 'Устный тест'},
  {name: 'Формирование группы'},
  {name: 'В днях в ожидании группы'},
]

const getDaysAwaitingGroup = (lesson_start_date, group_formed_date) =>
  Math.floor(((new Date(lesson_start_date || Date.now()) - new Date(group_formed_date)) / (24 * 60 * 60 * 1000)))

const getTotalDaysAwaitingForming = arr =>
  arr?.reduce((acc, el) => acc + getDaysAwaitingGroup(el.lesson_start_date, el.group_formed_date), 0) || 0

const downloadExcel = data => {
  import('xlsx-js-style').then(XLSX => {
    const headRows = [
      cols.map(({name}) => ({
        v: name,
        ...ExcelRowsParams.headerRowParam
      }))
    ]

    const wscols = [40, 30, 30, 30, 30].map(wch => ({wch}))

    const tableData = []

    data.forEach(client => {
      tableData.push([
        client.name,
        client.written_test_date || '-',
        client.verbal_test_date || '-',
        client.group_formed_date,
        getDaysAwaitingGroup(client.lesson_start_date, client.group_formed_date)
      ].map(v => ({
        v,
        ...ExcelRowsParams.tableRowParam.cell
      })))
    })

    // Итого
    const totalDaysAwaitingForming = getTotalDaysAwaitingForming(data)
    const emptyTotalCell = {
        v: '',
        ...ExcelRowsParams.footerRowParams.cell
      }
    tableData.unshift([
      {
        v: `Итого`,
        ...ExcelRowsParams.footerRowParams.firstCell
      },
      emptyTotalCell,
      emptyTotalCell,
      emptyTotalCell,
      {
        v: `Всего ${totalDaysAwaitingForming}, Среднее ${Math.floor(totalDaysAwaitingForming / data.length)}`,
        ...ExcelRowsParams.footerRowParams.cell
      },
    ])

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet([...headRows, ...tableData])
    ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "Формирование групп")
    XLSX.writeFile(wb, "Формирование групп.xlsx");
  })
}

const FormedGroups = () => {
  const {
    refWrapper,
    refSticky,
    refThead,
    data,
    setData,
    isLoading,
    setIsLoading,
    setFilter,
  } = useTableFixedHeader();

  const [catalog, setCatalog] = useState({})

  const filterCnf = [
    {
      type: 'select-custom',
      placeholder: 'Локация',
      values: catalog?.locations,
      sort_key: 'name',
      nameState: 'location',
      is_multi: true,
    },
    {
      type: 'checkbox',
      label: 'Показать письменный тест',
      name: 'written_test_date'
    },
    {
      type: 'checkbox',
      label: 'Показать устный тест',
      name: 'verbal_test_date'
    },
    {
      type: 'month-range-picker',
      data: {
        date_from: new Date(new Date().setDate(1)),
        date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
      },
      nameState: ['date_from', 'date_until']
    },
  ]

  const onChangeFilter = (obj) => {
    const filterData = {
      ...obj,
      ...(obj.date_from && {date_from: obj.date_from}),
      ...(obj.date_until && {date_until: obj.date_until}),
    }
    setFilter(filterData);
    if (obj.date_from && obj.date_until) getFilteredData(filterData);
  }

  const getFilteredData = (filter) => {
    setIsLoading(true)
    // Дату начала устанавливаем на первый день месяца
    const dateFrom = filter.date_from ? new Date(filter.date_from) : null
    // Дату конца устанавливаем на последний день месяца
    const dateUntil = filter.date_until ? new Date(filter.date_until) : null
    superAxiosRequest({
      ...apiConf,
      params: {
        ...filter,
        date_from: dateFormat(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1), "YYYY-mm-dd"),
        ...(dateUntil && {
          date_until: dateFormat(new Date(dateUntil.getFullYear(), dateUntil.getMonth() + 1, 0), "YYYY-mm-dd")
        })
      },
    }).then(({data}) => {
      setData(data.report.sort((a, b) => a.name > b.name ? 1 : -1))
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }
  
  const fetchCatalog = useCallback(() => {
    setIsLoading(true)
    superAxiosRequest(apiConf).then(({data}) => {
      setIsLoading(false)
      setCatalog(data)
    }).catch(() => {
      setIsLoading(false)
    })
  }, [setIsLoading])

  useEffect(() => {
    fetchCatalog()
  }, [fetchCatalog])

  const totalDaysAwaitingForming = getTotalDaysAwaitingForming(data)

  return (
    <div>
      <ContentHeader title="Формирование групп"/>
      <Filter config={filterCnf} getFilterParams={onChangeFilter} drop={() => setFilter({})}/>

      {isLoading ?
        <Loader/> :
        data && data.length > 0 ? (
          <>
            <div className={st.tableWrapper} ref={refWrapper}>
              <div ref={refSticky} className={st.sticky}>
                <table className={st.table}>
                  <THead cols={cols}/>
                </table>
              </div>
              <table className={`${st.table} ${st.tbodyStriped}`}>
                <THead cols={cols} ref={refThead}/>
                <tbody className={st.tbody}>
                <tr>
                  <td className={st.tdTotal} colSpan="4">Итого</td>
                  <td className={`${st.tdTotal} ${st.textAlignCenter}`}>
                    <div>
                      Всего {totalDaysAwaitingForming},
                      Среднее {Math.floor(totalDaysAwaitingForming / data.length)}
                    </div>
                  </td>
                </tr>
                {Array.isArray(data) && data.map((row, index) =>
                  <tr key={index}>
                    <td className={`${st.textAlignCenter} ${st.fz14}`}>
                      <NavLink to={'/account_clients/' + row.user_id} target="_blank">
                        {row.name}
                      </NavLink>
                    </td>
                    <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.written_test_date || '-'}</td>
                    <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.verbal_test_date || '-'}</td>
                    <td className={`${st.textAlignCenter} ${st.fz12}`}>{row.group_formed_date}</td>
                    <td className={`${st.textAlignCenter} ${st.fz12}`}>
                      {getDaysAwaitingGroup(row.lesson_start_date, row.group_formed_date)}
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>

            <div style={{marginTop: 20}}>
              <ExportExcelButton data={data} downloadExcel={downloadExcel}>
                Выгрузить в Excel
              </ExportExcelButton>
            </div>
          </>
        ) : <h1 className={st.noData}>Нет данных для отображения!</h1>}
    </div>
  )
}

export default FormedGroups