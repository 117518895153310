import {action, observable, toJS, makeObservable, computed} from "mobx";
import {superAxiosRequest} from "axiosApi";

class LessonStudentStore {
  lessonData = null
  favoritesExercisesData = []
  errorStatus = ''
  focusExercise = 0

  constructor() {
    makeObservable(this, {
      lessonData: observable,
      favoritesExercisesData: observable,
      errorStatus: observable,
      focusExercise: observable,

      setLessonData: action,
      setLessonExercises: action,
      setLessonAssignExercises: action,
      addExAnswers: action,
      setFocus: action,
      dropFocus: action,
      clearLesson: action,

      lesson: computed,
    })
  }

  fetchLesson = uuid => {
    const apiConf = {
      method: 'get',
      link: 'ebooks/init_lesson/' + uuid,
    }
    superAxiosRequest(apiConf).then(res => {
      this.setLessonData(res.data)
      this.favoritesExercisesData = res.data.favorites || []
    }).catch(err => {
      if (err.response?.status === 404) {
        this.errorStatus = 404
      }
    })
  }

  fetchHomework = uuid => {
    const apiConf = {
      method: 'get',
      link: 'ebooks/home-work/' + uuid,
    }
    superAxiosRequest(apiConf).then(res => {
      this.setLessonData(res.data)
      this.favoritesExercisesData = res.data.favorites
    }).catch(err => {
      if (err.response?.status === 404) {
        this.errorStatus = 404
      }
    })
  }

  fetchPastLesson = uuid => {
    const apiConf = {
      method: 'get',
      link: 'ebooks/past_lessons/' + uuid,
    }
    superAxiosRequest(apiConf).then(res => {
      this.setLessonData({...res.data, uuid})
      this.favoritesExercisesData = res.data.favorites
    }).catch(err => {
      if (err.response?.status === 404) {
        this.errorStatus = 404
      }
    })
  }

  fetchStudentFavorites = id => {
    superAxiosRequest({method: 'get', link: 'ebooks/favorite_exercises/' + id}).then(res => {
      this.setLessonData(res.data)
      this.favoritesExercisesData = res.data.exercises.map(ex => ex.id)
    }).catch(err => {
      this.errorStatus = err.response.status
    })
  }

  addToFavorites = id => {
    superAxiosRequest({
      method: 'post',
      link: 'ebooks/favorite_exercises'
    }, {
      exercise_id: id
    }).then(() => {
      this.favoritesExercisesData = [...this.favoritesExercisesData, id]
    }).catch(() => {
      this.favoritesExercisesData = [...this.favoritesExercisesData, id]
    })
  }

  removeFromFavorites = id => {
    superAxiosRequest({
      method: 'delete',
      link: 'ebooks/favorite_exercises/' + id
    }).then(() => {
      this.favoritesExercisesData = this.favoritesExercisesData.filter(exID => exID !== id)
    }).catch(() => {
      this.favoritesExercisesData = this.favoritesExercisesData.filter(exID => exID !== id)
    })
  }

  setLessonData = data => this.lessonData = data

  setLessonExercises = exercises => {
    this.lessonData = {
      ...this.lessonData,
      exercises
    }
  }

  setLessonAssignExercises = assign_exercises => {
    this.setLessonData({
      ...this.lessonData,
      assign_exercises
    })
  }

  addExAnswers = (ex_id, data) => {
    this.setLessonData({
      ...this.lessonData,
      assign_exercises: this.lessonData.assign_exercises.map(ae =>
        ae.exercise_id === ex_id ?
          {exercise_id: ae.exercise_id, answers: data} :
          ae
      )
    })
  }

  setFocus = id => {
    this.focusExercise = id
  }

  dropFocus = () => {
    this.focusExercise = 0
  }

  inFavorites = id => this.favoritesExercises?.includes(id)

  clearLesson = () => {
    this.setLessonData(null)
    this.errorStatus = null
  }

  get lesson() {
    return toJS(this.lessonData)
  }

  get favoritesExercises() {
    return toJS(this.favoritesExercisesData)
  }
}

LessonStudentStore = new LessonStudentStore()

export default LessonStudentStore
