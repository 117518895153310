import React, {useEffect, useRef, useState} from 'react';
import Stores from 'Stores';
import styles from './QuestionImagesThumb.module.scss'
import HoverPreview from "../components/HoverPreview/HoverPreview";
import {ReactComponent as FullImageIcon} from "components/HeadBox/img/full_image_icon.svg";
import FileSelectModal from "components/UI/FileSelector/components/FileSelectModal";


export default function QuestionImagesThumb(props) {
    const [showModal, setShowModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);

    const handleEyeClick = (imageFile) => {
        setShowModal(true);
        setSelectedImage(imageFile);
    };

    const box = useRef()

    useEffect(() => {
        if (!props.mainImage) return
        if (props.handleAnswers) {
            props.handleAnswers([""], true)
        }
    }, [props.mainImage])

    return (
        <>
            {props.images.length > 0 &&
            <div className={styles.questionImagesThumb}>
                <ul ref={box}>
                    {props.images.map((img, index) =>
                        <li key={index}>
                            <HoverPreview
                              childrenPhoto={
                                  <div>
                                      <img src={Stores.baseLink() + img.file} alt={img.description}/>
                                  </div>
                              }
                              childrenIcon={
                                  <FullImageIcon className={styles.fullImageIcon}
                                                 onClick={() => handleEyeClick(img.file)}/>
                              }
                            />
                            <div className={styles.description}>{img.description}</div>
                        </li>
                    )}
            </ul>
            </div>
            }
            {showModal && <FileSelectModal
              img={Stores.baseLink() + selectedImage}
              setOpen={() => setShowModal(false)} />}
        </>
    )
}
