import React from 'react';
import st from "./Colours.module.scss";

const Colours = ({changeColor}) => {

    const colours = [
        {
            id: 1,
            cmd: 'foreColor',
            val: '#FFC700',
        },
        {
            id: 2,
            cmd: 'foreColor',
            val: "#1ABC97"
        },
        {
            id: 3,
            cmd: 'foreColor',
            val: "#1BE7FF"
        },
        {
            id: 4,
            cmd: 'foreColor',
            val: "#FF0022"
        },
        {
            id: 5,
            cmd: 'foreColor',
            val: "#C903FF"
        },
        {
            id: 6,
            cmd: 'foreColor',
            val: "#55B1F3"
        },
    ]

    const mappedColours = colours.map(color => {
        return (
            <div className={st.container} key={color.id}>
                <div
                    className={st.boxBorderCircle}
                    style={{border: `1px solid ${color.val}`}}
                    onClick={() => changeColor(color.cmd, color.val)}
                >
                    <div className={st.circle} style={{backgroundColor: color.val}}></div>
                </div>
            </div>
        )
    })

    return (
        <div className={st.colours}>
            {mappedColours}
        </div>
    )
};

export default Colours;