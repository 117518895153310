import React from "react";
import PropTypes from "prop-types";
import st from "./AccentSaveAnswer.module.scss";
import {ReactComponent as SaveIcon} from "components/UI/icons/save-line.svg";
import {ReactComponent as AlarmIcon} from "components/UI/icons/alarm-warning-line.svg";

const AccentSaveAnswer = ({isActive, onClick}) => {
  return (
    <div className={`${st.accentSaveAnswer} ${isActive ? '' : st.accentSaveAnswer__noHover}`}>
      {isActive ?
        <span><SaveIcon/> Don't forget to save your answer!</span> :
        <span className={st.danger}><AlarmIcon/> You didn’t answer all the tasks in the exercise.</span>}
      <button className={isActive ? undefined : st.hide} onClick={onClick}>Save Answer</button>
    </div>
  )
}

AccentSaveAnswer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default AccentSaveAnswer;
