import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import groupStore from 'Stores/groupStore';
import modalStore from 'Stores/modalStore';
import CheckboxToggle from "components/ContentBox/Global/CheckboxToggle/CheckboxToggle";
import Checkbox from "components/ContentBox/Global/Checkbox/Checkbox";
import st from './StudentSense.module.scss'
import {localisationMaster} from "Localisation/Localisation";
import Stores from 'Stores';

const StudentSense = () => {
    const {groupStudents} = groupStore
    const {modalValues, changeValues} = modalStore

    const onChangePresence = id => {
        const arr = modalValues.students.map(x => x.student === id ? {...x, presence: !x.presence} : x)
        changeValues(arr, 'students')
    }

    useEffect(() => {
        const arr = groupStudents.filter(student => student.client_profile_status).map(x => ({student: x.id, presence: x.status === 1}))
        changeValues(arr, 'students')
    }, [])
    
    return (
        <div className="super-modal__form-column">
            <div className={st.fieldset}>
                <h3 className={st.title}><span>{localisationMaster('text_1', 'groups/group', 'modal')}</span><span>{localisationMaster('text_2', 'groups/group', 'modal')}</span></h3>
                <ul>{groupStudents.filter(x => x.status === 1 && x.client_profile_status).map(student =>
                    <li key={student.id} className={st.row} onClick={() => {onChangePresence(student.id)}}>
                        <span> {`${Stores.transliterate(`${student.first_name} ${student.last_name}`)}`}</span>
                        <CheckboxToggle checked={modalValues.students.find(x => x.student === student.id)?.presence}
                                        onChange={() => {}}/>
                    </li>)}
                </ul>
            </div>

            <div className={st.fieldset}>
                <h3 className={st.title}><span>{localisationMaster('text_3', 'groups/group', 'modal')}</span><span>{localisationMaster('text_2', 'groups/group', 'modal')}</span></h3>
                <ul>{groupStudents.filter(x => x.status !== 1 && x.client_profile_status).map(student =>
                    <li key={student.id} className={st.row} onClick={() => {onChangePresence(student.id)}}>
                        <span>  {`${Stores.transliterate(`${student.first_name} ${student.last_name}`)}`}</span>
                        <CheckboxToggle checked={modalValues.students.find(x => x.student === student.id)?.presence}
                                        onChange={() => {}}/>
                    </li>)}
                </ul>
            </div>

            <div className="super-modal__form-field">
                <div className="super-modal__form-label">{localisationMaster('comment', 'online-schedule/future-slots')}:</div>
                <div>
                    <textarea placeholder={`${localisationMaster('comment', 'online-schedule/future-slots')}:`}
                              value={modalValues.comment}
                              onChange={e => changeValues(e.target.value, 'comment')}/>
                </div>
            </div>

            <div className={st.confirm}>
                <Checkbox checked={modalValues.confirmed}
                          onChange={() => {}}
                          label={localisationMaster('text_61', 'modal_templates')}/>
            </div>
        </div>
    );
};

export default observer(StudentSense)
