import React, {useState, useEffect} from 'react';
import './dayofweek.css';
import {localisationMaster} from "../../../../Localisation/Localisation";

export default function DayOfWeek(props) {
    const [daySelected, setDaySelected] = useState(props.bl)
    const days = [
        {
            name: `${localisationMaster('monday', 'DayOfWeek')}/${localisationMaster('wednesday', 'DayOfWeek')}/${localisationMaster('friday', 'DayOfWeek')}`,
            compiled: true,
            days: [1, 3, 5]
        },
        {
            name: `${localisationMaster('tuesday', 'DayOfWeek')}/${localisationMaster('thursday', 'DayOfWeek')}`,
            compiled: true,
            days: [2, 4]
        },
        {name: localisationMaster('monday', 'DayOfWeek'), compiled: false, days: 1},
        {name: localisationMaster('tuesday', 'DayOfWeek'), compiled: false, days: 2},
        {name: localisationMaster('wednesday', 'DayOfWeek'), compiled: false, days: 3},
        {name: localisationMaster('thursday', 'DayOfWeek'), compiled: false, days: 4},
        {name: localisationMaster('friday', 'DayOfWeek'), compiled: false, days: 5},
        {name: localisationMaster('saturday', 'DayOfWeek'), compiled: false, days: 6},
        {name: localisationMaster('sunday', 'DayOfWeek'), compiled: false, days: 7},
    ]

    const dayChangeHandler = (i, c) =>{
        if(!c){
            if(!daySelected.includes(i)){
            setDaySelected([...daySelected, +i])
            } else if(daySelected.length > 1){
            setDaySelected(daySelected.filter(x=>x !== +i))
            }
        } else {
            setDaySelected(i)
        }
    }
    useEffect(() => {
        props.objectChangeHandler(daySelected, 'weekdays')
    }, [daySelected])
    
    useEffect(() => {
        if(props.bl !== daySelected){
            setDaySelected([...props.bl])
        }
    }, [props.bl])
    return (
        <div className={`dow-box ${props.disabled ? `dow-dis`: ''}`}>
        {days.map((item,index)=>{
            return(
                <div className={!daySelected.includes(item.days) ? null : 'dow-box-selected'} onClick={()=> {!props.disabled && dayChangeHandler(item.days, item.compiled)}} key={index}>
                    {item.name}
                </div>
            )
        })}            
        </div>
    )
}
