import React, {useEffect, useState} from 'react';
import {superAxiosRequestEasy} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";

import './ChoiceLocation.scss'

export default function ChoiceLocation({locationId, soft_drop, suppotSelectors, onChangeLocation, widget, onChangeAge, onChangeProgram, age, program, isDisabled}) {

    const [locationList, setLocationList] = useState(null);
    const [ages, setAges] = useState([false, []])
    const [programs, setPrograms] = useState([false, []])

    const getLocationTimeStart = id => {
        const location = locationList.find(x => x.id === id)
        return location.start_at
    }

    const getLocationTimeEnd = id => {
        const location = locationList.find(x => x.id === id)
        return location.end_at
    }

    const getLocationCity = id => {
        const location = locationList.find(x => x.id === id)
        return location.address.split(/[\s,]+/, 2)
    }

    const getLocation = id => locationList.find(loc => loc.id === id)

    /*const returnLocation = id => {
        if (locationList !== 0) {
            // soft_drop()//!* ?
            if (id)
                onChangeLocation(getLocation(id))
            else if (locationId !== 0)
                onChangeLocation(getLocation(locationId))
        }
    }*/

    /*const selectorDisabled = param => {
        if (widget) {
            return true
        } else {
            return !param
        }
    }*/

    useEffect(() => {
        let apiConf = {
            method: 'get',
            link: 'db/locations',
            params: {
                offset: 0,
                limit: 50,
                is_active: true,
            }
        }

        // Get locations
        superAxiosRequestEasy(apiConf, 'data').then(response => {
            const result = response.data.result
            const aLocation = widget ? result.filter(x => x['only_online'] === true) : result
            aLocation.sort((a, b) => a.name < b.name ? 1 : -1)
            setLocationList(aLocation);
        })

        if (suppotSelectors) {
            // Get ages
            apiConf = {
                method: 'get',
                link: 'db/age',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            superAxiosRequestEasy(apiConf).then(response => {
                setAges([true, response.data.result.sort((a, b) => a.from_age - b.from_age)])
            })

            // Get programs
            apiConf = {
                method: 'get',
                link: 'db/programs',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            superAxiosRequestEasy(apiConf).then(response => {
                setPrograms([true, response.data.result.sort((a, b) => a.name > b.name ? 1 : -1)])
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (locationId !== 0 && locationList !== null)
            onChangeLocation(getLocation(locationId))
            // eslint-disable-next-line
    }, [locationId, locationList])

    return (
        <div className="choiceLocation">
            <div className="choiceLocation__selectors">
                <div className="choiceLocation__selectWrapper">
                    {/*<select className="choiceLocation__select" onChange={e => returnLocation(+e.target.value)} value={locationId}>
                    <option value='0'
                            disabled> {localisationMaster('select-placeholder', 'online-schedule/record-customer')}</option>
                    {locationList !== null &&
                    locationList.map((item, index) => {
                        if (item.is_active) {
                            return (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            )
                        }
                    })
                    }
                </select>*/}
                    <SelectCustom
                        placeholder={localisationMaster('select-placeholder', 'online-schedule/record-customer')}
                        options={locationList}
                        sortKey="from_age"
                        valueID={locationId}
                        isDisabled={isDisabled}
                        onChange={o => onChangeLocation(o)}
                        isLoading={locationList === null}
                    />
                </div>

                {suppotSelectors &&
                <>
                    <div className="choiceLocation__selectWrapper">
                        {/*<select className="choiceLocation__select" onChange={e => onChangeAge(+e.target.value)} disabled={selectorDisabled(ages[0])} value={age}>
                            <option value='0' disabled selected> Выберите возраст</option>
                            {ages[0] &&
                            ages[1].map((item, index) => {
                                if (item.is_active) {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            })
                            }
                        </select>*/}
                        <SelectCustom
                            placeholder="Выберите возраст"
                            options={ages[1]}
                            valueID={age}
                            onChange={o => onChangeAge(o.id)}
                            isDisabled={widget}
                            isLoading={!ages[0]}
                        />
                    </div>

                    <div className="choiceLocation__selectWrapper">
                        {/*<select
                            className="choiceLocation__select"
                            onChange={e => onChangeProgram(+e.target.value)}
                            disabled={selectorDisabled(programs[0])}
                            value={program}
                        >
                            <option value='0' disabled selected> Выберите программу</option>
                            {programs[0] &&
                            programs[1].sort((a, b) => a.name > b.name).map((item, index) => {
                                if (item.is_active) {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            })
                            }
                        </select>*/}
                        <SelectCustom
                            placeholder="Выберите программу"
                            options={programs[1]}
                            valueID={program}
                            onChange={o => onChangeProgram(o.id)}
                            isDisabled={widget}
                            isLoading={!programs[0]}
                        />
                    </div>
                </>
                }
            </div>

            {(locationId !== 0 && locationList !== null) &&
            <div className="choiceLocation__notice">
                <b>Обратите внимание:</b> данная локация работает
                с <b>{getLocationTimeStart(+locationId)} до {getLocationTimeEnd(+locationId)}</b> в часовом
                поясе: <b>(GMT+6) {getLocationCity(+locationId)}</b>
            </div>
            }
        </div>
    )
}  
