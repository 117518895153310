import React, { useState, useEffect } from "react";
import { classNames as cn } from "helpers";
import "./InputTypes.scss";

const InputTypes = ({
  value,
  onChange,
  error = false,
  disabled = false,
  placeholder,
}) => {
  const protocols = ["https://", "http://"];
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState("https://");
  const [classes, setClasses] = useState("");

  useEffect(() => {
    onChange(type + text);
  }, [text, type]);

  useEffect(() => {
    if (value.length > `${text}${type}`.length) {
      const protocol = protocols.find((item) => value.startsWith(item));
      const url = value.replace(protocol, protocol + "|").split("|");
      setType(url[0]);
      setText(url[1]);
    };
    if(!value.startsWith("http") && value.length < text.length) {
      setText('');
    };
  }, [value]);

  useEffect(() => {
    if (isOpen)
      document.body.addEventListener("click", () => setIsOpen(false), {
        once: true,
      });
  }, [isOpen]);

  return (
    <div
      className={cn({
        "placementTestInputType": true,
        "placementTestInputType--error": error,
        [classes]: true,
      })}
    >
      <div
        className={cn({
          "placementTestInputType__current": true,
          "placementTestInputType__current--disabled": disabled,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{type}</p>
      </div>
      <ul className={isOpen ? "placementTestInputType__dropdown" : "dn"}>
        {protocols.map((data, index) => {
          if (data !== type) {
            return (
              <li
                key={index}
                onClick={() => {
                  setType(data);
                  setIsOpen(false);
                }}
              >
                {data}
              </li>
            );
          }
        })}
      </ul>
      <input
        type="text"
        disabled={disabled && true}
        onChange={(e) => setText(e.target.value.replace('https://', '').replace('http://', ''))}
        value={text}
        placeholder={placeholder}
        onFocus={() => setClasses("placementTestInputType--focus")}
        onBlur={() => setClasses("")}
      />
    </div>
  );
};

export default InputTypes;
