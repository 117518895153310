import React, { useEffect, useRef } from 'react';
import Paper from 'components/UI/Paper/Paper';
import Flex from 'components/UI/Flex/Flex';
import Time from './Time';
import Break from './Break';
import Duration from './Duration';
import TimeGap from './TimeGap';
import styles from './SlotSettings.module.scss';
import slotsScheduleSettingsStore, { DataType } from 'Stores/slotsScheduleSettingsStore';
import { scrollToRef } from 'helpers';

function SlotSettings() {
  const settingsRef = useRef();
  const { setData, editingData } = slotsScheduleSettingsStore;

  useEffect(() => {
    setData(settingsRef, DataType.settingsRef);
    scrollToRef(settingsRef, 100);
  }, [editingData]);

  return (
    <Paper ref={settingsRef}>
      <Flex classes={styles.wrapper}>
        <Time/>
        <Duration/>
        <Break/>
        <TimeGap/>
      </Flex>
    </Paper>
  );
}
export default SlotSettings;
