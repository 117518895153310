import React, {useEffect, Fragment, useState} from "react";
import {observer} from "mobx-react";
import {superAxiosRequest} from "axiosApi";
import {permissions} from "settings/permissions";
import {checkPermList} from "helpers";
import {dateFormat} from "helpers/date";
import useUrlParams from 'hooks/useUrlParams';
import useLocalization from "hooks/useLocalization";
import Stores from "Stores";
import listingStore from "Stores/listingStore";
import groupStore from "Stores/groupStore";
import asideModalStore from "Stores/asideModalStore";
import AsideFilterStore from "components/UI/AsideFilter/asideFilterStore";
import GroupCard from "components/ContentBox/Groups/GroupCard";
import Loader from "components/ContentBox/Global/Loader/Loader";
import GroupTabs from "components/Modules/Groups/GroupList/GroupTabs";
import notFound from "components/ContentBox/img/noRegister.svg";
import SearchBooks from "components/Modules/Ebook/Books/components/SearchBooks/SearchBooks";
import GroupsForm from "components/ContentBox/Global/GlobalModalSingle/templates/groups/GroupsForm";
import Pagination from "components/UI/Pagination/Pagination";
import {HeaderAddButton} from "components/UI/SuperButton/SuperButton";
import PageHeader from "components/UI/PageHeader/PageHeader";
import AsideFilter from "components/UI/AsideFilter/AsideFilter";
import FilterTextValues from "components/UI/AsideFilter/FilterTextValues";
import style from "./GroupsList.module.scss";
import {ReactComponent as SystemLineInformation} from "components/UI/icons/information-line.svg";
import useLocalStorageFilter from "helpers/useLocalStorageFilter";
import { useHistory } from 'react-router-dom';


const GroupsList = observer(() => {
  const {page, perPage, setPage, setPerPage, data, setFilterParams, filterType, setFilterType} = listingStore
  const {isOpenFilter, closeFilter, setTextValues, changeTextValue, filterTextValues} = AsideFilterStore

  const [gc, setgc] = useState([false, []])
  const [dropped, setDropped] = useState(false)
  const [listing, setListing] = useState(null)
  const {urlParams, setAllParams} = useUrlParams()
  const [filter, updateFilter] = useLocalStorageFilter()
  const history = useHistory()

  const {translate} = useLocalization({
    section: 'groups',
    subsection: 'all_groups',
    dict: {
      "study_groups": "Study Classes",
      "add_group": "Add Class",
      "add_new_group": "Add New Class",
      "search_placeholder": "Search for a class by Name or Number",
      "filter": "Filter",
      "search": "Search",
      "groups": "Classes",
      "students": "Students",
      "total": "Total",
      "actual": "Actual",
      "debt": "Debt",
      "page": "Page",
      "company": "Company",
      "standard": "Standard",
      "mini": "Mini class",
      "one_on_one": "One on one",
      "select_company": "Select Company",
      "location": "Location",
      "select_location": "Select Location",
      "offline": "Offline",
      "online": "Online",
      "cancel": "Cancel",
      "save": "Save",
      "group_name_or_number": "Class Name or Number",
      "client_information": "Client Information",
      "client_info": "Name, surname, IIN, Phone or e-mail",
      "teacher": "Teacher",
      "select_teacher": "Select Teacher",
      "Location": "Location",
      "group_level": "Class Level",
      "select_group_level": "Select Class Level",
      "group_type": "Class Type",
      "select_group_type": "Select Class Type",
      "course_level": "Course Level",
      "select_course_level": "Select Course Level",
      "groups_type": "Classes Type",
      "training_dates": "Training Dates",
      "start_date": "Start Date",
      "end_date": "End Date",
      "reset_all": "Reset All",
      "apply": "Apply",
      "academic_hour": "ac. hr."
    }
  })

  const groupsType = [
    {id: 0, name: translate.offline},
    {id: 1, name: translate.online}
  ]

  const config = [
    {
      nameState: 'name_number',
      placeholder: translate.group_name_or_number,
      type: "input",
      label: translate.group_name_or_number,
    },
    {
      nameState: 'student',
      placeholder: translate.client_info,
      type: "input",
      label: translate.client_information
    },
    {
      values: gc[0] ? gc[1].teachers.items.sort((a, b) => a.full_name > b.full_name ? 1 : -1) : [],
      nameState: 'teacher',
      placeholder: translate.select_teacher,
      type: "select",
      key_name: 'teachers',
      option_label_key: 'full_name',
      sort_key: 'full_name',
      isSearchable: true,
      isSearch: true,
      convertNum: true,
      label: translate.teacher
    },
    {
      type: "select-multi",
      placeholder: translate.select_location,
      apiLink: 'db/locations',
      sort_key: 'name',
      nameState: 'location',
      convertNum: true,
      label: translate.location
    },
    {
      placeholder: translate.select_group_type,
      values: gc[0] ? gc[1].types.items : [],
      nameState: 'type',
      type: "select",
      convertNum: true,
      label: translate.group_type
    },
    {
      values: groupsType,
      nameState: 'online',
      type: "type_selector",
      key_name: 'online',
      label: translate.groups_type
    },
    {
      type: 'date_day_picker',
      nameState: ['date_from', 'date_until'],
      label: translate.training_dates
    },
  ];

  const changeListing = () => setListing(new Date()); // Помогает обновить компонент если данные по фильтру или табам изменились.

  const getStatusGroups = () => listingStore.getMeta.group_statuses?.find(x => x.filter_type === listingStore.filterType)

  useEffect(() => {
    setDropped(true);
    if (urlParams) { // Проверяем в url на наличие query params. Если есть, то распределяем их по store.
      Object.keys(urlParams).forEach(key => setFilterParams(urlParams[key], key));
      urlParams?.limit && setPerPage(urlParams?.limit);
      urlParams?.filter_type && setFilterType(urlParams.filter_type);
      if (+urlParams?.offset) { // Здесь вычисляем на какой странице сохранился пагинация.
        urlParams?.offset && setPage(urlParams?.offset / (urlParams?.limit || perPage) + 1);
      }
    }
    changeListing() // Дает разрешения нижестоящему useEffect на запрос в бек после обновлений страницы. Помогает избавиться от лишних запросов.

    if (!gc[0]) {
      let apiConf = {
        method: 'get',
        link: 'groups/groups_catalog',
        headers: {
          'accept-language': "en,ru;q=0.9,de;q=0.8,en-US;q=0.7,ru-RU;q=0.6,kk;q=0.5"
        }
      }
      superAxiosRequest(apiConf).then(res => setgc([true, res.data]))
    }

    return () => {
      listingStore.dropData()
      AsideFilterStore.unmountFilter()
    }
  }, [])

  useEffect(() => {
    if (listing === null) return;

    const params = {
      ...filter, // по фильтру,
      filter_type: filterType, // по табам,
      offset: (page - 1) * perPage, // пагинация,
      limit: perPage
    }
    const apiConf = {
      method: 'get',
      link: 'groups',
      params
    }

    const unlisten = history.listen((location) => {
      // Проверяем, если произошел переход на другую страницу
      if (location.pathname !== '/groups/all-groups') {
        updateFilter({}) // Сбросить значения фильтра
      }
    })

    setAllParams(params); // сохраняет в url
    listingStore.fillData(apiConf) // и отправляет GET запрос по ним.

    return () => {
      unlisten(); // Отменяем прослушивание при размонтировании компонента
    }
  }, [listing, page, perPage, filter, filterType])

  const addGroupButton = () => {
    const ms = {
      title: translate.add_new_group,
      modalBodyComponent: () => <GroupsForm/>,
      button: translate.add_group,
      link: 'groups',
      onSubmitSuccess: groupStore.redirectToGroup,
      isResponsive: true,
      valid: {
        location: 'required',
        status: 'required',
        number: 'required',
        name: 'required',
        type: 'required',
        discipline: 'required',
      }
    }
    const md = {
      number: `${dateFormat(new Date(), "YYYYmm")}`,
      online: true,
      new_schedule: true
    }
    asideModalStore.activity(ms, md)
  }

  return (
    <Fragment>
      <div className={style.container}>
        <PageHeader title={translate.study_groups}>{checkPermList(permissions.add_study_groups) &&
          <HeaderAddButton title={translate.add_new_group || ''} onClick={addGroupButton}/>}
        </PageHeader>

        <div className={style.pageColumn}>
          <div className={style.searchFilterValues}>
            <SearchBooks
              onSearch={text => {
                updateFilter({...filter, name_number: text})
                changeTextValue('name_number', {
                  key: 'name_number',
                  label: translate.group_name_or_number,
                  value: text
                })
              }}
              onClear={() => {
                const {name_number, ...obj} = filter
                updateFilter(obj)
                setTextValues(filterTextValues.filter(field => field.key !== 'name_number'))
              }}
              value={filter?.name_number || ''}
              translate={translate}
              placeholder={`${translate.search_placeholder}...`}/>

            {!!filterTextValues.length && <FilterTextValues onDelete={key => {
              const {[key]: _, ...obj} = filter
              updateFilter(obj)
            }}/>}

            {isOpenFilter && <AsideFilter
              translate={translate}
              getFilterParams={e => {
                updateFilter(e)
                setPage(1)
              }}
              config={config}
              filterValue={filter}
              onDropFilter={() => {
                updateFilter({})
                closeFilter()
              }}
            />}
          </div>

          <GroupTabs list={Stores.observableArray(listingStore.meta).group_statuses}/>

          {!listingStore.loading ?
            <>
              {(data && dropped) ?
                <>
                  <div className={style.header}>
                    <SystemLineInformation/>
                    <div className={style.info}>
                      <div>
                        {translate.groups}: <b>{listingStore.meta.count_items}</b>
                      </div>
                      <div className={style.delimiter}></div>
                      <div>
                        {translate.students}: <b>{getStatusGroups()?.active_students}</b> / <b>{getStatusGroups()?.total_students}</b>
                      </div>
                    </div>
                  </div>
                  <div className={style.groups}>{listingStore.loading ?
                    <Loader/> :
                    Stores.observableArray(listingStore.data).map((item, index) =>
                      <GroupCard key={index} item={item} translate={translate}/>)}
                  </div>
                  <Pagination
                    translate={translate}
                    limit={+perPage}
                    count={listingStore.meta.count_items || 0}
                    page={page}
                    onChange={setPage}
                    onChangePerPage={setPerPage}/>
                </>
                :
                <div className={style.notFoundGroupBlock}>
                  <img src={notFound} alt="not found"/>
                  {checkPermList(permissions.add_study_groups) &&
                    <button className={style.addGroupButton} onClick={addGroupButton}>
                      {translate.add_new_group}
                    </button>}
                </div>}
            </> : <Loader/>}
        </div>
      </div>
    </Fragment>
  )
})

export default GroupsList
