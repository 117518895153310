import React, {useState, useEffect, useRef} from 'react';
import st from "./WritingEssay.module.scss"

export default function WritingEssay(props) {
  const [inputText, setInputText] = useState('')
  const [characterCount, setCharacterCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);

  const ref = useRef()
  const handleInput = e => {
    if (props.studentAnswer) return;
    const newText = e.target.value;
    setInputText(newText);
    countWords(newText);
  }
  const countWords = (text) => {
    if(text.length !== 0) {
      const cleanedText = text.replace(/[-.,|\/_=+\?|!`'".,(){}*:;$#@&]/g, ' ');

      const words = cleanedText.split(/\s+/);
      const wordCount = words.filter(word => word.trim() !== '').length;
      const charCount = text.length;

      setCharacterCount(charCount);
      setWordCount(wordCount);
    } else {
        setCharacterCount(0);
        setWordCount(0);
    }
  };

  useEffect(() => {
    countWords(inputText)
  })

  useEffect(() => setInputText(''), [props.attempts])

  //обновление стейта с ответами в компоненте с самим вопросом
  useEffect(() => {
    const ready = inputText.length > 0 && !props.studentAnswer
    props.handleAnswers(inputText, ready)
  }, [inputText])

    useEffect(() => {
        const aiCheck = props.aiChecks.find(el => el.attempt === props.try)
        if (aiCheck && !props.isStudent) {
            const newText = `\n\nПроверка AI: ${aiCheck.text}`
            setInputText(props.studentAnswer?.answer + `\n\n${'-'.repeat(70)}` + newText)
        } else {
            if (typeof props.attempts[props.try - 1]?.result_in_detail === 'string' && !props.isStudent) {
                    const newText = `\n\nПроверка AI: ${props.attempts[props.try - 1]?.result_in_detail}`
                    setInputText(props.studentAnswer?.answer + `\n\n${'-'.repeat(70)}` + newText)
            } else {
                setInputText(props.studentAnswer?.answer || '')
            }
        }
    }, [props.studentAnswer, props.attempts, props.aiChecks])

  useEffect(() => {
    const textArea = ref.current
    if (textArea) {
      textArea.style.height = '36px'
      textArea.style.height = textArea.scrollHeight + 'px'
    }
  }, [inputText])

  return (
    <div className={st.writingEssay}>
      <div className={st.essayField}>
        <textarea placeholder={" "} value={inputText} onChange={handleInput} ref={ref}/>
        <div className={st.countLettersBlock}>
          <span>Characters: {characterCount}</span>
          <span className={st.line}></span>
          <span>Words: {wordCount}</span>
        </div>
      </div>
    </div>
  )
}
