import React, {useContext} from 'react';
import Context from "components/Modules/Study/TeacherResult/resultContext";
import st from "./AnswerStat.module.scss";
import {ReactComponent as IconEdit} from 'components/ContentBox/img/edit2.svg'
import {localisationMaster} from "Localisation/Localisation";


export default function AnswerStat(props) {
    const {stats, ...answer} = props.answer
    const {isClient} = useContext(Context)

    return (
        <div className={st.stats}>
            <div className={st.col}>
                <div className={st.param}>
                    <span>{localisationMaster('text_2', 'study/test', 'result')}</span>
                    <b>{stats.correct_answers}</b>
                </div>
                <div className={st.param}>
                    <span>{localisationMaster('text_11', 'study/test', 'result')}</span>
                    <b> </b>
                </div>
            </div>
            <div className={st.col}>
                <div className={st.param}>
                    <span>{localisationMaster('text_12', 'study/test', 'result')}</span><b>{stats.points}</b>
                </div>
                <div className={st.param}>
                    <span className={st.paramEdit}>
                        {localisationMaster('text_13', 'study/test', 'result')}
                        {!isClient && <IconEdit onClick={props.openModal}/>}
                    </span>
                    <b>{answer.employee_points || localisationMaster('button_2', 'alert')}</b>
                </div>
            </div>
            {!isClient &&
            <div className={st.col}>
                <div className={st.param}>
                    <span>{localisationMaster('text_14', 'study/test', 'result')}</span>
                    <b>{answer.focus_live_count} ({answer.focus_live_during} {localisationMaster('sec', 'pays/invoice')}.)</b>
                </div>
                <div className={st.param}>
                    <span>{localisationMaster('text_16', 'study/test')}</span>
                    <b>{answer.mouse_freeze_count} ({answer.mouse_freeze_during} {localisationMaster('sec', 'pays/invoice')}.)</b>
                </div>
            </div>}
        </div>
    );
};
