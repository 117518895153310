import React, { useEffect, useState } from 'react';
import { superAxiosRequest } from 'axiosApi';
import { observer } from "mobx-react";
import { localisationMaster } from 'Localisation/Localisation'
import modalStore from "Stores/modalStore";
import Select from '../../Select/Select';
import FilterInput from "components/ContentBox/Global/Filter/FilterInput";
import SelectRequest from '../../Select/SelectRequest';
import DatePicker from "react-datepicker";
import Checkbox from "components/ContentBox/Global/Checkbox";
import AddAvatar from "./AddAvatar";
import Textarea from '../../Textarea/Textarea';
import InputInn from "../../InputInn/InputInn";
import SelectCustom from "../../Select/SelectCustom";
import ImageSelector from "../../ImageSelector/ImageSelector";
import FileUploader from "./files/FileUploader";

const Trio = props => {

    const ms = modalStore.data;
    let apiConf;
    const { changeValues, changeValuesArray, check, modalValue, errorColor } = modalStore
    const [selectorItems, setselectorItems] = useState([])
    const [media, setMedia] = useState([])
    const pushClientData = (items) => {
        let data = {
            client_id: items.id,
            doc_type: ms.id
        }
        changeValuesArray(data)
        //props.objectChangeHandler(ms.id, 'doc_type')
    }


    const insertFile = e => {
        const file = e.target.files[0];
        setMedia([file, file.name])
    }

    const changeHandler = (e, key_name, type) => {
        if (type === 'media') {
            insertFile(e)
        } else {
            changeValues(e, key_name);
        }
    }

    const returnStoreValue = key_name => modalValue[key_name]

    const onChangeCheckbox = () => changeValues(!modalValue[ms.checkbox_name], ms.checkbox_name)

    const getMediaName = name => {
        const arr = name.split('.')
        const ext = arr.pop()
        let filename = arr.join('')
        filename = filename.length > 15 ? filename.substring(0, 15) + '...' : filename
        return `${filename}.${ext}`
    }

    useEffect(() => {
        if (ms.active_inputs.includes('select') && ms.select_link) {
            apiConf = {
                method: 'get',
                link: ms.select_link,
            }
            if (ms.link_params) apiConf.params = ms.link_params
            superAxiosRequest(apiConf).then(response => {
                setselectorItems(response.data.result)
            })
        }
        if (ms.active_inputs.includes('multi-select-filtred')) {
            apiConf = {
                method: 'get',
                link: ms.select_link
            }
            superAxiosRequest(apiConf).then(() => {
                //setselectorSecondItems(response.data.result)
            })
        }
        if (ms.ready_selected) {
            changeValues(+ms.ready_selected, ms.name_select)
        }
        if (ms.old) {
            changeValues(ms.old, ms.name_textarea)
        }
        if (ms.course_id) {
            changeValues(ms.course_id, 'course')
        }
        /*if (ms.lesson) {
            changeValues(ms.lesson, 'lesson')
        }*/
        /*if(ms.lesson_edit){
            let arr = {
                 course: ms.lesson_edit.course,
                 description: ms.lesson_edit.description,
                 home_work: ms.lesson_edit.home_work,
                 name: ms.lesson_edit.name
            }
            changeValuesArray({...arr})
        }*/
        if (ms.course_edit) {
            let arr = {
                company: +ms.course_edit.company,
                course_level: +ms.course_edit.course_level,
                id: +ms.course_edit.id,
                name: ms.course_edit.name,
                skill_level: +ms.course_edit.skill_level,
            }
            changeValuesArray({ ...arr })
        }
        if (modalValue.start_date === undefined && ms.active_inputs.includes('date')) {
            changeValues(new Date(), 'start_date')
        }
        if (props.fix_data) {
            changeValuesArray({ ...props.fix_data })
        }
    }, [])

    useEffect(() => {
        if (media.length) {
            changeValues(media[0], ms.name_media)
        }
    }, [media])
    
    return (
        <div className="super-modal__form-column">
            {ms.active_inputs.includes('multi-number') ?
                <div className="super-modal-twin-selectors">
                    <input name="from" type="number" min="1" value={returnStoreValue(ms.name_number)}
                        id="1"
                        placeholder={ms.pl_number}
                        onChange={(e) => changeHandler(e.target.value, ms.name_number)}
                        style={{ borderColor: check(ms.name_number) && errorColor }}
                    />

                    <input name="to" type="number" max="100" value={returnStoreValue(ms.name_number_second)}
                        id="2"
                        placeholder={ms.pl_number_second}
                        onChange={(e) => changeHandler(e.target.value, ms.name_number_second)}
                        style={{ borderColor: check(ms.name_number_second) && errorColor }}
                    />

                </div>
                : null}
            {ms.active_inputs.includes('name-number') ?
                <div className="super-modal-twin-selectors">
                    <input
                        placeholder={ms.pl_text}
                        name="valueInput"
                        type="text"
                        value={returnStoreValue(ms.name_text)}
                        onChange={(e) => changeHandler(e.target.value, ms.name_text)}
                        style={{ borderColor: check(ms.name_text) && errorColor }}
                    />

                    <input name="to" type="number" max="100000" value={returnStoreValue(ms.name_number)}
                        id="2"
                        placeholder={ms.pl_number}
                        onChange={(e) => changeHandler(e.target.value, ms.name_number)}
                        style={{ borderColor: check(ms.name_number) && errorColor }}
                    />
                    {/*<div className='superModalGroup__modalRowLine'>*/}
                    {/*    <p>Размер :</p>*/}
                    {/*    <Counter    step={1}*/}
                    {/*                max={1000000000}*/}
                    {/*                returnValue={props.returnValue}*/}
                    {/*                objectChangeHandler={props.objectChangeHandler}*/}
                    {/*                name={'size'}/>*/}
                    {/*</div>*/}

                </div>
                : null}
            {ms.active_inputs.includes('multi-select-filtred') ?
                <div className="super-modal-twin-selectors">
                    <Select onChange={ms.ChangeError}
                        value_error={check(ms.name_select)}
                        valueId={returnStoreValue(ms.name_select)}
                        nameState={ms.name_select}
                        selected={1} stateError={ms.name_select + "_error"}
                        myChangeHandler={(e) => changeHandler(e.id, ms.name_select, 'int')}
                        text={ms.pl_select}
                        value={selectorItems}
                        ChangeError={ms.ChangeError}
                        width="40%" />
                    <Select onChange={ms.ChangeError}
                        value_error={check(ms.name_select_second)}
                        valueId={returnStoreValue(ms.name_select_second)}
                        nameState={ms.name_select_second}
                        selected={0} stateError={ms.name_select_second + "_error"}
                        myChangeHandler={(e) => changeHandler(e.id, ms.name_select_second, 'int')}
                        text={ms.pl_select_second}
                        value={selectorItems.filter(x => x[ms.filter_by] === selectorItems)}
                        ChangeError={ms.ChangeError}
                        width="40%" />

                </div>
                : null}

            {ms.active_inputs.includes('text') &&
                <div>
                    <input
                        placeholder={ms.pl_text}
                        name="valueInput"
                        type="text"
                        value={returnStoreValue(ms.name_text) || ''}
                        onChange={e => changeHandler(e.target.value, ms.name_text)}
                        //style={{ borderColor: check(ms.name_text) && errorColor }}
                        className={check(ms.name_text) ? 'super-modal__input--error' : undefined}
                    />
                </div>}

            {ms.active_inputs.includes('twoInputs') ?
                <div>
                    <p className='contentZoom'>ZOOM Client ID</p>
                    <input
                        placeholder={ms.pl_text}
                        name="valueInput"
                        type="text"
                        value={ms.name_text}
                        onChange={(e) => changeHandler(e.target.value, ms.name_text)}
                        style={{ borderColor: check(ms.name_text) && errorColor }}
                    />
                    <p className='contentZoom'>ZOOM Secret ID</p>
                    <input
                        placeholder={ms.pl_textTwo}
                        name="valueInputTwo"
                        type="text"
                        value={ms.name_textTwo}
                        onChange={(e) => changeHandler(e.target.value, ms.name_textTwo)}
                        style={{ borderColor: check(ms.name_textTwo) && errorColor }}
                    />
                </div> : null}
                
            {ms.active_inputs.includes('select') ?
                // <Select onChange={ms.ChangeError}
                //         value_error={check(ms.name_select)}
                //         nameState={ms.name_select}
                //         s_value={returnValuefunc(ms.name_select)}
                //         selected={0} stateError={ms.name_select + "_error"}
                //         myChangeHandler={(e)=>changeHandler(e.target.value, ms.name_select,)}
                //         text={ms.pl_select}
                //         value={ms.select_array ? ms.select_array : selectorItems}
                //         ChangeError={ms.ChangeError}
                //         new_key={ms.new_key}
                //         width="100%"/>
                    <SelectCustom
                        placeholder={ms.pl_select}
                        options={ms.select_array ?  ms.select_array: selectorItems}
                        valueID={returnStoreValue(ms.name_select) || ''}
                        onChange={o => changeHandler(o.id, ms.name_select)}
                        isSearchable={ms.select_is_searchable}
                        error={check(ms.name_select)}
                    />
                : null}
            {ms.active_inputs.includes('SelectRequest') ?
                <>                
                      <SelectRequest
                        value_error={check(ms.select_name)}
                        valueId={returnStoreValue(ms.select_name, 1)}
                        selected={0}
                        myChangeHandler={(e) => changeHandler(e.id, ms.select_name)}
                        text={ms.pl_select}
                        value={returnStoreValue(ms.select_name)}
                        width="100%"
                        optionsHours={ms.optionsHours || null}
                        link={ms.select_link}
                    />
                </>

                : null}
            {ms.active_inputs.includes('SelectCustomRequest') ?
                <>
                      <SelectCustom
                        placeholder={ms.placeholder}
                        apiLink={ms.api_link}
                        valueID={returnStoreValue(ms.name_select)}
                        onChange={o => changeHandler(o.id, ms.name_select)}
                    />
                </>

                : null}

            {ms.active_inputs.includes('SelectCustom') && 
                <SelectCustom
                    placeholder={ms.placeholder}
                    options={ms.options}
                    apiLink={ms.api_link}
                    apiConfParams={ms.api_conf_params}
                    sortKey={ms.sort_key}
                    optionLabelKey={ms.option_label_key}
                    getOptionLabel={ms.get_option_label}
                    formatOptionLabel={ms.format_option_label}
                    isSearchable={ms.is_search}
                    valueID={returnStoreValue(ms.name_select)}
                    onChange={o => changeHandler(o.id, ms.name_select)}
                />}

            {ms.active_inputs.includes('multi-select-split') ?
                <div className="super-modal-twin-selectors">
                    <SelectRequest onChange={props.ChangeError}
                        value_error={check(ms.multiSelect[0].name)}
                        valueId={returnStoreValue(ms.multiSelect[0].name)}
                        nameState={ms.multiSelect[0].name}
                        selected={0} stateError={"selectValue_error"}
                        myChangeHandler={(e) => changeHandler(e.target.value, ms.multiSelect[0].name)}
                        text={ms.multiSelect[0].pl}
                        value={returnStoreValue(ms.multiSelect[0].nameState)}
                        ChangeError={props.ChangeError}
                        width="230px"
                        link={ms.multiSelect[0].link}
                        subMenu={props.subMenu}
                        key_name={ms.multiSelect[0].key_name}
                        returnValue={props.returnValue}
                    />
                    <SelectRequest onChange={props.ChangeError}
                        value_error={check(ms.multiSelect[1].name)}
                        valueId={returnStoreValue(ms.multiSelect[1].name, 1)}
                        nameState={ms.multiSelect[1].name}
                        selected={0} stateError={"selectValue_error"}
                        myChangeHandler={(e) => changeHandler(e.target.value, ms.multiSelect[1].name)}
                        text={ms.multiSelect[1].pl}
                        value={returnStoreValue(ms.multiSelect[1].nameState)}
                        ChangeError={props.ChangeError}
                        width="230px"
                        link={ms.multiSelect[1].link}
                        subMenu={props.subMenu}
                        key_name={ms.multiSelect[1].key_name}
                        returnValue={props.returnValue}
                    />

                </div>
                : null}
            {ms.active_inputs.includes('filter-input') ?
                <FilterInput placeholder={ms.placeholder} link='accounts/clients' clientId={pushClientData} />
                : null}

            {ms.active_inputs.includes('media_img') &&
                <div>
                    <AddAvatar changeHandler={changeValues}
                        objectChangeHandler={changeValues}
                        returnValue={props.returnValue}
                        // values={props.inputsValues}
                        modal_settings={ms}
                        errorCheker={check}
                    />
                </div>}

            {ms.active_inputs.includes('image-selector') &&
                <div>
                    <ImageSelector onChange={image => changeValues(image, 'image')} />
                </div>}

            {ms.active_inputs.includes('media') &&
                <div className={'super-modal-media' + (check(ms.name_media) ? ' super-modal-media--error' : '')}>
                    <input id="file" name="file" type="file"
                        onChange={insertFile}
                        //onChange={(e) => changeHandler(e, ms.name_media, 'media')}
                        className="inputfile"
                    //style={{ borderColor: check(ms.name_media) && errorColor }}
                    />
                    <label className="labelFile" htmlFor="file">
                        {!media[0] ?
                            ms.media_name ? ms.media_name :
                                localisationMaster('button-image-one', 'tests/questions', 'image_plus_variants') :
                            localisationMaster('button-image-three', 'tests/questions', 'image_plus_text') + ': ' + getMediaName(media[1])}
                    </label>
                    <p className="bold">
                        {ms.media_type ? ms.media_type : '(png, jpeg, jpg)'}
                    </p>
                </div>
            }

            {ms.active_inputs.includes('file-upload') &&
                <FileUploader onUploadEnd={o => { changeValues(o.file, ms.name_media) }} error={check(ms.name_media)} />}

            {ms.active_inputs.includes('date') ?
                <div className="super-modal-date">
                    <DatePicker selected={modalValue.start_date !== undefined ? new Date(modalValue.start_date) : new Date()}
                        onChange={date => changeValues(date, 'start_date')}
                        dateFormat="dd.MM.yyyy" />
                    <span>{ms.date_label}</span>


                </div>
                : null}
            {ms.active_inputs.includes('textarea') ?
                <>
                    <textarea value={returnStoreValue(ms.name_textarea)}
                        placeholder={ms.pl_textarea}
                        id=""
                        onChange={(e) => changeHandler(e.target.value, ms.name_textarea)}
                        style={{ borderColor: check(ms.name_textarea) ? errorColor : '' }}

                    />
                    {(ms.size_textarea && returnStoreValue(ms.name_textarea) && returnStoreValue(ms.name_textarea).length > ms.size_textarea) &&
                        <label style={{ color: 'red' }} htmlFor={ms.name_textarea}>Максимальное количество символов {ms.size_textarea}</label>}
                </>
                : null}
            {ms.active_inputs.includes('textarea_second') ?
                <textarea
                    value={returnStoreValue(ms.name_textarea_s)}
                    placeholder={ms.pl_textarea_s}
                    onChange={(e) => changeHandler(e.target.value, ms.name_textarea_s)}
                    style={{ borderColor: check(ms.name_textarea_s) && errorColor, marginTop: '15px' }}

                />
                : null}
            {ms.active_inputs.includes('multi_inn') ?
                <div className="modalClientInfo__field" style={{ width: '100%' }}>
                    <p>ИНН/ИИН:</p>
                    <InputInn
                        // error={errorInn && returnValue('inn') !== oldInn}
                        disabled={false}
                        onChange={val => { changeValues(val, ms.name_inn) }}
                        value={returnStoreValue(ms.name_inn)} />
                    <p style={{ paddingTop: '10px', fontSize: '12px', justifyContent: 'center' }}>
                        Вам необходимо заполнить ИИН/ИНН
                    </p>
                </div>
                : null}
            {ms.active_inputs.includes('textarea_tags') ?
                <Textarea onChange={changeHandler}
                    value={returnStoreValue(ms.name_textarea_tags)}
                    stateName={ms.name_textarea_tags}
                    placeholder={ms.pl_textarea_tags}
                    style={{ borderColor: check(ms.name_textarea_tags) && errorColor, marginTop: '15px' }}
                    error={check('description')}
                />
                : null}
            {ms.active_inputs.includes('textarea_tags_second') ?
                <Textarea onChange={changeHandler}
                    value={returnStoreValue(ms.name_textarea_tags_second)}
                    stateName={ms.name_textarea_tags_second}
                    placeholder={ms.pl_textarea_tags_second}
                    style={{ borderColor: check(ms.name_textarea_tags_second) && errorColor, marginTop: '15px' }}
                />
                : null}

            {ms.active_inputs.includes('checkbox') && !ms.checkbox_hidden ?
                <div>
                    <Checkbox
                        id="duas"
                        label={ms.pl_checkbox}
                        checked={modalValue[ms.checkbox_name] || false}
                        isChecked={onChangeCheckbox}
                    />
                </div>
                : null}
        </div>
    )
}

export default observer(Trio);
