import React from 'react';
import StudyTestResultStore from "Stores/studyTestResultStore";
import modalStore from "Stores/modalStore";
import AnswerQuestion from "./CompleteAnswer/AnswerQuestion";
import AnswerStat from "./CompleteAnswer/AnswerStat";
import AnswerFiles from "./CompleteAnswer/AnswerFiles";
import st from "./CompleteAnswer.module.scss"
import {localisationMaster} from "Localisation/Localisation";


export default function CompleteAnswer(props) {
    const {setAnswerData} = StudyTestResultStore

    const answer = props.answer

    const openModal = () => {
        const ms = {
            type: 'quad',
            title: 'Ответ №' + props.number,
            fields: [
                {
                    type: 'text',
                    name: 'employee_points',
                    label: 'Балл',
                    onChange: (prev, next) => (next > 0 && next <=100) || next === '' ? +next : prev,
                },
                {
                    type: 'text',
                    name: 'employee_comment',
                    label: localisationMaster('table-row-title-2', 'company/companies'),
                }
            ],
            button: 'Сохранить',
            is_put: true,
            link: 'study/set_student_answer_points/' + answer.id,
            func: data => setAnswerData(data, answer.id)
        }
        const md = {
            ...(answer?.employee_comment && { employee_comment: answer.employee_comment }),
            ...(answer?.employee_points && { employee_points: answer?.employee_points }),
        }
        modalStore.activity(ms, md)
    }

    return (
        <div key={answer.id} className={answer.correct_answers.includes(false) ? st.answerFail : st.answer}>
            <AnswerQuestion answer={props.answer}/>
            <AnswerStat answer={answer} openModal={openModal}/>
            <div className={st.comment}>
                <span>{localisationMaster('table-row-title-2', 'company/companies')}:</span>
                <div>{answer.employee_comment}</div>
            </div>
            <AnswerFiles answer={answer}/>
        </div>
    );
};
