import React from 'react'
import {shuffle} from 'helpers'
import Stores from 'Stores'
import AudioPlayer from 'components/ContentBox/Global/MediaPlayer/AudioPlayer'
import VideoPlayer from 'components/ContentBox/Global/MediaPlayer/VideoPlayer'
import Variants from './components/Variants'
import Loader from 'components/ContentBox/Global/Loader/Loader'
import SelectWords from './components/SelectWords'
import CompleteSentence from "./components/CompleteSentence";
import MatchItems from "./components/MatchItems";
import WriteWords from "./components/WriteWords";
import TextAnswer from "./components/TextAnswer";
import CheckVariants from "./components/CheckVariants";
import MatchWordsImages from "./components/MatchWordsImages";
import Essay from "./components/Essay";
import MakeWord from "./components/MakeWord";
import SortSentences from "./components/SortSentences";
import './TestQuestion.scss'
import DragWords from "./components/DragWords";

export default function TestQuestion(props) {

  const glue = arr => {
    props.getAnswer(props.question[0], arr)
  }

  const renderQuestion = question => {

    if (question.answers && ['variants', 'question_image_variants', 'audio_plus_variants', 'video_plus_variants'].includes(question.type))
      question.answers = [...shuffle(question.answers)]

    const logo = question.files.find(q => q.is_question)

    const renderImage = logo ?
      <div className="questions-box__image">
        <img src={Stores.baseLink() + logo.file} alt="q"/>
      </div> : null

    const renderAudioFile = question.audio_file ?
      <div>
        <audio src={Stores.baseLink() + question.audio_file} controls></audio>
      </div> : null

    const renderQuestionText =
      <div className="questions-box__text" dangerouslySetInnerHTML={{__html: question.text_question}}/>

    let renderContent = null

    switch (question.type) {
      case 'question_plus_text':
        renderContent = <TextAnswer text={props.answer.answers?.[0]}
                                    onChange={val => props.getAnswer(props.question[0], val ? [val] : [])}/>
        break;
      case "variants":
        renderContent = <Variants values={question} answers={glue} selected={props.answer?.answers || null}/>
        break;
      case "question_image_variants":
        renderContent = <CheckVariants question={question} onChange={glue} answers={props.answer?.answers || null}/>
        break
      case "audio_plus_variants":
        renderContent =
          <>
            <AudioPlayer a_link={Stores.baseLink() + question.files?.find(q => !q.is_question)?.file}/>
            <Variants values={question} answers={glue} selected={props.answer?.answers || null}/>
          </>
        break
      case "video_plus_variants":
        renderContent =
          <>
            <VideoPlayer v_link={Stores.baseLink() + question.files.find(q => !q.is_question).file}/>
            <Variants values={question} answers={glue} selected={props.answer?.answers || null}/>
          </>
        break
      case "text_missing_words":
        renderContent = <SelectWords text={question.answers[0].value}
                                     handleAnswers={glue}
                                     answers={props.answer?.answers}/>
        break
      case "complete_sentence":
        renderContent = <CompleteSentence sentence={question.answers[0].value} onComplete={glue} answer={props.answer}/>
        break
      case 'word_matching':
      case 'image_matching':  
        renderContent =
          <MatchItems type={question.type} cols={question.answers} answers={props.answer.answers} onComplete={glue}/>
        break
      case 'match_words_images':
        renderContent = <MatchWordsImages type={question.type} cols={question.answers} answers={props.answer.answers} onComplete={glue} />
        break
      case 'drag_words_in_blanks':
        renderContent = <DragWords text={question.answers.value}
                                   words={question.answers.words}
                                   onComplete={glue}
                                   answers={props.answer?.answers}/>
        break
      case 'words_in_blanks':
        renderContent = <WriteWords text={question.answers[0].value}
                                    handleAnswers={glue}
                                    answers={props.answer?.answers}/>
        break
      case 'essay':
        renderContent = <Essay text={props.answer.answers?.[0]}
                               files={props.answer.files}
                               onChange={val => {
                                 props.getAnswer(props.question[0], val.text, val.files,)
                               }}/>
        break
      case 'make_word_from_letters': 
        renderContent = <MakeWord letters={question.answers} onComplete={glue} answers={props.answer.answers}/>
        break
      case 'sort_sentences':
        renderContent = <SortSentences answers={props.answer.answers || question.answers} onChange={glue}/>
        break
      default:
        break
    }

    return (
      <>
        {renderImage}
        {renderAudioFile}
        {renderQuestionText}
        <div className="questions-box__content">
          {renderContent}
        </div>
      </>
    )
  }

  return (
    <div className="questions-box">
      {props.loader ? <Loader/> : renderQuestion(props.question[1])}
    </div>
  )
}
