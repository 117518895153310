import React, {useEffect, useRef, useState} from 'react';
import Stores from 'Stores'
import st from "./ImagesCarousel.module.scss"
import {ReactComponent as IconArrow} from "components/ContentBox/img/arrow-left.svg";
import {ReactComponent as FullImageIcon} from "components/HeadBox/img/full_image_icon.svg";
import HoverPreview from "components/ContentBox/Lessons/components/HoverPreview/HoverPreview";
import FileSelectModal from "components/UI/FileSelector/components/FileSelectModal";

export default function ImagesCarousel(props) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [imageLine, setImageLine] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setImageLine(props.images)
  }, [props.images])

  const reset = () => setCurrentSlideIndex(0)

  const onSlideHandler = direction => {
    if (direction === 'prev')
      setCurrentSlideIndex(Math.max(currentSlideIndex - 1, 0))
    if (direction === 'next')
      setCurrentSlideIndex(Math.min(currentSlideIndex + 1, props.images.length - 1))
  }

  const handleEyeClick = (imageFile) => {
    setShowModal(true);
    setSelectedImage(imageFile);
  };

  useEffect(() => {
    window.addEventListener('resize', reset)

    return () => {
      window.removeEventListener('resize', reset)
    }
  }, [])

  useEffect(() => {
    const slides = refSlides.current
    const slide = slides.children[currentSlideIndex]
    if (slide) {
      const offset = Math.min(slide.offsetLeft, slides.scrollWidth - slides.parentElement.offsetWidth)
      slides.style.left = -offset + 'px'
    }
  }, [currentSlideIndex])

  useEffect(() => {
    if (!props.mainImage) return
    if (props.handleAnswers) {
      props.handleAnswers([""], true)
    }
  }, [props.mainImage])

  const refSlides = useRef()

  return (
    <>
      <div>
        <div className={st.slider}>
          <div className={st.control}>
            <div onClick={() => {
              onSlideHandler('prev')
            }} data-disabled={currentSlideIndex < 1}><IconArrow/></div>
            <div className={st.sliderInfo}>
              <span className={st.slideCount}>{`${currentSlideIndex + 1}`.padStart(2, '0') + ' of ' + `${props.images.length}`.padStart(2, '0')}</span>
              <div className={st.imageLineBlock}>
                {imageLine?.map((el, index) =>
                  <div
                    key={index}
                    className={`${st.imageLine} ${currentSlideIndex === index ? st.active : ''}`}
                    onClick={() => setCurrentSlideIndex(index)}
                  ></div>
                )}
              </div>
            </div>
            <div onClick={() => {
              onSlideHandler('next')
            }} data-disabled={currentSlideIndex > props.images.length - 2}><IconArrow/></div>
          </div>
          <ul ref={refSlides}>
            {props.images.map((img, index) =>
              <li key={index}>
                <HoverPreview
                  childrenPhoto={
                    <div>
                      <img src={Stores.baseLink() + img.file} alt={img.description}/>
                    </div>
                  }
                  childrenIcon={
                    <FullImageIcon className={st.fullImageIcon}
                                   onClick={() => handleEyeClick(img.file)}/>
                  }
                />
                <div className={st.description}>{img.description}</div>
              </li>
            )}
          </ul>
        </div>
      </div>
      {showModal && <FileSelectModal
        img={Stores.baseLink() + selectedImage}
        setOpen={() => setShowModal(false)} />}
    </>
  )
}
