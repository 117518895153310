import React, {useEffect, useState} from 'react';
import Checkbox from "components/ContentBox/Global/Checkbox";
import cn from "./Question.module.css"

export default function Question(props) {
    const initAnswer = {
        correct: false,
        text: ''
    }

    const [answers, setAnswers] = useState([])

    const onChangeHandler = (val, index, key) => {
        const arr = answers
        arr[index][key] = val
        setAnswers([...arr])
    }

    const deleteAnswer = index => {
        const arr = answers
        arr.splice(index, 1)
        setAnswers([...arr])
    }

    useEffect(() => {
        if (props.question.variants.length > 0){
            setAnswers(props.question.variants)
        } else {
            setAnswers([initAnswer])
        }
    }, [])

    useEffect(() => {
        props.onChange(answers, props.index, 'variants')
    }, [answers])

    return (
        <div className={cn.questionBlock}>
            <div>Вопрос {props.index + 1}</div>
            <div className={cn.questionDelete} onClick={() => props.onDelete(props.index)}>x</div>
            <div>
                <input type="text"
                       placeholder="Текст вопроса"
                       value={props.question.text}
                       onChange={e => props.onChange(e.target.value, props.index, 'text')} />
            </div>

            {props.isPoint &&
            <div className={cn.pointsRow}>
                <span>Количество баллов за этот вопрос:</span>
                <div className={cn.pointsField}>
                    <input type="text"
                           value={props.question.points}
                           onChange={e => props.onChange(+e.target.value, props.index, 'points')}/>
                </div>
            </div>}

            <div className={cn.answers}>
                {answers.map((item, index) =>
                    <div key={index} className={cn.answer}>
                        <div>
                            <Checkbox id={`${props.index}_${index}_id`} checked={item.correct}
                                      isChecked={() => onChangeHandler(!item.correct, index, 'correct')}/>
                        </div>

                        <div className={cn.variant}>
                            <input type="text"
                                   placeholder={`Вариант ответа ${index+1}`}
                                   value={item.text}
                                   onChange={e => onChangeHandler(e.target.value, index, 'text')}
                            />
                        </div>
                        <span className={index > 0 ? cn.deleteButton : cn.deleteButtonHide} onClick={() => deleteAnswer(index)}/>
                    </div>
                )}
            </div>
            <div className={"modal-question-add-button-add " + cn.addButton}
                 onClick={() => setAnswers([...answers, initAnswer])}>+</div>
        </div>
    );
};