import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {dateFormat} from "helpers/date";
import ModalStore from "Stores/modalStore";
import DatePicker from "react-datepicker";
import SelectCustom from "components/ContentBox/Global/Select/SelectCustom";
import DateTime from "components/ContentBox/Global/DateTime/DateTime";
import st from "./ModalSchedule.module.scss"
import CheckboxToggle from "../../ContentBox/Global/CheckboxToggle/CheckboxToggle";

export const unidanceTypes = [
  {id: 1, name: 'Танцы'},
  {id: 2, name: 'Музыка'},
  {id: 3, name: 'Индивидуальные'},
  {id: 4, name: 'Аренда зала'},
]

const ModalSchedule = () => {
  const {data: ms, modalValues, changeValues, check} = ModalStore

  const [period, setPeriod] = useState(1)

  const onChangeDate = val => {
    const date = dateFormat(val, "YYYY-mm-dd")
    changeValues(ms.is_put ? date : [date], 'date')
    // сбрасываем период
    setPeriod(1)
  }

  const onChangePeriod = (check, val) => {
    setPeriod(check ? val : 1)
    if (check){
      const startDate = new Date(modalValues.date[0])
      const arr = [modalValues.date[0]]
      for (let i = 1; i < val; i++){
        startDate.setDate(startDate.getDate() + 1)
        arr.push(dateFormat(startDate, "YYYY-mm-dd"))
      }
      changeValues(arr, 'date')
    } else {
      changeValues([modalValues.date[0]], 'date')
    }
  }

  useEffect(() => {
    if (!modalValues.date) changeValues([dateFormat(new Date(), "YYYY-mm-dd")], 'date')
  }, [])

  return (
    <div className="super-modal__form-column">
      <div className="super-modal__form-field">
        <div className="super-modal__form-label--required">Мастер</div>
        <SelectCustom apiLink="unidance/master"
                      apiGetResult={({result}) => result.map(({id, first_name, last_name}) => ({
                        id,
                        name: `${first_name} ${last_name}`
                      }))}
                      placeholder="Мастер"
                      valueID={modalValues.master}
                      onChange={({id}) => changeValues(id, 'master')}
                      error={check('master')}
        />
      </div>
      <div className="super-modal__form-field">
        <div className="super-modal__form-label--required">Тип</div>
        <SelectCustom placeholder="Тип"
                      options={unidanceTypes}
                      valueID={modalValues.type}
                      onChange={({id}) => changeValues(id, 'type')}
                      error={check('type')}
        />
      </div>
      <div className="super-modal__form-row2">
        <div className="super-modal__form-field">
          <div className="super-modal__form-label--required">Дата занятия</div>
          <div className={`react-datepicker-custom ${st.reactDatepickerCustom}`}>
            <DatePicker selected={new Date(ms.is_put ? modalValues.date : modalValues.date?.[0] || Date.now())}
                        onChange={onChangeDate}
                        dateFormat="dd.MM.yyyy"/>
          </div>
        </div>
        <div className="super-modal__form-field">
          <div className="super-modal__form-label--required">Время занятия</div>
          <DateTime placeholder='9:00'
                    errorCheker={() => {
                    }}
                    start_at={modalValues.start_at}
                    finish_at={modalValues.finish_at}
                    objectChangeHandler={changeValues}/>
        </div>
      </div>
      {!ms.is_put &&
        <div className="super-modal__form-column">
          {[
            {num: 3, text: 'дня'},
            {num: 7, text: 'дней'},
            {num: 10, text: 'дней'},
          ].map(item =>
            <CheckboxToggle key={item.num}
                            checked={period === item.num}
                            onChange={val => {onChangePeriod(val, item.num)}}
                            right={`Продлить на ${item.num} ${item.text}`}
                            mode="inline"/>
          )}
        </div>}
    </div>
  )
}

export default observer(ModalSchedule)
