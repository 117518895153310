import React, { useState } from 'react';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import MainTableRow from 'components/ContentBox/Global/MainTable/MainTableRow/MainTableRow';
import ClientNotify from 'components/Modules/Slots/components/ClientNotify/ClientNotify';
import { colsSettings, getColSettings } from './settings';
import { showSwal } from './utils';
import slotsBookedScheduleStore from 'Stores/slotsBookedScheduleStore';
import LocalStorage from 'services/LocalStorage';
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";


function CustomerItem({data}) {
  const history = useHistory();
  const [isModalShown, setIsModalShown] = useState(false);
  const {deleteCustomer} = slotsBookedScheduleStore;
  const timeZone = LocalStorage.get('ud').user_data.profile.time_zone;

  const handleDrop = () => {
    // TODO: add drop handle
  };

  const handleActions = (type, evt, item) => {
    switch (type) {
      case 'zoom':
        return showSwal({date: item.date, link: item.zoom_url}, 'zoom');
      case 'notify':
        setIsModalShown(true);
        break;
      case 'code':
        const {email_code, sms_code, student_phone, student_email} = item;
        const swalType = email_code ? 'email' : 'sms';
        const swalData = email_code ? { email:  student_email, code: email_code} : { phone: student_phone, code: sms_code };

        return showSwal(swalData, swalType);
      case 'emails':
        window.open('/c/mailing/email', '_blank');
        break;
      case 'sms':
        window.open('/c/mailing/sms', '_blank');
        break;
      case 'edit':
        history.push(`/online-schedule-v2/record-customer/${data.id}`);
        break;
      case 'customer':
        const {uuid_cache = ''} = item;
        const a = document.createElement('a');
        a.target = "_blank";
        a.href = window.location.hostname !== 'localhost'
          ? `https://${window.location.hostname}/api/v1/online_schedule/client/${uuid_cache}`
          : `https://dev.phoenix24.kz/api/v1/online_schedule/client/${uuid_cache}`;
        a.click();
        break;
      case 'delete':
        swal('', 'Вы действительно хотите удалить запись?', 'warning', {buttons: ['Отмена', 'OK']})
          .then((response) => {
            if (!response) {
              return;
            }
            deleteCustomer(
              data.id,
              () => swal('', 'Запись успешно удалена', 'success'),
              () => swal('', 'Ошибка удаления записи', 'warning'),
            );
          });
        break;
      default:
        return;
    }
  };
  const colsSettingsCopy = getColSettings(colsSettings, 'is_active', data, timeZone)

  return (
    <li>
      <MainTableRow
        items={data}
        colsSettings={checkPermList(permissions.change_slot_client) ? colsSettingsCopy : {...colsSettingsCopy, action: []}}
        drop={handleDrop}
        actions={handleActions}
      />
      { isModalShown &&
        <ClientNotify
          slotClientIDs={[data.id]}
          onClose={() => setIsModalShown(false)}
        />
      }
    </li>
  );
}

export default CustomerItem;
