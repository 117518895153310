import React from 'react';
import PropTypes from "prop-types";
import st from './ResultSwitcher.module.scss'
import {localisationMaster} from "Localisation/Localisation";


const ResultSwitcher = props => {
    const values = [localisationMaster('text_9', 'study/test', 'result'), localisationMaster('text_10', 'study/test', 'result')]

    return (
        <div className={st.switcher}>{values.map((v, i) =>
            <div key={i} className={i === +props.value ? st.btnActive : st.btn} onClick={() => props.onChange(i)}>{v}</div>)}
        </div>
    );
};

ResultSwitcher.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
}

export default ResultSwitcher;
