import React, {useState} from 'react'

import Filter from '../../../ContentBox/Global/Filter/Filter'
import Loader from "components/ContentBox/Global/Loader/Loader";
import {timeConverter} from "components/ContentBox/Online-schedule/components/functions";
import styles from "./LoadingAudiences.module.scss"
import {dateFormat} from "helpers/date";
import {superAxiosRequest} from "axiosApi";
import LineChartTable from './LineChartTable/LineChartTable';
import ContentHeader from '../../../ContentBox/Global/ContentHeader';
import {ExcelRowsParams} from '../../../utils/ExcelExport';


const LoadingAudiences = () => {
    const [isLoading, setIsLoading] = useState({})
    const [groupCatalog, setGroupCatalog] = useState({})
    const [total, setTotal] = useState(0)

    const filterCnf = [
        {
            type: 'select-custom',
            placeholder: 'Компания',
            values: groupCatalog?.companies,
            sort_key: 'name',
            nameState: 'company',
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Локация',
            values: groupCatalog?.locations,
            sort_key: 'name',
            nameState: 'location',
        },

        {
            type: 'month-range-picker',
            data: {
                date_from: new Date(new Date().setDate(1)),
                date_until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
            },
            nameState: ['date_from', 'date_until']
        },
        {
            type: 'select-custom',
            is_multi: true,
            placeholder: 'Уровень группы',
            values: groupCatalog?.skill_levels,
            sort_key: 'name',
            nameState: 'skill_level',

        },
        {
            type: "type-selector",
            nameState: "online",
            values: [
                {id: 2, name: "Все группы"},
                {id: 1, name: "Онлайн"},
                {id: 0, name: "Офлайн"},
            ]
        },
    ]


    const [myData, setMyData] = useState(false)
    const [date, setDate] = useState(
        {
            from: new Date(new Date().setDate(1)),
            until: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
        }
    )



    const getMaxHour = (maxMinute) => {
        let maxHour = Math.ceil((maxMinute / 45) / 10) * 10
        return maxHour > 10 ? maxHour : 10
    }

    const sendRequest = (param) => {
        if (param.company && param.date_until && param.date_from) {
            setIsLoading(true)
            let apiConf = {
                method: 'get',
                link: 'progress_report/classrooms_loading',
                params: {
                    ...param
                }
            }
            delete apiConf.params.is_active
            if (apiConf.params.skill_level && apiConf.params.skill_level.length > 0) apiConf.params.skill_level = `[${apiConf.params.skill_level.map(item => item)}]`
            else delete apiConf.params.skill_level
            if (apiConf.params.course_level && apiConf.params.course_level.length > 0) apiConf.params.course_level = `[${apiConf.params.course_level.map(item => item)}]`
            else delete apiConf.params.course_level
            if (apiConf.params.location && apiConf.params.location.length > 0) apiConf.params.location = `[${apiConf.params.location.map(item => item)}]`
            else delete apiConf.params.location
            if (apiConf.params.date_from) apiConf = {...apiConf, params: {...apiConf.params, date_from: dateFormat(new Date(apiConf.params.date_from), "YYYY-mm-dd")}}
            if (apiConf.params.date_until) apiConf = {...apiConf, params: {...apiConf.params, date_until: dateFormat(new Date(apiConf.params.date_until.getFullYear(), apiConf.params.date_until.getMonth() + 1, 0), "YYYY-mm-dd")}}
            superAxiosRequest(apiConf).then(res => {
                setTotal(res.data.total)
                setDate({
                    from: new Date(apiConf.params.date_from),
                    until: new Date(apiConf.params.date_until)
                })
                let maxMinute = 0
                res.data.result.forEach(element => {
                    if (element.minutes > maxMinute) maxMinute = element.minutes
                })

                const maxHours = getMaxHour(maxMinute)
                const sectionsLenght = maxHours / 10
                let locationsData = [...res.data.result]
                let newState = []
                let inddex = 0

                while (Boolean(locationsData[0])) {
                    let id = locationsData[0].location.id
                    newState[inddex] = {
                        location: locationsData[0].location,
                        audience: [],
                        total_minutes: 0,
                    }
                    locationsData = locationsData.filter(item => {
                        if (item.location.id !== id) return true
                        else {
                            newState[inddex].total_minutes = (Number(newState[inddex].total_minutes) + Number((item.minutes / 45).toFixed(2))).toFixed(2)
                            newState[inddex].audience.push(
                                {
                                    "minutes": item.minutes,
                                    "id": item.id,
                                    "name": item.name,
                                    hours: (item.minutes / 45).toFixed(2),
                                    lenght: (item.minutes / 45) / sectionsLenght,
                                })
                            return false
                        }
                    })
                    inddex++
                }             
                setMyData({
                    settings: {
                        maxValue: maxHours,
                        interval: sectionsLenght
                    },
                    rows: newState
                })
                setIsLoading(false)
            })
        }


    }
    const sendFerstReqest = () => {
        superAxiosRequest({
            method: 'get',
            link: 'progress_report/classrooms_loading'
        })
            .then(({data}) => {
                setGroupCatalog(data)
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false));
    }

    const downloadExcel = (data) => {
        import('xlsx-js-style').then(XLSX => {
            const headRow = [
                {
                    v: "Локация",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Аудитория",
                    ...ExcelRowsParams.headerRowParam
                },
                {
                    v: "Колличиство ак.ч.",
                    ...ExcelRowsParams.headerRowParam
                }
            ]

            let tableData = []

            let merges = []
            let wscols = [
                {wch: 35},
                {wch: 20},
                {wch: 20},
            ];
            let currentRow = 1
            data.forEach((row) => {
                merges.push({
                    s: {r: currentRow, c: 0},
                    e: {r: currentRow  + row.audience.length - 1, c: 0},
                  })
                row.audience.forEach((item) => {
                    currentRow++
                    tableData.push([
                        {
                            v: `${row.location.name}`,
                            ...ExcelRowsParams.tableRowParam.firstCell
                        },
                        {
                            v: `${item.name}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                        {
                            v: `${item.hours}`,
                            ...ExcelRowsParams.tableRowParam.cell
                        },
                    ])
                })
            })

            let wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([headRow, ...tableData]);
            ws['!cols'] = wscols;
            ws["!merges"] = merges
            XLSX.utils.book_append_sheet(wb, ws, "List 1");

            XLSX.writeFile(wb, "Загрузка аудиторий.xlsx");
        })
    };

    return (
        <div className={styles.page}>
            <ContentHeader title="Загрузка аудиторий" />
            <Filter config={filterCnf} getFilterParams={e => sendRequest(e)} drop={() => sendFerstReqest()} />
            {isLoading ? <Loader />
                : myData ? <>
                    <LineChartTable
                        chartData={myData}
                        title={`Загрузка аудиторий в часах c ${timeConverter(date.from, 'day')} по ${timeConverter(date.until, 'day')}`}
                    />
                    <button onClick={() => {downloadExcel(myData.rows)}} style={{
                        margin: '10px 10px 0 ',
                        width: 'calc(100% - 20px)'
                    }}>Выгрузить в Excel</button>
                    <div className={styles.total}>Итого по всем локациям: {total / 60} ч.</div>
                </> : <h1 style={{textAlign: 'center'}}>Нет данных для отображения!</h1>
            }

        </div>
    )
}

export default LoadingAudiences