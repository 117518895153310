import React, {useContext} from 'react';
import {superAxiosRequest} from "axiosApi";
import useFileUpload from "hooks/useFileUpload";
import StudyTestResultStore from "Stores/studyTestResultStore";
import Context from "../../../../resultContext";
import st from "./AnswerFiles.module.scss";
import {ReactComponent as IconFile} from "components/ContentBox/img/google-docs.svg";
import {localisationMaster} from "Localisation/Localisation";


export default function AnswerFiles(props) {
    const {setAnswerData} = StudyTestResultStore

    const {isClient} = useContext(Context)

    const {files, ...answer} = props.answer

    const onUploadEnd = path => {
        const apiConf = {
            method: 'put',
            link: 'study/set_student_answer_points/' + answer.id,
        }
        const body = {
            files: [{file: path}]
        }
        superAxiosRequest(apiConf, body).then(({data}) => {
            setAnswerData(data, answer.id)
        }).catch(() => {

        })
    }

    const {refInput, setFile} = useFileUpload({url: 'files/study_test_doc_upload', onUploadEnd})

    const getFileName = path => {
        const basename = path.split('/').pop()
        const aPart = basename.split('.')
        const ext = aPart.pop()
        const name = aPart.join('')
        return [`${name.length > 25 ? `${name.slice(0, 25)}...` : name}`, ext]
    }

    return (
        !isClient || files.length ?
            <div className={`${st.answerFiles} ${(props.modal && !files.length) && st.noLine}`}>
                {(!isClient && !props.modal) &&
                <div className={st.chooseFile}>
                    <input type="file" ref={refInput} style={{display: 'none'}} onChange={e => setFile(e.target.files[0])}/>
                    <button className="button-type1" onClick={() => {refInput.current.click()}}>{localisationMaster('text_16', 'study/test', 'result')}</button>
                    <span>{localisationMaster('text_15', 'study/test', 'result')}</span>
                </div>}

                {files.length > 0 &&
                <ul className={st.files}>{files.map(f =>
                    <li key={f.id} className={st.file}>
                        <div className={st.fileIcon}>
                            <IconFile/>
                            <span>{getFileName(f.file)[1]}</span>
                        </div>
                        <span className={st.fileName}>{getFileName(f.file)[0]}</span>
                    </li>)}
                </ul>}
            </div>
            : null
    );
};
