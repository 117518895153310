import React, {useEffect, useState} from 'react';
import {addZero} from "helpers/math";
import st from './TestInfo.module.scss'

export default function TestInfo({ getResult, initTest }) {
    const [now, setNow] = useState(new Date())
    const to = new Date(initTest.must_be_finished)
    const diff = new Date(to - now)

    const getTimeString = () => {
        const arr = `${addZero(diff.getUTCHours())}:${addZero(diff.getUTCMinutes())}:${addZero(diff.getUTCSeconds())}`.split('')
        return arr.map((x, i) => <span key={i}>{x}</span>)
    }

    const breakTest = () => getResult(initTest.id, 'student_left_test')

    useEffect(() => {
        diff < 0 ? getResult(initTest.id, 'time_out') : setTimeout(() => setNow(new Date()), 1000);
    }, [now])

    return (
        <div className={st.info}>
            <h1>{initTest.purpose_name}</h1>
            <div>До конца осталось:</div>
            <div className={st.timer}>{diff > 0 ? getTimeString() : '00 : 00 : 00'}</div>
            <div>
                <button className="button-type1" onClick={breakTest}>Прервать тест и выйти</button>
            </div>
        </div>
    );
};