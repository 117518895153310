import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ObservableSet } from 'mobx';
import { observer } from 'mobx-react';
import Flex from 'components/UI/Flex/Flex';
import SectionTitle from 'components/UI/SectionTitle/SectionTitle';
import ArrowButton from 'components/UI/Calendar/components/ArrowButton/ArrowButton';
import CalendarItem from 'components/UI/Calendar/components/CalendarItem/CalendarItem';
import WeekDays from 'components/UI/Calendar/components/WeekDays/WeekDays';
import ShowMoreButton from 'components/UI/Calendar/components/ShowMoreButton/ShowMoreButton';
import Loader from 'components/UI/Loader/Loader';
import { SHOWN_DAYS, calcDaysRange, shiftDaysRange, humanizeDate, checkDateIsPast, calcCurrentDay } from 'components/UI/Calendar/utils';
import Context from 'components/UI/Calendar/context';
import styles from './Calendar.module.scss';

function Calendar({
  startDate,
  dates,
  selectedDates,
  isLoading,
  resetData,
  isSelectedDaysAvailable,
  onStartDateChange,
  onClick,
  withMultipleSelect,
  type,
  timeZone,
  allowPast,
  classes,
}) {
  const calendarRef = useRef(null);
  const todayStamp = Date.now();
  const [showMore, setShowMore] = useState(false);
  const [endDate, setEndDate] = useState(calcDaysRange(todayStamp).endDate);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startSelectedDate, setStartSelectedDate] = useState(null);
  const [contextMenuID, setContextMenuID] = useState(-1);

  const days = showMore ? dates : dates.slice(0, SHOWN_DAYS / 2);

  const handleArrowButtonClick = (evt) => {
    const type = evt.target.dataset.arrow;
    const {startDate: start, endDate: end } = shiftDaysRange(type, startDate);
    selectedDates.clear();
    onStartDateChange(start);
    setEndDate(end);
    resetData();
  };
  const handleMouseDown = (id) => {
    setIsMouseDown(true);
    if (withMultipleSelect) {
      selectedDates.has(id) ? selectedDates.delete(id) : selectedDates.add(id);
      setStartSelectedDate(id);
    } else {
      if (selectedDates.size > 0 && !selectedDates.has(id)) {
        selectedDates.clear();
      }
      selectedDates.has(id) ? selectedDates.delete(id) : selectedDates.add(id);
      onClick(id);
    }
  };
  const handleMouseUp = (id) => {
    setIsMouseDown(false);
    setContextMenuID(id);
    if (selectedDates.size === 0) {
      resetData();
    }
  };
  const handleMouseEnter = (id) => {
    if (!isMouseDown || !withMultipleSelect) {
      return;
    }
    // if mouse move to right get selected from left to right
    if (id > startSelectedDate) {
      days.forEach((item, index) => {
        const dayID = item?.id || index;
        dayID >= startSelectedDate && dayID <= id
          ? selectedDates.add(dayID)
          : selectedDates.delete(dayID);
      });
      // else get selected from right to left
    } else {
      days.forEach((item) => {
        item.id <= startSelectedDate && item.id >= id
          ? selectedDates.add(item.id)
          : selectedDates.delete(item.id);
      });
    }
  };

  useEffect(() => {
    onStartDateChange(calcDaysRange(todayStamp).startDate);
  }, []);

  return (
    <Context.Provider value={{ isSelectedDaysAvailable, selectedDates, onClick, type, timeZone }}>
      <div className={`${styles.calendar} ${classes}`}>
        <div className={styles.wrapper}>

          <Flex classes={styles.header}>
            <SectionTitle
              title={`${humanizeDate(startDate, 'withoutYear')} - ${humanizeDate(endDate)}`}
              withMargin={false}
              adaptive
            />
            <Flex>
              <ArrowButton left isDisabled={checkDateIsPast(startDate) && !allowPast} onClick={handleArrowButtonClick} />
              <ArrowButton onClick={handleArrowButtonClick} />
            </Flex>
          </Flex>

          <WeekDays />

          <div className={styles.dayslist_wrapper}>
            <ul className={styles.dayslist} ref={calendarRef}>
              {days.map((day, index) => {
                const currentDay = calcCurrentDay(startDate, index);
                const dayID = day?.id || index;

                return (
                  <CalendarItem
                    key={dayID.toString()}
                    id={dayID}
                    data={day}
                    type={type}
                    contextMenuID={contextMenuID}
                    currentTime={currentDay}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseEnter={handleMouseEnter}
                    isSelected={selectedDates.has(dayID)}
                    withMultipleSelect={withMultipleSelect}
                    allowPast={allowPast}
                  />
                )
              })}
            </ul>
            {isLoading &&
              <Loader classes={styles.loader}/>
            }
          </div>
        </div>

        <ShowMoreButton isShown={showMore} onClick={setShowMore} />
      </div>
    </Context.Provider>
  );
}

Calendar.propTypes = {
  dates: PropTypes.array.isRequired,
  selectedDates: PropTypes.oneOfType([
    PropTypes.instanceOf(ObservableSet),
    PropTypes.instanceOf(Set),
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetData: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  withMultipleSelect: PropTypes.bool,
  isSelectedDaysAvailable: PropTypes.bool,
  allowPast: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  type: PropTypes.oneOf(['teacher-schedule', 'schedule-settings', 'record-customer', 'record-online', 'booked-slots']),
  timeZone: PropTypes.number,
  classes: PropTypes.string,
};

Calendar.defaultProps = {
  withMultipleSelect: false,
  type: '',
  allowPast: false,
  timeZone: 0,
  classes: '',
};

export default observer(Calendar);
