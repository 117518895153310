import React from "react";
import { localisationMaster } from "Localisation/Localisation";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "components/ContentBox/img/arrow-right.svg";
import "./Bundles.scss";

const Bundles = ({
  program,
  age,
  questions_count,
  name,
  id,
  icon
}) => (
  <div className="placementTestBundle">
    <div className="placementTestBundle__left">
      {icon()}
      <div className="placementTestBundle__options">
        <p>{name}</p>
        <p>
          {age.name} | {program?.name} | {localisationMaster("text_42", "c/tests/placement-test/create-widget")}: {questions_count || 0}
        </p>
      </div>
    </div>
    <Link to={`/c/widget/bundle/${id}`} target="_blank">
      {localisationMaster("link", "c/tests/placement-test/create-widget")}
      <ArrowRight />
    </Link>
  </div>
);

export default Bundles;
