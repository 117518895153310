import React from 'react';
import styles from './Avatar.module.scss';
import {colors} from 'components/ContentBox/Global/ContentTable_test/avatar';
import Stores from 'Stores';

const Avatar = ({ link = null, first_name, last_name }) => {
    if(link) {
        return (
            <div className={styles.avatar}>
                <img src={link} alt="avatar" />
            </div>
        )
    }

    return (
        <div className={styles.avatar}>
            <div 
                style={{backgroundColor: colors(first_name + last_name)}}
                className={styles.avatar__name}
            >
                {Stores.transliterate(first_name.substr(0, 1) + last_name.substr(0, 1))}
            </div>
        </div>
    )
}

export default Avatar;