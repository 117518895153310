import React from "react";
import { classNames as cn } from "helpers";
import "./GroupSelector.scss";
import { translate as loc, dict } from "Localisation";

export default function GroupSelector(props) {
  const getWeekdays = (arr) => {
    const weekdays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    return arr.map((x) => weekdays[x - 1]);
  };

  return (
    <div className="groupSelector">
      <div className="modalAddPayment__field">
        <p className="superModalGroup__label">{loc(dict.pays.text_2)}:</p>
        <div className="modalAddPayment__groupList">
          {props.groups.map((g) => (
            <div
              key={g.id}
              onClick={() => props.onChange(g)}
              className={cn({
                groupSelector__item: true,
                "groupSelector__item--active":
                  g.student_group_rel === props.value,
              })}
            >
              <div
                className={cn({
                  groupSelectorPoint: true,
                  "groupSelectorPoint--active":
                    g.student_group_rel === props.value,
                })}
              />
              <div className="groupSelector__content">
                <div className="groupSelector__title">
                  {g.number} - {g.name}
                </div>
                {Object.keys(g.schedule).length > 0 &&
                <div className="groupSelector__subtitle">
                  {`${g.schedule.start_at?.substring(0, 5)} - ${g.schedule.finish_at?.substring(0, 5)} | ${getWeekdays(g.schedule.weekdays).join("/")} | Менеджер: ${g?.responsible?.last_name} ${g?.responsible?.first_name}`}
                </div>}
              </div>
            </div>
          ))}
          {props.companies.map((c) => (
            <div
              key={c.id}
              onClick={() => props.onChange(c)}
              className={cn({
                groupSelector__item: true,
                "groupSelector__item--active": c.company.id === props.value,
              })}
            >
              <div
                className={cn({
                  groupSelectorPoint: true,
                  "groupSelectorPoint--active": c.company.id === props.value,
                })}
              />
              <div className="groupSelector__text">
                {loc(dict.pays.text_3)} {c.company.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
