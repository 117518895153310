import React, { useEffect, useState } from 'react'
import { superAxiosRequest } from '../../../../../../axiosApi'
import modalStore from '../../../../../../Stores/modalStore'
import Idlist from '../../../Idlist/idlist'

const Certificates = () => {
    const {changeValues, changeValuesArray, errorChecker, modalValue, errorColor} = modalStore
    const [companies, setcompanies] = useState([])

    const getIds =(data)=>{
        var cleanId = []
        for(let i =0; i < data.length; i++){
            cleanId.push(data[i]['id'])
        }
        changeValues(cleanId, 'companies');
    }

    useEffect(() => {
      let conf  = {
          link: 'db/companies',
          method: 'get',
          params: {
              limit: 999
          }
      }
      superAxiosRequest(conf).then(res=> setcompanies(res.data.result))
    }, [])
    return (
        <div className='superModalGroup'>
            <div className="superModalGroup__modalLine superModalGroup__modalLine--first">
                <div style={{width: '100%'}}>
                    <p className="superModalGroup__label">Название сертификата:</p>
                    <input onChange={e => changeValues(e.target.value, 'name')} value={modalValue.name} placeholder="Название сертификата"/>
                </div>
            </div>
            <div className="superModalGroup__modalLine">
                <div>
                    <p className="superModalGroup__label">Дней действия:</p>
                    <input type="number" onChange={e => changeValues(e.target.value, 'days_active')} value={modalValue.days_active}/>
                </div>
                <div>
                    <p className="superModalGroup__label">Сумма сертификата, тг:</p>
                    <input type="number" onChange={e => changeValues(e.target.value, 'amount')} value={modalValue.amount}/>
                </div>
            </div>
            <div className="superModalGroup__modalLine">
                <div style={{width: '100%'}}>
                    <Idlist height="200px" items={companies}
                        selected={modalValue.companies}
                        getIds={getIds}
                    />
                </div>
            </div>
        </div>
    )
}

export default Certificates
