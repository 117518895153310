import React, { useState,useEffect, Fragment, useCallback } from 'react';
import './Avatar.css';

export default function Avatar(props) {


    return (
        <div className="avatarContent" style={{width:props.width,  height:props.height, borderRadius: props.radius}} >
            <img src={props.link} />
        </div>
        
    );

}
