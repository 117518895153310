import React from 'react';
import DayOfWeek from '../../Global/DayOfWeek/DayOfWeek';
import { createJobTime } from '../../Online-schedule/components/functions'
import { localisationMaster } from "Localisation/Localisation";
import {classNames as cn} from "helpers";
import Checkbox from '../../Global/Checkbox';
import SelectCustom from '../../Global/Select/SelectCustom';
import './GroupFilter.scss';
import styles from 'components/Modules/Slots/SlotSettings/components/Tabs/PaidTab/Employee.module.scss';

export default function GroupFilter({data, onChange, drop, setFilter, timeInterval, setTimeInterval}) {
    const levels = [
        // {id: 4, name: 'InterPress Lite'},
        {id: 5, name: 'InterPress Smart'},
        {id: 6, name: 'InterPress Pro'},
    ]

    const changeHandler = (value, key) => {
        onChange(value, key);
    }

    const createSoftTime = (d, type) => {
        let arr = createJobTime()
        let arr_new = []
        let beginTime = new Date('01.01.2020 ' + d.time_start);
        let endTime = new Date('01.01.2020 ' + d.time_end);
        for (let i = 0; i < arr.length; i++) {
            if (type === 'end') {
                if ((new Date('01.01.2020 ' + arr[i].time)) > beginTime)
                    arr_new.push(
                        { id: arr[i].time, name: arr[i].time }
                    )
            } else {
                if ((new Date('01.01.2020 ' + arr[i].time)) < endTime)
                    arr_new.push(
                        { id: arr[i].time, name: arr[i].time }
                    )
            }
        }
        return arr_new
    }

    const multiple = (d) => {
        let a  = d.map(x => x.id)
        onChange(a, 'location')
    }

    const levelsHandler = (d) => {
        let arr = data.course_level
        if(arr.includes(d)){
            arr = arr.filter(x => x !== d)
        } else {
            arr.push(d)
        }
        onChange(arr, 'course_level')
    }

    const levelTpl = lvl =>
        <div key={lvl.id}
             onClick={() => levelsHandler(lvl.id)}
             className={cn({
                 'group-filter__level': true,
                 'group-filter__level--selected': data.course_level?.includes(lvl.id),
             })}>
            {lvl.name}
        </div>

    return (
        <div className="group-filter">
            <div className="group-filter__levels">{levels.map(lvl => levelTpl(lvl))}</div>

            <div className="group-filter__selectors">
                <div className="group-filter__selectorUnit">
                    <p> {localisationMaster('text_30', 'groups/all-groups')}</p>
                    <SelectCustom
                        placeholder={'Дисциплина'}
                        apiLink="db/disciplines"
                        apiGetResult={(value) => value.result.sort((a, b) => a.id - b.id)}
                        valueID={data.discipline || 0}
                        onChange={o => changeHandler(o.id, 'discipline')}
                        isSearchable
                    />
                </div>
                <div className="group-filter__selectorUnit">
                    <p> {localisationMaster('text_32', 'groups/all-groups')}</p>
                    <SelectCustom
                        placeholder={'Локация'}
                        apiLink="db/locations"
                        valueID={data.location || [0]}
                        onChange={o => multiple(o)}
                        isMulti
                    />
                </div>
            </div>
            <div className="group-filter__selectors">
            <div className="group-filter__selectorUnit">
                    <p> {localisationMaster('text_34', 'groups/all-groups')}</p>
                    <div className="group-filter__selectorUnitDouble">
                        <SelectCustom 
                            placeholder={'Локация'}
                            options={createSoftTime(data)}
                            valueID={data.time_start || '08:00'}
                            onChange={o => changeHandler(o.id, 'time_start')}
                        />
                        <SelectCustom 
                            placeholder={'Локация'}
                            options={createSoftTime(data, 'end')}
                            valueID={data.time_end || '22:00'}
                            onChange={o => changeHandler(o.id, 'time_end')}
                        />
                    </div>
                </div>
                <div className="group-filter__selectorUnit">
                    &nbsp;
                    {!timeInterval && (
                        <button
                            className={styles.button}
                            type='button'
                            onClick={() => setTimeInterval(true)}
                            aria-label='Добавить дополнительное время'
                        />
                    )}
                    {timeInterval && (
                        <>
                            <div className="group-filter__selectorUnitTriple">
                                <SelectCustom 
                                    options={createSoftTime(data, 'end')}
                                    valueID={data.time_start_plus}
                                    onChange={o => changeHandler(o.id, 'time_start_plus')}
                                />
                                <SelectCustom 
                                    options={createSoftTime(data, 'end')}
                                    valueID={data.time_end_plus}
                                    onChange={o => changeHandler(o.id, 'time_end_plus')}
                                />
                                <button
                                    className={`${styles.button} ${styles.button__remove}`}
                                    type="button"
                                    onClick={() => setTimeInterval(false)}
                                    aria-label="Удалить дополнительное время"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="group-filter__days">
                <DayOfWeek objectChangeHandler={changeHandler} bl={data.weekdays} />
            </div>

            <div className="group-filter__line">
                <div>
                    <Checkbox label={localisationMaster('text_35', 'groups/all-groups')}
                              id="uno"
                              key_name={'online'}
                              checked={data.online}
                              isChecked={() => changeHandler(!data.online, 'online')}/>
                </div>
                <div className="filter-box__buttons">
                    <button className="filter-box__apply-button"
                            onClick={() => setFilter()}>
                        {localisationMaster('text-7', 'filter')}
                    </button>
                    <span className="filter-box__reset-button"
                          onClick={drop}>{localisationMaster('text-10', 'filter')}
                        </span>
                </div>
                {/*  <button onClick={drop} style={{minWidth: '280px'}}>{localisationMaster('text_37', 'groups/all-groups')}</button> */}
            </div>
        </div>
    )
}
