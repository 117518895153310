import React, {useEffect, useState} from 'react';
import Stores from 'Stores';

import "./QuestionImagesMatchText.scss"

export default function QuestionImagesMatchText(props) {

    const [words, setWords] = useState([])
    const [result, setResult] = useState([])
    const [answersButton, setAnswersButton] = useState(false)

    //обновление стейта с ответами в компоненте с самим вопросом
    useEffect(() => {
        setAnswersButton(!words.length)
        props.handleAnswers(result, answersButton)
    }, [result, answersButton])

    let dragged, dragIndex

    const onDragStartHandler = (e, i) => {
        dragged = e.target
        dragIndex = i
        e.target.classList.add('onDrag')
    }

    const onDragEndHandler = e => {
        e.target.classList.remove('onDrag')
    }

    const onDragEnterHandler = e => {
        e.target.classList.add('onDragOver')
    }

    const onDragLeaveHandler = e => {
        e.target.classList.remove('onDragOver')
    }

    const onDragOverHandler = e => {
        // Нужно проверить, есть ли перетаскиваемый элемент, чтобы защитить от перетаскивания слова из другого упражнения
        if (dragged)
            // prevent default to allow drop
            e.preventDefault()
    }

    const onDropHandler = ({target}, index) => {
        target.classList.remove('onDragOver')

        // Удаляем элемент из слов
        const word = words[dragIndex]
        setWords(words.filter((x,i) => i!==dragIndex))

        const arr = result.slice()
        arr[index].description = word
        setResult(arr)
    }

    useEffect(() => {
        setWords(props.items.description_list)
    }, [props.items.description_list])

    useEffect(() => {
        let arr = []
        props.items.value_list.forEach(link => {
            arr.push({link: link, description: ''})
        })
        setResult(arr)
    }, [props.items.value_list])

    useEffect(() => {
    }, [result])

    const dropZone = index =>
        result[index].description ?
            <div
                key={index}
                className="questionImagesMatchText__unit"
                onClick={() => {
                    let arr

                    const word = result[index].description

                    arr = result.slice()
                    arr[index].description = ''
                    setResult(arr)

                    arr = words.slice()
                    arr.push(word)
                    setWords(arr)
                }}>
                <figure>
                    <img src={Stores.baseLink() + result[index].link} alt=""/>
                </figure>
                <div>{result[index].description}</div>
            </div>
            :
            <div
                key={index}
                className="questionImagesMatchText__unit"
                onDragEnter={onDragEnterHandler}
                onDragLeave={onDragLeaveHandler}
                onDragOver={onDragOverHandler}
                onDrop={e=>{onDropHandler(e, index)}}
            >
                <figure>
                    <img src={Stores.baseLink() + result[index].link} alt=""/>
                </figure>
                <div/>
            </div>

    return (
        <div className="questionImagesMatchText">
            <div dangerouslySetInnerHTML={{__html: props.text}} className="lessonQuestion__contentText"/>
            {(props.is_clean_ex || (props.is_teacher_view && (!props.at))) ?
            <>
            <div className="lessonQuestion__wordsBox">
                {words.map((desc, index)=>
                <span
                    key={index}
                    draggable
                    onDragStart={e => {onDragStartHandler(e, index)}}
                    onDragEnd={onDragEndHandler}
                >
                    {desc}
                </span>
                )}
            </div>
            <div className="questionImagesMatchText__images">
                {result.map((unit, index) =>
                dropZone(index)
                )}
            </div>
            </> :
            null
            }
            {(props.is_student_checked_try || (props.is_teacher_view && props.at)) ?
            <div className="questionImagesMatchText__images">
                {result.map((unit, index) =>
                    <div key={index} className="questionImagesMatchText__unit">
                        <figure>
                            <img src={Stores.baseLink() + (props.answ_array[index].split('~~~'))[0]} alt=""/>
                        </figure>
                    <div className={props.correct_answ[index] ? 'lessonQuestion__correctAnswer' : 'lessonQuestion__wrongAnswer'}>
                        {(props.answ_array[index].split('~~~'))[1]}</div>
                    </div>
                )}
            </div> :
            null
            }
        </div>
    );
};