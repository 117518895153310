import React from 'react';
import st from './SectionEditMenu.module.scss'
import {translate as loc, dict} from "Localisation";
import {ControlPanel} from "./ControlPanel/ControlPanel";
import {AddButton} from "./AddButton/AddButton";
import {checkPermList} from "helpers";
import {permissions} from "settings/permissions";


export default function SectionEditMenu({sections, action, activeSection, setActiveSection, translate}) {
    const scrollToTop = () => {
        if (window.innerWidth > 1440) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    return (
    <div className={st.wrapper}>
      <div className={st.row}>
        <div>{`${loc(dict.books.sections)}: ${sections.length}`}</div>
      </div>
      {sections.length > 0 ? sections.map((section, index) => (
          <div key={section.id} className={st.section} onClick={() => {
              scrollToTop()
              setActiveSection(null, index + 1)}
          }>
            <span className={activeSection === index + 1 ? st.activeName : st.name}>{section.name}</span>
             {/* EDITP*/}
              {(checkPermList(permissions.change_chapter_section) && <ControlPanel sections={sections} section={section} index={index} action={action}/> )}
          </div>
        ))
        :
        <div className={st.empty}>{translate.no_sections}</div>
      }
        {/* EDITP*/}
        {(checkPermList(permissions.add_chapter_section) &&
            <div className={st.btn}>
            <AddButton action={action}>{translate.add_new_section}</AddButton>
        </div>)}
    </div>
    );
}
