import React, { useEffect, useState } from 'react'
import { superAxiosRequest } from 'axiosApi';
import { localisationMaster } from "Localisation/Localisation";
import Stores from 'Stores'
import ContentHeader from '../Global/ContentHeader'
import GroupFilter from './components/GroupFilter'
import SurfMenu from './components/SurfMenu'
import GroupUserUnit from './components/GroupUserUnit'
import Loader from '../Global/Loader/Loader'

export default function GroupListClients(props) {
    const { profileId } = Stores;
    const [udata, setUdata] = useState(null)
    const [gropsList, setgropsList] = useState([false, []])
    const [type, settype] = useState(1)
    const [timeInterval, setTimeInterval] = useState(false);
    const initFilter = {
        course_level: [5, 6],
        time_start: '09:00',
        time_end: '13:00',
        weekdays: [1, 2, 3, 4, 5, 6, 7],
        online: true,
    }
    const [filter, setFilter] = useState(initFilter)

    const menu = [
        { name: localisationMaster('text_3', 'groups/all-groups'), active: true, link: 1 },
        { name: localisationMaster('text_4', 'groups/all-groups'), active: false, link: 2 },
    ]

    const dropFilters = () => {
        setFilter({ ...initFilter })
        getList(initFilter)
    }

    const filterChangeHandler = (val, key) => {
        setFilter({ ...filter, [key]: val })
    }

    const getList = (f) => {
        let fil = { ...filter }
        if (!!f) fil = { ...f }

        if(!timeInterval) {
            delete fil.time_start_plus;
            delete fil.time_end_plus;
        }
        let apiConf = {
            method: 'get',
            link: 'groups/for_subscribe',
            params: {
                status: type,
                limit: 999,
                client_id: props.match.params['id'] || profileId,
                ...fil
            }
        }
        superAxiosRequest(apiConf).then(response => {
            setgropsList([true, response.data.result])
        }).catch(() => {
            setgropsList([true, []])
        });
    }

    const changeType = data => {
        settype(data)
    }

    useEffect(() => {
        setgropsList([true, []])
        getList()
    }, [type])


    useEffect(() => {
        if (profileId === +props.match.params['id'] || !props.match.params['id']) return
        superAxiosRequest({
            link: 'accounts/client_info/' + +props.match.params['id'],
            method: 'get'
        }).then(res => setUdata(res.data))
    }, [])

    return (
        <>
            <ContentHeader title={localisationMaster('text_20', 'groups/all-groups')} />
            <GroupFilter timeInterval={timeInterval} setTimeInterval={setTimeInterval} data={filter} onChange={filterChangeHandler} drop={dropFilters} setFilter={getList} />
            <SurfMenu menu={menu} changeType={changeType} type={type} />
            {!gropsList[0] ? <Loader/> : <div className="user-group-listing">
                {gropsList[0] && gropsList[1].length > 0 ?
                    gropsList[1].map((item, index) =>
                        <GroupUserUnit drop={dropFilters} key={index} items={item}
                                       udata={udata} id_user={+props.match.params['id'] || profileId}/>)
                    :
                    <div className="sg-probka">
                        {localisationMaster('text_21', 'groups/all-groups')}
                    </div>
                }
            </div>}
        </>
    )
}
