import {observable, action, computed, toJS, makeObservable} from 'mobx';
import Stores from '.';
import cogoToast from "cogo-toast";

class ModalStore {
  open = false;
  loading = false;
  data = {};
  errors = [];
  modalValue = {};
  tags = {};
  reload = false;
  errorColor = '#db3031';
  formErrors = [];

  constructor() {
    makeObservable(this, {
      open: observable,
      data: observable,
      errors: observable,
      loading: observable,
      modalValue: observable,
      formErrors: observable,
      reload: observable,
      errorColor: observable,
      tags: observable,

      activity: action,
      loadingHandler: action,
      changeValues: action,
      addTag: action,
      deleteWithKey: action,
      listInKeyChangeHandler: action,
      changeValuesArray: action,
      setValid: action,
      deleteErrors: action,
      setLink: action,

      getModalValues: computed,
      modalValues: computed,
    })
  }

  activity = (config, inner_data) => {
    if (this.open) {
      this.open = false

      if (this.loading) this.loadingHandler(false)
      this.tags = {};
      this.data = {};
      this.modalValue = {};
    } else {
      if (config) {
        this.data = config;
        if (inner_data) this.modalValue = {...inner_data}
      }
      this.open = true
    }
  }

  loadingHandler = state => {
    this.loading = state
  }

  changeValues = (value, key, in_key) => {
    const values = toJS(this.modalValue)
    if (in_key) {
      values[in_key][key] = value
      this.modalValue = {...values}
    } else {
      this.modalValue = {...values, [key]: value}
    }
    // Эксперимент
    if (this.formErrors.includes(key)) {
      this.formErrors = this.formErrors.filter(x => x !== key)
    }
  }

  listInKeyChangeHandler = (array, key_name, in_key) => {
    let a = array.map(el => el);
    if (in_key) {
      this.modalValue[in_key][key_name] = [...a];
    } else {
      this.modalValue[key_name] = [...a];
    }
  }

  changeValuesArray = obj => {
    this.modalValue = {...obj}
  }

  addTag = (tag, name) => {
    this.tags = {...this.tags, [name]: tag}
  }

  deleteWithKey = (dKey) => {
    const values = Stores.observableArray(this.modalValue)
    //let cleaned = {}
    let cleaned = {...values}
    /*for(let key in values){
        if(key !== dKey) cleaned[key] = values[key]
    }*/
    delete cleaned[dKey]
    this.modalValue = cleaned
  }

  setLink = link => {
    this.data = {...this.data, link}
  }

  setValid = valid => {
    this.deleteErrors()
    this.data = {...this.data, valid}
  }

  deleteErrors = () => {
    this.errors = []
    this.formErrors = []
  }

  get modalSettings() {
    return toJS(this.data)
  }

  get getModalValues() {
    return Stores.observableArray(this.modalValue)
  }

  get modalValues() {
    return toJS(this.modalValue)
  }

  check = key => {
    //const values = toJS(this.formErrors)
    return Object.values(this.formErrors).includes(key)
  }

  changeErrors = (key_error) => {
    this.errors = {...key_error}
  }

  errorChecker = (key) => {
    const values = Stores.observableArray(this.errors)
    return Object.values(values).includes(key)
  }

  ShowToast = (content, type) => {
    const types = {
      error: cogoToast.error,
      info: cogoToast.info,
      success: cogoToast.success,
      warn: cogoToast.warn,
    };

    const options = {
      hideAfter: 4,
      position: 'top-center',
    };

    types[type](content, options);

    //const audio = new Audio(quack);
    //audio.play();
  };
}

export default new ModalStore()
