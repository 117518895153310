import React, { useEffect, useState } from "react";
import { superAxiosRequest } from "axiosApi";
import Loader from "../../Global/Loader/Loader";
import ContentHeader from "../../Global/ContentHeader";
import { phoneMask } from "../../../../helpers/phoneMask";
import { localisationMaster } from "Localisation/Localisation";
import { timeConverter } from "../../Online-schedule/components/functions";
import { ReactComponent as TimerIcon } from "./icons/clock.svg";
import { ReactComponent as TrueAnswer } from "../../img/true.svg";
import { ReactComponent as WrongAnswer } from "../../img/false.svg";
import {
  agetostr,
  getTimeStr,
} from "../../Online-schedule/components/functions";
import "./style.scss";

const PlacementTestResult = (props) => {
  const [content, setContent] = useState({
    answers: [],
    total_questions: 0,
    total_answers: 0,
    correct_answers: 0,
    phone: "",
    date_end: null,
    birth_date: null,
    date_start: null,
  });
  const [loader, setLoader] = useState(false);
  const id = props.match.params.id;

  useEffect(() => {
    setLoader(true);
    superAxiosRequest({
      link: `placement_test/finished_tests/${id}`,
      method: "get",
    }).then(({ data }) => {
      setContent(data);
      setLoader(false);
    });
  }, [id]);

  const recordOnline = () => {
    const body = {
      first_name: content.first_name,
      email: content.email,
      birthday: content.birth_date,
      ...(content.father_name && { father_name: content.father_name }),
      ...(content.last_name && { last_name: content.last_name }),
      ...(content.phone && { phone: content.phone }),
      ...(content.level && { level: content.level }),
  };
    localStorage.setItem('save_data_record_customer', JSON.stringify(body));
    window.open("/online-schedule-v2/record-customer/");
  };

  if (loader) return <Loader />;
  return (
    <div className="placementTestResult">
      <ContentHeader
        breadСrumbs={[
          { title: 'Клиенты прошедшие тест', route: '/c/tests/placement-test-clients' }
        ]}
        title={
          content.bundle?.name
            ? content.bundle?.name +
              ": " +
              localisationMaster("text_4", "placement_test/result")
            : "Сбор данных о клиенте"
        }
      />
      <div className="placementTestResult__box">
        {content?.bundle ? (
          <div className="placementTestResult__corrects">
            <p>{content.correct_answers}</p>
            <p>correct: answers</p>
            {content?.bundle ? (
              <p>
                {content.level
                  ? content.level
                  : `
                ${localisationMaster("text_3", "placement_test/result")} ${
                      content.total_answers
                    } / ${content.total_questions}
              `}
              </p>
            ) : null}
          </div>
        ) : null}
        <div className="placementTestResult__content">
          <div className="placementTestResult__user">
            <div className="placementTestResult__user--name">
              <p>
                {content.last_name || ""}{" "}
                {content.first_name ||
                  localisationMaster("text_2", "placement_test/result")}
              </p>
              <p>
                {agetostr(content.birth_date)} (
                {timeConverter(content.birth_date, "age")})
              </p>
            </div>
            <div className="placementTestResult__user--contact">
              {content?.phone ? (
                <>
                  <a
                    href={`tel:${
                      content.phone.startsWith("+")
                        ? content.phone
                        : `+${content.phone}`
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {phoneMask(
                      content.phone ||
                        localisationMaster("text_2", "placement_test/result")
                    )}
                  </a>
                  <p>|</p>
                </>
              ) : null}
              <a href={`mailto:${content.email}`} target="_blank" rel="noopener noreferrer">
                {content.email ||
                  localisationMaster("text_2", "placement_test/result")}
              </a>
            </div>
          </div>
          <div className="placementTestResult__description">
            <div className="placementTestResult__description--titles">
              {content?.location ? (
                <p>
                  {localisationMaster("table-row-title-2", "company/profiles")}:
                </p>
              ) : null}
              {content?.bundle ? (
                <p>
                  {localisationMaster(
                    "placeholder-10",
                    "online-schedule/record-customer"
                  )}
                  :
                </p>
              ) : null}
              <p>{localisationMaster("text_1", "placement_test/result")}:</p>
            </div>
            <div className="placementTestResult__description--text">
              {content?.location ? <p>{content.location.name}</p> : null}
              {content?.bundle ? (
                <p>
                  {content.bundle.program.name} | {content.bundle.age.name}
                </p>
              ) : null}
              <p>
                {content?.date_end
                  ? `${timeConverter(content.date_end, "age")} ${new Date(
                      content.date_start
                    ).toLocaleTimeString("ru")} `
                  : localisationMaster("text_2", "placement_test/result")}
              </p>
            </div>
          </div>
          <div className="placementTestResult__description--mobile">
            {content?.location ? (
              <p>
                {localisationMaster(
                  "teacher-info-2",
                  "company/profiles/account"
                )}
              </p>
            ) : null}
            {content?.location ? <p>{content.location.name}</p> : null}
            {content?.bundle ? (
              <>
                <p>
                  {localisationMaster(
                    "placeholder-10",
                    "online-schedule/record-customer"
                  )}
                  :
                </p>
                <p>
                  {content.bundle?.program?.name} | {content.bundle?.age?.name}
                </p>
              </>
            ) : null}
            <p>{localisationMaster("text_1", "placement_test/result")}:</p>
            <p>
              {content.date_end
                ? `${timeConverter(content.date_end, "age")} 
                  ${new Date(content.date_start).toLocaleTimeString("ru")} 
                `
                : localisationMaster("text_2", "test/placement_test")}
            </p>
          </div>
        </div>
      </div>
      <div onClick={recordOnline} className="placementTestResult__btn">
        <p>{localisationMaster("record_to_online", "placement_test/result")}</p>
      </div>
      {content?.bundle ? (
        <div className="placementTestResult__result">
          <div className="placementTestResult__total">
            <div className="placementTestResult__total--box">
              <p className="placementTestResult__total--time">
                <TimerIcon />
                {content.date_end
                  ? getTimeStr(content.date_start, content.date_end)
                  : localisationMaster("text_3", "placement_test/result")}
              </p>
              <p className="placementTestResult__total--correct">
                <TrueAnswer />
                {
                  content.answers
                    .map((item) => item.is_correct)
                    .flat()
                    .filter((item) => item).length
                }
              </p>
              <p className="placementTestResult__total--mistake">
                <WrongAnswer />
                {
                  content.answers
                    .map((item) => item.is_correct)
                    .flat()
                    .filter((item) => !item).length
                }
              </p>
            </div>
          </div>
          <ol className="placementTestResult__answers">
            {content.answers.map((item, index) => {
              return (
                <li key={index}>
                  <p>{index + 1}</p>
                  {item.is_correct.map((item, index) => {
                    return item ? <TrueAnswer key={index} /> : <WrongAnswer key={index} />;
                  })}
                </li>
              );
            })}
          </ol>
        </div>
      ) : null}
    </div>
  );
};

export default PlacementTestResult;
