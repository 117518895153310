import React from 'react';
import PropTypes from 'prop-types';
import { classNames as cn } from '../../../helpers';
import styles from './InputPrice.module.scss';

function InputPrice({
  tag,
  label,
  value,
  currency,
  onChange,
  isError,
  }) {
  const Tag = tag;

  const handleChange = (evt) => {
    let value = evt.target.value;
    value = value.replace(/\D/g, '');
    onChange(+value);
  };

  return (
    <Tag className={styles.wrapper}>
      <label className={styles.label}>
        {label && <span className={styles.label_text}>{label}:</span>}
        <span className={cn({
          [styles.input_wrapper]: true,
          [styles.input_wrapper__error]: isError,
        })}>
          <input
            min={0}
            type='number'
            className={styles.input}
            value={value.toString()}
            onChange={handleChange}
          />
          <span className={styles.currency}>{currency}</span>
        </span>
      </label>
    </Tag>
  );
}

InputPrice.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.oneOf(['div', 'li']),
  label: PropTypes.string,
  isError: PropTypes.bool,
};

InputPrice.defaultProps = {
  tag: 'div',
  label: 'Стоимость',
  isError: false,
}

export default InputPrice;
