import React from 'react';
import style from './ShowMark.module.scss'
import {ReactComponent as Circle} from 'components/UI/icons/circle.svg';
import {ReactComponent as Confirm} from "components/UI/icons/confirm_icon.svg";
import {ReactComponent as Failed} from "components/UI/icons/failed_icon.svg";
import DiscussionButton from "../HomeworkStudent/ReviewInfoBlock/DiscussionButton/DiscussionButton";

const ShowMark = ({mark , type, scroll}) => {

    const grades = [
        {value: 1, symbol: 'A+'},
        {value: 2, symbol: 'A'},
        {value: 3, symbol: 'A-'},
        {value: 4, symbol: 'B+'},
        {value: 5, symbol: 'B'},
        {value: 6, symbol: 'B-'},
        {value: 7, symbol: 'C+'},
        {value: 8, symbol: 'C'},
        {value: 9, symbol: 'C-'},
        {value: 10, symbol: 'D+'},
        {value: 11, symbol: 'D'},
        {value: 12, symbol: 'D-'},
        {value: 13, symbol: 'F'},
    ];
    const grade = grades.find((g) => g.value === mark);

    const renderMark = () => {
        let markColor = '';

        switch (type) {
            case 1:
                if (mark === 1) {
                    markColor = style.markGreen;
                } else {
                    markColor = style.markRed;
                }
                return (
                    <div className={`${style.container} ${markColor}`}>
                        {mark === 1 ? (
                            <>
                                <div>
                                    <Confirm />
                                    <span>Homework Passed!</span>
                                </div>
                                <div>
                                    <DiscussionButton scrollToBlock={scroll}/>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <Failed />
                                    <span>Failed!</span>
                                </div>
                                <div>
                                    <DiscussionButton scrollToBlock={scroll}/>
                                </div>
                            </>
                        )}
                    </div>
                );
            case 2:
                if (mark === 5 || mark === 4) {
                    markColor = style.markGreen;
                } else if (mark === 3) {
                    markColor = style.markYellow;
                } else if (mark === 2 || mark === 1) {
                    markColor = style.markRed;
                }
                return (
                    <div className={`${style.container} ${markColor}`}>
                        <div className={`${style.markContainer}`}>
                            <Circle />
                            <span className={style.mark}>{mark}</span>
                        </div>
                        <span>Teacher Confirmed The Lesson!</span>
                        {/*<DiscussionButton scrollToBlock={scroll}/>*/}
                    </div>
                );
            case 3:
                if (grade?.value >= 1 && grade?.value <= 3) {
                    markColor = style.markRed;
                } else if (grade?.value >= 4 && grade?.value <= 6) {
                    markColor = style.markYellow;
                } else if (grade?.value >= 7) {
                    markColor = style.markGreen;
                }
                return (
                    <div className={`${style.container} ${markColor}`}>
                        <div className={`${style.markContainer}`}>
                            <Circle />
                            <span className={style.mark}>{mark}</span>
                        </div>
                        <span>Teacher Confirmed The Lesson!</span>
                    </div>
                );
            case 4:
                if (grade?.symbol >= 'A' && grade?.symbol <= 'B-') {
                    markColor = style.markGreen;
                } else if (grade?.symbol >= 'C' && grade?.symbol <= 'D-') {
                    markColor = style.markYellow;
                } else if (grade?.symbol === 'F') {
                    markColor = style.markRed;
                }
                return (
                    <div className={`${style.container} ${markColor}`}>
                        <div className={`${style.markContainer}`}>
                            <Circle />
                            <span className={style.mark}>{grade?.symbol}</span>
                        </div>
                        <span>Teacher Confirmed The Lesson!</span>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        renderMark()
    );
};

export default ShowMark;