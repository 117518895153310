import React, { Fragment, useEffect, useState, useMemo } from 'react';
import ContentHeader from "components/UI/ContentHeader/ContentHeader";
import Filter from 'components/ContentBox/Global/Filter/Filter';
import useTableFixedHeader from "hooks/useTableFixedHeader";
import st from "components/ContentBox/Global/ReportTable/Table.module.scss";
import THead from "components/ContentBox/Global/ReportTable/THead";
import Loader from "components/ContentBox/Global/Loader/Loader";
import { superAxiosRequest } from 'axiosApi';
import { dateFormat } from "helpers/date";
import { Link } from 'react-router-dom';
import styles from './ConversionReport.module.scss';
import ContentPagination from 'components/ContentBox/Global/ContentPagination/ContentPagination';
import ExportExcelButton from "../ExportExcelButton/ExportExcelButton";
import {ExcelRowsParams} from '../../../utils/ExcelExport';

const filterCnf = [
    {
        type: 'select-custom',
        placeholder: 'Компания',
        nameState: 'company',
        apiLink: "db/companies",
    },
    {
        type: 'select-custom',
        placeholder: 'Локация',
        nameState: 'location',
        apiLink: "db/locations",
    },
    {
        type: 'input',
        placeholder: 'Фамилия',
        nameState: 'surname'
    },
]

const Clock = () => <span>&#9203;</span>;

const parseCols = (levels) => {
    const result = [{ name: 'Студент' }];
    const head = Object.values(levels).map((level) => {
        return [{ name: level }, { name: <Clock /> }];
    }).flat().slice(0,-1)

    return [...result, ...head];
};

const parseHours = (hours) => Math.round((hours / 60) / 45);

const alphabeticalSorting = (array) =>  array.sort((a, b) => {
    if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
      return -1;
    }
    if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
      return 1;
    }
    return 0;
})

const ConversionReport = () => {
    const [error, setError] = useState(false);
    const [levels, setLevels] = useState({});
    const [meta, setMeta] = useState({});
    const [params, setParams] = useState({});
    const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
    const [conversionStats, setConversionStats] = useState({})

    const {
        refWrapper,
        refSticky,
        refThead,
        data,
        setData,
        isLoading,
        setIsLoading,
    } = useTableFixedHeader();

    const paramsPagination = useMemo(() => ({
        offset: (pagination.page - 1) * pagination.perPage,
        limit: pagination.perPage,
    }), [pagination]);

    const changePagination = (key, data) => setPagination({ ...pagination, [key]: data });

    const sendRequest = async (params) => {
        setIsLoading(true);

        delete params.is_active;

        superAxiosRequest({ 
            method: 'get', 
            link: 'progress_report/conversion', 
            params: { ...params, ...paramsPagination }
        }).then((response) => {
            setIsLoading(false);
            setData(response.data.result);
            setLevels(response.data.levels);
            setMeta(response.data._meta);
            setConversionStats(response.data.conversion_stats)
        }).catch(() => {
            setIsLoading(false);
            setError(true);
        });
    };

    const downloadExcel = (value) => {
        import('xlsx-js-style').then(XLSX => {
            const merges = [];
            const rows = value.map(() => ({ hpx: 60 })).concat({ hpx: 60 });
            const nameLevels = ['Студент']
                .concat(Object.values(levels))
                .map((level, idx) => [level, idx !== 0 && 'Ожидание'])
                .flat()
                .filter((level) => level)
                .slice(0, -1)

            const headRow = nameLevels.map((level) => ({ v: level, ...ExcelRowsParams.headerRowParam }));
            const wscols = nameLevels.map((_, idx) => idx === 0 ? { wch: 35 } : { wch: 25 });
            const tableData = alphabeticalSorting(value).map((student) => {
                const studentLevels = Object.keys(levels).map((key) => student?.levels[key]);
                return [
                    {
                        v: student.full_name,
                        ...ExcelRowsParams.tableRowParam.cell
                    },
                    ...studentLevels.map((level, levelIdx) => {
                        return [
                            levelIdx !== 0 && {
                                v: level?.waited > 0 ? `${level.waited} д.` : '',
                                ...ExcelRowsParams.tableRowParam.cell
                            },
                            {
                                v: `${level?.start ? `с ${dateFormat(level?.start, "dd.mm.YY")}` : ''}
${level?.end ? `по ${dateFormat(level?.end, "dd.mm.YY")}` : ''}
${level?.hours > 0 ? `${parseHours(level?.hours)} ак.ч.` : ''}`,
                                ...ExcelRowsParams.tableRowParam.cell
                            }
                        ].flat();
                    }).flat().filter((item) => item)
                ];
            });
            tableData.push([
                {
                    v: `Студентов: ${conversionStats.count_student}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[1]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_starter_elementary}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[2]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_elementary_pre_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[3]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_pre_intermediate_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[4]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_intermediate_upper_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[5]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_upper_intermediate_advanced}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[6]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_advanced_IELTS}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${levels[7]}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ])
            tableData.push([
                {
                    v: `Количество студентов:`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_starter}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_starter_elementary}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_elementary}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_elementary_pre_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_pre_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_pre_intermediate_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_intermediate_upper_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_upper_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_upper_intermediate_advanced}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_advanced}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.student_count_advanced_IELTS}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.count_student_IELTS}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ])
            tableData.push([
                {
                    v: `Конверсия:`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_starter}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_elementary}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_pre_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_upper_intermediate}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_advanced}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: ``,
                    ...ExcelRowsParams.footerRowParams.cell
                },
                {
                    v: `${conversionStats.share_student_IELTS}`,
                    ...ExcelRowsParams.footerRowParams.cell
                },
            ])


            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([ headRow, ...tableData ]);

            ws['!cols'] = wscols;
            ws["!merges"] = merges;
            ws['!rows'] = rows;
    
            XLSX.utils.book_append_sheet(wb, ws, "List 1");
            XLSX.writeFile(wb, "Отчет по конверсии.xlsx");
        });
    };

    useEffect(() => {
        setError(false);
    }, [data]);

    useEffect(() => {
        sendRequest(params);
    }, [pagination]);

    return (
        <>
            <ContentHeader title="Отчет по конверсии" />
            <Filter  
                config={filterCnf}
                thisPage={null}
                getFilterParams={(filterParams) => {
                    setParams(filterParams);
                    setPagination({ page: 1, perPage: 10 });
                }}
                drop={() => {
                    setParams({});
                    setPagination({ page: 1, perPage: 10 });
                }} 
            />
            {isLoading
                ? <Loader /> 
                : (
                    <>
                        {error 
                            ? <h2 className={styles.title}>Произошла ошибка!</h2> 
                            : <div>
                                <div className={st.tableWrapper} ref={refWrapper}>
                                    <div ref={refSticky} className={st.sticky}>
                                        <table className={st.table}>
                                            <THead cols={parseCols(levels)} />
                                        </table>
                                    </div>
                                    <table className={`${st.table} ${st.tbodyStriped}`}>
                                        <THead cols={parseCols(levels)} ref={refThead} />
                                        <tbody className={st.tbody}>
                                            {Array.isArray(data) && (
                                                alphabeticalSorting(data)
                                                .map((student) => {
                                                    const studentLevels = Object.keys(levels).map((key) => student?.levels[key]);

                                                    return (
                                                        <tr className={`${st.bdt} ${st.thead}`} key={student.id}>
                                                            <td>
                                                                <Link to={`/account_clients/${student.user_id}`}>
                                                                    {student.full_name}
                                                                </Link>
                                                            </td>
                                                            {studentLevels.map((level, levelIdx) => (
                                                                <Fragment key={levelIdx}>
                                                                    {levelIdx !== 0 && (
                                                                        <td>
                                                                            {level?.waited > 0 && `${level.waited} д.`}
                                                                        </td>
                                                                    )}
                                                                    <td className={styles.level}>
                                                                        <p>
                                                                            {level?.start && `с ${dateFormat(level?.start, "dd.mm.YY")}`}
                                                                        </p>
                                                                        <p>
                                                                            {level?.end && `по ${dateFormat(level?.end, "dd.mm.YY")}`}
                                                                        </p>
                                                                        <p>{level?.hours > 0 && `${parseHours(level?.hours)} ак.ч.`}</p>
                                                                    </td>
                                                                </Fragment>
                                                            ))}
                                                        </tr>
                                                    )
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <ContentPagination
                                    page={pagination.page}
                                    allItems={meta.count_items}
                                    per_page={pagination.perPage}
                                    perPage={(number) => changePagination('perPage', number)}
                                    changePage={(number) => changePagination('page', number)}
                                />
                                <div className={styles.totalConversion}>
                                    <table className={`${st.table} ${st.tbodyStriped}`}>
                                        <tbody className={st.tbody}>
                                        <tr className={st.bdt}>
                                            <td className={`${st.tdTotal} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                Студентов: {conversionStats.count_student}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels[1]}`}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_starter_elementary}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['2']}`}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_elementary_pre_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['3']}`}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_pre_intermediate_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['4']}`}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_intermediate_upper_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['5']}`}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_upper_intermediate_advanced}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['6']}`}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_advanced_IELTS}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {`${levels['7']}`}
                                            </td>
                                        </tr>
                                        <tr className={st.bdt}>
                                            <td className={`${st.tdTotal} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                Количество студентов:
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_starter}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle} ${st.bdlN}`}>
                                                {conversionStats.student_count_starter_elementary}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_elementary}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.student_count_elementary_pre_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_pre_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.student_count_pre_intermediate_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.student_count_intermediate_upper_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_upper_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.student_count_upper_intermediate_advanced}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_advanced}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.student_count_advanced_IELTS}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.count_student_IELTS}
                                            </td>
                                        </tr>
                                        <tr className={st.bdt}>
                                            <td className={`${st.tdTotal} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                Конверсия:
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_starter}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle} ${st.bdlN}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_elementary}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_pre_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.textAlignCenter} ${st.tdMiddle}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdlN} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_upper_intermediate}
                                            </td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_advanced}
                                            </td>
                                            <td className={`${st.tdTotal}  ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}></td>
                                            <td className={`${st.tdTotal} ${st.bdrN} ${st.textAlignCenter} ${st.tdMiddle}`}>
                                                {conversionStats.share_student_IELTS}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={styles.excelButton}>
                                    <ExportExcelButton data={data} downloadExcel={downloadExcel}/>
                                </div>
                            </div>
                             
                        }
                    </>
                )
            }
        </>
    )
}

export default ConversionReport;