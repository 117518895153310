import React from 'react'
import {observer} from "mobx-react";
import modalStore from "Stores/modalStore";
import DateSpliter from 'components/ContentBox/Global/DateSpliter/DateSpliter'
import st from "./ModalDateRange.module.css"
import {localisationMaster} from "Localisation/Localisation";

const ModalDateRange = () => {
  const {changeValuesArray, data: ms, modalValue} = modalStore

  return (
    <div className="super-modal__form-column">
      <DateSpliter titles={[
                        `${localisationMaster('start_date', 'groups/group', 'dors')}:`,
                        `${localisationMaster('end_date', 'groups/group', 'dors')}:`,
                    ]}
                   keys={['start_study_at', 'finish_study_at']}
                   drop_date={ms.payload.lastDate || false}
                   yearsStart="currentPlus3"
                   yearsEnd="currentPlus3"
                   arrayChangeHandler={changeValuesArray}
                   fix_data={modalValue}/>
      <div className={st.attention}>
              <strong>{ localisationMaster('text_60', 'modal_templates')}</strong>
      </div>
    </div>
  )
}

export default observer(ModalDateRange);
