import React from 'react';
import st from './TextMarkup.module.scss'
const TextMarkup = ({children}) => {
    return (
        <div className={st.content}>
            {children}
        </div>
    );
};

export default TextMarkup;