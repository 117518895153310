import React, {useState, useEffect} from 'react'

export default function WordMatching(props) {
    const [values, setvalues] = useState([])

    const changeHandler = (e, index, key) =>{
        let arr = values;
        arr[index][key] = e.target.value
        setvalues([...arr])
    }

    const addLIne = () =>{
        let arr = values;
        arr.push(
            {word_1: '', word_2: ''}
            )
        setvalues([...arr])
    }

    const deleteLine = index =>{
        let arr = [];
        for(let i=0; i < values.length; i++){
            if(index !== i){
                arr.push(
                    values[i]
                )
            }
        }
        setvalues([...arr])

    }
    useEffect(() => {
      props.objectChangeHandler(values, 'items')
    }, [values])

    useEffect(() => {
       if(props.update &&  props.value) {
        setvalues(props.value)
        } else {
            setvalues([{word_1: '', word_2: ''}])
        }
    }, [])

    return (
        <>
        <div className="ex_word_matching">
            {
                values.map((item,index)=>{
                    return(
                        <div className="ex_word_matching__line">
                        <input placeholder='Слово 1' type="text" value={item.word_1} onChange={(e)=>changeHandler(e, index, 'word_1')}/>
                        <input placeholder='Слово 2' type="text" value={item.word_2} onChange={(e)=>changeHandler(e, index, 'word_2')}/>
                            {values.length > 1 ? <div className="ex_word_matching__line__close" onClick={()=>deleteLine(index)}>
                            &#10006;
                        </div>: null}
                    </div>
                    )
                })
            }

        </div>
        <div className="modal-question-add-button-add" onClick={()=>addLIne()}> + </div>
        </>
    )
}
