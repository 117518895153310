import React from "react";
import FormLabel from "./FormLabel";
import FormField from "./FormField";
import styles from "./FormDateSelector.module.scss"
import {ReactComponent as CalendarIcon} from "components/UI/icons/calendar-line.svg";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import FormInput from "./FormInput";
import {timeConverter2} from "../../../ContentBox/Online-schedule/components/functions";

export default function FormDateSelector(props) {
    const onClickButtonHandler = (action) => {
        return action === "today" ? props.onChange(new Date()) : props.onChange(null);
    }

    const DpCustomInput = React.forwardRef(({value, onClick}, ref) => (
        <div className={styles.inputContainer} onClick={onClick} ref={ref}>
            <CalendarIcon className={styles.calendarIcon}/>
            <FormInput isError={props.error}>
                <input
                    value={value}
                    className={styles.input}
                    placeholder={props.placeholder}
                    type="text"
                    onChange={() => {}}
                />
            </FormInput>
        </div>
    ));

    return (
        <FormField>
            <FormLabel isRequired={props.isRequired}>
                {props.label}
                {props.button &&
                    <span className={styles.action}
                          onClick={() => onClickButtonHandler(props.button.action)}>
                        {props.button && props.button.name}
                    </span>
                }
            </FormLabel>
            <DatePicker
                selected={props.selected ? new Date(props.selected) : props.value ? new Date(props.value) : null}
                onChange={date => props.onChange(timeConverter2(date))}
                minDate={props.minDate}
                maxDate={props.maxDate}
                excludeDates={props.excludeDates}
                locale={'ru'}
                dateFormat={"yyyy-MM-dd"}
                disabled={props.disabled}
                customInput={<DpCustomInput/>}
            />
        </FormField>
    )
}

FormDateSelector.propTypes = {
    button: PropTypes.shape({
        name: PropTypes.string,
        action: PropTypes.string,
    }),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    excludeDates: PropTypes.array,
};
