import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Calendar from 'components/UI/Calendar/Calendar';
import { getMondayDate } from 'components/UI/Calendar/utils';
import { dateFormat } from 'helpers/date';
import slotsBookedScheduleStore, {ActionType} from 'Stores/slotsBookedScheduleStore';

function CalendarWrapper({classes}) {
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedCompany,
    selectedLocation,
    dates,
    selectedDates,
    resetData,
    getDatesFromServer,
    getCustomersFromServer,
  } = slotsBookedScheduleStore;

  const handleClick = () => {
    if (selectedDates.size === 0) {
      return;
    }
    resetData(ActionType.resetCustomers);
    getCustomersFromServer();
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleReset = () => {
    resetData(ActionType.resetCalendar);
  };

  useEffect(() => {
    const isReadyToLoadCalendar = selectedCompany && selectedLocation;
    const dateFrom = startDate ? new Date(startDate) : new Date();
    if (!isReadyToLoadCalendar) {
      return;
    }
    setIsLoading(true);
    getDatesFromServer({
      params: {
        date_from: dateFormat(getMondayDate(dateFrom), 'YYYY-mm-dd'),
      },
      onFinally: () => setIsLoading(false),
    });
  }, [startDate, selectedCompany, selectedLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Calendar
      startDate={startDate}
      isLoading={isLoading}
      dates={dates}
      selectedDates={selectedDates}
      resetData={handleReset}
      onStartDateChange={handleStartDateChange}
      onClick={handleClick}
      type='booked-slots'
      allowPast={true}
      classes={classes}
    />
  );
}

export default observer(CalendarWrapper);
