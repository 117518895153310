import React from "react";
import PropTypes from "prop-types";
import st from "./FormInput.module.scss"

const FormInput = props => {
  return (
    <div className={props.isError ? st.formInputError : st.formInput}>
      {props.children}
    </div>
  )
}

FormInput.propTypes = {
  isError: PropTypes.bool
}

export default FormInput
