import React, {useEffect, useState} from 'react';
import {superAxiosRequest} from 'axiosApi';
import {localisationMaster} from "Localisation/Localisation";
//import Stores from 'Stores';
import ContentHeader from 'components/ContentBox/Global/ContentHeader';
import Calendar from 'components/ContentBox/Online-schedule/components/Calendar';
import TeacherSlotsCard from './TeacherSlotsCard';
import Loader from 'components/ContentBox/Global/Loader/Loader';
import SlotsExplain from "../components/SlotsExplain/SlotsExplain";
import SelectCustom from "../../Global/Select/SelectCustom";
import slotsStore from '../../../../Stores/slotsStore';
import { observer } from 'mobx-react';

let apiConf;
let Link = 'online-schedule/booked-teachers-schedule'
const BookedTeachersSchedule = () => {
    const { setDateBookedListProxy, dateBookedList, dropSlots} = slotsStore;

    //const [slotsPastLoaded, setslotsPastLoaded] = useState([false, []])
    //const [loaded, setLoaded] = useState([false, []])
    //const [clientsInSlot, setClientsInSlot] = useState(false, [])
    const [locationList, setLocationList] = useState([]);
    const [location, setLocation] = useState(0);
    const [someDay, setSomeDay] = useState(0);
    const [slots, setSlots] = useState([false, []])
    const [dateSaE, setDateSaE] = useState([]);
    const [calendar, setCalendar] = useState([])

    const updateAvailable = day => {
        setSomeDay(day)
        setSlots([false, []])
    }

    const timeConverter = UNIX_timestamp => {
        let a = new Date(UNIX_timestamp);
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        if (date < 10) {
            date = '0' + date;
        }
        return year + '-' + month + '-' + date;
    }

    const createDaysInformation = () => {
        let daysInformation = [];
        let newDate = new Date(dateSaE[0]);
        let day = newDate.getDay()
        if (day === 0) { day = 7 }
        let start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
        let inject, i, somd, dow, c, fullDate, fullDate_timestampt;

        for (i = 0; i < 28; i++) {
            c = i * 86400;
            somd = Math.round(new Date(start).getTime() / 1000);
            somd = (somd + c) * 1000;

            dow = (new Date(somd)).getDay();

            if (dow === 0) { dow = 7 }

            fullDate_timestampt = (new Date(somd)).getTime();
            fullDate = timeConverter(fullDate_timestampt)

            somd = (new Date(somd)).getDate();

            inject = { date: somd, times: '1', time: localisationMaster('text-info', Link), dow: dow, slotSize: '', date_full: fullDate }

            daysInformation.push(
                inject
            )

        }
        return daysInformation
    }

    const createCalendar = (dts, nav) => {
        let newDate = new Date();
        if (dts) newDate = new Date(dts)
        if (nav) {
            switch (nav) {
                case 'prev':
                    newDate = new Date(newDate.setDate(newDate.getDate() - 7));
                    break;
                case 'next':
                    newDate = new Date(newDate.setDate(newDate.getDate() + 7));
                    break;
                default: break;
            }
        }
        let day = newDate.getDay();
        if (day === 0) { day = 7 }
        let start = newDate.setDate(newDate.getDate() - parseInt(day) + 1);
        let end = newDate.setDate(newDate.getDate() + 27);
        setDateSaE([timeConverter(start), timeConverter(end)])
        setSlots([false, []])
    }

    useEffect(() => {
        // get locations
        if (locationList.length < 1) {
            apiConf = {
                method: 'get',
                link: 'db/locations',
                params: {
                    offset: 0,
                    limit: 50,
                    is_active: true,
                }
            }
            superAxiosRequest(apiConf, 'data').then(response => {
                setLocationList(response.data.result);
            });
        }

        createCalendar(new Date())
        return () => {
            dropSlots()
        }
    }, [])


    useEffect(() => {
        if(location !== 0) 
            dropSlots()
            setSomeDay(0)
    }, [location])

  
    useEffect(() => {
       if(someDay === 0) return;
       setDateBookedListProxy(someDay, location)
    }, [someDay])

    useEffect(() => {
        if(dateSaE.length) setCalendar(createDaysInformation())
    }, [dateSaE])

    return (
        <>
            <ContentHeader title={localisationMaster('title', Link)} />
            <div className="onlineSchedulePage">

                <div className="choiceLocation">
                    <div className="choiceLocation__selectors">
                        {/*<select className="choiceLocation__select" onChange={e => setLocation(e.target.value)} value={location}>
                            <option value='0' disabled> {localisationMaster('select-placeholder', Link)} </option>
                            {locationList !== null ?
                                locationList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                })
                                : null
                            }
                        </select>*/}
                        <div className="choiceLocation__selectWrapper">
                            <SelectCustom
                                placeholder={localisationMaster('select-placeholder', Link)}
                                options={locationList}
                                sortKey="name"
                                valueID={location}
                                onChange={o => setLocation(o.id)}
                            />
                        </div>
                    </div>
                </div>

                {location !== 0 &&
                <Calendar
                    loaded={calendar}
                    calendarType="clean"
                    setSomeDay={updateAvailable}
                    someDayValue={someDay}
                    slide={true}
                    SaE={dateSaE}
                    slideAction={createCalendar}
                />
                }

                <div className="onlineSchedulePage__mt20">
                    {dateBookedList.length ?
                        dateBookedList
                            .sort((a, b) => a.teacher_name > b.teacher_name ? 1 : -1)
                            .map(item =>
                                <TeacherSlotsCard key={item.teacher_id} item={item}/>
                            )
                        :
                        (dateBookedList.length < 1 && someDay !== 0) && <Loader/>
                    }
                </div>

                {(dateBookedList.length>0) &&
                    <div className="onlineSchedulePage__mt20">
                        <SlotsExplain/>
                    </div>
                }

            </div>
        </>
    )
}

export default observer(BookedTeachersSchedule);